import retoolIcon from 'assets/resource-icons/retool.svg'
import { AdhocResourceType, ResourceType } from 'common/resourceTypes'

export const RETOOL_VERSION = '2.70.21'
export const RETOOL_CLOUD_DOMAIN = window.MAIN_DOMAIN || 'tryretool.com'
export const RETOOL_ORIGIN = `https://${RETOOL_CLOUD_DOMAIN}`
export const RETOOL_SPECS_DOMAIN = __DEV__
  ? 'http://swagger-spec-server:8480'
  : `https://specs.${RETOOL_CLOUD_DOMAIN}/specs`

export const BILLING_PLAN_NAMES = {
  free: 'Free Plan',
  freePlus: 'Free Plus Plan 5 Seat Limit',
  plus: 'Plus Plan',
  pro: 'Pro Plan',
}

export const PLAN_FEATURES_CATEGORIES = ['Apps', 'Resources', 'Security & Support'] as const
export type PlanFeatureCategory = typeof PLAN_FEATURES_CATEGORIES[number]

export const PLAN_FEATURES: { [key in PlanFeatureCategory]: { label: string; tooltip: string }[] } = {
  Apps: [
    { label: 'Maximum users', tooltip: 'Maximum number of users allowed using the on-prem version of Retool.' },
    {
      label: 'Toggle to user mode',
      tooltip:
        'Editor mode allows all users to edit apps. User mode lets you view an app as a user would, without the editor interface.',
    },
    {
      label: 'Modules',
      tooltip: 'Modules let you reuse groups of components and queries between Retool applications.',
    },
    { label: 'Public apps', tooltip: 'Embed Retool via our SDK, or share your app publicly through an iFrame.' },
    { label: 'Custom components', tooltip: '' },
    { label: 'Custom themes', tooltip: 'Create visual consistency across apps with custom visual themes.' },
    { label: 'Branding', tooltip: 'Add custom branding to navigation bars for app presentation mode and non-editors.' },
  ],
  Resources: [
    {
      label: 'Connect to most database or REST API',
      tooltip: 'Most connectors, except Salesforce, are included in the free plan.',
    },
    { label: 'Query Library﻿', tooltip: 'Our IDE to build, share, and reuse queries for any database or API.' },
    {
      label: 'Staging & product resources',
      tooltip:
        'Test your app against a staging resource (database or API) and seamlessly switch it to production resources when in user mode.',
    },
    {
      label: 'Salesforce, Oracle, & SQL Server integrations﻿',
      tooltip: 'Connect to Salesforce with our native connector, and query it with SOQL / other API endpoints.',
    },
    {
      label: 'Custom connector',
      tooltip:
        'Need a custom connector or component? Just reach out and we will work together with you to get it built.',
    },
  ],
  'Security & Support': [
    {
      label: 'Version release & history',
      tooltip: 'We maintain edit history of your app, so you can restore it to any moment in time.',
    },
    {
      label: 'Granular access controls',
      tooltip: 'Create groups of users, and assign each group particular permissions to particular apps.',
    },
    {
      label: 'Audit logs',
      tooltip: 'Track every query that is run in Retool, including reading / writing from both databases and APIs.',
    },
    {
      label: 'Git syncing',
      tooltip: '',
    },
    {
      label: 'On premise deployment',
      tooltip:
        'Retool can be deployed on-premises, in your own EC2 instance. Setup is via Docker, which takes around 15 minutes. See the FAQs below for more information.',
    },
    { label: 'SAML SSO', tooltip: 'Integrate with Okta, Active Directory, or any other SAML 2.0 SSO provider.' },
    {
      label: 'Priority support',
      tooltip: 'Get priority support with a dedicated customer success manager and a shared Slack channel.',
    },
  ],
}

export const PLANS = ['Free', 'Startup', 'Pro', 'Enterprise'] as const
export type Plan = typeof PLANS[number]

export const PLAN_DETAILS: {
  name: Plan
  price: string
  features: { [key in PlanFeatureCategory]: (string | boolean)[] }
}[] = [
  {
    name: 'Free',
    price: '0',
    features: {
      Apps: ['Up to 5', 'Edit mode only', 'Up to 5', false, false, false, false],
      Resources: [true, true, false, false, false],
      'Security & Support': [false, false, false, false, false, false, false],
    },
  },
  {
    name: 'Startup',
    price: '10',
    features: {
      Apps: ['Up to 5', true, 'Up to 5', false, false, false, false],
      Resources: [true, true, true, false, false],
      'Security & Support': [true, false, false, false, false, false, false],
    },
  },
  {
    name: 'Pro',
    price: '50',
    features: {
      Apps: ['Up to 5', true, true, true, true, true, false],
      Resources: [true, true, true, true, false],
      'Security & Support': [true, true, true, false, false, false, false],
    },
  },
  {
    name: 'Enterprise',
    price: 'Custom pricing',
    features: {
      Apps: ['Unlimited', true, true, true, true, true, true],
      Resources: [true, true, true, true, true],
      'Security & Support': [true, true, true, true, true, true, true],
    },
  },
]

export const QUERY_EDITOR_Z_INDEX = 800

export const ipWhitelistResources = [
  'cosmosdb',
  'couchdb',
  'denodo',
  'elasticsearch',
  'mongodb',
  'mssql',
  'mysql',
  'oracledb',
  'postgresql',
  'presto',
  'redis',
  'redshift',
  'rethinkdb',
  'saphana',
  'vertica',
]

export const miscIcons = {
  analytics: require(`./assets/resource-icons/analytics.svg`),
  transformer: require(`./assets/resource-icons/js-transformer.svg`),
  jsQuery: require(`./assets/resource-icons/js-query.svg`),
  folderclosed: require(`./assets/resource-icons/folder-closed.svg`),
  folderopen: require(`./assets/resource-icons/folder-open.svg`),
  resourceQuery: require('./assets/resource-icons/resource-query.svg'),
  importedQueryIcon: require('assets/resource-logos/retool.svg'),
}

export const resourceIcons: { [key in ResourceType | AdhocResourceType]: string } = {
  postgresql: require(`./assets/resource-icons/postgresql.svg`),
  mysql: require(`./assets/resource-icons/mysql.svg`),
  stripe: require(`./assets/resource-icons/stripe.svg`),
  twilio: require(`./assets/resource-icons/twilio.svg`),
  googlesheets: require(`./assets/resource-icons/googlesheets.svg`),
  s3: require(`./assets/resource-icons/s3.svg`),
  gcs: require(`./assets/resource-icons/gcs.svg`),
  salesforce: require(`./assets/resource-icons/salesforce.svg`),
  sendgrid: require(`./assets/resource-icons/sendgrid.svg`),
  mongodb: require(`./assets/resource-icons/mongodb.svg`),
  redshift: require(`./assets/resource-icons/redshift.svg`),
  mssql: require(`./assets/resource-icons/mssql.svg`),
  athena: require(`./assets/resource-icons/athena.svg`),
  bigquery: require(`./assets/resource-icons/bigquery.svg`),
  graphql: require(`./assets/resource-icons/graphql.svg`),
  elasticsearch: require(`./assets/resource-icons/elasticsearch.svg`),
  cassandra: require(`./assets/resource-icons/cassandra.svg`),
  cosmosdb: require(`./assets/resource-icons/cosmosdb.svg`),
  couchdb: require(`./assets/resource-icons/couchdb.png`),
  rethinkdb: require(`./assets/resource-icons/rethinkdb.png`),
  snowflake: require(`./assets/resource-icons/snowflake.png`),
  denodo: require(`./assets/resource-icons/denodo.png`),
  redis: require(`./assets/resource-icons/redis.png`),
  github: require(`./assets/resource-icons/github.svg`),
  slack: require(`./assets/resource-icons/slack.svg`),
  restapi: require(`./assets/resource-icons/restapi.svg`),
  firebase: require(`./assets/resource-icons/firebase.svg`),
  dynamodb: require(`./assets/resource-icons/dynamodb.svg`),
  basecamp: require(`./assets/resource-icons/basecamp.svg`),
  closeio: require(`./assets/resource-icons/closeio.png`),
  oracledb: require(`./assets/resource-logos/oracledb.png`),
  vertica: require(`./assets/resource-icons/vertica.png`),
  presto: require('./assets/resource-icons/presto.png'),
  saphana: require('./assets/resource-icons/saphana.png'),
  lambda: require(`./assets/resource-icons/lambda.svg`),
  sqltransform: require(`./assets/resource-icons/sqltransform.svg`),
  javascript: require(`./assets/resource-icons/js-query.svg`),
  parentwindow: require(`./assets/resource-icons/parentwindow.svg`),
  pdfexporter: require(`./assets/resource-icons/pdfexporter.svg`),
  openapi: require(`./assets/resource-icons/swagger.svg`),
  retoolapprovalworkflow: retoolIcon,
  datastore: require(`./assets/resource-icons/cloud-datastore.png`),
  shell: require(`./assets/resource-icons/cloud-datastore.png`),
  grpc: require(`./assets/resource-icons/grpc.svg`),
  smtp: require(`./assets/resource-icons/smtp.svg`),
  flows: require(`./assets/resource-icons/flows.svg`),
  globalwidget: require(`./assets/resource-icons/js-query.svg`),
  asana: require(`./assets/resource-icons/asana.svg`),
  datadog: require(`./assets/resource-icons/datadog.svg`),
  jira: require(`./assets/resource-icons/jira.svg`),
  bigid: require(`./assets/resource-icons/bigid.svg`),
  googleAnalytics: require(`assets/resource-icons/google-analytics.svg`),
  googleMaps: require(`assets/resource-icons/google-maps.svg`),
  dropbox: '',
  launchdarkly: require(`assets/resource-icons/launchdarkly.svg`),
  front: require(`assets/resource-icons/front.svg`),
  onesignal: require(`assets/resource-icons/onesignal.svg`),
  openAI: require(`assets/resource-icons/openai.svg`),
  circleci: require('assets/resource-icons/circleci.svg'),
  airflow: require(`./assets/resource-icons/airflow.svg`),
  googleSearchConsole: require('assets/resource-icons/google-search-console.svg'),
  hubspot: require(`assets/resource-icons/hubspot.svg`),
}
export const RESOURCE_DOCS: { [key in ResourceType | AdhocResourceType]: string } = {
  postgresql: 'postgresql-integration',
  mysql: 'mysql-integration',
  stripe: 'stripe-integration',
  twilio: 'twilio-integration',
  googlesheets: 'google-sheets-integration',
  s3: 's3-integration-1',
  gcs: 'google-cloud-storage-integration',
  salesforce: 'salesforce-integration',
  sendgrid: 'sendgrid-integration',
  mongodb: 'mongodb',
  redshift: 'redshift-integration',
  mssql: 'microsoft-sql-server-integration',
  athena: 'aws-athena',
  bigquery: 'bigquery',
  graphql: 'graphql',
  elasticsearch: 'elasticsearch-integration',
  cassandra: 'cassandra-integration',
  cosmosdb: '',
  couchdb: 'couchdb-integration',
  rethinkdb: '',
  snowflake: 'snowflake-integration',
  denodo: '',
  redis: 'redis-integration',
  github: 'github-integration',
  slack: 'slack-integration',
  restapi: 'api-authentication',
  firebase: 'firebase-integration',
  dynamodb: 'dynamodb-integration',
  basecamp: 'basecamp-integration',
  closeio: 'closeio-integration',
  oracledb: 'oracle-database-integration',
  vertica: 'vertica-integration',
  presto: '',
  saphana: 'sap-hana-integration',
  lambda: 'lambda-integration',
  sqltransform: '',
  javascript: '',
  parentwindow: '',
  pdfexporter: '',
  openapi: '',
  retoolapprovalworkflow: '',
  datastore: '',
  shell: '',
  grpc: 'grpc',
  flows: '',
  globalwidget: '',
  smtp: 'smtp-integration',
  asana: 'asana-integration',
  datadog: 'datadog-integration',
  bigid: 'bigid',
  jira: 'jira-integration',
  googleAnalytics: 'google-analytics-integration',
  googleMaps: 'google-maps-integration',
  googleSearchConsole: 'google-search-console-integration',
  dropbox: '',
  launchdarkly: 'launchdarkly',
  front: 'front-integration',
  onesignal: 'onesignal-integration',
  openAI: 'openai-integration',
  circleci: 'circleci-integration',
  airflow: 'airflow-integration',
  hubspot: 'hubspot-integration',
}
export const resourceStyles: {
  [key in ResourceType]: {
    label: string
    logo: string
    openApiLogo?: string
  }
} = {
  postgresql: {
    label: 'Postgres',
    logo: require(`assets/resource-icons/postgresql.svg`),
  },
  mysql: {
    label: 'MySQL',
    logo: require(`assets/resource-icons/mysql.svg`),
  },
  stripe: {
    label: 'Stripe',
    logo: require(`assets/resource-icons/stripe.svg`),
    openApiLogo: require(`assets/resource-icons/stripe_big.svg`),
  },
  twilio: {
    label: 'Twilio',
    logo: require(`assets/resource-icons/twilio.svg`),
    openApiLogo: require(`assets/resource-icons/twilio_big.svg`),
  },
  googlesheets: {
    label: 'Google Sheets',
    logo: require(`assets/resource-icons/googlesheets.svg`),
  },
  s3: {
    label: 'Amazon S3',
    logo: require(`assets/resource-icons/s3.svg`),
  },
  gcs: {
    label: 'Google Cloud Storage',
    logo: require(`assets/resource-icons/gcs.svg`),
  },
  salesforce: {
    label: 'Salesforce',
    logo: require(`assets/resource-icons/salesforce.svg`),
  },
  sendgrid: {
    label: 'SendGrid',
    logo: require(`assets/resource-icons/sendgrid.svg`),
  },
  mongodb: {
    label: 'MongoDB',
    logo: require(`assets/resource-icons/mongodb.svg`),
  },
  redshift: {
    label: 'Amazon Redshift',
    logo: require(`assets/resource-icons/redshift.svg`),
  },
  mssql: {
    label: 'Microsoft SQL',
    logo: require(`assets/resource-icons/mssql.svg`),
  },
  athena: {
    label: 'Amazon Athena',
    logo: require(`assets/resource-icons/athena.svg`),
  },
  bigquery: {
    label: 'BigQuery',
    logo: require(`assets/resource-icons/bigquery.svg`),
  },
  graphql: {
    label: 'GraphQL',
    logo: require(`assets/resource-icons/graphql.svg`),
  },
  elasticsearch: {
    label: 'Elastic Search',
    logo: require(`assets/resource-icons/elasticsearch.svg`),
  },
  cassandra: {
    label: 'Cassandra',
    logo: require(`assets/resource-icons/cassandra.svg`),
  },
  cosmosdb: {
    label: 'CosmosDB',
    logo: require(`assets/resource-icons/cosmosdb.svg`),
  },
  couchdb: {
    label: 'CouchDB',
    logo: require(`assets/resource-icons/couchdb.svg`),
  },
  rethinkdb: {
    label: 'RethinkDB',
    logo: require(`assets/resource-icons/rethinkdb.svg`),
  },
  snowflake: {
    label: 'Snowflake',
    logo: require(`assets/resource-icons/snowflake.png`),
  },
  denodo: {
    label: 'Denodo',
    logo: require(`assets/resource-icons/denodo.png`),
  },
  redis: {
    label: 'Redis',
    logo: require(`assets/resource-icons/redis.svg`),
  },
  github: {
    label: 'GitHub',
    logo: require(`assets/resource-icons/github.svg`),
  },
  slack: {
    label: 'Slack',
    logo: require(`assets/resource-icons/slack.svg`),
  },
  restapi: {
    label: 'REST API',
    logo: require(`assets/resource-icons/restapi.svg`),
  },
  firebase: {
    label: 'Firebase',
    logo: require(`assets/resource-icons/firebase.svg`),
  },
  retoolapprovalworkflow: {
    label: 'Approval Manager',
    logo: require(`assets/resource-icons/retool.svg`),
  },
  dynamodb: {
    label: 'DynamoDB',
    logo: require(`assets/resource-icons/dynamodb.svg`),
  },
  basecamp: {
    label: 'Basecamp',
    logo: require(`assets/resource-icons/basecamp.svg`),
  },
  closeio: {
    label: 'Close.io',
    logo: require(`assets/resource-icons/closeio.png`),
  },
  oracledb: {
    label: 'Oracle DB',
    logo: require(`assets/resource-logos/oracledb.png`),
  },
  vertica: {
    label: 'Vertica',
    logo: require(`assets/resource-icons/vertica.png`),
  },
  presto: {
    label: 'Presto',
    logo: require(`assets/resource-icons/presto.png`),
  },
  saphana: {
    label: 'SAP Hana',
    logo: require(`assets/resource-icons/saphana.png`),
  },
  lambda: {
    label: 'Lambda',
    logo: require(`assets/resource-icons/lambda.svg`),
  },
  openapi: {
    label: 'Open API',
    logo: require(`assets/resource-icons/swagger.svg`),
  },
  datastore: {
    label: 'Cloud Datastore',
    logo: require(`assets/resource-icons/cloud-datastore.png`),
  },
  shell: {
    label: 'Shell Script',
    logo: require(`assets/resource-icons/cloud-datastore.png`),
  },
  grpc: {
    label: 'gRPC',
    logo: require(`assets/resource-icons/grpc.svg`),
  },
  smtp: {
    label: 'SMTP',
    logo: require(`assets/resource-icons/smtp.svg`),
  },
  asana: {
    label: 'Asana',
    logo: require(`assets/resource-icons/asana.svg`),
    openApiLogo: require(`assets/resource-icons/asana_big.svg`),
  },
  datadog: {
    label: 'Datadog',
    logo: require(`assets/resource-icons/datadog.svg`),
    openApiLogo: require(`assets/resource-icons/datadog_big.svg`),
  },
  bigid: {
    label: 'BigID',
    logo: require(`assets/resource-icons/bigid.svg`),
    openApiLogo: require(`assets/resource-icons/bigid_big.svg`),
  },
  jira: {
    label: 'Jira',
    logo: require(`assets/resource-icons/jira.svg`),
    openApiLogo: require(`assets/resource-icons/jira_big.svg`),
  },
  googleAnalytics: {
    label: 'Google Analytics',
    logo: require(`assets/resource-icons/google-analytics.svg`),
    openApiLogo: require(`assets/resource-icons/google-analytics_big.svg`),
  },
  googleMaps: {
    label: 'Google Maps',
    logo: require(`assets/resource-icons/google-maps.svg`),
    openApiLogo: require(`assets/resource-icons/google-maps_big.svg`),
  },
  dropbox: {
    // We don't actually show this anywhere but I'm adding this to make typescript happy
    label: 'Dropbox',
    logo: '',
  },
  launchdarkly: {
    label: 'LaunchDarkly',
    logo: require('assets/resource-icons/launchdarkly.svg'),
    openApiLogo: require('assets/resource-icons/launchdarkly_big.svg'),
  },
  front: {
    label: 'Front',
    logo: require('assets/resource-icons/front.svg'),
  },
  onesignal: {
    label: 'OneSignal',
    logo: require('assets/resource-icons/onesignal.svg'),
    openApiLogo: require('assets/resource-icons/onesignal_big.png'),
  },
  openAI: {
    label: 'OpenAI',
    logo: require('assets/resource-icons/openai.svg'),
    openApiLogo: require('assets/resource-icons/openai_big.svg'),
  },
  circleci: {
    label: 'CircleCI',
    logo: require('assets/resource-icons/circleci.svg'),
    openApiLogo: require('assets/resource-icons/circleci_big.svg'),
  },
  airflow: {
    label: 'Airflow',
    logo: require(`assets/resource-icons/airflow.svg`),
    openApiLogo: require('assets/resource-icons/airflow.svg'),
  },
  googleSearchConsole: {
    label: 'Google Search Console',
    logo: require('assets/resource-icons/google-search-console.svg'),
    openApiLogo: require('assets/resource-icons/google-search-console_big.svg'),
  },
  hubspot: {
    label: 'HubSpot',
    logo: require('assets/resource-icons/hubspot.svg'),
    openApiLogo: require('assets/resource-icons/hubspot_big.svg'),
  },
}
export const importedQueryIcon: string = retoolIcon
export const testIcon: string = require(`assets/test_icon.svg`)
export const testSuiteIcon: string = require(`assets/test_icon.svg`)
export const customAuthResourceTypes: ResourceType[] = ['restapi', 'graphql']

export type SignUpTheme = 'firebase' | 'graphql' | 'mongodb' | 'elasticsearch'

type SignupThemeDetails = Partial<{
  name: string
  headingText: string
  subheadingText: string
  bullet1: string
  bullet2: string
  bullet3: string
  bullet4: string
}>

export const signupThemeMap: Map<SignUpTheme, SignupThemeDetails> = new Map([
  [
    'firebase',
    {
      name: 'Firebase',
      bullet1: 'Pre-built admin panel templates for Firestore, Auth, and Realtime Database',
      bullet2: 'Convenient GUI for reading and writing Firebase data',
    },
  ],
  [
    'graphql',
    {
      name: 'GraphQL',
      subheadingText: 'Retool connects to your GraphQL server in a few clicks and lets you query and mutate quickly.',
      bullet1: 'Built in schema explorer and documentation',
      bullet2: 'Native support for variables',
    },
  ],
  [
    'mongodb',
    {
      name: 'MongoDB',
      subheadingText: 'Retool connects to your Mongo cluster in a few clicks and lets you read and write quickly.',
    },
  ],
  [
    'elasticsearch',
    {
      name: 'Elasticsearch',
      subheadingText: 'Easily manage your Elasticsearch cluster. Add indices, create snapshots and more.',
    },
  ],
])

export const signupThemeTemplateMap: Map<SignUpTheme, string[]> = new Map([
  [
    'firebase',
    ['firebase-admin-panel', 'firestore-admin-panel', 'firebase-auth-admin-panel', 'firebase-realtimedb-admin-panel'],
  ],
])

export const IS_PRODUCTION_DOMAIN =
  window.MAIN_DOMAIN === 'retool-secondary-backup.com' ||
  window.MAIN_DOMAIN === 'retool-secondary.com' ||
  window.MAIN_DOMAIN === 'onretool.com' ||
  window.MAIN_DOMAIN === 'tryretool.com' ||
  window.MAIN_DOMAIN === 'retool.com'

export const IS_STAGING_DOMAIN =
  window.MAIN_DOMAIN === 'retool-staging-fallback.com' ||
  window.MAIN_DOMAIN === 'retool-staging.com' ||
  window.MAIN_DOMAIN === 'retool.be'

export const IS_ON_PREM = !IS_PRODUCTION_DOMAIN && !IS_STAGING_DOMAIN

function getRetoolEnvironment() {
  if (__DEV__) {
    return 'development'
  }
  if (IS_STAGING_DOMAIN) {
    return 'staging'
  }
  return 'production'
}
export const RETOOL_ENVIRONMENT = getRetoolEnvironment()

export const LICENSE_TYPE_TRIAL = 'trial'
export const LICENSE_TYPE_LOCAL_TRIAL = 'local-trial'
export const LICENSE_TYPE_UNKNOWN = 'unknown'

export const ONBOARDING_URL_RESOURCES = 'resources/new'
export const ONBOARDING_URL_QUERY_LIBRARY = 'setup/querylibrary'
export const ONBOARDING_URL_QUERY_LIBRARY_TWO = `${ONBOARDING_URL_QUERY_LIBRARY}/two`

export const ONBOARDING_URL_FIREBASE = 'setup/firebase'
export const ONBOARDING_PREFIX_CLONED_TEMPLATE = 'editor'

const BOOK_A_CALL_HREF_MARKETING_CAMPAIGNS = 'https://retool.chilipiper.com/router/marketing-campaigns'
export function bookACallHref(email: string, source: string): string {
  return `${BOOK_A_CALL_HREF_MARKETING_CAMPAIGNS}?id=${email}&utm_source=app&utm_medium=product&meeting_page_source=${source}`
}

// this is copied into the CSP policy; if you change this, please change the CSP policy.
export const CLOUD_FRONT_URL = 'https://d3399nw8s4ngfo.cloudfront.net'
export const GLOBAL_WIDGET_TYPE = 'GlobalWidget'

export const OAUTH_2_SSO_LOGIN_URL = '/oauth2sso/login'
export const SAML_LOGIN_URL = '/saml/login'

export const RETOOL_IP = '52.175.251.223'

export const USE_DEMO_RESOURCE = 'use demo resource'
export const ONBOARDING_TUTORIAL_ENABLED_RESOURCE_TYPES: ResourceType[] = ['firebase']
export const ONBOARDING_TUTORIAL_RESOURCE_TYPE_TEMPLATE_ID_MAP: { [key: string]: string } = {
  firebase: 'firebase-admin-panel',
}

export const BILLING_PLAN_IDS = {
  cloudPlus: 'plan_DORkeksyFtsAm3',
  cloudPro: 'plan_DORq0rFumT4Dz6',
  onpremPlus: RETOOL_ENVIRONMENT !== 'production' ? 'price_1IGowFAUrA5dwCBAZ09UdF5c' : 'price_1IGoxDAUrA5dwCBA804IvI6l',
  onpremPro: RETOOL_ENVIRONMENT !== 'production' ? 'price_1IGownAUrA5dwCBAXOt1980M' : 'price_1IGox7AUrA5dwCBAWta9aXMP',
}

import React from 'react'
import ls from 'local-storage'
import { Icon, Button } from 'antd'
import { connect } from 'react-redux'

import { showOnboarding } from 'store/onboarding'
import PlaceholderResourcesWarning from 'routes/Editor/components/Header/PlaceholderResourcesWarning'
import { retoolAzure } from 'retoolColors'

type Props = {
  showOnboarding: () => void
}

const ExpandedOnboardingNotice = (props: any) => {
  return (
    <div
      style={{
        position: 'fixed',
        right: 100,
        bottom: 20,
        width: 450,

        padding: 20,
        borderRadius: 3,
        border: '1px solid lightgrey',

        background: 'white',
        zIndex: 1000,
      }}
    >
      <Icon
        onClick={props.onDismissClick}
        style={{ position: 'absolute', top: 12, right: 12, cursor: 'pointer' }}
        type="close"
      />
      <h4 style={{ fontWeight: 500, marginBottom: 15 }}>🎓 Start the 5-minute Retool Tutorial</h4>
      <div style={{ marginBottom: 15 }}>
        Before you can customize your template, you'll need to learn how Retool works! Click below to start the tutorial
        &mdash; it just takes 5 minutes.
      </div>
      <PlaceholderResourcesWarning
        MessageComponent={({ onClick }: any) => (
          <div style={{ marginBottom: 15 }}>
            If you want to start using your app immediately,{' '}
            <span onClick={onClick} style={{ cursor: 'pointer', color: retoolAzure }}>
              click here to connect your own data
            </span>
          </div>
        )}
      />
      <Button style={{ float: 'right' }} type="primary" onClick={props.showOnboarding}>
        Start Tutorial
      </Button>
    </div>
  )
}

const CollapsedOnboardingNotice = ({ onClick }: any) => {
  return (
    <div
      onClick={onClick}
      style={{
        position: 'fixed',
        right: 100,
        bottom: 20,
        height: 60,
        width: 60,
        padding: 10,
        fontSize: 32,
        textAlign: 'center',
        borderRadius: 30,
        background: 'white',
        boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.2)',
        cursor: 'pointer',
      }}
    >
      🎓
    </div>
  )
}

class OnboardingNotice extends React.Component<Props> {
  state = {
    isDismissed: ls.get('dismissedOnboardingNotice'),
  }

  onDismissClick = () => {
    ls.set('dismissedOnboardingNotice', true)
    this.setState({ isDismissed: true })
  }

  render() {
    if (this.state.isDismissed)
      return <CollapsedOnboardingNotice onClick={() => this.setState({ isDismissed: false })} />

    return <ExpandedOnboardingNotice {...this.props} onDismissClick={this.onDismissClick} />
  }
}

export default connect(null, { showOnboarding })(OnboardingNotice)

import { isArray } from 'lodash'

import * as writeModel from '../SqlQuery/writeModel'
import * as readModel from '../SqlQuery/readModel'
import modelWrapper from '../modelWrapper'
import { SqlResourceSettings, ResourceFromServer, SQL_QUERY_VERSION_UNIFIED, ImmutableMapType } from 'common/records'

export type EditorMode = 'sql' | 'gui'
export type SqlUnifiedQueryTemplate = {
  // Read props
  query: string

  // Write props
  actionType: string
  tableName: string
  recordId: string
  filterBy: string
  changeset: string
  records: string
  bulkUpdatePrimaryKey: string
  enableBulkUpdates: boolean
  doNotThrowOnNoOp: boolean

  // General props
  editorMode: EditorMode
  databaseNameOverride: string
  databaseUsernameOverride: string
  databasePasswordOverride: string

  // Mysql specific
  shouldEnableBatchQuerying?: boolean

  // BigQuery specific
  shouldUseLegacySql?: boolean
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RunQueryFn = (template: SqlUnifiedQueryTemplate, model: any) => any

// `any` is the resource type used in the resource pages
export const isUnifiedSqlResource = (resourceSettings: SqlResourceSettings) => {
  return resourceSettings.options?.version === SQL_QUERY_VERSION_UNIFIED
}

export const isResourceWritable = (
  resource: ImmutableMapType<ResourceFromServer>,
  environment: 'production' | 'staging',
) => {
  const resourceSettings = resource.toJS()[environment]
  return (
    (resourceSettings && resourceSettings.editPrivilege) || resource.get('editorType') === SQL_QUERY_VERSION_UNIFIED
  )
}

export const isLaunchedUnifiedSqlResource = (resource: { id?: number } & SqlResourceSettings) => {
  const newResource = resource.id == null
  return newResource || resource.options?.version === SQL_QUERY_VERSION_UNIFIED
}

const getEditorModeFromTemplate = (queryTemplate: SqlUnifiedQueryTemplate): EditorMode => {
  return queryTemplate.editorMode
}

export default modelWrapper({
  name: 'SqlQueryUnified',
  template: {
    query: '',
    dataArray: [],

    actionType: '',
    tableName: '',
    recordId: '',
    records: '',
    filterBy: '',
    changeset: '',
    bulkUpdatePrimaryKey: '',
    enableBulkUpdates: false,
    doNotThrowOnNoOp: false,

    editorMode: 'sql',
    databaseNameOverride: '',
    databaseUsernameOverride: '',
    databasePasswordOverride: '',
    shouldEnableBatchQuerying: false,
    shouldUseLegacySql: false,
    runWhenModelUpdates: true,
  },

  options: {
    docs: {},
    propertyAnnotations: {
      data: {
        updatesSync: ['dataArray'],
      },
    },
  },
  resourceSpecificTemplateDefaults: (resource: ResourceFromServer) => {
    const settings: SqlResourceSettings = resource.production
    if (settings.options?.queryEditorModes?.allowSqlMode === false) {
      return { editorMode: 'gui', runWhenModelUpdates: false }
    }
    return {}
  },
  queryProperties: [
    'query',
    'databaseNameOverride',
    'databaseUsernameOverride',
    'databasePasswordOverride',
    'shouldEnableBatchQuerying',
    'shouldUseLegacySql',
  ],

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPreviewParams: async (queryTemplate: SqlUnifiedQueryTemplate, modelValues: any) => {
    const editorMode = getEditorModeFromTemplate(queryTemplate)

    let fn: RunQueryFn
    if (editorMode === 'gui') {
      fn = writeModel.getPreviewParams
    } else {
      fn = readModel.getPreviewParams
    }

    return fn(queryTemplate, modelValues)
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRunQueryParams: async (template: SqlUnifiedQueryTemplate, model: any) => {
    const editorMode = getEditorModeFromTemplate(template)

    let fn: RunQueryFn
    if (editorMode === 'gui') {
      fn = writeModel.getRunQueryParams
    } else {
      fn = readModel.getRunQueryParams
    }

    return fn(template, model)
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatResult: (result: any, template: SqlUnifiedQueryTemplate) => {
    const editorMode = getEditorModeFromTemplate(template)

    if (editorMode === 'sql') {
      if (isArray(result)) {
        const data = result[0]
        const dataArray = result
        return { data, dataArray }
      } else {
        return {
          data: result,
          dataArray: [result],
        }
      }
    }

    return { data: result, dataArray: [] }
  },
})

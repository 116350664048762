import React from 'react'
import { Input } from 'antd'
import { InputField, SERVICE_ACCOUNT_PLACEHOLDER } from './common'
import { ResourceFormProps } from './types'

class FirebaseForm extends React.Component<ResourceFormProps> {
  render() {
    // By default showDatabaseUrlField should be true if undefined (e.g. if we render this component from /resources/new/firebase)
    const { showDatabaseUrlField = true } = this.props

    return (
      <div className="grid-1c mb12">
        <div className="grid-offset-1">
          Enter your Firebase details below. You can find your database URL and Firestore ID in your
          <a href="https://console.firebase.google.com" target="_blank" rel="noopener">
            {' '}
            Firebase project console.{' '}
          </a>
          Having trouble? Please check out the{' '}
          <a href="https://docs.retool.com/docs/firebase-integration" target="_blank" rel="noopener">
            Firebase Docs
          </a>
          .
        </div>
        {showDatabaseUrlField && (
          <InputField
            label="Firebase Database URL"
            dataTestId="databaseURL-id"
            resourceKey="databaseURL"
            required
            {...this.props}
          />
        )}
        <InputField
          label="Firestore Project ID"
          resourceKey="projectId"
          required
          dataTestId="project-id"
          {...this.props}
        />

        <label className="input-label required">
          The private key associated with a Service Account with Firebase privileges
        </label>
        <div className="light-gray">
          <Input.TextArea
            value={this.props.resource.options.serviceAccountKey}
            onChange={this.props.onChangeOption('serviceAccountKey', { handleEvent: true })}
            autosize={{ minRows: 12, maxRows: 12 }}
            placeholder={SERVICE_ACCOUNT_PLACEHOLDER}
          />
          See{' '}
          <a href="https://firebase.google.com/docs/admin/setup" target="_blank" rel="noopener noreferrer">
            here
          </a>{' '}
          for documentation on how to obtain this key.
        </div>
      </div>
    )
  }
}

export default {
  label: 'Firebase',
  form: FirebaseForm,
  defaults: { options: { authentication: 'serviceAccount' } },
  validator: ({ options }: any) => {
    return !!options.serviceAccountKey && !!(options.databaseURL || options.projectId)
  },
}

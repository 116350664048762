import { isEqual } from 'lodash'
import React, { createContext, FC, useState } from 'react'
import useWindowEventListener from './hooks/useWindowEventListener'
import hasModifierKeys, { MODIFIER_KEYS, ModifierKey, ModifierKeyEvent } from './utils/hasModifierKeys'

const DEFAULT_MODIFIER_KEYS: Record<ModifierKey, boolean> = {
  alt: false,
  command: false,
  ctrl: false,
  meta: false,
  shift: false,
}

type HeldModifierKeys = typeof DEFAULT_MODIFIER_KEYS

type KeyboardContextValue = {
  heldModifierKeys: HeldModifierKeys
}

export const KeyboardContext = createContext<KeyboardContextValue | undefined>(undefined)
KeyboardContext.displayName = 'KeyboardContext'

export const KeyboardProvider: FC = ({ children }) => {
  const [value, setValue] = useState<KeyboardContextValue>({ heldModifierKeys: DEFAULT_MODIFIER_KEYS })

  function setModifierKeys(event: ModifierKeyEvent) {
    const heldModifierKeys = Object.fromEntries(
      MODIFIER_KEYS.map((key) => [key, hasModifierKeys(event, key)]),
    ) as Record<ModifierKey, boolean>

    if (!isEqual(heldModifierKeys, value.heldModifierKeys)) {
      setValue({ heldModifierKeys })
    }
  }

  useWindowEventListener('keydown', setModifierKeys)
  useWindowEventListener('keyup', setModifierKeys)
  useWindowEventListener('mousemove', setModifierKeys)

  return <KeyboardContext.Provider value={value}>{children}</KeyboardContext.Provider>
}

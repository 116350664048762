import React, { createContext, FC, useContext, useRef, useState } from 'react'
import useCallbackRef from './hooks/useCallbackRef'
import useLazyRef from './hooks/useLazyRef'

const ResizeObserverContext = createContext<ResizeObserver | undefined>(undefined)
const { Provider } = ResizeObserverContext

const callbackKey = Symbol('resizeObserverCallback')

type ObserverCallback = (rect: DOMRectReadOnly) => void

type ObserverElement = Element & { [callbackKey]?: ObserverCallback }

type ObserverEntry = Omit<ResizeObserverEntry, 'target'> & { target: ObserverElement }

export type ObserverRef = (node: Element | null) => void

export const ResizeObserverProvider: FC = ({ children }) => {
  const value = useLazyRef(
    () =>
      new ResizeObserver((entries: ObserverEntry[]) => {
        entries.forEach(({ target, contentRect }) => {
          target[callbackKey]?.(contentRect)
        })
      }),
  ).current

  return <Provider value={value}>{children}</Provider>
}

/**
 * Observe changes to the dimensions of a node
 *
 * @example
 *
 * const ref = useResizeObserver(({ width, height }) => {
 *   // called any time the node is resized
 * })
 *
 * return <div ref={ref}>...
 */
export const useResizeObserver = (callback: ObserverCallback): ObserverRef => {
  const observer = useContext(ResizeObserverContext)
  const ref = useRef<ObserverElement | null>(null)

  const wrappedCallback = useCallbackRef(callback)

  return useCallbackRef((node: ObserverElement | null) => {
    if (ref.current) {
      ref.current[callbackKey] = undefined
      observer?.unobserve(ref.current)
    }

    ref.current = node

    if (ref.current) {
      ref.current[callbackKey] = wrappedCallback
      observer?.observe(ref.current)
    }
  })
}

type Dimension = number | null

/**
 * Returns the current width and height of the observed node
 *
 * @example
 *
 * const [width, height, ref] = useDimensions()
 *
 * return <div ref={ref}>...
 */
export const useDimensions = (): [width: Dimension, height: Dimension, ref: ObserverRef] => {
  // separate states to prevent rerenders when the values don't change
  const [width, setWidth] = useState<Dimension>(null)
  const [height, setHeight] = useState<Dimension>(null)

  const ref = useResizeObserver(({ width, height }) => {
    setWidth(width)
    setHeight(height)
  })

  return [width, height, ref]
}

/**
 * Returns the current width of the observed node
 *
 * @example
 *
 * const [width, ref] = useWidth()
 *
 * return <div ref={ref}>...
 */
export const useWidth = (): [width: Dimension, ref: ObserverRef] => {
  const [width, setWidth] = useState<Dimension>(null)
  const ref = useResizeObserver(({ width }) => setWidth(width))
  return [width, ref]
}

/**
 * Returns the current height of the observed node
 *
 * @example
 *
 * const [height, ref] = useHeight()
 *
 * return <div ref={ref}>...
 */
export const useHeight = (): [height: Dimension, ref: ObserverRef] => {
  const [height, setHeight] = useState<Dimension>(null)
  const ref = useResizeObserver(({ height }) => setHeight(height))
  return [height, ref]
}

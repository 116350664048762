import { List } from 'immutable'
import * as Immutable from 'immutable'
import * as React from 'react'
import _ from 'lodash'
import { CSSProperties } from 'react'
import moment from 'moment'
import * as transit from 'transit-immutable-js'
const { Record } = Immutable
import { RETOOL_VERSION, SignUpTheme, signupThemeMap } from 'retoolConstants'
import { PRECONFIGURED_OPENAPI_SPECS } from '../__globalShared__/common'
import { WARNINGS as SQL_ERR_WARNINGS } from 'components/plugins/datasources/SqlQuery/detectCommonErrors'
import type { UserInvite, UserInviteSuggestion } from '__globalShared__/accounts'
import type {
  AccessLevel,
  UserInviteGroup,
  UserGroup,
  Group,
  GroupFolderDefault,
  GroupPage,
  GroupResource,
  UserPermissions as UserPermissionsShape,
} from '__globalShared__/permissions'
import type { AppStyles } from 'components/plugins/widgets/style/types'
import { sampleSentryMessage } from './sentry'
import { SafeAny } from './types'
import { makeTypedMap, TypedMap } from './utils/immutable'
import { AdhocResourceType, PreconfiguredOpenAPIResourceType, ResourceType } from './resourceTypes'
import { CommonCodeEditorProps } from 'components/CodeEditor/types'
import CodeMirror from 'codemirror'

// copied from SqlQueryUnified to avoid a cycle
export type SqlQueryEditorMode = 'sql' | 'gui'

// copied from ./utils to avoid a cycle
const ss = (selector: Selector): string => selector.join('.')
type Selector = string[]

export type GenericErrorResponse = { payload: { status: number; statusText: string; response?: { message: string } } }

export type ModelChangeset = {
  selector: Selector
  newValue: any
}

export type UsageRecord = {
  propertyIdentifier: number | string
  uuid: string
  name: string
}

export const resourceTypeIsPreconfigured = (type: ResourceType): type is PreconfiguredOpenAPIResourceType =>
  PRECONFIGURED_OPENAPI_SPECS.includes(type as PreconfiguredOpenAPIResourceType)

export type AdhocResourceName =
  | 'JavascriptQuery'
  | 'ParentWindow'
  | 'PDFExporter'
  | 'REST-WithoutResource'
  | 'GraphQL-WithoutResource'
  | 'SQL Transforms'
  | 'Flows'
  | 'GlobalWidgetQuery'

export type AdhocResourceEditorType =
  | 'RESTQuery'
  | 'GraphQLQuery'
  | 'ParentWindowQuery'
  | 'SqlTransformQuery'
  | 'PDFExporter'
  | 'JavascriptQuery'
  | 'FlowsQuery'
  | 'GlobalWidgetQuery'

export function isSupportedSignUpTheme(arg: string): arg is SignUpTheme {
  return signupThemeMap.has(arg as SignUpTheme)
}

// TODO convert these strings into actual types
export const ADHOC_RESOURCE_PROPERTIES: {
  [key in AdhocResourceType]: {
    name: string | AdhocResourceName
    displayName: string
    value: string
    label: string
    editorType: AdhocResourceEditorType
    resourceType: AdhocResourceType
    hasWriteAccess: boolean
    optionFilter?: string
  }
} = {
  restapi: {
    name: 'REST-WithoutResource',
    value: 'REST-WithoutResource',
    label: 'RESTQuery (restapi)',
    displayName: 'REST',
    editorType: 'RESTQuery',
    resourceType: 'restapi',
    optionFilter: 'HTTPS REST API POST GET PATCH PUT DELETE',
    hasWriteAccess: true,
  },
  graphql: {
    name: 'GraphQL-WithoutResource',
    value: 'GraphQL-WithoutResource',
    editorType: 'GraphQLQuery',
    label: 'GraphQL (graphql)',
    displayName: 'GraphQL',
    resourceType: 'graphql',
    hasWriteAccess: true,
  },
  parentwindow: {
    name: 'ParentWindow',
    displayName: 'ParentWindow',
    value: 'ParentWindow',
    label: 'ParentWindow (parentwindow)',
    editorType: 'ParentWindowQuery',
    resourceType: 'parentwindow',
    hasWriteAccess: true,
  },
  sqltransform: {
    name: 'SQL Transforms',
    displayName: 'SQL Transforms',
    value: 'SQL Transforms',
    label: 'Query JSON with SQL',
    editorType: 'SqlTransformQuery',
    resourceType: 'sqltransform',
    optionFilter: 'Query JSON with SQL Transforms',
    hasWriteAccess: true,
  },
  pdfexporter: {
    name: 'PDFExporter',
    displayName: 'PDFExporter',
    value: 'PDFExporter',
    label: 'PDF Exporter (exporter)',
    editorType: 'PDFExporter',
    resourceType: 'pdfexporter',
    hasWriteAccess: true,
  },
  javascript: {
    name: 'JavascriptQuery',
    displayName: 'JavascriptQuery',
    value: 'JavascriptQuery',
    label: 'Run JS Code (javascript)',
    editorType: 'JavascriptQuery',
    resourceType: 'javascript',
    optionFilter: 'Run JS Code javascript function',
    hasWriteAccess: true,
  },
  flows: {
    name: 'Flows',
    displayName: 'Flows',
    value: 'Flows',
    editorType: 'FlowsQuery',
    label: 'Flows (flows)',
    resourceType: 'flows',
    hasWriteAccess: true,
  },
  globalwidget: {
    name: 'GlobalWidgetQuery',
    displayName: 'GlobalWidgetQuery',
    value: 'GlobalWidgetQuery',
    label: 'Module Input Query',
    editorType: 'GlobalWidgetQuery',
    resourceType: 'globalwidget',
    hasWriteAccess: true,
  },
}

export type QueryEditorType = QueryType | AdhocResourceEditorType

type PositionParams = {
  x?: number
  y?: number
  height?: number
  width?: number
}
export class Position extends Record(
  {
    x: 0,
    y: 0,
    height: 0,
    width: 0,
  },
  'position',
) {
  constructor(params?: PositionParams) {
    super(params)
  }
}

export interface Position2Params {
  row: number
  col: number
  height: number
  width: number
  container: string

  // this allows components to have multiple sets of children. For example, a Table can have multiple
  // columns that are set to be a "modal" type, so we need a way to let the Table have multiple sets
  // of children
  subcontainer?: string

  // TODO: deprecate use of tabNum and shift to using `subcontainer` for everything else.
  tabNum?: number | string
}
const defaultPosition2Values: Position2Params = {
  container: '',
  subcontainer: '',
  row: 0,
  col: 0,
  height: 1,
  width: 3,
  tabNum: 0,
}

export type Position2Diff = Partial<Position2Params>
export type Position2Box = {
  top: number
  left: number
  bottom: number
  right: number
  width: number
  height: number
}

export class Position2 extends Record(defaultPosition2Values, 'position2') {
  static getContainerKey({
    container,
    subcontainer,
    tabNum,
  }: Partial<Pick<Position2Params, 'container' | 'subcontainer' | 'tabNum'>>): string {
    return `${container || '__root'}__${subcontainer || ''}__${tabNum || 0}`
  }

  getContainerKey(): string {
    return Position2.getContainerKey(this)
  }

  getBox(): Position2Box {
    const { row: top, col: left, width, height } = this
    return {
      top,
      left,
      bottom: top + height,
      right: left + width,
      width,
      height,
    }
  }

  applyDiff(diff: Position2Diff): this {
    return this.mergeWith((oldValue, newValue, key) => {
      switch (key) {
        case 'container':
        case 'subcontainer':
        case 'tabNum':
          return newValue
        case 'row':
        case 'col':
        case 'height':
        case 'width':
          return newValue + oldValue
        default:
          return oldValue
      }
    }, diff)
  }

  intersectsWith(other: Position2): boolean {
    return (
      // same container
      this.getContainerKey() === other.getContainerKey() &&
      // intersects along y axis
      this.row < other.row + other.height &&
      this.row + this.height > other.row &&
      // intersects along x axis
      this.col < other.col + other.width &&
      this.col + this.width > other.col
    )
  }

  encapsulate(...otherPositions: Position2[]): this {
    return this.withMutations((thisPosition) =>
      otherPositions.reduce((aggPosition, otherPosition) => {
        const { row, col, width, height } = aggPosition
        const { row: otherRow, col: otherCol, width: otherWidth, height: otherHeight } = otherPosition

        const newRow = Math.min(row, otherRow)
        const newCol = Math.min(col, otherCol)
        const newRight = Math.max(col + width, otherCol + otherWidth)
        const newBottom = Math.max(row + height, otherRow + otherHeight)

        return aggPosition
          .set('row', newRow)
          .set('col', newCol)
          .set('width', newRight - newCol)
          .set('height', newBottom - newRow)
      }, thisPosition),
    )
  }
}

export type WidgetType =
  | 'TextWidget'
  | 'TableWidget'
  | 'TextInputWidget'
  | 'ButtonWidget'
  | 'SelectWidget'
  | 'ImageWidget'
  | 'ContainerWidget'
  | 'TabbedContainerWidget'
  | 'KeyValueMapWidget'
  | 'CheckboxWidget'
  | 'JSONSchemaFormWidget'
  | 'NoteWidget'
  | 'JSONExplorerWidget'
  | 'JSONEditorWidget'
  | 'ReorderableListWidget'
  | 'MapWidget'
  | 'ProgressWidget'
  | 'VideoWidget'
  | 'ModalWidget'
  | 'ListViewWidget'
  | 'FormWidget'
  | 'ToggleListWidget'
  | 'SliderWidget'
  | 'ToggleWidget'
  | 'DateTimePickerWidget'
  | 'DateRangePickerWidget'
  | 'FilePickerWidget'
  | 'S3UploaderWidget'
  | 'TimePickerWidget'
  | 'RateWidget'
  | 'CheckboxGroupWidget'
  | 'MultiSelectWidget'
  | 'RadioGroupWidget'
  | 'TextEditorWidget'
  | 'StripeCardFormWidget'
  | 'BraintreeCardFormWidget'
  | 'PDFViewerWidget'
  | 'SignaturePadWidget'
  | 'TimerWidget'
  | 'MicrophoneWidget'
  | 'ButtonGroupWidget'
  | 'ChartWidget'
  | 'BarChartWidget'
  | 'AreaChartWidget'
  | 'AlertWidget'
  | 'PieChartWidget'
  | 'BoundingBoxWidget'
  | 'TextAnnotationWidget'
  | 'ScannerWidget'
  | 'TableauWidget'
  | 'LookerWidget'
  | 'TimelineWidget'
  | 'IFrameWidget'
  | 'EditableTextWidget'
  | 'CalendarWidget'
  | 'CascaderWidget'
  | 'AuthLoginWidget'
  | 'CheckboxTreeWidget'
  | 'StatisticWidget'
  | 'QueryBuilderWidget'
  | 'CustomComponentWidget'
  | 'PlotlyChartWidget'
  | 'WizardWidget'
  | 'GlobalWidget'
  | 'ModuleContainerWidget'
  | 'BuildingBlockContainerWidget'
  | 'SpacerWidget'
  | 'NavigationWidget'

export type QueryType =
  | 'SqlQueryUnified'
  | 'SqlQuery'
  | 'ApiQuery'
  | 'NoSqlQuery'
  | 'CouchDBQuery'
  | 'RethinkDBQuery'
  | 'CosmosDBQuery'
  | 'RedisQuery'
  | 'PrestoQuery'
  | 'SAPHanaQuery'
  | 'GoogleSheetsQuery'
  | 'RetoolTableQuery'
  | 'RESTQuery'
  | 'GraphQLQuery'
  | 'S3Query'
  | 'GCSQuery'
  | 'SlackQuery'
  | 'ElasticSearchQuery'
  | 'SalesforceQuery'
  | 'AthenaQuery'
  | 'OpenAPIQuery'
  | 'FirebaseQuery'
  | 'DynamoQuery'
  | 'LambdaQuery'
  | 'RetoolApprovalWorkflowQuery'
  | 'DatastoreQuery'
  | 'ShellQuery'
  | 'GRPCQuery'
  | 'GlobalWidgetQuery'
  | 'JavascriptQuery'
  | 'SMTPQuery'

export const SQL_QUERY_VERSION_UNIFIED = 'SqlQueryUnified'

export type ValidationStateType = 'SUCCESS' | 'ERROR' | ''

export type PluginSubtype =
  | WidgetType
  | QueryType
  | 'Function'
  | 'State'
  | 'GlobalWidgetProp'
  | 'GlobalWidgetOutput'
  | 'Frame'

export interface PluginTemplateParams<TemplateType> {
  id: string
  type:
    | 'widget'
    | 'function'
    | 'instrumentation'
    | 'state'
    | 'datasource'
    | 'globalwidgetprop'
    | 'globalwidgetoutput'
    | 'frame'
  subtype: PluginSubtype
  namespace: PluginNamespaceInfo | undefined
  resourceName: string
  template: TemplateType
  style?: TypedMap<Partial<AppStyles>>
  position2?: Position2
  mobilePosition2?: Position2
  tabIndex: number
  container: string
  createdAt: Date
  updatedAt: Date
  folder: string
}
export type PluginTemplateType<T> = Immutable.Record<PluginTemplateParams<T>> & Readonly<PluginTemplateParams<T>>

const defaultPluginTemplateValues: PluginTemplateParams<any> = {
  id: undefined!,
  type: undefined!,
  subtype: undefined!,
  namespace: undefined,
  resourceName: undefined!,
  template: undefined,
  style: undefined,
  position2: undefined,
  mobilePosition2: undefined,
  tabIndex: undefined!,
  container: '',
  createdAt: undefined!,
  updatedAt: undefined!,
  folder: '',
}
export interface WidgetTemplateType<T = any> extends PluginTemplateType<T> {
  subtype: WidgetType
  type: 'widget'
}
export interface QueryTemplateType<T = any> extends PluginTemplateType<T> {
  subtype: QueryType
  type: 'datasource'
}
export interface FunctionTemplateType<T = any> extends PluginTemplateType<T> {
  subtype: 'Function'
  type: 'function'
}
export interface StateTemplateType<T = any> extends PluginTemplateType<T> {
  subtype: 'State'
  type: 'state'
}

export interface FrameTemplateType<T = any> extends PluginTemplateType<T> {
  subtype: 'Frame'
  type: 'frame'
}

export interface GlobalWidgetPropTemplateType<T = any> extends PluginTemplateType<T> {
  subtype: 'GlobalWidgetProp'
  type: 'globalwidgetprop'
}

export interface GlobalWidgetOutputTemplateType<T = any> extends PluginTemplateType<T> {
  subtype: 'GlobalWidgetOutput'
  type: 'globalwidgetoutput'
}

export class PluginTemplate extends Record(defaultPluginTemplateValues, 'pluginTemplate') {
  constructor(params: any) {
    params.createdAt = params.createdAt || new Date()
    params.updatedAt = params.updatedAt || new Date()

    super(params)
  }

  isWidget(): boolean {
    return this.type === 'widget'
  }

  isModule(): boolean {
    return this.isWidget() && this.subtype === 'GlobalWidget'
  }
}

/** @deprecated use TypedMap or ReadOnlyTypedMap */
export type ImmutableMapType<T> = TypedMap<T>

export type DefinitionKey = string
export type Definition = Immutable.Map<DefinitionKey, string>
export type DefinitionUpdate = { name?: string; value?: string }

export type CustomShortcut = { shortcut: string; action: string }

export type TestEntity = Test | TestSuite

export interface AppTemplateParams {
  isFetching: boolean // when loading for first time, or restoring
  plugins: Immutable.OrderedMap<string, PluginTemplate> // keyed plugins
  createdAt: string
  version?: string
  appThemeId?: number
  preloadedAppJavaScript: string
  preloadedAppJSLinks: string[]
  testEntities: TestEntity[]
  tests?: Test[]
  appStyles?: string
  responsiveLayoutDisabled: boolean
  loadingIndicatorsDisabled: boolean
  urlFragmentDefinitions: Immutable.List<Definition>
  pageLoadValueOverrides: Immutable.List<Definition>
  height?: any
  customDocumentTitle: string
  customDocumentTitleEnabled: boolean
  customShortcuts: CustomShortcut[]
  isGlobalWidget: boolean
  folders: Immutable.List<string>
}
const defaultAppTemplate: AppTemplateParams = {
  isFetching: false,
  plugins: Immutable.OrderedMap(),
  createdAt: '',
  version: RETOOL_VERSION,
  appThemeId: undefined,
  preloadedAppJavaScript: '',
  preloadedAppJSLinks: [],
  testEntities: [],
  tests: undefined,
  appStyles: '',
  responsiveLayoutDisabled: false,
  loadingIndicatorsDisabled: false,
  urlFragmentDefinitions: Immutable.List(),
  pageLoadValueOverrides: Immutable.List(),
  customDocumentTitle: '',
  customDocumentTitleEnabled: false,
  customShortcuts: [],
  isGlobalWidget: false,
  folders: Immutable.List(),
}
function updateNameValueList(list: Immutable.List<Definition>, index: number, update: DefinitionUpdate) {
  if (list.has(index)) {
    if (Object.keys(update).length === 0) {
      return list.delete(index)
    } else {
      return list.update(index, (def: any) => {
        return def.merge(update as any)
      })
    }
  } else {
    let newDef: Definition = Immutable.Map()
    newDef = newDef.merge({ name: update.name || '', value: update.value || '' })
    return list.push(newDef)
  }
}
export class AppTemplate extends Record(defaultAppTemplate, 'appTemplate') {
  getPlugin(id: string): PluginTemplate {
    return this.plugins.get(id)!
  }

  reorderQueries(originalQueryIndex: number, newQueryIndex: number) {
    const queriesList = this.plugins.filter((p) => p.type === 'datasource').toList()

    // reorder the queries
    const reorderedQuery = queriesList.get(originalQueryIndex)
    const reorderedQueriesList = queriesList.splice(originalQueryIndex, 1).splice(newQueryIndex, 0, reorderedQuery!)

    // reorder the full plugins map
    // we don't care about the order of anything that's not a query, so just stick em all in the back
    const reorderedQueriesMap = reorderedQueriesList.toOrderedMap().mapKeys((k, v) => v.get('id'))
    const nonQueryPlugins = this.plugins.filter((p) => p.type !== 'datasource')
    const reorderedPlugins = reorderedQueriesMap.merge(nonQueryPlugins)

    return this.set('plugins', reorderedPlugins)
  }

  reorderTabs(tabbedContainerId: string, index: number, newIndex: number): this {
    // Find all children of this tabbed container.
    const largeScreenChildren = this.plugins.filter((p) => p.position2?.container === tabbedContainerId)
    const mobileChildren = this.plugins.filter((p) => p.mobilePosition2?.container === tabbedContainerId)

    let newAppTemplate = this
    // Define a mapping from current tab -> new tab

    const tabNumUpdater = (tabNum: number) => {
      if (tabNum === index) {
        return newIndex
      }
      // Will change or not?
      // Case when index < newIndex
      //  * move 2 -> 4
      //  * 0,1 don't move
      //  * 5,6,7 don't move
      //  * 2 -> 4
      //  * 3 -> 2
      //  * 4 -> 3
      //
      // Case when index > newIndex
      //  * move 4 -> 2
      //  * 0,1 don't move
      //  * 5,6,7 don't move
      //  * 2 -> 3
      //  * 3 -> 4
      //  * 4 -> 2

      let willChange
      if (index < newIndex) {
        willChange = tabNum >= index && tabNum <= newIndex
      } else {
        willChange = tabNum >= newIndex && tabNum <= index
      }
      const change = index > newIndex ? 1 : -1
      if (willChange) {
        return tabNum + change
      }
      return tabNum
    }
    for (const [id] of largeScreenChildren) {
      if (newAppTemplate.hasIn(['plugins', id, 'position2', 'tabNum'])) {
        newAppTemplate = newAppTemplate.updateIn(['plugins', id, 'position2', 'tabNum'], tabNumUpdater)
      }
    }
    for (const [id] of mobileChildren) {
      if (newAppTemplate.hasIn(['plugins', id, 'mobilePosition2', 'tabNum'])) {
        newAppTemplate = newAppTemplate.updateIn(['plugins', id, 'mobilePosition2', 'tabNum'], tabNumUpdater)
      }
    }
    return newAppTemplate
  }

  getPluginType(widgetId: string): string {
    return this.plugins.getIn([widgetId, 'subtype'])
  }

  updateFragmentDefinition(index: number, update: DefinitionUpdate): any {
    if (!Immutable.List.isList(this.urlFragmentDefinitions)) {
      return this.set('urlFragmentDefinitions', Immutable.List()).updateFragmentDefinition(index, update)
    }
    return this.update('urlFragmentDefinitions', (defs) => {
      return updateNameValueList(defs, index, update)
    })
  }

  updatePageLoadOverrides(index: number, update: DefinitionUpdate): any {
    if (!Immutable.List.isList(this.pageLoadValueOverrides)) {
      return this.set('pageLoadValueOverrides', Immutable.List()).updatePageLoadOverrides(index, update)
    }
    return this.update('pageLoadValueOverrides', (overrides) => {
      return updateNameValueList(overrides, index, update)
    })
  }

  getPosition2(widgetId: string, largeScreen: boolean): Position2 {
    if (largeScreen) {
      return this.plugins.getIn([widgetId, 'position2'])
    } else {
      return this.plugins.getIn([widgetId, 'mobilePosition2'])
    }
  }

  setCustomShortcuts(newShortcuts: { shortcut: string; action: string }[]) {
    return this.set('customShortcuts', newShortcuts)
  }
}

type ImmutableValuesType = Immutable.OrderedMap<string, any>

export type InputType =
  | 'text'
  | 'password'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'month'
  | 'number'
  | 'range'
  | 'search'
  | 'tel'
  | 'time'
  | 'url'
  | 'week'
  | 'currency'
// TODO: figure out where to put plugin model/template types
// currently some of these (e.g. TableWidgetModelShape) are duplicated
export type TextInputModelShape = {
  required: boolean
  pluginType: 'TextInputWidget'
  type: InputType
  value: unknown
}
type SelectWidgetModelShape = {
  required: boolean
  pluginType: 'SelectWidget'
  value: unknown
}
type MultiSelectWidgetModelShape = {
  required: boolean
  pluginType: 'MultiSelectWidget'
  value: unknown
}
type ListViewModelShape = {
  pluginType: 'ListViewWidget'
}
type ButtonWidgetModelShape = {
  pluginType: 'ButtonWidget'
}
export type TableWidgetModelShape = {
  pluginType: 'TableWidget'
  normalizedData: unknown[]
}
export type PluginModelShape = (
  | TextInputModelShape
  | SelectWidgetModelShape
  | ListViewModelShape
  | ButtonWidgetModelShape
  | TableWidgetModelShape
  | MultiSelectWidgetModelShape
  | { pluginType: '__invalid_plugin__' }
) & { hidden: boolean }

type QueryModelShape = PluginModelShape & {
  isFetching: boolean
  queryTriggerDelay: string
  timestamp: number
  successMessage: string
  notificationDuration: string
  error: string
  data: {}

  // TODO: A lot of properties on the query model are missing
  requestSentTimestamp?: number | null
  finished?: number
}
export type PluginModel = TypedMap<PluginModelShape>
export type QueryModel = TypedMap<QueryModelShape>

/**
 * a wrapper for modelValues, so that we can switch it from being
 * an Immutable Map to a regular object gradually
 */
export class AppModelValues {
  values: ImmutableValuesType = Immutable.OrderedMap()
  size = 0

  constructor(values?: ImmutableValuesType) {
    if (values) {
      this.values = values
      this.size = values.size
    }
  }

  forEach(...args: Parameters<ImmutableValuesType['forEach']>): ReturnType<ImmutableValuesType['forEach']> {
    return this.values.forEach(...args)
  }
  find(...args: Parameters<ImmutableValuesType['find']>): ReturnType<ImmutableValuesType['find']> {
    return this.values.find(...args)
  }
  mapKeys(...args: Parameters<ImmutableValuesType['mapKeys']>): AppModelValues {
    return new AppModelValues((this.values as any).mapKeys(...args))
  }
  filter(...args: Parameters<ImmutableValuesType['filter']>): AppModelValues {
    return new AppModelValues(this.values.filter(...args))
  }
  map(...args: Parameters<ImmutableValuesType['map']>): AppModelValues {
    return new AppModelValues(this.values.map(...args))
  }
  keySeq(): ReturnType<ImmutableValuesType['keySeq']> {
    return this.values.keySeq()
  }
  has(...args: Parameters<ImmutableValuesType['has']>): ReturnType<ImmutableValuesType['has']> {
    return this.values.has(...args)
  }
  get(...args: Parameters<ImmutableValuesType['get']>): unknown {
    // todo(abdul): route everything thru .getPlugin(...)
    // reportUnexpectedGet()
    return this.values.get(...args)
  }
  getIn(...args: Parameters<ImmutableValuesType['getIn']>): unknown {
    // todo(abdul): route everything thru .getPlugin(...)
    // reportUnexpectedGetIn()
    return this.values.getIn(...args)
  }
  getUnsafe(...args: Parameters<ImmutableValuesType['get']>): any {
    // todo(abdul): route everything thru .getPlugin(...)
    // reportUnexpectedGet()
    return this.values.get(...args)
  }
  getInUnsafe(...args: Parameters<ImmutableValuesType['getIn']>): any {
    // todo(abdul): route everything thru .getPlugin(...)
    // reportUnexpectedGetIn()
    return this.values.getIn(...args)
  }
  hasPlugin(maybePluginId: string): boolean {
    return this.values.has(maybePluginId)
  }
  getPlugin<T = PluginModelShape>(pluginId: string): TypedMap<T> {
    const plugin = this.values.get(pluginId)
    if (!plugin) {
      // reportUnexpectedNullPlugin(pluginId)
      return Immutable.Map() as TypedMap<T>
    }
    return plugin
  }
  getQuery(pluginId: string): QueryModel {
    return this.getPlugin(pluginId) as QueryModel
  }
  hasIn(...args: Parameters<ImmutableValuesType['hasIn']>): ReturnType<ImmutableValuesType['hasIn']> {
    return this.values.hasIn(...args)
  }
  setIn(...args: Parameters<ImmutableValuesType['setIn']>): AppModelValues {
    return new AppModelValues(this.values.setIn(...args))
  }
  deleteIn(...args: Parameters<ImmutableValuesType['deleteIn']>): AppModelValues {
    return new AppModelValues(this.values.deleteIn(...args))
  }
  toList(): ReturnType<ImmutableValuesType['toList']> {
    return this.values.toList()
  }
  toJS(): {} {
    return this.values.toJS()
  }
  clear() {
    return new AppModelValues(this.values.clear())
  }
}

// CallStack:
//
// This is a very basic datastructure that will get passed throughout the
// execution phase in Retool. As it get passed around, it is a useful
// datastructure that we can use to add tracking information to.
//
// In it's current state, the only thing we care about tracking is
// what was the "trigger" as well as all selectors that changed
// as the change is propagated throughout the rest of the application
export type CallStack = {
  id: number
  triggers: ModelChangeset[] // the list of changesets that set off the change; the order is not important
  changedSelectors: Selector[] // an array of selectors that got changed, ordered from first to last. A superset of the selectors in triggers
}

// ExecutionCallStacks
//
// This is a simple datastructure that we use to keep track of the call stacks in the current
// application. It uses a fun circular buffer to recycle stacks as they go in and out of use.
// For now, we've chosen 500 as a reasonable number to start off with, though we can adjust this.
//
// The reason why we need to limit the number of callstacks being stored is because we're trying to avoid
// a memory leak. Since every time anything in Retool changes a new CallStack is created, keeping everything
// would be dangerous. For instance, typing "123456789" in a TextInput component would create 9 CallStack objects.
//
//
// The problem was that I could not come up with a straightforward way to reliably determining whether or not a
// CallStack had "finished." Rather than be blocked on this problem, I thought it was a reasonable solution to
// just throw away CallStacks as the stack grew -- which is exactly what a circular buffer is for!
//
// That said, there is a check that will send a message to Sentry if the circular buffer ever overflows.
//
// It's stored on the AppModel since that's where we handle all our execution logic
const CALLSTACK_BUFFER_SIZE = 500
const CALLSTACK_OVERFLOW_SENTRY_SAMPLE = 0.01
export class ExecutionCallStacks {
  // store the callstacks in a fun circular buffer
  private stacks: CallStack[]
  private currentStackId: number

  constructor() {
    this.currentStackId = 0
    this.stacks = new Array(CALLSTACK_BUFFER_SIZE)
  }

  getNewStackId() {
    this.currentStackId += 1
    return this.currentStackId
  }

  createStack(triggers: ModelChangeset[]) {
    const newStack: CallStack = {
      id: this.getNewStackId(),
      triggers: triggers || [],
      changedSelectors: [],
    }
    this.stacks[this.getStackIndexInBuffer(newStack.id)] = newStack
    return newStack
  }

  getStackIndexInBuffer(stackId: number) {
    if (stackId <= this.currentStackId - CALLSTACK_BUFFER_SIZE) {
      // If the circular buffer overflowed, we should notify someone!
      // But since it's probably harmless given it's only used with forward cursor pagination,
      // we won't kill the page.
      sampleSentryMessage(
        CALLSTACK_OVERFLOW_SENTRY_SAMPLE,
        `[SAMPLED: ${CALLSTACK_OVERFLOW_SENTRY_SAMPLE * 100}%] CallStack circular buffer overflowed.
Current stack id: ${this.currentStackId}
Attempted stack id accessed: ${stackId}
Buffer size: ${CALLSTACK_BUFFER_SIZE}
`,
      )
    }

    // Since stackId is always postive, this is guaranteed to return
    // a positive number and a valid index in the circular buffer
    return stackId % CALLSTACK_BUFFER_SIZE
  }

  getStack(stackId: number): CallStack | null {
    return this.stacks[this.getStackIndexInBuffer(stackId)]
  }
}

interface AppModelParams {
  values: AppModelValues
  errors: Immutable.OrderedMap<string, any>
  dirty: Immutable.Set<string>
  pendingUserTriggeredQueries: Immutable.Set<string>
  pendingUserTriggeredQueryOptions: Immutable.Map<string, any>
  dependencyGraph: IDependencyGraph
  globals: Immutable.OrderedMap<string, any>
  environment: 'production' | 'staging'
  cachedJSValues: {} | null
  executionCallStacks: ExecutionCallStacks
  modelInitialized: boolean
  loadedPluginTypes: Immutable.Set<string>
}

// todo: make this conform w/ type AppModelParams
const defaultAppModelParams: AppModelParams = {
  cachedJSValues: null,
  values: new AppModelValues(),
  errors: Immutable.OrderedMap(),
  dirty: Immutable.Set(),
  pendingUserTriggeredQueries: Immutable.Set(),
  pendingUserTriggeredQueryOptions: Immutable.Map(),
  dependencyGraph: null!,
  globals: Immutable.OrderedMap({
    email: '',
    terminalLog: Immutable.List(),
  }),
  environment: 'production',
  executionCallStacks: null!,
  modelInitialized: false,
  loadedPluginTypes: Immutable.Set(),
}

export class AppModelRecord extends Immutable.Record(defaultAppModelParams, 'appmodel') {
  constructor(params: Partial<AppModelParams>) {
    if (params) {
      params.executionCallStacks = new ExecutionCallStacks()
      super(params)
    } else {
      super({ executionCallStacks: new ExecutionCallStacks() })
    }
  }

  pluginParent(pluginId: string) {
    if (!this.dependencyGraph) {
      return null
    }
    return (this.dependencyGraph as any).parentPointers[pluginId]
  }

  pluginType(pluginId: string): string | undefined {
    const plugin = this.values.getPlugin(pluginId)
    return plugin?.get('pluginType')
  }

  pluginInListView(pluginId: string): { inListView: boolean; instances: number | null } {
    const parent = this.pluginParent(pluginId)
    if (parent) {
      const inListView = this.values.getPlugin(parent).get('pluginType') === 'ListViewWidget'
      if (inListView) {
        const instances = (this.values.getPlugin(parent) as any).get('instances') || '0'
        return { inListView, instances: Number(instances) }
      }

      // recursively check if we have a listview ancestor
      return this.pluginInListView(parent)
    }
    return { inListView: false, instances: null }
  }

  pluginInContainer(pluginId: string, containerId: string): boolean {
    const parent = this.pluginParent(pluginId)
    if (parent) {
      const inContainerId = parent === containerId
      if (inContainerId) {
        return true
      }
      return this.pluginInContainer(parent, containerId)
    }
    return false
  }

  addDirtyNodes(nodes: string[]) {
    if (nodes.length === 0) {
      return this
    }
    const dirtyNodes = Immutable.Set(nodes)
    return this.set('dirty', this.dirty.union(dirtyNodes))
  }

  markAsClean(node: string) {
    return this.set('dirty', this.dirty.remove(node))
  }

  isDirty(node: string) {
    return this.dirty.has(node)
  }

  hasDirtyAncestors(selector: Selector) {
    const ancestors = this.dependencyGraph.getDependenciesOf(selector).map((node: any) => ss(node.selector))
    return !ancestors.intersect(this.dirty).isEmpty()
  }

  addPendingUserTriggeredQuery(queryId: string, queryOptions: any) {
    return this.set('pendingUserTriggeredQueries', this.pendingUserTriggeredQueries.add(queryId)).set(
      'pendingUserTriggeredQueryOptions',
      this.pendingUserTriggeredQueryOptions.set(queryId, queryOptions),
    )
  }

  removePendingUserTriggeredQuery(queryId: string) {
    return this.set('pendingUserTriggeredQueries', this.pendingUserTriggeredQueries.remove(queryId)).set(
      'pendingUserTriggeredQueryOptions',
      this.pendingUserTriggeredQueryOptions.remove(queryId),
    )
  }

  mergeInGlobals(globals: any) {
    return this.mergeIn(['globals'], globals)
  }

  updateValue(updateFn: any) {
    const newState = this.delete('cachedJSValues') // bust cachedJSValues
    return newState.update('values', updateFn)
  }

  getIn(arr: any) {
    if (arr[0] === 'values') {
      return this.values.getIn(arr.slice(1))
    }
    return super.getIn(arr)
  }

  setValue(selector: any[], value: any): AppModelType {
    let newState = this.set('values', this.values.setIn(selector, value))

    const cachedJSValues = newState.get('cachedJSValues')
    if (!cachedJSValues) {
      newState = newState.set('cachedJSValues', newState.get('values').toJS())
    } else {
      // we explicitly want to use setWith here because using set interprets keys that are numbers as array indices
      _.setWith(cachedJSValues, selector, value, Object)
    }

    return newState
  }

  setDependencyGraph(depGraph: IDependencyGraph) {
    return this.set('dependencyGraph', depGraph)
  }

  deleteValue(selector: string[]) {
    const newState = this.delete('cachedJSValues') // bust cachedJSValues
    return newState.set('values', newState.values.deleteIn(selector))
  }

  nonRecursiveSetError(selector: string[], value: any) {
    return this.setIn(['errors'].concat(selector), value)
  }

  setError(selector: string[], error: any) {
    let newState = this
    for (let i = 0; i < selector.length; i++) {
      if (!newState.errors.getIn(selector.slice(0, i))) {
        newState = newState.setIn(['errors'].concat(selector.slice(0, i)), Immutable.Map())
      }
    }
    return newState.setIn(['errors'].concat(selector), error)
  }

  clearValues() {
    return this.set('values', this.values.clear())
  }

  pluginTypeLoaded(type: string): this {
    return this.update('loadedPluginTypes', (types) => types.add(type))
  }
}

export enum OauthAuthorizedStatus {
  NOT_STARTED,
  SUCCESSFUL,
  IN_PROGRESS,
}

export type AppModelType = Omit<
  AppModelRecord,
  // make all the writes go thru this class, in order to make
  // certain perf optimizations easier
  | 'set'
  | 'update'
  | 'merge'
  | 'mergeDeep'
  | 'mergeWith'
  | 'mergeDeepWith'
  | 'delete'

  // deep
  | 'setIn'
  | 'updateIn'
  | 'mergeIn'
  | 'mergeDeepIn'
  | 'deleteIn'

  // aliasses
  | 'remove'
  | 'removeIn'
>

export const recordTransit = transit.withRecords([Position, PluginTemplate, AppTemplate, Position2])

interface PreviewParams {
  isFetching: boolean
  fetchingTimestamp: number
  timestamp: number
  data: any
  error?: {
    message: string
    hint?: string
    syntaxErrorPosition?: number
  }
}
const defaultPreviewParams: PreviewParams = {
  isFetching: false,
  fetchingTimestamp: 0,
  timestamp: 0,
  data: null,
  error: undefined,
}
export class Preview extends Record(defaultPreviewParams) {}

/**
 * TODO: split up this monstrosity into
 * AppQuerySettings (page related stuff like isFetching and runWhenPageLoads)
 * and QueryTemplate (resource related stuff) and QuerySettings
 * (stuff like queryTimeout and transformer)
 */

type QueryFailureCondition = {
  condition: string
  message: string
}

export type QueryFailureConditions = QueryFailureCondition[]

export interface QueryTemplateShape {
  runWhenPageLoads: boolean
  runWhenPageLoadsDelay: string
  runWhenModelUpdates: boolean
  requireConfirmation: boolean
  updateSetValueDynamically: boolean
  confirmationMessage?: string
  successMessage?: string
  showSuccessConfetti: boolean
  queryDisabled?: string
  queryDisabledMessage?: string
  triggersOnSuccess: string[]
  triggersOnFailure: string[]
  privateParams: string[]
  watchedParams: string[]
  allowedGroups: string[]
  queryRefreshTime: string
  queryThrottleTime: string
  queryTimeout: string
  queryTriggerDelay: string
  cacheKeyTtl: string
  enableCaching: boolean
  isFetching: boolean

  transformer: string
  errorTransformer: string
  mockResponseTransformer?: string
  enableTransformer: boolean
  enableErrorTransformer: boolean
  enableMockResponseTransformer?: boolean
  queryFailureConditions: string
  isClonedDemoQuery?: boolean
  clonedDemoResourceType?: string
  showUpdateSetValueDynamicallyToggle: boolean

  data: any
  metadata: any
  rawData: any
  timestamp: number
  showSuccessToaster: boolean
  showFailureToaster: boolean
  resourceNameOverride: string

  notificationDuration: string

  isImported: boolean
  playgroundQueryUuid: string
  playgroundQueryId: number | undefined
  playgroundQuerySaveId: PlaygroundQuerySaveId
  importedQueryInputs: { [param: string]: string }
  importedQueryDefaults: { [param: string]: any }
  query: string
  showLatestVersionUpdatedWarning?: boolean
}
export interface LibraryQueryTemplateShape extends QueryTemplateShape {
  retoolVersion: string
}
export type QueryState<T> = TypedMap<
  Omit<QueryTemplateShape, 'importedQueryInputs' | 'importedQueryDefaults'> & {
    // ugh so, we create the QueryState using fromJS(), which is untyped.
    // ImmutableMapType only shallowly converts to Immutable, so for the nested
    // collections, we have to do it manually like this
    importedQueryInputs: Immutable.Map<string, string>
    importedQueryDefaults: Immutable.Map<string, any>
  } & { warningCodes: SQL_ERR_WARNINGS[]; editorMode?: SqlQueryEditorMode } & T
>

export enum EditorSections {
  Queries = 'Queries',
  Transformers = 'Transformers',
  Instrumentation = 'Instrumentation',
}

export const EDITOR_TYPE_HAS_SCHEMA: { [t in QueryEditorType]?: boolean } = {
  SqlQueryUnified: true,
  SqlQuery: true,
  RetoolTableQuery: true,
  DatastoreQuery: true,
  FirebaseQuery: true,
  DynamoQuery: true,
  GraphQLQuery: true,
  NoSqlQuery: true,
}

export const EDITOR_SCHEMA_IS_SAMPLED: { [t in QueryEditorType]?: boolean } = {
  NoSqlQuery: true,
}

export type QueryEditorTabType = 'General' | 'Response' | 'Advanced'
export type UntransformedQueryResponse = {
  data: SafeAny
  metadata: any
  error: string
  errors: any
}

interface EditorModelParams {
  saveUpToDate: boolean
  isPastingWidgets: boolean
  isSaving: boolean
  queryTypeChanged: boolean

  copiedWidgets: Immutable.Set<string>
  selectedWidgets: Immutable.Set<string>
  highlightedWidgets: Immutable.Set<string>
  selectedFunctionId: string
  selectedInstrumentId: string
  selectedDatasourceId: string
  selectedResourceName: string
  selectedStateId: string
  selectedFrameId: string
  selectedGlobalWidgetPropId: string
  queryState: Immutable.Map<string, QueryState<{}>>
  preview: Preview
  schemaViewerOpen: boolean
  queryEditorOpen: boolean
  selectedQuerySettingsTab: QueryEditorTabType
  modelBrowserOpen: boolean
  widgetPickerOpen: boolean
  widgetManagerOpen: boolean
  debuggerOpen: boolean
  previewOpen: boolean
  /** disable preloaded app styles, for use when the styles editor is open */
  appStylesDisabled: boolean
  queryEditorHeight: number
  schemas: Immutable.Map<string, any>
  showGrid: boolean
  accessLevel: string
  transformerChanged: boolean
  instrumentChanged: boolean
  querySyntaxErrors: {
    [queryId: string]: {
      sqlText: string
      message: string
      syntaxErrorPosition?: number | null
      syntaxErrorLineNumberIdx?: number | null
      syntaxErrorLineCol?: number | null
    }
  }
  defaultModelBrowserExpandedPluginIds: Immutable.Set<string>
  untransformedQueryResponses: { [queryName: string]: UntransformedQueryResponse }
  currentPopoutCodeEditor: {
    id: string
    props: CommonCodeEditorProps
    cursor?: CodeMirror.Position
  } | null
  lastSelectedElements: Immutable.Set<string>
  changedPlugins: Immutable.Set<string>
  undoRedoCount: number
}

export const QUERY_EDITOR_MIN_HEIGHT = 300

const defaultEditorModelParams: EditorModelParams = {
  saveUpToDate: true,
  isPastingWidgets: false,
  isSaving: false,
  queryTypeChanged: false,
  copiedWidgets: Immutable.Set(),
  selectedWidgets: Immutable.Set(),
  highlightedWidgets: Immutable.Set(),
  selectedFunctionId: '',
  selectedInstrumentId: '',
  selectedDatasourceId: '',
  selectedResourceName: '',
  selectedStateId: '',
  selectedFrameId: '',
  selectedGlobalWidgetPropId: '',
  queryState: Immutable.Map(),
  preview: new Preview(),
  schemaViewerOpen: true,
  queryEditorOpen: true,
  selectedQuerySettingsTab: 'General',
  modelBrowserOpen: false,
  debuggerOpen: false,
  widgetPickerOpen: true,
  previewOpen: false,
  appStylesDisabled: false,
  queryEditorHeight: QUERY_EDITOR_MIN_HEIGHT,
  schemas: Immutable.Map(),
  showGrid: false,
  widgetManagerOpen: true,
  accessLevel: 'write',
  transformerChanged: false,
  querySyntaxErrors: {},
  defaultModelBrowserExpandedPluginIds: Immutable.Set(),
  instrumentChanged: false,
  changedPlugins: Immutable.Set(),
  untransformedQueryResponses: {},
  currentPopoutCodeEditor: null,
  lastSelectedElements: Immutable.Set(),
  undoRedoCount: 0,
}

export class EditorModel extends Record(defaultEditorModelParams) {
  constructor() {
    super()

    let { queryEditorOpen, modelBrowserOpen, widgetPickerOpen, queryEditorHeight } = defaultEditorModelParams

    try {
      const getLocalStorageValue = <T>(key: string, fallback: T, expectedType: string): T => {
        const value = JSON.parse(localStorage.getItem(key) ?? '')
        return typeof value === expectedType ? value : fallback
      }

      const getLocalStorageBoolean = (key: string, fallback: boolean) =>
        getLocalStorageValue<boolean>(key, fallback, 'boolean')
      const getLocalStorageNumber = (key: string, fallback: number) =>
        getLocalStorageValue<number>(key, fallback, 'number')

      queryEditorOpen = getLocalStorageBoolean('retool:queryEditorOpen', queryEditorOpen)
      modelBrowserOpen = getLocalStorageBoolean('retool:modelBrowserOpen', modelBrowserOpen)
      widgetPickerOpen = getLocalStorageBoolean('retool:widgetPickerOpen', widgetPickerOpen)
      queryEditorHeight = getLocalStorageNumber('retool:queryEditorHeight', queryEditorHeight)
    } catch (err) {
      // just accessing window.localStorage in the sandbox raises an error,
      // so we can't even import the local-storage library here
      return
    }

    return this.withMutations((editor) => {
      editor
        .set('queryEditorOpen', queryEditorOpen)
        .set('modelBrowserOpen', modelBrowserOpen)
        .set('widgetPickerOpen', widgetPickerOpen)
        .set('queryEditorHeight', queryEditorHeight)
    })
  }

  selectWidget(widgetId: string): this {
    if (!widgetId) return this.clearEditorFormSelection()

    const selectedWidgets = this.get('selectedWidgets')
    if (selectedWidgets.size === 1 && selectedWidgets.has(widgetId)) return this

    return this.clearEditorFormSelection().set('selectedWidgets', Immutable.Set([widgetId]))
  }

  demultiselectWidget(widgetId: string): this {
    return this.update('selectedWidgets', (selectedWidgets) => selectedWidgets.remove(widgetId))
  }

  multiselectWidget(widgetId: string): this {
    if (this.get('selectedWidgets').has(widgetId)) return this

    return this.withMutations((editor) =>
      editor
        .update('selectedWidgets', (selectedWidgets) => selectedWidgets.add(widgetId))
        .set('selectedStateId', '')
        .set('selectedFrameId', ''),
    )
  }

  selectMultipleWidgets(widgetIds: string[]): this {
    return this.clearEditorFormSelection().set('selectedWidgets', Immutable.OrderedSet(widgetIds))
  }

  deselectWidget(): this {
    return this.update('selectedWidgets', (selectedWidgets) => selectedWidgets.clear())
  }

  selectState(stateId: string): this {
    if (this.get('selectedStateId') === stateId) return this

    return this.clearEditorFormSelection().set('selectedStateId', stateId)
  }

  selectFrame(frameId: string): this {
    if (this.get('selectedFrameId') === frameId) return this

    return this.clearEditorFormSelection().set('selectedFrameId', frameId)
  }

  clearEditorFormSelection(): this {
    return this.withMutations((editor) =>
      editor
        .update('selectedWidgets', (selectedWidgets) => selectedWidgets.clear())
        .set('selectedStateId', '')
        .set('selectedFrameId', ''),
    )
  }

  multipleWidgetsSelected(): boolean {
    return this.selectedWidgets.size > 1
  }

  isMultiselected(widgetId: string): boolean {
    return this.selectedWidgets.has(widgetId)
  }

  getSelectedWidgetId(): string | '' {
    return this.selectedWidgets.size !== 1 ? '' : this.selectedWidgets.first()
  }

  firstSelectedWidgetId(): string | undefined {
    return this.selectedWidgets.first()
  }

  getEditorFormId(): string {
    return this.getSelectedWidgetId() || this.selectedFrameId || this.selectedStateId || this.selectedGlobalWidgetPropId
  }

  getHighlightedWidgetIds(): Immutable.Set<string> {
    return this.highlightedWidgets
  }

  highlightWidget(id: string): this {
    return this.update('highlightedWidgets', (highlighted) => highlighted.add(id))
  }

  unhighlightWidget(id: string): this {
    return this.update('highlightedWidgets', (highlighted) => highlighted.remove(id))
  }

  unhighlightAllWidgets(): this {
    return this.update('highlightedWidgets', (highlighted) => highlighted.clear())
  }
}

export const DEFAULT_EXPERIMENT_VALUES = {
  approvalWorkflows: false,
  organizationThemingForNonEnterprisePlan: false,
  flows: false,
  shell: false,
  firebaseRawQuery: false,
  destroyInactiveTab: __TEST__ || false,
  selfServiceOnPremBillingUpgrade: true,
  selfServiceOnPremCTA: false,
  newSuggestModal: false,
  appShell: __DEV__,
  appShellOrgSettings: __DEV__,
  customOnboardingResources: __DEV__,
  googleAnalyticsIntegration: __DEV__,
  googleMapsIntegration: __DEV__,
  deprecatedComponents: false,
  appsUsingResources: false,
  hubspotIntegration: __DEV__,
  launchdarklyIntegration: __DEV__,
  frontIntegration: __DEV__,
  pageDocumentation: __DEV__,
  oneSignalIntegration: __DEV__,
  circleciIntegration: __DEV__,
  showOnPremBillingToggle: false,
  evaluatorTemplateCaching: false,
  exportDemoApp: __DEV__,
  sandboxHeartbeat: __DEV__,
  pinImportedQueryToLatestVersion: __DEV__,
  espree: __DEV__,
  hoverModelBrowser: __DEV__,
  airflowIntegration: __DEV__,
  openAIIntegration: __DEV__,
  googleSearchConsoleIntegration: __DEV__,
  testingInterface: __DEV__,
  popoutCodeEditor: __DEV__,
  autocompleteInTemplate: __DEV__,
  v2Inputs: __DEV__,
  performanceDashboard: false,
  retoolPillRedesign: __DEV__,
  queryFoldersV2: false,
  debugPage: __DEV__,
  showSuiteAddOption: false,
  newRowHeight: __DEV__,
  showAllExperimentToggles: __DEV__,
  preventCurrentUserVariableSpoofing: false,
}

export type ExperimentValues = typeof DEFAULT_EXPERIMENT_VALUES

type ProfilePageUserShape = {
  firstName: string
  lastName: string
  email: string
  twoFactorAuthEnabled: boolean
}

// A one-off temporary type for use in Profile.tsx until we remove Immutable.js and can
// more easily streamline the typing there
export type ProfilePageUser = TypedMap<ProfilePageUserShape>

export type UserPermissions = TypedMap<UserPermissionsShape>

export type ResourcesFromServer = Immutable.List<TypedMap<ResourceFromServer>>

export interface InstrumentationIntegrationType {
  config?: string[]
  key?: string | null
  integration: string
  enabled: boolean
  createdAt: string
  updatedAt: string
}

export class UserModel extends Record({
  resources: Immutable.Record({
    isFetching: false,
    resources: Immutable.List() as ResourcesFromServer,
    errorMessage: '',
  })(),
  flows: Immutable.Record({
    isFetching: false,
    flows: Immutable.List<TypedMap<Flow>>(),
  })(),
  branches: Immutable.Record({
    isFetching: false,
    branches: Immutable.List<TypedMap<Branch>>(),
  })(),
  orgInfo: Immutable.Record({
    isFetching: false,
    licenseKey: '',
    instrumentationIntegrations: Immutable.Map<string, InstrumentationIntegrationType>(),
    organization: Immutable.Map() as any,
    users: Immutable.Map<string, TypedMap<User>>(),
    userInvites: Immutable.Map<string, TypedMap<UserInvite>>(),
    userGroups: Immutable.Map<string, TypedMap<UserGroup>>(),
    userInviteGroups: Immutable.Map<string, TypedMap<UserInviteGroup>>(),
    userInviteSuggestions: Immutable.Map<string, TypedMap<UserInviteSuggestion>>(),
    groups: Immutable.Map<string, TypedMap<Group>>(),
    pages: Immutable.Map(),
    groupPages: Immutable.Map<string, TypedMap<GroupPage>>(),
    groupFolderDefaults: Immutable.Map<string, TypedMap<GroupFolderDefault>>(),
    groupResources: Immutable.Map<string, TypedMap<GroupResource>>(),
    experiments: {},
    groupMembershipManagementDisabled: false,
  })(),
  changePassword: Immutable.Record({
    isFetching: false,
    errorMessage: '',
  })(),
  cardIsUpdating: false,
  planIsUpdating: false,
  licenseStatus: Immutable.Map(),
  user: Immutable.Map() as any,
  experimentValues: DEFAULT_EXPERIMENT_VALUES,
  twoFactorAuthChallenged: false,
}) {}

export interface EditorSelectionParams {
  x?: number
  y?: number
  height?: number
  width?: number
  isUnion: boolean
}
const defaultEditorSelectionParams: any = {
  x: null,
  y: null,
  height: null,
  width: null,
  isUnion: false,
}
export class EditorSelection extends Record(defaultEditorSelectionParams) {
  normalizedDimensions() {
    const x = this.width > 0 ? this.x : this.x + this.width
    const y = this.height > 0 ? this.y : this.y + this.height
    const width = Math.abs(this.width)
    const height = Math.abs(this.height)
    return { x, y, width, height }
  }

  isActive() {
    return this.x != null && this.y != null && this.height != null && this.width != null
  }

  nonZeroSize() {
    return this.isActive() && Math.abs(this.height) + Math.abs(this.width) > 0
  }

  contains(rect: DOMRect) {
    const { x, y, width, height } = this.normalizedDimensions()
    const intersects = x + width > rect.x && rect.x + rect.width > x && y + height > rect.y && rect.y + rect.height > y

    const anchorInside =
      this.x > rect.x && this.x < rect.x + rect.width && this.y > rect.y && this.y < rect.y + rect.height
    return intersects && !anchorInside
  }
}

export interface User {
  id: number
  firstName: string
  lastName: string
  email: string
  profilePhotoUrl: string
}

export type AuthMethodType = 'password' | 'saml' | 'google' | 'okta' | 'oauth2sso'
export interface AuditTrailEvent {
  id: number
  user: User
  metadata: {
    notYetAcceptedInviteUsers: any
    users: any
    user: any
    page: any
    group: any
    name: any
    invitee: any
    method: AuthMethodType
  }
  ipAddress: string
  responseTimeMs: number
  actionType: string
  pageName?: string
  queryName?: string
  resourceName?: string
  createdAt: string
  updatedAt: string
}

export type LoginResponse = {
  authUrl: string
  authorizationToken: string
}

export interface AuditTrailModelParams {
  events: AuditTrailEvent[]
  totalCount: number
  currentPage: number
  isFetching: boolean
  isTotalCountTruncated: boolean // indicates that there are more events than totalCount suggests.
}
const defaultAuditTrailModelParams: AuditTrailModelParams = {
  events: [],
  totalCount: 0,
  currentPage: 0,
  isFetching: false,
  isTotalCountTruncated: false,
}
export class AuditTrailModel extends Record(defaultAuditTrailModelParams) {}

// ------------------------------------
// Onboarding Step
// ------------------------------------

interface OnboardingSubStepParams {
  hintNodeSelector: string | ((template: AppTemplate, model: AppModelType, editor: EditorModel) => string)
  hintNodeStyle: CSSProperties
  nextCondition: (template: AppTemplate, model: AppModelType, editor: EditorModel) => boolean
  prevCondition: (template: AppTemplate, model: AppModelType, editor: EditorModel) => boolean
  title?: string
  hintText?: string
}

const defaultOnboardingSubstep: OnboardingSubStepParams = {
  hintNodeSelector: '',
  hintNodeStyle: {},
  nextCondition: () => false,
  prevCondition: () => false,
  title: '',
  hintText: '',
}
export class OnboardingSubStep extends Record(defaultOnboardingSubstep, 'onboardingSubStep') {}

export interface OnboardingStepParams {
  title: string
  message: string | React.ComponentType<any>
  condition: (template: AppTemplate, model: AppModelType, editor: EditorModel) => boolean
  hidden?: () => void
  substeps: Immutable.List<OnboardingSubStep> | OnboardingSubStep
  doItForMe: () => void
  hideBottomRow: boolean
  image: string
  messageSteps: (string | React.ComponentType<any>)[]
  messageNote: string | React.ComponentType<any>
}
const defaultOnboardingStep: OnboardingStepParams = {
  title: '',
  message: '',
  hideBottomRow: false,
  condition: null!,
  doItForMe: null!,
  hidden: undefined,
  substeps: Immutable.List(),
  image: '',
  messageSteps: [],
  messageNote: '',
}
export class OnboardingStep extends Record(defaultOnboardingStep, 'onboardingStep') {
  get substepList() {
    if (Immutable.isIndexed(this.substeps)) {
      return this.substeps
    } else if (this.substeps != null) {
      return Immutable.List([this.substeps])
    } else {
      return Immutable.List<OnboardingSubStep>()
    }
  }
}

// ------------------------------------
// Onboarding Modal (dnd state)
// ------------------------------------

export interface OnboardingModalStateParams {
  top: number
  left: number
}
const defaultOnboardingModalState: OnboardingModalStateParams = {
  left: window.innerWidth - 500 - 280,
  top: 72,
}
export class OnboardingModalState extends Record(defaultOnboardingModalState, 'onboardingModalState') {}

// ------------------------------------
// Onboarding Overal State
// ------------------------------------

export type Checklist = {
  steps: {
    completedAt: Date | null
    step: string
  }[]
}

export interface OnboardingStateParams {
  id: string
  steps: Immutable.List<OnboardingStep>
  currentStep: number
  currentSubStep: number
  modalState: OnboardingModalState
  skipped: boolean
  showOnboarding: boolean
  onboardingChecklist: Checklist | null
  isOnboardingChecklistCollapsed: boolean
  onboardingChecklistPulseState: {
    resourceSelected: boolean
  }
  showOnboardingModal: boolean
}
const defaultOnboardingState: OnboardingStateParams = {
  id: '',
  steps: Immutable.List(),
  currentStep: 0,
  currentSubStep: 0,
  modalState: new OnboardingModalState(),
  skipped: false,
  showOnboarding: false,
  onboardingChecklist: null,
  isOnboardingChecklistCollapsed: false,
  onboardingChecklistPulseState: {
    resourceSelected: false,
  },
  showOnboardingModal: true,
}
export class OnboardingState extends Record(defaultOnboardingState, 'onboardingState') {
  get progress() {
    return (this.currentStep + 1) / this.steps.size
  }

  get substepProgress() {
    const substeps = this.currentOnboardingStep?.substepList ?? List()
    return substeps.size > 0 ? (this.currentSubStep + 1) / substeps.size : 1
  }

  setSkipped(skipped: boolean) {
    return this.set('skipped', skipped)
  }

  setStep(stepNumber: number) {
    stepNumber = Math.min(Math.max(0, stepNumber), this.steps.size - 1)
    return this.set('currentStep', stepNumber).set('currentSubStep', 0)
  }

  setSubStep(subStepNumber: number) {
    if ((this.currentOnboardingStep?.substepList?.size ?? 0) > 0) {
      subStepNumber = Math.min(Math.max(0, subStepNumber), this.currentOnboardingStep!.substepList.size - 1)
      return this.set('currentSubStep', subStepNumber)
    } else {
      return this
    }
  }

  get currentOnboardingStep() {
    return this.steps.get(this.currentStep)
  }

  get currentOnboardingSubStep() {
    const substeps = this.currentOnboardingStep?.substepList
    return substeps?.get(this.currentSubStep)
  }

  updateModalPosition(left: number, top: number) {
    return this.update('modalState', (modalState) => {
      return modalState.set('left', left).set('top', top)
    })
  }
}

export interface Save {
  id: number
  commitMessage: string
  createdAt: string
  user: User | null
  tagName?: string | null
  isReleased?: boolean
  gitSha: string | null
}

export interface PaginatedSavesParams {
  saves: Save[]
  totalCount: number
  pageSize: number
  currentPage: number
  isFetching: boolean
}
const defaultPaginatedSavesParams: PaginatedSavesParams = {
  saves: [],
  totalCount: 0,
  pageSize: 5,
  currentPage: 1,
  isFetching: false,
}
export class PaginatedSaves extends Record(defaultPaginatedSavesParams) {}

export interface PlainResourceOptions {
  authentication?: 'session' | 'oauth2' | 'custom'
  reloginUrl?: string
  oauth2_scope?: string
  attemptLogin?: boolean
  oauth2_access_token?: string
  auth0_access_token?: string
  auth0_access_token_expiry_date?: string
  oauth2_share_user_credentials?: boolean
  verify_session_action?: {
    query?: string
    method?: string
    type?: any
    runWhenModelUpdates?: boolean
    bodyType?: string
    headers?: string
  }
  baseURL?: string
  urlparams?: [string, string][]
  verify_session_url?: string
  verify_session_action_enabled?: boolean
  headers?: [string, string][]
  verify_session_after_custom_expiry_action?: {
    expiryTimeInSeconds?: string
  }
}
/**
 * what the backend returns
 */
export interface PlainResource {
  id: number
  name: string
  displayName: string
  type: ResourceType
  environment: 'production' | 'staging'
  options: PlainResourceOptions
}

export interface Folder {
  id: number
  accessLevel: AccessLevel
  organizationId: number
  parentFolderId: number
  name: string
  systemFolder: boolean
  createdAt: string
  updatedAt: string
}

export interface EncryptedOauthSessionStateType {
  key: string
  value: string
  updatedAt: string
}

export interface ResourceFromServer {
  name: string
  displayName: string
  editorType: QueryEditorType
  type: ResourceType | AdhocResourceType
  hasWriteAccess: boolean
  production: ResourceSettings
  staging?: ResourceSettings
  encryptedOauthSessionStates?: EncryptedOauthSessionStateType
}

export interface StageQuery {
  model: { [key: string]: string }
  playgroundQuerySaveId: number
}

export interface Stage {
  id?: string
  name: string
  isFinalStage: boolean
  callback?: string
  query?: StageQuery
}

export type FlowInputType = 'boolean' | 'string' | 'email' | 'integer' | 'double'

export interface InputSchema {
  id?: string
  name: string
  type: FlowInputType
  uniqueForOpenTasks: boolean
}

export interface Flow {
  id?: string
  name: string
  inputs: InputSchema[]
  stages: Stage[]
}

export interface Branch {
  id: string
  name: string
  pageId: number
  ownerId: number
  updatedAt: string
}

export enum TestState {
  SUCCESSFUL,
  FAILURE,
  NOT_RUN,
  PENDING,
}

export enum HookType {
  BEFORE_EACH,
  AFTER_EACH,
  BEFORE_ALL,
  AFTER_ALL,
}

export type TestHook = {
  type: HookType
  body: string
}

export type TestSuite = {
  id: string
  name: string
  type: 'suite'
  hooks: TestHook[]
  tests: Test[]
  pauseAllQueries: boolean
}

export type Mock = {
  queryId: string
  returnValue: {}
}

export type Test = {
  id: string
  name: string
  body: string
  type: 'test'
  state: TestState
  pauseAllQueries: boolean
  failureMessage?: string
  suiteId?: string
}

export interface Resource extends ResourceFromServer {
  resourceType: ResourceType | AdhocResourceType

  // antd dropdown props...
  // optionFilter is for searching, e.g. so you can search for "http" and still find "Rest Query"
  optionFilter: string
  value: string // name and value are the exact same... -.-
  label: string
}

type CredentialPrivilege = {
  allowRead: boolean
  allowWrite: boolean
}

type QueryEditorModes = {
  allowSqlMode: boolean
  allowGuiMode: boolean
}

export const DEFAULT_CREDENTIAL_PRIVILEGES: CredentialPrivilege = {
  allowRead: true,
  allowWrite: true,
}

export const DEFAULT_QUERY_EDITOR_MODES: QueryEditorModes = {
  allowGuiMode: true,
  allowSqlMode: true,
}

export type ResourceSettings = {
  id: number
  type: ResourceType
  editPrivilege?: boolean
}
export type SqlResourceSettings = ResourceSettings & {
  options?: {
    disableServerSidePrepare?: boolean
    enableDynamicDatabaseCredentials?: boolean
    enableDynamicDatabaseNames?: boolean

    // unified SQL params
    version?: string
    credentialPrivileges?: CredentialPrivilege
    queryEditorModes?: QueryEditorModes
  }
}

export type MongoDBResource = PlainResource & {
  host: string
  port: string
  options: {
    connectionString?: string
    useDNSSeedList?: boolean
  }
}

export interface OpenApiResourceSetting {
  displayName?: string
  options?: {
    spec: string
  }
}

export interface Oauth2ResourceSetting {
  options?: {
    oauth2_client_id: string
    oauth2_client_secret: string
    oauth2_callback_url: string
    oauth2_auth_url: string
    oauth2_access_token_url: string
    oauth2_scope: string
    oauth2_audience?: string
    oauth2_share_user_credentials: boolean
  }
}

export interface VerifiableResourceSetting {
  options?: {
    verify_session_action_enabled: boolean
    verify_session_action: {
      query: string
      method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
      type: string
      runWhenModelUpdates: boolean
      bodyType: string
      headers?: string
    }
  }
}

export interface ApiKeyResourceSetting {
  options?: {
    api_key: string
  }
}

export type AirflowResourceSettings = OpenApiResourceSetting & {
  options?: {
    api_key: string
    customQueryParameters: string[][]
    customHeaders: string[][]
    defaultServerVariables?: string[][]
    authentication: 'apiKey'
  }
}

export type DatadogResourceSettings = OpenApiResourceSetting & {
  options?: {
    customQueryParameters: string[][]
    customHeaders: string[][]
    api_key: string
    application_key: string
    defaultServerVariables?: string[][]
  }
}

export type BigidResourceSettings = OpenApiResourceSetting & {
  options?: {
    customQueryParameters: string[][]
    customHeaders: string[][]
    defaultServerVariables?: string[][]
  }
}

export type CircleCIResourceSettings = OpenApiResourceSetting & {
  options: {
    api_key: string
    customQueryParameters: string[][]
    customHeaders: string[][]
    authentication: 'apiKey'
  }
}

export type TwilioResourceSettings = OpenApiResourceSetting & {
  options?: {
    basic_username: string
    basic_password: string
    customQueryParameters: string[][]
    defaultServerVariables?: string[][]
    customHeaders: string[][]
    authentication: 'basic'
  }
}

export type AsanaResourceSettings = OpenApiResourceSetting &
  Oauth2ResourceSetting &
  ApiKeyResourceSetting &
  VerifiableResourceSetting & {
    options?: {
      customQueryParameters: string[][]
      customHeaders: string[][]
      authentication: 'apiKey' | 'oauth2'
    }
  }

export type HubspotResourceSettings = OpenApiResourceSetting &
  Oauth2ResourceSetting &
  ApiKeyResourceSetting &
  VerifiableResourceSetting & {
    options?: {
      customQueryParameters: string[][]
      customHeaders: string[][]
      oauth2_optional_scope: string
      authentication: 'apiKey' | 'oauth2'
    }
  }

export type GoogleAnalyticsResourceSettings = OpenApiResourceSetting &
  Oauth2ResourceSetting &
  VerifiableResourceSetting & {
    options?: {
      customQueryParameters: string[][]
      customHeaders: string[][]
      authentication: 'oauth2'
    }
  }

export type GoogleMapsResourceSettings = OpenApiResourceSetting & {
  options: {
    api_key: string
    customQueryParameters: string[][]
    customHeaders: string[][]
    authentication: 'apiKey'
  }
}
export type GoogleSearchConsoleResourceSettings = OpenApiResourceSetting &
  Oauth2ResourceSetting &
  VerifiableResourceSetting & {
    options?: {
      customQueryParameters: string[][]
      customHeaders: string[][]
      authentication: 'oauth2'
    }
  }

export type FrontResourceSettings = OpenApiResourceSetting &
  Oauth2ResourceSetting &
  VerifiableResourceSetting & {
    options?: {
      customQueryParameters: string[][]
      customHeaders: string[][]
      authentication: 'oauth2'
    }
  }

export type LaunchDarklyResourceSettings = OpenApiResourceSetting & {
  options: {
    api_key: string
    customQueryParameters: string[][]
    customHeaders: string[][]
    authentication: 'apiKey'
  }
}

export type JiraResourceSettings = OpenApiResourceSetting &
  Oauth2ResourceSetting & {
    options?: {
      defaultServerVariables?: string[][]
      authentication: 'oauth2'
    }
  }

export type OneSignalResourceSettings = OpenApiResourceSetting & {
  options: {
    user_auth_key: string
    api_key: string
    customQueryParameters: string[][]
    customHeaders: string[][]
  }
}

export type OpenAIResourceSettings = OpenApiResourceSetting & {
  options: {
    api_key: string
    customQueryParameters: string[][]
    customHeaders: string[][]
    authentication: 'apiKey'
  }
}

export type StripeResourceSettings = OpenApiResourceSetting & {
  options: {
    api_key: string
    customQueryParameters: string[][]
    customHeaders: string[][]
    authentication: 'apiKey'
  }
}

export type RetoolApprovalWorkflowOptions = {
  options: {
    namespace: string
    timeout: number
    creatorGroups: string[]
    approverGroups: [string, string][]
  }
}

export type RetoolApprovalWorkflowSettings = ResourceSettings & RetoolApprovalWorkflowOptions

export type FlowsSettings = ResourceSettings

export type FunctionPlugin = {
  id: string
  template: {
    funcBody: string
  }
  namespace?: PluginNamespaceInfo
}

export interface InstrumentationProperty {
  pluginId: string
  property: string
}

export type InstrumentationTemplate = {
  jsonBody: string
  properties: InstrumentationProperty[]
  hasConditionsEnabled: boolean
  conditionBody: string
}

export type InstrumentPlugin = {
  id: string
  template: InstrumentationTemplate
}

export type ShellQueryOptions = {
  options: {
    shellHost: string
    shellPost: string
  }
}

export type ShellQueryEditorSettings = ResourceSettings & ShellQueryOptions

export type QueryTemplate = Immutable.Map<string, any>
export type ResourceTemplates = Immutable.Map<string, QueryTemplate>
export interface QueryUsage {
  pageUuid: string
  pageId: string
  pageName: string
  querySavedTimestamp?: moment.Moment
  pinnedToLatestVersion?: boolean
}

export type PlaygroundQueryUsage = QueryUsage & {
  path: string | null
  accessLevel: AccessLevel | null
}

export interface PlaygroundQueryRunResponse {
  isFetching: boolean
  data: any
  error: Error | null
  startedFetchAt: moment.Moment | null
  fetchedAt: moment.Moment | null
  queryId: number
}

interface PlaygroundQueryParams {
  id: number
  name: string
  description: string
  resourceId?: number
  adhocResourceType?: AdhocResourceEditorType
  shared: boolean
  saveId: PlaygroundQuerySaveId
  template: QueryTemplate
  updatedAt: moment.Moment
  editorName: string
}

export interface PlaygroundQuerySave {
  id: PlaygroundQuerySaveId
  createdAt: moment.Moment
  resourceId?: number
  adhocResourceType?: AdhocResourceEditorType
  template: { [s: string]: any }
  editor: { firstName: string; lastName: string }
}

export type PlaygroundQuerySaveId = number | 'latest'

export type PlaygroundQuerySaves = Immutable.Map<number, { [saveId: number]: PlaygroundQuerySave }>

const defaultPlaygroundQueryParams: PlaygroundQueryParams = {
  id: 0,
  name: '',
  description: '',
  shared: false,
  saveId: 'latest',
  template: Immutable.Map(),
  updatedAt: null!,
  editorName: '',
}

export class PlaygroundQuery extends Record(defaultPlaygroundQueryParams) {}

interface PlaygroundModelParams {
  queryTemplates: Immutable.Map<number, ResourceTemplates>
  queryResponses: Immutable.Map<number, PlaygroundQueryRunResponse>

  savedQueries: { [id: number]: PlaygroundQuery }
  savedUserQueryIds: number[]
  savedOrganizationQueryIds: number[]
  savedQuerySaves: PlaygroundQuerySaves
  isFetchingQuerySaves?: boolean

  selectedResource?: Resource
  selectedQueryId?: number
  selectedQuerySaveId?: PlaygroundQuerySaveId
  selectedQueryUsages: QueryUsage[]
  environment: string
  schemas: Immutable.Map<string, any>
}

const defaultPlaygroundModelParams: PlaygroundModelParams = {
  queryTemplates: Immutable.Map(),
  queryResponses: Immutable.Map(),

  savedQueries: {},
  savedUserQueryIds: [],
  savedOrganizationQueryIds: [],
  savedQuerySaves: Immutable.Map(),

  selectedQueryId: undefined,
  selectedQuerySaveId: 'latest',
  selectedResource: undefined,
  selectedQueryUsages: [],
  isFetchingQuerySaves: false,

  environment: 'production',
  schemas: Immutable.Map(),
}

export interface AutogeneratePageParams {
  resourceName: string
  resourceType: ResourceType
  tableName: string
  columnName: string
}

export class PlaygroundModel extends Record(defaultPlaygroundModelParams) {}
export interface GlobalWidgetType {
  id: number
  name: string
  queries: string[]
  props: string[]
  outputs: string[]
  description: string | null
  dimensions: { width: number; height: number }
  pageUuid: string
  tags?: string[]
}

export type GlobalWidgetInputType = 'data' | 'query'
export type GlobalWidgetPropertyType = GlobalWidgetInputType | 'output'

/**
 * An object representing the namespace of a plugin.
 * Provides some convenience methods for working with namespaces.
 */
export interface PluginNamespaceInfo {
  /**
   * Get the namespace represented as an array.
   * The return value will have an entry for each nesting
   * of the namespace with the outer-most namespace first.
   *
   * e.g. ['addressWithMapGlobal', 'addressGlobal']
   */
  getNamespace: () => string[]

  /**
   * Get the original ID of the plugin if it was provided (undefined otherwise)
   * e.g. if the plugin `textinput1` was under a module `global1`, this
   * method would return `textinput1`.
   */
  getOriginalId: () => string | undefined

  /**
   * A convenience method for getting the namespace of the parent.
   *
   * e.g if a widget is namespaced n levels deep, this method will return a Namespace for
   * the n-1 level.
   *
   * If this namespace is only 1 level deep, this will return undefined (the parent has no namespace)
   *
   * Note: this returns a NEW COPY of the namespace for the parent and does not preserve the original ID even if set.
   */
  getParentNamespace: () => PluginNamespaceInfo | undefined

  /**
   * A convenience method for getting a user-friendly name for this plugin (e.g. for success/error messages)
   * global1::global10::query1 -> global1's query1
   */
  getFriendlyPluginName: () => string | undefined
}
export class PluginNamespaceInfoImpl implements PluginNamespaceInfo {
  namespace: string[]
  originalId?: string
  constructor(namespace: string[], originalId?: string) {
    this.namespace = namespace
    if (originalId) {
      this.originalId = originalId
    }
  }

  getNamespace() {
    return this.namespace
  }

  getOriginalId() {
    return this.originalId
  }

  getFriendlyPluginName() {
    const outermostNamespaceName = this.getNamespace()[0]
    const originalId = this.getOriginalId()
    if (outermostNamespaceName) {
      return `${outermostNamespaceName}'s ${originalId}`
    }
    return originalId
  }

  private hasParentNamespace() {
    return this.namespace && this.namespace.length > 1
  }

  getParentNamespace() {
    if (this.hasParentNamespace()) {
      return new PluginNamespaceInfoImpl(this.namespace.slice(0, -1))
    } else {
      return undefined
    }
  }
}

export type BlobStorageEntry = {
  id: string
  name: string
  createdAt: number
  updatedAt: number
}

export interface OrgImageBlobsParams {
  blobs: Immutable.Map<string, BlobStorageEntry>
}

export class OrgImageBlobsModel extends Record<OrgImageBlobsParams>({
  blobs: Immutable.Map(),
}) {}

export interface NodeData {
  selector: Selector
  templateString?: string
  updatesAsync?: string[]

  /** getTemplateStringOverride
   * The getTemplateStringOverride property is an optional method that is defined in a PropertyAnnotation. See comments
   * in src/components/plugins/index.ts for more information.
   */
  getTemplateStringOverride?: (
    appTemplate: AppTemplate,
    dependencyGraph: IDependencyGraph,
    pluginId: string,
    namespace?: PluginNamespaceInfo,
  ) => string
  namespace?: string
  originalId?: string
}

export interface IDependencyGraph {
  parentPointers: Map<string, string>
  getSize: () => number
  getNodes: () => { [s: string]: NodeData }
  lookupTemplateString: (selector: Selector) => string | null | undefined
  lookupUpdatesAsync: (selector: Selector) => Selector
  lookupNamespace: (selector: Selector) => PluginNamespaceInfo | undefined
  getObjectSelectors: (id: string) => Selector[]
  addObject: (selector: Selector, data: any, namespace?: PluginNamespaceInfo) => void
  addPlugin: (plugin: PluginTemplate, data: any, namespace?: PluginNamespaceInfo) => void
  deletePlugin: (pluginId: string) => void
  updatePlugin: (plugin: PluginTemplate, data: any, namespace?: PluginNamespaceInfo) => void
  updateObject: (selector: Selector, data: any, containers?: Set<string>) => void
  deleteObjects: (selectors: Selector[]) => void
  updatePropertyAnnotations: (plugin: PluginTemplate) => void

  getDependenciesOf: (selector: Selector) => Immutable.Set<NodeData>
  getDirectDependenciesOf: (selector: Selector) => Immutable.Set<NodeData>
  getDependantsOf: (selector: Selector) => Immutable.Set<NodeData>
  getDirectDependentsOf(selector: Selector): Immutable.Set<NodeData>
  getDependantsOfUpdate: (parentObject: Map<string, any>, prefix?: string[]) => Immutable.Set<NodeData>
  renamePlugin: (pluginId: string, newId: string) => void
  topologicalSort: () => Selector[]
  getAncestorListViewSelector: (childOfListViewSelector: Selector) => string | null
  isReachableWithout: (start: Selector, end: Selector, without: Selector) => boolean

  applyTemplateStringOverrides: (appTemplate: AppTemplate) => void

  hasNode: (selector: Selector) => boolean
}

export type Page = {
  id: number
  uuid: string
  name: string
  path: string
  folderId: number
  organizationId: number
  accessLevel: 'own' | 'write' | 'read'
  lastEditedBy: number
  createdAt: string
  updatedAt: string
  isGlobalWidget: boolean
  protected: boolean
  releasedTagId: string
  description: string
}

export type NestedGlobalWidget = {
  moduleUuid: string
  moduleSaveId: number
  moduleName: string
  data: { appState: string }
  appTemplate: AppTemplate
}
export type NestedGlobalWidgets = { [uuid: string]: NestedGlobalWidget }

type AvailableModules = {
  isFetching: boolean
  modules: Immutable.List<TypedMap<GlobalWidgetType>>
}
export interface ModulesReducer {
  availableModules: TypedMap<AvailableModules>
  templates: NestedGlobalWidgets
}

export class ModulesReducerModel extends Record<ModulesReducer>({
  availableModules: makeTypedMap<AvailableModules>({
    isFetching: false,
    modules: Immutable.List<TypedMap<GlobalWidgetType>>(),
  }),
  templates: {},
}) {}

export type PositionKey = 'position2' | 'mobilePosition2'

export type PageNameAndUUID = {
  name: string
  pageName: string
  uuid: string
}

export type PagesModel = TypedMap<{
  folders: Immutable.Map<string, TypedMap<Folder>>
  pages: any
  pageNames: Immutable.List<PageNameAndUUID>
  pageTags: any
  pageTagName: any
  pageReleasedTagName: string
  pageName: any
  pageDescription: string
  pageUuid: any
  pageBranchName: any
  pageBranchId: any
  pageProtected: boolean
  pageCommit: any
  editorMode: boolean
  connectedEditors: any
  embed_uuid: any
  embedPassword: any
  uuidToPaths: any
  nestedModules: NestedGlobalWidgets
  isFetchingPagesForFirstTime: boolean
  heartbeatInterval: NodeJS.Timeout
}>

import { AppTemplate, Test, TestEntity, TestState, TestSuite } from 'common/records'
import _ from 'lodash'
import { moveInArray } from 'common/utils'

const findTestEntityWithId = (
  testEntities: TestEntity[],
  testEntity: TestEntity,
): { entity: TestEntity | undefined; suiteId: string | undefined } => {
  // First look for the test entity in the root state
  const thisEntity = testEntities.find((currentEntity: TestEntity) => currentEntity.id === testEntity.id)
  if (thisEntity) {
    return { entity: thisEntity, suiteId: undefined }
  } else {
    const allSuites = testEntities.filter((currentEntity: TestEntity) => currentEntity.type === 'suite') as TestSuite[]
    for (const suite of allSuites) {
      const entityInSuite = suite.tests.find((currentEntity: TestEntity) => currentEntity.id === testEntity.id)
      if (entityInSuite) {
        return { entity: entityInSuite, suiteId: suite.id }
      }
    }
  }

  return { entity: undefined, suiteId: undefined }
}

const updateTestEntityNotInsideSuite = (allEntities: TestEntity[], testEntity: TestEntity) => {
  const testEntitiesCloned = _.cloneDeep(allEntities)
  const thisEntityIndex = testEntitiesCloned.findIndex(
    (currentEntity: TestEntity) => currentEntity.id === testEntity.id,
  )
  const entityArrayAfterTest = testEntitiesCloned.slice(thisEntityIndex + 1)
  return allEntities.slice(0, thisEntityIndex).concat([testEntity], entityArrayAfterTest)
}

const updateTestEntityInsideSuite = (allEntities: TestEntity[], testEntity: TestEntity, suiteId: string) => {
  const testEntitiesCloned = _.cloneDeep(allEntities)
  const suite = testEntitiesCloned.find((testEntity) => testEntity.id === suiteId) as TestSuite
  const thisEntityIndex = suite.tests.findIndex((currentTest: Test) => currentTest.id === testEntity.id)
  const suiteTestsAfterUpdate = suite.tests.slice(thisEntityIndex + 1)
  suite.tests = suite.tests
    .slice(0, thisEntityIndex)
    // Only tests (not more suites) can exist inside of suites
    .concat([testEntity as Test], suiteTestsAfterUpdate)
  return testEntitiesCloned
}

export function handleUpdateTestEntity(state: AppTemplate, action: { payload: { testEntity: TestEntity } }) {
  const testEntity = action.payload.testEntity
  const testEntities = state.get('testEntities')
  const { suiteId } = findTestEntityWithId(testEntities, testEntity)
  let updatedTestEntities
  if (suiteId === undefined) {
    updatedTestEntities = updateTestEntityNotInsideSuite(testEntities, testEntity)
  } else {
    updatedTestEntities = updateTestEntityInsideSuite(testEntities, testEntity, suiteId)
  }
  return state.set('testEntities', updatedTestEntities)
}

export function handleAddTestEntity(state: AppTemplate, action: { payload: { testEntity: TestEntity } }) {
  const testEntity = action.payload.testEntity
  const allEntitiesClonedToForceRerender = _.cloneDeep(state.get('testEntities'))
  allEntitiesClonedToForceRerender.push(testEntity)
  return state.set('testEntities', allEntitiesClonedToForceRerender)
}

export function handleMoveTestEntity(
  state: AppTemplate,
  action: { payload: { originalIndex: number; newIndex: number } },
) {
  const { originalIndex, newIndex } = action.payload
  const testEntities = state.get('testEntities')
  const tests = testEntities.filter((entity) => entity.type === 'test')
  const testsClonedToForceRerender = moveInArray(tests, originalIndex, newIndex)
  const suites = testEntities.filter((entity) => entity.type === 'suite')
  return state.set('testEntities', suites.concat(testsClonedToForceRerender))
}

export function handleMoveTestEntityInSuite(
  state: AppTemplate,
  action: { payload: { originalIndex: number; newIndex: number; suiteId: string } },
) {
  const { originalIndex, newIndex, suiteId } = action.payload
  const testEntities = _.cloneDeep(state.get('testEntities'))
  const suite = testEntities?.find((testEntity) => testEntity.id === suiteId) as TestSuite
  const suiteTests = suite.tests
  suite.tests = moveInArray(suiteTests, originalIndex, newIndex)
  return state.set('testEntities', testEntities)
}

export function handleMarkTestsPending(state: AppTemplate, action: { payload: { tests: Test[] } }) {
  const { tests } = action.payload
  const allEntitiesClonedToForceRerender = _.cloneDeep(state.get('testEntities'))
  allEntitiesClonedToForceRerender.forEach((entity) => {
    if (entity.type === 'test' && tests.some((test) => test.id === entity.id)) {
      entity.state = TestState.PENDING
    }
  })
  return state.set('testEntities', allEntitiesClonedToForceRerender)
}

const deleteTestEntityNotInsideSuite = (allEntities: TestEntity[], testEntity: TestEntity) => {
  const testEntitiesCloned = _.cloneDeep(allEntities)
  const thisEntityIndex = testEntitiesCloned.findIndex(
    (currentEntity: TestEntity) => currentEntity.id === testEntity.id,
  )
  const entityArrayAfterTest = testEntitiesCloned.slice(thisEntityIndex + 1)
  return testEntitiesCloned.slice(0, thisEntityIndex).concat(entityArrayAfterTest)
}

const deleteTestEntityInsideSuite = (allEntities: TestEntity[], testEntity: TestEntity, suiteId: string) => {
  const testEntitiesCloned = _.cloneDeep(allEntities)
  const suite = testEntitiesCloned.find((testEntity) => testEntity.id === suiteId) as TestSuite
  const thisEntityIndex = suite.tests.findIndex((currentTest: Test) => currentTest.id === testEntity.id)
  const suiteTestsAfterUpdate = suite.tests.slice(thisEntityIndex + 1)
  suite.tests = suite.tests.slice(0, thisEntityIndex).concat(suiteTestsAfterUpdate)
  return testEntitiesCloned
}

export function handleDeleteTestEntity(state: AppTemplate, action: { payload: { testEntity: TestEntity } }) {
  const testEntity = action.payload.testEntity
  const testEntities = state.get('testEntities')
  const { suiteId } = findTestEntityWithId(testEntities, testEntity)
  let updatedTestEntities
  if (suiteId === undefined) {
    updatedTestEntities = deleteTestEntityNotInsideSuite(testEntities, testEntity)
  } else {
    updatedTestEntities = deleteTestEntityInsideSuite(testEntities, testEntity, suiteId)
  }
  return state.set('testEntities', updatedTestEntities)
}

import * as React from 'react'
import { Shortcuts } from 'react-shortcuts'
import Omnibar from './omnibar/Omnibar'
import { createSearchExtension, SearchResult } from './extensions'
import { browserHistory } from 'react-router'
import './Omnibox.scss'
import { Folder, Page, PlaygroundQuery } from 'common/records'
import { ShortcutName } from 'shortcutsKeymap'

type SearchModalProps = {
  mouseHidden: boolean
  onAction: (item: SearchResult, newTab?: boolean) => void
  hideOmnibar: () => void
  extensions: ((query: string) => SearchResult[])[]
}
const SearchModal = (props: SearchModalProps) => {
  const { mouseHidden } = props
  return (
    <div style={{ cursor: mouseHidden ? 'none' : 'inherit' }} className="omnibox-modal" onClick={props.hideOmnibar}>
      <div
        onClick={(evt) => {
          // don't close the omnibox when someone clicks on the text input
          evt.stopPropagation()
        }}
        className="omnibox-bar"
      >
        <Omnibar
          defaultValue=""
          placeholder="Quick open..."
          extensions={props.extensions}
          autoFocus
          onAction={props.onAction}
        />
      </div>
    </div>
  )
}

type OmniboxProps = {
  pages: Page[]
  folders: Folder[]
  resources: any
  playgroundQueries: PlaygroundQuery[]
  visible: boolean
  className?: string
  children: React.ReactNode
  showOmnibox: () => void
  hideOmnibox: () => void
}
type OmniboxState = {
  visible?: boolean
  mouseHidden: boolean
}
class Omnibox extends React.Component<OmniboxProps, OmniboxState> {
  constructor(props: OmniboxProps) {
    super(props)
    this.state = { mouseHidden: false }
  }

  componentDidMount() {
    window.addEventListener('keydown', () => {
      if (!this.state.mouseHidden) this.setState({ mouseHidden: true })
    })
    window.addEventListener('mousemove', () => {
      if (this.state.mouseHidden) this.setState({ mouseHidden: false })
    })
  }
  _handleShortcuts = (action: string, event: KeyboardEvent) => {
    switch (action as ShortcutName<'OMNIBOX'>) {
      case 'TOGGLE':
        event.preventDefault()
        this.props.visible ? this.props.hideOmnibox() : this.props.showOmnibox()
        break
      case 'HIDE':
        this.props.hideOmnibox()
        break
    }
  }

  render() {
    const extensions = [
      createSearchExtension(this.props.pages, this.props.folders, this.props.resources, this.props.playgroundQueries),
    ]
    return (
      <Shortcuts
        className={this.props.className}
        name="OMNIBOX"
        handler={this._handleShortcuts}
        isolate
        global
        alwaysFireHandler
        targetNodeSelector="body"
      >
        {this.props.visible && (
          <SearchModal
            mouseHidden={this.state.mouseHidden}
            extensions={extensions}
            hideOmnibar={() => this.props.hideOmnibox()}
            onAction={(item, newTab) => {
              if (item) {
                if (newTab) {
                  window.open(item.path, '_blank')?.focus()
                } else {
                  this.props.hideOmnibox()
                  browserHistory.push(item.path)
                }
              }
            }}
          />
        )}
        {this.props.children}
      </Shortcuts>
    )
  }
}

export default Omnibox

import { OpenApiResourceSetting } from 'common/records'
import { SafeAny } from 'common/types'
import React, { ReactNode } from 'react'
import { ResourceFormProps } from '../resources/types'
import { GENERATOR_AUTH_TYPES, ResourceAuthTemplate, SupportedAuthType } from './generator-types'
import MultiAuthView from './MultiAuthView'
import PreconfiguredResourceFormView from './PreconfiguredResourceFormView'
import buildValidator from './validationGenerator'

function isSupportedAuthType(authType: SafeAny): authType is SupportedAuthType {
  return GENERATOR_AUTH_TYPES.includes(authType as SupportedAuthType)
}

const getCurrentAuthMode: (resource: ResourceFormProps['resource']) => SupportedAuthType = (resource) => {
  const authMode = resource.options?.authentication
  return authMode
}

/**
 * Generated a resource form based on an ResourceAuthTemplate. This is useful for resources that
 * require a common type of configuration. Currently, this is mostly for OpenAPI resources and supports
 * things like OAuth2 configurations, API Key auth and allows for specifying multiple and switching between them.
 */
export function generateResourceForm<ResourceTemplate extends OpenApiResourceSetting>(
  integrationLabel: string,
  initialTemplate: ResourceTemplate,
  authTemplate: ResourceAuthTemplate,
  hasServerVariables?: boolean,
) {
  // Build the visual form for the resource
  const resourceForm = (props: ResourceFormProps) => {
    const authOptions = authTemplate.auth

    const views: Partial<{ [k in Partial<SupportedAuthType>]: ReactNode }> = {}
    authOptions.forEach((auth) => {
      const view = (
        <PreconfiguredResourceFormView
          {...props}
          auth={auth}
          integrationLabel={integrationLabel}
          hasServerVariables={hasServerVariables}
        />
      )
      views[auth.type] = view
    })

    if (authOptions.length < 2) {
      // Get the non-null view. Cannot be of length 0 because the type is defined as either one or two elements
      return Object.values(views).find((v) => v)
    } else if (authOptions.length === 2) {
      // For now we only support a APIKey, OAuth tuple. (We'll expand as we get to different configurations)
      const view = views[getCurrentAuthMode(props.resource)]
      return (
        <MultiAuthView {...props} authOptions={authOptions} getView={getCurrentAuthMode}>
          {view}
        </MultiAuthView>
      )
    } else {
      // This should never happen because we defined the type to be a tuple
      throw new Error('We only support a maximum of two ways to authenticate to any resource.')
    }
  }

  // build validators that decide when the Create Resource button is clickable
  const validator = (resource: ResourceFormProps['resource']) => {
    const options = resource.options
    const authType = options?.authentication

    // const currentAuthMode = getCurrentAuthMode(resource)
    // also these are still broken
    // TODO fix this
    const currentAuthMode = isSupportedAuthType(authType) ? authType : 'custom'

    return buildValidator(authTemplate.auth, resource, currentAuthMode)
  }

  return {
    label: integrationLabel,
    form: resourceForm,
    defaults: initialTemplate,
    validator,
    isOAuth: authTemplate.isOAuth,
    hideTestConnection: authTemplate.hideTestConnection,
    hideCreateResourceButton: authTemplate.hideCreateResourceButton,
  }
}

import React from 'react'
import { IS_ON_PREM } from 'retoolConstants'
import { SHARED_OAUTH_CALLBACK_URL, USER_CALLBACK_URL } from '../oauthForm'
import { ResourceFormProps } from '../resources/types'
import OAuthAppInput from '../resources/components/OAuthAppInput'
import OAuthScopeRadioInput from '../resources/components/OAuthScopeRadioInput'
import OAuthShareableOptionInput from '../resources/components/OAuthShareableOptionInput'
import ResourceOAuthForm from '../resources/ResourceOAuthForm'
import { ResourceOauth2Auth } from './generator-types'
import { NAME_MISSING } from '../resources/constants'

export const defaultShowConnectedApp = (_resource?: ResourceFormProps['resource']) => !IS_ON_PREM || __DEV__

const getCallbackUrl = (shareUserCredentials: boolean) =>
  shareUserCredentials ? SHARED_OAUTH_CALLBACK_URL : USER_CALLBACK_URL

/**
 * An OAuth2 resource form. It takes care of parsing the config to see if it supports
 * a Retool Hosted OAuth2 App (also known as a 1-click integration). If not, it defaults to
 * a custom app form where the user has to provide their own client id and secret.
 *
 * It also takes care of defaulting to the custom app if Retool is hosted on-prem.
 * Allows for the "shared credential" option as well.
 */
function OAuth2View(props: ResourceFormProps & { integrationLabel: string; auth: ResourceOauth2Auth }) {
  const { integrationLabel, auth, ...restOfProps } = props
  const options = auth?.options
  const customAppOptions = options.customApp
  const shareUserCredentials = props.resource?.options?.oauth2_share_user_credentials

  // Sometimes we'll have a Retool owned app for the integration
  // which will allow users to do a 1-click oAuth flow instead
  // of having to create their own app and giving us the Client ID and Client Secret
  const retoolHostedAppView = options?.retoolHostedApp && (
    <div className="grid-offset-1">
      <ResourceOAuthForm
        onTriggerSaveResource={props.saveResource}
        resource={props.resource}
        disabled={!props.resource.displayName}
        disabledTooltipTitle={NAME_MISSING}
        resourceTypeForLabel={integrationLabel}
        connectButtonTextOverride={
          shareUserCredentials ? undefined : `Test OAuth integration with your own ${integrationLabel} account`
        }
        hideInProgressAuthorizationStatusAlert={!shareUserCredentials}
        onUpdateOauthAuthorizedStatus={props.onUpdateOauthAuthorizedStatus}
      />
    </div>
  )

  // However, even if we have one it won't work for on-prem installations
  // since we have no way to store our Client ID and Client Secret securely there.
  // Thus, we have to fall back on having the user give us their app credentials.
  const showRetoolHostedAppView = options?.showHostedApp || defaultShowConnectedApp
  const showRetoolHosted = retoolHostedAppView && showRetoolHostedAppView(props.resource)
  const oAuthView = showRetoolHosted ? (
    retoolHostedAppView
  ) : (
    <OAuthAppInput {...restOfProps} customAppDescription={customAppOptions.description} />
  )

  // Sometimes we support multiple scopes for an integration
  // e.g. a readOnly scope and a readWrite scope
  // Other times there can be many scopes a user can choose from!
  // If we have multiple scopes, we'll show a UI component that lets the user choose.
  const availableScopes = options?.availableScopes

  // We also have the option of sharing tokens across all users rather than
  // having each person authenticate and receive their own token. This option is
  // displayed as a checkbox.
  const isShareableAcrossUsers = options?.isShareableAcrossUsers
  return (
    <>
      {availableScopes && availableScopes?.length > 1 && (
        <div className="grid-1c mb12">
          <div />
          <div>
            <OAuthScopeRadioInput {...restOfProps} availableScopes={availableScopes} />
          </div>
        </div>
      )}
      <div className="grid-1c mb20">{oAuthView}</div>
      {isShareableAcrossUsers && (
        <>
          <div className="grid-1c mb20">
            <div className="grid-offset-1">
              {shareUserCredentials && props.resource?.options?.oauth2_access_token && (
                <a
                  onClick={async () => {
                    await props.updateResourceOptions({
                      oauth2_access_token: '',
                    })
                    props.saveResource()
                  }}
                >
                  Reset {integrationLabel} access token
                </a>
              )}
            </div>
          </div>
          <hr />
          <h5 className="section-heading light-gray">Advanced</h5>
          <br />
          <div className="grid-1c mb20">
            <OAuthShareableOptionInput
              updateResourceOptions={props.updateResourceOptions}
              label={integrationLabel}
              getCallbackUrl={getCallbackUrl}
              shareUserCredentials={shareUserCredentials}
            />
          </div>
        </>
      )}
    </>
  )
}

export default OAuth2View

import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { Menu, Dropdown } from 'antd'
import Button, { ButtonProps } from '../Button'
import Tooltip from '../Tooltip'
import './SplitButton.scss'
import { IconCaretDownCropped } from '../Icon/icons'
import removeFragmentFromNode from 'common/utils/removeFragmentFromNode'

export type SplitButtonMenuSection = {
  title?: string
  rows: React.ReactNodeArray
}

export type SplitButtonMenu = SplitButtonMenuSection[]

export type SplitButtonProps = {
  tooltip?: string
  menu: ReactNode
} & ButtonProps

const prefix = 'SplitButton'

export default function SplitButton({
  children,
  className,
  disabled = false,
  menu,
  tooltip,
  type = 'default',
  ...buttonProps
}: SplitButtonProps) {
  return (
    <div className={classNames(prefix, className)}>
      <Button {...buttonProps} type={type} className={`${prefix}__mainButton`} disabled={disabled}>
        <Tooltip title={tooltip}>{children}</Tooltip>
      </Button>
      <Dropdown
        disabled={disabled}
        trigger={['click']}
        placement="bottomRight"
        overlay={
          <Menu className={`${prefix}__menu`}>
            {/* Menu breaks when using a Fragment to wrap MenuItems */}
            {removeFragmentFromNode(menu)}
          </Menu>
        }
      >
        <Button type={type} className={`${prefix}__menuButton`} disabled={disabled}>
          <IconCaretDownCropped />
        </Button>
      </Dropdown>
    </div>
  )
}

import React from 'react'
import { OAuthForm, SHARED_OAUTH_CALLBACK_URL } from '../../oauthForm'
import { ResourceFormProps } from '../types'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'

const BASECAMP_OAUTH_PROPERTIES = {
  oauth2_callback_url: SHARED_OAUTH_CALLBACK_URL,
  oauth2_auth_url: 'https://launchpad.37signals.com/authorization/new?type=web_server',
  oauth2_access_token_url: 'https://launchpad.37signals.com/authorization/token',
}

class BasecampForm extends React.Component<ResourceFormProps> {
  UNSAFE_componentWillMount() {
    this.props.updateResourceOptions({
      ...BASECAMP_OAUTH_PROPERTIES,
      authentication: 'oauth2',
      oauth2_scope: '',
      customHeaders: [['', '']],
      basic_username: '',
      basic_password: '',
      spec: `${RETOOL_SPECS_DOMAIN}/basecamp.json`,
    })
  }
  render() {
    return (
      <div className="grid-1c mb20">
        {this.props.resource.id && (
          <div className="grid-offset-1">
            <OAuthForm resourceId={this.props.resource.id} buttonText="Connect BaseCamp" />
          </div>
        )}
      </div>
    )
  }
}

export default {
  label: 'Basecamp',
  isOAuth: () => true,
  form: BasecampForm,
  defaults: {
    options: {
      customQueryParameters: [['', '']],
      customHeaders: [['', '']],
      authentication: 'oauth2',
      basic_username: '',
      basic_password: '',
      spec: `${RETOOL_SPECS_DOMAIN}/basecamp.json`,
    },
  },
}

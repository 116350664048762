import { injectReducer } from 'store/reducers'
import { getResources } from 'store/user'
import { ResourceType } from 'common/resourceTypes'
import { pagesLoad } from 'store/appModel/pages'
import { getImportableQueries } from 'routes/Editor/modules/editor'
import { getPermissions } from 'routes/Settings/modules/groups'
import { resourceStyles } from 'retoolConstants'

export default function ResourcesRoute(store: any) {
  return {
    path: 'resources',
    /*  Async getComponent is only invoked when route matches   */
    childRoutes: [
      {
        path: 'new',
        onEnter: async (nextState: any, replace: any, cb: any) => {
          const { resetLoadedResource } = await import(/* webpackChunkName: "resources" */ './modules/resources')
          await store.dispatch(resetLoadedResource())
          document.title = 'Connect a resource | Retool'
          cb()
        },
        getComponent: async (nextState: any, cb: any) => {
          const ResourceSelector = await import(
            /* webpackChunkName: "resources" */
            './components/ResourceSelector'
          )
          cb(null, ResourceSelector.default)
        },
        pageName: 'resources',
      },
      {
        path: 'new/autogenerate-app',
        getComponent: async (nextState: any, cb: any) => {
          const AutogeneratedAppEditor = await import(
            /* webpackChunkName: "resources" */
            './containers/AutogeneratedAppEditorContainer'
          )
          cb(null, AutogeneratedAppEditor.default)
        },
        pageName: 'resources',
      },
      {
        path: 'new/:resourceType',
        onEnter: async (nextState: { params: { resourceType: ResourceType } }, replace: any, cb: any) => {
          const { resetLoadedResourceWithType } = await import(
            /* webpackChunkName: "resources" */
            './modules/resources'
          )
          await store.dispatch(resetLoadedResourceWithType(nextState.params.resourceType))
          const resourceType = nextState.params.resourceType
          const resourceTypeStyles = resourceType && resourceStyles[resourceType]
          document.title = `${
            resourceTypeStyles && resourceTypeStyles.label ? `Configure ${resourceTypeStyles.label}` : 'Configure'
          } Resource | Retool`
          cb()
        },
        getComponent: async (nextState: any, cb: any) => {
          const ResourceEditor = await import(
            /* webpackChunkName: "resources" */
            './containers/ResourceEditorContainer'
          )
          cb(null, ResourceEditor.default)
        },
        pageName: 'resources',
      },
      {
        path: '(:resourceName)',
        onEnter: async (nextState: any, replace: any, cb: any) => {
          const { resourceLoad } = await import(/* webpackChunkName: "resources" */ './modules/resources')
          await store.dispatch(resourceLoad(nextState.params.resourceName))
          document.title = `${nextState.params.resourceName} Resource | Retool`
          cb()
        },
        getComponent: async (nextState: any, cb: any) => {
          const ResourceEditor = await import(
            /* webpackChunkName: "resources" */
            './containers/ResourceEditorContainer'
          )
          cb(null, ResourceEditor.default)
        },
        pageName: 'resources',
      },
    ],
    onEnter: async (nextState: any, replace: any, cb: any) => {
      const [reducer, { default: playgroundReducer }] = await Promise.all([
        import(/* webpackChunkName: "resources" */ './modules/resources'),
        import(/* webpackChunkName: "playgroundReducer" */ 'store/playground'),
      ])

      store.dispatch(getResources())
      store.dispatch(pagesLoad())
      store.dispatch(getPermissions())
      store.dispatch(getImportableQueries())

      injectReducer(store, { key: 'resources', reducer: reducer.default })
      injectReducer(store, { key: 'playground', reducer: playgroundReducer })
      cb()
    },
    indexRoute: {
      onEnter: async () => {
        document.title = 'Resources | Retool'
      },
      getComponent: async (nextState: any, cb: any) => {
        const Resources = await import(/* webpackChunkName: "resources" */ './containers/ResourcesContainer')
        return cb(null, Resources.default)
      },
    },
  }
}

import React, { useRef } from 'react'

import { connect } from 'react-redux'
import { Icon } from 'antd'

import { RetoolState } from 'store'
import { editorPrivilegedSelector } from 'store/selectors'
import { getResources, getBranches } from 'store/user'
import { getMarketingTemplates } from 'store/marketingTemplates'

import NavigationSidebar from './sections/NavigationSidebar'
import ApplicationListing from './sections/ApplicationListing'

import { HomeViewFilter } from 'routes/Home/filters'
import { emptyRootSelector, preloadedCSSSelector } from 'routes/Home/selectors'

import './HomeView.scss'

const AskForPermissionsView = () => (
  <div className="non-ideal-state">
    <div className="non-ideal-state-icon">
      <Icon type="folder-open" />
    </div>
    <h4>No Pages Available</h4>
    <div className="description">
      Looks like you don't have any access to any pages! Contact your organization's admin and ask them for access.
    </div>
  </div>
)

type AppBrowserProps = {
  currentFilter: HomeViewFilter
  editorPrivileged: boolean
  emptyRoot: boolean
  preloadedCSS: string | null
}

export const AppBrowser = (props: AppBrowserProps) => {
  const scroller = useRef<HTMLDivElement | null>(null)
  return (
    <div className="home-scroll-container" ref={scroller}>
      <div className="home-container">
        <div className="app-browser">
          {!props.editorPrivileged && props.emptyRoot ? (
            <AskForPermissionsView />
          ) : (
            <>
              <NavigationSidebar />
              <div className="home-content">
                <ApplicationListing scroller={scroller} />
              </div>
            </>
          )}
        </div>
      </div>
      {props.preloadedCSS && <style>{props.preloadedCSS}</style>}
    </div>
  )
}

interface HomeViewProps extends AppBrowserProps {
  getResources: () => Promise<void>
  getMarketingTemplates: () => void
  getBranches: () => Promise<void>
}

export class HomeView extends React.Component<HomeViewProps, {}> {
  UNSAFE_componentWillMount() {
    this.props.getResources()
    this.props.getMarketingTemplates()
    this.props.getBranches()
  }

  render() {
    return (
      <>
        <AppBrowser {...this.props} />
        {/* for displaying the template clone flow when necessary */}
        {this.props.children}
      </>
    )
  }
}

const mapDispatchToProps = {
  getMarketingTemplates,
  getResources,
  getBranches,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    editorPrivileged: editorPrivilegedSelector(state),
    emptyRoot: emptyRootSelector(state),
    preloadedCSS: preloadedCSSSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeView)

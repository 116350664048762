import React, { useState } from 'react'

import './CreateAppFromDataModal.scss'

import { Modal } from 'components/design-system'
import EditAppFromDataBody from './EditAppFromDataBody'
import PickAppFromDataModalBody from './PickAppFromDataModalBody'
import { AutogeneratePageParams } from 'common/records'

type CreateAppFromDataModalProps = {
  visible: boolean
  closeModal: () => void
}

const PICK_TAB_KEY = 'pick'
const EDIT_TAB_KEY = 'edit'

function CreateAppFromDataModal(props: CreateAppFromDataModalProps) {
  const [activeTab, setActiveTab] = useState(PICK_TAB_KEY)
  const [selectedResourceData, setSelectedResourceData] = useState<AutogeneratePageParams>()

  const moveToEditTab = () => setActiveTab(EDIT_TAB_KEY)
  const moveToPickTab = () => setActiveTab(PICK_TAB_KEY)

  return (
    <Modal
      className="homepage-modal create-app-from-data-modal"
      title={
        <div className="flex items-center">
          <div>Generate an app from data</div>
        </div>
      }
      destroyOnClose
      width={720}
      visible={props.visible}
      onCancel={props.closeModal}
      footer={null}
    >
      <div>
        {activeTab === PICK_TAB_KEY && (
          <PickAppFromDataModalBody onNext={moveToEditTab} setSelectedResourceData={setSelectedResourceData} />
        )}
        {activeTab === EDIT_TAB_KEY && (
          <EditAppFromDataBody
            onBack={moveToPickTab}
            closeModal={props.closeModal}
            selectedResourceData={selectedResourceData}
          />
        )}
      </div>
    </Modal>
  )
}

export default CreateAppFromDataModal

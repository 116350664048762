import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import useBooleanState from 'common/hooks/useBooleanState'

import CreateNewGroupModal from './CreateNewGroupModalContainer'
import GroupSidebar from './GroupSidebar'
import { navigateToGroupDetail } from '../utils'
import { hasPermissionsFeatureSelector } from '../selectors'
import { useGroups } from '../hooks'
import { Group } from '__globalShared__/permissions'

export type GroupSideBarProps = {
  activeGroupId: number
}

const GroupSidebarContainer: React.FC<GroupSideBarProps> = ({ activeGroupId }) => {
  const createGroupModalVisible = useBooleanState(false)

  const groups = useGroups()
  const hasPermissionsFeature = useSelector(hasPermissionsFeatureSelector)

  // don't recompute activeGroup on changes to groups in order to freeze the original ID associated with
  // activeGroupName. this saves us from doing a full page reload on group name changes
  const activeGroup = useMemo(() => groups.find(({ id }) => id === activeGroupId), [activeGroupId])

  const handleOnCreateGroup = (group: Pick<Group, 'id' | 'name'>) => {
    navigateToGroupDetail(group)
    createGroupModalVisible.setFalse()
  }

  return (
    <>
      <GroupSidebar
        onClickCreateGroup={createGroupModalVisible.setTrue}
        activeGroupId={activeGroup?.id}
        groups={groups}
        hasPermissionsFeature={hasPermissionsFeature}
      />
      {createGroupModalVisible.value && (
        <CreateNewGroupModal onRequestClose={createGroupModalVisible.setFalse} onSuccess={handleOnCreateGroup} />
      )}
    </>
  )
}

export default GroupSidebarContainer

import { ResourceConfig } from './types'

import postgresql from './postgresql'
import oracledb from './oracledb'
import mysql from './mysql'
import mssql from './mssql'
import denodo from './denodo'
import redshift from './redshift'
import mongodb from './mongodb'
import googlesheets from './googlesheets'
import elasticsearch from './elasticsearch'
import couchdb from './couchdb'
import rethinkdb from './rethinkdb'
import cassandra from './cassandra'
import cosmosdb from './cosmosdb'
import redis from './redis'
import restapi from './restapi'
import graphql from './graphql'
import bigquery from './bigquery'
import s3 from './s3'
import gcs from './gcs'
import slack from './slack'
import salesforce from './salesforce'
import athena from './athena'
import github from './preconfigured/github'
import stripe from './preconfigured/stripe'
import twilio from './preconfigured/twilio'
import sendgrid from './preconfigured/sendgrid'
import firebase from './firebase'
import dynamodb from './dynamodb'
import basecamp from './preconfigured/basecamp'
import closeio from './preconfigured/closeio'
import snowflake from './snowflake'
import vertica from './vertica'
import presto from './presto'
import saphana from './saphana'
import lambda from './lambda'
import openapi from './openapi'
import retoolapprovalworkflow from './retoolapprovalworkflow'
import datastore from './datastore'
import grpc from './grpc'
import { ResourceType } from 'common/resourceTypes'
import shell from './shell'
import smtp from './smtp'
import bigid from './preconfigured/bigid'
import jira from './preconfigured/jira'

type DefaultResource = ResourceConfig<any>

const initialResources = {
  postgresql,
  mysql,
  mssql,
  denodo,
  oracledb,
  redshift,
  mongodb,
  googlesheets,
  elasticsearch,
  cassandra,
  cosmosdb,
  couchdb,
  rethinkdb,
  restapi,
  graphql,
  bigquery,
  s3,
  gcs,
  slack,
  salesforce,
  athena,
  github,
  stripe,
  twilio,
  sendgrid,
  firebase,
  dynamodb,
  basecamp,
  closeio,
  snowflake,
  redis,
  vertica,
  presto,
  saphana,
  lambda,
  openapi,
  retoolapprovalworkflow,
  datastore,
  shell,
  grpc,
  smtp,
  jira,
  bigid,
}
type InitialResourceKeys = keyof typeof initialResources
type ResourceConfigFor<Key extends ResourceType> = Key extends InitialResourceKeys
  ? typeof initialResources[Key]
  : DefaultResource
const resources = initialResources as {
  [k in ResourceType]: ResourceConfigFor<k>
}

// require.context doesnt exist in tests
if (!__TEST__) {
  const context = require.context('./preconfigured/templatized', true, /\.tsx$/)
  context.keys().forEach((filename) => {
    if (filename) {
      const resourceFormName = filename.match(/\.\/(.*?).tsx/)?.[1]
      const resourceForm = context(filename).default
      // eslint-disable-next-line no-console
      console.log(`[DBG] Load resourceForm ${resourceFormName}`)
      if (resourceFormName) {
        registerResourceForm(resourceFormName as ResourceType, resourceForm)
      } else {
        // eslint-disable-next-line no-console
        console.log('Could not extract resource form name from file', filename)
      }
    }
  })
}

export function registerResourceForm(resourceName: ResourceType, resourceForm: ResourceConfig<unknown>) {
  ;(resources as any)[resourceName] = resourceForm
}

export default resources

import { MutableRefObject, useRef } from 'react'

/**
 * A wrapper around useRef that only calls initialValue on the first render
 */
export default function useLazyRef<T>(initialValue: () => T): MutableRefObject<T> {
  const ref = useRef<T | null>(null)

  if (ref.current === null) {
    ref.current = initialValue()
  }

  return ref as MutableRefObject<T>
}

import React, { useState, useEffect } from 'react'

import './PickTemplateModalBody.scss'

import { connect } from 'react-redux'
import Button from 'components/design-system/Button'
import { RetoolState } from 'store'
import { Icon, TextInput } from 'components/design-system'
import { marketingTemplatesSelector } from 'store/selectors'
import { MarketingTemplateType } from 'store/marketingTemplates.d'
import classNames from 'classnames'
import { resourceIcons } from 'retoolConstants'

type PickTemplateModalBodyProps = {
  onNext: () => void
  templates: MarketingTemplateType[]
  selectedTemplate: MarketingTemplateType | undefined
  onSelectedTemplateChange: (template: MarketingTemplateType) => void
}

function isValidState(selectedTemplate: MarketingTemplateType | undefined) {
  const isTemplateSelected = selectedTemplate !== undefined && selectedTemplate !== null
  return isTemplateSelected
}

function PickTemplateModalBody(props: PickTemplateModalBodyProps) {
  const { selectedTemplate, onSelectedTemplateChange } = props

  const initialTemplates = props.templates || []

  const [templates, setTemplates] = useState<MarketingTemplateType[]>(initialTemplates)
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState<boolean>(false)

  useEffect(() => {
    setIsNextButtonEnabled(isValidState(selectedTemplate))
  }, [selectedTemplate])

  // Default to the first template
  useEffect(() => {
    if (!selectedTemplate && templates.length > 0) {
      onSelectedTemplateChange(templates[0])
    }
  }, [selectedTemplate, onSelectedTemplateChange, templates])

  useEffect(() => {
    let filteredTemplates: MarketingTemplateType[]

    if (searchTerm) {
      filteredTemplates = props.templates.filter((template) => {
        return template.name.toLowerCase().includes(searchTerm.toLowerCase())
      })
    } else {
      filteredTemplates = props.templates
    }

    setTemplates(filteredTemplates)
  }, [searchTerm, props.templates])

  const renderTemplate = (template: MarketingTemplateType) => {
    const isSelected = selectedTemplate !== undefined && template.id === selectedTemplate.id
    return (
      <div
        className={classNames(
          'flex items-top pv4 ph8 mv2 hover-bg-near-white fw-500 br4 pointer',
          'create-app-from-template__resource-container',
          isSelected ? 'dark-gray' : 'gray',
          {
            'bg-faint-gray': isSelected,
          },
        )}
        onClick={() => props.onSelectedTemplateChange(template)}
      >
        <div className="mr8">
          {template.resources && template.resources.length > 0 && resourceIcons[template.resources[0]] ? (
            <img
              style={{ maxWidth: 16, maxHeight: 16, verticalAlign: 'middle' }}
              width="16"
              src={resourceIcons[template.resources[0]]}
            />
          ) : (
            <img
              style={{ maxWidth: 16, maxHeight: 16, verticalAlign: 'middle' }}
              width="16"
              src={resourceIcons.restapi}
            />
          )}
        </div>
        <div className="mr8">{template.name}</div>
      </div>
    )
  }

  return (
    <div>
      <div className="flex" style={{ minHeight: 600 }}>
        <div
          style={{ width: 240, maxHeight: 600 }}
          className="flex fd-col pt16 br-washed-gray create-app-from-template__sidebar-container "
        >
          <div className="pa16 bb-washed-gray" style={{ minHeight: 68 }}>
            <TextInput
              placeholder="Search templates"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              prefix={<Icon type="magnifying-glass" className="create-app-from-template-search-icon" />}
            />
          </div>
          <div className="pa16" style={{ overflowY: 'auto' }}>
            {templates.filter((t) => !t.hideInApp).map(renderTemplate)}
          </div>
        </div>

        {selectedTemplate ? (
          <div className="flex fd-col create-app-from-template__preview-container" style={{ maxHeight: 600 }}>
            <div className="flex pv16 ph20 create-app-from-template__preview-title-container bb-washed-gray">
              <div className="flex fd-col create-app-from-template__preview-title-and-tags-container ">
                <div className="fw-600 dark-gray fs-14 mb4">{selectedTemplate.name}</div>
                <div className="flex mr12">
                  {selectedTemplate.resources &&
                    selectedTemplate.resources
                      .filter((resource) => resourceIcons[resource])
                      .map((resource) => {
                        return (
                          <div className="flex items-center mr8" key={resource}>
                            <img
                              style={{ maxWidth: 14, maxHeight: 12 }}
                              width="14"
                              className="mr4"
                              src={resourceIcons[resource]}
                            />
                            <div className="fs-10 lh-18 fw-600 mr4 light-gray" style={{ letterSpacing: '0.03em' }}>
                              {resource.toUpperCase()}
                            </div>
                          </div>
                        )
                      })}
                </div>
              </div>
              <div className="pl36 create-app-from-template__preview-title-description-container lh-18 gray">
                {selectedTemplate.description}
              </div>
            </div>
            <div className="flex bg-faint-gray create-app-from-template__preview-screenshot-container">
              <iframe className="create-app-from-template__preview-iframe" src={selectedTemplate.embeddedAppUrl} />
            </div>
            <div
              style={{ paddingRight: 28, zIndex: 1, background: 'white' }}
              className="flex items-center pv12 bt-washed-gray justify-end create-app-from-template__preview-nav-container"
            >
              <Button type="primary" onClick={props.onNext} disabled={!isNextButtonEnabled}>
                Pick this template <Icon type="arrow-right" className="ml4" />
              </Button>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RetoolState) => {
  return {
    templates: marketingTemplatesSelector(state),
  }
}

export default connect(mapStateToProps)(PickTemplateModalBody)

import { fetchWithHeadersAndHostname } from 'common/fetch'
import type { DatadogEvent, DatadogRoutePayload } from '__globalShared__/datadogEventTypes'
import { browserHistory } from 'react-router'
import getAppViewModeFromPathname from 'common/utils/getAppViewModeFromPathname'

export type PerfTimerHandle = {
  end: (buildEventWithDuration: (duration: number) => DatadogEvent) => void
}

type ApiRequestFn = (payload: DatadogRoutePayload) => Promise<void>

export const createDatadogReporterForTest = (mockPostRequestFn: ApiRequestFn) => {
  return new DatadogReporter(mockPostRequestFn)
}

class DatadogReporter {
  pageLoadedAt = performance.now()
  private isFirstPageLoad = true
  private currentPageEvents: Array<DatadogEvent> = []
  private queuedPayload: DatadogRoutePayload = { eventsPerPage: [] }
  private flushTimer: number | null = null

  constructor(private apiRequestFn: ApiRequestFn) {
    // Reset perf metrics when navigating to a different page
    browserHistory.listenBefore(() => {
      this.moveCurrentEventsToQueuedPayload()
      this.isFirstPageLoad = false
      this.scheduleFlushQueuedPayload()
      this.pageLoadedAt = performance.now()
    })

    this.scheduleFlushQueuedPayload()
  }

  startTimer(): PerfTimerHandle {
    const t0 = performance.now()

    return {
      end: (buildEventWithDuration: (duration: number) => DatadogEvent) => {
        const t1 = performance.now()
        const duration = t1 - t0
        const datadogEvent = buildEventWithDuration(duration)
        this.currentPageEvents.push(datadogEvent)

        // Log performance timings in tests
        if (__TEST__) {
          // eslint-disable-next-line no-console
          console.log(datadogEvent)
        }
      },
    }
  }

  reportEvent(datadogEvent: DatadogEvent) {
    this.currentPageEvents.push(datadogEvent)
  }

  private moveCurrentEventsToQueuedPayload() {
    if (this.currentPageEvents.length > 0) {
      const payloadForThisPage = {
        events: this.currentPageEvents,
        pageContext: {
          isFirstPageLoad: this.isFirstPageLoad,
          isRetoolin: window.location.host.includes('retoolin'),
          appViewMode: getAppViewModeFromPathname(window.location.pathname),
        },
      }

      this.currentPageEvents = []
      this.queuedPayload.eventsPerPage.push(payloadForThisPage)
    }
  }

  private flushQueuedPayloadImmediately = async (): Promise<void> => {
    this.moveCurrentEventsToQueuedPayload()
    if (this.queuedPayload.eventsPerPage.length > 0) {
      const promise = this.apiRequestFn(this.queuedPayload)
      this.queuedPayload = { eventsPerPage: [] }
      return promise
    }
  }

  private clearFlushTimer = () => {
    if (this.flushTimer) {
      window.clearTimeout(this.flushTimer)
    }
  }

  private scheduleFlushQueuedPayload = () => {
    this.clearFlushTimer()

    this.flushTimer = window.setTimeout(
      () => {
        this.flushQueuedPayloadImmediately()
          .catch(() => {})
          .then(() => {
            return this.scheduleFlushQueuedPayload()
          })
          .catch(() => {})
      },
      // This will ensure there is at least 10 seconds apart between flushes
      10000,
    )
  }
}

const postRequestFn = async (payload: DatadogRoutePayload) => {
  if (!__TEST__) {
    await fetchWithHeadersAndHostname('/api/dd', {
      method: 'POST',
      body: JSON.stringify(payload),
    })
  }
}

export default new DatadogReporter(postRequestFn)

import keyBy from 'lodash/keyBy'
import groupBy from 'lodash/groupBy'
import { createSelector } from 'reselect'

import type {
  GroupMap,
  UserGroupMap,
  UserMap,
  UserInviteGroupMap,
  UserInviteMap,
  GroupPageMap,
  PageMap,
  GroupResourceMap,
  UserInviteSuggestionMap,
  GroupFolderDefaultMap,
} from '__globalShared__/permissions'
import type { Folder, ResourceFromServer } from 'common/records'
import { createFeatureSelector } from 'common/paymentPlans'
import { orgInfoSelector, foldersSelector, userSelector } from 'store/selectors'

import { makeAccountFromUser, makeAccountFromSuggestion, makeAccountFromUserInvite } from './utils'

export const hasPermissionsFeatureSelector = createFeatureSelector('AccessControls')

export const immutableResourcesSelector = createSelector(userSelector, (user) => user.get('resources').get('resources'))
export const resourceArraySelector = createSelector(
  immutableResourcesSelector,
  (resources) => resources.toJS() as ResourceFromServer[],
)
// resources keyed by production environment ID
export const resourcesSelector = createSelector(resourceArraySelector, (resources) => keyBy(resources, 'production.id'))

export const immutablePagesSelector = createSelector(orgInfoSelector, (orgInfo) => orgInfo.get('pages'))
export const pagesSelector = createSelector(immutablePagesSelector, (pages) => (pages?.toJS() ?? {}) as PageMap)
export const pagesArrraySelector = createSelector(pagesSelector, (pageMap) => Object.values(pageMap))

export const foldersArraySelector = createSelector(
  foldersSelector,
  (folders) => Object.values(folders.toJS()) as Folder[],
)

export const immutableGroupsSelector = createSelector(orgInfoSelector, (orgInfo) => orgInfo.get('groups'))
export const groupsSelector = createSelector(immutableGroupsSelector, (groups) => (groups?.toJS() ?? {}) as GroupMap)
export const groupsArraySelector = createSelector(groupsSelector, (groups) => Object.values(groups))
export const groupMembershipManagementDisabledSelector = createSelector(orgInfoSelector, (orgInfo) =>
  orgInfo.get('groupMembershipManagementDisabled'),
)

export const immutableUserInviteGroupsSelector = createSelector(orgInfoSelector, (orgInfo) =>
  orgInfo.get('userInviteGroups'),
)
export const userInviteGroupsSelector = createSelector(
  immutableUserInviteGroupsSelector,
  (userInviteGroups) => (userInviteGroups?.toJS() ?? {}) as UserInviteGroupMap,
)
export const userInviteGroupsArraySelector = createSelector(userInviteGroupsSelector, (userInviteGroups) =>
  Object.values(userInviteGroups),
)

export const immutableUserInvitesSelector = createSelector(orgInfoSelector, (orgInfo) => orgInfo.get('userInvites'))
export const userInvitesSelector = createSelector(
  immutableUserInvitesSelector,
  (userInvites) => (userInvites?.toJS() ?? {}) as UserInviteMap,
)
export const userInvitesArraySelector = createSelector(userInvitesSelector, (userInvites) => Object.values(userInvites))

export const immutableUserInviteSuggestionsSelector = createSelector(orgInfoSelector, (orgInfo) =>
  orgInfo.get('userInviteSuggestions'),
)
export const userInviteSuggestionsSelector = createSelector(
  immutableUserInviteSuggestionsSelector,
  (userInviteSuggestions) => (userInviteSuggestions.toJS() ?? {}) as UserInviteSuggestionMap,
)
export const userInviteSuggestionsArraySelector = createSelector(
  userInviteSuggestionsSelector,
  (userInviteSuggestions) => Object.values(userInviteSuggestions),
)

export const immutableUsersSelector = createSelector(orgInfoSelector, (orgInfo) => orgInfo.get('users'))
export const usersSelector = createSelector(immutableUsersSelector, (users) => (users?.toJS() ?? {}) as UserMap)
export const usersArraySelector = createSelector(usersSelector, (users) => Object.values(users))

// select users, userInvites, suggestions (for user list component)
export const accountsSelector = createSelector(
  usersArraySelector,
  userInvitesArraySelector,
  userInviteSuggestionsArraySelector,
  usersSelector,
  (users, userInvites, userInviteSuggestions, userMap) =>
    users
      .map(makeAccountFromUser)
      .concat(userInviteSuggestions.map(makeAccountFromSuggestion))
      .concat(userInvites.map((invite) => makeAccountFromUserInvite(invite, userMap[invite.invitedById]))),
)

const immutableUserGroupsSelector = createSelector(orgInfoSelector, (orgInfo) => orgInfo.get('userGroups'))
export const userGroupsSelector = createSelector(
  immutableUserGroupsSelector,
  (userGroups) => (userGroups?.toJS() ?? {}) as UserGroupMap,
)
export const userGroupsArraySelector = createSelector(userGroupsSelector, (userGroups) => Object.values(userGroups))

const immutableGroupPagesSelector = createSelector(orgInfoSelector, (orgInfo) => orgInfo.get('groupPages'))
export const groupPagesSelector = createSelector(
  immutableGroupPagesSelector,
  (groupPages) => (groupPages?.toJS() ?? {}) as GroupPageMap,
)
export const groupPageArraySelector = createSelector(groupPagesSelector, (groupPages) => Object.values(groupPages))

const immutableGroupFolderDefaultSelector = createSelector(orgInfoSelector, (orgInfo) =>
  orgInfo.get('groupFolderDefaults'),
)
export const groupFolderDefaultSelector = createSelector(
  immutableGroupFolderDefaultSelector,
  (groupPages) => (groupPages?.toJS() ?? {}) as GroupFolderDefaultMap,
)
export const groupFolderDefaultArraySelector = createSelector(groupFolderDefaultSelector, (groupPages) =>
  Object.values(groupPages),
)

const immutableGroupResourcesSelector = createSelector(orgInfoSelector, (orgInfo) => orgInfo.get('groupResources'))
export const groupResourcesSelector = createSelector(
  immutableGroupResourcesSelector,
  (groupResources) => (groupResources?.toJS() ?? {}) as GroupResourceMap,
)
export const groupResourceArraySelector = createSelector(groupResourcesSelector, (groupResources) =>
  Object.values(groupResources),
)

export const userGroupsByUserIdSelector = createSelector(userGroupsArraySelector, (userGroups) =>
  groupBy(userGroups, 'userId'),
)

export const userGroupsByGroupIdSelector = createSelector(userGroupsArraySelector, (userGroups) =>
  groupBy(userGroups, 'groupId'),
)

export const userInviteGroupsByUserInviteIdSelector = createSelector(
  userInviteGroupsArraySelector,
  (userInviteGroups) => groupBy(userInviteGroups, 'userInviteId'),
)

export const userInviteGroupsByGroupIdSelector = createSelector(userInviteGroupsArraySelector, (userInviteGroups) =>
  groupBy(userInviteGroups, 'groupId'),
)

export const groupPagesByGroupIdSelector = createSelector(groupPageArraySelector, (groupPages) =>
  groupBy(groupPages, 'groupId'),
)

export const groupFolderDefaultsByGroupIdSelector = createSelector(groupFolderDefaultArraySelector, (groupFolders) =>
  groupBy(groupFolders, 'groupId'),
)

export const groupResourcesByGroupIdSelector = createSelector(groupResourceArraySelector, (groupResources) =>
  groupBy(groupResources, 'groupId'),
)

import { Middleware } from 'redux'
import { RetoolState } from './'
import {
  CALL_API_DEPRECATE as CALL_API,
  CallAPIAction_DEPRECATE as CallAPIAction,
  CallAPIResult_DEPRECATE as CallAPIResult,
  APIResultT_DEPRECATE as APIResultT,
} from 'networking/index.deprecated'
import { ResolveThunks } from 'react-redux'
import { GET_AUTH_HEADERS } from 'networking/util'

export type ConnectProps<StateToProps, DispatchToProps> = StateToProps extends (...args: any[]) => any
  ? DispatchToProps extends (...args: any[]) => any
    ? ReturnType<StateToProps> & ResolveThunks<ReturnType<DispatchToProps>>
    : ReturnType<StateToProps> & ResolveThunks<DispatchToProps>
  : DispatchToProps extends (...args: any[]) => any
  ? ResolveThunks<ReturnType<DispatchToProps>>
  : ResolveThunks<DispatchToProps>

export const applyAuthenticationHeaders: Middleware = () => (next) => (action: any) => {
  if (!action) return next(action)

  const callApiParams = action[CALL_API]

  // Check if this action is a redux-api-middleware action.
  if (callApiParams) {
    callApiParams.headers = Object.assign({}, callApiParams.headers, GET_AUTH_HEADERS())
  }

  // Pass the FSA to the next action.
  return next(action)
}

export type RetoolAPIDispatcher<R extends APIResultT> = (
  dispatch: (arg: CallAPIAction<R>) => CallAPIResult<R>,
  getState: () => RetoolState,
) => any

export enum CHECK_QUERY_AUTH {
  REQUEST = 'REQUEST_CHECK_QUERY_AUTH',
  SUCCESS = 'RECEIVE_CHECK_QUERY_AUTH',
  FAILURE = 'FAILURE_CHECK_QUERY_AUTH',
}

import { UPDATE_SANDBOX_GLOBALS } from './constants'
import { SandboxGlobalsType } from './globals.types'

export const updateSandboxGlobals = (sandboxGlobals: string[]): SandboxGlobalsType => {
  return {
    type: UPDATE_SANDBOX_GLOBALS,
    payload: {
      sandboxGlobals,
    },
  }
}

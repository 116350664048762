import { Form, Icon, Spin } from 'antd'
import classnames from 'classnames'
import { copyValueToClipboard } from 'common/copy'
import { Button, Checkbox, Icon as DesignSystemIcon, TextInput, Tooltip } from 'components/design-system'
import Immutable from 'immutable'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { ipWhitelistResources, RETOOL_IP } from 'retoolConstants'
import { RetoolState } from 'store'
import { resourcesIsFetchingSelector } from 'store/selectors'
import resourceFormMap from './resources'
import { isSupportedSignUpTheme, OauthAuthorizedStatus } from 'common/records'
import { ResourceType } from 'common/resourceTypes'
import { callInternalApi } from 'networking'
import './styles.scss'
import { onLocalTrialSelector } from 'store/selectors/billingSelectors'
import getResourceErrorTip from './getResourceErrorTip'
import CollapsibleAlert from './CollapsibleAlert'

// Resources where we shouldn't have a "Test connection" link
export const NO_TEST_CONNECTION_RESOURCES = ['restapi', 'grpc', 'googlesheets', 'salesforce']

export const DELETE_RESOURCE_CONFIRMATION_TITLE = (
  <>
    <strong>Are you sure?</strong>
    <p>
      This will break <strong>Apps</strong> and <strong>Query Library</strong>
      <br />
      queries that use this resource
    </p>
  </>
)

type ResourceFormProps = {
  resource?: Immutable.Map<string, any>
  resources: Immutable.Map<string, any>
  hideEditPrivilege?: boolean
  hideResourceType?: boolean
  saveResource: (
    changeset: any,
    isOAuth: boolean,
    newResourceName: string,
    hideSuccessModal?: boolean,
    triggeredByOauth?: boolean,
  ) => Promise<void>
  disableSubmitButton: (shouldDisableForm: boolean, shouldShowNameTooltip: boolean) => void
  clearResourceErrorMessage: () => void
  isFetching: boolean
  onLocalTrial: boolean
  hideLabelAndType?: boolean
  fieldNamesToReplace: any
  environment?: string
  emptyResourceType?: ResourceType
  isEditing?: boolean
  isModal?: boolean
  isInModal?: boolean
  showDatabaseUrlField?: boolean
}

export type ResourceState = {
  name: string
  displayName: string
  [key: string]: unknown
  options?: {
    dynamicallyQueryable?: boolean
    [key: string]: unknown
  }
}

type ResourceFormState = {
  resource: ResourceState
  testConnectionState?: '' | 'SUCCESS' | 'FAILURE'
  testConnectionError?: string
  oauthAuthorizedStatus?: OauthAuthorizedStatus
}

export class ResourceForm extends React.Component<ResourceFormProps, ResourceFormState> {
  static defaultProps = {
    fieldNamesToReplace: [],
  }
  form: any

  constructor(props: any) {
    super(props)

    // resource is used to populate the form's fields
    // we generate an empty resource given a resource type if there is no resource passed in
    const emptyResourceType = this.props.emptyResourceType ? this.props.emptyResourceType : 'postgresql'
    this.state = { resource: this.getDefaultState(emptyResourceType, props) }

    // when opening a new resource form, there should be no persisting error
    this.props.clearResourceErrorMessage()
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    // issue: reducer also resets error message
    const error = !!nextProps.resources.get('errorMessage')
    if (this.props.resource && nextProps.resource) {
      if (
        this.props.resource.get('id') !== nextProps.resource.get('id') ||
        this.props.resource.get('environment') !== nextProps.resource.get('environment')
      ) {
        // TODO warn about unsaved changed
        this.setState({ resource: nextProps.resource.toJS() })
        if (error) this.props.clearResourceErrorMessage()
      }
    } else if (!this.props.resource && nextProps.resource) {
      // move to new resource creation UI
      this.setState({ resource: nextProps.resource.toJS() })
      if (error) this.props.clearResourceErrorMessage()
    } else if (this.props.resource && !nextProps.resource) {
      this.setState({ resource: this.getDefaultState('postgresql', nextProps) })
    } else if (nextProps && nextProps.emptyResourceType) {
      this.setState({ resource: this.getDefaultState(nextProps.emptyResourceType, nextProps) })
    }
  }

  getDefaultState(type: any, props: any) {
    if (props.resource) {
      const [...resourceKeys] = props.resource.keys()
      let res = null
      if (resourceKeys.length === 1 && resourceKeys[0] === 'type') {
        res = {
          id: null,
          name: '',
          type: props.resource.get('type'),
          ...resourceFormMap[props.resource.get('type') as ResourceType].defaults,
        }
      } else {
        res = props.resource.toJS()
      }
      for (const key of props.fieldNamesToReplace) {
        _.setWith(res, key, '', Object)
      }
      return res
    } else {
      return {
        id: null,
        name: '',
        displayName: '',
        type,
        ...resourceFormMap[type as ResourceType].defaults,
      }
    }
  }

  _enableSubmitIfInputsValid = () => {
    const shouldDisableSubmit = !this._inputsValid()
    const shouldShowNameTooltip = !this._nameValid()
    this.props.disableSubmitButton(shouldDisableSubmit, shouldShowNameTooltip)
  }

  _onChange = (key: string, { handleEvent } = { handleEvent: false }) => (e: any) => {
    const value = handleEvent ? e.target.value : e
    if (key === 'type' && value !== this.state.resource.type) {
      this.setState(
        {
          resource: { ...this.getDefaultState(value, {}), name: this.state.resource.name },
        },
        this._enableSubmitIfInputsValid,
      )
      if (this.props.resources.get('errorMessage')) {
        this.props.clearResourceErrorMessage()
      }
    } else {
      this.updateResource(key, value)
    }
  }

  updateResource = (key: string, value: string) => {
    this.setState(
      // setState takes a function(prevState, props) => nextState
      (prevState) => ({ ...prevState, resource: { ...prevState.resource, [key]: value } }),
      this._enableSubmitIfInputsValid,
    )
  }

  _onChangeTopLevel = (key: string, { handleEvent } = { handleEvent: false }) => (e: any) => {
    this.setState(
      {
        resource: {
          ...this.state.resource,
          [key]: handleEvent ? e.target.value : e,
        },
      },
      this._enableSubmitIfInputsValid,
    )
  }

  _onChangeOption = (key: string, { handleEvent } = { handleEvent: false }) => (e: any) => {
    const additionalOptions = { [key]: handleEvent ? e.target.value : e }
    // Clear the base URL input field when toggling custom base URL off
    if (key === 'usingThirdPartyProvider' && !e) {
      additionalOptions.s3_base_url = ''
    }
    this.setState(
      (prevState) => ({
        resource: {
          ...prevState.resource,
          options: { ...prevState.resource.options, ...additionalOptions },
        },
      }),
      this._enableSubmitIfInputsValid,
    )
  }

  _nameValid = () => {
    return !!this.state.resource.displayName
  }

  _inputsValid = () => {
    if (!this.state.resource.displayName) {
      return false
    }
    const resourceDefinition = this._getCurrentResourceDefinition()
    if ('validator' in resourceDefinition) {
      const { validator } = resourceDefinition
      return validator(this.state.resource, this.props.resource ? (this.props.resource.toJS() as any) : null)
    }
    return true
  }

  _getCurrentResourceDefinition(): typeof resourceFormMap[ResourceType] {
    return resourceFormMap[this.state.resource.type as ResourceType]
  }

  _getCurrentResourceChangeset() {
    return this.state.resource
  }

  renderLabelAndType = () => {
    // Adding a bit of tech debt here; We think having a more pronounced "Name"
    // section will make it more obvious to users that name is required (we see
    // user confusion in full story sessions); if this helps, let's follow up
    // for all resources.
    if (this.state.resource && this.state.resource.type === 'googlesheets') {
      return (
        <div>
          <h5 className="section-heading light-gray mb12">Name your resource</h5>
          <div className="grid-1c mb12">
            {this.renderNameInput(
              'i.e. "Marketing Google Drive"',
              "Your Google Sheets resource needs a unique name. You'll use this name when creating queries in the Retool editor.",
            )}
          </div>
        </div>
      )
    } else {
      return (
        <div className="grid-1c mb12">
          {this.renderNameInput(
            'i.e. "Production DB (readonly)" or "Internal Admin API"',
            'The name for this resource when creating queries in the Retool editor.',
          )}
        </div>
      )
    }
  }

  renderNameInput = (placeholderText: string, productionLabel: string) => {
    return (
      <>
        <label className="input-label" style={{ display: 'inline-block', marginTop: 8 }}>
          <span className="light-red mr4">*</span>Name{' '}
        </label>
        <div>
          <TextInput
            aria-label="Name"
            placeholder={placeholderText}
            value={this.state.resource.displayName}
            disabled={this.props.environment === 'staging'}
            onChange={this._onChange('displayName', { handleEvent: true })}
          />
          <div className="mt4">
            {this.props.environment === 'staging' ? 'Edit the name in the production tab.' : productionLabel}
          </div>
        </div>
      </>
    )
  }

  renderForm() {
    const CurrentResourceForm = this._getCurrentResourceDefinition().form as React.ComponentType<any>
    const optionalProps: { hideEditPrivilege?: boolean } = {}
    if (this.props.hideEditPrivilege != null) {
      optionalProps.hideEditPrivilege = this.props.hideEditPrivilege
    }
    return (
      CurrentResourceForm && (
        <CurrentResourceForm
          {...optionalProps}
          showDatabaseUrlField={this.props.showDatabaseUrlField}
          ref={(form: any) => (this.form = form)}
          resource={this.state.resource}
          updateResource={(update: any) => this.setState({ resource: { ...this.state.resource, ...update } })}
          updateResourceOptions={(update: any) => {
            this.setState(
              (prevState) => ({
                resource: { ...prevState.resource, options: { ...prevState.resource.options, ...update } },
              }),
              () => this._enableSubmitIfInputsValid(),
            )
          }}
          onChangeTopLevel={this._onChangeTopLevel}
          onChangeOption={this._onChangeOption}
          saveResource={(triggeredByOauth = false) =>
            this.props.saveResource(this.state.resource, false, this.state.resource.name, true, triggeredByOauth)
          }
          onUpdateOauthAuthorizedStatus={(oauthAuthorizedStatus: OauthAuthorizedStatus) => {
            this.setState({ oauthAuthorizedStatus })
          }}
        />
      )
    )
  }

  // Introducing a little tech debt of some duplicated code to add TestResourceConnection
  // for the resource form modal pop up during new user onboarding. Need to refactor original
  // function in ResourceEditor component to be able to easily pass this down to ResourceForm
  // component.
  testResourceConnection = async (resourceName: string) => {
    const { environment } = this.state.resource
    const changeset = this._getCurrentResourceChangeset()
    this.setState({ testConnectionState: '', testConnectionError: '' })

    if (changeset) {
      try {
        const response = await callInternalApi({
          url: `/api/resources/testConnection`,
          method: 'POST',
          body: { resourceName, changeset, environment },
        })

        if (response.success) {
          this.setState({ testConnectionState: 'SUCCESS', testConnectionError: '' })
          return
        }
      } catch (e) {
        this.setState({ testConnectionState: 'FAILURE', testConnectionError: e.toString() })
        return
      }
    }
    this.setState({ testConnectionState: 'FAILURE', testConnectionError: '' })
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const resourceDefinition = this._getCurrentResourceDefinition()
    let isOAuth = false
    if (resourceDefinition && 'isOAuth' in resourceDefinition) {
      if (typeof resourceDefinition.isOAuth === 'boolean') {
        isOAuth = resourceDefinition.isOAuth
      } else if (typeof resourceDefinition.isOAuth === 'function') {
        isOAuth = resourceDefinition.isOAuth(this.state.resource)
      }
    }

    this.props.saveResource(this.state.resource, isOAuth, this.state.resource.name)
  }

  renderErrors() {
    const { resource, testConnectionState, testConnectionError } = this.state
    const resourceType = resource.type as ResourceType
    const resourceDefinition = this._getCurrentResourceDefinition()

    const errorMessage = this.props.resources.get('errorMessage')

    let saveErrorTip: JSX.Element | null = null
    let testErrorTip: JSX.Element | null = null
    if (resourceDefinition && 'getResourceErrorTip' in resourceDefinition) {
      saveErrorTip =
        resourceDefinition.getResourceErrorTip?.({
          errMsg: errorMessage,
          resourceName: resource.displayName,
          resourceType,
        }) || null
      testErrorTip =
        resourceDefinition.getResourceErrorTip?.({
          errMsg: testConnectionError || '',
          resourceName: resource.displayName,
          resourceType,
        }) || null
    }
    if (!saveErrorTip) {
      saveErrorTip = getResourceErrorTip({
        errMsg: errorMessage,
        resourceName: resource.displayName,
        resourceType,
      })
    }
    if (!testErrorTip) {
      testErrorTip = getResourceErrorTip({
        errMsg: testConnectionError || '',
        resourceName: resource.displayName,
        resourceType,
      })
    }

    return (
      <>
        {errorMessage ? (
          <>
            <CollapsibleAlert title="Saving failed." message={errorMessage} type="error" />
            {saveErrorTip && !testErrorTip && <CollapsibleAlert title="Tip:" message={saveErrorTip} type="info" />}
          </>
        ) : null}
        {testConnectionState && (
          <>
            <CollapsibleAlert
              title={testConnectionState === 'SUCCESS' ? 'Connection success!' : 'Unable to connect.'}
              message={testConnectionState === 'FAILURE' ? testConnectionError : ''}
              type={testConnectionState === 'SUCCESS' ? 'success' : 'warning'}
            />
            {testConnectionState === 'FAILURE' && testErrorTip && (
              <CollapsibleAlert title="Tip:" message={testErrorTip} type="info" />
            )}
          </>
        )}
      </>
    )
  }

  render() {
    const { resource } = this.state
    const resourceType = resource.type as ResourceType
    const includeWhitelist =
      ipWhitelistResources.includes(resourceType) && !this.props.isInModal && !this.props.onLocalTrial

    // temporary hack so that users can always click save on a googlesheets resource
    // because a gsheets resource is always saved when "Connect to Google Sheet" is clicked
    // and when the user finishes authenticating with gsheets, the "Save Changes" button is always disabled
    // todo: fix when we clarify edit vs. create workflows for resource forms
    if (resource && resourceType === 'googlesheets')
      this.props.disableSubmitButton(!this._inputsValid(), !this._nameValid())

    return (
      <div className="resource-form">
        <div
          className={classnames('resource-detail-view common-detail-view', { 'include-whitelist': includeWhitelist })}
        >
          <Spin spinning={this.props.isFetching}>
            <Form id="resource-form-component" layout="vertical" onSubmit={this.onSubmit}>
              <div className="form-content" style={{ height: '100%' }}>
                {!this.props.hideLabelAndType && this.renderLabelAndType()}
                {this.renderForm()}
                {(__DEV__ || __BETA__) && (
                  <>
                    <div className="resource-form__toggles-group grid-1c mb4">
                      <Checkbox
                        checked={_.get(this.state.resource, 'options.dynamicallyQueryable') as boolean}
                        onChange={(checked) => {
                          this._onChangeOption('dynamicallyQueryable')(checked.target.checked)
                        }}
                        className="grid-offset-1"
                      >
                        Enable dynamic querying{' '}
                        <span
                          style={{ padding: '1px 4px', background: 'var(--faint-yellow)', color: 'var(--dark-yellow)' }}
                          className="ml4 br4 fs-10"
                        >
                          Beta
                        </span>
                      </Checkbox>
                    </div>
                  </>
                )}
                {this.state.resource.type === 'mssql' && (
                  <div className="resource-form__toggles-group grid-1c">
                    <Checkbox
                      checked={_.get(this.state.resource, 'options.isApplicationIntentReadOnly') as boolean}
                      onChange={(checked) => {
                        this._onChangeOption('isApplicationIntentReadOnly')(checked.target.checked)
                      }}
                      className="grid-offset-1"
                    >
                      Connect to a read-only replica{' '}
                      <Tooltip
                        title={
                          <div>
                            {`If you are using Azure's Read Scale-Out feature, this will set the ApplicationIntent option in the connection string to to readOnly. More info in Azure's `}
                            <a href="https://docs.microsoft.com/en-us/azure/azure-sql/database/read-scale-out">docs</a>.
                          </div>
                        }
                        trigger="hover"
                        placement="topRight"
                      >
                        <DesignSystemIcon type="tooltip" className="washed-gray ml4" />
                      </Tooltip>
                    </Checkbox>
                  </div>
                )}
                {this.props.isModal && (
                  <div className="resource-form__end-buttons flex fd-col justify-end">
                    {this.renderErrors()}
                    {/* For Oauth resources, don't show the Save button if we haven't successfully authed yet
                      to prompt users to connect first */}
                    {(this.state.oauthAuthorizedStatus === undefined ||
                      this.state.oauthAuthorizedStatus === OauthAuthorizedStatus.SUCCESSFUL) && (
                      <div className="resource-form__end-buttons-container flex justify-end">
                        {!NO_TEST_CONNECTION_RESOURCES.includes(this.state.resource.type as ResourceType) &&
                          isSupportedSignUpTheme(this.state.resource.type as ResourceType) && (
                            <Button
                              href="#"
                              className="resource-form__test-connection-button fs-13 fw-600"
                              style={{
                                paddingRight: '14px',
                                backgroundColor: 'white',
                                color: 'var(--light-blue)',
                                display: 'inline-flex',
                              }}
                              onClick={() => this.testResourceConnection(this.state.resource.name)}
                            >
                              Test Connection
                            </Button>
                          )}

                        <div>
                          <Button
                            htmlType="submit"
                            className="resource-form__done-button fs-12"
                            disabled={!this._inputsValid()}
                            style={{ float: 'right', display: 'inline-flex' }}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Form>
          </Spin>
        </div>
        {(MAIN_DOMAIN || __DEV__ || this.props.onLocalTrial) && (includeWhitelist || this.props.onLocalTrial) && (
          <div className="light-box flex-no-shrink">
            <>
              <div className="fs-13 fw-600 mb4 lh-24">
                {includeWhitelist ? (
                  <>
                    {' '}
                    🏳 <br /> Whitelisting{' '}
                  </>
                ) : (
                  'Local Resource?'
                )}
              </div>
              <div className="fs-12 lh-16 gray">
                {includeWhitelist ? (
                  <>
                    Please allow Retool to connect to your database by white-listing our IP address:{' '}
                    <div>
                      <span className="dark-gray fw-500" style={{ marginRight: '4px' }}>
                        {RETOOL_IP}
                      </span>
                      <Icon type="copy" onClick={() => copyValueToClipboard(RETOOL_IP)} />
                    </div>
                  </>
                ) : (
                  <>
                    Use the hostname
                    <div>
                      <span className="dark-gray fw-500" style={{ marginRight: '4px' }}>
                        host.docker.internal
                      </span>
                      <Icon type="copy" onClick={() => copyValueToClipboard('host.docker.internal')} />
                    </div>
                  </>
                )}
              </div>
            </>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: RetoolState) => ({
  isFetching: resourcesIsFetchingSelector(state),
  onLocalTrial: onLocalTrialSelector(state),
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(ResourceForm)

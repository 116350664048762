import React from 'react'
import { Checkbox as AntCheckbox } from 'antd'
import classNames from 'classnames'
import { CheckboxProps } from 'antd/lib/checkbox/Checkbox'
import 'antd/lib/checkbox/style/index'
import './Checkbox.scss'

export { CheckboxProps }

export default function Checkbox(props: CheckboxProps) {
  return <AntCheckbox {...props} className={classNames('retool-checkbox', props.className)} />
}

import React from 'react'

const Disclaimer = () => {
  return (
    <div>
      <div className="privacy-policy">
        By using Retool you are agreeing to our{' '}
        <a target="_blank" rel="noopener" href="https://docs.retool.com/docs/privacy-policy">
          privacy policy
        </a>{' '}
        and{' '}
        <a target="_blank" rel="noopener" href="https://cdn.tryretool.com/tos.pdf">
          terms
        </a>
        .
      </div>
    </div>
  )
}

export default Disclaimer

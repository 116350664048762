import { createSelector } from 'reselect'
import { RetoolState } from 'store'
import { resourcesJSSelector } from 'store/selectors'
import { isOnboardingResource } from '../../components/plugins/datasources/common/utils'
import { MANAGED_DB } from '__globalShared__/common'

export const onboardingSelector = createSelector(
  (state: RetoolState) => state.onboarding,
  (onboarding) => onboarding.update('steps', (steps) => steps.filter((step) => !step.hidden?.())),
)

export const onboardingModalSelector = createSelector(onboardingSelector, (onboarding) => onboarding.modalState)
export const stepsSelector = createSelector(onboardingSelector, (onboarding) => onboarding.steps)
export const currentStepNumberSelector = createSelector(onboardingSelector, stepsSelector, (onboarding, steps) =>
  Math.min(Math.max(0, onboarding.currentStep), steps.size - 1),
)
export const currentStepSelector = createSelector(stepsSelector, currentStepNumberSelector, (steps, currentStep) =>
  steps.get(currentStep),
)
export const numberOfStepsSelector = createSelector(stepsSelector, (steps) => steps.size)
export const currentSubStepSelector = createSelector(
  onboardingSelector,
  (onboarding) => onboarding.currentOnboardingSubStep,
)

export const onboardingProgressSelector = createSelector(onboardingSelector, (onboarding) =>
  Math.min(onboarding.progress, 1),
)
export const onboardingIdSelector = createSelector(onboardingSelector, (onboarding) => onboarding?.id)
export const onboardingSkippedSelector = createSelector(onboardingSelector, (onboarding) => onboarding.skipped)

export const showOnboardingModalSelector = createSelector(
  onboardingSelector,
  (onboarding) => onboarding.showOnboardingModal,
)

export const firstCreatedResourceTypeSelector = createSelector(resourcesJSSelector, (resources) => {
  const regularResources = resources.filter((r) => !isOnboardingResource(r)).filter((r) => r.name !== MANAGED_DB)
  let firstCreatedResourceType = null
  if (regularResources.length > 0) {
    const firstCreatedResource = regularResources.reduce((c, n) =>
      Date.parse(n.created) < Date.parse(c.created) ? n : c,
    )
    firstCreatedResourceType = firstCreatedResource.type
  }

  return firstCreatedResourceType
})

export const hasDemoResourceForTutorialSelector = createSelector(
  resourcesJSSelector,
  firstCreatedResourceTypeSelector,
  (resources, firstCreatedResourceType) => {
    const demoResource = resources.find((r) => {
      return r.name.startsWith(`[demo] ${firstCreatedResourceType}`)
    })

    return demoResource !== undefined
  },
)

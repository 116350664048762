import React from 'react'
import { message as AntMessage } from 'antd'

import { IconInfoCircle, IconExclamationCircle, IconCheckCircle, IconCloseCircle } from '../Icon/icons'

import 'antd/lib/message/style/index'
import './Message.scss'

interface MessageApi {
  info: (content: React.ReactNode, duration?: number) => void
  success: (content: React.ReactNode, duration?: number) => void
  warning: (content: React.ReactNode, duration?: number) => void
  error: (content: React.ReactNode, duration?: number) => void
}

const Message: MessageApi = {
  info: (content: React.ReactNode, duration = 1) =>
    AntMessage.open({
      content,
      duration,
      type: 'success',
      icon: <IconInfoCircle />,
    }),
  success: (content: React.ReactNode, duration = 1) =>
    AntMessage.open({
      content,
      duration,
      type: 'success',
      icon: <IconCheckCircle />,
    }),
  warning: (content: React.ReactNode, duration = 1) =>
    AntMessage.open({
      content,
      duration,
      type: 'success',
      icon: <IconExclamationCircle />,
    }),
  error: (content: React.ReactNode, duration = 1) =>
    AntMessage.open({
      content,
      duration,
      type: 'success',
      icon: <IconCloseCircle />,
    }),
}

export default Message

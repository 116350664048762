import React from 'react'
import classNames from 'classnames'

import './TabMenu.scss'
import Icon, { IconType } from '../Icon'
import Tooltip from '../Tooltip'

export type TabProps<T> = {
  name: T
  title?: React.ReactNode
  disabled?: boolean
  iconType?: IconType
  tooltip?: React.ReactNode
}

export type TabMenuProps<T> = {
  values: TabProps<T>[]
  tabIdPrefix?: string
  activeTab?: T
  size?: 'default' | 'large'
  className?: string
  onChange?: (newTab: T) => void
}

const cn = 'retool-tab-menu'

export default function TabMenu<T extends number | string>({
  className,
  size = 'default',
  tabIdPrefix,
  values,
  activeTab,
  onChange,
}: TabMenuProps<T>) {
  return (
    <div className={classNames(cn, `${cn}--${size}`, className)}>
      {values.map(({ name, title, disabled, iconType, tooltip }) => (
        <button
          role="button"
          className={classNames(`${cn}__tab`, name === activeTab && `${cn}__tab--active`)}
          key={`${name}`}
          onClick={() => onChange?.(name)}
          disabled={disabled}
          {...(tabIdPrefix ? { id: `${tabIdPrefix}-${name}` } : {})}
        >
          {iconType && <Icon type={iconType} className="mr4" />}
          {title ?? name}
          {tooltip && (
            <Tooltip title={tooltip}>
              <Icon type="tooltip" className={`${cn}__tab-tooltip`} />
            </Tooltip>
          )}
        </button>
      ))}
    </div>
  )
}

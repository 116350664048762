import { makeAssert, isPlainObject, includes } from './typeguards'

type Primitive = 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function'

type ValueType = 'literal' | ObjectShape | Primitive | Primitive[]

type SharedConfig = { optional?: boolean; array?: boolean }

type Config = SharedConfig & {
  type: Exclude<ValueType, 'literal'>
}

type StringLiteralConfig = SharedConfig & {
  type: 'literal'
  values: string[]
}

type FieldConfig = Config | StringLiteralConfig

export type ObjectShape = {
  [key: string]: FieldConfig
}

const checkValue = <T>(value: unknown, config: FieldConfig): value is T => {
  const { type } = config
  if (isPlainObject(value)) {
    if (!isPlainObject(type)) return false
    if (!isObjectOfType(value, type)) return false
  } else if (Array.isArray(type)) {
    if (!includes(type, typeof value)) return false
  } else if (config.type === 'literal') {
    if (typeof value !== 'string' || !config.values.includes(value)) return false
  } else if (type !== typeof value) {
    return false
  }

  return true
}

export const isObjectOfType = <T>(obj: unknown, shape: ObjectShape): obj is T => {
  if (!isPlainObject(obj)) {
    return false
  }

  for (const [key, fieldConfig] of Object.entries(shape)) {
    const { optional, array } = fieldConfig

    const value = obj[key]

    if (optional && value == null) continue

    if (array) {
      if (!Array.isArray(value)) return false
      if (!value.every((v) => checkValue(v, fieldConfig))) return false
    } else if (!checkValue(value, fieldConfig)) {
      return false
    }
  }

  return true
}

export const assertObjectOfType: <T>(value: unknown, shape: ObjectShape, message: string) => asserts value is T = (
  value,
  shape,
  message,
) => makeAssert(<T>(val: unknown): val is T => isObjectOfType<T>(val, shape))(value, message)

import React, { Component, SyntheticEvent } from 'react'

import { connect } from 'react-redux'

import { Form } from 'antd'
import { Button, TextInput } from 'components/design-system'
import owasp from 'common/owasp'
import { isLongEnoughPassword } from 'common/utils/authenticationHelpers'

import { confirmResetPassword } from '../modules/confirmResetPassword'
import ErrorDisplay from './../components/ErrorDisplay'

const FormItem = Form.Item

interface ConfirmResetPasswordFormProps {
  location: any // <-- improve type after refactoring `location.ts & upgrading react-router`
  confirmResetPassword: any // <-- improve type after refactoring `login.js`
}

interface ConfirmResetPasswordFormState {
  email: string
  errors: Array<string>
  isPasswordValid: boolean
  newPassword: string
  newPasswordConfirm: string
  token: string
}

class ConfirmResetPasswordForm extends Component<ConfirmResetPasswordFormProps, ConfirmResetPasswordFormState> {
  constructor(props: ConfirmResetPasswordFormProps) {
    super(props)
    const email = props.location.query.email
    const token = props.location.query.token
    this.state = {
      email: email ? email : '',
      errors: [],
      newPassword: '',
      token: token ? token : '',
      newPasswordConfirm: '',
      isPasswordValid: false,
    }
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value,
    })
  }

  handleTokenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      token: event.target.value,
    })
  }

  handleNewpasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      errors: owasp.test(event.target).errors,
      newPassword: event.target.value,
    })
  }

  handleNewPassswordConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      newPasswordConfirm: event.target.value,
    })
  }

  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault()

    const { confirmResetPassword } = this.props

    confirmResetPassword(this.state.email, this.state.token, this.state.newPassword, this.state.newPasswordConfirm)
  }

  render() {
    const { email, errors, newPassword, newPasswordConfirm, token } = this.state

    const passwordsMatch = newPassword === newPasswordConfirm
    const passwordLengthGood = isLongEnoughPassword(newPassword)

    const formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    }

    return (
      <Form className="login-form" layout="vertical">
        <div className="mb12">
          <FormItem {...formItemLayout} label="Email">
            <TextInput
              placeholder="Email"
              defaultValue={email}
              className={!email ? 'red-border' : ''}
              onChange={this.handleEmailChange}
            />
          </FormItem>
          <FormItem {...formItemLayout} label="Token">
            <TextInput
              placeholder="Token"
              className={!token ? 'red-border' : ''}
              defaultValue={token}
              onChange={this.handleTokenChange}
            />
          </FormItem>
          <FormItem {...formItemLayout} label="New password">
            <TextInput
              placeholder="New Password"
              type="Password"
              className={!newPassword ? 'red-border' : ''}
              onChange={this.handleNewpasswordChange}
            />
          </FormItem>
          <FormItem {...formItemLayout} label="Confirm new password">
            <TextInput
              placeholder="Confirm New Password"
              type="Password"
              className={!newPasswordConfirm || !passwordsMatch ? 'red-border' : ''}
              onChange={this.handleNewPassswordConfirmChange}
            />
          </FormItem>
        </div>
        <Button
          type="primary"
          htmlType="submit"
          className="auth-button"
          disabled={!email || !token || !newPassword || !passwordsMatch || !passwordLengthGood}
          onClick={this.handleSubmit}
          block
        >
          Change Password
        </Button>
        <ErrorDisplay
          errors={errors}
          passwordsMatch={passwordsMatch}
          passwordGoodLength={newPassword.length === 0 || passwordLengthGood}
        />
      </Form>
    )
  }
}

const mapDispatchToProps = {
  confirmResetPassword,
}

export default connect(null, mapDispatchToProps)(ConfirmResetPasswordForm)

// Valid javascript identifiers
export const VALID_JS_IDENTIFIER_REGEX = /^[$A-Z_][0-9A-Z_$]*$/i

export const ANY_TEMPLATE_REGEX = /{{([\s\S]+?}?)}}/g

export const FIRST_TEMPLATE_REGEX = /{{([\s\S]+?}?)}}/

export const SEMVER_REGEX = /^(\d+)\.(\d+)\.(\d+)$/g

export const JSON_LIKE_REGEX = /^{[^{]([\s\S]+?}?)}$/g
export const ARRAY_LIKE_REGEX = /^\[([\s\S]+?}?)\]$/g

export const OBJECT_REGEX = /^{{([\s\S]+?)}}$/g

export const URL_REGEX = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/

export const isSingleObjectString = (v: string) => {
  const templateMatches = v.match(ANY_TEMPLATE_REGEX)
  if (templateMatches) {
    if (templateMatches.length > 1) {
      return false
    }
  }

  return v.match(OBJECT_REGEX)
}

import React, { useState } from 'react'
import { browserHistory } from 'react-router'

import AuthRetoolLogo from '../components/AuthRetoolLogo'
import { SafeAny } from 'common/types'
import cookies from 'js-cookie'
import { REDIRECTING_TO_BILLING_PORTAL_COOKIE_NAME } from 'store/constants'
import { resourceStyles } from 'retoolConstants'

export interface SignupPageProps {
  emphasizeGoogleLogin: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invite: SafeAny // <-- either 'null' or an immutable map. //TODO: @Karen will add stronger type after removing immutable
  showSignupForm: boolean
  signupFooter: React.ReactNode
  signupForm: React.ReactNode
  signupIntegration?: string
}

type SignupHeaderProps = {
  invite: SafeAny
  showSignupForm: boolean
  signupIntegration?: string
}

const InnerSignupHeader = (props: SignupHeaderProps) => {
  const { invite, showSignupForm } = props
  const redirectingToBilling = cookies.get(REDIRECTING_TO_BILLING_PORTAL_COOKIE_NAME) != null

  if (invite) {
    return <div className="auth-subheading">Your email {invite.get('email')} was invited.</div>
  } else if (redirectingToBilling) {
    return (
      <div className="auth-subheading">
        Already have a cloud account?{' '}
        <a target="_blank" rel="noopener" onClick={() => browserHistory.push('/auth/login')}>
          Sign in
        </a>{' '}
        instead.
      </div>
    )
  } else if (!invite && showSignupForm) {
    return (
      <div className="auth-subheading">
        Is your team already on Retool?&nbsp;
        <a target="_blank" rel="noopener" onClick={() => browserHistory.push('/auth/login')}>
          Sign in
        </a>
        &nbsp;or check your email for an invite.
      </div>
    )
  } else {
    return <></>
  }
}

const SignupHeader = (props: SignupHeaderProps) => {
  const { invite, showSignupForm, signupIntegration } = props
  const redirectingToBilling = cookies.get(REDIRECTING_TO_BILLING_PORTAL_COOKIE_NAME) != null

  let headingText = <span> Get started with Retool </span>
  if (signupIntegration && signupIntegration in resourceStyles) {
    headingText = (
      <span> Build a UI on top of {resourceStyles[signupIntegration as keyof typeof resourceStyles].label}</span>
    )
  }

  if (invite) {
    const invitedByFirstName = invite.get('invitedBy').get('firstName')
    headingText = <span> {invitedByFirstName} invited you to make an account! </span>
  } else if (redirectingToBilling) {
    headingText = (
      <span>
        {' '}
        Create a cloud account to manage your <br /> on-prem billing{' '}
      </span>
    )
  }

  return (
    <>
      <AuthRetoolLogo className="mb20" signupIntegration={signupIntegration} />
      <h6 className="mb4">{headingText}</h6>
      <InnerSignupHeader showSignupForm={showSignupForm} invite={invite} />
    </>
  )
}

const DefaultSignupPage = (props: SignupPageProps) => {
  const [emailFormVisible] = useState(true)

  const { invite, emphasizeGoogleLogin, signupForm, signupFooter, showSignupForm, signupIntegration } = props

  return (
    <div id="signup-page">
      <div className="body-inner">
        <div className="auth-container">
          <div className="auth-form-container">
            <div style={emphasizeGoogleLogin && !emailFormVisible ? { height: 460, position: 'relative' } : {}}>
              <SignupHeader invite={invite} showSignupForm={showSignupForm} signupIntegration={signupIntegration} />
              {signupForm}
              {signupFooter}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DefaultSignupPage

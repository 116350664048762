import sqlFormatter from 'sql-formatter'
import { FIRST_TEMPLATE_REGEX } from 'common/regexes'

const re = new RegExp(FIRST_TEMPLATE_REGEX, 'g')

const formatSql = (sqlString: string) =>
  sqlFormatter
    .format(sqlString)
    .replace(/\~ \*/g, '~*') // postgres regex
    .replace(/\! \~/g, '!~') // postgres regex

export const formatSqlWithTemplate = (sqlString: string) => {
  // formats a SQL string containing templates
  const match = sqlString.match(re)
  if (match == null) {
    // do nothing special if there are no templates.
    return formatSql(sqlString)
  }

  // if there are templates:
  // 1. extract all templates via regex (above)
  // 2. replace each with the sql question mark parameter
  // 3. send the string to sql-formatter
  // 4. return the templates back into the string
  sqlString = formatSql(sqlString.replace(re, '?'))
  Array.from(match).forEach((template) => {
    sqlString = sqlString.replace('?', template)
  })

  return sqlString
}

import canvasConfetti from 'canvas-confetti'
import { PluginMethodConfig } from 'components/plugins'

const confetti: PluginMethodConfig = {
  metadata: {
    example: 'utils.confetti()',
    label: 'Confetti',
    description: 'Fire confetti 🎉',
  },
  method: () => canvasConfetti({ spread: 100, particleCount: 150 }),
}

export default confetti

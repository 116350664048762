/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { evaluate, evaluateBatchJob, evaluateSafe, evaluateSimple } from 'common/evaluator'
import {
  AppModelType,
  AppTemplate,
  IDependencyGraph,
  PluginModel,
  PluginNamespaceInfo,
  PluginSubtype,
  TableWidgetModelShape,
} from 'common/records'
import { hasErrorDeep, Selector, ss, unflatten } from 'common/utils'
import { TypedMap } from 'common/utils/immutable'
import { PluginPropertyAnnotationsResolver } from 'components/plugins'
import Immutable from 'immutable'
import _ from 'lodash'
import queryString from 'query-string'
import { RetoolState } from 'store'
import { appTemplateSelector, pageLoadValueOverridesObjectSelector } from 'store/selectors'
import { isPlainObject } from 'types/typeguards'
import getNamespaceAwareModel from './getNamespaceAwareModel'
import { canUseSimpleEvaluation } from '../../common/evaluator'

type GetFinalValueType = (model: any, value: any) => any
type StageModel = (model: AppModelType) => void

const RETOOL_CONTROLLED_PROPERTY = '__RETOOL_CONTROLLED_PROPERTY__'

type RenderTemplateStringModifiers = {
  getCurrentModel: (getState: () => RetoolState) => AppModelType
  stageModel?: StageModel
  getState: () => RetoolState
  model?: AppModelType
  dependencyGraph?: any
  stageModelAfterEvaluation?: boolean
  namespace?: PluginNamespaceInfo
}

export async function renderTemplateString(
  selector: Selector,
  templateString: string | null | undefined,
  stackId: number,
  templateArgs: RenderTemplateStringModifiers,
): Promise<AppModelType> {
  const {
    model = null,
    dependencyGraph = null,
    stageModelAfterEvaluation = false,
    namespace = undefined,
    getCurrentModel,
    stageModel,
    getState,
  } = templateArgs

  const _model = () => model || getCurrentModel(getState)
  if (
    selector.every((x) => x !== 'queryDisabled') &&
    (_model().hasDirtyAncestors(selector) || _model().dirty.has(ss(selector)))
  ) {
    // Skip!
    return _model()
  }

  const hasPreviousValue = _model().values.hasIn(selector)

  if (templateString === RETOOL_CONTROLLED_PROPERTY) {
    templateString = ''
  }

  if (templateString === undefined) {
    return _model()
  }

  const maybePluginId = selector[0]
  const pluginOrMap = _model().values.hasPlugin(maybePluginId)
    ? _model().values.getPlugin(maybePluginId)
    : (Immutable.Map() as PluginModel)
  const pluginType = pluginOrMap.get('pluginType')

  let dirtyNodes: any = []

  let getFinalValue = (model: any, value: any) => {
    const pageLoadValueOverrides = pageLoadValueOverridesObjectSelector(getState())
    if (!hasPreviousValue) {
      resetSelectorTemplateString(_model().dependencyGraph, selector, appTemplate, pageLoadValueOverrides)
      if (dependencyGraph) {
        resetSelectorTemplateString(dependencyGraph, selector, appTemplate, pageLoadValueOverrides)
      }
    }
    return value
  }
  if (pluginType) {
    const propertyAnnotation = PluginPropertyAnnotationsResolver(pluginType)[selector[1]]
    if (propertyAnnotation) {
      if (propertyAnnotation.getValue) {
        getFinalValue = (model, value) => {
          if (!hasPreviousValue) {
            const pageLoadValueOverrides = pageLoadValueOverridesObjectSelector(getState())
            resetSelectorTemplateString(_model().dependencyGraph, selector, appTemplate, pageLoadValueOverrides)
            if (dependencyGraph) {
              resetSelectorTemplateString(dependencyGraph, selector, appTemplate, pageLoadValueOverrides)
            }
          }
          const stack = _model().executionCallStacks.getStack(stackId)
          if (stack == null) {
            throw new Error(`Tried to get the stack with id ${stackId} but it was null`)
          }
          return propertyAnnotation.getValue!(model, value, selector, stack)
        }
      }
    }
  }

  const updatesAsync = _model().dependencyGraph.lookupUpdatesAsync(selector)
  if (updatesAsync && updatesAsync.length > 0) {
    dirtyNodes = updatesAsync.map((p: any) => `${maybePluginId}.${p}`)
  }

  const rootId = selector[0]

  const dependencies = _model().dependencyGraph.getDirectDependenciesOf(selector)

  const rootDependencyIds = dependencies.map((d: any) => d.selector[0]).toArray()
  const preNamespaceModelJS = _model().get('cachedJSValues')
    ? _.pick(_model().get('cachedJSValues'), rootDependencyIds)
    : _model()
        .get('values')
        .filter((v: any, k: string) => rootDependencyIds.includes(k))
        .toJS()

  const modelJS = getNamespaceAwareModel(preNamespaceModelJS, namespace, pluginType as PluginSubtype)

  if (rootId === 'urlFragments') {
    let value = ''
    try {
      value = await evaluate(modelJS, templateString, { i: 0 })
    } catch (err) {
      // Do nothing
    }
    const query = queryString.parse(window.location.hash)
    query[selector[1]] = value

    window.location.hash = queryString.stringify(query)
    return _model()
  }

  // Handle custom document titles
  if (rootId === 'customDocumentTitle') {
    let value
    try {
      value = await evaluate(modelJS, templateString)
    } catch (err) {
      // Do nothing - don't update the document title
    }
    if (value) {
      document.title = value
    }
    return _model()
  }

  const { instances } = _model().pluginInListView(rootId)
  const isTablePlugin =
    _model().values.hasPlugin(maybePluginId) &&
    _model().values.getPlugin(maybePluginId).get('pluginType') === 'TableWidget'
  // TODO: ideally these special case "mapped" nodes would be property annotations
  // on the table plugin, rather than listed out here

  // Selector keywords that indicate we need table mapping
  const actionButtonTableMappedKeywords = ['actionButtonText', 'actionButtonDisabled']

  // NOTE: table mapped keywords need to be referenced here [1] in
  // order to be evaluated after the table data is loaded.
  // [1] https://github.com/tryretool/retool_development/blob/dev/frontend/src/components/plugins/widgets/Table/index.tsx#L313
  const columnTableMappedKeywords = ['columnMappers', 'columnColors', 'columnRestrictedEditing']
  const buttonTableMappedKeywords = [
    'buttonDisabled',
    'internalUrlQuery',
    'internalUrlHashParams',
    'url',
    'valueToCopy',
  ]
  const dropdownTableMappedKeywords = ['dropdownLabels', 'dropdownValues']
  const checkboxTableMappedKeywords = ['disabled']

  let isTableMappedNode = false
  if (isTablePlugin) {
    // handle action buttons
    // e.g. ["table1", "actionButtons", 0, “actionButtonText"]
    if (actionButtonTableMappedKeywords.includes(selector[3])) {
      isTableMappedNode = true
      // handle column specific mappings
    } else if (columnTableMappedKeywords.includes(selector[1])) {
      isTableMappedNode = true
      // handle button column types
      // e.g. ["table1", “columnTypeSpecificExtras", "testMe", "buttonDisabled"]
    } else if (selector[1] === 'columnTypeSpecificExtras' && buttonTableMappedKeywords.includes(selector[3])) {
      isTableMappedNode = true
      // handle dropdown column tyes
      // e.g. ["table1", "columnTypeSpecificExtras", "testMe", "dropdownLabels"]
    } else if (selector[1] === 'columnTypeSpecificExtras' && dropdownTableMappedKeywords.includes(selector[3])) {
      isTableMappedNode = true
      // handle checkbox column types
      // e.g. ["table1", "columnTypeSpecificExtras", "testMe", "disabled"]
    } else if (selector[1] === 'columnTypeSpecificExtras' && checkboxTableMappedKeywords.includes(selector[3])) {
      isTableMappedNode = true
    }
  }

  const appTemplate = appTemplateSelector(getState())
  const pluginTemplate = appTemplate.plugins.getIn([maybePluginId, 'template'])
  const isImportedPlugin = pluginTemplate && pluginTemplate.get('isImported')

  // Keep track of changedSelectors correctly
  const stack = _model().executionCallStacks.getStack(stackId)
  if (instances != null && stack !== null) {
    _.range(instances).forEach((i) => {
      stack.changedSelectors.push([selector[0], String(i)].concat(selector.slice(1)))
    })
  } else if (stack !== null) {
    stack.changedSelectors.push(selector)
  }

  if (instances != null && stack !== null) {
    const triggeredBySelectors = stack.triggers.map((s) => s.selector)
    return await renderListViewPlugin(
      templateString,
      modelJS,
      instances,
      getFinalValue,
      _model,
      maybePluginId,
      rootId,
      selector,
      dirtyNodes,
      stageModelAfterEvaluation,
      triggeredBySelectors,
      stageModel,
    )
  } else if (isTableMappedNode) {
    return await renderTableMappedNode(
      templateString,
      modelJS,
      getFinalValue,
      _model,
      maybePluginId,
      rootId,
      selector,
      dirtyNodes,
      stageModelAfterEvaluation,
      stageModel,
    )
  } else if (isImportedPlugin) {
    let newValue = ''
    let error = null
    try {
      // TODO: hacked the types here to compile, but this should be addressed
      const importedQueryInputsModel = pluginOrMap.get('importedQueryInputs' as any) as Immutable.Map<string, any>
      const inputParams = importedQueryInputsModel && importedQueryInputsModel.toJS()

      const unflattenedInputParams = unflatten(inputParams)
      newValue = getFinalValue(
        pluginOrMap,
        await evaluate({ ...unflattenedInputParams, ...modelJS }, templateString, { i: 0 }),
      )
    } catch (e) {
      error = isFirstError(_model(), selector) ? e.message : null
    }

    const newModel = _model().setValue(selector, newValue).setError(selector, error).addDirtyNodes(dirtyNodes)
    if (stageModelAfterEvaluation) stageModel?.(newModel)
    return newModel
  } else {
    try {
      let newValue

      // Special case to handle Select/MultiSelect plugin with html renderer since we require {{ i }} additionalParam
      const isSelectPluginRendererHtml =
        pluginTemplate &&
        pluginTemplate.get('enableHtmlRenderer') &&
        (pluginType === 'SelectWidget' || pluginType === 'MultiSelectWidget') &&
        selector &&
        selector[selector.length - 1] === 'rendererHtml'

      if (isSelectPluginRendererHtml) {
        newValue = await getSelectPluginRendererHtmlValue(_model, getFinalValue, templateString, maybePluginId, modelJS)
      } else {
        const evaluatedTemplateString = canUseSimpleEvaluation(templateString)
          ? evaluateSimple(templateString)
          : await evaluate(modelJS, templateString, { i: 0 })
        newValue = getFinalValue(pluginOrMap, evaluatedTemplateString)
      }

      const newModel = _model().setValue(selector, newValue).setError(selector, null).addDirtyNodes(dirtyNodes)
      if (stageModelAfterEvaluation) stageModel?.(newModel)
      return newModel
    } catch (e) {
      const newModel = _model()
        .setValue(selector, '')
        .setError(selector, isFirstError(_model(), selector) ? e.message : null)
        .addDirtyNodes(dirtyNodes)
      if (stageModelAfterEvaluation) stageModel?.(newModel)
      return newModel
    }
  }
}

/**
 * Evaluates the templateString and updates the appModel
 * accordingly. note that this is a special case because
 * the actionButtons template is mapped, i.e. {{ 'foo' }}
 * doesn't mean "foo", it means ['foo', 'foo', ...]
 */
async function renderTableMappedNode(
  templateString: string | null | undefined,
  modelJS: _.PartialDeep<Object>,
  getFinalValue: GetFinalValueType,
  _model: () => AppModelType,
  pluginId: string,
  rootId: string,
  selector: string[],
  dirtyNodes: any[],
  stageModelAfterEvaluation: boolean,
  stageModel?: StageModel,
) {
  const tablePlugin: TypedMap<TableWidgetModelShape> = _model().values.getPlugin(pluginId)

  // Here we use the normalizedData instead of the Table's data property
  // since it's more consistent (it's always an array, and in the forward
  // cursor based pagination case it contains the full data).
  let tableDataArray = tablePlugin.get('normalizedData') || []
  // if tableDataArray is not a valid array, then assume it's a []
  // but there's no guarantee since it's user input
  if (!_.isArray(tableDataArray)) {
    tableDataArray = []
  }

  // for selectors that look like ['table1', 'columnTypeSpecificExtras', 'myColumnName', 'foo']
  // or ['table1', 'columnMappers', 'myColumnName']
  // we want to populate `self` to be the cell's value
  const otherColumns = ['columnTypeSpecificExtras', 'columnColors', 'columnMappers', 'columnRestrictedEditing']

  let columnName: string | null = null
  if (otherColumns.includes(selector[1])) {
    columnName = selector[2]
  }

  let values = []

  if (tableDataArray.length > 0) {
    // evaluate the template
    const batchJobResults = await evaluateBatchJob({
      code: templateString,
      commonScope: modelJS,
      states: tableDataArray.map((currentRow: unknown, i: number) => ({
        currentRow,
        i,
        self: columnName && isPlainObject(currentRow) ? currentRow[columnName] : undefined,
      })),
    })

    values = batchJobResults.map((newValue: any, i: any) => {
      const oldValue = _model()
        .values.getPlugin(pluginId)
        .getIn(selector.slice(1).concat([i]))
      return getFinalValue(oldValue, newValue)
    })
  } else {
    // If the table is empty, the mapped values will only impact the new row.
    // For the new row, we don't have any additional scope
    /*
      NOTE: we have to use evaluateSafe here to ensure that we properly handle
            variables like currentRow and self not evaluating when there
            is no data in the table.
    */
    const valueForNewRow = await evaluateSafe(modelJS, templateString)
    if (valueForNewRow) {
      values.push(valueForNewRow)
    }
  }

  // now update the model with the results
  let newModel = _model()
  newModel = newModel.setValue(selector, values)
  newModel = newModel.nonRecursiveSetError(selector, null).addDirtyNodes(dirtyNodes)

  if (stageModelAfterEvaluation) stageModel?.(newModel)
  return newModel
}

/**
 * Evaluates the templateString and updates the appModel
 * accordingly. note that this is a special case because
 * templates in ListViews mapped, i.e. {{ 'foo' }}
 * doesn't mean "foo", it means ['foo', 'foo', ...].
 *
 * This is a slightly complex function but the logic goes as follows:
 * > Is the rowKeys property set on the listview?
 *
 *    > Yes - rowKeys are set
 *      > Is this selector updating ONLY because of a change in listview.instances?
 *
 *          > Yes - listview.instances is the only thing causing this to change.
 *            * Keep any old values that should remain in the new render by looking at rowKey and evaluate the rest. Here's an example:
 *                Imagine the data for our previous render looked like this: [{rowKey: "rowDmitriy", value:"dmitriy"}. {rowKey: "rowChristina" value: "christina"}, {rowKey: "rowYogi", value: "yogi"}]
 *                We know the new rowKeys are: ["rowChristina", "rowJoseph", "rowYogi"].
 *                This means we keep the old values and slot them into their new place: [{rowKey: "rowChristina" value: "christina"}, undefined, {rowKey: "rowYogi", value: "yogi"}]
 *                And then we evaluate the template for i = 1 to fill the empty space at index 1.
 *
 *          > No - something else is also causing it to update.
 *            * The template is evaluated from scratch for each index to make sure we are picking up the new value of our other dependency.
 *
 *    > No - rowKeys are not set
 *      * The template is evaluated from scratch for each index. This means old values are overwritten (i.e. components that are not connected are reset to their defaults)
 *
 */
async function renderListViewPlugin(
  templateString: string | null | undefined,
  modelJS: _.PartialDeep<Object>,
  instances: any,
  getFinalValue: GetFinalValueType,
  _model: () => AppModelType,
  pluginId: string,
  rootId: string,
  selector: string[],
  dirtyNodes: any[],
  stageModelAfterEvaluation: boolean,
  triggeredBySelectors: Selector[],
  stageModel?: StageModel,
) {
  const listViewParentId = _model().dependencyGraph.getAncestorListViewSelector(selector)! // we're in a listview - this can't be null
  const newRowKeys = getRowKeys(_model, listViewParentId)
  const previousRowKeys = getPreviousRowKeys(_model, listViewParentId, instances)

  let batchJobResults = []

  if (shouldArrangeListViewValuesByRowKey(previousRowKeys, newRowKeys)) {
    batchJobResults = await arrangeValuesByRowKey(
      _model,
      templateString,
      modelJS,
      triggeredBySelectors,
      instances,
      selector,
      listViewParentId,
      pluginId,
      previousRowKeys,
      newRowKeys,
    )
  } else {
    batchJobResults = await evaluateBatchJob({
      code: templateString,
      commonScope: modelJS,
      states: _.range(instances).map((i) => ({ i })),
    })
  }

  const values = getFinalValues(instances, _model, getFinalValue, pluginId, batchJobResults)
  let newModel = _model()
  for (let i = 0; i < instances; i++) {
    newModel = newModel.setValue([rootId, i].concat(selector.slice(1)), values[i])
  }
  newModel = newModel.nonRecursiveSetError(selector, null).addDirtyNodes(dirtyNodes)
  if (stageModelAfterEvaluation) stageModel?.(newModel)
  return newModel
}

async function arrangeValuesByRowKey(
  _model: () => AppModelType,
  templateString: string | null | undefined,
  modelJS: _.PartialDeep<Object>,
  triggeredBySelectors: Selector[],
  instances: number,
  selector: Selector,
  listViewParentId: string,
  pluginId: string,
  previousRowKeys: string[],
  newRowKeys: string[],
) {
  const start = performance.now()
  const isReachableWithoutListviewInstances =
    triggeredBySelectors.length > 0
      ? _model().dependencyGraph.isReachableWithout(triggeredBySelectors[0], selector, [listViewParentId, 'instances'])
      : true
  const end = performance.now()
  // eslint-disable-next-line no-console
  console.log('Triggered instances took', end - start)

  // This node has other dependencies that caused it to trigger - do not save any old values and evaluate the template.
  if (isReachableWithoutListviewInstances) {
    return await evaluateBatchJob({
      code: templateString,
      commonScope: modelJS,
      states: _.range(instances).map((i) => ({ i })),
    })
  }

  const listViewData = (_model().values.getPlugin(listViewParentId) as any).get('data')
  const previousDataLength = listViewData ? listViewData.length : 0

  const oldValues = _.range(Math.min(previousDataLength, instances)).map((i) =>
    _model()
      .values.getPlugin(pluginId)
      .getIn(([i] as Array<string | number>).concat(selector.slice(1))),
  )

  const rowKeyToNewIndex = newRowKeys.reduce(
    (a: { [key: string]: number }, b: string, i: number) => ((a[b] = i), a),
    {},
  )

  const newOrder = new Array(instances).fill(undefined)

  // Go through the old values and see if any of their rowKeys are in the new rowKeys
  // if so, put them in the correct position
  oldValues.forEach((val, i) => {
    const previousRowKey = previousRowKeys[i]
    const newIndex = rowKeyToNewIndex[previousRowKey]
    if (newIndex !== null && newIndex !== undefined) {
      newOrder[newIndex] = val
    }
  })

  // Find which ever ones are blank (we have to compute new values for those indexes)
  const blankIndexes: number[] = []
  newOrder.forEach((val, idx) => {
    if (val === undefined) {
      blankIndexes.push(idx)
    }
  })

  const newResults = await evaluateBatchJob({
    code: templateString,
    commonScope: modelJS,
    states: blankIndexes.map((i) => ({ i })),
  })

  // Fill in with new values
  blankIndexes.forEach((newIdx, i) => {
    newOrder[newIdx] = newResults[i]
  })

  return newOrder
}

/**
 * If rowKeys are filled out and the previous render also had row keys, we should make sure
 * we are rendering the listview children by rowKey rather than by index.
 */
function shouldArrangeListViewValuesByRowKey(previousRowKeys: string[], newRowKeys: string[]) {
  return previousRowKeys && previousRowKeys.length > 0 && newRowKeys && newRowKeys.length > 0
}

/**
 * Get the current array of rowKeys for the new render
 */
function getRowKeys(model: () => AppModelType, listViewId: string): string[] {
  return model().values.getUnsafe(listViewId).get('rowKeys')
}

/**
 * Get the array of rowKeys for the previous render.
 *
 * Note: this is a hacky solution! We are taking advantage of the implicit fact that listview.data
 * gets updated after the model re-renders. This could change and this would no longer work.
 * We need to think of a longer term solution on where to store previous rowKeys.
 */
function getPreviousRowKeys(model: () => AppModelType, listViewId: string, instances: number) {
  const previousData = model()
    .values.getPlugin(listViewId)
    .get('data' as any)
  const previousDataLength = previousData ? previousData.length : 0
  const rangeToCalculate = Math.min(instances, previousDataLength)
  return _.range(rangeToCalculate).map((i) =>
    model()
      .values.getPlugin(listViewId)
      .getIn((['data', i] as Array<string | number>).concat(['retoolInternal_rowKey'])),
  )
}

function resetSelectorTemplateString(
  dependencyGraph: IDependencyGraph,
  selector: any,
  appTemplate: AppTemplate,
  pageLoadValueOverrides: { [key: string]: unknown },
) {
  if (pageLoadValueOverrides[ss(selector)]) {
    const pluginId = selector[0]
    const property = selector[1]
    const originalPlugin = appTemplate.plugins.get(pluginId)
    if (originalPlugin) {
      dependencyGraph.updatePlugin(originalPlugin, Immutable.Map({ [property]: originalPlugin.template.get(property) }))
    }
  }
}

async function getSelectPluginRendererHtmlValue(
  _model: () => AppModelType,
  getFinalValue: GetFinalValueType,
  templateString: string | undefined | null,
  pluginId: string,
  modelJS: _.PartialDeep<Object>,
) {
  const pluginModel =
    _model().values.hasPlugin(pluginId) &&
    ((_model().values.getPlugin(pluginId) as unknown) as Immutable.Map<string, string[]>)
  const selectWidgetValues = pluginModel ? (pluginModel.get('values') as string[]) : []

  const batchJobResults = await evaluateBatchJob({
    code: templateString,
    commonScope: modelJS,
    states: _.range(selectWidgetValues.length).map((i) => ({ i })),
  })

  const newValue = getFinalValues(batchJobResults.length, _model, getFinalValue, pluginId, batchJobResults)
  return newValue.reduce((acc, val, i) => ({ ...acc, [i]: val }), {})
}

function getFinalValues(
  instances: number,
  _model: () => AppModelType,
  getFinalValue: GetFinalValueType,
  pluginId: string,
  batchJobResults: any[],
): any[] {
  const values = []
  for (let i = 0; i < instances; i++) {
    try {
      const newValue = getFinalValue(
        (_model().values.getPlugin(pluginId) as Immutable.Map<string | number, any>).get(i),

        batchJobResults[i],
      )
      values.push(newValue)
    } catch (err) {
      const newValue = ''
      values.push(newValue)
    }
  }
  return values
}

function isFirstError(model: AppModelType, selector: Selector) {
  const dependencies = model.dependencyGraph.getDependenciesOf(selector)
  const errors = model.errors
  return !_.some(
    dependencies
      .map(({ selector }: any) => {
        const error = errors.getIn(selector)
        if (!error || typeof error === 'string') return error
        return hasErrorDeep(error.valueSeq().toJS())
      })
      .toArray(),
  )
}

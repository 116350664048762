import { message } from 'antd'
import Blob from 'blob'
import { evaluateWithCallbacks } from 'common/evaluator'
import { JSON_HEADERS } from 'networking/util'
import sizeof from 'object-sizeof'
import {
  AppTemplate,
  DefinitionUpdate,
  GenericErrorResponse,
  GlobalWidgetInputType,
  NestedGlobalWidgets,
  PluginSubtype,
  PluginTemplate,
  Position2,
  Position2Params,
  Resource,
  Test,
  TestEntity,
} from 'common/records'
import { initSandbox } from 'common/sandbox'

import { primitiveTypeToAnalyticsType } from 'common/utils'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { Message } from 'components/design-system'
import {
  DefaultWidgetTemplate,
  loadPlugin,
  MigrationsResolver,
  PluginPropertyAnnotationsResolver,
  TemplateCodeCustomAliasesResolver,
  TemplateResolver,
} from 'components/plugins'
import { GLOBAL_WIDGET_GRID } from 'components/plugins/widgets/GlobalWidget/constants'
import { SPECIAL_NEW_WIDGET_CONSTANT, WidgetMove } from 'components/plugins/widgets/layoutManager'
import { showSuccessToaster } from 'components/toasters'
import { DepGraph } from 'dependency-graph'
import { saveAs } from 'file-saver'
import * as Immutable from 'immutable'
import { OrderedMap } from 'immutable'
import _, { flatten, keyBy } from 'lodash'
import { isNotNullish } from 'types/typeguards'

import queryString from 'query-string'
import undoable, { ActionCreators as reduxUndoActionCreators } from 'redux-undo'
import { GLOBAL_WIDGET_TYPE } from 'retoolConstants'
import semverCmp from 'semver-compare'
import { extendDefaultShortcuts } from 'shortcutsKeymap'
import { dispatch, RetoolActionDispatcher, RetoolDispatch, RetoolState, RetoolThunk } from 'store'
import {
  createModuleOutputCreationActions,
  dispatchModuleOutputCreationActions,
  getChildPage,
  getGlobalOutputs,
  getGlobalWidgetsFromTemplate,
  getPluginsForGlobalWidget,
  globalWidgetCreateHelper,
  initializeGlobalWidgetProps,
  storeNestedModules,
} from 'store/appModel/namespaceHelpers'
import { RETOOL_DEFAULT_THEME_ID } from 'components/plugins/widgets/style/constants'
import { defaultAppThemeIdSelector } from 'components/plugins/widgets/style/selectors'
import { callApi } from 'store/callApi'
import {
  appModelJSValuesSelector,
  appModelSelector,
  appTemplateSelector,
  currentPageUuidSelector,
  customRetoolSandboxRestrictionsSelector,
  isTemplateGlobalWidgetSelector,
  largeScreenSelector,
  localPluginsSelector,
  preloadedAppJavaScriptSelector,
  preloadedAppJSLinksSelector,
  preloadedOrgJavaScriptSelector,
  preloadedOrgJSLinksSelector,
  selectedDatasourceSelector,
} from 'store/selectors'
import { nestedModulesPreloadedJSSelector, preloadedModulesJSLinksSelector } from 'store/selectors/modulesSelector'

import { calculateNewTemplate, initializeFromTemplate, recalculatePostWidgetMove, recalculateTemplate } from './model'
import { rerenderModuleSelectors } from './preloadedJS'
import { FAILURE_SAVE, MODEL_BROWSER_OPEN, RECEIVE_SAVE, TEARDOWN_PAGE } from './actionTypes'
import { DATASOURCE_TYPE_CHANGE, PLUGIN_DELETE, PLUGIN_UPDATE_ID, RESET_TEMPLATE } from './constants'

import {
  BATCH_WIDGET_TEMPLATE_CREATE,
  BATCH_WIDGET_TEMPLATE_UPDATE,
  debouncedSendSave,
  deserializeSave,
  getAppComplexity,
  sendSave,
  WIDGET_TEMPLATE_UPDATE,
  widgetTemplateUpdate,
} from 'store/appModel/templateUtils'
import widgetTypeIsContainer from 'components/plugins/widgets/common/layoutUtils/widgetTypeIsContainer'
import { positionKeySelector, widgetsSelector } from 'store/selectors/widgetSelectors'
import { getNewGlobalWidgetId, getNewPluginId, getNewWidgetId } from 'common/utils/pluginIds'
import { migrateAppTemplate } from './migrateAppTemplate'
import { assertValidWidgetParent } from 'components/plugins/widgets/common/layoutUtils/isWidgetParentValid'
import { recentQuerySelector } from 'store/selectors/querySelectors'
import { defaultOverridesForNewQueries } from 'components/plugins/datasources/modelConstants'
import { batchUndoGroupBy, startBatchUndoGroup, stopBatchUndoGroup } from './batchUndoGroupBy'
import { generateJsApi } from './jsApi'
import datadogReporter from 'DatadogReporter'
import {
  handleAddTestEntity,
  handleDeleteTestEntity,
  handleMarkTestsPending,
  handleMoveTestEntity,
  handleMoveTestEntityInSuite,
  handleUpdateTestEntity,
} from 'store/appModel/application-tests/applicationTestReducers'

// ------------------------------------
// Resources
// ------------------------------------

// ------------------------------------
// Constants
// ------------------------------------

export const PLUGIN_UPDATE = 'PLUGIN_UPDATE'
export const REORDER_TABBED_CONTAINER = 'REORDER_TABBED_CONTAINER'
export const REORDER_QUERIES = 'REORDER_QUERIES'
export const WIDGET_REPOSITION = 'WIDGET_REPOSITION'
export const WIDGET_REPOSITION2 = 'WIDGET_REPOSITION2'
export const WIDGET_SET_POSITION2 = 'WIDGET_SET_POSITION2'
export const WIDGET_SET_MOBILE_POSITION2 = 'WIDGET_SET_MOBILE_POSITION2'
export const WIDGET_TEMPLATE_CREATE = 'WIDGET_TEMPLATE_CREATE'
export const WIDGET_PASTE_START = 'WIDGET_PASTE_START'
export const WIDGET_PASTE_FINISH = 'WIDGET_PASTE_FINISH'
export const DATASOURCE_TEMPLATE_CREATE = 'DATASOURCE_TEMPLATE_CREATE'
export const FUNCTION_TEMPLATE_CREATE = 'FUNCTION_TEMPLATE_CREATE'
export const INSTRUMENT_TEMPLATE_CREATE = 'INSTRUMENT_TEMPLATE_CREATE'
export const STATE_TEMPLATE_CREATE = 'STATE_TEMPLATE_CREATE'
export const FRAME_TEMPLATE_CREATE = 'FRAME_TEMPLATE_CREATE'
export const PLUGIN_FOLDER_CHANGE = 'PLUGIN_FOLDER_CHANGE'
export const CLEAR_PLUGINS = 'CLEAR_PLUGINS'
export const DISABLE_RESPONSIVE_LAYOUT = 'DISABLE_RESPONSIVE_LAYOUT'
export const UPDATE_PRELOADED_APP_JAVASCRIPT = 'UPDATE_PRELOADED_APP_JAVASCRIPT'
export const UPDATE_PRELOADED_APP_JS_LINKS = 'UPDATE_PRELOADED_APP_JS_LINKS'
export const UPDATE_TEST_ENTITY = 'UPDATE_TEST_ENTITY'
export const MARK_TESTS_PENDING = 'MARK_TEST_PENDING'
export const ADD_TEST_ENTITY = 'ADD_TEST_ENTITY'
export const MOVE_TEST_ENTITY = 'MOVE_TEST_ENTITY'
export const MOVE_TEST_ENTITY_IN_SUITE = 'MOVE_TEST_ENTITY_IN_SUITE'
export const DELETE_TEST_ENTITY = 'DELETE_TEST_ENTITY'
export const UPDATE_APP_STYLES = 'UPDATE_APP_STYLES'
export const DISABLE_LOADING_INDICATORS = 'DISABLE_LOADING_INDICATORS'
export const FOLDER_CREATE = 'FOLDER_CREATE'
export const FOLDER_DELETE = 'FOLDER_DELETE'
export const FOLDER_MOVE = 'FOLDER_MOVE'
export const QUERY_MOVE = 'QUERY_MOVE'
export const FOLDER_RENAME = 'FOLDER_RENAME'
export const SET_CUSTOM_DOCUMENT_TITLE = 'SET_CUSTOM_DOCUMENT_TITLE'
export const SET_CUSTOM_SHORTCUTS = 'SET_CUSTOM_SHORTCUTS'
export const ENABLE_CUSTOM_DOCUMENT_TITLE = 'ENABLE_CUSTOM_DOCUMENT_TITLE'
export const URL_FRAGMENT_DEF_UPDATE = 'URL_FRAGMENT_DEF_UPDATE'
export const PAGE_LOAD_VALUE_OVERRIDE_UPDATE = 'PAGE_LOAD_VALUE_OVERRIDE_UPDATE'
export const UPDATE_GLOBAL_STYLES = 'UPDATE_GLOBAL_STYLES'

// ------------------------------------
// Actions
// ------------------------------------

export function instrumentCreate(folder?: string) {
  return async (dispatch: any, getState: () => RetoolState) => {
    const { plugins } = appTemplateSelector(getState())
    const instrumentCount = plugins.filter((plugin) => plugin.subtype === 'Function').count()

    let i = instrumentCount + 1
    const baseText = 'analytics'
    const newId = () => `${baseText}${i}`
    while (plugins.get(newId())) {
      i++
    }

    const instrumentTemplateFn = TemplateResolver('Instrumentation')
    const instrumentTemplate = _.cloneDeep(instrumentTemplateFn())
    const template = new PluginTemplate({
      id: newId(),
      type: 'instrumentation',
      subtype: 'Instrumentation',
      template: instrumentTemplate,
      folder,
    })

    dispatch({ type: INSTRUMENT_TEMPLATE_CREATE, payload: { template } })
    await dispatch(calculateNewTemplate(template))
    dispatch(sendSave({ trigger: 'forced' }))
    return newId()
  }
}

export function folderCreate(folderName?: string) {
  return async (dispatch: any, getState: () => RetoolState) => {
    const { plugins, folders } = appTemplateSelector(getState())
    let i = 1
    const baseText = 'untitled_folder'
    const newId = () => `${baseText}_${i}`
    while (plugins.filter((x) => x.get('folder') === newId()).size > 0 || folders.contains(newId())) {
      i++
    }

    await dispatch({
      type: FOLDER_CREATE,
      payload: { folderName: folderName ?? newId() },
    })
    debouncedSendSave(dispatch, getState)
  }
}

type FolderMovePayload = { folders: Immutable.List<string> }

export function folderMove(payload: FolderMovePayload): RetoolThunk<void> {
  return (dispatch, getState) => {
    dispatch({ type: FOLDER_MOVE, payload })
    debouncedSendSave(dispatch, getState)
  }
}

type FolderRenamePayload = { id: string; newId: string }

export function folderRename(payload: FolderRenamePayload): RetoolThunk<void> {
  return (dispatch, getState) => {
    dispatch({ type: FOLDER_RENAME, payload })
    debouncedSendSave(dispatch, getState)
  }
}

export function folderDelete(folderName: string) {
  return async (dispatch: any, getState: () => RetoolState) => {
    await dispatch({
      type: FOLDER_DELETE,
      payload: { folderName },
    })
    debouncedSendSave(dispatch, getState)
  }
}

type QueryMovePayload = { oldIndex: number; newIndex: number; newFolder: string }

export function queryTabMove(payload: QueryMovePayload): RetoolThunk<void> {
  return (dispatch, getState) => {
    dispatch({ type: QUERY_MOVE, payload })
    debouncedSendSave(dispatch, getState)
  }
}

export function functionCreate(options: any = {}, folder?: string) {
  return async (dispatch: any, getState: () => RetoolState) => {
    const plugins = localPluginsSelector(getState())
    const transformerCount = plugins.filter((plugin) => plugin.subtype === 'Function').count()
    // generate a unique widget name
    let i = 1
    let newId = `transformer${transformerCount + i}`
    while (plugins.get(newId)) {
      i += 1
      newId = `transformer${transformerCount + i}`
    }

    const FnTemplate = TemplateResolver('Function')
    const template = new PluginTemplate({
      id: newId,
      type: 'function',
      subtype: 'Function',
      template: FnTemplate().toMap().merge(options),
      folder,
    })

    dispatch({ type: FUNCTION_TEMPLATE_CREATE, payload: { template } })
    await dispatch(calculateNewTemplate(template))
    dispatch(sendSave({ trigger: 'forced' }))
    return newId
  }
}

// generate a unique widget name
function generateName(plugins: Immutable.OrderedMap<string, PluginTemplate>, pluginSubtype: string, baseName: string) {
  const functionCount = plugins.filter((plugin) => plugin.subtype === pluginSubtype).count()
  let i = 1
  let newId = `${baseName}${functionCount + i}`
  while (plugins.get(newId)) {
    i += 1
    newId = `${baseName}${functionCount + i}`
  }
  return newId
}

type StateCreateOptions = {
  defaultId?: string
  skipSave?: boolean
  skipSelect?: boolean
}

export function stateCreate({ defaultId, skipSave = false, skipSelect = false }: StateCreateOptions = {}) {
  return async (dispatch: RetoolDispatch, getState: () => RetoolState): Promise<string> => {
    const newId = getNewPluginId(getState(), defaultId || 'state1')

    const StateTemplate = TemplateResolver('State')
    const template = new PluginTemplate({
      id: newId,
      type: 'state',
      subtype: 'State',
      template: StateTemplate().toMap(),
    })

    dispatch({ type: STATE_TEMPLATE_CREATE, payload: { template } })

    await dispatch(calculateNewTemplate(template))

    if (!skipSave) {
      dispatch(sendSave({ trigger: 'forced' }))
    }

    if (!skipSelect) {
      dispatch({
        type: 'STATE_SELECT',
        payload: { stateId: newId },
      })
    }

    return newId
  }
}

const getGlobalWidgetPropTemplate = (id: string) => {
  const GlobalWidgetPropTemplate = TemplateResolver('GlobalWidgetProp')

  return {
    id,
    type: 'globalwidgetprop',
    subtype: 'GlobalWidgetProp',
    template: GlobalWidgetPropTemplate().toMap(),
  }
}

const getGlobalWidgetQueryTemplate = (id: string) => {
  const Template = TemplateResolver('GlobalWidgetQuery')

  return {
    id,
    type: 'datasource',
    subtype: 'GlobalWidgetQuery',
    resourceName: 'GlobalWidgetQuery',
    template: Template(),
  }
}

export function convertGlobalWidgetInputType(pluginId: string, newType: GlobalWidgetInputType) {
  return async (dispatch: any, _getState: () => RetoolState) => {
    const newPlugin =
      newType === 'data' ? getGlobalWidgetPropTemplate(pluginId) : getGlobalWidgetQueryTemplate(pluginId)

    dispatch({ type: PLUGIN_UPDATE, payload: { pluginId, newPlugin } })
    const newTemplate = new PluginTemplate({
      id: newPlugin.id,
      type: newPlugin.type,
      subtype: newPlugin.subtype,
      template: newPlugin.template,
    })
    dispatch(calculateNewTemplate(newTemplate))
  }
}

// TODO(mattevenson): Update the height of the widget, if needed
export function convertWidgetSubtype(widgetId: string, newSubtype: PluginSubtype): RetoolThunk {
  return async (dispatch, getState) => {
    const plugin = appTemplateSelector(getState()).plugins.get(widgetId)

    if (!plugin) return

    const newTemplate = TemplateResolver(newSubtype)(plugin.template)
    const newPlugin = plugin.set('subtype', newSubtype).set('template', newTemplate)

    dispatch({
      type: PLUGIN_UPDATE,
      payload: { pluginId: widgetId, newPlugin },
    })

    dispatch(recalculateTemplate(widgetId, newPlugin, newTemplate))
    dispatch(sendSave({ trigger: 'forced' }))
  }
}

export function globalWidgetPropCreate() {
  return async (dispatch: any, getState: () => RetoolState) => {
    const { plugins } = appTemplateSelector(getState())
    const newId = generateName(plugins, 'GlobalWidgetProp', 'input')

    const GlobalWidgetPropTemplate = TemplateResolver('GlobalWidgetProp')
    const template = new PluginTemplate({
      id: newId,
      type: 'globalwidgetprop',
      subtype: 'GlobalWidgetProp',
      template: GlobalWidgetPropTemplate().toMap(),
    })

    dispatch({ type: STATE_TEMPLATE_CREATE, payload: { template } })

    await dispatch(calculateNewTemplate(template))
    dispatch(sendSave({ trigger: 'forced' }))
  }
}

export function globalWidgetOutputCreate() {
  return async (dispatch: any, getState: () => RetoolState) => {
    const { plugins } = appTemplateSelector(getState())
    const newId = generateName(plugins, 'GlobalWidgetOutput', 'output')

    const GlobalWidgetOutputTemplate = TemplateResolver('GlobalWidgetOutput')
    const template = new PluginTemplate({
      id: newId,
      type: 'globalwidgetoutput',
      subtype: 'GlobalWidgetOutput',
      template: GlobalWidgetOutputTemplate().toMap(),
    })

    dispatch({ type: STATE_TEMPLATE_CREATE, payload: { template } })

    await dispatch(calculateNewTemplate(template))
    dispatch(sendSave({ trigger: 'forced' }))
  }
}

export function reorderQueries(originalIndex: number, newIndex: number) {
  return (dispatch: any, getState: () => RetoolState) => {
    retoolAnalyticsTrack('Query Tabs Reordered', {
      ...getAppComplexity(appTemplateSelector(getState())),
    })
    dispatch({
      type: REORDER_QUERIES,
      payload: { originalIndex, newIndex },
    })
    debouncedSendSave(dispatch, getState)
  }
}

export function reorderTabbedContainer(tabbedContainerId: string, index: number, newIndex: number) {
  return (dispatch: any, getState: () => RetoolState) => {
    dispatch({
      type: REORDER_TABBED_CONTAINER,
      payload: { tabbedContainerId, index, newIndex },
    })
    debouncedSendSave(dispatch, getState)
  }
}

type PluginDefaultTemplate = { [key: string]: unknown }

export type WidgetCreateParams = {
  defaultId?: string
  defaultTemplate?: PluginDefaultTemplate
  fixes?: WidgetMove[]
  position: Position2Params
  /** Set to true to skip automatic selection of this widget after creation in editor. */
  skipSelect?: boolean
  widgetType: string
}

/*
 * like widgetCreate, but for more than one new widget.  defers expensive operations
 * (like saving to the server) to the end.
 *
 * Utilize WidgetCreateParams.skipSelect to control which widget is selected as part of this operation.
 * If none of the params have skipSelect == true, no selection change will occur.
 */
export const batchWidgetCreate = (widgetCreateParams: WidgetCreateParams[], skipSave = false) => async (
  dispatch: RetoolDispatch,
  getState: () => RetoolState,
): Promise<string[]> => {
  startBatchUndoGroup()

  const newIds: string[] = []
  const positionKey = positionKeySelector(getState())

  for (const param of widgetCreateParams) {
    // Each widget might have a different container
    const containerType = getState().appModel.pluginType(param.position.container)
    assertValidWidgetParent([param.widgetType], containerType)
  }

  for (const param of widgetCreateParams) {
    const { defaultId, defaultTemplate = {}, fixes = [], position, skipSelect = false, widgetType } = param
    const { row = 0, col = 0, width = 4, height = 1, container = '', subcontainer = '', tabNum = 0 } = position
    const WidgetTemplate = TemplateResolver(widgetType)
    let template = WidgetTemplate(defaultTemplate).toMap()

    const state = getState()
    const newId =
      widgetType === GLOBAL_WIDGET_TYPE
        ? getNewGlobalWidgetId(state, template.get('name'), defaultId)
        : getNewWidgetId(state, widgetType, defaultId)
    newIds.push(newId)

    let globalWidgetPostCreation
    if (widgetType === GLOBAL_WIDGET_TYPE) {
      // When adding a global widget we need to make sure there isn't an infinite nested loop of global widgets
      const globalWidgetCycleDetector = new DepGraph<string>()
      const pageUuid = currentPageUuidSelector(state)
      const widgetUuid = template.get('pageUuid')

      globalWidgetCycleDetector.addNode(widgetUuid)
      globalWidgetCycleDetector.addNode(pageUuid)
      globalWidgetCycleDetector.addDependency(pageUuid, widgetUuid)

      // Check that it's not being added into itself.
      try {
        globalWidgetCycleDetector.dependenciesOf(pageUuid)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(
          "Error: the module you're trying to add contains the current module, which is not allowed.",
          err.message,
        )
        Message.error("Error: the module you're trying to add contains the current module, which is not allowed.")
        throw new Error(err)
      }

      const { newTemplate, globalWidgetPostCreation: postCreate } = await globalWidgetCreateHelper(
        newId,
        template,
        globalWidgetCycleDetector,
      )
      if (!newTemplate) {
        Message.error(`Could not find module for ${template.get('name')} - was the module deleted?`)
      } else {
        template = newTemplate
        globalWidgetPostCreation = postCreate
      }
    }

    const widgetTemplate = new PluginTemplate({
      id: newId,
      type: 'widget',
      subtype: widgetType,
      template,
      style: Immutable.Map({}),
      [positionKey]: new Position2({ row, col, width, height, container, subcontainer, tabNum }),
    })

    dispatch({
      type: WIDGET_TEMPLATE_CREATE,
      payload: {
        template: widgetTemplate,
        skipSelect,
      },
    })

    if (fixes.length > 0) {
      const fixesWithNewId = fixes.map((move) => {
        const { widgetIds } = move
        if (widgetIds.includes(SPECIAL_NEW_WIDGET_CONSTANT)) {
          return {
            ...move,
            widgetIds: widgetIds.map((id) => (id === SPECIAL_NEW_WIDGET_CONSTANT ? newId : id)),
          }
        }

        return move
      })

      dispatch(applyMoves(fixesWithNewId))
    }

    stopBatchUndoGroup()

    // Add the plugin to the dependency graph, render its template strings and update the model
    await dispatch(calculateNewTemplate(widgetTemplate))

    if (widgetType === GLOBAL_WIDGET_TYPE) {
      if (globalWidgetPostCreation) {
        await dispatch(globalWidgetPostCreation(widgetTemplate))
        await dispatch(initSandboxWithStoredJS())
      } else {
        throw new Error('Could not find postCreate function for global widget!')
      }
    }
  }

  if (!skipSave) {
    dispatch(sendSave({ trigger: 'forced' }))
  }

  return newIds
}

export function widgetCreate(params: WidgetCreateParams, skipSave = false) {
  return async (dispatch: RetoolDispatch): Promise<string> =>
    dispatch(batchWidgetCreate([params], skipSave)).then(([newId]) => newId)
}

export function widgetCreateFromDirectory(
  widgetType: string,
  defaultTemplate: DefaultWidgetTemplate | undefined,
  position: Position2Params,
  fixes: WidgetMove[] = [],
) {
  return async (dispatch: RetoolDispatch, getState: () => RetoolState): Promise<string> => {
    await loadPlugin(widgetType)
    const params: WidgetCreateParams = { widgetType, position, fixes }

    if (typeof defaultTemplate === 'function') {
      const state = getState()
      const query = recentQuerySelector(state)

      if (query) {
        const data = state.appModel.values.getQuery(query.id).get('data')
        params.defaultTemplate = defaultTemplate(query.id, data)
      } else {
        params.defaultTemplate = defaultTemplate()
      }
    } else {
      params.defaultTemplate = defaultTemplate
    }

    return dispatch(widgetCreate(params))
  }
}

export const getWidgetAndChildren = (widgetId: string, state: RetoolState): Immutable.List<PluginTemplate> => {
  const plugins = appTemplateSelector(state).get('plugins')
  const positionKey = largeScreenSelector(state) ? 'position2' : 'mobilePosition2'
  const pluginsList = plugins.toList()

  const _getWidgetAndChildren = (widgetId: string): Immutable.List<PluginTemplate> => {
    const widget = plugins.get(widgetId)
    if (!widget) return Immutable.List([])

    const widgetType = widget?.subtype

    if (widgetTypeIsContainer(widgetType)) {
      const children = pluginsList.flatMap((plugin) =>
        plugin[positionKey]?.container === widgetId ? _getWidgetAndChildren(plugin.id) : [],
      )
      return children.unshift(widget)
    }

    return Immutable.List([widget])
  }

  return _getWidgetAndChildren(widgetId)
}

export function widgetReposition2(move: WidgetMove, fixes: WidgetMove[]) {
  return async (dispatch: any, getState: () => RetoolState) => {
    const newContainer = move.move.container

    if (newContainer != null) {
      const state = getState()
      const appModel = state.appModel
      // All widgets in a move have the same root container
      const containerType = appModel.pluginType(newContainer)
      const allMovingWidgetIds = move.widgetIds.flatMap((id) =>
        getWidgetAndChildren(id, state)
          .map((w) => w.get('id'))
          .toJS(),
      )
      assertValidWidgetParent(
        allMovingWidgetIds.map((widgetId) => appModel.pluginType(widgetId) || ''),
        containerType,
      )

      const currentContainer = appTemplateSelector(state).getIn([
        'plugins',
        move.widgetIds[0],
        'position2',
        'container',
      ])
      if (newContainer !== currentContainer) {
        for (const widgetId of move.widgetIds) {
          await dispatch(recalculatePostWidgetMove(widgetId, newContainer))
        }
      }
    }

    await dispatch(applyMoves([move].concat(fixes)))
    debouncedSendSave(dispatch, getState)
  }
}

export function duplicateWidget(widgetId: string, position: Position2Params, fixes: WidgetMove[]) {
  return async (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const state = getState()
    const positionKey = largeScreenSelector(state) ? 'position2' : 'mobilePosition2'

    const widgets = getWidgetAndChildren(widgetId, state)
    const oldToNewWidgetId: { [k: string]: string } = {}

    assertValidWidgetParent(widgets.map((w) => w.get('subtype')).toJS(), state.appModel.pluginType(position.container))

    for (const widget of widgets) {
      const oldPosition = widget[positionKey]
      const newPosition =
        widget.id === widgetId
          ? position
          : oldPosition?.merge({ container: oldToNewWidgetId[oldPosition?.container || ''] })

      // making TS happy - newPosition won't be undefined in practice
      if (!newPosition) continue

      const params: WidgetCreateParams = {
        defaultId: widget.id,
        defaultTemplate: widget.template,
        fixes,
        position: newPosition,
        widgetType: widget.subtype,
      }

      const newWidgetId = await dispatch(widgetCreate(params, true))
      oldToNewWidgetId[widget.id] = newWidgetId
    }

    dispatch(sendSave({ trigger: 'forced' }))

    await dispatch({
      type: 'WIDGET_SELECT',
      payload: { widgetId: oldToNewWidgetId[widgetId] },
    })
  }
}

export function applyMoves(moves: WidgetMove[]): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    const largeScreen = largeScreenSelector(getState())
    return dispatch({
      type: WIDGET_REPOSITION2,
      payload: {
        moves,
        largeScreen,
      },
    })
  }
}

export const updateGlobalStyles = (appThemeId?: number) => async (
  dispatch: RetoolDispatch,
  getState: () => RetoolState,
) => {
  const state = getState()

  const defaultAppThemeId = defaultAppThemeIdSelector(state)

  // a themeId of RETOOL_DEFAULT_THEME_ID resets the styles to retool defaults
  // a themeId of null subscribes to the org's default theme
  const payload: { appThemeId: number | null } = { appThemeId: appThemeId ?? RETOOL_DEFAULT_THEME_ID }

  // if we're setting the app to the default theme id, remove the ID from
  // the template to stay subscribed to the default theme if it changes
  if (defaultAppThemeId && appThemeId === defaultAppThemeId) {
    payload.appThemeId = null
  }

  dispatch({ type: UPDATE_GLOBAL_STYLES, payload })
  dispatch(sendSave({ trigger: 'forced' }))
}

export function disableResponsiveLayout(disabled: any) {
  return async (dispatch: any, getState: () => RetoolState) => {
    await dispatch({
      type: DISABLE_RESPONSIVE_LAYOUT,
      payload: { disabled },
    })
    debouncedSendSave(dispatch, getState)
  }
}

export function initSandboxWithStoredJS() {
  return async (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const state = getState()
    const preloadedOrgJavaScript = preloadedOrgJavaScriptSelector(state)
    const preloadedOrgJSLinks = preloadedOrgJSLinksSelector(state)

    const preloadedAppJavaScript = preloadedAppJavaScriptSelector(state)
    const preloadedAppJSLinks = preloadedAppJSLinksSelector(state)

    const preloadedModulesJavaScript = Object.values(nestedModulesPreloadedJSSelector(state)).join('\n\n')
    const preloadedModulesJSLinks = flatten(Object.values(preloadedModulesJSLinksSelector(state)))

    const customRetoolSandboxRestrictions = customRetoolSandboxRestrictionsSelector(state)

    const preloadedJS = [preloadedOrgJavaScript ?? '', preloadedAppJavaScript ?? '', preloadedModulesJavaScript]
    const javaScriptLinks = flatten([preloadedOrgJSLinks, preloadedAppJSLinks, preloadedModulesJSLinks])

    const initSandboxParams = {
      preloadedJavaScript: preloadedJS.join('\n'),
      javaScriptLinks,
      environmentVariables: { environmentVariables: customRetoolSandboxRestrictions },
    }

    initSandbox(initSandboxParams)
    if (preloadedModulesJavaScript || preloadedModulesJSLinks.length) {
      await dispatch(rerenderModuleSelectors())
    }
  }
}

export function updatePreloadedAppJavaScript(preloadedAppJavaScript: string) {
  return async (dispatch: any, getState: () => RetoolState) => {
    await dispatch({
      type: UPDATE_PRELOADED_APP_JAVASCRIPT,
      payload: { preloadedAppJavaScript },
    })
    await dispatch(initSandboxWithStoredJS())
    debouncedSendSave(dispatch, getState)
  }
}

export function updateTestEntity(testEntity: TestEntity) {
  return (dispatch: any, getState: () => RetoolState) => {
    dispatch({
      type: UPDATE_TEST_ENTITY,
      payload: { testEntity },
    })
    debouncedSendSave(dispatch, getState)
  }
}

export function markTestsPending(tests: Test[]) {
  return (dispatch: any, getState: () => RetoolState) => {
    dispatch({
      type: MARK_TESTS_PENDING,
      payload: { tests },
    })
    debouncedSendSave(dispatch, getState)
  }
}

export function renameTestEntity(testEntity: TestEntity, newName: string) {
  return (dispatch: any, getState: () => RetoolState) => {
    const updatedTest = _.cloneDeep(testEntity)
    updatedTest.name = newName

    dispatch({
      type: UPDATE_TEST_ENTITY,
      payload: { testEntity: updatedTest },
    })

    debouncedSendSave(dispatch, getState)
  }
}

export function addTestEntity(testEntity: TestEntity) {
  return (dispatch: any, getState: () => RetoolState) => {
    dispatch({
      type: ADD_TEST_ENTITY,
      payload: { testEntity },
    })
    debouncedSendSave(dispatch, getState)
  }
}

export function moveTestEntity(originalIndex: number, newIndex: number, suiteId?: string) {
  return (dispatch: any, getState: () => RetoolState) => {
    if (suiteId) {
      dispatch({
        type: MOVE_TEST_ENTITY_IN_SUITE,
        payload: { originalIndex, newIndex, suiteId },
      })
    } else {
      dispatch({
        type: MOVE_TEST_ENTITY,
        payload: { originalIndex, newIndex },
      })
    }
    debouncedSendSave(dispatch, getState)
  }
}

export function deleteTestEntity(testEntity: TestEntity) {
  return (dispatch: any, getState: () => RetoolState) => {
    dispatch({
      type: DELETE_TEST_ENTITY,
      payload: { testEntity },
    })
    debouncedSendSave(dispatch, getState)
  }
}

export function updatePreloadedAppJSLinks(preloadedAppJSLinks: string[]) {
  return async (dispatch: any, getState: () => RetoolState) => {
    await dispatch({
      type: UPDATE_PRELOADED_APP_JS_LINKS,
      payload: { preloadedAppJSLinks },
    })
    await dispatch(initSandboxWithStoredJS())
    debouncedSendSave(dispatch, getState)
  }
}

export function updateAppStyles(value: string) {
  return (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    dispatch({ type: UPDATE_APP_STYLES, payload: { value } })
    debouncedSendSave(dispatch, getState)
  }
}

export function setDisableLoadingIndicators(disabled: any) {
  return async (dispatch: any, getState: () => RetoolState) => {
    await dispatch({
      type: DISABLE_LOADING_INDICATORS,
      payload: { disabled },
    })
    debouncedSendSave(dispatch, getState)
  }
}

export function setCustomDocumentTitle(customDocumentTitle: string) {
  return async (dispatch: any, getState: () => RetoolState) => {
    await dispatch({
      type: SET_CUSTOM_DOCUMENT_TITLE,
      payload: { customDocumentTitle },
    })
    debouncedSendSave(dispatch, getState)
  }
}

export function setCustomShortcuts(customShortcuts: { shortcut: string; action: string }[]) {
  return async (dispatch: any, getState: () => RetoolState) => {
    await dispatch({
      type: SET_CUSTOM_SHORTCUTS,
      payload: { customShortcuts },
    })
    extendDefaultShortcuts(customShortcuts)
    debouncedSendSave(dispatch, getState)
  }
}

export function runCustomShortcut(action: string) {
  return async (_dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const matched = /RETOOL_SHORTCUT_(.*)/g.exec(action)
    if (matched) {
      const shortcutIndex = parseInt(matched[1])
      const customShortcuts = getState().appTemplate.present.customShortcuts
      const shortcutToRun = customShortcuts[shortcutIndex]
      try {
        await evaluateWithCallbacks(
          shortcutToRun.action,
          () => appModelJSValuesSelector(getState()),
          () => generateJsApi(appModelJSValuesSelector(getState())),
        )
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Error in running shortcut', err)
      }
    }
  }
}

export function enableCustomDocumentTitle(enabled: boolean) {
  return async (dispatch: any, getState: () => RetoolState) => {
    await dispatch({
      type: ENABLE_CUSTOM_DOCUMENT_TITLE,
      payload: { enabled },
    })
    debouncedSendSave(dispatch, getState)
  }
}

export function setMobilePosition2(widgetId: any, newPosition: any) {
  return async (dispatch: any, getState: () => RetoolState) => {
    await dispatch({
      type: WIDGET_SET_MOBILE_POSITION2,
      payload: {
        widgetId,
        newPosition,
      },
    })
    debouncedSendSave(dispatch, getState)
  }
}

export function setPosition2(widgetId: any, newPosition: any) {
  return async (dispatch: any, getState: () => RetoolState) => {
    await dispatch({
      type: WIDGET_SET_POSITION2,
      payload: {
        widgetId,
        newPosition,
      },
    })
    debouncedSendSave(dispatch, getState)
  }
}

export function updateFragmentDefinition(index: number, update: DefinitionUpdate) {
  return (dispatch: any, getState: () => RetoolState) => {
    const urlParamNameChange = !!update.name || _.isEmpty({})
    if (urlParamNameChange) {
      const query = queryString.parse(window.location.hash)
      const oldName = appTemplateSelector(getState()).urlFragmentDefinitions.getIn([index, 'name'])
      if (_.has(query, oldName)) {
        delete query[oldName]
        window.location.hash = queryString.stringify(query)
      }
    }
    dispatch({
      type: URL_FRAGMENT_DEF_UPDATE,
      payload: { index, update },
    })
    const appTemplate = appTemplateSelector(getState())
    const urlFragmentsObject: any = {}
    appTemplate.urlFragmentDefinitions.map((def) => {
      const name = def.get('name')
      const value = def.get('value')
      urlFragmentsObject[name!] = value
    })
    // Only reset the model if the name of one of the fragments was updated
    dispatch(recalculateTemplate('urlFragments', null, urlFragmentsObject, urlParamNameChange))
    debouncedSendSave(dispatch, getState)
  }
}

function handleUrlFragmentDefUpdate(
  state: AppTemplate,
  action: { payload: { index: number; update: DefinitionUpdate } },
) {
  const { index, update } = action.payload
  return state.updateFragmentDefinition(index, update)
}

export function updatePageLoadOverrides(index: number, update: DefinitionUpdate) {
  return (dispatch: any, getState: () => RetoolState) => {
    dispatch({
      type: PAGE_LOAD_VALUE_OVERRIDE_UPDATE,
      payload: { index, update },
    })
    debouncedSendSave(dispatch, getState)
  }
}

function handlePageLoadOverridesUpdate(
  state: AppTemplate,
  action: { payload: { index: number; update: DefinitionUpdate } },
) {
  const { index, update } = action.payload
  return state.updatePageLoadOverrides(index, update)
}

function handleTemplateUpdate(state: any, action: any) {
  return state
    .mergeIn(['plugins', action.payload.widgetId, 'template'], Immutable.fromJS(action.payload.update))
    .setIn(['plugins', action.payload.widgetId, 'updatedAt'], new Date())
}

function handleBatchTemplateUpdate(
  state: AppTemplate,
  action: {
    payload: {
      updates: { [widgetId: string]: { [key: string]: unknown } }
    }
  },
) {
  return state.withMutations((newState) => {
    Object.entries(action.payload.updates).forEach(([id, update]) => {
      newState
        .mergeIn(['plugins', id, 'template'], Immutable.fromJS(update))
        .setIn(['plugins', id, 'updatedAt'], new Date())
    })
  })
}

export function pluginUpdateId(pluginId: any, newId: any) {
  return async (dispatch: any, getState: () => RetoolState) => {
    startBatchUndoGroup()
    dispatch({
      type: PLUGIN_UPDATE_ID,
      payload: { pluginId, newId },
    })
    dispatch(sendSave({ trigger: 'forced' }))
    const appState = getState()
    const queryState = await propagatePluginIdReferenceChange(pluginId, newId, appState)
    const selectedDatasource = selectedDatasourceSelector(appState)
    if (selectedDatasource) {
      dispatch({
        type: 'QUERY_EDITOR_UPDATE',
        payload: {
          editorType: selectedDatasource.subtype,
          newValue: queryState,
        },
      })
    }
    stopBatchUndoGroup()
    debouncedSendSave(dispatch, appState)
  }
}

const getModulePropPlugins = (appTemplate: AppTemplate, plugin: PluginTemplate) => {
  const childNamespace = plugin.template.get('childNamespace')
  return appTemplate.plugins.filter((p) => {
    const namespace = p.get('namespace')
    if (!namespace) {
      return false
    }

    if (namespace.getNamespace()[0] !== childNamespace) {
      return false
    }

    return p.get('subtype') === 'GlobalWidgetProp'
  })
}

async function propagatePluginIdReferenceChange(
  pluginId: string,
  newId: string,
  appState: RetoolState,
  allowUndo = true,
) {
  const { updateProperty } = await import(/* webpackChunkName: "dependencyGraph" */ 'store/appModel/dependencyGraph')

  const appTemplate = appTemplateSelector(appState)
  const isDeleting = newId === ''
  const updatedPlugin = appTemplate.getPlugin(newId)
  const pluginIsModule = updatedPlugin?.get('subtype') === 'GlobalWidget'
  const renamingModule = pluginIsModule && !isDeleting

  const localPlugins = localPluginsSelector(appState)
  const pluginsToUpdate = renamingModule
    ? localPlugins.merge(getModulePropPlugins(appTemplate, updatedPlugin))
    : localPlugins
  pluginsToUpdate.forEach((plugin) => {
    const update: any = {}
    const propertyAnnotations = PluginPropertyAnnotationsResolver(plugin.subtype)
    plugin.template?.map((templateString: any, key: any) => {
      // if we're an imported query field with template aliases, dont rename since the app's scope is separate from our scope
      const hasTemplateAliases = !_.isEmpty(
        TemplateCodeCustomAliasesResolver(plugin.subtype)(plugin.template)([plugin.id, key]),
      )
      if (hasTemplateAliases) return

      const propertyType = _.get(propertyAnnotations, `${key}.type`)
      //Don't allow cascading delete of strings since we don't know how to set a good default
      if (propertyType !== 'pluginId' && propertyType !== 'pluginIdList' && isDeleting) {
        return
      }
      const { newValueExists, newValue } = updateProperty(templateString, pluginId, newId, propertyType)
      if (newValueExists) {
        update[key] = newValue
      }
    })

    if (_.keys(update).length > 0) {
      dispatch(widgetTemplateUpdate(plugin.id, update, undefined, { isUserTriggered: allowUndo }))
    }
  })

  // Also propagate the changed input name to other places in the state tree
  const selectedDatasource = selectedDatasourceSelector(appState)
  if (!selectedDatasource) return []
  let queryState = appState.editor.queryState.get(selectedDatasource.subtype)!
  const queryPropertyAnnotations = PluginPropertyAnnotationsResolver(selectedDatasource.subtype)
  queryState.forEach((_value, key) => {
    // if we're an imported query field with template aliases, dont rename since the app's scope is separate from our scope
    const hasTemplateAliases = !_.isEmpty(
      TemplateCodeCustomAliasesResolver(selectedDatasource.subtype)(queryState)(['foo', key]),
    )
    if (hasTemplateAliases) return

    //Don't allow cascading delete of strings since we don't know how to set a good default
    const templateString = queryState.get(key)
    const propertyType = _.get(queryPropertyAnnotations, `${key}.type`)
    if (propertyType !== 'pluginId' && propertyType !== 'pluginIdList' && newId === '') {
      return
    }
    const { newValueExists, newValue } = updateProperty(templateString, pluginId, newId, propertyType)
    if (newValueExists) {
      queryState = queryState.set(key, newValue)
    }
  })
  return queryState
}

export const generateUniqueDatasourceId = (plugins: OrderedMap<string, PluginTemplate>) => {
  const queryCount = plugins.filter((plugin) => plugin.type === 'datasource').count()
  let i = 1
  let newId = `query${queryCount + i}`
  while (plugins.get(newId)) {
    i += 1
    newId = `query${queryCount + i}`
  }
  return newId
}

export function datasourceCreate(
  resourceName: string,
  datasourceType: string,
  defaultId?: string,
  defaultTemplate: PluginDefaultTemplate = {},
  resource?: Resource,
  folder?: string,
) {
  return async (dispatch: RetoolDispatch, getState: () => RetoolState): Promise<string> => {
    const state = getState()
    const Template = TemplateResolver(datasourceType, resource)

    // generate a unique widget name
    const newId = defaultId ? getNewPluginId(state, defaultId) : generateUniqueDatasourceId(localPluginsSelector(state))
    const newQueryOverrides = Immutable.fromJS(defaultOverridesForNewQueries)
    const datasourceTemplate = new PluginTemplate({
      id: newId,
      type: 'datasource',
      subtype: datasourceType,
      resourceName,
      template: Template().merge(defaultTemplate).merge(newQueryOverrides),
      folder,
    })

    dispatch({
      type: DATASOURCE_TEMPLATE_CREATE,
      payload: {
        template: datasourceTemplate,
      },
    })

    await dispatch(calculateNewTemplate(datasourceTemplate))
    dispatch(sendSave({ trigger: 'forced' }))
    return newId
  }
}

export function datasourceTypeChange(pluginId: string, resourceName: string, newType: string, oldType?: string) {
  return {
    type: DATASOURCE_TYPE_CHANGE,
    payload: { pluginId, resourceName, newType, oldType },
  }
}

export function pluginFolderChange(pluginId: string, folderName: string) {
  return {
    type: PLUGIN_FOLDER_CHANGE,
    payload: { pluginId, folderName },
  }
}

export function getPluginIdsToDelete(pluginIds: string[], state: RetoolState) {
  const widgets = widgetsSelector(state)
  const idsToDelete = pluginIds.slice()
  widgets.forEach((widget) => {
    if (idsToDelete.includes(widget.id)) return
    // A widget can be a child of different containers for desktop and mobile.
    // We only want to delete a child component if all of its parents are being deleted.
    const containerIds = [widget.position2?.container, widget.mobilePosition2?.container].filter(isNotNullish)
    if (containerIds.length > 0 && containerIds.every((containerId) => idsToDelete.includes(containerId))) {
      idsToDelete.push(widget.id)
      // Delete deeply nested components
      if (widgetTypeIsContainer(widget.subtype)) {
        return getPluginIdsToDelete(idsToDelete, state)
      }
    }
  })
  return idsToDelete
}

export function pluginDelete(pluginIds: string[], showToast = true, allowUndo = true) {
  return async (dispatch: any, getState: () => RetoolState) => {
    startBatchUndoGroup()
    const state = getState()
    const idsToDelete = getPluginIdsToDelete(pluginIds, state)

    const pluginTypes = pluginIds.map((id: string) => state.appTemplate.present.getPluginType(id))

    const isAppGlobalWidget = isTemplateGlobalWidgetSelector(state)
    if (isAppGlobalWidget && idsToDelete.includes(GLOBAL_WIDGET_GRID)) {
      Message.warning('You cannot delete the module container')
      return
    }

    await dispatch({
      type: PLUGIN_DELETE,
      payload: idsToDelete,
    })

    for (const pluginId of idsToDelete) {
      const queryState = await propagatePluginIdReferenceChange(pluginId, '', getState(), allowUndo)
      dispatch({
        type: 'QUERY_EDITOR_UPDATE',
        payload: {
          editorType: selectedDatasourceSelector(getState())?.subtype,
          newValue: queryState,
        },
      })
    }

    if (pluginTypes.includes(GLOBAL_WIDGET_TYPE)) {
      await dispatch(initSandboxWithStoredJS())
    }

    if (showToast) showSuccessToaster('Deleted component succesfully! (Ctrl+Z to undo)')
    stopBatchUndoGroup()
    dispatch(sendSave({ trigger: 'forced' }))
  }
}

export function saveState(options: any) {
  return async (dispatch: any) => {
    const result = await dispatch(sendSave(options))
    if (result.type === RECEIVE_SAVE) {
      showSuccessToaster('Successfully saved')
    }
  }
}

export const REQUEST_EXPORT = 'REQUEST_EXPORT'
export const RECEIVE_EXPORT = 'RECEIVE_EXPORT'
export const FAILURE_EXPORT = 'FAILURE_EXPORT'

const MAX_MODEL_SIZE_IN_MEGA_BYTES = 60

export function exportSave(
  pageName: string,
  pageUuid: string,
  downloadJson: boolean,
  isGlobalWidget: boolean,
  pageBranch?: string,
  turnIntoDemoApp?: boolean,
  shareWithRetoolSlack?: boolean,
  shareMetadata?: { [key: string]: string },
) {
  return async (dispatch: any, getState: any) => {
    const query = pageBranch ? `?branch=${encodeURIComponent(pageBranch)}` : ''
    let body
    if (turnIntoDemoApp) {
      const appModel = appModelSelector(getState()).get('values').toJS()

      // Some users have really large app models. Eventually we should a) do the showcase transformations on the frontend
      // and b) cut off overly large mock transformer values. We don't know how common this is right now so adding tracking +
      // clearer error message for the time being
      if (sizeof(appModel) > MAX_MODEL_SIZE_IN_MEGA_BYTES * 1000000) {
        Message.error(
          `The data your app relies on makes this app too large to showcase (${MAX_MODEL_SIZE_IN_MEGA_BYTES} megabytes exceeded). Try reducing the amount of data you load onto the frontend (e.g. using pagination)`,
          10,
        )
        retoolAnalyticsTrack('Attempted to showcase overly large app', {
          sizeInBytes: sizeof(appModel),
          shareMetadata,
          shareWithRetoolSlack,
        })

        return {}
      }

      body = JSON.stringify({
        appModel,
        asDemoApp: true,
        shareWithRetoolSlack,
        shareMetadata,
      })
    }

    const save = await dispatch(
      callApi({
        endpoint: `/api/pages/uuids/${pageUuid}/export${query}`,
        method: 'POST',
        types: [REQUEST_EXPORT, RECEIVE_EXPORT, FAILURE_EXPORT],
        headers: JSON_HEADERS,
        body,
      }),
    )
    const exportData = _.pick(save.payload, ['uuid', 'page', 'modules'])

    if (downloadJson) {
      saveAs(new Blob([JSON.stringify(exportData)], { type: 'data:text/json' }), `${pageName}.json`)
      retoolAnalyticsTrack(`${isGlobalWidget ? 'Module' : 'App'} JSON Exported`, {
        pageUuid,
        forDemo: turnIntoDemoApp,
      })
    }

    return exportData
  }
}

export function restoreFromFile(file: any) {
  return async (dispatch: any) => {
    if (!file) {
      return
    }

    const reader = new FileReader()
    reader.onload = async (event: any) => {
      const data = JSON.parse(event.target.result)
      const deserializedSave = deserializeSave(data.page.data.appState)
      await dispatch(runMigrationsAndStart(deserializedSave))
      await dispatch(sendSave({ trigger: 'imported' }))
    }
    reader.readAsText(file)
  }
}

export const REQUEST_NULL = 'REQUEST_NULL'
export const RECEIVE_NULL = 'RECEIVE_NULL'
export const FAILURE_NULL = 'FAILURE_NULL'
export const REQUEST_RESTORE = 'REQUEST_RESTORE'
export const RECEIVE_RESTORE = 'RECEIVE_RESTORE'
export const FAILURE_RESTORE = 'FAILURE_RESTORE'
export const MIGRATIONS_SUCCESS = 'MIGRATIONS_SUCCESS'
export const MIGRATIONS_UP_TO_DATE = 'MIGRATIONS_UP_TO_DATE'
export const MIGRATIONS_FAILURE = 'MIGRATIONS_FAILURE'
export const RESTORE_LOADED = 'RESTORE_LOADED'

export function restoreSave(pageUuid: any, saveId: any) {
  return async (dispatch: any) => {
    const result:
      | { type: typeof RECEIVE_RESTORE; payload: { page: { data: { appState: JSON } } } }
      | { type: typeof FAILURE_RESTORE } = await dispatch(
      callApi({
        endpoint: `/api/pages/uuids/${pageUuid}/saves/${saveId}`,
        method: 'GET',
        types: [REQUEST_RESTORE, RECEIVE_RESTORE, FAILURE_RESTORE],
      }),
    )

    if (result.type === RECEIVE_RESTORE) {
      const deserializedSave = deserializeSave(result.payload.page.data.appState)
      await dispatch(runMigrationsAndStart(deserializedSave))
      await dispatch(sendSave({ trigger: 'restored' }))
    } else {
      message.error('Could not restore release.')
    }
  }
}

export function retrieveReleaseYaml(pageUuid: string, releaseTagName: string) {
  return async (dispatch: any, _: () => RetoolState) => {
    const result:
      | { type: typeof RECEIVE_RESTORE; payload: { yaml: string } }
      | { type: typeof FAILURE_RESTORE } = await dispatch(
      callApi({
        endpoint: `/api/pages/uuids/${pageUuid}/releases/${releaseTagName}?yaml=true`,
        method: 'GET',
        types: [REQUEST_RESTORE, RECEIVE_RESTORE, FAILURE_RESTORE],
      }),
    )

    if (result.type === RECEIVE_RESTORE) {
      return result.payload.yaml
    } else {
      message.error('Could not load release to diff.')
      return ''
    }
  }
}

export function restoreRelease(pageUuid: string, releaseTagName: string) {
  return async (dispatch: any) => {
    const result:
      | { type: typeof RECEIVE_RESTORE; payload: { page: { data: { appState: JSON } } } }
      | { type: typeof FAILURE_RESTORE } = await dispatch(
      callApi({
        endpoint: `/api/pages/uuids/${pageUuid}/releases/${releaseTagName}`,
        method: 'GET',
        types: [REQUEST_RESTORE, RECEIVE_RESTORE, FAILURE_RESTORE],
      }),
    )

    if (result.type === RECEIVE_RESTORE) {
      const deserializedSave = deserializeSave(result.payload.page.data.appState)
      await dispatch(runMigrationsAndStart(deserializedSave))
      // manually trigger a save after restoring previous page state
      await dispatch(sendSave({ trigger: 'restored' }))
    } else {
      message.error('Could not restore release.')
    }
  }
}

export function runPluginMigrations(migrations: any, appVersion: any, plugin: any) {
  const unrunMigrations = migrations.filter((m: any) => semverCmp(m.toVersion, appVersion) > 0)
  const sortedUnrunMigrations = unrunMigrations.sort((m1: any, m2: any) => {
    return semverCmp(m1.toVersion, m2.toVersion)
  })
  return sortedUnrunMigrations.reduce((acc: any, migration: any) => {
    // eslint-disable-next-line no-console
    console.log(`Migrating widget ${plugin.id} of type ${plugin.subtype} to ${migration.toVersion}`)
    return migration.up(acc)
  }, plugin)
}

export function migratePlugin(appVersion: any, plugin: any) {
  const migrations = MigrationsResolver(plugin.subtype)
  return runPluginMigrations(migrations, appVersion, plugin)
}

export const migrateTests = (appTemplate: AppTemplate) => {
  const existingTests = appTemplate.get('testEntities')
  if (appTemplate.has('tests') && appTemplate.get('tests') !== undefined) {
    appTemplate.get('tests')?.forEach((test) => {
      const newTest = _.cloneDeep(test)
      newTest.type = 'test'
      newTest.pauseAllQueries = true
      existingTests.push(newTest)
    })
    return appTemplate.set('testEntities', existingTests).set('tests', [])
  }

  return appTemplate
}

export function runMigrationsAndStart(appTemplate: any, nestedGlobalWidgetsCache: NestedGlobalWidgets = {}) {
  // eslint-disable-next-line no-console
  console.log('[DBG] page load: init app', new Date().getTime() - window.htmlLoadedAt)
  return async (dispatch: any, getState: () => RetoolState) => {
    const appMigrateTimer = datadogReporter.startTimer()
    appTemplate = appTemplate.update('plugins', (plugins: any) => {
      return plugins.filter((p: any) => !!p.get('id'))
    })
    let newAppTemplate = appTemplate
    const globalWidgets = getGlobalWidgetsFromTemplate(appTemplate)
    newAppTemplate = migrateTests(appTemplate)
    const postCreationCallbacks = []
    if (globalWidgets && globalWidgets.length > 0) {
      const additionalPlugins = []

      // Fetch all the widgets we'll need
      const uniqueUUIDs: string[] = [
        ...new Set([
          ...globalWidgets.map((globalWidget) => globalWidget.get('template').get('pageUuid')), // all first level modules
          ...Object.keys(nestedGlobalWidgetsCache), // deeply nested modules
        ]),
      ]
      const childSaves = await Promise.all(
        uniqueUUIDs.map((uuid) => {
          return nestedGlobalWidgetsCache[uuid] || getChildPage(uuid)
        }),
      )

      dispatch(storeNestedModules(keyBy(childSaves, 'moduleUuid')))

      const uuidToAppTemplate: { [k: string]: AppTemplate | undefined } = {}
      for (const [index, childSave] of childSaves.entries()) {
        const uuid = uniqueUUIDs[index]
        uuidToAppTemplate[uuid] = childSave.appTemplate
      }

      // Find all of the plugins we need to add for global widgets
      for (const globalWidget of globalWidgets) {
        const modulePageUuid = globalWidget.get('template').get('pageUuid')

        const appTemplate = uuidToAppTemplate[modulePageUuid]

        if (appTemplate === undefined) {
          continue
        }

        const { childPlugins, globalInputs, initGlobalOutputsCallbacks } = await getPluginsForGlobalWidget(
          globalWidget.get('id'),
          globalWidget,
          undefined,
          nestedGlobalWidgetsCache,
        )

        additionalPlugins.push(...childPlugins)

        const template = newAppTemplate.getIn(['plugins', globalWidget.get('id'), 'template'])
        const templateWithInputProps = initializeGlobalWidgetProps(globalWidget.get('id'), globalInputs, template)

        newAppTemplate = newAppTemplate.setIn(['plugins', globalWidget.get('id'), 'template'], templateWithInputProps)

        const globalOutputs = getGlobalOutputs(appTemplate)
        // Outputs need to be set last after the GlobalWidget has been created
        // because they refer to the properties inside the module e.g. {{global1::myOutput1.value}}
        // and global1::myOutput.value needs to exist in the dependency graph before we add
        // the output so we can draw an edge between the two. (Otherwise, there will be no edge created
        // and the output will never update.)
        postCreationCallbacks.push(createModuleOutputCreationActions(globalWidget, globalOutputs))

        if (initGlobalOutputsCallbacks && initGlobalOutputsCallbacks.length > 0) {
          postCreationCallbacks.push(...initGlobalOutputsCallbacks)
        }
      }

      additionalPlugins.forEach((plugins) => {
        newAppTemplate = newAppTemplate.mergeIn(['plugins'], plugins)
      })
    }

    const [migratedAppTemplate, migrated] = migrateAppTemplate(newAppTemplate)

    appMigrateTimer.end((duration) => ({
      type: 'frontend.performance.app_page.migrate',
      durationMs: duration,
    }))

    //Resetting the template will interfere with apps automatically generated as part of onboarding if the user generates them during
    if (
      !getState().onboarding.showOnboarding &&
      decodeURIComponent(window.location.pathname).indexOf('Onboarding Page') !== -1
    ) {
      dispatch({ type: RESET_TEMPLATE })
    }
    if (migrated) {
      dispatch({ type: MIGRATIONS_SUCCESS, payload: migratedAppTemplate })
      dispatch(sendSave({ trigger: 'migrated' }))
    } else {
      dispatch({ type: MIGRATIONS_UP_TO_DATE, payload: migratedAppTemplate })
    }
    await dispatch(initializeFromTemplate(appTemplateSelector(getState())))
    if (migratedAppTemplate.isGlobalWidget) {
      dispatch({ type: MODEL_BROWSER_OPEN })
    }
    dispatch({ type: RESTORE_LOADED, payload: migratedAppTemplate })

    if (postCreationCallbacks && postCreationCallbacks.length > 0) {
      const postCreationCallbacksTimer = datadogReporter.startTimer()
      dispatchModuleOutputCreationActions(dispatch, postCreationCallbacks)
      postCreationCallbacksTimer.end((duration) => ({
        type: 'frontend.performance.app_page.run_post_creation_callbacks',
        durationMs: duration,
      }))
    }

    // We don't want users to accidentally undo the save load.
    dispatch(reduxUndoActionCreators.clearHistory())

    // Instantiate the shortcuts
    extendDefaultShortcuts(appTemplate.customShortcuts)
  }
}

function handlePluginCreate(state: AppTemplate, action: { payload: { template: PluginTemplate } }) {
  const { template } = action.payload
  return state.setIn(['plugins', template.get('id')], template)
}

function handlePluginUpdateId(state: any, action: any) {
  const { pluginId, newId } = action.payload

  return state
    .update('plugins', (plugins: any) => plugins.mapKeys((k: any) => (k === pluginId ? newId : k)))
    .update('plugins', (plugins: any) =>
      plugins.map((plugin: any) => {
        if (plugin.position2 && plugin.position2.container === pluginId) {
          return plugin.set('position2', plugin.position2.set('container', newId))
        }
        if (plugin.mobilePosition2 && plugin.mobilePosition2.container === pluginId) {
          return plugin.set('mobilePosition2', plugin.mobilePosition2.set('container', newId))
        }
        return plugin
      }),
    )
    .setIn(['plugins', newId, 'id'], newId)
}

function handleReorderTabbedContainer(state: AppTemplate, action: any) {
  const { tabbedContainerId, index, newIndex } = action.payload
  return state.reorderTabs(tabbedContainerId, index, newIndex)
}

function handleReorderQueries(state: AppTemplate, action: any) {
  const { originalIndex, newIndex } = action.payload
  return state.reorderQueries(originalIndex, newIndex)
}

function handleWidgetReposition2(state: any, action: any) {
  const { moves, largeScreen } = action.payload
  const positionKey = largeScreen ? 'position2' : 'mobilePosition2'
  return moves.reduce((newState: any, widgetMove: any) => {
    const { widgetIds, move } = widgetMove
    return widgetIds.reduce((newState: any, widgetId: any) => {
      return newState.updateIn(['plugins', widgetId, positionKey], (pos: any) => {
        return pos.applyDiff(move)
      })
    }, newState)
  }, state)
}

function handleWidgetSetMobilePosition2(state: any, action: any) {
  const { widgetId, newPosition } = action.payload
  return state.updateIn(['plugins', widgetId, 'mobilePosition2'], () => {
    if (newPosition) {
      return new Position2(newPosition)
    }
    return null
  })
}

function handleWidgetSetPosition2(state: any, action: any) {
  const { widgetId, newPosition } = action.payload
  return state.updateIn(['plugins', widgetId, 'position2'], () => {
    if (newPosition) {
      return new Position2(newPosition)
    }
    return null
  })
}

function handleDatasourceTypeChange(state: any, action: any) {
  const { pluginId, resourceName, newType, oldType } = action.payload
  if (newType === oldType) {
    return state.setIn(['plugins', pluginId, 'resourceName'], resourceName)
  } else {
    const Type = TemplateResolver(newType)
    return state
      .setIn(['plugins', pluginId, 'subtype'], newType)
      .setIn(['plugins', pluginId, 'resourceName'], resourceName)
      .setIn(['plugins', pluginId, 'template'], Type())
  }
}

function handlePluginFolderChange(state: any, action: any) {
  const { pluginId, folderName } = action.payload
  return state.setIn(['plugins', pluginId, 'folder'], folderName)
}

function handlePluginDelete(state: AppTemplate, action: { payload: string[] }) {
  const idsToDelete = action.payload.slice()

  // refactor to deleteAll once we update Immutable.js
  const newPlugins = idsToDelete.reduce((map: any, key: any) => {
    retoolAnalyticsTrack('Primitive Deleted', {
      type: primitiveTypeToAnalyticsType[map.getIn([key, 'type'])],
      subtype: map.getIn([key, 'subtype']),
    })
    return map.delete(key)
  }, state.plugins)
  const newState = state.set('plugins', newPlugins)

  return newState
}

function handlePluginsClear(state: any) {
  return state.set('plugins', Immutable.OrderedMap())
}

function handleBatchTemplateCreate(state: any, action: { payload: any }) {
  return batchTemplateCreateHelper(state, action.payload.templates)
}

function handleTemplateCreate(state: any, action: any) {
  return batchTemplateCreateHelper(state, [action.payload.template])
}

function batchTemplateCreateHelper(state: any, templates: any[]) {
  let newState = state
  for (const template of templates) {
    const widgetId = template.get('id')
    newState = newState.setIn(['plugins', widgetId], template)
  }
  return newState
}

function handleTearDownPage(state: any) {
  return state.clear()
}

function handleDisableResponsiveLayout(state: any, action: any) {
  return state.set('responsiveLayoutDisabled', action.payload.disabled)
}

function handleUpdatePreloadedAppJavaScript(state: any, action: any) {
  return state.set('preloadedAppJavaScript', action.payload.preloadedAppJavaScript)
}

function handleUpdatePreloadedAppJSLinks(state: any, action: any) {
  return state.set('preloadedAppJSLinks', action.payload.preloadedAppJSLinks)
}

function handleUpdateAppStyles(state: AppTemplate, action: { payload: { value: string } }) {
  return state.set('appStyles', action.payload.value)
}

function handleSetDisableLoadingIndicators(state: any, action: any) {
  return state.set('loadingIndicatorsDisabled', action.payload.disabled)
}

function handleFolderCreate(state: any, action: { payload: { folderName: string } }) {
  return state.set('folders', state.get('folders').push(action.payload.folderName))
}

function handleFolderMove(state: AppTemplate, action: { payload: FolderMovePayload }) {
  return state.set('folders', action.payload.folders)
}

function handleFolderRename(state: AppTemplate, action: { payload: FolderRenamePayload }) {
  const { id, newId } = action.payload
  return state
    .update('folders', (folders) => folders.splice(folders.indexOf(id), 1, newId))
    .update('plugins', (plugins) =>
      plugins.map((template) => {
        if (template.get('folder') === id) {
          return template.set('folder', newId)
        }
        return template
      }),
    )
}

function handleQueryMove(state: AppTemplate, action: { payload: QueryMovePayload }) {
  const { newIndex, oldIndex, newFolder } = action.payload
  return state.update('plugins', (plugins) => {
    const pluginList = plugins.toList()

    const movedQuery = pluginList.get(oldIndex)
    if (!movedQuery) return plugins

    const removeIndex = oldIndex + (newIndex <= oldIndex ? 1 : 0)
    return pluginList
      .splice(newIndex, 0, movedQuery.set('folder', newFolder))
      .splice(removeIndex, 1)
      .toOrderedMap()
      .mapKeys((_, v) => v.get('id'))
  })
}

function handleFolderDelete(state: AppTemplate, action: { payload: { folderName: string } }) {
  const { folderName } = action.payload
  return state
    .set(
      'folders',
      state.get('folders').filter((x: string) => x !== folderName),
    )
    .update('plugins', (plugins) =>
      plugins.map((plugin) => (plugin.get('folder') === folderName ? plugin.set('folder', '') : plugin)),
    )
}

function handleSetCustomDocumentTitle(state: any, action: { payload: { customDocumentTitle: string } }) {
  return state.set('customDocumentTitle', action.payload.customDocumentTitle)
}

function handleEnableCustomDocumentTitle(state: any, action: { payload: { enabled: boolean } }) {
  return state.set('customDocumentTitleEnabled', action.payload.enabled)
}

function handleSetCustomShortcuts(
  state: any,
  action: { payload: { customShortcuts: { shortcut: string; action: string }[] } },
) {
  return state.setCustomShortcuts(action.payload.customShortcuts)
}

function handleUpdateGlobalStyles(state: AppTemplate, action: { payload: { appThemeId: number } }) {
  return state.set('appThemeId', action.payload.appThemeId)
}

const ACTION_HANDLERS: any = {
  [WIDGET_TEMPLATE_CREATE]: handleTemplateCreate,
  [BATCH_WIDGET_TEMPLATE_CREATE]: handleBatchTemplateCreate,
  [WIDGET_REPOSITION2]: handleWidgetReposition2,
  [WIDGET_SET_POSITION2]: handleWidgetSetPosition2,
  [WIDGET_SET_MOBILE_POSITION2]: handleWidgetSetMobilePosition2,
  [PLUGIN_UPDATE]: (state: AppTemplate, action: any) =>
    state.mergeDeepIn(['plugins', action.payload.pluginId], action.payload.newPlugin),
  [WIDGET_TEMPLATE_UPDATE]: handleTemplateUpdate,
  [BATCH_WIDGET_TEMPLATE_UPDATE]: handleBatchTemplateUpdate,
  [FUNCTION_TEMPLATE_CREATE]: handlePluginCreate,
  [STATE_TEMPLATE_CREATE]: handlePluginCreate,
  [DATASOURCE_TEMPLATE_CREATE]: handlePluginCreate,
  [INSTRUMENT_TEMPLATE_CREATE]: handlePluginCreate,
  [FRAME_TEMPLATE_CREATE]: handlePluginCreate,
  [PLUGIN_UPDATE_ID]: handlePluginUpdateId,
  [DATASOURCE_TYPE_CHANGE]: handleDatasourceTypeChange,
  [PLUGIN_FOLDER_CHANGE]: handlePluginFolderChange,
  [PLUGIN_DELETE]: handlePluginDelete,
  [REQUEST_RESTORE]: (state: any) => state.set('isFetching', true),
  [MIGRATIONS_SUCCESS]: (state: any, action: any) => action.payload,
  [MIGRATIONS_UP_TO_DATE]: (state: any, action: any) => action.payload,
  [FAILURE_RESTORE]: (state: any) => state.set('isFetching', false),
  [TEARDOWN_PAGE]: handleTearDownPage,
  [RESET_TEMPLATE]: handleTearDownPage,
  [CLEAR_PLUGINS]: handlePluginsClear,
  [DISABLE_RESPONSIVE_LAYOUT]: handleDisableResponsiveLayout,
  [UPDATE_PRELOADED_APP_JAVASCRIPT]: handleUpdatePreloadedAppJavaScript,
  [UPDATE_TEST_ENTITY]: handleUpdateTestEntity,
  [MARK_TESTS_PENDING]: handleMarkTestsPending,
  [ADD_TEST_ENTITY]: handleAddTestEntity,
  [MOVE_TEST_ENTITY]: handleMoveTestEntity,
  [MOVE_TEST_ENTITY_IN_SUITE]: handleMoveTestEntityInSuite,
  [DELETE_TEST_ENTITY]: handleDeleteTestEntity,
  [UPDATE_PRELOADED_APP_JS_LINKS]: handleUpdatePreloadedAppJSLinks,
  [UPDATE_APP_STYLES]: handleUpdateAppStyles,
  [DISABLE_LOADING_INDICATORS]: handleSetDisableLoadingIndicators,
  [FOLDER_CREATE]: handleFolderCreate,
  [FOLDER_RENAME]: handleFolderRename,
  [FOLDER_MOVE]: handleFolderMove,
  [QUERY_MOVE]: handleQueryMove,
  [FOLDER_DELETE]: handleFolderDelete,
  [SET_CUSTOM_DOCUMENT_TITLE]: handleSetCustomDocumentTitle,
  [SET_CUSTOM_SHORTCUTS]: handleSetCustomShortcuts,
  [ENABLE_CUSTOM_DOCUMENT_TITLE]: handleEnableCustomDocumentTitle,
  [FAILURE_SAVE]: (state: any, action: GenericErrorResponse) => {
    message.error(action.payload.response ? action.payload.response.message : 'An unknown error occured.')
    return state
  },
  [URL_FRAGMENT_DEF_UPDATE]: handleUrlFragmentDefUpdate,
  [PAGE_LOAD_VALUE_OVERRIDE_UPDATE]: handlePageLoadOverridesUpdate,
  [REORDER_TABBED_CONTAINER]: handleReorderTabbedContainer,
  [REORDER_QUERIES]: handleReorderQueries,
  [UPDATE_GLOBAL_STYLES]: handleUpdateGlobalStyles,
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = new AppTemplate()

function appTemplateReducer(state = initialState, action: any) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

// We don't want to allow undo for actions that aren't user triggered
// and don't have a visible effect.
const notUndoableActions = Immutable.Set([REQUEST_RESTORE, FAILURE_RESTORE, TEARDOWN_PAGE, FAILURE_SAVE])

export default undoable(appTemplateReducer, {
  groupBy: batchUndoGroupBy,
  filter: (action) => {
    if (action.type === WIDGET_TEMPLATE_UPDATE && !action.isUserTriggered) {
      return false
    }
    // Only allow undo for actions handled by the appTemplate reducer (for now).
    return ACTION_HANDLERS[action.type] && !notUndoableActions.has(action.type)
  },
})

/* eslint-disable @typescript-eslint/no-explicit-any */
import { getProfile } from 'store/user'
import trackPageview from './trackPageview'
import { setEmbedFriendlyCookie } from 'common/utils'

const requireLogin = (store: any, onLoginSubdomain = false) => async (nextState: any, replace: any, cb: any) => {
  try {
    await store.dispatch(getProfile(onLoginSubdomain))
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Encountered an unexpected error', err)
  }

  trackPageview(nextState.location)

  if (window.MAIN_DOMAIN) {
    // We set the subdomain in a cookie so we can have unauthenticated links
    // from tryretool.com lead into the authenticated app (in the subdomain)

    setEmbedFriendlyCookie('subdomain', location.hostname, { domain: `.${window.MAIN_DOMAIN}` })
  }

  cb()
}

export default requireLogin

import { injectReducer } from '../../store/reducers'
import { browserHistory } from 'react-router'
import { getTags, pageLoad, pagesLoad, tearDownPage } from 'store/appModel/pages'
import { locationLoading, locationLoaded } from 'store/location'
import { getResources, getBranches } from 'store/user'
import requirePlugins from 'components/plugins/requirePlugins'
import { RESET_TEMPLATE } from 'store/appModel/constants'
import { pagePathFromUuid } from 'common/utils'
import { TREE_URL_PREFIX } from 'store/constants'
import type { RetoolStore } from 'store'

export default function PresentationRoute(store: RetoolStore) {
  const onEnter = (nextState: any) => {
    // eslint-disable-next-line no-console
    console.log('[DBG] page load: fetching route info', new Date().getTime() - window.htmlLoadedAt)

    store.dispatch({ type: RESET_TEMPLATE })
    store.dispatch(pagesLoad({ useCache: true }))
    store.dispatch(locationLoading())
    store.dispatch(getBranches({ useCache: true }))

    requirePlugins(() => {
      require.ensure(
        [],
        (require) => {
          const paginatedSavesReducer = require('store/saves')
          const presentation = require('./modules/presentation')
          injectReducer(store, { key: 'presentation', reducer: presentation.default })
          injectReducer(store, { key: 'paginatedSaves', reducer: paginatedSavesReducer.default })

          const branch = nextState.params.branch
          let splat = nextState.location.pathname.replace('/apps/', '')
          if (branch) {
            splat = nextState.location.pathname.replace(`${TREE_URL_PREFIX}/${encodeURIComponent(branch)}/apps/`, '')
          }
          async function loadPage() {
            store.dispatch(getResources({ useCache: true }))
            await store.dispatch(pageLoad(splat, false, undefined, nextState.location.query._releaseVersion, branch))
            await store.dispatch(getTags())
            await store.dispatch(presentation.checkQueryAuth())
          }
          loadPage()
        },
        'presentation',
      )
    })
    document.title = `${nextState.params.splat} | Retool`
    window.onbeforeunload = () => {}
  }

  const onLeave = () => {
    store.dispatch(tearDownPage())
  }

  /*  Async getComponent is only invoked when route matches   */
  const getComponent = (nextState: any, cb: any) => {
    /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
    require.ensure(
      [],
      (require) => {
        const App = require('./containers/PresentationContainer').default
        store.dispatch(locationLoaded())
        cb(null, App)
      },
      'presentation',
    )
  }
  return [
    {
      path: 'presentation/**',
      onEnter: (nextState: any) => {
        const splat = nextState.location.pathname.replace('/presentation/', '')
        browserHistory.push(`/apps/${splat}${window.location.search}${window.location.hash}`)
      },
    },
    {
      path: 'apps/**',
      onEnter,
      onLeave,
      getComponent,
    },
    {
      path: 'tree/:branch/apps/**',
      onEnter,
      onLeave,
      getComponent,
    },
    {
      path: 'uuid/apps/:uuid',
      onEnter: async (nextState: any, replace: any, cb: any) => {
        const uuid = nextState.params.uuid
        await store.dispatch(pagesLoad())
        const pagePath = pagePathFromUuid(store.getState(), uuid)

        const queryParams = nextState.location.search || ''
        const url = pagePath ? `/apps/${pagePath}${queryParams}` : '/pageNotFound'
        replace(url)
        cb()
      },
    },
  ]
}

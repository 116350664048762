import type { BooleanState } from 'common/hooks/useBooleanState'
import { compact, keyBy } from 'lodash'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetUserTwoFactorAuth, revokeInvitation, setUserEnabledFlag } from 'routes/Settings/modules/settings'
import { RetoolDispatch, RetoolThunk } from 'store'
import { foldersSelector, isAdminSelector, isGroupAdminSelector } from 'store/selectors'
import { inviteToOrg, updateUserSuggestionStatus } from 'store/user'
import type {
  Group,
  NewGroupFolderDefault,
  NewGroupPage,
  NewGroupFolderDefaultMap,
  NewGroupPageMap,
  NewGroupResource,
  UserInviteGroup,
  UserGroup,
} from '__globalShared__/permissions'
import type { Account, PartialAccount } from '__globalShared__/accounts'
import type { Page, PageWithFolderName } from '__globalShared__/pages'
import {
  groupFolderDefaultsByGroupIdSelector,
  groupPagesByGroupIdSelector,
  groupResourcesByGroupIdSelector,
  groupsArraySelector,
  groupsSelector,
  pagesArrraySelector,
  pagesSelector,
  resourceArraySelector,
  resourcesSelector,
  userGroupsByGroupIdSelector,
  userGroupsByUserIdSelector,
  userInviteGroupsByGroupIdSelector,
  userInviteGroupsByUserInviteIdSelector,
  userInvitesArraySelector,
  userInvitesSelector,
  userInviteSuggestionsArraySelector,
  userInviteSuggestionsSelector,
  usersArraySelector,
  foldersArraySelector,
  usersSelector,
  hasPermissionsFeatureSelector,
  userGroupsArraySelector,
  userInviteGroupsArraySelector,
} from './selectors'
import { UserRole, UserListFilter } from './types'
import {
  copyInviteLink,
  filterAccount,
  getEmailFromAccount,
  getIdFromAccount,
  groupIsAdmin,
  groupIsAllUsers,
  hasAccess,
  makeAccountFromSuggestion,
  makeAccountFromUser,
  makeAccountFromUserInvite,
  sortByAccessLevel,
  sortGroups,
  withHighestAccessLevel,
} from './utils'

export const useCurrentUserRole = (): UserRole => {
  const userIsAdmin = useSelector(isAdminSelector)
  const userIsGroupAdmin = useSelector(isGroupAdminSelector)
  if (userIsAdmin) {
    return UserRole.Admin
  } else if (userIsGroupAdmin) {
    return UserRole.GroupAdmin
  } else {
    return UserRole.Member
  }
}

export const useUserInviteGroups = (groupId: number): UserInviteGroup[] => {
  const userInviteGroups = useSelector(userInviteGroupsArraySelector)
  return useMemo(() => userInviteGroups.filter((userInviteGroup) => userInviteGroup.groupId === groupId), [
    groupId,
    userInviteGroups,
  ])
}

export const useUserGroups = (groupId: number): UserGroup[] => {
  const userGroups = useSelector(userGroupsArraySelector)
  return useMemo(() => userGroups.filter((userGroup) => userGroup.groupId === groupId), [groupId, userGroups])
}

export const useAccounts = (mutuallyExclusiveFilters: UserListFilter[]): Account[] => {
  const users = useSelector(usersArraySelector)
  const userInvites = useSelector(userInvitesArraySelector)
  const userInviteSuggestions = useSelector(userInviteSuggestionsArraySelector)

  return useMemo(() => {
    const userAccounts = users.map(makeAccountFromUser)
    const userInviteAccounts = userInvites.map((invite) => makeAccountFromUserInvite(invite, users[invite.invitedById]))
    const userInviteSuggestionAccounts = userInviteSuggestions.map((suggestion) =>
      makeAccountFromSuggestion(suggestion),
    )
    const allAccounts = userAccounts.concat(userInviteAccounts).concat(userInviteSuggestionAccounts)
    const filteredAccounts = allAccounts.filter((account) =>
      mutuallyExclusiveFilters.some((filter) => filterAccount(account, filter)),
    )
    return filteredAccounts
  }, [users, userInvites, userInviteSuggestions])
}

export const useGroups = () => {
  const groups = useSelector(groupsArraySelector)
  const hasPermissionsFeature = useSelector(hasPermissionsFeatureSelector)

  return useMemo(
    () =>
      groups
        .filter(({ name }) => {
          // if you have the permissions feature, you can see all groups
          if (hasPermissionsFeature) return true

          // otherwise, only show relevant groups
          return groupIsAllUsers(name) || groupIsAdmin(name)
        })
        .sort(sortGroups),
    [groups],
  )
}

export const useGroupsAccounts = (groupId?: number): Account[] => {
  const userGroups = useSelector(userGroupsByGroupIdSelector)
  const userInviteGroups = useSelector(userInviteGroupsByGroupIdSelector)
  const accounts = useAccounts(['enabled', 'invites'])

  return useMemo(() => {
    let accountIdsInGroup: string[] = []
    if (groupId && userGroups[groupId]) {
      accountIdsInGroup = accountIdsInGroup.concat(
        userGroups[groupId].map((userGroup) => getIdFromAccount({ id: userGroup.userId, type: 'user' })),
      )
    }
    if (groupId && userInviteGroups[groupId]) {
      accountIdsInGroup = accountIdsInGroup.concat(
        userInviteGroups[groupId].map((inviteGroup) =>
          getIdFromAccount({ id: inviteGroup.userInviteId, type: 'invite' }),
        ),
      )
    }
    const accountsById = keyBy(accounts, (account) => String(getIdFromAccount(account)))
    return compact(accountIdsInGroup.map((accountId) => accountsById[accountId]))
  }, [groupId, userGroups, userInviteGroups, accounts])
}

export const useGroupAdminAccounts = (groupId?: number): Account[] => {
  const accounts = useGroupsAccounts(groupId)
  const userGroups = useSelector(userGroupsArraySelector)
  return useMemo(() => {
    const groupAdminUserIds = userGroups
      .filter((userGroup) => userGroup.groupId === groupId && userGroup.isAdmin)
      .map((userGroup) => getIdFromAccount({ id: userGroup.userId, type: 'user' }))
    return accounts.filter((account) => groupAdminUserIds.includes(getIdFromAccount(account)))
  }, [groupId, accounts, userGroups])
}

export const useGroup = (groupId?: number): Group | undefined => {
  const groups = useSelector(groupsSelector)
  if (groupId) return groups[groupId]
}

export const useAccount = ({ id, type }: PartialAccount): Account | undefined => {
  const users = useSelector(usersSelector)
  const userInvites = useSelector(userInvitesSelector)
  const userInviteSuggestions = useSelector(userInviteSuggestionsSelector)

  if (type === 'user' && users[id]) {
    return makeAccountFromUser(users[id])
  }

  if (type === 'invite' && userInvites[id]) {
    const invite = userInvites[id]
    return makeAccountFromUserInvite(invite, users[invite.invitedById])
  }

  if (type === 'suggestion' && userInviteSuggestions[id]) {
    return makeAccountFromSuggestion(userInviteSuggestions[id])
  }
}

export const useGroupsForAccount = ({ id, type }: PartialAccount, orgGroups: Group[]) => {
  const groupMap = useMemo(() => keyBy(orgGroups, 'id'), [orgGroups])
  const userInviteGroups = useSelector(userInviteGroupsByUserInviteIdSelector)
  const userGroups = useSelector(userGroupsByUserIdSelector)

  return useMemo(() => {
    if (type === 'invite') {
      return compact(userInviteGroups[id]?.map(({ groupId }) => groupMap[groupId])) ?? []
    }

    if (type === 'user') {
      return compact(userGroups[id]?.map(({ groupId }) => groupMap[groupId])) ?? []
    }

    return []
  }, [id, type, groupMap, userInviteGroups, userGroups])
}

export const useAllUsersGroup = (groups: Group[]) => {
  // unlikely (maybe impossible currently) for an `All Users` group to not exist in redux,
  // but default to a placeholder ID just in case to avoid a crash
  return useMemo(() => groups.find(groupIsAllUsers) ?? { id: -1 }, [groups])
}

export const useAdminGroup = (groups: Group[]) => {
  return useMemo(() => groups.find(groupIsAdmin) ?? { id: -1 }, [groups])
}

export const useUniversalAccess = (groups?: Group[]) => {
  const [universalAccess] = useMemo(
    () => groups?.map(({ universalAccess }) => universalAccess).sort(sortByAccessLevel) ?? [],
    [groups],
  )
  return universalAccess
}

export const useUniversalResourceAccess = (groups?: Group[]) => {
  const [universalAccess] = useMemo(
    () => groups?.map(({ universalResourceAccess }) => universalResourceAccess).sort(sortByAccessLevel) ?? [],
    [groups],
  )
  return universalAccess
}

export const useValidWorkspacePages = (group: Group | undefined, groupPages: NewGroupPage[]): PageWithFolderName[] => {
  const pageMap = useSelector(pagesSelector)
  const pageArray = useSelector(pagesArrraySelector)
  const folders = useSelector(foldersSelector)

  const makePageWithFolderName = (page?: Page) => {
    if (!page) return null
    const folderName = folders.getIn([`${page.folderId}`, 'name'])
    return { ...page, folderName: folderName === 'root' ? undefined : folderName }
  }

  return useMemo(() => {
    if (hasAccess(group?.universalAccess)) {
      return compact(pageArray.map(makePageWithFolderName))
    }

    return compact(groupPages.map(({ pageId }) => makePageWithFolderName(pageMap[pageId])))
  }, [group, groupPages, pageArray, pageMap, folders])
}

export const useGroupPagesAndGroupFolderDefaultsForGroup = (group?: Group) => {
  const groups = useMemo(() => (group ? [group] : []), [group])
  return useGroupPagesAndGroupFolderDefaultsForGroups(groups)
}

export const useGroupPagesAndGroupFolderDefaultsForGroups = (
  groups: Group[],
): [NewGroupPage[], NewGroupFolderDefault[]] => {
  const groupPagesByGroupId = useSelector(groupPagesByGroupIdSelector)
  const groupFolderDefaultsByGroupId = useSelector(groupFolderDefaultsByGroupIdSelector)

  const groupFolders = useMemo(() => compact(groups?.flatMap(({ id }) => groupFolderDefaultsByGroupId[id])) ?? [], [
    groups,
    groupFolderDefaultsByGroupId,
  ])

  const groupPages = useMemo(() => compact(groups?.flatMap(({ id }) => groupPagesByGroupId[id])) ?? [], [
    groups,
    groupPagesByGroupId,
  ])

  return [groupPages, groupFolders]
}

export const useGroupResourcesForGroup = (group?: Group): NewGroupResource[] => {
  const groupResourcesByGroupId = useSelector(groupResourcesByGroupIdSelector)

  return useMemo(() => (group && groupResourcesByGroupId[group.id] ? groupResourcesByGroupId[group.id] : []), [
    groupResourcesByGroupId,
    group,
  ])
}

export const usePermissionedPagesAndFoldersForGroups = (groups: Group[]): [NewGroupPage[], NewGroupFolderDefault[]] => {
  const pages = useSelector(pagesArrraySelector)
  const folders = useSelector(foldersArraySelector)

  const universalAccess = useUniversalAccess(groups)
  const [groupPages, groupFolderDefaults] = useGroupPagesAndGroupFolderDefaultsForGroups(groups)

  return useMemo(() => {
    // map pageId to groupPage
    const groupPageMap: NewGroupPageMap = {}
    const groupFolderDefaultMap: NewGroupFolderDefaultMap = {}
    if (hasAccess(universalAccess)) {
      pages.forEach(({ id }) => {
        groupPageMap[id] = { pageId: id, accessLevel: universalAccess }
      })
      folders.forEach(({ id }) => {
        groupFolderDefaultMap[id] = { folderId: id, accessLevel: universalAccess }
      })
    }

    // if the user has "own" access to all pages & folders, we don't need to continue
    if (universalAccess === 'own') {
      return [Object.values(groupPageMap), Object.values(groupFolderDefaultMap)]
    }

    return [
      withHighestAccessLevel(
        groupPages,
        ({ pageId }) => pageId,
        ({ pageId }, accessLevel) => ({ pageId, accessLevel }),
        groupPageMap,
      ),
      withHighestAccessLevel(
        groupFolderDefaults,
        ({ folderId }) => folderId,
        ({ folderId }, accessLevel) => ({ folderId, accessLevel }),
        groupFolderDefaultMap,
      ),
    ]
  }, [universalAccess, groupPages, groupFolderDefaults, folders, pages])
}

export const usePermissionedResourcesForGroup = (group?: Group) => {
  const groups = useMemo(() => (group ? [group] : []), [group])
  return usePermissionedResourcesForGroups(groups)
}

export const usePermissionedResourcesForGroups = (groups?: Group[]) => {
  const groupResourcesByGroupId = useSelector(groupResourcesByGroupIdSelector)
  const resources = useSelector(resourceArraySelector)
  const resourceMap = useSelector(resourcesSelector)

  const groupResources = useMemo(() => compact(groups?.flatMap(({ id }) => groupResourcesByGroupId[id])) ?? [], [
    groups,
    groupResourcesByGroupId,
  ])

  const universalResourceAccess = useUniversalResourceAccess(groups)

  return useMemo(() => {
    if (hasAccess(universalResourceAccess)) {
      return resources
    }

    return compact(groupResources.map(({ resourceId }) => resourceMap[resourceId]))
  }, [resources, universalResourceAccess, groupResources, resourceMap])
}

export const useAccountActions = (account: Account, groupIds: number[], loadingState?: BooleanState) => {
  const { id } = account

  const dispatch = useDispatch<RetoolDispatch>()

  const withLoading = async <T>(action: RetoolThunk<Promise<T>>) => {
    loadingState?.setTrue()
    const ret = await dispatch(action)
    loadingState?.setFalse()
    return ret
  }

  const email = getEmailFromAccount(account)
  const orgGroups = useSelector(groupsArraySelector)
  const allUsersGroup = useAllUsersGroup(orgGroups)

  const disableUser = () => withLoading(setUserEnabledFlag(id, false))
  const enableUser = () => withLoading(setUserEnabledFlag(id, true))

  const revokeInvite = () => withLoading(revokeInvitation(id))
  const resendInvite = async () => {
    const payload = await withLoading(inviteToOrg([email], groupIds, true))

    // TODO: create a re-invite endpoint
    if (payload && payload.invitees) {
      const [newInvite] = payload.invitees
      if (newInvite) {
        return makeAccountFromUserInvite(newInvite)
      }
    }
  }

  const declineSuggestion = () => withLoading(updateUserSuggestionStatus(id, email, 'decline', []))
  const approveSuggestion = async () => {
    const payload = await withLoading(updateUserSuggestionStatus(id, email, 'approve', [allUsersGroup.id.toString()]))
    return makeAccountFromUserInvite(payload.invitee) as Account | undefined
  }

  const reset2FA = () => withLoading(resetUserTwoFactorAuth(id))
  const copyInvite = () => {
    if (account.type === 'invite') {
      copyInviteLink(account.signupToken)
    }
  }

  return {
    disableUser,
    enableUser,
    resendInvite,
    reset2FA,
    revokeInvite,
    declineSuggestion,
    approveSuggestion,
    copyInvite,
  }
}

import React from 'react'
import './ListEmptyState.scss'

export type ListEmptyStateProps = {
  content?: React.ReactNode
}

const ListEmptyState: React.FC<ListEmptyStateProps> = ({ content = 'No items' }) => (
  <div className="list-empty-state">{content}</div>
)

export default ListEmptyState

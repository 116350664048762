import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Spin, Alert } from 'antd'
import { suggestUsersToInviteToOrg } from 'store/user'
import { TextInput, Button, Message, Icon } from 'components/design-system'

import { ConnectProps } from 'store/storeUtils'

const EmailInviteInputs = (props: { emails: string[]; onChange: (str: string, num: number) => void }) => {
  const inviteEmailsList = _.range(props.emails.length).map((num) => (
    <TextInput
      className="mb8"
      key={`${num}-user-invite-text-input`}
      placeholder="example@email.com"
      value={props.emails[num]}
      onChange={(e) => props.onChange(e.target.value, num)}
    />
  ))
  return <>{inviteEmailsList}</>
}

const mapDispatchToProps = {
  suggestUsersToInviteToOrg,
}

type ReduxProps = ConnectProps<null, typeof mapDispatchToProps>

type UserSuggestionFormProps = Pick<ReduxProps, 'suggestUsersToInviteToOrg'> & {
  onSuggest: () => void
}

type UserSuggestionFormState = {
  loading: boolean
  emails: string[]
  suggestSuccessMessage: string
  suggestErrorMessage: React.ReactNode
}

export type SuggestUsersPayloadType = {
  message: string
  suggestedUsers: { suggestedEmail: string }[]
}
export class UserSuggestionForm extends React.Component<UserSuggestionFormProps, UserSuggestionFormState> {
  constructor(props: UserSuggestionFormProps) {
    super(props)

    this.state = {
      loading: false,
      emails: ['', '', ''],
      suggestErrorMessage: '',
      suggestSuccessMessage: '',
    }
  }

  handleEmailEdit = (updatedEmail: string, arrayIndex: number) => {
    const updatedEmails = this.state.emails.map((email, i) => (i === arrayIndex ? updatedEmail : email))
    this.setState({ emails: updatedEmails })
  }

  suggestUsers = async () => {
    this.setState({ loading: true })

    const emailsToSuggest = this.state.emails.filter((email) => email.length > 0)

    try {
      const payload: SuggestUsersPayloadType = await this.props.suggestUsersToInviteToOrg(emailsToSuggest)

      // we may have only suggested a part of the email list
      const suggestedEmails = payload.suggestedUsers.map((i: { suggestedEmail: string }) => i.suggestedEmail)
      const invalidEmails = emailsToSuggest.filter((email) => !suggestedEmails.find((e: string) => e === email))
      const nextState = { loading: false, suggestSuccessMessage: '', suggestErrorMessage: '' }
      if (invalidEmails.length > 0) {
        if (suggestedEmails.length > 0) {
          nextState.suggestSuccessMessage = payload.message
        }
        nextState.suggestErrorMessage = `Couldn't suggest ${
          invalidEmails.length
        } user(s) because they have an existing Retool Account or have already been invited: ${invalidEmails.join(
          ', ',
        )}`
      } else {
        Message.success(payload.message)
        this.props.onSuggest()
      }
      this.setState(nextState)
    } catch (err) {
      this.setState({
        loading: false,
        suggestErrorMessage: (
          <div
            className="error-message-display"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: err.message,
            }}
          />
        ),
      })
    }
  }

  render() {
    const { emails } = this.state

    return (
      <Spin spinning={this.state.loading}>
        <h3 className="fw-600 dark-gray fs-16 mb4 lh-28">Suggest new members</h3>
        <p className="gray fs-12 mb12 lh-16">
          Your request will be sent to your admins and you will be notified about the status of your suggestion through
          email.
        </p>
        <p className="fw-500 dark-gray fs-12 mb8 lh-16">Emails</p>
        <EmailInviteInputs emails={emails} onChange={this.handleEmailEdit} />
        <a
          href="#"
          className="flex items-center"
          onClick={() => {
            this.setState({ emails: [...emails, ''] })
          }}
        >
          <Icon type="plus" className="blue" style={{ marginRight: '5px' }} />
          <span className="fw-600 blue fs-12">Add more emails</span>
        </a>
        <div className="invite-form__sub-container__groups-action flex justify-end">
          <Button type="primary" disabled={this.state.emails.length === 0} onClick={this.suggestUsers} className="mt12">
            Suggest new members
          </Button>
        </div>
        {this.state.suggestSuccessMessage && (
          <Alert
            style={{ marginTop: 10, marginBottom: 15 }}
            message={this.state.suggestSuccessMessage}
            type="success"
            className="mb12"
          />
        )}
        {this.state.suggestErrorMessage && (
          <Alert className="mb12" message={this.state.suggestErrorMessage} type="error" />
        )}
      </Spin>
    )
  }
}

export default connect(null, mapDispatchToProps)(UserSuggestionForm)

import { message } from 'antd'
import { callInternalApi } from 'networking'
import { RetoolDispatch, RetoolThunk } from 'store'
import {
  UPDATE_GROUP_RECEIVE,
  SET_GROUPS_FOR_ACCOUNT_RECEIVE,
  SET_ACCOUNTS_FOR_GROUP_RECEIVE,
  GET_PERMISSIONS_RECEIVE,
  SET_GROUP_ADMINS_RECEIVE,
  FAILURE_DELETE_GROUP,
  RECEIVE_DELETE_GROUP,
  REQUEST_DELETE_GROUP,
  FAILURE_CREATE_GROUP,
  RECEIVE_CREATE_GROUP,
  REQUEST_CREATE_GROUP,
} from 'store/constants'

import type { AccountType, User, UserInvite, UserInviteSuggestion } from '__globalShared__/accounts'
import { Page } from '__globalShared__/pages'
import type {
  Group,
  UserGroup,
  UserInviteGroup,
  GroupPage,
  GroupResource,
  Workspace,
  GroupFolderDefault,
  NewGroupResource,
  NewGroupFolderDefault,
  NewGroupPage,
  NewUserGroup,
  NewUserInviteGroup,
} from '__globalShared__/permissions'

export type GetPermissionsResult = {
  users: User[]
  userInvites: UserInvite[]
  userInviteSuggestions: UserInviteSuggestion[]
  groups: Group[]
  pages: Page[]
  userGroups: UserGroup[]
  groupPages: GroupPage[]
  groupFolderDefaults: GroupFolderDefault[]
  groupResources: GroupResource[]
  userInviteGroups: UserInviteGroup[]
}

const getPermissionsReceive = (result: GetPermissionsResult) => ({ type: GET_PERMISSIONS_RECEIVE, payload: result })

export const getPermissions = (): RetoolThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const result = await callInternalApi({
      url: `/api/organization/permissions`,
      method: 'GET',
    })
    dispatch(getPermissionsReceive(result))
  } catch (error) {
    return error.message as string
  }
}

type UpdateGroupPayload = {
  group?: Group
  groupPages?: NewGroupPage[]
  groupFolderDefaults?: NewGroupFolderDefault[]
  groupResources?: NewGroupResource[]
  userGroups?: NewUserGroup[]
  userInviteGroups?: NewUserInviteGroup[]
  workspace?: number | null
}

export type UpdateGroupResult = {
  group: Group
  groupPages: GroupPage[]
  groupFolderDefaults: GroupFolderDefault[]
  groupResources: GroupResource[]
  userGroups: UserGroup[]
  userInviteGroups: UserInviteGroup[]
  workspace: Workspace
}

const updateGroupReceive = (result: UpdateGroupResult) => ({ type: UPDATE_GROUP_RECEIVE, payload: result })

export const updateGroup = (
  groupId: number,
  changeset: UpdateGroupPayload,
): RetoolThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const result = await callInternalApi({
      url: `/api/organization/permissions/groups/${groupId}/update`,
      method: 'POST',
      body: changeset,
    })
    dispatch(updateGroupReceive(result))
    return
  } catch (error) {
    return error.message as string
  }
}

export type SetGroupsForAccountResult = { accountId: number } & (
  | { accountType: 'user'; userGroups: UserGroup[] }
  | { accountType: 'invite'; userInviteGroups: UserInviteGroup[] }
)

const setGroupsForAccountReceive = (result: SetGroupsForAccountResult) => ({
  type: SET_GROUPS_FOR_ACCOUNT_RECEIVE,
  payload: result,
})

export const setGroupsForAccount = (
  accountId: number,
  accountType: Exclude<AccountType, 'suggestion'>,
  groupIds: number[],
): RetoolThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const result = await callInternalApi({
      url: `/api/organization/permissions/account/${accountId}/setGroups`,
      method: 'POST',
      body: { accountType, groupIds },
    })
    dispatch(setGroupsForAccountReceive({ accountId, accountType, ...result }))
  } catch (error) {
    return error.message as string
  }
}

export type SetAccountsForGroupResult = {
  groupId: number
  userGroups: UserGroup[]
  userInviteGroups: UserInviteGroup[]
}

const setAccountsForGroupReceive = (result: SetAccountsForGroupResult) => ({
  type: SET_ACCOUNTS_FOR_GROUP_RECEIVE,
  payload: result,
})

export const setAccountsForGroup = (
  groupId: number,
  userGroups: NewUserGroup[],
  userInviteGroups: NewUserInviteGroup[],
): RetoolThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const result = await callInternalApi({
      url: `/api/organization/permissions/groups/${groupId}/setMembers`,
      method: 'POST',
      body: { userGroups, userInviteGroups },
    })
    dispatch(setAccountsForGroupReceive({ groupId, ...result }))
  } catch (error) {
    return error.message as string
  }
}

type SetGroupAdminsPayload = {
  userIds?: number[]
}

export type SetGroupAdminsResult = {
  groupId: number
  userGroups: UserGroup[]
}

const setGroupAdminsReceive = (result: SetGroupAdminsResult) => ({ type: SET_GROUP_ADMINS_RECEIVE, payload: result })

export const setGroupAdmins = (
  groupId: number,
  changeset: SetGroupAdminsPayload,
): RetoolThunk<Promise<string | undefined>> => async (dispatch) => {
  try {
    const result = await callInternalApi({
      url: `/api/organization/permissions/groups/${groupId}/setAdmins`,
      method: 'POST',
      body: changeset,
    })
    dispatch(setGroupAdminsReceive({ groupId, ...result }))
    return
  } catch (error) {
    return error.message as string
  }
}

interface ApiCreateGroupSuccessGroupType {
  createdAt: string
  id: number
  name: string
  organizationId: number
  universalAccess: string
  universalResourceAccess: string
  universalQueryLibraryAccess: string
  updatedAt: string
}
interface ApiCreateGroupSuccessType {
  group: ApiCreateGroupSuccessGroupType
}

interface CreateGroupRequestType {
  type: typeof REQUEST_CREATE_GROUP
}
const createGroupRequest = (): CreateGroupRequestType => {
  return {
    type: REQUEST_CREATE_GROUP,
  }
}

export interface CreateGroupReceiveType {
  type: typeof RECEIVE_CREATE_GROUP
  payload: {
    group: ApiCreateGroupSuccessGroupType
  }
}
const createGroupReceive = (json: ApiCreateGroupSuccessType): CreateGroupReceiveType => {
  message.success('Successfully created group.')
  return {
    type: RECEIVE_CREATE_GROUP,
    payload: {
      group: json.group,
    },
  }
}

export interface CreateGroupFailureType {
  type: typeof FAILURE_CREATE_GROUP
}
const createGroupFailure = (errorMessage: string): CreateGroupFailureType => {
  message.error(errorMessage)
  return {
    type: FAILURE_CREATE_GROUP,
  }
}

interface DeleteGroupRequestType {
  type: typeof REQUEST_DELETE_GROUP
}
const deleteGroupRequest = (): DeleteGroupRequestType => {
  return {
    type: REQUEST_DELETE_GROUP,
  }
}

export interface DeleteGroupReceiveType {
  type: typeof RECEIVE_DELETE_GROUP
}
const deleteGroupReceive = (): DeleteGroupReceiveType => {
  message.success('Successfully deleted group.')
  return {
    type: RECEIVE_DELETE_GROUP,
  }
}

export interface DeleteGroupFailureType {
  type: typeof FAILURE_DELETE_GROUP
}
const deleteGroupFailure = (errorMessage: string): DeleteGroupFailureType => {
  message.error(errorMessage)
  return {
    type: FAILURE_DELETE_GROUP,
  }
}

export const createGroup = (name: string) => async (
  dispatch: RetoolDispatch,
): Promise<ApiCreateGroupSuccessGroupType | false> => {
  dispatch(createGroupRequest())
  try {
    const result = await callInternalApi({
      url: '/api/organization/permissions/groups/',
      method: 'POST',
      body: { name },
    })
    // TODO: this call to refresh the permissions is not ideal - it should happen
    // on `createGroupReceive`
    dispatch(getPermissions())
    dispatch(createGroupReceive(result))
    return result.group
  } catch (error) {
    dispatch(createGroupFailure(error.message))
    return false
  }
}

export const deleteGroup = (groupId: number) => async (dispatch: RetoolDispatch) => {
  dispatch(deleteGroupRequest())
  try {
    await callInternalApi({
      url: `/api/organization/permissions/groups/${groupId}`,
      method: 'DELETE',
    })
    // TODO: this call to refresh the permissions is not ideal - it should happen
    // on `deleteGroupReceive`
    dispatch(getPermissions())
    dispatch(deleteGroupReceive())
  } catch (error) {
    dispatch(deleteGroupFailure(error.message))
  }
}

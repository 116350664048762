import { browserHistory } from 'react-router'
import groupBy from 'lodash/groupBy'

import type { SafeAny } from 'common/types'
import { copyToClipboard } from 'common/utils'
import { Message } from 'components/design-system'
import { FuzzyFindInput } from 'components/FuzzyFind'
import type { Group, AccessLevel, UniversalAccessUnion, MaybeUniversalAccessUnion } from '__globalShared__/permissions'
import type { Account, User, UserInvite, UserInviteSuggestion } from '__globalShared__/accounts'

import { UserListFilter } from './types'
import { paths } from './constants'

const ADMIN_GROUP_NAME = 'admin'
const EDITOR_GROUP_NAME = 'editor'
const VIEWER_GROUP_NAME = 'viewer'
const ALL_USERS_GROUP_NAME = 'All Users'

export const ACCESS_LEVEL_ORDER: MaybeUniversalAccessUnion[] = ['own', 'write', 'read', 'none', null]
export const PROTECTED_ACCESS_GROUPS = [ADMIN_GROUP_NAME, EDITOR_GROUP_NAME, VIEWER_GROUP_NAME] as const
export const PROTECTED_NAME_GROUPS = [...PROTECTED_ACCESS_GROUPS, ALL_USERS_GROUP_NAME] as const
export type ProtectedAccessGroupName = typeof PROTECTED_ACCESS_GROUPS[number]
export type ProtectedGroupName = typeof PROTECTED_NAME_GROUPS[number]

export const sortGroups = (groupA: Group, groupB: Group) => {
  const indexDiff =
    PROTECTED_NAME_GROUPS.indexOf(groupB.name as ProtectedGroupName) -
    PROTECTED_NAME_GROUPS.indexOf(groupA.name as ProtectedGroupName)

  // neither is a protected group, fallback to alphabetical
  if (!indexDiff) {
    return groupA.name.localeCompare(groupB.name)
  }

  return indexDiff
}

export const groupNameIsReserved = (name?: string): name is ProtectedGroupName =>
  !!name && PROTECTED_NAME_GROUPS.includes(name as ProtectedGroupName)

export const groupUniversalAccessIsProtected = (name?: string): name is ProtectedAccessGroupName =>
  !!name && PROTECTED_ACCESS_GROUPS.includes(name as ProtectedAccessGroupName)

export const groupIsAllUsers = (groupOrName?: string | Group) => {
  if (!groupOrName) return false
  if (typeof groupOrName === 'string') {
    return groupOrName === ALL_USERS_GROUP_NAME
  } else {
    return groupOrName.name === ALL_USERS_GROUP_NAME
  }
}

export const groupMembershipIsProtected = (name?: string) => groupIsAllUsers(name)

export const groupIsAdmin = (groupOrName?: string | Group) => {
  if (!groupOrName) return false
  if (typeof groupOrName === 'string') {
    return groupOrName === ADMIN_GROUP_NAME
  } else {
    return groupOrName.name === ADMIN_GROUP_NAME
  }
}

export const sortByAccessLevel = (accessA: MaybeUniversalAccessUnion, accessB: MaybeUniversalAccessUnion) =>
  ACCESS_LEVEL_ORDER.indexOf(accessA) - ACCESS_LEVEL_ORDER.indexOf(accessB)

export const hasAccess = (access?: MaybeUniversalAccessUnion): access is Exclude<UniversalAccessUnion, 'none'> =>
  !!access && access !== 'none'

export const getAccessLabel = (items: SafeAny[], universalAccess: MaybeUniversalAccessUnion) =>
  hasAccess(universalAccess) ? 'All' : items.length

export const getFuzzyFindInputFromAccount = (account: Account): FuzzyFindInput<Account> => [
  account,
  {
    name: getNameFromAccount(account).toLowerCase(),
    email: getEmailFromAccount(account).toLowerCase(),
  },
]

export const makeAccountFromUserInvite = (userInvite: UserInvite, invitedBy?: User): Account => ({
  ...userInvite,
  invitedBy,
  firstName: '',
  lastName: '',
  type: 'invite',
})

export const makeAccountFromUser = (user: User): Account => ({
  ...user,
  type: 'user',
})

export const makeAccountFromSuggestion = (userInviteSuggestion: UserInviteSuggestion): Account => ({
  ...userInviteSuggestion,
  type: 'suggestion',
})

// because we always store a universalAccess type in the db we have to check to see if any groupResources or groupPages are assigned
// a group can have "mixed" access but the universalAccess will be set to "none". this check ensures the UI represents that state
export const getDerivedUniversalAccess = <T extends MaybeUniversalAccessUnion>(groupItems: SafeAny[], access: T) =>
  access === 'none' && groupItems.length ? 'mixed' : access ?? 'mixed'

export const getIdFromAccount = (account: Pick<Account, 'type' | 'id'>) => `${account.type}--${account.id}` as SafeAny

export const getNameFromUser = (user?: User) => (user ? `${user.firstName} ${user.lastName}` : '')

export const getLastActiveFromAccount = (account: Account) => {
  if (account.type === 'user') {
    return account.lastActive ?? account.lastLoggedIn
  }
  return account.createdAt
}

const isAccount = (account: Account | User): account is Account => !!(account as Account).type

export const getEmailFromAccount = (account?: Account | User) => {
  if (!account) return ''

  if (!isAccount(account)) {
    return account.email
  }

  switch (account.type) {
    case 'user':
    case 'invite':
      return account.email

    case 'suggestion':
      return account.suggestedEmail

    default:
      return '' as never
  }
}

export const getFirstNameFromAccount = (account: Account) => {
  if (account.type === 'user') return account.firstName
  return ''
}

export const getLastNameFromAccount = (account: Account) => {
  if (account.type === 'user') return account.lastName
  return ''
}

export const getNameFromAccount = (account?: Account | User) => {
  if (!account) {
    return ''
  }

  if (!isAccount(account)) {
    return getNameFromUser(account)
  }

  switch (account.type) {
    case 'user':
      return getNameFromUser(account)

    default:
      return getEmailFromAccount(account)
  }
}

export const copyInviteLink = (token?: string | null) => {
  const inviteLinkPathname = `/auth/invite/${token ?? ''}`
  let inviteLinkUrl = `${window.location.origin}${inviteLinkPathname}`
  if (window.MAIN_DOMAIN) {
    inviteLinkUrl = `https://login.${window.MAIN_DOMAIN}${inviteLinkPathname}`
  }

  copyToClipboard(inviteLinkUrl)
  Message.success('Copied Invite URL to clipboard!')
}

export const navigateToGroupDetail = (group: Pick<Group, 'id' | 'name'>): void =>
  browserHistory.push(paths.createGroupDetailPath(group))

export const navigateToGroupList = (): void => browserHistory.push(paths.root)

export const getWorkspaceFromGroup = (group?: Group) => group?.workspace?.homePageId ?? null

export const paritionAccountListByType = (accounts: Account[]) => {
  const { user = [], invite = [], suggestion = [] } = groupBy(accounts, 'type')
  return {
    users: user,
    invites: invite,
    suggestions: suggestion,
  }
}

export const filterAccount = (account: Account, filter: UserListFilter): boolean => {
  // special case for when an invite is reinvited. we create a new row and until organization
  // is refetched two with that email will exist in redux. we currently set a flag to tell the UI
  // which is stale. TODO: change this!
  if (account.type === 'invite' && account.isReinvitedDuplicate) return false

  const enabled = account.type === 'user' && !!account.enabled
  const disabled = account.type === 'user' && !account.enabled
  const invited = account.type === 'invite'
  const suggested = account.type === 'suggestion'

  switch (filter) {
    case 'enabled':
      return enabled
    case 'disabled':
      return disabled
    case 'all':
      return enabled || invited || suggested
    case 'invites':
      return invited
    case 'suggestions':
      return suggested

    default:
      return false
  }
}

type AddIfChanged = {
  <T, S>(oldValue: T, newValue: T, formattedValue: S): S | undefined
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  <T, S>(oldValue: T, newValue: T): T | undefined
}

export const addIfChanged: AddIfChanged = <S, T>(oldValue: T, newValue: T, formattedValue?: S) => {
  if (oldValue !== newValue) {
    return formattedValue ?? newValue
  }
}

export const withHighestAccessLevel = <T extends { accessLevel: AccessLevel }>(
  entities: T[],
  getId: (t: T) => number,
  cb: (entity: T, accessLevel: AccessLevel) => T,
  map: { [key: number]: T },
) => {
  entities.forEach((entity) => {
    const id = getId(entity)
    const { accessLevel } = entity
    if (!map[id] || sortByAccessLevel(map[id].accessLevel, accessLevel) > 0) {
      map[id] = cb(entity, accessLevel)
    }
  })
  return Object.values(map)
}

export const createAccountMap = (accounts: Account[]) =>
  accounts.reduce((acc, account) => acc.set(getIdFromAccount(account), account), new Map<string, Account>())

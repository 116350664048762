import { notification } from 'antd'
import { PluginMethodConfig } from 'components/plugins'
import { assertIsNumber, assertIsString, assertPlainObject, assertStringLiteral } from 'types/typeguards'

const showNotification: PluginMethodConfig = {
  metadata: {
    label: 'Show notification',
    example: '`utils.showNotification({ title, description, notificationType, duration })`',
    description:
      'Shows a notification message on the top right corner of the screen for `duration` seconds (default 4.5s). Use this to display messages like error messages after a query fails. Supported `notificationTypes` are : "info" | "success" | "warning" | "error"',
    params: [
      {
        type: 'objectInput',
        name: 'options',
        props: {
          label: 'Options',
          params: [
            { type: 'codeInput', name: 'title', props: { label: 'Title' } },
            { type: 'codeInput', name: 'description', props: { label: 'Description' } },
            {
              type: 'select',
              name: 'notificationType',
              props: {
                allowDynamic: true,
                label: 'Type',
                defaultValue: 'info',
                options: [
                  { value: 'info', label: 'Info' },
                  { value: 'success', label: 'Success' },
                  { value: 'warning', label: 'Warning' },
                  { value: 'error', label: 'Error' },
                ],
              },
            },
            { type: 'codeInput', name: 'duration', props: { label: 'Duration (s)', placeholder: '4.5' } },
          ],
        },
      },
    ],
  },
  method: (params = {}) => {
    const errorMessage =
      "`NotificationParameters` must be of type { title?: string, description?: string, notificationType?: 'info' | 'success' | 'warning' | 'error', duration?: number }"

    assertPlainObject(params, errorMessage)

    const { title, description, notificationType = 'info', duration = 4.5 } = params
    assertIsString(notificationType, errorMessage)
    assertIsNumber(duration, errorMessage)

    const type = notificationType.toLowerCase() || 'info'
    assertStringLiteral(type, ['info', 'success', 'warning', 'error'], errorMessage)

    notification[type]({
      message: title == null ? 'Notification' : String(title),
      description: description == null ? undefined : String(description),
      duration,
    })
  },
}

export default showNotification

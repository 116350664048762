import React, { SVGProps } from 'react'
import classNames from 'classnames'
import * as Icons from './icons'

import './Icon.scss'

export const icons = {
  app: Icons.IconApp,
  'app-small': Icons.IconAppSmall,
  apps: Icons.IconApps,
  amplitude: Icons.Amplitude,
  'arrow-right': Icons.IconArrowRight,
  back: Icons.IconBack,
  backFitted: Icons.IconBackFitted,
  link: Icons.IconLink,
  clip: Icons.IconClip,
  unclip: Icons.IconUnclip,
  'blue-check': Icons.BlueCheck,
  'blue-check-circle': Icons.IconBlueCheckCircle,
  'blue-check-circle-light': Icons.IconBlueCheckCircleLight,
  'bottom-panel': Icons.IconBottomPanel,
  'caret-down': Icons.IconCaretDown,
  'caret-down-cropped': Icons.IconCaretDownCropped,
  'caret-left': Icons.IconCaretLeft,
  'caret-right': Icons.IconCaretRight,
  'caret-up': Icons.IconCaretUp,
  'chat-bubble': Icons.IconChatBubble,
  check: Icons.IconCheck,
  'check-circle': Icons.IconCheckCircle,
  'check-circle-dark': Icons.IconCheckCircleDark,
  'check-circle-light': Icons.IconCheckCircleLight,
  datadog: Icons.Datadog,
  ellipse: Icons.Ellipse,
  copy: Icons.IconCopy,
  'google-analytics': Icons.GoogleAnalytics,
  hamburger: Icons.IconHamburger,
  'horizontal-align-left': Icons.IconHorizontalAlignLeft,
  'horizontal-align-center': Icons.IconHorizontalAlignCenter,
  'horizontal-align-right': Icons.IconHorizontalAlignRight,
  'horizontal-align-justify': Icons.IconHorizontalAlignJustify,
  'vertical-align-top': Icons.IconVerticalAlignTop,
  'vertical-align-center': Icons.IconVerticalAlignCenter,
  'vertical-align-bottom': Icons.IconVerticalAlignBottom,
  close: Icons.IconClose,
  'close-cropped': Icons.IconCloseCropped,
  'close-circle': Icons.IconCloseCircle,
  'create-app-from-data': Icons.CreateAppFromData,
  'create-app-from-import': Icons.CreateAppFromImport,
  'create-app-from-template': Icons.CreateAppFromTemplate,
  'create-new-folder': Icons.CreateFolder,
  'dark-green-check-circle': Icons.IconDarkGreenCheckCircle,
  'data-editor': Icons.IconDataEditor,
  desktop: Icons.IconDesktop,
  'disabled-edit': Icons.IconDisabledEdit,
  doc: Icons.IconDoc,
  download: Icons.IconDownload,
  'drag-handle': Icons.IconDragHandle,
  'drag-handle-alt': Icons.IconDragHandleAlt,
  'exclamation-circle': Icons.IconExclamationCircle,
  'exclamation-circle-solid': Icons.IconExclamationCircleSolid,
  'exclamation-red-circle': Icons.IconExclamationRedCircle,
  'cross-circle-solid': Icons.IconCrossCircleSolid,
  favorite: Icons.IconFavorite,
  filter: Icons.IconFilter,
  folder: Icons.IconFolder,
  'folder-open': Icons.IconFolderOpen,
  function: Icons.IconFunction,
  hide: Icons.IconHide,
  'info-circle': Icons.IconInfoCircle,
  'info-circle-solid': Icons.IconInfoCircleSolid,
  'larger-check-circle': Icons.IconLargerCheckCircle,
  'left-panel': Icons.IconLeftPanel,
  'magnifying-glass': Icons.IconMagnifyingGlass,
  mobile: Icons.IconMobile,
  'more-info': Icons.IconMoreInfo,
  'more-info-h': Icons.IconMoreInfoHorizontal,
  pencil: Icons.IconPencil,
  plus: Icons.IconPlus,
  'plus-big': Icons.IconPlusBig,
  'plus-huge': Icons.IconPlusHuge,
  private: Icons.IconPrivate,
  'query-library': Icons.IconQueryLibrary,
  'query-library-colored': Icons.QueryLibraryColored,
  refresh: Icons.IconRefresh,
  resources: Icons.IconResources,
  'right-panel': Icons.IconRightPanel,
  run: Icons.IconRun,
  segment: Icons.Segment,
  shared: Icons.IconShared,
  show: Icons.IconShow,
  'smaller-check-circle': Icons.SmallerIconCheckCircle,
  'solid-right': Icons.IconSolidRight,
  terminal: Icons.Terminal,
  tooltip: Icons.IconTooltip,
  trash: Icons.IconTrash,
  tutorial: Icons.IconTutorial,
  'white-open-circle': Icons.IconWhiteOpenCircle,
  flows: Icons.Flows,
  maximize: Icons.Maximize,
  minimize: Icons.Minimize,
  module: Icons.IconModule,
  'module-small': Icons.IconModuleSmall,
  'dependency-graph': Icons.DependencyGraph,
  'legend-left-align': Icons.IconLegendLeftAlign,
  'legend-right-align': Icons.IconLegendRightAlign,
  'legend-bottom-align': Icons.IconLegendBottomAlign,
  'legend-top-align': Icons.IconLegendTopAlign,
  'legend-none': Icons.IconLegendNone,
  reset: Icons.IconReset,
  loading: Icons.Loading,
  tableViewBuildingBlock: Icons.TableBuildingBlock,
  'resource-query': Icons.ResourceQuery,
  'js-query': Icons.JSQuery,
  'import-query-library': Icons.QueryLibrary,
  'query-editor-folder': Icons.QueryEditorFolder,
  analytics: Icons.Analytics,
  transformer: Icons.Transformer,
  grid: Icons.Grid,
  list: Icons.List,
  'alert-success': Icons.IconAlertSuccess,
  'alert-error': Icons.IconAlertError,
  'alert-warning': Icons.IconAlertWarning,
  'alert-info': Icons.IconAlertInfo,
  branch: Icons.IconBranch,
  locked: Icons.IconLocked,
  create: Icons.IconCreate,
  'query-settings-icon': Icons.QuerySettings,
  globe: Icons.IconGlobe,
  help: Icons.Help,
  embedCode: Icons.IconEmbedCode,
  'help-docs': Icons.IconHelpDocs,
  'help-community': Icons.IconHelpCommunity,
  'help-support': Icons.IconHelpSupport,
  inspect: Icons.IconInspect,
  insert: Icons.IconInsert,
  brackets: Icons.IconBrackets,
  markdown: Icons.IconMarkdown,
  popOut: Icons.IconPopOut,
  'preview-markdown': Icons.IconPreviewMarkdown,
  frame: Icons.IconFrame,
  'user-admin': Icons.IconUserAdmin,
  'user-add': Icons.IconUserAdd,
}

export type IconType = keyof typeof icons

export type IconProps = {
  type: IconType
  rotate?: number
  style?: React.CSSProperties
  className?: string
  onClick?: React.MouseEventHandler<SVGSVGElement>
  accessibilityLabel?: string
} & SVGProps<SVGSVGElement>

const Icon = (props: IconProps): React.ReactElement => {
  const { type, style = {}, rotate, accessibilityLabel, className, onClick, ...otherProps } = props
  const Icon = icons[type]
  const styleWithRotation = {
    ...style,
    ...(rotate ? { transform: `${style.transform ?? ''}rotate(${rotate || 0}deg)` } : null),
  }

  return (
    <Icon
      {...otherProps}
      aria-label={accessibilityLabel}
      className={classNames('retool-icon', `retool-icon-${type}`, className, {
        'retool-icon-clickable': !!onClick,
      })}
      style={styleWithRotation}
      onClick={onClick}
    />
  )
}

export default Icon

import React from 'react'
import { Input } from 'antd'

import { InputField, SERVICE_ACCOUNT_PLACEHOLDER } from './common'
import { ResourceFormProps } from './types'

class GoogleCloudStorageForm extends React.Component<ResourceFormProps> {
  render() {
    return (
      <div className="grid-1c mb20">
        <label className="input-label">The private key associated with a Service Account with GCS privileges</label>
        <div className="light-gray">
          <Input.TextArea
            value={this.props.resource.options.serviceAccountKey}
            onChange={this.props.onChangeOption('serviceAccountKey', { handleEvent: true })}
            autosize={{ minRows: 12, maxRows: 12 }}
            placeholder={SERVICE_ACCOUNT_PLACEHOLDER}
          />
          See{' '}
          <a href="https://cloud.google.com/iam/docs/service-accounts" target="_blank" rel="noopener noreferrer">
            here
          </a>{' '}
          for documentation on service accounts.
        </div>

        <InputField label="GCS bucket name (optional)" resourceKey="gcs_default_bucket_name" {...this.props} />
      </div>
    )
  }
}

export default {
  label: 'Google Cloud Storage',
  form: GoogleCloudStorageForm,
  defaults: { options: { authentication: 'serviceAccount', gcs_default_bucket_name: '' } },
  validator: ({ options }: any) => {
    return !!options.serviceAccountKey
  },
}

import Immutable, { Record } from 'immutable'
import { JSON_HEADERS } from 'networking/util'
import { message } from 'antd'
import { browserHistory } from 'react-router'
import { callApi } from '../../../store/callApi'

// ------------------------------------
// Constants
// ------------------------------------
//

export const REQUEST_RESET_FINISH = 'REQUEST_RESET_FINISH'
export const RECEIVE_RESET_FINISH = 'RECEIVE_RESET_FINISH'
export const FAILURE_RESET_FINISH = 'FAILURE_RESET_FINISH'

export function confirmResetPassword(email: any, token: any, newPassword: any, newPasswordConfirm: any) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: `/api/user/confirmResetPassword`,
        body: JSON.stringify({ email, token, newPassword, newPasswordConfirm }),
        method: 'POST',
        headers: JSON_HEADERS,
        types: [REQUEST_RESET_FINISH, RECEIVE_RESET_FINISH, FAILURE_RESET_FINISH],
      }),
    )
    if (result.type === RECEIVE_RESET_FINISH) {
      message.success('Successfully reset password')
      browserHistory.push(`/auth/login`)
    } else if (result.type === FAILURE_RESET_FINISH) {
      message.error(result.payload.response.message)
    }
    return result
  }
}

const ACTION_HANDLERS: any = {
  [REQUEST_RESET_FINISH]: (state: any) => state.setIn(['confirmResetPassword', 'isFetching'], true),
  [RECEIVE_RESET_FINISH]: (state: any) => state.setIn(['confirmResetPassword', 'isFetching'], false),
  [FAILURE_RESET_FINISH]: (state: any) => state.setIn(['confirmResetPassword', 'isFetching'], false),
}

const defaultPasswordResetParams: any = {
  confirmResetPassword: Immutable.Map({
    isFetching: false,
    errorMessage: '',
  }),
}

class ConfirmResetPasswordModel extends Record(defaultPasswordResetParams) {}

const initialState = new ConfirmResetPasswordModel()

export default function resetPasswordReducer(state = initialState, action: any) {
  // eslint-disable-next-line no-console
  console.log('Got here')
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

import useBooleanState from './useBooleanState'

const useConditionallyShownPopover = () => {
  const isParentHovered = useBooleanState(false)
  const isPopoverOpen = useBooleanState(false)

  return {
    showPopoverTriggers: isParentHovered.value || isPopoverOpen.value,
    parentHandlers: {
      onMouseEnter: isParentHovered.setTrue,
      onMouseLeave: isParentHovered.setFalse,
    },
    popoverProps: {
      onPopoverClose: () => isPopoverOpen.setFalse(),
      onVisibleChange: (visible: boolean) => {
        visible ? isPopoverOpen.setTrue() : isPopoverOpen.setFalse()
      },
    },
  }
}

export default useConditionallyShownPopover

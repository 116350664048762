import React from 'react'

const NotFoundPage = () => (
  <div style={{ margin: 40 }}>
    404 :(
    <br />
    <a href="/"> go home </a>
  </div>
)

export default NotFoundPage

import { OnboardingStep, OnboardingSubStep } from 'common/records'
import { ResourceType } from 'common/resourceTypes'
import { fromJS } from 'immutable'
import _ from 'lodash'
import {
  BuildingBlock,
  BuildingBlockId,
  GOOGLE_SHEET_PLACEHOLDER,
  TABLE_PLACEHOLDER,
  TABLE_VIEW_BUILDING_BLOCK,
  WidgetBuildingBlock,
} from '../buildingBlocks'
import { allResourceSheets, allResourcesInput, allResourceTables } from 'components/BuildingBlocks/generatorInputs'

const viewTabularDataQueryName = 'tableData'
export const TABLE_ID = 'table1'

const constructBuildingBlock = () => {
  const widgets = []
  const queries = []

  const tableBuildingBlock: WidgetBuildingBlock = {
    id: `${TABLE_ID}`,
    type: 'TableWidget',
    parentBuildingBlockId: TABLE_VIEW_BUILDING_BLOCK,
    mergeWidget: true,
    options: {
      data: {
        value: `{{${viewTabularDataQueryName}.data}}`,
        priority: 1,
        hideInPreview: true,
      },
    },
  }

  widgets.push(tableBuildingBlock)

  const postgresSearchBuildingBlock = generatePostgresBuildingBlock()
  const googleSheetsBuildingBlock = generateGoogleSheetsBuildingBlock()
  const mysqlBlock = _.cloneDeep(postgresSearchBuildingBlock)
  mysqlBlock.type = 'mysql'

  queries.push(postgresSearchBuildingBlock)
  queries.push(mysqlBlock)
  queries.push(googleSheetsBuildingBlock)

  const tableWithSearchBuildingBlock: BuildingBlock = {
    id: 'tableView',
    displayName: 'View data',
    description: 'Display your information in a table',
    widgets,
    queries,
    showInEditor: true,
    showInOnboarding: false,
    generatorInputs: [allResourcesInput, allResourceTables, allResourceSheets],
  }

  return tableWithSearchBuildingBlock
}

const generatePostgresBuildingBlock = () => {
  const postgresSelectTabHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `#query-editor__tab-${viewTabularDataQueryName}`,
    title: `Click on ${viewTabularDataQueryName} `,
    hintText: `Navigate to right query from the bottom query editor.`,
    nextCondition: (_template, _model, editor) => {
      const allSelectedElements = editor.lastSelectedElements
      return allSelectedElements.has(`query-editor__tab-${viewTabularDataQueryName}`)
      //Query is defined and different from the template's query
    },
  })

  const postgresChangeTableHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `#code-editor-${viewTabularDataQueryName}`,
    title: 'Select a table',
    hintText: `Confirm ${TABLE_PLACEHOLDER} is the table you want to query.`,
    nextCondition: (_template, _model, editor) => {
      const allSelectedElements = editor.lastSelectedElements
      return allSelectedElements.has(`code-editor-${viewTabularDataQueryName}`)
      //Query is defined and different from the template's query
    },
  })

  const postgresOnboardingSubsteps = [postgresSelectTabHint, postgresChangeTableHint]
  const postgresOnboardingStep = new OnboardingStep({
    title: 'Adjust your postgres read query',
    substeps: fromJS(postgresOnboardingSubsteps),
    condition: postgresOnboardingSubsteps[postgresOnboardingSubsteps.length - 1].nextCondition,
  })

  return {
    id: viewTabularDataQueryName,
    parentBuildingBlockId: TABLE_VIEW_BUILDING_BLOCK as BuildingBlockId,
    type: 'postgresql' as ResourceType,
    options: {
      query: `select * from ${TABLE_PLACEHOLDER} limit 100`,
    },
    hintSequence: postgresOnboardingStep,
  }
}

const generateGoogleSheetsBuildingBlock = () => {
  return {
    id: viewTabularDataQueryName,
    type: 'googlesheets' as ResourceType,
    parentBuildingBlockId: TABLE_VIEW_BUILDING_BLOCK as BuildingBlockId,
    options: {
      actionType: 'read',
      spreadsheetId: TABLE_PLACEHOLDER,
      sheetName: GOOGLE_SHEET_PLACEHOLDER,
    },
  }
}

//TODO: figure out mongodb
/*const mongoDBBuildingBlock = () => {
  const mongoDBBuildingBlockHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${viewTabularDataQueryName}"] .cm-evaluation-wrapper`,
    hintText: 'Confirm the table and column you want to use',
    nextCondition: (template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      const queryState = editor.queryState.get(queryStateKey)?.toJS()
      const queryPlugin = template.plugins.get(viewTabularDataQueryName)?.toJS()
      //Query is defined and different from the template's query
      return (
        queryState?.query != null && queryState.query !== queryPlugin?.template.query && queryState.query.length > 0
      )
    },
  })

  const postgresOnboardingSubsteps = [postgresChangeTableHint]
  const postgresOnboardingStep = new OnboardingStep({
    title: 'Set up your postgres query',
    substeps: fromJS(postgresOnboardingSubsteps),
    condition: postgresOnboardingSubsteps[postgresOnboardingSubsteps.length - 1].nextCondition,
  })

  return {
    id: viewTabularDataQueryName,
    type: 'mongodb' as ResourceType,
    options: {
      query: `{ : { "$regex": {{textinput1.value ? textinput1.value : {$exists: true} }}, "$options": "i" } }`,
    },
    hintSequence: postgresOnboardingStep,
  }
}

const generateMysqlBuildingBlock = () => {
  const postgresChangeTableHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${viewTabularDataQueryName}"] .cm-evaluation-wrapper`,
    hintText: 'Confirm the table and column you want to use',
    nextCondition: (template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      const queryState = editor.queryState.get(queryStateKey)?.toJS()
      const queryPlugin = template.plugins.get(viewTabularDataQueryName)?.toJS()
      //Query is defined and different from the template's query
      return (
        queryState?.query != null && queryState.query !== queryPlugin?.template.query && queryState.query.length > 0
      )
    },
  })

  const mysqlOnboardingSubsteps = [postgresChangeTableHint]
  const mysqlOnboardingStep = new OnboardingStep({
    title: 'Set up your mysql query',
    substeps: fromJS(mysqlOnboardingSubsteps),
    condition: mysqlOnboardingSubsteps[mysqlOnboardingSubsteps.length - 1].nextCondition,
  })

  return {
    id: viewTabularDataQueryName,
    type: 'mysql' as ResourceType,
    options: {
      query: `select * from ${TABLE_PLACEHOLDER} where ${COLUMN_PLACEHOLDER} like {{"%" +textOne.value+ "%"}} limit 100`,
    },
    hintSequence: mysqlOnboardingStep,
  }
}

const generateRestBuildingBlock = () => {
  const restQueryUrl = 'https://represent.opennorth.ca/boundary-sets'
  const restChangeResourceHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${viewTabularDataQueryName}"] .resourceSelector`,
    hintText: 'Select your api from the resource dropdown ',
    nextCondition: (_template, _model, editor) => {
      //Need to use the editor rather then the template since we want the version before it's saved
      return (
        editor.selectedDatasourceId === viewTabularDataQueryName &&
        editor.selectedResourceName !== 'REST-WithoutResource'
      )
    },
  })

  const restChangeUrlHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${viewTabularDataQueryName}"] .http-query-editor .query-scroller`,
    hintText: 'Change the URL to select from your api',
    nextCondition: (_template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      return editor.queryState.get(queryStateKey)?.get('query') !== restQueryUrl
    },
  })

  const restOnboardingSubsteps = [restChangeResourceHint, restChangeUrlHint]
  const restOnobardingStep = new OnboardingStep({
    title: 'Set up your REST resource',
    substeps: fromJS(restOnboardingSubsteps),
    condition: (template, model, _editor) => {
      const data = model.values.get(`${TABLE_ID}`).get('data')
      return data.length > 0 && data[0].domain !== 'Acton Vale, QC'
    },
  })

  return {
    id: viewTabularDataQueryName,
    type: 'restapi' as ResourceType,
    options: {
      query: 'https://represent.opennorth.ca/boundary-sets',
      type: 'GET',
    },
    hintSequence: restOnobardingStep,
  }
}*/

export default constructBuildingBlock

import { GoogleSearchConsoleResourceSettings } from 'common/records'
import { SHARED_OAUTH_CALLBACK_URL } from 'components/ResourceForm/oauthForm'
import { generateResourceForm } from 'components/ResourceForm/resourceFormGenerator/generator'
import { AuthTemplate } from 'components/ResourceForm/resourceFormGenerator/generator-types'
import { defaultShowConnectedApp } from 'components/ResourceForm/resourceFormGenerator/OAuth2View'
import React from 'react'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'
import { transformHeadersForEditor } from '../../components/AuthTriggerInput'

const GOOGLE_AUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth'
const GOOGLE_TOKEN_URL = 'https://www.googleapis.com/oauth2/v4/token'

export const readonlyScope = 'https://www.googleapis.com/auth/webmasters.readonly'
export const editScope = 'https://www.googleapis.com/auth/webmasters'

const defaultOptions: GoogleSearchConsoleResourceSettings = {
  options: {
    customQueryParameters: [['', '']],
    customHeaders: [['Authorization', 'Bearer OAUTH2_TOKEN']],
    authentication: 'oauth2',
    oauth2_client_id: '',
    oauth2_client_secret: '',
    oauth2_scope: readonlyScope,
    oauth2_callback_url: SHARED_OAUTH_CALLBACK_URL,
    oauth2_auth_url: GOOGLE_AUTH_URL,
    oauth2_access_token_url: GOOGLE_TOKEN_URL,
    oauth2_share_user_credentials: true,
    spec: `${RETOOL_SPECS_DOMAIN}/google/search-console/3.0.0/v1.json`,
    verify_session_action_enabled: true,
    verify_session_action: {
      query: 'https://www.googleapis.com/webmasters/v3/sites',
      method: 'GET',
      type: 'http_request',
      runWhenModelUpdates: false,
      bodyType: 'raw',
      headers: transformHeadersForEditor([['Authorization', 'Bearer OAUTH2_TOKEN']]),
    },
  },
}

const auth: AuthTemplate = [
  {
    type: 'oauth2',
    options: {
      retoolHostedApp: {},
      customApp: {
        description: (
          <>
            To connect Retool to Google Search Console create a connected app in your Google account for Retool.{' '}
            <a target="_blank" href="https://docs.retool.com/docs/google-oauth-credentials" rel="noopener noreferrer">
              Here is a guide on how to create an application in Google
            </a>
          </>
        ),
      },
      isShareableAcrossUsers: true,
      availableScopes: [
        {
          label: 'Read only',
          description: 'Queries using this resource can read data from the Search Console API.',
          value: readonlyScope,
        },
        {
          label: 'Read and write',
          value: editScope,
          description: 'Queries using this resource can read data from and write data to the Search Console API.',
        },
      ],
    },
  },
]

export default generateResourceForm('Google Search Console', defaultOptions, {
  auth,
  hideTestConnection: () => true,
  hideCreateResourceButton: () => defaultShowConnectedApp(),
  isOAuth: () => true,
})

import React from 'react'
import { Select } from 'components/design-system'

import { DatabaseForm, databaseDefaults, databaseValidator, SSHTunnelForm, InputField } from './common'
import { ResourceFormProps } from './types'
import { isLaunchedUnifiedSqlResource } from 'components/plugins/datasources/SqlQueryUnified/model'

const TDS_VERSIONS = [
  {
    value: '7_1',
    label: '7.1 (SQL Server 2000)',
  },
  {
    value: '7_2',
    label: '7.2 (SQL Server 2005)',
  },
  {
    value: '7_3_A',
    label: '7.3a (SQL Server 2008)',
  },
  {
    value: '7_3_B',
    label: '7.3b (SQL Server 2008)',
  },
  {
    value: '7_4',
    label: '7.4 (default) ',
  },
]
const MSSQLServerForm = (props: ResourceFormProps) => (
  <SSHTunnelForm {...props}>
    <>
      <DatabaseForm
        placeholderHost="IP address or hostname of your database"
        placeholderPort="1433"
        placeholderUsername="retool"
        databaseNameOverrideSupported
        databaseCredentialsOverrideSupported
        isUnifiedSqlResource={isLaunchedUnifiedSqlResource(props.resource)}
        {...props}
      />
      <hr />
      <h5 className="section-heading light-gray mb12">Advanced </h5>
      <div className="grid-1c mb12">
        <InputField
          label="SQL Server Proxy host"
          placeholder="Host of the proxy server that we should use to connect to your database"
          resourceKey="sqlServerProxyHost"
          {...props}
        />
        <label className="input-label">TDS version</label>
        <Select showSearch value={props.resource.options.tds} onChange={props.onChangeOption('tds')}>
          {TDS_VERSIONS.map((tdsVersion) => (
            <Select.Option key={tdsVersion.value} value={tdsVersion.value}>
              {tdsVersion.label}
            </Select.Option>
          ))}
        </Select>
      </div>
    </>
  </SSHTunnelForm>
)

export default {
  label: 'MS SQL Server',
  form: MSSQLServerForm,
  defaults: {
    ...databaseDefaults,
    options: {
      ...databaseDefaults.options,
      tds: '7_4',
    },
    port: '1433',
  },
  validator: databaseValidator,
}

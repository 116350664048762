import React from 'react'
import classNames from 'classnames'

import { Alert as AntAlert } from 'antd'
import { AlertProps as AntAlertProps } from 'antd/lib/alert'
import Icon from 'components/design-system/Icon'

import './Alert.scss'

export type AlertProps = Omit<AntAlertProps, 'icon' | 'showIcon'>

const ALERT_ICONS: { [alertType in NonNullable<AlertProps['type']>]: React.ReactNode } = {
  success: <Icon type="check-circle" />,
  info: <Icon type="info-circle" />,
  warning: <Icon type="exclamation-circle" />,
  error: <Icon type="close-circle" />,
}

const Alert = (props: AlertProps) => {
  return (
    <AntAlert
      {...props}
      className={classNames('retool-alert', `retool-alert__${props.type || 'info'}`, props.className)}
      icon={ALERT_ICONS[props.type || 'info']}
      showIcon
    />
  )
}

export default Alert

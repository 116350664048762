import { applyMiddleware, compose, createStore as createReduxStore, StoreEnhancer } from 'redux'
import thunk from 'redux-thunk'
import { browserHistory } from 'react-router'
import { apiMiddleware_DEPRECATE as apiMiddleware } from 'networking/index.deprecated'
import { SafeAny } from '../common/types'

import crashMiddleware from './crashMiddleware'
import sentryLogMiddleware from './sentryLogMiddleware'
import queryMiddleware from './queryMiddleware'
import apiSuccessMiddleware from './apiSuccessMiddleware'
import makeRootReducer, { RootReducerCreator } from './reducers'
import { updateLocation } from './location'
import { publishStore, RetoolStore, RetoolMiddleware } from './'
import { applyAuthenticationHeaders } from './storeUtils'
import { mediaQuery, setLargeScreen, setSmallScreen } from './responsive'
import { onboardingMiddleware } from './onboarding/onboardingMiddleware'

// redux specifix extensions
declare const window: Window & {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
}

export default function createStore(initialState: any, asyncReducersToLoad?: any) {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware: RetoolMiddleware[] = [
    crashMiddleware,
    thunk,
    queryMiddleware,
    onboardingMiddleware,
    applyAuthenticationHeaders,
    apiMiddleware,
    sentryLogMiddleware,
    apiSuccessMiddleware,
  ]

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers: StoreEnhancer[] = []

  let composeEnhancers = compose

  if (__DEV__ || window.location.search.includes('withReduxDevTools')) {
    // see http://extension.remotedev.io/docs/API/Arguments.html
    const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    if (typeof composeWithDevToolsExtension === 'function') {
      composeEnhancers = composeWithDevToolsExtension({ trace: true, traceLimit: 200 } as SafeAny)
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createReduxStore(
    makeRootReducer(asyncReducersToLoad),
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers),
  ) as RetoolStore
  store.asyncReducers = {}

  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  store.unsubscribeHistory = browserHistory.listen(updateLocation(store))

  mediaQuery.addListener((mq) => {
    if (mq.matches) {
      store.dispatch(setLargeScreen())
    } else {
      store.dispatch(setSmallScreen())
    }
  })

  if (module.hot) {
    module.hot.accept(['./reducers', './onboarding/onboardingMiddleware'], () => {
      const reducers = (<{ default: RootReducerCreator }>require('./reducers')).default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }
  publishStore(store)
  return store
}

import React, { Component, SyntheticEvent } from 'react'

import { Form } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { TextInput, Button, Icon } from 'components/design-system'
import cookies from 'js-cookie'
import { REDIRECTING_TO_BILLING_PORTAL_COOKIE_NAME } from 'store/constants'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { MINIMUM_PASSWORD_LENGTH } from 'common/utils/authenticationHelpers'

const FormItem = Form.Item

interface SignupFormProps extends FormComponentProps {
  claimInvitation: any // <-- improve type after refactoring `login.js`
  signup: any // <-- improve type after refactoring `login.js`
  signupToken?: string
  signupEmail?: string
  invitedEmail: string
  signupTheme?: string
}

interface SignupFormState {
  firstName: string
  lastName: string
  email: string
  password: string
}

interface FormValidationErrorType {
  firstName?: {
    errors: Array<{
      field: string
      message: string
    }>
  }
  lastName?: {
    errors: Array<{
      field: string
      message: string
    }>
  }
  email?: {
    errors: Array<{
      field: string
      message: string
    }>
  }
  password?: {
    errors: Array<{
      field: string
      message: string
    }>
  }
}

class SignupForm extends Component<SignupFormProps, SignupFormState> {
  constructor(props: SignupFormProps) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: props.signupEmail || '',
      password: '',
    }
  }

  componentDidMount() {
    /**
     * If the browser autofills the input fields, this will make sure our component
     * state knows about it.
     */
    const { invitedEmail } = this.props
    const firstNameInput = document.getElementById('firstName') as HTMLInputElement
    const lastNameInput = document.getElementById('lastName') as HTMLInputElement
    let email = invitedEmail
    if (!invitedEmail) {
      // the email input field will not exist when a user is invited
      const emailInput = document.getElementById('email') as HTMLInputElement
      email = emailInput.value
    }
    const passwordInput = document.getElementById('password') as HTMLInputElement
    this.setState({
      firstName: firstNameInput.value,
      lastName: lastNameInput.value,
      email,
      password: passwordInput.value,
    })
  }

  componentDidUpdate(prevProps: SignupFormProps) {
    // HACK:
    //
    // validating the signup token takes some time.  the signup email isn't
    // available until signup token validation completes, so we can't prefill
    // the email field until signup token validation finishes.
    //
    // to work around this, we can see if the signupEmail prop ever changes.  if
    // it ever transitions from a falsey value to a truthy value, that means
    // signup token validation finished and the new value of signupEmail is the
    // value fetched via token validation.  we can then prefill signupEmail at
    // this time.  this is safe, as this is the only time the signupEmail prop
    // can change.

    if (!prevProps.signupEmail && Boolean(this.props.signupEmail)) {
      // setState did not work here.  you're not supposed to use setState with antd
      // Forms.  it's unclear why the other setStates in this file work ¯\_(ツ)_/¯
      // see antd docs: https://3x.ant.design/components/form/
      this.props.form.setFieldsValue({ email: this.props.signupEmail })
    }
  }

  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault()
    const { form, signup, signupToken, signupTheme, claimInvitation } = this.props
    const { firstName, lastName, email, password } = this.state
    form.validateFields((err: FormValidationErrorType) => {
      if (err) {
        return
      }
      if (signupToken) {
        window.setTimeout(() => claimInvitation({ signupToken, firstName, lastName, email, password }), 300)
      } else {
        window.setTimeout(() => signup({ firstName, lastName, email, password, signupTheme }), 300)
      }
      retoolAnalyticsTrack('Signup Button Clicked', {
        method: 'email',
        theme: signupTheme,
      })
    })
  }

  handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      firstName: event.target.value,
    })
  }

  handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      lastName: event.target.value,
    })
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value,
    })
  }

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: event.target.value,
    })
  }

  render() {
    const { form, invitedEmail } = this.props

    const formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    }

    const { getFieldDecorator } = form
    const redirectingToBilling = cookies.get(REDIRECTING_TO_BILLING_PORTAL_COOKIE_NAME) != null
    const signupButtonText = redirectingToBilling ? 'Sign up for cloud account' : 'Sign up'

    return (
      <Form className="login-form ant-form-vertical " layout="vertical" onSubmit={this.handleSubmit}>
        <div style={{ display: 'flex' }}>
          <FormItem {...formItemLayout} label="First name" style={{ marginRight: '18px' }}>
            {getFieldDecorator('firstName', {
              rules: [
                {
                  required: true,
                  message: 'First name required',
                },
              ],
            })(<TextInput placeholder="First name" onChange={this.handleFirstNameChange} />)}
          </FormItem>

          <FormItem {...formItemLayout} label="Last name">
            {getFieldDecorator('lastName', {
              rules: [
                {
                  required: true,
                  message: 'Last name required',
                },
              ],
            })(<TextInput placeholder="Last name" onChange={this.handleLastNameChange} />)}
          </FormItem>
        </div>

        {!invitedEmail && (
          <FormItem {...formItemLayout} label="Work email">
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'Please enter a valid email',
                },
                {
                  required: true,
                  message: 'Email required',
                },
              ],
            })(<TextInput placeholder="you@company.com" onChange={this.handleEmailChange} />)}
          </FormItem>
        )}
        <FormItem {...formItemLayout} label="Password">
          {getFieldDecorator('password', {
            rules: [
              { required: true, message: 'Password required' },
              {
                min: MINIMUM_PASSWORD_LENGTH,
                message: `Passwords must be at least ${MINIMUM_PASSWORD_LENGTH} characters`,
              },
            ],
          })(<TextInput placeholder="••••••••" type="password" onChange={this.handlePasswordChange} />)}
        </FormItem>
        <div className="auth-button-row">
          <Button type="primary" htmlType="submit" className="auth-button" block>
            {signupButtonText} <Icon className="ml4" type="arrow-right" />
          </Button>
        </div>
      </Form>
    )
  }
}

const SignupFormAsAntdForm = Form.create<SignupFormProps>()(SignupForm)

export default SignupFormAsAntdForm

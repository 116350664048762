import { OnboardingStep, OnboardingSubStep } from 'common/records'
import { ResourceType } from 'common/resourceTypes'
import { fromJS } from 'immutable'
import _ from 'lodash'
import {
  ALL_TABLE_COLUMNS,
  BuildingBlock,
  BuildingBlockGeneratorInput,
  BuildingBlockId,
  DELETE_RECORD_BUILDING_BLOCK,
  GOOGLE_SHEET_PLACEHOLDER,
  QUERY_ID_PLACEHOLDER,
  TABLE_ID_PLACEHOLDER,
  TABLE_PLACEHOLDER,
  TABLE_WIDGET_DATA_SOURCE_PLACEHOLDER,
  UNIQUE_COLUMN,
  UNIQUE_COLUMN_PLACEHOLDER,
  WidgetBuildingBlock,
} from '../buildingBlocks'
import { allResourceSheets, allResourcesInput, allResourceTables } from '../generatorInputs'

const deleteTableQueryName = 'deleteSelectedRecord'
const deleteRowButtonId = 'deleteRowButtonId'

//Building block assumes a table is made from another building block (this is enforced in the building block grid)
const constructBuildingBlock = () => {
  const widgets = []
  const queries = []

  const textInputWidget: WidgetBuildingBlock = {
    id: `${deleteRowButtonId}`,
    type: 'ButtonWidget',
    parentBuildingBlockId: DELETE_RECORD_BUILDING_BLOCK,
    mergeWidget: false,
    options: {
      action: {
        value: `${DELETE_RECORD_BUILDING_BLOCK}-${QUERY_ID_PLACEHOLDER}`,
        priority: 0,
      },
      value: {
        value: `Delete row`,
        priority: 0,
      },
    },
  }

  widgets.push(textInputWidget)

  const postgresSearchBuildingBlock = generatePostgresBuildingBlock()
  const googleSheetsBuildingBlock = generateGoogleSheetsBuildingBlock()
  const mysqlBlock = _.cloneDeep(postgresSearchBuildingBlock)
  mysqlBlock.type = 'mysql'

  queries.push(postgresSearchBuildingBlock)
  queries.push(googleSheetsBuildingBlock)
  queries.push(mysqlBlock)

  const allTableColumnsDelete: BuildingBlockGeneratorInput = {
    id: UNIQUE_COLUMN,
    options: ALL_TABLE_COLUMNS,
    label: 'Column to uniquely identify row',
  }

  const updateExistingRecordsBuildingBlock: BuildingBlock = {
    id: 'deleteRecord',
    displayName: 'Delete Row',
    description: 'Button that can delete the selected row from a table',
    widgets,
    queries,
    showInEditor: true,
    showInOnboarding: true,
    generatorInputs: [allResourcesInput, allResourceTables, allResourceSheets, allTableColumnsDelete],
  }

  return updateExistingRecordsBuildingBlock
}

const generatePostgresBuildingBlock = () => {
  const navigateToQuery: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `#query-editor__tab-${deleteTableQueryName}`,
    title: `Navigate to ${deleteTableQueryName}`,
    nextCondition: (_template, _model, editor) => {
      const allSelectedElements = editor.lastSelectedElements
      return allSelectedElements.has(`query-editor__tab-${deleteTableQueryName}`)
      //Query is defined and different from the template's query
    },
  })

  const postgresChangeTableHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `#title-selector-${deleteTableQueryName}`,
    title: 'Confirm the table you want to use',
    hintText: `We picked ${TABLE_PLACEHOLDER} as a default table when we autogenerated the app`,
    nextCondition: (template, _model, editor) => {
      const allSelectedElements = editor.lastSelectedElements
      return allSelectedElements.has(`title-selector-${deleteTableQueryName}`)
      //Query is defined and different from the template's query
    },
  })

  const postgresChangeColumnHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `#keyed-table-editor-filterBy`,
    title: 'Confirm the column you want to use',
    hintText: `This is the column we use to identify the row to delete`,
    nextCondition: (template, _model, editor) => {
      const allSelectedElements = editor.lastSelectedElements
      return allSelectedElements.has(`keyed-table-editor-filterBy`)
      //Query is defined and different from the template's query
    },
  })

  const postgresChangeColumnSelectorHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${deleteTableQueryName}"] .cm-evaluation-wrapper`,
    title: 'Get the updated column from the table',
    hintText: `This part of the query specifies the value of the key we want to delete based on `,
    nextCondition: (template, _model, editor) => {
      //Query is defined and different from the template's query
      const allSelectedElements = editor.lastSelectedElements
      return allSelectedElements.has(`value-editor-0`)
    },
  })

  const postgresOnboardingSubsteps = [
    navigateToQuery,
    postgresChangeTableHint,
    postgresChangeColumnHint,
    postgresChangeColumnSelectorHint,
  ]
  const postgresOnboardingStep = new OnboardingStep({
    title: 'Adjust your delete query',
    substeps: fromJS(postgresOnboardingSubsteps),
  })

  return {
    id: deleteTableQueryName,
    type: 'postgresql' as ResourceType,
    parentBuildingBlockId: DELETE_RECORD_BUILDING_BLOCK as BuildingBlockId,
    options: {
      editorMode: 'gui',
      requireConfirmation: true,
      actionType: 'DELETE_BY',
      tableName: TABLE_PLACEHOLDER,
      runWhenModelUpdates: false,
      filterBy: JSON.stringify([
        {
          value: `{{${TABLE_ID_PLACEHOLDER}.selectedRow.data['${UNIQUE_COLUMN_PLACEHOLDER}']}}`,
          operation: '=',
          key: UNIQUE_COLUMN_PLACEHOLDER,
        },
      ]),
      triggersOnSuccess: [TABLE_WIDGET_DATA_SOURCE_PLACEHOLDER],
    },
    hintSequence: postgresOnboardingStep,
  }
}

const generateGoogleSheetsBuildingBlock = () => {
  return {
    id: deleteTableQueryName,
    parentBuildingBlockId: DELETE_RECORD_BUILDING_BLOCK as BuildingBlockId,
    type: 'googlesheets' as ResourceType,
    options: {
      actionType: 'delete',
      spreadsheetId: `${TABLE_PLACEHOLDER}`,
      runWhenModelUpdates: false,
      requireConfirmation: true,
      sheetName: GOOGLE_SHEET_PLACEHOLDER,
      triggersOnSuccess: [TABLE_WIDGET_DATA_SOURCE_PLACEHOLDER],
      filterBy: JSON.stringify([
        {
          key: UNIQUE_COLUMN_PLACEHOLDER,
          operation: '=',
          value: `{{${TABLE_ID_PLACEHOLDER}.selectedRow.data['${UNIQUE_COLUMN_PLACEHOLDER}']}}`,
        },
      ]),
    },
  }
}

//TODO: currently only postgres is ready, the rest need to be modified!
/*const generateRestBuildingBlock = () => {
  const restQueryUrl = 'https://represent.opennorth.ca/boundary-sets'
  const restChangeResourceHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${viewTabularDataQueryName}"] .resourceSelector`,
    hintText: 'Select your api from the resource dropdown ',
    nextCondition: (_template, _model, editor) => {
      //Need to use the editor rather then the template since we want the version before it's saved
      return (
        editor.selectedDatasourceId === viewTabularDataQueryName &&
        editor.selectedResourceName !== 'REST-WithoutResource'
      )
    },
  })

  const restChangeUrlHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${viewTabularDataQueryName}"] .http-query-editor .query-scroller`,
    hintText: 'Change the URL to select from your api',
    nextCondition: (_template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      return editor.queryState.get(queryStateKey)?.get('query') !== restQueryUrl
    },
  })

  const restOnboardingSubsteps = [restChangeResourceHint, restChangeUrlHint]
  const restOnobardingStep = new OnboardingStep({
    title: 'Set up your REST resource',
    substeps: fromJS(restOnboardingSubsteps),
    condition: (template, model, _editor) => {
      const data = model.values.get(`${TABLE_ID}`).get('data')
      return data.length > 0 && data[0].domain !== 'Acton Vale, QC'
    },
  })

  return {
    id: viewTabularDataQueryName,
    type: 'restapi' as ResourceType,
    options: {
      query: 'https://represent.opennorth.ca/boundary-sets',
      type: 'GET',
    },
    hintSequence: restOnobardingStep,
  }
}

*/

export default constructBuildingBlock

import {
  ADHOC_RESOURCE_PROPERTIES,
  AdhocResourceName,
  BlobStorageEntry,
  EncryptedOauthSessionStateType,
  FunctionPlugin,
  InstrumentPlugin,
  Page,
  PlaygroundQuery,
  PlaygroundQueryRunResponse,
  PlaygroundQuerySaves,
  PluginTemplate,
  QueryState,
  Resource,
  ResourceFromServer,
  UserPermissions,
  InstrumentationIntegrationType,
  PlaygroundQueryUsage,
  GlobalWidgetType,
  Test,
  TestSuite,
} from 'common/records'

import { unused } from 'common/types'
import {
  createDeepEqualSelector,
  createImmutableDeepEqualSelector,
  isClonedTemplateASupportedSignupTheme,
  onEmbeddedPage,
} from 'common/utils'
import { ONBOARDING_SUGGESTIONS_DISMISSED, STAGES } from './userConstants'
import { User } from '__globalShared__/accounts'
import { Group, UserGroup, Workspace } from '__globalShared__/permissions'

import queryModels from 'components/plugins/datasources/index'
import { Map } from 'immutable'
import cookies from 'js-cookie'
import _ from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import {
  IS_ON_PREM,
  ONBOARDING_PREFIX_CLONED_TEMPLATE,
  ONBOARDING_URL_FIREBASE,
  ONBOARDING_URL_QUERY_LIBRARY,
  ONBOARDING_URL_RESOURCES,
} from 'retoolConstants'

import { RetoolState } from 'store'
import { NEW_RESOURCE_URL_PREFIX, QUERY_LIBRARY_URL_PREFIX, TREE_URL_PREFIX } from 'store/constants'

import { Experiment, OrganizationType } from './user.d'
import { TypedMap } from 'common/utils/immutable'

export type RecentVisit = {
  userId: number
  pageId: number
  visitType: string
  createdAt: Date
  updatedAt: Date
}

export type WorkspaceGroupPageArray = { group: Group; workspace: Workspace; page: Page }[]

export type FolderWithChildren = {
  id: number
  systemFolder: boolean
  name: string
  path: string
  children: FolderWithChildren[]
  pages: Page[]
}

export const appModelSelector = (state: RetoolState) => state.appModel
export const editorSelector = (state: RetoolState) => state.editor
export const appTemplateSelector = (state: RetoolState) => state.appTemplate.present
export const userSelector = (state: RetoolState) => state.user
export const settingsSelector = (state: RetoolState) => state.settings
export const responsiveSelector = (state: RetoolState) => state.responsive
export const pagesSelector = (state: RetoolState) => state.pages
export const environmentSelector = (state: RetoolState) => state.appModel.environment
export const locationSelector = (state: RetoolState) => state.location
export const presentationSelector = (state: RetoolState) => state.presentation
export const globalsSelector = (state: RetoolState) => state.globals
export const loginSelector = (state: RetoolState) => state.login
export const paginatedSavesSelector = (state: RetoolState) => state.paginatedSaves
export const embeddedSelector = (state: RetoolState) => state.embedded
export const selectedWidgetsSelector = (state: RetoolState) => state.editor.selectedWidgets
export const experimentValuesSelector = (state: RetoolState) => state.user.experimentValues
export const modulesSelector = (state: RetoolState) => state.modules

export const runningQueriesSelector = (state: RetoolState): string[] => {
  const maybeStringArray = state.appModel.values.getIn(['retoolContext', 'runningQueries'])

  // Poorman's type checking
  // (XXX) If retoolContext.runningQueries is something we refer in our checked in code
  // Then it should be strongly typed in our state
  if (Array.isArray(maybeStringArray)) {
    if (maybeStringArray[0] && !(typeof maybeStringArray[0] === 'string')) {
      return []
    }

    return maybeStringArray
  }

  return []
}

export const pluginsSelector = createSelector(appTemplateSelector, (appTemplate) => appTemplate.plugins)

const _localPluginsSelector = createSelector(pluginsSelector, (plugins) => plugins.filter((p) => !p.namespace))
export const localPluginsSelector = createDeepEqualSelector(_localPluginsSelector, (p) => p)

export const signupTokenSelector = createSelector(loginSelector, (login) => login.signupToken)
export const signupEmailSelector = createSelector(loginSelector, (login) => login.email)

const _pluginIdsSelector = createSelector(pluginsSelector, (plugins) => {
  return [...plugins.keys()]
})
export const pluginIdsSelector = createDeepEqualSelector(_pluginIdsSelector, (x) => x)

export const appModelValuesSelector = createSelector(appModelSelector, (appModel) => appModel.values)
export const appModelJSValuesSelector = createSelector(
  appModelValuesSelector,
  appModelSelector,
  (appModelValues, appModel) => {
    const cachedJSValues = appModel.get('cachedJSValues')
    if (cachedJSValues) {
      return cachedJSValues
    }
    return appModelValues.toJS()
  },
)

export const terminalLogSelector = createSelector(appModelSelector, (appModel) =>
  appModel.get('globals').get('terminalLog'),
)

export const appModelCachedJSValuesSelector = createSelector(appModelSelector, (appModel) =>
  appModel.get('cachedJSValues'),
)

export const organizationSelector = createSelector(
  userSelector,
  (user) => user.getIn(['orgInfo', 'organization']) as OrganizationType,
)

export const usersSelector = createSelector(userSelector, (user) => user.getIn(['orgInfo', 'users']))
export const userArraySelector = createSelector(usersSelector, (users) => users.toList().toJS())
export const usersByIdSelector = createSelector(userArraySelector, (users) => {
  const pairs = users.map((u: User) => [u.id, u])
  return _.fromPairs(pairs) as { [k: number]: User }
})
export const activatedFeaturesSelector = createSelector(organizationSelector, (org) => org.features)
export const allFeaturesSelector = createSelector(organizationSelector, (org) => org.allFeatures)
export const companyNameSelector = createSelector(organizationSelector, (org) => org.companyName)
export const planSelector = createSelector(organizationSelector, (org) => org.plan)

export const preloadedOrgJavaScriptSelector = createSelector(organizationSelector, (org) => org.preloadedJavaScript)
export const preloadedOrgJSLinksSelector = createSelector(organizationSelector, (org) => org.javaScriptLinks)

export const organizationThemeSelector = createSelector(organizationSelector, (org) => org.theme)
export const organizationThemeIsFetchingSelector = createSelector(organizationSelector, (org) => org.themeIsFetching)
export const organizationGitUrlSelector = createSelector(organizationSelector, (org) => org.gitUrl)
export const organizationGitBranchSelector = createSelector(organizationSelector, (org) => org.gitBranch)

export const organizationProtectedOrg = createSelector(organizationSelector, (org) => org.protectedGitHubOrg)
export const organizationProtectedRepo = createSelector(organizationSelector, (org) => org.protectedGitHubRepo)
export const organizationProtectedBranch = createSelector(
  organizationSelector,
  (org) => org.protectedGitBranch || 'master',
)
export const organizationProtectedEnterpriseUrl = createSelector(
  organizationSelector,
  (org) => org.protectedGitHubEnterpriseUrl,
)
export const protectedAppsEnvVarsSetSelector = createSelector(
  organizationSelector,
  (org) => org.protectedAppsEnvVarsSet,
)

export const pageNamesSelector = createSelector(pagesSelector, (pages) => pages?.get('pageNames'))

export const sortedPageNamesSelector = createSelector(pageNamesSelector, (pageNames) =>
  pageNames.sortBy((p) => decodeURIComponent(p.name).toLowerCase()),
)

export type PageTag = {
  id: string | null
  name: string
  createdAt?: string
  updatedAt?: string
  description?: string
  creatorUserId?: number | null
  releaserUserId?: number | null
  pageSave?: {
    user?: {
      id: number
      email: string
      firstName: string | null
      lastName: string | null
      profilePhotoUrl: string | null
    }
    createdAt?: string
  }
}

export const pageTagsSelector = createSelector(pagesSelector, (pages) =>
  (pages?.get('pageTags').toJS() as PageTag[]).sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix()),
)

export const newestTagNameSelector = createSelector(pageTagsSelector, (tags) => tags[0]?.name as string | undefined)

const latestTag: PageTag = { id: null, name: 'latest', description: 'Current working version' }

export const releasedTagNameSelector = createSelector(pagesSelector, (pages) => pages.get('pageReleasedTagName'))

export const tagsSelector = createSelector(pageTagsSelector, (pageTags) => {
  return [latestTag].concat(pageTags)
})

export const releasedTagSelector = createSelector(tagsSelector, releasedTagNameSelector, (tags, releasedTagName) =>
  tags.find(({ name }) => name === releasedTagName && name !== latestTag.name),
)

export const releasedAndLiveTagsSelector = createSelector(releasedTagSelector, (releasedTag) =>
  releasedTag ? [latestTag, releasedTag] : [latestTag],
)

export const currentPageSelector = createSelector(pagesSelector, (pages) => pages?.get('pageName') as string)

/** Query string to append to the edit or preview URLs */
export const queryStringSelector = createSelector(locationSelector, (location) => {
  const urlParams = new URLSearchParams(location.search)

  urlParams.delete('_releaseVersion')

  const queryString = urlParams.toString()
  return queryString ? `?${queryString}` : ''
})

/** URL to edit the current app */
export const editUrlSelector = createSelector(
  currentPageSelector,
  queryStringSelector,
  (pageName, queryString) => `/editor/${pageName}${queryString}`,
)

export const pageTagNameSelector = createSelector(pagesSelector, (pages) => pages?.get('pageTagName'))

export const currentPageUuidSelector = createSelector(pagesSelector, (pages): string => pages?.get('pageUuid') ?? '')

export const currentPageProtectedSelector = createSelector(pagesSelector, (pages) => pages?.get('pageProtected'))

export const currentPageBranchNameSelector = createSelector(pagesSelector, (pages) => pages?.get('pageBranchName'))

export const currentPageBranchIdSelector = createSelector(pagesSelector, (pages) => pages?.get('pageBranchId'))

export const currentPageCommitSelector = createSelector(pagesSelector, (pages) => pages?.get('pageCommit'))

export const currentPageFullSelector = createSelector(pagesSelector, currentPageUuidSelector, (pages, pageUuid) => {
  if (!pages || !pageUuid) {
    return false
  }
  return pages?.getIn(['pages', pageUuid])
})

export const canEditPageSelector = createSelector(pagesSelector, currentPageUuidSelector, (pages, pageUuid) => {
  if (!pages || !pageUuid) {
    return false
  }
  const accessLevel = pages?.getIn(['pages', pageUuid, 'accessLevel'])
  return accessLevel === 'own' || accessLevel === 'write'
})

export const makeIsPageProtectedSelector = (pageUuid: string) =>
  createSelector(pagesSelector, (pages) => {
    if (!pages || !pageUuid) {
      return false
    }
    return pages?.getIn(['pages', pageUuid, 'protected'])
  })

const branchesParentSelector = (state: RetoolState) => state.user.get('branches')

export const branchesSelector = createSelector(branchesParentSelector, (branches) => {
  return branches
    .get('branches')
    .toJS()
    .sort((a, b) => -1 * a.updatedAt.localeCompare(b.updatedAt))
})

const currentPageIdSelector = createSelector(pagesSelector, currentPageUuidSelector, (pages, pageUuid) => {
  if (!pages || !pageUuid) {
    return false
  }
  return pages?.getIn(['pages', pageUuid, 'id'])
})

export const currentPageBranchesSelector = createSelector(branchesSelector, currentPageIdSelector, (branches, id) => {
  return branches.filter((branch) => branch.pageId === id)
})

export const isFetchingBranchesSelector = createSelector(branchesParentSelector, (branches) => {
  return branches.get('isFetching')
})

export const responsiveLayoutDisabledSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.responsiveLayoutDisabled
})

export const preloadedAppJavaScriptSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.preloadedAppJavaScript
})

export const preloadedAppJSLinksSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.preloadedAppJSLinks
})

export const testEntitiesSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.testEntities
})

export const testsSelector = createSelector(testEntitiesSelector, (testEntities) => {
  return testEntities.filter((entity) => entity.type === 'test') as Test[]
})

export const testSuitesSelector = createSelector(testEntitiesSelector, (testEntities) => {
  return testEntities.filter((entity) => entity.type === 'suite') as TestSuite[]
})

export const appStylesSelector = createSelector(appTemplateSelector, ({ appStyles }) => appStyles)

export const loadingIndicatorsDisabledSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.loadingIndicatorsDisabled
})

export const customDocumentTitleSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.customDocumentTitle
})

export const customShortcutsSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.customShortcuts
})

export const customDocumentTitleEnabledSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.customDocumentTitleEnabled
})

export const largeScreenSelector = createSelector(
  responsiveLayoutDisabledSelector,
  pagesSelector,
  responsiveSelector,
  (responsiveLayoutDisabled, pages, responsive) => {
    if (responsiveLayoutDisabled) {
      return true
    }
    if (pages?.get('editorMode')) {
      return responsive.largeScreen && !responsive.mobileModeForced
    } else {
      return responsive.largeScreen
    }
  },
)

export const resourcesSelector = createSelector(userSelector, (user) => user.resources.get('resources'))
export const resourcesJSSelector = createSelector(userSelector, (user) => user.resources.get('resources').toJS())

export const currentResourceByEnvSelector = (state: RetoolState) => state.resources?.resourceByEnv

export const currentResourceEncryptedOauthSessionStatesByEnvSelector = createSelector(
  currentResourceByEnvSelector,
  (resourceByEnv) => {
    const currentResourceEncryptedOauthSessionStatesByEnv: TypedMap<{
      [env: string]: { [key: string]: EncryptedOauthSessionStateType }
    }> = resourceByEnv.map((resource: TypedMap<ResourceFromServer>) => {
      return resource.get('encryptedOauthSessionStates')
    })

    return currentResourceEncryptedOauthSessionStatesByEnv.toJS()
  },
)

export const resourcesIsFetchingSelector = createSelector(userSelector, (user) => user.resources.get('isFetching'))

export const orgInfoSelector = (state: RetoolState) => state.user.get('orgInfo')
export const groupsSelector = createSelector(orgInfoSelector, (orgInfo) =>
  orgInfo.get('groups') ? orgInfo.get('groups').toJS() : {},
)
export const orgExperimentsSelector = createSelector(
  orgInfoSelector,
  (orgInfo): { [name: string]: Experiment } => orgInfo.get('experiments') || {},
)

export const defaultPlaygroundResources = [ADHOC_RESOURCE_PROPERTIES.restapi, ADHOC_RESOURCE_PROPERTIES.graphql]

const resourceFormatter = (resources: any, defaultResources: any): Resource[] => {
  const plainResources = resources ? resources.toJS() : []
  return plainResources
    .map((d: any) => ({
      ...d,
      name: d.name,
      displayName: d.displayName,
      value: d.name,
      label: `${d.displayName} (${d.type})`,
      editorType: d.editorType,
      resourceType: d.type,
    }))
    .concat(defaultResources)
    .map((v: any) => ({ ...v, optionFilter: v.optionFilter || v.displayName }))
}

export const isTemplateGlobalWidgetSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.isGlobalWidget
})

export const isOnPremSelector = (_: unused) => IS_ON_PREM

export const editorResourcesSelector = createSelector(
  resourcesSelector,
  isTemplateGlobalWidgetSelector,
  experimentValuesSelector,
  (r, isGlobalWidget, experimentValues) => {
    const defaultEditorResources = defaultPlaygroundResources.concat([
      ADHOC_RESOURCE_PROPERTIES.parentwindow,
      ADHOC_RESOURCE_PROPERTIES.sqltransform,
      ADHOC_RESOURCE_PROPERTIES.pdfexporter,
      ADHOC_RESOURCE_PROPERTIES.javascript,
      ...(experimentValues.flows ? [ADHOC_RESOURCE_PROPERTIES.flows] : []),
      ...(isGlobalWidget ? [ADHOC_RESOURCE_PROPERTIES.globalwidget] : []),
    ])

    return resourceFormatter(r, defaultEditorResources)
  },
)

export const isSavingSelector = createSelector(editorSelector, (editor) => editor.isSaving)
export const saveUpToDateSelector = createSelector(editorSelector, (editor) => editor.saveUpToDate)

export const selectedDatasourceSelector = createSelector(editorSelector, appTemplateSelector, (editor, appTemplate) =>
  editor.selectedDatasourceId ? (appTemplate.getIn(['plugins', editor.selectedDatasourceId]) as PluginTemplate) : null,
)

export const selectedResourceSelector = createSelector(
  editorSelector,
  editorResourcesSelector,
  selectedDatasourceSelector,
  (editor, resources, selectedDatasource): Resource | undefined => {
    if (editor.selectedResourceName) {
      const found = _.find(resources, (r: any) => r.value === editor.selectedResourceName)
      if (found) {
        if (editor.queryState.has(found.editorType)) {
          return found
        }
      }
      if (selectedDatasource) {
        return ({
          id: selectedDatasource.id,
          name: selectedDatasource.resourceName,
          value: selectedDatasource.resourceName,
          editorType: selectedDatasource.subtype,
        } as unknown) as Resource
      }
    } else {
      return ({} as unknown) as Resource
    }
  },
)

export const schemaViewerOpenSelector = createSelector(editorSelector, (editor) => editor.get('schemaViewerOpen'))

export const schemasSelector = createSelector(editorSelector, (editor) => editor.get('schemas'))

const selectedSchemaWrapperSelector = createSelector(
  selectedResourceSelector,
  schemasSelector,
  (selectedResource, schemas) => {
    return selectedResource && 'name' in selectedResource && schemas.get(selectedResource.name)
      ? schemas.get(selectedResource.name)
      : {}
  },
)
export const selectedSchemaSelector = createSelector(selectedSchemaWrapperSelector, (selectedSchemaWrapper) => {
  return selectedSchemaWrapper.schema || {}
})

export const selectedSchemaErrorSelector = createSelector(selectedSchemaWrapperSelector, (selectedSchemaWrapper) => {
  return !!selectedSchemaWrapper.error
})

export const collectionsSelector = createSelector(selectedSchemaSelector, (schema) =>
  Object.keys(schema).map((name) => ({ value: name, label: name })),
)

export const queryStateSelector = createSelector(
  editorSelector,
  selectedResourceSelector,
  (editor, selectedResource) => {
    if (editor.queryState && selectedResource) {
      const selectedQueryState = editor.queryState.get(selectedResource.editorType)
      if (selectedQueryState) {
        return selectedQueryState
      }
    }
    return Map() as QueryState<{}>
  },
)

export const queryRunWhenModelUpdatesSelector = createSelector(queryStateSelector, (queryState) => {
  return queryState.get('runWhenModelUpdates')
})

export const queryEditorTypeSelector = createSelector(selectedResourceSelector, (selectedResource) => {
  if (selectedResource) {
    return selectedResource.editorType
  }
  return null
})

export const showUpdateSetValueDynamicallySelector = createSelector(
  queryStateSelector,
  queryEditorTypeSelector,
  (queryState, queryEditorType) => {
    return queryState.get('showUpdateSetValueDynamicallyToggle') && queryEditorType === 'JavascriptQuery'
  },
)

export const selectedQueryEditorTabSelector = createSelector(
  editorSelector,
  (editor) => editor.selectedQuerySettingsTab,
)

export const queryChangedSelector = createSelector(
  editorSelector,
  queryStateSelector,
  selectedDatasourceSelector,
  (editor, queryState, selectedDatasource): boolean => {
    if (!selectedDatasource) return false
    const model: any = queryModels[selectedDatasource.subtype]
    if (!model) return false
    const a = selectedDatasource.template.toJS()
    const b = queryState.toJS()
    return editor.selectedResourceName !== selectedDatasource.resourceName || model.hasChanged(a, b)
  },
)

export const datasourceNamesSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.plugins.filter(({ type }, _) => type === 'datasource').keySeq()
})

export const placeholderResourceNamesSelector = createSelector(
  (state: RetoolState) => state.appTemplate.present.plugins,
  (plugins) => {
    return plugins
      .valueSeq()
      .toJS()
      .map((p) => p.resourceName)
      .filter((n) => n && n.includes('[demo]'))
  },
)

export type DefinitionList = {
  name: string
  value: string
}[]
export const urlFragmentDefinitionsSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.urlFragmentDefinitions
})

export const urlFragmentFormsSelector = createSelector(urlFragmentDefinitionsSelector, (urlFragmentDefinitions) => {
  const curDefs = urlFragmentDefinitions.toArray().map((e) => e.toJS()) as DefinitionList
  curDefs.push({
    name: '',
    value: '',
  })
  return curDefs
})

export const allTablesSelector = createSelector(pluginsSelector, (plugins) => {
  return plugins
    .filter((plugin) => plugin.subtype === 'TableWidget')
    .valueSeq()
    .toArray()
})

export const ownPropsSelectorWithProps = (state: RetoolState, ownProps: any) => ownProps

export const pageLoadValueOverridesSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.pageLoadValueOverrides
})

export const pageLoadValueOverrideFormsSelector = createSelector(
  pageLoadValueOverridesSelector,
  (pageLoadValueOverrides) => {
    const curDefs = pageLoadValueOverrides.toArray().map((e) => e.toJS()) as DefinitionList
    curDefs.push({
      name: '',
      value: '',
    })
    return curDefs
  },
)

export const pageLoadValueOverridesObjectSelector = createSelector(pageLoadValueOverrideFormsSelector, (defs) => {
  return defs.reduce((acc, def) => {
    if (!def.name) {
      return acc
    }
    return {
      ...acc,
      [def.name]: def.value,
    }
  }, {})
})

export const validPageOverrideNamesSelector = createSelector(appTemplateSelector, (appTemplate) => {
  const validNames: any = []
  appTemplate.plugins.forEach((plugin) => {
    if (plugin.template) {
      plugin.template.map((value: any, key: any) => {
        if (
          key === 'value' ||
          key === 'selectedIndex' ||
          key === 'selectedTab' ||
          key === 'endValue' ||
          key === 'startValue'
        ) {
          validNames.push(`${plugin.id}.${key}`)
        }
      })
    }
  })
  return validNames
})

export const fetchingDatasourcesSelector = createSelector(
  appModelSelector,
  datasourceNamesSelector,
  (appModel, dataSources) => {
    return appModel.values
      .filter((plugin: any, pluginName: any) => {
        return dataSources.includes(pluginName) && plugin.get('isFetching')
      })
      .keySeq()
  },
)

export const pageListSelector = (state: RetoolState) => state.pages?.get('pages')
export const foldersSelector = (state: RetoolState) => state.pages?.get('folders')

export const foldersWithWriteAccessSelector = createSelector(foldersSelector, (folders) => {
  const foldersToDisplay = folders.filter(
    (folder) => folder.get('accessLevel') === 'write' || folder.get('accessLevel') === 'own',
  )

  return Array.from(foldersToDisplay.values()).filter((folder) => folder.get('name') !== 'archive')
})

export const hasFoldersWithWriteAccessSelector = createSelector(
  foldersWithWriteAccessSelector,
  (folders) => !!folders.length,
)

// Pick out the rootFolder
const rootFolderSelector = createSelector(foldersSelector, (folders) => {
  return folders.filter((f: any) => f.get('name') === 'root' && !f.parentFolderId && f.get('systemFolder')).first(null)
})

function expandFolder(curFolder: any, folders: any, parentPath = '') {
  let path = parentPath
  if (curFolder.get('name') !== 'root' || !curFolder.get('systemFolder')) {
    path = `${parentPath + encodeURIComponent(curFolder.get('name'))}/`
  }
  const children = folders
    .filter((folder: any) => folder.get('parentFolderId') === curFolder.get('id'))
    .map((child: any) => expandFolder(child, folders, path))
    .sortBy(
      (v: any) => v.get('name'),
      (name1: any, name2: any) => {
        if (name1 === 'archive') return -1
        if (name2 === 'archive') return 1
        if (name1 > name2) return 1
        if (name1 < name2) return -1
        return 0
      },
    )
    .toList()

  curFolder = curFolder.set('children', children).set('path', path)
  return curFolder
}

// Generate the denormalized structure of the folder (i.e. nested)
const denormalizedFoldersSelector = createSelector(foldersSelector, rootFolderSelector, (folders, rootFolder) => {
  if (!rootFolder) {
    return null
  }
  return expandFolder(rootFolder, folders)
})

function attachPagesToFolders(curFolder: any, pages: any) {
  const childrenPages = pages
    .filter((page: any) => page.get('folderId') === curFolder.get('id'))
    .map((p: any) => p.set('path', curFolder.get('path') + encodeURIComponent(p.get('name'))))
    .toList()
    .sortBy((v: any) => v.get('name'))
  const children = curFolder.get('children').map((folder: any) => attachPagesToFolders(folder, pages))
  return curFolder.set('pages', childrenPages).set('children', children)
}

// Generate the denormalized structure of the folder and attach pages to each folder
export const folderTreeSelector = createSelector(
  pageListSelector,
  denormalizedFoldersSelector,
  (pages, denormalizedFolders) => {
    if (!denormalizedFolders) {
      return null
    }
    return attachPagesToFolders(denormalizedFolders, pages).toJS() as FolderWithChildren
  },
)

const findFolder = (name: string) => {
  const finder = (tree: FolderWithChildren): FolderWithChildren | undefined => {
    if (!tree) return undefined
    if (name === tree.name) return tree

    return tree.children.find(finder)
  }
  return finder
}

// Pick out the archive folder
export const archiveFolderSelector = createSelector(folderTreeSelector, (tree) => {
  if (!tree) {
    return null
  }

  return findFolder('archive')(tree) || null
})

export const archiveFolderIdSelector = createSelector(archiveFolderSelector, (archiveFolder) => archiveFolder?.id)

export const allFoldersSelector = createSelector(folderTreeSelector, (tree) => {
  return tree ? tree.children : []
})

export function getPages(folder: FolderWithChildren): Page[] {
  if (!folder) {
    return []
  }
  const pages = folder.pages
  const childrenPages: Page[] = _.flatten(folder.children.map(getPages))
  return pages.concat(childrenPages)
}

export const pagesWithPathsSelector = createSelector(folderTreeSelector, (folder) => (folder ? getPages(folder) : []))

export const pathSelector = createSelector(locationSelector, (location) => location.pathname as string)
export const locationLoadedSelector = createSelector(locationSelector, (location) => location.loaded)
export const previousPathSelector = createSelector(locationSelector, (location) => location.previousPathname as string)
export const currentPathSelector = createSelector(
  pathSelector,
  previousPathSelector,
  locationLoadedSelector,
  (path, previousPath, locationLoaded) => (locationLoaded ? path : previousPath || path),
)

export const authModalSelector = createSelector(presentationSelector, (presentation) => presentation?.get('authModal'))
export const numCustomAuthResourcesInAppSelector = createSelector(presentationSelector, (presentation) =>
  presentation ? presentation.get('numCustomAuthResourcesInApp') : 0,
)

// get app path via folders; eg ['my folder', 'my app']
export const currentAppPathArraySelector = createSelector(currentPathSelector, (path) =>
  (path || '')
    .split('/')
    .splice(path && path.startsWith(TREE_URL_PREFIX) ? 4 : 2)
    .map((p: any) => decodeURIComponent(p)),
)

export const onHomeSelector = createSelector(currentPathSelector, (path) => {
  return path === '/'
})

export const onSetupSelector = createSelector(currentPathSelector, (path) => {
  return path.indexOf('/setup') === 0
})

export const onOnboardingTutorialSelector = createSelector(currentPathSelector, (path) => {
  return path.indexOf('/Onboarding%20Page') > -1 || path.indexOf('/Onboarding Page') > -1
})

export const onPresentationSelector = createSelector(currentPathSelector, (path) => {
  if (path.startsWith(TREE_URL_PREFIX)) {
    const parts = path.split('/')
    return parts.length >= 4 && parts[1] === 'tree' && parts[3] === 'apps'
  } else {
    return path.indexOf('/apps') === 0
  }
})

export const currentModeSelector = createSelector(onPresentationSelector, (onPresentation) => {
  return onPresentation ? 'presentation' : onEmbeddedPage() ? 'public' : 'editor'
})

export const onBillingSelector = createSelector(currentPathSelector, (path) => {
  return path.indexOf('/settings/billing') === 0
})

export const onPlaygroundSelector = createSelector(currentPathSelector, (path) => {
  return path.indexOf(`/queryplayground`) === 0 || path.indexOf(QUERY_LIBRARY_URL_PREFIX) === 0
})

export const onResourcesSelector = createSelector(currentPathSelector, (path) => {
  return path.indexOf(`/resources`) === 0
})

export const onResourceOnboardingSelector = createSelector(currentPathSelector, (path) => {
  return path.indexOf(NEW_RESOURCE_URL_PREFIX) > -1 && cookies.get('onboardingUrl') === ONBOARDING_URL_RESOURCES
})

export const onQueryLibraryOnboardingSelector = createSelector(currentPathSelector, () => {
  return cookies.get('onboardingUrl') === ONBOARDING_URL_QUERY_LIBRARY
})

export const onEditOrCreateResourceSelector = createSelector(currentPathSelector, (path) => {
  // Either on /resources/new, /resources/new/resourcetype or /resources/myresource
  return path.indexOf(`/resources/`) === 0
})

export const onEditorOnboardingSelector = createSelector(locationSelector, () => {
  let clonedTemplateId = null
  const onboardingUrl = cookies.get('onboardingUrl')

  if (onboardingUrl && onboardingUrl.includes(ONBOARDING_PREFIX_CLONED_TEMPLATE)) {
    const onboardingUrlPath = onboardingUrl.split('/')
    clonedTemplateId = onboardingUrlPath[onboardingUrlPath.length - 1]
  }

  return !!(
    onboardingUrl === ONBOARDING_URL_FIREBASE ||
    (clonedTemplateId && isClonedTemplateASupportedSignupTheme(clonedTemplateId))
  )
})

// Use currentPageUuidSelector to ensure the page has been loaded
export const onEditorSelector = createSelector(currentPathSelector, (path) => {
  if (path.startsWith(TREE_URL_PREFIX)) {
    const parts = path.split('/')
    return parts.length >= 4 && parts[1] === 'tree' && parts[3] === 'editor'
  } else {
    return path.indexOf('/editor') === 0
  }
})

export const on2FASelector = createSelector(currentPathSelector, (path) => {
  return path.indexOf('/two-factor-challenge') === 0
})

const _datasourcePluginsSelector = createSelector(appTemplateSelector, (appTemplate) =>
  appTemplate.plugins.toList().filter((plugin) => plugin.get('type') === 'datasource'),
)

const _datasourceNonNamespacedPluginsSelector = createSelector(_datasourcePluginsSelector, (plugins) =>
  plugins.filter((plugin) => !plugin.get('namespace')),
)
// when an irrelevant part of the template (e.g. widget-related things) change, we don't
// want to invalidate our selector and potentially cause a cascade of unnecessary re-renders,
// so we deep-compare the datasource plugins and only return a new obj when they actually change
export const datasourcePluginsSelector: typeof _datasourcePluginsSelector = createImmutableDeepEqualSelector(
  _datasourcePluginsSelector,
  (s: any) => s,
)

export const actionFoldersSelector = createSelector(appTemplateSelector, (appTemplate) => appTemplate.folders)

const _actionPluginsSelector = createSelector(appTemplateSelector, (appTemplate) =>
  appTemplate.plugins
    .toList()
    .filter(
      (plugin) =>
        !plugin.get('namespace') &&
        (plugin.get('type') === 'datasource' ||
          plugin.get('type') === 'instrumentation' ||
          plugin.get('type') === 'function'),
    ),
)

export const actionPluginsSelector: typeof _actionPluginsSelector = createImmutableDeepEqualSelector(
  _actionPluginsSelector,
  (s) => s,
)

export const datasourceNonNamespacedPluginsSelector: typeof _datasourceNonNamespacedPluginsSelector = createImmutableDeepEqualSelector(
  _datasourceNonNamespacedPluginsSelector,
  (s: any) => s,
)

const _functionPluginsSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.plugins.filter((plugin) => plugin.get('type') === 'function').toJS() as {
    [pluginId: string]: FunctionPlugin
  }
})

const _functionNonNamespacedPluginsSelector = createSelector(_functionPluginsSelector, (plugins) => {
  return _.pickBy(plugins, (p) => p && !p.namespace) as {
    [pluginId: string]: FunctionPlugin
  }
})

export const functionPluginsSelector: typeof _functionPluginsSelector = createDeepEqualSelector(
  _functionPluginsSelector,
  (s: any) => s,
)
const _instrumentPluginsSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.plugins.filter((plugin) => plugin.get('type') === 'instrumentation').toJS() as {
    [pluginId: string]: InstrumentPlugin
  }
})

export const instrumentPluginsSelector: typeof _instrumentPluginsSelector = createDeepEqualSelector(
  _instrumentPluginsSelector,
  (s: any) => s,
)

export const functionNonNamespacedPluginsSelector: typeof _functionPluginsSelector = createDeepEqualSelector(
  _functionNonNamespacedPluginsSelector,
  (s: any) => s,
)

export const functionPluginIdsSelector = createSelector(functionPluginsSelector, (functions) => Object.keys(functions))
const _datasourcePluginsErrorSelector = createSelector(
  appModelSelector,
  datasourceNamesSelector,
  (appModel, dataSources) => {
    return appModel.values
      .filter((plugin: any, pluginName: any) => {
        return dataSources.includes(pluginName) && plugin.get('error')
      })
      .map((datasource) => datasource.get('error'))
  },
)
export const datasourcePluginsErrorSelector = createImmutableDeepEqualSelector(
  _datasourcePluginsErrorSelector,
  (s) => s,
)

export const selectedFunctionIdSelector = createSelector(editorSelector, (editor) => editor.selectedFunctionId)

export const selectedDatasourceIdSelector = createSelector(editorSelector, (editor) => editor.selectedDatasourceId)

export const selectedFunctionChangedSelector = createSelector(editorSelector, (editor) => editor.transformerChanged)

export const selectedInstrumentChangedSelector = createSelector(editorSelector, (editor) => editor.instrumentChanged)

export const selectedInstrumentIdSelector = createSelector(editorSelector, (editor) => editor.selectedInstrumentId)

export const profileSelector = createSelector(userSelector, (user) => user.get('user').toJS())

export const selectedResourceIdSelector = createSelector(
  selectedDatasourceIdSelector,
  (selectedDatasourceId): string | null => selectedDatasourceId,
)

export const selectedQueryUntransformedResponseSelector = createSelector(
  editorSelector,
  selectedDatasourceIdSelector,
  (editor, queryName) => {
    return editor.getIn(['untransformedQueryResponses', queryName])
  },
)

const getDisplayName = (user: User | null, currentUser: User | null) => {
  if (!user) {
    return null
  }

  if (user && currentUser && user.id === currentUser.id) {
    ;('you')
  }

  if (!(user.firstName || user.lastName)) {
    return 'Unset'
  }

  return _.compact([user.firstName, user.lastName]).join(' ')
}

export const userDisplayNamesSelector = createSelector(userArraySelector, profileSelector, (users, currentUser) => {
  const displayNames: { [k: number]: string } = {}
  for (const i in users) {
    const user = users[i]
    const displayName = getDisplayName(user, currentUser)
    if (displayName) {
      displayNames[user.id] = displayName
    }
  }
  return displayNames
})

export type LastPageEdit = {
  user: string | undefined
  timestamp: string
}

export const pagesEditedBySelector = createSelector(
  pagesWithPathsSelector,
  userDisplayNamesSelector,
  (pages, userNames) => {
    const pairs = pages.map((p: Page) => {
      const edit = {
        user: userNames[p.lastEditedBy],
        timestamp: p.updatedAt,
      } as LastPageEdit
      return [p.id, edit]
    })
    return _.fromPairs(pairs)
  },
)

export const pageNameSelector = createSelector(pagesSelector, (pages) => {
  // 'pageName' is the url path including folders
  const fullPath = pages?.get('pageName')

  // get the last segment i.e. the actual page name without folder path
  const pathSegments = (fullPath || '').split('/')
  return pathSegments[pathSegments.length - 1]
})

export const instrumentationIntegrationsSelector = createSelector(orgInfoSelector, (org) => {
  return Object.values(org.instrumentationIntegrations.toJS()) as InstrumentationIntegrationType[]
})

export const permissionsSelector = createSelector(
  userSelector,
  (user): UserPermissions => {
    return user.getIn(['user', 'permissions']) || Map()
  },
)

export const isAdminSelector = createSelector(permissionsSelector, (permissions) => permissions.get('admin'))
export const isGroupAdminSelector = createSelector(userSelector, (user) => {
  const groups: Map<
    string,
    TypedMap<
      Group & {
        userGroup?: Map<string, TypedMap<UserGroup>>
      }
    >
  > = user.getIn(['user', 'groups'])
  return (groups ?? []).map((group) => group.get('userGroup')).some((userGroup) => !!userGroup?.get('isAdmin'))
})

export const isCreatorSelector = createSelector(permissionsSelector, (permissions) => permissions.get('creator'))
export const isEditorSelector = createSelector(permissionsSelector, (permissions) => permissions.get('editor'))
export const queryLibraryAccessSelector = createSelector(permissionsSelector, (permissions) =>
  permissions.get('queryLibraryAccess'),
)

export const canViewUserListSelector = createSelector(permissionsSelector, (permissions) =>
  permissions.get('userListAccess'),
)

export const canAccessQueryLibrarySelector = createSelector(
  queryLibraryAccessSelector,
  (accessLevel) => accessLevel === 'read' || accessLevel === 'write',
)

export const hasQueryLibraryEditorPermissionsSelector = createSelector(
  queryLibraryAccessSelector,
  (accessLevel) => accessLevel === 'write',
)

export const editorPrivilegedSelector = createSelector(
  isAdminSelector,
  isEditorSelector,
  (admin, editor) => admin || editor,
)

export const creatorPrivilegedSelector = createSelector(
  isAdminSelector,
  isCreatorSelector,
  (admin, creator) => admin || creator,
)

export const permissionLabelSelector = createSelector(
  isAdminSelector,
  isCreatorSelector,
  isEditorSelector,
  (admin, creator, editor) => {
    if (admin) {
      return 'Admin'
    }
    if (creator) {
      return 'Creator'
    }
    if (editor) {
      return 'Editor'
    }
    return 'User'
  },
)

export const onboardingSuggestionsStagesCompletedSelector = createSelector(organizationSelector, (organization) => {
  return organization.onboardingStagesCompleted
})

export const onboardingSuggestionsVisibleSelector = createSelector(
  isOnPremSelector,
  isAdminSelector,
  onboardingSuggestionsStagesCompletedSelector,
  (isOnPrem, isAdmin, stagesCompleted) =>
    !isOnPrem &&
    isAdmin &&
    stagesCompleted !== null &&
    !stagesCompleted.includes(ONBOARDING_SUGGESTIONS_DISMISSED) &&
    stagesCompleted.length < STAGES.length,
)

export const hasMobileEnabledWidgetsSelector = createSelector(appTemplateSelector, (appTemplate) => {
  if (!appTemplate) {
    return false
  }
  return appTemplate.plugins.some((widget) => {
    return !!widget.mobilePosition2
  })
})

export const hasMobileLayoutSelector = createSelector(appTemplateSelector, pagesSelector, (appTemplate, pages) => {
  if (!appTemplate) {
    return false
  }
  if (pages?.get('editorMode')) {
    return true
  }
  return appTemplate.plugins.some((widget) => {
    return !!widget.mobilePosition2
  })
})

export const platformLevelAuthStepsSelector = createSelector(organizationSelector, (organization) => {
  return _.get(organization, ['platformLevelAuthSteps']) || []
})

const resourcesWithStagingSelector = createSelector(resourcesSelector, (resources) => {
  return resources.filter((r) => r.has('staging'))
})

const stagingResourcesMapSelector = createSelector(resourcesWithStagingSelector, (resources): {
  [key in AdhocResourceName]: true
} & { [key: string]: true } => {
  return resources.reduce(
    (acc, r) => {
      return { ...acc, [r.get('name')]: true }
    },
    {
      JavascriptQuery: true,
      'SQL Transforms': true,
      PDFExporter: true,
      ParentWindow: true,
      'REST-WithoutResource': true,
      'GraphQL-WithoutResource': true,
      Flows: true,
      GlobalWidgetQuery: true,
    },
  )
})

export const stagingRequiredQueriesSelector = createSelector(
  stagingResourcesMapSelector,
  datasourcePluginsSelector,
  (stagingResourcesMap, queries) => {
    return queries.filter((q) => !stagingResourcesMap[q.resourceName]).toArray()
  },
)

export const isStagingQueriesPresentSelector = createSelector(
  stagingResourcesMapSelector,
  datasourcePluginsSelector,
  (stagingResourcesMap, queries) => {
    return queries.some((q) => stagingResourcesMap[q.resourceName])
  },
)

export const marketingTemplatesSelector = (state: RetoolState) => state.marketingTemplates.marketingTemplates

/* Playground */
export const playgroundSelector = (state: RetoolState) => state.playground
export const playgroundResourcesSelector = createSelector(resourcesSelector, (r) =>
  resourceFormatter(r, defaultPlaygroundResources),
)
export const playgroundSelectedResourceSelector = createSelector(
  playgroundSelector,
  playgroundResourcesSelector,
  (pg, defaults) => {
    return pg?.selectedResource || defaults[0]
  },
)

export const playgroundQueryEditorTypeSelector = createSelector(
  playgroundSelectedResourceSelector,
  (selectedResource) => {
    return selectedResource ? selectedResource.editorType : null
  },
)

export const playgroundEnvironmentSelector = createSelector(playgroundSelector, (pg) => {
  return pg.environment
})
export const playgroundSchemasSelector = createSelector(playgroundSelector, (pg) => pg.schemas)

const playgroundSelectedSchemaWrapperSelector = createSelector(
  playgroundSelectedResourceSelector,
  playgroundSchemasSelector,
  (selectedResource, schemas) => {
    return selectedResource && schemas.get(selectedResource.name) ? schemas.get(selectedResource.name) : {}
  },
)
export const playgroundSelectedSchemaSelector = createSelector(
  playgroundSelectedSchemaWrapperSelector,
  (schemaWrapper) => {
    return schemaWrapper.schema || {}
  },
)

export const playgroundSavedQueriesSelector = createSelector(playgroundSelector, (pg) => {
  return pg?.savedQueries ?? {}
})

export const playgroundSavedQueriesArraySelector = createSelector(playgroundSavedQueriesSelector, (savedQueries) => {
  return Object.values(savedQueries)
})

export const playgroundSavedUserQueryIdsSelector = createSelector(playgroundSelector, (pg) => {
  return pg.savedUserQueryIds
})

export const playgroundSavedOrganizationQueryIdsSelector = createSelector(playgroundSelector, (pg) => {
  return pg.savedOrganizationQueryIds
})

export const playgroundUserQueriesSelector = createSelector(
  playgroundSavedQueriesSelector,
  playgroundSavedUserQueryIdsSelector,
  (savedQueries: { [queryId: number]: PlaygroundQuery }, savedUserQueryIds): PlaygroundQuery[] => {
    const userQueryIds = savedUserQueryIds
    return userQueryIds.map((qid) => savedQueries[qid])
  },
)

export const playgroundOrganizationQueriesSelector = createSelector(
  playgroundSavedQueriesSelector,
  playgroundSavedOrganizationQueryIdsSelector,
  (savedQueries: { [queryId: number]: PlaygroundQuery }, savedOrganizationQueryIds): PlaygroundQuery[] => {
    const orgQueryIds = savedOrganizationQueryIds
    return orgQueryIds.map((qid) => savedQueries[qid])
  },
)

export const playgroundSelectedQuerySelector = createSelector(playgroundSelector, (pg: any): PlaygroundQuery | null => {
  return pg.selectedQueryId ? (pg.savedQueries[pg.selectedQueryId] as PlaygroundQuery) : null
})

export const playgroundSelectedQueryUsagesSelector = createSelector(
  playgroundSelector,
  pagesWithPathsSelector,
  (pg, pages) => {
    const pagesByUuid = _.keyBy(pages, 'uuid')
    return pg.selectedQueryUsages.map(
      (usage): PlaygroundQueryUsage => {
        const page = pagesByUuid[usage.pageUuid]
        return {
          ...usage,
          path: page ? page.path : null,
          accessLevel: page ? page.accessLevel : null,
        }
      },
    )
  },
)

export const playgroundSelectedQueryLatestVersionUsagesSelector = createSelector(
  playgroundSelectedQueryUsagesSelector,
  (usages) => {
    return usages.filter((usage) => usage.pinnedToLatestVersion)
  },
)

export const playgroundQueryTemplateSelector = createSelector(
  playgroundSelector,
  playgroundSelectedQuerySelector,
  playgroundSelectedResourceSelector,
  (pg, query, resource) => {
    if (pg.queryTemplates && query && resource) {
      const template = pg.queryTemplates.getIn([query.id, resource.name])
      if (template) {
        return template
      }
    }
    return Map()
  },
)

export const playgroundSelectedQueryInputsSelector = createSelector(playgroundQueryTemplateSelector, (template) => {
  const inputs = template.get('importedQueryInputs')
  return inputs ? inputs.toJS() : {}
})

export const playgroundSelectedQueryValuesSelector = createSelector(playgroundQueryTemplateSelector, (template) => {
  const values = template.get('importedQueryDefaults')
  return values ? values.toJS() : {}
})

export const playgroundSqlResourceSelectedEditorMode = createSelector(playgroundQueryTemplateSelector, (template) => {
  return template.get('editorMode')
})

const defaultQueryResponse: PlaygroundQueryRunResponse = {
  isFetching: false,
  data: null,
  error: null,
  startedFetchAt: null,
  fetchedAt: null,
  queryId: 0,
}

export const playgroundQueryResponseSelector = createSelector(
  playgroundSelector,
  playgroundSelectedQuerySelector,
  (pg, query): PlaygroundQueryRunResponse => {
    if (query && query.id) {
      const resp = pg.getIn(['queryResponses', query.id])
      if (resp) {
        // Sometimes this is being passed in as immutable rather than json
        return Map.isMap(resp) ? resp.toJS() : resp
      }
    }
    return defaultQueryResponse
  },
)

export const playgroundQuerySavesSelector = createSelector(
  playgroundSelector,
  (pg): PlaygroundQuerySaves => {
    return pg.savedQuerySaves
  },
)

export const playgroundSelectedQuerySavesSelector = createSelector(
  playgroundSelectedQuerySelector,
  playgroundQuerySavesSelector,
  (query, saves) => {
    if (!query) return {}

    return saves.get(query.id) || {}
  },
)

export const playgroundSelectedQuerySaveIdSelector = createSelector(playgroundSelector, (pg) => pg.selectedQuerySaveId)

export const playgroundSelectedQuerySaveSelector = createSelector(
  playgroundSelectedQuerySavesSelector,
  playgroundSelectedQuerySaveIdSelector,
  (saves, saveId) => {
    if (saveId) {
      return saveId === 'latest' ? _.last(Object.values(saves)) : saves[saveId]
    }
  },
)

export const playgroundSelectedQuerySaveResourceSelector = createSelector(
  playgroundResourcesSelector,
  playgroundSelectedQuerySaveSelector,
  (resources, save) => {
    if (!save) return null
    if (save.adhocResourceType) {
      switch (save.adhocResourceType) {
        case 'RESTQuery':
          return resources.find((r) => r.name === 'REST-WithoutResource')
        default:
          // eslint-disable-next-line no-console
          console.log('Could not find resource of type', save.adhocResourceType)
          return null
      }
    }

    return resources.find((r) => r.production.id === save.resourceId)
  },
)

export const playgroundQueryChangedSelector = createSelector(
  playgroundQueryTemplateSelector,
  playgroundSelectedResourceSelector,
  playgroundSelectedQuerySelector,
  (template, resource, query) => {
    if (!query || !query.template) return false
    if (!template) return false

    if (resource.production && resource.production.id !== query.resourceId) return true

    return !query.template.equals(template)
  },
)

export const playgroundQuerySavesIsFetchingSelector = createSelector(playgroundSelector, (playground) =>
  playground.get('isFetchingQuerySaves'),
)

export const showPlaygroundQueryLatestVersionUpdatedWarningSelector = createSelector(
  selectedDatasourceSelector,
  (query) => {
    return query && query.template.get('showLatestVersionUpdatedWarning')
  },
)

/* Reusable Queries */
export const isImportedQuerySelector = createSelector(queryStateSelector, (queryState) => {
  return queryState && queryState.get('isImported')
})

export const pagesByIdSelector = createSelector(pagesWithPathsSelector, (pages) => {
  return pages.reduce((acc: { [key: number]: Page }, page: Page) => {
    return { ...acc, [page.id]: page }
  }, {})
})

export const authCustomThemeSelector = createSelector(globalsSelector, (globals) => globals.theme)

export const intercomOpenSelector = createSelector(globalsSelector, (globals) => globals.intercomOpen)

export const sandboxGlobalsSelector = createSelector(globalsSelector, (globals) => globals.sandboxGlobals)

export const allActiveMocksSelector = createSelector(globalsSelector, (globals) => globals.activeMocks)
export const currentlyRunningTestIdSelector = createSelector(
  globalsSelector,
  (globals) => globals.currentlyRunningTestId,
)

export const currentlyRunningTestObjectSelector = createSelector(
  testsSelector,
  currentlyRunningTestIdSelector,
  (tests, currentlyRunningTestId) => tests.find((currentTest) => currentTest.id === currentlyRunningTestId),
)

export const isLoadingPreAuthOrgDataSelector = createSelector(
  globalsSelector,
  (globals) => globals.isLoadingPreAuthOrgData,
)

export const isAirgappedSelector = createSelector(globalsSelector, (globals) => globals.airgapped)

export const hideProdAndStagingTogglesSelector = createSelector(
  globalsSelector,
  (globals) => globals.hideProdAndStagingToggles,
)

export const stagingModeEnabledSelector = createSelector(
  stagingRequiredQueriesSelector,
  hideProdAndStagingTogglesSelector,
  (queries, hideProdAndStagingToggles) => !hideProdAndStagingToggles && queries.length === 0,
)

export const enableClientSideCustomAuthBrowserCallsSelector = createSelector(
  globalsSelector,
  (globals) => globals.enableClientSideCustomAuthBrowserCalls,
)

export const enableCustomPlatformLevelAuthStepsSelector = createSelector(
  globalsSelector,
  (globals) => globals.enableCustomPlatformLevelAuthSteps,
)

export const customRetoolSandboxRestrictionsSelector = createSelector(
  globalsSelector,
  (globals) => globals.customRetoolSandboxRestrictions,
)

const flowsParentSelector = (state: RetoolState) => state.user.get('flows')
export const flowsSelector = createSelector(flowsParentSelector, (flows) => {
  return flows.get('flows').toJS()
})
export const isFetchingFlowsSelector = createSelector(flowsParentSelector, (flows) => {
  return flows.get('isFetching')
})

export const selectedWidgetIdSelector = createSelector(editorSelector, (editor) => editor.getSelectedWidgetId())
export const selectedWidgetModelSelector = createSelector(
  appModelSelector,
  selectedWidgetIdSelector,
  (appModel, selectedPluginId) => {
    const { instances } = appModel.pluginInListView(selectedPluginId)
    if (instances) {
      return (appModel.values.getPlugin(selectedPluginId) as any).get(0)
    }
    return appModel.values.getPlugin(selectedPluginId)
  },
)
export const selectedPluginSelector = createSelector(editorSelector, appTemplateSelector, (editor, appTemplate) => {
  const pluginId = editor.getEditorFormId()
  return pluginId ? appTemplate.getIn(['plugins', pluginId]) : null
})

export const globalWidgetsParentSelector = (state: RetoolState) => state.modules.get('availableModules')
export const globalWidgetsSelector = createSelector(
  globalWidgetsParentSelector,
  (availableModules): GlobalWidgetType[] => {
    return availableModules.get('modules').toJS()
  },
)
export const importableGlobalWidgetsSelector = createSelector(
  globalWidgetsSelector,
  currentPageUuidSelector,
  (globalWidgets, pageUuid) => {
    // Prevent module inception.
    return globalWidgets.filter((globalWidget) => globalWidget.pageUuid !== pageUuid)
  },
)

export const moduleCalloutShownSelector = createSelector(
  editorPrivilegedSelector,
  pagesSelector,
  pathSelector,
  (isEditor, pages, path) => {
    const orgHasGlobalWidgets = !!pages?.get('pages').some((p: TypedMap<Page>) => p.get('isGlobalWidget'))
    return isEditor && !orgHasGlobalWidgets && path.indexOf('/views/modules') !== -1
  },
)

const _globalWidgetInputsPluginSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.plugins
    .filter((plugin) => plugin.get('type') === 'globalwidgetprop' || plugin.get('subtype') === 'GlobalWidgetQuery')
    .filter((plugin) => !plugin.get('namespace'))
    .toList()
    .toJS()
})
export const globalWidgetInputsPluginSelector = createDeepEqualSelector(_globalWidgetInputsPluginSelector, (p) => p)

const _globalWidgetPropPluginSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.plugins
    .filter((plugin) => plugin.get('type') === 'globalwidgetprop')
    .filter((plugin) => !plugin.get('namespace'))
    .toList()
    .toJS()
    .sort()
})
export const globalWidgetPropPluginSelector = createDeepEqualSelector(_globalWidgetPropPluginSelector, (s) => s)

const _globalWidgetQueryPluginsSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.plugins
    .filter((plugin) => plugin.get('type') === 'datasource' && plugin.get('subtype') === 'GlobalWidgetQuery')
    .filter((plugin) => !plugin.get('namespace'))
    .toJS()
})
export const globalWidgetQueryPluginsSelector = createDeepEqualSelector(_globalWidgetQueryPluginsSelector, (s) => s)

const _globalWidgetOuputPluginSelector = createSelector(appTemplateSelector, (appTemplate) => {
  return appTemplate.plugins
    .filter((plugin) => plugin.get('type') === 'globalwidgetoutput')
    .filter((plugin) => !plugin.get('namespace'))
    .toList()
    .toJS()
    .sort()
})
export const globalWidgetOutputPluginSelector = createDeepEqualSelector(_globalWidgetOuputPluginSelector, (s) => s)

export const orgImageBlobsSelector = (state: RetoolState): Map<string, BlobStorageEntry> => state.orgImageBlobs.blobs

export const orgImageBlobsSequenceSelector = createSelector<
  RetoolState,
  BlobStorageEntry[],
  ReturnType<typeof orgImageBlobsSelector>
>(orgImageBlobsSelector, (blobs) => blobs.valueSeq().toJS())

const ONE_HOUR = 1000 * 60 * 60

// joined in the last hour, hasn't started nor finished onboarding
export const isNewAccountSelector = (state: RetoolState) =>
  +new Date() - +new Date(state.user.get('user').get('createdAt')) < ONE_HOUR

export const onboardingSourcePageSelector = createSelector(locationSelector, (location) =>
  new URLSearchParams(location.search).get('onboardingSourcePage'),
)

export const canShowModuleTutorialSelector = createSelector(
  onEditorSelector,
  editorPrivilegedSelector,
  isTemplateGlobalWidgetSelector,
  (onEditor, isEditorPrivileged, isModule) => {
    return onEditor && isEditorPrivileged && isModule
  },
)

export const alwaysShowSelfServeOnPremCTA = createSelector(locationSelector, (location) =>
  new URLSearchParams(location.search).get('selfServiceOnPrem'),
)

import React from 'react'
import { Alert, Spin } from 'antd'
import ResetPasswordForm from './../forms/ResetPasswordForm'
import Disclaimer from '../components/Disclaimer'
import AuthRetoolLogo from '../components/AuthRetoolLogo'

interface ResetPasswordPageProps {
  error: string
  isFetching: boolean
  restrictedDomain: string
  disableUserPassLogin: boolean
  isAirgapped: boolean
}

const ResetPasswordPage = (props: ResetPasswordPageProps) => {
  const { error, isFetching, restrictedDomain, isAirgapped, disableUserPassLogin } = props

  if (restrictedDomain || disableUserPassLogin) {
    return null
  }

  return (
    <div>
      <div className="body-inner">
        <div className="auth-container">
          <div className="auth-form-container">
            <AuthRetoolLogo className="mb24" />
            <h6 className="mb8">Reset your password</h6>
            <p className="auth-subheading mb16">
              {/* Since we don't currently have a way of sending emails on an airgapped instance,
              we don't support resetting passwords. Admins can use a Retool app to reset passwords*/}
              {isAirgapped
                ? 'Please contact your administrator to reset your password.'
                : 'Enter the email address associated with your account. We will send a reset link to your email.'}
            </p>
            {!isAirgapped && (
              <Spin spinning={isFetching}>
                <ResetPasswordForm />
                {error && (
                  <Alert message={error} type="error" style={{ margin: 'auto', marginBottom: 15, marginTop: 20 }} />
                )}
              </Spin>
            )}
            <Disclaimer />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordPage

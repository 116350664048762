import * as React from 'react'
import { callInternalApi } from 'networking'
import useFetch from 'common/hooks/useFetch'
import { useEffect, useState } from 'react'
import { Icon } from 'components/design-system'
import cookies from 'js-cookie'
import { browserHistory } from 'react-router'

type EmbedContainerProps = {}

export const getAppsClonedFromTemplate = async (templateName: string): Promise<string[]> => {
  const apiUrl = `/api/organization/appsClonedFromTemplate?templateName=${templateName}`

  const response = await callInternalApi({
    url: apiUrl,
    method: 'GET',
  })

  return response.pageUuids
}

const PARTNER_TEMPLATES = {
  'elastic-search-management': {
    integration: 'elasticsearch',
  },
}

const IFrameEmbedContainer = (_props: EmbedContainerProps) => {
  const [error, setError] = useState<string | undefined>()

  const urlParams = new URLSearchParams(window.location.search)
  const template = urlParams.get('template')

  const onTemplateNotYetCloned = (alreadyAuthed: boolean) => {
    // If <alreadyAuthed>, User already has an account. Take them to clone template flow
    // Otherwise, we don't know if the user has an account or not. We assume not, so we take them to the signup flow for cloning a template
    if (alreadyAuthed) {
      browserHistory.push(`/clone-template/${template}`)
    } else {
      const integration = template && PARTNER_TEMPLATES[template as keyof typeof PARTNER_TEMPLATES]?.integration
      browserHistory.push(
        `/auth/signup?iFrameEmbed=true${
          integration ? `&integration=${integration}` : ''
        }&next=/clone-template/${template}`,
      )
    }
  }

  const onAppsClonedFromTemplateSuccess = (uuids: string[]) => {
    if (uuids.length === 0) {
      onTemplateNotYetCloned(true)
      return
    }

    // For now, we redirect to the first cloned app. Eventually we may have a UI where the user
    // can select which previously cloned app to view
    browserHistory.push(`/uuid/apps/${uuids[0]}`)
  }

  const fetchAppsClonedFromTemplate = useFetch(() => getAppsClonedFromTemplate(template as string), {
    onSuccess: (uuids) => onAppsClonedFromTemplateSuccess(uuids),
  })

  useEffect(() => {
    const subdomain = cookies.get('subdomain')

    if (!subdomain) {
      // Not an existing Retool user or not logged in
      return onTemplateNotYetCloned(false)
    }

    const isOnSubdomain = (window?.location?.href || '').includes(subdomain)
    if (isOnSubdomain) {
      if (!template) {
        setError('Error: template query parameter not provided')
        return
      }

      fetchAppsClonedFromTemplate()
    } else {
      // We are on https://login.retool.com/iframeembed. Redirect to http://<subdomain>/iframeembed so
      // accessToken is included in calls to backend
      window.location.href = `${window.location.protocol}//${subdomain}${window.location.pathname}${
        window.location.search || ''
      }`
    }
  }, [])

  if (error) {
    return <div>{error}</div>
  }

  return (
    <div className="flex items-center justify-center w-100 h-100">
      <Icon type="loading" />
    </div>
  )
}

export default IFrameEmbedContainer

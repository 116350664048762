import { hasFeature__UNSAFE_IN_RENDER } from 'common/paymentPlans'
import { Tooltip } from 'components/design-system'
import ProfilePhoto from 'components/standards/ProfilePhoto'
import { memoize, uniqBy } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { retoolOrangeBackground } from 'retoolColors'
import { paths } from 'routes/Settings/components/permissions/constants'
import { getPermissions } from 'routes/Settings/modules/groups'
import { RetoolState } from 'store'
import { TypedMap } from 'common/utils/immutable'
import { User } from '__globalShared__/accounts'
import './AppDetails.scss'

class AppDetailsPane extends React.Component<any> {
  componentDidMount() {
    this.props.getPermissions()
  }

  render() {
    const hasPermissionsFeature = hasFeature__UNSAFE_IN_RENDER('AccessControls')
    return (
      <div
        className="app-details"
        style={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 3,
          maxHeight: '100%',
        }}
      >
        <div className="fw-600 fs-16 mb12">User list</div>
        {!hasPermissionsFeature && (
          <div
            style={{
              padding: '12px 20px',
              background: retoolOrangeBackground,
              color: 'white',
              borderRadius: 3,
              marginBottom: 20,
            }}
          >
            Access controls isn't available on your plan. Go to{' '}
            <Link style={{ color: 'white', textDecoration: 'underline' }} to="/settings/billing">
              Billing
            </Link>{' '}
            to change your plan.
          </div>
        )}
        <div style={{ marginBottom: 20 }}>
          <div className="fs-13 gray">
            {this.props.users.length} users have access to <strong>{this.props.page.name}</strong>. To modify this, go
            to <Link to={paths.root}>Permission Groups</Link>.
          </div>
        </div>
        <div className="app-details__user-list">
          <div className="app-details__user-list-header">
            <div style={{ width: '40%' }}>User</div>
            <div style={{ width: '40%' }}>Groups</div>
            <div>Permissions</div>
          </div>
          {this.props.users.map((user: any, idx: any) => {
            const groupsDisplay = user.groups.join(', ')
            return (
              <div className="app-details__user-list-user" key={idx}>
                <div className="flex items-center" style={{ width: '40%' }}>
                  <div className="mr20">
                    <ProfilePhoto user={user} />
                  </div>
                  <div className="flex fd-col truncate pr20">
                    <div className="fw-500 truncate">
                      {user.firstName} {user.lastName}
                    </div>
                    <div className="fw-400 truncate">{user.email}</div>
                  </div>
                </div>
                <div style={{ width: '40%' }} className="truncate flex items-center">
                  <Tooltip overlay={groupsDisplay}>
                    <div className="truncate pr20">{groupsDisplay}</div>
                  </Tooltip>
                </div>
                <div className="tt-capitalize">{user.accessLevel}</div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

// returns array of users, including:
// * group that gives them access
// * what type of access
const ACCESS_POWER = ['own', 'write', 'read', 'none']
const usersOfApp = memoize(
  (orgInfo, pageId) => {
    const userGroups = orgInfo.get('userGroups')
    const groupPages = orgInfo.get('groupPages')
    const groups = orgInfo.get('groups')
    const users = orgInfo.get('users')

    const res: any = []
    users.forEach((user: TypedMap<User>) => {
      // Hide disabled users
      if (!user.get('enabled')) return

      const relevantGroups = userGroups
        .toList()
        .filter((ug: any) => ug.get('userId') === user.get('id'))
        .map((ug: any) => groups.get(String(ug.get('groupId'))))

      const matchingCustomGroups = groupPages.filter(
        (gp: any) =>
          gp.get('pageId') === pageId && relevantGroups.find((group: any) => group.get('id') === gp.get('groupId')),
      )
      let highestAccessGroupId: any = null
      let highestAccessLevel: any = null

      const displayGroups = [
        ...matchingCustomGroups.map((g: any) => ({ id: g.get('groupId'), accessLevel: g.get('accessLevel') })).values(),
        ...relevantGroups.map((g: any) => ({ id: g.get('id'), accessLevel: g.get('universalAccess') })),
      ].filter((g: any) => g.accessLevel !== 'none')

      // access is determined by:
      // 1. the access level from groupPages
      // 2. the universal access level of the group (e.g. admin is always "own")
      const updateHighestAccessGroup = (group: any) => {
        if (!highestAccessGroupId) {
          highestAccessGroupId = group.id
          highestAccessLevel = group.accessLevel
          return
        }

        const isBetter = ACCESS_POWER.indexOf(highestAccessLevel) - ACCESS_POWER.indexOf(group.accessLevel) > 0
        highestAccessGroupId = isBetter ? group.id : highestAccessGroupId
        highestAccessLevel = isBetter ? group.accessLevel : highestAccessLevel
      }

      displayGroups.forEach(updateHighestAccessGroup)

      if (!highestAccessGroupId) return
      if (highestAccessLevel === 'none') return

      const uniqueSortedDisplayGroups = uniqBy(
        displayGroups.sort((one, two) => {
          const oneAccessPower = ACCESS_POWER.indexOf(one.accessLevel)
          const twoAccessPower = ACCESS_POWER.indexOf(two.accessLevel)

          // sort by access level
          if (oneAccessPower > twoAccessPower) return 1
          if (oneAccessPower < twoAccessPower) return -1

          // sort by group id (ex: to show editor before custom group if same access level)
          return one.id - two.id
        }),
        (g) => g.id,
      )

      res.push({
        ...user.toJS(),
        accessLevel: highestAccessLevel,
        groups: uniqueSortedDisplayGroups.map((g) => groups.get(`${g.id}`).get('name')),
      })
    })

    return res
  },
  // _.memoize only supports one arg by default
  (...args) => JSON.stringify(args),
)

export default connect(
  (state: RetoolState, ownProps: any) => {
    return {
      users: usersOfApp(state.user.orgInfo, ownProps.page.id),
    }
  },
  {
    getPermissions,
  },
)(AppDetailsPane)

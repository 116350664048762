import * as React from 'react'
import { browserHistory } from 'react-router'

import { callInternalApi } from 'networking'

const EmailVerificationCheckPage = ({ registrationToken }: { registrationToken?: string }) => {
  const [verifying, setVerifying] = React.useState(true)
  const [verificationError, setVerificationError] = React.useState<Error | null>(null)
  React.useEffect(() => {
    callInternalApi({
      method: 'POST',
      url: '/api/verifyEmail',
      body: { registrationToken },
    })
      .then(() => {
        browserHistory.push('/auth/joinOrg')
        return
      })
      .catch((err: Error) => {
        setVerificationError(err)
      })
      .finally(() => {
        setVerifying(false)
      })
  }, [])
  return (
    <div>
      <div className="body-inner">
        <div>
          <div className="auth-container">
            <div className="auth-form-container">
              {verifying && <>Checking...</>}
              {verificationError !== null && (
                <div>
                  <h6>An error occurred validating your email</h6>
                  <div className="mt12">{verificationError.message}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmailVerificationCheckPage

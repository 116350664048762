import { useEffect } from 'react'
import useCallbackRef from './useCallbackRef'

/**
 * Adds an event listener to the window on mount, and removes it on unmount.
 *
 * The callback function can change on every render with no impact on perf.
 */
export default function useWindowEventListener<K extends keyof WindowEventMap>(
  type: K,
  listener: (ev: WindowEventMap[K]) => void,
  options?: boolean | AddEventListenerOptions,
): void {
  const callback = useCallbackRef(listener)

  useEffect(() => {
    window.addEventListener(type, callback, options)

    return () => {
      window.removeEventListener(type, callback, options)
    }
  }, [type, callback, options])
}

import { Position2, Position2Params, PluginNamespaceInfo, Position2Diff } from 'common/records'
import { OrderedMap } from 'immutable'
import min from 'lodash/min'
import times from 'lodash/times'

export const SPECIAL_NEW_WIDGET_CONSTANT = 'SPECIAL_NEW_WIDGET_CONSTANT'

// Based on the provided positions, returns the correct positions if some of the widgets are hidden
// Creates space to collapse by container
/** @deprecated use the layout manager */
export const adjustPositions = (positions: Position2[], positionIsHidden: (index: number) => boolean) => {
  const spaceToCollapseByContainer: { [s: string]: number[] } = {}
  for (const position of positions) {
    if (!spaceToCollapseByContainer[position.getContainerKey()]) {
      spaceToCollapseByContainer[position.getContainerKey()] = times(12, () => 0)
    }
  }
  const adjustedPositions: Position2[] = []
  for (const [i, position] of positions.entries()) {
    const left = position.col
    const right = left + position.width
    const spaceToCollapseByColumn = spaceToCollapseByContainer[position.getContainerKey()]
    const minSpaceToCollapse = min(spaceToCollapseByColumn.slice(left, right))

    let adjustedPosition = position.set('row', position.row - minSpaceToCollapse!)
    let newSpaceToCollapse = minSpaceToCollapse
    if (positionIsHidden(i)) {
      newSpaceToCollapse = minSpaceToCollapse! + adjustedPosition.height
      adjustedPosition = adjustedPosition.set('height', 0)
    }
    adjustedPositions.push(adjustedPosition)
    for (let i = left; i < right; i++) {
      spaceToCollapseByColumn[i] = newSpaceToCollapse!
    }
  }
  return adjustedPositions
}

export const WIDGET_OVERFLOW_TYPES = ['scroll', 'hidden', 'visible'] as const
export type WidgetOverflowType = typeof WIDGET_OVERFLOW_TYPES[number]

export const WIDGET_HEIGHT_TYPES = ['auto', 'fixed'] as const
export type WidgetHeightType = typeof WIDGET_HEIGHT_TYPES[number]

export interface WidgetLayout {
  id: string
  widgetType: string
  position: Position2
  childGridPadding: number
  currentContainerKey: string
  showFetchingIndicator: boolean
  instances?: number
  itemHeight?: number
  namespace?: PluginNamespaceInfo
  hidden?: boolean
  showInEditor?: boolean
  overflowType?: WidgetOverflowType
  heightType?: WidgetHeightType
  dynamicHeight?: number
  selected?: boolean
}
export interface WidgetTreeNode extends WidgetLayout {
  children: WidgetTreeNode[]
}
export type WidgetTree = WidgetTreeNode[]

export type WidgetLayoutMap = OrderedMap<string, WidgetLayout>

export type WidgetMoveType = 'drag' | 'resize' | 'keyboard' | undefined

export interface WidgetMove {
  widgetIds: string[]
  move: Position2Diff
  moveType?: WidgetMoveType
}

export interface NewWidgetParams {
  widgetType: string
  position: Position2Params
}

export interface CopyWidgetParams extends NewWidgetParams {
  widgetId: string
}

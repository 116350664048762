import React from 'react'
import { ShortcutSection, Shortcut } from './shortcutSections'
import ShortcutKeys from './ShortcutKeys'

import './ShortcutDetails.scss'

export interface ShortcutDetailsProps {
  sections?: ShortcutSection[]
}

const cn = 'shortcut-details'

export default function ShortcutDetails({ sections = [] }: ShortcutDetailsProps) {
  return (
    <div className={cn}>
      {sections.map((section: ShortcutSection) => (
        <ShortcutDetailsSection key={section.label} {...section} />
      ))}
    </div>
  )
}

function ShortcutDetailsSection({ label, shortcuts }: ShortcutSection) {
  return (
    <section>
      <header className={`${cn}__section-header`}>{label}</header>
      <dl>
        {shortcuts.map((shortcut: Shortcut) => (
          <ShortcutDetailsRow key={shortcut.name} {...shortcut} />
        ))}
      </dl>
    </section>
  )
}

function ShortcutDetailsRow({ name, bindings }: Shortcut) {
  return (
    <div className={`${cn}__item`}>
      <dt>{name}</dt>
      <dd>
        {bindings.map((keys) => (
          <span key={keys} className={`${cn}__binding`}>
            <ShortcutKeys keys={keys} />
          </span>
        ))}
      </dd>
    </div>
  )
}

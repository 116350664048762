import React, { useEffect } from 'react'

import {
  InputField,
  HostInputField,
  KeyValueField,
  AuthenticationSelection,
  APIAuthMethodType,
  Auth0APIOptions,
  OAuth2APIOptions,
  API_AUTH_METHODS,
  RSA_KEY_PLACEHOLDER_SHORTENED,
  CERTIFICATE_PLACEHOLDER_SHORTENED,
} from './common'
import { ResourceFormProps } from './types'
import Select from 'components/design-system/Select'
import Checkbox from 'components/design-system/Checkbox'
import TextInput from 'components/design-system/TextInput'
import Collapse from 'components/design-system/Collapse'
import { connect } from 'react-redux'
import { getGrpcServiceNames } from '../../../routes/Resources/modules/resources'
import { RetoolState } from 'store'
import { createSelector } from 'reselect'
import { Spin } from 'antd'

type GRPCFormOptions = {
  serviceName: string
  metadata: [string, string][]
  authentication: string
} & Auth0APIOptions &
  OAuth2APIOptions

type GRPCFormFields = {
  ssl: boolean
  options: GRPCFormOptions
}

type GRPCFormProps = {
  getGrpcServiceNames: () => void
  isFetchingServiceNames: boolean
  grpcServiceNames: string[]
}

export const gRPC_AUTH_METHODS: APIAuthMethodType[] = ['auth0', 'oauth2', 'custom']

const defaultOptions: GRPCFormFields = {
  ssl: false,
  options: {
    serviceName: '',
    metadata: [['', '']],
    authentication: '',
    ...API_AUTH_METHODS.auth0.defaults,
    ...API_AUTH_METHODS.oauth2.defaults,
  },
}

const GRPCForm = (props: ResourceFormProps & GRPCFormProps) => {
  const { getGrpcServiceNames, isFetchingServiceNames, grpcServiceNames } = props

  useEffect(() => {
    getGrpcServiceNames()
  }, [getGrpcServiceNames])

  return (
    <div className="mb20">
      <div className="grid-1c mb12">
        <div className="grid-offset-1">
          Select your gRPC service below. These options are parsed from your proto files.
          <br />
          Not seeing your services? Please check out the{' '}
          <a href="https://docs.retool.com/docs/grpc" target="_blank" rel="noopener">
            gRPC guide
          </a>
          .
        </div>
        <label className="input-label">
          <span>
            <span className="light-red mr4">*</span> Service Name
          </span>
        </label>
        <Select
          showSearch
          placeholder="Select a service"
          notFoundContent={
            isFetchingServiceNames ? (
              <div className="flex justify-center">
                <Spin size="small" />
              </div>
            ) : (
              'No gRPC services found'
            )
          }
          value={props.resource.options.serviceName}
          onChange={props.onChangeOption('serviceName')}
        >
          {grpcServiceNames.map((serviceName) => (
            <Select.Option key={serviceName} value={serviceName}>
              {serviceName}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="grid-1c mb12">
        <HostInputField {...props} />
        <InputField label="Port" resourceKey="port" topLevel {...props} />
        <KeyValueField
          label="Metadata"
          resourceKey="metadata"
          resource={props.resource}
          updateResourceOptions={props.updateResourceOptions}
        />
      </div>
      <div className="grid-1c mb12">
        <Checkbox
          checked={props.resource.ssl}
          onChange={(checked) => {
            props.onChangeTopLevel('ssl')(checked.target.checked)
          }}
          className="grid-offset-1"
        >
          Connect using SSL
        </Checkbox>
      </div>
      {props.resource.ssl && (
        <div className="grid-1c mb12">
          <Checkbox
            checked={props.resource.options.gRPCConnectWithSelfSignedCertificate}
            onChange={(checked) => {
              props.updateResourceOptions({
                gRPCConnectWithSelfSignedCertificate: checked.target.checked,
                ca_cert: null,
                client_cert_and_key: null,
              })
            }}
            className="grid-offset-1"
          >
            Use a self-signed certificate
          </Checkbox>

          {props.resource.options.gRPCConnectWithSelfSignedCertificate && (
            <>
              <label className="input-label">CA cert</label>
              <TextInput.TextArea
                value={props.resource.options.root_certs}
                onChange={props.onChangeOption('root_certs', { handleEvent: true })}
                autosize={{ minRows: 8 }}
                placeholder={CERTIFICATE_PLACEHOLDER_SHORTENED}
              />
              <label className="input-label">
                Client Certificate <br />
                Private Key
              </label>
              <TextInput.TextArea
                value={props.resource.options.private_key}
                onChange={props.onChangeOption('private_key', { handleEvent: true })}
                autosize={{ minRows: 8 }}
                placeholder={RSA_KEY_PLACEHOLDER_SHORTENED}
              />
              <label className="input-label">
                Client Certificate <br />
                Cert Chain
              </label>
              <TextInput.TextArea
                value={props.resource.options.cert_chain}
                onChange={props.onChangeOption('cert_chain', { handleEvent: true })}
                autosize={{ minRows: 8 }}
                placeholder={CERTIFICATE_PLACEHOLDER_SHORTENED}
              />
            </>
          )}
        </div>
      )}
      <div className="grid-1c">
        <AuthenticationSelection authMethods={gRPC_AUTH_METHODS} {...props} />
      </div>
      <hr />

      <Collapse expandIconPosition="right">
        <Collapse.Panel key={0} forceRender header={<h5 className="section-heading light-gray">Advanced</h5>}>
          <div className="grid-1c">
            <InputField
              label="Maximum incoming message size (bytes)"
              resourceKey="max_receive_message_length"
              {...props}
            />
            <InputField
              label="Maximum outgoing message size (bytes)"
              resourceKey="max_send_message_length"
              {...props}
            />
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}

const resourcesSelector = (state: RetoolState) => state.resources
const isFetchingGRPCServiceNamesSelector = createSelector(
  resourcesSelector,
  (resources) => resources.isFetchingGrpcServiceNames,
)
const grpcServiceNamesSelector = createSelector(resourcesSelector, (resources) => resources.grpcServiceNames)

const mapStateToProps = (state: RetoolState) => {
  return {
    isFetchingServiceNames: isFetchingGRPCServiceNamesSelector(state),
    grpcServiceNames: grpcServiceNamesSelector(state),
  }
}

const mapDispatchToProps = {
  getGrpcServiceNames,
}

export default {
  label: 'gRPC',
  form: connect(mapStateToProps, mapDispatchToProps)(GRPCForm),
  defaults: {
    options: defaultOptions,
  },
  validator: ({ options }: { options: GRPCFormOptions }) => {
    return (
      options.serviceName && // truthy service name
      ((options.authentication === 'auth0' && options.auth0_domain && options.auth0_clientID) ||
        (options.authentication === 'oauth2' &&
          options.oauth2_auth_url &&
          options.oauth2_access_token_url &&
          options.oauth2_client_id &&
          options.oauth2_auth_url) ||
        options.authentication === 'custom' ||
        options.authentication === undefined ||
        options.authentication === 'none' ||
        options.authentication === '')
    )
  },
}

/**
 * Allow globals ONLY in this file, which transfers the value of global variables
 * to the more easily visualized global applicaton state.
 */
/* global __AIRGAPPED__, __BETA__ */
/* global API_HOSTNAME, CLIENT_ID, INCLUDE_COOKIES_IN_API_CALLS, MAIN_DOMAIN */
/* global RESTRICTED_DOMAIN, SANDBOX_DOMAIN, DISABLE_USER_PASS_LOGIN */
import { callInternalApi } from 'networking'

import {
  ADD_ACTIVE_MOCK_FOR_TEST,
  FAILURE_PRE_AUTH_ORG_DATA,
  INTERCOM_OPEN,
  MARK_TEST_FINISHED,
  RECEIVE_PRE_AUTH_ORG_DATA,
  RECEIVE_USER_PROFILE,
  REMOVE_ALL_ACTIVE_MOCKS_FOR_TEST,
  REQUEST_PRE_AUTH_ORG_DATA,
  SET_CURRENTLY_RUNNING_TEST,
  UPDATE_SANDBOX_GLOBALS,
} from './constants'
import { RECEIVE_PAGE_LOAD } from 'store/appModel/pages'

import {
  GlobalsReducerType,
  PreAuthOrgDataAsyncActionType,
  PreAuthOrgDataFailureType,
  PreAuthOrgDataReceiveType,
  PreAuthOrgDataRequestType,
} from './globals.types'

import { byteArrayToImgBase64String } from 'common/utils'
import { RetoolDispatch } from 'store'
import { Mock } from 'common/records'
import { cloneDeep } from 'lodash'

// ------------------------------------
// Synchronous Actions
// ------------------------------------
const orgPreAuthDataRequest = (): PreAuthOrgDataRequestType => {
  return {
    type: REQUEST_PRE_AUTH_ORG_DATA,
  }
}

const orgPreAuthDataReceive = (json: { ssoType: '' }): PreAuthOrgDataReceiveType => {
  return {
    type: RECEIVE_PRE_AUTH_ORG_DATA,
    payload: json,
  }
}

const orgPreAuthDataFailure = (errorMessage: string): PreAuthOrgDataFailureType => {
  return {
    type: FAILURE_PRE_AUTH_ORG_DATA,
    payload: {
      error: errorMessage,
    },
  }
}

// ------------------------------------
// Async Actions
// ------------------------------------
export const preAuthOrgData: PreAuthOrgDataAsyncActionType = () => async (dispatch: RetoolDispatch) => {
  dispatch(orgPreAuthDataRequest())
  try {
    // For on-prem call the API for , for cloud return { ssoType: 'google' }
    const result = !MAIN_DOMAIN
      ? await callInternalApi({
          url: `/api/organization/preAuthData`,
          method: 'GET',
        })
      : { ssoType: 'google', theme: false }
    dispatch(orgPreAuthDataReceive(result))
  } catch (error) {
    dispatch(orgPreAuthDataFailure(error))
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  // global environment variables --> global application state
  airgapped: __AIRGAPPED__,
  beta: __BETA__,
  // coverage: __COVERAGE__,
  // dev: __DEV__,
  // prod: __PROD__,
  // test: __TEST__,
  apiHostname: API_HOSTNAME,
  clientId: CLIENT_ID,
  includeCookiesInApiCalls: INCLUDE_COOKIES_IN_API_CALLS,
  mainDomain: MAIN_DOMAIN,
  restrictedDomain: RESTRICTED_DOMAIN,
  disableUserPassLogin: DISABLE_USER_PASS_LOGIN,
  ssoType: '',
  sandboxDomain: SANDBOX_DOMAIN,
  htmlEscapeRetoolExpressions: false,
  enableClientSideCustomAuthBrowserCalls: false,
  enableCustomPlatformLevelAuthSteps: false,
  isLoadingPreAuthOrgData: false,
  hideProdAndStagingToggles: false,
  customRetoolSandboxRestrictions: '',
  instrumentation: [],
  intercomOpen: false,
  sandboxGlobals: [],
  activeMocks: {},
  currentlyRunningTestId: '',
  // fs: FS,
  // analytics,
  // tableau,
}

const handleAddActiveMock = (state: GlobalsReducerType, action: { payload: { mock: Mock; testId: string } }) => {
  const { mock, testId } = action.payload
  const currentMocks = cloneDeep(state.activeMocks)
  if (currentMocks[testId] === undefined) {
    currentMocks[testId] = []
  }
  currentMocks[testId].push(mock)
  return {
    ...state,
    activeMocks: currentMocks,
  }
}

const handleMarkTestFinished = (state: GlobalsReducerType) => {
  const currentState = cloneDeep(state)
  currentState.currentlyRunningTestId = ''
  return currentState
}

const handleSetCurrentlyRunningTest = (state: GlobalsReducerType, action: { payload: { testId: string } }) => {
  const { testId } = action.payload
  if (state.currentlyRunningTestId !== '') {
    throw new Error('Attempting to set a currently running test while another is still running. ')
  }
  return {
    ...state,
    currentlyRunningTestId: testId,
  }
}

const handleRemoveAllMocksForTest = (state: GlobalsReducerType, action: { payload: { testId: string } }) => {
  const { testId } = action.payload
  const currentMocks = cloneDeep(state.activeMocks)
  delete currentMocks[testId]
  return {
    ...state,
    activeMocks: currentMocks,
  }
}

// Temporarily type actions as any because typing reducers is low value
const globalsReducer = (state: GlobalsReducerType = initialState, action: any): GlobalsReducerType => {
  switch (action.type) {
    case RECEIVE_PRE_AUTH_ORG_DATA: {
      const payloadTheme = action.payload.theme
      let theme
      if (payloadTheme && payloadTheme.logoFile && payloadTheme.logoFile.data) {
        theme = {
          logoFileBase64String: byteArrayToImgBase64String(payloadTheme.logoFile.data),
        }
      }
      return {
        ...state,
        ssoType: action.payload.ssoType,
        theme,
        isLoadingPreAuthOrgData: false,
      }
    }
    case REQUEST_PRE_AUTH_ORG_DATA: {
      return {
        ...state,
        isLoadingPreAuthOrgData: true,
      }
    }
    case FAILURE_PRE_AUTH_ORG_DATA: {
      return {
        ...state,
        isLoadingPreAuthOrgData: false,
      }
    }
    case ADD_ACTIVE_MOCK_FOR_TEST: {
      return handleAddActiveMock(state, action)
    }
    case REMOVE_ALL_ACTIVE_MOCKS_FOR_TEST: {
      return handleRemoveAllMocksForTest(state, action)
    }
    case SET_CURRENTLY_RUNNING_TEST: {
      return handleSetCurrentlyRunningTest(state, action)
    }
    case MARK_TEST_FINISHED: {
      return handleMarkTestFinished(state)
    }
    case INTERCOM_OPEN: {
      return {
        ...state,
        intercomOpen: action.payload.intercomOpen,
      }
    }
    case UPDATE_SANDBOX_GLOBALS: {
      return {
        ...state,
        sandboxGlobals: action.payload.sandboxGlobals,
      }
    }
    case RECEIVE_USER_PROFILE: {
      return {
        ...state,
        htmlEscapeRetoolExpressions: action.payload.org.environmentVariables?.htmlEscapeRetoolExpressions || false,
        hideProdAndStagingToggles: action.payload.org.environmentVariables?.hideProdAndStagingToggles || false,
        enableClientSideCustomAuthBrowserCalls:
          action.payload?.org?.environmentVariables?.enableClientSideCustomAuthBrowserCalls || false,
        enableCustomPlatformLevelAuthSteps:
          action.payload?.org?.environmentVariables?.enableCustomPlatformLevelAuthSteps || false,
        customRetoolSandboxRestrictions:
          action.payload?.org?.environmentVariables?.customRetoolSandboxRestrictions || '',
      }
    }
    case RECEIVE_PAGE_LOAD: {
      return {
        ...state,
        htmlEscapeRetoolExpressions: action.payload.environmentVariables?.htmlEscapeRetoolExpressions || false,
        hideProdAndStagingToggles: action.payload.environmentVariables?.hideProdAndStagingToggles || false,
        enableClientSideCustomAuthBrowserCalls:
          action.payload?.org?.environmentVariables?.enableClientSideCustomAuthBrowserCalls || false,
        enableCustomPlatformLevelAuthSteps:
          action.payload?.org?.environmentVariables?.enableCustomPlatformLevelAuthSteps || false,
        customRetoolSandboxRestrictions:
          action.payload?.org?.environmentVariables?.customRetoolSandboxRestrictions || '',
      }
    }
    default: {
      return state
    }
  }
}

export default globalsReducer

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Map, Record } from 'immutable'

export const makeTypedMap = <T extends {}>(collection: T): TypedMap<T> => Map(collection) as any

export interface TypedMap<T extends {}> extends Map<keyof T, any> {
  toJS(): T
  toJSON(): T
  toObject(): T

  get<K extends keyof T>(key: K): T[K]
  set<K extends keyof T>(key: K, value: T[K]): this

  update<K extends keyof T>(key: K, notSetValue: T[K], updater: (value: T[K]) => T[K]): this
  update<K extends keyof T>(key: K, updater: (value: T[K]) => T[K]): this
  update<R>(updater: (value: this) => R): R

  // TODO: don't allow required keys to be deleted
  delete<K extends keyof T>(key: K): this
  remove<K extends keyof T>(key: K): this

  merge<K extends keyof T>(...collections: Iterable<[K, T[K]]>[]): this
  merge(...collections: Partial<T>[]): this
  concat<K extends keyof T>(...collections: Iterable<[K, T[K]]>[]): this
  concat(...collections: Partial<T>[]): this

  // TODO: some methods should return a new type of `TypedMap<?>` rather than `this` or `Map`
  // e.g., map, filter, merge, etc
}

export interface ReadOnlyTypedMap<T extends {}> {
  toJS(): T
  get<K extends keyof T>(key: K): T[K]
}

type AllowUndefined<T> = { [K in keyof T]: T[K] | undefined }

export type DefaultRecordValues<T> = AllowUndefined<Required<T>>

export const makeTypedRecord = <T>(defaultValues: DefaultRecordValues<T>, name?: string): Record.Factory<T> =>
  Record(defaultValues as T, name)

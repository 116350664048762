import React, { useEffect } from 'react'
import { browserHistory } from 'react-router'

import { Alert, Spin } from 'antd'

import Disclaimer from '../components/Disclaimer'
import GoogleLoginButton from './../components/GoogleLoginButton'
import SSOLoginButton from '../components/SSOLoginButton'

import LoginForm from './../forms/LoginForm'

import GoogleLogo from 'assets/google.svg'
import AuthRetoolLogo from '../components/AuthRetoolLogo'
import { setCookies } from 'routes/Login/logic/cookieUtils'
import cookies from 'js-cookie'
import { REDIRECTING_TO_BILLING_PORTAL_COOKIE_NAME } from 'store/constants'

interface LoginPageProps {
  clientId: string
  error: string | null
  isFetching: boolean
  login: any // <-- improve type after refactoring `login.js`
  redirectUri: string
  restrictedDomain: string
  disableUserPassLogin: boolean
  ssoType: string
}

type LoginPageSubheadingProps = {
  showLoginForm: boolean
}

const LoginPageSubheading = (props: LoginPageSubheadingProps) => {
  const { showLoginForm } = props
  const redirectingToBilling = cookies.get(REDIRECTING_TO_BILLING_PORTAL_COOKIE_NAME) != null

  if (showLoginForm) {
    return (
      <div className="auth-subheading">
        {redirectingToBilling ? "Don't have a cloud account?" : 'Need to create a new organization?'}&nbsp;
        <a className="login-a-header" onClick={() => browserHistory.push('/auth/signup')}>
          Sign up
        </a>
        &nbsp;here.
      </div>
    )
  } else {
    return <></>
  }
}

const LoginPage = (props: LoginPageProps) => {
  const { clientId, isFetching, login, redirectUri, restrictedDomain, ssoType, disableUserPassLogin } = props
  let { error } = props
  const showGoogleAuthButton = clientId && ssoType === 'google'
  const showSSOAuthButton = ssoType === 'okta' || ssoType === 'saml' || ssoType === 'customoauth2'
  const showLoginForm = !(disableUserPassLogin || restrictedDomain)
  const redirectingToBilling = cookies.get(REDIRECTING_TO_BILLING_PORTAL_COOKIE_NAME) != null
  const googleLoginText = redirectingToBilling ? 'Sign in to cloud with Google' : 'Sign in with Google'
  const headerText = redirectingToBilling ? <>Manage billing with your Retool.com account</> : <>Welcome back</>
  if (redirectingToBilling && error) {
    error = `${error}. Make sure to use a cloud retool.com login.`
  }

  useEffect(() => {
    setCookies()
  }, [])

  return (
    <div>
      <div className="body-inner">
        <div>
          <div className="auth-container">
            <div className="auth-form-container">
              <AuthRetoolLogo className="mb24" />
              <h6 className="mb4">{headerText}</h6>
              <LoginPageSubheading showLoginForm={showLoginForm} />
              <div className="auth-form-inner">
                <Spin spinning={isFetching}>
                  {showSSOAuthButton && (
                    <div className="auth-button-row">
                      <SSOLoginButton ssoType={ssoType} />
                    </div>
                  )}
                  {showGoogleAuthButton && (
                    <div className="auth-button-row">
                      <GoogleLoginButton
                        clientId={clientId}
                        uxMode="redirect"
                        redirectUri={`${window.location.origin}/oauthcallback`}
                        responseType="code token id_token"
                        scope="openid profile email"
                        onClick={(): void => null!}
                        fetchBasicProfile
                      >
                        <img src={GoogleLogo} className="logo" />
                        {googleLoginText}
                      </GoogleLoginButton>
                    </div>
                  )}
                  {(showSSOAuthButton || showGoogleAuthButton) && showLoginForm && (
                    <div
                      style={{ borderTop: '1px solid var(--washed-gray)' }}
                      className=" flex items-center justify-center mt20 mb8"
                    >
                      <div style={{ marginTop: '-12px', background: '#fff' }} className="ph12 light-gray">
                        or
                      </div>
                    </div>
                  )}
                  {showLoginForm && <LoginForm login={login} redirectUri={redirectUri} />}
                  {error && (
                    <Alert message={error} type="error" style={{ margin: 'auto', marginBottom: 15, marginTop: 20 }} />
                  )}
                </Spin>
              </div>
              <Disclaimer />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage

import { ExperimentValues } from 'common/records'
import { useSelector } from 'react-redux'
import { getState as getGlobalStateDeprecated, RetoolState } from 'store'

/**
 * gets the experiment value out of redux.
 *
 * NOTE: this only works on the main app, and won't work on the sandbox
 *
 * @deprecated use useExperiment where possible
 */
export function getExperimentValue(key: keyof ExperimentValues): boolean | null {
  const state = getGlobalStateDeprecated()
  if (!state) return null

  return experimentValueSelector(state, key)
}

/**
 * Returns whether an experiment flag is enabled for the current user/org
 */
export function experimentValueSelector(state: RetoolState, key: keyof ExperimentValues): boolean {
  return !!state?.user?.experimentValues?.[key]
}

/**
 * Returns whether an experiment flag is enabled for the current user/org
 */
export function useExperiment(key: keyof ExperimentValues): boolean {
  return useSelector((state: RetoolState) => experimentValueSelector(state, key))
}

import React from 'react'
import classNames from 'classnames'
import { Pagination as AntPagination } from 'antd'
import { PaginationProps as AntPaginationProps } from 'antd/lib/pagination'

import 'antd/lib/pagination/style/index'
import './Pagination.scss'

export type PaginationProps = AntPaginationProps & {}

const Pagination = (props: PaginationProps) => {
  return <AntPagination {...props} className={classNames('retool-pagination', props.className)} />
}

export default Pagination

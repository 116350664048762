import * as Sentry from '@sentry/browser'

import { RetoolMiddleware } from './'

const crashReporter: RetoolMiddleware = () => (next) => (action) => {
  try {
    return next(action)
  } catch (err) {
    Sentry.withScope((scope) => {
      scope.setExtras({ action: { type: action.type } })
      Sentry.captureException(err)
      // eslint-disable-next-line no-console
      console.error(err)
    })
  }
}

export default crashReporter

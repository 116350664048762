import { isArray, isObject } from 'lodash'
import * as Papa from 'papaparse'

export function convertToRowBased(columns: any) {
  if (isArray(columns)) {
    if (columns.length === 0) {
      return [[]]
    }

    if (isArray(columns[0])) {
      return columns
    }

    const keySet: Set<string> = new Set()
    columns.forEach((datum: object) => {
      Object.keys(datum).forEach((key: string) => {
        keySet.add(key)
      })
    })
    const keys = Array.from(keySet)
    if (keys.length === 0) {
      return [[]]
    }
    const data = []
    data.push(keys)
    for (let i = 0; i < columns.length; i += 1) {
      const row = keys.map((k) => columns[i][k])
      data.push(row)
    }
    return data
  } else {
    const keys = Object.keys(columns)
    if (keys.length === 0) {
      return [[]]
    }

    const numRows = columns[keys[0]].length

    const data = []
    data.push(keys)
    for (let i = 0; i < numRows; i += 1) {
      const row = keys.map((k) => columns[k][i])
      data.push(row)
    }

    return data
  }
}

export const sanitizeTabularDataForExcel = (data: any) => {
  /**
   * Prevents spreadsheet formula injection from occuring when data that is exported from
   * Retool is opened in Excel, Google Sheets, or other spreadsheet programs.
   *
   * See "Update (April 2016)" section here for background:
   * https://www.contextis.com/en/blog/comma-separated-vulnerabilities)
   */
  const stringifiedData = data.map((row: any) => row.map((col: any) => (isObject(col) ? JSON.stringify(col) : col)))
  const sanitizedData = stringifiedData.map((dataRow: any) => {
    return dataRow.map((dataRowItem: any) => {
      if (typeof dataRowItem === 'string' && dataRowItem.trim()) {
        const trimmedDataRowItem = dataRowItem.trim()
        if (['=', '+', '-', '@'].includes(trimmedDataRowItem.charAt(0))) {
          // if the trimmed string matches a "safe" regex, do not prepend an apostrophe
          // so that we avoid unnecessarily prepending apostrophes to safe values like -$600,000
          const safeNegativeNumberRegex = /^\-[\$\€]?[\d\,\.]+$/g
          if (trimmedDataRowItem.match(safeNegativeNumberRegex)) {
            return trimmedDataRowItem
          }

          // prepend the string with an apostrophe to prevent data from being
          // interpreted as a formula by Microsoft Excel
          return `'${dataRowItem}`
        }
      }
      return dataRowItem
    })
  })
  return sanitizedData
}

export function getDataAsCSV(data: any, options = {}) {
  const sanitizedData = sanitizeTabularDataForExcel(data)

  return Papa.unparse(sanitizedData, options)
}

import { PluginNamespaceInfo } from 'common/records'
import { SHARED_PLUGINS_BETWEEN_GLOBAL_WIDGETS_AND_PARENT } from 'store/appModel/constants'

/**
 * The separator we use for namespacing plugin IDs e.g. global1::textinput1
 *
 * Do not rely on this and do not try to parse out the namespace using `split(NAMESPACE_SEPERATOR)`!!
 * Use the `namespace` property on the plugin instead.
 *
 * Also - this choice of separator is not valid in JS namespaces - the benefit of this is there can be
 * no naming collisions (no one can name their widget dmitriy::textinput1 and be confused when it assumes dmitriy is a namespace)
 * However, this means that you cannot reference namespaced components in a template {{dmitriy::textinput1}} will not parse. On one hand,
 * thats good because we don't want people referencing child components - you have to expose them as outputs. On the other its very string,
 * and takes away the flexibility of potentially doing that if we change our mind about wanting outputs. Happy to discuss further. This is probably
 * a type 1.5 decision - _I think_ we can always change this pretty safely to something parsable in JS.
 */

export const NAMESPACE_SEPARATOR = '::'
/**
 * In places like CSS selectors we need to use the escaped version of the namespace separator
 */

export const ESCAPED_NAMESPACE_SEPARATOR = '\\:\\:'
/**
 * Namespace an ID to a provided namespace
 */

export const addNamespace = (namespace: PluginNamespaceInfo, id: string) => {
  const pluginIsSharedProperty = SHARED_PLUGINS_BETWEEN_GLOBAL_WIDGETS_AND_PARENT.includes(id)
  if (pluginIsSharedProperty) {
    return id
  }

  return namespace.getNamespace().concat([id]).join(NAMESPACE_SEPARATOR)
}

export const removeNamespace = (maybeNamespacedId: string) => {
  return maybeNamespacedId.split(NAMESPACE_SEPARATOR).slice(-1)[0]
}

import React, { useEffect } from 'react'
import { Input } from 'antd'

import { Checkbox, Tooltip, Icon } from 'components/design-system'
import { isLaunchedUnifiedSqlResource } from 'components/plugins/datasources/SqlQueryUnified/model'
import {
  InputField,
  HostInputField,
  CERTIFICATE_PLACEHOLDER,
  SSHTunnelForm,
  EditPrivilege,
  DangerZoneheader,
  QueryEditorModesToggle,
  sqlUnifiedResourceDefaults,
} from './common'
import { ResourceFormProps } from './types'

export const databaseDefaults = {
  host: '',
  port: '9042',
  databaseUsername: '',
  databasePassword: '',
  ssl: false,
}

export const databaseValidator = (resource: any) => resource.host && resource.options.localDataCenter
const CassandraForm = (props: ResourceFormProps) => {
  const isUnifiedSql = isLaunchedUnifiedSqlResource(props.resource)

  useEffect(() => {
    // TODO https://linear.app/retool/issue/ENG-531/clean-up-readwrite-feature-flags
    if (props.resource.options.version === undefined && isUnifiedSql) {
      props.updateResourceOptions(sqlUnifiedResourceDefaults)
    }
  }, [])

  return (
    <SSHTunnelForm {...props}>
      <>
        <hr />
        <h5 className="section-heading light-gray mb12">General</h5>
        <div className="grid-1c">
          <HostInputField {...props} />
          <InputField label="Port" placeholder="9042" resourceKey="port" topLevel required {...props} />
          <InputField label="Keyspace" resourceKey="keyspace" required {...props} />
          <InputField
            label="Local datacenter"
            resourceKey="localDataCenter"
            placeholder="datacenter1"
            required
            {...props}
          />

          <InputField
            label="Database username"
            placeholder="retool"
            resourceKey="databaseUsername"
            topLevel
            required
            {...props}
          />
          <InputField
            label="Database Password"
            placeholder="••••••••"
            resourceKey="databasePassword"
            topLevel
            password
            {...props}
          />
          <label className="input-label">SSL CA certificate</label>
          <Input.TextArea
            value={props.resource.options.sslCACertificate}
            onChange={props.onChangeOption('sslCACertificate', { handleEvent: true })}
            autosize={{ minRows: 18 }}
            placeholder={CERTIFICATE_PLACEHOLDER}
          />
        </div>
        <DangerZoneheader />
        {!isUnifiedSql && <EditPrivilege {...props} />}
        <div className="grid-1c mb12">
          {!props.resource.editPrivilege && (
            <Checkbox
              checked={props.resource.options.disableServerSidePrepare}
              onChange={(checked) => {
                props.onChangeOption('disableServerSidePrepare')(checked.target.checked)
              }}
              className="grid-offset-1"
            >
              Disable converting queries to prepared statements
              <Tooltip title="This allows you to use Javascript to dynamically generate SQL but also turns off SQL injection protection.">
                <Icon type="tooltip" className="washed-gray hover-lightest-gray ml4" />
              </Tooltip>
            </Checkbox>
          )}
          {isUnifiedSql && (
            <QueryEditorModesToggle resource={props.resource} updateResourceOptions={props.updateResourceOptions} />
          )}
        </div>
      </>
    </SSHTunnelForm>
  )
}

export default {
  label: 'Cassandra',
  form: CassandraForm,
  validator: databaseValidator,
  defaults: {
    ...databaseDefaults,
    options: {
      keyspace: '',
      localDataCenter: 'datacenter1',
      disableServerSidePrepare: false,
    },
  },
}

import React from 'react'
import classNames from 'classnames'
import { AutoSizer, List, ListRowRenderer } from 'react-virtualized'

import { Group } from '__globalShared__/permissions'
import { Button, Icon, ListEmptyState, TextInput } from 'components/design-system'
import useFuzzyMatch from 'common/hooks/useFuzzyMatch'
import { annotate } from 'components/FuzzyFind'

import { navigateToGroupDetail, navigateToGroupList, groupNameIsReserved } from '../utils'
import './GroupSidebar.scss'

export type GroupSidebarProps = {
  groups: Group[]
  activeGroupId?: number
  hasPermissionsFeature?: boolean
  onClickCreateGroup?: () => void
  isLoading?: boolean
}

const cn = 'group-sidebar'

export const GroupSidebar: React.FC<GroupSidebarProps> = ({
  isLoading = false,
  hasPermissionsFeature = false,
  groups,
  activeGroupId,
  onClickCreateGroup,
}) => {
  const [[matchesById, filteredGroups], searchTerm, setSearchTerm] = useFuzzyMatch(
    groups,
    ({ id }) => id,
    ({ name, ...rest }) => [{ name, ...rest }, { name: name.toLowerCase() }],
  )

  const renderGroupLink: ListRowRenderer = ({ style, index }) => {
    const { id, name } = filteredGroups[index]
    const isDefaultGroup = groupNameIsReserved(name)
    return (
      <div style={style} key={name}>
        <button
          role="button"
          className={classNames(
            `${cn}__group-link`,
            activeGroupId === id && `${cn}__group-link--active`,
            isDefaultGroup && 'tt-capitalize',
          )}
          onClick={() => navigateToGroupDetail({ id, name })}
        >
          {annotate(name, matchesById[id]?.name)} {isDefaultGroup && <Icon type="locked" className="lighter-gray" />}
        </button>
      </div>
    )
  }

  return (
    <div className={cn}>
      <Button className={`${cn}__back`} type="link" onClick={navigateToGroupList}>
        <Icon type="arrow-right" className="mr8" /> Back to all permissions
      </Button>
      <div className={`${cn}__search-bar`}>
        <TextInput
          allowClear
          icon="magnifying-glass"
          placeholder="Search"
          value={searchTerm}
          onChange={({ target: { value } }) => setSearchTerm(value)}
        />
        {hasPermissionsFeature && (
          <Button className="ml8 flex-no-shrink" type="primary" loading={isLoading} onClick={onClickCreateGroup}>
            <Icon type="plus" className="mr4" /> New
          </Button>
        )}
      </div>
      <div className={`${cn}__links`}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              rowCount={filteredGroups.length}
              width={width}
              height={height}
              rowHeight={32}
              rowRenderer={renderGroupLink}
              noRowsRenderer={() => <ListEmptyState content="No groups." />}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  )
}

export default GroupSidebar

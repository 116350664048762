import * as React from 'react'
import _ from 'lodash'
import classNames from 'classnames'

import './KeyedTableEditor.scss'

import { Button, Icon, Select } from 'components/design-system'

import CodeEditor from 'components/CodeEditor'

export const tableToString = (table: any) => {
  return JSON.stringify(table)
}

export type RowOptions = {
  value: string
  label?: string
  key?: string
  operation?: string
}

interface KeyedTableEditorProps {
  disabled?: boolean
  queryEditorUpdateNoDebounce: (newObject: { [s: string]: any }) => void
  queryEditorUpdate: (newObject: { [s: string]: any }) => void
  dict: string
  dictName: string
  id?: string
  keyOptions?: RowOptions[]
  defaultValue?: RowOptions[]
  operations?: RowOptions[]
  autocompleteHidden?: boolean
  customGlobals?: any
  evaluationNote?: string
  placeholders?: { key: string; value: string }
  includeOnlyCustomGlobalsInScope?: boolean
  includeDefaultScope?: boolean

  keyName?: string
  valueName?: string
}

class KeyedTableEditor extends React.PureComponent<KeyedTableEditorProps> {
  render() {
    const props = this.props
    const { customGlobals, dict, dictName, keyOptions, operations, autocompleteHidden, placeholders } = props
    const keyName = props.keyName || 'key'
    const valueName = props.valueName || 'value'
    const EMPTY_ROW = { [keyName]: '', [valueName]: '' }
    let state: any
    try {
      state = JSON.parse(dict)
    } catch (err) {
      state = props.defaultValue || [EMPTY_ROW]
    }
    // state should not be empty
    if (state == null || state.length === 0) {
      state = props.defaultValue || [EMPTY_ROW]
    }

    const computeUpdate = (index: any, key: any, value: any) => {
      const dictClone = _.cloneDeep(state)
      dictClone[index][key] = value
      return tableToString(dictClone)
    }

    const updateColumn = (index: any, value: any) => {
      props.queryEditorUpdateNoDebounce({ [dictName]: computeUpdate(index, keyName, value) })
    }

    const updateOperation = (index: any, value: any) => {
      props.queryEditorUpdateNoDebounce({ [dictName]: computeUpdate(index, 'operation', value) })
    }

    const updateNewValue = (index: any, value: any) => {
      props.queryEditorUpdate({ [dictName]: computeUpdate(index, valueName, value) })
    }

    const deleteColumn = (index: any) => {
      const dictClone = state.slice(0)
      dictClone.splice(index, 1)
      props.queryEditorUpdateNoDebounce({ [dictName]: JSON.stringify(dictClone) })
    }

    const addRow = () => {
      props.queryEditorUpdateNoDebounce({ [dictName]: JSON.stringify(state.concat(props.defaultValue || EMPTY_ROW)) })
    }

    return (
      <div className="keyed-table-editor query-editor-block" id={`keyed-table-editor-${dictName}`}>
        {state.map((change: any, i: number) => {
          const keyEditor = keyOptions ? (
            <Select
              disabled={props.disabled}
              showSearch
              allowClear
              value={change[keyName]}
              onChange={(v) => {
                updateColumn(i, v || '')
              }}
            >
              {keyOptions.map((o) => (
                <Select.Option value={o.value} className="large-option" key={o.value}>
                  {o.label}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <div className="key-editor" data-testid="keyed-table-editor-key-editor">
              <CodeEditor
                disabled={props.disabled}
                readOnly={props.disabled}
                customGlobals={customGlobals}
                includeOnlyCustomGlobalsInScope={props.includeOnlyCustomGlobalsInScope}
                includeDefaultScope={props.includeDefaultScope}
                autocompleteHidden={autocompleteHidden}
                key={props.id + change[valueName]}
                value={change[keyName]}
                baseMode="application/json"
                placeholder={placeholders ? placeholders.key : 'key'}
                oneLine
                onChange={(v) => {
                  updateColumn(i, v)
                }}
                evaluationNote={props.evaluationNote}
              />
            </div>
          )

          const opEditor = operations ? (
            <Select
              disabled={props.disabled}
              showSearch
              value={change.operation === undefined && operations.length > 0 ? operations[0].value : change.operation} // Select the first operator by default
              style={{ width: 120, flexShrink: 0 }}
              onChange={(v) => {
                updateOperation(i, v || '')
              }}
            >
              {operations.map((o) => (
                <Select.Option value={o.value} className="large-option" key={o.value}>
                  {o.label}
                </Select.Option>
              ))}
            </Select>
          ) : null

          return (
            <React.Fragment key={i}>
              <div className="key">{keyEditor}</div>
              <div className="value">
                <div className="value-editor" id={`value-editor-${i}`}>
                  {opEditor}
                  <CodeEditor
                    disabled={props.disabled}
                    readOnly={props.disabled}
                    customGlobals={customGlobals}
                    autocompleteHidden={autocompleteHidden}
                    includeOnlyCustomGlobalsInScope={props.includeOnlyCustomGlobalsInScope}
                    includeDefaultScope={props.includeDefaultScope}
                    key={props.id + change[keyName]}
                    value={change[valueName]}
                    baseMode="application/json"
                    placeholder={placeholders ? placeholders.value : 'value'}
                    onChange={(v) => {
                      updateNewValue(i, v)
                    }}
                    oneLine
                    evaluationNote={props.evaluationNote}
                  />
                  <div
                    className={classNames('kv-delete', {
                      disabled: props.disabled,
                    })}
                  >
                    <Button disabled={props.disabled} type="link" onClick={() => deleteColumn(i)}>
                      <Icon type="close" />
                    </Button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )
        })}
        <div
          className={classNames('new-row', {
            disabled: props.disabled,
          })}
        >
          <Button disabled={props.disabled} type="link" onClick={() => addRow()}>
            <Icon type="plus" className="mr4" /> Add new
          </Button>
        </div>
      </div>
    )
  }
}

export default KeyedTableEditor

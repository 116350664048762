import React from 'react'

import { DatabaseForm, databaseDefaults, databaseValidator } from './common'
import { ResourceFormProps } from './types'
import { isLaunchedUnifiedSqlResource } from 'components/plugins/datasources/SqlQueryUnified/model'

const RedshiftForm = (props: ResourceFormProps) => (
  <DatabaseForm
    placeholderHost="cluster.au48w6x.us-west-2.redshift.amazonaws.com"
    placeholderPort="5439"
    placeholderUsername="retool"
    isUnifiedSqlResource={isLaunchedUnifiedSqlResource(props.resource)}
    {...props}
  />
)

export default {
  label: 'Amazon Redshift',
  form: RedshiftForm,
  defaults: {
    ...databaseDefaults,
    options: {
      ...databaseDefaults.options,
    },
  },
  validator: databaseValidator,
}

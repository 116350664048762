import { convertToParameters, evaluateSafe } from 'common/evaluator'

export type RetoolTableQueryTemplate = {
  actionType: string
  tableName: string
  recordId: string
  filterBy: string
  changeset: string
  records: string
  bulkUpdatePrimaryKey: string
  enableBulkUpdates: boolean
  doNotThrowOnNoOp: boolean
  databaseNameOverride: string
  databaseUsernameOverride: string
  databasePasswordOverride: string
}

export const getPreviewParams = async (queryTemplate: RetoolTableQueryTemplate, model: any) => {
  const databaseUsernameOverrideParams = await convertToParameters(queryTemplate.databaseUsernameOverride || '', model)
  const databasePasswordOverrideParams = await convertToParameters(queryTemplate.databasePasswordOverride || '', model)
  const databaseNameOverrideParams = await convertToParameters(queryTemplate.databaseNameOverride || '', model)

  const commonUserParams = {
    databaseNameOverrideParams,
    databaseUsernameOverrideParams,
    databasePasswordOverrideParams,
  }

  let userParams
  if (
    queryTemplate.actionType === 'BULK_INSERT' ||
    queryTemplate.actionType === 'BULK_UPDATE_BY_KEY' ||
    queryTemplate.actionType === 'BULK_UPSERT_BY_KEY'
  ) {
    const records = await evaluateSafe(model, queryTemplate.records, {}, { disallowJSON: true })
    userParams = { ...commonUserParams, records }
  } else {
    const changesetParams = await convertToParameters(queryTemplate.changeset, model, true, true)
    const filterByParams = await convertToParameters(queryTemplate.filterBy, model, true, true)
    userParams = {
      ...commonUserParams,
      filterByParams,
      changesetParams,
    }
  }

  return { queryTemplate, userParams }
}

export const getRunQueryParams = async (queryTemplate: RetoolTableQueryTemplate, model: any) => {
  const databaseUsernameOverrideParams = await convertToParameters(queryTemplate.databaseUsernameOverride || '', model)
  const databasePasswordOverrideParams = await convertToParameters(queryTemplate.databasePasswordOverride || '', model)
  const databaseNameOverrideParams = await convertToParameters(queryTemplate.databaseNameOverride || '', model)

  const commonUserParams = {
    databaseNameOverrideParams,
    databaseUsernameOverrideParams,
    databasePasswordOverrideParams,
  }

  if (
    queryTemplate.actionType === 'BULK_INSERT' ||
    queryTemplate.actionType === 'BULK_UPDATE_BY_KEY' ||
    queryTemplate.actionType === 'BULK_UPSERT_BY_KEY'
  ) {
    const records = await evaluateSafe(model, queryTemplate.records, {}, { disallowJSON: true })
    return { ...commonUserParams, records }
  } else {
    const changesetParams = await convertToParameters(queryTemplate.changeset, model, true, true)
    const filterByParams = await convertToParameters(queryTemplate.filterBy, model, true, true)
    return {
      ...commonUserParams,
      filterByParams,
      changesetParams,
    }
  }
}

import React from 'react'
import { GoogleMapsResourceSettings } from 'common/records'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'
import { generateResourceForm } from 'components/ResourceForm/resourceFormGenerator/generator'
import { AuthTemplate } from 'components/ResourceForm/resourceFormGenerator/generator-types'

const defaultOptions: GoogleMapsResourceSettings = {
  options: {
    customQueryParameters: [['key', 'API_KEY']],
    customHeaders: [[]],
    authentication: 'apiKey',
    api_key: '',
    spec: `${RETOOL_SPECS_DOMAIN}/google/maps/3.0.3/1.8.0.json`,
  },
}

const auth: AuthTemplate = [
  {
    type: 'apiKey',
    options: {
      label: 'API Key',
      description: (
        <>
          Enter your Google Maps API key above. To retrieve or create an API key visit the{' '}
          <a rel="noopener noreferrer" target="_blank" href="https://console.cloud.google.com/apis/credentials">
            API Credentials
          </a>{' '}
          page in Google Cloud Platform or read the documentation{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://developers.google.com/maps/documentation/places/web-service/get-api-key"
          >
            here
          </a>
          .<br />
          <br />
          <b>Note:</b> This resource requires the Google project associated with your API key to have enabled the{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://console.cloud.google.com/marketplace/product/google/distance-matrix-backend.googleapis.com"
          >
            Distance Matrix API
          </a>
          ,{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://console.cloud.google.com/apis/library/elevation-backend.googleapis.com"
          >
            Elevation API
          </a>
          ,{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://console.cloud.google.com/apis/library/geocoding-backend.googleapis.com"
          >
            Geocoding API
          </a>
          ,{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://console.cloud.google.com/marketplace/product/google/geolocation.googleapis.com"
          >
            Geolocation API
          </a>
          ,{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://console.cloud.google.com/apis/library/roads.googleapis.com"
          >
            Roads API
          </a>
          , and the{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://console.cloud.google.com/apis/library/timezone-backend.googleapis.com"
          >
            Time Zone API
          </a>
          . Use the{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://console.cloud.google.com/google/maps-apis/overview"
          >
            Google Maps Platform dashboard
          </a>{' '}
          to view enabled APIs and usage information.
        </>
      ),
    },
  },
]

export default generateResourceForm('Google Maps', defaultOptions, { auth, hideTestConnection: () => true })

import { Spin, Icon as AntIcon } from 'antd'
import { GithubPullRequestOptions, githubPullRequestUrl } from 'common/utils/github'
import { Alert, Button, Icon, Modal } from 'components/design-system'
import React, { FC } from 'react'
import _styles from "./PageProtectionModal-styles.module.scss";


export type PageType = 'module' | 'application'

export type ModalState = 'ready' | 'pushing' | 'readyToMerge' | 'checkingForMerge'

const styles = _styles

export type PageProtectionModalProps = {
  visible: boolean
  closeModal: () => void
  pageType?: PageType
  onPushPage: () => void
  onMergePR: () => void
  pullRequestOptions: GithubPullRequestOptions | undefined
  pageName: string
  modalState: ModalState
}

const PageProtectionModal: FC<PageProtectionModalProps> = ({
  visible,
  closeModal,
  pageType,
  pageName,
  onMergePR,
  onPushPage,
  pullRequestOptions,
  modalState,
}) => {
  const antLoadingIcon = <AntIcon type="loading" style={{ fontSize: 32, width: 32 }} />

  const handleMergePR = () => {
    if (!pullRequestOptions) return
    onMergePR()
    window.open(githubPullRequestUrl(pullRequestOptions), '_blank')
  }

  return (
    <Modal
      title={`Protect the ${pageName} ${pageType}`}
      visible={visible}
      footer={null}
      onCancel={closeModal}
      width="650px"
      maskClosable
    >
      <div className={styles.panel}>
        {modalState === 'ready' && (
          <>
            <p>
              Once you protect this {pageType}, changes are made on branches and must be reviewed and merged via GitHub
              pull requests.
            </p>
            <div className={styles.alertContainer}>
              <Alert
                message={
                  <span>
                    Protected {pageType}s or folders that contain protected applications/modules <b>cannot</b> be
                    renamed, moved, or deleted. You can unprotect this {pageType} anytime.
                  </span>
                }
                type="info"
              />
            </div>
          </>
        )}
        {modalState === 'pushing' && (
          <>
            <Spin spinning={true} indicator={antLoadingIcon} />
            <p className={styles.label}>Connecting to GitHub...</p>
          </>
        )}
        {modalState === 'readyToMerge' && (
          <>
            <p>
              Successfully connected to Github! Click on the link below to open and merge an initial pull request
              containing the application source code. The branch was automatically created for you.
            </p>
          </>
        )}
        {modalState === 'checkingForMerge' && (
          <>
            <Spin spinning={true} indicator={antLoadingIcon} />
            <p className={styles.label}>Waiting for the PR to be created and merged.</p>
          </>
        )}
      </div>
      <div className={styles.footer}>
        {(modalState === 'ready' || modalState === 'readyToMerge') && (
          <Button type="primary" className="button" onClick={modalState === 'ready' ? onPushPage : handleMergePR}>
            {`${modalState === 'ready' ? `Protect ${pageType}` : `Merge PR`}`}
            <Icon type="arrow-right" />
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default PageProtectionModal

import React from 'react'
import { AirflowResourceSettings } from 'common/records'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'
import { generateResourceForm } from 'components/ResourceForm/resourceFormGenerator/generator'
import { AuthTemplate } from 'components/ResourceForm/resourceFormGenerator/generator-types'

const defaultOptions: AirflowResourceSettings = {
  options: {
    customQueryParameters: [['', '']],
    customHeaders: [['Authorization', 'API_KEY']],
    authentication: 'apiKey',
    api_key: '',
    spec: `${RETOOL_SPECS_DOMAIN}/airflow/3.0.3/1.0.0.json`,
  },
}

const auth: AuthTemplate = [
  {
    type: 'apiKey',
    options: {
      label: 'API key',
      description: (
        <>
          Enter your Airflow API key. To learn how to access your API key refer to:{' '}
          <ul>
            <li>
              {' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.astronomer.io/docs/cloud/stable/customize-airflow/airflow_api"
              >
                <b>Astronomer</b>
              </a>{' '}
            </li>
          </ul>
        </>
      ),
    },
  },
]

export default generateResourceForm(
  'Airflow',
  defaultOptions,
  {
    auth,
    hideTestConnection: () => true,
  },
  true,
)

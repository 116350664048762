import React, { useEffect, useRef, useState } from 'react'
import { dispatch, RetoolState } from 'store'
import { currentResourceEncryptedOauthSessionStatesByEnvSelector } from 'store/selectors'
import { redirectToOauth } from 'store/session'

import { Button, Icon, Alert, Tooltip } from 'components/design-system'
import { OAUTH_AUTHORIZE_URL } from '../oauthForm'
import { SafeAny } from 'common/types'
import { PlainResource as Resource, OauthAuthorizedStatus } from 'common/records'
import { connect } from 'react-redux'

import { Link } from 'react-router'
import _tooltipStyles from "./ResourceOAuthForm-tooltipStyles.module.scss";


type ResourceOAuthFormProps = {
  onTriggerSaveResource: (triggeredByOauth: boolean) => Promise<SafeAny>
  resource?: Resource
  disabled: boolean
  disabledTooltipTitle: string
  resourceTypeForLabel: string
  connectButtonTextOverride?: string
  hideInProgressAuthorizationStatusAlert?: boolean
  onUpdateOauthAuthorizedStatus: (oauthAuthorizedStatus: OauthAuthorizedStatus) => void
} & ReturnType<typeof mapStateToProps>

const AuthorizationStatusAlert = (props: {
  authorizeStatus: OauthAuthorizedStatus
  resourceTypeForLabel: string
  hideInProgressAuthorizationStatusAlert?: boolean
}) => {
  const { hideInProgressAuthorizationStatusAlert } = props
  switch (props.authorizeStatus) {
    case OauthAuthorizedStatus.SUCCESSFUL:
      return (
        <Alert
          className="mt12 mb12"
          message={
            <div>
              Connected! Ready to start building?{' '}
              <Link
                to="/?initialIsModalOpen=true"
                className="fw-500"
                style={{ color: 'var(--green)', display: 'inline-flex', alignItems: 'center' }}
              >
                Create a new app now <Icon type="arrow-right" style={{ marginLeft: '2px', color: 'var(--green)' }} />
              </Link>
            </div>
          }
          type="success"
        />
      )
    case OauthAuthorizedStatus.IN_PROGRESS:
      return (
        <>
          {!hideInProgressAuthorizationStatusAlert && (
            <Alert
              className="mt12 mb12"
              message={`Not completed yet. Click to connect to your ${props.resourceTypeForLabel} account.`}
              type="warning"
            />
          )}
        </>
      )
    default:
      return null
  }
}

const tooltipStyles = _tooltipStyles

/**
 * A button that shows a tooltip on hover when it's disabled.
 * Unfortunately disabled inputs have some gotchas around mouse events
 * so this component handles the various necessary workarounds.
 */
const TooltipButton: React.FC<{
  disabled: boolean
  disabledTooltipTitle: string
  onClick: React.MouseEventHandler<HTMLElement>
}> = ({ disabled, disabledTooltipTitle, children, onClick }) => {
  const button = (
    <Button
      onClick={onClick}
      type="default"
      disabled={disabled}
      className={`google-login-button login-button ${tooltipStyles.button}`}
    >
      {children}
    </Button>
  )

  return disabled && disabledTooltipTitle ? (
    <Tooltip
      placement="left"
      title={disabledTooltipTitle}
      // shift up by 4px to account for Button's 8px bottom margin
      align={{ offset: [0, -4] }}
    >
      {/* NB: wrap the disabled button in an extra element to fix mouse events
        (https://github.com/facebook/react/issues/18753) */}
      <span className={tooltipStyles.buttonWrapper}>{button}</span>
    </Tooltip>
  ) : (
    button
  )
}

/**
 * Oauth form which auto-saves the resource before the flow begins (i.e. can be used on resource creation)
 * Also shows connectivity status below the Oauth connect button, both for per-user oauth and shared oauth
 * Defaults to shared oauth unless oauth2_share_user_credentials is passed in resource options
 * @param props
 */
const ResourceOAuthForm = (props: ResourceOAuthFormProps) => {
  const {
    resource,
    disabled,
    disabledTooltipTitle,
    onTriggerSaveResource,
    resourceTypeForLabel,
    connectButtonTextOverride,
    encryptedOauthSessionStatesByEnv,
    hideInProgressAuthorizationStatusAlert,
    onUpdateOauthAuthorizedStatus,
  } = props

  const [resourceSaved, setResourceSaved] = useState(false)
  const resourceId = resource?.id
  const resourceEnvironment = resource?.environment
  const oauth2AccessToken = resource?.options?.oauth2_access_token
  const oauth2ShareUserCredentials = resource?.options?.oauth2_share_user_credentials
  const encryptedOauthSessionStates = encryptedOauthSessionStatesByEnv?.[resourceEnvironment as string] || {}
  const hasPerUserAccessToken = !!encryptedOauthSessionStates?.oauth2_access_token

  const redirectingInitiated = useRef(false)

  const [authorizeStatus, _setAuthorizeStatus] = useState(OauthAuthorizedStatus.NOT_STARTED)

  const setAuthorizeStatus = (newAuthorizeStatus: OauthAuthorizedStatus) => {
    _setAuthorizeStatus(newAuthorizeStatus)
    onUpdateOauthAuthorizedStatus(newAuthorizeStatus)
  }

  useEffect(() => {
    const getAuthorizeStatus = () => {
      if (resourceId) {
        const credentialsArePerUser = oauth2ShareUserCredentials === false
        let successfullyOAuthed
        if (credentialsArePerUser) {
          // For per user oauath, look if there is an access token in the encrypted session states
          successfullyOAuthed = hasPerUserAccessToken
        } else {
          // With shared oauth, look at the access token in the resource options
          successfullyOAuthed = !!oauth2AccessToken
        }
        return successfullyOAuthed ? OauthAuthorizedStatus.SUCCESSFUL : OauthAuthorizedStatus.IN_PROGRESS
      } else {
        return OauthAuthorizedStatus.NOT_STARTED
      }
    }

    // Don't update the authorize status when we are redirecting since we don't want the
    // AuthorizationStatusAlert to show until after a user returns to the resource page
    if (!redirectingInitiated.current) {
      setAuthorizeStatus(getAuthorizeStatus())
    }
  }, [oauth2AccessToken, oauth2ShareUserCredentials, hasPerUserAccessToken, resourceId])

  let connectButtonText =
    authorizeStatus === OauthAuthorizedStatus.SUCCESSFUL
      ? `Reconnect ${resourceTypeForLabel}`
      : `Connect to ${resourceTypeForLabel}`

  if (connectButtonTextOverride) {
    connectButtonText = connectButtonTextOverride
  }

  useEffect(() => {
    async function fetchTokenAndRedirectToOAuth() {
      // NOTE: this action name is misleading, it just fetches
      // the authorization token. Submitting the form does the redirect.
      // It should probably be called "fetchOAuthToken".
      const res = await dispatch(redirectToOauth())
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', 'authorizationToken')
      input.setAttribute('value', res.payload.authorizationToken)
      hiddenFormRef.current!.appendChild(input)
      hiddenFormRef.current!.submit()
      hiddenFormRef.current!.removeChild(input)
    }

    // We can only submit the form if we:
    // 1. Have successfully saved the resource
    // 2. Have the resourceId (we were previously not
    //    waiting on having the resourceId, which was causing a bug)

    if (resourceSaved && resourceId) {
      fetchTokenAndRedirectToOAuth()
    }
  }, [resourceId, resourceSaved])

  const hiddenFormRef = useRef<HTMLFormElement>(null)
  let xsrfToken = ''
  try {
    xsrfToken = document.cookie.match('xsrfToken=(.*)')![1].split(';')[0]
  } catch (err) {}

  const triggerSaveResource = async () => {
    redirectingInitiated.current = true
    setResourceSaved(false)
    const res = await onTriggerSaveResource(true)

    // TODO (emily) this response should really return a 200 or 201 when it succeeds
    if (res.payload.status === 400) {
      throw Error('Could not create or edit resource')
    } else {
      setResourceSaved(true)
    }
  }

  return (
    <>
      <div>
        <form ref={hiddenFormRef} action={OAUTH_AUTHORIZE_URL} method="POST">
          <input type="hidden" name="xsrfToken" value={xsrfToken} />
          <input type="hidden" name="resourceId" value={resourceId ? resourceId : undefined} />
          <input type="hidden" name="resourceName" value="" />
          <input type="hidden" name="environment" value="" />
          <input type="hidden" name="stepNum" value="" />
          <input type="hidden" name="redirectUri" value={window.location.href.split('#')[0]} />
        </form>
        <TooltipButton
          disabled={disabled}
          disabledTooltipTitle={disabledTooltipTitle}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            triggerSaveResource()
          }}
        >
          <>
            {connectButtonText}
            <Icon type="arrow-right" style={{ marginLeft: '4px' }} />
          </>
        </TooltipButton>
      </div>
      <AuthorizationStatusAlert
        authorizeStatus={authorizeStatus}
        resourceTypeForLabel={resourceTypeForLabel}
        hideInProgressAuthorizationStatusAlert={hideInProgressAuthorizationStatusAlert}
      />
    </>
  )
}

const mapStateToProps = (state: RetoolState) => {
  return {
    encryptedOauthSessionStatesByEnv: currentResourceEncryptedOauthSessionStatesByEnvSelector(state),
  }
}

export default connect(mapStateToProps)(ResourceOAuthForm)

import { UntransformedQueryResponse } from 'common/records'
import { Action, Dispatch } from 'redux'

//Seperated from the editor file where we define the reducers since we use some of these outside the editor
//and bad things happen when we try to load things from the editor bundle outside the editor
export const UNTRANSFORMED_QUERY_RESPONSE_UPDATE = 'UNTRANSFORMED_QUERY_RESPONSE_UPDATE'

export const updateUntransformedQueryResponse = (queryName: string, response: UntransformedQueryResponse) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: UNTRANSFORMED_QUERY_RESPONSE_UPDATE,
      payload: { queryName, response },
    })
  }
}

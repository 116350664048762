/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useRef } from 'react'

/**
 * Returns a referentially equal callback on every render.
 *
 * Unlike `useCallback`, the callback does not change when dependencies change.
 */
export default function useCallbackRef<T extends (...args: any[]) => any>(callback: T): T {
  const ref = useRef(callback)
  ref.current = callback

  return useCallback(((...args) => ref.current(...args)) as T, [])
}

import React from 'react'

import { DatabaseForm, databaseDefaults, databaseValidator, SSHTunnelForm, sshTunnelDefaults } from './common'
import { ResourceFormProps } from './types'
import { isLaunchedUnifiedSqlResource } from 'components/plugins/datasources/SqlQueryUnified/model'

const PostgresForm = (props: ResourceFormProps) => {
  return (
    <SSHTunnelForm {...props}>
      <DatabaseForm
        placeholderHost="IP address or hostname of your database"
        placeholderPort="5432"
        placeholderUsername="postgres"
        placeholderConnectionString="postgresql://dbuser:secretpassword@database.server.com:5432/mydb"
        gcpSelfSignedCertificatesSupported
        isUnifiedSqlResource={isLaunchedUnifiedSqlResource(props.resource)}
        {...props}
      />
    </SSHTunnelForm>
  )
}

// public for testing
export const POSTGRES_FORM_DEFAULTS = {
  ...databaseDefaults,
  ...sshTunnelDefaults,
  options: {
    ...databaseDefaults.options,
    ...sshTunnelDefaults.options,
  },
  port: '5432',
}

export default {
  label: 'Postgres',
  form: PostgresForm,
  defaults: POSTGRES_FORM_DEFAULTS,
  validator: databaseValidator,
}

export const DATASOURCE_TYPE_CHANGE = 'DATASOURCE_TYPE_CHANGE'
export const PLUGIN_DELETE = 'PLUGIN_DELETE'
export const PLUGIN_UPDATE_ID = 'PLUGIN_UPDATE_ID'
export const RESET_TEMPLATE = 'RESET_TEMPLATE'

// Global Query Helpers
export const GLOBAL_QUERY_PASS_THROUGH_PROPERTIES = ['data', 'error', 'metadata']

// Global widgets need to access certain parent properties outside of just the global widget props
export const SHARED_PLUGINS_BETWEEN_GLOBAL_WIDGETS_AND_PARENT = [
  'current_user',
  'localStorage',
  'retoolContext',
  'viewport',
  'urlparams',
]

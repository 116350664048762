import React from 'react'

import { DatabaseForm, databaseDefaults, databaseValidator, SSHTunnelForm, sshTunnelDefaults } from './common'
import { ResourceFormProps } from './types'
import { isLaunchedUnifiedSqlResource } from 'components/plugins/datasources/SqlQueryUnified/model'

const MySQLForm = (props: ResourceFormProps) => (
  <SSHTunnelForm {...props}>
    <DatabaseForm
      placeholderHost="IP address or hostname of your database"
      placeholderPort="3306"
      placeholderUsername="retool"
      databaseNameOverrideSupported
      acceptSelfSignedCertificatesSupported
      gcpSelfSignedCertificatesSupported
      isUnifiedSqlResource={isLaunchedUnifiedSqlResource(props.resource)}
      {...props}
    />
  </SSHTunnelForm>
)

export default {
  label: 'MySQL',
  form: MySQLForm,
  defaults: {
    ...databaseDefaults,
    ...sshTunnelDefaults,
    options: {
      ...databaseDefaults.options,
      ...sshTunnelDefaults.options,
    },
    port: '3306',
  },
  validator: databaseValidator,
}

import React from 'react'
import { connect } from 'react-redux'
import { RetoolState } from 'store'
import { WorkspaceGroupPageArray, editorPrivilegedSelector } from 'store/selectors'
import { browserHistory } from 'react-router'
import { isFetchingPagesForFirstTimeSelector } from 'routes/Home/selectors'
import { Icon } from 'antd'
import { getLink } from 'common/utils'
import { hasPresentationModeFeatureSelector } from 'common/paymentPlans'
import { pagesLoad } from 'store/appModel/pages'
import { getImportableQueries } from 'routes/Editor/modules/editor'
import './fetchPagesAndRedirectWorkspaces.scss'
import { authorizedWorkspacesSelector } from 'store/selectors/userSelectors'

type RedirectProps = {
  authorizedWorkspaces: WorkspaceGroupPageArray
  hasPresentationModeFeature: boolean
  canEdit: boolean
}

type Props = {
  isFetchingPagesForFirstTime: boolean
} & RedirectProps

const LoadingPage = () => (
  <div className="non-ideal-state">
    <div className="loading-text">
      <div>Welcome to Retool!</div>
      <div className="loading-text__description">We're getting your apps...</div>
    </div>
    <Icon type="loading" />
  </div>
)

// Returns undefined if no redirecting should occur
const workspaceLinkToRedirectTo = (params: RedirectProps) => {
  const { authorizedWorkspaces, hasPresentationModeFeature, canEdit } = params
  if (!authorizedWorkspaces || authorizedWorkspaces.length === 0 || canEdit) {
    return undefined
  }

  return getLink('read', authorizedWorkspaces[0].page.path, hasPresentationModeFeature)
}

/*
Expected behavior:
- If first time loading pages we should show a loading screen and redirect to a workspace if one exists, otherwise show Component
- If not the first time loading pages, we should show the component right away and not redirect to any workspaces (but we should
  still get the updated pages)
*/
export default function fetchPagesAndRedirectWorkspaces(Component: any) {
  class WrappedComponent extends React.Component<any & Props, { showLoading: boolean }> {
    constructor(props: any) {
      super(props)
      this.state = {
        showLoading: props.isFetchingPagesForFirstTime,
      }
    }

    componentDidMount() {
      this.props.pagesLoad()
      this.props.getImportableQueries()
    }

    componentDidUpdate(prevProps: Props) {
      if (this.state.showLoading && prevProps.isFetchingPagesForFirstTime && !this.props.isFetchingPagesForFirstTime) {
        const { hasPresentationModeFeature, authorizedWorkspaces, canEdit } = this.props
        const workspaceLink = workspaceLinkToRedirectTo({
          hasPresentationModeFeature,
          canEdit,
          authorizedWorkspaces,
        })
        if (workspaceLink) {
          browserHistory.push(workspaceLink)
        } else {
          this.setState({
            showLoading: false,
          })
        }
      }
    }

    render() {
      const {
        isFetchingPagesForFirstTime,
        hasPresentationModeFeature,
        authorizedWorkspaces,
        canEdit,
        ...props
      } = this.props

      return this.state.showLoading ? <LoadingPage /> : <Component {...props} />
    }
  }

  const mapDispatchToProps = {
    pagesLoad,
    getImportableQueries,
  }

  const mapStateToProps = (state: RetoolState) => ({
    authorizedWorkspaces: authorizedWorkspacesSelector(state),
    isFetchingPagesForFirstTime: isFetchingPagesForFirstTimeSelector(state),
    hasPresentationModeFeature: hasPresentationModeFeatureSelector(state),
    canEdit: editorPrivilegedSelector(state),
  })

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}

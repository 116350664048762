/* eslint-disable react/no-find-dom-node */
import React, { ReactElement } from 'react'
import ReactDOM from 'react-dom'
import { Icon as AntIcon } from 'antd'

import Message from 'components/design-system/Message'

import './InlineEdit.scss'
import Icon from '../Icon'
import Tooltip from '../Tooltip'

// Started from https://github.com/jacqt/ReactInlineEdit

function selectInputText(element: any) {
  element.setSelectionRange(0, element.value.length)
}

export type InlineEditProps = {
  text: string
  displayText?: string | ReactElement
  className?: string
  activeClassName?: string
  minLength?: number
  maxLength?: number
  validate?: (val: string) => boolean
  validationFailure?: (val: string) => void
  onChange?: (value: string) => void
  placeholder?: string
  style?: React.CSSProperties
  editingElement?: string
  staticElement?: string
  tabIndex?: number
  isDisabled?: boolean
  editing?: boolean
  onEditChange?: (editing: boolean) => void
  ref?: any
  stopPropagation?: () => void
  disabledTooltipText?: string
  showDisabledIconIfDisabled?: boolean
}

type InlineEditState = {
  editing: boolean
  text: string
  minLength: number
  maxLength: number
}

export default class InlineEdit extends React.Component<InlineEditProps, InlineEditState> {
  inputRef: any

  static defaultProps = {
    minLength: 1,
    maxLength: 256,
    className: '',
    activeClassName: '',
    editingElement: 'input',
    staticElement: 'span',
    tabIndex: 0,
    isDisabled: false,
    editing: false,
    showDisabledIconIfDisabled: false,
  }

  state = {
    editing: !!this.props.editing,
    text: this.props.text,
    minLength: this.props.minLength ?? 0,
    maxLength: this.props.maxLength ?? 256,
  }

  UNSAFE_componentWillMount() {
    this.isInputValid = this.props.validate || this.isInputValid

    if (this.props.ref) {
      this.props.ref({
        focus: () => {
          this.setState({ editing: true, text: this.props.text })
        },
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: InlineEditProps) {
    const isTextChanged = nextProps.text !== this.props.text
    const isEditingChanged = nextProps.editing !== this.props.editing
    const nextState: any = {}
    if (isTextChanged) {
      nextState.text = nextProps.text
    }
    if (isEditingChanged) {
      nextState.editing = nextProps.editing
    }
    if (isTextChanged || isEditingChanged) {
      this.setState(nextState)
    }
  }

  componentDidUpdate(prevProps: InlineEditProps, prevState: InlineEditState) {
    const inputElem: any = ReactDOM.findDOMNode(this.inputRef)!
    if (this.state.editing && !prevState.editing) {
      inputElem.focus()
      selectInputText(inputElem)
    } else if (this.state.editing && prevProps.text !== this.props.text) {
      this.finishEditing()
    }
  }

  startEditing = (e: any) => {
    if (this.props.stopPropagation) {
      e.stopPropagation()
    }
    if (this.props.onEditChange) {
      this.props.onEditChange(true)
    }
    this.setState({ editing: true, text: this.props.text })
  }

  finishEditing = (): void => {
    const { text, minLength, maxLength } = this.state
    const { text: savedText, onEditChange, validationFailure } = this.props

    onEditChange?.(false)

    if (text === savedText) {
      this.cancelEditing()
      return
    }

    if (!this.isInputValid(text)) {
      if (text.length < minLength) {
        Message.warning(`Input is too short. Min length is ${minLength} characters.`)
      } else if (text.length > maxLength) {
        Message.warning(`Input is too long. Max length is ${maxLength} characters.`)
      }

      this.cancelEditing()
      validationFailure?.(text)
      return
    }

    this.commitEditing(text)
  }

  cancelEditing = () => {
    this.setState({ editing: false, text: this.props.text })
  }

  commitEditing = (value: string): void => {
    this.setState({ editing: false })
    this.props.onChange?.(value)
  }

  clickWhenEditing = (e: any) => {
    if (this.props.stopPropagation) {
      e.stopPropagation()
    }
  }

  isInputValid = (text: string) => {
    return text.length >= this.state.minLength && text.length <= this.state.maxLength
  }

  keyDown = (event: any) => {
    if (event.keyCode === 13) {
      this.finishEditing()
    } else if (event.keyCode === 27) {
      this.cancelEditing()
    }
  }

  textChanged = (event: any) => {
    this.setState({
      text: event.target.value.trim(),
    })
  }

  renderElement() {
    const activeClassName = `${this.props.activeClassName} retool-inline-edit-input`
    const className = `${this.props.className} retool-inline-edit dib truncate`

    const { text, displayText, placeholder } = this.props
    const staticText = displayText || text || placeholder

    if (this.props.isDisabled) {
      const Element: any = this.props.staticElement
      const disabledElement = (
        <Element className={className} style={this.props.style}>
          {staticText}
        </Element>
      )

      if (this.props.disabledTooltipText) {
        return (
          <Tooltip title={this.props.disabledTooltipText} trigger="click">
            {disabledElement}
          </Tooltip>
        )
      }

      return disabledElement
    } else if (!this.state.editing) {
      const Element: any = this.props.staticElement
      return (
        <Element
          className={className}
          onClick={this.startEditing}
          tabIndex={this.props.tabIndex}
          style={this.props.style}
        >
          {staticText}
        </Element>
      )
    } else {
      const Element: any = this.props.editingElement
      return (
        <Element
          onClick={this.clickWhenEditing}
          onKeyDown={this.keyDown}
          onBlur={this.finishEditing}
          className={activeClassName}
          placeholder={this.props.placeholder}
          defaultValue={this.state.text}
          onChange={this.textChanged}
          style={this.props.style}
          ref={(input: any) => (this.inputRef = input)}
        />
      )
    }
  }

  renderIcon() {
    if (this.props.isDisabled && this.props.showDisabledIconIfDisabled) {
      return <Icon type="disabled-edit" style={{ opacity: 1, top: '6px' }} />
    } else {
      // <Icon type="edit" /> NOTE: this should eventually replace the ant icon
      return <AntIcon className="retool-icon" type="edit" />
    }
  }

  render() {
    return (
      <div
        className={`retool-inline-edit-wrapper ${this.state.editing ? 'retool-inline-edit-wrapper-editing' : ''}  ${
          this.props.isDisabled && this.props.showDisabledIconIfDisabled ? 'retool-inline-edit-wrapper-disabled' : ''
        }`}
      >
        {this.renderIcon()}
        {this.renderElement()}
      </div>
    )
  }
}

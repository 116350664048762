import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'antd'

import { Button, TextInput } from 'components/design-system'

const FormItem = Form.Item

import { setup2FA, confirm2FASetup } from 'store/user'
import AuthRetoolLogo from 'routes/Login/components/AuthRetoolLogo'

class Setup2FA extends React.Component<any, any> {
  state = {
    modalOpen: false,
    twoFactorAuthToken: '',
    qrcode: '',
  }

  closeModal = () => {
    this.setState({ twoFactorAuthToken: '', modalOpen: false })
  }

  confirmCode = async () => {
    const succeeded = await this.props.confirm2FASetup(this.state.twoFactorAuthToken.replace(' ', ''))
    if (succeeded && this.props.afterSuccess) {
      this.props.afterSuccess()
    }
  }

  async componentDidMount() {
    const qrcode = await this.props.setup2FA()
    this.setState({ qrcode })
  }

  render() {
    return (
      <>
        <AuthRetoolLogo className="mb24" />
        <div className="auth-heading mb8">Scan this QR code</div>
        <span className="auth-subheading">
          <p>
            Two Factor Authentication keeps your account safe by making sure you're who you say you are. Your Retool
            admin has turned this feature on and requires you to set this up before you can proceed.
          </p>
        </span>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <img src={this.state.qrcode} />
          <span className="auth-subheading">
            Scan this QR code in your Authenticator App, and then enter in the six digit code below to finalize the
            setup.
          </span>
        </div>
        <Form layout="vertical">
          <div className="resource-form__common-fields mt20">
            <FormItem label="Authenticator code">
              <TextInput
                placeholder="123456"
                className="resource-form__label-input"
                onChange={(e) => this.setState({ twoFactorAuthToken: e.target.value })}
                onPressEnter={this.confirmCode}
              />
            </FormItem>
          </div>
        </Form>
        <div className="resource-form__end-buttons mb16">
          <Button
            htmlType="submit"
            type="primary"
            className="resource-form__done-button"
            onClick={this.confirmCode}
            disabled={this.state.twoFactorAuthToken.length < 6}
          >
            Submit
          </Button>
        </div>
      </>
    )
  }
}

const mapDispatchToProps = {
  setup2FA,
  confirm2FASetup,
}

export default connect(() => ({}), mapDispatchToProps)(Setup2FA)

import qs from 'querystring'
import { CALL_API_DEPRECATE as CALL_API } from 'networking/index.deprecated'

export default function queryMiddleware() {
  return (next: any) => (action: any) => {
    if (
      action &&
      Object.prototype.hasOwnProperty.call(action, CALL_API) &&
      Object.prototype.hasOwnProperty.call(action[CALL_API], 'query')
    ) {
      const request = action[CALL_API]
      request.endpoint = [request.endpoint.replace(/\?*/, ''), qs.stringify(request.query)].join('?')
      delete request.query

      return next({ [CALL_API]: request })
    }

    return next(action)
  }
}

import Immutable from 'immutable'
import { TypedMap, makeTypedMap } from 'common/utils/immutable'
import { EventHandler, EventHandlerShape } from './types'
import { ButtonClickShape } from '../../Clickable/template'

const parseOpenAppParams = (params: string): unknown[] | undefined => {
  let parsed = params && typeof params === 'string' ? JSON.parse(params) : params

  if (!parsed) return

  if (typeof parsed === 'string') {
    parsed = JSON.parse(parsed)
  }

  return parsed
}

const makeClickableEventHandler = (template: TypedMap<ButtonClickShape>): EventHandler | undefined => {
  const baseEvent = {
    event: 'click',
    method: 'trigger',
    type: 'datasource',
    pluginId: '',
    waitType: 'debounce',
    waitMs: '0',
  } as const

  switch (template.get('buttonType')) {
    case 'export': {
      const data = template.get('exportQuery')
      const fileName = template.get('exportFileName')
      const fileType = template.get('exportFileType')
      if (!data) return
      return makeTypedMap<EventHandlerShape>({
        ...baseEvent,
        type: 'util',
        method: 'exportData',
        params: Immutable.fromJS({ data, fileName, fileType, options: {} }),
      })
    }

    case 'action': {
      const pluginId = template.get('action')
      if (!pluginId) return
      return makeTypedMap<EventHandlerShape>({ ...baseEvent, type: 'datasource', method: 'trigger', pluginId })
    }

    case 'internal-url': {
      const uuid = template.get('internalUrlPath')
      const stringQueryParams = template.get('internalUrlQuery')
      const stringHashParams = template.get('internalUrlHashParams')
      const newTab = template.get('newWindow')
      if (!uuid) return
      const queryParams = parseOpenAppParams(stringQueryParams)
      const hashParams = parseOpenAppParams(stringHashParams)
      return makeTypedMap<EventHandlerShape>({
        ...baseEvent,
        type: 'util',
        method: 'openApp',
        params: Immutable.fromJS({ uuid, options: { queryParams, hashParams, newTab } }),
      })
    }

    case 'url': {
      const url = template.get('url')
      const newTab = template.get('newWindow')
      if (!url) return
      return makeTypedMap<EventHandlerShape>({
        ...baseEvent,
        type: 'util',
        method: 'openUrl',
        params: Immutable.fromJS({ url, options: { newTab } }),
      })
    }

    case 'copy-to-clipboard': {
      const value = template.get('valueToCopy')
      if (!value) return
      return makeTypedMap<EventHandlerShape>({
        ...baseEvent,
        type: 'util',
        method: 'copyToClipboard',
        params: Immutable.fromJS({ value }),
      })
    }
  }
}

export default makeClickableEventHandler

import React from 'react'
import Switch from '../Switch'
import './FeatureToggle.scss'

type FeatureToggleProps = {
  checked: boolean
  label: string
  description: string
  onChange: (checked: boolean) => void
}
const FeatureToggle = (props: FeatureToggleProps) => {
  const { label, description, checked, onChange } = props
  return (
    <div className="feature-toggle">
      <div>
        <Switch
          checked={checked}
          size="small"
          onChange={(checked) => {
            onChange(checked)
          }}
        />
      </div>
      <div>
        <b> {label} </b>
        <span> {description} </span>
      </div>
    </div>
  )
}

export default FeatureToggle

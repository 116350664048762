import * as Sentry from '@sentry/browser'
import { SafeAny } from './types'

export const sampleSentryMessage = (rate = 0.01, message: string, level?: Sentry.Severity | undefined) => {
  if (Math.random() < rate) {
    Sentry.captureMessage(message, level)
  }
}

export const sampleSentryException = (rate = 0.01, exception: SafeAny) => {
  if (Math.random() < rate) {
    Sentry.captureException(exception)
  }
}

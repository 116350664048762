import cookies from 'js-cookie'

export const getXsrfToken = () => {
  return cookies.get('xsrfToken')
}

export const GET_AUTH_HEADERS = () => ({
  'X-Xsrf-Token': getXsrfToken() ?? '',
})

export const JSON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

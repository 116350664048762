import React, { useEffect, useState } from 'react'

import { KeyValueField } from '../common'
import { ResourceFormProps } from '../types'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'
import { ServerVariablesKeyValueField } from '../openapi'
import { BigidResourceSettings } from 'common/records'
import { fetchOpenAPISpec, OpenAPISpec } from 'store/fetchOpenAPISpec'
import { constant } from 'lodash'

const BididForm = (props: ResourceFormProps<BigidResourceSettings>) => {
  const [spec, setSpec] = useState<OpenAPISpec | undefined>(undefined)
  const specURL = props.resource.options?.spec || ''

  useEffect(() => {
    ;(async () => {
      const { spec: newSpec } = await fetchOpenAPISpec(specURL, 'bigid')
      if (newSpec != null) {
        setSpec(newSpec)
      }
    })()
  }, [specURL])

  return (
    <div className="grid-1c mb20">
      <KeyValueField
        label="Custom Headers"
        resourceKey="customHeaders"
        resource={props.resource}
        updateResourceOptions={props.updateResourceOptions}
      />
      <ServerVariablesKeyValueField
        spec={spec}
        updateResourceOptions={props.updateResourceOptions}
        resource={props.resource}
      />
    </div>
  )
}

const defaultOptions: BigidResourceSettings = {
  options: {
    customQueryParameters: [['', '']],
    customHeaders: [['', '']],
    spec: `${RETOOL_SPECS_DOMAIN}/bigid/3.0.3/1.0.0.json`,
  },
}

export default {
  label: 'BigID',
  form: BididForm,
  defaults: defaultOptions,
  // We don't validate for server variables/headers here because BigID is embedding Retool into their service and injecting
  // these variables via a ParentWindow query in-app (i.e. you should be able to create a resource without providing these params)
  validator: constant(true),
}

export const MODIFIER_KEYS = ['alt', 'command', 'ctrl', 'meta', 'shift'] as const
export type ModifierKey = typeof MODIFIER_KEYS[number]
type KeyboardEventModifierKey = 'altKey' | 'ctrlKey' | 'metaKey' | 'shiftKey'

export type ModifierKeyEvent = Pick<KeyboardEvent, KeyboardEventModifierKey>

function normalizeKey(key: ModifierKey) {
  if (key === 'command') {
    return /Mac|iPhone|iPad/.test(navigator.platform) ? 'metaKey' : 'ctrlKey'
  }

  return `${key}Key` as KeyboardEventModifierKey
}

export default function hasModifierKeys(event: ModifierKeyEvent, ...keys: ModifierKey[]) {
  return keys.every((key) => !!event[normalizeKey(key)])
}

import React from 'react'

export const pageCount = (count: number | undefined) => {
  if (typeof count !== 'number') {
    return <></>
  }

  return (
    <>
      &nbsp;<span className="page-count">({count})</span>
    </>
  )
}

export const fuzzyHighlight = (slab: string) => <span className="fuzzy-match-region">{slab}</span>

import confetti from 'canvas-confetti'
import { AnyAction, MiddlewareAPI } from 'redux'
import { RetoolDispatch, RetoolMiddleware, RetoolState } from 'store'
import { NEW_RESOURCE_URL_PREFIX, RECEIVE_CREATE_RESOURCE, RECEIVE_INVITE_EMAIL } from 'store/constants'
import { getProfile } from 'store/user'
import { RECEIVE_PAGE_CREATE } from 'store/appModel/pages'
import { RECEIVE_SAVE } from 'store/appModel/actionTypes'
import { CALL_API_CONST_DEPRECATE as CALL_API_CONST } from 'networking/index.deprecated'
import { Checklist } from 'common/records'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import cookies from 'js-cookie'
import ls from 'local-storage'
import { browserHistory } from 'react-router'
import {
  EXPAND_ONBOARDING_CHECKLIST,
  ONBOARDING_FINISH,
  ONBOARDING_GO_TO_NEXT_STEP,
  ONBOARDING_GO_TO_NEXT_SUB_STEP,
  ONBOARDING_GO_TO_PREV_STEP,
  ONBOARDING_GO_TO_PREV_SUB_STEP,
  ONBOARDING_MODAL_REPOSITION,
  RECEIVE_SUBMIT_APP_BEING_BUILT,
} from 'store/onboarding/constants'
import { onboardingSelector } from './selectors'

// ------------------------------------
// Actions
// ------------------------------------

export function repositionModal(left: number, top: number) {
  return {
    type: ONBOARDING_MODAL_REPOSITION,
    payload: { top, left },
  }
}

export function goToNextStep() {
  return {
    type: ONBOARDING_GO_TO_NEXT_STEP,
  }
}

export function goToPrevSubStep() {
  return {
    type: ONBOARDING_GO_TO_PREV_SUB_STEP,
  }
}

export function goToNextSubStep() {
  return {
    type: ONBOARDING_GO_TO_NEXT_SUB_STEP,
  }
}

export function goToPreviousStep() {
  return {
    type: ONBOARDING_GO_TO_PREV_STEP,
  }
}

export function expandOnboardingChecklist() {
  localStorage.checklistCollapsed = false
  return { type: EXPAND_ONBOARDING_CHECKLIST }
}

export function finishOnboarding({ doNotNavigate = false } = {}) {
  cookies.remove('onboardingUrl')
  return (dispatch: any) => {
    dispatch({
      type: ONBOARDING_FINISH,
    })

    if (doNotNavigate) return

    const nextUrl = ls.get('templateUserInfo') ? JSON.parse(ls.get('templateUserInfo')).appUrl : NEW_RESOURCE_URL_PREFIX
    browserHistory.push(nextUrl)
  }
}

/**
 * re-fetch the onboarding checklist when an action
 * that might affect the checklist goes thru (e.g. when someone
 * creates a new app)
 */
const onboardingChecklistMiddleware = (store: MiddlewareAPI<RetoolDispatch, RetoolState>, action: AnyAction) => {
  const onboardingChecklist = store.getState().onboarding.onboardingChecklist

  const isCallApiThunk = action[CALL_API_CONST]
  if (!isCallApiThunk) return

  const receiveActionType = action[CALL_API_CONST].types && action[CALL_API_CONST].types[1]
  if (
    onboardingChecklist &&
    [
      RECEIVE_CREATE_RESOURCE,
      RECEIVE_SUBMIT_APP_BEING_BUILT,
      RECEIVE_PAGE_CREATE,
      RECEIVE_SAVE,
      RECEIVE_INVITE_EMAIL,
    ].includes(receiveActionType)
  ) {
    // the onboarding checklist may have updated, so re-fetch it.
    return store.dispatch(getProfile()).then((org) => {
      if (!(org && org.onboardingChecklist)) return

      const getNumCompletedSteps = (checklist: Checklist) => checklist.steps.filter((step) => !!step.completedAt).length
      const stepJustCompleted =
        getNumCompletedSteps(org.onboardingChecklist) > getNumCompletedSteps(onboardingChecklist)
      if (stepJustCompleted) {
        retoolAnalyticsTrack('Onboarding Checklist Step Completed', {
          stepId: (org.onboardingChecklist.steps as Array<any>).find(
            (step, idx) => step.completedAt && !onboardingChecklist.steps[idx].completedAt,
          ).step,
          numStepsLeft: (org.onboardingChecklist.steps as Array<any>).filter((step) => !step.completedAt).length,
        })

        // when a step completes, we want to expand the checklist
        // so that the user can see what the next step is
        store.dispatch(expandOnboardingChecklist())
      }
      return
    })
  }
}

export const onboardingMiddleware: RetoolMiddleware = (store) => (next) => async (action) => {
  const { dispatch, getState } = store
  let state = getState()

  const prevOnboardingState = onboardingSelector(state)
  const prevAppTemplate = state.appTemplate.present
  const prevAppModel = state.appModel
  const prevEditorState = state.editor

  const res = await next(action)

  onboardingChecklistMiddleware(store, action)
  state = getState()
  const nextOnboardingState = onboardingSelector(state)
  if (prevOnboardingState !== nextOnboardingState) {
    // Then do nothing
  } else {
    const nextAppTemplate = state.appTemplate.present
    const nextAppModel = state.appModel
    const nextEditorState = state.editor
    if (
      nextAppTemplate !== prevAppTemplate ||
      nextAppModel !== prevAppModel ||
      nextEditorState !== prevEditorState ||
      action.type === 'SELECTION_BOX_START' ||
      action.type === 'FAILURE_PREVIEW_QUERY' ||
      action.type === 'RECEIVE_PREVIEW_QUERY'
    ) {
      try {
        if (
          nextOnboardingState.showOnboarding &&
          nextOnboardingState.substepProgress === 1 &&
          nextOnboardingState.progress === 1
        ) {
          if (!state.onboarding.skipped) {
            confetti() // :)
          }
          dispatch(finishOnboarding({ doNotNavigate: true }))
        } else if (
          nextOnboardingState.currentOnboardingStep?.condition?.(nextAppTemplate, nextAppModel, nextEditorState)
        ) {
          dispatch(goToNextStep())
        } else if (
          nextOnboardingState.currentOnboardingSubStep?.nextCondition?.(nextAppTemplate, nextAppModel, nextEditorState)
        ) {
          dispatch(goToNextSubStep())
        } else if (
          nextOnboardingState.currentOnboardingSubStep?.prevCondition?.(nextAppTemplate, nextAppModel, nextEditorState)
        ) {
          dispatch(goToPrevSubStep())
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error in condition for this onboarding step:', nextOnboardingState.currentOnboardingStep, err)
      }
    }
  }
  return res
}

export const REQUEST_PRE_AUTH_ORG_DATA = 'REQUEST_PRE_AUTH_ORG_DATA'
export const RECEIVE_PRE_AUTH_ORG_DATA = 'RECEIVE_PRE_AUTH_ORG_DATA'
export const FAILURE_PRE_AUTH_ORG_DATA = 'FAILURE_PRE_AUTH_ORG_DATA'

export const REQUEST_NEW_AUTH_TOKEN = 'REQUEST_NEW_AUTH_TOKEN'
export const RECEIVE_NEW_AUTH_TOKEN = 'RECEIVE_NEW_AUTH_TOKEN'
export const FAILURE_NEW_AUTH_TOKEN = 'FAILURE_NEW_AUTH_TOKEN'

export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD'
export const RECEIVE_RESET_PASSWORD = 'RECEIVE_RESET_PASSWORD'
export const FAILURE_RESET_PASSWORD = 'FAILURE_RESET_PASSWORD'

export const REQUEST_TEMPLATES_LOAD = 'REQUEST_TEMPLATES_LOAD'
export const RECEIVE_TEMPLATES_LOAD = 'RECEIVE_TEMPLATES_LOAD'
export const FAILURE_TEMPLATES_LOAD = 'FAILURE_TEMPLATES_LOAD'

export const REQUEST_MARKETING_TEMPLATES_LOAD = 'REQUEST_MARKETING_TEMPLATES_LOAD'
export const RECEIVE_MARKETING_TEMPLATES_LOAD = 'RECEIVE_MARKETING_TEMPLATES_LOAD'
export const FAILURE_MARKETING_TEMPLATES_LOAD = 'FAILURE_MARKETING_TEMPLATES_LOAD'

export const SET_LARGE_SCREEN = 'SET_LARGE_SCREEN'
export const SET_SMALL_SCREEN = 'SET_SMALL_SCREEN'
export const FORCE_MOBILE_MODE = 'FORCE_MOBILE_MODE'
export const UNFORCE_MOBILE_MODE = 'UNFORCE_MOBILE_MODE'

export const REQUEST_RESOURCES = 'REQUEST_RESOURCES'
export const RECEIVE_RESOURCES = 'RECEIVE_RESOURCES'
export const FAILURE_RESOURCES = 'FAILURE_RESOURCES'

export const REQUEST_FLOWS = 'REQUEST_FLOWS'
export const RECEIVE_FLOWS = 'RECEIVE_FLOWS'
export const FAILURE_FLOWS = 'FAILURE_FLOWS'

export const REQUEST_BRANCHES = 'REQUEST_BRANCHES'
export const RECEIVE_BRANCHES = 'RECEIVE_BRANCHES'
export const FAILURE_BRANCHES = 'FAILURE_BRANCHES'

export const REQUEST_BRANCH_CREATE = 'REQUEST_BRANCH_CREATE'
export const RECEIVE_BRANCH_CREATE = 'RECEIVE_BRANCH_CREATE'
export const FAILURE_BRANCH_CREATE = 'FAILURE_BRANCH_CREATE'

export const REQUEST_BRANCH_DELETE = 'REQUEST_BRANCH_DELETE'
export const RECEIVE_BRANCH_DELETE = 'RECEIVE_BRANCH_DELETE'
export const FAILURE_BRANCH_DELETE = 'FAILURE_BRANCH_DELETE'

export const REQUEST_BRANCH_RENAME = 'REQUEST_BRANCH_RENAME'
export const RECEIVE_BRANCH_RENAME = 'RECEIVE_BRANCH_RENAME'
export const FAILURE_BRANCH_RENAME = 'FAILURE_BRANCH_RENAME'

export const REQUEST_MODULES = 'REQUEST_MODULES'
export const RECEIVE_MODULES = 'RECEIVE_MODULES'
export const FAILURE_MODULES = 'FAILURE_MODULES'

export const STORE_MODULE_TEMPLATES = 'STORE_MODULE_TEMPLATES'

export const REQUEST_UPSERT_FLOW = 'REQUEST_UPSERT_FLOW'
export const RECEIVE_UPSERT_FLOW = 'RECEIVE_UPSERT_FLOW'
export const FAILURE_UPSERT_FLOW = 'FAILURE_UPSERT_FLOW'

export const REQUEST_CREATE_RESOURCE = 'REQUEST_CREATE_RESOURCE'
export const RECEIVE_CREATE_RESOURCE = 'RECEIVE_CREATE_RESOURCE'
export const FAILURE_CREATE_RESOURCE = 'FAILURE_CREATE_RESOURCE'

export const REQUEST_RESOURCE = 'REQUEST_RESOURCE_UPDATE'
export const RECEIVE_RESOURCE = 'RECEIVE_RESOURCE_UPDATE'
export const FAILURE_RESOURCE = 'FAILURE_RESOURCE_UPDATE'

export const REQUEST_DELETE_RESOURCE = 'REQUEST_DELETE_RESOURCE'
export const RECEIVE_DELETE_RESOURCE = 'RECEIVE_DELETE_RESOURCE'
export const FAILURE_DELETE_RESOURCE = 'FAILURE_DELETE_RESOURCE'

export const CLEAR_RESOURCE_ERROR_MESSAGE = 'CLEAR_RESOURCE_ERROR_MESSAGE'

export const REQUEST_USER_PROFILE = 'REQUEST_USER_PROFILE'
export const RECEIVE_USER_PROFILE = 'RECEIVE_USER_PROFILE'
export const FAILURE_USER_PROFILE = 'FAILURE_USER_PROFILE'

export const REQUEST_ORG_IMAGE_BLOB = 'REQUEST_ORG_IMAGE_BLOB'
export const RECEIVE_ORG_IMAGE_BLOB = 'RECEIVE_ORG_IMAGE_BLOB'
export const FAILURE_ORG_IMAGE_BLOB = 'FAILURE_ORG_IMAGE_BLOB'

export const REQUEST_UPLOAD_ORG_IMAGE_BLOB = 'REQUEST_UPLOAD_ORG_IMAGE_BLOB'
export const RECEIVE_UPLOAD_ORG_IMAGE_BLOB = 'RECEIVE_UPLOAD_ORG_IMAGE_BLOB'
export const FAILURE_UPLOAD_ORG_IMAGE_BLOB = 'FAILURE_UPLOAD_ORG_IMAGE_BLOB'

export const REQUEST_CHANGE_PASSWORD = 'REQUEST_CHANGE_PASSWORD'
export const RECEIVE_CHANGE_PASSWORD = 'RECEIVE_CHANGE_PASSWORD'
export const FAILURE_CHANGE_PASSWORD = 'FAILURE_CHANGE_PASSWORD'

export const REQUEST_ORGANIZATION = 'REQUEST_ORGANIZATION'
export const RECEIVE_ORGANIZATION = 'RECEIVE_ORGANIZATION'
export const FAILURE_ORGANIZATION = 'FAILURE_ORGANIZATION'

export const REQUEST_PATCH_ORGANIZATION = 'REQUEST_PATCH_ORGANIZATION'
export const RECEIVE_PATCH_ORGANIZATION = 'RECEIVE_PATCH_ORGANIZATION'
export const FAILURE_PATCH_ORGANIZATION = 'FAILURE_PATCH_ORGANIZATION'

export const REQUEST_GET_INSTRUMEMTATION_INTEGRATIONS = 'REQUEST_GET_INSTRUMEMTATION_INTEGRATIONS'
export const RECEIVE_GET_INSTRUMEMTATION_INTEGRATIONS = 'RECEIVE_GET_INSTRUMEMTATION_INTEGRATIONS'
export const FAILURE_GET_INSTRUMEMTATION_INTEGRATIONS = 'FAILURE_GET_INSTRUMEMTATION_INTEGRATIONS'

export const REQUEST_PATCH_INSTRUMEMTATION_INTEGRATION = 'REQUEST_PATCH_INSTRUMEMTATION_INTEGRATION'
export const RECEIVE_PATCH_INSTRUMEMTATION_INTEGRATION = 'RECEIVE_PATCH_INSTRUMEMTATION_INTEGRATION'
export const FAILURE_PATCH_INSTRUMEMTATION_INTEGRATION = 'FAILURE_PATCH_INSTRUMEMTATION_INTEGRATION'

export const REQUEST_DELETE_INSTRUMEMTATION_INTEGRATION = 'REQUEST_DELETE_INSTRUMEMTATION_INTEGRATION'
export const RECEIVE_DELETE_INSTRUMEMTATION_INTEGRATION = 'RECEIVE_DELETE_INSTRUMEMTATION_INTEGRATION'
export const FAILURE_DELETE_INSTRUMEMTATION_INTEGRATION = 'FAILURE_DELETE_INSTRUMEMTATION_INTEGRATION'

export const REQUEST_PATCH_APP_THEME = 'REQUEST_PATCH_APP_THEME'
export const RECEIVE_PATCH_APP_THEME = 'RECEIVE_PATCH_APP_THEME'
export const FAILURE_PATCH_APP_THEME = 'FAILURE_PATCH_APP_THEME'

export const REQUEST_DELETE_APP_THEME = 'REQUEST_DELETE_APP_THEME'
export const RECEIVE_DELETE_APP_THEME = 'RECEIVE_DELETE_APP_THEME'
export const FAILURE_DELETE_APP_THEME = 'FAILURE_DELETE_APP_THEME'

export const REQUEST_SET_DEFAULT_APP_THEME = 'REQUEST_SET_DEFAULT_APP_THEME'
export const RECEIVE_SET_DEFAULT_APP_THEME = 'RECEIVE_SET_DEFAULT_APP_THEME'
export const FAILURE_SET_DEFAULT_APP_THEME = 'FAILURE_SET_DEFAULT_APP_THEME'

export const REQUEST_ORGANIZATION_EXPERIMENTS = 'REQUEST_ORGANIZATION_EXPERIMENTS'
export const RECEIVE_ORGANIZATION_EXPERIMENTS = 'RECEIVE_ORGANIZATION_EXPERIMENTS'
export const FAILURE_ORGANIZATION_EXPERIMENTS = 'FAILURE_ORGANIZATION_EXPERIMENTS'

export const REQUEST_ENABLE_EXPERIMENT = 'REQUEST_ENABLE_EXPERIMENT'
export const RECEIVE_ENABLE_EXPERIMENT = 'RECEIVE_ENABLE_EXPERIMENT'
export const FAILURE_ENABLE_EXPERIMENT = 'FAILURE_ENABLE_EXPERIMENT'

export const REQUEST_DISABLE_EXPERIMENT = 'REQUEST_DISABLE_EXPERIMENT'
export const RECEIVE_DISABLE_EXPERIMENT = 'RECEIVE_DISABLE_EXPERIMENT'
export const FAILURE_DISABLE_EXPERIMENT = 'FAILURE_DISABLE_EXPERIMENT'

export const REQUEST_DISMISS_SALES_CTA = 'REQUEST_DISMISS_SALES_CTA'
export const RECEIVE_DISMISS_SALES_CTA = 'RECEIVE_DISMISS_SALES_CTA'
export const FAILURE_DISMISS_SALES_CTA = 'FAILURE_DISMISS_SALES_CTA'

export const REQUEST_DISMISS_ONBOARDING_SUGGESTIONS = 'REQUEST_DISMISS_ONBOARDING_SUGGESTIONS'
export const RECEIVE_DISMISS_ONBOARDING_SUGGESTIONS = 'RECEIVE_DISMISS_ONBOARDING_SUGGESTIONS'
export const FAILURE_DISMISS_ONBOARDING_SUGGESTIONS = 'FAILURE_DISMISS_ONBOARDING_SUGGESTIONS'

export const REQUEST_DISMISS_TUTORIAL_CTA = 'REQUEST_DISMISS_TUTORIAL_CTA'
export const RECEIVE_DISMISS_TUTORIAL_CTA = 'RECEIVE_DISMISS_TUTORIAL_CTA'
export const FAILURE_DISMISS_TUTORIAL_CTA = 'FAILURE_DISMISS_TUTORIAL_CTA'

export const REQUEST_INVITE_EMAIL = 'REQUEST_INVITE_EMAIL'
export const RECEIVE_INVITE_EMAIL = 'RECEIVE_INVITE_EMAIL'
export const FAILURE_INVITE_EMAIL = 'FAILURE_INVITE_EMAIL'

export const REQUEST_INVITE_SUGGESTION = 'REQUEST_INVITE_SUGGESTION'
export const RECEIVE_INVITE_SUGGESTION = 'RECEIVE_INVITE_SUGGESTION'
export const FAILURE_INVITE_SUGGESTION = 'FAILURE_INVITE_SUGGESTION'

export const REQUEST_UPDATE_INVITE_SUGGESTION = 'REQUEST_UPDATE_INVITE_SUGGESTION'
export const RECEIVE_UPDATE_INVITE_SUGGESTION = 'RECEIVE_UPDATE_INVITE_SUGGESTION'
export const FAILURE_UPDATE_INVITE_SUGGESTION = 'FAILURE_UPDATE_INVITE_SUGGESTION'

export const REQUEST_VIEW_INVITE_SUGGESTION = 'REQUEST_VIEW_INVITE_SUGGESTION'
export const RECEIVE_VIEW_INVITE_SUGGESTION = 'RECEIVE_VIEW_INVITE_SUGGESTION'
export const FAILURE_VIEW_INVITE_SUGGESTION = 'FAILURE_VIEW_INVITE_SUGGESTION'

export const REQUEST_REVOKE_INVITE = 'REQUEST_REVOKE_INVITE'
export const RECEIVE_REVOKE_INVITE = 'RECEIVE_REVOKE_INVITE'
export const FAILURE_REVOKE_INVITE = 'FAILURE_REVOKE_INVITE'

export const REQUEST_REMOVE_USER = 'REQUEST_REMOVE_USER'
export const RECEIVE_REMOVE_USER = 'RECEIVE_REMOVE_USER'
export const FAILURE_REMOVE_USER = 'FAILURE_REMOVE_USER'

export const REQUEST_STRIPE_CUSTOMER_UPDATE = 'REQUEST_STRIPE_CUSTOMER_UPDATE'
export const RECEIVE_STRIPE_CUSTOMER_UPDATE = 'RECEIVE_STRIPE_CUSTOMER_UPDATE'
export const FAILURE_STRIPE_CUSTOMER_UPDATE = 'FAILURE_STRIPE_CUSTOMER_UPDATE'

export const REQUEST_PLAN_CHANGE = 'REQUEST_PLAN_CHANGE'
export const RECEIVE_PLAN_CHANGE = 'RECEIVE_PLAN_CHANGE'
export const FAILURE_PLAN_CHANGE = 'FAILURE_PLAN_CHANGE'

export const REQUEST_PUT_WORKSPACE = 'REQUEST_PUT_WORKSPACE'
export const RECEIVE_PUT_WORKSPACE = 'RECEIVE_PUT_WORKSPACE'
export const FAILURE_PUT_WORKSPACE = 'FAILURE_PUT_WORKSPACE'

export const REQUEST_PATCH_ORGANIZATION_LIBRARIES = 'REQUEST_PATCH_ORGANIZATION_LIBRARIES'
export const RECEIVE_PATCH_ORGANIZATION_LIBRARIES = 'RECEIVE_PATCH_ORGANIZATION_LIBRARIES'
export const FAILURE_PATCH_ORGANIZATION_LIBRARIES = 'FAILURE_PATCH_ORGANIZATION_LIBRARIES'

export const REQUEST_SET_USER_ENABLED_FLAG = 'REQUEST_SET_USER_ENABLED_FLAG'
export const RECEIVE_SET_USER_ENABLED_FLAG = 'RECEIVE_SET_USER_ENABLED_FLAG'
export const FAILURE_SET_USER_ENABLED_FLAG = 'FAILURE_SET_USER_ENABLED_FLAG'

export const REQUEST_RESET_USER_TWO_FACTOR_AUTH = 'REQUEST_RESET_USER_TWO_FACTOR_AUTH'
export const RECEIVE_RESET_USER_TWO_FACTOR_AUTH = 'RECEIVE_RESET_USER_TWO_FACTOR_AUTH'
export const FAILURE_RESET_USER_TWO_FACTOR_AUTH = 'FAILURE_RESET_USER_TWO_FACTOR_AUTH'

export const REQUEST_SETUP_2FA = 'REQUEST_SETUP_2FA'
export const RECEIVE_SETUP_2FA = 'RECEIVE_SETUP_2FA'
export const FAILURE_SETUP_2FA = 'FAILURE_SETUP_2FA'

export const REQUEST_CONFIRM_2FA = 'REQUEST_CONFIRM_2FA'
export const RECEIVE_CONFIRM_2FA = 'RECEIVE_CONFIRM_2FA'
export const FAILURE_CONFIRM_2FA = 'FAILURE_CONFIRM_2FA'

export const BEGIN_2FA_CHALLENGE = 'BEGIN_2FA_CHALLENGE'
export const REQUEST_VERIFY_2FA_CHALLENGE = 'REQUEST_VERIFY_2FA_CHALLENGE'
export const RECEIVE_VERIFY_2FA_CHALLENGE = 'RECEIVE_VERIFY_2FA_CHALLENGE'
export const FAILURE_VERIFY_2FA_CHALLENGE = 'FAILURE_VERIFY_2FA_CHALLENGE'

export const REQUEST_RESET_2FA = 'REQUEST_RESET_2FA'
export const RECEIVE_RESET_2FA = 'RECEIVE_RESET_2FA'
export const FAILURE_RESET_2FA = 'FAILURE_RESET_2FA'

export const REQUEST_FAVORITE_PAGE = 'REQUEST_FAVORITE_PAGE'
export const RECEIVE_FAVORITE_PAGE = 'RECEIVE_FAVORITE_PAGE'
export const FAILURE_FAVORITE_PAGE = 'FAILURE_FAVORITE_PAGE'

export const REQUEST_UNFAVORITE_PAGE = 'REQUEST_UNFAVORITE_PAGE'
export const RECEIVE_UNFAVORITE_PAGE = 'RECEIVE_UNFAVORITE_PAGE'
export const FAILURE_UNFAVORITE_PAGE = 'FAILURE_UNFAVORITE_PAGE'

export const REQUEST_FAVORITE_FOLDER = 'REQUEST_FAVORITE_FOLDER'
export const RECEIVE_FAVORITE_FOLDER = 'RECEIVE_FAVORITE_FOLDER'
export const FAILURE_FAVORITE_FOLDER = 'FAILURE_FAVORITE_FOLDER'

export const REQUEST_UNFAVORITE_FOLDER = 'REQUEST_UNFAVORITE_FOLDER'
export const RECEIVE_UNFAVORITE_FOLDER = 'RECEIVE_UNFAVORITE_FOLDER'
export const FAILURE_UNFAVORITE_FOLDER = 'FAILURE_UNFAVORITE_FOLDER'

export const REQUEST_UPDATE_PHOTO_URL = 'REQUEST_UPDATE_PHOTO_URL'
export const RECEIVE_UPDATE_PHOTO_URL = 'RECEIVE_UPDATE_PHOTO_URL'
export const FAILURE_UPDATE_PHOTO_URL = 'FAILURE_UPDATE_PHOTO_URL'

export const USE_CASE_TEMPLATE_TYPE = 'useCase'
export const INTEGRATION_TEMPLATE_TYPE = 'integration'

export const QUERY_LIBRARY_URL_PREFIX = '/querylibrary'
export const RESOURCES_URL_PREFIX = '/resources'
export const DATA_SOURCES_URL_PREFIX = '/datasources'
export const FLOWS_URL_PREFIX = '/flows'
export const TREE_URL_PREFIX = '/tree'
export const CLONED_TEMPLATE_PREFIX = '/editor'

export const NEW_RESOURCE_URL_PREFIX = `${RESOURCES_URL_PREFIX}/new`
export const NEW_FLOW_URL_PREFIX = `${FLOWS_URL_PREFIX}/new`

export const REQUEST_UPDATE_ORGANIZATION_THEME = 'REQUEST_UPDATE_ORGANIZATION_THEME'
export const RECEIVE_UPDATE_ORGANIZATION_THEME = 'RECEIVE_UPDATE_ORGANIZATION_THEME'
export const FAILURE_UPDATE_ORGANIZATION_THEME = 'FAILURE_UPDATE_ORGANIZATION_THEME'

export const REQUEST_REMOVE_ORGANIZATION_THEME = 'REQUEST_REMOVE_ORGANIZATION_THEME'
export const RECEIVE_REMOVE_ORGANIZATION_THEME = 'RECEIVE_REMOVE_ORGANIZATION_THEME'
export const FAILURE_REMOVE_ORGANIZATION_THEME = 'FAILURE_REMOVE_ORGANIZATION_THEME'

export const INTERCOM_OPEN = 'INTERCOM_OPEN'

export const ADD_ACTIVE_MOCK_FOR_TEST = 'ADD_ACTIVE_MOCK_FOR_TEST'
export const REMOVE_ALL_ACTIVE_MOCKS_FOR_TEST = 'REMOVE_ALL_ACTIVE_MOCKS_FOR_TEST'

export const SET_CURRENTLY_RUNNING_TEST = 'SET_CURRENTLY_RUNNING_TEST'
export const MARK_TEST_FINISHED = 'MARK_TEST_FINISHED'

export const UPDATE_SANDBOX_GLOBALS = 'UPDATE_SANDBOX_GLOBALS'

export const SWITCH_USER_PLAN = 'SWITCH_USER_PLAN'

// permissions

export const UPDATE_GROUP_RECEIVE = 'UPDATE_GROUP_RECEIVE'
export const SET_GROUPS_FOR_ACCOUNT_RECEIVE = 'SET_GROUPS_FOR_ACCOUNT_RECEIVE'
export const SET_ACCOUNTS_FOR_GROUP_RECEIVE = 'SET_ACCOUNTS_FOR_GROUP_RECEIVE'
export const GET_PERMISSIONS_RECEIVE = 'GET_PERMISSIONS_RECEIVE'
export const SET_GROUP_ADMINS_RECEIVE = 'SET_GROUP_ADMINS_RECEIVE'

export const REQUEST_DELETE_GROUP = 'REQUEST_DELETE_GROUP'
export const RECEIVE_DELETE_GROUP = 'RECEIVE_DELETE_GROUP'
export const FAILURE_DELETE_GROUP = 'FAILURE_DELETE_GROUP'

export const REQUEST_CREATE_GROUP = 'REQUEST_CREATE_GROUP'
export const RECEIVE_CREATE_GROUP = 'RECEIVE_CREATE_GROUP'
export const FAILURE_CREATE_GROUP = 'FAILURE_CREATE_GROUP'

export const REQUEST_ADD_USERS_TO_GROUP = 'REQUEST_ADD_USERS_TO_GROUP'
export const RECEIVE_ADD_USERS_TO_GROUP = 'RECEIVE_ADD_USERS_TO_GROUP'
export const FAILURE_ADD_USERS_TO_GROUP = 'FAILURE_ADD_USERS_TO_GROUP'

export const REQUEST_SET_USERS_FOR_GROUP = 'REQUEST_SET_USERS_FOR_GROUP'
export const RECEIVE_SET_USERS_FOR_GROUP = 'RECEIVE_SET_USERS_FOR_GROUP'
export const FAILURE_SET_USERS_FOR_GROUP = 'FAILURE_SET_USERS_FOR_GROUP'

export const REQUEST_SET_PAGES_FOR_GROUP = 'REQUEST_SET_PAGES_FOR_GROUP'
export const RECEIVE_SET_PAGES_FOR_GROUP = 'RECEIVE_SET_PAGES_FOR_GROUP'
export const FAILURE_SET_PAGES_FOR_GROUP = 'FAILURE_SET_PAGES_FOR_GROUP'

export const REQUEST_SET_RESOURCES_FOR_GROUP = 'REQUEST_SET_RESOURCES_FOR_GROUP'
export const RECEIVE_SET_RESOURCES_FOR_GROUP = 'RECEIVE_SET_RESOURCES_FOR_GROUP'
export const FAILURE_SET_RESOURCES_FOR_GROUP = 'FAILURE_SET_RESOURCES_FOR_GROUP'

export const REQUEST_PATCH_GROUP = 'REQUEST_PATCH_GROUP'
export const RECEIVE_PATCH_GROUP = 'RECEIVE_PATCH_GROUP'
export const FAILURE_PATCH_GROUP = 'FAILURE_PATCH_GROUP'

// This cookie doesn't do the actual redirecting, that's done by redirectUrl, but signals that we are
// redirecting to the billing portal so we can change the signup flow
export const REDIRECTING_TO_BILLING_PORTAL_COOKIE_NAME = 'redirectingToBillingPortal'

export const FLIP_ONPREM_BILLING_PORTAL = 'FLIP_ONPREM_BILLING_PORTAL'
export const LOCAL_TRIAL_KEY = 'LOCAL-ONLY-TRIAL'

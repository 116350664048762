import React from 'react'
import { Radio } from 'components/design-system'
import { ResourceFormProps } from './types'
import ResourceOAuthForm from './ResourceOAuthForm'
import { SHARED_OAUTH_CALLBACK_URL } from '../oauthForm'
import { PlainResource } from 'common/records'
import { NAME_MISSING } from './constants'

export const readonlyScope =
  'email profile https://www.googleapis.com/auth/spreadsheets.readonly https://www.googleapis.com/auth/drive.readonly'
export const editScope =
  'email profile https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/spreadsheets'

export const GOOGLE_OAUTH_PROPERTIES = {
  oauth2_callback_url: SHARED_OAUTH_CALLBACK_URL,
  oauth2_auth_url: 'https://accounts.google.com/o/oauth2/v2/auth',
  oauth2_access_token_url: 'https://www.googleapis.com/oauth2/v4/token',
  oauth2_client_id: CLIENT_ID,
}

class GoogleSheetsForm extends React.Component<ResourceFormProps> {
  constructor(props: ResourceFormProps) {
    super(props)
  }

  UNSAFE_componentWillMount() {
    this.props.updateResourceOptions({
      ...GOOGLE_OAUTH_PROPERTIES,
      oauth2_scope: this.props.resource.options.oauth2_scope || editScope,
    })
  }

  render() {
    return (
      <div className="form-content-google">
        <hr />
        <h5 className="section-heading light-gray mb12">Authorize</h5>
        <div className="grid-1c mb12">
          <div />
          <div>
            <p className="mb4">
              If you want to build Retool apps that can modify your spreadsheets, make sure to enable read and write
              access.
            </p>
            <Radio.Group
              className="form-google-authorize-radio"
              value={this.props.resource.options.oauth2_scope || editScope}
              onChange={(e: any) => {
                this.props.onChangeOption('oauth2_scope')(e.target.value)
              }}
            >
              <Radio value={editScope}>Read and write</Radio>
              <div className="ml24 fw-400 mb8">
                Your Retool queries can read values, update rows, append rows, copy sheets, create new spreadsheets, and
                more.
              </div>
              <Radio value={readonlyScope}>Read only</Radio>
              <div className="ml24 fw-400">Your Retool queries can only read data from spreadsheets.</div>
            </Radio.Group>
            <div className="mt16">
              <ResourceOAuthForm
                onTriggerSaveResource={this.props.saveResource}
                resource={this.props.resource}
                resourceTypeForLabel="Google Sheets"
                disabled={!this.props.resource.displayName}
                disabledTooltipTitle={NAME_MISSING}
                onUpdateOauthAuthorizedStatus={this.props.onUpdateOauthAuthorizedStatus}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default {
  label: 'Google Sheets',
  isOAuth: () => true,
  form: GoogleSheetsForm,
  defaults: { options: {} },
  validator: (resource: PlainResource, prevResource: Partial<PlainResource>) => {
    // when scope changes, we need the user to click the "Connect to Sheets" button again
    const scopeChanged =
      prevResource && prevResource.options && prevResource.options.oauth2_scope !== resource.options.oauth2_scope
    return !!resource.id && !scopeChanged
  },
  hideCreateResourceButton: () => true,
}

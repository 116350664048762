import React from 'react'
import { OneSignalResourceSettings } from 'common/records'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'
import { generateResourceForm } from 'components/ResourceForm/resourceFormGenerator/generator'
import { AuthTemplate } from 'components/ResourceForm/resourceFormGenerator/generator-types'

const INTEGRATION_LABEL = 'OneSignal'
const defaultOptions: OneSignalResourceSettings = {
  options: {
    customQueryParameters: [['', '']],
    customHeaders: [], // Need to do a special switch for OneSignal because the key used depends on the api path
    user_auth_key: '',
    api_key: '',
    spec: `${RETOOL_SPECS_DOMAIN}/onesignal/3.0.0/1.0.0.json`,
  },
}

const auth: AuthTemplate = [
  {
    type: 'custom',
    fields: [
      {
        type: 'token',
        fieldName: 'user_auth_key',
        required: false,
        label: 'User Auth Key',
        description: (
          <>
            Used for `/apps/` endpoints. Enter your secret OneSignal user auth key above. To retrieve or create a key
            refer to the{' '}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://documentation.onesignal.com/docs/accounts-and-keys#user-auth-key"
            >
              user auth key
            </a>{' '}
            section of the OneSignal documentation.
          </>
        ),
      },
      {
        type: 'token',
        fieldName: 'api_key',
        required: false,
        label: 'REST API Key',
        description: (
          <>
            Used for all other endpoints. Enter your secret OneSignal REST API key above. To retrieve or create a key
            refer to the{' '}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://documentation.onesignal.com/docs/accounts-and-keys#rest-api-key"
            >
              REST API key
            </a>{' '}
            section of the OneSignal documentation.
          </>
        ),
      },
    ],
  },
]

export default generateResourceForm(INTEGRATION_LABEL, defaultOptions, { auth, hideTestConnection: () => true })

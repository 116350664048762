import Menu from 'antd/lib/menu'
import { Icon } from 'components/design-system'
import React from 'react'

export type BranchMenuItemProps = {
  branchName: string
  onClick?: () => void
  isSelected?: boolean
  isProtected?: boolean
}

const BranchMenuItem: React.FC<BranchMenuItemProps> = ({
  branchName,
  isSelected = false,
  isProtected = false,
  onClick,
}) => {
  return (
    <Menu.Item className="ant-dropdown-menu-item flex items-center" onClick={onClick}>
      <Icon type={isProtected ? 'locked' : 'branch'} />
      <div className="ml4 mr12 fs-12 fw-500 medium-gray lh-20">{branchName}</div>
      {isSelected && <Icon type="blue-check" />}
    </Menu.Item>
  )
}

export default BranchMenuItem

import { injectReducer } from '../../store/reducers'
import { getResources } from 'store/user'
import { browserHistory } from 'react-router'
import requirePlugins from 'components/plugins/requirePlugins'
import { RESET_TEMPLATE } from 'store/appModel/constants'
import { getSavedQueries } from 'store/playground'
import { pagesLoad } from 'store/appModel/pages'
import { QUERY_LIBRARY_URL_PREFIX } from 'store/constants'

export default function QueryPlaygroundRoute(store: any) {
  return [
    {
      path: `querylibrary**`,
      pageName: 'Query Library',
      onEnter: async () => {
        // eslint-disable-next-line no-console
        console.log('[DBG] page load', new Date().getTime() - window.htmlLoadedAt)
        document.title = 'Query Library | Retool'
      },
      onLeave: async () => {
        store.dispatch({ type: RESET_TEMPLATE })
      },
      getComponent(nextState: any, cb: any) {
        require.ensure(
          [],
          (require) => {
            requirePlugins(
              async () => {
                const QueryPlaygroundContainer: any = require(/* webpackChunkName: "queryplayground" */ './containers/QueryPlaygroundContainer')
                  .default

                const playgroundReducer = require('store/playground').default
                injectReducer(store, { key: 'playground', reducer: playgroundReducer })

                const pathParts = nextState.location.pathname.split('/')
                const queryId = parseInt(pathParts[2]) || 0
                store
                  .dispatch(getResources())
                  .then(() => store.dispatch(getSavedQueries(queryId)))
                  .catch((err: any) => {
                    // eslint-disable-next-line no-console
                    console.log('Error fetching queries', err)
                  })
                store.dispatch(pagesLoad())
                cb(null, QueryPlaygroundContainer)
              },
              { datasources: true },
            )
          },
          'queryplayground',
        )
      },
    },
    {
      path: 'queryplayground**',
      onEnter: async (nextState: any, _replace: any, cb: any) => {
        const splat = nextState.location.pathname.replace('/queryplayground', '')
        browserHistory.push(`${QUERY_LIBRARY_URL_PREFIX}${splat}`)
        cb()
      },
    },
  ]
}

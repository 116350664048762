import React from 'react'
import { connect } from 'react-redux'
import { verify2FAChallenge } from 'store/user'
import { Button, TextInput } from 'components/design-system'
import AuthLayout from 'components/AuthLayout/AuthLayout'
import AuthRetoolLogo from 'routes/Login/components/AuthRetoolLogo'

class TwoFactorAuthChallenge extends React.Component<any, any> {
  state = { code: '' }
  confirmCode = async () => {
    this.props.verify2FAChallenge(this.state.code.replace(' ', ''))
  }
  render() {
    return (
      <div className="body-inner">
        <AuthLayout
          top={
            <div>
              <AuthRetoolLogo className="mb24" />
              <div className="auth-heading mb8"> Enter your verification code</div>
              <span className="auth-subheading">Please enter your code from your authenticator app.</span>
              <TextInput
                placeholder="123456"
                onChange={(e) => this.setState({ code: e.target.value })}
                onPressEnter={this.confirmCode}
                className="mt20"
              />
              <Button
                htmlType="submit"
                type="primary"
                className="auth-button mt16"
                onClick={this.confirmCode}
                disabled={this.state.code.length !== 6}
              >
                Verify Token
              </Button>
            </div>
          }
        />
      </div>
    )
  }
}

const mapStateToProps = () => ({})
const mapDispatchToProps = {
  verify2FAChallenge,
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuthChallenge)

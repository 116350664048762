/* eslint-disable @typescript-eslint/no-explicit-any */
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import cookies from 'js-cookie'
import { capitalize } from 'lodash'
import { ONBOARDING_URL_QUERY_LIBRARY, ONBOARDING_URL_RESOURCES } from 'retoolConstants'

function guessName(path: any) {
  const names = path.split('/').filter((s: any) => s !== '')

  if (names.length === 0) {
    return 'Home'
  }
  switch (names[0]) {
    case 'settings': {
      if (names.length > 1) {
        if (names[1] === 'permissions') {
          return 'Settings - Manage Users'
        }
        return `Settings - ${capitalize(names[1])}`
      }
      return 'Settings'
    }
    case 'folders': {
      return 'Folder'
    }
    case 'editor': {
      return 'Editor'
    }
    case 'presentation': {
      return 'Presentation'
    }
    case 'datasources': {
      return 'Datasources'
    }
    case 'audit': {
      return 'Audit Logs'
    }
    case 'embedded': {
      return 'Public App'
    }
    case 'pwa': {
      return 'Public App (PWA)'
    }
    case 'setup': {
      return 'Setup'
    }
    case 'querylibrary':
    case 'queryplayground':
      return 'Query Library'

    case 'auth': {
      if (names[1] === 'login') {
        return 'Login'
      } else if (names[1] === 'signup') {
        return 'Signup'
      } else if (names[1] === 'resetPassword') {
        return 'Reset Password'
      } else if (names[1] === 'confirmResetPassword') {
        return 'Confirm Reset Password'
      } else if (names[1] === 'invite') {
        return 'Invite'
      }
      return 'Authentication'
    }
    case 'resources': {
      if (names.length > 1) {
        if (names[1] === 'new') {
          if (names[2]) {
            return `Resources - New - ${capitalize(names[2])}`
          }
          return 'Resources - New'
        }
        return 'Resources - Edit'
      }
      return 'Resources'
    }
    case 'flows': {
      return 'Flows'
    }
    case 'clone-template': {
      if (names.length > 1) {
        return `Clone Template - ${names[1]}`
      }
    }
  }
  return name
}

const trackPageview = (location: any) => {
  const strippedLocation = {
    ...location,
    query: JSON.stringify(location.query),
  }
  const properties = {
    name: guessName(location.pathname),
    url: decodeURIComponent(location.pathname),
    location: strippedLocation,
  }
  if (location.pathname.split('/').filter((s: string) => s !== '')[0] === 'resources') {
    const onboardingUrl = cookies.get('onboardingUrl')
    Object.assign(properties, {
      isResourceOnboarding: onboardingUrl === ONBOARDING_URL_RESOURCES,
      isQueryLibraryOnboarding: onboardingUrl === ONBOARDING_URL_QUERY_LIBRARY,
    })
  }
  retoolAnalyticsTrack('Page Viewed', properties)
}

export default trackPageview

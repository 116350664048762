// to be kept in sync with retool-colors.scss
export const retoolGrey = '#bbbbbb'
export const retoolMediumGrey = '#d9d9d9'
export const retoolLightGrey = '#f4f4f4'
export const retoolMediumDarkGrey = '#979797'
export const retoolDarkGrey = '#7a7a7a'
export const retoolPinkishGrey = '#d1cfcf'
export const retoolAzure = '#3c92dc'
export const white = '#ffffff'
export const retoolRed = '#f9354c'
export const retoolUnfocusedGrey = 'rgba(56, 56, 56, 0.3)'
export const retoolBoxShadowGrey = 'rgba(217, 217, 217, 0.5)'
export const retoolDividerGrey = '#d2d1d1'
export const retoolFocusedGrey = '#353535'
export const retoolGreen = '#26bf6a'
export const retoolBlack = '#312929'
export const retoolBackgroundGrey = '#e1e8ed'
export const retoolSectionHeaderGrey = '#ece6e6'
export const retoolEditorGrey = '#f6f9fc'
export const retoolEditorBorderGrey = '#e3e3e3'
export const retoolEditorBoxShadow = 'rgba(234, 234, 234, 0.5)'
export const retoolEditorLabelGrey = '#484444'
export const retoolInputActiveBorder = '#47c2ff'
export const retoolCanvasBackground = 'rgb(248,248,249)'
export const retoolOrangeBackground = '#f56a00'

export const colors = {
  white: '#ffffff',
  black: '#000000',
  nearWhite: '#f5f5f5',
  nearBlack: '#262626',
  darkGray: '#3d3d3d',
  mediumGray: '#555555',
  gray: '#6a6a6a',
  lightGray: '#979797',
  lightestGray: '#b3b3b3',
  lighterGray: '#c7c7c7',
  washedGray: '#dedede',
  faintGray: '#ededed',
  fainterGray: '#fafafa',
  purple: '#6976d9',
  darkBlue: '#247bc7',
  blue: '#3c92dc',
  lightBlue: '#4aa1ed',
  lightestBlue: '#85c1f4',
  washedBlue: '#daecfc',
  faintBlue: '#ecf5fd',
  fainterBlue: '#f7fafc',
  darkRed: '#c04035',
  red: '#cc5248',
  lightRed: '#d96459',
  lightestRed: '#e07f76',
  washedRed: '#f7d6d4',
  faintRed: '#fdf3f2',
  darkGreen: '#3b734f',
  green: '#478b60',
  lightGreen: '#55a874',
  lightestGreen: '#82bf99',
  washedGreen: '#aed6bd',
  faintGreen: '#daece1',
  darkYellow: '#d69d10',
  yellow: '#e9ab11',
  lightYellow: '#f2bd3b',
  lightestYellow: '#eeca86',
  washedYellow: '#f6e5c4',
  faintYellow: '#fcf5e9',
  transparent: 'transparent',

  // values from v2/variables.scss
  blue60: 'hsl(208, 70%, 55%)',
  green60: 'hsl(141, 20%, 55%)',
  yellow60: 'hsl(36, 90%, 55%)',
  red60: 'hsl(5, 56%, 55%)',
}

export const defaultColors = [
  retoolAzure,
  '#d6757f',
  '#cc5248',
  '#478b60',
  '#5b9dab',
  '#555555',
  '#e9ab11',
  '#d87b1f',
  '#9C76C1',
]

export const chartColors = [
  '#033663',
  '#247BC7',
  '#55A1E3',
  '#DAECFC',
  '#EECA86',
  '#E9AB11',
  '#D47E2F',
  '#C15627',
  '#224930',
  '#238146',
  '#55A874',
  '#AED6BD',
  '#E3D7FF',
  '#BCAAE7',
  '#7E63C2',
  '#4A3480',
]

import platform from 'platform'
import { Platform, ShortcutManager } from 'react-shortcuts'

const TOGGLE_MODEL_BROWSER = 'mod+b'
const TOGGLE_PRESENTATION_MODE = 'mod+shift+p'
const TOGGLE_DEBUGGER = 'ctrl+`'

const shortcuts = {
  QUERY_EDITOR: {
    PREVIEW_QUERY: 'mod+enter',
    REFORMAT_QUERY: 'mod+l',
    SAVE_QUERY: 'mod+s',
    DUPLICATE_QUERY: 'mod+d',
  },
  PLAYGROUND: {
    RUN_QUERY: 'mod+enter',
    SAVE_QUERY: 'mod+s',
  },
  EDITOR_CANVAS: {
    MOVE_COMPONENT_UP: 'up',
    MOVE_COMPONENT_RIGHT: 'right',
    MOVE_COMPONENT_DOWN: 'down',
    MOVE_COMPONENT_LEFT: 'left',
    RESIZE_COMPONENT_UP: 'mod+up',
    RESIZE_COMPONENT_RIGHT: 'mod+right',
    RESIZE_COMPONENT_DOWN: 'mod+down',
    RESIZE_COMPONENT_LEFT: 'mod+left',
    COPY_COMPONENT: 'mod+c',
    CUT_COMPONENT: 'mod+x',
    PASTE_COMPONENT: 'mod+v',
    SAVE_PAGE: 'mod+s',

    // TODO(ajpaon): Can we get rid of this since we have ctrl+shift+p?
    PRESENT_PAGE: TOGGLE_PRESENTATION_MODE,
    DESELECT_COMPONENT: 'esc',
    DELETE_COMPONENT: ['del', 'backspace'],
    SELECT_ALL: 'mod+a',
  },
  GLOBAL_EDITOR: {
    SEND_DEBUG_INFO: 'mod+i',
    REDO: 'mod+shift+z',
    UNDO: 'mod+z',
    TOGGLE_GRID: 'mod+shift+g',
    TOGGLE_VIM_MODE: 'ctrl+alt+v',
    TOGGLE_PRESENTATION_MODE,
    TOGGLE_MODEL_BROWSER,
    TOGGLE_DEBUGGER,
  },
  EDITOR: {
    SAVE_PAGE: 'mod+s',
    PRESENT_PAGE: TOGGLE_PRESENTATION_MODE,
    FOCUS_QUERY_SEARCH: 'mod+o',
  },
  OMNIBOX: {
    TOGGLE: 'mod+k',
    HIDE: 'esc',
  },
  GLOBAL_PUBLIC: {},
  GLOBAL_PRESENTATION: {
    TOGGLE_PRESENTATION_MODE,
    TOGGLE_DEBUGGER,
  },
  MISC: {
    // NOTE: this is here for formatting reasons but is not actually
    // implemented using react-shortcuts. Possible to unify?
    AUTOCOMPLETE: 'ctrl+space',
  },
  TABLE_COMPONENT: {
    SELECT_NEXT_ROW: ['down', 'j', 'ctrl+n'],
    SELECT_PREV_ROW: ['up', 'k', 'ctrl+p'],
  },
}

export type ShortcutName<T extends keyof typeof shortcuts> = keyof typeof shortcuts[T]

export function currentPlatform(): Platform {
  if (!platform.os || !platform.os.family) return 'other'
  switch (platform.os.family.toLowerCase().replace(' ', '')) {
    case 'windows':
      return 'windows'
    case 'osx':
      return 'osx'
    case 'linux':
      return 'linux'
    default:
      return 'other'
  }
}

export function formatShortcutForPlatform(shortcut: string, platform: Platform): string {
  return shortcut
    .replace('mod+', platform === 'osx' ? ' ⌘' : ' ⌃')
    .replace('command+', ' ⌘')
    .replace('ctrl+', ' ⌃')
    .replace('shift+', '⇧')
    .replace('enter', '\u23CE')
    .replace('alt+', platform === 'osx' ? '⌥' : '⎇')
    .replace('del', 'del')
    .replace('backspace', '⌫')
    .replace('up', '↑')
    .replace('right', '→')
    .replace('down', '↓')
    .replace('left', '←')
    .replace('esc', 'esc')
    .toUpperCase()
}

// TODO(ajpaon) Move this and related functions to the keys display component.
export function shortcutFormatter<N extends keyof typeof shortcuts, A extends keyof typeof shortcuts[N]>(
  namespace: N,
  action: A,
): string {
  const actionObj = shortcuts[namespace]?.[action]
  const plat = currentPlatform()

  if (typeof actionObj === 'string') {
    return formatShortcutForPlatform(actionObj, plat)
  } else if (Array.isArray(actionObj)) {
    return formatShortcutForPlatform(actionObj[0], plat)
  } else {
    // "this should never happen"
    // TODO: Figure out how to get typescript to help with this.
    return ''
  }
}

export const shortcutManager = new ShortcutManager(shortcuts)

export const extendDefaultShortcuts = (customShortcuts: { shortcut: string; action: string }[]): void => {
  const formattedCustomShortcuts = customShortcuts.reduce((acc: { [s: string]: string }, customShortcut, index) => {
    const shortcutName = `RETOOL_SHORTCUT_${index}`
    acc[shortcutName] = customShortcut.shortcut
    return acc
  }, {})
  const newKeyMap = {
    ...shortcuts,
    GLOBAL_EDITOR: {
      ...shortcuts.GLOBAL_EDITOR,
      ...formattedCustomShortcuts,
    },
    GLOBAL_PRESENTATION: {
      ...shortcuts.GLOBAL_PRESENTATION,
      ...formattedCustomShortcuts,
    },
    GLOBAL_PUBLIC: {
      ...shortcuts.GLOBAL_PUBLIC,
      ...formattedCustomShortcuts,
    },
  }
  shortcutManager.setKeymap(newKeyMap)
}

export default shortcuts

import { copyToClipboard } from 'common/utils'
import Message from 'components/design-system/Message'

const truncateDisplayValue = (value: string) => {
  let slice = value.slice(0, 64)
  if (slice.length !== value.length) {
    slice += ' ...'
  }

  return slice
}

export const copyValueToClipboard = (value: string) => {
  let stringValue = null

  try {
    if (typeof value === 'string') {
      stringValue = value
    } else {
      stringValue = JSON.stringify(value)
    }
  } catch (e) {
    if (e instanceof TypeError) {
      throw Error('Copy to clipboard failed probably due to serializing circular references.')
    } else {
      throw Error(`There was an error when serializing value to json: ${e.message}`)
    }
  }

  if (value != null && stringValue != null) {
    copyToClipboard(stringValue)
    Message.success(`Copied ${truncateDisplayValue(stringValue)} to clipboard`)
  } else {
    throw Error(`Copy to clipboard failed because a ${String(value)} value cannot be copied.`)
  }
}

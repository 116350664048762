import { message } from 'antd'
import * as evaluator from 'common/evaluator'
import {
  EditorModel,
  PlaygroundQuery,
  PlaygroundQuerySave,
  PluginTemplate,
  Position2,
  Position2Diff,
  PositionKey,
  Preview,
  QueryEditorTabType,
  QueryTemplateShape,
  recordTransit,
  QueryTemplate,
  PlaygroundQuerySaveId,
  QueryTemplateType,
  QueryEditorType,
} from 'common/records'
import { ANY_TEMPLATE_REGEX } from 'common/regexes'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { formatSqlWithTemplate } from 'common/sqlFormatter'
import { formatList, ss } from 'common/utils'
import { TemplateResolver, UnknownModel } from 'components/plugins'
import queryModels from 'components/plugins/datasources/index'
import { QUERIES_WITH_METADATA } from 'components/plugins/datasources/modelConstants'
import getChildWidgetIds from 'components/plugins/widgets/common/layoutUtils/getChildWidgetIds'
import {
  getLeftMostColBottomMostRow,
  getPasteBounds,
  getPositionBelowSelection,
  getPositionContainerSelected,
  getPositionFrameSelected,
  selectedWidgetsForClipboardSelector,
} from 'components/plugins/widgets/common/layoutUtils/getNewPositions'
import { assertValidWidgetParent } from 'components/plugins/widgets/common/layoutUtils/isWidgetParentValid'
import widgetTypeIsContainer, {
  widgetTypeIsModalLike,
} from 'components/plugins/widgets/common/layoutUtils/widgetTypeIsContainer'
import LayoutManager from 'components/plugins/widgets/PushLayoutManager2'
import Immutable from 'immutable'
import ls from 'local-storage'
import _ from 'lodash'
import moment from 'moment'
import { JSON_HEADERS } from 'networking/util'
import { getState, RetoolAction, RetoolDispatch, RetoolState, RetoolActionDispatcher } from 'store'
import { MODEL_BROWSER_CLOSE, MODEL_BROWSER_OPEN, RECEIVE_SAVE, REQUEST_SAVE } from 'store/appModel/actionTypes'
import { DATASOURCE_TYPE_CHANGE, PLUGIN_DELETE, PLUGIN_UPDATE_ID } from 'store/appModel/constants'
import { computeTemplateStringDependencies } from 'store/appModel/dependencyGraph'
import { calculateNewTemplate, triggerModelUpdate } from 'store/appModel/model'
import { RECEIVE_PAGE_LOAD } from 'store/appModel/pages'

import { getExperimentValue } from 'common/utils/experiments'

import {
  DATASOURCE_TEMPLATE_CREATE,
  ENABLE_CUSTOM_DOCUMENT_TITLE,
  FOLDER_CREATE,
  FUNCTION_TEMPLATE_CREATE,
  INSTRUMENT_TEMPLATE_CREATE,
  MIGRATIONS_SUCCESS,
  MIGRATIONS_UP_TO_DATE,
  PAGE_LOAD_VALUE_OVERRIDE_UPDATE,
  PLUGIN_FOLDER_CHANGE,
  RECEIVE_RESTORE,
  REORDER_QUERIES,
  REORDER_TABBED_CONTAINER,
  RESTORE_LOADED,
  SET_CUSTOM_DOCUMENT_TITLE,
  SET_CUSTOM_SHORTCUTS,
  URL_FRAGMENT_DEF_UPDATE,
  WIDGET_REPOSITION2,
  WIDGET_TEMPLATE_CREATE,
  WIDGET_PASTE_START,
  WIDGET_PASTE_FINISH,
  applyMoves,
  datasourceCreate,
  exportSave,
  functionCreate,
  pluginDelete,
  widgetCreate,
  FRAME_TEMPLATE_CREATE,
  datasourceTypeChange,
} from 'store/appModel/template'
import { batchUndoGroup } from 'store/appModel/batchUndoGroupBy'
import { WIDGET_TEMPLATE_UPDATE, widgetTemplateUpdate } from 'store/appModel/templateUtils'
import { FAILURE_RESOURCES, RECEIVE_RESOURCE, REQUEST_RESOURCES } from 'store/constants'
import { UNTRANSFORMED_QUERY_RESPONSE_UPDATE } from 'store/editor'
import {
  appSpecificTemplateValuesFilter,
  getPlaygroundQuerySaves,
  getResourceForQuery,
  resourceSpecificTemplateValuesFilter,
  savedQueriesFailureActionCreator,
  savedQueriesRequestActionCreator,
  savedQueriesSuccessActionCreator,
  sortAndFormatSavedQueries,
  createQuery,
} from 'store/playground'
import {
  appTemplateSelector,
  appModelValuesSelector,
  editorResourcesSelector,
  environmentSelector,
  isTemplateGlobalWidgetSelector,
  playgroundQuerySavesSelector,
  playgroundSavedOrganizationQueryIdsSelector,
  playgroundSavedQueriesSelector,
  queryChangedSelector,
  queryStateSelector,
  selectedDatasourceSelector,
  selectedFunctionChangedSelector,
  selectedResourceSelector,
  playgroundSavedUserQueryIdsSelector,
  selectedDatasourceIdSelector,
} from 'store/selectors'
import { positionKeySelector, targetContainerKeySelector, widgetsSelector } from 'store/selectors/widgetSelectors'
import { RetoolAPIDispatcher } from 'store/storeUtils'
import { isString } from 'types/typeguards'
import { showConfirm } from '../../../components/standards/Modal'
import { callInternalApi } from '../../../networking'
import { callApi } from '../../../store/callApi'
import { deletePluginWithDialog } from './dialogs'
import { ActionTypes } from 'redux-undo'
import formatJS from './jsFormatter'

const { parse, print } = require('graphql')

// ------------------------------------
// Constants
// ------------------------------------
export const QUERY_STATE_DATASOURCE_TYPE_CHANGE = 'QUERY_STATE_DATASOURCE_TYPE_CHANGE'
export const CONVERT_SELECTED_QUERY_TO_LOCAL_QUERY = 'CONVERT_SELECTED_QUERY_TO_LOCAL_QUERY'
export const WIDGET_SELECT = 'WIDGET_SELECT'
export const WIDGET_SELECT_ALL = 'WIDGET_SELECT_ALL'
export const WIDGET_COPY = 'WIDGET_COPY'
export const WIDGET_MULTISELECT = 'WIDGET_MULTISELECT'
export const FUNCTION_SELECT = 'FUNCTION_SELECT'
export const INSTRUMENT_SELECT = 'INSTRUMENT_SELECT'
export const INSTRUMENT_CHANGED = 'INSTRUMENT_CHANGED'
export const STATE_SELECT = 'STATE_SELECT'
export const FRAME_SELECT = 'FRAME_SELECT'
export const DATASOURCE_SELECT = 'DATASOURCE_SELECT'
export const QUERY_EDITOR_UPDATE = 'QUERY_EDITOR_UPDATE'
export const QUERY_RUN_FAIL = 'QUERY_RUN_FAIL'
const QUERY_TEMPLATE_RESET = 'QUERY_TEMPLATE_RESET'

export const SELECT_IMPORTED_QUERY = 'QUERY_EDITOR_SELECT_IMPORTED_QUERY'

export const REQUEST_QUERY_PREVIEW = 'REQUEST_QUERY_PREVIEW'
export const RECEIVE_QUERY_PREVIEW = 'RECEIVE_QUERY_PREVIEW'
export const FAILURE_QUERY_PREVIEW = 'FAILURE_QUERY_PREVIEW'

export const SCHEMA_VIEWER_OPEN = 'SCHEMA_VIEWER_OPEN'
export const SCHEMA_VIEWER_CLOSE = 'SCHEMA_VIEWER_CLOSE'

export const QUERY_EDITOR_CLOSE = 'QUERY_EDITOR_CLOSE'
export const QUERY_EDITOR_OPEN = 'QUERY_EDITOR_OPEN'

export const QUERY_EDITOR_SELECT_TAB = 'QUERY_EDITOR_SELECT_TAB'
export const EDITOR_SIDEBAR_SELECT_TAB = 'EDITOR_SIDEBAR_SELECT_TAB'

export const DEBUGGER_OPEN = 'DEBUGGER_OPEN'
export const DEBUGGER_CLOSE = 'DEBUGGER_CLOSE'

export const WIDGET_PICKER_OPEN = 'WIDGET_PICKER_OPEN'
export const WIDGET_PICKER_CLOSE = 'WIDGET_PICKER_CLOSE'

export const QUERY_EDITOR_HEIGHT_CHANGE = 'QUERY_EDITOR_HEIGHT_CHANGE'

export const PREVIEW_CLOSE = 'PREVIEW_CLOSE'
export const PREVIEW_OPEN = 'PREVIEW_OPEN'

export const GRID_OPEN = 'GRID_OPEN'
export const GRID_CLOSE = 'GRID_CLOSE'

export const APP_STYLES_DISABLE = 'APP_STYLES_DISABLE'
export const APP_STYLES_ENABLE = 'APP_STYLES_ENABLE'

export const UPADTE_LOADING = 'UPADTE_LOADING'

export const QUERY_EDITOR_REST_PARAM_UPDATE = 'QUERY_EDITOR_REST_PARAM_UPDATE'

export const REQUEST_RESOURCE_SCHEMA = 'REQUEST_RESOURCE_SCHEMA'
export const RECEIVE_RESOURCE_SCHEMA = 'RECEIVE_RESOURCE_SCHEMA'
export const FAILURE_RESOURCE_SCHEMA = 'FAILURE_RESOURCE_SCHEMA'

export const UPDATE_COLLECTION_SCHEMA = 'UPDATE_COLLECTION_SCHEMA'

export const REFORMAT_QUERY = 'REFORMAT_QUERY'

export const CLEAR_EDITOR = 'CLEAR_EDITOR'

export const ADD_RECORD_TO_SCHEMA = 'ADD_RECORD_TO_SCHEMA'

export const SET_POPOUT_CODE_EDITOR = 'SET_POPOUT_CODE_EDITOR'

const MODELBROWSER_EXPAND_PLUGIN_ID = 'MODELBROWSER_EXPAND_PLUGIN_ID'
const UNUSED_ACTION = 'UNUSED_ACTION'
const WIDGET_MANAGER_TOGGLE = 'WIDGET_MANAGER_TOGGLE'

export const HIDE_LAST_VERSION_UPDATE_WARNING = 'HIDE_LAST_VERSION_UPDATE_WARNING'

export enum SEND_DEBUG_INFO {
  REQUEST = 'REQUEST_SEND_DEBUG_INFO',
  SUCCESS = 'RECEIVE_SEND_DEBUG_INFO',
  FAILURE = 'SEND_DEBUG_INFO_FAILURE',
}

function getFirstDatasource(template: any) {
  return template.plugins.filter((p: any) => p.type === 'datasource').first()
}

// ------------------------------------
// Actions
// ------------------------------------
export function widgetSelect(widgetId: string) {
  return {
    type: WIDGET_SELECT,
    payload: { widgetId },
  }
}

export function widgetSelectAll() {
  return (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const state = getState()
    const widgets = widgetsSelector(state)
    const positionKey = positionKeySelector(state)
    const containerKey = targetContainerKeySelector(state)

    const widgetIds = widgets
      .filter((widget) => widget[positionKey]?.getContainerKey() === containerKey)
      .map(({ id }) => id)
      .valueSeq()
      .toArray()

    dispatch({
      type: WIDGET_SELECT_ALL,
      payload: { widgetIds },
    })
  }
}

export function setPopoutCodeEditor(editorInfo: EditorModel['currentPopoutCodeEditor']) {
  return {
    type: SET_POPOUT_CODE_EDITOR,
    payload: editorInfo,
  }
}

export function expandPluginIdInModelBrowser(pluginId: string) {
  return { type: MODELBROWSER_EXPAND_PLUGIN_ID, pluginId }
}

export function sendDebugInfo() {
  return async (dispatch: any, getState: () => RetoolState) => {
    const { appModel, appTemplate } = getState()

    const pageName = getState().pages.get('pageName')
    const pageUuid = getState().pages.get('pageUuid')
    const isGlobalWidget = appTemplate.present.isGlobalWidget
    const pageSave = await dispatch(exportSave(pageName, pageUuid, false, isGlobalWidget))

    await dispatch(
      callApi({
        endpoint: `/api/debugInfo`,
        method: 'POST',
        body: JSON.stringify({
          appModel,
          pageSave,
        }),
        headers: JSON_HEADERS,
        types: [SEND_DEBUG_INFO.REQUEST, SEND_DEBUG_INFO.SUCCESS, SEND_DEBUG_INFO.FAILURE],
      }),
    )

    message.success('Sent debug info to the Retool team!')
  }
}

export function deleteSelectedWidgets() {
  return (dispatch: any, getState: () => RetoolState) => {
    const editor: EditorModel = getState().editor
    const selectedWidgets = editor.selectedWidgets.toJS()
    //Currently cascading delete only works for widgets with 1 id, so bypass if we have more than one
    if (selectedWidgets.length === 1) {
      dispatch(deletePluginWithDialog(selectedWidgets[0]))
    } else {
      showConfirm({
        title: `Are you sure you want to delete ${formatList(getState().editor.selectedWidgets.toArray())}`,
        onOk: () => {
          dispatch(pluginDelete(selectedWidgets))
        },
        onCancel: () => {},
      })
    }
  }
}

export function copyComponent() {
  return (dispatch: any, getState: () => RetoolState) => {
    const state = getState()
    const editor: EditorModel = state.editor
    const selectedWidgets = editor.selectedWidgets
    const positionKey = positionKeySelector(state)
    if (selectedWidgets.size === 0) {
      return message.warning('Select at least one component to copy!')
    } else {
      const widgets = selectedWidgetsForClipboardSelector(state)

      ls.set('retool:componentClipboard', recordTransit.toJSON(widgets))
      ls.set('retool:componentClipboardPositionKey', positionKey)
      retoolAnalyticsTrack('Copied Component', {})

      dispatch({
        type: WIDGET_COPY,
        payload: selectedWidgets,
      })
    }
  }
}

export function cutComponent() {
  return (dispatch: any, getState: () => RetoolState) => {
    const state = getState()
    const editor: EditorModel = state.editor
    const selectedWidgets = editor.selectedWidgets
    const positionKey = positionKeySelector(state)
    if (selectedWidgets.size === 0) {
      return message.warning('Select at least one component to cut!')
    } else if (selectedWidgets.size > 1) {
      return message.warning('Retool currently does not support cutting more than one component at a time.')
    } else {
      const selectedWidgetId = selectedWidgets.first<string>()
      const widgetIds = getChildWidgetIds(selectedWidgetId, state)
      const widgets = selectedWidgetsForClipboardSelector(state)

      ls.set('retool:componentClipboard', recordTransit.toJSON(widgets))
      ls.set('retool:componentClipboardPositionKey', positionKey)
      dispatch(pluginDelete(widgetIds.toArray(), false))

      message.success(`Cut component ${selectedWidgetId}.`)
      retoolAnalyticsTrack('Cut Component', {})
    }
  }
}

/**
 * returns true if there were selected widgets to move
 * (so we know whether or not to event.preventDefault())
 */
export function moveComponent(diff: Position2Diff, layoutManager: LayoutManager) {
  return (dispatch: any, getState: () => RetoolState) => {
    const editor: EditorModel = getState().editor
    const selectedWidgets = editor.selectedWidgets
    const widgetCount = selectedWidgets.size
    if (widgetCount === 0) return

    const resizing = 'width' in diff || 'height' in diff
    if (resizing && widgetCount > 1) return

    const widgetIds = Array.from(selectedWidgets)
    const movePreview = layoutManager.previewWidgetMove({ widgetIds, move: diff, moveType: 'keyboard' })
    if (!movePreview) return

    const { move, fixes } = movePreview
    dispatch(applyMoves([move, ...fixes]))

    retoolAnalyticsTrack('Shortcut Used', {
      type: 'moveComponent',
      numSelectedWidgets: selectedWidgets.size,
    })

    return true
  }
}

export function queryStateDatasourceTypeChange(pluginId: any, resourceName: any, newType: any, oldType: any) {
  return {
    type: QUERY_STATE_DATASOURCE_TYPE_CHANGE,
    payload: { pluginId, resourceName, newType, oldType },
  }
}

export function pasteComponent(layoutManager: LayoutManager) {
  return batchUndoGroup(async (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    // await to allow the pasting spinner to render before we continue
    await dispatch({
      type: WIDGET_PASTE_START,
    })

    // this allows time for the pasting spinner to render
    await new Promise((resolve) => {
      setTimeout(resolve, 0)
    })

    const state = getState()
    const { plugins } = appTemplateSelector(state)
    const inModule = isTemplateGlobalWidgetSelector(state)
    const serializedComponents = ls.get('retool:componentClipboard')
    const copiedPositionKey: PositionKey = ls.get('retool:componentClipboardPositionKey')
    let widgets
    // Widgets is an ordered Map
    // Key: string of parent component id. Value: list of PluginTemplates (itself & child components)

    try {
      widgets = recordTransit.fromJSON(serializedComponents)
    } catch (err) {
      // the pasted string probably wasn't a serialized component, so ignore
      return
    }

    const editor: EditorModel = state.editor
    const selectedWidgets = editor.selectedWidgets
    const selectedFrameId = editor.selectedFrameId
    const positionKey = positionKeySelector(state)
    const multiWidgetsToPaste = widgets.size > 1
    let positionDiff: Position2Diff

    const createWidgetInCanvas = async (widget: PluginTemplate, positionDiff: Position2Diff) => {
      const position = widget[copiedPositionKey]
      if (!position) {
        return
      }

      const newPosition = position.merge(positionDiff).toJS()
      return await dispatch(
        widgetCreate({
          defaultId: widget.id,
          defaultTemplate: widget.template,
          position: newPosition,
          widgetType: widget.subtype,
        }),
      )
    }

    const parentWidgets: Array<PluginTemplate> = []
    widgets.forEach((pluginTemplates: Immutable.List<PluginTemplate>) => {
      const parentPluginTemplate = pluginTemplates.get(0)
      // make TS happy
      if (parentPluginTemplate) {
        parentWidgets.push(parentPluginTemplate)
      }
    })

    const pasteBounds = getPasteBounds(parentWidgets, copiedPositionKey)

    // Shift all the components below the selectedWidget(s) down in order to paste. This only applies if there is a widget selection.
    // If frame/nothing is selected, we already paste below the most bottom component, so there is nothing to shift down.
    if (selectedWidgets.size) {
      const selectedWidgetId = selectedWidgets.first<string>()
      const widgetType = plugins.getIn([selectedWidgetId, 'subtype'])
      const isContainer = widgetTypeIsContainer(widgetType)
      const model = appModelValuesSelector(state).getPlugin(selectedWidgetId) as UnknownModel
      const isClosedModal = widgetTypeIsModalLike(widgetType) && !model.get('opened')

      const shiftDown =
        selectedWidgets.size > 1 ||
        !isContainer ||
        isClosedModal ||
        (parentWidgets[0].id === selectedWidgetId && !multiWidgetsToPaste)

      if (shiftDown) {
        const appTemplate = appTemplateSelector(state)
        const selectedWidgetsWithPositions = selectedWidgets
          .toArray()
          .map((selectedWidgetId: string) => appTemplate.getIn(['plugins', selectedWidgetId]))
        const selectedWidget = selectedWidgetsWithPositions[0]
        const selectedColRow = getLeftMostColBottomMostRow(selectedWidgetsWithPositions, positionKey)
        const colDiff = Math.min(selectedColRow.col - pasteBounds.left, 12 - pasteBounds.right)

        const pasteBoundsPosition = new Position2({
          container: selectedWidget[positionKey].container,
          subcontainer: selectedWidget[positionKey].subcontainer,
          row: selectedColRow.row,
          col: pasteBounds.left + colDiff,
          height: pasteBounds.bottom - pasteBounds.top,
          width: pasteBounds.right - pasteBounds.left,
          tabNum: selectedWidget[positionKey].tabNum,
        })

        const result = layoutManager.previewWidgetCreate(
          {
            widgetType: '',
            position: pasteBoundsPosition,
          },
          { skipClamping: true },
        )
        if (result) {
          await dispatch(applyMoves(result.fixes))
        }
      }
    }

    const pastedWidgetIdsDict: { [key: string]: string } = {}
    const pastedWidgetIds: Array<string> = []
    const widgetsToPaste = widgets.valueSeq().toArray()

    for (const widgetPluginTemplates of widgetsToPaste) {
      // widgetPluginTemplates is a list of the pluginTemplates with the first element being the parent
      // If widget has children, the remaining elements are its children
      const widget = widgetPluginTemplates.get(0)
      if (selectedWidgets.size) {
        const selectedWidgetId = selectedWidgets.first<string>()
        const widgetType = plugins.getIn([selectedWidgetId, 'subtype'])
        const isContainer = widgetTypeIsContainer(widgetType)

        // TODO: 1) refactor positionDiff to be the shared diff of the copied widget position
        // Currently it is the final position of the widget to be pasted
        // 2) Simplify positionDiff helper functions into one single getPastePositionDiff method
        if (isContainer && selectedWidgets.size === 1) {
          positionDiff = getPositionContainerSelected(
            widget,
            selectedWidgetId,
            inModule,
            plugins,
            positionKey,
            state,
            pasteBounds,
            multiWidgetsToPaste,
          )
        } else {
          positionDiff = getPositionBelowSelection(widget, selectedWidgets, state, positionKey, pasteBounds)
        }
      } else {
        positionDiff = getPositionFrameSelected(widget, selectedFrameId, inModule, plugins, positionKey, pasteBounds)
      }

      assertValidWidgetParent(
        parentWidgets.map((w: PluginTemplate) => w.get('subtype')),
        state.appModel.pluginType(positionDiff.container || ''),
      )

      const createdWidgetId = await createWidgetInCanvas(widget, positionDiff)

      // this maps the old widget id to the new widget id, e.g.
      // `container1` --> `container5`
      // we use it to put the pasted child widgets into their newly pasted parents
      if (createdWidgetId) {
        pastedWidgetIdsDict[widget.id] = createdWidgetId
        pastedWidgetIds.push(createdWidgetId)
      }

      // check if widget has children. The first element will be the parent (original widgetId provided) and not a child.
      const childWidgets = widgetPluginTemplates.shift()
      for (const childWidget of childWidgets) {
        const position = childWidget[copiedPositionKey]
        if (!position) {
          return
        }

        // TODO: add skipSelect option to widgetCreate since we manually select all the created widgets at the end
        const createdWidgetId = await dispatch(
          widgetCreate({
            defaultId: childWidget.id,
            defaultTemplate: childWidget.template,
            position: position
              ?.merge({
                container: pastedWidgetIdsDict[position.container],
              })
              .toJS(),
            widgetType: childWidget.subtype,
          }),
        )
        pastedWidgetIdsDict[childWidget.id] = createdWidgetId
      }
    }

    dispatch({
      type: WIDGET_SELECT_ALL,
      payload: { widgetIds: pastedWidgetIds },
    })

    dispatch({
      type: WIDGET_PASTE_FINISH,
    })

    retoolAnalyticsTrack('Pasted Component', {
      numPastedWidgets: Object.keys(pastedWidgetIdsDict).length,
    })
  })
}

export const setSelectedFunctionChanged = (isChanged: boolean) => {
  return {
    type: 'TRANSFORMER_CHANGED',
    payload: isChanged,
  }
}

export const setSelectedInstrumentChanged = (isChanged: boolean) => {
  return {
    type: INSTRUMENT_CHANGED,
    payload: isChanged,
  }
}

export function instrumentSelect(instrumentId: string) {
  return {
    type: INSTRUMENT_SELECT,
    payload: { instrumentId },
  }
}

export function functionSelect(functionId: string) {
  return {
    type: FUNCTION_SELECT,
    payload: { functionId },
  }
}

export function stateSelect(stateId: string) {
  return {
    type: STATE_SELECT,
    payload: { stateId },
  }
}

export function stateReset() {
  return (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const state = getState()
    const { plugins } = appTemplateSelector(state)
    const statePlugins = plugins.filter((plugin) => plugin.type === 'state')

    return statePlugins.map((plugin) => {
      // We set isUserTriggered to true b/c the app template is not actually
      // changing, and so we should not allow undo.
      dispatch(
        widgetTemplateUpdate(plugin.id, { value: plugin.getIn(['template', 'value']) }, undefined, {
          isUserTriggered: false,
        }),
      )
    })
  }
}

export function frameSelect(frameId: string) {
  return async (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const { plugins } = appTemplateSelector(getState())

    if (!plugins.has(frameId)) {
      // create the frame if it doesn't exist
      const makeTemplate = TemplateResolver('Frame')
      const template = new PluginTemplate({
        id: frameId,
        type: 'frame',
        subtype: 'Frame',
        template: makeTemplate({
          type: frameId.replace('$', ''),
          sticky: frameId === '$header',
        }),
        style: Immutable.Map({}),
      })

      dispatch({ type: FRAME_TEMPLATE_CREATE, payload: { template } })
      await dispatch(calculateNewTemplate(template))
    }

    return dispatch({
      type: FRAME_SELECT,
      payload: { frameId },
    })
  }
}

export const duplicateQuery = (pluginId: string) => (dispatch: any, getState: () => RetoolState) => {
  const appTemplate = appTemplateSelector(getState())
  const resources = editorResourcesSelector(getState())

  const datasource = appTemplate.plugins.get(pluginId)
  if (!datasource) {
    throw new Error('Query could not be duplicated - the query has been renamed or deleted')
  }
  const resourceName = datasource.resourceName
  const resource = _.find(resources, (r) => r.value === resourceName)
  if (!resource) {
    throw new Error('Query could not be duplicated - the resource it uses has been deleted.')
  }
  return dispatch(
    datasourceCreate(
      resourceName,
      resource.editorType,
      undefined,
      datasource.template,
      undefined,
      datasource.get('folder'),
    ),
  )
}

export const duplicateFunction = (pluginId: string) => (dispatch: any, getState: () => RetoolState) => {
  const { plugins } = appTemplateSelector(getState())

  const funcToCopy = plugins.get(pluginId)
  if (!funcToCopy) {
    throw new Error('Transformer could not be duplicated - the transformer has been deleted')
  }
  dispatch(functionCreate(funcToCopy.template, funcToCopy.get('folder')))
}

export function datasourceSelect(datasourceId: string, showConfirmation = true) {
  const QUERY_CHANGE_PROMPT = "You didn't save your query. Are you sure you want to discard changes?"
  const FUNCTION_CHANGE_PROMPT = "You didn't save your transformer. Are you sure you want to discard changes?"

  return async (dispatch: any, getState: () => RetoolState) => {
    if (showConfirmation && queryChangedSelector(getState()) && !confirm(QUERY_CHANGE_PROMPT)) {
      return
    }

    if (showConfirmation && selectedFunctionChangedSelector(getState()) && !confirm(FUNCTION_CHANGE_PROMPT)) {
      return
    }

    // Default to the 'General' tab when a different datasource is selected
    const currentDatasource = selectedDatasourceSelector(getState())
    if (currentDatasource && currentDatasource.id !== datasourceId) {
      dispatch(selectQueryEditorSettingsTab('General'))
    }

    const datasource = appTemplateSelector(getState()).getIn(['plugins', datasourceId]) as QueryTemplateType<
      Immutable.Record<QueryTemplateShape>
    >

    const template = datasource?.template

    await dispatch({
      type: DATASOURCE_SELECT,
      payload: {
        datasourceId,
        template,
        editorType: datasource.get('subtype'),
        resourceName: datasource.get('resourceName'),
      },
    })

    const isImported = template?.get('isImported')
    const playgroundQueryId = template?.get('playgroundQueryId')
    const playgroundQuerySaveId = template?.get('playgroundQuerySaveId')

    // If the selected query is imported and pinned to latest version, fetch the latest version.
    if (
      (!currentDatasource || currentDatasource.id !== datasourceId) &&
      isImported &&
      playgroundQueryId &&
      playgroundQuerySaveId === 'latest'
    ) {
      await dispatch(selectImportablePlaygroundQuery(playgroundQueryId, playgroundQuerySaveId, true))
    }
  }
}

export function queryEditorUpdate(newValue: { [key in keyof QueryTemplateShape]?: any }) {
  return (dispatch: any, getState: () => RetoolState) => {
    const selectedResource = selectedResourceSelector(getState())
    const editorType = selectedResource!.editorType
    dispatch({
      type: QUERY_EDITOR_UPDATE,
      payload: { newValue, editorType },
    })
  }
}

export function getImportableQueries(): RetoolAPIDispatcher<any> {
  return async (dispatch: any) => {
    dispatch(savedQueriesRequestActionCreator())
    try {
      const response = await callInternalApi({
        url: '/api/playground',
        method: 'GET',
      })

      const { userQueries, orgQueries } = response
      const { savedQueries, savedUserQueryIds, savedOrgQueryIds } = sortAndFormatSavedQueries(userQueries, orgQueries)
      await dispatch(savedQueriesSuccessActionCreator(savedQueries, savedUserQueryIds, savedOrgQueryIds))
    } catch (error) {
      dispatch(savedQueriesFailureActionCreator(error))
    }
  }
}

const createQueryFromPlaygroundQuerySave = (
  query: PlaygroundQuery,
  saves: { [saveId: number]: PlaygroundQuerySave },
  saveId: PlaygroundQuerySaveId,
) => {
  if (saveId !== 'latest' && !(saveId in saves)) {
    // eslint-disable-next-line no-console
    console.log('Could not find requested save')
    return query
  }

  if (saveId === 'latest') {
    const latestSave = _.last(Object.values(saves))
    return {
      ...query,
      saveId,
      template: Immutable.fromJS(latestSave?.template),
      adhocResourceType: latestSave?.adhocResourceType,
      resourceId: latestSave?.resourceId,
    }
  }

  const save = saves[saveId]

  return {
    ...query,
    saveId,
    resourceId: save.resourceId,
    adhocResourceType: save.adhocResourceType,
    template: Immutable.fromJS(save.template),
    updatedAt: save.createdAt,
  }
}

export function convertSelectedQueryToImportedQuery() {
  return async (dispatch: any, getState: () => RetoolState) => {
    const state = getState()
    const savedOrganizationQueryIds = playgroundSavedOrganizationQueryIdsSelector(state)
    if (!savedOrganizationQueryIds || savedOrganizationQueryIds.length === 0) {
      message.warning("You don't have any importable queries. Create one in the playground")
      return
    }

    dispatch(selectImportablePlaygroundQuery(savedOrganizationQueryIds[0]))
  }
}

export function convertImportedQueryToLocalQuery() {
  return async (dispatch: any, getState: () => RetoolState) => {
    const resource = selectedResourceSelector(getState())
    dispatch({
      type: CONVERT_SELECTED_QUERY_TO_LOCAL_QUERY,
      payload: { resourceType: resource && resource.editorType },
    })
  }
}

export function convertImportedQueryToLocalQueryAndResetTemplate() {
  return async (dispatch: any, getState: () => RetoolState) => {
    const resource = selectedResourceSelector(getState())
    const payload = { resourceType: resource && resource.editorType }
    dispatch({
      type: CONVERT_SELECTED_QUERY_TO_LOCAL_QUERY,
      payload,
    })
    dispatch({
      type: QUERY_TEMPLATE_RESET,
      payload,
    })
  }
}

export function selectImportablePlaygroundQuery(
  queryId: number,
  saveId?: PlaygroundQuerySaveId,
  autosave?: boolean,
): any {
  return async (dispatch: any, getState: () => RetoolState) => {
    const state = getState()
    const selectedResource = selectedResourceSelector(state)
    const editor = state.editor
    let queries = playgroundSavedQueriesSelector(state)
    let querySaves = playgroundQuerySavesSelector(state)
    const resources = editorResourcesSelector(state)
    const selectedDatasourceId = selectedDatasourceIdSelector(state)
    const previousQueryId = editor.getIn(['queryState', selectedResource!.editorType, 'playgroundQueryId'])
    const pinImportedQueryToLatestVersionEnabled = getExperimentValue('pinImportedQueryToLatestVersion')

    // Get importable queries if we can't find the desired query.
    if (!(queryId in queries)) {
      await dispatch(getImportableQueries())
      queries = playgroundSavedQueriesSelector(getState())
    }

    // Get saves for queries if none are available or query is pinned to latest version.
    if (!querySaves.has(queryId) || saveId === 'latest') {
      await dispatch(getPlaygroundQuerySaves(queryId))
      querySaves = playgroundQuerySavesSelector(getState())
    }

    // The above API calls can take a while so we should
    // make sure the original imported query is still selected before updating the UI.
    if (selectedDatasourceIdSelector(getState()) !== selectedDatasourceId) {
      return
    }

    let query: PlaygroundQuery = queries[queryId]
    // If save id is specified, pull that version of the query, otherwise pull the latest version.
    const saves = querySaves.get(queryId)
    if (saveId !== undefined || pinImportedQueryToLatestVersionEnabled) {
      query = createQueryFromPlaygroundQuerySave(query, saves!, saveId || 'latest')
    }

    const resource = getResourceForQuery(query, resources)

    const currentQueryInputs =
      (editor.getIn(['queryState', selectedResource!.editorType, 'importedQueryInputs']) as Immutable.Map<
        string,
        string
      >) || Immutable.Map<string, string>()
    const appSpecificTemplateValues = editor
      .getIn(['queryState', selectedResource!.editorType])
      .filter(appSpecificTemplateValuesFilter)
    const resourceSpecificTemplateValues = query.template.filter(resourceSpecificTemplateValuesFilter)

    const defaultValues = query.template.get('importedQueryDefaults')

    // We parse through the fields that potentially need parameters, and compute the inputs
    // For example, a sql query with `query` = "select * from users where id = {{ foo.bar }}" returns
    // `{ 'foo.bar': '' }`
    let importedQueryInputs = Immutable.Map<string, string>()
    // Ex: { 'user.name': null, 'user.age': null, 'amount': null }

    resourceSpecificTemplateValues.valueSeq().forEach((value: any) => {
      if (typeof value !== 'string') return
      const val: string = value
      const templateDeps = computeTemplateStringDependencies(val, { unescapeRetoolExpressions: false })
      for (const dep of templateDeps) {
        const key = ss(dep)
        if (queryId === previousQueryId) {
          importedQueryInputs = importedQueryInputs.set(
            key,
            currentQueryInputs.get(key) || defaultValues.get(key) || '',
          )
        } else {
          // don't transfer over current inputs if it's a different query
          importedQueryInputs = importedQueryInputs.set(key, defaultValues.get(key) || '')
        }
      }
    })

    const selectedDatasource = selectedDatasourceSelector(getState())

    // Show a warning if the variables (inputs) for the selected query have changed.
    const queryIsSame = previousQueryId === queryId
    const queryHasInputs = [...importedQueryInputs.keys()].length > 0
    const queryInputsAreDifferent = !_.isEqual(
      [...selectedDatasource!.template.get('importedQueryInputs').keys()],
      [...importedQueryInputs.keys()],
    )
    const showLatestVersionUpdatedWarning = queryIsSame && queryHasInputs && queryInputsAreDifferent

    await dispatch({
      type: SELECT_IMPORTED_QUERY,
      payload: {
        query,
        resource,
        template: appSpecificTemplateValues.merge(resourceSpecificTemplateValues),
        importedQueryInputs,
        showLatestVersionUpdatedWarning,
      },
    })

    // Hide the warning if we are pinning to a specific version.
    if (saveId && saveId !== 'latest') {
      await dispatch(hideLatestVersionUpdatedWarning())
    }

    // "Save" the query state to the app template - useful when refreshing a query that
    // is pinned to the latest version so that we don't have "unsaved changes."
    if (autosave && pinImportedQueryToLatestVersionEnabled) {
      const queryState = queryStateSelector(getState())
      dispatch(datasourceTypeChange(selectedDatasource!.id, selectedResource!.name, selectedResource!.editorType))
      // We don't recalculateTemplate b/c that would re-run the query,
      // which would call this very function, leading to an infinite loop.
      dispatch(
        widgetTemplateUpdate(selectedDatasource!.id, queryState.toJS(), true, {
          shouldRecalculateTemplate: false,
          isUserTriggered: false,
        }),
      )
    }
  }
}

export const hideLatestVersionUpdatedWarning = () => {
  return async (dispatch: any, getState: () => RetoolState) => {
    const selectedDatasourceId = selectedDatasourceIdSelector(getState())
    const resource = selectedResourceSelector(getState())
    // Save to both query state and app template to avoid mismatch.
    dispatch({
      type: HIDE_LAST_VERSION_UPDATE_WARNING,
      payload: {
        resource,
      },
    })
    // isUserTriggered set to false to exclude this action from undo history.
    dispatch(
      widgetTemplateUpdate(selectedDatasourceId, { showLatestVersionUpdatedWarning: false }, true, {
        shouldRecalculateTemplate: false,
        isUserTriggered: false,
      }),
    )
  }
}

// Replace all of the expressions in {{ }} with inputs with generic names (variable0, variable1, etc.)
// and maintain a mapping from the input to the original expression.
// For example, `{{ foo.bar }}` becomes `{{ variable0 }}`.
export const replaceQueryExpressionsWithInputs = (template: QueryTemplate) => {
  const expressionToInputMapping = new Map<string, string>()

  template = template.withMutations((t) => {
    t.filter(resourceSpecificTemplateValuesFilter)
      .filter(isString)
      .forEach((v, k) => {
        v = v.replace(ANY_TEMPLATE_REGEX, (_, expr: string) => {
          expr = expr.trim()
          if (!expressionToInputMapping.has(expr)) {
            const inputName = `variable${expressionToInputMapping.size}`
            expressionToInputMapping.set(expr, inputName)
            // Add the input to the playground query template
            t.setIn(['importedQueryInputs', inputName], '')
          }
          return `{{ ${expressionToInputMapping.get(expr)} }}`
        })
        t.set(k, v)
      })
  })

  return { template, expressionToInputMapping }
}

// "Extracts" a local query from the query editor to the Query Library
// and swaps out the local query for the imported query.
// TODO: Perform the template processing on the backend instead
// to safeguard against failure between steps.
export function extractQueryToPlayground(): RetoolActionDispatcher {
  return batchUndoGroup(async (dispatch, getState) => {
    const state = getState()
    const pluginId = selectedDatasourceIdSelector(state)
    const resource = selectedResourceSelector(state)

    if (!pluginId || !resource) {
      return
    }

    retoolAnalyticsTrack('Playground Query Extracted', {
      name: pluginId,
      type: 'query',
      subtype: resource.editorType,
      resourceType: resource.resourceType,
      resourceName: resource.name,
    })

    // Process the selected query's template and replace expressions with inputs.
    let queryState = queryStateSelector(state)
    const Type = TemplateResolver(resource.editorType)
    const { template, expressionToInputMapping } = replaceQueryExpressionsWithInputs(
      Type().merge(queryState) as QueryTemplate,
    )

    // Create a shared playground query with the contents of our local query.
    await dispatch(
      createQuery(resource, {
        name: pluginId,
        data: template as Partial<QueryTemplateShape>,
        shared: true,
      }),
    )

    // Get the playground query ID.
    const userQueryIds = playgroundSavedUserQueryIdsSelector(getState())
    const playgroundQueryId = userQueryIds[0]

    // Set the value of the playground query variables to the original expressions.
    const importedQueryInputs = Immutable.Map<string, string>().withMutations((m) => {
      for (const [expr, input] of expressionToInputMapping) {
        m.set(input, `{{ ${expr} }}`)
      }
    })

    // Update the query editor UI.
    await dispatch(queryEditorUpdate({ importedQueryInputs, playgroundQueryId }))
    await dispatch(selectImportablePlaygroundQuery(playgroundQueryId))
    await dispatch(hideLatestVersionUpdatedWarning())

    // Save the query after extracting.
    queryState = queryStateSelector(getState())
    await dispatch(datasourceTypeChange(pluginId, resource.name, resource.editorType))
    await dispatch(widgetTemplateUpdate(pluginId, queryState.toJS(), true, { shouldRecalculateTemplate: false }))
  })
}

export const selectQueryEditorSettingsTab = (tab: QueryEditorTabType) => {
  return { type: QUERY_EDITOR_SELECT_TAB, payload: { tab } }
}

export function changeQueryEditorOpen(open: boolean) {
  ls.set('retool:queryEditorOpen', open)

  if (open) {
    retoolAnalyticsTrack('Query Editor Shown', {})
    return { type: QUERY_EDITOR_OPEN }
  } else {
    retoolAnalyticsTrack('Query Editor Hidden', {})
    return { type: QUERY_EDITOR_CLOSE }
  }
}

export function changeModelBrowserOpen(open: boolean) {
  ls.set('retool:modelBrowserOpen', open)

  if (open) {
    retoolAnalyticsTrack('LHS Shown', {})
    return { type: MODEL_BROWSER_OPEN }
  } else {
    retoolAnalyticsTrack('LHS Hidden', {})
    return { type: MODEL_BROWSER_CLOSE }
  }
}

export function changeDebuggerOpen(open: boolean) {
  if (open) {
    return { type: DEBUGGER_OPEN }
  }

  return { type: DEBUGGER_CLOSE }
}

export function changeWidgetPickerOpen(open: boolean) {
  ls.set('retool:widgetPickerOpen', open)

  if (open) {
    retoolAnalyticsTrack('RHS Shown', {})
    return { type: WIDGET_PICKER_OPEN }
  } else {
    retoolAnalyticsTrack('RHS Hidden', {})
    return { type: WIDGET_PICKER_CLOSE }
  }
}

export function changeQueryEditorHeight(height: number) {
  ls.set('retool:queryEditorHeight', Math.max(0, height))

  return { type: QUERY_EDITOR_HEIGHT_CHANGE, payload: height }
}

export function changeShowGrid(open: boolean) {
  ls.set('retool:showGrid', open)

  if (open) {
    retoolAnalyticsTrack('Grid Shown', {})
    return { type: GRID_OPEN }
  } else {
    retoolAnalyticsTrack('Grid Hidden', {})
    return { type: GRID_CLOSE }
  }
}

export const openSchemaViewer = () => ({ type: SCHEMA_VIEWER_OPEN })
export const closeSchemaViewer = () => ({ type: SCHEMA_VIEWER_CLOSE })

export function closePreview() {
  return { type: PREVIEW_CLOSE }
}

export function openPreview() {
  return { type: PREVIEW_OPEN }
}

export const disableAppStyles = () => ({ type: APP_STYLES_DISABLE })
export const enableAppStyles = () => ({ type: APP_STYLES_ENABLE })

export function requestReformatQuery(queryType: QueryEditorType) {
  return async (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const state = getState()

    const templateKey = queryType === 'GraphQLQuery' ? 'body' : 'query'
    let formattedValue = state.editor.getIn(['queryState', queryType, templateKey])
    switch (queryType) {
      case 'SqlQuery':
      case 'SqlQueryUnified':
      case 'AthenaQuery':
      case 'SqlTransformQuery':
      case 'CosmosDBQuery':
        formattedValue = formatSqlWithTemplate(formattedValue)
        break
      case 'GraphQLQuery':
        formattedValue = print(parse(formattedValue))
        break
      case 'JavascriptQuery':
        formattedValue = await formatJS(formattedValue)
        break
    }

    dispatch({ type: REFORMAT_QUERY, payload: { queryType, templateKey, formattedValue } })
  }
}

export function previewQuery() {
  return async (dispatch: any, getState: () => RetoolState) => {
    dispatch(closePreview())

    // If an imported query is pinned to latest version, get the latest version of the query
    // for previewing purposes but do not save it to app template.
    let queryState = queryStateSelector(getState()).toJS()
    if (queryState.isImported && queryState.playgroundQuerySaveId === 'latest') {
      await dispatch(selectImportablePlaygroundQuery(queryState.playgroundQueryId!, 'latest', false))
    }

    const state = getState()
    const id = state.editor.get('selectedDatasourceId')
    const pageUuid = getState().pages.get('pageUuid')
    const resource = selectedResourceSelector(state)
    const environment = state.appModel.environment

    retoolAnalyticsTrack('Query Previewed', {
      name: id,
      type: 'query',
      subtype: resource!.editorType,
      resourceType: resource!.resourceType,
      resourceName: resource!.name,
    })

    const queryModel = queryModels[resource!.editorType]
    queryState = queryStateSelector(state).toJS()
    const modelValues = {
      ...state.appModel.get('values').toJS(),
      i: 0,
    }

    let queryResultHandler = null
    if (queryModel.handleResponse) {
      const handler = queryModel.handleResponse(queryState)
      if (handler) {
        queryResultHandler = handler
      }
    }

    try {
      const body = await queryModel.getPreviewParams(queryState, modelValues)
      const queryType = resource!.editorType

      const queryCarriesMetadata = QUERIES_WITH_METADATA.includes(queryType)
      if (body !== null) {
        const queryBody = body.queryTemplate.query
        const res = await dispatch(
          callApi({
            endpoint: `/api/pages/uuids/${pageUuid}/preview`,
            body: JSON.stringify({
              resourceName: resource!.name,
              queryType,
              environment,
              queryName: id,
              frontendVersion: '1',
              ...body,
            }),
            method: 'POST',
            headers: JSON_HEADERS,
            types: [
              REQUEST_QUERY_PREVIEW,
              { type: RECEIVE_QUERY_PREVIEW, meta: { showData: !queryResultHandler } },
              { type: FAILURE_QUERY_PREVIEW, meta: { queryBody } },
            ],
          }),
        )

        // According to redux-api-middleware docs, a failed fetch would dispatch a REQUEST FSA with an error flag.
        // So we need to dispatch FAILURE_QUERY_PREVIEW from this action manually.
        // This behavior has been fixed in v3, but since we're on v1 we need to work around that manually.
        //
        // TODO: Upgrade the middleware to v3 and re-evaluate this code.
        if (res.type === REQUEST_QUERY_PREVIEW && res.error) {
          return dispatch({
            type: FAILURE_QUERY_PREVIEW,
            meta: { queryBody },
            payload: {
              response: {
                message: res?.payload?.message || 'Unknown error',
              },
            },
          })
        }

        if (res.type === RECEIVE_QUERY_PREVIEW) {
          let data = res.payload
          if (queryModel.handleResponse) {
            const handler = queryModel.handleResponse(queryState)
            if (handler) {
              data = await handler(data)
            }
          }

          const formattedData = queryModel.formatResult ? queryModel.formatResult(data) : { data }
          data = formattedData.data

          const queryHasTransformer = queryState.enableTransformer && queryState.transformer

          const queryHasErrorTransformer = queryState.enableErrorTransformer && queryState.errorTransformer

          if (queryHasErrorTransformer) {
            let errorMessage
            if (queryCarriesMetadata) {
              errorMessage = await evaluator.evaluateQueryErrorTransformer(id, data, queryStateSelector(state))
            } else {
              errorMessage = await evaluator.evaluateQueryErrorTransformer(
                id,
                { data, metadata: {} },
                queryStateSelector(state),
              )
            }

            let message
            if (typeof errorMessage === 'string') {
              message = `Error transformer produced: ${errorMessage}`
            } else if (errorMessage && typeof errorMessage === 'object') {
              message = `Error transformer produced: ${JSON.stringify(errorMessage, null, 2)}`
            }

            if (message) {
              return dispatch({
                type: FAILURE_QUERY_PREVIEW,
                meta: { queryBody },
                payload: {
                  response: {
                    message,
                  },
                },
              })
            }
          }

          if (queryHasTransformer) {
            // REST API, GraphQL, and Open API queries return preview data nested in some special keys
            // handle those edge cases here so that query transformers work as expected
            // TODO: make the backend return a more consistent data format for query previews
            const dataAndMetadata = queryCarriesMetadata ? data : { data, metadata: {} }

            try {
              const transformedData = await evaluator.evaluateQueryTransformer(
                id,
                dataAndMetadata,
                queryStateSelector(state),
              )
              if (transformedData) {
                if (queryCarriesMetadata) {
                  data.data = transformedData
                } else {
                  data = transformedData
                }
              }
            } catch (err) {
              message.error(`Could not evaluate transformer in ${id}: ${err}`)
            }
          }
          if (data) {
            dispatch({
              type: RECEIVE_QUERY_PREVIEW,
              payload: data,
            })
          }
        }
      } else {
        try {
          await dispatch(queryModel.runPreview(id, queryState, modelValues))
        } catch (err) {
          dispatch({
            type: FAILURE_QUERY_PREVIEW,
            payload: {
              response: {
                message: err.message,
              },
            },
          })
        }
      }
    } catch (e) {
      // template evaluation errors
      return dispatch(
        triggerModelUpdate([
          {
            selector: [id, 'error'],
            newValue: e.message,
          },
        ]),
      )
    }

    return dispatch(
      triggerModelUpdate([
        {
          selector: [id, 'error'],
          newValue: null,
        },
      ]),
    )
  }
}

export function requestSchemaFromEnv(resourceName: string, env: string) {
  return callInternalApi({
    url: `/api/resources/${encodeURIComponent(resourceName)}/schema?environment=${env}`,
    method: 'GET',
  })
}

export const examineCollection = function examineCollectionInternalApiEndpoint(
  resourceName: string,
  collectionId: string,
  env: string,
  subIdentifier?: string,
) {
  const parameters = `environment=${env}${subIdentifier}` != null ? `&subIdentifier=${subIdentifier}` : ''
  return callInternalApi({
    url: `/api/resources/${encodeURIComponent(resourceName)}/examineCollection/${encodeURIComponent(
      collectionId,
    )}?${parameters}`,
    method: 'GET',
    body: {},
  })
}

export function getDatabaseSchema(resourceName: string, force = false) {
  return (dispatch: any, getState: () => RetoolState) => {
    const schema = getState().editor.getIn(['schemas', resourceName])
    if (!force && (schema || !resourceName)) {
      return
    }

    dispatch(
      callApi({
        endpoint: `/api/resources/${encodeURIComponent(resourceName)}/schema?environment=${environmentSelector(
          getState(),
        )}`,
        method: 'GET',
        headers: JSON_HEADERS,
        types: [
          REQUEST_RESOURCE_SCHEMA,
          RECEIVE_RESOURCE_SCHEMA,
          { type: FAILURE_RESOURCE_SCHEMA, meta: { resourceName } },
        ],
      }),
    )
  }
}

export function getApproxCollectionSchema(resource: any, collectionName: any) {
  return async (dispatch: any, getState: () => RetoolState) => {
    // fetch some docs to guess-timate schema
    const environment = getState().appModel.environment
    const response = await dispatch(
      callApi({
        endpoint: `/api/preview`,
        method: 'POST',
        headers: JSON_HEADERS,
        types: [UNUSED_ACTION, UNUSED_ACTION, UNUSED_ACTION], // fml
        body: JSON.stringify({
          environment,
          queryName: 'dummy',
          queryTemplate: {
            firebaseService: 'firestore',
            firestoreCollection: collectionName,
            firestoreWhere: '',
            actionType: 'queryFirestore',
            limit: '1',
          },
          queryType: 'FirebaseQuery',
          resourceName: resource.name,
          userParams: {
            firebaseServiceParams: { length: 0 },
            firestoreCollectionParams: { length: 0 },
            firestoreWhereParams: { length: 0 },
            actionTypeParams: { length: 0 },
            limitParams: { length: 0 },
          },
        }),
      }),
    )
    const document = response.payload[0]

    if (!document) {
      return {}
    }

    const schema: any = {}
    Object.keys(document).forEach((key) => {
      let dataType = 'varchar'
      if (typeof document[key] === 'number') {
        dataType = 'integer'
      } else if (typeof document[key] === 'boolean') {
        dataType = 'bool'
      } else if (moment(document[key], moment.ISO_8601, true).isValid()) {
        dataType = 'datetime'
      }
      schema[key] = { data_type: dataType }
    })

    dispatch({
      type: UPDATE_COLLECTION_SCHEMA,
      payload: { resourceName: resource.name, collectionName, schema },
    })

    return schema
  }
}

export enum INVALIDATE_CACHE {
  REQUEST = 'REQUEST_INVALIDATE_CACHE',
  SUCCESS = 'RECEIVE_INVALIDATE_CACHE',
  FAILURE = 'FAILURE_INVALIDATE_CACHE',
}

export function invalidateCache(queryId: string) {
  return async (dispatch: any, getState: () => RetoolState) => {
    const pageUuid = getState().pages.get('pageUuid')
    const res = await dispatch(
      callApi({
        endpoint: `/api/pages/uuids/${pageUuid}/invalidateCache`,
        method: 'POST',
        headers: JSON_HEADERS,
        types: [INVALIDATE_CACHE.REQUEST, INVALIDATE_CACHE.SUCCESS, INVALIDATE_CACHE.FAILURE],
        body: JSON.stringify({
          queryName: queryId,
        }),
      }),
    )
    if (res.type === INVALIDATE_CACHE.SUCCESS) {
      message.success('Invalidated cache')
    }
    if (res.type === INVALIDATE_CACHE.FAILURE) {
      message.error(res.payload.response.message)
    }
    return res
  }
}

export enum CREATE_TAG {
  REQUEST = 'REQUEST_CREATE_TAG',
  SUCCESS = 'RECEIVE_CREATE_TAG',
  FAILURE = 'FAILURE_CREATE_TAG',
}

export function createTag(tagName: string, tagDescription: string): RetoolAPIDispatcher<typeof CREATE_TAG> {
  return async (dispatch, getState) => {
    const pageUuid = getState().pages.get('pageUuid')
    const res = await dispatch(
      callApi({
        endpoint: `/api/pages/uuids/${pageUuid}/createTag`,
        method: 'POST',
        headers: JSON_HEADERS,
        types: [CREATE_TAG.REQUEST, CREATE_TAG.SUCCESS, CREATE_TAG.FAILURE],
        body: JSON.stringify({ tagName, tagDescription }),
      }),
    )
    if (res.type === CREATE_TAG.FAILURE) {
      message.error(res.payload.response.message)
    } else {
      message.success(res.payload.message)
    }
    return res
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
//

type EditorActionHandler = (state: EditorModel, action: RetoolAction) => EditorModel

const handleDatasourceTypeChange: EditorActionHandler = (state, action) => {
  let newState = state
  if (action.payload.newType !== action.payload.oldType) {
    const defaultTemplate = TemplateResolver(action.payload.newType)()
    const oldTemplate = newState.getIn(['queryState', action.payload.newType]) || Immutable.Map()
    newState = newState
      .setIn(['queryState', action.payload.newType], defaultTemplate.merge(oldTemplate))
      .setIn(
        ['queryState', action.payload.newType, 'triggersOnFailure'],
        state.getIn(['queryState', action.payload.oldType, 'triggersOnFailure']),
      )
      .setIn(
        ['queryState', action.payload.newType, 'triggersOnSuccess'],
        state.getIn(['queryState', action.payload.oldType, 'triggersOnSuccess']),
      )
  } else if (action.payload.oldType === 'OpenAPIQuery' && action.payload.newType === 'OpenAPIQuery') {
    newState = newState.setIn(['queryState', action.payload.newType, 'specPathOverride'], '')
  }
  return newState
    .set('previewOpen', false)
    .set('queryTypeChanged', true)
    .set('selectedResourceName', action.payload.resourceName)
}

const handleFailureQueryPreview: EditorActionHandler = (state, action) => {
  let newState = state
  const message: string =
    _.get(action, ['payload', 'response', 'message']) || _.get(action, ['payload', 'message'], 'Unknown error')
  const hint: string = _.get(action, ['payload', 'response', 'hint'], undefined)

  const syntaxErrorPosition: number | null = _.get(action, ['payload', 'response', 'position'])
  const syntaxErrorLineNumber: number | null = _.get(action, ['payload', 'response', 'lineNumber'])
  const syntaxErrorLineCol: number | null = _.get(action, ['payload', 'response', 'lineCol'])
  if (syntaxErrorPosition || syntaxErrorLineNumber) {
    newState = newState.set('querySyntaxErrors', {
      [state.selectedDatasourceId]: {
        sqlText: action?.meta?.queryBody ?? '',
        message,
        // postgres syntax error positions are 1-indexed?
        syntaxErrorPosition: syntaxErrorPosition != null ? syntaxErrorPosition - 1 : null,
        syntaxErrorLineNumberIdx: syntaxErrorLineNumber != null ? syntaxErrorLineNumber - 1 : null,
        syntaxErrorLineCol,
      },
    })
  }

  return newState
    .set('previewOpen', true)
    .set(
      'preview',
      newState
        .get('preview')
        .set('isFetching', false)
        .set('fetchingTimestamp', 0)
        .set('timestamp', Date.now())
        .set('data', null)
        .set('error', { message, hint }),
    )
}

const handleSchemaViewerOpen: EditorActionHandler = (state) => state.set('schemaViewerOpen', true)
const handleSchemaViewerClose: EditorActionHandler = (state) => state.set('schemaViewerOpen', false)

const handleQueryEditorOpen: EditorActionHandler = (state) => {
  return state.set('queryEditorOpen', true)
}

const handleQueryEditorClose: EditorActionHandler = (state) => {
  return state.set('queryEditorOpen', false)
}

const handleReceiveRestore: EditorActionHandler = (state) => {
  return state.set('saveUpToDate', true)
}

interface ReformatAction extends RetoolAction {
  queryType?: any
}

const reformatQuery: EditorActionHandler = (state, action: ReformatAction) => {
  const { queryType, templateKey, formattedValue } = action.payload
  return state.setIn(['queryState', queryType, templateKey], formattedValue)
}

const handleRestoreLoaded: EditorActionHandler = (state, action) => {
  const firstDatasource = getFirstDatasource(action.payload)
  if (firstDatasource) {
    return state
      .set('selectedDatasourceId', firstDatasource.get('id'))
      .set('selectedResourceName', firstDatasource.get('resourceName'))
      .setIn(['queryState', firstDatasource.get('subtype')], firstDatasource.get('template'))
  } else {
    return state
  }
}

const handlePluginUpdateId: EditorActionHandler = (state, action) => {
  if (state.getSelectedWidgetId() === action.payload.pluginId) {
    return state.selectWidget(action.payload.newId)
  }
  if (state.selectedDatasourceId === action.payload.pluginId) {
    return state.set('selectedDatasourceId', action.payload.newId)
  }
  if (state.selectedFunctionId === action.payload.pluginId) {
    return state.set('selectedFunctionId', action.payload.newId)
  }
  if (state.selectedStateId === action.payload.pluginId) {
    return state.set('selectedStateId', action.payload.newId)
  }
  return state
}

const handlePluginDelete: EditorActionHandler = (state, action) => {
  const ids = action.payload
  const deleted = state.selectedWidgets.intersect(ids)
  if (!deleted.isEmpty()) {
    // dispose deleted widgets in selectedWidets set
    state = state.set('selectedWidgets', state.selectedWidgets.subtract(deleted))
  }
  if (ids.includes(state.selectedDatasourceId)) {
    state = state.set('selectedDatasourceId', '').set('selectedResourceName', '')
  }
  return state
}

const handleWidgetSelect: EditorActionHandler = (state, action) => {
  return state.selectWidget(action.payload.widgetId)
}

const handleWidgetSelectAll: EditorActionHandler = (state, action) => {
  return state.selectMultipleWidgets(action.payload.widgetIds)
}

const handleWidgetMultiselect: EditorActionHandler = (state, action) => {
  const widgetId = action.payload.widgetId
  if (state.isMultiselected(widgetId)) {
    return state.demultiselectWidget(widgetId)
  } else {
    return state.multiselectWidget(widgetId)
  }
}

const handleFunctionSelect: EditorActionHandler = (state, action) => {
  return state
    .set('selectedFunctionId', action.payload.functionId)
    .set('selectedDatasourceId', action.payload.functionId)
}

const handleInstrumentSelect: EditorActionHandler = (state, action) => {
  return state.set('selectedInstrumentId', action.payload.instrumentId)
}

const handleStateSelect: EditorActionHandler = (state, { payload: { stateId } }) => state.selectState(stateId)

const handleFrameSelect: EditorActionHandler = (state, { payload: { frameId } }) => state.selectFrame(frameId)

const handleDatasourceSelect: EditorActionHandler = (state, action) => {
  return state
    .set('selectedDatasourceId', action.payload.datasourceId)
    .set('selectedResourceName', action.payload.resourceName)
    .set('selectedFunctionId', '')
    .set('queryState', Immutable.Map())
    .setIn(['queryState', action.payload.editorType], action.payload.template)
    .set('previewOpen', false)
    .set('queryEditorOpen', true)
    .set('queryTypeChanged', false)
    .set('transformerChanged', false)
}

const handleRequestQueryPreview: EditorActionHandler = (state) =>
  state
    .setIn(['preview', 'isFetching'], true)
    .setIn(['preview', 'fetchingTimestamp'], Date.now())
    .set('querySyntaxErrors', { [state.selectedDatasourceId]: null! })

const handleReceiveQueryPreview: EditorActionHandler = (state, action) => {
  const showData = _.get(action, ['meta', 'showData'], true)
  return state
    .setIn(['preview', 'isFetching'], false)
    .setIn(['preview', 'fetchingTimestamp'], null)
    .setIn(['preview', 'timestamp'], Date.now())
    .setIn(['preview', 'data'], showData ? action.payload : {})
    .setIn(['preview', 'error'], null)
    .set('previewOpen', true)
}

const handlePageLoad: EditorActionHandler = (state, action) => {
  return state.set('accessLevel', action.payload.accessLevel)
}

const handleSetPopoutCodeEditor: EditorActionHandler = (state, action) => {
  return state.set('currentPopoutCodeEditor', action.payload)
}

const handleWidgetCopy: EditorActionHandler = (state, action) => {
  return state.set('copiedWidgets', action.payload)
}

const handleWidgetPasteStart: EditorActionHandler = (state) => {
  return state.set('isPastingWidgets', true)
}

const handleWidgetPasteFinish: EditorActionHandler = (state) => {
  return state.set('isPastingWidgets', false)
}

const handleSelectImportedQuery: EditorActionHandler = (state, action) => {
  const { query, resource, template, importedQueryInputs, showLatestVersionUpdatedWarning } = action.payload
  const defaultTemplate = TemplateResolver(resource.editorType)()
  return state
    .setIn(['queryState', resource.editorType], defaultTemplate)
    .mergeIn(['queryState', resource.editorType], template)
    .mergeIn(['queryState', resource.editorType], {
      isImported: true,
      importedQueryInputs,
      playgroundQueryUuid: query.uuid,
      playgroundQueryId: query.id,
      playgroundQuerySaveId: query.saveId,
      ...(showLatestVersionUpdatedWarning && { showLatestVersionUpdatedWarning }),
    })
    .set('selectedResourceName', resource.name)
}

const handleResetQueryTemplate: EditorActionHandler = (state, action) => {
  const { resourceType } = action.payload
  const defaultTemplate = TemplateResolver(resourceType)()

  return state.mergeIn(['queryState', resourceType], defaultTemplate)
}

const handleConvertSelectedQueryToLocalQuery: EditorActionHandler = (state, action) => {
  const { resourceType } = action.payload
  return state.mergeIn(['queryState', resourceType], {
    isImported: false,
    playgroundQueryId: 0,
    playgroundQuerySaveId: 0,
    importedQueryInputs: {},
    importedQueryDefaults: {},
  })
}

const ACTION_HANDLERS: { [signal: string]: EditorActionHandler } = {
  // Special function changed
  TRANSFORMER_CHANGED: (state, action) => state.set('transformerChanged', action.payload),
  [INSTRUMENT_CHANGED]: (state, action) => {
    return state.set('instrumentChanged', action.payload)
  },
  [WIDGET_SELECT]: handleWidgetSelect,
  [WIDGET_SELECT_ALL]: handleWidgetSelectAll,
  [WIDGET_MULTISELECT]: handleWidgetMultiselect,
  [WIDGET_COPY]: handleWidgetCopy,
  [WIDGET_PASTE_START]: handleWidgetPasteStart,
  [WIDGET_PASTE_FINISH]: handleWidgetPasteFinish,
  [FUNCTION_SELECT]: handleFunctionSelect,
  [INSTRUMENT_SELECT]: handleInstrumentSelect,
  [FUNCTION_TEMPLATE_CREATE]: (state: any, action: any) =>
    state.set('selectedDatasourceId', action.payload.template.get('id')),
  [INSTRUMENT_TEMPLATE_CREATE]: (state: any, action: any) =>
    state.set('selectedDatasourceId', action.payload.template.get('id')),
  [STATE_SELECT]: handleStateSelect,
  [FRAME_SELECT]: handleFrameSelect,
  [DATASOURCE_SELECT]: handleDatasourceSelect,
  [PLUGIN_UPDATE_ID]: handlePluginUpdateId,
  [UNTRANSFORMED_QUERY_RESPONSE_UPDATE]: (state, action) =>
    state.setIn(['untransformedQueryResponses', action.payload.queryName], action.payload.response),
  [QUERY_EDITOR_UPDATE]: (state, action) =>
    state.mergeIn(['queryState', action.payload.editorType], action.payload.newValue),
  [SELECT_IMPORTED_QUERY]: handleSelectImportedQuery,
  [REQUEST_QUERY_PREVIEW]: handleRequestQueryPreview,
  [RECEIVE_QUERY_PREVIEW]: handleReceiveQueryPreview,
  [FAILURE_QUERY_PREVIEW]: handleFailureQueryPreview,
  [WIDGET_TEMPLATE_CREATE]: (state, action) => {
    if (!action.payload.skipSelect) {
      return state.selectWidget(action.payload.template.get('id'))
    }
    return state
  },
  [DATASOURCE_TEMPLATE_CREATE]: (state, action) => {
    return state
      .set('selectedDatasourceId', action.payload.template.get('id'))
      .set('selectedResourceName', action.payload.template.get('resourceName'))
      .set('preview', new Preview())
      .set('previewOpen', false)
      .set('queryState', Immutable.Map())
      .setIn(['queryState', action.payload.template.get('subtype')], action.payload.template.get('template'))
      .setIn(['queryState', 'isImported'], action.payload.template.getIn(['template', 'isImported']))
      .set('queryTypeChanged', true) // hack to make sure that new query is savable
  },
  [SCHEMA_VIEWER_OPEN]: handleSchemaViewerOpen,
  [SCHEMA_VIEWER_CLOSE]: handleSchemaViewerClose,
  [QUERY_EDITOR_OPEN]: handleQueryEditorOpen,
  [QUERY_EDITOR_CLOSE]: handleQueryEditorClose,
  [QUERY_EDITOR_SELECT_TAB]: (state, action) => state.set('selectedQuerySettingsTab', action.payload.tab),
  [MODEL_BROWSER_OPEN]: (state) => state.set('modelBrowserOpen', true),
  [MODEL_BROWSER_CLOSE]: (state) => state.set('modelBrowserOpen', false),
  [DEBUGGER_OPEN]: (state) => state.set('debuggerOpen', true),
  [DEBUGGER_CLOSE]: (state) => state.set('debuggerOpen', false),
  [GRID_OPEN]: (state) => state.set('showGrid', true),
  [GRID_CLOSE]: (state) => state.set('showGrid', false),
  [WIDGET_PICKER_OPEN]: (state) => state.set('widgetPickerOpen', true),
  [QUERY_EDITOR_HEIGHT_CHANGE]: (state, action) => state.set('queryEditorHeight', action.payload),
  [WIDGET_PICKER_CLOSE]: (state) => state.set('widgetPickerOpen', false),
  [PREVIEW_CLOSE]: (state) => state.set('previewOpen', false),
  [PREVIEW_OPEN]: (state) => state.set('previewOpen', true),
  [HIDE_LAST_VERSION_UPDATE_WARNING]: (state, action) =>
    state.setIn(['queryState', action.payload.resource.editorType, 'showLatestVersionUpdatedWarning'], false),

  [APP_STYLES_DISABLE]: (state) => state.set('appStylesDisabled', true),
  [APP_STYLES_ENABLE]: (state) => state.set('appStylesDisabled', false),

  [WIDGET_MANAGER_TOGGLE]: (state) => state.set('widgetManagerOpen', !state.get('widgetManagerOpen')),

  [QUERY_STATE_DATASOURCE_TYPE_CHANGE]: handleDatasourceTypeChange,
  [QUERY_TEMPLATE_RESET]: handleResetQueryTemplate,
  [CONVERT_SELECTED_QUERY_TO_LOCAL_QUERY]: handleConvertSelectedQueryToLocalQuery,

  [RECEIVE_RESOURCE]: (state, action) => {
    if (state.get('selectedResourceName') === action.payload.oldResourceName) {
      return state.set('selectedResourceName', action.payload.newResource.name)
    }
    return state
  },

  [RECEIVE_RESTORE]: handleReceiveRestore,
  [RESTORE_LOADED]: handleRestoreLoaded,
  [MIGRATIONS_UP_TO_DATE]: handleReceiveRestore,
  [REQUEST_SAVE]: (state) => state.set('isSaving', true),
  [RECEIVE_SAVE]: (state) => state.set('saveUpToDate', true).set('isSaving', false).set('queryTypeChanged', false),

  [REQUEST_RESOURCES]: (state) => state,
  [FAILURE_RESOURCES]: (state) => state,

  [REQUEST_RESOURCE_SCHEMA]: (state) => state,
  [RECEIVE_RESOURCE_SCHEMA]: (state, action) => {
    return state.setIn(['schemas', action.payload.resourceName], { error: false, schema: action.payload.schema })
  },
  [ADD_RECORD_TO_SCHEMA]: (state, action) => {
    return state.mergeIn(
      ['schemas', action.payload.resourceName, 'schema', action.payload.collectionId],
      action.payload.schema,
    )
  },
  [FAILURE_RESOURCE_SCHEMA]: (state, action: { type: string; meta?: { resourceName: string } }) =>
    state.setIn(['schemas', action.meta!.resourceName], { error: true }),
  [UPDATE_COLLECTION_SCHEMA]: (state, action) =>
    state.setIn(
      ['schemas', action.payload.resourceName, 'schema', action.payload.collectionName],
      action.payload.schema,
    ),

  [PLUGIN_DELETE]: handlePluginDelete,
  [RECEIVE_PAGE_LOAD]: handlePageLoad,
  [SET_POPOUT_CODE_EDITOR]: handleSetPopoutCodeEditor,
  [REFORMAT_QUERY]: reformatQuery,
  [CLEAR_EDITOR]: () => new EditorModel(),
  [ActionTypes.UNDO]: (state) => state.set('undoRedoCount', state.undoRedoCount + 1),
  [ActionTypes.REDO]: (state) => state.set('undoRedoCount', state.undoRedoCount + 1),
  QUERY_TRIGGER: (state, action) => state.set('querySyntaxErrors', { [action.payload.queryName]: null! }),
  [QUERY_RUN_FAIL]: (
    state,
    action: { payload?: { queryId: string; data: { message: string; position?: string } } },
  ) => {
    let newState = state

    if (action.payload?.data?.position) {
      const message: string = _.get(action, ['payload', 'response', 'message'], 'Unknown error')

      const syntaxErrorPosition: number | null = _.get(action, ['payload', 'data', 'position'])
      const syntaxErrorLineNumber: number | null = _.get(action, ['payload', 'data', 'lineNumber'])
      const syntaxErrorLineCol: number | null = _.get(action, ['payload', 'data', 'lineCol'])
      newState = newState.set('querySyntaxErrors', {
        [action.payload.queryId]: {
          sqlText: getState().appTemplate.present.plugins.getIn([action.payload.queryId, 'template', 'query']),
          message,
          // postgres syntax error positions are 1-indexed?
          syntaxErrorPosition: syntaxErrorPosition != null ? syntaxErrorPosition - 1 : null,
          syntaxErrorLineNumberIdx: syntaxErrorLineNumber != null ? syntaxErrorLineNumber - 1 : null,
          syntaxErrorLineCol,
        },
      })
    }
    return newState
  },

  [MODELBROWSER_EXPAND_PLUGIN_ID]: (state, action: any) =>
    state.set(
      'defaultModelBrowserExpandedPluginIds',
      state.get('defaultModelBrowserExpandedPluginIds').add(action.pluginId),
    ),
}

// ------------------------------------
// Reducer
// ------------------------------------

const initialState = new EditorModel()

const templateMutatingActions = Immutable.Set([
  WIDGET_REPOSITION2,
  WIDGET_TEMPLATE_CREATE,
  WIDGET_TEMPLATE_UPDATE,
  DATASOURCE_TEMPLATE_CREATE,
  PLUGIN_UPDATE_ID,
  QUERY_STATE_DATASOURCE_TYPE_CHANGE,
  DATASOURCE_TYPE_CHANGE,
  PLUGIN_DELETE,
  MIGRATIONS_SUCCESS,
  URL_FRAGMENT_DEF_UPDATE,
  PAGE_LOAD_VALUE_OVERRIDE_UPDATE,
  SELECT_IMPORTED_QUERY,
  REORDER_QUERIES,
  REORDER_TABBED_CONTAINER,
  SET_CUSTOM_SHORTCUTS,
  SET_CUSTOM_DOCUMENT_TITLE,
  ENABLE_CUSTOM_DOCUMENT_TITLE,
  PLUGIN_FOLDER_CHANGE,
  FOLDER_CREATE,
  ActionTypes.UNDO,
  ActionTypes.REDO,
])

export default function editorReducer(state: EditorModel = initialState, action: any) {
  const handler = ACTION_HANDLERS[action.type]

  const nextState = handler ? handler(state, action) : state

  if (templateMutatingActions.has(action.type)) {
    return nextState.set('saveUpToDate', false)
  }
  return nextState
}

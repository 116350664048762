import { Store, Action, AnyAction, Reducer, Middleware } from 'redux'
import {
  EditorModel,
  AppTemplate,
  AppModelType,
  EditorSelection,
  OnboardingState,
  PaginatedSaves,
  PlaygroundModel,
  OrgImageBlobsModel,
  UserModel,
  Flow,
  ModulesReducerModel,
  PagesModel,
} from 'common/records'
import { Map } from 'immutable'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { GlobalsReducerType } from './globals.types'
import { ResponsiveReducerType } from './responsive.d'
import { MarketingTemplatesReducerType } from './marketingTemplates.d'
import { AppThemeType } from './user.d'
import { SafeAny } from 'common/types'
import { PerformanceModel } from 'common/types/performance'

// ------------------------------------
// Interfaces
// ------------------------------------

export interface RetoolAction extends Action {
  type: string
  meta?: any
  payload?: any
}

export type RetoolThunkSync<ReturnType = SafeAny> = ThunkAction<ReturnType, RetoolState, unknown, Action<string>>
export type RetoolThunk<ReturnType = Promise<SafeAny>> = ThunkAction<ReturnType, RetoolState, unknown, Action<string>>
export type RetoolDispatch = ThunkDispatch<RetoolState, {}, AnyAction>
export type RetoolMiddleware = Middleware<{}, RetoolState, RetoolDispatch>
export type RetoolActionDispatcher = (dispatch: RetoolDispatch, getState: () => RetoolState) => any

export interface RetoolStore extends Store<RetoolState, AnyAction> {
  asyncReducers: { [key: string]: Reducer<any> }
  unsubscribeHistory: any
  dispatch: RetoolDispatch
}

export type RetoolState = {
  appTemplate: {
    past: Array<AppTemplate>
    future: Array<AppTemplate>
    present: AppTemplate
  }
  datasources: any
  appModel: AppModelType
  editor: EditorModel
  editorSelection: EditorSelection
  paginatedSaves: PaginatedSaves
  pages: PagesModel
  session: Map<string, any>
  user: UserModel
  orgImageBlobs: OrgImageBlobsModel
  onboarding: OnboardingState
  omnibox: any
  location: any
  responsive: ResponsiveReducerType
  marketingTemplates: MarketingTemplatesReducerType
  playground: PlaygroundModel
  modules: ModulesReducerModel
  embedded?: {
    password: string
    authenticated: boolean
    isFetching: boolean
    error: string
    preloadedCSS: string
    defaultAppThemeId: number | null
    appThemes: AppThemeType[]
  }
  globals: GlobalsReducerType
  login: any
  presentation: any
  subdomain: any
  settings?: any
  auditTrails?: any
  resources?: any
  auditLoginHistory?: any
  setup?: any
  changesRecord: any
  flows?: {
    selectedFlow: Flow
  }
  buildingBlocks: any
  // TODO: What type should this be?
  branches?: any
  performance?: PerformanceModel
}

// ------------------------------------
// global state
// ------------------------------------

// FIXME, don't make the state global, create + inject this when the app starts
// up!
let store: RetoolStore = null!
let currentModel: AppModelType = null!

// ------------------------------------
// state accessors
// ------------------------------------

/** @deprecated use `connect` with `mapDispatchToProps`, or the `dispatch` arg in the action creator thunk */
export function dispatch(action: RetoolAction | RetoolActionDispatcher) {
  return store.dispatch(action as any)
}

export function stageModel(model: AppModelType) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  currentModel = model
}

/** @deprecated use `connect` with `mapStateToProps`, or the `getState` arg in the action creator thunk */
export function getState(): RetoolState {
  if (!store) {
    return null!
  }
  return store.getState()
}

export function publishStore(newStore: any) {
  store = newStore
}

import queryString from 'query-string'
import { RetoolDispatch, RetoolState } from 'store'
import { getXsrfToken } from 'networking/util'
import { onEmbeddedPage } from 'common/utils'
import { UnknownObject } from 'common/types'
import { experimentValueSelector } from 'common/utils/experiments'

export function fetchWithHeadersAndHostname(url: any, options: any) {
  if (!options.headers) options.headers = {}
  options.headers['X-Xsrf-Token'] = getXsrfToken()
  options.headers['Content-Type'] = options.headers['Content-Type'] || 'application/json'
  options.credentials = INCLUDE_COOKIES_IN_API_CALLS || 'same-origin'
  return fetch(url, options)
}

export function formatData(data: any) {
  const f = new FormData()
  for (const key in data) {
    if (typeof data[key] === 'object') {
      for (const innerKey in data[key]) {
        f.append(`${key}[${innerKey}]`, data[key][innerKey])
      }
    } else {
      f.append(key, data[key])
    }
  }
  return f
}

function toQueryString(requestUrl: string, query: UnknownObject) {
  return `${requestUrl}?${queryString.stringify(query)}`
}

export function POST(
  requestUrl: string,
  query: UnknownObject,
  data: UnknownObject,
  headers?: UnknownObject,
): Promise<Response> {
  return fetchWithHeadersAndHostname(toQueryString(requestUrl, query), {
    method: 'post',
    body: JSON.stringify(data),
    headers,
  })
}

export function runQuery(
  queryName: any,
  userParams: any,
  queryType: any,
  environment: any,
  uuid?: string,
  isInGlobalWidget?: boolean,
  branchName?: string,
) {
  return (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const currentBranchName = isInGlobalWidget ? undefined : branchName || getState().pages.get('pageBranchName')
    const pageUuid = encodeURIComponent(uuid || getState().pages.get('pageUuid'))
    const isEditorMode = getState().pages.get('editorMode')
    const urlParams = new URLSearchParams(getState().location.search)
    const releaseVersion = urlParams.get('_releaseVersion')
    const showLatest = isEditorMode
    const apiVersion = '1'
    const includeQueryExecutionMetadata = experimentValueSelector(getState(), 'performanceDashboard')
    if (onEmbeddedPage()) {
      const password = getState().embedded?.password
      return POST(
        `/api/public/${pageUuid}/query`,
        { queryName },
        {
          userParams,
          password,
          environment,
          queryType,
          frontendVersion: apiVersion,
          releaseVersion,
          branchName: currentBranchName,
          isInGlobalWidget,
          includeQueryExecutionMetadata,
        },
        { timestamp: Date.now() },
      )
    } else {
      return POST(
        `/api/pages/uuids/${pageUuid}/query`,
        { queryName },
        {
          userParams,
          queryType,
          environment,
          showLatest,
          isEditorMode,
          frontendVersion: apiVersion,
          releaseVersion,
          branchName: currentBranchName,
          includeQueryExecutionMetadata,
        },
        { timestamp: Date.now() },
      )
    }
  }
}

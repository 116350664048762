import * as Sentry from '@sentry/browser'

import { RetoolMiddleware } from './'

import { PAGE_USER_HEARTBEATS_EVENT } from './actionTypes'

const ACTION_BLACKLIST = new Set([...Object.values(PAGE_USER_HEARTBEATS_EVENT)])

const ACTION_DATA_WHITELIST = new Set<string>([])

const sentryLogMiddleware: RetoolMiddleware = () => (next) => (action) => {
  // Don't add action types in the blacklist (some are spammy and not that useful)
  if (!ACTION_BLACKLIST.has(action.type)) {
    let data = null

    // Add action payloads for actions without sensitive data
    if (ACTION_DATA_WHITELIST.has(action.type)) {
      data = action.payload
    }

    Sentry.addBreadcrumb({
      category: 'redux-action',
      message: action.type,
      data,
    })
  }

  return next(action)
}

export default sentryLogMiddleware

import React, { Component } from 'react'
import Button from 'components/standards/Button'
import Modal from 'components/standards/Modal'
import TextInput from 'components/standards/TextInput'
import { message } from 'antd'

type RenameProps = {
  visible: boolean
  renameFunction: (name: string) => Promise<void>
  closeModal: () => void
  name: string
}

type RenameState = {
  newName: string
}

class RenameModal extends Component<RenameProps, RenameState> {
  constructor(props: any) {
    super(props)
    this.state = { newName: '' }
  }

  closeModal = () => {
    this.setState({ newName: '' })
    this.props.closeModal()
  }

  renameFormValid = () => this.state.newName.length > 0

  rename = () => {
    if (this.renameFormValid()) {
      this.props
        .renameFunction(this.state.newName)
        .then(() => this.closeModal())
        .catch((err) => message.error(`${err}`))
    }
  }

  render() {
    return (
      <Modal
        destroyOnClose
        title="Rename"
        width={380}
        visible={this.props.visible}
        onCancel={this.closeModal}
        footer={
          <Button size="large" key="submit" type="primary" onClick={this.rename} disabled={!this.renameFormValid()}>
            Rename!
          </Button>
        }
      >
        <div className="folder-view__modal-section">
          <div className="folder-view__name-label">New name for your {this.props.name.toLowerCase()}:</div>
          <TextInput
            autoFocus
            placeholder={`${this.props.name} name`}
            value={this.state.newName}
            onChange={(ev) => this.setState({ newName: ev.target.value })}
            onPressEnter={this.rename}
          />
        </div>
      </Modal>
    )
  }
}

export default RenameModal

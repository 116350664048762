import classNames from 'classnames'
import React, { ButtonHTMLAttributes, forwardRef, RefForwardingComponent } from 'react'
import _styles from "./Clickable-styles.module.scss";


export type ClickableProps = ButtonHTMLAttributes<HTMLButtonElement>

const styles = _styles

const Clickable: RefForwardingComponent<HTMLButtonElement, ClickableProps> = ({ className, ...props }, ref) => (
  <button type="button" className={classNames(styles.main, className)} {...props} ref={ref} />
)

export default forwardRef(Clickable)

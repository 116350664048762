import {
  AppTemplate,
  NestedGlobalWidget,
  NestedGlobalWidgets,
  PluginNamespaceInfo,
  PluginNamespaceInfoImpl,
  PluginTemplate,
} from 'common/records'
import { mapValues, last } from 'lodash'
import { SafeAny } from 'common/types'
import { onEmbeddedPage, ss } from 'common/utils'
import { addNamespace, ESCAPED_NAMESPACE_SEPARATOR, NAMESPACE_SEPARATOR } from 'common/utils/namespaces'
import { Message } from 'components/design-system'
import { GLOBAL_WIDGET_GRID } from 'components/plugins/widgets/GlobalWidget/constants'
import { DepGraph } from 'dependency-graph'
import Immutable from 'immutable'
import { GLOBAL_WIDGET_TYPE } from 'retoolConstants'
import { dispatch as UNSAFE_dispatch, RetoolDispatch, RetoolState } from 'store'

import { GLOBAL_QUERY_PASS_THROUGH_PROPERTIES } from './constants'
import {
  batchAddNewTemplate,
  batchExecOnTemplateUpdates,
  calculateNewTemplateForGlobalChildren,
  updateTemplate,
} from './model'
import { callApi } from 'store/callApi'
import SimpleCache from './SimpleCache'
import { batchWidgetTemplateUpdate, BATCH_WIDGET_TEMPLATE_CREATE, deserializeSave } from 'store/appModel/templateUtils'
import { STORE_MODULE_TEMPLATES } from 'store/constants'
import { migrateAppTemplate } from './migrateAppTemplate'
import { namespacePreloadedJSInModule, namespaceWindowReferencesInPlugin } from './preloadedJS'
import { startBatchUndoGroup, stopBatchUndoGroup } from './batchUndoGroupBy'

export const buildGlobalQueryPassThroughPropertyTemplate = (queryName: string, property: string) =>
  `{{${ss([queryName, property])}}}`

/**
 * We want to initialize global props to be empty strings if they are not set. Otherwise if they are undefined
 * we run into a funky dependency situation where the dependencyGraph tries to draw an edge to global1.myInputProp
 * but since it doesn't exist it draws a dependency to its parents global1 which causes a circular dependency.
 */
export function initializeGlobalWidgetProps(
  childNamespace: string,
  globalInputs: string[],
  template: Immutable.Map<string, unknown>,
) {
  let newTemplate = template
  for (const globalInput of globalInputs) {
    if (template.get(globalInput) === undefined) {
      newTemplate = newTemplate.set(globalInput, '')
    }
  }

  // Also set the childNamespace property so that we know the namespace that its children are using
  newTemplate = newTemplate.set('childNamespace', childNamespace)
  return newTemplate
}

/**
 * Global outputs are initialized to be the value of globaloutput plugin.
 */
export function createModuleOutputCreationActions(globalWidget: PluginTemplate, globalOutputs: string[]) {
  if (!globalOutputs || globalOutputs.length === 0) {
    return { updateTemplateAction: null, widgetTemplateUpdates: {} }
  }
  const outputUpdate = Object.fromEntries(globalOutputs.map((output) => [output, `{{${output}.value}}`]))
  const widgetNamespace = globalWidget.get('namespace')
  const newNamespace = widgetNamespace
    ? widgetNamespace.getNamespace().concat([widgetNamespace.getOriginalId()!])
    : [globalWidget.get('template').get('childNamespace')]

  return {
    updateTemplateAction: updateTemplate(
      globalWidget,
      Immutable.Map(outputUpdate),
      new PluginNamespaceInfoImpl(newNamespace),
    ),
    widgetTemplateUpdates: {
      [globalWidget.get('id')]: Object.fromEntries(
        globalOutputs.map((output) => {
          return [output, `{{${output}.value}}`]
        }),
      ),
    },
  }
}

export function dispatchModuleOutputCreationActions(
  dispatch: RetoolDispatch,
  actions: ReturnType<typeof createModuleOutputCreationActions>[],
) {
  startBatchUndoGroup()
  const updateTemplateActions = actions.flatMap((cb) => cb.updateTemplateAction ?? [])
  const widgetTemplateUpdates = actions.reduce((acc, cb) => Object.assign(acc, cb.widgetTemplateUpdates), {})

  updateTemplateActions.forEach(dispatch)
  dispatch(batchWidgetTemplateUpdate(widgetTemplateUpdates))
  stopBatchUndoGroup()
}

export function getGlobalWidgetsFromTemplate(appTemplate: AppTemplate) {
  const templates = appTemplate.get('plugins')
  const globalWidgets = templates
    .filter((plugin) => plugin.get('subtype') === GLOBAL_WIDGET_TYPE)
    .valueSeq()
    .toArray()
  return globalWidgets
}

const pageUuidCache = new SimpleCache<string, SafeAny>(1000 * 30)

export function pageLoadByUuid(pageUuid: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    const cachedPage = pageUuidCache.get(pageUuid)
    if (cachedPage) {
      return cachedPage
    }
    const isPageWithinEmbeddedPage = onEmbeddedPage()
    const result = await dispatch(
      callApi({
        endpoint: '/api/pages/lookupPageByUuid',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pageUuid,
          showLatest: true,
          mode: 'view',
          isPageWithinEmbeddedPage,
        }),
        types: [
          'REQUEST_PAGE_BY_UUID_LOAD',
          {
            type: 'RECEIVE_PAGE_BY_UUID_LOAD',
          },
          {
            type: 'FAILURE_PAGE_BY_UUID_LOAD',
          },
        ],
      }),
    )
    pageUuidCache.put(pageUuid, result)
    return result
  }
}

export const formatBackendModulesResponse = (modules: { [uuid: string]: Omit<NestedGlobalWidget, 'appTemplate'> }) => {
  return mapValues(modules, (m) => {
    return {
      ...m,
      appTemplate: deserializeSave(m.data.appState),
    }
  })
}

// Note: this function does not differ between public and non-public applications, and thus you need to be authenticated to call lookupPageByUuid.
export async function getChildPage(pageUuid: string, pageName?: string) {
  const childrenResults = await UNSAFE_dispatch(pageLoadByUuid(pageUuid))
  if (childrenResults.error) {
    // eslint-disable-next-line no-console
    console.error(
      `Unable to load page for UUID ${pageUuid}. Status: ${childrenResults.payload.status}, Message: ${childrenResults.payload.messag}`,
    )

    if (childrenResults.payload?.response?.data?.pageNotAccessible) {
      const errorMessage = `Could not find module ${pageName || ''} - was the module deleted?`
      Message.error(errorMessage, 5)
      // eslint-disable-next-line no-console
      console.error(errorMessage, pageUuid)
    } else {
      Message.error(childrenResults.payload?.response?.message, 5)
    }

    return undefined
  }
  const appTemplate = deserializeSave(childrenResults.payload.page.data.appState)
  return {
    page: childrenResults.payload,
    appTemplate,
    nestedModules: formatBackendModulesResponse(childrenResults.payload.modules ?? {}),
  }
}

export function getGlobalInputs(appTemplate: AppTemplate) {
  const originalChildPlugins = appTemplate.get('plugins')
  return getGlobalInputsFromPlugin(originalChildPlugins)
}

function getGlobalInputsFromPlugin(plugin: Immutable.Map<string, PluginTemplate>) {
  const inputs = plugin
    .filter((v) => v.get('subtype') === 'GlobalWidgetProp')
    .keySeq()
    .toArray()
  return inputs
}

export function getGlobalOutputs(appTemplate: AppTemplate) {
  const originalChildPlugins = appTemplate.get('plugins')
  const outputs = originalChildPlugins
    .filter((v) => v.get('subtype') === 'GlobalWidgetOutput')
    .keySeq()
    .toArray()
  return outputs
}

/**
 * Takes the template of the child widget and processes it so the plugins are ready to be added to the parent app.
 * This means appending namespaces to all the plugins and other misc. tweaks.
 */
export async function getPluginsForGlobalWidget(
  namespaceName: string,
  widget: PluginTemplate,
  currentModuleGraph = new DepGraph<string>(),
  nestedGlobalWidgetsCache: NestedGlobalWidgets = {},
) {
  const uuid = widget.get('template').get('pageUuid')
  const cachedWidget = nestedGlobalWidgetsCache[uuid]

  const childSave =
    cachedWidget?.appTemplate || (await getChildPage(uuid, widget.get('template').get('name')))?.appTemplate
  if (childSave === undefined) {
    const errorMessage = `Could not find module for ${widget.get('template').get('name')} - was the module deleted?`
    Message.error(errorMessage)
    // eslint-disable-next-line no-console
    console.error(errorMessage, uuid)
    return { childPlugins: [], globalInputs: [], initGlobalOutputsCallbacks: [], preloadedJS: [] }
  }
  const [migratedChildSave] = migrateAppTemplate(childSave)
  const containerId = widget.get('id')
  const originalChildPlugins = migratedChildSave.get('plugins')
  return processGlobalPlugins(
    namespaceName,
    widget,
    containerId,
    originalChildPlugins,
    migratedChildSave,
    currentModuleGraph,
    nestedGlobalWidgetsCache,
  )
}

export async function processGlobalPlugins(
  namespaceName: string,
  widget: PluginTemplate,
  containerId: string,
  originalChildPlugins: Immutable.OrderedMap<string, PluginTemplate>,
  childSave: AppTemplate,
  currentModuleGraph: DepGraph<string>,
  nestedGlobalWidgetsCache: NestedGlobalWidgets = {},
) {
  const uuid = widget.get('template').get('pageUuid')
  const containerNamespace = widget.get('namespace') // we are in a nested global widget

  // Compute new namespace by appending to the current one
  const namespaceArray = containerNamespace
    ? containerNamespace.getNamespace().concat([containerNamespace.getOriginalId()!])
    : [namespaceName]

  const globalOutputCallbacks: ReturnType<typeof createModuleOutputCreationActions>[] = []
  const globalOutputs = getGlobalOutputs(childSave)
  globalOutputCallbacks.push(createModuleOutputCreationActions(widget, globalOutputs))

  // Add the namespace and container for all the child widgets
  // TODO dmitriy add more docs about whats going on here
  const additionalPlugins = originalChildPlugins.filter((v, k) => k !== GLOBAL_WIDGET_GRID) // Ignore the module container

  const childPluginsObj: { [k: string]: PluginTemplate } = {}
  const nestedChildrenPlugins: Immutable.Map<string, PluginTemplate>[] = []

  const preloadedJSNamespacePrefix = createPreloadedJSPrefix(uuid)
  const preloadedJS = childSave.preloadedAppJavaScript ?? ''
  const namespacedPreloadedJS: string[] = [namespacePreloadedJSInModule(preloadedJS, preloadedJSNamespacePrefix)]
  for (const [k, v] of additionalPlugins.entries()) {
    const namespace = new PluginNamespaceInfoImpl(namespaceArray, k)
    const namespacedKey = addNamespace(namespace, k)

    const namespacedValue = namespaceWindowReferencesInPlugin(
      v.set('id', namespacedKey).set('namespace', namespace),
      preloadedJS,
      preloadedJSNamespacePrefix,
    )

    if (v.get('subtype') === 'GlobalWidgetProp') {
      const propPath = containerNamespace ? containerNamespace.getOriginalId()! : containerId
      // The value that is set for this prop in the global widget serves as the default value in the parent app
      // (in the case that no value is provided for the prop in the parent app)
      const defaultValue = namespacedValue.getIn(['template', 'defaultValue'])

      childPluginsObj[namespacedKey] = namespacedValue.set(
        'template',
        Immutable.Map({ value: `{{${ss([propPath, k])}}}`, defaultValue }),
      )
    } else if (v.get('subtype') === 'GlobalWidgetQuery') {
      const queryName = v.get('id')
      const mapping = widget.get('template').get(queryName)
      let template = namespacedValue.get('template')
      if (mapping) {
        GLOBAL_QUERY_PASS_THROUGH_PROPERTIES.forEach((k) => {
          template = template.set(k, buildGlobalQueryPassThroughPropertyTemplate(mapping, k))
        })
      }
      childPluginsObj[namespacedKey] = namespacedValue.set('template', template)
    } else {
      const existingContainer = namespacedValue.getIn(['position2', 'container'])
      const newContainer = getChildWidgetContainer(containerId, namespace, existingContainer)
      const containeredNamespacedValue = namespacedValue
        .set('position2', v.get('position2')?.set('container', newContainer))
        .set('mobilePosition2', v.get('mobilePosition2')?.set('container', newContainer))
      if (v.get('subtype') === GLOBAL_WIDGET_TYPE) {
        // Our global widget has a nested global widget inside of it. Lets keep track of it and make sure there are no cycles (aka infinite nesting)
        currentModuleGraph.addNode(uuid)
        currentModuleGraph.addNode(v.get('template').get('pageUuid'))
        currentModuleGraph.addDependency(uuid, v.get('template').get('pageUuid'))

        // Uh-oh, looks like we found an infinite loop!
        try {
          currentModuleGraph.dependenciesOf(uuid)
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(
            "Error: the module you're trying to add contains the current module, which is not allowed.",
            err.message,
          )
          Message.error("Error: the module you're trying to add contains the current module, which is not allowed.")
          currentModuleGraph.removeDependency(uuid, v.get('template').get('pageUuid'))
          return { childPlugins: [], globalInputs: [], initGlobalOutputsCallbacks: [], preloadedJS: [] }
        }

        // recursively parse any global widgets inside the global widget
        const {
          childPlugins: nestedPlugins,
          globalInputs: nestedInputs,
          initGlobalOutputsCallbacks: nestedOutputCallbacks,
          preloadedJS: nestedPreloadedJS,
        } = await getPluginsForGlobalWidget(
          v.get('id'),
          containeredNamespacedValue,
          currentModuleGraph,
          nestedGlobalWidgetsCache,
        )

        const valueWithProps = initializeGlobalWidgetProps(
          v.get('id'),
          nestedInputs,
          containeredNamespacedValue.get('template'),
        )
        nestedChildrenPlugins.push(...nestedPlugins)
        globalOutputCallbacks.push(...nestedOutputCallbacks)
        namespacedPreloadedJS.push(...nestedPreloadedJS)

        childPluginsObj[namespacedKey] = containeredNamespacedValue.set('template', valueWithProps)
      } else {
        childPluginsObj[namespacedKey] = containeredNamespacedValue
      }
    }
  }
  const childPlugins = Immutable.Map(childPluginsObj)
  const results = [childPlugins].concat(nestedChildrenPlugins)

  return {
    childPlugins: results,
    globalInputs: getGlobalInputs(childSave),
    initGlobalOutputsCallbacks: globalOutputCallbacks,
    preloadedJS: namespacedPreloadedJS,
  }
}

/**
 *
 * If the current widget has a container of GLOBAL_WIDGET_GRID put it into the parent container.
 *
 * If the current widget has a different container (a form for example), namespace that form widget and set
 * that as the container.
 *
 * If the current widget has no currentContainer something went wrong because all module widgets should be inside
 * the GLOBAL_WIDGET_GRID
 */
function getChildWidgetContainer(
  parentId: string,
  namespace: PluginNamespaceInfo,
  currentContainer: string | undefined,
) {
  if (currentContainer && currentContainer !== GLOBAL_WIDGET_GRID) {
    return addNamespace(namespace, currentContainer)
  } else {
    return parentId
  }
}

export function storeNestedModules(modules: NestedGlobalWidgets) {
  return {
    type: STORE_MODULE_TEMPLATES,
    payload: {
      modules,
    },
  }
}

type GlobalWidgetPostCreationType =
  | ((widgetTemplate: PluginTemplate) => (dispatch: RetoolDispatch, getState: () => RetoolState) => Promise<void>)
  | undefined

export async function globalWidgetCreateHelper(
  globalWidgetId: string,
  template: Immutable.Map<string, SafeAny>,
  globalWidgetCycleDetector: DepGraph<string> = new DepGraph(),
): Promise<{
  newTemplate: Immutable.Map<string, SafeAny> | undefined
  globalWidgetPostCreation: GlobalWidgetPostCreationType
}> {
  // If this a Global Widget we load the App it refers to and load up all the children!
  const uuid = template.get('pageUuid')
  const childPage = await getChildPage(uuid)
  if (childPage === undefined) {
    return { newTemplate: undefined, globalWidgetPostCreation: undefined }
  }
  const { page, appTemplate, nestedModules } = childPage

  const globalInputs = getGlobalInputs(appTemplate)
  const newTemplate = initializeGlobalWidgetProps(globalWidgetId, globalInputs, template)

  // This needs to run AFTER the widget has been initialized
  const globalWidgetPostCreation = (widgetTemplate: PluginTemplate) => {
    return async (dispatch: RetoolDispatch, getState: () => RetoolState) => {
      const { childPlugins: additionalPlugins, initGlobalOutputsCallbacks } = await getPluginsForGlobalWidget(
        globalWidgetId,
        widgetTemplate,
        globalWidgetCycleDetector,
        nestedModules,
      )

      dispatch(
        storeNestedModules({
          ...nestedModules,
          [uuid]: {
            moduleUuid: page.uuid,
            moduleSaveId: page.page.id,
            data: page.page.data,
            appTemplate: deserializeSave(page.page.data.appState),
          },
        }),
      )

      const widgetTemplates = []
      for (const additionalPlugin of additionalPlugins) {
        widgetTemplates.push(...additionalPlugin.valueSeq().toArray())
      }

      // Create all the widgets in the module
      dispatch({
        type: BATCH_WIDGET_TEMPLATE_CREATE,
        payload: { templates: widgetTemplates },
      })
      // Add all widgets in the module to the dependency graph
      await dispatch(batchAddNewTemplate(widgetTemplates))

      // Update the Retool model to take into account the widgets in this module
      // This only renders template strings of the children of the global widget (i.e. plugins that start with global1::)
      await dispatch(calculateNewTemplateForGlobalChildren(widgetTemplates))

      dispatchModuleOutputCreationActions(dispatch, initGlobalOutputsCallbacks)

      // Trigger any queries that need to run on load for the widget
      const stack = getState().appModel.executionCallStacks.createStack([])
      dispatch(
        batchExecOnTemplateUpdates(
          widgetTemplates.map((plugin) => {
            return { id: plugin.get('id'), type: plugin.get('subtype') }
          }),
          { userTriggered: false, pageLoad: true, stackId: stack.id },
        ),
      )
    }
  }

  return { newTemplate, globalWidgetPostCreation }
}

export function getNamespaceFriendlySelector(selector: string): string {
  if (!selector) {
    return selector
  }

  return selector.replace(NAMESPACE_SEPARATOR, ESCAPED_NAMESPACE_SEPARATOR)
}

export function getGlobalPropTemplate(globalPropNamespace: PluginNamespaceInfo, appTemplate: AppTemplate) {
  const globalWidgetTemplate = getGlobalWidgetFromNamespace(appTemplate, globalPropNamespace)

  const globalWidgetPropTemplate = globalWidgetTemplate?.getIn([
    'template',
    globalPropNamespace.getOriginalId(), // e.g. myWidgetProp1
  ])

  return globalWidgetPropTemplate
}

function getGlobalWidgetFromNamespace(
  appTemplate: AppTemplate,
  namespace: PluginNamespaceInfo,
): PluginTemplate | undefined {
  // To find a plugin from its namespace, we need to look at the plugin's childNamespace in case the
  // plugin has been renamed
  const pluginNamespace = last(namespace.getNamespace())
  return appTemplate.get('plugins').find((pluginTemplate, _key) => {
    return pluginTemplate.getIn(['template', 'childNamespace']) === pluginNamespace
  })
}

export function createPreloadedJSPrefix(moduleUuid: string): string {
  return `$INTERNAL_${moduleUuid.replace(/-/g, '')}`
}

export function createdNamespacedPreloadedJS(modules: NestedGlobalWidgets) {
  return Object.values(modules).map((module) => {
    const jsNamespace = createPreloadedJSPrefix(module.moduleUuid)
    const js = module.appTemplate.get('preloadedAppJavaScript')?.trim() ?? ''
    if (!js) {
      return ''
    }
    return `
// Module: ${jsNamespace}
window.${jsNamespace} = {};
((window) => {
  ${js}
})(window.${jsNamespace})`
  })
}

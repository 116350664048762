import React from 'react'
import { LaunchDarklyResourceSettings } from 'common/records'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'
import { generateResourceForm } from 'components/ResourceForm/resourceFormGenerator/generator'
import { AuthTemplate } from 'components/ResourceForm/resourceFormGenerator/generator-types'

const defaultOptions: LaunchDarklyResourceSettings = {
  options: {
    customQueryParameters: [['', '']],
    customHeaders: [['Authorization', 'API_KEY']],
    authentication: 'apiKey',
    api_key: '',
    spec: `${RETOOL_SPECS_DOMAIN}/launchdarkly/2.0/5.0.2.json`,
  },
}

const auth: AuthTemplate = [
  {
    type: 'apiKey',
    options: {
      label: 'Personal access token',
      description: (
        <>
          Enter your secret LaunchDarkly personal access token above. To retrieve or create a token refer to the{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://docs.launchdarkly.com/home/account-security/api-access-tokens#creating-api-access-tokens"
          >
            Creating API access tokens
          </a>{' '}
          section of the LaunchDarkly documentation.
        </>
      ),
    },
  },
]

export default generateResourceForm('LaunchDarkly', defaultOptions, { auth, hideTestConnection: () => true })

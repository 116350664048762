import { createSelector } from 'reselect'
import {
  editorPrivilegedSelector,
  isOnPremSelector,
  organizationSelector,
  planSelector,
  settingsSelector,
  userSelector,
  usersSelector,
} from 'store/selectors'
import { BILLING_PLAN_IDS, IS_ON_PREM, LICENSE_TYPE_TRIAL } from 'retoolConstants'
import _ from 'lodash'
import moment from 'moment'
import { LOCAL_TRIAL_KEY } from 'store/constants'

//This is a function so we can mock it in testing since I haven't figured out how to mock the hostname
export const onLocalHost = () => window.location.hostname.includes('localhost')

export const licenseStatusSelector = createSelector(userSelector, (user) => {
  return user.licenseStatus
})

export const licenseKeySelector = createSelector(userSelector, (user) =>
  user.getIn(['orgInfo', 'organization', 'licenseKey']),
)

export const currentOrgPlanIdSelector = createSelector(organizationSelector, (org) => {
  return _.get(org, ['plan', 'stripePlanId'], null)
})

export const planIdSelector = createSelector(organizationSelector, (organization) => {
  return _.get(organization, ['planId'])
})

export const trialExpiryDateSelector = createSelector(organizationSelector, (organization) => {
  return _.get(organization, ['trialExpiryDate'])
})

export const onCloudTrialSelector = createSelector(
  planIdSelector,
  trialExpiryDateSelector,
  (planId, trialExpiryDate) => {
    return planId == null && trialExpiryDate != null && moment(trialExpiryDate).isAfter()
  },
)

export const hasBillingDetailsSelector = createSelector(organizationSelector, (organization) => {
  return !!_.get(organization, ['stripeCustomerId'], null)
})

/*
 * This license key is used either when the user is on an unpaid trial of Retool locally or
 * if they're on limited usage self service on prem.
 */
export const onLocalTrialSelector = createSelector(licenseKeySelector, (licenseKey) => {
  return licenseKey === LOCAL_TRIAL_KEY
})

export const onPremSelfServiceSelector = createSelector(
  onLocalTrialSelector,
  licenseKeySelector,
  (onLocalTrial, licenseKey) => {
    return IS_ON_PREM && (onLocalTrial || (licenseKey && licenseKey.startsWith('SSOP_')))
  },
)

export const licenseExpiredSelector = createSelector(licenseStatusSelector, (licenseStatus) => {
  return licenseStatus.get('expired')
})

export const licenseOrTrialExpiredSelector = createSelector(licenseStatusSelector, (licenseStatus) => {
  return licenseStatus.get('expired') || licenseStatus.get('trialExpired')
})

export const ssopUserEmailSelector = createSelector(licenseStatusSelector, (licenseStatus) => {
  return licenseStatus.get('ssopUserEmail')
})

export const onTrialSelector = createSelector(
  licenseStatusSelector,
  onLocalTrialSelector,
  planSelector,
  (licenseStatus, onLocalTrial, plan) => {
    return licenseStatus.get('licenseType') === LICENSE_TYPE_TRIAL || (onLocalTrial && plan === null)
  },
)

export const licenseMessageSelector = createSelector(
  licenseStatusSelector,
  onTrialSelector,
  onPremSelfServiceSelector,
  licenseKeySelector,
  usersSelector,
  (licenseStatus, onTrial, onPremSelfService, licenseKey, users) => {
    const licenseType = licenseStatus.get('licenseType')
    const expiresSoon = licenseStatus.get('expiresSoon')
    const expiresIn = licenseStatus.get('expiresIn')
    const expired = licenseStatus.get('expired')
    const trialExpiresIn = licenseStatus.get('trialExpiresIn')
    const trialExpired = licenseStatus.get('trialExpired')
    const isLocalTrialKey = licenseKey === LOCAL_TRIAL_KEY
    const numUsers = users?.size ?? 1

    if (licenseType === 'notFound') {
      return 'Your license key could not be found, please ensure that you have configured it correctly. You will be unable to create or edit Retool pages.'
    }

    if (licenseType === 'unknown') {
      return 'You are currently using an unlicensed Retool copy! You will be unable to create or edit Retool pages.'
    }

    if (onPremSelfService && licenseType === LICENSE_TYPE_TRIAL) {
      if (trialExpired) {
        return 'Your trial has ended.'
      } else {
        return `Your trial is ending ${trialExpiresIn}`
      }
    }

    if (expired) {
      if (isLocalTrialKey && numUsers === 1) {
        return 'Your trial has ended. Your usage is unlimited for 1 user, but you need to pick a plan to add more.'
      } else if (licenseType === LICENSE_TYPE_TRIAL || isLocalTrialKey) {
        return 'Your trial has ended. You will be unable to create or edit Retool pages.'
      } else {
        return 'Your subscription has ended. You will be unable to create or edit Retool pages.'
      }
    }

    /**
     * We only want to show the trial ending message in isLocalTrial state
     * We don't want to show subscription ending in any state.
     */
    if (expiresSoon) {
      if (licenseType === LICENSE_TYPE_TRIAL || isLocalTrialKey) {
        return `Your trial is ending ${expiresIn}`
      } else if (!onPremSelfService) {
        return `Your subscription is ending ${expiresIn}`
      }
    }
  },
)

export const trialEndsVerySoonSelector = createSelector(licenseStatusSelector, (licenseStatus) => {
  const licenseType = licenseStatus.get('licenseType')
  if (licenseType === LICENSE_TYPE_TRIAL) {
    return licenseStatus.get('expiresVerySoon')
  }
  return false
})

export const shouldShowPublicAppsSelector = createSelector(
  onPremSelfServiceSelector,
  (onPremSelfService) => !onPremSelfService,
)

const salesCTADismissedSelector = createSelector(
  userSelector,
  (user) => user.getIn(['user', 'salesCTADismissed']) as boolean,
)

export const salesCTAShownSelector = createSelector(
  onTrialSelector,
  editorPrivilegedSelector,
  salesCTADismissedSelector,
  (isTrial, isPrivileged, dismissed) => {
    return isTrial && isPrivileged && !dismissed
  },
)

export const onPremBillingSelectedSelector = createSelector(settingsSelector, (settings) => {
  return settings?.billingPageInOnPremMode ?? false
})

export const currentOnPremPlanIdSelector = createSelector(organizationSelector, (org) => {
  return _.get(org, ['onpremStripePlanId'], null)
})

export const onEnterprisePlanSelector = createSelector(
  planIdSelector,
  onPremSelfServiceSelector,
  trialExpiryDateSelector,
  currentOnPremPlanIdSelector,
  onPremBillingSelectedSelector,
  (planId, ssop, expiryDate, onPremPlanId, onPremBillingPortal) => {
    return planId == null && expiryDate == null && !ssop && !onPremBillingPortal
  },
)

export const currentPlanNameSelector = createSelector(
  organizationSelector,
  onEnterprisePlanSelector,
  currentOrgPlanIdSelector,
  currentOnPremPlanIdSelector,
  onCloudTrialSelector,
  onPremBillingSelectedSelector,
  (org, onEnterprisePlan, currentCloudPlan, currentOnpremPlan, onCloudTrial, onBillingPortal) => {
    const currentPlanId = onBillingPortal ? currentOnpremPlan : currentCloudPlan
    if (onEnterprisePlan) {
      return 'enterprise'
    } else if (currentPlanId === BILLING_PLAN_IDS.cloudPlus || currentPlanId === BILLING_PLAN_IDS.onpremPlus) {
      return 'startup'
    } else if (
      currentPlanId === BILLING_PLAN_IDS.cloudPro ||
      (onCloudTrial && !onBillingPortal) ||
      currentPlanId === BILLING_PLAN_IDS.onpremPro
    ) {
      return 'pro'
    } else {
      return 'free'
    }
  },
)

export const shouldShowBillingPlans = createSelector(
  isOnPremSelector,
  onPremSelfServiceSelector,
  (isOnPrem, onPremSelfService) => {
    return !isOnPrem || onPremSelfService
  },
)

import { useEffect, EffectCallback, DependencyList, useRef } from 'react'

/**
 * An effect that is only called when the value of a dependency changes, not on initial mount
 */
export default function useUpdateEffect(effect: EffectCallback, deps: DependencyList): void {
  const mounted = useRef(false)

  useEffect(() => {
    if (mounted.current) {
      effect()
    } else {
      mounted.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

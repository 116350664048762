import { b64toBlob } from 'common/files'
import { UnknownObject } from 'common/types'
import { PluginMethodConfig } from 'components/plugins'
import compact from 'lodash/compact'
import { isPlainObject } from 'types/typeguards'

const downloadFile: PluginMethodConfig = {
  metadata: {
    label: 'Download file',
    example: '`utils.downloadFile(data, fileName, fileType)`',
    description: 'Downloads data using fileName (string) and fileType (string) if possible.',
  },
  method: async (data, fileName, fileType) => {
    if (!data) {
      // eslint-disable-next-line no-console
      console.log('Invalid data provided')
      return
    }

    let blob = null
    if (isPlainObject(data)) {
      if (data.base64Binary) {
        // binary file
        blob = new Blob([b64toBlob(data.base64Binary, fileType, 512)])
      } else {
        // json file
        blob = new Blob([JSON.stringify(data)], { type: 'application/json' })
      }
    } else {
      blob = new Blob([data as BlobPart])
    }

    if (!fileName) fileName = (data as UnknownObject).fileName || 'file'
    if (!fileType) fileType = (data as UnknownObject).fileType || ''

    // note: chrome downloads a file without a file type as a txt file
    // ex: 'file' downloads as 'file.txt'
    const saveName = compact([fileName, fileType]).join('.')

    const { saveAs } = await import('file-saver')
    return saveAs(blob, saveName)
  },
}

export default downloadFile

import React from 'react'
import { Checkbox } from 'components/design-system'
import { HostInputField, InputField, SSHTunnelForm } from './common'
import { ResourceFormProps } from '../resources/types'

export const databaseDefaults = {
  host: '',
  port: '5984',
  databaseUsername: '',
  databasePassword: '',
  ssl: false,
  options: {},
}

export const databaseValidator = (resource: any) =>
  resource.displayName && resource.type && resource.host && resource.port

const CouchDBForm = (props: ResourceFormProps) => (
  <SSHTunnelForm {...props}>
    <>
      <hr />
      <h5 className="section-heading light-gray mb12">General</h5>
      <div className="grid-1c mb20">
        <HostInputField {...props} />
        <InputField label="Port" placeholder="5984" resourceKey="port" topLevel {...props} />

        <InputField label="Database Username" placeholder="retool" resourceKey="databaseUsername" topLevel {...props} />
        <InputField
          label="Database Password"
          placeholder="••••••••"
          resourceKey="databasePassword"
          topLevel
          password
          {...props}
        />

        <Checkbox
          checked={props.resource.ssl}
          onChange={(checked) => {
            props.onChangeTopLevel('ssl')(checked.target.checked)
          }}
          className="grid-offset-1"
        >
          Connect using SSL
        </Checkbox>
      </div>
    </>
  </SSHTunnelForm>
)

export default {
  label: 'CouchDB',
  form: CouchDBForm,
  defaults: {
    ...databaseDefaults,
  },
  validator: databaseValidator,
}

import { QueryFailureConditions, UntransformedQueryResponse } from 'common/records'
import * as evaluator from 'common/evaluator'
import { isNaN, some } from 'lodash'
import { QueryRunError } from 'components/plugins/datasources/common/utils'
import { SafeAny } from 'common/types'
import { message } from 'antd'
import { RetoolDispatch, RetoolState } from 'store'

export function getQueryNotificationDuration(queryId: string) {
  return (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const rawMessageDuration = getState().appModel.values.getQuery(queryId).get('notificationDuration')
    return isNaN(parseFloat(rawMessageDuration)) ? undefined : parseFloat(rawMessageDuration)
  }
}

export const displayQueryFailureToast = (messageString: string, templateModel: SafeAny, queryId: string) => {
  return (dispatch: RetoolDispatch) => {
    const messageDuration = dispatch(getQueryNotificationDuration(queryId))
    if (templateModel.get('showFailureToaster')) {
      message.warning(messageString, messageDuration)
    }
  }
}

export const evaluateQueryFailureConditions = (
  templateModel: SafeAny,
  extendedModel: SafeAny,
  untransformedResponse: UntransformedQueryResponse,
  queryId: string,
  maybeError: QueryRunError | null,
  rawResponse: SafeAny,
) => {
  return async (dispatch: RetoolDispatch) => {
    const queryFailureConditions = JSON.parse(
      templateModel.get('queryFailureConditions') || '[]',
    ) as QueryFailureConditions
    const queryHasFailureConditions = templateModel.get('showFailureToaster') && queryFailureConditions.length > 0

    let metAnyFailureConditions = false
    let failureConditionMessages: string[] = []

    if (queryHasFailureConditions) {
      // evaluate if any failure conditions were met for the query
      const evalState = {
        ...extendedModel,
        ...untransformedResponse,
      }

      const failureConditionsEvaluationPromises = queryFailureConditions.map((check) =>
        evaluator.evaluateSafe(evalState, check.condition),
      )
      const metFailureConditions = (await Promise.all(failureConditionsEvaluationPromises)).map(Boolean)
      metAnyFailureConditions = some(metFailureConditions)

      // display failure notifications
      const failureConditionMessagesPromises = metFailureConditions.map((conditionMet, i) => {
        if (conditionMet) {
          return evaluator.evaluateSafe(evalState, queryFailureConditions[i].message)
        }
      })
      failureConditionMessages = (await Promise.all(failureConditionMessagesPromises)).filter(Boolean)
      failureConditionMessages.forEach((m) => {
        dispatch(displayQueryFailureToast(m, templateModel, queryId))
      })
    }

    if (metAnyFailureConditions) {
      let errorData: string | string[] =
        failureConditionMessages.length === 1 ? failureConditionMessages[0] : failureConditionMessages

      if (!errorData || errorData.length === 0) {
        errorData = 'Query returned an error'
      }
      throw new QueryRunError(failureConditionMessages.join(', '), rawResponse, errorData, 'FAILURE_CONDITION', {
        hideToast: true,
      })
    }

    if (maybeError) {
      throw maybeError
    }
  }
}

export const throwIfErrorTransformerActivated = async (
  templateModel: SafeAny,
  data: SafeAny,
  metadata: SafeAny,
  errors: SafeAny,
  queryId: string,
  rawResponse: SafeAny,
): Promise<void> => {
  const queryHasErrorTransformer = templateModel.get('enableErrorTransformer') && templateModel.get('errorTransformer')

  if (queryHasErrorTransformer) {
    const errorMessage = await evaluator.evaluateQueryErrorTransformer(
      queryId,
      { data, metadata, errors },
      templateModel,
    )

    if (errorMessage) {
      throw new QueryRunError(errorMessage, rawResponse, errorMessage, 'ERROR_TRANSFORMER')
    }
  }
}

import React, { CSSProperties } from 'react'
import { RetoolState } from 'store'
import { Menu } from 'antd'
import { Dropdown, MenuHeader, MenuLink } from 'components/design-system/Dropdown'
import { WorkspaceGroupPageArray, currentPageUuidSelector, onHomeSelector } from 'store/selectors'
import { Icon } from 'components/design-system'
import { hasPresentationModeFeatureSelector } from 'common/paymentPlans'
import { getLink } from 'common/utils'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ProfilePhoto, { ProfilePhotoUser } from 'components/standards/ProfilePhoto'
import { authorizedWorkspacesSelector } from 'store/selectors/userSelectors'
import logout from 'networking/logout'
import { TopNavigationProps } from './types'
import Clickable from 'components/Clickable'
import { RETOOL_VERSION } from 'retoolConstants'
import AppMenu from 'routes/Presentation/components/AppMenu'
import _styles from "./UserTools-styles.module.scss";


const styles = _styles

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StyledMenuLink = (props: any & { highlight: boolean }) => {
  const { highlight, style, ...propsToPass } = props
  const sharedMenuLinkStyle: CSSProperties = {
    color: 'var(--near-black)',
    whiteSpace: 'normal',
    height: 'auto',
    fontFamily: 'var(--sans-serif)',
    fontStyle: 'normal',
    ...(highlight ? { background: 'var(--faint-blue)' } : {}),
  }
  return <MenuLink {...propsToPass} style={{ ...style, ...sharedMenuLinkStyle }} />
}

const isDisplayable = (text: string | null) => text && text.trim().length > 0

const renderPositionLine = (permissionLabel: string, companyName: string) => {
  if (isDisplayable(companyName)) {
    return (
      <span>
        {permissionLabel} <span className="lighter-gray">•</span> {companyName}
      </span>
    )
  } else {
    return <span>{permissionLabel}</span>
  }
}

const renderNameLine = (name: string) => {
  return isDisplayable(name) ? (
    <div title={name} className="fw-600 dark-gray">
      {name}
    </div>
  ) : (
    <div className="fw-600 dark-gray">Unset</div>
  )
}

type UserToolsProps = {
  authorizedWorkspaces: WorkspaceGroupPageArray
  hasPresentationModeFeature: boolean
  currentPageUuid?: string
  onHome: boolean
  retoolPill?: React.ReactElement
}

const UserTools = (props: UserToolsProps & TopNavigationProps) => {
  const {
    canAdmin,
    user,
    displayName,
    permissionLabel,
    companyName,
    theme,
    currentPageUuid,
    onHome,
    hasPresentationModeFeature,
    authorizedWorkspaces,
    retoolPill,
    showOmnibox,
  } = props

  const nameLine = renderNameLine(displayName)
  const positionLine = renderPositionLine(permissionLabel, companyName)

  let profilePhotoStyle = {}
  if (props.useCustomThemeIfAvailable && theme && theme.headerIconColor) {
    profilePhotoStyle = { background: theme.headerIconColor }
  }
  const profilePhoto = <ProfilePhoto user={user as ProfilePhotoUser} style={profilePhotoStyle} />
  const profileSlab = (
    <div className="flex items-center">
      {profilePhoto}
      <div className="ml12 truncate fw-500 light-gray">
        {nameLine}
        {positionLine}
      </div>
    </div>
  )

  const showWorkspaces = authorizedWorkspaces.length > 0

  /* Note: there is a bug/flaw in Ant Design (related to https://github.com/ant-design/ant-design/issues/4853)
      where if you try to embed the MenuLink components in another component (e.g. WorkspaceLinks), MenuLinks
      won't close once clicked (which is why we can't extract the workspaces links to another component).
      This is also why we can't wrap the Menu.Divider, MenuHeader and StyledMenuLink with one conditional "showWorkspaces" */
  const workspaces = showWorkspaces ? (
    <div className="mb4">
      <div className="workspaces-header ph16 mt8 mb4">
        <span className="light-gray fw-500">Select a workspace</span>
      </div>
      <StyledMenuLink routedLink="/" label="Default Workspace" highlight={onHome} />
      {authorizedWorkspaces.map((workspace) => {
        const { name } = workspace.group
        const workspaceName = `${name.length > 0 ? name.charAt(0).toUpperCase() + name.slice(1) : 'Unnamed'} Workspace`
        const link = getLink('read', workspace.page.path, hasPresentationModeFeature)
        return (
          <StyledMenuLink
            key={workspace.workspace.id}
            routedLink={link}
            label={workspaceName}
            highlight={currentPageUuid === workspace.page.uuid}
          />
        )
      })}
      <hr />
    </div>
  ) : null

  const menu = (
    <Menu className="retool-dropdown-menu top-nav-user-menu clear-padding" mode="vertical">
      <MenuHeader>{profileSlab}</MenuHeader>

      {retoolPill
        ? [
            <StyledMenuLink key="home" routedLink="/" label="Back to home" />,
            <StyledMenuLink key="search" onSelected={showOmnibox} label="Search" />,
            <AppMenu key="browse_apps" inRetoolPill={true} label="Browse Apps" />,
            <hr key="line_break" />,
            <StyledMenuLink key="settings" routedLink="/settings" label="Settings" />,
          ]
        : [
            workspaces,
            <StyledMenuLink key="settings" routedLink="/settings" label="Settings" />,
            <StyledMenuLink key="audit_logs" routedLink="/audit" label="Audit logs" visible={canAdmin} />,
            <StyledMenuLink key="data_editor" routedLink="/datasources" label="Data editor" visible={canAdmin} />,
          ]}

      <Menu.Divider />
      <StyledMenuLink onSelected={logout} label="Logout" />
      <div className={styles.footerSection}>
        <div className={styles.label}>Retool version {RETOOL_VERSION}</div>
      </div>
    </Menu>
  )

  const children = retoolPill ? (
    <div className={styles.pillContainer}>{retoolPill}</div>
  ) : (
    <Clickable className={styles.main}>
      {profilePhoto}
      <Icon type="caret-down" />
    </Clickable>
  )

  const overlayStyle = retoolPill ? { left: 16, width: 185 } : { width: 200 }

  return (
    <Dropdown
      overlayClassName="user-utility-dropdown large"
      overlayStyle={overlayStyle}
      placement="bottomRight"
      trigger={['click']}
      overlay={menu}
    >
      {children}
    </Dropdown>
  )
}

const mapStateToProps = (state: RetoolState) => {
  return {
    authorizedWorkspaces: authorizedWorkspacesSelector(state),
    hasPresentationModeFeature: hasPresentationModeFeatureSelector(state),
    currentPageUuid: currentPageUuidSelector(state),
    onHome: onHomeSelector(state),
  }
}

export default withRouter(connect(mapStateToProps)(UserTools))

import range from 'lodash/range'
import { isPlainObject } from 'types/typeguards'
import { dispatch, getState } from 'store'
import { addNamespace } from 'common/utils/namespaces'
import { Mock, PluginNamespaceInfo } from 'common/records'
import { UnknownObject } from 'common/types'
import { utilMethods } from './jsApiUtils'
import {
  getAllPluginMethodsExceptEvents,
  getLocalStorageMethods,
  LOCAL_STORAGE_ID,
} from 'store/appModel/initalizePluginApi'
import { queriesSelector } from 'store/selectors/querySelectors'
import { addMockForTest } from 'store/appModel/mockApplicationTest'
import { extendScopeWithJsApi } from './extendScopeWithJsApi'

type UnknownPlugin = {
  pluginType: string
  namespace?: PluginNamespaceInfo
  [key: number]: UnknownObject
} & UnknownObject

// Similar to `generateJsApi` except it returns a full scope with functions merged inside it.
// Do not pass this extended scope into the sandbox because it contains functions which cannot
// be cloned via postMessage
export function generateExtendedScopeWithJsApi(scope: UnknownObject, testId?: string) {
  const jsApi = generateJsApi(scope, testId)
  return extendScopeWithJsApi(scope, jsApi)
}

const addTestGlobalMethods = (methods: {}, testId: string) => {
  return {
    ...methods,
    mock: (queryId: string, returnValue: {}) => {
      const queries = queriesSelector(getState())
      if (!queries.some((query) => query.id === queryId)) {
        throw new Error('Failure in mock. Please provide a valid query.')
      }
      const mock: Mock = { queryId, returnValue }
      dispatch(addMockForTest(mock, testId))
    },
  }
}

// This produces an object whose shape mirrors the scope, but only
// contain jsAPI functions,
//
// Use `generateExtendedScopeWithJsApi` if you want to merge jsAPI with the actual scope
//
// For example,
// Scope of {
//  textInput1: { value: 'foo', ...other },
//  textinput2: { value: 'bar', ...other }
// }
//
// will produce:
// {
//   localStorage: { setItem: () => {..}, getItem: () => {..}}
//   textInput1: { setValue: (value) => {..} },
//   textinput2: { setValue: (value) => {..} }
// }
//
export function generateJsApi(scope: UnknownObject, testId?: string) {
  const state = getState()
  const appModel = state?.appModel

  let scopeWithOnlyFunctions: UnknownObject = {
    utils: utilMethods,
  }

  Object.keys(scope).forEach((key) => {
    const value = scope[key]
    const insideOfTest = testId !== undefined

    if (key === LOCAL_STORAGE_ID && isPlainObject(value)) {
      scopeWithOnlyFunctions[key] = getLocalStorageMethods(insideOfTest)
      return
    }

    const { namespace, pluginType } = value as UnknownPlugin
    const namespacedId = namespace ? addNamespace(namespace, key) : key

    const { inListView, instances } = appModel?.pluginInListView(namespacedId)

    if (inListView && instances !== null) {
      const listViewScope = {} as UnknownObject

      range(instances).forEach((index) => {
        const plugin = { namespace, pluginType }
        listViewScope[index] = getAllPluginMethodsExceptEvents(state, plugin, key, testId, index)
      })

      scopeWithOnlyFunctions[key] = listViewScope
    } else {
      scopeWithOnlyFunctions[key] = getAllPluginMethodsExceptEvents(
        state,
        value as UnknownPlugin,
        key,
        testId,
        undefined,
      )
    }
  })

  if (testId !== undefined) {
    scopeWithOnlyFunctions = addTestGlobalMethods(scopeWithOnlyFunctions, testId)
  }

  return scopeWithOnlyFunctions
}

export default generateExtendedScopeWithJsApi

export const PRECONFIGURED_OPENAPI_SPECS = [
  'airflow',
  'asana',
  'basecamp',
  'bigid',
  'circleci',
  'closeio',
  'datadog',
  'dropbox',
  'front',
  'github',
  'googleAnalytics',
  'googleMaps',
  'googleSearchConsole',
  'hubspot',
  'jira',
  'launchdarkly',
  'onesignal',
  'openAI',
  'sendgrid',
  'stripe',
  'twilio',
] as const

// The name of a free db that users get to power the database editor
export const MANAGED_DB = 'managed_db' as const

export type BasicBranchType = {
  name: string
  sha: string
  url: string
  protected: boolean
}

import { PluginSubtype, PluginTemplate } from 'common/records'
import { computeTemplateStringDependencies, updateCode } from './dependencyGraph'
import { PluginPropertyAnnotationsResolver } from 'components/plugins'
import { RetoolActionDispatcher } from 'store'
import recalculateModelSelectors from './recalculateModelSelectors'
import parseJSExpression from '../../common/parseJSExpression'

// approximate type for esprima expression
// from: https://esprima.readthedocs.io/en/latest/syntax-tree-format.html
type ExpresssionStatement = {
  type: string
  expression: {
    type: string
    left?: {
      object?: {
        name: string
      }
      property?: {
        name: string
      }
    }
  }
}
export function getVariablesAssignedToWindow(code: string): Set<string> {
  try {
    const ast = parseJSExpression(code)

    const assignToWinowExpressions: ExpresssionStatement[] = ast.body.filter((e: ExpresssionStatement) => {
      if (e.type !== 'ExpressionStatement') {
        return false
      }

      if (e.expression.type !== 'AssignmentExpression') {
        return false
      }

      if (e.expression?.left?.object?.name !== 'window') return false

      return true
    })

    return new Set(assignToWinowExpressions.map((e) => e.expression.left?.property?.name ?? ''))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error parsing preloaded JS', code, e)
    return new Set()
  }
}

function replaceWindowReferencesInTemplateString(
  subtype: PluginSubtype,
  templateKey: string,
  code: unknown,
  namespace: string,
  dependenciesToUpdate: Set<string>,
) {
  if (typeof code !== 'string') {
    return code
  }

  const propertyAnnotations = PluginPropertyAnnotationsResolver(subtype)
  const unescapeRetoolExpressions = !!propertyAnnotations[templateKey]?.unescapeRetoolExpressions
  const deps = computeTemplateStringDependencies(code, { unescapeRetoolExpressions })

  let newCode = code
  for (const dep of deps) {
    // only namespace variables that aren't in the app template
    if (dependenciesToUpdate.has(dep[0])) {
      newCode = updateCode(newCode, dep[0], `${namespace}.${dep[0]}`)
    }
  }

  return newCode
}

export function namespaceWindowReferencesInPlugin(
  plugin: PluginTemplate,
  preloadedJS: string,
  namespace: string,
): PluginTemplate {
  const dependenciesToUpdate = getVariablesAssignedToWindow(preloadedJS)
  return plugin.withMutations((p) => {
    p.template.forEach((val: unknown, key: string) => {
      p.setIn(
        ['template', key],
        replaceWindowReferencesInTemplateString(p.subtype, key, val, namespace, dependenciesToUpdate),
      )
    })
  })
}

export function namespacePreloadedJSInModule(code: string, namespaceRef: string) {
  if (!code) {
    return code
  }
  const newCode = updateCode(code, 'window', `window.${namespaceRef}`)

  return `window.${namespaceRef} = {};\n${newCode}`
}

export function rerenderModuleSelectors(): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    const model = getState().appModel
    const dependencyGraph = model.dependencyGraph

    const selectorsToReerender = Object.values(dependencyGraph.getNodes())
      .filter((node) => !!node.namespace)
      .map((node) => node.selector)
    await dispatch(recalculateModelSelectors(selectorsToReerender))
  }
}

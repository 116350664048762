import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import { StringLiteral, UnknownObject } from 'common/types'

export function makeAssert<T>(check: (value: unknown) => value is T) {
  return (value: unknown, message: string): asserts value is T => {
    if (!check(value)) throw new Error(message)
  }
}

export const isNumber = (value: unknown): value is number => typeof value === 'number'
export const assertIsNumber: (value: unknown, message: string) => asserts value is number = makeAssert(isNumber)

export const isBoolean = (value: unknown): value is number => typeof value === 'boolean'
export const assertIsBoolean: (value: unknown, message: string) => asserts value is boolean = makeAssert(isBoolean)

export const isString = (value: unknown): value is string => typeof value === 'string'
export const assertIsString: (value: unknown, message: string) => asserts value is string = makeAssert(isString)

export const isStringOrNumber = (value: unknown): value is string | number =>
  typeof value === 'string' || typeof value === 'number'
export const assertStringOrNumber: (value: unknown, message: string) => asserts value is string | number = makeAssert(
  isStringOrNumber,
)

type LatLng = { latitude: number | string; longitude: number | string }
export const isLatLng = (value: unknown): value is LatLng =>
  isPlainObject(value) && 'latitude' in value && 'longitude' in value

export const assertLatLng: (value: unknown, message: string) => asserts value is LatLng = makeAssert(isLatLng)

export const assertArray: (value: unknown, message: string) => asserts value is unknown[] = makeAssert(Array.isArray)

const isStringOrArray = (value: unknown): value is string | unknown[] => Array.isArray(value) || isString(value)

export const assertStringOrArray: (value: unknown, message: string) => asserts value is string | unknown[] = makeAssert(
  isStringOrArray,
)

export const assertStringOrArrayOrFalsy: (
  value: unknown,
  message: string,
) => asserts value is string | unknown[] = makeAssert(isStringOrArray)

export const isStringOrNumberArray = (value: unknown): value is (string | number)[] => {
  return value instanceof Array && _.every(value, isStringOrNumber)
}

export const assertStringOrNumberArray: (value: unknown, message: string) => asserts value is string[] = makeAssert(
  isStringOrNumberArray,
)

export const isStringArray = (value: unknown): value is string[] => {
  return Array.isArray(value) && value.every((item) => typeof item === 'string')
}

export const assertStringArray: (value: unknown, message: string) => asserts value is string[] = makeAssert(
  isStringArray,
)

export const isArrayOfArrays = (val: unknown): val is unknown[][] => {
  return _.isArray(val) && _.every(val.map(_.isArray))
}

// https://stackoverflow.com/questions/52531303/
type SomeNonNullable<T, TKey> = { [P in keyof T]: P extends TKey ? NonNullable<T[P]> : T[P] }
export const propertiesAreNotNull = <T, TKey extends keyof T>(...props: TKey[]) => (
  values: T,
): values is SomeNonNullable<T, TKey> => {
  for (const key of props) {
    if (values[key] == null) return false
  }
  return true
}

/**
 * used for pattern matching, e.g. in a switch statement.
 *
 * when used in the `default` case of our reducers, turn `warn` to false.
 * this allows the typechecker to assert that all action types that should be handled by a
 * particular reducer have been handled, but prevents warnings from being thrown when the
 * `default` case is called (as will be the case when actions that have no effect on the
 * reducer are fired).
 */
export const assertNever = (argument: never, warn = true): null => {
  if (warn) {
    if (__DEV__) {
      // eslint-disable-next-line no-console
      console.warn(`Hit assertNever. Types must be wrong! ${argument}`)
    }
    Sentry.captureMessage(`Hit assertNever. Types must be wrong! ${argument}`)
  }
  return null
}

export const isPlainObject = (val: unknown): val is UnknownObject => _.isPlainObject(val)

export const assertPlainObject: (value: unknown, message: string) => asserts value is UnknownObject = makeAssert(
  isPlainObject,
)

export const isPlainObjectOrArray = (value: unknown): value is UnknownObject | unknown[] =>
  isPlainObject(value) || Array.isArray(value)

export const assertPlainObjectOrArray: (
  value: unknown,
  message: string,
) => asserts value is UnknownObject | unknown[] = makeAssert(isPlainObjectOrArray)

/**
 * A type guard for nullish values, primarily for use with `.filter` on arrays.
 *
 * @example arr.filter(isNotNullish)
 */
export function isNotNullish<T>(value: T | null | undefined): value is T {
  return value != null
}

/**
 * A type guard to determine if `value` is one of the string literals in `arr`.
 *
 * Only for use with arrays of string literals. For other array types, use `Array.includes`.
 */
export function includes<T extends string>(
  arr: ReadonlyArray<StringLiteral<T>>,
  value: string,
  fromIndex?: number,
): value is T {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return arr.includes(value as StringLiteral<T>, fromIndex)
}

export const assertStringLiteral: <T extends string>(
  value: unknown,
  options: ReadonlyArray<StringLiteral<T>>,
  message: string,
) => asserts value is T = (value, options, message) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  makeAssert(<T extends string>(val: unknown): val is T => options.includes(value as any))(value, message)

export const isPrimitive = (value: unknown): value is string | boolean | number | null | undefined =>
  value == null || ['string', 'boolean', 'number'].includes(typeof value)

export const assertPrimitive: (
  value: unknown,
  message: string,
) => asserts value is string | boolean | number | null | undefined = makeAssert(isPrimitive)

import React from 'react'

import { InputField } from './common'
import { ResourceFormProps } from './types'

type SMTPFormOptions = {
  host: string
  port: string
  username: string
  password: string
}

type SMTPFormFields = {
  options: SMTPFormOptions
}

const defaultOptions: SMTPFormFields = {
  options: {
    host: '',
    port: '',
    username: '',
    password: '',
  },
}

const SMTPForm = (props: ResourceFormProps) => {
  return (
    <div>
      <div className="grid-1c mb12">
        <div className="grid-offset-1">Enter your SMTP server credentials below.</div>
      </div>
      <div className="grid-1c mb12">
        <InputField label="Host" resourceKey="host" required={true} {...props} />
        <InputField label="Port" resourceKey="port" {...props} />
        <InputField label="Username" resourceKey="username" {...props} />
        <InputField label="Password" placeholder="••••••••" resourceKey="password" password {...props} />
      </div>
    </div>
  )
}

function isNumeric(value: string): boolean {
  return /^-?\d+$/.test(value)
}

export default {
  label: 'SMTP',
  form: SMTPForm,
  defaults: {
    options: defaultOptions,
  },
  validator: ({ options }: { options: SMTPFormOptions }) => options.host && (!options.port || isNumeric(options.port)),
}

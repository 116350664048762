export const COLORS = {
  BLACK: '#000',
  BLUE: '#00f',
  DARKGRAY: '#ddd',
  GRAY: '#eee',
  GREEN: '#0f0',
  RED: '#f00',
  WHITE: '#fff',
}

export const KEYS = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
}

export const BLUR_DELAY = 200

export const DEFAULT_HEIGHT = 70

import { useEffect, useRef } from 'react'

/**
 * An effect that is called when a component is unmounting, similar to `componentWillUnmount`
 */
export default function useUnmountEffect(effect: () => void): void {
  const effectRef = useRef(effect)
  effectRef.current = effect

  useEffect(
    () => () => {
      effectRef.current()
    },
    [],
  )
}

import React, { ReactNode } from 'react'
import { CallbackURLField, InputField } from '../common'
import { ResourceFormProps } from '../types'

function OAuthAppInput(props: ResourceFormProps & { customAppDescription: ReactNode }) {
  const { customAppDescription } = props
  return (
    <>
      <div className="grid-offset-1">{customAppDescription}</div>

      <CallbackURLField {...props} />

      <InputField label="Client ID" resourceKey="oauth2_client_id" inputAriaLabel="Client ID" {...props} />

      <InputField label="Client secret" resourceKey="oauth2_client_secret" inputAriaLabel="Client secret" {...props} />
    </>
  )
}

export default OAuthAppInput

import * as React from 'react'

export const baseProperties = {
  data: {
    name: 'data',
    label: 'Query data',
    docs: (
      <div>
        <p>
          You can use the <code>.data</code> property to refer to the result of this query.
        </p>
      </div>
    ),
  },
  error: {
    name: 'error',
    label: 'Query error',
    docs: 'If the query encountered an error while running, this field will be populated with the error message',
  },
  requestSentTimestamp: {
    name: 'requestSentTimestamp',
    label: '',
    docs: 'The time the query request was sent',
  },
  finished: {
    name: 'finished',
    label: 'Finish time',
    docs: 'The time the query finished executing',
  },
  queryDisabled: {
    name: 'queryDisabled',
    label: '',
    docs: 'Whether or not this query is disabled',
  },
  queryRunTime: {
    name: 'queryRunTime',
    label: '',
    docs: 'How long the query took to execute',
  },
  runWhenPageLoads: {
    name: 'runWhenPageLoads',
    label: '',
    docs: 'Whether or not this query auto runs on page load',
  },
  runWhenModelUpdates: {
    name: 'runWhenModelUpdates',
    label: '',
    docs: "Whether or not this query automatically refreshes when it's parameters change",
  },
}

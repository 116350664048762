import React, { Component } from 'react'

import cookies from 'js-cookie'

import { connect } from 'react-redux'

import { login, signup, claimInvitation } from '../modules/login'
import { preAuthOrgData } from './../../../store/globals'
import SignupContainer from './SignupContainer'
import ConfirmResetPasswordPage from './../pages/ConfirmResetPasswordPage'
import LoginPage from './../pages/LoginPage'
import ResetPasswordPage from './../pages/ResetPasswordPage'
import VerifyEmailPage from './../pages/VerifyEmailPage'
import EmailVerificationCheckPage from './../pages/EmailVerificationCheckPage'
import JoinOrgPage from '../pages/JoinOrgPage'

import { GlobalsReducerType, PreAuthOrgDataAsyncActionType } from './../../../store/globals.types'
import { RetoolState } from './../../../store/index'

import './AuthContainer.scss'
import './../../../components/AuthLayout/AuthLayout.scss'
import './../../Editor/components/Editor.scss'
import {
  isAirgappedSelector,
  globalsSelector,
  loginSelector,
  signupTokenSelector,
  signupEmailSelector,
} from 'store/selectors'
import { maybeRedirectToUrl } from 'routes/Login/logic/redirects'

interface AuthContainerProps {
  location: any // <-- improve type after refactoring `location.ts & upgrading react-router`
  pageName: string
  signupToken?: string
  signupEmail?: string
  loginData: any // <-- improve type after refactoring `login.js`
  login: (a: any, b: any) => any // <-- improve type after refactoring `login.js`
  signup: (v: any) => any // <-- improve type after refactoring `login.js`
  claimInvitation: (v: any) => any // <-- improve type after refactoring `login.js`
  globals: GlobalsReducerType
  preAuthOrgData: PreAuthOrgDataAsyncActionType
  isAirgapped: boolean
}

class AuthContainer extends Component<AuthContainerProps> {
  componentDidMount() {
    const { preAuthOrgData } = this.props
    preAuthOrgData()
    maybeRedirectToUrl()
  }

  render() {
    const {
      claimInvitation,
      location,
      login,
      loginData,
      pageName,
      globals,
      signup,
      signupToken,
      signupEmail,
      isAirgapped,
    } = this.props

    const { clientId, restrictedDomain, ssoType, disableUserPassLogin } = globals

    if (pageName.includes('login')) {
      return (
        <LoginPage
          clientId={clientId}
          error={loginData.error}
          isFetching={loginData.isFetching}
          login={login}
          redirectUri={location.query.redirect_uri}
          restrictedDomain={restrictedDomain}
          disableUserPassLogin={disableUserPassLogin}
          ssoType={ssoType}
        />
      )
    }

    if (pageName.includes('signup') || pageName.includes('invite')) {
      return (
        <SignupContainer
          claimInvitation={claimInvitation}
          clientId={clientId}
          error={loginData.error}
          invite={loginData.invite}
          isFetching={loginData.isFetching}
          restrictedDomain={restrictedDomain}
          disableUserPassLogin={disableUserPassLogin}
          ssoType={ssoType}
          signup={signup}
          signupToken={signupToken}
          signupEmail={signupEmail}
          signupTheme={location.query.integration}
        />
      )
    }

    if (pageName.includes('resetPassword')) {
      return (
        <ResetPasswordPage
          error={loginData.error}
          isFetching={loginData.isFetching}
          restrictedDomain={restrictedDomain}
          disableUserPassLogin={disableUserPassLogin}
          isAirgapped={isAirgapped}
        />
      )
    }

    if (pageName.includes('confirmResetPassword')) {
      return (
        <ConfirmResetPasswordPage
          error={loginData.error}
          isFetching={loginData.isFetching}
          location={location}
          restrictedDomain={restrictedDomain}
          disableUserPassLogin={disableUserPassLogin}
        />
      )
    }
    if (pageName.includes('verifyEmail')) {
      return (
        <VerifyEmailPage
          partialRegistrationType={location.query.partialRegistrationType}
          partialRegistrationId={location.query.partialRegistrationId}
          email={location.query.email}
          domain={location.query.domain}
          joinToken={location.query.joinToken}
        />
      )
    }
    if (pageName.includes('confirmEmail')) {
      return <EmailVerificationCheckPage registrationToken={location.query.registrationToken} />
    }

    if (pageName.includes('joinOrg')) {
      return <JoinOrgPage />
    }

    return <div />
  }
}

const mapStateToProps = (state: RetoolState, ownProps: any) => {
  let templateId = null
  const redirectUrl = cookies.get('redirectOnLogin')
  if (redirectUrl) {
    const isTemplateUrl = redirectUrl.indexOf('clone-template') !== -1
    if (isTemplateUrl) {
      templateId = redirectUrl.slice('/clone-template/'.length)
    }
  }

  return {
    pageName: ownProps.children.props.route.pageName,
    loginData: loginSelector(state),
    signupToken: signupTokenSelector(state),
    signupEmail: signupEmailSelector(state),
    templateId,
    globals: globalsSelector(state),
    isAirgapped: isAirgappedSelector(state),
  }
}

const mapDispatchToProps = {
  preAuthOrgData,
  login,
  signup,
  claimInvitation,
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer)

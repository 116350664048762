import TwoFactorAuth from './components/TwoFactorAuth'
import SetupTwoFactorAuth from './components/SetupTwoFactorAuth'

// Sync route definition
export default {
  onEnter: () => {
    document.title = 'Retool'
    window.onbeforeunload = () => {}
  },
  childRoutes: [
    {
      path: 'two-factor-challenge',
      component: TwoFactorAuth,
      onEnter: () => {
        document.title = 'Two Factor | Retool'
      },
    },
    {
      path: 'two-factor-challenge-setup',
      component: SetupTwoFactorAuth,
      onEnter: () => {
        document.title = 'Two Factor Setup | Retool'
      },
    },
  ],
}

import React, { useState } from 'react'
import moment from 'moment'
import { deleteSessionStateByKey, resourceLoad } from 'routes/Resources/modules/resources'
import { connect } from 'react-redux'
import { RetoolState } from 'store'
import { currentResourceEncryptedOauthSessionStatesByEnvSelector } from 'store/selectors'
import { ResourceFormProps } from '../types'
import { Table, Tag } from 'antd'
import { Modal, Button, Icon, Message } from 'components/design-system'
import { EncryptedOauthSessionStateType } from 'common/records'

const OAUTH_SESSION_TOKENS = [
  {
    key: 'oauth2_access_token',
    title: 'Access token',
  },
  {
    key: 'oauth2_refresh_token',
    title: 'Refresh token',
  },
  {
    key: 'oauth2_id_token',
    title: 'ID token',
  },
]

const sessionStateToData = (
  encryptedOauthSessionStates: {
    [key: string]: EncryptedOauthSessionStateType
  },
  sessionState: { key: string; title: string },
) => {
  const { title, key } = sessionState
  return {
    type: title,
    exists: !!encryptedOauthSessionStates[key],
    lastUpdated: encryptedOauthSessionStates[key]?.updatedAt,
    key,
  }
}

type SessionStateDataType = ReturnType<typeof sessionStateToData>

const OauthDebugHelper = (
  props: ResourceFormProps &
    ReturnType<typeof mapStateToProps> & {
      resourceLoad: (resourceName: string, includeEncryptedOauthSessionState?: boolean) => Promise<void>
      deleteSessionStateByKey: (resourceId: string, key: string) => Promise<void>
    },
) => {
  const [isVisible, setIsVisible] = useState(false)
  const [isLoadingLatestTokens, setIsLoadingLatestTokens] = useState(false)
  const [isLoadingRevokeToken, setIsLoadingRevokeToken] = useState(false)
  const { encryptedOauthSessionStatesByEnv } = props

  const isLoading = !encryptedOauthSessionStatesByEnv?.[props.resource.environment]
  const encryptedOauthSessionStates = encryptedOauthSessionStatesByEnv?.[props.resource.environment] || {}

  const oauthDebugColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (sessionStateType: string) => (
        <span>
          <Tag color="blue">{sessionStateType}</Tag>
        </span>
      ),
    },
    {
      title: 'Exists',
      dataIndex: 'exists',
      key: 'exists',
      render: (exists: boolean) => (
        <span>{!isLoading && <Tag color={exists ? 'green' : 'volcano'}>{exists ? 'Yes' : 'No'}</Tag>}</span>
      ),
    },
    {
      title: 'Last updated',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      render: (lastUpdated: string) => <p>{lastUpdated ? moment(lastUpdated).format('MMM Do YYYY, h:mm:ss a') : ''}</p>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: SessionStateDataType) => (
        <span>
          {!isLoading && record.exists && (
            <a
              onClick={async () => {
                setIsLoadingRevokeToken(true)
                await props.deleteSessionStateByKey(props.resource.id, record.key)
                await props.resourceLoad(props.resource.name)
                setIsLoadingRevokeToken(false)
              }}
            >
              Revoke
            </a>
          )}
        </span>
      ),
    },
  ]

  const data = OAUTH_SESSION_TOKENS.map((oauthSessionToken) =>
    sessionStateToData(encryptedOauthSessionStates, oauthSessionToken),
  )

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }} className="mb8">
      <a
        onClick={async () => {
          props.resourceLoad(props.resource.name)
          setIsVisible(true)
        }}
      >
        See token status
      </a>
      <Modal
        title={
          <div className="flex items-center">
            <div>Your Oauth session tokens</div>
          </div>
        }
        destroyOnClose
        width={720}
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        footer={null}
      >
        These are the Oauth tokens that exist for your <strong>{props.resource.displayName}</strong> resource session.
        Revoking tokens can help with testing your apps, e.g. revoke an access token to test the refresh workflow for a
        resource.
        <br />
        <br />
        <Table
          columns={oauthDebugColumns}
          dataSource={data}
          pagination={false}
          loading={isLoading || isLoadingRevokeToken}
        />
        <br />
        <div className="flex justify-end">
          <Button
            type="secondary"
            onClick={async () => {
              setIsLoadingLatestTokens(true)
              await props.resourceLoad(props.resource.name)
              setIsLoadingLatestTokens(false)
              Message.success('Showing latest tokens')
            }}
            loading={isLoadingLatestTokens}
          >
            <Icon type="refresh" className="ml4" />
          </Button>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state: RetoolState) => {
  return {
    encryptedOauthSessionStatesByEnv: currentResourceEncryptedOauthSessionStatesByEnvSelector(state),
  }
}

const mapDispatchToProps = {
  resourceLoad,
  deleteSessionStateByKey,
}

export default connect(mapStateToProps, mapDispatchToProps)(OauthDebugHelper)

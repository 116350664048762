import React from 'react'
import { connect } from 'react-redux'
import { authCustomThemeSelector, isLoadingPreAuthOrgDataSelector } from 'store/selectors'
import { MonochromeRetoolLogoFull } from '../../../common/logo'
import { RetoolState } from 'store'
import { ThemeType } from 'store/user.d'
import './AuthRetoolLogo.scss'
import cookies from 'js-cookie'
import { REDIRECTING_TO_BILLING_PORTAL_COOKIE_NAME } from 'store/constants'
import { resourceStyles } from 'retoolConstants'

type AuthRetoolLogoProps = {
  className?: string
  theme?: ThemeType
  isLoadingPreAuthOrgData: boolean
  signupIntegration?: string
}
const AuthRetoolLogo = (props: AuthRetoolLogoProps) => {
  if (props.isLoadingPreAuthOrgData) {
    return <div className={props.className} />
  }
  const { signupIntegration } = props
  const redirectingToBilling = cookies.get(REDIRECTING_TO_BILLING_PORTAL_COOKIE_NAME) != null

  let resourceStyle

  if (signupIntegration && signupIntegration in resourceStyles) {
    resourceStyle = resourceStyles[signupIntegration as keyof typeof resourceStyles]
  }
  return (
    <div className={props.className}>
      {props.theme && props.theme.logoFileBase64String ? (
        <div className="custom-logo">
          <img src={props.theme.logoFileBase64String} height="32" width="80" />
        </div>
      ) : (
        <div className="flex fd-row">
          <div className="flex items-center">
            <MonochromeRetoolLogoFull {...props} desc="Retool Logo" />
            {resourceStyle && (
              <div className="mb20 flex items-center">
                <div className="plus-sign">+</div>
                <img className="integration-logo" src={resourceStyle.logo} />
                <h6 className="ml8">{resourceStyle.label}</h6>
              </div>
            )}
          </div>
          {redirectingToBilling && <div className="login-banner flex justify-center items-center">Retool cloud</div>}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: RetoolState) => ({
  theme: authCustomThemeSelector(state),
  isLoadingPreAuthOrgData: isLoadingPreAuthOrgDataSelector(state),
})

export default connect(mapStateToProps)(AuthRetoolLogo)

import * as owasp from 'owasp-password-strength-test'

// Pass a hash of settings to the `config` method. The settings shown here are
// the defaults.
owasp.config({
  allowPassphrases: true,
  maxLength: 128,
  minLength: 8,
  minPhraseLength: 20,
  minOptionalTestsToPass: 4,
})

export default {
  test: (_pwd: any) => {
    return {
      errors: [],
    } as any
  },
}

import React, { useState } from 'react'

import { Button } from 'components/design-system'

import MoveItemsModal from '../modals/MoveItemsModalContainer'

interface MoveAppsButtonProps {
  selectedPageIds: number[]
  deselectItems: () => void
}

const MoveAppsButton = (props: MoveAppsButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false)

  const closeModal = () => {
    setModalOpen(false)
  }

  const openModal = () => {
    setModalOpen(true)
  }

  if (props.selectedPageIds.length === 0) {
    return <></>
  }

  return (
    <>
      <Button className="folder-view__create-button" type="primary" onClick={openModal}>
        Move apps ...
      </Button>
      <MoveItemsModal
        visible={modalOpen}
        closeModal={closeModal}
        selectedPageIds={props.selectedPageIds}
        deselectItems={props.deselectItems}
      />
    </>
  )
}

export default MoveAppsButton

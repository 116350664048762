import { performClientSideHttpRequest, ClientSideHttpRequestProperties } from 'common/utils'

import { POST } from 'common/fetch'
import { enableCustomPlatformLevelAuthStepsSelector } from './selectors'
import logout from 'networking/logout'
import { getState } from 'store'

async function performAuthStep(stepNum?: number, customScope?: {}) {
  // eslint-disable-next-line no-console
  console.log('Beginning global authorization step')

  try {
    const body = await POST(
      '/api/resourceAuth/processStep',
      {},
      {},
      {
        sourceUri: window.location.href.split('#')[0],
        resourceId: undefined,
        resourceName: undefined,
        stepNum,
        customScope,
        showDebugInfo: false,
        isPlatformLevelAuthSteps: true,
      },
    )

    if (body.actionRequired) {
      switch (body.type) {
        case 'client_side_http_request': {
          const { res, status } = await performClientSideHttpRequest(body.properties as ClientSideHttpRequestProperties)

          if (status >= 400) {
            throw new Error('Client side http request returned error')
          }

          performAuthStep(body.stepNum + 1, {
            [`http${body.stepNum + 1}`]: { body: res },
          })
          break
        }
        default:
          // eslint-disable-next-line no-console
          console.error(`Auth step of type ${body.type} not supported`)
          break
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Failed platform level auth steps. Logging out.')
    logout()
  }
}

export async function processCustomPlatformLevelAuthSteps() {
  const enableCustomPlatformLevelAuthSteps = enableCustomPlatformLevelAuthStepsSelector(getState())

  if (enableCustomPlatformLevelAuthSteps) {
    await performAuthStep(0)
  } else {
    // eslint-disable-next-line no-console
    console.error('Custom platform level auth steps are disabled')
  }
}

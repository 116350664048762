import { ReactNode } from 'react'
import { ResourceFormProps } from '../resources/types'
import { AvailableScope } from '../resources/components/OAuthScopeRadioInput'

export const GENERATOR_AUTH_TYPES = ['oauth2', 'apiKey', 'custom'] as const
export type SupportedAuthType = typeof GENERATOR_AUTH_TYPES[number]
export type AuthFieldType = 'token'
export type AuthTemplate = [ResourceAuth] | [ResourceApiKeyAuth, ResourceOauth2Auth]
export type ResourceAuth = ResourceOauth2Auth | ResourceApiKeyAuth | ResourceCustomAuth

export type AuthField = {
  type: AuthFieldType
  fieldName: string
  label: string
  required?: boolean
  description?: ReactNode
}

export type ResourceAuthTemplate = {
  auth: AuthTemplate
  hideTestConnection?: () => boolean
  hideCreateResourceButton?: () => boolean
  isOAuth?: (resource: ResourceFormProps['resource']) => boolean
}

export type ResourceOauth2Auth = {
  type: 'oauth2'
  options: {
    retoolHostedApp?: {}
    customApp: {
      description: ReactNode
    }
    showHostedApp?: () => boolean
    isShareableAcrossUsers?: boolean
    availableScopes?: AvailableScope[]
  }
}

export type ResourceApiKeyAuth = {
  type: 'apiKey'
  options: {
    label: string
    description?: ReactNode
    customHeaders?: [string, string][]
    customQueryParameters?: [string, string][]
  }
}

export type ResourceCustomAuth = {
  type: 'custom'
  fields: AuthField[]
}

export type Validation = {
  type: 'nonEmpty'
  field: string
}

export function isOauth2Auth(auth: ResourceAuth): auth is ResourceOauth2Auth {
  return auth.type === 'oauth2'
}

export function isApiKeyAuth(auth: ResourceAuth): auth is ResourceApiKeyAuth {
  return auth.type === 'apiKey'
}

export function isCustomAuth(auth: ResourceAuth): auth is ResourceCustomAuth {
  return auth.type === 'custom'
}

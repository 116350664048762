import { OnboardingStep, OnboardingSubStep } from 'common/records'
import { ResourceType } from 'common/resourceTypes'
import { fromJS } from 'immutable'
import _ from 'lodash'
import {
  ALL_TABLE_COLUMNS,
  BuildingBlock,
  BuildingBlockGeneratorInput,
  BuildingBlockId,
  FILTER_TEXT_INPUT_ID_PLACEHOLDER,
  GOOGLE_SHEET_PLACEHOLDER,
  TABLE_FILTER_BUILDING_BLOCK,
  TABLE_PLACEHOLDER,
  TARGET_COLUMN,
  TARGET_COLUMN_PLACEHOLDER,
  WidgetBuildingBlock,
} from '../buildingBlocks'
import { TABLE_ID } from './viewTabularData'
import { allResourceSheets, allResourcesInput, allResourceTables } from '../generatorInputs'
import { SafeAny } from 'common/types'

const searchQueryName = 'tableDataWithSearch'
const buildingBlockLabel = 'Filter Data'

const constructBuildingBlock = () => {
  const widgets = []
  const queries = []

  const tableBuildingBlock: WidgetBuildingBlock = {
    id: `${TABLE_ID}`,
    type: 'TableWidget',
    parentBuildingBlockId: TABLE_FILTER_BUILDING_BLOCK,
    options: {
      data: {
        value: `{{${searchQueryName}.data}}`,
        priority: 2,
      },
    },
    mergeWidget: true,
    hideInPreview: true,
  }

  const textBuildingBlock: WidgetBuildingBlock = {
    id: 'textOne',
    type: 'TextInputWidget',
    parentBuildingBlockId: TABLE_FILTER_BUILDING_BLOCK,
    mergeWidget: false,
    options: {
      value: {
        value: '',
        priority: 1,
      },
      label: {
        value: `Filter by ${TARGET_COLUMN_PLACEHOLDER}`,
        priority: 1,
      },
    },
  }

  widgets.push(tableBuildingBlock)
  widgets.push(textBuildingBlock)

  const postgresSearchBuildingBlock = generatePostgresBuildingBlock()
  const googleSheetsBuildingBlock = generateGoogleSheetsBuildingBlock()
  const mysqlBlock = _.cloneDeep(postgresSearchBuildingBlock)
  mysqlBlock.type = 'mysql'
  mysqlBlock.options.query = `select * from ${TABLE_PLACEHOLDER} where ${TARGET_COLUMN_PLACEHOLDER} like {{"%" +${FILTER_TEXT_INPUT_ID_PLACEHOLDER}.value+ "%"}} limit 100`

  queries.push(postgresSearchBuildingBlock)
  queries.push(mysqlBlock)
  queries.push(googleSheetsBuildingBlock)

  const allTableColumnsSearch: BuildingBlockGeneratorInput = {
    id: TARGET_COLUMN,
    options: ALL_TABLE_COLUMNS,
    label: 'Column to filter on',
  }

  const tableWithSearchBuildingBlock: BuildingBlock = {
    id: 'tableFilter',
    displayName: buildingBlockLabel,
    description: 'Add search functionality to a table',
    widgets,
    queries,
    showInEditor: true,
    generatorInputs: [allResourcesInput, allResourceTables, allResourceSheets, allTableColumnsSearch],
    showInOnboarding: true,
  }

  return tableWithSearchBuildingBlock
}

const generatePostgresBuildingBlock = () => {
  const postgresSelectTabHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `#query-editor__tab-${searchQueryName}`,
    title: `Navigate to ${searchQueryName} in the editor`,
    nextCondition: (_template, _model, editor) => {
      const allSelectedElements = editor.lastSelectedElements
      return allSelectedElements.has(`query-editor__tab-${searchQueryName}`)
      //Query is defined and different from the template's query
    },
  })

  const postgresChangeTableHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `#code-editor-${searchQueryName}`,
    title: 'Confirm the table and column you want to use',
    hintText: `We made a placeholder query that uses ${TARGET_COLUMN_PLACEHOLDER} to filter ${TABLE_PLACEHOLDER}.`,
    nextCondition: (_template, _model, editor) => {
      const allSelectedElements = editor.lastSelectedElements
      return allSelectedElements.has(`code-editor-${searchQueryName}`)
      //Query is defined and different from the template's query
    },
  })

  const postgresOnboardingSubsteps = [postgresSelectTabHint, postgresChangeTableHint]
  const postgresOnboardingStep = new OnboardingStep({
    title: 'Set up your table and search',
    substeps: fromJS(postgresOnboardingSubsteps),
    condition: postgresOnboardingSubsteps[postgresOnboardingSubsteps.length - 1].nextCondition,
  })

  return {
    id: searchQueryName,
    parentBuildingBlockId: TABLE_FILTER_BUILDING_BLOCK as BuildingBlockId,
    type: 'postgresql' as ResourceType,
    options: {
      query: `select * from ${TABLE_PLACEHOLDER} where ${TARGET_COLUMN_PLACEHOLDER}::text like {{"%" +${FILTER_TEXT_INPUT_ID_PLACEHOLDER}.value+ "%"}} limit 100`,
    },
    hintSequence: postgresOnboardingStep,
  }
}

const generateGoogleSheetsBuildingBlock = () => {
  const sheetsTransformer = `const results = data
const filterBy = {{${FILTER_TEXT_INPUT_ID_PLACEHOLDER}.value}}
if (results != null && filterBy.length > 0 ) {
  const filterKey = "${TARGET_COLUMN_PLACEHOLDER}"
  return results.filter( (result) => result[filterKey].includes(filterBy) )
} else {
  return results
}`

  const sheetsAdjustHint: OnboardingSubStep = new OnboardingSubStep({
    //For some reason this causes the bouncing box to appear at the top rather than right next to it :(
    hintNodeSelector: `[data-query="${searchQueryName}"] .input-label[data-label="Spreadsheet"]`,
    hintText: 'Confirm all settings in the top pane',
    nextCondition: (template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      const query = editor.queryState.get(queryStateKey) as SafeAny
      const queryPlugin = template.plugins.get(searchQueryName)?.toJS()
      return query != null && queryPlugin != null && query.get('spreadsheetId') !== queryPlugin.template.spreadsheetId
    },
  })

  const sheetsAdjustTransformerHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${searchQueryName}"] [data-sectiontitle="Transformer"]`,
    hintText: 'adjust what field you look at from the api and what table column you filter on',
    //TODO: (eventually) test this
    nextCondition: (_template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      const queryEditorState = editor.queryState.get(queryStateKey)
      return (
        queryEditorState != null && queryEditorState.get('transformer').indexOf('Object.keys(results[0])[0]') === -1
      )
    },
  })

  const sheetsOnboardingSubsteps = [sheetsAdjustHint, sheetsAdjustTransformerHint]
  const sheetOnboardingStep = new OnboardingStep({
    title: 'Set up your REST resource',
    substeps: fromJS(sheetsOnboardingSubsteps),
    condition: (template, model, _editor) => {
      const data = model.values.getUnsafe(`${TABLE_ID}`).get('data')
      return data.length > 0 && data[0].domain !== 'Acton Vale, QC'
    },
  })

  return {
    id: searchQueryName,
    type: 'googlesheets' as ResourceType,
    parentBuildingBlockId: TABLE_FILTER_BUILDING_BLOCK as BuildingBlockId,
    options: {
      actionType: 'read',
      enableTransformer: true,
      transformer: sheetsTransformer,
      spreadsheetId: `${TABLE_PLACEHOLDER}`,
      sheetName: GOOGLE_SHEET_PLACEHOLDER,
    },
    hintSequence: sheetOnboardingStep,
  }
}

//TODO: Currently only rest is ready, the rest need to be modified
/*const mongoDBBuildingBlock = () => {
  const mongoDBBuildingBlockHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${searchQueryName}"] .cm-evaluation-wrapper`,
    hintText: 'Confirm the table and column you want to use',
    nextCondition: (template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      const queryState = editor.queryState.get(queryStateKey)?.toJS()
      const queryPlugin = template.plugins.get(searchQueryName)?.toJS()
      //Query is defined and different from the template's query
      return (
        queryState?.query != null && queryState.query !== queryPlugin?.template.query && queryState.query.length > 0
      )
    },
  })

  const postgresOnboardingSubsteps = [postgresChangeTableHint]
  const postgresOnboardingStep = new OnboardingStep({
    title: 'Set up your postgres query',
    substeps: fromJS(postgresOnboardingSubsteps),
    condition: postgresOnboardingSubsteps[postgresOnboardingSubsteps.length - 1].nextCondition,
  })

  return {
    id: searchQueryName,
    type: 'mongodb' as ResourceType,
    options: {
      query: `{ : { "$regex": {{textinput1.value ? textinput1.value : {$exists: true} }}, "$options": "i" } }`,
    },
    hintSequence: postgresOnboardingStep,
  }
}*/

/*const generateRestBuildingBlock = () => {
  const restTransformer = `//Our sample api returns a json so we need to get the field with the array of results for the table
//TODO: remove .objects or change it to the field from the api response that has tabular data. Tables expect data with the form [{key: value}].
const objects = data.objects
const filterBy = {{textOne.value}}
if (filterBy.length > 0 ) {
  //TODO: change name to the key you want to filter based on
  return objects.filter( (result) => result.name.includes(filterBy) )
} else {
  return objects
}`

  const restQueryUrl = 'https://represent.opennorth.ca/boundary-sets'
  const restChangeResourceHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${searchQueryName}"] .resourceSelector`,
    hintText: 'Select your api from the resource dropdown ',
    nextCondition: (_template, _model, editor) => {
      //Need to use the editor rather then the template since we want the version before it's saved
      return editor.selectedDatasourceId === searchQueryName && editor.selectedResourceName !== 'REST-WithoutResource'
    },
  })

  const restChangeUrlHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${searchQueryName}"] .http-query-editor .query-scroller`,
    hintText: 'Change the URL to select from your api',
    nextCondition: (_template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      return editor.queryState.get(queryStateKey)?.get('query') !== restQueryUrl
    },
  })

  const restAdjustTransformerHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${searchQueryName}"] [data-sectiontitle="Transformer"]`,
    hintText: 'adjust what field you look at from the api and what table column you filter on',
    nextCondition: (_template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      return editor.queryState.get(queryStateKey)?.get('transformer').length !== restTransformer.length
    },
  })

  const restOnboardingSubsteps = [restChangeResourceHint, restChangeUrlHint, restAdjustTransformerHint]
  const restOnobardingStep = new OnboardingStep({
    title: 'Set up your REST resource',
    substeps: fromJS(restOnboardingSubsteps),
    condition: (template, model, _editor) => {
      const data = model.values.get(`${TABLE_ID}`).get('data')
      return false
      //return data.length > 0 && data[0].domain !== 'Acton Vale, QC'
    },
  })

  return {
    id: searchQueryName,
    type: 'restapi' as ResourceType,
    options: {
      query: 'https://represent.opennorth.ca/boundary-sets',
      type: 'GET',
      enableTransformer: true,
      transformer: restTransformer,
    },
    hintSequence: restOnobardingStep,
  }
}

const generateMysqlBuildingBlock = () => {
  const postgresChangeTableHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${searchQueryName}"] .cm-evaluation-wrapper`,
    hintText: 'Confirm the table and column you want to use',
    nextCondition: (template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      const queryState = editor.queryState.get(queryStateKey)?.toJS()
      const queryPlugin = template.plugins.get(searchQueryName)?.toJS()
      //Query is defined and different from the template's query
      return (
        queryState?.query != null && queryState.query !== queryPlugin?.template.query && queryState.query.length > 0
      )
    },
  })

  const mysqlOnboardingSubsteps = [postgresChangeTableHint]
  const mysqlOnboardingStep = new OnboardingStep({
    title: 'Set up your mysql query',
    substeps: fromJS(mysqlOnboardingSubsteps),
    condition: mysqlOnboardingSubsteps[mysqlOnboardingSubsteps.length - 1].nextCondition,
  })

  return {
    id: searchQueryName,
    type: 'mysql' as ResourceType,
    options: {
      //TODO: make sure that filtering works for mysql
      query: `select * from ${TABLE_PLACEHOLDER} where ${COLUMN_PLACEHOLDER}::text like {{"%" +textOne.value+ "%"}} limit 100`,
    },
    hintSequence: mysqlOnboardingStep,
  }
}

const generateGoogleSheetsBuildingBlock = () => {
  const sheetsTransformer = `const results = data
const filterBy = {{textOne.value}}
if (filterBy.length > 0 ) {
  //TODO: change filterKey to the key you want to filter based on
  const filterKey = Object.keys(results[0])[0]
  return results.filter( (result) => result[filterKey].includes(filterBy) )
} else {
  return results
}`

  const sheetsAdjustHint: OnboardingSubStep = new OnboardingSubStep({
    //For some reason this causes the bouncing box to appear at the top rather than right next to it :(
    hintNodeSelector: `[data-query="${searchQueryName}"] .input-label[data-label="Spreadsheet"]`,
    hintText: 'Confirm all settings in the top pane',
    nextCondition: (template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      const query = editor.queryState.get(queryStateKey) as SafeAny
      const queryPlugin = template.plugins.get(searchQueryName)?.toJS()
      return query != null && queryPlugin != null && query.get('spreadsheetId') !== queryPlugin.template.spreadsheetId
    },
  })

  const sheetsAdjustTransformerHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${searchQueryName}"] [data-sectiontitle="Transformer"]`,
    hintText: 'adjust what field you look at from the api and what table column you filter on',
    //TODO: (eventually) test this
    nextCondition: (_template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      const queryEditorState = editor.queryState.get(queryStateKey)
      return (
        queryEditorState != null && queryEditorState.get('transformer').indexOf('Object.keys(results[0])[0]') === -1
      )
    },
  })

  const sheetsOnboardingSubsteps = [sheetsAdjustHint, sheetsAdjustTransformerHint]
  const sheetOnboardingStep = new OnboardingStep({
    title: 'Set up your REST resource',
    substeps: fromJS(sheetsOnboardingSubsteps),
    condition: (template, model, _editor) => {
      const data = model.values.get(`${TABLE_ID}`).get('data')
      return data.length > 0 && data[0].domain !== 'Acton Vale, QC'
    },
  })

  return {
    id: searchQueryName,
    type: 'googlesheets' as ResourceType,
    options: {
      actionType: 'read',
      enableTransformer: true,
      transformer: sheetsTransformer,
      spreadsheetId: `${TABLE_PLACEHOLDER}`,
    },
    hintSequence: sheetOnboardingStep,
  }
}*/

export default constructBuildingBlock

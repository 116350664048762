import CoreLayout from '../layouts/CoreLayout'
import _404 from './404'
import _500 from './500'
import AuditTrailRoute from './AuditTrails'
import DatasourcesRoute from './Datasources'
import EditorRoute from './Editor'
import EmbeddedRoute from './Embedded'
import FlowsRoute from './Flows'
import Forbidden from './Forbidden'
import Home from './Home'
import LoginRoute from './Login'
import IFrameEmbedRoute from './IFrameEmbed'
import OrganizationUnverified from './OrganizationUnverified'
import PresentationRoute from './Presentation'
import QueryPlaygroundRoute from './QueryPlayground'
import OnPremBillingPortalRoute from './OnPremBillingPortal'
import ResourcesRoute from './Resources'
import SettingsRoute from './Settings'
import SetupRoute from './Setup'
import TwoFactorAuth from './TwoFactorAuth'
import LogoutRoute from './Logout'
import trackPageview from './routeUtils/trackPageview'
import requireLogin from './routeUtils/requireLogin'
import { RouteConfig } from 'react-router'

/*  Note: Instead of using JSX, we recommend using react-router
    PlainRoute objects to build route definitions.   */

export const createRoutes = (store: any): RouteConfig => {
  const refreshIntercom = () => {
    window.Intercom('update')
  }

  return {
    path: '/',
    onChange: (prev, next) => {
      trackPageview(next.location)
      refreshIntercom()
    },
    childRoutes: [
      {
        onEnter: requireLogin(store),
        component: CoreLayout,
        indexRoute: Home(store),
        childRoutes: [
          ...QueryPlaygroundRoute(store),
          ...EditorRoute(store),
          ...PresentationRoute(store),
          SettingsRoute(store),
          OnPremBillingPortalRoute(store),
          DatasourcesRoute(store),
          AuditTrailRoute(store),
          ResourcesRoute(store),
          FlowsRoute(store),
          LogoutRoute(store),
          TwoFactorAuth,
          Home(store),
        ],
      },
      SetupRoute(store),
      {
        onEnter: (nextState, replace, cb) => {
          const state = store.getState()
          if (state.session.user) {
            replace('home')
          }
          trackPageview(nextState.location)
          cb?.()
        },
        childRoutes: [LoginRoute(store), ...EmbeddedRoute(store), IFrameEmbedRoute()],
      },
      {
        path: 'unverified',
        component: OrganizationUnverified,
        onEnter: () => {
          document.title = 'Unverified Organization | Retool'
        },
      },
      {
        path: 'forbidden',
        component: Forbidden,
        onEnter: () => {
          document.title = 'Forbidden | Retool'
        },
      },
      {
        path: '500',
        component: _500,
        onEnter: () => {
          document.title = 'Server Error | Retool'
        },
      },
      {
        path: '*',
        component: _404,
        onEnter: () => {
          document.title = 'Not Found | Retool'
        },
      },
    ],
  }
}

/*  Note: childRoutes can be chunked or otherwise loaded programmatically
    using getChildRoutes with the following signature:

    getChildRoutes (location, cb) {
      require.ensure([], (require) => {
        cb(null, [
          // Remove imports!
          require('./Counter').default(store)
        ])
      })
    }

    However, this is not necessary for code-splitting! It simply provides
    an API for async route definitions. Your code splitting should occur
    inside the route `getComponent` function, since it is only invoked
    when the route exists and matches.
*/

export default createRoutes

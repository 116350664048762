import { message } from 'antd'

import { callInternalApi } from 'networking'

import { retoolAnalyticsTrack } from 'common/retoolAnalytics'

import { RetoolDispatch } from 'store'
import { getOrganization, getProfile } from 'store/user'
import {
  REQUEST_REVOKE_INVITE,
  RECEIVE_REVOKE_INVITE,
  FAILURE_REVOKE_INVITE,
  REQUEST_STRIPE_CUSTOMER_UPDATE,
  RECEIVE_STRIPE_CUSTOMER_UPDATE,
  FAILURE_STRIPE_CUSTOMER_UPDATE,
  REQUEST_PLAN_CHANGE,
  RECEIVE_PLAN_CHANGE,
  FAILURE_PLAN_CHANGE,
  REQUEST_SET_USER_ENABLED_FLAG,
  RECEIVE_SET_USER_ENABLED_FLAG,
  FAILURE_SET_USER_ENABLED_FLAG,
  REQUEST_RESET_USER_TWO_FACTOR_AUTH,
  RECEIVE_RESET_USER_TWO_FACTOR_AUTH,
  FAILURE_RESET_USER_TWO_FACTOR_AUTH,
  FLIP_ONPREM_BILLING_PORTAL,
} from 'store/constants'

// ------------------------------------
// Endpoint Return Types (Should eventually be moved)
// ------------------------------------

// Endpoint `/api/organization/admin/users/${userId}/setTwoFactorAuthEnabledFlag
interface ApiResetTwoFactorAuthUserType {
  createdAt: string
  email: string
  enabled: boolean
  firstName: string
  hasGoogleId: boolean
  id: number
  lastActive: string
  lastLoggedIn: string
  lastName: string
  organizationId: number
  profilePhotoUrl: string | null
  resetPasswordExpires: string | null
  resetPasswordToken: string | null
  sid: string
  twoFactorAuthEnabled: boolean
  updatedAt: string
  userName: string | null
}

interface ApiResetTwoFactorAuthSuccessType {
  user: ApiResetTwoFactorAuthUserType
  message: string
}

// Endpoint `/api/organization/admin/users/${userId}/setEnabledFlag`
interface ApiSetEnabledFlagUserType {
  createdAt: string
  email: string
  enabled: boolean
  firstName: string
  hasGoogleId: boolean
  id: number
  lastActive: string
  lastLoggedIn: string
  lastName: string
  organizationId: number
  profilePhotoUrl: string | null
  resetPasswordExpires: string | null
  resetPasswordToken: string | null
  sid: string
  twoFactorAuthEnabled: boolean
  updatedAt: string
  userName: string | null
}
interface ApiSetEnabledFlagSuccessType {
  user: ApiSetEnabledFlagUserType
  message: string
}

// Endpoint '/api/organization/admin/updateStripeCustomerId'
interface ApiAddStripeTokenToAccountType {
  error: boolean
  message: string
}

// Endpoint '/api/organization/admin/updateSubscriptionPlan'
interface ApiUpdateSubscriptionPlanType {
  error: boolean
  message: string
}

// Endpoint `/api/organization/admin/revokeInvitation`
interface ApiRevokeInvitationType {
  destroyedUserInviteId?: number
  error: boolean
  message: string
}

// ------------------------------------
// Synchronous Actions
// ------------------------------------
interface ResetUserTwoFactorAuthRequestType {
  type: typeof REQUEST_RESET_USER_TWO_FACTOR_AUTH
}
const resetUserTwoFactorAuthRequest = (): ResetUserTwoFactorAuthRequestType => {
  return {
    type: REQUEST_RESET_USER_TWO_FACTOR_AUTH,
  }
}

export interface ResetUserTwoFactorAuthReceiveType {
  type: typeof RECEIVE_RESET_USER_TWO_FACTOR_AUTH
  payload: {
    user: ApiResetTwoFactorAuthUserType
  }
}
const resetUserTwoFactorAuthReceive = (json: ApiResetTwoFactorAuthSuccessType): ResetUserTwoFactorAuthReceiveType => {
  message.success(json.message)
  return {
    type: RECEIVE_RESET_USER_TWO_FACTOR_AUTH,
    payload: {
      user: json.user,
    },
  }
}

export interface ResetUserTwoFactorAuthFailureType {
  type: typeof FAILURE_RESET_USER_TWO_FACTOR_AUTH
}
const resetUserTwoFactorAuthFailure = (errorMessage: string): ResetUserTwoFactorAuthFailureType => {
  message.error(errorMessage)
  return {
    type: FAILURE_RESET_USER_TWO_FACTOR_AUTH,
  }
}

interface SetUserEnabledFlagRequestType {
  type: typeof REQUEST_SET_USER_ENABLED_FLAG
}
const setUserEnabledFlagRequest = (): SetUserEnabledFlagRequestType => {
  return {
    type: REQUEST_SET_USER_ENABLED_FLAG,
  }
}

export interface SetUserEnabledFlagReceiveType {
  type: typeof RECEIVE_SET_USER_ENABLED_FLAG
  payload: {
    user: ApiSetEnabledFlagUserType
  }
}
const setUserEnabledFlagReceive = (json: ApiSetEnabledFlagSuccessType): SetUserEnabledFlagReceiveType => {
  message.success(json.message)
  return {
    type: RECEIVE_SET_USER_ENABLED_FLAG,
    payload: {
      user: json.user,
    },
  }
}

export interface SetUserEnabledFlagFailureType {
  type: typeof FAILURE_SET_USER_ENABLED_FLAG
}
const setUserEnabledFlagFailure = (errorMessage: string): SetUserEnabledFlagFailureType => {
  message.error(errorMessage)
  return {
    type: FAILURE_SET_USER_ENABLED_FLAG,
  }
}

export interface FlipOnpremBillingPageType {
  type: typeof FLIP_ONPREM_BILLING_PORTAL
  payload: {
    billingPageInOnPremMode: boolean
  }
}

export const setOnPremBillingPage = (billingPageInOnPremMode: boolean): FlipOnpremBillingPageType => {
  return {
    type: FLIP_ONPREM_BILLING_PORTAL,
    payload: {
      billingPageInOnPremMode,
    },
  }
}

interface AddStripeTokenToAccountRequestType {
  type: typeof REQUEST_STRIPE_CUSTOMER_UPDATE
}
const addStripeTokenToAccountRequest = (): AddStripeTokenToAccountRequestType => {
  return {
    type: REQUEST_STRIPE_CUSTOMER_UPDATE,
  }
}

export interface AddStripeTokenToAccountReceiveType {
  type: typeof RECEIVE_STRIPE_CUSTOMER_UPDATE
}
const addStripeTokenToAccountReceive = (json: ApiAddStripeTokenToAccountType): AddStripeTokenToAccountReceiveType => {
  message.success(json.message)
  return {
    type: RECEIVE_STRIPE_CUSTOMER_UPDATE,
  }
}

export interface AddStripeTokenToAccountFailureType {
  type: typeof FAILURE_STRIPE_CUSTOMER_UPDATE
}
const addStripeTokenToAccountFailure = (errorMessage: string): AddStripeTokenToAccountFailureType => {
  message.error(errorMessage)
  return {
    type: FAILURE_STRIPE_CUSTOMER_UPDATE,
  }
}

interface UpdateSubscriptionPlanRequestType {
  type: typeof REQUEST_PLAN_CHANGE
}
const updateSubscriptionPlanRequest = (): UpdateSubscriptionPlanRequestType => {
  return {
    type: REQUEST_PLAN_CHANGE,
  }
}

export interface UpdateSubscriptionPlanReceiveType {
  type: typeof RECEIVE_PLAN_CHANGE
}
const updateSubscriptionPlanReceive = (
  json: ApiUpdateSubscriptionPlanType,
  newPlanId: string,
  currentPlanId: string | null,
): UpdateSubscriptionPlanReceiveType => {
  message.success(json.message)
  retoolAnalyticsTrack('Plan Switched', {
    newPlan: newPlanId,
    oldPlan: currentPlanId,
  })
  return {
    type: RECEIVE_PLAN_CHANGE,
  }
}

export interface UpdateSubscriptionPlanFailureType {
  type: typeof FAILURE_PLAN_CHANGE
}
const updateSubscriptionPlanFailure = (errorMessage: string): UpdateSubscriptionPlanFailureType => {
  message.error(errorMessage)
  return {
    type: FAILURE_PLAN_CHANGE,
  }
}

interface RevokeInvitationRequestType {
  type: typeof REQUEST_REVOKE_INVITE
}
const revokeInvitationRequest = (): RevokeInvitationRequestType => {
  return {
    type: REQUEST_REVOKE_INVITE,
  }
}

interface RevokeInvitationReceiveType {
  type: typeof RECEIVE_REVOKE_INVITE
  payload: {
    destroyedUserInviteId: number
  }
}
const revokeInvitationReceive = (json: ApiRevokeInvitationType): RevokeInvitationReceiveType => {
  message.success(json.message)
  return {
    type: RECEIVE_REVOKE_INVITE,
    payload: {
      destroyedUserInviteId: json.destroyedUserInviteId!,
    },
  }
}

interface RevokeInvitationFailureType {
  type: typeof FAILURE_REVOKE_INVITE
}
const revokeInvitationFailure = (errorMessage: string): RevokeInvitationFailureType => {
  message.error(errorMessage)
  return {
    type: FAILURE_REVOKE_INVITE,
  }
}

// ------------------------------------
// Async Actions
// ------------------------------------
export const resetUserTwoFactorAuth = (userId: number) => async (dispatch: RetoolDispatch) => {
  dispatch(resetUserTwoFactorAuthRequest())
  try {
    const result = await callInternalApi({
      url: `/api/organization/admin/users/${userId}/reset2FA`,
      method: 'POST',
    })
    dispatch(resetUserTwoFactorAuthReceive(result))
  } catch (error) {
    dispatch(resetUserTwoFactorAuthFailure(error.message))
  }
}

export const setUserEnabledFlag = (userId: number, enabled: boolean) => async (dispatch: RetoolDispatch) => {
  dispatch(setUserEnabledFlagRequest())
  try {
    const result = await callInternalApi({
      url: `/api/organization/admin/users/${userId}/setEnabledFlag`,
      method: 'POST',
      body: { enabled },
    })
    dispatch(setUserEnabledFlagReceive(result))

    const eventName = enabled ? 'User Enabled' : 'User Disabled'
    retoolAnalyticsTrack(eventName, {
      userId,
    })
  } catch (error) {
    dispatch(setUserEnabledFlagFailure(error.message))
  }
}

// legacy stripe billing
interface StripeTokenType {
  token: {
    // `no-explicit-any` disabled for now, but we should add definitive typings for `card`
    // in the future
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    card: any
    client_ip: string
    created: number
    email: string
    id: string
    livemode: boolean
    object: string
    type: string
    used: boolean
  }
}
export const addStripeTokenToAccount = (
  email: string,
  stripeToken: StripeTokenType,
  cardholderName: string,
  refreshOrg = false,
) => async (dispatch: RetoolDispatch) => {
  dispatch(addStripeTokenToAccountRequest())
  try {
    const result = await callInternalApi({
      url: '/api/organization/admin/updateStripeCustomerId',
      method: 'POST',
      body: { email, stripeToken, cardholderName },
    })
    if (refreshOrg) {
      // todo: this call to refresh the organization is not ideal - it should happen
      // on `addStripeTokenToAccountReceive` to avoid an additional round-trip
      dispatch(getOrganization())
    }
    dispatch(addStripeTokenToAccountReceive(result))
  } catch (error) {
    dispatch(addStripeTokenToAccountFailure(error.message))
  }
}

export const updateSubscriptionPlan = (newPlanId: string | null, currentPlanId: string | null) => async (
  dispatch: RetoolDispatch,
) => {
  dispatch(updateSubscriptionPlanRequest())
  try {
    const result = await callInternalApi({
      url: '/api/organization/admin/updateSubscriptionPlan',
      method: 'POST',
      body: { stripePlanId: newPlanId },
    })
    // todo: this call to refresh the profile is not ideal - it should happen
    // on `updateSubscriptionPlanReceive` to avoid an additional round-trip
    dispatch(getProfile())
    const newPlanIdTracking = newPlanId === null ? 'free' : newPlanId
    dispatch(updateSubscriptionPlanReceive(result, newPlanIdTracking, currentPlanId))
  } catch (error) {
    dispatch(updateSubscriptionPlanFailure(error.message))
  }
}

export const revokeInvitation = (userInviteId: number) => async (dispatch: RetoolDispatch) => {
  dispatch(revokeInvitationRequest())
  try {
    const result = await callInternalApi({
      url: `/api/organization/admin/revokeInvitation`,
      method: 'POST',
      body: { userInviteId },
    })
    dispatch(revokeInvitationReceive(result))
  } catch (error) {
    dispatch(revokeInvitationFailure(error.message))
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  permissionsIsFetching: false,
  billingPageInOnPremMode: false,
}

type SettingsActionTypes =
  | RevokeInvitationRequestType
  | RevokeInvitationReceiveType
  | RevokeInvitationFailureType
  | SetUserEnabledFlagRequestType
  | SetUserEnabledFlagReceiveType
  | SetUserEnabledFlagFailureType
  | ResetUserTwoFactorAuthRequestType
  | ResetUserTwoFactorAuthReceiveType
  | ResetUserTwoFactorAuthFailureType
  | FlipOnpremBillingPageType

interface SettingsReducerType {
  permissionsIsFetching: boolean
  billingPageInOnPremMode: boolean
}

const handleFlipOnPremBilling = (state: SettingsReducerType, action: FlipOnpremBillingPageType) => {
  return {
    ...state,
    billingPageInOnPremMode: action.payload.billingPageInOnPremMode,
  }
}

const settingsReducer = (
  state: SettingsReducerType = initialState,
  action: SettingsActionTypes,
): SettingsReducerType => {
  switch (action.type) {
    case FLIP_ONPREM_BILLING_PORTAL: {
      return handleFlipOnPremBilling(state, action)
    }
    case REQUEST_REVOKE_INVITE:
    case REQUEST_SET_USER_ENABLED_FLAG:
    case REQUEST_RESET_USER_TWO_FACTOR_AUTH: {
      return {
        ...state,
        permissionsIsFetching: true,
      }
    }
    case FAILURE_REVOKE_INVITE:
    case FAILURE_SET_USER_ENABLED_FLAG:
    case FAILURE_RESET_USER_TWO_FACTOR_AUTH:
    case RECEIVE_REVOKE_INVITE:
    case RECEIVE_SET_USER_ENABLED_FLAG:
    case RECEIVE_RESET_USER_TWO_FACTOR_AUTH: {
      return {
        ...state,
        permissionsIsFetching: false,
      }
    }
    default: {
      return state
    }
  }
}

export default settingsReducer

// ------------------------------------
// Constants
// ------------------------------------
export const SHOW_OMNIBOX = 'SHOW_OMNIBOX'
export const HIDE_OMNIBOX = 'HIDE_OMNIBOX'

// ------------------------------------
// Actions
// ------------------------------------
export function showOmnibox() {
  return { type: SHOW_OMNIBOX }
}

export function hideOmnibox() {
  return { type: HIDE_OMNIBOX }
}

function handleShowOmnibox(state: any) {
  return { ...state, visible: true }
}

function handleHideOmnibox(state: any) {
  return { ...state, visible: false }
}

const ACTION_HANDLERS: any = {
  [SHOW_OMNIBOX]: handleShowOmnibox,
  [HIDE_OMNIBOX]: handleHideOmnibox,
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  visible: false,
}
export default function omniboxReducer(state = initialState, action: any) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

import React from 'react'

import { InputField } from '../common'
import { ResourceFormProps } from '../types'
import { TwilioResourceSettings } from 'common/records'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'

const TwilioForm = (props: ResourceFormProps) => (
  <div className="grid-1c mb12">
    <InputField label="Account SID" resourceKey="basic_username" {...props} />
    <InputField label="Auth token" resourceKey="basic_password" {...props} />
  </div>
)

const defaultOptions: TwilioResourceSettings = {
  options: {
    customQueryParameters: [['', '']],
    customHeaders: [['', '']],
    authentication: 'basic',
    basic_username: '',
    basic_password: '',
    spec: `${RETOOL_SPECS_DOMAIN}/twilio/twilio_api_v2010/3.0.1/1.9.0.json`,
  },
}

export default {
  label: 'Twilio',
  form: TwilioForm,
  defaults: defaultOptions,
  validator: ({ options }: any) => options.basic_username,
}

import { capitalize, startCase } from 'lodash'
import { EventHandler, EventHandlerShape, EventHandlerType } from './types'
import { makeTypedMap } from 'common/utils/immutable'
import Immutable from 'immutable'

export type EventProp<T extends string> = `on${Capitalize<T>}`

export const makeEventHandler = (event: string, queryId: string): EventHandler =>
  makeTypedMap<EventHandlerShape>({
    event,
    type: 'datasource',
    method: 'trigger',
    pluginId: queryId,
    params: Immutable.Map(),
    waitType: 'debounce',
    waitMs: '0',
  })

const capitalizeFirst = <T extends string>(value: T): Capitalize<T> =>
  `${value.charAt(0).toUpperCase()}${value.slice(1)}` as Capitalize<T>

export const getEventProp = <T extends string>(event: T): EventProp<T> => `on${capitalizeFirst(event)}` as EventProp<T>

export const getDefaultQueryId = (widgetId: string, eventName: string): string =>
  `${widgetId}${capitalizeFirst(eventName)}Handler`

export const getEventLabel = (event: string): string => capitalize(startCase(event))

const PLUGIN_ID_LABELS = {
  datasource: 'Query',
  state: 'State',
  util: 'Util',
  widget: 'Component',
} as const

export const getPluginTypeLabel = (type: EventHandlerType): string => {
  return PLUGIN_ID_LABELS[type]
}

import React from 'react'
import { Radio as AntRadio } from 'antd'
import classNames from 'classnames'
import { RadioProps as AntRadioProps, RadioGroupProps as AntRadioGroupProps } from 'antd/lib/radio/interface'
import { RadioButtonProps as AntRadioButtonProps } from 'antd/lib/radio/radioButton'

import 'antd/lib/radio/style/index'
import './Radio.scss'

type RadioGroupProps = AntRadioGroupProps & {
  children?: React.ReactNode
}

const RadioGroup = (props: RadioGroupProps) => (
  <AntRadio.Group {...props} className={classNames('retool-radio-group', props.className)}>
    {props.children}
  </AntRadio.Group>
)

type RadioButtonProps = AntRadioButtonProps & {
  children?: React.ReactNode
}

const RadioButton = (props: RadioButtonProps) => (
  <AntRadio.Button {...props} className={classNames('retool-radio-button', props.className)}>
    {props.children}
  </AntRadio.Button>
)

export type RadioProps = AntRadioProps & {
  children?: React.ReactNode
}

const Radio = (props: RadioProps) => (
  <AntRadio className={classNames('retool-radio', props.className)} style={props.style} {...props}>
    {props.children}
  </AntRadio>
)

Radio.Group = RadioGroup
Radio.Button = RadioButton

export default Radio

import * as React from 'react'
import { COLORS, DEFAULT_HEIGHT } from './constants'

interface Props {
  // callback method when the input value has changed
  onChange: (value: string) => void
  // callback method when a key is pressed
  onKeyDown: (evt: any /* Event */) => void
  // callback method when the input element is blurred
  onBlur: (evt: any /* Event */) => void
  // callback method when the input element is focused
  onFocus: (evt: any /* Event */) => void
  // optional input placeholder text
  placeholder?: string
  // optional style override
  style?: React.CSSProperties
  // optional default value
  defaultValue?: string
  // optional autoFocus
  autoFocus?: boolean
}

interface State {
  value: string
}

const INPUT_STYLE: React.CSSProperties = {
  width: '100%',
  height: DEFAULT_HEIGHT,
  boxSizing: 'border-box',
  outline: 0,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: COLORS.DARKGRAY,
  paddingLeft: 15,
  paddingRight: 15,
}

export default class Input extends React.PureComponent<Props, State> {
  state: State = {
    value: '',
  }

  handleChange = (evt: any /* Event */) => {
    const value = evt.target.value
    this.setState({ value })
    this.props.onChange(value)
  }

  render() {
    const style = { ...INPUT_STYLE, ...this.props.style }

    return (
      <input
        type="text"
        value={this.state.value || this.props.defaultValue}
        placeholder={this.props.placeholder}
        style={style}
        onChange={this.handleChange}
        onKeyDown={this.props.onKeyDown}
        onBlur={this.props.onBlur}
        onFocus={this.props.onFocus}
        autoFocus={this.props.autoFocus}
      />
    )
  }
}

import { RetoolDispatch } from 'store'
import { modelUpdate, triggerModelUpdate } from 'store/appModel/model'
import { SafeAny } from 'common/types'

/** triggerUpdateModel vs updateModel
 *
 * See "triggerModelUpdate vs modelUpdate" in src/store/appModel/model.ts on the difference and when to use one or the other
 */

export function triggerUpdateModel(id: string, delta: SafeAny) {
  return (dispatch: RetoolDispatch) => {
    return dispatch(
      triggerModelUpdate(
        Object.keys(delta).map((key) => {
          return {
            selector: [id, key],
            newValue: delta[key],
          }
        }),
      ),
    )
  }
}

export function updateModel(stackId: number, id: string, delta: SafeAny) {
  return (dispatch: RetoolDispatch) => {
    return dispatch(
      modelUpdate(
        Object.keys(delta).map((key) => {
          return {
            selector: [id, key],
            newValue: delta[key],
          }
        }),
        stackId,
      ),
    )
  }
}

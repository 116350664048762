import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { Button } from 'antd'

interface Props {
  boundaryName: string
  children: JSX.Element
}
interface State {
  error: any
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ error })

    // Sentry assumes DOMExceptions won't have stacktraces and doesn't report them, which isn't true.
    // if we have an error with a stacktrace, explicitly turn it to Error to ensure we keep the stacktrace
    // for reference: https://github.com/getsentry/sentry-javascript/blob/89bca28994a0eaab9bc784841872b12a1f4a875c/packages/browser/src/backend.ts#L80-L90
    if (error.stack) {
      const originalError = error
      error = new Error(originalError)
      error.stack = originalError.stack
    }
    Sentry.withScope((scope) => {
      scope.setExtras({ info, boundaryName: this.props.boundaryName })
      Sentry.captureException(error)
      // eslint-disable-next-line no-console
      console.error(error)
    })
  }

  render() {
    const { error } = this.state
    if (error) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            padding: 10,
          }}
        >
          <h3>Something went wrong.</h3>
          <h6>Our team has been notified. We're working to fix this ASAP.</h6>
          <pre style={{ fontSize: 14, whiteSpace: 'pre-wrap' }}>{error.stack}</pre>
          <br />
          <Button type="primary" size="large" onClick={() => window.location.reload()}>
            Try refreshing the page
          </Button>
        </div>
      )
    }
    return this.props.children
  }
}

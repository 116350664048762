import React, { useState } from 'react'

import { Button, Icon } from 'components/design-system'
import CreateAppModal from '../modals/CreateAppModal'
import './TryModules.scss'

type Feature = {
  header: string
  description: string
}
const MODULES_FEATURES: Feature[] = [
  {
    header: 'Reuse design and code',
    description: 'Create modules to reuse groups of components and queries.',
  },
  {
    header: 'Better organization',
    description: 'Organize components, queries and code in logical blocks.',
  },
  {
    header: 'Share with your team',
    description: 'Drag and drop from team libraries.',
  },
]

type TryModulesProps = {
  style?: React.CSSProperties
}

export default function TryModules({ style }: TryModulesProps) {
  const [createModuleModalOpen, setCreateModuleModalOpen] = useState(false)

  return (
    <div className="try-modules" style={style}>
      <CreateAppModal
        title="Create a module"
        visible={createModuleModalOpen}
        closeModal={() => setCreateModuleModalOpen(false)}
        isGlobalWidget
      />
      <div className="try-modules__info">
        <span className="br2 fw-600 new bg-washed-blue light-blue pv2 ph4 fs-11">New</span>
        <p className="fw-600 fs-17 mt4 mb8">Create your team library with Modules</p>
        <div className="try-modules__features fs-12">
          {MODULES_FEATURES.map((feature, i) => (
            <div className="flex items-center mb4" key={i}>
              <span>
                <Icon className="light-blue mr4" type="check" /> <span className="fw-600">{feature.header}:</span>{' '}
                <span className="gray">{feature.description}</span>
              </span>
            </div>
          ))}
        </div>
        <div className="flex items-center mt12">
          <Button type="primary" onClick={() => setCreateModuleModalOpen(true)}>
            Create a module <Icon className="ml4" type="arrow-right" />
          </Button>
          <Button className="ml20" type="link" href="https://docs.retool.com/docs/modules">
            Explore docs
          </Button>
        </div>
      </div>
      <div className="try-modules__hero">
        <img src={require('./try-modules.svg')} />
        <div className="try-modules__hero-background" />
      </div>
    </div>
  )
}

import { Skeleton } from 'antd'
import asyncComponent from 'components/AsyncComponent/async'
import React from 'react'
import { CommonCodeEditorProps } from './types'

const AsyncEditor = (asyncComponent({
  resolve: () => import(/* webpackChunkName: "CodeEditor" */ './CodeEditorContainer'),
  LoadingComponent: (props: any) => {
    if (props.oneLine) {
      return (
        <div className="loading-code-editor">
          <Skeleton title={false} paragraph={{ rows: 1 }} active />
        </div>
      )
    }
    return (
      <div className="loading-code-editor">
        <Skeleton title={false} active />
      </div>
    )
  },
  ErrorComponent: ({ error }: any) => <div>{error.message}</div>,
}) as unknown) as React.ComponentType<CommonCodeEditorProps>

const AsyncJSONViewer = (asyncComponent({
  resolve: () => import(/* webpackChunkName: "CodeEditor" */ './JSONViewer'),
  LoadingComponent: (props: any) => {
    if (props.oneLine) {
      return (
        <div className="loading-code-editor">
          <Skeleton title={false} paragraph={{ rows: 1 }} active />
        </div>
      )
    }
    return (
      <div className="loading-code-editor">
        <Skeleton title={false} active />
      </div>
    )
  },
  ErrorComponent: ({ error }: any) => <div>{error.message}</div>,
}) as unknown) as React.ComponentType<CommonCodeEditorProps>

export default AsyncEditor
export { AsyncJSONViewer as JSONViewer }

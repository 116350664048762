import { AppTemplate, CallStack, IDependencyGraph, PluginNamespaceInfo, ResourceFromServer } from 'common/records'
import { AsyncLoader, UnknownObject } from 'common/types'
import { Selector } from 'common/utils'
import { ReadOnlyTypedMap } from 'common/utils/immutable'
import * as Immutable from 'immutable'
import * as React from 'react'
import { RetoolThunkSync } from 'store'
import { QueryTriggerOptions } from 'store/appModel/modelTypes'
import { EditorConfig, WidgetEditorsArrayOrObject } from './widgets/common/types'
import { WidgetDirectorySection } from './widgets/common/widgetDirectoryConstants'
import { RetoolGridProps } from './widgets/retoolGridTypes'

export type EditorsOption<T = unknown> =
  | WidgetEditorsArrayOrObject<T>
  | (() => Promise<WidgetEditorsArrayOrObject<T> | { default: WidgetEditorsArrayOrObject<T> }>)

// TODO(Garrett): add types for each plugin type (widgets, queries, etc)
export interface PluginInfo<ModelShape = any> {
  plugin?: any
  loader?: AsyncLoader<any>
  template?: (args?: any) => Immutable.Map<string, any>
  onTemplateUpdate?: (
    id: string,
    userTriggered: boolean | undefined,
    instance: number | undefined,
    pageLoad: boolean | undefined,
    options: QueryTriggerOptions,
  ) => RetoolThunkSync
  editors?: EditorsOption<any>
  options?: WidgetOptions<ModelShape>
  migrations?: any[]
  mask?: string[]
  /**
   * a plugin can specify template template aliases for some of its fields.
   *
   * For example, say we have an imported query, where the query source is:
   * `select * from foo where id = {{ queryPlaygroundParam }}`
   *
   * and the user fills out the inputs as:
   * queryPlayGroundParameter: {{ texinput1.value }}
   *
   * `queryPlaygroundParam` doesn't exist in the retool app's scope, but `textinput1.value`
   * does. the imported query can provide a mapping like this:
   *
   * {
   *   'queryPlaygroundParam': 'textinput1.value'
   * }
   *
   * and the retool app will correctly setup the dependencies + evaluate it using the app value
   */
  templateCodeCustomAliases?: (template: any, selector: Selector) => { [key: string]: string }
  /**
   * Plugins can specify template overrides that are determined by resource configurations. The default
   * template values might not always be correct, and might need to be derived from the resource configuration.
   * This function returns overrides for values of the templates, computed from the resource. These overrides
   * are merged in, in `TemplateResolver`, so that when the template is initialized, it has the correct values.
   *
   * ex: For unified sql resources, queryEditorMode.allowSql = false means we init the template with the
   * editorMode = 'gui' option instead of defaulting to editorMode = 'sql'. To fix this, we can pass
   * a resource to this function, implemented in the resource model file (ex: see SqlQueryUnified/model.ts)
   */
  resourceSpecificTemplateDefaults?: (resource: ResourceFromServer) => { [key: string]: string }
}

// Internal repository of plugins.
export const PluginRepository: { [s: string]: PluginInfo } = {}

// Internal repository of example plugins. These plugins aren't connected to the redux store.
export const ExamplePlugins: { [s: string]: PluginInfo } = {}

export const registeredWidgetTypes = new Set<string>()

export interface PluginDoc<ModelShape = UnknownObject> {
  /** Human-readable name of the widget */
  name: string
  /** Description of the component (supports markdown or JSX) */
  description?: string
  /** Documentation for each model property */
  properties: { [K in keyof ModelShape]?: PropertyDoc }
  /** Component width in pixels */
  width?: number
  /** Component height in pixels */
  height?: number
  /** Which section to list the component in */
  section: 'Commonly used' | 'Inputs' | 'Data' | 'Display' | 'Layout' | 'Integrations' | 'Custom' | 'Deprecated'
  /** Default model values to use in the docs (does not support `{{ ... }}`) */
  values?: { [K in keyof ModelShape]?: ModelShape[K] }
  /** Hide the component on the docs page. Note: `getExperimentValue` is not supported. */
  hideDocsPage?: boolean
  /** Custom anchor ID for the component */
  anchorId?: string
}

export interface PropertyDoc {
  name?: string
  label?: string
  docs: string | React.Component<any, any> | JSX.Element
}

export type PropertyAnnotations = { [k: string]: PropertyAnnotation }

export type PropertyAnnotation = {
  type?: string | ((key: number | string) => PropertyAnnotation)
  /** updatesSync
   * An optional list of properties (or a function that returns a list of properties) that change in the same
   * evaluation loop as this property. This has the effect of adding a dependency link between this property and the list
   * of properties here.
   *
   * For example: table1.selectedIndex will modify table.selectedRow.data in the same evaluation loop.
   */
  updatesSync?: string[] | ((template: any) => string[])

  /** updatesAsync
   * An optional list of properties that _will_ be changed in the _future_ if this property changes. This
   * has the effect of adding a dependency link between this property and the list of properties.
   *
   * During an evaluation loop, if this property is updated, then all properties in this list are marked with a
   * "dirty" flag which gets cleared once the value is manually updated. Dirty properties are skipped in the
   * evaluation process.
   *
   * For example, query1.query will update the query1.data property asynchronously.
   */
  updatesAsync?: string[]

  /** getValue
   * An optional property that is called right after the template string of the property is interpolated. This function
   * should transform the interpolated value into the actual value that is stored on the app model. The function is also
   * passed the (currently evauated) model, the interpolated (rendered) value, the selector of the property, and a CallStack
   * that can be introspected for what triggered re-evaluation.
   *
   * This allows writing transformations based off of the CallStack and the currently computed model.
   */
  getValue?: (model: any, renderedValue: any, selector: Selector, stack: CallStack) => any

  /** getTemplateStringOverride
   * An optional property that is called _after_ the initial dependency graph is constructed to return a new template string
   * for the property. This function should return a string that will replace the existing templateString for the property. After
   * which the dependency graph recomputes the dependency graph with the new template string in mind.
   *
   * This allows the template string to be dynamically generated based off the current template as well dependency graph.
   * If getTemplateStringOverride returns undefined, no template string override will take place
   */
  getTemplateStringOverride?: (
    appTemplate: AppTemplate,
    dependencyGraph: IDependencyGraph,
    widgetId: string,
    namespace?: PluginNamespaceInfo,
  ) => string | undefined

  /** unescapeRetoolExpressions
   * Whether or not uenscape double quotes in {{ }} expressions before evaluating the entire expression.
   *
   * For example: in the RESTAPI query, the body is represented as a string that looks like this:
   * '[ { "key": "textinput1", "value": "{{ textinput1.value }}" }, { "key": "textinput1", "value": "{{ \"foo\" + textinput2.value + \"bar\"  }}" ]'
   *
   * The `computeTemplateStringDependency` function applies a regex to extract every `{{.*}}` expression out of the string, which gives us:
   *  1. `{{ textinput1.value }}`
   *  2. `{{ \"foo\" + textinput2.value + \"bar\"  }}`
   *
   *  Unfortunately, the 2nd expression is not a valid JS expression which auses an error during the evaluation phase.
   *
   *  Turning this option, will ask the runtime to unescape the double quotes before actually evaulating the properties.
   */
  unescapeRetoolExpressions?: boolean

  /**
   * By default, when a key of an immutable collection is deleted in the template it remains in the model.
   * This annotation will keep all keys in sync by clearing the existing model value on all template updates.
   */
  resetValueOnTemplateUpdate?: boolean
}
export type WidgetPreset<Template = {}> = {
  /** Display a badge */
  badge?: 'Beta' | 'New'
  /** Number of rows for newly created widgets */
  defaultHeight: number
  /** Number of columns for newly created widgets */
  defaultWidth: number
  /** Short description of the widget */
  description?: string
  /** Conditionally hide the widget in the directory */
  hidden?: () => boolean
  /** Icon to display in the directory, usually an SVG */
  icon: string
  /**
   * ID for newly created widgets, which will have a number appended to it.
   * Defaults to the lowercase type without "Widget" (e.g., "TextWidget" -> "text").
   */
  idPrefix?: string
  /** Human-readable name of the widget */
  name: string
  /** Which directory section the widget belongs to */
  section: WidgetDirectorySection
  /** Relevant tags for search to match against */
  tags: string[]
  /** Default template values */
  template?: DefaultWidgetTemplate<Template>
}

export type UnknownModel = Immutable.Map<string, unknown>

export type PluginAPIMethodMetadata<ModelShape = UnknownObject> = {
  /** label provided to documentation, and event handlers */
  label: string
  description?: string
  example?: string
  /** editor config for event handlers */
  params?: EditorConfig<ModelShape>[]
}

export type PluginMethodConfig<ModelShape = UnknownObject> = {
  metadata: PluginAPIMethodMetadata<ModelShape>
  method: (...parameters: unknown[]) => unknown | Promise<unknown>
}

export type PluginMethodConfigs<ModelShape = UnknownObject> = {
  [methodName: string]: PluginMethodConfig<ModelShape>
}

type APIFactoryArgs<ModelShape = UnknownObject> = {
  /** pluginId */
  id: string
  /** Ref object to the widget instance */
  ref: UnknownObject | null
  /** Getter for the plugin's current model */
  getModel: () => ReadOnlyTypedMap<ModelShape> | undefined
  /** Update the plugin's model */
  updateModel: (data: Partial<ModelShape>) => void
}

export type PluginAPI<ModelShape = UnknownObject> = (
  factoryArgs: APIFactoryArgs<ModelShape>,
) => PluginMethodConfigs<ModelShape>

export type PluginMethods = {
  [method: string]: (...args: unknown[]) => Promise<unknown> | unknown
}

export type DefaultWidgetTemplate<Template = {}> =
  | Partial<Template>
  | ((queryId?: string, queryData?: unknown) => Partial<Template>)

export type WidgetLabelType = 'default' | 'inline' | 'slider'

export type WidgetOptions<ModelShape = UnknownModel> = {
  // Set this to true if the widget is a container. This provides an instance of the widget
  // an extra prop called `rgProps` that can be used to create child RetoolGrid for nesting components
  requiresRgProps?: boolean
  block?: boolean
  docsLoader?: AsyncLoader<PluginDoc<ModelShape>>
  minHeight?: number
  maxHeight?: number
  minWidth?: number
  propertyAnnotations?: PropertyAnnotations
  // TODO(Garrett): Remove in favor of presetsLoader
  directory?: WidgetPreset<ModelShape>
  api?: PluginAPI<ModelShape>
  container?: {
    /**
     * The difference between the containers height and the content height.
     *
     * e.g., content height + childGridPadding = container height
     */
    childGridPadding: number | ((model: ReadOnlyTypedMap<ModelShape>) => number)
    /** The currently rendered subcontainer */
    currentSubcontainer?: (model: ReadOnlyTypedMap<ModelShape>) => string
    /**
     * The currently rendered tabNum
     * @deprecated containers should use subcontainer
     */
    currentTabNum?: (model: ReadOnlyTypedMap<ModelShape>) => number | string
    /** By default, there's a small delay when containers are pushed down by a drag operation */
    disableDragDelay?: boolean
    /** Children will be displayed in a modal rather than on the canvas */
    modalLike?: boolean
  }
  /** Flag to set whether or not this plugin is allowed to be inside a ListView */
  blockListViewPlacement?: boolean
  /** Values to override the template defaults on widget creation */
  // TODO(Garrett): Remove in favor of presetsLoader
  defaultTemplate?: DefaultWidgetTemplate<ModelShape> // TODO: this should use Template, not Model
  /** Loader for the widget presets and directory entries */
  presetsLoader?: AsyncLoader<WidgetPreset[]>
  /** A list of events the component supports */
  events?: string[]
  /** Whether to enforce dynamic layout for the widget. Defaults to false, but should almost always be true. */
  dynamicHeight?: boolean
  /** Automatic editors to be disabled when custom logic or placement is needed. */
  disableAutoEditors?: ('events' | 'label' | 'validation')[]
  /**
   * Enable the label pseudo-component.
   *
   * Use `default` for components like Text Input where the label can sit to
   * the left or top of the component.
   *
   * Use `inline` for components like Checkbox where the label sits inline
   * after the component.
   *
   * Use `slider` for sliders and range sliders, which renders like `default`
   * but provides some additional a11y behavior.
   */
  labelType?: WidgetLabelType
  /** Whether the component supports validation */
  validation?: boolean
}

// Registers a plugin with the internal plugin repository
export function registerPlugin(
  type: string,
  plugin: any,
  template: any,
  onTemplateUpdate: PluginInfo['onTemplateUpdate'],
  editors: PluginInfo['editors'],
  options: WidgetOptions<any>,
) {
  PluginRepository[type] = {
    plugin,
    template,
    onTemplateUpdate,
    editors,
    migrations: PluginRepository[type] ? PluginRepository[type].migrations || [] : [],
    options,
  }
}

// TODO(Garrett):
// - move the plugin repository to redux (at least for widgets)
// - move widget type -> subtype (how widgets are handled in the template/model plugins)
// - break this out from registerPlugin
export const registerWidget: typeof registerPlugin = (type, ...rest) => {
  registeredWidgetTypes.add(type)
  registerPlugin(type, ...rest)
}

// Registers a plugin with the internal plugin repository
export function registerExamplePlugin(
  type: string,
  plugin: any,
  template: any,
  onTemplateUpdate: PluginInfo['onTemplateUpdate'],
  editors: PluginInfo['editors'],
  options: WidgetOptions<any>,
) {
  ExamplePlugins[type] = {
    plugin,
    template,
    onTemplateUpdate,
    editors,
    migrations: PluginRepository[type] ? PluginRepository[type].migrations || [] : [],
    options,
  }
}

export const loadPlugin = async (type: string): Promise<void> => await PluginRepository[type]?.loader?.()

export const setMask = (type: string, mask: string[]) => {
  PluginRepository[type].mask = mask
}

export const setTemplateCodeCustomAliases = (
  type: string,
  templateCodeCustomAliases: PluginInfo['templateCodeCustomAliases'],
) => {
  PluginRepository[type].templateCodeCustomAliases = templateCodeCustomAliases
}

export const setResourceSpecificTemplateDefaults = (
  type: string,
  defaults: PluginInfo['resourceSpecificTemplateDefaults'],
) => {
  PluginRepository[type].resourceSpecificTemplateDefaults = defaults
}

interface Migration {
  fromVersion: string
  toVersion: string
  up: (plugin: any) => any
}

export const addMigration = (type: string, migration: Migration) => {
  if (!PluginRepository[type]) {
    PluginRepository[type] = { migrations: [] }
  }
  PluginRepository[type].migrations!.push(migration)
}

export const getMigrationsBySemver = () => {
  const migarationsBySemver: any = {}
  Object.keys(PluginRepository).forEach((pluginType) => {
    const pluginMigrations = PluginRepository[pluginType].migrations
    pluginMigrations!.forEach((migration) => {
      if (!migarationsBySemver[migration.toVersion]) {
        migarationsBySemver[migration.toVersion] = []
      }
      migarationsBySemver[migration.toVersion].push({
        type: 'plugin',
        pluginType,
        migration,
      })
    })
  })

  return migarationsBySemver
}

// Function that maps a plugin type to a widget
type WidgetProps = {
  id: string
  childWidgets?: any[]
  componentHeight: number
  componentWidth: number
  instance?: number
  fetching: boolean
  rgProps?: Omit<RetoolGridProps, 'renderWidgetNode'>
  namespace?: PluginNamespaceInfo
}
export const WidgetResolver = (type: string): React.ComponentType<WidgetProps> => {
  const result = PluginRepository[type]
  if (result) {
    return result.plugin
  }
  // eslint-disable-next-line no-console
  console.error(`Widget for the type ${type} not found.`)
  return null!
}

export const ExampleWidgetResolver = (type: string): React.ComponentType<WidgetProps> => {
  const result = ExamplePlugins[type]
  if (result) {
    return result.plugin
  }
  throw Error(`Example widget for the type ${type} not found.`)
}

export const onTemplateUpdateResolver = (type: string) => {
  const result = PluginRepository[type]
  if (result != null) {
    return result.onTemplateUpdate
  }
  return undefined
}

export const APIResolver = (type: string): PluginAPI | undefined => PluginRepository[type]?.options?.api

export const APIMetadataResolver = (type: string): { [method: string]: PluginAPIMethodMetadata } => {
  const api = APIResolver(type)
  // here we instantiate the plugin's API without the necessary args to access the method metadata.
  // this should ONLY be used in the context of reading metadata about individual methods, and not for using the API, as it will break.
  const methods = api?.({} as APIFactoryArgs) ?? {}
  return Object.fromEntries(Object.entries(methods).map(([method, { metadata }]) => [method, metadata]))
}

export const APIDocsResolver = (type: string): { [method: string]: PropertyDoc } => {
  const metadata = APIMetadataResolver(type)
  return Object.fromEntries(
    Object.entries(metadata).map(([method, { description = '', example = '' }]) => [
      method,
      { label: description, docs: example },
    ]),
  )
}

export const WidgetEditorResolver = (
  type: string,
): WidgetEditorsArrayOrObject | Promise<WidgetEditorsArrayOrObject> => {
  const result = PluginRepository[type]
  if (result) {
    const { editors = [] } = result

    if (typeof editors === 'function') {
      return editors().then((editors) => {
        if ('default' in editors) {
          editors = editors.default
        }

        PluginRepository[type].editors = editors
        return editors
      })
    }

    return editors
  }
  throw Error(`Widget editors for the type ${type} was not found.`)
}

export const TemplateResolver = (type: string, resource?: ResourceFromServer) => {
  const result = PluginRepository[type]
  let templateGenerator: (args?: any) => Immutable.Map<string, any>
  if (result && result.template) {
    templateGenerator = result.template
  } else {
    // eslint-disable-next-line no-console
    templateGenerator = () => Immutable.Map<string, any>()
  }

  if (resource && result && result.resourceSpecificTemplateDefaults) {
    const template = templateGenerator()
    const templateDefaultGenerator = result.resourceSpecificTemplateDefaults
    templateGenerator = () => template.merge(templateDefaultGenerator(resource))
  }

  return templateGenerator
}

export const MigrationsResolver = (type: string) => {
  const result = PluginRepository[type]
  if (result) {
    return result.migrations ? result.migrations : {}
  }
  throw Error(`Migrations for the type ${type} was not found.`)
}

export const MaskResolver = (type: string) => (template: any) => {
  const plugin = PluginRepository[type]
  if (plugin && plugin.mask) {
    const keySet = Immutable.Set(plugin.mask)
    return template.filter((v: any, k: any) => {
      // if you add another `||` to the line below, please talk to @abdul @anthony
      return keySet.has(k) || k === 'hidden' || 'showFetchingIndicator'
    })
  } else {
    return template
  }
}

export const TemplateCodeCustomAliasesResolver = (type: string) => (template: any) => (selector: Selector) => {
  const plugin = PluginRepository[type]
  if (plugin && plugin.templateCodeCustomAliases) {
    return plugin.templateCodeCustomAliases(template, selector)
  }
  return {}
}

export const WidgetOptionsResolver = (type: string): WidgetOptions<any> => {
  return PluginRepository[type]?.options ?? {}
}

export const PluginPropertyAnnotationsResolver = (type: string): PropertyAnnotations => {
  return WidgetOptionsResolver(type).propertyAnnotations ?? {}
}

export const REDESIGN_COLOR_MIGRATION_MAP: { [color: string]: string } = {
  '#1ea9fb': '#3c92dc',
  '#F47373': '#d6757f',
  '#eb144c': '#cc5248',
  '#37D67A': '#478b60',
  '#2CCCE4': '#5b9dab',
  '#555555': '#555555',
  '#dce775': '#e9ab11',
  '#ff8a65': '#d87b1f',
  '#ba68c8': '#9C76C1',
}
export const Feb2020ColorRedesignMigration = (plugin: any, pluginColorPath: string[]) => {
  // default to old retool azure
  const currentColor = plugin.getIn(pluginColorPath) || '#1ea9fb'

  // default to existing color if not in map
  const newColor = REDESIGN_COLOR_MIGRATION_MAP[currentColor] || currentColor

  return plugin.setIn(pluginColorPath, newColor)
}

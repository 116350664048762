import React from 'react'
import { CircleCIResourceSettings } from 'common/records'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'
import { generateResourceForm } from 'components/ResourceForm/resourceFormGenerator/generator'
import { AuthTemplate } from 'components/ResourceForm/resourceFormGenerator/generator-types'

const defaultOptions: CircleCIResourceSettings = {
  options: {
    customQueryParameters: [['', '']],
    customHeaders: [['Circle-Token', 'API_KEY']],
    authentication: 'apiKey',
    api_key: '',
    spec: `${RETOOL_SPECS_DOMAIN}/circleci/3.0.0/v2.json`,
  },
}

const auth: AuthTemplate = [
  {
    type: 'apiKey',
    options: {
      label: 'Personal API Token',
      description: (
        <>
          Enter your secret CircleCI personal API token above. To retrieve or create a token visit the{' '}
          <a rel="noopener noreferrer" target="_blank" href="https://app.circleci.com/settings/user/tokens">
            Personal API Tokens
          </a>{' '}
          page of your CircleCI account or read the documentation{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://circleci.com/docs/2.0/api-developers-guide/#authentication-and-authorization"
          >
            here
          </a>
          .
        </>
      ),
    },
  },
]

export default generateResourceForm('CircleCI', defaultOptions, { auth, hideTestConnection: () => true })

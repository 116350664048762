import { createSelector } from 'reselect'
import _ from 'lodash'
import {
  editorPrivilegedSelector,
  isOnPremSelector,
  pagesSelector,
  pagesWithPathsSelector,
  RecentVisit,
  userSelector,
  WorkspaceGroupPageArray,
} from 'store/selectors'
import { Group } from '__globalShared__/permissions'
import { SafeAny } from 'common/types'
import { Page } from 'common/records'

export const firstNameSelector = createSelector(userSelector, (user) => user.getIn(['user', 'firstName']))
export const lastNameSelector = createSelector(userSelector, (user) => user.getIn(['user', 'lastName']))

export const userDisplayNameSelector = createSelector(firstNameSelector, lastNameSelector, (first, last) => {
  return _.compact([first, last]).join(' ')
})

export const pageFavoritesSelector = createSelector(userSelector, (user) => {
  const faves = user.getIn(['user', 'pageFavorites'])
  return (faves ? faves.toJS() : []) as number[]
})

export const folderFavoritesSelector = createSelector(userSelector, (user) => {
  const faves = user.getIn(['user', 'folderFavorites'])
  return (faves ? faves.toJS() : []) as number[]
})

export const tutorialCTADismissedSelector = createSelector(
  userSelector,
  (user) => user.getIn(['user', 'tutorialCTADismissed']) as boolean,
)

export const recentVisitsSelector = createSelector(userSelector, (user) => {
  const visits = user.getIn(['user', 'recentVisits'])
  return (visits ? visits.toJS() : []) as RecentVisit[]
})

// The groups the user belongs to with the associated workspace if it exists
export const groupsUserBelongsToWithWorkspacesSelector = createSelector(
  userSelector,
  (user) => (user.getIn(['user', 'groups']) ? user.getIn(['user', 'groups']).toJS() : []) as Group[],
)

export const userEmailSelector = createSelector(userSelector, (user): string => {
  return user?.getIn(['user', 'email']) || ''
})

export const userHasGoogleIdSelector = createSelector(userSelector, (user) => {
  return user?.getIn(['user', 'hasGoogleId']) || false
})

export const connectedEditorsSelector = createSelector(pagesSelector, userEmailSelector, (pages, currentUserEmail) => {
  return pages.get('connectedEditors').filter((user: SafeAny) => currentUserEmail !== user.email)
})

const _pagesByIdSelector = createSelector(pagesWithPathsSelector, (pages) => {
  return pages.reduce((acc: { [key: string]: Page }, page: Page) => {
    return { ...acc, [page.id]: page }
  }, {})
})

// pagesByIdSelector returns only pages that the user is authorized to access
export const authorizedWorkspacesSelector = createSelector(
  groupsUserBelongsToWithWorkspacesSelector,
  _pagesByIdSelector,
  (groups, pagesById: { [key: string]: Page }) => {
    return groups.reduce((acc: WorkspaceGroupPageArray, group: Group) => {
      if (!group.workspace || !group.workspace.homePageId || !pagesById[group.workspace.homePageId]) {
        return acc
      }
      return [...acc, { group, workspace: group.workspace, page: pagesById[group.workspace.homePageId] }]
    }, [])
  },
)

export const tutorialCTAShownSelector = createSelector(
  editorPrivilegedSelector,
  tutorialCTADismissedSelector,
  isOnPremSelector,
  (isPrivileged, dismissed, isOnPrem) => {
    return isPrivileged && !dismissed && !isOnPrem
  },
)

export const isTutorialCTAShouldAppearInMenuSelector = createSelector(
  editorPrivilegedSelector,
  tutorialCTADismissedSelector,
  isOnPremSelector,
  (isPrivileged, dismissed, isOnPrem) => {
    return isPrivileged && dismissed && !isOnPrem
  },
)

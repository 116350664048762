import React, { useEffect, useState } from 'react'
import { browserHistory } from 'react-router'
import { Alert, Spin } from 'antd'

import SignupThemePage from '../pages/SignupThemePage'
import DefaultSignupPage from '../pages/DefaultSignupPage'

import GoogleLoginButton from './../components/GoogleLoginButton'
import SSOLoginButton from '../components/SSOLoginButton'
import Disclaimer from '../components/Disclaimer'
import SignupForm from './../forms/SignupForm'

import GoogleLogo from 'assets/google.svg'
import { isSupportedSignUpTheme } from 'common/records'
import { setCookies } from 'routes/Login/logic/cookieUtils'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'

interface SignupContainerProps {
  clientId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  claimInvitation: any // <-- improve type after refactoring `login.js`
  error: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  invite: any // <-- either 'null' or an immutable map. will add stronger type after removing immutable
  isFetching: boolean
  restrictedDomain: string
  disableUserPassLogin: boolean
  ssoType: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signup: any // <-- improve type after refactoring `login.js`
  signupEmail?: string
  signupToken?: string
  signupTheme?: string
}

const SignupContainer = (props: SignupContainerProps) => {
  const [emailFormVisible, setEmailFormVisible] = useState(true)

  const {
    claimInvitation,
    clientId,
    disableUserPassLogin,
    error,
    invite,
    isFetching,
    restrictedDomain,
    ssoType,
    signup,
    signupToken,
    signupEmail,
    signupTheme,
  } = props

  useEffect(() => {
    // the template clone flow relies on the templateId cookie, which is normally set by
    // the marketing site. but now the marketing site is on retool.com, while the retool app is on
    // tryretool.com. so we manually set the templateId cookie by calling setCookies.
    // the flow that clones might send u to either path (login or signup)
    setCookies()
  }, [])

  const emphasizeGoogleLogin = ssoType === 'google' && !(restrictedDomain || disableUserPassLogin)
  const showGoogleAuthButton = clientId && ssoType === 'google' // set true for dev
  const showOktaAuthButton = ssoType === 'okta' || ssoType === 'saml'
  const allowEmailSignup = !(restrictedDomain || disableUserPassLogin)
  const showSignupForm = !(restrictedDomain || disableUserPassLogin) && emailFormVisible
  const showThirdPartySignUpButton = showGoogleAuthButton || showOktaAuthButton

  const invitedEmail = ''

  const thirdPartySignUpButton = showGoogleAuthButton ? (
    <GoogleLoginButton
      clientId={clientId}
      style={emphasizeGoogleLogin && !emailFormVisible ? { margin: 'auto' } : {}}
      uxMode="redirect"
      redirectUri={`${window.location.origin}/oauthcallback`}
      responseType="code token id_token"
      scope="openid profile email"
      onClick={(): void => {
        retoolAnalyticsTrack('Signup Button Clicked', { method: 'google', theme: signupTheme })
        return null!
      }}
      fetchBasicProfile
    >
      <img src={GoogleLogo} className="logo" />
      Sign up with Google
    </GoogleLoginButton>
  ) : showOktaAuthButton ? (
    <div>
      <SSOLoginButton ssoType={ssoType} />{' '}
    </div>
  ) : (
    <div />
  )

  const signupForm = (
    <>
      <div className="auth-form-inner">
        <Spin spinning={isFetching}>
          <div
            style={
              emphasizeGoogleLogin && !emailFormVisible
                ? { position: 'absolute', top: 100, width: '100%', padding: '0 20px' }
                : {}
            }
          >
            {!emailFormVisible && allowEmailSignup && (
              <div
                onClick={() => setEmailFormVisible(true)}
                style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  fontSize: 16,
                  textDecoration: 'underline',
                }}
              >
                Sign up with email
              </div>
            )}
            {showSignupForm && (
              <SignupForm
                signupTheme={signupTheme}
                invitedEmail={invitedEmail}
                signup={signup}
                claimInvitation={claimInvitation}
                signupToken={signupToken}
                signupEmail={signupEmail}
              />
            )}
            {showThirdPartySignUpButton && <div className="auth-button-row">{thirdPartySignUpButton}</div>}
            {error && (
              <Alert message={error} type="error" style={{ margin: 'auto', marginBottom: 15, marginTop: 20 }} />
            )}
          </div>
        </Spin>
      </div>
    </>
  )

  const signupFooter =
    signupTheme && isSupportedSignUpTheme(signupTheme) ? (
      <>
        <Disclaimer />
        <div className="auth-footer">
          Already have an account? Sign in&nbsp;
          <a target="_blank" rel="noopener" onClick={() => browserHistory.push('/auth/login')}>
            here
          </a>
          .
        </div>
      </>
    ) : (
      <>
        <Disclaimer />
      </>
    )

  if (signupTheme && isSupportedSignUpTheme(signupTheme)) {
    return (
      <SignupThemePage
        invite={invite}
        emphasizeGoogleLogin={emphasizeGoogleLogin}
        showSignupForm={showSignupForm}
        signupFooter={signupFooter}
        signupForm={signupForm}
        signupTheme={signupTheme}
      />
    )
  } else {
    return (
      <DefaultSignupPage
        invite={invite}
        emphasizeGoogleLogin={emphasizeGoogleLogin}
        showSignupForm={showSignupForm}
        signupFooter={signupFooter}
        signupForm={signupForm}
        signupIntegration={signupTheme}
      />
    )
  }
}

export default SignupContainer

import Immutable from 'immutable'

export function getViewportData(): Immutable.Map<string, number> {
  // TODO(Garrett): remove the class selector once appShell launches
  const canvasSelector = '.root.retool-container, [data-retool-viewport-target]'
  const node = document.querySelector(canvasSelector)
  if (!node) {
    return Immutable.Map({
      width: 0,
      height: 0,
    })
  }
  return Immutable.Map({
    width: node.clientWidth,
    height: node.clientHeight,
  })
}

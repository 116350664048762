import React, { useState, useEffect } from 'react'
import { Alert } from 'antd'
import { capitalize } from 'lodash'
import { getResources } from 'store/user'
import { getParameterByName, removeURLParameters } from 'common/utils'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { onEditorOnboardingSelector, placeholderResourceNamesSelector } from 'store/selectors'
import { firstCreatedResourceTypeSelector } from 'store/onboarding/selectors'
import { connect } from 'react-redux'
import './PlaceholderResourcesWarning.scss'
import { RetoolState } from 'store'
import { isSupportedSignUpTheme, ResourceFromServer } from 'common/records'
import { ResourceType } from 'common/resourceTypes'
import { ONBOARDING_TUTORIAL_ENABLED_RESOURCE_TYPES } from 'retoolConstants'
import EditorTutorial from 'components/Onboarding/editorTutorial'
import EditPlaceholderResourceModal from './EditPlaceholderResourceModal'
import EditPlaceholderResourceConfirmationModal from './EditPlaceholderResourceConfirmationModal'

interface PlaceholderResourcesWarningProps {
  getResources: () => void
  MessageComponent?: React.ReactType
  showEditorOnboarding: boolean
  pageName: string
  placeholderResourceNames: string[]
  resources: ResourceFromServer[]
  onOnboarding: boolean
  firstCreatedResourceType: ResourceType
}

const PlaceholderResourcesWarning = ({
  getResources,
  MessageComponent = HeaderWarningMessage,
  showEditorOnboarding,
  onOnboarding,
  pageName,
  placeholderResourceNames,
  resources,
  firstCreatedResourceType,
}: PlaceholderResourcesWarningProps) => {
  const [editedResource, setEditedResource] = useState(false)
  const [resourceName, setResourceName] = useState('')

  // If returning from Oauth flow, show the setup modal by default
  const returnedFromOauth = getParameterByName('finishedOAuth') === 'true'
  const demoResourceConnectionRequired = getParameterByName('demoResourceConnectionRequired') === 'true'
  const onResourceTypeTutorial =
    onOnboarding && ONBOARDING_TUTORIAL_ENABLED_RESOURCE_TYPES.includes(firstCreatedResourceType)
  const [showModalOne, setShowModalOne] = useState(false)
  const [showModalTwo, setShowModalTwo] = useState(false)
  useEffect(() => {
    if (resources.length === 0) {
      getResources()
    }

    if (!showEditorOnboarding) {
      setShowModalOne(false)
    }

    if (placeholderResourceNames.length > 0) {
      setResourceName(placeholderResourceNames[0])
      setEditedResource(false)
    }

    // user has edited and connected their own resource
    if (placeholderResourceNames.length === 0) {
      setShowModalTwo(false)
      setEditedResource(true)
    }
  }, [placeholderResourceNames])

  let signupTheme = ''
  let signupThemeCapitalized = ''
  let resourceType = null

  const resource = resources.find((r: any) => r.name === resourceName)

  if (resource) {
    resourceType = resource.type
    if (isSupportedSignUpTheme(resourceType)) {
      signupTheme = resourceType
      signupThemeCapitalized = capitalize(signupTheme)
    }
  }

  let showDatabaseUrlField = true
  if (pageName !== 'firebase realtimedb admin panel') {
    showDatabaseUrlField = false
  }

  const showResourceModal = showModalOne || returnedFromOauth || demoResourceConnectionRequired

  return (
    <div className="flex items-center">
      {!editedResource && !onResourceTypeTutorial && (
        <MessageComponent
          onClick={() => {
            retoolAnalyticsTrack('Replace Resource Modal Opened', {})
            setShowModalOne(true)
          }}
        />
      )}
      {showResourceModal && !editedResource && (
        <EditPlaceholderResourceModal
          onClose={() => {
            setShowModalOne(false)
            // We use the finishedOAuth parameter to determine whether this modal should be shown
            // after an oauth flow. If a user closes the modal, we get rid of the parameter
            removeURLParameters('finishedOAuth', 'demoResourceConnectionRequired')
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onCompleteEditing={(newResource: ResourceFromServer) => {
            setEditedResource(true)
            setResourceName(newResource.name)
            setShowModalTwo(true)
          }}
          resourceName={resourceName}
          showDatabaseUrlField={showDatabaseUrlField}
          signupTheme={signupThemeCapitalized}
        />
      )}

      {showModalTwo && editedResource && (
        <EditPlaceholderResourceConfirmationModal
          signupTheme={signupThemeCapitalized}
          onClose={() => {
            setShowModalTwo(false)
          }}
        />
      )}
      {editedResource && !showModalTwo && !showModalOne && showEditorOnboarding && <EditorTutorial />}
    </div>
  )
}

const HeaderWarningMessage = ({ onClick }: any) => (
  <Alert
    type="warning"
    className="alert-slimline"
    showIcon
    message={
      <span>
        This app is using demo data.{' '}
        <a className="button-link" onClick={onClick}>
          Connect your own
        </a>
      </span>
    }
  />
)

const mapDispatchToProps = {
  getResources,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    onOnboarding: state.onboarding.showOnboarding,
    firstCreatedResourceType: firstCreatedResourceTypeSelector(state),
    showEditorOnboarding: onEditorOnboardingSelector(state),
    pageName: decodeURIComponent(state.pages.get('pageName')),
    placeholderResourceNames: placeholderResourceNamesSelector(state),
    resources: state.user.resources.toJS().resources,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaceholderResourcesWarning)

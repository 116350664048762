import React from 'react'
import classNames from 'classnames'
import { Select } from 'antd'
import './Dropdown.scss'

class Dropdown extends React.PureComponent<any, any> {
  select: any
  constructor(props: any) {
    super(props)
  }
  focus() {
    if (this.select && this.select.rcSelect) {
      this.select.rcSelect.setOpenState(true, true)
    }
  }

  render() {
    return (
      <Select
        {...this.props}
        className={classNames('retool-dropdown', this.props.className)}
        ref={(e) => (this.select = e)}
        dropdownClassName={classNames(
          `retool-dropdown-items${this.props.size === 'large' ? '-lg' : ''}`,
          this.props.dropdownClassName,
        )}
      >
        {this.props.children}
      </Select>
    )
  }
}

export default Dropdown
export const Option = Select.Option

import useBooleanState from './useBooleanState'
import useFetch, { Options, Query, Fetch } from './useFetch'

/**
 * Wrapper around useFetch to supply a loading state
 */
const useFetchWithLoading = <Args extends unknown[] = [], Value = void>(
  query: Query<Args, Value>,
  config: Options<Args, Value> = {},
): [Fetch<Args>, boolean] => {
  const { fetchOnMount, onFetchStart, onFetchEnd } = config

  const loading = useBooleanState(!!fetchOnMount)

  const fetch = useFetch(query, {
    ...config,
    onFetchStart: (...args) => {
      loading.setTrue()
      onFetchStart?.(...args)
    },
    onFetchEnd: () => {
      loading.setFalse()
      onFetchEnd?.()
    },
  })

  return [fetch, loading.value]
}

export default useFetchWithLoading

/* Copy and paste util functions to calculate the new position of where copied component should be pasted */

import Immutable from 'immutable'
import { appTemplateSelector, appModelValuesSelector } from 'store/selectors'
import { targetContainerKeySelector } from 'store/selectors/widgetSelectors'
import { RetoolState } from 'store'
import { PluginTemplate, Position2, PositionKey, EditorModel, AppTemplate } from 'common/records'
import { WidgetOptionsResolver, UnknownModel } from 'components/plugins'
import { widgetTypeIsModalLike } from './widgetTypeIsContainer'
import { GLOBAL_WIDGET_GRID } from '../../GlobalWidget/constants'
import getChildWidgetIds from './getChildWidgetIds'

type ParentContainerPositionDiffType = { container: string; subcontainer: string; tabNum: number | string }

/** Returns an immutable map of selected widgets and its children for copy and cut */
export const selectedWidgetsForClipboardSelector = (state: RetoolState) => {
  const editor: EditorModel = state.editor
  const selectedWidgets = editor.selectedWidgets
  const template: AppTemplate = appTemplateSelector(state)
  let widgets = Immutable.Map()

  selectedWidgets.forEach((selectedWidgetId) => {
    const childWidgetIds = getChildWidgetIds(selectedWidgetId, state).map((widgetId) => template.plugins.get(widgetId))
    widgets = widgets.set(selectedWidgetId, childWidgetIds)
  })

  return widgets
}

export function getPasteBounds(
  widgets: PluginTemplate[],
  positionKey: PositionKey,
): { left: number; right: number; top: number; bottom: number } {
  if (widgets.length === 0) {
    return { left: 0, right: 0, top: 0, bottom: 0 }
  }
  return widgets.reduce(
    ({ left, right, top, bottom }, { [positionKey]: position }) =>
      position
        ? {
            left: Math.min(left, position.col),
            right: Math.max(right, position.col + position.width),
            top: Math.min(top, position.row),
            bottom: Math.max(bottom, position.row + position.height),
          }
        : { left, right, top, bottom },
    { left: Infinity, right: 0, top: Infinity, bottom: 0 },
  )
}

export function getLeftMostColBottomMostRow(
  widgets: PluginTemplate[],
  positionKey: PositionKey,
): { row: number; col: number } {
  if (widgets.length === 0) {
    return { col: 0, row: 0 }
  }
  return widgets.reduce(
    ({ row, col }, { [positionKey]: position }) =>
      position
        ? {
            row: Math.max(row, position.row + position.height),
            col: Math.min(col, position.col),
          }
        : { row, col },
    { row: 0, col: Infinity },
  )
}

export function getNextAvailableRow(
  plugins: Immutable.OrderedMap<string, PluginTemplate>,
  containerKey: string,
  positionKey: PositionKey,
): number {
  return plugins.reduce((newRow, { [positionKey]: position }) => {
    if (position?.getContainerKey() === containerKey) {
      return Math.max(newRow, position.row + position.height)
    }
    return newRow
  }, 0)
}

// newRow: 1 below bottom most component in that frame & newCol: 0
export const getPositionFrameSelected = (
  widget: PluginTemplate,
  selectedFrameId = '$main',
  inModule: boolean,
  plugins: Immutable.OrderedMap<string, PluginTemplate>,
  positionKey: PositionKey,
  pasteBounds: { left: number; right: number; top: number; bottom: number },
) => {
  const selectedFrame = selectedFrameId.substring(1)
  const newSubcontainer = selectedFrame === 'main' ? '' : selectedFrame
  const newContainer = inModule ? GLOBAL_WIDGET_GRID : ''

  // add reasonable defaults to make TS happy
  const widgetCol = widget[positionKey]?.col ?? 0
  const widgetRow = widget[positionKey]?.row ?? 0

  const colDiff = widgetCol - pasteBounds.left
  const rowDiff = widgetRow - pasteBounds.top
  const newRow =
    getNextAvailableRow(
      plugins,
      Position2.getContainerKey({ container: newContainer, subcontainer: newSubcontainer }),
      positionKey,
    ) + rowDiff
  const newCol = colDiff

  return {
    container: newContainer,
    subcontainer: newSubcontainer,
    col: newCol,
    row: newRow,
    tabNum: 0,
  }
}

// newRow: 1 below bottom most component & newCol: same col as left most component
export const getPositionBelowSelection = (
  widget: PluginTemplate,
  selectedWidgets: Immutable.Set<string>,
  state: RetoolState,
  positionKey: PositionKey,
  pasteBounds: { left: number; right: number; top: number; bottom: number },
) => {
  const selectedWidgetsWithPositions = selectedWidgets.toArray().map((selectedWidgetId: string) => {
    return appTemplateSelector(state).getIn(['plugins', selectedWidgetId])
  })
  const selectedWidget = selectedWidgetsWithPositions[0]
  const selectedColRow = getLeftMostColBottomMostRow(selectedWidgetsWithPositions, positionKey)

  // add reasonable defaults to make TS happy
  const widgetCol = widget[positionKey]?.col ?? 0
  const widgetRow = widget[positionKey]?.row ?? 0

  // desired shift versus available space
  const colDiff = Math.min(selectedColRow.col - pasteBounds.left, 12 - pasteBounds.right)
  const rowDiff = widgetRow - pasteBounds.top
  const newCol = widgetCol + colDiff
  const newRow = selectedColRow.row + rowDiff

  return {
    container: selectedWidget[positionKey].container,
    subcontainer: selectedWidget[positionKey].subcontainer ?? widget[positionKey]?.subcontainer,
    col: newCol,
    row: newRow,
    tabNum: selectedWidget[positionKey].tabNum ?? widget[positionKey]?.tabNum,
  }
}

// newRow: 1 below bottom most component in selected container & newCol: 0
export const getPositionContainerSelected = (
  widget: PluginTemplate,
  selectedContainerId: string,
  inModule: boolean,
  plugins: Immutable.OrderedMap<string, PluginTemplate>,
  positionKey: PositionKey,
  state: RetoolState,
  pasteBounds: { left: number; right: number; top: number; bottom: number },
  multiWidgetsToPaste: boolean,
) => {
  const widgetType = plugins.getIn([selectedContainerId, 'subtype'])
  const model = appModelValuesSelector(state).getPlugin(selectedContainerId) as UnknownModel
  const isClosedModal = widgetTypeIsModalLike(widgetType) && !model.get('opened')

  if ((widget.id === selectedContainerId && !multiWidgetsToPaste) || isClosedModal) {
    // Do not nest; paste below instead
    return getPositionBelowSelection(widget, Immutable.Set([selectedContainerId]), state, positionKey, pasteBounds)
  }

  const { container } = WidgetOptionsResolver(widgetType)
  const parentContainerPositionDiff: ParentContainerPositionDiffType = {
    container: inModule ? GLOBAL_WIDGET_GRID : '',
    subcontainer: '',
    tabNum: 0,
  }

  if (container) {
    const { currentSubcontainer, currentTabNum } = container
    parentContainerPositionDiff.container = selectedContainerId
    parentContainerPositionDiff.subcontainer = currentSubcontainer?.(model) ?? parentContainerPositionDiff.subcontainer
    parentContainerPositionDiff.tabNum = currentTabNum?.(model) ?? parentContainerPositionDiff.tabNum
  }

  const containerKey = targetContainerKeySelector(state)

  // add reasonable defaults to make TS happy
  const widgetCol = widget[positionKey]?.col ?? 0
  const widgetRow = widget[positionKey]?.row ?? 0

  const colDiff = 0 - pasteBounds.left
  const rowDiff = widgetRow - pasteBounds.top
  const newCol = widgetCol + colDiff
  const newRow = getNextAvailableRow(plugins, containerKey, positionKey) + rowDiff

  return {
    ...parentContainerPositionDiff,
    col: newCol,
    row: newRow,
  }
}

import React from 'react'
import { Checkbox } from 'components/design-system'
import { Select } from 'antd'
import Dropdown from 'components/standards/Dropdown'
import { AWSRegions, InputField } from './common'
import { ResourceFormProps } from '../resources/types'

const AthenaForm = (props: ResourceFormProps) => (
  <>
    <hr />
    <h5 className="section-heading light-gray mb12">General</h5>
    <div className="grid-1c mb20">
      <label className="input-label">AWS region</label>
      <Dropdown
        showSearch
        placeholder="us-east-2"
        value={props.resource.options.athena_aws_region}
        onChange={props.onChangeOption('athena_aws_region')}
      >
        {AWSRegions.map((region) => (
          <Select.Option key={region} value={region}>
            {region}
          </Select.Option>
        ))}
      </Dropdown>
      <InputField
        label="S3 Output Location"
        resourceKey="athena_s3_output_location"
        placeholder="s3://query-results-bucket/test-folder-1"
        {...props}
      />
      {(!MAIN_DOMAIN || __DEV__) && (
        <Checkbox
          checked={props.resource.options.authWithIAM}
          onChange={(checked) => {
            props.onChangeOption('authWithIAM')(checked.target.checked)
          }}
          className="grid-offset-1"
        >
          Connect using underlying AWS IAM Role
        </Checkbox>
      )}
      {!props.resource.options.authWithIAM && (
        <>
          <InputField label="AWS Access Key ID" resourceKey="athena_access_key_id" {...props} />

          <InputField label="AWS Secret Key" resourceKey="athena_secret_access_key" {...props} />
        </>
      )}
    </div>
  </>
)

export default {
  label: 'Athena',
  form: AthenaForm,
  defaults: {
    options: {
      athena_aws_region: '',
      athena_s3_output_location: '',
      athena_access_key_id: '',
      athena_secret_access_key: '',
    },
  },
}

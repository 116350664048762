import React from 'react'
import { Select } from 'components/design-system'

import { InputField, CallbackURLField } from '../common'
import { ResourceFormProps } from '../types'
import { OAuthForm, USER_CALLBACK_URL } from '../../oauthForm'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'

const GITHUB_SCOPES = [
  'admin:org',
  'admin:org_hook',
  'admin:public_key',
  'admin:repo_hook',
  'delete_repo',
  'gist',
  'notifications',
  'public_repo',
  'read:org',
  'read:public_key',
  'read:repo_hook',
  'repo',
  'repo:status',
  'repo_deployment',
  'user',
  'user:email',
  'user:follow',
  'write:org',
  'write:public_key',
  'write:repo_hook',
]

const GitHubOAuthForm = (props: ResourceFormProps) => (
  <>
    <CallbackURLField {...props} />

    <InputField label="OAuth Client ID" resourceKey="oauth2_client_id" required {...props} />
    <InputField label="OAuth Client Secret" resourceKey="oauth2_client_secret" required {...props} />

    <label className="input-label">Scopes</label>
    <Select
      mode="multiple"
      value={props.resource.options.oauth2_scope ? props.resource.options.oauth2_scope.split(' ') : []}
      onChange={(values: any) => props.onChangeOption('oauth2_scope')(values.join(' '))}
    >
      {GITHUB_SCOPES.map((scope) => (
        <Select.Option key={scope} value={scope}>
          {scope}
        </Select.Option>
      ))}
    </Select>

    {props.resource.id && (
      <OAuthForm
        buttonStyle={{ width: 'inherit' }}
        resourceId={props.resource.id}
        buttonText="Authorize GitHub integration with your own account."
      />
    )}
  </>
)

const GithubPersonalTokenForm = (props: any) => (
  <>
    <InputField label="GitHub username" resourceKey="basic_username" required {...props} />
    <InputField
      label={
        <span>
          GitHub personal token (generate one{' '}
          <a rel="noopener noreferrer" target="_blank" href="https://github.com/settings/tokens">
            here
          </a>
          )
        </span>
      }
      required
      resourceKey="basic_password"
      {...props}
    />
  </>
)

const GitHubForm = (props: any) => (
  <>
    <hr />
    <div className="flex mb16" style={{ justifyContent: 'space-between' }}>
      <h5 className="section-heading light-gray">General</h5>

      {props.resource.options.authentication === 'oauth2' ? (
        <a
          className="fs-12 fw-600 blue"
          href="#"
          onClick={() => {
            props.updateResourceOptions({
              authentication: 'basic',
              customHeaders: [],
            })
          }}
        >
          Continue with Personal Token
        </a>
      ) : (
        <a
          className="fs-12 fw-600 blue"
          href="#"
          onClick={() => {
            props.updateResourceOptions({
              authentication: 'oauth2',
              customHeaders: [['Authorization', 'token OAUTH2_TOKEN']],
            })
          }}
        >
          Continue with OAuth
        </a>
      )}
    </div>
    <div className="grid-1c mb20">
      {props.resource.options.authentication === 'oauth2' ? (
        <GitHubOAuthForm {...props} />
      ) : (
        <GithubPersonalTokenForm {...props} />
      )}
    </div>
  </>
)

export default {
  label: 'GitHub',
  form: GitHubForm,
  isOAuth: (resource: any) => resource.options.authentication === 'oauth2',
  defaults: {
    options: {
      authentication: 'basic',
      baseURL: 'https://api.github.com/graphql',
      basic_password: '',
      basic_username: '',
      oauth2_client_id: '',
      oauth2_client_secret: '',
      oauth2_scope: '',
      customQueryParameters: [['', '']],
      customHeaders: [],
      spec: `${RETOOL_SPECS_DOMAIN}/github.json`,
      oauth2_callback_url: USER_CALLBACK_URL,
      oauth2_auth_url: 'https://github.com/login/oauth/authorize',
      oauth2_access_token_url: 'https://github.com/login/oauth/access_token',
    },
  },
  validator: ({ options }: any) => {
    if (options.authentication === 'oauth2') {
      return options.oauth2_client_id && options.oauth2_client_secret
    } else {
      return options.basic_username && options.basic_password
    }
  },
}

export const RESOURCE = 'resource'
export const APPLICATION = 'application'
export const INVITE = 'invite'

export const ONBOARDING_SUGGESTIONS_DISMISSED = 'dismissed'

// NB: Order of these matters; it's used in the UI
export const STAGES = [RESOURCE, APPLICATION, INVITE]

export type Stage = typeof STAGES[number]

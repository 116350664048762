import React from 'react'
import _ from 'lodash'
import Immutable, { Record } from 'immutable'
import { CALL_API_DEPRECATE as CALL_API } from 'networking/index.deprecated'
import { JSON_HEADERS } from 'networking/util'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import {
  handleAuthResponse,
  REQUEST_LOGIN,
  RECEIVE_LOGIN,
  FAILURE_LOGIN,
  REQUEST_SIGNUP,
  RECEIVE_SIGNUP,
  FAILURE_SIGNUP,
} from 'store/session'
import { browserHistory } from 'react-router'
import { message } from 'antd'
import { REQUEST_RESET_PASSWORD, RECEIVE_RESET_PASSWORD, FAILURE_RESET_PASSWORD } from 'store/constants'
import { GenericErrorResponse } from 'common/records'
import { callApi } from '../../../store/callApi'

// ------------------------------------
// Constants
// ------------------------------------

const SET_AUTH_STATE = 'SET_AUTH_STATE'

export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'

export const REQUEST_INVITE_INFO = 'REQUEST_INVITE_INFO'
export const RECEIVE_INVITE_INFO = 'RECEIVE_INVITE_INFO'
export const FAILURE_INVITE_INFO = 'FAILURE_INVITE_INFO'

export const USE_SIGNUP_TOKEN = 'USE_SIGNUP_TOKEN'

export const RESET_PASSWORD = 'RESET_PASSWORD'

// ------------------------------------
// Actions
// ------------------------------------
//

type UseSignupTokenPayload = {
  email: string | null
  signupToken: string
}

export function setError(error: any) {
  return {
    type: SET_LOGIN_ERROR,
    payload: error,
  }
}
export function useSignupToken(signupToken: any) {
  return async (dispatch: any) => {
    const result = await dispatch({
      [CALL_API]: {
        endpoint: `/api/invite/${signupToken}`,
        method: 'GET',
        types: [REQUEST_INVITE_INFO, RECEIVE_INVITE_INFO, FAILURE_INVITE_INFO],
      },
    })
    const email = result.payload?.invite?.email
    const payload: UseSignupTokenPayload = {
      signupToken,
      email,
    }
    dispatch({ type: USE_SIGNUP_TOKEN, payload })
    const successful = !result.error
    retoolAnalyticsTrack('Clicked Verification Link', { successful, email })
  }
}

function postApi(endpoint: any, loginDetails: any, types: any) {
  return {
    [CALL_API]: {
      endpoint,
      body: JSON.stringify(loginDetails),
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: INCLUDE_COOKIES_IN_API_CALLS || 'same-origin',
      types,
    },
  }
}

const LOGIN_TYPES = [REQUEST_LOGIN, RECEIVE_LOGIN, FAILURE_LOGIN]
const SIGNUP_TYPES = [REQUEST_SIGNUP, RECEIVE_SIGNUP, FAILURE_SIGNUP]

const responseIsSuccessful = (response: any) => response.type === RECEIVE_LOGIN || response.type === RECEIVE_SIGNUP

export function login(loginDetails: any, _redirectUri = '/') {
  return async (dispatch: any) => {
    const endpoint = '/api/login'

    await dispatch({ type: SET_AUTH_STATE, payload: { email: loginDetails.email } })

    const actionResponse = await dispatch(postApi(endpoint, loginDetails, LOGIN_TYPES))

    if (responseIsSuccessful(actionResponse)) {
      dispatch(handleAuthResponse(actionResponse.payload))
    }
  }
}

export function signup(loginDetails: any) {
  const { signupTheme } = loginDetails
  return async (dispatch: any) => {
    const endpoint = '/api/signup'

    await dispatch({ type: SET_AUTH_STATE, payload: _.pick(loginDetails, ['email', 'firstName', 'lastName']) })
    const actionResponse = await dispatch(
      postApi(endpoint, _.pick(loginDetails, ['firstName', 'lastName', 'email', 'password']), SIGNUP_TYPES),
    )

    if (responseIsSuccessful(actionResponse)) {
      dispatch(handleAuthResponse(actionResponse.payload, signupTheme))
    }
  }
}

export function resetPassword(email: any) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: `/api/user/resetPassword`,
        body: JSON.stringify({ email }),
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        types: [REQUEST_RESET_PASSWORD, RECEIVE_RESET_PASSWORD, FAILURE_RESET_PASSWORD],
      }),
    )
    if (result.type === RECEIVE_RESET_PASSWORD) {
      // eslint-disable-next-line no-console
      console.log('Got here')
      message.success('Successfully sent reset password request')
    } else if (result.type === FAILURE_RESET_PASSWORD) {
      message.error(result.payload.response.message)
    }
    return result
  }
}

export function claimInvitation(loginDetails: any) {
  return async (dispatch: any) => {
    const endpoint = '/api/claimInvitation'

    await dispatch({ type: SET_AUTH_STATE, payload: _.pick(loginDetails, ['email', 'firstName', 'lastName']) })

    const actionResponse = await dispatch(postApi(endpoint, loginDetails, SIGNUP_TYPES))

    if (responseIsSuccessful(actionResponse)) {
      dispatch(handleAuthResponse(actionResponse.payload))
    }
  }
}

export function oktaLogin(accessToken: any, redirectUri = '/') {
  const endpoint = '/api/loginViaOkta'
  return async (dispatch: any) => {
    const actionResponse = await dispatch({
      [CALL_API]: {
        endpoint,
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ accessToken }),
        types: [REQUEST_LOGIN, RECEIVE_LOGIN, FAILURE_LOGIN],
      },
    })

    const orgCreated = actionResponse.payload.orgCreated

    if (orgCreated) {
      return await browserHistory.push('/')
    } else {
      return await browserHistory.push(redirectUri)
    }
  }
}

export function googleLogin(idToken: any, _redirectUri = '/') {
  const endpoint = '/api/google/login'

  return async (dispatch: any) => {
    if (!idToken) {
      dispatch({
        type: FAILURE_LOGIN,
        payload: {
          response: {
            message: (
              <div>
                Google Login failed. Privacy browser extensions are a known cause of this problem. For more information,
                see{' '}
                <a href="https://developers.google.com/identity/sign-in/web/troubleshooting#third-party_cookies_and_data_blocked">
                  here.
                </a>
              </div>
            ),
          },
        },
      })
      return browserHistory.push('/auth/login')
    }
    const actionResponse = await dispatch({
      [CALL_API]: {
        endpoint,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken }),
        credentials: 'same-origin',
        types: [
          REQUEST_LOGIN,
          {
            type: RECEIVE_LOGIN,
            meta: (action: any, state: any, res: any) => {
              if (res) {
                return { status: res.status }
              }
            },
          },
          FAILURE_LOGIN,
        ],
      },
    })

    if (responseIsSuccessful(actionResponse)) {
      dispatch(handleAuthResponse(actionResponse.payload))
    } else {
      return await browserHistory.push('/auth/login')
    }
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------
//

const ACTION_HANDLERS: any = {
  [SET_LOGIN_ERROR]: (state: any, action: any) => state.set('error', action.payload),
  [REQUEST_SIGNUP]: (state: any) => {
    return state.set('isFetching', true).set('error', null)
  },
  [RECEIVE_SIGNUP]: (state: any) => {
    return state.set('isFetching', false)
  },
  [FAILURE_SIGNUP]: (state: any, action: GenericErrorResponse) => {
    return state
      .set('error', action.payload.response ? action.payload.response.message : 'An unknown error occured')
      .set('isFetching', false)
  },
  [SET_AUTH_STATE]: (state: any, action: any) => {
    return state.merge(action.payload)
  },
  [REQUEST_LOGIN]: (state: any) => {
    return state.set('isFetching', true).set('error', null)
  },
  [RECEIVE_LOGIN]: (state: any) => {
    return state.set('isFetching', false)
  },
  [FAILURE_LOGIN]: (state: any, action: GenericErrorResponse) => {
    if (!action.payload.response) {
      return state.set('error', 'An unknown error has occurred.').set('isFetching', false)
    }
    return state.set('error', action.payload.response.message).set('isFetching', false)
  },
  [USE_SIGNUP_TOKEN]: (state: any, action: { payload: UseSignupTokenPayload }) => {
    return state.set('signupToken', action.payload.signupToken).set('email', action.payload.email)
  },
  [RECEIVE_INVITE_INFO]: (state: any, action: any) => {
    return state.set('invite', Immutable.fromJS(action.payload.invite))
  },
  [REQUEST_RESET_PASSWORD]: (state: any) => state.set('isFetching', true),
  [RECEIVE_RESET_PASSWORD]: (state: any) => state.set('isFetching', false),
  [FAILURE_RESET_PASSWORD]: (state: any) => state.set('isFetching', false),
}

// ------------------------------------
// Reducer
// ------------------------------------
const defaultLoginParams: any = {
  error: null,
  isFetching: false,
  signupToken: '',
  invite: null,
  firstName: '',
  lastName: '',
  email: '',
}

class LoginModel extends Record(defaultLoginParams) {}

const initialState = new LoginModel()

export default function loginReducer(state = initialState, action: any) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

import { sanitizeUrl } from 'common/utils'
import { shouldClientRoute } from 'common/utils/forceClientSideRoutingOnClickHandler'
import { PluginMethodConfig } from 'components/plugins'
import { browserHistory } from 'react-router'
import { assertIsString } from 'types/typeguards'
import { parse as parseUrl } from 'url'

const openUrl: PluginMethodConfig = {
  metadata: {
    label: 'Open URL',
    description:
      'Opens a URL (string) in a new tab by default. Pass in { newTab: false } to open url in current tab. Pass in { forceReload: true } to prevent client side routing and force a page reload. URL string must start with http:// or https://.',
    example: '`utils.openUrl(url, { newTab: boolean = true, forceReload: boolean = false })`',
    params: [
      { type: 'codeInput', name: 'url', props: { label: 'URL', validator: 'string' } },
      {
        type: 'objectInput',
        name: 'options',
        props: {
          label: 'Options',
          params: [
            { type: 'checkbox', name: 'newTab', props: { label: 'Open in a new tab' } },
            {
              type: 'checkbox',
              name: 'forceReload',
              props: {
                label: 'Disable client-side routing',
                docs: 'Prevents client-side routing and forces a page reload.',
              },
            },
          ],
        },
      },
    ],
  },
  method: (
    url,
    options = {
      newTab: true,
      forceReload: false,
    },
  ) => {
    assertIsString(url, '`URL` must be a string.')

    const { newTab, forceReload } =
      // Migrate old `newTab` boolean to object options
      typeof options === 'boolean'
        ? {
            newTab: options,
            forceReload: false,
          }
        : (options as { newTab: boolean; forceReload: boolean })

    const sanitizedUrl = sanitizeUrl(url)

    if (forceReload) {
      window.location.href = sanitizedUrl
    } else if (newTab) {
      window.open(sanitizedUrl)
    } else {
      const { hostname, pathname, search, hash } = parseUrl(sanitizedUrl)

      // Client side routing for customers linking between Retool apps
      if (hostname && pathname && shouldClientRoute({ hostname, pathname })) {
        browserHistory.push({
          pathname,
          search,
          hash,
        })
      } else {
        window.location.href = sanitizedUrl
      }
    }
  },
}

export default openUrl

import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Select } from 'components/design-system'

import { RetoolState } from 'store'
import { InputField, KeyValueField } from './common'
import { ResourceFormProps } from './types'
import { RetoolApprovalWorkflowSettings, RetoolApprovalWorkflowOptions } from 'common/records'
import { groupsSelector } from 'store/selectors'
import { Group } from '__globalShared__/permissions'

export const databaseDefaults: RetoolApprovalWorkflowOptions = {
  options: {
    namespace: '',
    timeout: 10000,
    creatorGroups: [],
    approverGroups: [],
  },
}

export const databaseValidator = (resource: RetoolApprovalWorkflowSettings) =>
  resource.options.namespace &&
  resource.options.approverGroups
    .filter((g: [string, string]) => !!g[0])
    .reduce((valid: boolean, curr: [string, string]) => valid && parseInt(curr[1]) > 0, true)

type RetoolApprovalWorkflowFormProps = ResourceFormProps & {
  groups: { [id: number]: Group }
}

const _RetoolApprovalWorkflowForm = (props: RetoolApprovalWorkflowFormProps) => {
  return (
    <div className="grid-1c mb20">
      <InputField label="Namespace" placeholder="user_approval" resourceKey="namespace" {...props} />

      <label className="input-label">Creator groups</label>
      <Select
        mode="multiple"
        value={props.resource.options.creatorGroups}
        onChange={props.onChangeOption('creatorGroups')}
      >
        {Object.values(props.groups).map((g) => (
          // coercing id's into string as this makes storage format for this and approverGroups match
          // doing away with the need for a transform that has to support both string and int
          <Select.Option key={g.id.toString()} value={g.id.toString()}>
            {g.name}
          </Select.Option>
        ))}
      </Select>

      <KeyValueField
        label="Approver groups"
        resourceKey="approverGroups"
        keyFieldPlaceholder="Group Name"
        valueFieldPlaceholder="Minimum Required Approvals"
        resource={props.resource}
        updateResourceOptions={props.updateResourceOptions}
        possibleValues={_.mapValues(props.groups, (g) => g.name)}
      />
    </div>
  )
}
const RetoolApprovalWorkflowForm = connect((state: RetoolState) => ({
  groups: groupsSelector(state) as { [id: number]: Group },
}))(_RetoolApprovalWorkflowForm)

export default {
  label: 'Retool Approval Workflows',
  form: RetoolApprovalWorkflowForm,
  defaults: {
    ...databaseDefaults,
  },
  validator: databaseValidator,
}

import React from 'react'
import { Checkbox } from 'components/design-system'
import { InputField, ARNInputField } from './common'
import { ResourceFormProps } from './types'

const S3Form = (props: ResourceFormProps) => (
  <>
    <div className="grid-1c mb12">
      {(!MAIN_DOMAIN || __DEV__) && (
        <Checkbox
          checked={props.resource.options.authWithIAM}
          onChange={(checked) => {
            props.onChangeOption('authWithIAM')(checked.target.checked)
          }}
          className="grid-offset-1"
        >
          Connect using underlying AWS IAM Role
        </Checkbox>
      )}
      <div className="grid-offset-1">
        <Checkbox
          checked={props.resource.options.usingThirdPartyProvider}
          onChange={(checked) => {
            props.onChangeOption('usingThirdPartyProvider')(checked.target.checked)
          }}
        >
          Use Custom S3 Endpoint
        </Checkbox>
        <div className="ml24">
          You can use this to connect Retool to other non-S3 services, like{' '}
          <a target="_blank" rel="noopener noreferrer" href="https://developers.digitalocean.com/documentation/spaces/">
            Digital Ocean Spaces
          </a>
          {' or '}
          <a target="_blank" rel="noopener noreferrer" href="https://wasabi.com/s3-compatible-cloud-storage/">
            Wasabi
          </a>
          .
        </div>
      </div>
      <div className="grid-offset-1">
        <Checkbox
          checked={props.resource.options.forcePathStyle}
          onChange={(checked) => {
            props.onChangeOption('forcePathStyle')(checked.target.checked)
          }}
        >
          Force S3 URLs to use path style
        </Checkbox>
        <div className="light-gray">This may help resolve connectivity issues with S3-compatible services.</div>
      </div>
      {props.resource.options.usingThirdPartyProvider && (
        <InputField label="Base URL" resourceKey="s3_base_url" {...props} />
      )}
      {!props.resource.options.authWithIAM && (
        <>
          <InputField label="Access key ID" resourceKey="s3_access_key_id" required {...props} />
          <InputField label="Secret access key ID" resourceKey="s3_secret_access_key_id" required {...props} />
          <div className="grid-offset-1 description">
            Find instructions on where to find access keys{' '}
            <a
              href="https://docs.aws.amazon.com/general/latest/gr/aws-sec-cred-types.html#access-keys-and-secret-access-keys"
              target="_blank"
              rel="noopener"
            >
              in the S3 docs
            </a>
            .
          </div>
        </>
      )}
      <InputField label="S3 bucket name" resourceKey="s3_default_bucket_name" {...props} />
      <ARNInputField {...props} />
      <InputField label="Default S3 ACL for uploaded files" resourceKey="s3_default_acl" {...props} />
      <div className="grid-offset-1 description">
        Don't forget to configure CORS on your bucket. See{' '}
        <a href="http://docs.aws.amazon.com/AmazonS3/latest/dev/cors.html" target="_blank">
          Cross-Origin Resource Sharing (CORS)
        </a>
        .
      </div>
    </div>
  </>
)

interface S3StoreOptionsType {
  s3_access_key_id: string
  s3_secret_access_key_id: string
  s3_default_bucket_name: string
  s3_default_acl: string
  assumeRole: string
  authWithIAM: boolean
  forcePathStyle: boolean
  usingThirdPartyProvider: boolean
}

export default {
  label: 'S3',
  form: S3Form,
  defaults: {
    options: {
      s3_access_key_id: '',
      s3_secret_access_key_id: '',
      s3_default_bucket_name: '',
      s3_default_acl: '',
      assumeRole: '',
      authWithIAM: false,
      forcePathStyle: false,
      usingThirdPartyProvider: false,
    },
  },
  validator: ({ options }: { options: S3StoreOptionsType }) =>
    options.authWithIAM || (options.s3_access_key_id && options.s3_secret_access_key_id),
}

import React, { FC, useState } from 'react'
import { Modal, Message, Button } from 'components/design-system'
import { autogeneratedBranchName } from 'common/utils/github'
import useFetchWithLoading from 'common/hooks/useFetchWithLoading'
import { callInternalApi } from 'networking'
import { useSelector } from 'react-redux'
import { userEmailSelector } from 'store/selectors/userSelectors'
import { Spin } from 'antd'
import type { CreateBranchResponseType } from '__globalShared__/branches'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { browserHistory } from 'react-router'
import CreateBranchInput from './CreateBranchInput'
import _styles from "./CreateBranchModal-styles.module.scss";


type CreateBranchModalProps = {
  closeModal: () => void
  pageUuid: string
  editLink: string
}

const styles = _styles

export const CreateBranchModal: FC<CreateBranchModalProps> = ({ closeModal, pageUuid, editLink }) => {
  const userEmail = useSelector(userEmailSelector)
  const [newBranchName, setNewBranchName] = useState(autogeneratedBranchName(userEmail))
  const [isBranchNameValid, setIsBranchNameValid] = useState(true)

  const handleCreateBranchResponse = (response: CreateBranchResponseType) => {
    if (response.created) {
      retoolAnalyticsTrack('Branch Created', {
        pageUuid,
      })
      Message.success('Successfully created branch.')
      browserHistory.push(`/tree/${encodeURIComponent(newBranchName)}${editLink}`)
      closeModal()
    }
  }

  const [handleCreateBranch, creatingBranch] = useFetchWithLoading(
    (newBranchName: string, pageUuid: string): Promise<CreateBranchResponseType> =>
      callInternalApi({
        url: `/api/branches`,
        method: 'POST',
        body: {
          name: newBranchName,
          pageId: pageUuid,
        },
      }),
    { onSuccess: handleCreateBranchResponse, onError: (error) => Message.error(`${error}`) },
  )

  return (
    <Modal title="Create a new branch" visible={true} footer={null} onCancel={closeModal} width="650px" maskClosable>
      <CreateBranchInput
        newBranchName={newBranchName}
        onUpdateBranchName={setNewBranchName}
        onSetIsSubmitEnabled={setIsBranchNameValid}
      />
      <div className={styles.label}>
        Your branch will be created locally. You will need to commit and push it to see it in GitHub.
      </div>
      <div className={styles.footer}>
        <Spin spinning={creatingBranch}>
          <Button
            type="primary"
            className="button"
            disabled={creatingBranch || !isBranchNameValid}
            onClick={() => handleCreateBranch(newBranchName, pageUuid)}
          >
            Create new branch
          </Button>
        </Spin>
      </div>
    </Modal>
  )
}

export default CreateBranchModal

import { pagePathFromUuid } from 'common/utils'
import requirePlugins from 'components/plugins/requirePlugins'
import { getPageNames, getTags, pageLoad, pagesLoad, tearDownPage } from 'store/appModel/pages'
import { TREE_URL_PREFIX } from 'store/constants'
import { locationLoaded, locationLoading } from 'store/location'
import { getBranches, getFlows, getInstrumentationIntegrations, getResources } from 'store/user'
import { fetchAvailableModules } from 'store/modules'
import { initializeBuildingBlocks } from 'components/BuildingBlocks/buildingBlocks'
import { injectReducer } from 'store/reducers'
import { getImportableQueries } from './modules/editor'
import type { RetoolStore } from 'store'
import { performanceReducer } from './modules/performance'

export default function EditorRoute(store: RetoolStore) {
  let editorLoaded = false
  async function loadPageData(pageName: any, queryParams: any, branchName?: string) {
    if (!editorLoaded) {
      return
    }
    // Make sure that these queries are fired sequentially

    await store.dispatch(pageLoad(pageName, true, queryParams._historyOffset, undefined, branchName))
    store.dispatch(getResources())
    store.dispatch(getInstrumentationIntegrations())
    store.dispatch(getImportableQueries())
    store.dispatch(getTags())
    store.dispatch(getPageNames())
    store.dispatch(pagesLoad({ useCache: true }))
    store.dispatch(fetchAvailableModules())
    store.dispatch(getFlows())
    store.dispatch(getBranches())
  }

  const onEnter = (nextState: any) => {
    // kick off library loading
    import(/* webpackChunkName: "prettierStandalone" */ 'prettier/standalone')
    import(/* webpackChunkName: "prettierStandalone" */ 'prettier/parser-babel')

    // eslint-disable-next-line no-console
    console.log('[DBG] page load: fetching route info', new Date().getTime() - window.htmlLoadedAt)
    document.title = `${nextState.params.splat} | Editor | Retool`
    let splat = nextState.location.pathname.replace('/editor/', '')
    const branch = nextState.params.branch
    if (branch) {
      splat = nextState.location.pathname.replace(`${TREE_URL_PREFIX}/${encodeURIComponent(branch)}/editor/`, '')
    }
    initializeBuildingBlocks()
    if (nextState.location.pathname.includes('Onboarding%20Page')) {
      window?.FS?.restart?.()
    }
    loadPageData(splat, nextState.location.query, branch)
  }

  const onLeave = () => {
    store.dispatch(tearDownPage())
    store.dispatch({ type: 'CLEAR_EDITOR' })
  }

  const getComponent = (nextState: any, cb: any) => {
    store.dispatch(locationLoading())
    require.ensure(
      [],
      (require) => {
        requirePlugins(() => {
          // eslint-disable-next-line no-console
          console.log('loading', nextState.params.splat)

          const EditorContainer = require('./containers/EditorContainer').default
          const editorStore: any = require('./modules/editor')
          const reducer = editorStore.default

          const selectionReducer = require('./modules/selection').default
          const paginatedSavesReducer = require('store/saves').default
          const playgroundReducer = require('store/playground').default
          const resourceReducer = require('./../Resources/modules/resources').default

          // TODO: Set branch?
          injectReducer(store, { key: 'editor', reducer })
          injectReducer(store, { key: 'paginatedSaves', reducer: paginatedSavesReducer })
          injectReducer(store, { key: 'editorSelection', reducer: selectionReducer })
          injectReducer(store, { key: 'playground', reducer: playgroundReducer })
          injectReducer(store, { key: 'instrumentation', reducer })
          injectReducer(store, { key: 'resources', reducer: resourceReducer })
          injectReducer(store, { key: 'performance', reducer: performanceReducer })

          if (!editorLoaded) {
            editorLoaded = true
            const branch = nextState.params.branch
            let splat = nextState.location.pathname.replace('/editor/', '')
            if (branch) {
              splat = nextState.location.pathname.replace(
                `${TREE_URL_PREFIX}/${encodeURIComponent(branch)}/editor/`,
                '',
              )
            }
            loadPageData(splat, nextState.location.query, branch)
          }
          store.dispatch(locationLoaded())

          cb(null, EditorContainer)
        })
      },
      'editor',
    )
  }

  return [
    {
      path: 'editor/**',
      onEnter,
      onLeave,
      getComponent,
    },
    {
      path: 'tree/:branch/editor/**',
      onEnter,
      onLeave,
      getComponent,
    },
    {
      path: 'uuid/editor/:uuid',
      onEnter: async (nextState: any, replace: any, cb: any) => {
        const uuid = nextState.params.uuid
        await store.dispatch(pagesLoad())
        const pagePath = pagePathFromUuid(store.getState(), uuid)

        const queryParams = nextState.location.search || ''
        const url = pagePath ? `/editor/${pagePath}${queryParams}` : '/pageNotFound'
        replace(url)
        cb()
      },
    },
  ]
}

import React from 'react'

const Forbidden = () => (
  <div style={{ margin: 40 }}>
    403 - You don't currently have access to this page. Request access from a Retool administrator in your organization.
    <br />
    <a href="/"> go home </a>
  </div>
)

export default Forbidden

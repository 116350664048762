import React, { useState } from 'react'
import pluralize from 'pluralize'

import useFuzzyMatch from 'common/hooks/useFuzzyMatch'
import { Folder, ImmutableMapType } from 'common/records'
import { Modal, Button, Select, Icon } from 'components/design-system'
import { annotate } from 'components/FuzzyFind'

import './MoveItemsModal.scss'

export type MoveItemsModalProps = {
  folders: ImmutableMapType<Folder>[]
  visible?: boolean
  numPages?: number
  onRequestClose?: () => void
  onSubmit?: (folderId: number) => void
}

const cn = 'move-items-modal'

const MoveItemsModal: React.FC<MoveItemsModalProps> = ({
  folders,
  visible = false,
  numPages = 1,
  onRequestClose,
  onSubmit,
}) => {
  const [selectedFolderId, setSelectedFolderId] = useState(() => folders[0].get('id'))

  const [[matchesByKey, filteredOptions], , setSearchTerm] = useFuzzyMatch(
    folders,
    (folder) => folder.get('id'),
    (folder) => [folder, { name: folder.get('name').toLowerCase() }],
  )

  const onClickSubmit = () => onSubmit?.(selectedFolderId)

  return (
    <Modal
      className={cn}
      footer={null}
      title="Add to folder"
      destroyOnClose
      onCancel={onRequestClose}
      visible={visible}
    >
      <div className={`${cn}__subheader`}>
        Move {numPages} {pluralize('apps', numPages)} to:
      </div>
      <Select
        showSearch
        filterOption={false}
        value={selectedFolderId}
        onSearch={setSearchTerm}
        onChange={(val) => setSelectedFolderId(val as number)}
        onSelect={() => setSearchTerm('')}
        onBlur={() => setSearchTerm('')}
      >
        {filteredOptions.map((folder) => {
          const id = folder.get('id')
          const name = folder.get('name')
          return (
            <Select.Option value={id} key={id}>
              <div className={`${cn}__option`}>
                <Icon className="mb2" type="folder" />
                <span className="ml8">
                  {name === 'root' ? 'Unassigned (root)' : annotate(name, matchesByKey[id]?.name)}
                </span>
              </div>
            </Select.Option>
          )
        })}
      </Select>
      <Button type="primary" onClick={onClickSubmit}>
        Move to folder
      </Button>
    </Modal>
  )
}

export default MoveItemsModal

import React, { useState } from 'react'
import { Popover } from 'antd'

import Checkbox from '../Checkbox'
import '../Popover/Popover.scss'
import './ColorPicker.scss'
import TextInput from '../TextInput'
import classNames from 'classnames'
import useUpdateEffect from 'common/hooks/useUpdateEffect'

const ColorBox = (props: { color: string }) => {
  const { color } = props
  return <div className="color-box mr8" style={{ backgroundColor: color, color }} />
}

const MIN_WIDTH = '208px'

export type ColorPickerProps = {
  colors: string[]
  colorMap?: { [key: string]: string }
  allowCustomColors?: boolean
  color?: string
  onChange?: (color: string) => void
  customMinWidth?: string
  useGrayCheckmark?: boolean
  getPopoverContainer?: (trigger: HTMLElement) => HTMLElement
}

const ColorPicker = (props: ColorPickerProps) => {
  const [selectedColor, setSelectedColor] = useState(props.color ?? '')
  // const [expanded, setExpanded] = useState(false)
  const colorMap = props.colorMap || {}

  const onChange = (colorName: string) => {
    setSelectedColor(colorName)
    if (props.onChange) props.onChange(colorName)
  }

  useUpdateEffect(() => {
    if (props.color) onChange(props.color)
  }, [props.color])

  return (
    <div className="color-picker-popover retool-popover mb4">
      <Popover
        trigger="click"
        placement="bottomLeft"
        getPopupContainer={props.getPopoverContainer}
        content={
          <div
            className="retool-color-picker retool-color-picker-popover"
            style={{ minWidth: props.customMinWidth || MIN_WIDTH }}
          >
            <div className="color-list">
              {props.colors.map((color) => (
                <div
                  key={color}
                  className={classNames('color-box', { 'gray-checkmark': props.useGrayCheckmark || false })}
                >
                  <Checkbox
                    style={{
                      backgroundColor: Object.keys(colorMap).length > 0 ? colorMap[color] : color,
                      color,
                    }}
                    // className="color-box"
                    checked={color === selectedColor}
                    onClick={() => onChange(color)}
                  />
                </div>
              ))}
            </div>

            {props.allowCustomColors && (
              <>
                <hr style={{ width: 'calc(100% + 30px)', marginLeft: -16, marginTop: 12, marginBottom: 12 }} />
                <div className="flex custom-color-picker">
                  <ColorBox color={selectedColor} />
                  <TextInput value={selectedColor} onChange={(ev) => onChange(ev.target.value)} />
                </div>
              </>
            )}
          </div>
        }
      >
        <div className="retool-color-picker">
          {/* <div className=" flex items-center color-view" onClick={() => setExpanded(!expanded)}> */}
          <div className="flex items-center color-view">
            <ColorBox color={Object.keys(colorMap).length > 0 ? colorMap[selectedColor] : selectedColor} />
            <span className="fw-500">{selectedColor}</span>
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default ColorPicker

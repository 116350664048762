import { injectReducer } from 'store/reducers'
import { getResources } from 'store/user'

export default function DatasourcesRoute(store: any) {
  return {
    path: 'datasources',
    /*  Async getComponent is only invoked when route matches   */
    childRoutes: [
      {
        path: ':resourceId',
        childRoutes: [
          {
            path: 'tables/:tableName',
            onEnter: (nextState: any, replace: any, cb: any) => {
              require.ensure(
                [],
                (require) => {
                  const datasources: any = require('./modules/datasources')
                  const selectTable = datasources.selectTable
                  const getTableData = datasources.getTableData

                  store.dispatch(selectTable(nextState.params.tableName))
                  store.dispatch(getTableData())

                  document.title = `${nextState.params.tableName} | Retool`
                  const DataEntry = require('./containers/DataEntryContainer.js').default
                  cb(null, DataEntry)
                },
                'tablePage',
              )
            },
            getComponent(nextState: any, cb: any) {
              require.ensure(
                [],
                (require) => {
                  const DataEntry = require('./containers/DataEntryContainer.js').default
                  cb(null, DataEntry)
                },
                'tablePage',
              )
            },
          },
        ],
        onEnter: (nextState: any, replace: any, cb: any) => {
          require.ensure(
            [],
            (require) => {
              const datasources: any = require('./modules/datasources')
              const selectDatabase = datasources.selectDatabase
              const getAllTables = datasources.getAllTables
              store.dispatch(selectDatabase(nextState.params.resourceId))
              store.dispatch(getAllTables())
              document.title = 'Database Editor | Retool'
              cb()
            },
            'tableModule',
          )
        },
        indexRoute: {
          getComponent(nextState: any, cb: any) {
            require.ensure(
              [],
              (require) => {
                const Database = require('./containers/DatabaseContainer.js').default
                cb(null, Database)
              },
              'database',
            )
          },
        },
      },
    ],
    indexRoute: {
      getComponent(nextState: any, cb: any) {
        /*  Webpack - use 'require.ensure' to create a split point
          and embed an async module loader (jsonp) when bundling   */
        require.ensure(
          [],
          (require) => {
            /*  Webpack - use require callback to define
            dependencies for bundling   */
            const Overview = require('./containers/OverviewContainer.js').default

            /*  Return getComponent   */
            cb(null, Overview)

            /* Webpack named bundle   */
          },
          'datasources',
        )
      },
    },
    onEnter: (nextState: any, replace: any, cb: any) => {
      require.ensure(
        [],
        (require) => {
          /*  Webpack - use require callback to define
            dependencies for bundling   */
          const datasources: any = require('./modules/datasources')
          const reducer = datasources.default

          /*  Add the reducer to the store on key 'counter'  */
          injectReducer(store, { key: 'datasources', reducer })
          store.dispatch(getResources())
          document.title = 'Database Editor | Retool'

          cb()
          /* Webpack named bundle   */
        },
        'datasourceModule',
      )
    },
  }
}

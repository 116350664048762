import * as _ from 'lodash'
import { RETOOL_VERSION } from 'retoolConstants'
import { dispatch, RetoolDispatch, RetoolState } from 'store'
import getNamespaceAwareModel from 'store/appModel/getNamespaceAwareModel'
import {
  EvalOptions,
  Repl,
  RETOOL_JS_API_PLACEHOLDER,
  RETOOL_JS_CALLBACK_PLACEHOLDER,
  SandboxRequest,
  SandboxScopeResponse,
  StandardEvaluationType,
} from '../sandbox/types'
import { messageIframe } from './iframeAdapter'
import { updateSandboxGlobals } from 'store/globalsUtils'
import performanceReporter, { PerfTimerHandle } from 'DatadogReporter'
import { getExperimentValue } from './utils/experiments'
import { appModelJSValuesSelector } from 'store/selectors'
import { extendScopeWithJsApi } from 'store/appModel/extendScopeWithJsApi'

let iframe: any = null
let iframeInitialized = false
let requestQueue: any = []
let requests: {
  [requestId: string]:
    | {
        resolve?: (arg: unknown) => void
        reject?: (arg: unknown) => void
        execFunc?: Function
      }
    | undefined
} = {}

function getSandboxRestrictions(customRetoolSandboxRestrictions: string) {
  const ALLOWED_CUSTOM_SANDBOX_RESTRICTIONS = ['allow-popups', 'allow-downloads', 'allow-modals']
  let sandboxRestrictions = `allow-scripts `
  if (customRetoolSandboxRestrictions) {
    const allowedCustomRetoolSandboxRestrictions = customRetoolSandboxRestrictions
      .split(' ')
      .filter((sandboxRestriction) => ALLOWED_CUSTOM_SANDBOX_RESTRICTIONS.includes(sandboxRestriction))
      .join(' ')
    sandboxRestrictions += allowedCustomRetoolSandboxRestrictions
  }

  return sandboxRestrictions
}

let sandboxPerfTimer: PerfTimerHandle | undefined

export function initSandbox({
  preloadedJavaScript,
  javaScriptLinks,
  environmentVariables,
}: {
  preloadedJavaScript: string
  javaScriptLinks: string[]
  environmentVariables?: any
}) {
  if (__TEST__) {
    return
  }

  sandboxPerfTimer = performanceReporter.startTimer()
  const customRetoolSandboxRestrictions = environmentVariables?.customRetoolSandboxRestrictions || ''

  const fetchJSFilesPromise: Promise<{
    alasql: string
    defaultLibraries: Array<{ name: string; code: string }>
    userProvidedLibraries: Array<{ name: string; code: string }>
  }> = (async () => {
    // Default libraries were used to be bundled with sandbox code
    // We now fetch them outside the sandbox so they can be persistently cached across page reloads
    const defaultLibraryLinks: string[] = [
      // All files are fetched from CDN and copied into assets folder during webpack build step
      // Checkout `DownloadVendorJSPlugin` used inside config/webpack.config.js to see how we are downloading these.
      `${__webpack_public_path__}vendor-js/lodash@4.17.12.min.js`,
      `${__webpack_public_path__}vendor-js/uuid@8.3.2.min.js`,
      `${__webpack_public_path__}vendor-js/moment-with-locales@2.24.0.min.js`,
      `${__webpack_public_path__}vendor-js/moment-timezone@0.5.33.min.js`,
      `${__webpack_public_path__}vendor-js/papaparse@4.6.3.min.js`,
      `${__webpack_public_path__}vendor-js/numbro@2.3.2.min.js`,

      require('yarpex-umd/browser/yarpex.min.js'),
      require('yarpex-bert-umd/browser/yarpex-bert.min.js'),
    ]

    const alasqlLink = require('alasql/dist/alasql.min.js')

    const userProvidedLibraries = Promise.all(
      javaScriptLinks.map(
        async (url: string): Promise<{ name: string; code: string }> => {
          const defaultReturn = { name: '', code: '' }

          try {
            const response = await fetch(url)
            if (!response.ok) {
              // eslint-disable-next-line no-console
              console.error('Failed to fetch JS library: ', url)
              // eslint-disable-next-line no-console
              console.error(
                'Status',
                response.status,
                (await response.text()) +
                  (response.status === 404 ? `, are you sure this URL is correct?: ${url}` : ''),
              )

              return defaultReturn
            }
            return { name: url, code: await response.text() }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Failed to JS library, are you sure this URL is correct?:  ', url)
            return defaultReturn
          }
        },
      ),
    )

    // In case the browser cached a bad response (ie: Asset with no CORS header)
    // We go re-fetch the response and put that new one in the cache.
    // This removes the need for user to manually reset browser cache.
    function fetchWithCacheBypassRetry(url: string) {
      return fetch(url)
        .catch(() => {
          return fetch(url, {
            cache: 'reload',
          })
        })
        .then((res) => res.text())
    }

    const defaultLibraries = Promise.all(
      defaultLibraryLinks.map(async (url: string) => {
        return { name: url, code: await fetchWithCacheBypassRetry(url) }
      }),
    )

    return {
      alasql: await fetchWithCacheBypassRetry(alasqlLink),
      defaultLibraries: await defaultLibraries,
      userProvidedLibraries: [...(await userProvidedLibraries).filter((lib) => lib.code !== '')],
    }
  })()

  if (iframe != null) {
    iframe.parentNode.removeChild(iframe)
  }
  iframe = document.createElement('iframe')
  iframe.sandbox = getSandboxRestrictions(customRetoolSandboxRestrictions)
  iframe.style = 'display: none;'
  iframe.src = iframe.src = `${SANDBOX_DOMAIN || ''}/sandbox.html?v=${RETOOL_VERSION}`

  iframe.onload = () => {
    fetchJSFilesPromise
      .then(({ alasql, defaultLibraries, userProvidedLibraries }) => {
        messageIframe(
          iframe,
          {
            type: 'init',
            data: {
              preloadedJavaScript,
              alasqlScript: alasql,
              defaultLibraries,
              userProvidedLibraries,
            },
          },
          '*',
        )
        return
      })
      .catch(() => {})
  }

  document.body.appendChild(iframe)

  iframeInitialized = false
  requestQueue = []
  requests = {}
}

function sendState() {
  return (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const scope = appModelJSValuesSelector(getState())
    if (scope !== null) {
      const noNamespaceScope = getNamespaceAwareModel(scope, undefined)
      const requestId = requestCount++
      const request: SandboxScopeResponse = {
        requestId,
        scope: noNamespaceScope,
      }

      messageIframe(
        iframe,
        {
          type: 'scopeResponse',
          data: request,
        },
        '*',
      )
    }
  }
}

function cleanupRequestPromise(requestId: string) {
  const request = requests[requestId]
  if (!request) return

  if (typeof request.execFunc === 'function') {
    // We don't want to delete execFunc since it might be invoked
    // in the future
    delete request.resolve
    delete request.reject
  } else {
    delete requests[requestId]
  }
}

window.addEventListener('message', (e) => {
  if ((iframe && e.source === iframe.contentWindow) || (iframe === null && e.source === null && __TEST__)) {
    const { requestId, resultType, result, error, sandboxGlobals } = e.data

    if (resultType === 'INITIALIZED') {
      iframeInitialized = true

      if (sandboxPerfTimer) {
        sandboxPerfTimer.end((duration) => ({
          type: 'frontend.performance.app_page.sandbox_init',
          durationMs: duration,
        }))
        sandboxPerfTimer = undefined
      }

      for (let i = 0; i < requestQueue.length; i++) {
        // eslint-disable-next-line no-console
        console.log('[DBG] page load: sandbox initialized', new Date().getTime() - window.htmlLoadedAt)
        const request = requestQueue[i]
        messageIframe(
          iframe,
          {
            type: 'request',
            data: request,
          },
          '*',
        )
      }
      dispatch(updateSandboxGlobals(sandboxGlobals))
    } else if (resultType === 'requestScope') {
      dispatch(sendState())
    } else if (resultType === 'batchRequest') {
      result.forEach((response: any) => {
        if (response.error) {
          requests[response.requestId]?.reject?.(new Error(response.error))
        } else {
          requests[response.requestId]?.resolve?.(response.result)
        }
        cleanupRequestPromise(response.requestId)
      })
    } else {
      const request = requests[requestId]
      if (!request) {
        // eslint-disable-next-line no-console
        return console.log(`No request found with the corresponding request id of ${requestId}, skipping...`)
      }
      if (resultType && resultType === 'FUNCTION_CALL') {
        const { functionName, args, resolveId, rejectId } = result
        request.execFunc?.(functionName, args, resolveId, rejectId)
      } else if (error) {
        request.reject?.(new Error(error))
        cleanupRequestPromise(requestId)
      } else {
        request.resolve?.(result)
        cleanupRequestPromise(requestId)
      }
    }
  }
})

/**
 * Batches up consecutive requests and sends them all at once to the sandbox
 */
export const batchedRequestSender = new (class {
  private flushQueueTimeout?: number
  private _requestQueue: SandboxRequest[] = []

  private flushQueue = () => {
    const batchRequest = { requests: this._requestQueue }
    this._requestQueue = []

    try {
      messageIframe(
        iframe,
        {
          type: 'batchRequest',
          data: batchRequest,
        },
        '*',
      )
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }

  queueRequest = (request: SandboxRequest) => {
    this._requestQueue.push(request)
    if (this.flushQueueTimeout) window.clearTimeout(this.flushQueueTimeout)
    this.flushQueueTimeout = window.setTimeout(this.flushQueue, 1)
  }
})()

let requestCount = 0

function replaceFunctionsWithJSApiPlaceholder(jsApi: {}): {} {
  return _.mapValues(jsApi, (variable: any) => {
    if (typeof variable === 'function') {
      return RETOOL_JS_API_PLACEHOLDER
    }
    return replaceFunctionsWithJSApiPlaceholder(variable)
  })
}

const experimentalEvaluatorCachingQueryParam = new URLSearchParams(window.location.search).get('evalCache')

const getExperimentalEvaluatorCaching = () => {
  if (experimentalEvaluatorCachingQueryParam === '1') {
    return true
  }

  if (experimentalEvaluatorCachingQueryParam === '0') {
    return false
  }

  return getExperimentValue('evaluatorTemplateCaching') ?? false
}

// Naive heruistic for reducing the size of scope being sent to the sandbox
// by simply checking if the global scope variable name is seen anywhere in the code string
export const getFilteredScopeByFindingTopLevelVariableNameInCode = (
  scope: { [globalVariableName: string]: unknown } | Array<unknown>,
  code: string,
) => {
  // For table specifically, it can send an array of scopes for each row,
  // We ignore that case for now
  if (Array.isArray(scope)) {
    return scope
  }

  const reducedScope: { [globalVariableName: string]: any } = {}

  Object.keys(scope).forEach((globalVariableName) => {
    if (code.indexOf(globalVariableName) > -1) {
      reducedScope[globalVariableName] = scope[globalVariableName]
    }
  })

  return reducedScope
}

export function safeEval(
  code: string,
  scope: { [key: string]: any },
  evalType: StandardEvaluationType,
  options: EvalOptions,
) {
  const reducedScope = getFilteredScopeByFindingTopLevelVariableNameInCode(scope, code)
  const reducedCommonScope = getFilteredScopeByFindingTopLevelVariableNameInCode(options.commonScope, code)

  return new Promise((resolve, reject) => {
    const requestId = requestCount++
    requests[requestId] = { resolve, reject }

    enqueueRequest(
      {
        code,
        requestId,
        scope: reducedScope,
        evalType,
        options,
        batch: options.batch,
        commonScope: getNamespaceAwareModel(reducedCommonScope, options.namespace),
        htmlEscapeRetoolExpressions: options.htmlEscapeRetoolExpressions,
        dataSourceId: options.dataSourceId,
        experimentalEvaluatorCaching: getExperimentalEvaluatorCaching(),
      } as any,
      iframeInitialized,
      requestQueue,
    )
  })
}

// Do not pass the full scope, or any large object to this function that can
// be referenced inside `execFunc`. Since `execFunc` is stored forever, that object
// will never be GC'd.
// You can see we pass functions that let you get the current scope
export function safeEvalWithCallbacks(
  code: string,
  getScope: () => {},
  getJsApi: () => {},
  repl: Repl,
  dataSourceId?: string,
) {
  return new Promise((resolve, reject) => {
    const requestId = requestCount++

    const execFunc = async (
      functionName: string[],
      args: any,
      resolveId: string,
      rejectId: string,
      callbackInitiatorRequestId: number,
    ) => {
      const convertedArgs = args.map((arg: any) => {
        if (typeof arg === 'object') {
          if (arg == null) {
            return arg
          }
          let mapper: any
          if (_.isArray(arg)) {
            mapper = _.map
          } else {
            mapper = _.mapValues
          }
          return mapper(arg, (field: any) => {
            if (field && field.type && field.type === RETOOL_JS_CALLBACK_PLACEHOLDER) {
              return function (data: any) {
                messageIframe(
                  iframe,
                  {
                    type: 'request',
                    data: {
                      evalType: 'callback',
                      requestId,
                      data,
                      callbackId: field.callbackId,
                    },
                  },
                  '*',
                )
              }
            } else {
              return field
            }
          })
        } else {
          return arg
        }
      })

      let jsApiScope = getJsApi() as any

      for (const key of functionName) {
        // Match against Object.keys() to prevent exploring the prototype chain
        const possibleIds = Object.keys(jsApiScope)
        const nextKey = _.find(possibleIds, (id) => id === key)
        const nextScope = nextKey && jsApiScope[nextKey]
        if (!nextScope) throw Error(`Could not find ${functionName.join('.')}`)
        jsApiScope = nextScope
      }
      const method = jsApiScope

      try {
        const data = await method.apply(null, convertedArgs)

        //Pass in the scope after we apply the change so that the sandbox can update the scope of the request
        let updatedScope = getScope()
        updatedScope = getFilteredScopeByFindingTopLevelVariableNameInCode(updatedScope, code)

        messageIframe(
          iframe,
          {
            type: 'request',
            data: {
              evalType: 'callback',
              callbackInitiatorRequestId,
              scope: updatedScope,
              requestId,
              data,
              callbackId: resolveId,
            },
          },
          '*',
        )
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
        messageIframe(
          iframe,
          {
            type: 'request',
            data: {
              evalType: 'callback',
              requestId,
              data: {},
              callbackId: rejectId,
            },
          },
          '*',
        )
      }
    }
    requests[requestId] = { resolve, reject, execFunc }

    const fullScope = getScope()
    const jsApiWithFunctionPlaceHolder = replaceFunctionsWithJSApiPlaceholder(getJsApi())
    const updateSetValueDynamically = dataSourceId && (fullScope as any)[dataSourceId]?.updateSetValueDynamically

    const extendedScopeWithJsApi = extendScopeWithJsApi(fullScope, jsApiWithFunctionPlaceHolder)

    enqueueRequest(
      {
        code,
        requestId,
        scope: extendedScopeWithJsApi,
        evalType: repl,
        htmlEscapeRetoolExpressions: false,
        dataSourceId,
        experimentalEvaluatorCaching: getExperimentalEvaluatorCaching(),
        updateSetValueDynamically,
      },
      iframeInitialized,
      requestQueue,
    )
  })
}

export function alasql(query: any, parameters: any) {
  return new Promise((resolve, reject) => {
    const requestId = requestCount++
    requests[requestId] = { resolve, reject }
    enqueueRequest(
      { evalType: 'alasql', requestId, query, parameters, htmlEscapeRetoolExpressions: false },
      iframeInitialized,
      requestQueue,
    )
  })
}

function enqueueRequest(request: SandboxRequest, iframeInitialized: boolean, requestQueue: SandboxRequest[]): void {
  //The iframe will never be initalized in tests but we need to use the request queue
  if (iframeInitialized || __TEST__) {
    batchedRequestSender.queueRequest(request)
  } else {
    requestQueue.push(request)
  }
}

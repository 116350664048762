import React, { useEffect, useState } from 'react'
import { OpenAPIV3 } from 'openapi-types'
import set from 'lodash/set'
import mapValues from 'lodash/mapValues'

import { InputField, API_AUTH_METHODS, AuthenticationSelection, KeyValueField } from './common'
import { ResourceFormProps } from './types'
import { fetchOpenAPISpec, OpenAPISpec } from 'store/fetchOpenAPISpec'
import { Resource } from 'common/records'

type ServerVariablesKeyValueFieldPropsType = {
  spec?: OpenAPISpec
  updateResourceOptions: (resource: Resource) => void
  resource: any
}
export const ServerVariablesKeyValueField = (props: ServerVariablesKeyValueFieldPropsType) => {
  const serverVariables = (props.spec as OpenAPIV3.Document)?.servers?.[0]?.variables || {}
  const serverVariableKeys = Object.keys(serverVariables)

  const valueFieldPlaceholderForKey = mapValues(serverVariables, 'default') as {
    [key: string]: string
  }
  if (!serverVariableKeys.length) {
    return null
  }
  const defaultServerVariables = props.resource.options?.defaultServerVariables
  if (!defaultServerVariables || !defaultServerVariables.length) {
    const updatedOptions = props.resource.options
    set(
      updatedOptions,
      'defaultServerVariables',
      serverVariableKeys.map((key) => [key, valueFieldPlaceholderForKey[key]]),
    )
    props.updateResourceOptions(updatedOptions)
    return null
  }

  const serverVariablePossibleValues = serverVariableKeys.reduce((acc: { [key: string]: string }, key: string) => {
    acc[key] = key
    return acc
  }, {})

  return (
    <KeyValueField
      label="Server variables"
      resourceKey="defaultServerVariables"
      resource={props.resource}
      updateResourceOptions={props.updateResourceOptions}
      possibleValues={serverVariablePossibleValues}
      valueFieldPlaceholderForKey={valueFieldPlaceholderForKey}
    />
  )
}

const OpenAPIForm = (props: ResourceFormProps) => {
  const [spec, setSpec] = useState<OpenAPISpec | undefined>(undefined)

  const resourceType = props.resource.type
  const specURL = props.resource.options?.spec

  useEffect(() => {
    ;(async () => {
      const { spec: newSpec } = await fetchOpenAPISpec(specURL, resourceType)
      if (newSpec != null) {
        setSpec(newSpec)
      }
    })()
  }, [resourceType, specURL])

  return (
    <div className="grid-1c mb20">
      <InputField label="Specification URL" resourceKey="spec" {...props} />
      <AuthenticationSelection authMethods={['basic', 'oauth2', 'apiKey']} {...props} />
      <KeyValueField
        label="Custom Headers"
        resourceKey="customHeaders"
        resource={props.resource}
        updateResourceOptions={props.updateResourceOptions}
      />
      <KeyValueField
        label="Custom Query Parameters"
        resourceKey="customQueryParameters"
        resource={props.resource}
        updateResourceOptions={props.updateResourceOptions}
      />
      <ServerVariablesKeyValueField
        spec={spec}
        updateResourceOptions={props.updateResourceOptions}
        resource={props.resource}
      />
    </div>
  )
}

export default {
  label: 'OpenAPI Service',
  form: OpenAPIForm,
  defaults: {
    options: {
      spec: '',
      authentication: '',
      ...API_AUTH_METHODS.basic.defaults,
      ...API_AUTH_METHODS.oauth2.defaults,
      ...API_AUTH_METHODS.apiKey.defaults,
      customHeaders: [['', '']],
      customQueryParameters: [['', '']],
      defaultServerVariables: [['', '']],
    },
  },
  validator: (resource: any) => resource.options.spec,
}

import Icon from 'components/design-system/Icon'
import React, { useState } from 'react'

import { Menu } from 'antd'
import { Dropdown, MenuItem } from 'components/design-system/Dropdown'
import { RETOOL_VERSION } from 'retoolConstants'
import { RetoolState } from 'store'
import { showOnboarding } from 'store/onboarding'
import { isOnPremSelector } from 'store/selectors'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { connect } from 'react-redux'
import './HelpBubble.scss'
import ShortcutDetailsModal from 'components/ShortcutDetails'
import useBooleanState from 'common/hooks/useBooleanState'

export const HelpBubble = () => {
  const [menuIsVisible, setMenuVisibility] = useState(false)
  const handleVisibilityChange = (isVisible: boolean) => {
    setMenuVisibility(isVisible)

    if (isVisible) {
      retoolAnalyticsTrack('Help menu opened')
    }
  }
  const hideMenu = () => setMenuVisibility(false)

  return (
    <Dropdown
      placement="topLeft"
      overlay={<HelpBubbleMenu hideMenu={hideMenu} />}
      onVisibleChange={handleVisibilityChange}
      visible={menuIsVisible}
      trigger={['click']}
    >
      <div className="retool-help-button">
        <Icon type="help" />
      </div>
    </Dropdown>
  )
}

type HelpBubbleMenuProps = {
  showEditorTutorialButton: boolean
  showReleaseNotesForVersion: boolean
  showOnboarding: () => void
  hideMenu: () => void
}

const _HelpBubbleMenu = (props: HelpBubbleMenuProps) => {
  const { showEditorTutorialButton, showReleaseNotesForVersion, hideMenu } = props
  const {
    value: shortcutDetailsVisible,
    setTrue: showShortcutDetails,
    setFalse: hideShortcutDetails,
  } = useBooleanState(false)

  const version = RETOOL_VERSION.replace(/\./g, '')
  const whatsNewLink = showReleaseNotesForVersion
    ? `https://docs.retool.com/docs/retool-v${version}-release-notes`
    : 'https://updates.retool.com/'

  return (
    <Menu className="retool-help-dropdown retool-dropdown-menu" selectable={false}>
      <MenuItem name="documentation">
        <a
          href="https://docs.retool.com/docs"
          target="_blank"
          rel="noopener"
          onClick={() => {
            retoolAnalyticsTrack('Help menu link clicked', { link: 'https://docs.retool.com/docs' })
          }}
        >
          <Icon type="help-docs" className="retool-help-icon" />
          View documentation
        </a>
      </MenuItem>
      <MenuItem name="community">
        <a
          href="https://community.retool.com/"
          target="_blank"
          rel="noopener"
          onClick={() => {
            retoolAnalyticsTrack('Help menu link clicked', { link: 'https://community.retool.com/' })
          }}
        >
          <Icon type="help-community" className="retool-help-icon" />
          Ask the community
        </a>
      </MenuItem>
      <MenuItem name="contact support">
        <a
          id="contact-retool-support"
          onClick={() => {
            retoolAnalyticsTrack('Help menu Intercom clicked')
          }}
        >
          <Icon type="help-support" className="retool-help-icon" />
          Chat with support
        </a>
      </MenuItem>
      <hr />
      <MenuItem name="changelog">
        <a
          href={whatsNewLink}
          target="_blank"
          rel="noopener"
          onClick={() => {
            retoolAnalyticsTrack('Help menu link clicked', { link: whatsNewLink })
          }}
        >
          What's new?
        </a>
      </MenuItem>
      <MenuItem name="components reference">
        <a
          href="https://retool.com/components"
          target="_blank"
          rel="noopener"
          onClick={() => {
            retoolAnalyticsTrack('Help menu link clicked', { link: 'https://retool.com/components' })
          }}
        >
          Component reference
        </a>
      </MenuItem>
      <MenuItem
        name="keyboard shortcuts"
        onClick={() => {
          showShortcutDetails()
          hideMenu()
        }}
      >
        Show keyboard shortcuts
      </MenuItem>

      {shortcutDetailsVisible && <ShortcutDetailsModal onClose={hideShortcutDetails} />}
      {showEditorTutorialButton && (
        <MenuItem
          name="take tutorial"
          onClick={() => {
            retoolAnalyticsTrack('Help menu tutorial clicked')
            props.showOnboarding()
          }}
        >
          Take editor tutorial
        </MenuItem>
      )}
      <hr />
      <MenuItem className="current-version" disabled>
        <div className="light-gray fw-400">Retool version {RETOOL_VERSION}</div>
      </MenuItem>
    </Menu>
  )
}

const mapStateToProps = (state: RetoolState) => {
  const isOnPrem = isOnPremSelector(state)
  return {
    showEditorTutorialButton: (MAIN_DOMAIN != null && !isOnPrem) || __DEV__,
    showReleaseNotesForVersion: isOnPrem,
  }
}

const mapDispatchToProps = {
  showOnboarding,
}

const HelpBubbleMenu = connect(mapStateToProps, mapDispatchToProps)(_HelpBubbleMenu)

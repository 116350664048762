import React from 'react'
import { InputField } from '../common'
import { ResourceFormProps } from '../types'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'

const CloseioForm = (props: ResourceFormProps) => (
  <div className="grid-1c mb20">
    <div className="grid-offset-1">
      Enter your Close.io API key below. You can create one via the API of{' '}
      <a href="https://app.close.com/settings/" target="_blank" rel="noopener">
        your Close.io settings page.
      </a>
    </div>
    <InputField label="API key" resourceKey="basic_username" required {...props} />
  </div>
)

export default {
  label: 'Close.io',
  form: CloseioForm,
  defaults: {
    options: {
      customQueryParameters: [['', '']],
      customHeaders: [['', '']],
      authentication: 'basic',
      basic_username: '',
      basic_password: '',
      spec: `${RETOOL_SPECS_DOMAIN}/close-io.json`,
    },
  },
  validator: ({ options }: any) => options.basic_username,
}

import React from 'react'
import { connect } from 'react-redux'
import * as ReactDOM from 'react-dom'
import { Popover } from 'antd'

import { OnboardingSubStep } from 'common/records'
import { appTemplateSelector, appModelSelector, editorSelector } from 'store/selectors'
import { PopoverProps } from 'antd/lib/popover'
import { RetoolState } from 'store'

import './OnboardingPulse.scss'

type HintNodeSelector = OnboardingSubStep['hintNodeSelector']
type OnboardingPulseProps = {
  selector: HintNodeSelector
  style?: React.CSSProperties
  popoverContent?: React.ReactNode | string
  popoverProps?: PopoverProps
  renderPopoverInSelectorNode?: boolean
}

function isStringCSSSelector(selector: HintNodeSelector): selector is string {
  if (typeof selector === 'string') {
    return true
  }
  return false
}

const mapStateToProps = (state: RetoolState) => {
  return {
    appTemplate: appTemplateSelector(state),
    appModel: appModelSelector(state),
    editor: editorSelector(state),
  }
}

type OnboardingPulseAllProps = OnboardingPulseProps & ReturnType<typeof mapStateToProps>

type OnboardingPulseState = {
  node: Element | null
  cssSelector: HintNodeSelector
}

class OnboardingPulse extends React.Component<OnboardingPulseAllProps, OnboardingPulseState> {
  constructor(props: OnboardingPulseAllProps) {
    super(props)
    this.state = {
      cssSelector: this.props.selector,
      node: null,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: OnboardingPulseAllProps) {
    const { appTemplate, appModel, editor } = nextProps
    let cssSelector: string
    if (isStringCSSSelector(nextProps.selector)) {
      cssSelector = nextProps.selector
    } else {
      cssSelector = nextProps.selector(appTemplate, appModel, editor)
    }
    if (cssSelector !== this.state.cssSelector) {
      this.setState({ cssSelector })
      this.findAndSaveNode()
    }
  }

  componentDidMount() {
    this.findAndSaveNode()
  }

  findAndSaveNode = () => {
    if (!this.state.cssSelector || typeof this.state.cssSelector !== 'string') {
      this.setState({ node: null })
    } else {
      const node = document.querySelector(this.state.cssSelector)
      if (!node) {
        if (this.state.node) {
          this.setState({ node: null })
        }
        return setTimeout(() => {
          this.findAndSaveNode()
        }, 300)
      } else {
        if (node !== this.state.node) {
          this.setState({ node })
        }
      }
    }
    setTimeout(() => {
      this.findAndSaveNode()
    }, 1000)
  }

  componentDidUpdate(prevProps: OnboardingPulseAllProps, prevState: OnboardingPulseState) {
    if (prevState.cssSelector !== this.state.cssSelector) {
      this.findAndSaveNode()
    }
  }

  componentWillUnmount() {
    // Try and find it again
    this.findAndSaveNode()
  }

  render() {
    if (this.state.node) {
      const pulse = <span className="pulse" style={this.props.style || {}} />
      if (this.props.popoverContent) {
        return ReactDOM.createPortal(
          <Popover
            getPopupContainer={() =>
              this.props.renderPopoverInSelectorNode ? (this.state.node as HTMLElement) : document.body
            }
            overlayStyle={{
              // rando magical width...
              width: 350,
              // needs to be <1000 to be behind modal (antd's default is 1030)
              zIndex: 900,
            }}
            visible
            content={this.props.popoverContent}
            placement="right"
            {...this.props.popoverProps}
          >
            {pulse}
          </Popover>,
          this.state.node,
        )
      }

      return ReactDOM.createPortal(pulse, this.state.node)
    } else {
      return null
    }
  }
}

export default connect(mapStateToProps)(OnboardingPulse)

import React from 'react'

import { InputField } from './common'
import { ResourceFormProps } from './types'

class ShellScriptResourceForm extends React.Component<ResourceFormProps> {
  render() {
    return (
      <div>
        <div className="grid-2c mb12">
          <InputField label="Host" placeholder="55.55.55.55" resourceKey="shellHost" {...this.props} />
          <InputField label="Port" placeholder="22" resourceKey="shellPort" {...this.props} />
        </div>
        <div className="mb12 grid-1c">
          <div />
          <div className="pa8 ph12 bg-near-white mb12 lh-16 br4">
            <div className="dark-gray fw-500 mb4">SSH public key authentication</div>
            <a href="/publicKeys/retool.pub" target="_blank">
              Download Retool's public key here
            </a>{' '}
            and add it to your <code>~/.ssh/authorized_keys</code> You'll need to create a user named{' '}
            <code>retool</code>. Click{' '}
            <a target="_blank" href="https://docs.retool.com/docs/enabling-ssh-tunnels" rel="noopener noreferrer">
              here
            </a>{' '}
            for docs on how to use this feature.
          </div>
        </div>
      </div>
    )
  }
}

export default {
  label: 'Shell Script',
  form: ShellScriptResourceForm,
  defaults: { options: {} },
  validator: (resource: any) => {
    return resource.options.shellHost && resource.options.shellPort
  },
}

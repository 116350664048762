import React from 'react'

import { connect } from 'react-redux'

import { Link } from 'react-router'

import { ReactHook as Hook } from 'common/types'

import { FolderWithChildren } from 'store/selectors'
import { favoriteFolder, unfavoriteFolder } from 'store/user'
import { RetoolState } from 'store'

import { Matches, annotate } from 'components/FuzzyFind'
import { Icon } from 'components/design-system'

import UserFolderControls from '../controls/UserFolderControls'
import { pageCount, fuzzyHighlight } from '../utilities/text'
import Favorite from '../controls/Favorite'
import { folderFavoritesSelector } from 'store/selectors/userSelectors'

const mapDispatchToProps = {
  favoriteFolder,
  unfavoriteFolder,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    favoriteFolderIds: folderFavoritesSelector(state),
  }
}

export type FolderItemProps = {
  favoriteFolder: (folderId: number) => Promise<void>
  unfavoriteFolder: (folderId: number) => Promise<void>
  selectedPageIdsHook: [number[], (ids: number[]) => void]
  favoriteFolderIds: number[]
  folder: FolderWithChildren
  matches: Matches | undefined
  folderToRenameHook: Hook<FolderWithChildren | null>
  isEditable: boolean
}

export const FolderItem = (props: FolderItemProps) => {
  const {
    favoriteFolder,
    unfavoriteFolder,
    matches,
    selectedPageIdsHook,
    favoriteFolderIds,
    folder,
    folderToRenameHook,
    isEditable,
  } = props

  const [selectedPageIds] = selectedPageIdsHook
  const selectionMode = selectedPageIds.length > 0

  const isFavorited = favoriteFolderIds.includes(folder.id)

  let linkProps: any = { to: `/folders/${encodeURIComponent(folder.name)}` }

  if (selectionMode) {
    linkProps = {
      onClick: (e: MouseEvent) => {
        // NB: when in select mode, clicking on a folder should not do anything
        // maybe theres a way to make this more obvious.
        e.preventDefault()
      },
    }
  }

  const count = pageCount(folder.pages.length)

  const favorite = () => favoriteFolder(folder.id)
  const unfavorite = () => unfavoriteFolder(folder.id)

  const nameMatches = matches ? matches.name : undefined

  return (
    <div key={folder.id} className="application-listing-item flex justify-center items-center bb-faint-gray h-100 pl20">
      <div className="listing-item-inner flex items-center justify-between w-100 h-100">
        <div className="pa12">
          <Icon type="folder" className="v-mid mh8" height={18} width={20} />
        </div>
        <div className="flex-grow h-100">
          <Link {...linkProps} className="fw-500 dark-gray fs-13 flex fd-col h-100 justify-center">
            <div className="listing-item-title truncate">
              {annotate(folder.name, nameMatches, fuzzyHighlight)}
              {count}&nbsp;&nbsp;
              <Favorite
                isFavorited={isFavorited}
                disabled={selectionMode}
                favorite={favorite}
                unfavorite={unfavorite}
              />
            </div>
          </Link>
        </div>
        {isEditable && <UserFolderControls folder={folder} folderToRenameHook={folderToRenameHook} />}
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderItem)

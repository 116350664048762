import React from 'react'
import { connect } from 'react-redux'

import { Menu } from 'antd'

import { Button, Icon } from 'components/design-system'
import { Dropdown, MenuLink } from 'components/design-system/Dropdown'

import { showConfirm } from 'components/standards/Modal'

import { unused, ReactHook as Hook } from 'common/types'

import { RetoolState } from 'store'

import { FolderWithChildren, folderTreeSelector } from 'store/selectors'
import { bulkMovePages, deleteFolder } from 'store/appModel/pages'

const mapDispatchToProps = {
  bulkMovePages,
  deleteFolder,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    rootFolder: folderTreeSelector(state),
  }
}

type UserFolderControlsProps = {
  bulkMovePages: (pageIds: number[], folderId: number) => Promise<any>
  deleteFolder: (folderId: number) => Promise<any>
  rootFolder: FolderWithChildren | null
  folder: FolderWithChildren
  folderToRenameHook: Hook<FolderWithChildren | null>
}

const UserFolderControls = (props: UserFolderControlsProps) => {
  const { bulkMovePages, folder, deleteFolder, folderToRenameHook, rootFolder } = props
  const [, setFolderToRename] = folderToRenameHook
  const triggerRename = (_: unused) => setFolderToRename(folder)
  const childPageIds = folder.pages.map((p) => p.id)

  const numPages = folder.pages.length
  const folderContainsProtectedPages = folder.pages.some((page) => page.protected)

  const confirmDeletion = async () => {
    let applicationDescriptor = `all ${numPages} contained applications`
    if (numPages === 1) {
      applicationDescriptor = `the 1 contained application`
    }

    showConfirm({
      title: `Are you sure you want to delete '${folder.name}'?`,
      okText: 'Move and delete',
      autoFocusButton: 'ok',
      okButtonProps: {
        className: 'btn-danger',
      },
      content: (
        <>
          Deleting the folder will move {applicationDescriptor} into <strong>All apps</strong>. You can also move the
          applications yourself by canceling.
        </>
      ),
      onOk: async (close: () => void) => {
        await bulkMovePages(childPageIds, rootFolder!.id)
        await deleteFolder(folder.id)
        close()
      },
    })
  }

  const triggerDelete = async (_: unused) => {
    if (numPages === 0) {
      await deleteFolder(folder.id)
    } else {
      await confirmDeletion()
    }
  }

  const menu = (
    <Menu className="ant-dropdown-menu" style={{ width: 160 }}>
      <MenuLink onSelected={triggerRename} label="Rename folder" disabled={folderContainsProtectedPages} />
      <MenuLink className="red fw-600" onSelected={triggerDelete} label="Delete" />
    </Menu>
  )

  return (
    <>
      <Dropdown placement="bottomRight" trigger={['click']} overlay={menu}>
        <Button type="ghost" style={{ padding: '2px 4px' }} className="ml8important" aria-label="More information">
          <Icon type="more-info-h" />
        </Button>
      </Dropdown>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserFolderControls)

import * as React from 'react'
import { connect } from 'react-redux'
import { onBillingSelector, onOnboardingTutorialSelector, onSetupSelector } from 'store/selectors'
import { Icon } from 'antd'
import classnames from 'classnames'
import ls from 'local-storage'
import { Button } from 'components/design-system'

import './ExpiryHeaderWarning.scss'
import { RetoolState } from 'store'
import {
  licenseOrTrialExpiredSelector,
  licenseMessageSelector,
  onLocalTrialSelector,
  onTrialSelector,
  onPremSelfServiceSelector,
} from 'store/selectors/billingSelectors'
import { browserHistory } from 'react-router'

const mapStateToProps = (state: RetoolState) => {
  return {
    licenseMessage: licenseMessageSelector(state),
    trialExpired: licenseOrTrialExpiredSelector(state),
    inTrial: onTrialSelector(state),
    inLocalTrial: onLocalTrialSelector(state),
    onBillingPage: onBillingSelector(state),
    onSetup: onSetupSelector(state),
    isOnboarding: onOnboardingTutorialSelector(state),
    isSelfServiceOnPrem: onPremSelfServiceSelector(state),
  }
}

type ExpiryHeaderWarningProps = ReturnType<typeof mapStateToProps> & {
  children: any
  isDismissed: boolean
  onDismiss: () => void
}

const ONE_DAY = 1000 * 60 * 60 * 24

class ExpiryBannerDismiss extends React.Component<{ children: (any: any) => React.ReactNode }> {
  state = {
    lastDismissTime: ls.get('expiryBannerLastDismissTime'),
  }

  onDismiss = () => {
    const lastDismissTime = new Date().getTime()
    ls.set('expiryBannerLastDismissTime', lastDismissTime)
    this.setState({ lastDismissTime })
  }

  render() {
    return this.props.children({
      isDismissed:
        this.state.lastDismissTime && new Date().getTime() - parseInt(this.state.lastDismissTime, 10) < ONE_DAY,
      onDismiss: this.onDismiss,
    })
  }
}

const moveToBilling = () => {
  browserHistory.push('/settings/billing')
}

const Header = (props: ExpiryHeaderWarningProps) => {
  const showExpiryHeader =
    !!props.licenseMessage && !props.onSetup && !props.isOnboarding && !props.isDismissed && !props.onBillingPage

  const upgradeCTAText = props.trialExpired ? 'Choose a plan to continue' : 'Upgrade now'

  return (
    <div style={{ height: '100%' }} className={`${showExpiryHeader ? 'show-expiry-header' : 'no-expiry-header'}`}>
      {showExpiryHeader && (
        <div
          style={{ position: 'relative' }}
          className={classnames('expiry-warning-header', {
            'expiry-warning-header--trial-ends-very-soon': props.trialExpired,
          })}
        >
          {props.licenseMessage}
          <Icon
            style={{
              position: 'absolute',
              right: 20,
              cursor: 'pointer',
            }}
            onClick={props.onDismiss}
            type="close"
          />
          {(MAIN_DOMAIN || __DEV__ || props.isSelfServiceOnPrem) && props.inTrial && !props.inLocalTrial && (
            <Button type="link" className="ml12" href="/settings/billing">
              {upgradeCTAText}
            </Button>
          )}
          {props.inLocalTrial && (
            <Button type="link" className="ml12" onClick={moveToBilling}>
              Select a plan
            </Button>
          )}
        </div>
      )}
      <div style={{ height: showExpiryHeader ? 'calc(100% - 40px)' : '100%' }}>{props.children}</div>
    </div>
  )
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)((props: ReduxProps) => (
  <ExpiryBannerDismiss>{(dismissProps) => <Header {...props} {...dismissProps} />}</ExpiryBannerDismiss>
))

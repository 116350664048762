import { callExternalApi } from 'networking'
import { PreconfiguredOpenAPIResourceType } from 'common/resourceTypes'
import { OpenAPIV2, OpenAPIV3 } from 'openapi-types'
import { SafeAny } from 'common/types'
import SimpleCache from 'store/appModel/SimpleCache'
import { PRECONFIGURED_OPENAPI_SPECS } from '../__globalShared__/common'
import { includes } from 'types/typeguards'

export type OpenAPISpec = OpenAPIV2.Document | OpenAPIV3.Document

function localDevSpecOverride(specUrl: string) {
  return specUrl.replace('swagger-spec-server', 'localhost')
}

const specCache = new SimpleCache<string, SafeAny>(1000 * 600)

async function resolveSwaggerSpec(specURL: string, resolve: (val: OpenAPISpecResponse) => void) {
  const Swagger = (await import(/* webpackChunkName: "SwaggerClient" */ 'swagger-client')).default
  Swagger.resolve({ url: specURL })
    .then((resolved: { errors: string[]; spec: OpenAPISpec }) => {
      if (resolved.errors.length > 0) {
        return resolve({ updating: false, spec: null, specErrorMessages: resolved.errors })
      } else {
        specCache.put(specURL, resolved.spec)
        return resolve({ updating: false, spec: resolved.spec, specErrorMessages: null })
      }
      return
    })
    .catch((err: Error) => {
      // eslint-disable-next-line no-console
      console.log(`Unable to resolve ${specURL}`, err)
      throw err
    })
}

type OpenAPISpecResponse = {
  updating: boolean
  spec: OpenAPISpec | null
  specErrorMessages: string[] | null
}

export const fetchOpenAPISpec = async (
  specURL: string,
  resourceType: PreconfiguredOpenAPIResourceType,
): Promise<OpenAPISpecResponse> => {
  return new Promise((resolve) => {
    const cachedSpec = specCache.get(specURL)
    if (cachedSpec) {
      return resolve({ updating: false, spec: cachedSpec, specErrorMessages: null })
    } else {
      // for native Retool OpenAPI integrations
      // instead of creating a Swagger instance (which can take up to 10 mins for large specs),
      // we simply pre-fetch the OpenAPI spec
      if (includes(PRECONFIGURED_OPENAPI_SPECS, resourceType)) {
        callExternalApi({
          url: __DEV__ ? localDevSpecOverride(specURL) : specURL,
          method: 'GET',
        })
          .then((resolved: OpenAPISpec) => {
            specCache.put(specURL, resolved)
            return resolve({ updating: false, spec: resolved, specErrorMessages: null })
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(`Unable to GET ${specURL}`, err)
            throw err
          })
      } else {
        // for non-native specs we make sure spec is valid by resolving it with Swagger.
        resolveSwaggerSpec(specURL, resolve)
      }
    }
  })
}

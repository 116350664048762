import React from 'react'
import classNames from 'classnames'
import { Tabs as AntTabs } from 'antd'
import { TabsProps as AntTabsProps, TabPaneProps as AntTabPaneProps } from 'antd/lib/tabs'

import 'antd/lib/tabs/style/index'
import './Tabs.scss'

type TabPaneProps = AntTabPaneProps & {
  children?: React.ReactNode
}

const TabPane = (props: TabPaneProps) => {
  return <AntTabs.TabPane {...props}>{props.children}</AntTabs.TabPane>
}

export type TabsProps = AntTabsProps & {
  children?: React.ReactNode
}

const Tabs = (props: TabsProps) => {
  return (
    <AntTabs animated={props.animated ?? false} {...props} className={classNames('retool-tabs', props.className)}>
      {props.children}
    </AntTabs>
  )
}

Tabs.TabPane = TabPane

export default Tabs

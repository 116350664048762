import React, { useState, useEffect } from 'react'
const Option = Select.Option

import './PickAppFromDataModalBody.scss'

import * as _ from 'lodash'
import SideIcon from 'assets/generate_an_app_from_data_icon.svg'

import { Resource, AutogeneratePageParams } from 'common/records'
import { ResourceType } from 'common/resourceTypes'
import { resourceIcons } from 'retoolConstants'
import { callInternalApi } from 'networking'
import { isOnboardingResource } from 'components/plugins/datasources/common/utils'
import { editorResourcesSelector } from 'store/selectors'
import { RetoolState } from 'store'
import { connect } from 'react-redux'
import Select from 'components/design-system/Select'
import { Icon, Button } from 'components/design-system'

type PickAppFromDataModalBodyProps = {
  onNext: () => void
  editorResources: Resource[]
  setSelectedResourceData: (data: AutogeneratePageParams) => void
}

function resourceToOption(resource: Resource) {
  return (
    <Option value={resource.value} key={resource.value} className="large-option">
      {resourceIcons[resource.resourceType] && (
        <div className="pick-app-from-data-modal__resource-dropdown-image-container">
          <img
            className="pick-app-from-data-modal__resource-dropdown-image"
            src={resourceIcons[resource.resourceType]}
          />
        </div>
      )}
      <span className="pick-app-from-data-modal__dropdown-label">{resource.label}</span>
    </Option>
  )
}

function tableNameToOption(tableName: string) {
  return (
    <Option value={tableName} key={tableName} className="large-option">
      <span className="pick-app-from-data-modal__dropdown-label">{tableName}</span>
    </Option>
  )
}

function columnNameToOption(columnName: string) {
  return (
    <Option value={columnName} key={columnName} className="large-option">
      <span className="pick-app-from-data-modal__dropdown-label">{columnName}</span>
    </Option>
  )
}

function isValidState(
  selectedResourceName: string | undefined,
  selectedTableName: string | undefined,
  selectedColumnName: string | undefined,
) {
  const isSelectedResourceNameValid = selectedResourceName !== undefined && selectedResourceName !== null
  const isSelectedTableNameValid = selectedTableName !== undefined && selectedTableName !== null
  const isSelectedColumnNameValid = selectedColumnName !== undefined && selectedColumnName !== null
  return isSelectedResourceNameValid && isSelectedTableNameValid && isSelectedColumnNameValid
}

function PickAppFromDataModalBody(props: PickAppFromDataModalBodyProps) {
  const [selectedResourceName, setSelectedResourceName] = useState<string | undefined>()
  const [selectedResourceType, setSelectedResourceType] = useState<ResourceType | undefined>()
  const [selectedTableName, setSelectedTableName] = useState<string | undefined>()
  const [selectedColumnName, setSelectedColumnName] = useState<string | undefined>()
  const [schema, setSchema] = useState<{ [index: string]: Object }>({})

  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState<boolean>(false)

  const onNextClick = () => {
    props.setSelectedResourceData({
      resourceName: selectedResourceName!,
      resourceType: selectedResourceType!,
      tableName: selectedTableName!,
      columnName: selectedColumnName!,
    })
    props.onNext()
  }

  useEffect(() => {
    setIsNextButtonEnabled(isValidState(selectedResourceName, selectedTableName, selectedColumnName))
  }, [selectedResourceName, selectedTableName, selectedColumnName])

  const onDatasourceSelectedChange = async (value: string) => {
    try {
      const resource = props.editorResources.filter((r: Resource) => r.name === value)[0]
      const response = await callInternalApi({
        url: `/api/resources/${resource.name}/schema?environment=production`,
        method: 'GET',
      })

      setSelectedResourceType(resource.resourceType as ResourceType)
      setSchema(response.schema)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
    setSelectedResourceName(value)
  }

  return (
    <div className="flex ph36 pv24">
      <div className="mt4">
        <img src={SideIcon} />
      </div>
      <div className="ml36">
        <div className="">
          <div className="fs-13 lh-20 light-gray">
            This app will consist of a table and a search bar. Currently only SQL-type data sources are supported.
          </div>
          <div className="mt16">
            <div className="label-title">
              Pick a datasource <span className="light-red">*</span>
            </div>
            <Select
              value={selectedResourceName}
              onChange={(v) => onDatasourceSelectedChange(v as string)}
              placeholder="Select a datasource"
            >
              {props.editorResources
                .filter((r) => r.resourceType === 'postgresql' || r.resourceType === 'mysql')
                .filter((r) => r.production)
                .filter((r) => !r.production.editPrivilege) // for now, we filter out write resources
                .filter((r) => !isOnboardingResource(r))
                .map(resourceToOption)}
            </Select>

            <div>
              <div className="label-title">
                Pick a table <span className="light-red">*</span>
              </div>
              <Select
                value={selectedTableName}
                disabled={selectedResourceName === undefined}
                onChange={(v) => setSelectedTableName(v as string)}
                placeholder="Select a table"
              >
                {Object.keys(schema).map(tableNameToOption)}
              </Select>

              <div className="label-title">
                Search by column name <span className="light-red">*</span>
              </div>
              <Select
                value={selectedColumnName}
                onChange={(v) => setSelectedColumnName(v as string)}
                placeholder="Select a column"
                disabled={selectedResourceName === undefined}
              >
                {selectedTableName &&
                  !_.isEmpty(schema[selectedTableName]) &&
                  Object.keys(schema[selectedTableName]).map(columnNameToOption)}
              </Select>
            </div>
          </div>
          <div className="flex mt24 justify-end">
            <Button type="primary" onClick={onNextClick} disabled={!isNextButtonEnabled}>
              Next <Icon type="arrow-right" className="ml4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RetoolState) => {
  return {
    editorResources: editorResourcesSelector(state),
  }
}

export default connect(mapStateToProps)(PickAppFromDataModalBody)

import cookies from 'js-cookie'

/* eslint-disable no-restricted-imports */
// Disable the no-restricted-imports for the `isomorphic-fetch` package here (but nowhere
// else) to guarantee it is only used behind its facade function(s)
import fetch from 'isomorphic-fetch'
import { SafeAny } from '../common/types'
import logout from './logout'
/* eslint-enable no-restricted-imports */

type Keys = string | number
export interface CallApiParams {
  url: string
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
  body?: { [k in Keys]: SafeAny }
  /* eslint-enable @typescript-eslint/no-explicit-any */
  formDataBody?: FormData
}

class HttpError extends Error {
  statusCode: number
  payload: object | undefined
  constructor(message: string, statusCode: number, payload: object) {
    super(message)
    this.statusCode = statusCode
    this.payload = payload
  }
}

const callInternalApi = async (params: CallApiParams) => {
  /**
   * A facade around our third-party networking library, currently `isomorphic-fetch`, to be
   * used for internal network requests.
   *
   * Since we insert auth headers into the request automatically, DO NOT use this function
   * to make requests to untrusted servers.
   */
  const { url, method, body, formDataBody } = params
  const isMultiPartFormData = formDataBody !== undefined

  const headers = {
    ...(!isMultiPartFormData && { Accept: 'application/json', 'Content-Type': 'application/json' }),
    'X-Xsrf-Token': cookies.get('xsrfToken') ?? '',
  }
  const credentials = INCLUDE_COOKIES_IN_API_CALLS || 'same-origin'

  let response
  if (method === 'GET' || method === 'DELETE') {
    response = await fetch(url, {
      method,
      headers,
      credentials,
    })
  } else {
    // POST, PUT, and PATCH requests
    response = await fetch(url, {
      method,
      headers,
      credentials,
      body: isMultiPartFormData ? formDataBody : JSON.stringify(body),
    })
  }

  // this section will only throw if the backend is unreachable or returns a malformed response
  let payload
  try {
    payload = await response.json()
  } catch (error) {
    throw new Error(`${response.status} ${response.statusText}`)
  }

  // this section will throw any time the response is unsuccessful (returns status code
  // outside the 2xx range)
  if (response.ok) {
    return payload
  } else {
    if (response.status === 401) {
      logout()
    }
    throw new HttpError(payload.message, response.status, payload)
  }
}

const callExternalApi = async (params: CallApiParams) => {
  /**
   * A facade around our third-party networking library, currently `isomorphic-fetch`, to be
   * used for external / untrusted network requests.
   */
  const { url, method, body } = params

  const headers = {
    Accept: 'application/json',
  }
  const credentials = 'omit'

  let response
  if (method === 'GET' || method === 'DELETE') {
    response = await fetch(url, {
      method,
      headers,
      credentials,
    })
  } else {
    // POST, PUT, and PATCH requests
    response = await fetch(url, {
      method,
      headers,
      credentials,
      body: JSON.stringify(body),
    })
  }

  // this section will only throw if the backend is unreachable or returns a malformed response
  let payload
  try {
    payload = await response.json()
  } catch (error) {
    throw new Error(`${response.status} ${response.statusText}`)
  }

  // this section will throw any time the response is unsuccessful (returns status code
  // outside the 2xx range)
  if (response.ok) {
    return payload
  } else {
    throw new HttpError(payload.message, response.status, payload)
  }
}

export { callInternalApi, callExternalApi }

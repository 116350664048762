import React, { useState, useEffect, ComponentType } from 'react'
import { asyncComponent as reactAsyncComponent, Configuration } from 'react-async-component'
import { Skeleton } from 'antd'
import { AsyncLoader } from 'common/types'

const LoadingComponent = (): JSX.Element => {
  // our tests depend on widgets being visible immediately
  const [show, setShow] = useState(!!__TEST__)

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  if (!show) {
    // the reactAsyncComponent types won't accept null
    return <></>
  }

  return (
    <div className="loading-component">
      <Skeleton title={false} paragraph={{ rows: 9 }} active />
    </div>
  )
}

const ErrorComponent = ({ error }: { error: Error }): JSX.Element => <div>{error.message}</div>

type ConfigWithDefault<P> = Omit<Configuration<P>, 'resolve'> & {
  resolve: AsyncLoader<ComponentType<P>>
}

export default function asyncComponent<P = {}>(config: ConfigWithDefault<P>): ComponentType<P> {
  // reactAsyncComponent handles .default internally, but their types say they don't
  const typedConfig = (config as unknown) as Configuration<P>

  return reactAsyncComponent<P>({
    LoadingComponent,
    ErrorComponent,
    ...typedConfig,
  })
}

import { RetoolDispatch } from 'store/index'
import { ADD_ACTIVE_MOCK_FOR_TEST, MARK_TEST_FINISHED, REMOVE_ALL_ACTIVE_MOCKS_FOR_TEST } from 'store/constants'
import { Mock } from 'common/records'

export const markTestFinished = () => {
  return (dispatch: RetoolDispatch) => {
    dispatch({
      type: MARK_TEST_FINISHED,
    })
  }
}

export const removeAllMocksForTest = (testId: string) => {
  return (dispatch: RetoolDispatch) => {
    dispatch({
      type: REMOVE_ALL_ACTIVE_MOCKS_FOR_TEST,
      payload: {
        testId,
      },
    })
  }
}

export const addMockForTest = (mock: Mock, testId: string) => {
  return (dispatch: RetoolDispatch) => {
    dispatch({
      type: ADD_ACTIVE_MOCK_FOR_TEST,
      payload: {
        mock,
        testId,
      },
    })
  }
}

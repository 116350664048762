import { createSelector } from 'reselect'
import { GLOBAL_WIDGET_TYPE } from 'retoolConstants'
import { createdNamespacedPreloadedJS } from 'store/appModel/namespaceHelpers'
import { pluginsSelector, modulesSelector } from '../selectors'

export const nestedModulesSelector = createSelector(pluginsSelector, (plugins) =>
  plugins.filter((plugin) => plugin?.subtype === GLOBAL_WIDGET_TYPE),
)

export const nestedModuleTemplatesSelector = createSelector(
  modulesSelector,
  nestedModulesSelector,
  (modules, nestedModules) => {
    return nestedModules.mapEntries(([_id, module]) => {
      const uuid = module.template.get('pageUuid')
      const template = modules.templates[uuid]?.appTemplate
      return [uuid, template]
    })
  },
)

export const templateContainsGlobalWidgetsSelector = createSelector(
  nestedModulesSelector,
  (plugins) => plugins.size > 0,
)

export const nestedModuleStylesSelector = createSelector(nestedModuleTemplatesSelector, (templates) =>
  templates.map((v) => v?.get('appStyles')).toJS(),
)

export const nestedModulesPreloadedJSSelector = createSelector(modulesSelector, (modules) => {
  return createdNamespacedPreloadedJS(modules.templates)
})

export const preloadedModulesJSLinksSelector = createSelector(
  nestedModuleTemplatesSelector,
  (templates) => templates.map((v) => v?.get('preloadedAppJSLinks')).toJS() as { [uuid: string]: string[] },
)

import Immutable from 'immutable'
import { Selector } from 'common/utils'
import { RetoolThunk } from 'store'
import { appTemplateSelector } from 'store/selectors'
import { batchedPropagateChanges, getSelectorsFromUpdate, commitModel } from './model'

/**
 * requests a recalculation of the supplied selectors and their dependants. try to use triggerModelUpdate when possible.
 * this function should only be used in user-triggered codepaths like event handlers where we need the latest model values.
 */
const recalculateModelSelectors = (selectors: Selector[]): RetoolThunk => async (dispatch, getState) => {
  const state = getState()
  const { appModel } = state
  const template = appTemplateSelector(state)

  const selectorsToRecalculate = selectors.reduce((acc, modelSelector) => {
    const [pluginId, ...templateSelector] = modelSelector
    const value = template.getPlugin(pluginId).getIn(['template', ...templateSelector])
    return acc.concat(getSelectorsFromUpdate(modelSelector, value).map((selector: Selector) => selector.join('.')))
  }, Immutable.Set<string>())

  const stackId = appModel.executionCallStacks.createStack([]).id

  await dispatch(batchedPropagateChanges(appModel, selectorsToRecalculate, [], stackId))
  dispatch(commitModel())
}

export default recalculateModelSelectors

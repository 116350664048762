import useBooleanState from 'common/hooks/useBooleanState'
import useFetchWithLoading from 'common/hooks/useFetchWithLoading'
import useInterval from 'common/hooks/useInterval'
import { Page } from 'common/records'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { Message } from 'components/design-system'
import { callInternalApi } from 'networking'
import React, { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  organizationProtectedBranch,
  organizationProtectedEnterpriseUrl,
  organizationProtectedOrg,
  organizationProtectedRepo,
} from 'store/selectors'
import { PushBranchWithAppAdditionResponseType, ProtectPageResponseType } from '__globalShared__/pages'
import { RemoteBranchContainsPageResponse } from '../ResetBranchModal/constants'
import PageProtectionModal, { PageType } from './PageProtectionModal'

type PageProtectionModalContainerProps = {
  visible: boolean
  page: Page
  closeModal: () => void
  pageType: PageType
}

const PageProtectionModalContainer: FC<PageProtectionModalContainerProps> = ({
  visible,
  page,
  closeModal,
  pageType,
}) => {
  const pageId = page.uuid
  const [newBranchName, setNewBranchName] = useState<string | undefined>(undefined)
  const initialCommitMerged = useBooleanState(false)
  const userOpenedPullRequest = useBooleanState(false)
  const organizationGitBranch = useSelector(organizationProtectedBranch)
  const organizationGitOrg = useSelector(organizationProtectedOrg)
  const organizationGitRepo = useSelector(organizationProtectedRepo)
  const organizationGitUrl = useSelector(organizationProtectedEnterpriseUrl)
  const dispatch = useDispatch()

  const pullRequestOptions = newBranchName
    ? {
        path: page.path,
        pageName: page.name,
        pageBranchName: newBranchName,
        organizationGitBranch,
        organizationGitOrg,
        organizationGitRepo,
        organizationGitUrl: organizationGitUrl ? organizationGitUrl : undefined,
        initialCommit: true,
      }
    : undefined

  const handlePushPageResponse = (response: PushBranchWithAppAdditionResponseType) => {
    setNewBranchName(response.branchName)
  }

  const [pushPage, pushingPage] = useFetchWithLoading(
    (): Promise<PushBranchWithAppAdditionResponseType> =>
      callInternalApi({
        url: `/api/pages/uuids/${pageId}/pushBranchWithAppAddition`,
        method: 'POST',
      }),
    { onSuccess: handlePushPageResponse, onError: () => Message.error(`Failed pushing page to GitHub.`) },
  )

  const handleProtectPageResponse = (response: ProtectPageResponseType) => {
    retoolAnalyticsTrack(response.page.isGlobalWidget ? 'Module Protected' : 'App Protected', { pageUuid: pageId })
    dispatch({
      type: 'RECEIVE_PAGE_PROTECT',
      payload: {
        page,
      },
    })
    Message.success('Page protected successfully.')
    closeModal()
  }

  const [protectPage, protectingPage] = useFetchWithLoading(
    (): Promise<ProtectPageResponseType> =>
      callInternalApi({
        url: '/api/pages/protectPage',
        method: 'POST',
        body: { pageUuid: pageId },
      }),
    { onSuccess: handleProtectPageResponse, onError: (e) => Message.error(`Failed protecting page. ${e}`) },
  )

  const handleBranchCheckResponse = (response: RemoteBranchContainsPageResponse) => {
    if (response.type === 'APP_FOUND') {
      initialCommitMerged.setTrue()
      protectPage()
    }
  }

  const [checkGithubForMerge] = useFetchWithLoading(
    (remoteBranchName: string): Promise<RemoteBranchContainsPageResponse> =>
      callInternalApi({
        url: `/api/pages/uuids/${pageId}/remoteBranchContainsPage`,
        method: 'POST',
        body: {
          remoteBranchName,
        },
      }),
    {
      onSuccess: handleBranchCheckResponse,
    },
  )

  const loading = pushingPage || protectingPage
  const shouldCheckForMerge = !loading && !!newBranchName && userOpenedPullRequest.value
  const showReadyToPush = !pushingPage && !shouldCheckForMerge && !pullRequestOptions?.pageBranchName
  const showOpenPR =
    !pushingPage && !shouldCheckForMerge && pullRequestOptions?.pageBranchName && !initialCommitMerged.value

  const modalState =
    (showReadyToPush && 'ready') ||
    (pushingPage && 'pushing') ||
    (showOpenPR && 'readyToMerge') ||
    ((shouldCheckForMerge || initialCommitMerged.value) && 'checkingForMerge') ||
    'ready'

  useInterval(() => checkGithubForMerge(organizationGitBranch), shouldCheckForMerge ? 2000 : null)

  return (
    <PageProtectionModal
      visible={visible}
      onPushPage={pushPage}
      onMergePR={userOpenedPullRequest.setTrue}
      closeModal={closeModal}
      pageType={pageType}
      pageName={page.name}
      pullRequestOptions={pullRequestOptions}
      modalState={modalState}
    />
  )
}

export default PageProtectionModalContainer

import _ from 'lodash'

import { unused } from 'common/types'

import { FolderWithChildren, getPages } from 'store/selectors'
import { Page } from 'common/records'
import React from 'react'

export type PageFilter = (pages: Page[]) => Page[]

export type SpecialViewFilter = {
  key: string
  name: string
  selectPages: PageFilter
  foldersToDisplay: FolderWithChildren[]
  tag?: React.ReactElement
}

// documentary type
export type HomeViewFilter = SpecialViewFilter | FolderWithChildren

const SPECIAL_VIEW_FILTERS = ['all', 'recent', 'starred', 'trash', 'modules', 'protected'] as const
type SpecialViewFilterKeys = typeof SPECIAL_VIEW_FILTERS
export type SpecialViewFilterKey = SpecialViewFilterKeys[number]

export type SpecialViewFilterMap = { [k in SpecialViewFilterKey]: SpecialViewFilter | undefined }

export const isSpecialViewFilter = (view: HomeViewFilter): view is SpecialViewFilter =>
  Object.prototype.hasOwnProperty.call(view, 'selectPages')

export const isTrashFilter = (view: HomeViewFilter): view is SpecialViewFilter =>
  isSpecialViewFilter(view) && view.key === 'trash'

export const isUserFolderFilter = (view: HomeViewFilter): view is FolderWithChildren => !isSpecialViewFilter(view)

export const selectPages = (view: HomeViewFilter): PageFilter => {
  if (isSpecialViewFilter(view)) {
    return view.selectPages
  } else {
    return (_: unused) => getPages(view)
  }
}

export const allApplications = (root: FolderWithChildren | null): SpecialViewFilter => {
  return {
    key: 'all',
    name: 'All',
    selectPages: (allPages) => {
      const pages = root ? root.pages : allPages
      return _.sortBy(pages, (p) => p.name.toLowerCase())
    },
    foldersToDisplay: root ? root.children : [],
  }
}

export const recentApplications = (ids: number[]): SpecialViewFilter => {
  return {
    key: 'recent',
    name: 'Recent',
    selectPages: (pages) => {
      return _.compact(ids.map((id) => pages.find((p) => p.id === id)))
    },
    foldersToDisplay: [],
  }
}

export const starredApplications = (
  favoritePageIds: number[],
  favoriteFolders: FolderWithChildren[],
  trashFolder: FolderWithChildren | null,
): SpecialViewFilter => {
  return {
    key: 'starred',
    name: 'Starred',
    selectPages: (pages) => {
      return pages.filter((p) => favoritePageIds.includes(p.id) && p.folderId !== trashFolder?.id)
    },
    foldersToDisplay: favoriteFolders,
  }
}

export const moduleApplications = (trashFolder: FolderWithChildren | null): SpecialViewFilter => {
  return {
    key: 'modules',
    name: 'Modules',
    selectPages: (pages) => {
      return pages.filter((p) => p.isGlobalWidget && p.folderId !== trashFolder?.id)
    },
    foldersToDisplay: [],
    tag: <p className="tag ml4">New</p>,
  }
}

export const protectedApplications = (): SpecialViewFilter => {
  return {
    key: 'protected',
    name: 'Protected',
    selectPages: (pages) => {
      return pages.filter((p) => p.protected)
    },
    foldersToDisplay: [],
  }
}

export const makeFilter = (
  folder: FolderWithChildren,
  overrides: Partial<SpecialViewFilter> = {},
): SpecialViewFilter => {
  const folderPages = getPages(folder)
  return {
    key: `folder-${folder.id.toString()}`,
    name: folder.name,
    selectPages: (_) => folderPages,
    foldersToDisplay: [],
    ...overrides,
  }
}

import Immutable from 'immutable'
import { RetoolState } from 'store'
import { appTemplateSelector } from 'store/selectors'
import { positionKeySelector } from 'store/selectors/widgetSelectors'
import widgetTypeIsContainer from './widgetTypeIsContainer'

export default function getChildWidgetIds(widgetId: string, state: RetoolState): Immutable.List<string> {
  const template = appTemplateSelector(state)
  const widgetType = appTemplateSelector(state).getIn(['plugins', widgetId, 'subtype'])
  const isContainer = widgetTypeIsContainer(widgetType)
  const positionKey = positionKeySelector(state)
  let res = Immutable.List([widgetId])

  // If we're getting a container, get its children too!
  if (isContainer) {
    const childWidgetIds = template
      .get('plugins')
      .filter((plugin) => plugin[positionKey]?.container === widgetId)
      .map((plugin) => plugin.id)
    childWidgetIds.forEach((childId) => {
      // recurse to get our grandchildren's ids too
      if (!childId) {
        return
      }
      res = res.concat(getChildWidgetIds(childId, state))
    })
  }
  return res
}

import { addMigration } from 'components/plugins'
import { EventHandlers } from './types'
import Immutable from 'immutable'
import { makeEventHandler } from './utils'
import makeClickableEventHandler from './makeClickableEventHandler'

const map = {
  ButtonGroupWidget: {
    onChange: 'change',
  },
  CascaderWidget: {
    onSelect: 'change',
  },
  CheckboxWidget: {
    onCheckQuery: 'true',
    onUncheckQuery: 'false',
  },
  ChecboxGroupWidget: {
    onChange: 'change',
  },
  CheckboxTreeWidget: {
    onChange: 'change',
  },
  DateRangePickerWidget: {
    onSelect: 'change',
  },
  DateTimePickerWidget: {
    onSelect: 'change',
  },
  EditableTextWidget: {
    onChange: 'change',
  },
  FilePickerWidget: {
    onFilePick: 'change',
  },
  FormWidget: {
    onSubmit: 'submit',
  },
  JSONSchemaFormWidget: {
    onSubmit: 'submit',
  },
  ModalWidget: {
    onModalOpen: 'open',
    onModalClose: 'close',
  },
  MultiSelectWidget: {
    onSelect: 'change',
  },
  PlotlyChartWidget: {
    onPointSelectQuery: 'select',
  },
  RadioGroupWidget: {
    onChangeQuery: 'change',
  },
  RateWidget: {
    onChange: 'change',
  },
  ReorderableListWidget: {
    onReorder: 'change',
  },
  S3UploaderWidget: {
    onUpload: 'upload',
  },
  ScannerWidget: {
    onScan: 'scan',
  },
  SelectWidget: {
    onSelect: 'change',
  },
  TableWidget: {
    bulkUpdateAction: 'saveChanges',
    onRowAdded: 'saveNew',
    onRowSelect: 'rowSelect',
  },
  TextAnnotationWidget: {
    onTagChange: 'change',
  },
  TextEditorWidget: {
    onBlur: 'blur',
  },
  TextInputWidget: {
    onSubmit: 'submit',
    onBlur: 'blur',
  },
  ToggleWidget: {
    toggleOnQuery: 'true',
    toggleOffQuery: 'false',
  },

  // clickable widgets
  ButtonWidget: {
    onClick: 'click',
  },
  ImageWidget: {
    onClick: 'click',
  },
  AlertWidget: {
    onClick: 'click',
  },
} as const

let added = false

export default function addEventHandlerMigrations(): void {
  if (added) return
  added = true

  Object.entries(map).forEach(([type, eventsMap]) => {
    addMigration(type, {
      fromVersion: '2.68.3',
      toVersion: '2.68.4',
      up: (plugin) => {
        const events: EventHandlers = []

        Object.entries(eventsMap).forEach(([oldKey, newEvent]) => {
          if (newEvent === 'click') {
            const clickEventHandler = makeClickableEventHandler(plugin.get('template'))
            if (!clickEventHandler) return
            events.push(clickEventHandler)
          } else {
            const queryId = plugin.getIn(['template', oldKey])
            if (!queryId) return
            events.push(makeEventHandler(newEvent, queryId))
          }
        })

        if (events.length) {
          return plugin.setIn(['template', 'events'], Immutable.List(events))
        }

        return plugin.removeIn(['template', 'events'])
      },
    })
  })
}

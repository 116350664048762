import React from 'react'
import { Modal } from 'antd'

import { Icon } from 'components/design-system'
import './Modal.scss'

class RetoolSkinnedModal extends React.PureComponent<any, any> {
  constructor(props: any) {
    super(props)
  }

  render() {
    return (
      <Modal
        {...this.props}
        className={`${this.props.className || ''} retool-modal`}
        wrapClassName={this.props.wrapClassName || 'vertical-center-modal'}
        maskStyle={{
          backgroundColor: 'rgba(0,0,0,0.40)',
        }}
      />
    )
  }
}

export default RetoolSkinnedModal

export function showWarning(props: any) {
  return Modal.warning({
    icon: (
      <Icon
        style={{ marginLeft: '-4px', marginBottom: '5px', float: 'left', marginRight: '6px' }}
        type="exclamation-circle"
      />
    ),
    className: `${props.className} retool-modal`,
    wrapClassName: 'vertical-center-modal',
    maskStyle: {
      backgroundColor: 'rgba(0,0,0,0.40)',
    },
    ...props,
  })
}

export function showSuccess(props: any) {
  return Modal.success({
    className: `${props.className} retool-modal`,
    wrapClassNam: 'vertical-center-modal',
    maskStyle: {
      backgroundColor: 'rgba(0,0,0,0.40)',
    },
    ...props,
  })
}

export function showConfirm(props: any) {
  return Modal.confirm({
    className: `${props.className} retool-modal`,
    autoFocusButton: 'ok',
    wrapClassName: 'vertical-center-modal',
    maskStyle: {
      backgroundColor: 'rgba(0,0,0,0.40)',
    },
    ...props,
  })
}

export function showEmptyModal(props: any) {
  return Modal.info({
    className: `${props.className} retool-modal retool-modal--empty`,
    wrapClassName: 'vertical-center-modal',
    icon: null,
    maskStyle: {
      backgroundColor: 'rgba(0,0,0,0.40)',
    },
    ...props,
  })
}

import React from 'react'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'
import { generateResourceForm } from 'components/ResourceForm/resourceFormGenerator/generator'
import { AuthTemplate } from 'components/ResourceForm/resourceFormGenerator/generator-types'
import { SHARED_OAUTH_CALLBACK_URL } from 'components/ResourceForm/oauthForm'
import { transformHeadersForEditor } from '../../components/AuthTriggerInput'
import { HubspotResourceSettings } from 'common/records'

const HUBSPOT_AUTH_URL = 'https://app.hubspot.com/oauth/authorize'
const HUBSPOT_TOKEN_URL = 'https://api.hubapi.com/oauth/v1/token'

const optionalScopes =
  'automation business-intelligence contacts content conversations.visitor_identification.tokens.create e-commerce files forms hubdb integration-sync sales-email-read social tickets timeline transactional-email'

const defaultOptions: HubspotResourceSettings = {
  options: {
    customQueryParameters: [['', '']],
    customHeaders: [['Authorization', 'Bearer OAUTH2_TOKEN']],
    api_key: '',
    authentication: 'oauth2',
    oauth2_client_id: '',
    oauth2_client_secret: '',
    oauth2_scope: 'contacts',
    oauth2_optional_scope: optionalScopes,
    oauth2_callback_url: SHARED_OAUTH_CALLBACK_URL,
    oauth2_auth_url: HUBSPOT_AUTH_URL,
    oauth2_access_token_url: HUBSPOT_TOKEN_URL,
    oauth2_share_user_credentials: true,
    spec: `${RETOOL_SPECS_DOMAIN}/hubspot/events/events/3.0.1/v3.json`,
    verify_session_action_enabled: true,
    verify_session_action: {
      query: 'https://api.hubapi.com/crm/v3/objects/contacts?limit=0',
      method: 'GET',
      type: 'http_request',
      runWhenModelUpdates: false,
      bodyType: 'raw',
      headers: transformHeadersForEditor([['Authorization', 'Bearer OAUTH2_TOKEN']]),
    },
  },
}

const auth: AuthTemplate = [
  {
    type: 'apiKey',
    options: {
      customQueryParameters: [['hapikey', 'API_KEY']],
      customHeaders: [['', '']],
      label: 'HubSpot personal access token',
      description: (
        <>
          (more info{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://knowledge.hubspot.com/integrations/how-do-i-get-my-hubspot-api-key"
          >
            here
          </a>
          )
        </>
      ),
    },
  },
  {
    type: 'oauth2',
    options: {
      retoolHostedApp: {},
      customApp: {
        description: (
          <>
            To connect Retool to Hubspot, create a connected app in your Hubspot account for Retool.{' '}
            <a
              target="_blank"
              href="https://legacydocs.hubspot.com/docs/faq/how-do-i-create-an-app-in-hubspot"
              rel="noopener noreferrer"
            >
              Here is a guide on how to create an application in Hubspot
            </a>
          </>
        ),
      },
      isShareableAcrossUsers: true,
    },
  },
]

export default generateResourceForm('HubSpot', defaultOptions, {
  auth,
  hideTestConnection: () => true,
  isOAuth: (resource) => resource.options?.authentication === 'oauth2',
})

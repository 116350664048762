import queryString from 'query-string'
import { getXsrfToken } from 'networking/util'

export function fetchWithHeadersAndHostname(url: any, options: any) {
  if (!options.headers) options.headers = {}
  options.headers['X-Xsrf-Token'] = getXsrfToken()
  options.headers['Content-Type'] = options.headers['Content-Type'] || 'application/json'
  options.credentials = INCLUDE_COOKIES_IN_API_CALLS || 'same-origin'
  return fetch(url, options)
}

function toQueryString(requestUrl: any, query: any) {
  return `${requestUrl}?${queryString.stringify(query)}`
}

export async function POST(requestUrl: string, query: any, headers: { [key: string]: string }, bodyData: any) {
  const res = await fetchWithHeadersAndHostname(toQueryString(requestUrl, query), {
    method: 'post',
    body: JSON.stringify(bodyData),
    headers,
  })
  let json
  try {
    json = await res.json()
  } catch (err) {
    throw Error(res.statusText)
  }
  if (!res.ok) {
    throw Error(json.message)
  } else {
    return json
  }
}

export default fetchWithHeadersAndHostname

import React from 'react'
import { connect } from 'react-redux'
import { Input, Button } from 'antd'
import * as onboardingActionCreators from './onboardingMiddleware'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'

const SURVEY_RESPONSES = [
  {
    emoji: '😞',
    value: 'bad',
    freeResponseLabel: 'Oh no! Please tell us more',
  },
  {
    emoji: '😐',
    value: 'meh',
    freeResponseLabel: 'Oh no! Please tell us more',
  },
  {
    emoji: '😊',
    value: 'good',
    freeResponseLabel: 'Woohoo! Anything else you want us to know?',
  },
]

class OnboardingSurvey extends React.Component<{ goToNextStep: () => void }, {}> {
  state: any = { surveyResponse: null, freeResponse: '' }

  onResponseClick = (surveyResponse: any) => {
    retoolAnalyticsTrack('Onboarding Survey', { emoji: surveyResponse.value })
    this.setState({ surveyResponse })
  }

  onNextClick = () => {
    if (this.state.freeResponse) {
      retoolAnalyticsTrack('Onboarding Survey Free Response', { text: this.state.freeResponse })
    }
    this.props.goToNextStep()
  }

  render() {
    return (
      <div>
        <div style={{ display: 'flex', marginBottom: 24 }}>
          {SURVEY_RESPONSES.map((option) => (
            <div
              key={option.value}
              style={
                this.state.surveyResponse && option !== this.state.surveyResponse
                  ? {
                      opacity: 0.3,
                    }
                  : {}
              }
              onClick={() => this.onResponseClick(option)}
              className="onboarding-modal__survey-feedback-option"
            >
              {option.emoji}
            </div>
          ))}
        </div>
        {this.state.surveyResponse && (
          <div>
            <div style={{ marginBottom: 12 }}>
              <div style={{ marginBottom: 8 }}>{this.state.surveyResponse.freeResponseLabel}</div>
              <Input.TextArea
                value={this.state.freeResponse}
                onChange={(evt) => this.setState({ freeResponse: evt.target.value })}
              />
            </div>
            <Button style={{ float: 'right' }} type="primary" size="large" onClick={this.onNextClick}>
              Next
            </Button>
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  () => ({}),
  (dispatch) => ({
    goToNextStep: () => dispatch(onboardingActionCreators.goToNextStep()),
  }),
)(OnboardingSurvey)

import { Group } from '__globalShared__/permissions'

export const groupNameToSlug = (name: string): string =>
  name
    .trim()
    .replace(/[^a-zA-Z0-9-]+/g, '-')
    .toLocaleLowerCase()

export const paths = {
  root: '/settings/permissions',
  detail: 'group/:groupId(/:groupSlug)',
  createGroupDetailPath: (group: Pick<Group, 'id' | 'name'>): string => {
    return `${paths.root}/group/${group.id}/${groupNameToSlug(group.name)}`
  },
} as const

import ls from 'local-storage'

import { SET_LARGE_SCREEN, SET_SMALL_SCREEN, FORCE_MOBILE_MODE, UNFORCE_MOBILE_MODE } from './constants'

import { assertNever } from 'types/typeguards'

import {
  ForceMobileModeType,
  SetLargeScreenType,
  SetSmallScreenType,
  ResponsiveActionTypes,
  ResponsiveReducerType,
} from './responsive.d'

export const mediaQuery = window.matchMedia('(min-width: 600px)')

// ------------------------------------
// Synchronous Actions
// ------------------------------------
export function setLargeScreen(): SetLargeScreenType {
  return {
    type: SET_LARGE_SCREEN,
  }
}

export function setSmallScreen(): SetSmallScreenType {
  return {
    type: SET_SMALL_SCREEN,
  }
}

export function forceMobileMode(forced: boolean): ForceMobileModeType {
  ls.set('mobileModeForced', forced)
  if (forced) {
    return {
      type: FORCE_MOBILE_MODE,
    }
  }
  return {
    type: UNFORCE_MOBILE_MODE,
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  largeScreen: mediaQuery.matches,
  mobileModeForced: ls.get('mobileModeForced'),
}

const locationReducer = (
  state: ResponsiveReducerType = initialState,
  action: ResponsiveActionTypes,
): ResponsiveReducerType => {
  switch (action.type) {
    case SET_LARGE_SCREEN: {
      return {
        ...state,
        largeScreen: true,
      }
    }
    case SET_SMALL_SCREEN: {
      return {
        ...state,
        largeScreen: false,
      }
    }
    case FORCE_MOBILE_MODE: {
      return {
        ...state,
        mobileModeForced: true,
      }
    }
    case UNFORCE_MOBILE_MODE: {
      return {
        ...state,
        mobileModeForced: false,
      }
    }
    default: {
      assertNever(action, false)
      return state
    }
  }
}

export default locationReducer

import React from 'react'

import { MongoDBResource } from 'common/records'
import { DatabaseForm, InputField, databaseDefaults, SSHTunnelForm } from './common'
import { Alert, Icon, Radio } from 'components/design-system'
import { ResourceType } from 'common/resourceTypes'
import { ResourceFormProps, ResourceConfig } from './types'
import { parse as parseMongoDbUri } from 'mongodb-uri'
import { IS_ON_PREM } from 'retoolConstants'
import _styles from "./mongodb-styles.module.scss";


type ConnectionStringSuggestionProps = {
  connectionString: string
  isOnPrem: boolean
}

const ConnectionStringSuggestion = (props: ConnectionStringSuggestionProps) => {
  const { connectionString, isOnPrem } = props

  if (!connectionString) {
    return null
  }

  const [type, message] = (function (): ['info' | 'warning', JSX.Element] | [null, null] {
    if (!connectionString.includes('://')) {
      return [
        'warning',
        <p key="message">
          Your connection string doesn't look right. Make sure you include the scheme: either <code>mongodb://</code> or{' '}
          <code>mongodb+srv://</code>.
        </p>,
      ]
    }

    let _res: ReturnType<typeof parseMongoDbUri> | null = null
    try {
      _res = parseMongoDbUri(connectionString)
    } catch (e) {
      return ['warning', <p key="message">We couldn't parse that connection string.</p>]
    }

    if (_res) {
      const res = _res
      if (res.scheme !== 'mongodb' && res.scheme !== 'mongodb+srv') {
        return [
          'warning',
          <p key="message">
            Your connection string doesn't look right. Make sure you use a supported scheme: either{' '}
            <code>mongodb://</code> or <code>mongodb+srv://</code>.
          </p>,
        ]
      }

      const invalidHosts = res.hosts.filter((host) => !host.host?.includes('.'))
      // NB: we can only guess hosts are invalid in a cloud-like env where we control the DNS.
      if (!isOnPrem && invalidHosts.length) {
        const hosts = Array.from(new Set(invalidHosts.map((h) => (h.port ? `${h.host}:${h.port}` : h.host))))

        return [
          'warning',
          <p key="message">
            Your connection string doesn't look right.{' '}
            {hosts.length === 1 ? (
              <>
                The provided hostname <code>{hosts[0]}</code> should be a fully qualified domain name or IP address.
              </>
            ) : (
              <>
                The provided hostnames{' '}
                {hosts.map((h, idx) => (
                  <React.Fragment key={h}>
                    <code>{h}</code>
                    {idx !== hosts.length - 1 ? <span>{', '}</span> : null}
                  </React.Fragment>
                ))}{' '}
                should be fully qualified domain names or IP addresses.
              </>
            )}
          </p>,
        ]
      }

      if (res.database === 'test') {
        return [
          'info',
          <p key="message">
            We'll attempt to authenticate against the <code>test</code> database.
          </p>,
        ]
      }
    }

    return [null, null]
  })()

  if (!type) {
    return null
  }

  return <Alert type={type} message={message} />
}

const styles = _styles

const MongoDBForm = (props: ResourceFormProps) => {
  return (
    <SSHTunnelForm {...props}>
      <>
        <DatabaseForm
          placeholderHost="IP address or hostname of your database"
          placeholderPort="27017"
          renderPort={(props) => (
            <>
              <label className="input-label">
                <span>
                  <span className="light-red mr4">*</span>
                  Connection format
                </span>
              </label>
              <div className={`flex items-center ${styles.radioSelect}`}>
                <Radio
                  value="standard"
                  checked={!props.resource.options.useDNSSeedList}
                  onClick={() => props.updateResourceOptions({ useDNSSeedList: false })}
                >
                  Standard connection
                </Radio>
                <Radio
                  value="dns-seed-list"
                  checked={props.resource.options.useDNSSeedList}
                  onClick={() => props.updateResourceOptions({ useDNSSeedList: true })}
                >
                  <span className="flex">
                    DNS seed list connection{' '}
                    <a
                      className="gray link fs-13 flex items-center"
                      href="https://docs.mongodb.com/manual/reference/connection-string/#dns-seed-list-connection-format"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Icon type="link" className="blue ml4" />
                    </a>
                  </span>
                </Radio>
              </div>
              {!props.resource.options.useDNSSeedList ? (
                <InputField
                  label="Port"
                  placeholder={props.placeholderPort}
                  resourceKey="port"
                  topLevel
                  required={!props.resource.options.useDNSSeedList}
                  {...props}
                />
              ) : null}
            </>
          )}
          renderConnectionString={(props) => (
            <>
              <InputField
                label="Connection String"
                placeholder={props.placeholderConnectionString}
                resourceKey="connectionString"
                required
                {...props}
              />
              <div className="grid-offset-1 description">
                We'll pass this directly to the MongoDB client. Find details on the format of this string{' '}
                <a href="https://docs.mongodb.com/v3.6/reference/connection-string/" target="_blank" rel="noopener">
                  in the MongoDB docs
                </a>
                .
              </div>
              <div className="grid-offset-1">
                <ConnectionStringSuggestion
                  connectionString={props.resource.options.connectionString || ''}
                  isOnPrem={IS_ON_PREM}
                />
              </div>
            </>
          )}
          placeholderUsername="retool"
          placeholderConnectionString="mongodb+srv://admin:password@host/mydb?retryWrites=true&w=majority"
          mongoDbSelfSignedCertificatesSupported={true}
          hideEditPrivilege
          hideDangerZone
          {...props}
        />
      </>
    </SSHTunnelForm>
  )
}

type Props = {
  errMsg: string
  resourceName: string
  resourceType: ResourceType
}
function getResourceErrorTip({ errMsg }: Props): JSX.Element | null {
  if (errMsg.includes('getaddrinfo ENOTFOUND')) {
    return (
      <>
        The hostname you provided doesn't work. Perhaps there's a typo, or it's not a public hostname? (Sometimes this
        is also related to not using a{' '}
        <a href="https://docs.mongodb.com/manual/reference/connection-string/#dns-seed-list-connection-format">
          DNS seed list connection.
        </a>
        )
      </>
    )
  }

  if (errMsg.includes('querySrv ENOTFOUND')) {
    return (
      <>
        Retool couldn't find a database host to connect to. Perhaps there's a typo, or it's not a public hostname? (e.g.
        behind a VPC)
      </>
    )
  }

  if (errMsg.includes('bad auth : Authentication failed')) {
    // NB: this error only seems to happen with an existing username + bad pw
    return <>Retool reached the host, but couldn't authenticate. Maybe the password is wrong?</>
  }
  if (errMsg.includes('Authentication failed')) {
    return <>Retool reached the host, but couldn't authenticate. Perhaps there's a typo in the username and password?</>
  }

  return null
}

export default {
  label: 'MongoDB',
  form: MongoDBForm,
  defaults: {
    ...databaseDefaults,
    options: {
      ...databaseDefaults.options,
      useDNSSeedList: false,
    },
  },
  getResourceErrorTip,
  validator: (resource) =>
    resource.options.connectionString ||
    (resource.type && resource.host && (resource.port || resource.options.useDNSSeedList)),
} as ResourceConfig<MongoDBResource>

import React from 'react'
import { OnboardingStep, OnboardingSubStep, AppModelType, PluginModel, SQL_QUERY_VERSION_UNIFIED } from 'common/records'
import * as _ from 'lodash'
import Immutable from 'immutable'

import { Icon, Button } from 'components/design-system'
import { shortcutFormatter } from 'shortcutsKeymap'
import OnboardingSurvey from '../OnboardingSurvey'

import HelloComponentsImage from 'assets/onboarding-images/hello-components.png'
import LearnRetoolImage from 'assets/onboarding-images/learn-retool.png'
import PutBackTheApprovalImage from 'assets/onboarding-images/put-back-the-approval.png'
import TableMeetsDataImage from 'assets/onboarding-images/table-meets-data.png'
import TestingItOutImage from 'assets/onboarding-images/testing-it-out.png'
import UnderstandTheUrlImage from 'assets/onboarding-images/understand-the-url.png'
import ButtonEventHandlerRunQueryImage from 'assets/onboarding-images/button-run-query-event-handler.png'

import { dispatch } from 'store'
import { selectedDatasourceSelector, selectedResourceSelector } from 'store/selectors'
import { QUERY_EDITOR_UPDATE } from 'routes/Editor/modules/editor'
import { datasourceTypeChange } from 'store/appModel/template'
import { widgetTemplateUpdate } from 'store/appModel/templateUtils'
import { batchUndoGroup } from 'store/appModel/batchUndoGroupBy'

function setQueryEditorState(newValue: any) {
  return dispatch((dispatch, getState) => {
    const selectedResource = selectedResourceSelector(getState())
    const editorType = selectedResource!.editorType
    dispatch({
      type: QUERY_EDITOR_UPDATE,
      payload: { newValue, editorType },
    })
  })
}

function queryStateDatasourceTypeChange(resourceName: string, newType: string) {
  return dispatch((dispatch, getState) => {
    const state = getState()
    const datasource = selectedDatasourceSelector(state)
    const oldType = datasource?.subtype ?? 'RESTQuery'
    dispatch({
      type: 'QUERY_STATE_DATASOURCE_TYPE_CHANGE',
      payload: { pluginId: datasource?.id, resourceName, newType, oldType },
    })
  })
}

function saveQuery() {
  dispatch(
    batchUndoGroup((dispatch: any, getState) => {
      const datasourceId = getState().editor.get('selectedDatasourceId')
      const queryState = getState().editor.queryState.get('RESTQuery')
      dispatch(datasourceTypeChange(datasourceId, getState().editor.selectedResourceName, 'RESTQuery'))
      dispatch(widgetTemplateUpdate(datasourceId, queryState!.toJS()))
    }),
  )
}

const findComponentAndVerifyPropertyInModel = (model: AppModelType, type: any, propPath: any, propValue: any) => {
  const possibleMatches = model.values.filter((v: any) => v.get('pluginType') === type)
  return !!possibleMatches.find((m: any) => m.getIn(propPath).toLowerCase() === propValue.toLowerCase())
}

const gsheetsStep = new OnboardingStep({
  title: 'Google Sheets, eh?',
  message: `Thanks for reading our blog!
  <br/><br/>
  Let's learn the basics of Retool first. We'll show you the Google Sheets app in a bit. :)`,
})

const useCase = new OnboardingStep({
  title: `👋 Learn Retool in 5 minutes`,
  message: `Want to learn the core concepts before you continue? Take this tutorial to build a simple approval app. We’ll get our users from our database via SQL, then approve them via our API.`,
  image: LearnRetoolImage,
})

const dragOnTable = new OnboardingStep({
  title: 'Hello, components',
  message: `Let's start by laying out the UI. To display our sign-ups, we'll
  want to render them into a table.`,
  image: HelloComponentsImage,
  messageSteps: [`Drag the table component onto the canvas.`],
  condition: (template) => {
    return !!template.plugins.find((v) => v.get('subtype') === 'TableWidget')
  },
  substeps: new OnboardingSubStep({
    hintNodeSelector: '[data-onboarding-target="WidgetPicker:TableWidget"]',
  }),
})

const dragOnButton = new OnboardingStep({
  title: 'Table, meet Button',
  message: `Nice! Now let's add a button to approve our users.`,
  messageSteps: [
    `Click on the canvas or hit<span class='onboarding-modal__key'>esc</span>. This will deselect the table.`,
    `Drag a <strong>Button</strong> onto the canvas, next to the table.`,
  ],
  condition: (template) => {
    return template.plugins.filter((v) => v.get('subtype') === 'ButtonWidget').size >= 1
  },
  substeps: Immutable.List([
    new OnboardingSubStep({
      hintNodeSelector: '._retool-table1',
      hintNodeStyle: { top: 25, left: -70 },
      nextCondition: (template, model, editor) => {
        return !editor.getSelectedWidgetId()
      },
    }),
    new OnboardingSubStep({
      hintNodeSelector: '[data-onboarding-target="WidgetPicker:ButtonWidget"]',
      prevCondition: (template, model, editor) => {
        return !!editor.getSelectedWidgetId()
      },
    }),
  ]),
})

const labelButtons = new OnboardingStep({
  title: 'Customize components',
  message: `In Retool, components have properties, which we can modify.`,
  messageSteps: [
    `Select the button.`,
    `In the component inspector, change the <strong>Text</strong> property from "<strong>Submit</strong>" to "<strong>Approve</strong>."`,
  ],
  condition: (template, model) => {
    return findComponentAndVerifyPropertyInModel(model, 'ButtonWidget', ['value'], 'approve')
  },
  substeps: Immutable.List([
    new OnboardingSubStep({
      hintNodeSelector: (template, model) => {
        const id = model.values
          .filter((v: PluginModel) => v.get('pluginType') === 'ButtonWidget')
          .keySeq()
          .first()
        return `._retool-${id}`
      },
      nextCondition: (template, model, editor) => {
        const selectedWidgetId = editor.getSelectedWidgetId()
        if (!selectedWidgetId) {
          return false
        }
        return template.plugins.get(selectedWidgetId)!.subtype === 'ButtonWidget'
      },
    }),
    new OnboardingSubStep({
      hintNodeSelector: '[data-onboarding-target="ButtonValueEditor"]',
      prevCondition: (template, model, editor) => {
        const expectedId = model.values
          .filter((v: PluginModel) => v.get('pluginType') === 'ButtonWidget')
          .keySeq()
          .first()
        const selectedWidgetId = editor.getSelectedWidgetId()
        if (!selectedWidgetId) return true
        if (selectedWidgetId === expectedId) return false
        const correctValue = template.plugins.get(selectedWidgetId)!.template.get('value') === 'Approve'
        return !correctValue
      },
      nextCondition: (template, model, editor) => {
        const selectedWidgetId = editor.getSelectedWidgetId()
        if (!selectedWidgetId) return false
        const hasChangedValue = template.plugins.get(selectedWidgetId)!.template.get('value') !== 'Submit'
        const correctValue = template.plugins.get(selectedWidgetId)!.template.get('value') === 'Approve'
        if (hasChangedValue && !correctValue) {
          const onboardingMessage = document.getElementsByClassName('onboarding-modal__description')[0]
          const additionalPrompt = `<div class="onboarding-modal__additional-prompt"><strong>To proceed to the next step,</strong> please change the <code>Text</code> property on the button to <strong><code>Approve</code></strong>!</div>`
          if (!onboardingMessage.innerHTML.includes(additionalPrompt)) {
            onboardingMessage.innerHTML = onboardingMessage.innerHTML + additionalPrompt
          }
        }
        return correctValue
      },
    }),
  ]),
})

const selectCorrectResource = new OnboardingStep({
  title: 'Get the right data',
  message: `We need to connect to the resource with the data we want to read.`,
  messageSteps: [`Select the resource <strong> onboarding_db </strong>`, `then click <strong> Save & Run </strong>`],
  condition: (template, model, editor) => {
    const query = editor.get('selectedResourceName')
    return query
      .toLowerCase()
      .trim()
      .startsWith(__DEV__ ? '[dev] internal_retool' : 'onboarding_db')
  },
  substeps: Immutable.List([
    new OnboardingSubStep({ hintNodeSelector: '.resourceSelector' }),
    new OnboardingSubStep({ hintNodeSelector: '.resourceSelector' }),
  ]),
})

const writeSqlQuery = new OnboardingStep({
  title: 'Data',
  message: `Great! Let’s read some data. In the query editor below, enter:`,
  messageSteps: [`<code>select * from users order by id;</code>`],
  condition: (template, model, editor) => {
    const query = editor.getIn(['queryState', SQL_QUERY_VERSION_UNIFIED, 'query'])
    return query.toLowerCase().trim().startsWith('select * from users order by id')
  },
  doItForMe: () => setQueryEditorState({ query: 'select * from users order by id;' }),
  substeps: new OnboardingSubStep({
    hintNodeSelector:
      '#query-editor > div > div.query-editor-body > div > div.Pane.horizontal.Pane1 > div > div.editor-wrapper > div.query-main-editor-body > div:nth-child(1) > div',
  }),
})

const PreviewSqlQueryMessageStepOne = () => {
  const previewShortcut: string[] = shortcutFormatter('QUERY_EDITOR', 'PREVIEW_QUERY')
    .split('')
    .filter((k) => k !== ' ')
  return (
    <div className="onboarding-modal__message-step-line">
      <span>
        Click
        <Button style={{ height: 20 }} className="query-editor__preview-button">
          <div className="query-editor__preview-button-inner flex items-center" aria-label="Preview query">
            Preview
          </div>
        </Button>
        or press
        {previewShortcut.map((key) => (
          <span className="onboarding-modal__key" key={key}>
            {key}
          </span>
        ))}
        to preview the query response.
      </span>
    </div>
  )
}
const PreviewSqlQueryMessageStepTwo = () => {
  const saveShortcut: string[] = shortcutFormatter('QUERY_EDITOR', 'SAVE_QUERY')
    .split('')
    .filter((k) => k !== ' ')
  return (
    <div className="onboarding-modal__message-step-line">
      <span>
        Click
        <span className="onboarding-modal__save-button">
          <Icon type="run" style={{ height: 8 }} /> Save & Run
        </span>
        or press
        {saveShortcut.map((key) => (
          <span className="onboarding-modal__key" key={key}>
            {key}
          </span>
        ))}
        to save the query and run it for the first time.
      </span>
    </div>
  )
}

const previewSqlQuery = new OnboardingStep({
  title: 'Preview and save',
  message: `Look before you leap! Don’t forget to save your queries.`,
  messageSteps: [PreviewSqlQueryMessageStepOne, PreviewSqlQueryMessageStepTwo],
  messageNote: `Always click save (or save & run) after making changes to your query or you'll lose them!`,
  condition: (template) => {
    const queries = template.plugins.filter((v) => v.get('subtype') === SQL_QUERY_VERSION_UNIFIED)
    return !!queries.find((q) => {
      return q.get('template').get('query').toLowerCase().trim().startsWith('select * from users')
    })
  },
  substeps: Immutable.List([
    new OnboardingSubStep({
      hintNodeSelector:
        '#query-editor > div:nth-child(2) > div.query-editor__header > div.navbar-right > button:nth-child(3)',
      nextCondition: (_template, _model, editor) => {
        return editor.get('previewOpen')
      },
    }),
    new OnboardingSubStep({
      hintNodeSelector:
        '#query-editor > div:nth-child(2) > div.query-editor__header > div.navbar-right > button:nth-child(2)',
    }),
  ]),
})

const dataInTable = new OnboardingStep({
  title: 'Table, meet data.',
  message: `Great! The data is in Retool now, but we've got to put it into the table.`,
  messageSteps: [
    `Click on the table. Replace the whole <strong>Data</strong> property with <code>{{ query1.data }}</code>. That’s how we refer to our query we just wrote.\n
    <strong>Note</strong>: if your data is returned in the form of a JSON object, and not an array, you may need to transform it into an array of JSON objects, by using  <code>{{ formatDataAsArray(query1.data) }}</code>`,
  ],
  messageNote: `Learn more about these curly braces <a href="https://docs.retool.com/docs/understanding-components" target="_blank">here</a>.`,
  image: TableMeetsDataImage,
  condition: (template, model) => {
    const tables = model.values.filter((v: any) => v.get('pluginType') === 'TableWidget')
    const depGraph = model.dependencyGraph
    const tablesWithDependencies = tables.filter((m: any, id: any) => {
      // ensure that the substring `.data` appears in the template
      const dataTemplate = template.getIn(['plugins', id, 'template', 'data'])
      if (dataTemplate.indexOf('.data') === -1) {
        return false
      }

      // ensure there is data
      const data = m.get('data')
      const values = _.values(data)
      if (values.length === 0 || values[0].length === 0) {
        return false
      }

      const dependencies = depGraph.getDependenciesOf([id, 'data'])
      for (const dep of dependencies) {
        const depId = dep.selector[0]
        if (template.plugins.get(depId)!.subtype === SQL_QUERY_VERSION_UNIFIED) {
          if (dep.selector[1] === 'data') {
            // some table is connected some query's data!
            return true
          }
        }
      }
    })

    return tablesWithDependencies.size > 0
  },
  substeps: Immutable.List([
    new OnboardingSubStep({
      hintNodeSelector: '._retool-table1',
      nextCondition: (template, model, editor) => {
        const selectedWidgetId = editor.getSelectedWidgetId()
        if (!selectedWidgetId) {
          return false
        }
        // eslint-disable-next-line no-console
        console.log(selectedWidgetId)
        // eslint-disable-next-line no-console
        console.log(template.plugins.get(selectedWidgetId))
        return template.plugins.get(selectedWidgetId)!.subtype === 'TableWidget'
      },
    }),
    new OnboardingSubStep({
      hintNodeSelector: '[data-onboarding-target="TableDataEditor"]',
      prevCondition: (template, model, editor) => {
        const selectedWidgetId = editor.getSelectedWidgetId()
        if (!selectedWidgetId) return true
        const correctValue =
          template.plugins.get(selectedWidgetId)!.template.get('value').replace('/s/g', '') === '{{query1.data}}'
        return !correctValue
      },
      nextCondition: (template, model, editor) => {
        const selectedWidgetId = editor.getSelectedWidgetId()
        if (!selectedWidgetId) return false
        const correctValue =
          template.plugins.get(selectedWidgetId)!.template.get('value').replace('/s/g', '') === '{{query1.data}}'
        return correctValue
      },
    }),
  ]),
})

const addTextInput = new OnboardingStep({
  title: 'Table, meet filter',
  message: `Now let's add a text input field where people can search the table for a specific row.`,
  messageSteps: [
    `Click on the canvas to deselect the table.`,
    `Drag a <strong>Text Input</strong> onto the canvas, next to the table.`,
  ],
  condition: (template) => {
    return template.plugins.filter((v) => v.get('subtype') === 'TextInputWidget').size >= 1
  },
  substeps: Immutable.List([
    new OnboardingSubStep({
      hintNodeSelector: '[data-onboarding-target="WidgetPicker:TextInputWidget"]',
    }),
  ]),
})

const editSqlQuery = new OnboardingStep({
  title: 'Modify your query',
  message: `Now let's change our query to reference the new text input. In the query editor below, change the query value to:`,
  messageSteps: [
    `<code>select * from users where first_name ilike {{ '%' + textinput1.value + '%' }} order by id</code>`,
  ],
  condition: (template, model, editor) => {
    const query = editor.getIn(['queryState', SQL_QUERY_VERSION_UNIFIED, 'query'])
    return query
      .toLowerCase()
      .trim()
      .startsWith(`select * from users where first_name ilike {{ '%' + textinput1.value + '%' }} order by id`)
  },
  doItForMe: () =>
    setQueryEditorState({
      query: `select * from users where first_name ilike {{ '%' + textinput1.value + '%' }} order by id;`,
    }),
  substeps: new OnboardingSubStep({
    hintNodeSelector:
      '#query-editor > div > div.query-editor-body > div > div.Pane.horizontal.Pane1 > div > div.editor-wrapper > div.query-main-editor-body > div:nth-child(1) > div',
  }),
})

const tryFilteringTable = new OnboardingStep({
  title: `Filter the table`,
  message: `Try typing in some first names from the table in the text input. You should see the values in the table change automatically!`,
  messageNote: `The query detects that its input has changed and knows to run again to refresh the data.`,
})

const saveEditSqlQuery = new OnboardingStep({
  title: 'Save it!',
  messageSteps: [`Looks good! <strong>Now click save & run...</strong>`],
  condition: (template) => {
    const queries = template.plugins.filter((v) => v.get('subtype') === SQL_QUERY_VERSION_UNIFIED)
    return !!queries.find((q) => {
      return q
        .get('template')
        .get('query')
        .toLowerCase()
        .trim()
        .startsWith(`select * from users where first_name ilike {{ '%' + textinput1.value + '%' }} order by id`)
    })
  },
  substeps: new OnboardingSubStep({
    hintNodeSelector:
      '#query-editor > div:nth-child(2) > div.query-editor__header > div.navbar-right > button:nth-child(2)',
  }),
})

export const eventHandlerClickOnButton = new OnboardingStep({
  title: `Button, run query.`,
  message: `Let's hook the button up, so it actually approves sign-ups.`,
  messageSteps: [
    `Select the button component.`,
    `Under <strong>Event handlers</strong>, click <strong>+ New</strong> to create an event handler.`,
    `In the <strong>Query</strong> dropdown, click <strong>Create a new query</strong> to create a query that will trigger when the button is clicked.`,
  ],
  condition: (template) => {
    const queries = template.plugins.filter((v) => v.get('type') === 'datasource')
    return !!queries.find((q) => !!q.get('id')?.match(/button.*ClickHandler/))
  },
  image: ButtonEventHandlerRunQueryImage,
  substeps: Immutable.List([
    new OnboardingSubStep({
      hintNodeSelector: '[data-onboarding-target="RetoolWidget:ButtonWidget"]',
      nextCondition: (template, _, editor) => {
        const selectedComp = editor.selectedWidgets.first()
        return template.plugins.getIn([selectedComp, 'subtype']) === 'ButtonWidget'
      },
    }),
    new OnboardingSubStep({
      hintNodeSelector: '[data-onboarding-target="CreateNewEventHandler"]',
      nextCondition: (template, _, editor) => {
        const selectedComp = editor.selectedWidgets.first()
        return !!template.plugins.getIn([selectedComp, 'template', 'events'])?.size
      },
    }),
    new OnboardingSubStep({
      hintNodeSelector: '[data-onboarding-target="EventHandlerPluginSelect"]',
    }),
  ]),
})

const writeRestQuery = new OnboardingStep({
  title: `PUT back an approval`,
  message: `Great. We’ve created a new query that the button will fire. It’s a PUT request back to our API. Typically, you’d select the REST resource, fill in the url, body, etc. But we will do this for you. Just click the button below!`,
  image: PutBackTheApprovalImage,
  doItForMe: () => {
    queryStateDatasourceTypeChange(__DEV__ ? '[dev] custom_auth (redirect)' : 'onboarding_api', 'RESTQuery')
    setQueryEditorState({
      type: 'PUT',
      runWhenModelUpdates: false,
      triggersOnSuccess: Immutable.List(['query1']),
      showSuccessConfetti: true,
      query: 'users/{{table1.selectedRow.data.id}}',
      bodyType: 'json',
      body: JSON.stringify([
        {
          key: 'active',
          value: 'true',
        },
      ]),
    })
    saveQuery()
  },
  condition: (template) => {
    const queries = template.plugins.filter((v) => v.get('subtype') === 'RESTQuery')
    const restQuery = queries.find((q) => !!q.get('id')?.match(/button.*ClickHandler/))
    if (restQuery?.template?.get('query') === 'users/{{table1.selectedRow.data.id}}') {
      return true
    }
    return false
  },
})

const useItEditor = new OnboardingStep({
  title: `Understanding it`,
  message: `Look at the query, does it make sense? Select a row. Then put your cursor inside the <strong>URL parameter input</strong> and look at the green preview. You can see the URL change and understand how <strong>selectedRow</strong> works.`,
  image: UnderstandTheUrlImage,
})

const useItEditor2 = new OnboardingStep({
  title: `Testing it out`,
  message: `Great, now let's approve some users.`,
  messageSteps: [
    `Select a row in the table. You might need to clear your text input if you don't see any rows.`,
    `Click on the Approve button. You will need to click on it <strong>twice</strong>.`,
  ],
  messageNote: `The first click selects the button so you can edit its properties. The second click actually triggers the event.`,
  image: TestingItOutImage,
})

const onboardingFeedbackSurvey = new OnboardingStep({
  title: `Was this helpful?`,
  message: OnboardingSurvey,
  hideBottomRow: true,
})

const nextSteps = new OnboardingStep({
  // the final modal is different because it takes over the screen +
  // isn't draggable and stuff. handled explicitly in OnboardingModal.tsx
})

const defaultFlow = Immutable.List([
  useCase,
  dragOnTable,
  dragOnButton,
  labelButtons,
  selectCorrectResource,
  writeSqlQuery,
  previewSqlQuery,
  dataInTable,
  addTextInput,
  editSqlQuery,
  saveEditSqlQuery,
  tryFilteringTable,
  eventHandlerClickOnButton,
  writeRestQuery,
  useItEditor,
  useItEditor2,
  onboardingFeedbackSurvey,
  nextSteps,
])

export const GoogleSheetsFlow = defaultFlow.unshift(gsheetsStep)

export default defaultFlow

import { PluginMethodConfig } from 'components/plugins'
import some from 'lodash/some'
import { getState } from 'store'
import { currentModeSelector } from 'store/selectors'
import { assertObjectOfType } from 'types/isObjectOfType'
import { assertIsString } from 'types/typeguards'

export type DownloadPageOptions = {
  selectorsToExclude?: string[]
  componentsToExclude?: string[]
  scale?: number
  fullscreen?: boolean
}

const downloadPage: PluginMethodConfig = {
  metadata: {
    label: 'Download page',
    example: '`utils.downloadPage(fileName, { selectorsToExclude, componentsToExclude, scale, fullscreen })`',
    description: `Downloads the current Retool page as a PDF with the provided fileName. An optional second parameter lets you provide an array of components (e.g. ['select1', 'textinput1']) and/or an array of css selectors to determine which elements are excluded from the screenshot. You can also pass a numerical "scale" parameter to configure the resolution (defaults to window.devicePixelRatio) and a boolean "fullscreen" parameter if you want to export the entire Retool page in presentation mode.`,
    params: [{ type: 'codeInput', name: 'fileName', props: { label: 'File name', validator: 'string' } }],
  },
  method: async (
    fileName = 'download.pdf',
    options = { fullScreen: false, scale: 1, selectorsToExclude: [], componentsToExclude: [] },
  ) => {
    assertIsString(fileName, '`fileName` must be a string.')
    assertObjectOfType<DownloadPageOptions>(
      options,
      {
        scale: { type: 'number', optional: true },
        fullscreen: { type: 'boolean', optional: true },
        selectorsToExclude: { type: 'string', array: true, optional: true },
        componentsToExclude: { type: 'string', array: true, optional: true },
      },
      '`options` must be of type { scale?: number, fullscreen?: boolean, selectorsToExclude?: string[], componentsToExclude?: string[] }',
    )

    const { jsPDF } = await import('jspdf')
    const html2canvas = (await import('html2canvas')).default

    // We don't have a good selector to export the full screen in editor mode
    const exportFullcreen = options?.fullscreen && currentModeSelector(getState()) !== 'editor'

    const selectorToDownload = exportFullcreen ? '.retool-fullscreen-container' : '.retool-container'

    const element = document.querySelector(selectorToDownload) as HTMLElement
    const { width, height } = element.getBoundingClientRect()

    const selectorsToExclude = options?.selectorsToExclude || []
    const componentsToExclude = options?.componentsToExclude || []
    const retoolComponentsToExcludeSelectors = componentsToExclude.map((widgetId) => `._retool-${widgetId}`)
    const allSelectorsToExclude = [...selectorsToExclude, ...retoolComponentsToExcludeSelectors]

    const canvas = await html2canvas(element, {
      ...(options?.scale != null && { scale: options.scale }),
      imageTimeout: 0,
      allowTaint: true,
      useCORS: true,
      onclone: (clonedDocument) => {
        // TODO: remove all transitions in the cloned document. Transitions that are
        // partially underway when converting the canvas to an image could cause undesired
        // visual effects in the final pdf
        clonedDocument.querySelectorAll('.fetching-mask').forEach((el) => ((el as HTMLElement).style.display = 'none'))
      },
      ignoreElements: (element) => {
        return some(allSelectorsToExclude, (selector) => element.matches(selector))
      },
    })

    const imgData = canvas.toDataURL('image/jpeg')

    // html2canvas defines the longer side of the canvas to be the y axis so we
    // have to set the orientation to match the Retool orientation
    const orientation = height > width ? 'portrait' : 'landscape'

    const pdf = new jsPDF({
      format: [width, height],
      orientation,
    })

    pdf.addImage(imgData, 'JPEG', 0, 0, width, height, 'someAlias', 'SLOW')

    pdf.save(fileName)
  },
}

export default downloadPage

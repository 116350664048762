import React, { FC } from 'react'
import classNames from 'classnames'
import { Modal as AntModal } from 'antd'
import { ModalProps as AntModalProps } from 'antd/lib/modal/Modal'
import Icon from '../Icon'

import 'antd/lib/modal/style/index'
import './Modal.scss'

export type ModalProps = AntModalProps & {
  children?: React.ReactNode
  isError?: boolean
}

const Modal: FC<ModalProps> = ({ className, ...props }) => {
  return (
    <AntModal
      maskClosable={false}
      transitionName="none"
      maskTransitionName="none"
      className={classNames('retool-modal', 'retool-modal-ds', className)}
      closeIcon={<Icon type="close" />}
      width={400}
      {...props}
    />
  )
}

export default Modal

import { injectReducer } from '../../store/reducers'
import AuthContainer from './containers/AuthContainer'
import { getExperimentValue } from 'common/utils/experiments'

export default function LoginRoute(store: any) {
  const onEnter = () => {
    if (getExperimentValue('selfServiceOnPremCTA')) {
      window?.FS?.restart?.()
    } else {
      window?.FS?.shutdown?.()
    }
  }

  const onLeave = () => {
    if (Math.random() >= 0.15) {
      window?.FS?.shutdown?.()
    } else {
      window?.FS?.restart?.()
    }
  }

  return {
    childRoutes: [
      {
        path: 'auth',
        onEnter,
        onLeave,
        /*  Async getComponent is only invoked when route matches   */
        childRoutes: [
          {
            path: 'login',
            component: AuthContainer,
            pageName: 'login',
            onEnter: () => {
              document.title = 'Log In | Retool'
            },
          },
          {
            path: 'signup',
            component: AuthContainer,
            pageName: 'signup',
            onEnter: () => {
              document.title = 'Sign Up | Retool'
            },
          },
          {
            path: 'invite/:signupToken',
            component: AuthContainer,
            pageName: 'invite',
            onEnter: () => {
              document.title = 'Invite | Retool'
            },
          },
          {
            path: 'resetPassword',
            component: AuthContainer,
            pageName: 'resetPassword',
            onEnter: () => {
              document.title = 'Reset Password | Retool'
            },
          },
          {
            path: 'confirmResetPassword',
            component: AuthContainer,
            pageName: 'confirmResetPassword',
            onEnter: () => {
              document.title = 'Confirm Reset Password | Retool'
            },
          },
          {
            path: 'verifyEmail',
            component: AuthContainer,
            pageName: 'verifyEmail',
            onEnter: () => {
              document.title = 'Verify Email | Retool'
            },
          },
          {
            path: 'confirmEmail',
            component: AuthContainer,
            pageName: 'confirmEmail',
            onEnter: () => {
              document.title = 'Confirm Email | Retool'
            },
          },
          {
            path: 'joinOrg',
            component: AuthContainer,
            pageName: 'joinOrg',
            onEnter: () => {
              document.title = 'Join Org | Retool'
            },
          },
        ],
        getComponent(nextState: any, cb: any) {
          /*  Webpack - use 'require.ensure' to create a split point
              and embed an async module loader (jsonp) when bundling   */
          require.ensure(
            [],
            (require) => {
              /*  Webpack - use require callback to define
                dependencies for bundling   */
              const loginModule: any = require('./modules/login')
              const reducer = loginModule.default

              /*  Add the reducer to the store on key 'counter'  */
              injectReducer(store, { key: 'login', reducer })

              if (nextState.params.signupToken) {
                store.dispatch(loginModule.useSignupToken(nextState.params.signupToken))
              }
              if (nextState.location.query.error) {
                store.dispatch(loginModule.setError(nextState.location.query.error))
              }

              /*  Return getComponent   */
              cb(null, AuthContainer)

              /* Webpack named bundle   */
            },
            'login',
          )
        },
      },
    ],
  }
}

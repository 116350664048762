export default function queryMiddleware() {
  return (next: any) => (action: any) => {
    if (
      action &&
      Object.prototype.hasOwnProperty.call(action, 'meta') &&
      action.meta &&
      Object.prototype.hasOwnProperty.call(action.meta, 'onSuccess')
    ) {
      action.meta.onSuccess(action)
    }
    return next(action)
  }
}

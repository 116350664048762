import { getState } from 'store'
import { browserHistory } from 'react-router'
import { Message } from 'components/design-system'
import dropRight from 'lodash/dropRight'
import { PluginMethodConfig } from 'components/plugins'
import queryString from 'query-string'
import { assertIsString, assertPlainObject, isString, isPlainObject } from 'types/typeguards'
import { onEmbeddedPage, pagePathFromUuid } from 'common/utils'
import { UnknownObject } from 'common/types'

// params come in two different flavors:
// from JSQuery: { foo: 'bar', hello: 'world' }
// from event handler: { 0: { key: 'foo', value: 'bar' }, 1: { key: 'hello', value: 'world' } }
// here, we unify these formats
const parseParams = (params: unknown): UnknownObject | undefined => {
  if (!params) return {}

  const parsed = isString(params) ? JSON.parse(params) : params

  if (isPlainObject(parsed)) {
    return Object.fromEntries(
      Object.entries(parsed).map(([key, value]) => {
        if (isPlainObject(value) && 'key' in value && 'value' in value) {
          return [value.key, value.value]
        }

        return [key, value]
      }),
    )
  }
}

const openApp: PluginMethodConfig = {
  metadata: {
    label: 'Open Retool app',
    description: 'Retool uses client-side routing by default when `newTab` is `false`.',
    example: "`utils.openApp(pageUuid, { queryParams: { key: 'value' }, newTab: true })`",
    params: [
      {
        type: 'appSelect',
        name: 'uuid',
        props: {
          label: 'App',
          docs: 'Note: if a user does not have access to the selected app this action will fail.',
        },
      },
      {
        type: 'objectInput',
        name: 'options',
        props: {
          label: 'Options',
          params: [
            { type: 'keyValueInput', name: 'queryParams', props: { label: 'Query params' } },
            { type: 'keyValueInput', name: 'hashParams', props: { label: 'Hash params' } },
            { type: 'checkbox', name: 'newTab', props: { label: 'Open in a new tab' } },
          ],
        },
      },
    ],
  },
  method: (uuid, options = {}) => {
    assertIsString(uuid, '`uuid` must be a string.')
    assertPlainObject(options, '`options` must be an object')

    const state = getState()
    const embedded = onEmbeddedPage()

    let rootPath: string
    let pagePath: string | undefined

    if (embedded) {
      const uuidToPaths = state.pages.get('uuidToPaths')
      pagePath = uuidToPaths[uuid].uuid
      rootPath = `${dropRight(window.location.pathname.split('/'), 1).join('/')}/`
    } else {
      const editorMode = state.pages.get('editorMode')
      pagePath = pagePathFromUuid(state, uuid)
      rootPath = editorMode ? '/editor/' : '/apps/'
    }

    if (!pagePath) {
      return Message.error(
        `Tried to open "${uuid}", but the app ${
          embedded
            ? "does not exist or it's not publicly accessible"
            : "does not exist or you don't have permission to view it"
        }.`,
      )
    }

    const { queryParams = {}, hashParams = {}, newTab = false } = options

    const queryParamsDict = parseParams(queryParams)
    const hashParamsDict = parseParams(hashParams)

    const paramsErrorMessage = 'must be a plain object of string key/value pairs.'
    assertPlainObject(queryParamsDict, `\`queryParams\` ${paramsErrorMessage}`)
    assertPlainObject(hashParamsDict, `\`hashParams\` ${paramsErrorMessage}`)

    const queryParamsString = queryString.stringify(queryParamsDict)
    const hashParamsString = queryString.stringify(hashParamsDict)

    let url = `${rootPath}${pagePath}`
    if (queryParamsString) url += `?${queryParamsString}`
    if (hashParamsString) url += `#${hashParamsString}`

    if (newTab) {
      window.open(window.location.origin + url)
    } else {
      browserHistory.push(url)
    }
  },
}
export default openApp

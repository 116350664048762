import { Position2 } from 'common/records'
import { createSelector } from 'reselect'
import {
  appModelValuesSelector,
  hasMobileLayoutSelector,
  largeScreenSelector,
  pluginsSelector,
  selectedWidgetsSelector,
} from 'store/selectors'
import getCurrentContainerKey from 'components/plugins/widgets/common/layoutUtils/getCurrentContainerKey'
import { widgetTypeIsModalLike } from 'components/plugins/widgets/common/layoutUtils/widgetTypeIsContainer'
import { RetoolState } from 'store'
import { UnknownModel } from 'components/plugins'

/** Returns an immutable map of all widgets by ID */
export const widgetsSelector = createSelector(pluginsSelector, (plugins) =>
  plugins.filter(({ type }) => type === 'widget'),
)

/** Returns the position key for the current viewport */
export const positionKeySelector = createSelector(
  largeScreenSelector,
  hasMobileLayoutSelector,
  (largeScreen, hasMobileLayout) => (largeScreen || !hasMobileLayout ? 'position2' : 'mobilePosition2'),
)

/**
 * Returns the container key to target for actions like select-all.
 * based on the currently selected widget.
 */
export const targetContainerKeySelector = (state: RetoolState): string => {
  const widgets = widgetsSelector(state)
  const positionKey = positionKeySelector(state)
  const selectedIds = selectedWidgetsSelector(state)
  const selectionCount = selectedIds.size

  if (!selectionCount) {
    // target the top-level canvas
    return Position2.getContainerKey({})
  }

  const selectedId = selectedIds.first<string>()
  const selectedWidget = widgets.get(selectedId)
  const selectedPosition = selectedWidget?.[positionKey]

  if (!selectedWidget || !selectedPosition) {
    // selected widget isn't visible in the current viewport
    return Position2.getContainerKey({})
  }

  if (selectionCount === 1) {
    // target the current widget, if its a container
    const { subtype: widgetType } = selectedWidget
    const model = appModelValuesSelector(state).getPlugin(selectedId) as UnknownModel
    const containerKey = getCurrentContainerKey(selectedId, widgetType, model)

    // if the container is a modal, it must be open
    const isClosedModal = widgetTypeIsModalLike(widgetType) && !model.get('opened')

    if (containerKey && !isClosedModal) {
      return containerKey
    }
  }

  // target siblings of the current selection
  return selectedPosition.getContainerKey()
}

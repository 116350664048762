import React from 'react'

import { DatabaseForm, databaseDefaults, databaseValidator, SSHTunnelForm, sshTunnelDefaults } from './common'
import { ResourceFormProps } from './types'
import { isLaunchedUnifiedSqlResource } from 'components/plugins/datasources/SqlQueryUnified/model'

const PostgresForm = (props: ResourceFormProps) => (
  <SSHTunnelForm {...props}>
    <DatabaseForm
      placeholderHost="your.vertica.host"
      placeholderPort="5433"
      placeholderUsername="vertica"
      placeholderConnectionString="jdbc:vertica://your.vertica.host:5433/databaseName?user=username&password=password"
      isUnifiedSqlResource={isLaunchedUnifiedSqlResource(props.resource)}
      {...props}
    />
  </SSHTunnelForm>
)

export default {
  label: 'Vertica',
  form: PostgresForm,
  defaults: {
    ...databaseDefaults,
    ...sshTunnelDefaults,
    options: {
      ...databaseDefaults.options,
      ...sshTunnelDefaults.options,
    },
    port: '5433',
  },
  validator: databaseValidator,
}

import { lifecycle, compose } from 'recompose'
import { connect, ResolveThunks } from 'react-redux'
import { browserHistory } from 'react-router'
import { cloneTemplate } from 'store/user'

type CloneTemplateProps = ResolveThunks<typeof mapDispatchToProps> & {
  params: { templateId: string }
  location: { query: { demoResourceOptionOverrides?: string } }
}

const mapDispatchToProps = { cloneTemplate }

const CloneTemplate = compose(
  connect(null, mapDispatchToProps),
  lifecycle<CloneTemplateProps, {}>({
    async componentDidMount() {
      const templateId = this.props.params.templateId

      const demoResourceOptionOverrides = this.props.location?.query?.demoResourceOptionOverrides

      const { newPageName, demoResourceConnectionRequired } = await this.props.cloneTemplate(
        templateId,
        undefined,
        undefined,
        undefined,
        demoResourceOptionOverrides,
      )
      browserHistory.replace(
        `/editor/${encodeURIComponent(newPageName)}${
          demoResourceConnectionRequired ? '?demoResourceConnectionRequired=true' : ''
        }`,
      )
    },
  }),
)(
  // the loading UI is handled by HomeView
  () => null,
)

export default CloneTemplate

import {
  APIResultT_DEPRECATE as APIResultT,
  CALL_API_DEPRECATE as CALL_API,
  CallAPIFn_DEPRECATE as CallAPIFn,
} from '../networking/index.deprecated'

/**
 * @deprecated use networking/callInternalApi instead.
 */
export const callApi: CallAPIFn<APIResultT> = (params) => {
  // for some reason, lowercase 'patch' doesn't work properly
  // (even tho other lowercase methods work), so we uppercase
  // all methods
  const method = params.method ? params.method.toUpperCase() : params.method

  return {
    [CALL_API]: Object.assign(
      {
        credentials: INCLUDE_COOKIES_IN_API_CALLS || 'same-origin',
      },
      params,
      { method },
    ),
  }
}

import React from 'react'
import classNames from 'classnames'
import { Select as AntSelect } from 'antd'
import { SelectProps as AntSelectProps, SelectValue } from 'antd/lib/select'

import Icon from '../Icon'

import 'antd/lib/select/style/index'
import './Select.scss'

const Option = AntSelect.Option
const OptGroup = AntSelect.OptGroup

export type SelectProps = AntSelectProps & {
  children?: React.ReactNode
  isError?: boolean
  dropdownAlign?: {
    points: string[]
    offset: number[]
    overflow: {
      adjustX: number
      adjustY: number
    }
  }
}

class Select extends React.PureComponent<SelectProps, { searchValue: unknown }> {
  static Option: typeof AntSelect.Option
  static OptGroup: typeof AntSelect.OptGroup

  antSelectRef: AntSelect | null = null

  constructor(props: SelectProps) {
    super(props)

    this.state = {
      searchValue: null,
    }
  }

  // we need this because antd treats empty strings as
  // valid values and doesn't show the placeholder
  // empty arrays are truthy and also should not hide placeholder
  showPlaceholder = () => {
    // don't show placeholder if there is a search value
    if (this.state.searchValue) {
      return null
    }

    if (!this.props.value) {
      return this.props.placeholder
    }

    if (Array.isArray(this.props.value) && this.props.value.length === 0) {
      return this.props.placeholder
    }

    return null
  }

  setSearchValue = (value: unknown) => {
    this.setState({ searchValue: value })
  }

  focus = () => {
    // converting these to any to access a private member rcSelect for `focus` functionality
    // should ideally convert this to something less hacky if we can figure out how
    if (this.antSelectRef && (this.antSelectRef as any).rcSelect) {
      ;(this.antSelectRef as any).rcSelect.setOpenState(true, true)
    }
  }

  render() {
    const props = this.props
    return (
      <AntSelect
        {...props}
        className={classNames(props.className, 'retool-select', {
          'is-error': props.isError,
        })}
        suffixIcon={<Icon type="caret-down" />}
        placeholder={this.showPlaceholder()}
        onSearch={(value) => {
          this.setSearchValue(value)
          if (props.onSearch) props.onSearch(value)
        }}
        ref={(e) => (this.antSelectRef = e)}
        dropdownClassName={classNames(props.dropdownClassName, 'retool-select-dropdown')}
      >
        {props.children}
      </AntSelect>
    )
  }
}

Select.Option = AntSelect.Option
Select.OptGroup = AntSelect.OptGroup

export default Select
export { Option, OptGroup, SelectValue }

import cookies from 'js-cookie'
import { REDIRECTING_TO_BILLING_PORTAL_COOKIE_NAME } from 'store/constants'
import { setEmbedFriendlyCookie } from 'common/utils'

export const maybeRedirectToUrl = () => {
  const loginRedirect = new URLSearchParams(location.search).get('redirectOnLogin')
  if (loginRedirect == null) {
    return
  }

  // If they have a subdomain set that means they have an account created
  // and we can redirect them. If they're authenticated it just works, if they
  // aren't authenticated then they'll be redirected again after login.
  const subdomain = cookies.get('subdomain')
  if (subdomain != null && window.MAIN_DOMAIN !== null) {
    window.location.href = `http://${subdomain}/${loginRedirect}`
    return
  }

  // Otherwise we need to set the redirectURI to redirect them afterwards
  // we also need to turn off the self service on prem CTA, since they
  // don't make a subdomain in that case which will make the redirect not work
  // we need to set both redirectOnlogin and onboardingUrl since redirectOnLogin is used
  // if the person logs in and onboardingUrl is used if they're just signing up

  // Have the cookies expire in 15 minutes to ensure we don't accidently send the user places in perpetuity
  const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000)
  // We want redirects to also work when Retool is embedded in an iFrame
  setEmbedFriendlyCookie('redirectOnLogin', loginRedirect, {
    domain: `.${window.MAIN_DOMAIN}`,
    expires: inFifteenMinutes,
  })
  cookies.set('onboardingUrl', loginRedirect, { domain: `.${window.MAIN_DOMAIN}`, expires: inFifteenMinutes })
  cookies.set(REDIRECTING_TO_BILLING_PORTAL_COOKIE_NAME, 'true', {
    domain: `.${window.MAIN_DOMAIN}`,
    expires: inFifteenMinutes,
  })
}

import { pluginsSelector } from 'store/selectors'
import { RetoolState } from 'store'
import { createSelector } from 'reselect'
import { PluginTemplate } from 'common/records'

/** Get all queries for the current app, not including namespaced queries from modules */
export const queriesSelector = createSelector(pluginsSelector, (plugins) =>
  plugins.filter(({ namespace, type }) => type === 'datasource' && !namespace),
)

/** Get the currently selected or most recently edited query ID */
export const recentQueryIdSelector = (state: RetoolState): string | undefined => {
  const queries = queriesSelector(state)
  const selectedId = state.editor?.selectedDatasourceId

  // selectedDatasourceId isn't always type:datasource (unfortunately),
  // so we need the has() check
  if (selectedId && queries.has(selectedId)) {
    return selectedId
  }

  return queries.maxBy(({ updatedAt }) => updatedAt)?.id
}

/** Get the currently selected or most recently edited query */
export const recentQuerySelector = (state: RetoolState): PluginTemplate | undefined => {
  const id = recentQueryIdSelector(state)
  return id ? queriesSelector(state).get(id) : undefined
}

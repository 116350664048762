import { PerformanceModel, QueryRunStats } from 'common/types/performance'
import { AnyAction } from 'redux'
import datadogReporter from 'DatadogReporter'

const PERF_QUERY_RUN_STATS_REPORTED = '@retool/performance/queryRunStatsReported'

export function performanceReducer(
  state: PerformanceModel = new PerformanceModel(),
  action: AnyAction,
): PerformanceModel {
  switch (action.type) {
    case PERF_QUERY_RUN_STATS_REPORTED: {
      const { queryId, queryRunStats } = action.payload
      return state.setIn(['queryPerformance', queryId], { lastRun: queryRunStats })
    }
  }
  return state
}

interface QueryPerformanceStatsReportAction extends AnyAction {
  payload: {
    queryId: string
    queryRunStats: QueryRunStats
  }
}

export function reportQueryPerformanceStats(
  queryId: string,
  queryRunStats: QueryRunStats,
): QueryPerformanceStatsReportAction {
  return {
    type: PERF_QUERY_RUN_STATS_REPORTED,
    payload: {
      queryId,
      queryRunStats,
    },
  }
}

type QueryMark =
  | 'queryBegin'
  | 'prepareBegin'
  | 'prepareEnd'
  | 'backendBegin'
  | 'backendEnd'
  | 'frontendRunBegin'
  | 'frontendRunEnd'
  | 'handleResponseBegin'
  | 'handleResponseEnd'
  | 'transformerBegin'
  | 'transformerEnd'
  | 'postProcessingBegin'
  | 'postProcessingEnd'
  | 'queryEnd'

type QueryMeasure =
  | 'backendTimeMs'
  | 'dbconnectorTimeMs'
  | 'estimatedResponseSizeBytes'
  | 'frontendRunTimeMs'
  | 'handleResponseTimeMs'
  | 'postProcessingTimeMs'
  | 'prepareQueryTimeMs'
  | 'totalTimeMs'
  | 'transformTimeMs'
export class QueryPerformanceTracer {
  private marks: Map<QueryMark, number>
  private measures: Map<string, number>

  constructor() {
    this.marks = new Map<QueryMark, number>()
    this.measures = new Map<string, number>()
  }

  /** Record timestamp associated with a specific step of query lifecycle */
  public mark(name: QueryMark): void {
    this.marks.set(name, performance.now())
  }

  /** Measure duration between the two marks */
  public measure(name: QueryMeasure, startMark: QueryMark, endMark: QueryMark): void
  /** Manually set the measure (when marks aren't available) */
  public measure(name: QueryMeasure, duration: number): void

  public measure(name: QueryMeasure, startMarkOrDuration: QueryMark | number, endMark?: QueryMark): void {
    if (typeof startMarkOrDuration === 'number') {
      this.measures.set(name, startMarkOrDuration)
      return
    }

    if (endMark) {
      const startTimestamp = this.marks.get(startMarkOrDuration)
      const endTimestamp = this.marks.get(endMark)

      if (startTimestamp && endTimestamp) {
        this.measures.set(name, endTimestamp - startTimestamp)
        return
      }
    }

    this.measures.set(name, 0)
  }

  public getMeasure(name: QueryMeasure): number {
    return this.measures.get(name) ?? 0
  }

  public calculateQueryRunStats(): QueryRunStats {
    this.measure('backendTimeMs', 'backendBegin', 'backendEnd')
    this.measure('frontendRunTimeMs', 'frontendRunBegin', 'frontendRunEnd')
    this.measure('handleResponseTimeMs', 'handleResponseBegin', 'handleResponseEnd')
    this.measure('postProcessingTimeMs', 'postProcessingBegin', 'postProcessingEnd')
    this.measure('prepareQueryTimeMs', 'prepareBegin', 'prepareEnd')
    this.measure('totalTimeMs', 'queryBegin', 'queryEnd')
    this.measure('transformTimeMs', 'transformerBegin', 'transformerEnd')

    return {
      backendTimeMs: this.getMeasure('backendTimeMs'),
      dbconnectorTimeMs: this.getMeasure('dbconnectorTimeMs'),
      estimatedResponseSizeBytes: this.getMeasure('estimatedResponseSizeBytes'),
      frontendRunTimeMs: this.getMeasure('frontendRunTimeMs'),
      handleResponseTimeMs: this.getMeasure('handleResponseTimeMs'),
      postProcessingTimeMs: this.getMeasure('postProcessingTimeMs'),
      prepareQueryTimeMs: this.getMeasure('prepareQueryTimeMs'),
      totalTimeMs: this.getMeasure('totalTimeMs'),
      transformerTimeMs: this.getMeasure('transformTimeMs'),
    }
  }
}

export function reportQueryRunStatsToDatadog(queryRunStats: QueryRunStats, resourceType: string): void {
  const tags = {
    resource_type: resourceType,
  }
  datadogReporter.reportEvent({
    type: 'frontend.performance.query.frontend_prepare_query',
    durationMs: queryRunStats.prepareQueryTimeMs,
    tags,
  })
  datadogReporter.reportEvent({
    type: 'frontend.performance.query.frontend_post_process',
    durationMs: queryRunStats.postProcessingTimeMs,
    tags,
  })
  datadogReporter.reportEvent({
    type: 'frontend.performance.query.total',
    durationMs: queryRunStats.totalTimeMs,
    tags,
  })

  // The metrics below are optional. Some queries don't have frontend execution component, others don't have backend component.
  if (queryRunStats.backendTimeMs > 1) {
    datadogReporter.reportEvent({
      type: 'frontend.performance.query.backend_execute',
      durationMs: queryRunStats.backendTimeMs,
      tags,
    })
  }
  if (queryRunStats.dbconnectorTimeMs > 1) {
    datadogReporter.reportEvent({
      type: 'frontend.performance.query.dbconnector_execute',
      durationMs: queryRunStats.dbconnectorTimeMs,
      tags,
    })
  }
  if (queryRunStats.frontendRunTimeMs > 1) {
    datadogReporter.reportEvent({
      type: 'frontend.performance.query.frontend_execute',
      durationMs: queryRunStats.frontendRunTimeMs,
      tags,
    })
  }
  if (queryRunStats.transformerTimeMs > 1) {
    datadogReporter.reportEvent({
      type: 'frontend.performance.query.frontend_transform_result',
      durationMs: queryRunStats.transformerTimeMs,
      tags,
    })
  }
}

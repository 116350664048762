import confetti from 'canvas-confetti'
import { OnboardingState, OnboardingStep } from 'common/records'
import { ResourceType } from 'common/resourceTypes'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { List } from 'immutable'
import cookies from 'js-cookie'
import { browserHistory } from 'react-router'
import { QUERY_STATE_DATASOURCE_TYPE_CHANGE } from 'routes/Editor/modules/editor'
import { dispatch } from 'store'

import { createPage } from 'store/appModel/pages'
import { RECEIVE_USER_PROFILE } from 'store/constants'
import { ONBOARDING_TUTORIAL_ENABLED_RESOURCE_TYPES } from 'retoolConstants'
import { JSON_HEADERS } from 'networking/util'
import uuidv4 from 'uuid/v4'
import { callApi } from '../callApi'
import welcomeFlow, { GoogleSheetsFlow } from './tutorials/welcomeFlow'
import firebaseFlow from './tutorials/firebaseFlow'
import { finishOnboarding } from 'store/onboarding/onboardingMiddleware'
import {
  COLLAPSE_ONBOARDING_CHECKLIST,
  EXPAND_ONBOARDING_CHECKLIST,
  FAILURE_DISMISS_CHECKLIST,
  FAILURE_SUBMIT_APP_BEING_BUILT,
  ONBOARDING_ADVANCE_TUTORIAL,
  ONBOARDING_FINISH,
  ONBOARDING_GO_TO_END,
  ONBOARDING_GO_TO_NEXT_STEP,
  ONBOARDING_GO_TO_NEXT_SUB_STEP,
  ONBOARDING_GO_TO_PREV_STEP,
  ONBOARDING_GO_TO_PREV_SUB_STEP,
  ONBOARDING_MODAL_REPOSITION,
  ONBOARDING_REVIVE_TUTORAIL,
  ONBOARDING_REWIND_TUTORIAL,
  ONBOARDING_SKIP_NO_NEXT_STEPS,
  ONBOARDING_START,
  RECEIVE_DISMISS_CHECKLIST,
  RECEIVE_SUBMIT_APP_BEING_BUILT,
  REQUEST_DISMISS_CHECKLIST,
  REQUEST_SUBMIT_APP_BEING_BUILT,
  FIRST_CREATED_RESOURCE_TYPE_TUTORIAL,
} from 'store/onboarding/constants'
import { onboardingSelector } from './selectors'

const ONBOARDING_CLOSE_MODAL = 'ONBOARDING_CLOSE_MODAL'
const ONBOARDING_SOURCE_URL_PARAM = 'onboardingSourcePage'

export const ONBOARDING_RESOURCE_COOKIE = 'showOnboardingModal'

// ------------------------------------
// Actions
// ------------------------------------

export function setFirstCreatedResourceTypeTutorial(resourceType: ResourceType) {
  dispatch({
    type: FIRST_CREATED_RESOURCE_TYPE_TUTORIAL,
    payload: {
      resourceType,
    },
  })
}

export function repositionModal(left: number, top: number) {
  return {
    type: ONBOARDING_MODAL_REPOSITION,
    payload: { top, left },
  }
}

export function goToNextStep() {
  return {
    type: ONBOARDING_GO_TO_NEXT_STEP,
  }
}

export function goToEnd() {
  return {
    type: ONBOARDING_GO_TO_END,
  }
}

export function skipAndNoNextSteps() {
  return {
    type: ONBOARDING_SKIP_NO_NEXT_STEPS,
  }
}

export function advanceTutorial() {
  return dispatch((dispatch, getState) => {
    const onboardingState = onboardingSelector(getState())
    //If the next step is the placeholder final step, then we're done!
    if (onboardingState.currentStep + 1 === onboardingState.steps.size - 1) {
      confetti()
      return finishOnboarding({ doNotNavigate: true })
    } else {
      return dispatch({
        type: ONBOARDING_ADVANCE_TUTORIAL,
      })
    }
  })
}

export function rewindTutorial() {
  return {
    type: ONBOARDING_REWIND_TUTORIAL,
  }
}

export function reviveTutorial() {
  return {
    type: ONBOARDING_REVIVE_TUTORAIL,
  }
}

export function setOnboardingModalClosed() {
  return {
    type: ONBOARDING_CLOSE_MODAL,
  }
}

export function goToPrevSubStep() {
  return {
    type: ONBOARDING_GO_TO_PREV_SUB_STEP,
  }
}

export function goToNextSubStep() {
  return {
    type: ONBOARDING_GO_TO_NEXT_SUB_STEP,
  }
}

export function goToPreviousStep() {
  return {
    type: ONBOARDING_GO_TO_PREV_STEP,
  }
}

export function collapseOnboardingChecklist() {
  localStorage.checklistCollapsed = true
  return { type: COLLAPSE_ONBOARDING_CHECKLIST }
}

const getOnboardingPage = function getOnboardingPageFromLocationPathForOnboardingTutorial() {
  const paths = location.pathname.split('/')
  return paths[paths.length - 1]
}

export function showOnboarding() {
  return async (dispatch: any) => {
    const newOnboardingPageName = `Onboarding Page - ${uuidv4().replace('-', '').slice(0, 4)}`
    await dispatch(createPage(newOnboardingPageName))
    const currentPageEncoded = encodeURIComponent(getOnboardingPage())
    browserHistory.push(
      `/editor/${encodeURIComponent(newOnboardingPageName)}?${ONBOARDING_SOURCE_URL_PARAM}=${currentPageEncoded}`,
    )

    dispatch({
      type: ONBOARDING_START,
      payload: {
        alreadyOptedIn: true,
      },
    })
  }
}

export function dismissChecklist() {
  retoolAnalyticsTrack('Onboarding Checklist Dismissed', {})
  return async (dispatch: any) => {
    await dispatch(
      callApi({
        endpoint: '/api/organization/dismissChecklist',
        method: 'POST',
        headers: JSON_HEADERS,
        types: [REQUEST_DISMISS_CHECKLIST, RECEIVE_DISMISS_CHECKLIST, FAILURE_DISMISS_CHECKLIST],
      }),
    )
  }
}

export function submitAppBeingBuilt(appBeingBuilt: string) {
  retoolAnalyticsTrack('Onboarding Checklist App Description Submitted', { appBeingBuilt })
  return async (dispatch: any) => {
    await dispatch(
      callApi({
        endpoint: '/api/organization/submitAppBeingBuilt',
        method: 'POST',
        body: JSON.stringify({ appBeingBuilt }),
        headers: JSON_HEADERS,
        types: [REQUEST_SUBMIT_APP_BEING_BUILT, RECEIVE_SUBMIT_APP_BEING_BUILT, FAILURE_SUBMIT_APP_BEING_BUILT],
      }),
    )
  }
}

const handleFirstCreatedResourceTypeTutorial: ActionHandler = (state, action) => {
  const { resourceType } = action.payload
  setTimeout(() => {
    retoolAnalyticsTrack('Resource Type Tutorial Set', {
      resourceType,
    })
  }, 300)
  // Introducing a little bit of tech debt for firebase specific tutorial.
  // Upon expanding to other resource type tutorials, refactor to set the corresponding tutorial.
  if (ONBOARDING_TUTORIAL_ENABLED_RESOURCE_TYPES.includes(resourceType) && resourceType === 'firebase') {
    return state.set('steps', firebaseFlow)
  } else {
    return state
  }
}

const handleOnboardingModalReposition: ActionHandler = (state, action) => {
  const { left, top } = action.payload
  return state.updateModalPosition(left, top)
}

const handleOnboardingGoToNextStep: ActionHandler = (state: OnboardingState) => {
  const nextStep = state.steps.get(state.currentStep + 1)
  if (nextStep) {
    setTimeout(() => {
      retoolAnalyticsTrack('Onboarding Progressed', {
        stepNumber: state.currentStep + 1,
        stepTitle: nextStep.title,
      })
    }, 300)
  }

  return state.setStep(state.currentStep + 1)
}

const handleOnboardingGoToPrevStep: ActionHandler = (state) => {
  return state.setStep(state.currentStep - 1)
}

const handleOnboardingGoToEnd: ActionHandler = (state) => {
  return state.setStep(state.steps.size - 1).setSkipped(true)
}

//Does not bring the user to the end
const handleOnboardingSkipAndNoNextSteps: ActionHandler = (state) => {
  return state.setSkipped(true)
}

const handleOnboardingGoToNextSubStep: ActionHandler = (state, action) => {
  const currentStep = state.currentOnboardingStep

  //If the step doesn't have a condition the substep advancing could cause the step to advance
  if (currentStep && currentStep.condition == null) {
    return handleOnboardingAdvanceTutorial(state, action)
  }
  return state.setSubStep(state.currentSubStep + 1)
}

const handleOnboardingGoToPrevSubStep: ActionHandler = (state) => {
  return state.setSubStep(state.currentSubStep - 1)
}

const handleOnboardingStart: ActionHandler = (state, action) => {
  setTimeout(() => {
    retoolAnalyticsTrack('Onboarding Started', {
      trigger: 'manual',
    })
  }, 300)

  //Skip the modal asking if the user wants to start onboarding if they've already said they want to
  if (action?.payload?.alreadyOptedIn) {
    return state.set('showOnboarding', true).setStep(1).setSkipped(false)
  } else {
    return state.set('showOnboarding', true).setStep(0).setSkipped(false)
  }
}

const handleOnboardingFinish: ActionHandler = (state) => {
  if (!state.steps.get(state.currentStep + 1) && !state.skipped) {
    setTimeout(() => {
      retoolAnalyticsTrack('Onboarding Completed', {})
    }, 300)
  } else {
    setTimeout(() => {
      retoolAnalyticsTrack('Onboarding Skipped', { stepsLeft: state.steps.size - state.currentStep + 1 })
    }, 300)
  }
  return state.set('showOnboarding', false).setSkipped(false)
}

const handleOnboardingRevive: ActionHandler = (state) => {
  if (!state.steps.get(state.currentStep + 1) && !state.skipped) {
    retoolAnalyticsTrack('Onboarding Completed', {})
  } else {
    retoolAnalyticsTrack('Onboarding Skipped', { stepsLeft: state.steps.size - state.currentStep + 1 })
  }
  const lastStep = state.steps.get(state.steps.size - 2) as OnboardingStep
  const lastSubStepNumber = lastStep.substepList.size
  return (
    state
      .set('showOnboarding', true)
      .set('showOnboardingModal', false)
      .setSubStep(lastSubStepNumber)
      //Minus two since the last step is the "finished" modal
      .setStep(state.steps.size - 2)
  )
}

const handleCloseOnboardingModal: ActionHandler = (state) => {
  return state.set('showOnboardingModal', false)
}

const handleOnboardingAdvanceTutorial: ActionHandler = (state) => {
  if (state.substepProgress < 1) {
    return state.setSubStep(state.currentSubStep + 1)
  } else {
    return state.setStep(state.currentStep + 1)
  }
}

const handleOnboardingRewindTutorial: ActionHandler = (state) => {
  if (state.currentSubStep === 0 && state.currentStep > 0) {
    const nextStepIndex = state.currentStep - 1
    const nextStepSubSteps = state.steps.get(nextStepIndex)?.substeps
    const nextSubStepIndex = List.isList(nextStepSubSteps) ? nextStepSubSteps.size - 1 : 0
    return state.setStep(nextStepIndex).setSubStep(nextSubStepIndex)
  } else if (state.currentSubStep > 0) {
    return state.setSubStep(state.currentSubStep - 1)
  } else {
    return state
  }
}

type ActionHandler = (state: OnboardingState, action: { type: string; payload: any }) => OnboardingState
type ActionHandlerMap = { [actionType: string]: ActionHandler }
const ACTION_HANDLERS: ActionHandlerMap = {
  [ONBOARDING_MODAL_REPOSITION]: handleOnboardingModalReposition,
  [ONBOARDING_GO_TO_NEXT_STEP]: handleOnboardingGoToNextStep,
  [ONBOARDING_GO_TO_END]: handleOnboardingGoToEnd,
  [ONBOARDING_CLOSE_MODAL]: handleCloseOnboardingModal,
  [ONBOARDING_ADVANCE_TUTORIAL]: handleOnboardingAdvanceTutorial,
  [ONBOARDING_REWIND_TUTORIAL]: handleOnboardingRewindTutorial,
  [ONBOARDING_REVIVE_TUTORAIL]: handleOnboardingRevive,
  [ONBOARDING_GO_TO_NEXT_SUB_STEP]: handleOnboardingGoToNextSubStep,
  [ONBOARDING_GO_TO_PREV_SUB_STEP]: handleOnboardingGoToPrevSubStep,
  [ONBOARDING_GO_TO_PREV_STEP]: handleOnboardingGoToPrevStep,
  [ONBOARDING_SKIP_NO_NEXT_STEPS]: handleOnboardingSkipAndNoNextSteps,
  [ONBOARDING_START]: handleOnboardingStart,
  [ONBOARDING_FINISH]: handleOnboardingFinish,
  [FIRST_CREATED_RESOURCE_TYPE_TUTORIAL]: handleFirstCreatedResourceTypeTutorial,
  // For the "write your first query" onboarding checklist step:
  // hides the OnboardingPulse + checklist once the user picks a resource
  // to give them room to write the query
  [QUERY_STATE_DATASOURCE_TYPE_CHANGE]: (state) =>
    state
      .set('onboardingChecklistPulseState', {
        ...state.onboardingChecklistPulseState,
        resourceSelected: true,
      })
      .set('isOnboardingChecklistCollapsed', true),
  [COLLAPSE_ONBOARDING_CHECKLIST]: (state) => state.set('isOnboardingChecklistCollapsed', true),
  [EXPAND_ONBOARDING_CHECKLIST]: (state) => state.set('isOnboardingChecklistCollapsed', false),
  [RECEIVE_DISMISS_CHECKLIST]: (state) => state.set('onboardingChecklist', null),
  [RECEIVE_USER_PROFILE]: (state, action) => {
    if (
      !!cookies.get('onboardingUrl') &&
      decodeURIComponent(window.location.pathname).indexOf('Onboarding Page') !== -1
    ) {
      setTimeout(() => {
        retoolAnalyticsTrack(
          'Onboarding Started',
          {
            trigger: 'auto',
          },
          action.payload.user.sid,
        )
      }, 300)
    }
    return state.set('onboardingChecklist', action.payload.org.onboardingChecklist)
  },
}

const marketingRef = cookies.get('marketing_referrer')

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = new OnboardingState()
  .set('steps', marketingRef === 'google-sheets-post' ? GoogleSheetsFlow : welcomeFlow)
  .set('showOnboarding', !!cookies.get('onboardingUrl'))
  .set('showOnboardingModal', !!cookies.get(ONBOARDING_RESOURCE_COOKIE))
  .set('isOnboardingChecklistCollapsed', localStorage.checklistCollapsed === 'true')

export default function onboardingReducer(state = initialState, action: any) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

import React from 'react'
import { Input } from 'antd'
import classNames from 'classnames'
import { InputProps as AntInputProps } from 'antd/lib/input/Input'
import { TextAreaProps as AntTextAreaProps } from 'antd/lib/input/TextArea'

import Icon, { IconType } from '../Icon'

import './TextInput.scss'

type TextAreaProps = AntTextAreaProps
const TextArea = (props: TextAreaProps) => {
  return (
    <div className="retool-textarea">
      <Input.TextArea {...props} className={props.className} />
    </div>
  )
}

export type TextInputProps = AntInputProps & {
  icon?: IconType | undefined
  isCode?: boolean
  isError?: boolean
}

const TextInput = (props: TextInputProps) => (
  <div className="retool-textinput">
    {props.icon && <Icon type={props.icon} />}
    <Input
      {...props}
      style={props.style}
      className={classNames('fs-12', props.className, {
        code: props.isCode,
        'sans-serif': !props.isCode,
        'is-error': props.isError,
        'has-icon': !!props.icon,
      })}
    />
  </div>
)

TextInput.TextArea = TextArea

export default TextInput

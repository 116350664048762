import React, { Component, SyntheticEvent } from 'react'

import { Form, Input } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { Button } from 'components/design-system'
import { browserHistory } from 'react-router'
import cookies from 'js-cookie'
import { REDIRECTING_TO_BILLING_PORTAL_COOKIE_NAME } from 'store/constants'

const FormItem = Form.Item

interface LoginFormProps extends FormComponentProps {
  login: any // <-- improve type after refactoring `login.js`
  redirectUri: string
}

interface LoginFormState {
  email: string
  password: string
}

interface FormValidationErrorType {
  email?: {
    errors: Array<{
      field: string
      message: string
    }>
  }
  password?: {
    errors: Array<{
      field: string
      message: string
    }>
  }
}
class LoginForm extends Component<LoginFormProps, LoginFormState> {
  constructor(props: LoginFormProps) {
    super(props)
    this.state = {
      email: '',
      password: '',
    }
  }

  componentDidMount() {
    /**
     * If the browser autofills the input fields, this will make sure our component
     * state knows about it.
     */
    const emailInput = document.getElementById('email') as HTMLInputElement
    const passwordInput = document.getElementById('password') as HTMLInputElement
    this.setState({
      email: emailInput.value,
      password: passwordInput.value,
    })
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value,
    })
  }

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: event.target.value,
    })
  }

  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault()
    const { form, login, redirectUri } = this.props
    const { email, password } = this.state

    form.validateFields((err: FormValidationErrorType) => {
      if (err) {
        return
      }
      login({ email, password }, redirectUri)
    })
  }

  render() {
    const { form } = this.props

    const formItemLayout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    }
    const { getFieldDecorator } = form
    const redirectingToBilling = cookies.get(REDIRECTING_TO_BILLING_PORTAL_COOKIE_NAME) != null
    const signInLabel = redirectingToBilling ? 'Sign in to Retool cloud' : 'Sign in'

    return (
      <Form className="login-form" layout="vertical" onSubmit={this.handleSubmit}>
        <FormItem {...formItemLayout} label="Email">
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: 'Please enter a valid email',
              },
              {
                required: true,
                message: 'Email required',
              },
            ],
          })(<Input placeholder="Email" size="large" onChange={this.handleEmailChange} />)}
        </FormItem>
        <FormItem
          {...formItemLayout}
          label={
            <div className="flex justify-between">
              <label title="Password">Password</label>
              <a className="fw-400" onClick={() => browserHistory.push('/auth/resetPassword')}>
                Forgot password?
              </a>
            </div>
          }
        >
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Password required' }],
          })(<Input placeholder="••••••••" type="password" size="large" onChange={this.handlePasswordChange} />)}
        </FormItem>
        <div className="auth-button-row">
          <Button type="primary" htmlType="submit" className="auth-button" block>
            {signInLabel}
          </Button>
        </div>
      </Form>
    )
  }
}

const LoginFormAsAntdForm = Form.create<LoginFormProps>()(LoginForm)

export default LoginFormAsAntdForm

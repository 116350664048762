import { generateResourceForm } from 'components/ResourceForm/resourceFormGenerator/generator'
import { AuthTemplate } from 'components/ResourceForm/resourceFormGenerator/generator-types'
import React from 'react'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'

const INTEGRATION_LABEL = 'Datadog'
const SPEC_URL = `${RETOOL_SPECS_DOMAIN}/datadog/v1/3.0.0/1.0.json`
const initialTemplate = {
  options: {
    customQueryParameters: [['', '']],
    customHeaders: [
      ['DD-API-KEY', 'API_KEY'],
      ['DD-APPLICATION-KEY', 'APPLICATION_KEY'],
    ],
    api_key: '',
    application_key: '',
    spec: SPEC_URL,
  },
}

const auth: AuthTemplate = [
  {
    type: 'custom',
    fields: [
      {
        type: 'token',
        fieldName: 'api_key',
        required: true,
        label: 'API Key',
        description: (
          <>
            Enter your secret Datadog API key above. To retrieve or create a API key visit the{' '}
            <a rel="noopener noreferrer" target="_blank" href="https://app.datadoghq.com/account/settings#api">
              API keys
            </a>{' '}
            section of your Datadog account.
          </>
        ),
      },
      {
        type: 'token',
        fieldName: 'application_key',
        required: true,
        label: 'Application Key',
        description: (
          <>
            Enter your secret Datadog application key above. To retrieve or create a API key visit the{' '}
            <a rel="noopener noreferrer" target="_blank" href="https://app.datadoghq.com/access/application-keys">
              application keys
            </a>{' '}
            section of your Datadog account.
          </>
        ),
      },
    ],
  },
]

const template = {
  auth,
  hideTestConnection: () => true,
}

export default generateResourceForm(INTEGRATION_LABEL, initialTemplate, template)

import { message } from 'antd'
import {
  AppModelRecord,
  AppModelType,
  AppModelValues,
  AppTemplate,
  IDependencyGraph,
  ModelChangeset,
  PluginNamespaceInfo,
  PluginTemplate,
  PluginTemplateParams,
} from 'common/records'
import { ANY_TEMPLATE_REGEX } from 'common/regexes'
import { SafeAny, UnknownObject } from 'common/types'
import { ds, partitionBy, primitiveTypeToAnalyticsType, Selector, SelectorString, ss } from 'common/utils'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { addNamespace } from 'common/utils/namespaces'
import { onTemplateUpdateResolver, PluginPropertyAnnotationsResolver } from 'components/plugins'
import { QueryTriggerOptions, TriggerQuery } from 'store/appModel/modelTypes'
import { runInstrument } from 'components/plugins/Instrument'
import Immutable from 'immutable'
import ls from 'local-storage'
import _, { sortBy } from 'lodash'
import queryString from 'query-string'
import { RetoolActionDispatcher, RetoolDispatch, RetoolState } from 'store'
import { RECEIVE_USER_PROFILE } from 'store/constants'
import {
  appModelSelector,
  appTemplateSelector,
  currentlyRunningTestIdSelector,
  largeScreenSelector,
  pluginsSelector,
} from 'store/selectors'
import { TEARDOWN_PAGE } from './actionTypes'
import { DATASOURCE_TYPE_CHANGE, PLUGIN_DELETE, PLUGIN_UPDATE_ID, RESET_TEMPLATE } from './constants'
import { onEnterprisePlanSelector } from 'store/selectors/billingSelectors'
import { renderTemplateString } from './renderTemplateString'
import { renderAppTemplate } from './renderAppTemplate'
import { getViewportData } from './getViewportData'

_.templateSettings.interpolate = ANY_TEMPLATE_REGEX // TODO remove?

// These are module-level variables that are here for historical performance reasons
// TODO: these variables make it harder to test this code. Remove them.
let currentModel: AppModelType | null = null
let deferredChangesets: ModelChangeset[] = []

export function stageModel(model: AppModelType): void {
  currentModel = model
}

// TODO: remove this completely
function getCurrentModel(getState: () => RetoolState) {
  return currentModel ?? getState().appModel
}

function isChangesetDeferrable(state: AppModelType, changeset: ModelChangeset) {
  // If no dependencyGraph, don't defer
  if (!state.dependencyGraph) {
    return false
  }

  // If the selector refers to a query's requestSentTimestamp` we should defer the evaluation of the
  // node since it doesn't exist in the dependency graph and it's also not actually useful
  if (changeset.selector.length === 2 && changeset.selector[1] === 'requestSentTimestamp') {
    return true
  }

  // If no nodes depend on the selector, it is deferrable
  const dependants = state.dependencyGraph.getDependantsOf(changeset.selector)

  // since a selector like ['query', 'isFetching'] will have ['query'] as a dependant,
  // we need to filter dependants that are not just a root node
  if (
    dependants.filter((d) => d.selector.length > 1).size === 0 &&
    // TODO: this is buggy with JS queries, so we limit it to with `isFetching` only.
    // if i have a JS query, `myJsQuery` which is triggered on success after `myRestQuery`,
    // referencing `myRestQuery.data` in `myJsQuery` might be stale. Can repro using this app:
    // https://gist.github.com/ymmn/c52e639bb0240f2f1689952d6be8d3c6
    changeset.selector.length === 2 &&
    changeset.selector[1] === 'isFetching'
  ) {
    return true
  }

  // Otherwise, it is not deferrable
  return false
}

function deferChangesets(changesets: ModelChangeset[]) {
  return (dispatch: RetoolDispatch) => {
    deferredChangesets = deferredChangesets.concat(changesets)
    dispatch(queueFlushDeferredChangesets)
  }
}

export const flushDeferredChangesets = () => {
  return async (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const currentModel = getCurrentModel(getState)
    const stack = currentModel.executionCallStacks.createStack([])
    // copy the changesets to be flushed
    const deferredChangeSetsCopy = deferredChangesets.slice(0)

    // clear the changesets before awaiting so that if new
    // changesets are deferred as we propogate the current
    // set we don't lose any
    deferredChangesets = []

    // now we await propogating the copied changesets
    await dispatch(applyAndPropogateChangesets(currentModel, deferredChangeSetsCopy, stack.id, false))
  }
}

const queueFlushDeferredChangesets = _.debounce(flushDeferredChangesets(), 100)

// ------------------------------------
// Constants
// ------------------------------------
export const MODEL_UPDATE = 'MODEL_UPDATE'
export const INITIALIZE_MODEL = 'INITIALIZE_MODEL'
export const SET_ENVIRONMENT = 'SET_ENVIRONMENT'
export const ENQUEUE_USER_TRIGGERED_QUERY_RUN = 'ENQUEUE_USER_TRIGGERED_QUERY_RUN'
export const DEQUEUE_USER_TRIGGERED_QUERY_RUN = 'DEQUEUE_USER_TRIGGERED_QUERY_RUN'
export const TERMINAL_LOG_UPDATE = 'TERMINAL_LOG_UPDATE'
const PLUGIN_TYPE_LOADED = 'PLUGIN_TYPE_LOADED'

export function startModelLifeCycle(appTemplate: AppTemplate, stackId: number, origPageUuid: string) {
  return (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    // Hack so that onTemplateUpdate can dispatch actions.
    setTimeout(() => {
      const curPageUuid = getState().pages.get('pageUuid')
      if (curPageUuid === origPageUuid) {
        dispatch(
          batchExecOnTemplateUpdates(
            appTemplate.plugins
              .toList()
              .toJS()
              .map((plugin) => {
                return { id: plugin.id, type: plugin.subtype }
              }),
            { userTriggered: false, pageLoad: true, stackId },
          ),
        )
      }
    }, 1)
  }
}

// ------------------------------------
// Actions
// ------------------------------------

export function initializeFromTemplate(appTemplate: AppTemplate): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    const state = getCurrentModel(getState)
    const { model, currentStackId, pageUuid } = await renderAppTemplate(state, appTemplate, getCurrentModel, getState)

    retoolAnalyticsTrack('Info Logged', {
      type: 'Initialized App Template',
      location: 'model.ts-initializeFromTemplate',
    })

    dispatch({
      type: INITIALIZE_MODEL,
      payload: model,
    })

    dispatch(startModelLifeCycle(appTemplate, currentStackId, pageUuid))
  }
}

export function regenerateModelAndDependencyGraph(): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    const state = getCurrentModel(getState)
    const appTemplate = appTemplateSelector(getState())
    const { model, currentStackId, pageUuid } = await renderAppTemplate(state, appTemplate, getCurrentModel, getState)

    dispatch({
      type: INITIALIZE_MODEL,
      payload: model,
    })

    dispatch(startModelLifeCycle(appTemplate, currentStackId, pageUuid))
  }
}

export function commitModel() {
  return (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const currentModel = getCurrentModel(getState)
    dispatch({ type: MODEL_UPDATE, payload: currentModel })
    stageModel(null!)
  }
}

export function enqueueQueryRun(queryId: string, options?: QueryTriggerOptions) {
  return {
    type: ENQUEUE_USER_TRIGGERED_QUERY_RUN,
    payload: { queryId, options },
  }
}

export function dequeueQueryRun(queryId: string) {
  return {
    type: DEQUEUE_USER_TRIGGERED_QUERY_RUN,
    payload: { queryId },
  }
}

export const triggerModelResetTo = (newModelValue: AppModelType) => {
  return (dispatch: RetoolDispatch): void => {
    stageModel(newModelValue)
    dispatch(commitModel())
  }
}

/** triggerModelUpdate vs modelUpdate
 * This is a function that should be used most of the time over the corresponding modelUpdate function.
 *
 * The key difference is that this function creates a new CallStack, while the modelUpdate function expects
 * you to pass an existing stackId to associate the selector to.
 *
 * Example where you should use triggerModelUpdate:
 * - When you are changing data in an event handler to a user-triggered action, since in this scenario the change is an
 *   initiator of change.
 *
 * Example when you should use modelUpdate:
 * - When you are updating the data property of a query, since in this scenario the trigger to this change was a user
 *   action that was triggered upstream.
 */

export function triggerModelUpdate(changesets: ModelChangeset[], userTriggered = false): RetoolActionDispatcher {
  return async (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const currentModel = getCurrentModel(getState)
    const stack = currentModel.executionCallStacks.createStack(changesets)
    return dispatch(modelUpdate(changesets, stack.id, userTriggered))
  }
}

export function modelUpdate(
  changesets: ModelChangeset[],
  stackId: number,
  userTriggered = false,
): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    const currentModel = getCurrentModel(getState)

    // The deferring logic is used to minimize the number of times we trigger a React render loop which are expensive.
    // Specifically, one common case is when one model update triggers 10+ queries to run. Previously, each query
    // would run and fire a modelUpdate for it's `.isFetching` property, which would require
    // O(number of queries triggered) React render loops.
    //
    // By deferring and triggering them all at the same time, we only need to do O(1) React re-renders.
    if (userTriggered) {
      // If the model update was triggered by user, don't try to defer the changes because that sounds like it could
      // lead to potentially unexpected behaviors.
      await dispatch(applyAndPropogateChangesets(currentModel, changesets, stackId, userTriggered))
    } else {
      // Otherwise, batch and defer the changesets that have no downstream dependencies
      const [deferrableChangesets, undeferrableChangesets] = partitionBy(changesets, (changeset) => {
        return isChangesetDeferrable(currentModel, changeset)
      })

      if (deferrableChangesets.length > 0) {
        dispatch(deferChangesets(deferrableChangesets))
      }
      if (undeferrableChangesets.length > 0) {
        await dispatch(applyAndPropogateChangesets(currentModel, undeferrableChangesets, stackId, userTriggered))
      }
    }
  }
}

function markSelectorsClean(selectors: Selector[], _userTriggered = false) {
  return async (dispatch: any, getState: () => RetoolState) => {
    let state = getState().appModel
    const actuallyDirtySelectors = Immutable.Set(selectors.filter((selector) => state.isDirty(ss(selector))))

    for (const selector of actuallyDirtySelectors) {
      state = state.markAsClean(ss(selector))
    }

    stageModel(state)
    dispatch(commitModel())

    // Below is code that would cause downstream dependencies to be calculated after the node is marked clean.
    // This behavior most closely what resembles Retool performed in the past, and the most "correct" behavior.
    // The most "correct" behavior is for this node to have *never* been marked as dirty, and the dependants
    // would not be evaluated.

    /*
    if (!state.dependencyGraph) {
      // no dep graph because of page load / reload
      return
    }

    const dependants = actuallyDirtySelectors
      .map(selector => state.dependencyGraph.getDependantsOf(selector).map(a => a.selector.join('.')))
      .reduce((dependants, subDependants) => dependants.union(subDependants), Immutable.Set<string>())

    const sourceTemplates = actuallyDirtySelectors.map(selector => selector[0]).toSet()

    const dependantTemplates = dependants.map(selector => ds(selector)[0]).filter(id => !sourceTemplates.has(id))

    await propagateChanges(state, dependants)
    commitModel()

    let updatedParams = {}
    for (let selector of actuallyDirtySelectors) {
      updatedParams[ss(selector)] = true
    }

    setTimeout(() => {
      dependantTemplates.map(pluginId => {
        const onTemplateUpdate = onTemplateUpdateResolver(state.getIn(['values', pluginId, 'pluginType']))
        onTemplateUpdate(pluginId, userTriggered, undefined, undefined, { updatedParams })
      })
    }, 1)
    */
  }
}

type RecalculateTemplateOptions = {
  additionalSelectorsToRender?: Immutable.Set<string[]>
  selectorsToDelete?: Selector[]
}

export type TemplateUpdate = UnknownObject | Immutable.Map<string, unknown>

/**
 * Function used when users modify the properties of a component in the Retool Editor
 *
 * DO NOT USE this for updating Retool app state. Because this modifies the dependency graph,
 * Therefore This should never be run in presentation mode.
 *
 * Use triggerModelUpdate or modelUpdate instead
 */
export function recalculateTemplate(
  widgetId: string,
  plugin: PluginTemplate | null,
  update: TemplateUpdate,
  resetModel = false,
  options?: RecalculateTemplateOptions,
): RetoolActionDispatcher {
  return batchRecalculateTemplate([{ widgetId, plugin, update }], resetModel, options)
}

export function batchRecalculateTemplate(
  updates: {
    widgetId: string
    plugin: PluginTemplate | null
    update: TemplateUpdate
  }[],
  resetModel = false,
  options?: RecalculateTemplateOptions,
): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    let currentModel = getCurrentModel(getState)
    if (!currentModel.dependencyGraph) {
      // Page has not been initialized - shortcircuit.
      return
    }

    let selectors: Immutable.Set<Selector> = Immutable.Set()
    const changedValues: SafeAny = {}

    const updateMaps = updates.reduce((acc, { widgetId, update }) => {
      acc[widgetId] = Immutable.fromJS(update) as Immutable.Map<string, unknown>
      return acc
    }, {} as { [widgetId: string]: Immutable.Map<string, unknown> })

    updates.forEach(({ widgetId, plugin }) => {
      const updateMap = updateMaps[widgetId]

      if (plugin) {
        currentModel.dependencyGraph.updatePlugin(plugin, updateMap)

        const propertyAnnotations = PluginPropertyAnnotationsResolver(plugin.subtype)

        currentModel = currentModel.withMutations((model) => {
          updateMap.forEach((_, key) => {
            if (propertyAnnotations[key]?.resetValueOnTemplateUpdate) {
              model.deleteValue([widgetId, key])
            }
          })
        })
      } else if (resetModel) {
        currentModel.dependencyGraph.deletePlugin(widgetId)
        currentModel.dependencyGraph.updateObject(ds(widgetId), updateMap)
        currentModel = currentModel.deleteValue([widgetId])
      } else {
        currentModel.dependencyGraph.updateObject(ds(widgetId), updateMap)
      }

      stageModel(currentModel)
    })

    if (options?.selectorsToDelete) {
      // Delete the selectors from the dependency graph.
      currentModel.dependencyGraph.deleteObjects(options?.selectorsToDelete)
      // Delete the selectors from the model.
      currentModel = currentModel.withMutations((model) => {
        options?.selectorsToDelete?.forEach((selector) => {
          model.deleteValue(selector)
        })
      })
      stageModel(currentModel)
    }

    currentModel.dependencyGraph.applyTemplateStringOverrides(getState().appTemplate.present)

    updates.forEach(({ widgetId, update }) => {
      const updateMap = updateMaps[widgetId]
      const potentialSelectors = getSelectorsFromUpdate([widgetId], updateMap)
      const validSelectors = Immutable.Set(
        currentModel.dependencyGraph.getObjectSelectors(widgetId).map((s: any) => ss(s)),
      )
      selectors = selectors.union(potentialSelectors.filter((s: string[]) => validSelectors.has(ss(s))))

      const tempUpdate = Immutable.isMap(update) ? update.toJS() : update
      for (const key of Object.keys(tempUpdate)) {
        changedValues[`${widgetId}.${key}`] = true
      }
    })

    if (options?.additionalSelectorsToRender) {
      selectors = selectors.union(options?.additionalSelectorsToRender)
    }

    const stack = currentModel.executionCallStacks.createStack([])
    let newModel = currentModel
    for (const selector of selectors) {
      newModel = await renderTemplateString(
        selector,
        newModel.dependencyGraph.lookupTemplateString(selector),
        stack.id,
        {
          stageModelAfterEvaluation: true,
          namespace: newModel.dependencyGraph.lookupNamespace(selector),
          stageModel,
          getCurrentModel,
          getState,
        },
      )
    }
    dispatch(commitModel())

    // The renderTemplateString has a side effect of updating the stack with
    // a list of changedSelectors.
    //
    // We can use this list to figure out what other selectors we need to update
    const dependants = stack.changedSelectors
      .map((selector) => {
        return newModel.dependencyGraph.getDependantsOf(selector).map((node) => node.selector)
      })
      .reduce((dependants, subDependants) => dependants.union(subDependants), Immutable.Set<Selector>())

    // Figure out the list of downstream plugins that are affected, so we can call the
    // "onTemplateUpdate" function as necessary
    const dependantTemplates = dependants.map((selector) => selector[0]).union(updates.map((u) => u.widgetId))
    setTimeout(() => {
      try {
        dispatch(
          batchExecOnTemplateUpdates(
            dependantTemplates.toJS().map((id: string) => {
              return { id, type: newModel.values.getPlugin(id).get('pluginType') }
            }),
            { stackId: stack.id },
          ),
        )
      } catch (err) {
        // Silently swallow
      }
    }, 50)

    // Propagate the changes we made in recalculating the template
    await dispatch(
      serialPropagateChanges(
        newModel,
        dependants.map((selector) => ss(selector)),
        stack.id,
      ),
    )
    dispatch(commitModel())

    dispatch(runInstrumentations(changedValues))
  }
}

function runInstrumentations(changedValues: SafeAny) {
  return (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    setTimeout(() => {
      if (onEnterprisePlanSelector(getState())) {
        dispatch(async () => {
          const instrumentation = appTemplateSelector(getState())
            .plugins.filter((plugin) => plugin.get('type') === 'instrumentation')
            .values()

          for (const instrumentPlugin of instrumentation) {
            runInstrument(instrumentPlugin, changedValues)
          }
        })
      }
    }, 1)
  }
}

/**
 * Add a batch of plugins to the dependency graph.
 * Why do we need this? Plugin might have dependencies between them and we need them
 * all added to the dependency graph before we can compute their models and create edges
 */
export function batchAddNewTemplate(templates: PluginTemplate[]): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    const currentModel = getCurrentModel(getState)
    if (!currentModel.dependencyGraph) {
      return
    }

    templates.forEach((template) => {
      currentModel.dependencyGraph.addPlugin(template, template.template)
    })
  }
}

/**
 * Update a plugin - this is primarily used to set a namespace on a specific set of properties
 * in the dependency graph (like GlobalWidgetProps which need to have a namespace in the dependency graph to have access to its values)
 */
export function updateTemplate(
  template: PluginTemplate,
  update: any,
  namespace?: PluginNamespaceInfo,
): RetoolActionDispatcher {
  return async (_dispatch, getState) => {
    const currentModel = getCurrentModel(getState)
    if (!currentModel.dependencyGraph) {
      return
    }

    currentModel.dependencyGraph.updatePlugin(template, update, namespace)
    stageModel(currentModel)
  }
}

export function calculateNewTemplate(template: PluginTemplate): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    const currentModel = getCurrentModel(getState)
    if (!currentModel.dependencyGraph) {
      return
    }

    currentModel.dependencyGraph.addPlugin(template, template.template)
    currentModel.dependencyGraph.updatePlugin(template, template.template)

    currentModel.dependencyGraph.applyTemplateStringOverrides(getState().appTemplate.present)

    const stack = currentModel.executionCallStacks.createStack([])

    const selectors = currentModel.dependencyGraph.getObjectSelectors(template.id)
    let newModel = currentModel.setValue([template.id, 'pluginType'], template.subtype)
    for (const selector of selectors) {
      newModel = await renderTemplateString(
        selector,
        newModel.dependencyGraph.lookupTemplateString(selector),
        stack.id,
        {
          model: newModel,
          stageModel,
          getCurrentModel,
          getState,
        },
      )
    }

    retoolAnalyticsTrack('Primitive Created', {
      type: primitiveTypeToAnalyticsType[template.get('type')!],
      subtype: template.get('subtype'),
    })

    dispatch({
      type: MODEL_UPDATE,
      payload: newModel,
    })
    stageModel(newModel)
    dispatch(commitModel())

    // Make sure we run onTemplateUpdate on initial component creation
    // Particularly important for the ListView initializing it's own data property
    dispatch(
      executeOnTemplateUpdate(template.id, false, undefined, false, { stackId: stack.id, isNew: true }, template),
    )
  }
}

const executeOnTemplateUpdate = function executeOnTemplateUpdate(
  id: string,
  userTriggered: boolean | undefined,
  instance: number | undefined,
  pageLoad: boolean | undefined,
  options: QueryTriggerOptions,
  template: SafeAny,
) {
  return async (dispatch: RetoolDispatch) => {
    const onTemplateUpdate = onTemplateUpdateResolver(template.subtype)
    if (onTemplateUpdate != null) {
      const templateUpdateResult = onTemplateUpdate(template.id, false, undefined, false, options)
      if (typeof templateUpdateResult === 'function') {
        dispatch(templateUpdateResult)
      }
    }
  }
}

// This is largely similar to a "batch" version of calculateNewTemplate but with some oddities
// I haven't figured out if its better to not duplicate this logic but haven't figured out a clean
// way to avoid doing that.
// This function takes the templates of all the children of a global widget and renders all of them in the
// appropriate order (+ adds them to the model)
export function calculateNewTemplateForGlobalChildren(templates: PluginTemplate[]): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    const currentModel = getCurrentModel(getState)
    if (!currentModel.dependencyGraph) {
      return
    }

    let newModel = currentModel
    for (const template of templates) {
      currentModel.dependencyGraph.updatePlugin(template, template.template, template.get('namespace'))
    }

    const topologicalSort = currentModel.dependencyGraph.topologicalSort().map((s) => ss(s))
    const stack = currentModel.executionCallStacks.createStack([])

    currentModel.dependencyGraph.applyTemplateStringOverrides(getState().appTemplate.present)

    let allSelectors: Selector[] = []

    for (const template of templates) {
      const id = template.id
      const selectors = currentModel.dependencyGraph.getObjectSelectors(id)
      allSelectors = allSelectors.concat(selectors)
      const selectorString = template.id
      newModel = newModel.setValue([selectorString, 'pluginType'], template.subtype)
      const templateNamespace = template.get('namespace')
      if (templateNamespace) {
        newModel = newModel.setValue([selectorString, 'namespace'], templateNamespace)
      }
      stageModel(newModel)
    }
    const orderedSelectors = sortBy(allSelectors, (s) => topologicalSort.indexOf(ss(s)))

    for (const selector of orderedSelectors) {
      newModel = await renderTemplateString(
        selector,
        newModel.dependencyGraph.lookupTemplateString(selector),
        stack.id,
        {
          stageModelAfterEvaluation: true,
          namespace: newModel.dependencyGraph.lookupNamespace(selector),
          stageModel,
          getCurrentModel,
          getState,
        },
      )
    }

    await dispatch({
      type: MODEL_UPDATE,
      payload: newModel,
    })

    // Make sure we run onTemplateUpdate on initial component creation
    // Particularly important for the ListView initializing it's own data property
    for (const template of templates) {
      dispatch(executeOnTemplateUpdate(template.id, false, undefined, false, { stackId: stack.id }, template))
    }
  }
}

export function recalculatePostWidgetMove(widgetId: string, container: string): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    const currentModel = getCurrentModel(getState)
    currentModel.dependencyGraph.updateObject(ds(widgetId), Immutable.Map(), new Set([container || '']))
    const selectors = currentModel.dependencyGraph.getObjectSelectors(widgetId)
    const stack = currentModel.executionCallStacks.createStack([])

    let newModel = currentModel
    for (const selector of selectors) {
      newModel = await renderTemplateString(
        selector,
        newModel.dependencyGraph.lookupTemplateString(selector),
        stack.id,
        {
          model: newModel,
          namespace: newModel.dependencyGraph.lookupNamespace(selector),
          stageModel,
          getCurrentModel,
          getState,
        },
      )
    }

    dispatch({
      type: MODEL_UPDATE,
      payload: newModel,
    })
  }
}

export const QUERY_TRIGGER = 'QUERY_TRIGGER'

export const triggerQuery: TriggerQuery = (
  queryName: string,
  userTriggered = false,
  instance?: number,
  options?: Omit<QueryTriggerOptions, 'stackId'>,
) => {
  return (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    if (options && options.pageUuid) {
      const currentPageUuid = getState().pages.get('pageUuid')
      if (options.pageUuid !== currentPageUuid) {
        return
      }
    }

    let namespacedQueryName = queryName
    if (options && options.namespace && !options.queryAlreadyNamespaced) {
      namespacedQueryName = addNamespace(options.namespace, queryName)
    }

    const appModel = appModelSelector(getState())
    if (!appModel.values.hasPlugin(namespacedQueryName)) {
      // this can happen if:
      // 1. the query was deleted, and is no longer valid
      // 2. we navigated away from the page and tore down the appModel
      const modelIsTornDown = appModel.dependencyGraph == null
      if (!modelIsTornDown) {
        return message.error(`${namespacedQueryName} is not a valid query. Did you delete it or rename it?`)
      }
    }

    return dispatch(performTriggerQuery(namespacedQueryName, userTriggered, instance, options))
  }
}

const CLEARABLE_WIDGET_TYPES: { [key in PluginTemplateParams<any>['subtype']]?: boolean } = {
  TextInputWidget: true,
  SelectWidget: true,
  MultiSelectWidget: true,
  ButtonGroupWidget: true,
  RateWidget: true,
  DateTimePickerWidget: true,
  CheckboxWidget: true,
}

// For each plugin subtype, the value that it should be set to when cleared
const valueToClearTo = (pluginSubType: PluginTemplateParams<any>['subtype']) => {
  switch (pluginSubType) {
    case 'CheckboxWidget':
      return false
    default:
      return null
  }
}

// TODO - (ENG-513) Ideally, widgets that support validation implement an interface and we can
// generate this list dynamically so that we don't have to make a change here every time validation
// is added to a new widget. The same goes for "properties to clear" these should just be fields
// from the ValidatedWidget interface.
const VALIDATED_WIDGET_TYPES: { [key in PluginTemplateParams<any>['subtype']]?: boolean } = {
  TextInputWidget: true,
}
const PROPERTIES_TO_CLEAR = [
  ['validationState', 'required'],
  ['validationState', 'validationType'],
]

export function resetChildrenValues(container: string): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    const appTemplate = appTemplateSelector(getState())
    const appModel = getState().appModel
    const largeScreen = largeScreenSelector(getState())
    const children = appTemplate.plugins
      .filter((p) => {
        if (largeScreen) {
          return _.get(p, ['position2', 'container']) === container
        } else {
          return _.get(p, ['mobilePosition2', 'container']) === container
        }
      })
      .filter((p) => {
        return CLEARABLE_WIDGET_TYPES[p.subtype] && (appModel.values.getPlugin(p.id) as any).has('value')
      })
      .toList()
      .toArray()
    const changesets = children.flatMap((c) => {
      const changeset = [
        {
          selector: [c.id, 'value'],
          newValue: valueToClearTo(c.subtype),
        },
      ]

      if (VALIDATED_WIDGET_TYPES[c.subtype]) {
        PROPERTIES_TO_CLEAR.forEach((prop) => {
          changeset.push({
            selector: [c.id, prop].flat(),
            newValue: null,
          })
        })
      } else if (c.subtype === 'SelectWidget') {
        // TODO (ENG-513) This is hopefully a one-off case where validationState is a string instead of an object.
        // We need to standardize the validation API so that we don't need to do hacky stuff like this
        // and can instead take care of clearing validation in the if-statement above.
        changeset.push({
          selector: [c.id, 'validationState'],
          newValue: null,
        })
      }
      return changeset
    })
    const stack = appModel.executionCallStacks.createStack(changesets)
    await dispatch(modelUpdate(changesets, stack.id) as any)
    try {
      dispatch(
        batchExecOnTemplateUpdates(
          children.map((c) => ({ id: c.id, type: c.subtype })),
          { stackId: stack.id },
        ),
      )
    } catch (err) {
      // Silently swallow
    }
  }
}

function handleModelUpdate(state: AppModelType, action: any) {
  return action.payload
}

// onTemplateUpdate can trigger additional changes (e.g. a form
// wants to update its own model in response on its children changing).
// we batch up all those updates to apply once, for performance reasons
export function batchExecOnTemplateUpdates(
  pluginIdAndTypes: { id: string; type: string }[],
  {
    userTriggered,
    instance,
    pageLoad,
    options,
    stackId,
  }: {
    instance?: number
    userTriggered?: boolean
    pageLoad?: boolean
    options?: Omit<QueryTriggerOptions, 'stackId'>
    stackId: number
  },
) {
  return (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    let addtlChangeSetsToApply: ModelChangeset[] = []
    let selectorsToMarkClean: Selector[] = []

    for (const { id: pluginId, type: pluginType } of pluginIdAndTypes) {
      const onTemplateUpdate = onTemplateUpdateResolver(pluginType)
      if (onTemplateUpdate == null) {
        continue
      }
      const currentModel = getCurrentModel(getState)
      const onTemplateUpdateResult = onTemplateUpdate(pluginId, userTriggered, instance, pageLoad, {
        ...options,
        namespace: currentModel.dependencyGraph.lookupNamespace([pluginId]),
        stackId,
      })
      let addtlChanges = null

      if (typeof onTemplateUpdateResult === 'function') {
        addtlChanges = dispatch(onTemplateUpdateResult)
      }

      if (addtlChanges && typeof addtlChanges === 'object' && 'modelUpdates' in addtlChanges) {
        addtlChangeSetsToApply = addtlChangeSetsToApply.concat(addtlChanges.modelUpdates!)
      }
      if (addtlChanges && typeof addtlChanges === 'object' && 'selectorsToMarkClean' in addtlChanges) {
        selectorsToMarkClean = selectorsToMarkClean.concat(addtlChanges.selectorsToMarkClean)
      }
    }

    if (addtlChangeSetsToApply.length > 0) {
      dispatch(modelUpdate(addtlChangeSetsToApply, stackId))
    }
    if (selectorsToMarkClean.length > 0) {
      dispatch(markSelectorsClean(selectorsToMarkClean))
    }
  }
}

function applyAndPropogateChangesets(
  state: AppModelType,
  changesets: ModelChangeset[],
  stackId: number,
  userTriggered = false,
) {
  return async (dispatch: RetoolDispatch) => {
    const stack = state.executionCallStacks.getStack(stackId)
    //  There is a race condition where if we call this function in flush deferrred changesets
    //  and, before finishing the execution, call it in response to a user triggered event, we can change the
    //  stack in response to the user action and potentially have fewer execution stacks than before, so
    //  we get an out of bound index when we reference them.
    if (stack == null) {
      return
    }

    const newState = changesets.reduce((state, changeset) => {
      return applyChangeset(state, changeset)
    }, state)
    stageModel(newState)
    dispatch(commitModel())

    if (!state.dependencyGraph) {
      // no dep graph because of page load / reload
      return
    }

    const dependants = changesets
      .map(({ selector }) => state.dependencyGraph.getDependantsOf(selector).map((a: any) => a.selector.join('.')))
      .reduce((dependants, subDependants) => dependants.union(subDependants), Immutable.Set<string>())

    const sourceTemplates = Immutable.Set(changesets.map(({ selector }) => selector[0]))

    const dependantTemplates = dependants.map((selector) => ds(selector)[0]).filter((id) => !sourceTemplates.has(id))

    // Add the changes to the CallStack's changedSelectors
    for (const changeset of changesets) {
      stack.changedSelectors.push(changeset.selector)
    }

    const changedDownstreamSelectors = await dispatch(
      batchedPropagateChanges(newState, dependants, changesets, stack.id),
    )

    dispatch(commitModel())

    const updatedParams: any = {}
    for (const changeset of changesets) {
      updatedParams[ss(changeset.selector)] = true
    }
    for (const selector of changedDownstreamSelectors) {
      updatedParams[ss(selector)] = true
    }

    setTimeout(() => {
      dispatch(
        batchExecOnTemplateUpdates(
          dependantTemplates.toJS().map((id) => {
            return { id, type: state.values.getPlugin(id).get('pluginType') }
          }),
          { userTriggered, options: { updatedParams }, stackId: stack.id },
        ),
      )
    }, 1)

    dispatch(runInstrumentations(updatedParams))
  }
}

/**
 * Given a bunch of selectors, we return groups of selectors that can be
 * independently evaluated in parallel, for performance purposes
 *
 * Example:
 * ['text1.value', 'select1.value', 'table1.data']
 * and 'table1.data' depends on 'select1.value'
 *
 * Returns:
 * [
 *   ['text1.value', 'select1.value'],
 *   ['table1.data']
 * ]
 */
function computeEvalBatches(selectors: Selector[], dependencyGraph: IDependencyGraph) {
  const batches: Selector[][] = []
  let curBatch: Selector[] = []
  let unresolvedSelectors = Immutable.Set(selectors.map((s) => s.join('.')))

  for (let i = 0; i < selectors.length; i++) {
    const curSelector = selectors[i]
    const dependencies = dependencyGraph.getDependenciesOf(curSelector).map((d) => d.selector.join('.'))
    const dependsOnPrevValues = dependencies.intersect(unresolvedSelectors)

    if (dependsOnPrevValues.size > 0) {
      // this value depends on a value from the current batch.
      // can't evaluate it until the previous batch is evaluated
      batches.push(curBatch)
      curBatch = [curSelector]
      unresolvedSelectors = Immutable.Set(selectors.map((s) => s.join('.')).slice(i))
    } else {
      curBatch.push(curSelector)
    }
  }
  batches.push(curBatch)

  return batches
}

/**
 * Batched version of `serialPropagateChanges`
 *
 * Takes a bunch of selectors to evaluate
 * evaluates them in the correct dependency order + updates appModel
 */
export function batchedPropagateChanges(
  state: AppModelType,
  dependants: Immutable.Set<SelectorString>,
  changesets: ModelChangeset[],
  stackId: number,
) {
  return async (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const dependantSelectors = state.dependencyGraph
      .topologicalSort()
      .filter((selector) => dependants.has(selector.join('.')))
    const evalBatches = computeEvalBatches(dependantSelectors, state.dependencyGraph)
    for (const evalBatch of evalBatches) {
      await Promise.all(
        evalBatch.map((selector) =>
          renderTemplateString(selector, state.dependencyGraph.lookupTemplateString(selector), stackId, {
            stageModelAfterEvaluation: true,
            namespace: state.dependencyGraph.lookupNamespace(selector),
            stageModel,
            getCurrentModel,
            getState,
          }),
        ),
      )
    }
    return dependantSelectors
  }
}

function serialPropagateChanges(state: AppModelType, dependants: Immutable.Set<string>, stackId: number) {
  return async (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const dependantSelectors = state.dependencyGraph
      .topologicalSort()
      .filter((selector: any) => dependants.has(selector.join('.')))
    let newModel = state
    for (const selector of dependantSelectors) {
      newModel = await renderTemplateString(
        selector,
        newModel.dependencyGraph.lookupTemplateString(selector),
        stackId,
        {
          stageModelAfterEvaluation: true,
          namespace: newModel.dependencyGraph.lookupNamespace(selector),
          stageModel,
          getCurrentModel,
          getState,
        },
      )
    }
    return dependantSelectors
  }
}

export function setEnvironment(environment: 'staging' | 'production', refresh = true) {
  return (dispatch: any) => {
    ls.set('appEnvironment', environment)
    dispatch({
      type: SET_ENVIRONMENT,
      payload: { environment },
    })
    if (refresh) {
      setTimeout(() => {
        dispatch(regenerateModelAndDependencyGraph())
      }, 1000)
    }
  }
}

function applyChangeset(state: AppModelType, changeset: any) {
  const { selector, newValue } = changeset
  return state.setValue(selector, newValue).markAsClean(ss(selector))
}

export function getSelectorsFromUpdate(selector: Selector, object: any): any {
  if (Immutable.isCollection(object)) {
    return object.reduce((selectors, v, k) => {
      const childSelector = selector.concat([k])
      return selectors.union(getSelectorsFromUpdate(childSelector, v))
    }, Immutable.Set())
  } else {
    return Immutable.Set([selector])
  }
}

function handlePluginUpdateId(state: AppModelType, action: any) {
  const { pluginId, newId } = action.payload
  state.dependencyGraph.renamePlugin(pluginId, newId)
  return state
    .updateValue((values: AppModelValues) => values.mapKeys((k: any) => (k === pluginId ? newId : k)))
    .setValue([newId, 'id'], newId)
}

function handleDatasourceTypeChange(state: AppModelType, action: any) {
  const { pluginId, newType } = action.payload
  return state.setValue([pluginId, 'pluginType'], newType).deleteValue([pluginId, 'error'])
}

function performTriggerQuery(
  queryName: string,
  userTriggered: boolean,
  instance?: number,
  options?: Omit<QueryTriggerOptions, 'stackId'>,
) {
  return (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const currentModel = getCurrentModel(getState)
    const stack = currentModel.executionCallStacks.createStack([])
    const currentRunningTestId = currentlyRunningTestIdSelector(getState())

    // If a test is running we need to ensure the query knows about it, and we need to do that
    // right before triggering the test since because we do setTimeout, the test may not still be running
    // once the query is actually executed
    const queryOptions = { testId: currentRunningTestId, ..._.cloneDeep(options) }

    setTimeout(() => {
      // eslint-disable-next-line no-console
      dispatch(
        batchExecOnTemplateUpdates(
          [{ id: queryName, type: currentModel.values.getPlugin(queryName).get('pluginType') }],
          {
            instance,
            options: queryOptions,
            userTriggered,
            stackId: stack.id,
          },
        ),
      )
    }, 50)

    return getState()
  }
}

function handlePluginDelete(state: AppModelType, action: { payload: string[] }) {
  const ids = action.payload
  return ids.reduce((state: AppModelType, id: any) => {
    state.dependencyGraph.deletePlugin(id)
    return state.deleteValue([id])
  }, state)
}

function handleReceiveOrganization(state: AppModelType, action: any) {
  const {
    user: { id, email, firstName, lastName, groups, metadata },
  } = action.payload
  const update = {
    id,
    email,
    firstName,
    lastName,
    fullName: `${firstName} ${lastName}`,
    groups: groups.map((g: any) => _.pick(g, ['id', 'name', 'createdAt', 'updatedAt'])),
    metadata,
  }

  retoolAnalyticsTrack('Info Logged', {
    type: 'Profile Received',
    user: {
      firstName,
      lastName,
      email,
    },
    location: 'model.ts',
  })

  return state.mergeInGlobals(update)
}

function handleTearDownPage(state: AppModelType) {
  // TODO (ENG-507): in order to fix a bug where components might have a random looking error
  // called "cannot read property getStack of null", we will do a hack for now, to make sure
  // that even after we tear down a page, there is still a valid .executionCallStacks
  // property.
  //
  // We have the option a completely new ExecutionCallStack, or to re-use the old one --
  // I think re-using is better, because if we create a new one we might run into future issues
  // where we could be hitting null issues

  const currentExecutionCallstacks = state.executionCallStacks
  return state
    .clear()
    .set('globals', state.globals)
    .set('environment', state.environment)
    .set('executionCallStacks', currentExecutionCallstacks)
    .set('modelInitialized', false)
}

function handleInitializeModel(state: AppModelRecord, action: { payload: AppModelRecord }) {
  return action.payload.set('modelInitialized', true)
}

function getEnvFromUrl() {
  const environment = queryString.parse(window.location.search)._environment
  if (environment && (environment === 'staging' || environment === 'production')) {
    ls.set('appEnvironment', environment)
    return environment
  } else {
    return null
  }
}

export const resizeViewport = function resizeViewportThunk() {
  return async (dispatch: RetoolDispatch) => {
    const viewportData = getViewportData()
    setTimeout(() => {
      dispatch(
        triggerModelUpdate([
          { selector: ['viewport', 'width'], newValue: viewportData.get('width') },
          {
            selector: ['viewport', 'height'],
            newValue: viewportData.get('height'),
          },
        ]),
      )
    })
  }
}

type PluginTypeLoadedAction = {
  type: typeof PLUGIN_TYPE_LOADED
  payload: string
}

/**
 * Record that an async plugin has been loaded, and add
 * the property annotations to the dependency graph.
 */
export const pluginTypeLoaded = (type: string) => (dispatch: RetoolDispatch, getState: () => RetoolState): void => {
  const model = getCurrentModel(getState)
  if (!model.dependencyGraph || model.get('loadedPluginTypes').includes(type)) return

  const plugins = pluginsSelector(getState())

  plugins.forEach((plugin) => {
    if (plugin.subtype === type) {
      model.dependencyGraph.updatePropertyAnnotations(plugin)
    }
  })

  dispatch<PluginTypeLoadedAction>({ type: PLUGIN_TYPE_LOADED, payload: type })
}

const handlePluginTypeLoaded = (state: AppModelType, { payload }: PluginTypeLoadedAction) =>
  state.pluginTypeLoaded(payload)

const ACTION_HANDLERS: any = {
  [INITIALIZE_MODEL]: handleInitializeModel,
  [MODEL_UPDATE]: handleModelUpdate,
  [PLUGIN_UPDATE_ID]: handlePluginUpdateId,
  [PLUGIN_DELETE]: handlePluginDelete,
  [DATASOURCE_TYPE_CHANGE]: handleDatasourceTypeChange,
  [RECEIVE_USER_PROFILE]: handleReceiveOrganization,
  [TEARDOWN_PAGE]: handleTearDownPage,
  [RESET_TEMPLATE]: handleTearDownPage,
  [SET_ENVIRONMENT]: (state: any, action: any) => state.set('environment', action.payload.environment),
  [ENQUEUE_USER_TRIGGERED_QUERY_RUN]: (state: any, action: any) =>
    state.addPendingUserTriggeredQuery(action.payload.queryId, action.payload.options),
  [DEQUEUE_USER_TRIGGERED_QUERY_RUN]: (state: any, action: any) =>
    state.removePendingUserTriggeredQuery(action.payload.queryId),
  [TERMINAL_LOG_UPDATE]: (state: any, action: any) => {
    return state.setIn(['globals', 'terminalLog'], state.getIn(['globals', 'terminalLog']).push(action.payload.newLog))
  },
  [PLUGIN_TYPE_LOADED]: handlePluginTypeLoaded,
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = new AppModelRecord({
  environment: getEnvFromUrl() || ls.get('appEnvironment') || 'production',
})

export default function appModelReducer(state = initialState, action: any) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

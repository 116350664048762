import React from 'react'

import { APIForm, apiDefaults, APIAuthMethodType } from './common'
import { ResourceFormProps } from './types'

export const REST_API_AUTH_METHODS: APIAuthMethodType[] = [
  'auth0',
  'basic',
  'oauth1',
  'oauth2',
  'session',
  'custom',
  'aws4',
]

const RestAPIForm = (props: ResourceFormProps) => <APIForm authMethods={REST_API_AUTH_METHODS} {...props} />

export default {
  label: 'Rest API',
  form: RestAPIForm,
  isOAuth: (resource: any) => {
    return resource.options.authentication === 'oauth2'
  },
  defaults: { ...apiDefaults, options: { ...apiDefaults.options } },
  validator: ({ options }: any) =>
    options.baseURL.length === 0 ||
    (options.baseURL.length > 4 &&
      (options.baseURL.substr(0, 4) === 'http' || options.baseURL.match(/^%RETOOL_EXPOSED.*%$/)) &&
      ((options.authentication === 'auth0' && options.auth0_domain && options.auth0_clientID) ||
        (options.authentication === 'basic' && options.basic_username) ||
        (options.authentication === 'oauth2' && options.oauth2_access_token_url && options.oauth2_client_id) ||
        options.authentication === 'oauth1' ||
        (options.authentication === 'session' && options.session_cookie_name) ||
        options.authentication === 'none' ||
        options.authentication === '' ||
        options.authentication === 'custom' ||
        (options.authentication === 'aws4' &&
          options.amazon_aws_region &&
          options.amazon_service_name &&
          (options.authWithDefaultCredentialProviderChain ||
            (options.amazon_access_key_id && options.amazon_secret_access_key))) ||
        options.authentication === undefined)),
}

import React from 'react'

import { DatabaseForm, databaseDefaults, databaseValidator, SSHTunnelForm } from './common'
import { ResourceFormProps } from './types'

const RethinkDBForm = (props: ResourceFormProps) => (
  <SSHTunnelForm {...props}>
    <DatabaseForm
      placeholderHost="IP address or hostname of your database"
      placeholderPort="28015"
      placeholderUsername="retool"
      customCertificateSupported
      hideEditPrivilege
      hideDangerZone
      {...props}
    />
  </SSHTunnelForm>
)

export default {
  label: 'RethinkDB',
  form: RethinkDBForm,
  defaults: {
    ...databaseDefaults,
    options: {
      ...databaseDefaults.options,
    },
    port: '28015',
  },
  validator: databaseValidator,
}

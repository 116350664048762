import Immutable from 'immutable'
import { REQUEST_SAVE } from './appModel/actionTypes'
import {
  WIDGET_REPOSITION,
  WIDGET_REPOSITION2,
  WIDGET_SET_POSITION2,
  WIDGET_SET_MOBILE_POSITION2,
  WIDGET_TEMPLATE_CREATE,
  DATASOURCE_TEMPLATE_CREATE,
  FUNCTION_TEMPLATE_CREATE,
  INSTRUMENT_TEMPLATE_CREATE,
  STATE_TEMPLATE_CREATE,
  DISABLE_RESPONSIVE_LAYOUT,
  URL_FRAGMENT_DEF_UPDATE,
  PAGE_LOAD_VALUE_OVERRIDE_UPDATE,
  UPDATE_PRELOADED_APP_JAVASCRIPT,
  UPDATE_PRELOADED_APP_JS_LINKS,
  UPDATE_APP_STYLES,
  UPDATE_GLOBAL_STYLES,
  ENABLE_CUSTOM_DOCUMENT_TITLE,
  SET_CUSTOM_DOCUMENT_TITLE,
  SET_CUSTOM_SHORTCUTS,
  RECEIVE_RESTORE,
  FOLDER_CREATE,
  FOLDER_RENAME,
  FOLDER_MOVE,
  FOLDER_DELETE,
  PLUGIN_FOLDER_CHANGE,
  REORDER_QUERIES,
  REORDER_TABBED_CONTAINER,
  PLUGIN_UPDATE,
  DISABLE_LOADING_INDICATORS,
  QUERY_MOVE,
  UPDATE_TEST_ENTITY,
  ADD_TEST_ENTITY,
  MOVE_TEST_ENTITY,
  MOVE_TEST_ENTITY_IN_SUITE,
  DELETE_TEST_ENTITY,
  CLEAR_PLUGINS,
  FRAME_TEMPLATE_CREATE,
  MIGRATIONS_SUCCESS,
} from './appModel/template'
import { DATASOURCE_TYPE_CHANGE, PLUGIN_DELETE, PLUGIN_UPDATE_ID, RESET_TEMPLATE } from 'store/appModel/constants'
import {
  WIDGET_TEMPLATE_UPDATE,
  BATCH_WIDGET_TEMPLATE_UPDATE,
  BATCH_WIDGET_TEMPLATE_CREATE,
} from 'store/appModel/templateUtils'
import { RetoolAction } from 'store'
import { TypedMap } from 'common/utils/immutable'

// ------------------------------------
// Actions
// ------------------------------------

type ChangesRecordState = TypedMap<{ changes: Immutable.List<unknown> }>

const handleRequestSave = () => initialState

const handleChange = (state: ChangesRecordState, action: RetoolAction) =>
  state.set('changes', state.get('changes').push(action))

const handleChangeTypeOnly = (state: ChangesRecordState, action: RetoolAction) =>
  handleChange(state, { type: action.type })

const ACTION_HANDLERS: any = {
  [REQUEST_SAVE]: handleRequestSave,
  [WIDGET_REPOSITION]: handleChange,
  [WIDGET_REPOSITION2]: handleChange,
  [WIDGET_SET_POSITION2]: handleChange,
  [WIDGET_SET_MOBILE_POSITION2]: handleChange,
  [WIDGET_TEMPLATE_CREATE]: handleChange,
  [WIDGET_TEMPLATE_UPDATE]: handleChange,
  [BATCH_WIDGET_TEMPLATE_CREATE]: handleChangeTypeOnly,
  [BATCH_WIDGET_TEMPLATE_UPDATE]: handleChangeTypeOnly,
  [DATASOURCE_TEMPLATE_CREATE]: handleChange,
  [FUNCTION_TEMPLATE_CREATE]: handleChange,
  [STATE_TEMPLATE_CREATE]: handleChange,
  [PLUGIN_UPDATE_ID]: handleChange,
  [DATASOURCE_TYPE_CHANGE]: handleChange,
  [PLUGIN_DELETE]: handleChange,
  [DISABLE_RESPONSIVE_LAYOUT]: handleChange,
  [URL_FRAGMENT_DEF_UPDATE]: handleChangeTypeOnly,
  [PAGE_LOAD_VALUE_OVERRIDE_UPDATE]: handleChangeTypeOnly,
  [INSTRUMENT_TEMPLATE_CREATE]: handleChange,
  [UPDATE_PRELOADED_APP_JAVASCRIPT]: handleChangeTypeOnly,
  [UPDATE_PRELOADED_APP_JS_LINKS]: handleChangeTypeOnly,
  [UPDATE_APP_STYLES]: handleChangeTypeOnly,
  [UPDATE_GLOBAL_STYLES]: handleChange,
  [ENABLE_CUSTOM_DOCUMENT_TITLE]: handleChange,
  [SET_CUSTOM_DOCUMENT_TITLE]: handleChange,
  [SET_CUSTOM_SHORTCUTS]: handleChange,
  [INSTRUMENT_TEMPLATE_CREATE]: handleChange,
  [RECEIVE_RESTORE]: handleChangeTypeOnly,
  [FOLDER_CREATE]: handleChange,
  [FOLDER_RENAME]: handleChange,
  [FOLDER_MOVE]: handleChange,
  [FOLDER_DELETE]: handleChange,
  [PLUGIN_FOLDER_CHANGE]: handleChange,
  [REORDER_QUERIES]: handleChangeTypeOnly,
  [QUERY_MOVE]: handleChangeTypeOnly,
  [REORDER_TABBED_CONTAINER]: handleChange,
  [PLUGIN_UPDATE]: handleChange,
  [DISABLE_LOADING_INDICATORS]: handleChangeTypeOnly,
  [ADD_TEST_ENTITY]: handleChange,
  [UPDATE_TEST_ENTITY]: handleChange,
  [MOVE_TEST_ENTITY]: handleChange,
  [MOVE_TEST_ENTITY_IN_SUITE]: handleChange,
  [DELETE_TEST_ENTITY]: handleChange,
  [CLEAR_PLUGINS]: handleChangeTypeOnly,
  [FRAME_TEMPLATE_CREATE]: handleChange,
  [RESET_TEMPLATE]: handleChangeTypeOnly,
  [MIGRATIONS_SUCCESS]: handleChangeTypeOnly,
  '@@redux-undo/UNDO': handleChangeTypeOnly,
  '@@redux-undo/REDO': handleChangeTypeOnly,
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = Immutable.Map({
  changes: Immutable.List(),
})
export default function changesRecordReducer(state = initialState, action: any) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

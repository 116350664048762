import { AsanaResourceSettings } from 'common/records'
import { USER_CALLBACK_URL } from 'components/ResourceForm/oauthForm'
import { generateResourceForm } from 'components/ResourceForm/resourceFormGenerator/generator'
import { AuthTemplate } from 'components/ResourceForm/resourceFormGenerator/generator-types'
import React from 'react'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'
import { transformHeadersForEditor } from '../../components/AuthTriggerInput'

const ASANA_AUTH_URL = 'https://app.asana.com/-/oauth_authorize'
const ASANA_TOKEN_URL = 'https://app.asana.com/-/oauth_token'

const defaultOptions: AsanaResourceSettings = {
  options: {
    customQueryParameters: [['', '']],
    customHeaders: [['Authorization', 'Bearer OAUTH2_TOKEN']],
    authentication: 'oauth2',
    api_key: '',
    oauth2_client_id: '',
    oauth2_client_secret: '',
    oauth2_scope: '',
    oauth2_callback_url: USER_CALLBACK_URL,
    oauth2_auth_url: ASANA_AUTH_URL,
    oauth2_access_token_url: ASANA_TOKEN_URL,
    oauth2_share_user_credentials: false,
    spec: `${RETOOL_SPECS_DOMAIN}/asana/3.0.0/1.0.json`,
    verify_session_action_enabled: true,
    verify_session_action: {
      query: 'https://app.asana.com/api/1.0/workspaces',
      method: 'GET',
      type: 'http_request',
      runWhenModelUpdates: false,
      bodyType: 'raw',
      headers: transformHeadersForEditor([['Authorization', 'Bearer OAUTH2_TOKEN']]),
    },
  },
}

const auth: AuthTemplate = [
  {
    type: 'apiKey',
    options: {
      label: 'Personal access token',
      description: (
        <>
          Enter your secret Asana personal access token above. To retrieve or create a token visit the{' '}
          <a rel="noopener noreferrer" target="_blank" href="https://app.asana.com/0/developer-console">
            Developer Console
          </a>{' '}
          section of your Asana account.
        </>
      ),
    },
  },
  {
    type: 'oauth2',
    options: {
      retoolHostedApp: {},
      customApp: {
        description: (
          <>
            To connect Retool to Asana create a connected app in your Asana account for Retool.{' '}
            <a
              target="_blank"
              href="https://developers.asana.com/docs/register-an-application"
              rel="noopener noreferrer"
            >
              Here is a guide on how to create an application in Asana
            </a>
          </>
        ),
      },
    },
  },
]

export default generateResourceForm('Asana', defaultOptions, {
  auth,
  isOAuth: (resource) => resource.options?.authentication === 'oauth2',
  hideTestConnection: () => true,
})

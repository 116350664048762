import React from 'react'
import { Button, Modal } from 'components/design-system'

interface EditPlaceholderResourceConfirmationModal {
  onClose: () => void
  signupTheme: string
}

const EditPlaceholderResourceConfirmationModal = (props: EditPlaceholderResourceConfirmationModal) => {
  const { onClose, signupTheme } = props

  return (
    <>
      <Modal destroyOnClose width={600} style={{ top: 375 }} visible footer={null} onCancel={onClose}>
        <div className="resource-confirmation-modal">
          <div className="resource-confirmation-modal-emoji">🎉</div>
          <div className="resource-confirmation-modal-header">Success! {signupTheme} Connected.</div>
          <div className="resource-confirmation-modal-text">
            Nice! Click on the button below to start building on top of your {signupTheme} app.
          </div>
          <Button className="confirmation-done-button fs-12" onClick={onClose}>
            See your app
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default EditPlaceholderResourceConfirmationModal

import React, { useEffect, useState } from 'react'
import { Modal, Icon } from 'components/design-system'
import { clearResourceErrorMessage, editResource } from 'store/user'
import ResourceForm, { ResourceState } from 'components/ResourceForm'
import { userSelector } from 'store/selectors'
import { resourceLoad } from 'routes/Resources/modules/resources'
import Immutable from 'immutable'
import { resourceIcons } from 'retoolConstants'
import { connect } from 'react-redux'
import { RetoolState } from 'store'
import { ResourceFromServer } from 'common/records'
import { ResourceType } from 'common/resourceTypes'
import './PlaceholderResourcesWarning.scss'
import { removeURLParameters } from 'common/utils'
import { createSelector } from 'reselect'

const FIELD_NAMES_TO_REPLACE = {
  github: ['options.basic_username', 'options.basic_password'],
  s3: ['s3_access_key_id', 's3_default_bucket_name', 's3_secret_access_key_id'],
  stripe: ['api_key'],
  firebase: ['name', 'options.databaseURL', 'options.projectId', 'options.serviceAccountKey'],
  slack: ['options.slack_webhook_url'],
  buffer: [
    'options.oauth2_access_token',
    'options.oauth2_access_token_url',
    'options.oauth2_client_id',
    'options.oauth2_client_secret',
  ],
  closeio: ['options.basic_username'],
  salesforce: ['options.instanceUrl'],
  sendgrid: ['options.api_key'],
}

interface EditPlaceholderResourcesModalProps {
  clearResourceErrorMessage: () => void
  onClose: () => void
  onCompleteEditing: (newResource: ResourceFromServer) => void
  onSaveResource: (changeset: ResourceState, resourceName: string) => Promise<any>
  resources: Immutable.Map<string, any>
  resourceName: string
  showDatabaseUrlField?: boolean
  signupTheme?: string
  resourceLoad: (resourceName: string) => void
}

const EditPlaceholderResourceModal = (props: EditPlaceholderResourcesModalProps) => {
  const {
    resources,
    clearResourceErrorMessage,
    onClose,
    onCompleteEditing,
    resourceName,
    onSaveResource,
    showDatabaseUrlField,
    signupTheme,
    resourceLoad,
  } = props
  const showNameField = !signupTheme
  const [resource, setResource] = useState<Immutable.Map<string, any> | undefined>()

  const modalHeader = signupTheme ? <h3>Connect your {signupTheme} Account</h3> : <h3>Connect your data</h3>

  useEffect(() => {
    ;(async () => {
      if (resourceName) {
        // We load the resource with /api/resources/names since we need additional info, e.g. encrypted access tokens,
        // to display if oauth resources have connected successfully
        const result = (await resourceLoad(resourceName)) as any
        setResource(Immutable.Map(result?.payload?.resourceByEnv?.production))
      }
    })()
  }, [resourceName, resourceLoad])

  const resourceForm = (
    <>
      <div className="resource-form-container">
        {resource ? (
          <ResourceForm
            resource={resource}
            resources={resources}
            fieldNamesToReplace={
              resource && FIELD_NAMES_TO_REPLACE[resource.get('type') as keyof typeof FIELD_NAMES_TO_REPLACE]
            }
            disableSubmitButton={() => {}}
            clearResourceErrorMessage={clearResourceErrorMessage}
            showDatabaseUrlField={showDatabaseUrlField}
            hideLabelAndType={showNameField}
            isModal
            saveResource={async (
              changeset: ResourceState,
              isOAuth: boolean,
              newResourceName: string,
              hideSuccessModal?: boolean,
              triggeredByOauth?: boolean,
            ) => {
              const result = await onSaveResource(changeset, resourceName)

              // If save is triggered by oauth, the user is still editing the resource after the oauth flow.
              // Therefore, don't close the modal yet
              if (!triggeredByOauth && !result.error) {
                onCompleteEditing(result.payload.newResource)
                onClose()
                // After saving the resource, we no longer need to know if we are returning from an oauth flow
                removeURLParameters('finishedOAuth', 'demoResourceConnectionRequired')
              }

              return result
            }}
          />
        ) : (
          <div className="flex items-center justify-center mb20">
            <Icon type="loading" />
          </div>
        )}
      </div>
    </>
  )

  return (
    <Modal destroyOnClose width={900} visible footer={null} onCancel={onClose} className="resource-placeholder-modal">
      <div className="modal-header">
        <img
          style={{ width: 24, marginRight: 8 }}
          width="34"
          src={resource && resourceIcons[resource.get('type') as ResourceType]}
        />
        {modalHeader}
      </div>
      {resourceForm}
    </Modal>
  )
}

const resourcesSelector = createSelector(userSelector, (user) => user.resources)

function mapStateToProps(state: RetoolState) {
  return {
    resources: resourcesSelector(state) as any,
  }
}

function onSaveResource(changeset: ResourceState, resourceName: string) {
  return async (dispatch: any) => {
    const result = await dispatch(editResource(resourceName, 'production', { ...changeset }))
    await dispatch(resourceLoad(resourceName))
    return result
  }
}

const mapDispatchToProps = { resourceLoad, onSaveResource, clearResourceErrorMessage }

export default connect(mapStateToProps, mapDispatchToProps)(EditPlaceholderResourceModal)

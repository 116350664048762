import React from 'react'

export type ListViewProps<T> = {
  records: T[]
  children: (_: T) => React.ReactElement
  className?: string
  ifEmpty?: React.ReactElement
}

export function ListView<T>(props: ListViewProps<T>) {
  const { records, children: renderer, className, ifEmpty } = props

  if (records.length === 0 && ifEmpty !== undefined) {
    return ifEmpty
  } else {
    return <div className={className}>{records.map(renderer)}</div>
  }
}

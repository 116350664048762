import useCallbackRef from 'common/hooks/useCallbackRef'
import { UnknownObject } from 'common/types'

type UnknownRef = UnknownObject | null

const widgetApiRefs: { [key: string]: UnknownRef } = {}

const getKey = (id: string, instance?: number): string => (instance == null ? id : `${id}--${instance}`)

export type WidgetApiRef = (node: UnknownRef) => void

export const useWidgetApiRef = (id: string, instance?: number): WidgetApiRef =>
  useCallbackRef((node) => (widgetApiRefs[getKey(id, instance)] = node))

export const getWidgetRef = (id: string, instance?: number): UnknownRef => widgetApiRefs[getKey(id, instance)] ?? null

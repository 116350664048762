import { getState } from 'store'
import { createSelector } from 'reselect'
import { allFeaturesSelector, activatedFeaturesSelector, planSelector } from 'store/selectors'

export const hasFeature__UNSAFE_IN_RENDER = (name: string) => {
  try {
    const state = getState()
    const plan = planSelector(state)
    const features = activatedFeaturesSelector(state)
    const allFeatures = allFeaturesSelector(state)
    if (plan === null || features === null || allFeatures === null) return true
    if (allFeatures.map((f: any) => f.name).indexOf(name) === -1) {
      return true
    }
    return features.map((f: any) => f.name).indexOf(name) !== -1
  } catch (err) {
    return true
  }
}

export const createFeatureSelector = (featureName: string) =>
  createSelector(allFeaturesSelector, planSelector, activatedFeaturesSelector, (allFeatures, plan, features) => {
    try {
      if (plan === null && (features === null || allFeatures === null)) return true
      if (allFeatures.map((f) => f.name).indexOf(featureName) === -1) {
        return true
      }
      return features ? features.map((f) => f.name).indexOf(featureName) !== -1 : true
    } catch (err) {
      return true
    }
  })

export const hasPresentationModeFeatureSelector = createFeatureSelector('PresentationMode')
export const hasPublicAppsFeatureSelector = createFeatureSelector('PublicApps')
export const hasStylingFeatureSelector = createFeatureSelector('Styling')

import Immutable from 'immutable'
import _ from 'lodash'
import * as escope from 'escope'
import undeclaredV2 from 'common/undeclared/undeclaredV2'
import { DepGraph } from 'dependency-graph'
import { message } from 'antd'

import { ANY_TEMPLATE_REGEX } from 'common/regexes'
import {
  AppTemplate,
  PluginNamespaceInfo,
  PluginNamespaceInfoImpl,
  PluginTemplate,
  IDependencyGraph,
  NodeData,
} from 'common/records'
import {
  MaskResolver,
  PluginPropertyAnnotationsResolver,
  PropertyAnnotations,
  TemplateCodeCustomAliasesResolver,
} from 'components/plugins'
import { ds, logger, Selector, ss } from 'common/utils'
import { addNamespace } from 'common/utils/namespaces'
import { sampleSentryException } from 'common/sentry'
import { SHARED_PLUGINS_BETWEEN_GLOBAL_WIDGETS_AND_PARENT } from './constants'
import { globalScopeFuncs } from 'common/evaluator'
import parseJSExpression from 'common/parseJSExpression'

// ------------------------------------
// Resources
// ------------------------------------

function inits<A>(arr: A[]): A[][] {
  return arr.map((_, i) => arr.slice(0, i + 1))
}

interface DependencyGraphInterface<T> {
  nodes: { [s: string]: T }
  incomingEdges: { [s: string]: string[] }
  outgoingEdges: { [s: string]: string[] }
}

export class DependencyGraph implements IDependencyGraph {
  depGraph: DepGraph<NodeData> & DependencyGraphInterface<NodeData>
  parentPointers: any
  // topologicalSort() can take upwards of 50ms on a complicated app!
  // cache this for performance
  cachedTopologicalOrder: Selector[] | null = null

  constructor(originalDependencyGraph?: IDependencyGraph) {
    if (originalDependencyGraph) {
      this.depGraph = (originalDependencyGraph as DependencyGraph).depGraph.clone() as DepGraph<NodeData> &
        DependencyGraphInterface<NodeData>
      this.parentPointers = { ...originalDependencyGraph.parentPointers }
    } else {
      this.depGraph = new DepGraph<NodeData>() as DepGraph<NodeData> & DependencyGraphInterface<NodeData>

      // A map that connects the widgets to the container / listview it belongs to
      this.parentPointers = {}
    }
  }

  /**
   * TODO Dmitriy to clean this up - essentially this adds a namespace to a dependency
   * if its supposed to have one. e.g. if `global1::textinput1.value = '{{text2.value}}'`
   * we know its really supposed to be `{{global1::text2.value}}` since we are operating inside
   * the namespace.
   *
   * This doesn't apply to global props since they refer to things OUTSIDE of
   * the namespace. It also doesn't apply to dependencies that are shared between the module and
   * the parent, e.g. current_user
   */
  private appendNamespaceToSelector(pluginId: Selector, namespace?: PluginNamespaceInfo, isGlobalProp?: boolean) {
    // todo clean this up isglobalprop is a weird leaky abstraction?
    if (!namespace) {
      return pluginId
    }

    const selector = [...pluginId] // we don't want to mutate the pluginId as this causes problems, make a copy
    const pluginIsSharedProperty = SHARED_PLUGINS_BETWEEN_GLOBAL_WIDGETS_AND_PARENT.includes(selector[0])

    if (isGlobalProp) {
      const parentNamespace = namespace.getParentNamespace()
      if (parentNamespace) {
        selector[0] = addNamespace(parentNamespace, selector[0])
      }
    } else if (!pluginIsSharedProperty) {
      selector[0] = addNamespace(namespace, selector[0])
    }

    return selector
  }

  private clearTopologicalOrderCache() {
    // we have to clear the cache whenever we mutate the dep graph
    this.cachedTopologicalOrder = null
  }

  private getNodeNames(): string[] {
    return Object.keys(this.getNodes())
  }

  private hasTemplateString(id: string) {
    return this.depGraph.getNodeData(id).templateString !== undefined
  }

  private resolveToValidNode(selector: Selector): Selector {
    let candidates
    if (!this.selectorInListView(selector)) {
      candidates = inits(selector)
    } else {
      candidates = inits(_.filter(selector, (v, index) => index !== 1))
    }
    const n = candidates.length
    for (let i = 0; i < n; i += 1) {
      const candidate = candidates[n - 1 - i]
      if (this.depGraph.hasNode(ss(candidate))) {
        return candidate
      }
    }
    return null!
  }

  private updateNode(selector: Selector, templateString: string, namespace?: PluginNamespaceInfo) {
    const id = ss(selector)
    let data: NodeData = { selector, templateString }
    if (namespace) {
      data = this.addNamespaceToData(data, namespace)
    }
    if (!this.depGraph.hasNode(id)) {
      this.clearTopologicalOrderCache()

      this.depGraph.addNode(id, data)
    } else {
      this.mergeNodeData(selector, data)
    }
  }

  private addNamespaceToData(data: NodeData, namespace: PluginNamespaceInfo) {
    const namespaceString = ss(namespace.getNamespace())
    data.namespace = namespaceString // store the namespace in the node of the dependency graph so we can look it up
    if (namespace.getOriginalId()) {
      data.originalId = namespace.getOriginalId()
    }
    return data
  }

  private mergeNodeData(selector: Selector, update: any) {
    this.clearTopologicalOrderCache()
    const id = ss(selector)
    ;(this.depGraph as any).setNodeData(id, {
      ...this.depGraph.getNodeData(id),
      ...update,
    })
  }

  /**
   * A modified BFS to check whether its possible to get from start node to
   * end node without passing through the ignore node. This is useful to find
   * out if a specific node is the only thing triggering an update.
   *
   * e.g. all children in a listview get triggered when listview.instances changes.
   * Sometimes we want to not change a node if it was only triggered by a listview.instances change.
   * However, we have to make sure something ELSE isn't causing it to change as well
   * (like if it was hooked up to a query). In this case we can run
   * isReachableWithout(trigger, ['child', 'value'], 'listview.instaces'). If it returns true,
   * there is another path to get to this child and we should re-render its value.
   */
  isReachableWithout(start1: Selector, end1: Selector, ignore: Selector) {
    // ListView children selectors are of the form ['textinput1', index, 'value']
    // since there are multiple of them. However, when we traverse the dependency graph
    // the nodes are only the non-listview selectors (['textinput1', 'value']).
    // These two lines are essentially just removing the index to convert the
    // listview type selector into a normal selector (e.g. ['textinput1', index, 'value'] -> ['textinput1', 'value'])
    const start = start1.filter((e) => !Number.isInteger(e as any))
    const end = end1.filter((e) => !Number.isInteger(e as any))

    const queue = []
    const visited: { [key: string]: boolean } = {}

    queue.push(start)
    visited[ss(start)] = true
    while (queue.length !== 0) {
      const curr = queue.shift()
      const dependents = this.getDirectDependentsOf(curr!) // curr cannot be undefined because of the queue.length != 0 check
      for (const dependent of dependents) {
        const dependentSelector = dependent.selector
        if (ss(dependentSelector) === ss(end)) {
          return true
        }

        if (!visited[ss(dependentSelector)] && ss(dependentSelector) !== ss(ignore)) {
          visited[ss(dependentSelector)] = true
          queue.push(dependentSelector)
        }
      }
    }
    return false
  }

  getAncestorListViewSelector(selector: Selector): string | null {
    if (!selector || !selector.length) {
      return null
    }
    const rootId = selector[0]
    const container = this.parentPointers[rootId]
    if (container) {
      if (this.getNodes()[ss([container, 'instances'])]) {
        return container
      } else {
        return this.getAncestorListViewSelector([container])
      }
    }
    return null
  }

  private updateChild(
    childSelector: Selector,
    parentSelectors: Selector[],
    templateString: string,
    namespace?: PluginNamespaceInfo,
  ) {
    this.updateNode(childSelector, templateString, namespace)
    const id = ss(childSelector)
    const currentParentIds = this.depGraph.dependenciesOf(id)

    // Clear out the current dependencies
    this.clearTopologicalOrderCache()
    currentParentIds.forEach((pid) => this.depGraph.removeDependency(id, pid))

    // Add the new dependencies
    const newDependencies = parentSelectors.map((s) => this.resolveToValidNode(s))
    newDependencies.forEach((s) => this.addDependency(childSelector, s))

    // Add back the dependency that connects this to its container
    if (this.parentPointers[childSelector[0]]) {
      const container = this.parentPointers[childSelector[0]]

      const listviewAncestor = this.getAncestorListViewSelector(childSelector)
      if (listviewAncestor) {
        this.addDependency(childSelector, [listviewAncestor, 'instances'])
      }
      if (this.getNodes()[ss([container])]) {
        this.addDependency([container], childSelector)
      }
    }

    // Test if cyclic dependency found
    try {
      this.depGraph.dependenciesOf(id)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Cyclic dependency found', err)
      message.error(err.message)
      newDependencies.forEach((s) => this.depGraph.removeDependency(id, ss(s)))
    }
  }

  private addNode(selector: Selector, namespace?: PluginNamespaceInfo) {
    const id = ss(selector)
    if (!this.depGraph.hasNode(id)) {
      this.clearTopologicalOrderCache()
      let data: NodeData = { selector }
      if (namespace) {
        data = this.addNamespaceToData(data, namespace)
      }
      this.depGraph.addNode(id, data)
    }
  }

  private addDependency(from: Selector, to: Selector, check = false) {
    if (!from || !to) {
      return
    }
    this.clearTopologicalOrderCache()
    this.depGraph.addDependency(ss(from), ss(to))
    if (check) {
      try {
        this.depGraph.dependenciesOf(ss(from))
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Cyclic dependency found', err)
        message.error(err.message)
        this.depGraph.removeDependency(ss(from), ss(to))
      }
    }
  }

  private copyPlugin(pluginId: string, newId: string) {
    _.forEach(this.getNodes(), ({ selector, ...properties }, id) => {
      if (selector[0] === pluginId) {
        const newSelector = [newId].concat(selector.slice(1))
        const newKey = ss(newSelector)
        this.getNodes()[newKey] = {
          selector: newSelector,
          ...properties,
        }
        this.clearTopologicalOrderCache()
        this.depGraph.incomingEdges[newKey] = this.depGraph.incomingEdges[id].slice(0)
        this.depGraph.outgoingEdges[newKey] = this.depGraph.outgoingEdges[id].slice(0)
        this.depGraph.incomingEdges[newKey].map((inc) => this.depGraph.outgoingEdges[inc].push(newKey))
        this.depGraph.outgoingEdges[newKey].map((out) => this.depGraph.incomingEdges[out].push(newKey))
      }
    })
  }

  getSize() {
    return this.depGraph.size()
  }

  getNodes() {
    return this.depGraph.nodes
  }

  lookupTemplateString(selector: Selector) {
    const id = ss(selector)
    return this.depGraph.getNodeData(id).templateString
  }

  getNamespaceFromNodeData(nodeData: NodeData) {
    const namespaceString = nodeData.namespace
    const originalId = nodeData.originalId
    if (namespaceString) {
      return new PluginNamespaceInfoImpl(ds(namespaceString), originalId)
    }
  }

  /**
   * TODO - dmitriy to clean this up
   *
   * This allows you to lookup whether a specific node has a namespace in the dependency graph.
   * If this is a "root" selector like "textinput1" we check it's node property for a namespace.
   *
   * If this is a nested node "textinput1.value" we check that node and all the way up its parent
   * to see if its namespaced. I don't think this is actually used right now but its nice and save
   * us a lot of storage with only having to store the namespace on the root node and not copy it
   * for every subproperty.
   *
   */
  lookupNamespace(selector: Selector): PluginNamespaceInfo | undefined {
    //should be much cleaner
    if (selector.length === 1) {
      if (this.depGraph.hasNode(selector[0])) {
        const node = this.depGraph.getNodeData(selector[0])
        const namespaceString = node.namespace
        const originalId = node.originalId
        return namespaceString ? new PluginNamespaceInfoImpl(ds(namespaceString), originalId) : undefined
      } else {
        return undefined
      }
    } else {
      if (this.depGraph.hasNode(ss(selector))) {
        const node = this.depGraph.getNodeData(ss(selector))
        const namespaceString = node.namespace
        const originalId = node.originalId
        if (namespaceString) {
          return new PluginNamespaceInfoImpl(ds(namespaceString), originalId)
        } else {
          return this.lookupNamespace(selector.slice(0, -1))
        }
      } else {
        return undefined
      }
    }
  }

  lookupUpdatesAsync(selector: Selector) {
    const id = ss(selector)
    try {
      return this.depGraph.getNodeData(id).updatesAsync || []
    } catch (e) {
      logger.warn('Got an error when fetching updates async for selector ', selector)
      return []
    }
  }

  getObjectSelectors(id: string): Selector[] {
    return this.topologicalSort().filter((selector) => selector[0] === id && selector.length !== 1)
  }

  getDependenciesOf(selector: Selector): Immutable.Set<NodeData> {
    if (selector.length === 0) {
      return Immutable.Set()
    }
    const id = ss(selector)
    if (this.depGraph.hasNode(id)) {
      return Immutable.Set(this.depGraph.dependenciesOf(id).map((cid) => this.depGraph.getNodeData(cid)))
    }
    return this.getDependenciesOf(selector.slice(0, selector.length - 1))
  }

  getDirectDependenciesOf(selector: Selector): Immutable.Set<NodeData> {
    if (selector.length === 0) {
      return Immutable.Set()
    }
    const id = ss(selector)
    if (this.depGraph.hasNode(id)) {
      return Immutable.Set(this.depGraph.outgoingEdges[id].map((cid) => this.depGraph.getNodeData(cid)))
    }

    // FIXME: this should probably be this.getDirectDependenciesOf(selector.slice(0, selector.length -1)), but we need to investigate further
    return this.getDependenciesOf(selector.slice(0, selector.length - 1))
  }

  getDirectDependentsOf(selector: Selector): Immutable.Set<NodeData> {
    if (selector.length === 0) {
      return Immutable.Set()
    }
    const id = ss(selector)
    if (this.depGraph.hasNode(id)) {
      return Immutable.Set(this.depGraph.incomingEdges[id].map((cid) => this.depGraph.getNodeData(cid)))
    }
    return this.getDirectDependentsOf(selector.slice(0, selector.length - 1))
  }

  selectorInListView(selector: Selector) {
    return this.getAncestorListViewSelector(selector) != null
  }

  getDependantsOf(selector: Selector): Immutable.Set<NodeData> {
    if (selector.length === 0) {
      return Immutable.Set()
    }

    let id
    if (!this.selectorInListView(selector)) {
      id = ss(selector)
    } else {
      id = ss(_.filter(selector, (v, index) => index !== 1))
    }

    if (this.depGraph.hasNode(id)) {
      return Immutable.Set(this.depGraph.dependantsOf(id).map((cid) => this.depGraph.getNodeData(cid)))
    }

    return this.getDependantsOf(selector.slice(0, selector.length - 1))
  }

  getDependantsOfUpdate(parentObject: Map<string, any>, prefix: any = []): Immutable.Set<NodeData> {
    if (Immutable.Map.isMap(parentObject)) {
      return Immutable.Map(parentObject).reduce((childrenSet, v: string, k: any) => {
        const selector = prefix.concat([k])
        return this.getDependantsOf(selector)
          .union(this.getDependantsOfUpdate(parentObject.get(k), selector))
          .union(childrenSet)
      }, Immutable.Set())
    } else {
      return Immutable.Set()
    }
  }

  /** applyTemplateStringOverrides
   *
   * This function is called after the dependency graph is fully constructed. It loops
   * through the nodes and looks for nodes where it has a templateStringOverride function,
   * in which case it uses the function to replace it's templateString and then connects the
   * dependency links based off the new templateString
   *
   * This allows the template string to be dynamically generated based off the current template as well dependency graph

   */
  applyTemplateStringOverrides(appTemplate: AppTemplate) {
    Object.entries(this.depGraph.nodes).forEach(([, nodeData]) => {
      if (nodeData.getTemplateStringOverride) {
        const namespace = this.getNamespaceFromNodeData(nodeData)

        const newTemplateString = nodeData.getTemplateStringOverride(appTemplate, this, nodeData.selector[0], namespace)
        if (newTemplateString !== undefined) {
          const plugin = appTemplate.plugins.get(nodeData.selector[0])
          if (plugin == null) {
            return
          }
          this.updatePlugin(plugin, Immutable.fromJS({ [nodeData.selector[1]]: newTemplateString }))
        }
      }
    })
  }

  topologicalSort(): Selector[] {
    if (!this.cachedTopologicalOrder) {
      // eslint-disable-next-line no-console
      console.time('[DBG] computing topologicalSort')
      this.cachedTopologicalOrder = this.depGraph
        .overallOrder()
        .filter((id) => this.hasTemplateString(id))
        .map((id) => this.depGraph.getNodeData(id).selector)
      // eslint-disable-next-line no-console
      console.timeEnd('[DBG] computing topologicalSort')
    }
    return this.cachedTopologicalOrder
  }

  updatePlugin(plugin: PluginTemplate, data: any, namespace?: PluginNamespaceInfo) {
    const maskedTemplate = MaskResolver(plugin.subtype)(data)

    const containers = new Set<string>()

    if (plugin.position2) {
      containers.add(plugin.position2.container)
    }
    if (plugin.mobilePosition2) {
      containers.add(plugin.mobilePosition2.container)
    }

    const isGlobalProp = plugin.subtype === 'GlobalWidgetProp' || plugin.subtype === 'GlobalWidgetQuery'
    const propertyAnnotations = PluginPropertyAnnotationsResolver(plugin.subtype)

    this.updateObject(
      ds(plugin.id),
      maskedTemplate,
      containers,
      TemplateCodeCustomAliasesResolver(plugin.subtype)(maskedTemplate),
      namespace,
      isGlobalProp,
      propertyAnnotations,
    )

    this.updatePropertyAnnotations(plugin)
  }

  updatePropertyAnnotations(plugin: PluginTemplate): void {
    const propertyAnnotations = PluginPropertyAnnotationsResolver(plugin.subtype)
    const keys = Object.keys(propertyAnnotations)

    for (const key of keys) {
      const keySelector = [plugin.id, key]
      const propertyAnnotation = propertyAnnotations[key]
      let updatesSync = propertyAnnotation.updatesSync || []
      if (typeof updatesSync === 'function') {
        // we use this to specify dependencies on plugins that have a dynamic
        // template structure, e.g. the table plugin can have a variable
        // number of action buttons
        updatesSync = updatesSync(plugin.get('template'))
      }
      for (const property of updatesSync) {
        this.addDependency([plugin.id, property], keySelector, true)
      }

      let updatesAsync
      if (_.isFunction(propertyAnnotation.updatesAsync)) {
        updatesAsync = propertyAnnotation.updatesAsync(plugin) || []
      } else {
        updatesAsync = propertyAnnotation.updatesAsync || []
      }
      if (updatesAsync && updatesAsync.length > 0) {
        for (const property of updatesAsync) {
          this.addDependency([plugin.id, property], keySelector, true)
        }
        this.mergeNodeData(keySelector, {
          updatesAsync,
        })
      }

      const getTemplateStringOverride = propertyAnnotation.getTemplateStringOverride
      if (getTemplateStringOverride) {
        this.mergeNodeData(keySelector, { getTemplateStringOverride })
      }
    }
  }

  updateObject(
    selector: Selector,
    data: any,
    containers?: Set<string>,
    templateAliasesFn?: (selector: Selector) => { [key: string]: string },
    namespace?: PluginNamespaceInfo,
    isGlobalProp?: boolean,
    propertyAnnotations?: PropertyAnnotations,
  ) {
    // See if the node exists. If it doesn't, add it to the dependency graph if the parent also exists
    if (!this.depGraph.nodes[ss(selector)] && this.depGraph.nodes[ss(selector.slice(0, -1))]) {
      this.addObject(selector, data)
      // If the child gets updated, then the parent must be marked as dirty
      this.addDependency(selector.slice(0, -1), selector)
    }

    // Add dependency that connects this to its container
    // and keep track of which container this object belongs to
    containers?.forEach((container) => {
      this.parentPointers[selector[0]] = container
      if (container !== '') {
        if (this.getNodes()[ss([container, 'instances'])]) {
          this.addDependency(selector, [container, 'instances'])
        }
        if (this.getNodes()[ss([container])]) {
          this.addDependency([container], selector)
        }
      }
    })

    if (Immutable.isCollection(data)) {
      data.map((v, k) => {
        const propertySelector = selector.concat([k])
        if (typeof v === 'string') {
          const unescapeRetoolExpressions = !!propertyAnnotations?.[k]?.unescapeRetoolExpressions

          const parents = templateAliasesFn?.(propertySelector)
            ? computeDependenciesWithTemplateAliases(v, templateAliasesFn(propertySelector), {
                unescapeRetoolExpressions,
              })
            : computeTemplateStringDependencies(v, { unescapeRetoolExpressions })
          // for namespaced components we have to remember that their templates are scoped inside the namespace
          // so 'textinput1.value' really means 'global1::textinput1.value'. Below we check whether out current node
          // is namespaced and then append the namespace to all the parsed dependencies before setting up edges
          // TODO - dmitriy do we even need this first part of the || - should we just use lookupNamespace?
          const computedNamespace = namespace || this.lookupNamespace(propertySelector)
          const namespacedParents = parents.map((p) =>
            this.appendNamespaceToSelector(p, computedNamespace, isGlobalProp),
          )
          return this.updateChild(propertySelector, namespacedParents, v, computedNamespace)
        } else {
          return this.updateObject(propertySelector, v, containers)
        }
      })
    } else {
      this.updateChild(selector, [], data, namespace)
    }
  }

  addPlugin(plugin: PluginTemplate, data: any) {
    const maskedTemplate = MaskResolver(plugin.subtype)(data)
    this.addObject(ds(plugin.id), maskedTemplate, plugin.namespace)
  }

  addObject(selector: Selector, object: any, namespace?: PluginNamespaceInfo) {
    if (Immutable.isCollection(object)) {
      this.addNode(selector, namespace)
      object.map((v, k) => {
        const childSelector = selector.concat([k])
        this.addObject(childSelector, v)

        // If the child gets updated, then the parent must be marked as dirty
        this.addDependency(selector, childSelector)
      })
    } else {
      this.addNode(selector, namespace)
    }
  }

  deletePlugin(pluginId: string) {
    _.forEach(this.getNodes(), ({ selector }, id) => {
      if (selector[0] === pluginId) {
        this.clearTopologicalOrderCache()
        this.depGraph.removeNode(id)
      }
    })
  }

  // Delete nodes matching the selectors and any "child" nodes.
  deleteObjects(selectors: Selector[]) {
    _.forEach(this.getNodes(), ({ selector: nodeSelector }, id) => {
      selectors.forEach((selector) => {
        // Checks if the 2nd array starts with the 1st array.
        if (_.difference(selector, nodeSelector).length === 0) {
          this.clearTopologicalOrderCache()
          this.depGraph.removeNode(id)
        }
      })
    })
  }

  renamePlugin(pluginId: string, newId: string) {
    this.copyPlugin(pluginId, newId)
    this.deletePlugin(pluginId)
  }

  hasNode(selector: Selector) {
    return this.depGraph.hasNode(ss(selector))
  }
}

type VariableRange = [number, number]
export function renameVariables(code: string, before: string, after: string) {
  const wrappedCode = `${before} => {\n${code}\n}`
  const ast = parseJSExpression(wrappedCode, { range: true })
  const scopeManager = escope.analyze(ast)
  const scope = scopeManager.acquire(ast.body[0].expression)
  const variableOccurrences: VariableRange[] = scope.variables[0].references
    .map((ref: any) => ref.identifier.range)
    .sort((first: VariableRange, second: VariableRange) => first[0] - second[0] || first[1] - second[1])
    .reverse()
  const renamedCode = variableOccurrences.reduce((code: any, [start, end]: [any, any]) => {
    return code.substring(0, start) + after + code.substring(end)
  }, wrappedCode)
  const lines = renamedCode.split('\n')
  return lines.slice(1, lines.length - 1).join('\n')
}

export function updateCode(code: string, oldVarName: string, newVarName: string) {
  const newCode = code.replace(ANY_TEMPLATE_REGEX, (orig: any, code: any) => {
    let result = code
    try {
      result = renameVariables(code, oldVarName, newVarName)
    } catch (err) {
      //TODO: (BUG-711) fix the cause of this throwing errors often. log deleted since it was noisy.
    }
    if (result === code) {
      return orig
    } else {
      return `{{${result}}}`
    }
  })
  return newCode
}

export function updateProperty(templateString: any, oldId: any, newId: any, propertyType: any) {
  let newValue: any, newValueExists: any
  if (propertyType === 'pluginId') {
    const ids = templateString?.split(',') ?? []
    newValue = ids.map((id: any) => (id === oldId ? newId : id)).join(',')
    newValueExists = newValue !== templateString
  } else if (propertyType === 'pluginIdList') {
    //Sometimes we're passing templateString as Immutable List and sometimes we're passing it as an array with all the properties in index 0
    const transformedTemplateString = Immutable.isList(templateString)
      ? templateString.toJS()
      : templateString[0].split(',')
    newValue = transformedTemplateString.map((pid: any) => (pid === oldId ? newId : pid))
    newValue = newValue.filter((pid: any) => pid !== '')
    newValueExists = !_.isEqual(newValue, transformedTemplateString)
  } else if (typeof templateString === 'string') {
    const renamedTemplateString = updateCode(templateString, oldId, newId)
    if (templateString !== renamedTemplateString) {
      newValue = renamedTemplateString
      newValueExists = true
    }
  } else if (Immutable.isCollection(templateString)) {
    newValueExists = false
    newValue = templateString.map((value, key) => {
      let subpropertyType
      if (typeof propertyType === 'function') {
        subpropertyType = propertyType(key).type
      }
      const res = updateProperty(value, oldId, newId, subpropertyType)
      if (res.newValueExists) {
        newValueExists = true
        return res.newValue
      }
      return value
    })
  }
  return { newValue, newValueExists }
}

// Say we have a templateString like `{{ templateAlias1 + templateAlias2 }}`
// and templateAliases like { templateAlias1: 'query1.inputs.templateAlias1' }
// `templateAlias1` isn't part of the dependency graph, but `query1` is.
// so we need to follow dependencies on the templateAliases we run into, so that
// we correctly depend on `query1`
function computeDependenciesWithTemplateAliases(
  templateString: string,
  templateAliases: { [key: string]: string },
  options: { unescapeRetoolExpressions: boolean },
) {
  const templateAliasNames = Object.keys(templateAliases)
  const maybeCustomSelectors = computeTemplateStringDependencies(templateString, options)

  const res: Selector[] = []
  maybeCustomSelectors.forEach((maybeCustomSelector) => {
    const maybeTemplateAlias = ss(maybeCustomSelector)
    const isTemplateAlias = templateAliasNames.find((varname) => maybeTemplateAlias.startsWith(varname))
    if (isTemplateAlias) {
      res.push(ds(templateAliases[maybeTemplateAlias]))
    } else {
      res.push(maybeCustomSelector)
    }
  })
  return res
}

/**
 * Given a template string, returns the selectors that this string
 * depends on.
 *
 * For example:
 *
 * >> "{{ foo.bar + baz }}"
 * [['foo', 'bar], ['baz']]
 */
export function computeTemplateStringDependencies(
  templateString: string,
  options: { unescapeRetoolExpressions: boolean },
  ignoredGlobals: Set<string> = globalScopeFuncs,
) {
  const parents = [] as Selector[]
  templateString.replace(ANY_TEMPLATE_REGEX, (_, code) => {
    let retoolExpression
    if (options.unescapeRetoolExpressions) {
      // The naieve regex fails because for an expression like this:
      // -> retoolExpression = code.replace(/\\"/g, '"')
      //
      //   `{{ a.b.c.d.e.f.g == " foo \\" bar " }}`
      //
      try {
        retoolExpression = JSON.parse(`{ "v": "${code}" }`).v
      } catch (err) {
        sampleSentryException(
          0.01,
          new Error(`Could not unescape expression

${code}

in

${templateString}`),
        )
        retoolExpression = code
      }
    } else {
      retoolExpression = code
    }
    try {
      let parsed
      try {
        parsed = parseJSExpression(retoolExpression)
      } catch (err) {
        parsed = parseJSExpression(`(${retoolExpression})`)
      }

      const parsedVars = undeclaredV2(parsed)
      const vars: string[] = Array.from(parsedVars)
      const varSelectors = vars.map((v) => ds(v))

      // The bug that was fixed now correctly returns
      // functions (e.g. moment(a) -> ["moment", "a"])
      // Since a lot of Retool was built on the incorrect behavior
      // of only returning ["a"], we filter any ignoredGlobals
      // to keep the behavior the same (e.g. moment, numbro, etc.)
      // This is most obvious in query library where we don't want
      // {{moment(a)}} to create two inputs: moment and a.
      const varArr = varSelectors?.filter((v) => {
        return !ignoredGlobals.has(v[0])
      })
      varArr.forEach((v) => parents.push(v))
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error in computeTemplateStringDependencies', retoolExpression, err)
    }
    return ''
  })
  return parents
}

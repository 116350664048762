import * as evaluator from 'common/evaluator'
import {
  ADHOC_RESOURCE_PROPERTIES,
  AppModelType,
  LibraryQueryTemplateShape,
  PlaygroundModel,
  PlaygroundQuery,
  QueryEditorType,
  QueryTemplate,
  QueryTemplateShape,
  PlaygroundQuerySave,
  QueryUsage,
  Resource,
  ResourceFromServer,
} from 'common/records'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { formatSqlWithTemplate } from 'common/sqlFormatter'
import { ds, ss } from 'common/utils'
import { Message } from 'components/design-system'
import { TemplateResolver } from 'components/plugins'
import queryModels from 'components/plugins/datasources/index'
import { DEFAULT_TEMPLATE_KEYS } from 'components/plugins/datasources/modelConstants'
import { showWarning } from 'components/standards/Modal'
import { parse, print } from 'graphql'
import Immutable from 'immutable'
import { find, get, keyBy, orderBy, pickBy, values } from 'lodash'
import moment, { Moment } from 'moment'
import { callInternalApi } from 'networking'
import { JSON_HEADERS } from 'networking/util'
import { RETOOL_VERSION } from 'retoolConstants'
import { dispatch as globalDispatch, RetoolAction, RetoolActionDispatcher, RetoolState, RetoolThunk } from 'store'
import {
  appModelSelector,
  playgroundEnvironmentSelector,
  playgroundQueryTemplateSelector,
  playgroundResourcesSelector,
  playgroundSavedOrganizationQueryIdsSelector,
  playgroundSavedQueriesSelector,
  playgroundSavedUserQueryIdsSelector,
  playgroundSelectedQuerySaveResourceSelector,
  playgroundSelectedQuerySaveSelector,
  playgroundSelectedQuerySelector,
  playgroundSelectedResourceSelector,
  playgroundSelector,
} from 'store/selectors'
import { RetoolAPIDispatcher } from 'store/storeUtils'
import { computeTemplateStringDependencies } from './appModel/dependencyGraph'
import { MODEL_UPDATE } from './appModel/model'
import { callApi } from './callApi'

const SELECT_RESOURCE = 'PLAYGROUND_SELECT_RESOURCE'
const SELECT_QUERY = 'PLAYGROUND_SELECT_QUERY'
const SELECT_QUERY_SAVE = 'PLAYGROUND_SELECT_QUERY_SAVE'
const QUERY_EDITOR_UPDATE = 'PLAYGROUND_QUERY_EDITOR_UPDATE'
const QUERY_INPUT_VALUE_UPDATE = 'PLAYGROUND_QUERY_INPUT_VALUE_UPDATE'
const RESET_QUERY_TEMPLATE = 'PLAYGROUND_DISCARD_QUERY_CHANGES'
const QUERY_USAGES_UPDATE = 'PLAYGROUND_UPDATE_QUERY_USAGES'
const REFORMAT_PLAYGROUND_QUERY = 'REFORMAT_PLAYGROUND_QUERY'

enum QUERY_RUN_EVENT {
  REQUEST = 'PLAYGROUND_REQUEST_QUERY_RUN',
  SUCCESS = 'PLAYGROUND_RECEIVE_QUERY_RUN',
  FAILURE = 'PLAYGROUND_FAILURE_QUERY_RUN',
}

enum RESOURCE_SCHEMA_EVENT {
  REQUEST = 'PLAYGROUND_REQUEST_RESOURCE_SCHEMA',
  SUCCESS = 'PLAYGROUND_RECEIVE_RESOURCE_SCHEMA',
  FAILURE = 'PLAYGROUND_FAILURE_RESOURCE_SCHEMA',
}

export enum SAVED_QUERIES_EVENT {
  REQUEST = 'PLAYGROUND_REQUEST_SAVED_QUERIES',
  SUCCESS = 'PLAYGROUND_RECEIVE_SAVED_QUERIES',
  FAILURE = 'PLAYGROUND_FAILURE_SAVED_QUERIES',
}

export enum QUERY_SAVES_EVENT {
  REQUEST = 'PLAYGROUND_REQUEST_QUERY_SAVES',
  SUCCESS = 'PLAYGROUND_RECEIVE_QUERY_SAVES',
  FAILURE = 'PLAYGROUND_FAILURE_QUERY_SAVES',
}

enum QUERY_CREATE_EVENT {
  REQUEST = 'PLAYGROUND_REQUEST_QUERY_CREATE',
  SUCCESS = 'PLAYGROUND_RECEIVE_QUERY_CREATE',
  FAILURE = 'PLAYGROUND_FAILURE_QUERY_CREATE',
}

enum QUERY_SAVE_EVENT {
  REQUEST = 'PLAYGROUND_REQUEST_QUERY_SAVE',
  SUCCESS = 'PLAYGROUND_RECEIVE_QUERY_SAVE',
  FAILURE = 'PLAYGROUND_FAILURE_QUERY_SAVE',
}

enum QUERY_DELETE_EVENT {
  REQUEST = 'PLAYGROUND_REQUEST_QUERY_DELETE',
  SUCCESS = 'PLAYGROUND_RECEIVE_QUERY_DELETE',
  FAILURE = 'PLAYGROUND_FAILURE_QUERY_DELETE',
}

enum QUERY_SHARE_EVENT {
  REQUEST = 'PLAYGROUND_REQUEST_QUERY_SHARE',
  SUCCESS = 'PLAYGROUND_RECEIVE_QUERY_SHARE',
  FAILURE = 'PLAYGROUND_FAILURE_QUERY_SHARE',
}

const TEMPLATE_KEYS_TO_IMPORT_FROM_QUERY_LIBRARY: Set<keyof QueryTemplateShape> = new Set([
  'runWhenModelUpdates',
  'query',
  'metadata',
])

export const appSpecificTemplateValuesFilter = (_value: unknown, key: string) =>
  DEFAULT_TEMPLATE_KEYS.has(key) && !TEMPLATE_KEYS_TO_IMPORT_FROM_QUERY_LIBRARY.has(key as keyof QueryTemplateShape)

export const resourceSpecificTemplateValuesFilter = (_value: unknown, key: string) =>
  !appSpecificTemplateValuesFilter(_value, key)

export const getResourceForQuery = (query: PlaygroundQuery, resources: Resource[]) => {
  let newResource = null
  if (query.resourceId) {
    newResource = find(resources, { production: { id: query.resourceId } } as any) as Resource
  } else {
    // is adhoc resource
    switch (query.adhocResourceType) {
      case ADHOC_RESOURCE_PROPERTIES.restapi.editorType:
        newResource = find(resources, { name: ADHOC_RESOURCE_PROPERTIES.restapi.name }) as Resource
        break
      case ADHOC_RESOURCE_PROPERTIES.graphql.editorType:
        newResource = find(resources, { name: ADHOC_RESOURCE_PROPERTIES.graphql.name }) as Resource
        break
      default:
        // eslint-disable-next-line no-console
        console.log('Could not find resource of type', query.adhocResourceType)
    }
  }

  return newResource
}

/* Actions */
export function selectResource(resourceName: string): RetoolAPIDispatcher<any> {
  return async (dispatch: any, getState: () => RetoolState) => {
    const state = getState()
    const resources = playgroundResourcesSelector(state)
    const newResource = find(resources, { name: resourceName }) as Resource
    const query = playgroundSelectedQuerySelector(state)

    getDatabaseSchemaIfNecessary(getState(), dispatch, newResource.name)

    dispatch({
      type: SELECT_RESOURCE,
      payload: {
        newResource,
        query,
        savedQueryResource: getResourceForQuery(query!, resources),
      },
    })

    await updateAppModel(getState())
  }
}

export function selectQuery(newId = 0): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    const state = getState()
    const currentQuery = playgroundSelectedQuerySelector(state)

    const savedQueries = playgroundSavedQueriesSelector(state)

    const idNotFound = !(newId in savedQueries)
    if (idNotFound) {
      // eslint-disable-next-line no-console
      console.log(`Could not find query with id: ${newId}`)

      const userQueryIds = playgroundSavedUserQueryIdsSelector(state)
      const orgQueryIds = playgroundSavedOrganizationQueryIdsSelector(state)
      if (orgQueryIds.length === 0 && userQueryIds.length === 0) {
        // eslint-disable-next-line no-console
        console.log(`No queries to select`)
        return
      }

      // if someone tried to select a query (and specified a query id)
      if (newId > 0) {
        showWarning({
          title: "It looks like we couldn't find this query.",
          content: "It's possible that it hasn't been shared yet or has been deleted. ",
          okText: 'Ok',
        })
      }

      newId = userQueryIds[0] || orgQueryIds[0]
      // eslint-disable-next-line no-console
      console.log(`Selecting query ${newId}`)
    }

    const newQuery = savedQueries[newId]
    const resources = playgroundResourcesSelector(state)
    const newResource = getResourceForQuery(newQuery, resources)

    getDatabaseSchemaIfNecessary(getState(), dispatch, newResource!.name)

    dispatch({
      type: SELECT_RESOURCE,
      payload: {
        newResource,
        query: newQuery,
        savedQueryResource: getResourceForQuery(newQuery, resources),
      },
    })

    dispatch({
      type: SELECT_QUERY,
      payload: {
        currentQuery,
        newQuery,
      },
    })

    dispatch(getQuerySavesAndUsages(newId))

    await updateAppModel(getState())
  }
}

export function getQuerySavesAndUsages(id: number) {
  return async (dispatch: any, _getState: any) => {
    const [
      { queryUsages: rawQueryUsages },
      {
        payload: { saves },
      },
    ] = await Promise.all([
      callInternalApi({
        url: `/api/playground/${id}/usages`,
        method: 'GET',
      }),

      dispatch(getPlaygroundQuerySaves(id)),
    ])

    const querySavesTimestamps = saves?.reduce(
      (acc: { [x: string]: Moment }, curr: { id: string; createdAt: string }) => {
        acc[curr.id] = moment(curr.createdAt)
        return acc
      },
      {},
    )
    const queryUsages: QueryUsage[] = rawQueryUsages.map((usage: any) => ({
      pageUuid: usage.pageUuid,
      pageId: usage.pageId,
      pageName: usage.pageName,
      querySavedTimestamp: usage.querySaveId ? querySavesTimestamps[usage.querySaveId] : undefined,
      pinnedToLatestVersion: usage.pinnedToLatestVersion,
    }))
    dispatch({ type: QUERY_USAGES_UPDATE, payload: { queryUsages } })
  }
}

export function selectQuerySave(id: number): RetoolActionDispatcher {
  return async (dispatch) => {
    dispatch({
      type: SELECT_QUERY_SAVE,
      payload: id,
    })
  }
}

export function discardQueryChanges(id: number): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    const state = getState()

    const savedQuery = playgroundSavedQueriesSelector(state)[id]
    const resources = playgroundResourcesSelector(state)
    const selectedResource = playgroundSelectedResourceSelector(state)
    const resource = getResourceForQuery(savedQuery, resources) as Resource

    dispatch({
      type: SELECT_RESOURCE,
      payload: {
        newResource: resource,
        query: savedQuery,
        savedQueryResource: selectedResource,
      },
    })

    dispatch({
      type: RESET_QUERY_TEMPLATE,
      payload: {
        id,
        savedQuery,
        resource,
      },
    })

    await updateAppModel(getState())
  }
}

function queryEditorUpdateActionCreator(
  queryPath: (string | number)[],
  newValue: any,
  newInputs: Immutable.Map<string, any>,
  addedKeys: Immutable.Set<string>,
  removedKeys: Immutable.Set<string>,
) {
  return { type: QUERY_EDITOR_UPDATE, payload: { queryPath, newValue, newInputs, addedKeys, removedKeys } }
}

export function queryEditorUpdate(newValue: any): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    const state = getState()
    const playgroundState = playgroundSelector(state)
    const selectedQuery = playgroundSelectedQuerySelector(state)
    const selectedResource = playgroundSelectedResourceSelector(state)
    const resourceName = selectedResource.name

    if (selectedQuery == null) {
      // eslint-disable-next-line no-console
      console.error('Tried to update query editor but selectedQuery was null!')
      return
    }
    const queryId = selectedQuery.id

    const queryPath = ['queryTemplates', queryId, resourceName]
    const currentQueryTemplate = playgroundState.getIn(queryPath)

    const newState = playgroundState.mergeIn(['queryTemplates', queryId, resourceName], newValue)
    const newQueryTemplate = newState.getIn(queryPath)

    const currentInputs = deriveQueryInputs(currentQueryTemplate)
    const newInputs = deriveQueryInputs(newQueryTemplate)

    const currentInputKeys = Immutable.Set(currentInputs.keys())
    const newInputKeys = Immutable.Set(newInputs.keys())
    const addedKeys = newInputKeys.subtract(currentInputKeys)
    const removedKeys = currentInputKeys.subtract(newInputKeys)

    dispatch(queryEditorUpdateActionCreator(queryPath, newValue, newInputs, addedKeys, removedKeys))

    await updateAppModel(getState())
  }
}

export function queryInputValueUpdate(inputKey: string, newValue: any): RetoolActionDispatcher {
  return async (dispatch: any, getState) => {
    const state = getState()
    const selectedQuery = playgroundSelectedQuerySelector(state)
    const queryTemplate = playgroundQueryTemplateSelector(state)
    const selectedResource = playgroundSelectedResourceSelector(state)
    const resourceName = selectedResource.name
    await dispatch({
      type: QUERY_INPUT_VALUE_UPDATE,
      payload: { inputKey, newValue, resourceName, queryId: selectedQuery!.id },
    })

    await updateAppModel(getState())

    if (queryTemplate.get('runWhenModelUpdates')) {
      dispatch(runQuery())
    }
  }
}

/**
 *
 * This function is required to
 *   1. evaluate variables in the playground
 *   2. give scope CodeEditors in the playground (they look directly in the appModel)
 *
 * WARNING: This clears the app model and should not be called when the editor is open
 * This should only be called in the playground
 */
async function updateAppModel(state: RetoolState) {
  const queryTemplate = playgroundQueryTemplateSelector(state).toJS()
  const appModel = appModelSelector(state)

  // Evaluate inputs to the playground
  const inputs = Object.keys(queryTemplate.importedQueryDefaults || {})
  let newModel = appModel.clearValues() as AppModelType
  for (const input of inputs) {
    if (!(input in queryTemplate.importedQueryInputs)) continue

    const templateVal = queryTemplate.importedQueryDefaults[input]
    const val = await evaluator.evaluateSafe(queryTemplate.importedQueryDefaults, templateVal)
    newModel = newModel.setValue(ds(input), val)
  }

  // Write values to model
  globalDispatch({
    type: MODEL_UPDATE,
    payload: newModel,
  })
}

export function runQuery(): RetoolAPIDispatcher<any> {
  return async (dispatch: any, getState: () => RetoolState) => {
    const state = getState()
    const resource = playgroundSelectedResourceSelector(state)

    // TODO(yogi) fetch this from playground UI
    const environment = playgroundEnvironmentSelector(state)
    const queryModel = queryModels[resource.editorType]
    const queryTemplate = playgroundQueryTemplateSelector(state).toJS()
    const query = playgroundSelectedQuerySelector(state)

    if (!query) {
      // eslint-disable-next-line no-console
      console.log('Could not find query')
      return
    }

    retoolAnalyticsTrack('Playground Query Run', {
      resourceType: resource.resourceType,
      adhocResourceType: query.adhocResourceType,
    })

    try {
      const appModel = appModelSelector(state)
      const body = await queryModel.getPreviewParams(queryTemplate, { ...appModel.get('values').toJS() })
      const queryType = resource.editorType
      const res = await dispatch(
        callApi({
          endpoint: '/api/playground/query',
          body: JSON.stringify({
            resourceName: resource.name,
            queryType,
            environment,
            queryName: query.name,
            queryId: query.id,
            ...body,
          }),
          method: 'POST',
          headers: JSON_HEADERS,
          types: [
            {
              type: QUERY_RUN_EVENT.REQUEST,
              meta: { queryId: query.id },
            },
            {
              type: QUERY_RUN_EVENT.SUCCESS,
              meta: { queryId: query.id },
            },
            {
              type: QUERY_RUN_EVENT.FAILURE,
              meta: { queryId: query.id },
            },
          ],
        }),
      )

      if (queryModel.handleResponse) {
        const handler = queryModel.handleResponse(queryTemplate)
        if (res.type === QUERY_RUN_EVENT.SUCCESS && handler) {
          const response = await handler(res.payload)
          if (response) {
            dispatch({ type: QUERY_RUN_EVENT.SUCCESS, payload: response })
          }
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error running playground query', e)
    }
  }
}

const QUERY_LIBRARY_TEMPLATE_OVERRIDES: Partial<LibraryQueryTemplateShape> = {
  queryTimeout: '100000', // 100 seconds, arbitrary, just has to be longer than 10s
  retoolVersion: RETOOL_VERSION, // so that we can run migrations based on version
}

function createQueryRequestActionCreator() {
  return { type: QUERY_CREATE_EVENT.REQUEST }
}
function createQuerySuccessActionCreator(query: any) {
  return { type: QUERY_CREATE_EVENT.SUCCESS, payload: { query } }
}
function createQueryFailureActionCreator(error: any) {
  return { type: QUERY_CREATE_EVENT.FAILURE, payload: { error } }
}

interface CreateQueryOptions {
  name?: string
  description?: string
  data?: Partial<LibraryQueryTemplateShape>
  shared?: boolean
}

export const createQuery = (resource: ResourceFromServer, options?: CreateQueryOptions): any => {
  return async (dispatch: any) => {
    const name = options?.name ?? 'Untitled'
    const description = options?.description ?? ''
    const resourceId = resource.production && resource.production.id
    // TODO rename adhocResourceType to adhocResourceEditorType
    const adhocResourceType = resource.editorType
    const shared = options?.shared ?? false
    let data = TemplateResolver(resource.editorType, resource)().merge(QUERY_LIBRARY_TEMPLATE_OVERRIDES)

    if (options?.data) {
      data = data.merge(options?.data)
    }

    dispatch(createQueryRequestActionCreator())
    try {
      const response = await callInternalApi({
        url: '/api/playground',
        method: 'POST',
        body: { name, description, data, resourceId, adhocResourceType, shared },
      })
      const { query } = response
      const newQuery = queryResponseToPlaygroundQuery(query)
      return dispatch(createQuerySuccessActionCreator(newQuery))
    } catch (err) {
      dispatch(createQueryFailureActionCreator(err))
      return
    }
  }
}

export function saveNewQuery(): RetoolAPIDispatcher<any> {
  return async (dispatch: any, getState: () => RetoolState) => {
    const selectedResource = playgroundSelectedResourceSelector(getState())

    retoolAnalyticsTrack('Playground Query Created', { resourceType: selectedResource.resourceType })

    await dispatch(createQuery(selectedResource))
    const userQueryIds = playgroundSavedUserQueryIdsSelector(getState())
    await dispatch(selectQuery(userQueryIds[0]))
    updateAppModel(getState())
  }
}

function saveQuerySuccessActionCreator(query: any, resource?: Resource | null) {
  return { type: QUERY_SAVE_EVENT.SUCCESS, payload: { query, resource } }
}
type SaveQueryBody = Partial<{
  name: string
  description: string
  shared: boolean
  data: { [s: string]: any }
  resourceId: string | number
  adhocResourceType: QueryEditorType
}>
function saveQuery(id: number, changeset: SaveQueryBody, resource?: Resource | null): RetoolThunk {
  return async (dispatch) => {
    const res = await callInternalApi({
      url: `/api/playground/${id}/save`,
      method: 'POST',
      body: changeset,
    })
    const { query } = res
    const newQuery = queryResponseToPlaygroundQuery(query)
    dispatch(saveQuerySuccessActionCreator(newQuery, resource))
    return res
  }
}

export function revertQueryToSelectedSave(): RetoolActionDispatcher {
  return async (dispatch: any, getState) => {
    const state = getState()
    const save = playgroundSelectedQuerySaveSelector(state)
    const query = playgroundSelectedQuerySelector(state)!
    const resource = playgroundSelectedQuerySaveResourceSelector(state)
    const { id, name, description, shared } = query

    retoolAnalyticsTrack('Playground Query Reverted', {})

    const body: SaveQueryBody = {
      name,
      description,
      shared,
      data: save?.template,
      resourceId: save?.resourceId,
      adhocResourceType: save?.adhocResourceType,
    }

    await dispatch(saveQuery(id, body, resource))

    await dispatch(selectQuery(query.id))
  }
}

export const deleteQuery = (id: number): RetoolAPIDispatcher<any> => {
  return async (dispatch: any, getState: () => RetoolState) => {
    const state = getState()
    const resource = playgroundSelectedResourceSelector(state)
    const query = playgroundSavedQueriesSelector(state)[id]
    const playgroundState = state.playground

    const savedOrganizationQueryIds = playgroundState.get('savedOrganizationQueryIds')
    const savedUserQueryIds = playgroundState.get('savedUserQueryIds')

    if (savedOrganizationQueryIds.concat(savedUserQueryIds).length <= 1) {
      // because as of 2019.10.31 the query library has no base state (if you don't
      // have any existing queries when you entery the library, one will be created
      // for  you), we prevent users from deleting the last query in the library.
      Message.error('Cannot delete query, the query library must contain at least one query.')
    } else {
      const savedOrganizationQueryIds = playgroundState.get('savedOrganizationQueryIds')
      const savedUserQueryIds = playgroundState.get('savedUserQueryIds')

      const allSavedQueryIds = savedOrganizationQueryIds.concat(savedUserQueryIds)
      const deletedQueryIndex = allSavedQueryIds.indexOf(id)

      let newSelectedQuery = 0
      if (allSavedQueryIds[deletedQueryIndex + 1]) {
        newSelectedQuery = allSavedQueryIds[deletedQueryIndex + 1]
      } else if (allSavedQueryIds[deletedQueryIndex - 1]) {
        newSelectedQuery = allSavedQueryIds[deletedQueryIndex - 1]
      }

      retoolAnalyticsTrack('Playground Query Deleted', {
        resourceType: resource.resourceType,
        adhocResourceType: query.adhocResourceType,
        shared: query.shared,
      })
      const result = await dispatch(
        callApi({
          endpoint: `/api/playground/${id}/delete`,
          method: 'DELETE',
          headers: JSON_HEADERS,
          types: [
            QUERY_DELETE_EVENT.REQUEST,
            { type: QUERY_DELETE_EVENT.SUCCESS, payload: { id } },
            QUERY_DELETE_EVENT.FAILURE,
          ],
        }),
      )
      if (result.type === QUERY_DELETE_EVENT.SUCCESS) {
        await dispatch(selectQuery(newSelectedQuery))
        Message.success('Successfully deleted query')
      } else {
        Message.error('Could not delete query')
      }
    }
  }
}

export function shareQuery(id: number): RetoolAPIDispatcher<any> {
  return async (dispatch: any, getState: () => RetoolState) => {
    const state = getState()
    const resource = playgroundSelectedResourceSelector(state)
    const query = playgroundSavedQueriesSelector(state)[id]

    retoolAnalyticsTrack('Playground Query Shared', {
      resourceType: resource.resourceType,
      adhocResourceType: query.adhocResourceType,
    })

    const result = await dispatch(
      callApi({
        endpoint: `/api/playground/${id}/share`,
        method: 'POST',
        headers: JSON_HEADERS,
        types: [QUERY_SHARE_EVENT.REQUEST, QUERY_SHARE_EVENT.SUCCESS, QUERY_SHARE_EVENT.FAILURE],
      }),
    )
    if (result.type === QUERY_SHARE_EVENT.SUCCESS) {
      Message.success('Successfully shared query')
    } else {
      Message.error('Could not share query')
    }
  }
}

export function getPlaygroundQuerySaves(queryId: number): any {
  return (dispatch: any) => {
    return dispatch(
      callApi({
        endpoint: `/api/playground/${queryId}/saves`,
        method: 'GET',
        headers: JSON_HEADERS,
        types: [QUERY_SAVES_EVENT.REQUEST, QUERY_SAVES_EVENT.SUCCESS, QUERY_SAVES_EVENT.FAILURE],
      }),
    )
  }
}

export function saveSelectedQuery(): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    const state = getState()
    const data = playgroundQueryTemplateSelector(state).toJS()
    const query = playgroundSelectedQuerySelector(state)!
    const resource = playgroundSelectedResourceSelector(state)
    const resourceId = resource.production && resource.production.id
    const adhocResourceType = resource.editorType
    const { id, name, description, shared } = query

    retoolAnalyticsTrack('Playground Query Edited', {
      resourceType: resource.resourceType,
      adhocResourceType: query.adhocResourceType,
    })

    const cleanedData = {
      ...data,
      importedQueryDefaults: pickBy(data.importedQueryDefaults, (_val, key) => key in data.importedQueryInputs),
    }
    const body: SaveQueryBody = { name, description, shared, data: cleanedData, resourceId, adhocResourceType }

    const resp = await dispatch(saveQuery(id, body, resource))
    dispatch(getPlaygroundQuerySaves(query.id))
    return resp
  }
}

export function editQueryName(name: string): any {
  return async (dispatch: any, getState: () => RetoolState) => {
    retoolAnalyticsTrack('Playground Query Renamed', {})

    const query = playgroundSelectedQuerySelector(getState())
    await dispatch(saveQuery(query!.id, { name }))
    dispatch(saveSelectedQuery())
  }
}

export const saveQueryTimeout = (): RetoolAPIDispatcher<any> => {
  return async (dispatch: any) => {
    const resp = await dispatch(saveSelectedQuery())
    if (resp?.query?.template?.queryTimeout) {
      Message.success('Changed query timeout')
    } else {
      Message.error('Error changing query timeout')
    }
  }
}

export function editQueryDescription(description: string): RetoolActionDispatcher {
  return (dispatch, getState) => {
    retoolAnalyticsTrack('Playground Query Description Edited', {})
    const query = playgroundSelectedQuerySelector(getState())
    dispatch(saveQuery(query!.id, { description }))
  }
}

const getDatabaseSchemaIfNecessary = (state: any, dispatch: any, resourceName: any) => {
  const schema = state.playground.getIn(['schemas', resourceName])
  if (schema) {
    return
  }

  dispatch(
    callApi({
      endpoint: `/api/resources/${encodeURIComponent(resourceName)}/schema?environment=${playgroundEnvironmentSelector(
        state,
      )}`,
      method: 'GET',
      headers: JSON_HEADERS,
      types: [
        RESOURCE_SCHEMA_EVENT.REQUEST,
        RESOURCE_SCHEMA_EVENT.SUCCESS,
        { type: RESOURCE_SCHEMA_EVENT.FAILURE, meta: { resourceName } },
      ],
    }),
  )
}

export function savedQueriesRequestActionCreator() {
  return { type: SAVED_QUERIES_EVENT.REQUEST }
}
export function savedQueriesSuccessActionCreator(savedQueries: any, savedUserQueryIds: any, savedOrgQueryIds: any) {
  return { type: SAVED_QUERIES_EVENT.SUCCESS, payload: { savedQueries, savedUserQueryIds, savedOrgQueryIds } }
}
export function savedQueriesFailureActionCreator(error: any) {
  return { type: SAVED_QUERIES_EVENT.FAILURE, payload: { error } }
}

export function reformatPlaygroundQuery(editorType: string): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    const state = getState()
    const selectedQuery = playgroundSelectedQuerySelector(state)
    const selectedResource = playgroundSelectedResourceSelector(state)

    const resourceName = selectedResource.name

    if (selectedQuery == null) return

    const queryId = selectedQuery.id
    const queryPath = ['queryTemplates', queryId, resourceName]

    dispatch({ type: REFORMAT_PLAYGROUND_QUERY, payload: { queryPath, editorType } })
  }
}

export function sortAndFormatSavedQueries(userQueries: any, orgQueries: any) {
  const toQueryDictWithTemplate = (queries: any) => {
    const playgroundQueries = queries.map((query: any) => queryResponseToPlaygroundQuery(query))
    return keyBy(playgroundQueries, (q) => q.id)
  }
  const savedUserQueries = toQueryDictWithTemplate(userQueries)
  const savedOrganizationQueries = toQueryDictWithTemplate(orgQueries)

  const savedQueries = { ...savedUserQueries, ...savedOrganizationQueries }

  // We are sorting here to avoid having queries jump around on save
  const computeSortedQueryIds = (queries: any) => orderBy(values(queries), ['updatedAt'], ['desc']).map((q) => q.id)

  const savedUserQueryIds = computeSortedQueryIds(savedUserQueries)
  const savedOrgQueryIds = computeSortedQueryIds(savedOrganizationQueries)
  return {
    savedQueries,
    savedUserQueryIds,
    savedOrgQueryIds,
  }
}

export const createStarterQueries = (): RetoolAPIDispatcher<any> => {
  return async (dispatch: any) => {
    dispatch(savedQueriesRequestActionCreator())
    try {
      const response = await callInternalApi({
        url: '/api/playground/createStarterQueries',
        method: 'POST',
      })

      const { userQueries, orgQueries } = response
      const { savedQueries, savedUserQueryIds, savedOrgQueryIds } = sortAndFormatSavedQueries(userQueries, orgQueries)
      return await dispatch(savedQueriesSuccessActionCreator(savedQueries, savedUserQueryIds, savedOrgQueryIds))
    } catch (error) {
      dispatch(savedQueriesFailureActionCreator(error))
    }
  }
}

export function getSavedQueries(queryToSelect = 0): RetoolActionDispatcher {
  return async (dispatch, getState) => {
    dispatch(savedQueriesRequestActionCreator())
    try {
      const response = await callInternalApi({
        url: '/api/playground',
        method: 'GET',
      })

      const { userQueries, orgQueries } = response
      const { savedQueries, savedUserQueryIds, savedOrgQueryIds } = sortAndFormatSavedQueries(userQueries, orgQueries)
      await dispatch(savedQueriesSuccessActionCreator(savedQueries, savedUserQueryIds, savedOrgQueryIds))
    } catch (error) {
      dispatch(savedQueriesFailureActionCreator(error))
    }

    const savedQueries = playgroundSavedQueriesSelector(getState())
    const resources = playgroundResourcesSelector(getState())

    // Create query if user is opening playground for the first time
    if (Object.keys(savedQueries).length === 0 && resources.length > 0) {
      await dispatch(createStarterQueries())
      await dispatch(selectQuery())
    } else {
      // otherwise select an existing query
      await dispatch(selectQuery(queryToSelect))
    }
  }
}

/* Handlers */
type PlaygroundActionHandler<Action extends RetoolAction = RetoolAction> = (
  state: PlaygroundModel,
  action: Action,
) => PlaygroundModel

const handleSelectResource: PlaygroundActionHandler = (state, action) => {
  const { newResource, query, savedQueryResource } = action.payload

  const newType = newResource.editorType
  const queryId = query.id

  let newTemplate =
    // if you have a template for the new resource, use that
    state.getIn(['queryTemplates', queryId, newResource.name]) ||
    // if you're switching between two resources of the same type, use the existing template values
    (savedQueryResource.editorType === newResource.editorType &&
      state.getIn(['queryTemplates', queryId, savedQueryResource.name]))

  // Use an existing template if possible
  if (!newTemplate) {
    // If no existing template, create a default template
    newTemplate = TemplateResolver(newType, newResource)()

    // If you just switched to the saved query resource (for the first time), get it from the store
    if (savedQueryResource.name === newResource.name) {
      newTemplate = state.getIn(['savedQueries', queryId, 'template'])
    }
  }

  return state.setIn(['queryTemplates', queryId, newResource.name], newTemplate).set('selectedResource', newResource)
}

const handleSelectQuery: PlaygroundActionHandler = (state, action) => {
  const { currentQuery, newQuery } = action.payload

  const curId = get(currentQuery, 'id')
  const newId = newQuery.id
  if (curId === newId) {
    return state
  }

  return state.set('selectedQueryId', newId)
}

const formattableSqlEditorTypes = ['AthenaQuery', 'CosmosDBQuery', 'SqlQuery', 'SqlQueryUnified']
export const formattableEditorTypes = [...formattableSqlEditorTypes, 'GraphQLQuery']

interface ReformatPlaygroundQueryAction extends RetoolAction {
  payload: {
    editorType: QueryEditorType
    queryPath: (string | number)[]
  }
}

const handleReformatPlaygroundQuery: PlaygroundActionHandler<ReformatPlaygroundQueryAction> = (state, action) => {
  const { queryPath, editorType } = action.payload
  if (formattableSqlEditorTypes.includes(editorType)) {
    const reformatted = formatSqlWithTemplate(state.getIn([...queryPath, 'query']))
    return state.setIn([...queryPath, 'query'], reformatted)
  } else if (editorType === 'GraphQLQuery') {
    const reformatted = print(parse(state.getIn([...queryPath, 'body'])))
    return state.setIn([...queryPath, 'body'], reformatted)
  }
  return state
}

const handleQueryEditorUpdate: PlaygroundActionHandler<ReturnType<typeof queryEditorUpdateActionCreator>> = (
  state,
  action,
) => {
  const { queryPath, newValue, newInputs, addedKeys, removedKeys } = action.payload
  const currentDefaults = state.getIn([...queryPath, 'importedQueryDefaults'])
  let newState = state.mergeIn(queryPath, newValue)

  // If there is one new and one old key, it is likely a rename, and we should maintain the input value
  if (addedKeys.size === 1 && removedKeys.size === 1) {
    const newKey = addedKeys.first()
    const removedKey = removedKeys.first()
    const removedKeyValue = state.getIn([...queryPath, 'importedQueryDefaults', removedKey])
    newState = newState.setIn([...queryPath, 'importedQueryDefaults', newKey], removedKeyValue)
  } else {
    // otherwise add empty values to defaults
    // the app model needs to know that these values exist and are unfilled
    // if we don't do this, it will complain that the newly added variables don't exist
    newState = newState.mergeIn(
      [...queryPath, 'importedQueryDefaults'],
      addedKeys.toMap().map((key) => currentDefaults.get(key) || ''),
    )
  }

  return newState.setIn([...queryPath, 'importedQueryInputs'], newInputs)
}

const handleQueryInputValueUpdate: PlaygroundActionHandler = (state, action) => {
  const { queryId, resourceName, inputKey, newValue } = action.payload

  return state.setIn(['queryTemplates', queryId, resourceName, 'importedQueryDefaults', inputKey], newValue)
}

const handleRequestQueryRun: PlaygroundActionHandler = (state, action) => {
  const { queryId } = action.meta
  return state.setIn(['queryResponses', queryId], {
    isFetching: true,
    fetchedAt: null,
    startedFetchAt: moment(),
    queryId,
  })
}

const handleReceiveQueryRun: PlaygroundActionHandler = (state, action) => {
  const { queryId } = action.meta
  return state.mergeIn(['queryResponses', queryId], {
    isFetching: false,
    data: action.payload,
    error: null,
    fetchedAt: moment(),
  })
}

const handleFailureQueryRun: PlaygroundActionHandler = (state, action) => {
  const { queryId } = action.meta
  const error = get(action, 'payload.response.message', 'Unknown error')
  return state.mergeIn(['queryResponses', queryId], {
    isFetching: false,
    data: null,
    error: { error },
    fetchedAt: null,
  })
}

const handleRequestSchema = (state: any) => {
  return state
}

const handleReceiveSchema = (state: any, action: any) => {
  const { schema, resourceName } = action.payload
  return state.setIn(['schemas', resourceName], { error: false, schema })
}

const handleFailureSchema = (state: any, action: { type: string; meta?: { resourceName: string } }) => {
  return state.setIn(['schemas', action.meta!.resourceName], { error: true })
}

const deriveQueryInputs = (template: QueryTemplate) => {
  const resourceSpecificTemplateValues = template.filter(resourceSpecificTemplateValuesFilter)
  let inputs = Immutable.Map<string, any>()
  // Ex: { 'user.name': null, 'user.age': null, 'amount': null }
  resourceSpecificTemplateValues.valueSeq().forEach((value: any) => {
    if (typeof value !== 'string') return
    const val: string = value
    const templateDeps = computeTemplateStringDependencies(val, { unescapeRetoolExpressions: false })
    for (const dep of templateDeps) {
      inputs = inputs.set(ss(dep), '')
    }
  })

  return inputs
}

export const queryResponseToPlaygroundQuery = (query: any) => {
  query = { ...query, updatedAt: moment(query.updatedAt) }
  if (query.template) {
    const template = Immutable.fromJS(query.template)
    const importedQueryInputs = deriveQueryInputs(template)
    const importedQueryDefaults = template.get('importedQueryDefaults') || importedQueryInputs
    return {
      ...query,
      template: template
        .set('importedQueryInputs', importedQueryInputs)
        .set('importedQueryDefaults', importedQueryDefaults),
    }
  }

  return query
}

const handleReceiveQuerySave = (state: any, action: ReturnType<typeof saveQuerySuccessActionCreator>) => {
  const { query, resource } = action.payload

  let newState = state.mergeIn(['savedQueries', query.id], query)

  if (resource) {
    newState = newState.setIn(['queryTemplates', query.id, resource.name], query.template)
  }

  return newState
}

const handleReceiveQueryDelete = (state: PlaygroundModel, action: any) => {
  const { id } = action.payload

  const newState = state
    .deleteIn(['savedQueries', id])
    .deleteIn(['queryTemplates', id])
    .set(
      'savedUserQueryIds',
      state.get('savedUserQueryIds').filter((queryId: number) => queryId !== id),
    )
    .set(
      'savedOrganizationQueryIds',
      state.get('savedOrganizationQueryIds').filter((queryId: number) => queryId !== id),
    )

  return newState
}

const handleReceiveQueryCreate: PlaygroundActionHandler<ReturnType<typeof createQuerySuccessActionCreator>> = (
  state,
  action,
) => {
  const { query } = action.payload
  const userQueryIds = state.savedUserQueryIds

  return state.setIn(['savedQueries', query.id], query).set('savedUserQueryIds', [query.id].concat(userQueryIds))
}

const handleRequestSavedQueries = (state: any) => state
const handleReceiveSavedQueries = (state: any, action: any) => {
  const { savedQueries, savedUserQueryIds, savedOrgQueryIds } = action.payload
  return state
    .set('savedQueries', savedQueries)
    .set('savedUserQueryIds', savedUserQueryIds)
    .set('savedOrganizationQueryIds', savedOrgQueryIds)
}
const handleFailureSavedQueries = (state: any) => state

const initialState = new PlaygroundModel()

const handleRequestQuerySaves = (state: any) => {
  return state.set('isFetchingQuerySaves', true)
}

const handleRecieveQuerySaves = (state: PlaygroundModel, action: any) => {
  const { query, saves } = action.payload

  const savesMap = keyBy(
    saves.map((s: any) => {
      return { ...s, createdAt: moment(s.createdAt), updatedAt: moment(s.updatedAt) }
    }),
    (s) => s.id,
  ) as { [saveId: number]: PlaygroundQuerySave }

  return state.setIn(['savedQuerySaves', query.id], savesMap).set('isFetchingQuerySaves', false)
}

const handleFailureQuerySaves = (state: any) => {
  return state.set('isFetchingQuerySaves', true)
}

const ACTION_HANDLERS: { [signal: string]: PlaygroundActionHandler<any> } = {
  [QUERY_EDITOR_UPDATE]: handleQueryEditorUpdate,
  [QUERY_INPUT_VALUE_UPDATE]: handleQueryInputValueUpdate,
  [SELECT_RESOURCE]: handleSelectResource,
  [SELECT_QUERY]: handleSelectQuery,
  [SELECT_QUERY_SAVE]: (state, action) => state.set('selectedQuerySaveId', action.payload),
  [RESET_QUERY_TEMPLATE]: (state, action) => {
    const { id, savedQuery, resource } = action.payload
    return state.setIn(['queryTemplates', id], { [resource.name]: savedQuery.template })
  },
  [REFORMAT_PLAYGROUND_QUERY]: handleReformatPlaygroundQuery,

  [QUERY_RUN_EVENT.REQUEST]: handleRequestQueryRun,
  [QUERY_RUN_EVENT.SUCCESS]: handleReceiveQueryRun,
  [QUERY_RUN_EVENT.FAILURE]: handleFailureQueryRun,

  [RESOURCE_SCHEMA_EVENT.REQUEST]: handleRequestSchema,
  [RESOURCE_SCHEMA_EVENT.SUCCESS]: handleReceiveSchema,
  [RESOURCE_SCHEMA_EVENT.FAILURE]: handleFailureSchema,

  [QUERY_CREATE_EVENT.SUCCESS]: handleReceiveQueryCreate,

  [QUERY_SAVE_EVENT.SUCCESS]: handleReceiveQuerySave,

  [QUERY_DELETE_EVENT.SUCCESS]: handleReceiveQueryDelete,

  [QUERY_SHARE_EVENT.SUCCESS]: (state, action) => {
    const { query } = action.payload
    const userIds = state.get('savedUserQueryIds')
    const orgIds = state.get('savedOrganizationQueryIds')
    return state
      .mergeIn(['savedQueries', query.id], query)
      .deleteIn(['savedUserQueryIds', userIds.indexOf(query.id)])
      .set('savedOrganizationQueryIds', [query.id, ...orgIds])
  },

  [SAVED_QUERIES_EVENT.REQUEST]: handleRequestSavedQueries,
  [SAVED_QUERIES_EVENT.SUCCESS]: handleReceiveSavedQueries,
  [SAVED_QUERIES_EVENT.FAILURE]: handleFailureSavedQueries,

  [QUERY_SAVES_EVENT.REQUEST]: handleRequestQuerySaves,
  [QUERY_SAVES_EVENT.SUCCESS]: handleRecieveQuerySaves,
  [QUERY_SAVES_EVENT.FAILURE]: handleFailureQuerySaves,

  [QUERY_USAGES_UPDATE]: (state, action) => state.set('selectedQueryUsages', action.payload.queryUsages),
}

export default function playgroundReducer(state: PlaygroundModel = initialState, action: any) {
  const handler = ACTION_HANDLERS[action.type]
  const nextState = handler ? handler(state, action) : state

  return nextState
}

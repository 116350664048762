import React from 'react'

import { DatabaseForm, databaseDefaults, databaseValidator } from './common'
import { ResourceFormProps } from './types'
import { isLaunchedUnifiedSqlResource } from 'components/plugins/datasources/SqlQueryUnified/model'

const DenodoForm = (props: ResourceFormProps) => (
  <DatabaseForm
    placeholderHost="IP address or hostname of your database"
    placeholderPort="9996"
    placeholderUsername="retool"
    isUnifiedSqlResource={isLaunchedUnifiedSqlResource(props.resource)}
    {...props}
  />
)

export default {
  label: 'Denodo ODBC',
  form: DenodoForm,
  defaults: {
    ...databaseDefaults,
    options: {
      ...databaseDefaults.options,
    },
    port: '9996',
  },
  validator: databaseValidator,
}

// eslint-disable-next-line no-console
console.log('[DBG] page load: js start', new Date().getTime() - window.htmlLoadedAt)

import React from 'react'
import ReactDOM from 'react-dom'
import createStore from './store/createStore'
import AppContainer from './containers/AppContainer'
import configureDOMPurify from 'common/configureDOMPurify'
import configurePluralize from 'common/configurePluralize'
import configureNumbro from 'common/configureNumbro'
import configurePolyfills from 'common/polyfills'

configureDOMPurify()
configurePluralize()
configureNumbro()
configurePolyfills()

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__
const store = createStore(initialState)

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root')

let render = () => {
  const routes = require('./routes/index').default(store)

  ReactDOM.render(<AppContainer store={store} routes={routes} />, MOUNT_NODE)
}

// This code is excluded from production bundle
if (__DEV__) {
  if (module.hot) {
    // Development render functions
    const renderApp = render
    const renderError = (error) => {
      const RedBox = require('redbox-react').default

      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE)
    }

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        // eslint-disable-next-line no-console
        renderError(error)
      }
    }

    // Setup hot module replacement
    module.hot.accept(['./routes/index', './containers/AppContainer'], () =>
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE)
        render()
      }),
    )
  }
}

// ========================================================
// Go! :D
// ========================================================
render()

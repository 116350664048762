import fetchPagesAndRedirectWorkspaces from 'components/Workspaces/fetchPagesAndRedirectWorkspaces'
import { injectReducer } from 'store/reducers'
import CloneTemplate from './components/CloneTemplate'
import Home from './components/HomeView'
import type { RetoolStore } from 'store'
import { RouteConfig } from 'react-router'

// Sync route definition
export default function HomeRoute(store: RetoolStore): RouteConfig {
  return {
    onEnter: async () => {
      document.title = 'Apps | Retool'
      window.onbeforeunload = () => {}
      const playgroundReducer = (await import(/* webpackChunkName: "playgroundReducer" */ 'store/playground')).default
      injectReducer(store, { key: 'playground', reducer: playgroundReducer })
    },
    component: fetchPagesAndRedirectWorkspaces(Home),
    childRoutes: [
      {
        path: 'folders/:folderName',
        onEnter: (nextState: { params: { folderName: string } }) => {
          document.title = `${nextState.params.folderName} | Apps | Retool`
        },
      },
      {
        path: 'views/all',
        onEnter: () => {
          document.title = `All Apps | Retool`
        },
      },
      {
        path: 'views/recent',
        onEnter: () => {
          document.title = `Recent Apps | Retool`
        },
      },
      {
        path: 'views/starred',
        onEnter: () => {
          document.title = `Starred Apps | Retool`
        },
      },
      {
        path: 'views/modules',
        onEnter: () => {
          document.title = `Modules | Retool`
        },
      },
      {
        path: 'views/protected',
        onEnter: () => {
          document.title = `Protected Apps | Retool`
        },
      },
      {
        path: 'views/trash',
        onEnter: () => {
          document.title = `Trash | Retool`
        },
      },
      {
        path: 'clone-template/:templateId',
        component: CloneTemplate,
      },
    ],
  }
}

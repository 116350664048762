import { combineReducers, Reducer } from 'redux'
import locationReducer from './location'
import sessionReducer from './session'
import appModelReducer from './appModel/model'
import appTemplateReducer from './appModel/template'
import pagesReducer from './appModel/pages'
import orgImageBlobsReducer from './orgImageBlobs'
import modulesReducer from './modules'
import userReducer from './user'
import responsiveReducer from './responsive'
import changesRecordReducer from './changesRecord'
import subdomainReducer from './subdomain'
import omniboxReducer from './omnibox'
import onboardingReducer from './onboarding'
import globalsReducer from './globals'
import { RetoolState } from './'
import marketingTemplatesReducer from './marketingTemplates'

export type RootReducerCreator = (asyncReducers?: { [key: string]: Reducer<any> }) => Reducer<RetoolState>

export const makeRootReducer: RootReducerCreator = (asyncReducers = {}) => {
  return (combineReducers({
    location: locationReducer,
    appTemplate: appTemplateReducer,
    appModel: appModelReducer,
    session: sessionReducer,
    pages: pagesReducer,
    user: userReducer,
    omnibox: omniboxReducer,
    responsive: responsiveReducer,
    subdomain: subdomainReducer,
    orgImageBlobs: orgImageBlobsReducer,
    modules: modulesReducer,
    onboarding: onboardingReducer,
    changesRecord: changesRecordReducer,
    marketingTemplates: marketingTemplatesReducer,
    globals: globalsReducer,
    ...asyncReducers,
  }) as unknown) as Reducer<RetoolState>
}

export const injectReducer = (store: any, { key, reducer }: { key: any; reducer: any }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer

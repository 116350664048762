import { ResourceType } from 'common/resourceTypes'
import {
  BuildingBlock,
  BuildingBlockId,
  GOOGLE_SHEET_PLACEHOLDER,
  INSERT_NEW_DATA_BUILDING_BLOCK,
  QUERY_ID_PLACEHOLDER,
  TABLE_PLACEHOLDER,
  WidgetBuildingBlock,
} from '../buildingBlocks'
import { allResourceSheets, allResourcesInput, allResourceTables } from 'components/BuildingBlocks/generatorInputs'
import _ from 'lodash'

const formId = 'addRecord'

//Building block assumes a table is made from another building block (this is enforced in the building block grid)
const constructBuildingBlock = () => {
  const widgets = []
  const queries = []

  const formWidget: WidgetBuildingBlock = {
    id: formId,
    type: 'FormWidget',
    mergeWidget: true,
    parentBuildingBlockId: INSERT_NEW_DATA_BUILDING_BLOCK,
    options: {
      onSubmit: {
        value: `${INSERT_NEW_DATA_BUILDING_BLOCK}-${QUERY_ID_PLACEHOLDER}`,
        priority: 0,
      },
    },
  }

  const postgresSearchBuildingBlock = generatePostgresBuildingBlock()
  const googleSheetsQuery = generateGoogleSheetsBuildingBlock()
  const mysqlBlock = _.cloneDeep(postgresSearchBuildingBlock)
  mysqlBlock.type = 'mysql'

  queries.push(postgresSearchBuildingBlock)
  queries.push(mysqlBlock)
  queries.push(googleSheetsQuery)
  widgets.push(formWidget)

  //TODO: (Andrew) make these a class
  const insertTableInput = { ...allResourceTables, label: 'Table to generate form for' }
  const insertBuildingBlock: BuildingBlock = {
    id: 'insertNewData',
    displayName: 'Insert New Row',
    description: 'Form that lets you insert a new row in a database',
    widgets,
    queries,
    showInEditor: true,
    showInOnboarding: true,
    generatorInputs: [allResourcesInput, allResourceSheets, insertTableInput],
  }

  return insertBuildingBlock
}

const generatePostgresBuildingBlock = () => {
  return {
    id: 'willNotShow',
    parentBuildingBlockId: INSERT_NEW_DATA_BUILDING_BLOCK as BuildingBlockId,
    type: 'postgresql' as ResourceType,
    options: {},
    hintOnlyBlock: true,
  }
}

const generateGoogleSheetsBuildingBlock = () => {
  return {
    id: 'insertIntoSheet',
    type: 'googlesheets' as ResourceType,
    parentBuildingBlockId: INSERT_NEW_DATA_BUILDING_BLOCK as BuildingBlockId,
    options: {
      actionType: 'append',
      spreadsheetId: `${TABLE_PLACEHOLDER}`,
      runWhenModelUpdates: false,
      requireConfirmation: true,
      sheetName: GOOGLE_SHEET_PLACEHOLDER,
    },
  }
}

export default constructBuildingBlock

import React from 'react'
import classNames from 'classnames'
import { Collapse as AntCollapse } from 'antd'
import { CollapseProps as AntCollapseProps } from 'antd/lib/collapse/Collapse'
import { CollapsePanelProps as AntCollapsePanelProps } from 'antd/lib/collapse'

import Icon from '../Icon'

import 'antd/lib/collapse/style/index'
import './Collapse.scss'

type CollapsePanelProps = AntCollapsePanelProps & {
  children?: React.ReactNode
}
const Panel = (props: CollapsePanelProps) => (
  <AntCollapse.Panel {...props} className={classNames(props.className, 'retool-collapse-panel')}>
    {props.children}
  </AntCollapse.Panel>
)

export type CollapseProps = AntCollapseProps & {
  expandIconPosition: 'left' | 'right'
  children?: React.ReactNode
}
const Collapse = (props: CollapseProps) => {
  const expandIconIsLeft = props.expandIconPosition === 'left'
  const computeIconRotation = expandIconIsLeft
    ? (isActive?: boolean) => (isActive ? 90 : 0)
    : (isActive?: boolean) => (isActive ? -90 : 90)
  return (
    <AntCollapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <Icon
          type="caret-right"
          style={{ color: 'var(--lightest-gray)' }}
          className="collapse-arrow"
          rotate={computeIconRotation(isActive)}
        />
      )}
      {...props}
      className={classNames(props.className, 'retool-collapse', expandIconIsLeft ? 'arrow-left' : 'arrow-right')}
    >
      {props.children}
    </AntCollapse>
  )
}
Collapse.Panel = Panel

export default Collapse

import Menu from 'antd/lib/menu'
import { Branch, Page } from 'common/records'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { Button, Icon } from 'components/design-system'
import { Dropdown, DropdownProps } from 'components/design-system/Dropdown'
import BranchMenuItem from 'components/ProtectedApps/BranchMenuItem'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { browserHistory, withRouter } from 'react-router'
import { RetoolState } from 'store'
import { branchesSelector, organizationProtectedBranch, profileSelector } from 'store/selectors'

const mapStateToProps = (state: RetoolState) => {
  return {
    branches: branchesSelector(state),
    user: profileSelector(state),
    protectedBranchName: organizationProtectedBranch(state),
  }
}

const connector = connect(mapStateToProps)
type RunApplicationDropdownReduxProps = ConnectedProps<typeof connector>

type RunApplicationDropdownProps = {
  endUserLink: string
  page: Page
} & Omit<DropdownProps, 'overlay'>

const navigateTo = (route: string) => browserHistory.push(route)

function renderMenuItemForBranch(endUserLink: string, branch: Branch) {
  return (
    <BranchMenuItem
      key={`branch-${branch.name}`}
      onClick={() => {
        retoolAnalyticsTrack('Branch Switched', { protectedBranch: false, mode: 'preview' })
        navigateTo(`/tree/${encodeURIComponent(branch.name)}${endUserLink}`)
      }}
      branchName={branch.name}
    />
  )
}

const RunApplicationDropdown = ({
  branches,
  protectedBranchName,
  page,
  endUserLink,
  user,
  ...dropdownProps
}: RunApplicationDropdownProps & RunApplicationDropdownReduxProps) => {
  const yourBranchesMenuItems = branches
    .filter((branch) => branch.pageId === page.id)
    .filter((branch) => branch.ownerId === user.id)
    .map((branch) => renderMenuItemForBranch(endUserLink, branch))
  const otherBranchesMenuItems = branches
    .filter((branch) => branch.pageId === page.id)
    .filter((branch) => branch.ownerId !== user.id)
    .map((branch) => renderMenuItemForBranch(endUserLink, branch))

  const menu = (
    <Menu>
      <BranchMenuItem
        key="protected-branch"
        branchName={protectedBranchName}
        onClick={() => {
          retoolAnalyticsTrack('Branch Switched', { protectedBranch: true, mode: 'preview' })
          navigateTo(endUserLink)
        }}
        isProtected
      />
      {yourBranchesMenuItems.length && <Menu.Divider />}
      {yourBranchesMenuItems.length && <div className="application-listing__menu-title">Your branches</div>}
      {yourBranchesMenuItems}
      {otherBranchesMenuItems.length && <Menu.Divider />}
      {otherBranchesMenuItems.length && <div className="application-listing__menu-title">Other branches</div>}
      {otherBranchesMenuItems}
    </Menu>
  )

  return (
    <Dropdown className="ml8 run-action run-action-dropdown" overlay={menu} trigger={['click']} {...dropdownProps}>
      <Button>
        <Icon type="run" />
        &nbsp;&nbsp;Preview
        <Icon type="caret-down" style={{ marginRight: -8 }} />
      </Button>
    </Dropdown>
  )
}

export default withRouter(connector(RunApplicationDropdown))

import React, { ReactNode } from 'react'
import { ResourceFormProps } from '../resources/types'
import { AuthTemplate, isApiKeyAuth, ResourceApiKeyAuth, SupportedAuthType } from './generator-types'

/**
 * A resource form wrapper that allows you to switch between multiple authentication types.
 * For example, our Asana resource can either use OAuth2 or an API Key to authenticate.
 */
function MultiAuthView(
  props: ResourceFormProps & {
    children: ReactNode
    getView: (resource: ResourceFormProps['resource']) => SupportedAuthType
    authOptions: AuthTemplate
  },
) {
  const { children, getView, authOptions } = props

  const authMode = getView(props.resource)
  const apiKeyOptions = authOptions?.find((auth) => isApiKeyAuth(auth)) as ResourceApiKeyAuth
  return (
    <>
      <hr />
      <div className="flex mb16 justify-between">
        <h5 className="section-heading light-gray">General</h5>
        {authMode === 'oauth2' ? (
          <a
            className="fs-12 fw-600 blue"
            href="#"
            onClick={() => {
              props.updateResourceOptions({
                authentication: 'apiKey',
                verify_session_action_enabled: false,
                customHeaders: apiKeyOptions?.options?.customHeaders || [['Authorization', 'Bearer API_KEY']],
                customQueryParameters: apiKeyOptions?.options?.customQueryParameters || [['', '']],
              })
            }}
          >
            Continue with Personal Token
          </a>
        ) : (
          <a
            className="fs-12 fw-600 blue"
            href="#"
            onClick={() => {
              props.updateResourceOptions({
                authentication: 'oauth2',
                verify_session_action_enabled: true,
                customHeaders: [['Authorization', 'Bearer OAUTH2_TOKEN']],
              })
            }}
          >
            Continue with OAuth
          </a>
        )}
      </div>
      {children}
    </>
  )
}

export default MultiAuthView

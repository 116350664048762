import Immutable from 'immutable'
import _ from 'lodash'
import { JSON_HEADERS } from 'networking/util'
import { retoolAnalyticsTrack, retoolAnalyticsIdentify } from 'common/retoolAnalytics'
import { GenericErrorResponse } from 'common/records'
import { callApi } from './callApi'
import { callInternalApi } from 'networking'

// ------------------------------------
// Constants
// ------------------------------------
export const SET_CURRENT_SUBDOMAIN = 'SET_CURRENT_SUBDOMAIN'

export const REQUEST_CHECK_SUBDOMAIN = 'REQUEST_CHECK_SUBDOMAIN'
export const RECEIVE_CHECK_SUBDOMAIN = 'RECEIVE_CHECK_SUBDOMAIN'
export const FAILURE_CHECK_SUBDOMAIN = 'FAILURE_CHECK_SUBDOMAIN'

export const REQUEST_INITIALIZE_ORGANIZATION = 'REQUEST_INITIALIZE_ORGANIZATION'
export const RECEIVE_INITIALIZE_ORGANIZATION = 'RECEIVE_INITIALIZE_ORGANIZATION'
export const FAILURE_INITIALIZE_ORGANIZATION = 'FAILURE_INITIALIZE_ORGANIZATION'

// ------------------------------------
// Actions
// ------------------------------------

const debouncedCheckSubdomain = _.debounce(async (dispatch, subdomain) => {
  const encodedSubdomain = encodeURIComponent(subdomain)
  dispatch({ type: REQUEST_CHECK_SUBDOMAIN })
  try {
    const payload = await callInternalApi({
      url: `/api/organization/admin/checkSubdomainAvailability?subdomain=${encodedSubdomain}`,
      method: 'GET',
    })
    dispatch({ type: RECEIVE_CHECK_SUBDOMAIN, payload })
  } catch (e) {
    dispatch({ type: FAILURE_CHECK_SUBDOMAIN, payload: e.payload })
  }
}, 500)

export function checkSubdomain(subdomain: string) {
  return async (dispatch: any) => {
    dispatch({ type: SET_CURRENT_SUBDOMAIN, payload: { subdomain } })
    debouncedCheckSubdomain(dispatch, subdomain)
  }
}

interface InitialOrganizationConfig {
  subdomain: string
  autoJoin: boolean
  isCompanyAccount?: boolean
  companyName?: string
  contactNumber?: string
  appBeingBuilt?: string
  isMobileSignup: boolean
}
export function initializeOrganization(config: InitialOrganizationConfig) {
  const { subdomain, autoJoin, isCompanyAccount, companyName, contactNumber, appBeingBuilt, isMobileSignup } = config
  retoolAnalyticsTrack('Subdomain Selected', { subdomain })
  retoolAnalyticsTrack('Post Signup App Description Submitted', { appBeingBuilt })
  retoolAnalyticsIdentify({
    isCompanyAccount,
    companyName,
    contactNumber,
    isMobileSignup,
  })
  return async (dispatch: any) => {
    await dispatch({ type: SET_CURRENT_SUBDOMAIN, payload: { subdomain } })
    return dispatch(
      callApi({
        endpoint: '/api/organization/admin/initializeOrganization',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ subdomain, isCompanyAccount, companyName, contactNumber, appBeingBuilt, autoJoin }),
        types: [REQUEST_INITIALIZE_ORGANIZATION, RECEIVE_INITIALIZE_ORGANIZATION, FAILURE_INITIALIZE_ORGANIZATION],
      }),
    )
  }
}

function handleRequestCheckSubdomain(state: any) {
  return state.set('checkSubdomainIsFetching', true)
}

function handleReceiveCheckSubdomain(state: any, action: any) {
  return state
    .set('checkSubdomainIsFetching', false)
    .set('subdomainValid', true)
    .set('queriedSubdomain', action?.payload?.subdomain)
    .set('successMessage', action?.payload?.message)
}

function handleFailureCheckSubdomain(state: any, action: any) {
  return state
    .set('checkSubdomainIsFetching', false)
    .set('subdomainValid', false)
    .set('subdomainAlternatives', action?.payload?.subdomainAlternatives ?? [])
    .set('queriedSubdomain', action?.payload?.subdomain)
    .set('errorMessage', action?.payload?.message)
}

function handleRequestInitializeOrganization(state: any) {
  return state.set('initializeOrganizationIsFetching', true)
}

function handleReceiveInitializeOrganization(state: any, action: any) {
  return state.set('initializeOrganizationIsFetching', false).set('successMessage', action.payload.message)
}

function handleFailureInitializeOrganization(state: any, action: GenericErrorResponse) {
  return state
    .set('initializeOrganizationIsFetching', false)
    .set('subdomainValid', false)
    .set('errorMessage', _.get(action, 'payload.response.message'))
}

function handleSetCurrentSubdomain(state: any, action: any) {
  return state.set('currentSubdomain', action.payload.subdomain)
}

const ACTION_HANDLERS: any = {
  [SET_CURRENT_SUBDOMAIN]: handleSetCurrentSubdomain,
  [REQUEST_CHECK_SUBDOMAIN]: handleRequestCheckSubdomain,
  [RECEIVE_CHECK_SUBDOMAIN]: handleReceiveCheckSubdomain,
  [FAILURE_CHECK_SUBDOMAIN]: handleFailureCheckSubdomain,
  [REQUEST_INITIALIZE_ORGANIZATION]: handleRequestInitializeOrganization,
  [RECEIVE_INITIALIZE_ORGANIZATION]: handleReceiveInitializeOrganization,
  [FAILURE_INITIALIZE_ORGANIZATION]: handleFailureInitializeOrganization,
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = Immutable.Map({
  checkSubdomainIsFetching: false,
  initializeOrganizationIsFetching: false,
  subdomainValid: false,
  queriedSubdomain: '',
  currentSubdomain: '',
})
export default function subdomainReducer(state = initialState, action: any) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

import React from 'react'
import { Checkbox } from 'components/design-system'
import { APIAuthMethodType, APIForm, APIOptions, apiDefaults } from './common'
import { ResourceFormProps } from './types'

export const GRAPHQL_AUTH_METHODS: APIAuthMethodType[] = ['auth0', 'basic', 'oauth2', 'custom']

const GraphQLForm = (props: ResourceFormProps) => (
  <>
    <APIForm authMethods={GRAPHQL_AUTH_METHODS} {...props} />
    <hr />
    <h5 className="section-heading light-gray mb12">GraphQL Introspection</h5>
    <div className="grid-1c mb12">
      <div className="grid-offset-1">
        <Checkbox
          checked={props.resource.options.disableIntrospection}
          onChange={(checked) => {
            props.onChangeOption('disableIntrospection')(checked.target.checked)
          }}
        >
          Disable Introspection
        </Checkbox>
        <div className="gray ml24">
          Use this if your GraphQL endpoint does not support introspection queries. Note that disabling introspection
          reduces the usability of GraphQL, as auto-complete and linting for this resource will no longer work.
        </div>
      </div>
    </div>
  </>
)

type OptionsType = APIOptions & { options: { disableIntrospection: boolean } }

export default {
  label: 'GraphQL',
  form: GraphQLForm,
  defaults: { ...apiDefaults, options: { ...apiDefaults.options, disableIntrospection: false } },
  validator: ({ options }: APIOptions) =>
    (options.authentication === 'auth0' && options.auth0_domain && options.auth0_clientID) ||
    (options.authentication === 'basic' && options.basic_username) ||
    (options.authentication === 'oauth2' &&
      options.oauth2_auth_url &&
      options.oauth2_access_token_url &&
      options.oauth2_client_id &&
      options.oauth2_auth_url) ||
    options.authentication === 'none' ||
    options.authentication === '' ||
    options.authentication === 'custom' ||
    options.authentication === undefined,
  hideTestConnection: ({ options: changeSetOptions }: OptionsType, { options: templateOptions }: OptionsType) => {
    return changeSetOptions ? changeSetOptions?.disableIntrospection : templateOptions?.disableIntrospection
  },
}

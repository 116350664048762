import React from 'react'
import { Checkbox, Tooltip, Icon } from 'components/design-system'
import ResourceOAuthForm from './ResourceOAuthForm'

import { InputField, CallbackURLField } from './common'
import { ResourceFormProps } from './types'
import { USER_CALLBACK_URL, SHARED_OAUTH_CALLBACK_URL } from '../oauthForm'
import { IS_ON_PREM } from 'retoolConstants'
import { NAME_MISSING } from './constants'

const SALESFORCE_AUTH_URL = 'https://login.salesforce.com/services/oauth2/authorize'
const SALESFORCE_TOKEN_URL = 'https://login.salesforce.com/services/oauth2/token'

const CustomConnectedAppForm = (props: ResourceFormProps) => (
  <>
    <div className="grid-offset-1">
      To connect Retool to Salesforce create a connected app in your Salesforce instance for Retool.{' '}
      <a target="_blank" href="https://docs.retool.com/docs/salesforce-integration" rel="noopener noreferrer">
        Here is a guide on connecting Salesforce to Retool using a custom connected app.
      </a>
    </div>

    <InputField
      label="Salesforce instance URL (must end in salesforce.com)"
      placeholder="https://na1.salesforce.com"
      resourceKey="instanceUrl"
      {...props}
    />

    <CallbackURLField {...props} />

    <InputField label="Salesforce consumer key" resourceKey="oauth2_client_id" {...props} />

    <InputField label="Salesforce consumer secret" resourceKey="oauth2_client_secret" {...props} />
  </>
)

// We don't support using the Retool connected app for on-prem customers because of oauth callback urls
const shouldUseRetoolConnectedApp = (options: any) =>
  options.use_retool_connected_app_if_cloud && (!IS_ON_PREM || __DEV__)

const getOauthUrl = (useSandboxEnv: boolean) =>
  useSandboxEnv ? SALESFORCE_AUTH_URL.replace('login', 'test') : SALESFORCE_AUTH_URL

const getAccessTokenUrl = (useSandboxEnv: boolean) =>
  useSandboxEnv ? SALESFORCE_TOKEN_URL.replace('login', 'test') : SALESFORCE_TOKEN_URL

const getCallbackUrl = (shareUserCredentials: boolean) =>
  shareUserCredentials ? SHARED_OAUTH_CALLBACK_URL : USER_CALLBACK_URL

const SalesforceForm = (props: ResourceFormProps) => {
  const useRetoolConnectedApp = shouldUseRetoolConnectedApp(props.resource.options)
  const shareUserCredentials = props.resource.options.oauth2_share_user_credentials
  const useSandboxEnv = props.resource.options.use_sandbox_environment

  const onTriggerSaveResource = async (triggeredByOauth: boolean) => {
    if (useRetoolConnectedApp) {
      // When sfdc is used in a cloned template, the following properties will be incorrect
      // on first save (since they are pre-configured via demoResources secret). This is why
      // we overwrite them here
      await props.updateResourceOptions({
        oauth2_auth_url: getOauthUrl(useSandboxEnv),
        oauth2_access_token_url: getAccessTokenUrl(useSandboxEnv),
        oauth2_callback_url: getCallbackUrl(shareUserCredentials),
      })
    }
    return await props.saveResource(triggeredByOauth)
  }

  return (
    <>
      <div className="grid-1c mb20">
        {/* If we use the Retool connected app, the Retool connected app credentials get injected in the backend */}
        {!useRetoolConnectedApp && <CustomConnectedAppForm {...props} />}
        <Checkbox
          checked={props.resource.options.use_sandbox_environment}
          onChange={(checked) => {
            props.updateResourceOptions({
              use_sandbox_environment: checked.target.checked,
              oauth2_auth_url: getOauthUrl(checked.target.checked),
              oauth2_access_token_url: getAccessTokenUrl(checked.target.checked),
            })
          }}
          className="grid-offset-1"
        >
          Connect to a sandbox organization
        </Checkbox>

        <>
          <div />
          <div className="flex items-center">
            <div className="mr12">
              <ResourceOAuthForm
                onTriggerSaveResource={onTriggerSaveResource}
                resource={props.resource}
                disabled={!props.resource.displayName}
                disabledTooltipTitle={NAME_MISSING}
                resourceTypeForLabel="Salesforce"
                connectButtonTextOverride={
                  shareUserCredentials ? undefined : 'Test OAuth integration with your own account'
                }
                // When credentials are per-user, showing "Not completed yet." when user hasn't used
                // their account to test the integration is misleading
                hideInProgressAuthorizationStatusAlert={!shareUserCredentials}
                onUpdateOauthAuthorizedStatus={props.onUpdateOauthAuthorizedStatus}
              />
            </div>
          </div>
        </>
        <>
          <div />
          {shareUserCredentials && (
            <a
              onClick={async () => {
                await props.updateResourceOptions({
                  oauth2_access_token: '',
                })
                props.saveResource()
              }}
            >
              Reset Salesforce access token
            </a>
          )}
        </>
      </div>
      <hr />
      <h5 className="section-heading light-gray">Advanced</h5>
      <br />
      <div className="grid-1c mb20">
        <Checkbox
          checked={shareUserCredentials}
          onChange={(checked) => {
            props.updateResourceOptions({
              oauth2_share_user_credentials: checked.target.checked,
              oauth2_access_token: undefined,
              oauth2_refresh_token: undefined,
              oauth2_callback_url: getCallbackUrl(checked.target.checked),
              oauth2_access_token_accessible_in_all_resources: false,
            })
          }}
          className="grid-offset-1"
        >
          Share Salesforce credentials between users
        </Checkbox>
        {!shareUserCredentials && (
          <Checkbox
            checked={props.resource.options.oauth2_access_token_accessible_in_all_resources}
            onChange={(checked) => {
              props.updateResourceOptions({
                oauth2_access_token_accessible_in_all_resources: checked.target.checked,
              })
            }}
            className="grid-offset-1"
          >
            Enable using Salesforce access token in any REST API resource&nbsp;
            <Tooltip
              placement="topLeft"
              title={`If enabled, ${encodeURIComponent(
                props.resource.name,
              )}.SALESFORCE_ACCESS_TOKEN will be replaced by the user's SFDC access token in all REST API resource definitions`}
            >
              <Icon type="tooltip" className="washed-gray hover-lightest-gray mr8" />
            </Tooltip>
          </Checkbox>
        )}

        {(!IS_ON_PREM || __DEV__) && (
          <Checkbox
            checked={!useRetoolConnectedApp}
            onChange={(checked) => {
              props.updateResourceOptions({
                use_retool_connected_app_if_cloud: !checked.target.checked,
              })
            }}
            className="grid-offset-1"
          >
            Use custom connected app&nbsp;
            <Tooltip
              placement="topLeft"
              title="Enable only if you would like to set up your own Salesforce connected app."
            >
              <Icon type="tooltip" className="washed-gray hover-lightest-gray mr8" />
            </Tooltip>
          </Checkbox>
        )}
      </div>
    </>
  )
}

const validator = ({ options }: any): boolean => {
  const useRetoolConnectedApp = shouldUseRetoolConnectedApp(options)
  if (useRetoolConnectedApp) {
    return true // users can oauth into salesforce now or later. They don't need to provide credentials
  }
  return options.instanceUrl && options.oauth2_client_id && options.oauth2_client_secret
}

export default {
  label: 'Salesforce',
  form: SalesforceForm,
  isOAuth: () => true,
  defaults: {
    options: {
      use_sandbox_environment: false,
      oauth2_share_user_credentials: true,
      instanceUrl: '',
      oauth2_client_id: '',
      oauth2_client_secret: '',
      oauth2_callback_url: SHARED_OAUTH_CALLBACK_URL,
      oauth2_auth_url: SALESFORCE_AUTH_URL,
      oauth2_access_token_url: SALESFORCE_TOKEN_URL,
      oauth2_scope: 'api refresh_token',
      use_retool_connected_app_if_cloud: true,
    },
  },
  validator,
  hideCreateResourceButton: () => true,
}

import Blob from 'blob'
import { Base64 } from 'js-base64'

const FILE_OBJECT_TYPE = '__FILE_OBJECT_AS_JSON__'
export type FileObject = typeof FILE_OBJECT_TYPE

function readBase64DataFromFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      const result = reader.result as string
      const strippedData = result.split(',')[1]
      resolve(strippedData)
    }
    reader.onerror = function (error) {
      reject(error)
    }
  })
}

export type FileAsJSON = {
  type: FileObject
  data: string
  contentType: string
  name: string
}

export async function formatFileAsJSON(file: File): Promise<FileAsJSON> {
  if (file.type.indexOf('text') !== -1) {
    // We should use the readAsText method
    const { data, name } = await readAsText(file)
    return {
      type: FILE_OBJECT_TYPE,
      data: Base64.encode(data),
      contentType: file.type,
      name,
    }
  } else {
    const data = await readBase64DataFromFile(file)
    const name = file.name
    return {
      type: FILE_OBJECT_TYPE,
      contentType: file.type,
      data,
      name,
    }
  }
}

type FileResult = { name: string; data: string }
export function readAsText(file: File): Promise<FileResult> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = function () {
      resolve({
        data: reader.result as string,
        name: file.name,
      })
    }
    reader.onerror = reject
  })
}

export const BAD_CHARS = ['ï»¿', '﻿']

export function b64toBlob(b64Data: any, contentType: any, sliceSize: any) {
  contentType = contentType || ''
  sliceSize = sliceSize || 512

  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

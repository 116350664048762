import React from 'react'
import { connect } from 'react-redux'

import { Button, Icon } from 'components/design-system'
import { RetoolState } from 'store'
import { dismissSalesCTA } from 'store/user'
import { salesCTAShownSelector } from 'store/selectors/billingSelectors'

type Props = {
  hide: () => Promise<void>
  visible: boolean
}

const BookACall = (props: Props) => {
  if (!props.visible) {
    return <></>
  }

  return (
    <div className="flex pa8 fs-10 bg-near-white br4 mt12">
      <Icon type="chat-bubble" className="v-mid mr12 mt4 ml4" />
      <div className="fs-12 gray flex-grow">
        Need a 1:1 experience?
        <br />
        <a className="flex items-center book_a_meeting_bot_launcher">
          Book a call&nbsp;
          <Icon type="arrow-right" />
        </a>
      </div>
      <div>
        <Button type="link" onClick={props.hide} className="pa4">
          <Icon type="close" className="lighter-gray hover-blue" />
        </Button>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  hide: dismissSalesCTA,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    visible: salesCTAShownSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookACall)

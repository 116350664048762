import React from 'react'
import { ResourceFormProps } from '../resources/types'
import APIKeyField from '../resources/components/APIKeyField'
import { ResourceCustomAuth } from './generator-types'

const typeMap = {
  token: APIKeyField,
}

/**
 * Resource Form for creating a custom view - supports field types that are specified in the
 * typeMap above. (This is useful for when your resource expects multiple different auth values at once).
 *
 * Datadog integration is a good example of this since it expects and API Key and an Application Key.
 */
function CustomAuthView(props: ResourceFormProps & { auth: ResourceCustomAuth }) {
  const { auth, ...restOfProps } = props
  const customFormFields = auth.fields.map((fieldTemplate) => {
    const Field = typeMap[fieldTemplate.type]
    return (
      <Field
        {...restOfProps}
        key={fieldTemplate.fieldName}
        fieldName={fieldTemplate.fieldName}
        fieldLabel={fieldTemplate.label}
        fieldDescription={fieldTemplate.description}
        fieldRequired={fieldTemplate.required}
      />
    )
  })
  return <div className="grid-1c mb12">{customFormFields}</div>
}

export default CustomAuthView

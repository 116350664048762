import { Folder, ImmutableMapType, isSupportedSignUpTheme, Page, PluginTemplate } from 'common/records'
import Immutable, { OrderedMap } from 'immutable'
import _, { isArray, last, values, keys, setWith, isEqual } from 'lodash'
import moment from 'moment'
import { browserHistory } from 'react-router'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import { signupThemeTemplateMap } from 'retoolConstants'
import { RetoolState } from 'store'
import { AccessLevel } from '__globalShared__/permissions'
import { URL_REGEX } from './regexes'
import cookies from 'js-cookie'

export const addSlashToUrl = (url: string): string => {
  if (!url) {
    return ''
  } else {
    return url[url.length - 1] === '/' ? url : `${url}/`
  }
}
// Assume v1.length = v2.length
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arrayLexicalComparator = (v1: any[], v2: any[]): 1 | 0 | -1 => {
  for (let i = 0; i < v1.length; i++) {
    if (v1[i] < v2[i]) {
      return -1
    }
    if (v1[i] > v2[i]) {
      return 1
    }
  }
  return 0
}

export const sleep = (ms: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

export const filterAndSortWidgets = (
  plugins: OrderedMap<string, PluginTemplate>,
  largeScreen = true,
): OrderedMap<string, PluginTemplate> => {
  return plugins
    .filter((plugin) => plugin.type === 'widget')
    .sortBy((p) => {
      const key = largeScreen ? 'position2' : 'mobilePosition2'
      const pos = p[key]
      if (!pos) {
        return [-100, -100]
      }
      return [pos.row, pos.col]
    }, arrayLexicalComparator)
}

export function hasErrorDeep(errors: {}[]): boolean {
  if (!errors) return false
  let error = false
  errors.forEach((v: any) => {
    if (typeof v === 'string') {
      error = true
      return
    } else if (v && typeof v === 'object') {
      error = error || hasErrorDeep(Object.keys(v).map((k) => v[k]))
    }
  })
  return error
}

export function onEmbeddedPage(): boolean {
  return !!window.location.pathname.match(/^\/embedded\/public.*/) || !!window.location.pathname.match(/^\/pwa\/.*\/.*/)
}

export const primitiveTypeToAnalyticsType: any = {
  widget: 'component',
  datasource: 'query',
  function: 'transformer',
}

export function formatDataAsArray(data: any, outputArrayOfArrays?: boolean): {}[] {
  const keys = Object.keys(data || {})
  if (keys.length === 0) {
    return []
  }
  const rows = []
  const fkey = keys[0]
  for (let i = 0; i < data[fkey].length; i++) {
    const row = keys.reduce((acc: any, key) => {
      acc[key] = data[key][i]
      return acc
    }, {})
    rows.push(row)
  }
  if (outputArrayOfArrays) {
    const keys = Object.keys(rows[0])
    return [keys].concat(
      rows.slice(1).map((row) => {
        return keys.map((k) => row[k])
      }),
    )
  }
  return rows
}

export function formatDataAsObject(data: any): { [key: string]: {}[] } {
  if (data.length === 0) return {}

  const keys = Object.keys(data[0] || {})
  const res: any = {}
  keys.forEach((key) => {
    res[key] = []
  })

  data.forEach((row: any) => {
    keys.forEach((key) => {
      res[key].push(row[key])
    })
  })

  return res
}

/** @deprecated use `import { getNewWidgetId } from 'common/utils/pluginIds'` */
export function generateUniqueWidgetId(
  widgetType: string,
  plugins: OrderedMap<string, PluginTemplate>,
  defaultId?: string,
): string {
  if (defaultId && !plugins.get(defaultId)) return defaultId
  // generate a unique widget name
  const widgetTypeCount = plugins.filter((plugin) => plugin.subtype === widgetType).count()
  const widgetPrefix = widgetType.toLowerCase().replace('widget', '')
  // generate a unique widget name
  let i = 1
  let newId = `${widgetPrefix}${widgetTypeCount + i}`
  while (plugins.get(newId)) {
    i += 1
    newId = `${widgetPrefix}${widgetTypeCount + i}`
  }
  return newId
}

export const ss = (selector: Selector): string => selector.join('.')
export const ds = (id: string): string[] => id.split('.')
export type Selector = string[] // FIX (string | number)
export type SelectorString = string

export const copyToClipboard = (txt: string): void => {
  const activeElement = document.activeElement as HTMLElement
  const node = document.createElement('textarea')
  node.value = txt
  document.body.appendChild(node)
  node.select()
  document.execCommand('copy')
  node.remove()
  activeElement?.focus({ preventScroll: true })
}

export const ROW_BASED = 1
export const COLUMN_BASED = 2

export const tableDataType = (data: any): 1 | 2 | null => {
  if (isArray(data)) {
    if (data.length > 0 && typeof data[0] !== 'object') {
      return null
    }
    return ROW_BASED
  }
  if (typeof data === 'string') {
    return null
  }
  if (typeof data === 'object') {
    if (data != null && keys(data).length > 0) {
      if (values(data).every(isArray)) {
        return COLUMN_BASED
      }
    }
  }
  return null
}

export const formatList = (list: string[]): string => {
  list = list.slice()
  if (list.length === 0) return ''
  if (list.length === 1) return list[0]
  if (list.length === 2) return `${list[0]} and ${list[1]}`
  list[list.length - 1] = `and ${list[list.length - 1]}`
  return list.join(', ')
}

// Hacky way to sort plugin properties
const keyPriority: { [key: string]: number } = {
  sheetData: 10,
  tags: 10,
  selectedRow: 10,
  boundingBoxes: 10,
  value: 9,
  data: 8,
  error: 7,
  label: 6,
  pluginType: -100,
}
export const pureKeySorter = (a: string, b: string): number => {
  // certain keys are significantly more relevant than others
  const aV = keyPriority[a] || 0
  const bV = keyPriority[b] || 0
  if (aV || bV) {
    return bV - aV
  }

  // otherwise sort alphabetically
  return a < b ? -1 : 1
}

const groupPriority: any = {
  common: 10,
}

export const pureGroupSorter = (a: any, b: any): number => {
  const aV = a ? groupPriority[a] || 0 : -10
  const bV = b ? groupPriority[b] || 0 : -10
  return bV - aV
}

export const isSelectionActive = (): boolean => {
  const activeElement = (document.activeElement || {}) as HTMLInputElement
  return (
    activeElement.selectionStart !== undefined ||
    (activeElement.className || '').indexOf('ql-editor') !== -1 ||
    activeElement.contentEditable === 'true'
  )
}

// Returns true if the url is valid
export function isValidUrl(url: string): boolean {
  return !!URL_REGEX.test(url)
}

// Assert that the url begins with http or https
export function sanitizeUrl(url: string) {
  const trimmedUrl = url.trim()
  // should keep in sync with the DOMPurify regex at configureDOMPurify.ts
  const allowedProtocols = [
    'http',
    'https',
    'mailto',
    'tel',
    'sms',
    'facetime',
    'callto',
    'cid',
    'xmpp',
    'slack',
    'ftp',
    'ftps',
  ]
  if (allowedProtocols.find((protocol) => trimmedUrl.startsWith(`${protocol}:`))) {
    return trimmedUrl
  }
  throw Error(`Invalid URL: the URL may only start with one of the following protocols: ${allowedProtocols.join(', ')}`)
}

/**
 * convert a flat dictionary into a nested one
 * ex: { 'page.name': 'my_name', 'page.id': 2 } -> { 'page': { 'name': 'my_name', 'id': 2 }}
 *
 * note: this does not work for all cases ex: { 'one': 1, 'one.two': 'val' }
 */
export const unflatten = (obj: { [s: string]: any }) => {
  const ret = {}
  for (const key in obj) {
    setWith(ret, key, obj[key], Object)
  }
  return ret
}

export function partitionBy<T>(elements: T[], tester: (element: T) => boolean) {
  const a = []
  const b = []
  for (const e of elements) {
    if (tester(e)) {
      a.push(e)
    } else {
      b.push(e)
    }
  }
  return [a, b]
}

export const createDeepEqualSelector: typeof createSelector = createSelectorCreator(defaultMemoize, isEqual)
export const createImmutableDeepEqualSelector: typeof createSelector = createSelectorCreator(
  defaultMemoize,
  (a: any, b: any) => a === b || (a && b && isEqual(a.toJS(), b.toJS())),
)

export const createImmutableEqualitySelector: typeof createSelector = createSelectorCreator(
  defaultMemoize,
  (a: Immutable.Collection<any, any>, b: Immutable.Collection<any, any>) => {
    return a.equals(b)
  },
)

export const logger = {
  debug(message: any, ...optionalParams: any[]) {
    if (__DEV__ && !__TEST__) {
      // eslint-disable-next-line no-console
      console.debug(message, ...optionalParams)
    }
  },
  warn(message: any, ...optionalParams: any[]) {
    if (__DEV__ && !__TEST__) {
      // eslint-disable-next-line no-console
      console.warn(message, ...optionalParams)
    }
  },
}

// Copied from https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
export function getParameterByName(name: string, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const removeURLParameters = (...queryNames: string[]) => {
  const location = Object.assign({}, browserHistory.getCurrentLocation())
  queryNames.forEach((q) => delete location.query[q])
  browserHistory.push(location)
}

export function hexColorToRgbColor(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

// Returns the link to a page
export const getLink = (accessLevel: AccessLevel, path: Page['path'], hasPresentationModeFeature = true) => {
  const isWrite = (accessLevel: AccessLevel) => accessLevel === 'own' || accessLevel === 'write'
  if (!hasPresentationModeFeature || isWrite(accessLevel)) {
    return `/editor/${path}`
  } else {
    return `/apps/${path}`
  }
}

export const byteArrayToImgBase64String = (byteArray: number[], contentType?: string): string => {
  const data = btoa(
    new Uint8Array(byteArray).reduce(function (data, byte) {
      return data + String.fromCharCode(byte)
    }, ''),
  )
  return `data:${contentType ?? 'image/jpeg'};base64,${data}`
}

const isQueryParamTrue = (queryParam: string) => {
  const urlParams = new URLSearchParams(window.location.search)
  const value = urlParams.get(queryParam)
  return value === 'yes' || value === 'true'
}

export const isDatasourceTimerHidden = () => isQueryParamTrue('_hideTimer') || isQueryParamTrue('_embed')
export const isMinWidthDisabled = () => isQueryParamTrue('_noMinWidth') || isQueryParamTrue('_embed')
export const isNavigationAreaHidden = () => isQueryParamTrue('_hideNav') || isQueryParamTrue('_embed')

export const pagePathFromPage = (folders: Immutable.Map<String, ImmutableMapType<Folder>>, page: any) => {
  if (!page) {
    return
  }
  const folder = page.get('folderId') ? folders.get(String(page.get('folderId'))) : undefined
  let pagePath = ''
  if (folder && !(folder.get('systemFolder') && folder.get('name') === 'root')) {
    pagePath += `${encodeURIComponent(folder.get('name'))}/`
  }
  pagePath += encodeURIComponent(page.get('name'))

  return pagePath
}

export const pagePathFromUuid = (state: RetoolState, uuid: string) => {
  const folders = state.pages.get('folders')
  const page = state.pages.getIn(['pages', uuid])
  const pagePath = pagePathFromPage(folders, page)
  return pagePath
}

// If there is a signupTheme, append the theme name to the redirectUri to display signupTheme specific UI
// This prevents passing this to the backend
export const buildSignupRedirectUri = (
  redirectUri: string,
  signupTheme?: string,
  partialRegistrationType?: string,
  partialRegistrationId?: string,
  domain?: string,
  email?: string,
  joinToken?: string,
) => {
  const params = new URLSearchParams({})
  if (!redirectUri.includes('needAdmin=true') && signupTheme && isSupportedSignUpTheme(signupTheme)) {
    params.append('signupTheme', signupTheme)
  }
  if (new URLSearchParams(location.search).get('selfServiceOnPrem') !== null) {
    params.append('selfServiceOnPrem', 'true')
  }
  if (partialRegistrationType != null) {
    params.append('partialRegistrationType', partialRegistrationType)
  }
  if (partialRegistrationId != null) {
    params.append('partialRegistrationId', partialRegistrationId)
  }
  if (domain != null) {
    params.append('domain', domain)
  }
  if (email != null) {
    params.append('email', email)
  }
  if (joinToken != null) {
    params.append('joinToken', joinToken)
  }
  if ([...params.keys()].length === 0) {
    return redirectUri
  }
  return `${redirectUri + (redirectUri.split('?')[1] ? '&' : '?')}${params.toString()}`
}

export type ClientSideHttpRequestProperties = {
  query: string
  method: string
  headers: string
}

export async function performClientSideHttpRequest(properties: ClientSideHttpRequestProperties) {
  const clientSideHttpRequestProperties: ClientSideHttpRequestProperties = properties

  const headersArray: { key: string; value: string }[] = clientSideHttpRequestProperties.headers
    ? JSON.parse(clientSideHttpRequestProperties.headers)
    : []

  const headers = headersArray.reduce(
    (acc: { [key: string]: string }, keyValue) => ({ ...acc, [keyValue.key]: keyValue.value }),
    {},
  )

  const resRaw = await fetch(clientSideHttpRequestProperties.query, {
    method: clientSideHttpRequestProperties.method,
    headers,
    credentials: 'include', // send cookies, even for a cross-origin call
  })

  const res = await resRaw.json()
  return { res, status: resRaw.status }
}
export interface RecursiveObjectType {
  [key: string]: RecursiveObjectType | string
}

/**
 * Sample obj input: { test: { hello: 'hi' }}
 * Sample output: { "test.hello": 'hi' }
 */
export const flattenObj = (obj: RecursiveObjectType, parent?: string, res: { [key: string]: string } = {}) => {
  for (const key in obj) {
    const propName: string = parent ? `${parent}.${key}` : key
    if (typeof obj[key] === 'object') {
      flattenObj(obj[key] as RecursiveObjectType, propName, res)
    } else {
      res[propName] = obj[key] as string
    }
  }
  return res
}

export const isClonedTemplateASupportedSignupTheme = (clonedTemplateId: string) => {
  let isClonedTemplateASupportedSignupTheme = false
  signupThemeTemplateMap.forEach((templateIds) => {
    if (templateIds.includes(clonedTemplateId)) {
      isClonedTemplateASupportedSignupTheme = true
    }
  })
  return isClonedTemplateASupportedSignupTheme
}

// <_urlSearchParams> is a string
// like ?integration=postgresql&next=/clone-template/postgresql-admin-panel?demoResourceOptionOverrides=eyJbZGVtb10gc3
export const clonedTemplateInfoFromUrl = (_urlSearchParams: string) => {
  const search = new URLSearchParams(_urlSearchParams)
  const next = search.get('next')

  if (next) {
    const url = new URL(next, `https://demo.com`) // Second param is necessary for new URL() but not used
    const { pathname } = url // pathname like /clone-template/postgresql-admin-panel
    const splitPathname = pathname.split('/')
    if (splitPathname?.[1] !== 'clone-template') {
      return {}
    }
    const clonedTemplateId = last(splitPathname)

    const demoResourceOptionOverrides = url.searchParams.get('demoResourceOptionOverrides') || undefined
    return { clonedTemplateId, demoResourceOptionOverrides }
  }

  return {}
}

// /editor/postgresql-admin-panel -> postgresql-admin-panel
export const clonedTemplateIdFromRedirectOnLoginCookie = (redirectOnLoginCookie: string) => {
  const urlParams = redirectOnLoginCookie.split('/')
  if (urlParams.length !== 3 || !(urlParams[1] === 'clone-template' || urlParams[1] === 'editor')) {
    return undefined
  }
  const clonedTemplateId = urlParams[2]
  return clonedTemplateId
}

// Taken from: https://github.com/lodash/lodash/issues/2142#issuecomment-264391064
export function isJSON(str: string) {
  try {
    const obj = JSON.parse(str)
    if (obj && typeof obj === 'object' && obj !== null) {
      return true
    }
  } catch (err) {}
  return false
}

export const getRelativeDate = (date: string, includeTime = true): string => {
  const mDate = moment(date)
  const formatString = includeTime ? '[on] MMM D [at] h:mm A' : '[on] MMM D'
  return mDate.isSame(moment(), 'd')
    ? mDate.fromNow() // "X hours ago"
    : mDate.format(formatString)
}

// For setting cookies that should be usable when Retool is embedded in an iFrame. NOT to be used for auth-related cookies
// e.g. accessToken
export function setEmbedFriendlyCookie(
  name: string,
  value: string | object,
  options?: cookies.CookieAttributes | undefined,
) {
  let prodCookieAttributes = {}
  // We don't include secure=true when on localhost since it won't get set (requires https)
  if (!__DEV__) {
    // We set sameSite='None' on prod to make cookies accessible when embedded in iframe
    prodCookieAttributes = { sameSite: 'None', secure: true }
  }
  cookies.set(name, value, { ...options, ...prodCookieAttributes })
}

export const moveInArray = <T>(array: T[], from: number, to: number): T[] => {
  const newArray = _.cloneDeep(array)
  newArray.splice(to, 0, newArray.splice(from, 1)[0])
  return newArray
}

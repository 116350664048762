import { BuildingBlock, EXAMINE_DATA_BUILDING_BLOCK, WidgetBuildingBlock } from '../buildingBlocks'
import { allResourceSheets, allResourcesInput, allResourceTables } from 'components/BuildingBlocks/generatorInputs'

//Building block assumes a table is made from another building block (this is enforced in the building block grid)
const constructBuildingBlock = () => {
  const textContainer: WidgetBuildingBlock = {
    id: 'examineTextContainer',
    type: 'ContainerWidget',
    parentBuildingBlockId: EXAMINE_DATA_BUILDING_BLOCK,
    options: {
      title: {
        value: 'Examine your data',
        priority: 0,
      },
    },
  }

  const widgets = [textContainer]

  //This has no hint sequence so do not show it in the onboarding flow
  //TODO (andrew): make the new table generation a checkbox for this one
  const examineTableBuildingBlock: BuildingBlock = {
    id: EXAMINE_DATA_BUILDING_BLOCK,
    displayName: 'Examine a table',
    description: 'Show the value of each column in the selected row of a table',
    widgets,
    queries: [],
    showInEditor: true,
    showInOnboarding: false,
    generatorInputs: [allResourcesInput, allResourceTables, allResourceSheets],
  }

  return examineTableBuildingBlock
}

export default constructBuildingBlock

import React, { useMemo } from 'react'

import TextInput from 'components/design-system/TextInput'
import Select, { SelectValue } from 'components/design-system/Select'
import { Option } from 'components/design-system/Select'

import './NameAndFolderSelector.scss'
import { ImmutableMapType, Folder } from 'common/records'
import { connect } from 'react-redux'
import { RetoolState } from 'store'
import { creatorPrivilegedSelector, foldersWithWriteAccessSelector } from 'store/selectors'
import { newAppFolderIdSelector } from 'routes/Home/selectors'

type NameAndFolderSelectorProps = {
  appName: string | undefined
  foldersWithWriteAccess: ImmutableMapType<Folder>[]
  newAppFolderId: number
  onAppNameChange: (appName: string) => void
  onCurrentFolderIdChange: (folderId: number) => void
  isGlobalWidget?: boolean
  creatorPrivileged: boolean
}

function NameAndFolderSelector(props: NameAndFolderSelectorProps) {
  const { foldersWithWriteAccess } = props

  const onFolderChange = (value: SelectValue) => {
    if (typeof value === 'number') {
      props.onCurrentFolderIdChange(value)
    } else {
      // eslint-disable-next-line no-console
      console.error(`Selected folder is not valid. FolderId:${value}`)
    }
  }

  // we only want to display the folder selection dropdown if there are folders
  const hasFoldersToDisplay = useMemo(
    () => foldersWithWriteAccess.filter((folder) => folder.get('parentFolderId') !== null).length > 0,
    [foldersWithWriteAccess],
  )

  const defaultFolderValue = useMemo(() => {
    // check to ensure the user can create an app in the current folder
    if (foldersWithWriteAccess.find((folder) => folder.get('id') === props.newAppFolderId)) {
      return props.newAppFolderId
    } else {
      // otherwise return an id from what they can create in
      const folderId = foldersWithWriteAccess[0]?.get('id')
      // have to inform the parent so that if props.newAppFolderId is stateful we DONT
      // create an app in a folder the user cannot access.
      // TODO (alexi) refactor these modals
      props.onCurrentFolderIdChange(folderId)
      return folderId
    }
  }, [foldersWithWriteAccess, props.newAppFolderId])

  return (
    <div>
      <div className="name-and-folder-selector__name-div">
        <div className="name-and-folder-selector__label">
          {props.isGlobalWidget ? 'Module name' : 'App name'}{' '}
          <span className="name-and-folder-selector__name-required-asterisk">*</span>
        </div>
        <TextInput
          className="name-and-folder-selector__name-input"
          autoFocus
          onChange={(evt) => props.onAppNameChange(evt.target.value)}
          placeholder={`Give your ${props.isGlobalWidget ? 'module' : 'app'} a name`}
          value={props.appName}
        />
      </div>
      {hasFoldersToDisplay && (
        <div className="name-and-folder-selector__folder-div">
          <div className="name-and-folder-selector__label">Add to folder</div>
          <Select defaultValue={defaultFolderValue} optionFilterProp="title" showSearch onChange={onFolderChange}>
            {foldersWithWriteAccess.map((folder) => {
              const folderId = folder.get('id')
              const folderName = folder.get('name')
              const isRoot = folder.get('parentFolderId') === null
              return (
                <Option key={folderId} value={folderId} title={folderName}>
                  {isRoot ? <div className="light-gray">None (root)</div> : folderName}
                </Option>
              )
            })}
          </Select>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: RetoolState) => {
  return {
    foldersWithWriteAccess: foldersWithWriteAccessSelector(state),
    newAppFolderId: newAppFolderIdSelector(state),
    creatorPrivileged: creatorPrivilegedSelector(state),
  }
}

export default connect(mapStateToProps)(NameAndFolderSelector)

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RetoolDispatch } from 'store'
import { foldersWithWriteAccessSelector } from 'store/selectors'
import { bulkMovePages } from 'store/appModel/pages'

import MoveItemsModal, { MoveItemsModalProps } from './MoveItemsModal'

type MoveItemsModalOwnProps = Omit<MoveItemsModalProps, 'folders' | 'onRequestClose' | 'onSubmit'> & {
  selectedPageIds?: number[]
  closeModal?: () => void
  deselectItems?: () => void
}

const MoveItemsModalContainer: React.FC<MoveItemsModalOwnProps> = ({
  visible = false,
  selectedPageIds = [],
  deselectItems,
  closeModal,
}) => {
  const folders = useSelector(foldersWithWriteAccessSelector)
  const dispatch = useDispatch<RetoolDispatch>()

  const onSubmit = async (folderId: number) => {
    await dispatch(bulkMovePages(selectedPageIds, folderId))
    deselectItems?.()
    closeModal?.()
  }

  return (
    <MoveItemsModal
      folders={folders}
      visible={visible}
      onRequestClose={closeModal}
      numPages={selectedPageIds.length}
      onSubmit={onSubmit}
    />
  )
}

export default MoveItemsModalContainer

import React from 'react'

import './ShortcutKeys.scss'

export interface ShortcutKeysProps {
  /** A formatted string like  ' ⌘I' or ' ⌘DRAG' */
  keys?: string
}

const cn = 'shortcut-keys'

export default function ShortcutKeys({ keys = '' }: ShortcutKeysProps) {
  return (
    <>
      {keys.split(/(\b[^ ]+\b|[^ ])/).map((term) => {
        if (term === '' || term === ' ') {
          return null
        }
        if (term === '+') {
          return ' + '
        }
        if (term === 'Click' || term === 'Drag') {
          return term
        }
        return (
          <span key={term} className={`${cn}__key`}>
            {term === 'SPACE' ? 'Space' : term}
          </span>
        )
      })}
    </>
  )
}

/**
 * TRIGGER_GLOBAL_WIDGET_QUERY event type is sent from the global widget iframe to the parent
 * when a query in the parent should be triggered
 */
export const TRIGGER_GLOBAL_WIDGET_QUERY = 'triggerGlobalWidgetQuery'

/**
 * GLOBAL_WIDGET_OUTPUT_UPDATE event type is sent from the iframe to the parent
 * when the iframe wants to share updated "output" values
 */
export const GLOBAL_WIDGET_OUTPUT_UPDATE = 'globalWidgetOutputUpdate'

/**
 * REQUEST_GLOBAL_WIDGET_PROPERTIES event type is sent from the iframe to the parent
 * when the iframe is ready to receive properties (on initialization)
 */
export const REQUEST_GLOBAL_WIDGET_PROPERTIES = 'requestGlobalWidgetProperties'

/**
 * GLOBAL_WIDGET_PROPERTIES_UPDATE event type is sent from the parent to the iframe
 * with all the properties (sent whenever requested or when the properties change)
 */
export const GLOBAL_WIDGET_PROPERTIES_UPDATE = 'globalWidgetPropertiesUpdate'

// The id of the container that contains all the components that make up a global widget
export const GLOBAL_WIDGET_GRID = 'moduleContainer'

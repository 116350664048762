import React from 'react'
import classNames from 'classnames'
import { Input } from 'antd'
import { InputProps } from 'antd/lib/input/Input'
import './TextInput.scss'

class StandardTextInput extends React.PureComponent<InputProps, any> {
  private setTextInputRef: any
  private textInput: any
  constructor(props: any) {
    super(props)
    this.setTextInputRef = (ref: any) => (this.textInput = ref)
  }

  focus = () => {
    setTimeout(() => this.textInput.focus())
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focus()
    }
  }

  render() {
    return (
      <Input
        {...this.props}
        className={classNames('retool-text-input', this.props.className, {
          'retool-text-input--small': this.props.size === 'small',
        })}
        ref={this.setTextInputRef}
      />
    )
  }
}

export const TextArea = Input.TextArea

export default StandardTextInput

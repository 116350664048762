import React from 'react'

import { InputField } from '../common'
import { ResourceFormProps } from '../types'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'

const SendGridForm = (props: ResourceFormProps) => (
  <div className="grid-1c mb12">
    <InputField label="Access Token" resourceKey="api_key" required {...props} />
  </div>
)

export default {
  label: 'SendGrid',
  form: SendGridForm,
  defaults: {
    options: {
      customQueryParameters: [['', '']],
      customHeaders: [['Authorization', 'Bearer API_KEY']],
      authentication: 'apiKey',
      api_key: '',
      spec: `${RETOOL_SPECS_DOMAIN}/sendgrid/2.0/v3.0.json`,
    },
  },
  validator: ({ options }: any) => options.api_key,
}

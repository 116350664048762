import { AppTemplate, recordTransit } from 'common/records'
import { TemplateResolver } from 'components/plugins'
import * as Immutable from 'immutable'
import { RetoolState } from 'store/index'
import { appTemplateSelector } from 'store/selectors'
import { batchRecalculateTemplate, recalculateTemplate } from 'store/appModel/model'
import { SafeAny } from 'common/types'
import _ from 'lodash'
import { callApi } from 'store/callApi'
import { FAILURE_SAVE, RECEIVE_SAVE, REQUEST_SAVE } from 'store/appModel/actionTypes'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { WidgetTemplateUpdateOptionsType } from './templateUtilsTypes'

export const BATCH_WIDGET_TEMPLATE_CREATE = 'BATCH_WIDGET_TEMPLATE_CREATE'
export const WIDGET_TEMPLATE_UPDATE = 'WIDGET_TEMPLATE_UPDATE'
export const BATCH_WIDGET_TEMPLATE_UPDATE = 'BATCH_WIDGET_TEMPLATE_UPDATE'

export function deserializeSave(data: SafeAny) {
  const rtWithLoadingSaves = recordTransit.fromJSON(data)

  const newTemplate = new AppTemplate(rtWithLoadingSaves)

  return newTemplate.update('plugins', (plugins) => {
    return plugins.map((plugin) => {
      const Type = TemplateResolver(plugin.subtype)
      const defaultTemplate = Type()
      const template = defaultTemplate.merge(Immutable.fromJS(plugin.template))
      return plugin.set('template', template)
    })
  })
}

export function sendSave(metadata: SafeAny) {
  return async (dispatch: SafeAny, getState: () => RetoolState) => {
    const state = getState()
    const pageUuid = encodeURIComponent(state.pages.get('pageUuid'))
    const editorMode = state.pages.get('editorMode')
    // TODO: Obviously not the right thing to do
    const branchName = metadata.branch || state.pages.get('pageBranchName')

    // since encodeURIComponent(null) => "null"
    if (pageUuid === 'null' || !editorMode) {
      return
    }
    const template = appTemplateSelector(state)

    // Don't save anthing with a namespace since it is created on pageLoad
    const pluginsWithoutNamespace = template.get('plugins').filter((v) => {
      return !v.get('namespace')
    })
    const templateWithoutNamespaces = template.set('plugins', pluginsWithoutNamespace)

    const appState = recordTransit.toJSON(templateWithoutNamespaces)
    const changesRecord = state.changesRecord.get('changes').toJSON()
    const result = await dispatch(
      callApi({
        endpoint: `/api/pages/uuids/${pageUuid}/save`,
        body: JSON.stringify({ appState, changesRecord, branchName }),
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        types: [REQUEST_SAVE, RECEIVE_SAVE, FAILURE_SAVE],
      }),
    )

    if (result.type === RECEIVE_SAVE) {
      const appSize = JSON.stringify(appState).length
      const eventType = template.get('isGlobalWidget') ? 'Module Saved' : 'App Saved'
      retoolAnalyticsTrack(eventType, {
        ...getAppComplexity(template),
        appSize,
        pageName: state.pages.get('pageName'),
        pageUuid: state.pages.get('pageUuid'),
        trigger: metadata.trigger || 'debounced',
      })
    }
    return result
  }
}

export function batchWidgetTemplateUpdate(updates: { [widgetId: string]: SafeAny }) {
  return (dispatch: SafeAny, getState: () => RetoolState) => {
    dispatch({
      type: BATCH_WIDGET_TEMPLATE_UPDATE,
      payload: { updates },
    })

    debouncedSendSave(dispatch, getState)

    const plugins = appTemplateSelector(getState()).plugins
    const updatesToRecalculate = Object.entries(updates).map(([widgetId, update]) => ({
      widgetId,
      update,
      plugin: plugins.get(widgetId) ?? null,
    }))

    dispatch(batchRecalculateTemplate(updatesToRecalculate))
  }
}

export function widgetTemplateUpdate(
  widgetId: SafeAny,
  update: SafeAny,
  forceSave?: SafeAny,
  {
    isUserTriggered = true,
    additionalSelectorsToRender = Immutable.Set(),
    shouldRecalculateTemplate = true,
  }: WidgetTemplateUpdateOptionsType = {},
) {
  return async (dispatch: SafeAny, getState: () => RetoolState) => {
    // Update the template
    const plugin = appTemplateSelector(getState()).plugins.get(widgetId)
    dispatch({
      type: WIDGET_TEMPLATE_UPDATE,
      isUserTriggered,
      payload: { widgetId, plugin, update },
    })

    // WIP Primitive Edited event
    // Missing the following properties:
    // * trigger: was it changed by the user directly, or auto changed?
    // * efficiently batching
    //
    // for (let key of Object.keys(update)) {
    //   retoolAnalyticsTrack('Primitive Edited', {
    //     type: primitiveTypeToAnalyticsType[plugin.type],
    //     subtype: plugin.subtype,
    //     primitiveName: widgetId,
    //     propertyName: key,
    //     newValue: update[key],
    //   })
    // }

    if (forceSave) {
      await dispatch(sendSave({ trigger: 'forced' }))
    } else {
      debouncedSendSave(dispatch, getState)
    }

    // Now trigger a recalculation of the model if flag is true.
    if (shouldRecalculateTemplate) {
      dispatch(recalculateTemplate(widgetId, plugin ?? null, update, undefined, { additionalSelectorsToRender }))
    }
  }
}

export const debouncedSendSave = _.debounce((dispatch, _getState) => {
  return dispatch(sendSave({ trigger: 'debounced' }))
}, 2000)

export function getAppComplexity(appTemplate: AppTemplate) {
  let queryCount = 0
  let componentCount = 0
  let transformerCount = 0
  let stateCount = 0
  appTemplate.plugins.forEach((plugin) => {
    switch (plugin.type) {
      case 'datasource':
        queryCount += 1
        break
      case 'widget':
        componentCount += 1
        break
      case 'function':
        transformerCount += 1
        break
      case 'state':
        stateCount += 1
        break
    }
  })
  return { queryCount, componentCount, transformerCount, stateCount }
}

import React from 'react'
import classNames from 'classnames'

import { unused } from 'common/types'
import { Icon, Button } from 'components/design-system'

type Props = {
  disabled?: boolean
  favorite: () => Promise<void>
  isFavorited: boolean
  unfavorite: () => Promise<void>
}

export default function Favorite(props: Props) {
  const { disabled, favorite, isFavorited, unfavorite } = props

  const iconColor = isFavorited ? 'lightest-yellow' : 'lighter-gray'
  const toggleFavorite = isFavorited ? unfavorite : favorite

  const doNothing = (_: unused) => {}
  const performToggle = (e: React.MouseEvent) => {
    if (disabled !== true) {
      e.preventDefault()
      toggleFavorite()
    }
  }

  const onClick = disabled ? doNothing : performToggle

  // NB: `z-index` set because this is often in a clickable area
  return (
    <Button type="link" className="favorite-link" style={{ zIndex: 1 }} onClick={onClick}>
      <Icon className={classNames('favorite-icon', iconColor)} type="favorite" />
    </Button>
  )
}

import { OrderedMap } from 'immutable'
import _ from 'lodash'
import React from 'react'
import { localPluginsSelector } from 'store/selectors'
import { PluginTemplate } from '../../../common/records'
import { SafeAny } from '../../../common/types'
import { formatList, logger } from '../../../common/utils'
import { PluginPropertyAnnotationsResolver, TemplateCodeCustomAliasesResolver } from '../../../components/plugins'
import { showConfirm } from '../../../components/standards/Modal'
import { RetoolState } from '../../../store'
import { updateProperty } from '../../../store/appModel/dependencyGraph'
import { pluginDelete, getPluginIdsToDelete } from '../../../store/appModel/template'
import _styles from "./dialogs-styles.module.scss";


const styles = _styles

export type ResourceReference = {
  id: string
  property: string
  willCascade: boolean
}

export const calculateDependencies = (
  plugins: OrderedMap<string, PluginTemplate>,
  pluginId: string,
): ResourceReference[] => {
  const references: ResourceReference[] = []
  plugins.forEach((plugin) => {
    const propertyAnnotations = PluginPropertyAnnotationsResolver(plugin.subtype)
    plugin.template.forEach((templateString: string, key: string) => {
      // if we're an imported query field with template aliases, dont consider it since the app's scope is separate from our scope
      const hasTemplateAliases = !_.isEmpty(
        TemplateCodeCustomAliasesResolver(plugin.subtype)(plugin.template)([plugin.id, key]),
      )
      if (hasTemplateAliases) return

      const propertyType = _.get(propertyAnnotations, [key, 'type'])
      //Call update property to see if a new value exists ignore the return value. Don't actually update.
      const { newValueExists } = updateProperty(templateString, pluginId, '', propertyType)
      if (newValueExists) {
        //Don't replace templates in strings since we can't set a safe default
        const willCascade = propertyType === 'pluginId' || propertyType === 'pluginIdList'
        references.push({ id: plugin.id, property: key, willCascade })
      }
    })
  })
  return references
}

export function deletePluginWithDialog(pluginId: string, afterDelete?: () => void) {
  return (dispatch: SafeAny, getState: () => RetoolState) => {
    const state = getState()
    const plugins = localPluginsSelector(state)

    const dependentPlugins = calculateDependencies(plugins, pluginId)

    const doDelete = () => {
      dispatch(pluginDelete([pluginId]))
      if (afterDelete) {
        afterDelete()
      }
    }

    const listElementsToDelete = (a: ResourceReference[]) => {
      const elementsToDelete = a.map(
        (reference: { id: string; property: string }, i: number, arr: ResourceReference[]) => (
          <React.Fragment key={reference.id}>
            <code>
              {reference.id}
              {reference.property.length > 0 && '.'}
              {reference.property}
            </code>
            {arr.length - 1 === i ? '' : ', '}
          </React.Fragment>
        ),
      )
      return elementsToDelete
    }

    // If the plugin to delete is a container, get the child components
    const children = getPluginIdsToDelete([pluginId], state)
      .filter((id) => pluginId !== id)
      .map((id) => ({ id, property: '', willCascade: false }))

    if (dependentPlugins.length === 0) {
      const plugin = plugins.get(pluginId)
      if (plugin === undefined) {
        logger.warn("[dialogs] Couldn't find plugin object matching id")
        return
      }
      if (plugin.isWidget()) {
        let content
        if (!plugin.isModule() && children.length > 0) {
          content = (
            <div className={styles.content}>
              {`We will automatically delete the following child component${children.length > 1 ? 's' : ''}: `}
              {listElementsToDelete(children)}
            </div>
          )
        }
        showConfirm({
          title: `Are you sure you want to delete ${formatList(state.editor.selectedWidgets.toArray())}?`,
          content,
          onOk: doDelete,
          onCancel: () => {},
        })
      } else {
        doDelete()
      }
      return
    }

    const weDelete = dependentPlugins.filter((reference) => reference.willCascade)
    let weDeleteContent
    if (weDelete.length > 0) {
      const weDeleteReferenceLabel = weDelete.length > 1 ? 'references' : 'reference'
      weDeleteContent = (
        <>
          We will automatically delete the following standalone {weDeleteReferenceLabel}:{' '}
          {listElementsToDelete(weDelete)}
        </>
      )
    }

    const youDelete = dependentPlugins.filter((reference) => !reference.willCascade)
    let youDeleteContent
    if (youDelete.length > 0) {
      const youDeleteReferenceLabel = youDelete.length > 1 ? 'references' : 'reference'
      youDeleteContent = (
        <>
          You will need to manually delete the following JavaScript expression {youDeleteReferenceLabel}:{' '}
          {listElementsToDelete(youDelete)}
        </>
      )
    }

    const content = (
      <div className="query-editor__delete-confirmation">
        <div className="mb12">{weDeleteContent}</div>
        <div>{youDeleteContent}</div>
      </div>
    )

    const placeLabel = dependentPlugins.length > 1 ? 'places' : 'place'

    showConfirm({
      title: `${pluginId} is referenced in ${dependentPlugins.length} ${placeLabel}. Are you sure you want to delete it?`,
      content,
      okText: 'Delete',
      cancelText: "Don't delete",
      onOk: doDelete,
    })
  }
}

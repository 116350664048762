import * as DOMPurify from 'dompurify'

export default function configureDOMPurify() {
  DOMPurify.addHook('beforeSanitizeAttributes', function (node: any, _data: any, _config: any) {
    if ('target' in node) {
      if (node.getAttribute('target')) {
        node.setAttribute('data-retooltarget', '_blank')
      }
    }
    if ('controls' in node) {
      if (node.getAttribute('controls') != null) {
        node.setAttribute('data-retoolcontrols', 'true')
      }
    }
  })

  DOMPurify.addHook('afterSanitizeAttributes', function (node: any, _data: any, _config: any) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      if (node.getAttribute('data-retooltarget')) {
        node.removeAttribute('data-retooltarget')
        node.setAttribute('target', '_blank')
        node.setAttribute('rel', 'noopener noreferrer')
      }
    }
    if ('controls' in node) {
      if (node.getAttribute('data-retoolcontrols')) {
        node.removeAttribute('data-retoolcontrols')
        node.setAttribute('controls', '')
      }
    }
  })

  DOMPurify.setConfig({
    FORCE_BODY: true,
    // should keep in sync with the allowedProtocols in sanitizeUrl()
    ALLOWED_URI_REGEXP: /^(?:(?:(?:f|ht)tps?|mailto|tel|sms|facetime|callto|cid|xmpp|slack):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i,
  })
}

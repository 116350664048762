import React from 'react'

import { InputField, SSHTunnelForm } from './common'
import { ResourceFormProps } from './types'
import { Checkbox, Tooltip, Icon } from 'components/design-system'

const SAPHanaForm = (props: ResourceFormProps) => (
  <SSHTunnelForm {...props}>
    <>
      <hr />
      <h5 className="section-heading light-gray mb12">General</h5>
      <div className="grid-1c mb20">
        <InputField
          label={<span className="resource-form__connection-string-label">Connection String</span>}
          placeholder="jdbc:sap://host:port/?user=test&password=secure"
          resourceKey="connectionString"
          {...props}
        />
      </div>
      <div className="grid-1c mb12">
        <Checkbox
          checked={props.resource.options.disableServerSidePrepare}
          onChange={(checked) => {
            props.onChangeOption('disableServerSidePrepare')(checked.target.checked)
          }}
          className="grid-offset-1"
        >
          Disable converting queries to prepared statements
          <Tooltip title="This allows you to use Javascript to dynamically generate SQL but also turns off SQL injection protection.">
            <Icon type="tooltip" className="washed-gray hover-lightest-gray ml4" />
          </Tooltip>
        </Checkbox>
      </div>
    </>
  </SSHTunnelForm>
)

export default {
  label: 'SAP Hana',
  form: SAPHanaForm,
  defaults: {
    options: {
      connectionString: '',
    },
  },
}

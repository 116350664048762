import { WidgetOptionsResolver } from '../../..'

import Message from 'components/design-system/Message'

export default function isWidgetParentValid(widgetType: string, containerType: string | undefined): boolean {
  const { blockListViewPlacement } = WidgetOptionsResolver(widgetType)
  return containerType !== 'ListViewWidget' || !blockListViewPlacement
}

export function assertValidWidgetParent(widgetTypes: string[], containerType = '') {
  widgetTypes.forEach((widgetType) => {
    const isPositionValid = isWidgetParentValid(widgetType, containerType)

    if (!isPositionValid) {
      const msg = getInvalidPlacementMessage(widgetType, containerType)
      Message.error(msg)
      throw new Error(`Invalid Widget Placement: ${msg}`)
    }
  })
}

export function getInvalidPlacementMessage(widgetType: string, containerType: string): string {
  const widgetName = widgetType === 'GlobalWidget' ? 'Module' : WidgetOptionsResolver(widgetType)?.directory?.name
  const containerName = WidgetOptionsResolver(containerType)?.directory?.name
  return `Cannot place a ${widgetName} inside a ${containerName}`
}

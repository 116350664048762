import { ResourceFormProps } from '../resources/types'
import { AuthTemplate, ResourceCustomAuth, ResourceOauth2Auth, SupportedAuthType } from './generator-types'
import { defaultShowConnectedApp } from './OAuth2View'

/**
 * Generate validator function based on the type of authentication being used.
 *
 * A validator decides whether the "Create resource" button is enabled or not
 * based on the state of the form (e.g. at the most basic, we disable the Create resource button
 * until you provide a name for the resource. However, for things like API Key, we want to make sure
 * an API Key is entered.)
 */
function buildValidator(
  auth: AuthTemplate,
  resource: ResourceFormProps['resource'],
  currentAuthMode: SupportedAuthType,
) {
  if (currentAuthMode === 'oauth2') {
    const options = (auth.find((auth) => auth.type === 'oauth2') as ResourceOauth2Auth | undefined)?.options
    const showRetoolHostedAppView = options?.showHostedApp || defaultShowConnectedApp
    const showRetoolHosted = options?.retoolHostedApp && showRetoolHostedAppView(resource)
    if (!showRetoolHosted) {
      return resource?.options?.oauth2_client_id && resource?.options?.oauth2_client_secret
    }
    return true
  } else if (currentAuthMode === 'apiKey') {
    return resource?.options?.api_key
  } else if (currentAuthMode === 'custom') {
    const fields = (auth.find((auth) => auth.type === 'custom') as ResourceCustomAuth | undefined)?.fields
    const requiredFields = fields?.filter((field) => field.required)
    return requiredFields?.every((field) => !!resource.options?.[field.fieldName])
  }
}

export default buildValidator

import * as Immutable from 'immutable'
import { find, get, isEqual, isNaN, isNumber, pick, reduce, setWith, some } from 'lodash'
import { getState, RetoolDispatch, RetoolState } from 'store'
import { Message } from 'components/design-system'
import { dequeueQueryRun, enqueueQueryRun, modelUpdate, TERMINAL_LOG_UPDATE, triggerQuery } from 'store/appModel/model'
import getNamespaceAwareModel from 'store/appModel/getNamespaceAwareModel'
import { QueryTriggerOptions } from 'store/appModel/modelTypes'
import { getErrorFromResponse, throttled, throwIfMetadataError } from './common/utils'
import { baseProperties } from './common/docs'
import * as evaluator from 'common/evaluator'
import * as ajax from './common/ajax'
import { message } from 'antd'
import { showConfirm } from 'components/standards/Modal'
import {
  allActiveMocksSelector,
  appModelJSValuesSelector,
  appTemplateSelector,
  currentModeSelector,
  onPresentationSelector,
  pluginsSelector,
  queryStateSelector,
  runningQueriesSelector,
  testsSelector,
} from 'store/selectors'
import { ds, isJSON, sleep } from 'common/utils'
import { retoolAnalyticsError, retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { NAMESPACE_SEPARATOR, removeNamespace } from 'common/utils/namespaces'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { sanitize } from 'dompurify'
import confetti from 'canvas-confetti'
import { PluginNamespaceInfo, QueryTemplateShape, UntransformedQueryResponse } from 'common/records'
import { updateUntransformedQueryResponse } from 'store/editor'
import { selectImportablePlaygroundQuery } from 'routes/Editor/modules/editor'
import { defaultTemplate, QUERIES_WITH_ERRORS, QUERIES_WITH_METADATA } from './modelConstants'
import { updateModel } from 'components/plugins/datasources/common/unsafeUtils'
import { SafeAny } from 'common/types'
import {
  displayQueryFailureToast,
  evaluateQueryFailureConditions,
  getQueryNotificationDuration,
  throwIfErrorTransformerActivated,
} from 'components/plugins/datasources/queryFailureConditions'
import { reportQueryPerformanceStats, reportQueryRunStatsToDatadog } from 'routes/Editor/modules/performance'
import { QueryPerformanceTracer } from '../../../routes/Editor/modules/performance'

export const evaluateImportedQueryParams = async (
  template: QueryTemplateShape,
  additionalScope?: any,
  namespace?: PluginNamespaceInfo,
) => {
  const appScope: any = appModelJSValuesSelector(getState())
  const namespacedModel = getNamespaceAwareModel(appScope, namespace)
  const fullScope: any = Object.assign(namespacedModel, additionalScope || {})

  // Evaluate each input in this query and add it to queryModel
  // Ex: { 'user.name': 'first last', 'user.age': 40, 'amount': 123 }
  const queryModel = {}
  const [...inputs] = Object.keys(template.importedQueryInputs)
  for (const input of inputs) {
    const templateVal = template.importedQueryInputs[input]
    const val = templateVal
      ? await evaluator.evaluateSafe(fullScope, templateVal)
      : template.importedQueryDefaults[input]
    setWith(queryModel, input, val, Object)
  }

  return queryModel
}

// A global that stores query id -> timeout
// This ensures only one request for a repeat query is alive per query.
const currentTimeouts: any = {}

type ChangeSet = {
  requestSentTimestamp: number | null
  queryRunTime: number | null
  finished: number | null
  timestamp?: number
  error: any
  data?: any
  rawData?: any
  dataArray?: []
  queryExecution?: string
  errors?: any
  metadata?: any
}

const evaluateMockResponse = async (options: QueryTriggerOptions, queryId: string, templateModel: SafeAny) => {
  try {
    const { namespace } = options
    return await evaluator.evaluateQueryMockResponseTransformer(
      queryId,
      { data: {}, metadata: null },
      templateModel,
      namespace,
    )
  } catch (err) {
    message.error(`Could not evaluate mock transformer in ${queryId}: ${err}`)
  }
}

const runQuery = function (
  queryId: string,
  environment: any,
  queryType: any,
  templateModel: any,
  model: any,
  userTriggered: any,
  options: QueryTriggerOptions,
  resourceName: string,
  queryModel: SafeAny,
) {
  return async (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const queryPerformanceTracer = new QueryPerformanceTracer()
    // Get the current page uuid
    const currentPageUuid = getState().pages.get('pageUuid')
    const isImported = templateModel.get('isImported')

    const queryCarriesMetadata = QUERIES_WITH_METADATA.includes(queryType)
    const queryCarriesErrors = QUERIES_WITH_ERRORS.includes(queryType)

    let importedQueryParams = {}

    queryPerformanceTracer.mark('queryBegin')
    queryPerformanceTracer.mark('prepareBegin')

    if (isImported) {
      const additionalScope = options?.additionalScope
      importedQueryParams = await evaluateImportedQueryParams(templateModel.toJS(), additionalScope, options.namespace)
    }

    if (isImported) {
      const playgroundQueryId = templateModel.get('playgroundQueryId')

      // a query hasn't been chosen yet, so we can't run this
      if (!playgroundQueryId) return

      const mode = currentModeSelector(getState())

      if (mode === 'editor') {
        const queryState = queryStateSelector(getState())

        // if query is pinned to latest version, update the query editor UI to match latest version
        if (
          queryState.get('playgroundQueryId') === templateModel.get('playgroundQueryId') &&
          templateModel.get('playgroundQuerySaveId') === 'latest'
        ) {
          dispatch(selectImportablePlaygroundQuery(playgroundQueryId, 'latest', true))
        }
      }
    }

    const namespacedModel = getNamespaceAwareModel(model, options.namespace, templateModel.get('pluginType'))

    const extendedModel = {
      ...namespacedModel,
      ...(options != null ? options.additionalScope : {}),
      ...importedQueryParams,
    }
    let params: any = null

    const runQueryAfterConfirmation = async () => {
      const requestSentTimestamp = Date.now()

      let queryTriggerDelay = parseFloat(getState().appModel.values.getQuery(queryId).get('queryTriggerDelay'))
      if (isNaN(queryTriggerDelay) || !isNumber(queryTriggerDelay)) {
        queryTriggerDelay = 0
      }

      dispatch(
        modelUpdate(
          [
            { selector: [queryId, 'isFetching'], newValue: true },
            { selector: [queryId, 'requestSentTimestamp'], newValue: requestSentTimestamp },
            {
              selector: ['retoolContext', 'runningQueries'],
              newValue: runningQueriesSelector(getState()).concat(queryId),
            },
          ],
          options.stackId,
        ),
      )

      try {
        params = await queryModel.getRunQueryParams(templateModel.toJS(), extendedModel)
        let queryResultSize = 0
        let rawResponse
        let data
        let errors
        let metadata

        let changeSet: ChangeSet = {
          requestSentTimestamp: null,
          finished: null,
          queryRunTime: null,
          error: null,
        }

        const messageDuration = dispatch(getQueryNotificationDuration(queryId))
        queryPerformanceTracer.mark('prepareEnd')

        const queryHasMockResponseTransformer =
          templateModel.get('enableMockResponseTransformer') && templateModel.get('mockResponseTransformer')
        // Active mocks are a different concept used within tests to mock responses for a query
        const state = getState()
        const tests = testsSelector(state)
        const currentTest = tests.find((test) => test.id === options.testId)
        if (currentTest?.pauseAllQueries) {
          const mocks = allActiveMocksSelector(state)
          // Find returns the first occurrence and the user would expect the last mock to be the one that is used, so we're returning that.
          const mockForQuery = mocks[currentTest.id]?.reverse().find((mock) => mock.queryId)
          // If the user has an active mock they wrote it explicitly and we're running in a test so it should take priority
          if (mockForQuery) {
            data = mockForQuery.returnValue
          } else {
            data = ''
          }
        } else if (queryHasMockResponseTransformer) {
          data = await evaluateMockResponse(options, queryId, templateModel)
          metadata = null
        } else {
          if (params !== null) {
            queryPerformanceTracer.mark('backendBegin')
            if (__DEV__ || __BETA__) {
              const resourceNameOverride = get(extendedModel, [queryId, 'resourceNameOverride'])
              if (resourceNameOverride) {
                params.resourceNameParams = [resourceNameOverride]
              }
            }
            let res
            if (options.namespace) {
              // backend has no notion for a namespace so we send the original query id with
              // the UUID of the deepest namespace
              const deepestNamespaceUuid = find(model, (e) => {
                if (!e) {
                  return false
                }
                const namespaceArray = e?.namespace?.getNamespace() || []
                return isEqual(
                  namespaceArray.concat([e?.childNamespace]).join(NAMESPACE_SEPARATOR),
                  options.namespace?.getNamespace().join(NAMESPACE_SEPARATOR),
                )
              }).pageUuid
              // Long term we probably want the backend to have this logic
              res = await dispatch(
                ajax.runQuery(
                  options.namespace.getOriginalId(),
                  params,
                  queryType,
                  environment,
                  deepestNamespaceUuid,
                  true,
                ),
              )
            } else {
              res = await dispatch(ajax.runQuery(queryId, params, queryType, environment))
            }

            const previousQueryState = getState().appModel.values.getQuery(queryId)
            const timestampString = res.headers.get('timestamp')
            const timestamp = timestampString ? Number.parseInt(timestampString) : null // avoid NaNs
            const lastTimestamp = previousQueryState.get('timestamp')

            // don't do anything if our data is more recent than this request
            // We ignore this for query runs that were `userTriggered` because the logic is no longer valid
            // if you're trying to bulk create many many query runs. This might have edge cases, so let's keep
            // an eye out for intercom tickets / bug reports that might come out as a result of this.
            if (!userTriggered && lastTimestamp && timestamp && lastTimestamp > timestamp) return

            queryPerformanceTracer.mark('backendEnd')
            queryPerformanceTracer.mark('handleResponseBegin')

            try {
              const queryResponseJson = await res.json()
              // New format of Retool Query result that may contain metadata
              if (queryResponseJson.__retoolWrappedQuery__) {
                rawResponse = queryResponseJson.queryData
                queryPerformanceTracer.measure(
                  'dbconnectorTimeMs',
                  queryResponseJson.queryExecutionMetadata?.resourceTimeTakenMS,
                )
                queryPerformanceTracer.measure(
                  'estimatedResponseSizeBytes',
                  queryResponseJson.queryExecutionMetadata?.estimatedResponseSizeBytes,
                )
              } else {
                rawResponse = queryResponseJson
              }
              data = rawResponse
            } catch (err) {
              rawResponse = data = {}
            }

            if (queryCarriesMetadata) {
              data = rawResponse.data
              metadata = rawResponse.metadata
            }

            if (queryCarriesErrors) {
              errors = rawResponse.errors
              changeSet.errors = errors
            }

            changeSet.timestamp = timestamp!

            if (queryModel.handleResponse) {
              const handler = queryModel.handleResponse(templateModel.toJS(), extendedModel)
              if (handler) {
                data = await handler(data)
              }
            }

            // see if the query returned an error
            const maybeError = getErrorFromResponse(res, data, rawResponse)

            const untransformedResponse: UntransformedQueryResponse = {
              data,
              metadata,
              error: maybeError?.errorData,
              errors,
            }

            if (!untransformedResponse.data && isJSON(rawResponse.message)) {
              if (isJSON(rawResponse.message)) {
                untransformedResponse.data = JSON.parse(rawResponse.message)
              } else {
                untransformedResponse.data = rawResponse
              }
            }

            dispatch(updateUntransformedQueryResponse(queryId, untransformedResponse))
            await dispatch(
              evaluateQueryFailureConditions(
                templateModel,
                extendedModel,
                untransformedResponse,
                queryId,
                maybeError,
                rawResponse,
              ),
            )
            await throwIfErrorTransformerActivated(templateModel, data, metadata, errors, queryId, rawResponse)

            if (queryCarriesMetadata && metadata) {
              throwIfMetadataError(metadata, rawResponse)
            }

            queryPerformanceTracer.mark('handleResponseEnd')
          } else {
            queryPerformanceTracer.mark('frontendRunBegin')
            const passThroughOptions = pick(options, ['additionalScope', 'triggeredById']) // GlobalQuery needs this to pass on to the query it will call
            data = await dispatch(queryModel.runQuery(queryId, templateModel.toJS(), extendedModel, passThroughOptions))
            changeSet.data = data
            queryPerformanceTracer.mark('frontendRunEnd')
          }
        }

        let formattedData
        if (queryModel.formatResult) {
          formattedData = queryModel.formatResult(data, templateModel.toJS(), rawResponse)
        } else {
          formattedData = { data, metadata }
        }

        const rawData = formattedData.data
        // sometimes people delete the transformer value, expecting it to mean "don't use a transformer"
        // `&& templateModel.get('transformer')` ensures that behavior
        const queryHasTransformer = templateModel.get('enableTransformer') && templateModel.get('transformer')
        if (queryHasTransformer) {
          queryPerformanceTracer.mark('transformerBegin')
          try {
            const { namespace } = options
            const transformedData = await evaluator.evaluateQueryTransformer(
              queryId,
              { data: rawData, metadata },
              templateModel,
              namespace,
            )
            changeSet = {
              ...changeSet,
              ...formattedData,
              rawData,
              data: transformedData,
            }
          } catch (err) {
            message.error(`Could not evaluate transformer in ${queryId}: ${err}`, messageDuration)
          }
          queryPerformanceTracer.mark('transformerEnd')
        } else {
          changeSet = {
            ...changeSet,
            ...formattedData,
            rawData,
          }
        }
        // now that the query is complete, save it's timing
        const finished = Date.now()
        const queryRunTime = finished - requestSentTimestamp

        queryPerformanceTracer.mark('postProcessingBegin')
        changeSet.finished = finished
        changeSet.queryRunTime = queryRunTime

        await dispatch(updateModel(options.stackId, queryId, changeSet))

        if (options && options.onSuccess) {
          options.onSuccess(changeSet.data)
        }
        if (data === undefined) {
          queryResultSize = 0
        } else {
          queryResultSize = JSON.stringify(data).length
        }
        const mode = currentModeSelector(getState())
        if (!templateModel.get('runWhenModelUpdates') && userTriggered && !queryModel.options?.skipPostRunMessage) {
          if (templateModel.get('showSuccessToaster')) {
            if (templateModel.get('successMessage')) {
              Message.success(getState().appModel.values.getQuery(queryId).get('successMessage'), messageDuration)
            } else {
              // Show editors which query has run, e.g. query1 or global1's query1 (if query ran in a module)
              const { namespace } = options
              const queryName = namespace ? namespace.getFriendlyPluginName() : queryId

              const successMessage = `${mode === 'editor' ? queryName : 'Query'} ran successfully`
              Message.success(successMessage, messageDuration)
            }
          }
          if (templateModel.get('showSuccessConfetti')) {
            confetti({
              particleCount: 150,
              spread: 100,
            })
          }
        }

        if (!queryModel?.options?.skipOnSuccessQueries) {
          const triggersOnSuccessTemplate = templateModel.get('triggersOnSuccess') || []
          const additionalTriggerOnSuccess = options?.additionalOnSuccessQueries || []
          const triggersOnSuccess = triggersOnSuccessTemplate.concat(additionalTriggerOnSuccess)

          setTimeout(() => {
            triggersOnSuccess.forEach((query: any) => {
              dispatch(
                triggerQuery(query, true, undefined, {
                  pageUuid: currentPageUuid,
                  triggeredById: removeNamespace(queryId),
                  namespace: options?.namespace,
                }),
              )
            })
          }, queryTriggerDelay)
        }

        const triggeredById = options ? options.triggeredById : undefined
        const triggeredByWidget = triggeredById ? pluginsSelector(getState()).get(triggeredById) : undefined
        const triggeredBySubtype = triggeredByWidget ? triggeredByWidget.subtype : undefined

        const queryRunLog = {
          mode,
          type: 'query',
          subtype: queryType,
          name: queryId,
          environment,
          trigger: userTriggered ? 'auto' : 'action',
          triggeredById,
          triggeredBySubtype,
          clientQuery: !!params,
          queryRunTime,
          queryResultSize,
          resourceName,
          editorMode: templateModel.get('editorMode') ?? undefined,
        }
        retoolAnalyticsTrack('Query Run', queryRunLog)

        // Don't add this log into redux in presentation mode
        // This stores the log in memory forever and can be a huge source of memory leak
        // If you do store things in the log, make sure it's not query results because it can add up
        // very fast
        if (mode === 'editor') {
          const debuggerLog = {
            message: `[Success] Query ${queryId} finished in ${queryRunTime}ms. Total size: ${
              queryResultSize * 4
            } bytes`,
            ...queryRunLog,
            options,
            templateModel: templateModel.toJS(),
            queryStart: requestSentTimestamp,
            queryEnd: finished,
          }

          dispatch({
            type: TERMINAL_LOG_UPDATE,
            payload: {
              newLog: debuggerLog,
            },
          })
        }
      } catch (e) {
        if (!templateModel.get('runWhenModelUpdates') && userTriggered && !get(e, 'displayOptions.hideToast')) {
          let error = e.message
          if (error.length > 250) {
            error = `${error.substring(0, 247)}...`
          }

          const onPresentationMode = onPresentationSelector(getState())
          const errorMessage = onPresentationMode ? error : `${queryId}: ${error}`
          dispatch(displayQueryFailureToast(errorMessage, templateModel, queryId))
        }

        const finished = Date.now()
        const queryRunTime = finished - requestSentTimestamp
        const changeSet: ChangeSet = {
          finished,
          queryRunTime,
          requestSentTimestamp,
          error: e.errorData,
          rawData: null,
        }
        queryPerformanceTracer.mark('queryEnd')

        // Before failure conditions we were nesting `data` and `metadata` inside another `data`
        // like so: query1: { data: { data: blah, metadata: blah } }
        // Now that we're migrating people away from error transformers, this is a great opportunity
        // to migrate them to a new data model.
        // The new model will look like query1: { data: blah, metadata: blah }
        // We have the else because people are already relying on this behavior,
        // and we can't migrate without breaking their apps
        if (e.trigger === 'FAILURE_CONDITION') {
          changeSet.data = get(e, 'data.data') || get(e, 'data')
          changeSet.metadata = get(e, 'data.metadata') || null
        } else {
          changeSet.data = e.data
          if (queryCarriesMetadata) {
            changeSet.metadata = e.metadata || null
          }
        }

        if (queryCarriesErrors) {
          changeSet.errors = e.data.errors
        }

        dispatch(updateModel(options.stackId, queryId, changeSet))
        if (options && options.onFailure) {
          //Before we passed the object as a string so we need to continue doing that for backwards compatibility
          options.onFailure(JSON.stringify(e))
        }

        if (!queryModel?.options?.skipOnFailureQueries) {
          const triggersOnFailureTemplate = templateModel.get('triggersOnFailure') || []
          const additionalTriggerOnFailure = options?.additionalOnFailureQueries || []
          const triggersOnFailure = triggersOnFailureTemplate.concat(additionalTriggerOnFailure)
          setTimeout(() => {
            triggersOnFailure.forEach((query: any) => {
              dispatch(
                triggerQuery(query, true, undefined, {
                  namespace: options?.namespace,
                  triggeredById: queryId,
                }),
              )
            })
          }, queryTriggerDelay)
        }

        const mode = currentModeSelector(getState())

        const queryRunError = {
          mode,
          type: 'query',
          subtype: queryType,
          name: queryId,
          environment,
          trigger: userTriggered ? 'auto' : 'action',
          clientQuery: !!params,
          queryRunTime,
          resourceName,
        }

        retoolAnalyticsError('queryRunError', e.message, queryRunError)

        // Don't add debug log into redux in presentation mode
        // This stores the log in memory forever and can be a huge source of memory leak
        // If you do store things in the log, make sure it's not query results because memory can add up
        // very fast
        if (mode === 'editor') {
          const debuggerLog = {
            message: `[Failure] Query ${queryId} failed in ${queryRunTime}ms. Error: ${e.message}`,
            ...queryRunError,
            error: e,
            options,
            templateModel: templateModel.toJS(),
          }

          dispatch({
            type: TERMINAL_LOG_UPDATE,
            payload: {
              newLog: debuggerLog,
            },
          })
        }
      }

      setTimeout(() => {
        // Multiple same queryId could be firing at the same time
        function removeFirstQueryIdFound(queries: string[]) {
          const index = queries.findIndex((val) => val === queryId)
          return queries.filter((_, i) => i !== index)
        }

        dispatch(
          modelUpdate(
            [
              { selector: [queryId, 'isFetching'], newValue: false },
              {
                selector: ['retoolContext', 'runningQueries'],
                newValue: removeFirstQueryIdFound(runningQueriesSelector(getState())),
              },
            ],
            options.stackId,
          ),
        )
      }, 100)

      if (templateModel.get('queryRefreshTime')) {
        const previousTimeout = currentTimeouts[queryId]
        if (previousTimeout) {
          clearTimeout(previousTimeout)
        }
        currentTimeouts[queryId] = setTimeout(() => {
          dispatch(triggerQuery(queryId, true, undefined, { pageUuid: currentPageUuid }))
        }, parseInt(get(model, [queryId, 'queryRefreshTime']), 10))
      }
      queryPerformanceTracer.mark('postProcessingEnd')

      queryPerformanceTracer.mark('queryEnd')

      const queryRunStats = queryPerformanceTracer.calculateQueryRunStats()
      dispatch(reportQueryPerformanceStats(queryId, queryRunStats))
      setImmediate(() => {
        reportQueryRunStatsToDatadog(queryRunStats, resourceName)
      })
    }

    if (templateModel.get('requireConfirmation')) {
      const confirmationMessage = templateModel.get('confirmationMessage')
        ? await evaluator.evaluateSafe(extendedModel, templateModel.get('confirmationMessage'))
        : `Are you sure you want to run ${queryId}?`

      showConfirm({
        title: <ReactMarkdown renderers={{ root: 'div' }} source={sanitize(confirmationMessage)} escapeHtml={false} />,
        onOk() {
          return runQueryAfterConfirmation()
        },
        onCancel() {
          // eslint-disable-next-line no-console
          console.log('Cancelled confirmation of query')
        },
      })
    } else {
      return runQueryAfterConfirmation()
    }
  }
}

export default function modelWrapper(queryModel: any) {
  // TODO use Immutable.Record to typecheck this?
  const template = {
    ...defaultTemplate,
    ...queryModel.template,
  }

  return {
    name: queryModel.name,

    template: Immutable.fromJS(template),

    options: {
      ...queryModel.options,
      docs: {
        description: 'A query that can fetch data or perform actions. Commonly used: `.data`',
        ...get(queryModel, 'options.docs'),
        properties: {
          ...baseProperties,
          ...get(queryModel, 'options.docs.properties'),
        },
      },
      propertyAnnotations: {
        triggersOnSuccess: { type: 'pluginIdList' },
        triggersOnFailure: { type: 'pluginIdList' },
        ...get(queryModel, 'options.propertyAnnotations'),
        ...reduce(
          get(queryModel, 'queryProperties', []),
          (acc, property) => {
            return {
              ...acc,
              [property]: {
                ...(get(queryModel, ['options', 'propertyAnnotations', property]) || {}),
                updatesAsync: (template: any) => {
                  // TODO: how about when runWhenPageLoads?
                  if (template.template.get('runWhenModelUpdates')) {
                    return ['data']
                  } else {
                    return []
                  }
                },
              },
            }
          },
          {},
        ),
      },
    },

    // TODO set default values in model
    // isFetching: false,
    // error: null,
    // data: null,
    // timestamp: null,

    runQuery,

    getPreviewParams: async (queryTemplate: any, modelValues: any) => {
      const importedQueryParams = queryTemplate.isImported ? await evaluateImportedQueryParams(queryTemplate) : {}
      const previewParams = await queryModel.getPreviewParams(queryTemplate, {
        ...modelValues,
        ...importedQueryParams,
      })
      if (__DEV__ || __BETA__) {
        if (queryTemplate.resourceNameOverride) {
          const resourceName = await evaluator.evaluateSafe(modelValues, queryTemplate.resourceNameOverride)
          if (resourceName) {
            previewParams.userParams.resourceNameParams = [resourceName]
          }
        }
      }
      return previewParams
    },
    runPreview: queryModel.runPreview,
    resourceSpecificTemplateDefaults: queryModel.resourceSpecificTemplateDefaults,
    onTemplateUpdate(
      id: string,
      userTriggered: boolean,
      instance: number,
      pageLoad: boolean,
      options: QueryTriggerOptions,
    ) {
      return (dispatch: RetoolDispatch, getState: () => RetoolState) => {
        const model = {
          ...appModelJSValuesSelector(getState()),
          // the pluginId of the plugin that triggered this query
          // allows users to have very primitive parametrized queries
          triggeredById: options ? options.triggeredById : undefined,
          // The row that the query was triggered from. Defined if the
          // query is called from a button on a table.
          currentRow: options ? options.currentRow : undefined,
          // listview index, if appropriate
          i: instance,
        }
        const messageDuration = dispatch(getQueryNotificationDuration(id))

        const plugin = appTemplateSelector(getState()).getIn(['plugins', id])
        const template = plugin.template
        const queryType = plugin.subtype
        const resourceName = plugin.resourceName
        const environment = getState().appModel.environment
        if (getState().appModel.pendingUserTriggeredQueries.has(id)) {
          const extendedOptions = getState().appModel.pendingUserTriggeredQueryOptions.get(id)
          dispatch(dequeueQueryRun(id))
          userTriggered = true
          options = Object.assign({}, extendedOptions, options)
        } else {
          if (options && options.updatedParams) {
            const watchedParams = template.get('watchedParams') || Immutable.List()
            for (const param of watchedParams) {
              for (const updatedParam of Object.keys(options.updatedParams)) {
                // The .indexOf is not precise, but it is close enough in most cases.
                // The case where an exact match does not work is when something
                // like: "textinput1.value || textinput2.value" is being watched, but the
                // updatedParams only has the key "textinput1.value"
                if (param.indexOf(updatedParam) !== -1) {
                  userTriggered = true
                }
              }
            }
          }
        }

        const appModel = getState().appModel
        const queryProperties = queryModel.queryProperties || []
        const hasDirtyAncestors = some(queryProperties, (property) => {
          return appModel.hasDirtyAncestors([id, property as string])
        })

        if (template.get('queryDisabled')) {
          if (get(model, [id, 'queryDisabled'])) {
            const disabledMessage = get(model, [id, 'queryDisabledMessage'])
            if (userTriggered && disabledMessage) {
              message.warning(disabledMessage, messageDuration)
            }
            // eslint-disable-next-line no-console
            console.log(`Returning selectors to mark clean: `, id)
            return {
              selectorsToMarkClean: [[id, 'data']],
            }
          }
        }

        // if the only thing that changed is the transformer, 1. run the transformer again and 2. don't run the query
        if (options && options.updatedParams) {
          let queryParamsUpdated = 0
          for (const updatedParam of Object.keys(options.updatedParams)) {
            const pluginId = ds(updatedParam)[0]
            if (pluginId === id) {
              queryParamsUpdated += 1
            }
          }

          // if the only template param updated was the transformer
          if (queryParamsUpdated === 1 && options.updatedParams[`${id}.transformer`]) {
            // 1. if transformer is enabled, update transformer
            if (template.get('enableTransformer')) {
              evaluator
                .evaluateQueryTransformer(
                  id,
                  { data: get(model, [id, 'rawData']), metadata: get(model, [id, 'metadata']) },
                  template,
                  options.namespace,
                )
                .then((transformedData) => {
                  return dispatch(updateModel(options.stackId, id, { data: transformedData }))
                })
                .catch((err) => {
                  message.error(`Could not evaluate transformer in ${id}: ${err}`, messageDuration)
                })
            }

            // 2. return early to avoid re-running the query
            return
          }
        }

        // TODO also check renderedTemplate.type === 'GET'?
        // check triggersOnSuccess.length == 0?
        if (userTriggered) {
          if (hasDirtyAncestors) {
            // skip it, but enqueue it so that it gets run when it is possible to run it.
            dispatch(enqueueQueryRun(id, options))
            return
          }
          // Do not throttle if it is user triggered.
          dispatch(
            runQuery(id, environment, queryType, template, model, userTriggered, options, resourceName, queryModel),
          )
        } else if (
          !options?.isNew &&
          (template.get('runWhenModelUpdates') || (pageLoad && template.get('runWhenPageLoads')))
        ) {
          if (hasDirtyAncestors) {
            // skip!
            return
          }

          let pageDelayPromise = Promise.resolve()
          if (pageLoad && template.get('runWhenPageLoadsDelay')) {
            pageDelayPromise = sleep(parseInt(template.get('runWhenPageLoadsDelay')))
          }

          return pageDelayPromise.then(() => {
            return throttled(
              (...args: SafeAny) => dispatch((runQuery as SafeAny)(...args)),
              queryModel.name,
              template.get('queryThrottleTime'),
            )(id, environment, queryType, template, model, userTriggered, options, resourceName, queryModel)
          })
        }
      }
    },
    handleResponse: queryModel.handleResponse,

    hasChanged: (a: any, b: any): boolean => {
      return Object.keys(template).reduce((acc: boolean, k) => {
        return acc || !isEqual(a[k], b[k])
      }, false)
    },
  }
}

import Omnibox from 'components/Omnibox'
import TemplateOnboardingNotice from 'components/Onboarding/TemplateOnboardingNotice'
import TopNavigation from 'components/TopNavigation'
import cookies from 'js-cookie'
import ls from 'local-storage'
import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { RetoolState } from 'store'

import {
  currentPathSelector,
  editorPrivilegedSelector,
  isNewAccountSelector,
  intercomOpenSelector,
  on2FASelector,
  onEditorOnboardingSelector,
  onEditorSelector,
  onPlaygroundSelector,
  onPresentationSelector,
  onResourceOnboardingSelector,
  onResourcesSelector,
  onEditOrCreateResourceSelector,
} from 'store/selectors'
import { getExperimentValue } from 'common/utils/experiments'
import { isNavigationAreaHidden } from '../../common/utils'
import EditorOnboarding from '../../routes/Editor/components/modals/EditorOnboardingAsync'
import './CoreLayout.scss'
import { HelpBubble } from 'components/HelpBubble/HelpBubble'
import { toggleIntercom } from 'store/intercomUtils'
import { onboardingSelector } from 'store/onboarding/selectors'

type CoreLayoutProps = {
  onPresentation?: boolean
  onEditor: boolean
  on2FA: boolean
  showOnboarding?: boolean
  showTemplateOnboardingNotice: boolean
  isEditor: boolean
  intercomOpen: boolean
  onResourceOnboarding: boolean
  toggleIntercom: (newState: boolean) => void
}

export const CoreLayout: React.FC<CoreLayoutProps> = (props) => {
  const topNavigationHidden =
    props.onEditor ||
    props.on2FA ||
    props.onResourceOnboarding ||
    isNavigationAreaHidden() ||
    (props.onPresentation && getExperimentValue('appShell'))

  window.Intercom('onShow', () => {
    props.toggleIntercom(true)
  })

  window.Intercom('onHide', () => {
    props.toggleIntercom(false)
  })

  return (
    <Omnibox
      className={`container text-center app-container${props.onPresentation ? ' presentation-mode' : ''}${
        props.onEditor ? ' editor-mode' : ''
      }${props.on2FA ? ' two-factor-mode' : ''}${props.isEditor ? ' user-is-editor' : ''}`}
    >
      {!topNavigationHidden && <TopNavigation renderAsIfOnPresentation={!!props.onPresentation} />}
      <div className="core-layout__viewport">{props.children}</div>
      {props.showOnboarding && props.onEditor && <EditorOnboarding />}
      {props.showTemplateOnboardingNotice && <TemplateOnboardingNotice />}
      {!(props.onPresentation && (getExperimentValue('appShell') || getExperimentValue('pageDocumentation'))) &&
        props.isEditor &&
        !props.intercomOpen && <HelpBubble />}
    </Omnibox>
  )
}

const mapDispatchToProps = {
  toggleIntercom,
}

const onOnboardingAppSelector = createSelector(currentPathSelector, (path) => {
  return decodeURIComponent(path || '').indexOf('Onboarding Page') !== -1
})

const hasntFinishedOnboardingSelector = () => cookies.get('onboardingUrl')

export const showOnboardingSelector = createSelector(
  onPresentationSelector,
  onEditorSelector,
  onboardingSelector,
  onOnboardingAppSelector,
  (onPresentation, onEditor, onboarding, onOnboardingApp) => {
    return onboarding.showOnboarding && (onPresentation || onEditor) && onOnboardingApp
  },
)

const onTemplateOnboardingNoticeBlocklistSelector = createSelector(
  onEditOrCreateResourceSelector, // Notice hides the save/create button on resource page so we hide it
  (onEditOrCreateResource) => {
    return onEditOrCreateResource
  },
)

const showTemplateOnboardingNoticeSelector = createSelector(
  isNewAccountSelector,
  hasntFinishedOnboardingSelector,
  showOnboardingSelector,
  onEditorOnboardingSelector,
  onTemplateOnboardingNoticeBlocklistSelector,
  (isNewAccount, hasntFinishedOnboarding, showOnboarding, onEditorOnboarding, onTemplateOnboardingNoticeBlocklist) => {
    return !!(
      ls.get('templateUserInfo') &&
      isNewAccount &&
      !showOnboarding &&
      hasntFinishedOnboarding &&
      !onEditorOnboarding &&
      !onTemplateOnboardingNoticeBlocklist
    )
  },
)

const showOnboardingChecklistSelector = createSelector(
  showOnboardingSelector,
  onboardingSelector,
  (showOnboarding, onboarding) => {
    return !showOnboarding && onboarding.onboardingChecklist
  },
)

const mapStateToProps = (state: RetoolState) => {
  return {
    isEditor: editorPrivilegedSelector(state),
    onEditor: onEditorSelector(state),
    on2FA: on2FASelector(state),
    onPresentation: onPresentationSelector(state),
    onPlayground: onPlaygroundSelector(state),
    onResources: onResourcesSelector(state),
    onResourceOnboarding: onResourceOnboardingSelector(state),
    showOnboarding: showOnboardingSelector(state),
    showTemplateOnboardingNotice: showTemplateOnboardingNoticeSelector(state),
    showOnboardingChecklist: showOnboardingChecklistSelector(state),
    intercomOpen: intercomOpenSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoreLayout)

import * as React from 'react'
import { AutoSizer, List, ListRowRenderer } from 'react-virtualized'
import classnames from 'classnames/bind'
import CustomIcon from 'components/standards/CustomIcon'
import { AdhocResourceType, ResourceType } from 'common/resourceTypes'
import { resourceIcons } from 'retoolConstants'
import _styles from "./Results-styles.module.scss";

import { SearchResult } from '../extensions'

const styles = _styles

const cx = classnames.bind(styles)

const RESULT_ROW_HEIGHT = 36
const NO_RESULTS_HEIGHT = 300

interface NoResultsFoundProps {
  onResetQuery: () => void
}

const NoResultsFound: React.FC<NoResultsFoundProps> = ({ onResetQuery }) => {
  return (
    <div className={cx('noResults')}>
      <CustomIcon className={cx('icon')} type="noResultsFound" />
      <div>No results founds.</div>
      <div>
        Try a different query or{' '}
        <span onClick={onResetQuery} className={cx('resetQueryText')}>
          show everything alphabetically
        </span>
        .
      </div>
    </div>
  )
}

const ResourceIcon = ({ resourceType }: { resourceType: string }) => {
  const src = resourceIcons[resourceType as ResourceType | AdhocResourceType]
  return <img title={resourceType} className={cx('resourceIcon')} src={src} />
}

type ResultItemProps = {
  item: SearchResult
  isSelected: boolean
  onMouseMove?: (e: React.MouseEvent) => void
  onClickItem?: (e: React.MouseEvent) => void
  style?: React.CSSProperties
}

const ResultItem: React.FC<ResultItemProps> = ({ item, isSelected, onClickItem, onMouseMove, style }) => {
  let typeIcon
  let typeText
  let secondaryIcon = null

  if (item.type === 'page') {
    typeIcon = 'app'
    typeText = 'Retool App'
  } else if (item.type === 'module') {
    typeIcon = 'module'
    typeText = 'Module'
  } else if (item.type === 'resource') {
    typeIcon = 'resource'
    typeText = 'Resource'
    secondaryIcon = <ResourceIcon resourceType={item.resourceType} />
  } else if (item.type === 'staticpage') {
    typeIcon = 'page'
    typeText = 'Page'
  } else if (item.type === 'folder') {
    typeIcon = 'folder'
    typeText = 'Folder'
  } else if (item.type === 'query') {
    typeIcon = 'query'
    typeText = 'Query'
    secondaryIcon = <ResourceIcon resourceType={item.resourceType} />
  }

  return (
    <div
      className={cx('result-item', {
        'result-item--selected': isSelected,
      })}
      onClick={onClickItem}
      onMouseMove={onMouseMove}
      style={style}
    >
      <div className="result-item-left">
        <CustomIcon type={typeIcon as any} className="result-item-icon" />
        <span className="result-item-name">{item.title}</span>
      </div>
      <div className="result-item-type">
        {secondaryIcon}
        {typeText}
      </div>
    </div>
  )
}

interface ResultsListProps {
  adjustScroll?: boolean | number
  // the currently selected index
  selectedIndex: number
  // list of result items
  items: SearchResult[]
  onMouseMoveItem?: (index: number) => void
  onClickItem?: (e: React.MouseEvent) => void
  // optional override container style
  style?: React.CSSProperties
  onResetQuery: () => void
}

const ResultsList: React.FC<ResultsListProps> = ({
  items,
  selectedIndex,
  onMouseMoveItem,
  onClickItem,
  onResetQuery,
}) => {
  const renderResultRow: ListRowRenderer = ({ style, index }) => {
    const item = items[index]
    return (
      <ResultItem
        key={index}
        style={style}
        isSelected={selectedIndex === index}
        item={item}
        onClickItem={onClickItem}
        onMouseMove={onMouseMoveItem && (() => onMouseMoveItem(index))}
      />
    )
  }

  const containerHeight = items.length > 0 ? Math.min(items.length, 8) * RESULT_ROW_HEIGHT : NO_RESULTS_HEIGHT

  return (
    <div className={cx('container')} style={{ height: containerHeight }}>
      <AutoSizer>
        {({ width, height }) => (
          <List
            width={width}
            height={height}
            rowHeight={RESULT_ROW_HEIGHT}
            rowCount={items.length}
            rowRenderer={renderResultRow}
            noRowsRenderer={() => <NoResultsFound onResetQuery={onResetQuery} />}
          />
        )}
      </AutoSizer>
    </div>
  )
}

export default ResultsList

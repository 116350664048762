import SideIcon from 'assets/generate_an_app_from_data_icon.svg'
import { AutogeneratePageParams, Folder, ImmutableMapType } from 'common/records'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { Icon, Message } from 'components/design-system'
import Button from 'components/design-system/Button'
import Immutable from 'immutable'
import { callInternalApi } from 'networking'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { newAppFolderIdSelector } from 'routes/Home/selectors'
import { RetoolState } from 'store'
import { foldersSelector } from 'store/selectors'
import { navigateToApp } from './CreateAppModal'
import './EditAppFromDataBody.scss'
import NameAndFolderSelector from './NameAndFolderSelector'

type EditAppFromDataBodyProps = {
  onBack: () => void
  folders: Immutable.Map<String, ImmutableMapType<Folder>>
  newAppFolderId: number
  closeModal: () => void
  selectedResourceData: AutogeneratePageParams | undefined
}

function isValidState(appName: string | undefined, selectedResourceData: AutogeneratePageParams | undefined) {
  const appNameValid = appName !== undefined && appName.length > 0
  const selectedResourceDataValid = selectedResourceData !== undefined
  return appNameValid && selectedResourceDataValid
}

function EditAppFromDataBody(props: EditAppFromDataBodyProps) {
  const [appName, setAppName] = useState<string | undefined>()
  const [parentFolderId, setParentFolderId] = useState<number>(props.newAppFolderId)
  const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false)

  // If we change our current directory, default to that in the folder selector
  useEffect(() => {
    setParentFolderId(props.newAppFolderId)
  }, [props.newAppFolderId])

  useEffect(() => {
    setIsSubmitEnabled(isValidState(appName, props.selectedResourceData))
  }, [appName, props.selectedResourceData])

  const createAppFromBody = async () => {
    const body: AutogeneratePageParams = {
      ...props.selectedResourceData!, // checked for undefined in isValidState
      ...{ appName, folderId: parentFolderId },
    }

    const response = await callInternalApi({
      url: `/api/pages/autogeneratePage`,
      method: 'POST',
      body,
    })
    return response.url
  }

  const create = () => {
    if (!isValidState(appName, props.selectedResourceData)) {
      return
    }

    try {
      createAppFromBody()
      retoolAnalyticsTrack('App Created', { createType: 'autogenerate', appName, parentFolderId })
    } catch (err) {
      return Message.error(err.message)
    }

    const selectedFolder = props.folders.get(parentFolderId.toString())
    navigateToApp({ folder: selectedFolder, appName: appName!, inEditView: false })

    props.closeModal()
  }

  return (
    <div className="flex ph36 pv24 fd-col">
      <div className="flex">
        <div>
          <img src={SideIcon} />
        </div>
        <div className="ml36 edit-from-data-modal-body__name-and-folder-container">
          <NameAndFolderSelector
            appName={appName}
            onAppNameChange={setAppName}
            onCurrentFolderIdChange={setParentFolderId}
          />
        </div>
      </div>
      <div className="flex justify-between mt12 edit-from-data-modal-body__footer-container">
        <div>
          <Button type="link" onClick={props.onBack}>
            <div className="flex items-center">
              <div>
                <Icon type="caret-left" />
              </div>
              <div>Select a different data source</div>
            </div>
          </Button>
        </div>
        <div>
          <Button type="primary" disabled={!isSubmitEnabled} onClick={create}>
            Create app
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RetoolState) => {
  return {
    folders: foldersSelector(state),
    newAppFolderId: newAppFolderIdSelector(state),
  }
}

export default connect(mapStateToProps)(EditAppFromDataBody)

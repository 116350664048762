import React from 'react'
import { OnboardingStep, OnboardingSubStep, AppModelType, PluginModel } from 'common/records'
import * as _ from 'lodash'
import Immutable from 'immutable'
import { SafeAny } from 'common/types'

import { Icon, Button } from 'components/design-system'
import { shortcutFormatter } from 'shortcutsKeymap'
import OnboardingSurvey from '../OnboardingSurvey'

import { eventHandlerClickOnButton } from './welcomeFlow'
import HelloComponentsImage from 'assets/onboarding-images/hello-components.png'
import LearnRetoolImage from 'assets/onboarding-images/firebase-onboarding-images/learn-retool.png'
import UpdateUserImage from 'assets/onboarding-images/firebase-onboarding-images/update-user.png'
import TableMeetsDataImage from 'assets/onboarding-images/firebase-onboarding-images/table-meets-data.png'
import TestingItOutImage from 'assets/onboarding-images/firebase-onboarding-images/testing-it-out.png'
import UnderstandSelectedRowImage from 'assets/onboarding-images/firebase-onboarding-images/understand-selected-row.png'
import OnSuccessTriggerImage from 'assets/onboarding-images/firebase-onboarding-images/on-success-trigger.png'
import { dispatch } from 'store'
import { selectedResourceSelector } from 'store/selectors'
import { QUERY_EDITOR_UPDATE } from 'routes/Editor/modules/editor'
import { datasourceTypeChange } from 'store/appModel/template'
import { widgetTemplateUpdate } from 'store/appModel/templateUtils'
import { batchUndoGroup } from 'store/appModel/batchUndoGroupBy'

function setQueryEditorState(newValue: SafeAny) {
  return dispatch((dispatch, getState) => {
    const state = getState()
    const selectedResource = selectedResourceSelector(state)
    const editorType = selectedResource!.editorType
    dispatch({
      type: QUERY_EDITOR_UPDATE,
      payload: { newValue, editorType },
    })
  })
}

function queryStateDatasourceTypeChange(resourceName: string, newType: string, oldType: string) {
  return dispatch((dispatch, getState) => {
    const datasourceId = getState().editor.get('selectedDatasourceId')
    dispatch({
      type: 'QUERY_STATE_DATASOURCE_TYPE_CHANGE',
      payload: { pluginId: datasourceId, resourceName, newType, oldType },
    })
  })
}

function saveQuery(oldType: string) {
  return dispatch(
    batchUndoGroup((dispatch, getState) => {
      const datasourceId = getState().editor.get('selectedDatasourceId')
      const queryState = getState().editor.queryState.get('FirebaseQuery')
      dispatch(datasourceTypeChange(datasourceId, getState().editor.selectedResourceName, 'FirebaseQuery', oldType))
      dispatch(widgetTemplateUpdate(datasourceId, queryState!.toJS()))
    }),
  )
}

const findComponentAndVerifyPropertyInModel = (
  model: AppModelType,
  type: SafeAny,
  propPath: SafeAny,
  propValue: SafeAny,
) => {
  const possibleMatches = model.values.filter((v: SafeAny) => v.get('pluginType') === type)
  return !!possibleMatches.find((m: SafeAny) => m.getIn(propPath).toLowerCase() === propValue.toLowerCase())
}

const useCase = new OnboardingStep({
  title: `👋 Learn Retool with Firebase`,
  message: `To learn some core concepts, let's build a simple app to get our users from a demo Firebase resource. We'll return to your Firebase resource afterwards.`,
  image: LearnRetoolImage,
})

const dragOnTable = new OnboardingStep({
  title: 'Hello, components',
  message: `Let's start by laying out the UI. To display our sign-ups, we'll
  want to render them into a table.`,
  image: HelloComponentsImage,
  messageSteps: [`Drag the table component onto the canvas.`],
  condition: (template) => {
    return !!template.plugins.find((v) => v.get('subtype') === 'TableWidget')
  },
  substeps: new OnboardingSubStep({
    hintNodeSelector: '[data-onboarding-target="WidgetPicker:TableWidget"]',
  }),
})

const dragOnButton = new OnboardingStep({
  title: 'Table, meet Button',
  message: `Nice! Now let's add a button to disable our users.`,
  messageSteps: [
    `Click on the canvas or hit<span class='onboarding-modal__key'>esc</span>. This will deselect the table.`,
    `Drag a <strong>Button</strong> onto the canvas, next to the table.`,
  ],
  condition: (template) => {
    return template.plugins.filter((v) => v.get('subtype') === 'ButtonWidget').size >= 1
  },
  substeps: Immutable.List([
    new OnboardingSubStep({
      hintNodeSelector: '._retool-table1',
      hintNodeStyle: { top: 25, left: -70 },
      nextCondition: (template, model, editor) => {
        return !editor.getSelectedWidgetId()
      },
    }),
    new OnboardingSubStep({
      hintNodeSelector: '[data-onboarding-target="WidgetPicker:ButtonWidget"]',
      prevCondition: (template, model, editor) => {
        return !!editor.getSelectedWidgetId()
      },
    }),
  ]),
})

const labelButtons = new OnboardingStep({
  title: 'Customize components',
  message: `In Retool, components have properties, which we can modify.`,
  messageSteps: [
    `Select the button.`,
    `In the component inspector, change the <strong>Text</strong> property from "<strong>Submit</strong>" to "<strong>Disable</strong>."`,
  ],
  condition: (template, model) => {
    return findComponentAndVerifyPropertyInModel(model, 'ButtonWidget', ['value'], 'disable')
  },
  substeps: Immutable.List([
    new OnboardingSubStep({
      hintNodeSelector: '._retool-button1',
      nextCondition: (template, model, editor) => {
        const selectedWidgetId = editor.getSelectedWidgetId()
        if (!selectedWidgetId) {
          return false
        }
        return template.plugins.get(selectedWidgetId)!.subtype === 'ButtonWidget'
      },
    }),
    new OnboardingSubStep({
      hintNodeSelector: '[data-testid="codeInput-Text"]',
      prevCondition: (template, model, editor) => {
        const expectedId = model.values
          .filter((v: PluginModel) => v.get('pluginType') === 'ButtonWidget')
          .keySeq()
          .first()
        const selectedWidgetId = editor.getSelectedWidgetId()
        if (!selectedWidgetId) return true
        if (selectedWidgetId === expectedId) return false
        const correctValue = template.plugins.get(selectedWidgetId)!.template.get('value') === 'Disable'
        return !correctValue
      },
      nextCondition: (template, model, editor) => {
        const selectedWidgetId = editor.getSelectedWidgetId()
        if (!selectedWidgetId) return false
        const hasChangedValue = template.plugins.get(selectedWidgetId)!.template.get('value') !== 'Submit'
        const correctValue = template.plugins.get(selectedWidgetId)!.template.get('value') === 'Disable'
        if (hasChangedValue && !correctValue) {
          const onboardingMessage = document.getElementsByClassName('onboarding-modal__description')[0]
          const additionalPrompt = `<div class="onboarding-modal__additional-prompt"><strong>To proceed to the next step,</strong> please change the <code>Text</code> property on the button to <strong><code>Disable</code></strong>!</div>`
          if (!onboardingMessage.innerHTML.includes(additionalPrompt)) {
            onboardingMessage.innerHTML = onboardingMessage.innerHTML + additionalPrompt
          }
        }
        return correctValue
      },
    }),
  ]),
})

const selectCorrectResource = new OnboardingStep({
  title: `Get the right data.`,
  message: `We created a demo Firebase resource for you. Let's connect to it.`,
  messageSteps: [
    `Select the resource <strong> [demo] firebase (firebase) </strong>. It's listed under Onboarding Resources in the query editor.`,
  ],
  condition: (template, model, editor) => {
    const query = editor.get('selectedResourceName')
    return query.toLowerCase().trim().startsWith('[demo] firebase')
  },
  substeps: new OnboardingSubStep({ hintNodeSelector: '.resourceSelector' }),
})

const writeFirebaseQuery = new OnboardingStep({
  title: 'Data',
  message: `Great! Let’s read some data. In the query editor below:`,
  messageSteps: [
    `For Service Type: select <strong> Firebase Auth </strong>`,
    'For Action Type: select <strong> List users </strong>',
  ],
  condition: (template, model, editor) => {
    const actionType = editor.getIn(['queryState', 'FirebaseQuery', 'actionType'])
    const firebaseService = editor.getIn(['queryState', 'FirebaseQuery', 'firebaseService'])
    return actionType === 'listAllUsers' && firebaseService === 'auth'
  },
  doItForMe: () => {
    setQueryEditorState({
      firebaseService: 'auth',
      actionType: 'listAllUsers',
    })
    saveQuery('FirebaseQuery')
  },
  substeps: Immutable.List([
    new OnboardingSubStep({ hintNodeSelector: '.firebase-query-editor' }),
    new OnboardingSubStep({ hintNodeSelector: '.firebase-query-editor' }),
  ]),
})

const PreviewFirebaseQueryMessageStepOne = () => {
  const previewShortcut: string[] = shortcutFormatter('QUERY_EDITOR', 'PREVIEW_QUERY')
    .split('')
    .filter((k) => k !== ' ')
  return (
    <div className="onboarding-modal__message-step-line">
      <span>
        Click
        <Button style={{ height: 20 }} className="query-editor__preview-button">
          <div className="query-editor__preview-button-inner flex items-center" aria-label="Preview query">
            Preview
          </div>
        </Button>
        or press
        {previewShortcut.map((key) => (
          <span className="onboarding-modal__key" key={key}>
            {key}
          </span>
        ))}
        to preview the query response.
      </span>
    </div>
  )
}
const PreviewFirebaseQueryMessageStepTwo = () => {
  const saveShortcut: string[] = shortcutFormatter('QUERY_EDITOR', 'SAVE_QUERY')
    .split('')
    .filter((k) => k !== ' ')
  return (
    <div className="onboarding-modal__message-step-line">
      <span>
        Click
        <span className="onboarding-modal__save-button">
          <Icon type="run" style={{ height: 8 }} /> Save & Run
        </span>
        or press
        {saveShortcut.map((key) => (
          <span className="onboarding-modal__key" key={key}>
            {key}
          </span>
        ))}
        to save the query and run it for the first time.
      </span>
    </div>
  )
}

const previewFirebaseQuery = new OnboardingStep({
  title: 'Preview and save',
  message: `Look before you leap! Don’t forget to save your queries.`,
  messageSteps: [PreviewFirebaseQueryMessageStepOne, PreviewFirebaseQueryMessageStepTwo],
  messageNote: `Always click save (or save & run) after making changes to your query or you'll lose them!`,
  condition: (template) => {
    const queries = template.plugins.filter((v) => v.get('subtype') === 'FirebaseQuery')
    return !!queries.find((q) => {
      return (
        q.get('template').get('actionType') === 'listAllUsers' && q.get('template').get('firebaseService') === 'auth'
      )
    })
  },
  substeps: Immutable.List([
    new OnboardingSubStep({
      hintNodeSelector:
        '#query-editor > div:nth-child(2) > div.query-editor__header > div.navbar-right > button:nth-child(2)',
      nextCondition: (_template, _model, editor) => {
        return editor.get('previewOpen')
      },
    }),
    new OnboardingSubStep({
      hintNodeSelector:
        '#query-editor > div:nth-child(2) > div.query-editor__header > div.navbar-right > button:nth-child(3)',
    }),
  ]),
})

const dataInTable = new OnboardingStep({
  title: 'Table, meet data.',
  message: `Great! The data is in Retool now, but let's put it into the table.`,
  messageSteps: [
    `Click on the table.`,
    `Replace the whole <strong>Data</strong> property with <code>{{ query1.data.users }}</code>. That’s how we refer to our query we just wrote.`,
  ],
  messageNote: `Learn more about these curly braces <a href="https://docs.retool.com/docs/understanding-components#component-properties" target="_blank">here</a>.`,
  image: TableMeetsDataImage,
  condition: (template, model) => {
    const tables = model.values.filter((v: SafeAny) => v.get('pluginType') === 'TableWidget')
    const depGraph = model.dependencyGraph
    const tablesWithDependencies = tables.filter((m: SafeAny, id: SafeAny) => {
      // ensure that the substring `.data` appears in the template
      const dataTemplate = template.getIn(['plugins', id, 'template', 'data'])
      if (dataTemplate.indexOf('.data') === -1) {
        return false
      }

      // ensure there is data
      const data = m.get('data')
      const values = _.values(data)
      if (values.length === 0 || values[0].length === 0) {
        return false
      }

      const dependencies = depGraph.getDependenciesOf([id, 'data'])
      for (const dep of dependencies) {
        const depId = dep.selector[0]
        if (template.plugins.get(depId)!.subtype === 'FirebaseQuery') {
          if (dep.selector[1] === 'data') {
            // some table is connected some query's data!
            return true
          }
        }
      }
    })

    return tablesWithDependencies.size > 0
  },
  substeps: Immutable.List([
    new OnboardingSubStep({
      hintNodeSelector: '._retool-table1',
      nextCondition: (template, model, editor) => {
        const selectedWidgetId = editor.getSelectedWidgetId()
        if (!selectedWidgetId) {
          return false
        }
        return template.plugins.get(selectedWidgetId)!.subtype === 'TableWidget'
      },
    }),
    new OnboardingSubStep({
      hintNodeSelector:
        'div.Pane.vertical.Pane2 > div > div:nth-child(2) > div.editor-form-body > div > div:nth-child(1) > div.ant-collapse-content.ant-collapse-content-active > div > div.widget-input.widget-editor-code > div.flex.mb4.mt12.label-title-container > span',
      prevCondition: (template, model, editor) => {
        const selectedWidgetId = editor.getSelectedWidgetId()
        if (!selectedWidgetId) return true
        const correctValue =
          template.plugins.get(selectedWidgetId)!.template.get('data').replace(/\s/g, '') === '{{query1.data.users}}'
        return !correctValue
      },
      nextCondition: (template, model, editor) => {
        const selectedWidgetId = editor.getSelectedWidgetId()
        if (!selectedWidgetId) return false
        const correctValue =
          template.plugins.get(selectedWidgetId)!.template.get('data').replace(/\s/g, '') === '{{query1.data.users}}'
        return correctValue
      },
    }),
  ]),
})

const writeUpdateQuery = new OnboardingStep({
  title: `Update user`,
  message: `Cool! We created a new query that the button will fire. Let's create another Firebase Query to update the selected user in the table. Typically, you’d fill in the user uid and options below. But we will do this for you. Just click the button below!`,
  image: UpdateUserImage,
  doItForMe: () => {
    queryStateDatasourceTypeChange('[demo] firebase', 'FirebaseQuery', 'RESTQuery')
    setQueryEditorState({
      runWhenModelUpdates: false,
      firebaseService: 'auth',
      actionType: 'updateUser',
      uid: '{{table1.selectedRow.data.uid}}',
      userOptions: '{disabled:true}',
      triggersOnSuccess: Immutable.List(['query1']),
    })
    saveQuery('RESTQuery')
  },
  condition: (template) => {
    const queries = template.plugins.filter((v) => v.get('subtype') === 'FirebaseQuery')
    const firebaseButtonTriggerQuery = queries.find((q) => {
      return !!q.get('id')!.match(/button.*Trigger/)
    })
    if (firebaseButtonTriggerQuery) {
      if (
        firebaseButtonTriggerQuery.template.get('firebaseService') === 'auth' &&
        firebaseButtonTriggerQuery.template.get('actionType') === 'updateUser' &&
        firebaseButtonTriggerQuery.template.get('uid') === '{{table1.selectedRow.data.uid}}' &&
        firebaseButtonTriggerQuery.template.get('userOptions') === '{disabled:true}'
      ) {
        return true
      }
    }
    return false
  },
})

const useItEditor = new OnboardingStep({
  title: `Understanding it`,
  message: `Look at the query, does it make sense? Put your cursor inside the <strong>User UID input</strong> and look at the green preview. You can see that this matches with the UID of the table's <strong>selectedRow</strong>.`,
  image: UnderstandSelectedRowImage,
})

const useItEditor2 = new OnboardingStep({
  title: `Understanding it`,
  message: `Scroll down on the query. Note that upon success of this query, <strong>query1</strong> is triggered to update the data in table.`,
  image: OnSuccessTriggerImage,
})

const useItEditor3 = new OnboardingStep({
  title: `Testing it out`,
  message: `Great, now let's disable some users.`,
  messageSteps: [
    `Select a row in the table.`,
    `Click the Disable button. You will need to click on it <strong>twice</strong>.`,
    `Watch the disabled column update from <strong>false</strong> to <strong>true</strong>`,
  ],
  messageNote: `The first click selects the button so you can edit its properties. The second click actually triggers the event.`,
  image: TestingItOutImage,
})

const summary = new OnboardingStep({
  title: `Summary`,
  message: `Congrats! We just built a simple disable users app with Firebase. Now let's do it for your own data!`,
  image: LearnRetoolImage,
})

const onboardingFeedbackSurvey = new OnboardingStep({
  title: `Was this helpful?`,
  message: OnboardingSurvey,
  hideBottomRow: true,
})

const nextSteps = new OnboardingStep({
  // the final modal is different because it takes over the screen +
  // isn't draggable and stuff. handled explicitly in OnboardingModal.tsx
})

const firebaseFlow = Immutable.List([
  useCase,
  dragOnTable,
  dragOnButton,
  labelButtons,
  selectCorrectResource,
  writeFirebaseQuery,
  previewFirebaseQuery,
  dataInTable,
  eventHandlerClickOnButton,
  writeUpdateQuery,
  useItEditor,
  useItEditor2,
  useItEditor3,
  summary,
  onboardingFeedbackSurvey,
  nextSteps,
])

export default firebaseFlow

import { injectReducer } from '../../store/reducers'
import { tearDownPage } from 'store/appModel/pages'
import requirePlugins from 'components/plugins/requirePlugins'

export default function EmbeddedRoute(store: any) {
  return [
    {
      path: 'embedded',
      childRoutes: [
        {
          path: 'public/:uuid',
          onEnter: (nextState: any) => {
            require.ensure(
              [],
              (require) => {
                requirePlugins(() => {
                  const embedded: any = require('./modules/embedded')
                  injectReducer(store, { key: 'embedded', reducer: embedded.default })
                  store.dispatch(embedded.publicPageLoad(nextState.params.uuid))
                })
              },
              'embeddable',
            )
          },
          onLeave: () => {
            store.dispatch(tearDownPage())
          },
          getComponent(nextState: any, cb: any) {
            require.ensure(
              [],
              (require) => {
                requirePlugins(() => {
                  // eslint-disable-next-line no-console
                  console.log('loading', nextState.params.pageName)
                  const App = require('./containers/EmbeddedContainer').default
                  cb(null, App)
                })
              },
              'embeddable',
            )
          },
        },
      ],
    },
    {
      path: 'pwa',
      childRoutes: [
        {
          path: ':pwaId/:uuid',
          onEnter: (nextState: any) => {
            require.ensure(
              [],
              (require) => {
                requirePlugins(() => {
                  const embedded: any = require('./modules/embedded')
                  injectReducer(store, { key: 'embedded', reducer: embedded.default })
                  store.dispatch(embedded.publicPageLoad(nextState.params.uuid))
                  const pwaId = nextState.params.pwaId
                  const appId = nextState.params.uuid
                  const manifestURL = `/api/manifest.json?pwaId=${pwaId}&appId=${appId}`
                  const manifestPlaceholder = document.querySelector('#pwa-manifest-placeholder')
                  if (manifestPlaceholder) {
                    manifestPlaceholder.setAttribute('href', manifestURL)
                  }
                })
              },
              'embeddable',
            )
          },
          onLeave: () => {
            store.dispatch(tearDownPage())
          },
          getComponent(nextState: any, cb: any) {
            require.ensure(
              [],
              (require) => {
                requirePlugins(() => {
                  // eslint-disable-next-line no-console
                  console.log('loading', nextState.params.pageName)
                  const App = require('./containers/EmbeddedContainer').default
                  cb(null, App)
                })
              },
              'embeddable',
            )
          },
        },
      ],
    },
  ]
}

import { copyValueToClipboard } from 'common/copy'
import { PluginMethodConfig } from 'components/plugins'
import { assertStringOrNumber } from 'types/typeguards'

const copyToClipboard: PluginMethodConfig = {
  metadata: {
    label: 'Copy to clipboard',
    description: "Sets the clipboard's content to the provided string value.",
    example: "`utils.copyToClipboard('value')`",
    params: [{ type: 'codeInput', name: 'value', props: { label: 'Value', validator: 'string' } }],
  },
  method: (value) => {
    assertStringOrNumber(value, '`value` must be a string or number')
    copyValueToClipboard(String(value))
  },
}

export default copyToClipboard

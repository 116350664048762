import React, { useState } from 'react'
import Button from 'components/design-system/Button'
import Modal from 'components/design-system/Modal'
import TextInput from 'components/design-system/TextInput'

import './CreateFolderModal.scss'

import { createFolder } from 'store/appModel/pages'
import { connect } from 'react-redux'
import { RetoolState } from 'store'
import { folderTreeSelector, FolderWithChildren } from 'store/selectors'
import { Folder } from 'common/records'
import Form from 'antd/lib/form'

type CreateFolderProps = {
  visible: boolean
  createFolder: (parentFolderId: number, folderName: string) => Promise<Folder>
  closeModal: () => void
  rootFolder: FolderWithChildren | null
}

function isValid(folderName: string | undefined) {
  return folderName !== undefined && folderName.length > 0
}

function CreateFolderModal(props: CreateFolderProps) {
  const [folderName, setFolderName] = useState<string>()

  const create = async () => {
    if (!isValid(folderName)) {
      return
    }
    await props.createFolder(props.rootFolder!.id, folderName!)
    props.closeModal()
  }

  return (
    <Modal
      destroyOnClose
      className="homepage-modal"
      title="Create a new folder"
      width={520}
      visible={props.visible}
      onCancel={props.closeModal}
      footer={null}
    >
      <Form id="createFolderForm">
        <div className="create-folder-modal__label">
          Folder name <span className="light-red">*</span>
        </div>
        <div className="mt8">
          <TextInput
            autoFocus
            placeholder="Give your folder a name"
            value={folderName}
            onChange={(ev) => setFolderName(ev.target.value)}
          />
        </div>
        <div className="flex mt24">
          <Button
            className="create-folder-modal__submit-button"
            type="primary"
            htmlType="submit"
            form="createFolderForm"
            onClick={create}
            disabled={!isValid(folderName)}
          >
            Create folder
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  createFolder,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    rootFolder: folderTreeSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateFolderModal)

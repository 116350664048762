import * as _ from 'lodash'
import { Resource } from 'common/records'
import { logger } from 'common/utils'

const throttledRunQueryFuncs: any = {}

export function throttled(runQuery: any, prefix: any, throttleTime = 750) {
  return function (...args: any) {
    const uniqueKey = `${prefix}-${throttleTime}-${arguments[0]}-${arguments[1]}`
    if (!throttledRunQueryFuncs[uniqueKey]) {
      throttledRunQueryFuncs[uniqueKey] = _.debounce(_.throttle(runQuery, throttleTime), 100)
    }

    throttledRunQueryFuncs[uniqueKey].apply(this, args)
  }
}

export const isOnboardingResource = (resource: Resource) => {
  // these names are from the `generateDummyDatabases` method in the backend
  const DEMO_RESOURCE_NAMES = [
    'onboarding_db (readonly)',
    'onboarding_api',
    'onboarding_db (edit)',
    'Sample GSheets',
    'onboarding_db',
  ]
  // placeholder resources are created when a user clones a template that uses one
  const isPlaceholderResource = resource.name.startsWith('[demo]')

  return (
    DEMO_RESOURCE_NAMES.includes(resource.name) ||
    isPlaceholderResource ||
    (resource as any)?.production?.options?.isSampleDatabase
  )
}

// NATURAL_FAILURE is the query failed by itself without being triggered by a failure condition or error transformer
type QueryErrorTrigger = 'NATURAL_FAILURE' | 'FAILURE_CONDITION' | 'ERROR_TRANSFORMER'

export class QueryRunError extends Error {
  data: any
  errorData: any
  trigger: QueryErrorTrigger
  displayOptions?: {
    hideToast?: boolean
  }

  constructor(
    message: any,
    queryData: any,
    errorData: any,
    trigger: QueryErrorTrigger,
    displayOptions = { hideToast: false },
  ) {
    super(message)
    this.data = queryData
    this.errorData = errorData
    this.displayOptions = displayOptions
    this.trigger = trigger
  }
}

export function getErrorFromResponse(res: any, data?: any, rawResponse?: any): QueryRunError | null {
  const attemptGetError = (obj: any) => {
    if (!obj) {
      return null
    }

    const errorObject = { status: res.status, message: res.statusText, ...obj }
    let responseString = JSON.stringify(errorObject)
    // We wrap the entire response in the message field when proxying the request, so we need to unwrap that to get the message.
    if (obj.message) {
      try {
        let responseBody = JSON.parse(obj.message)
        // Some resources return as an array as the response body. Pick the first element.
        if (Array.isArray(responseBody)) {
          responseBody = responseBody[0]
        }
        responseString = responseBody.message || responseString
      } catch (e) {
        logger.warn(`Parsing query error, failed tounwrap message as a JSON.`, e)
      }
    }
    return new QueryRunError(responseString, rawResponse, obj.message || errorObject.message, 'NATURAL_FAILURE')
  }

  if (!res.ok || (data && data.error === true)) {
    const error = attemptGetError(data)
    if (error) {
      return error
    }
  }

  if (!res.ok || (rawResponse && rawResponse.error === true)) {
    const error = attemptGetError(rawResponse)
    if (error) {
      return error
    }
  }

  return null
}

export function throwIfMetadataError(metadata: any, rawResponse?: any) {
  if (metadata && metadata.isError === true) {
    throw new QueryRunError(metadata.error, rawResponse, metadata.error, 'NATURAL_FAILURE')
  }
}

export function migrateKeyedTableEditorto17(dict: any) {
  let newValue
  if (!dict) {
    return dict
  }
  if (dict.indexOf('columnName') === -1 || dict.indexOf('newValue') === -1) {
    return dict
  }
  try {
    newValue = JSON.parse(dict).filter((r: any) => r.columnName !== '')
    newValue = newValue.map((v: any) => {
      return { key: v.columnName, value: v.newValue }
    })
  } catch (err) {
    newValue = []
  }
  if (dict == null) {
    newValue = []
  }
  return JSON.stringify(
    newValue.filter((row: any) => {
      return row.key !== '' || row.value !== ''
    }),
  )
}

import * as React from 'react'

import { googleLogin } from 'common/googleLogin'
import { Button } from 'components/design-system'

export default class GoogleLoginButton extends React.Component<any, any> {
  render() {
    return (
      <Button
        style={this.props.style}
        className="google-login-button"
        onClick={() => {
          this.props.onClick()
          window.setTimeout(googleLogin, 300)
        }}
        type="default"
        block
      >
        {this.props.children}
      </Button>
    )
  }
}

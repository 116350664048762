import TextInput from 'components/design-system/TextInput'
import React, { FC, useEffect, useState } from 'react'
import { organizationProtectedBranch } from 'store/selectors'
import { useSelector } from 'react-redux'
import { isValidBranchName } from 'common/utils/github'
import _styles from "./CreateBranchInput-styles.module.scss";


type CreateBranchInputProps = {
  newBranchName: string
  onUpdateBranchName: (newBranchName: string) => void
  onSetIsSubmitEnabled: (valid: boolean) => void
}

const styles = _styles

export const CreateBranchInput: FC<CreateBranchInputProps> = ({
  newBranchName,
  onUpdateBranchName,
  onSetIsSubmitEnabled,
}) => {
  const [newBranchError, setNewBranchError] = useState<string>('')
  const defaultBranch = useSelector(organizationProtectedBranch)

  useEffect(() => {
    const { valid, message } = isValidBranchName(newBranchName)
    onSetIsSubmitEnabled(valid)
    setNewBranchError(message)
  }, [newBranchName, onSetIsSubmitEnabled])

  const handleUpdateBranchName = (newBranchName: string) => {
    onUpdateBranchName(newBranchName)
  }

  return (
    <div className={styles.main}>
      <div className={styles.description}>
        {`Create a new branch based off the ${defaultBranch} branch.`}
        <span className={styles.nameRequiredAsterisk}>*</span>
        {newBranchName && newBranchError && <span className={styles.errorMessage}>{newBranchError}</span>}
      </div>
      <TextInput autoFocus onChange={(event) => handleUpdateBranchName(event.target.value)} value={newBranchName} />
    </div>
  )
}

export default CreateBranchInput

import { PluginMethodConfigs } from 'components/plugins'
import mapValues from 'lodash/mapValues'
import confetti from './confetti'
import copyToClipboard from './copyToClipboard'
import downloadFile from './downloadFile'
import downloadPage from './downloadPage'
import exportData from './exportData'
import getCurrentPosition from './getCurrentPosition'
import openApp from './openApp'
import openUrl from './openUrl'
import showNotification from './showNotification'

const RETOOL_UTILITY_API: PluginMethodConfigs = {
  confetti,
  copyToClipboard,
  downloadPage,
  downloadFile,
  exportData,
  getCurrentPosition,
  openApp,
  openUrl,
  showNotification,
}

export const utilDocs = mapValues(RETOOL_UTILITY_API, ({ metadata }) => ({
  // workaround for how utility methods are rendered in codemirror
  name: metadata.example?.replace(/`/g, '') ?? '',
  docs: metadata.description ?? '',
}))

export const utilMethods = mapValues(RETOOL_UTILITY_API, ({ method }) => method)

export const utilParams = mapValues(RETOOL_UTILITY_API, ({ metadata }) => metadata.params ?? [])

export default RETOOL_UTILITY_API

import { SafeAny } from 'common/types'

export default class SimpleCache<K extends string | number | symbol, V> {
  ttlMs: number
  cache: {
    [k in K]: {
      value: V
      timestamp: number
    }
  }
  constructor(ttlMs: number) {
    this.ttlMs = ttlMs
    this.cache = {} as SafeAny
  }

  get(key: K): V | undefined {
    const result = this.cache[key]
    if (result && result.timestamp + this.ttlMs > Date.now()) {
      return result.value
    } else {
      return undefined
    }
  }

  put(key: K, value: V) {
    this.cache[key] = { value, timestamp: Date.now() }
  }
}

import React from 'react'
import _ from 'lodash'
import { Switch as AntSwitch } from 'antd'
import { SwitchProps as AntSwitchProps } from 'antd/lib/switch/index'
import 'antd/lib/switch/style/index'
import './Switch.scss'

export type SwitchProps = {
  label?: String
} & AntSwitchProps

const Switch = (props: SwitchProps) => (
  <div className={`${props.className} retool-checkbox-wrapper`}>
    <AntSwitch style={props.style} size="small" {..._.omit(props, ['className'])} />
    {!!props.label && <label className="switch-label">{props.label}</label>}
  </div>
)

export default Switch

import { WidgetOptionsResolver } from 'components/plugins'

export default function widgetTypeIsContainer(widgetType: string): boolean {
  return !!WidgetOptionsResolver(widgetType).container
}

export function widgetTypeIsModalLike(widgetType: string): boolean {
  return !!WidgetOptionsResolver(widgetType).container?.modalLike
}

export function widgetTypeHasDragDelay(widgetType: string): boolean {
  const { container } = WidgetOptionsResolver(widgetType)
  return !!container && !container.disableDragDelay
}

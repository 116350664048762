import React from 'react'

import { Link } from 'react-router'
import { connect } from 'react-redux'

import classNames from 'classnames'

import _ from 'lodash'

import { ListView } from 'components/ListView'
import { Expandable } from 'components/Expandable'
import { Icon, Button, Modal } from 'components/design-system'
import UserInviteForm from 'components/UserInvite'
import UserSuggestionForm from 'components/UserSuggestion'
import { Page } from 'common/records'
import { RetoolState } from 'store'
import { FolderWithChildren, isAdminSelector, pagesWithPathsSelector } from 'store/selectors'
import { favoriteFolder, unfavoriteFolder } from 'store/user'

import {
  HomeViewFilter,
  SpecialViewFilterMap,
  isSpecialViewFilter,
  isUserFolderFilter,
  selectPages,
  SpecialViewFilter,
  SpecialViewFilterKey,
} from 'routes/Home/filters'
import { specialViewFilterMapSelector, displayableFoldersSelector, currentFilterSelector } from 'routes/Home/selectors'

import './NavigationSidebar.scss'
import BookACall from '../onboarding-helpers/BookACall'
import Favorite from '../controls/Favorite'
import { pageCount } from '../utilities/text'
import { folderFavoritesSelector } from 'store/selectors/userSelectors'

type Props = {
  allPages: Page[]
  currentFilter: HomeViewFilter
  displayableFolders: FolderWithChildren[]
  favoriteFolder: (folderId: number) => Promise<void>
  favoriteFolderIds: number[]
  specialViewFilterMap: SpecialViewFilterMap
  unfavoriteFolder: (folderId: number) => Promise<void>
  userIsAdmin: boolean
}

const defaultFilterClasses = 'page-filter flex items-center dark-gray'

const matchingSpecialFilter = (a: HomeViewFilter, b: HomeViewFilter) =>
  isSpecialViewFilter(a) && isSpecialViewFilter(b) && a.key === b.key

const matchingFolderFilter = (a: HomeViewFilter, b: HomeViewFilter) =>
  isUserFolderFilter(a) && isUserFolderFilter(b) && a.id === b.id

const isFilterSelected = (filter: HomeViewFilter, currentlySelected: HomeViewFilter) =>
  matchingSpecialFilter(filter, currentlySelected) || matchingFolderFilter(filter, currentlySelected)

type SpecialFilterNavItemProps = {
  view: SpecialViewFilter
  current: HomeViewFilter
  count?: number
  tag: SpecialViewFilter['tag']
}

const SpecialFilterNavItem = ({ view, current, count, tag }: SpecialFilterNavItemProps) => {
  const countIfPresent = pageCount(count)

  const filterClasses = classNames(defaultFilterClasses, {
    selected: isFilterSelected(view, current),
  })

  return (
    <Link to={`/views/${view.key}`}>
      <div className={filterClasses}>
        <span className="truncate">{view.name}</span>
        {countIfPresent}
        {tag}
      </div>
    </Link>
  )
}

type UserFolderFilterNavItemProps = {
  favoriteFolder: (folderId: number) => Promise<void>
  unfavoriteFolder: (folderId: number) => Promise<void>
  folder: FolderWithChildren
  isFavorited: boolean
  current: HomeViewFilter
}

const UserFolderFilterNavItem = (props: UserFolderFilterNavItemProps) => {
  const { favoriteFolder, unfavoriteFolder, folder, isFavorited, current } = props

  const countIfPresent = pageCount(folder.pages.length)

  const filterClasses = classNames(defaultFilterClasses, {
    selected: isFilterSelected(folder, current),
  })

  const favorite = () => favoriteFolder(folder.id)
  const unfavorite = () => unfavoriteFolder(folder.id)

  return (
    <Link to={`/folders/${encodeURIComponent(folder.name)}`}>
      <div className={filterClasses}>
        <Icon type="folder" className="mr8 flex-no-shrink" />
        <span className="truncate">{folder.name}</span>
        {countIfPresent}&nbsp;&nbsp;
        <Favorite isFavorited={isFavorited} favorite={favorite} unfavorite={unfavorite} />
      </div>
    </Link>
  )
}

const NavigationSidebarUnconnected = (props: Props) => {
  const {
    favoriteFolder,
    unfavoriteFolder,
    allPages,
    currentFilter,
    displayableFolders,
    favoriteFolderIds,
    specialViewFilterMap,
  } = props

  const [inviteModelShown, setInviteModelShown] = React.useState(false)
  const hideModal = () => setInviteModelShown(false)

  const sortedFolders = _.sortBy(displayableFolders, (f) => f.name.toUpperCase())

  const keys = Object.keys(specialViewFilterMap) as Array<SpecialViewFilterKey>

  return (
    <>
      <div className="home-sidebar-page-filters h-100 fs-13 justify-between relative">
        <div className="flex-grow">
          <ListView className="global-filters" records={keys}>
            {(key) => {
              const view = specialViewFilterMap[key]
              if (!view) return <React.Fragment key={key} />

              let count = selectPages(view)(allPages).length
              if (key === 'starred') {
                count += view.foldersToDisplay.length
              }
              return <SpecialFilterNavItem key={key} view={view} current={currentFilter} count={count} tag={view.tag} />
            }}
          </ListView>
          <div className="folder-filters">
            <div className="fs-12 lightest-gray fw-500 ttu mb4">Folders</div>
            {/* TODO: folders that were favorited first */}
            <Expandable limit={10} data={sortedFolders}>
              {(folder) => {
                const isFavorited = favoriteFolderIds.includes(folder.id)
                return (
                  <UserFolderFilterNavItem
                    key={folder.id}
                    current={currentFilter}
                    folder={folder}
                    isFavorited={isFavorited}
                    favoriteFolder={favoriteFolder}
                    unfavoriteFolder={unfavoriteFolder}
                  />
                )
              }}
            </Expandable>
          </div>
        </div>
        <div style={{ bottom: 24, position: 'fixed', width: 240 }}>
          <div className="home-sidebar-invite">
            <Button type="link" onClick={() => setInviteModelShown(true)}>
              <Icon type="shared" />
              <span className="ml8"> {props.userIsAdmin ? 'Invite new members' : 'Suggest new members'}</span>
            </Button>
          </div>
          <BookACall />
        </div>
      </div>
      <Modal className="invite-form-modal" visible={inviteModelShown} onCancel={hideModal} destroyOnClose footer={null}>
        {props.userIsAdmin ? (
          <UserInviteForm onInvite={hideModal} allowConfigureGroups />
        ) : (
          <UserSuggestionForm onSuggest={hideModal} />
        )}
      </Modal>
    </>
  )
}

const mapDispatchToProps = {
  favoriteFolder,
  unfavoriteFolder,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    allPages: pagesWithPathsSelector(state),
    currentFilter: currentFilterSelector(state),
    displayableFolders: displayableFoldersSelector(state),
    favoriteFolderIds: folderFavoritesSelector(state),
    specialViewFilterMap: specialViewFilterMapSelector(state),
    userIsAdmin: isAdminSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationSidebarUnconnected)

import React, { useState, useEffect } from 'react'

import * as Immutable from 'immutable'

import { message, Upload } from 'antd'

import { uploadPage, createGlobalWidgetFromApp } from 'store/appModel/pages'

import './ImportAppModal.scss'

import { connect } from 'react-redux'
import NameAndFolderSelector from './NameAndFolderSelector'
import Modal from 'components/design-system/Modal'
import Button from 'components/design-system/Button'
import { foldersSelector, currentPageSelector, currentPageUuidSelector } from 'store/selectors'
import { RetoolState } from 'store'
import { ImmutableMapType, Folder } from 'common/records'
import { navigateToApp } from './CreateAppModal'
import { newAppFolderIdSelector } from 'routes/Home/selectors'

type ImportAppMode = 'IMPORT_GLOBAL_WIDGET_FROM_APP' | 'IMPORT_APP_FROM_FILE'

type ImportAppModalProps = {
  visible: boolean
  folders: Immutable.Map<String, ImmutableMapType<Folder>>
  newAppFolderId: number
  uploadPage: (file: File, pageName: string, folderId: number) => Promise<unknown>
  closeModal?: () => void
  createGlobalWidgetFromApp: (
    newPageName: string,
    newFolderId: number,
    currentPageName: string,
    currentPageUuid: string,
  ) => void
  mode: ImportAppMode
  pageName: string
  pageUuid: string
}

function isValidState(appName: string | undefined, uploadedFile: File | null, mode: ImportAppMode) {
  const appNameValid = appName !== undefined
  switch (mode) {
    case 'IMPORT_GLOBAL_WIDGET_FROM_APP': {
      return appNameValid
    }
    case 'IMPORT_APP_FROM_FILE': {
      const uploadedFileValid = uploadedFile !== null
      return appNameValid && uploadedFileValid
    }
  }
}

function ImportAppModal(props: ImportAppModalProps) {
  const [appName, setAppName] = useState<string | undefined>(undefined)
  const [parentFolderId, setParentFolderId] = useState<number>(props.newAppFolderId)
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { mode } = props

  // If we change our current directory, default to that in the folder selector
  useEffect(() => {
    setParentFolderId(props.newAppFolderId)
  }, [props.newAppFolderId])

  useEffect(() => {
    setIsSubmitEnabled(isValidState(appName, uploadedFile, mode))
  }, [appName, uploadedFile, mode])

  const create = async () => {
    if (!isValidState(appName, uploadedFile, mode)) {
      return
    }
    setIsLoading(true)
    try {
      switch (mode) {
        case 'IMPORT_GLOBAL_WIDGET_FROM_APP': {
          await props.createGlobalWidgetFromApp(appName!, parentFolderId, props.pageName, props.pageUuid)
          break
        }
        case 'IMPORT_APP_FROM_FILE': {
          await props.uploadPage(uploadedFile!, appName!, parentFolderId) // uploadedFile was checked for null in validState()
          break
        }
      }
    } catch (err) {
      setIsLoading(false)
      return message.error(err.message)
    }

    const selectedFolder = props.folders.get(parentFolderId.toString())
    navigateToApp({ folder: selectedFolder, appName: appName! })
    setIsLoading(false)
    props.closeModal?.()
  }
  const isImportAppFromFile = mode === 'IMPORT_APP_FROM_FILE'
  return (
    <Modal
      className="homepage-modal"
      width={540}
      title={isImportAppFromFile ? 'Import an app' : 'Create app from module'}
      destroyOnClose
      visible={props.visible}
      onCancel={props.closeModal}
      footer={
        <div className="footer-container">
          <Button
            key="submit"
            type="primary"
            onClick={create}
            disabled={!isSubmitEnabled}
            className="footer-button"
            loading={isLoading}
          >
            {isImportAppFromFile ? 'Create app' : 'Create module'}
          </Button>
        </div>
      }
    >
      {isImportAppFromFile && (
        <div className="flex items-center justify-between mb12">
          <div>
            <Upload
              showUploadList
              onRemove={() => {
                setUploadedFile(null)
              }}
              beforeUpload={(file) => {
                setUploadedFile(file)
                return false
              }}
            >
              {!uploadedFile && <Button type="link">Upload a file</Button>}
            </Upload>
          </div>
          <div className="light-gray">Only .json types are supported</div>
        </div>
      )}

      <NameAndFolderSelector
        appName={appName}
        onAppNameChange={setAppName}
        onCurrentFolderIdChange={setParentFolderId}
        isGlobalWidget={props.mode === 'IMPORT_GLOBAL_WIDGET_FROM_APP'}
      />
    </Modal>
  )
}

const mapDispatchToProps = {
  uploadPage,
  createGlobalWidgetFromApp,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    folders: foldersSelector(state),
    newAppFolderId: newAppFolderIdSelector(state),
    pageName: currentPageSelector(state),
    pageUuid: currentPageUuidSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportAppModal)

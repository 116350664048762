import { useState, useMemo } from 'react'

import { fuzzyFindAll, FuzzyFindResult, FuzzyFindInput } from 'components/FuzzyFind'
import useUpdateEffect from './useUpdateEffect'

type State<T> = {
  result: FuzzyFindResult<T>
  query: string
}

const useFuzzyMatch = <T>(items: T[], keyExtractor: (item: T) => number, makeInput: (item: T) => FuzzyFindInput<T>) => {
  const [{ query, result }, setState] = useState<State<T>>({
    query: '',
    result: [{}, items],
  })

  const formattedInput = useMemo(() => items.map(makeInput), [items])

  useUpdateEffect(() => {
    setState({ query, result: fuzzyFindAll(query.toLowerCase(), keyExtractor, formattedInput) })
  }, [formattedInput])

  const setQuery = (newQuery: string) => {
    const formattedQuery = newQuery.toLowerCase()
    if (formattedQuery !== query.toLowerCase()) {
      const result = fuzzyFindAll(formattedQuery, keyExtractor, formattedInput)
      return setState({
        query: newQuery,
        result,
      })
    }

    return setState({ query: newQuery, result: [{}, items] })
  }

  return [result, query, setQuery] as const
}

export default useFuzzyMatch

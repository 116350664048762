import logout from 'networking/logout'
import { RetoolStore } from 'store'

export default function LogoutRoutes(_store: RetoolStore) {
  return {
    path: 'logout',
    onEnter: () => {
      document.title = 'Logout | Retool'
      logout()
    },
  }
}

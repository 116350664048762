import React from 'react'
import { OpenAIResourceSettings } from 'common/records'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'
import { generateResourceForm } from 'components/ResourceForm/resourceFormGenerator/generator'
import { AuthTemplate } from 'components/ResourceForm/resourceFormGenerator/generator-types'

const defaultOptions: OpenAIResourceSettings = {
  options: {
    customQueryParameters: [['', '']],
    customHeaders: [['Authorization', 'Bearer API_KEY']],
    authentication: 'apiKey',
    api_key: '',
    spec: `${RETOOL_SPECS_DOMAIN}/openai/3.0.0/2021-05-11.json`,
  },
}

const auth: AuthTemplate = [
  {
    type: 'apiKey',
    options: {
      label: 'API key',
      description: (
        <>
          Enter your OpenAI API key. To retrieve or create an API key visit the{' '}
          <a rel="noopener noreferrer" target="_blank" href="https://beta.openai.com/account/api-keys">
            API Keys
          </a>{' '}
          page of your OpenAI account.
        </>
      ),
    },
  },
]

export default generateResourceForm('OpenAI', defaultOptions, { auth, hideTestConnection: () => true })

//moved from presentation to avoid a circular dependency
import { SafeAny } from 'common/types'

// ------------------------------------
// Constants
// ------------------------------------

export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL'
export const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL'

export function closeAuthModal(modal: SafeAny) {
  return async (dispatch: SafeAny) => {
    modal.destroy()
    return dispatch({
      type: CLOSE_AUTH_MODAL,
    })
  }
}

import React from 'react'

import { DatabaseForm, databaseDefaults, databaseValidator, sshTunnelDefaults } from './common'
import { ResourceFormProps } from './types'
import { isLaunchedUnifiedSqlResource } from 'components/plugins/datasources/SqlQueryUnified/model'

const OracleDbForm = (props: ResourceFormProps) => (
  <DatabaseForm
    placeholderHost="IP address or hostname of your database"
    placeholderPort="1521"
    placeholderUsername="oracle"
    isUnifiedSqlResource={isLaunchedUnifiedSqlResource(props.resource)}
    {...props}
  />
)

export default {
  label: 'Oracle DB',
  form: OracleDbForm,
  defaults: {
    ...databaseDefaults,
    ...sshTunnelDefaults,
    options: {
      ...databaseDefaults.options,
      ...sshTunnelDefaults.options,
      connectWithSid: false,
    },
    port: '1521',
  },
  validator: databaseValidator,
}

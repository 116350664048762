import React from 'react'
import { connect } from 'react-redux'
import { IconType } from 'components/design-system/Icon'
import { Link } from 'react-router'
import { organizationThemeSelector } from 'store/selectors'
import { Button, Icon } from 'components/design-system'
import { RetoolState } from 'store'
import { NavigationThemeTypes } from './types'
import './Navigator.scss'

type NavigatorProps = {
  name: string
  icon: IconType
  active: boolean
  hidden?: boolean
  navigatesTo: string
  inBeta?: boolean
} & NavigationThemeTypes

const Navigator = (props: NavigatorProps) => {
  const { name, icon, active, hidden, navigatesTo, theme, useCustomThemeIfAvailable } = props
  if (hidden) {
    return <></>
  }
  let customStyle = {}
  if (useCustomThemeIfAvailable && theme && theme.headerNavigatorButtonColor) {
    customStyle = {
      color: theme.headerNavigatorButtonColor,
    }
  }
  return (
    <Link to={navigatesTo}>
      <Button className={active ? 'active' : undefined} style={customStyle}>
        <Icon type={icon} />
        <span style={{ marginLeft: 8 }}>{name}</span>
      </Button>
    </Link>
  )
}

const mapStateToProps = (state: RetoolState) => ({
  theme: organizationThemeSelector(state),
})

export default connect(mapStateToProps)(Navigator)

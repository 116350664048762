import React from 'react'

export default function ServerError() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <div className="elevation-1" style={{ padding: 50, borderRadius: 5 }}>
        <h1 style={{ textAlign: 'center' }}> 500 </h1>
        <br />
        <p> Looks like something went wrong. </p>
        <a href="/"> Go home </a>
      </div>
    </div>
  )
}

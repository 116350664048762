import { useEffect } from 'react'

/**
 * An effect that is only called on mount, similar to `componentDidMount`
 */
export default function useMountEffect(effect: () => void): void {
  useEffect(() => {
    effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

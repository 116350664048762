import React, { useState } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames/bind'

import { retoolAnalyticsTrack } from 'common/retoolAnalytics'

import { withRouter } from 'react-router'
import Icon from 'components/design-system/Icon'
import Dropdown from 'components/design-system/Dropdown'
import Menu from 'antd/lib/menu'
import CreateAppModal from '../modals/CreateAppModal'
import ImportAppModal from '../modals/ImportAppModal'
import CreateAppFromTemplateModal from '../modals/CreateAppFromTemplateModal'
import CreateAppFromDataModal from '../modals/CreateAppFromDataModal'
import CreateFolderModal from '../modals/CreateFolderModal'
import { Button } from 'components/design-system'

import { RetoolState } from 'store'
import { creatorPrivilegedSelector, editorPrivilegedSelector, hasFoldersWithWriteAccessSelector } from 'store/selectors'
import _styles from "./CreateApplicationDropdown-styles.module.scss";


const styles = _styles

const cx = classNames.bind(styles)

interface CreateApplicationDropdownProps {
  className?: string
  hasFoldersWithWriteAccess: boolean
  editorPrivileged: boolean
  creatorPrivileged: boolean
  enableNewFolder?: boolean
  openRight?: boolean
  children: React.ReactNode
  router: any
}

const BLANK_APP_MODAL_TYPE = 'blank_app_modal'
const IMPORT_APP_MODAL_TYPE = 'import_app_modal'
const CREATE_APP_FROM_TEMPLATE_MODAL_TYPE = 'create_app_from_template_modal'
const CREATE_APP_FROM_DATA_MODAL = 'create_app_from_data_modal'
const NEW_FOLDER_MODAL = 'new_folder_modal'
const GLOBAL_WIDGET_MODAL_TYPE = 'global_widget_modal'

const CreateApplicationDropdown = (props: CreateApplicationDropdownProps) => {
  // auto-open the modal for OnboardingChecklist
  const [modalOpen, setModalOpen] = useState(props.router.location.query.initialIsModalOpen)
  const [modalType, setModalType] = useState(BLANK_APP_MODAL_TYPE)

  const closeModal = () => {
    setModalOpen(false)
  }

  const isModalType = (name: string) => () => modalOpen && modalType === name

  const isBlankAppModalOpen = isModalType(BLANK_APP_MODAL_TYPE)
  const isImportAppModalOpen = isModalType(IMPORT_APP_MODAL_TYPE)
  const isCreateAppFromTemplateModalOpen = isModalType(CREATE_APP_FROM_TEMPLATE_MODAL_TYPE)
  const isCreateAppFromDataModalOpen = isModalType(CREATE_APP_FROM_DATA_MODAL)
  const isNewFolderModalOpen = isModalType(NEW_FOLDER_MODAL)
  const isGlobalWidgetModalOpen = isModalType(GLOBAL_WIDGET_MODAL_TYPE)

  const fireAppCreateFlowStarted = () => {
    retoolAnalyticsTrack('App Create Flow Started', {
      type: 'page',
    })
  }

  const openModalType = (name: string) => () => {
    setModalOpen(true)
    setModalType(name)
    fireAppCreateFlowStarted()
  }

  const openBlankAppModal = openModalType(BLANK_APP_MODAL_TYPE)
  const openImportAppModal = openModalType(IMPORT_APP_MODAL_TYPE)
  const openCreateAppFromTemplateModal = openModalType(CREATE_APP_FROM_TEMPLATE_MODAL_TYPE)
  const openCreateAppFromDataModal = openModalType(CREATE_APP_FROM_DATA_MODAL)
  const openNewFolderModal = openModalType(NEW_FOLDER_MODAL)
  const openGlobalWidgetAppModal = openModalType(GLOBAL_WIDGET_MODAL_TYPE)

  const linkClass = 'ml8 mr24 fs-12 fw-500 medium-gray lh-20'

  const createMenu = (
    <Menu>
      <Menu.Item onClick={openBlankAppModal}>
        <div className="flex items-center create-blank-app">
          <Icon type="plus-big" />
          <div className={linkClass}>Create a blank app</div>
        </div>
      </Menu.Item>
      <Menu.Item onClick={openCreateAppFromTemplateModal}>
        <div className="flex items-center">
          <Icon type="create-app-from-template" />
          <div className={linkClass}>Create from template</div>
        </div>
      </Menu.Item>
      <Menu.Item onClick={openCreateAppFromDataModal}>
        <div className="flex items-center">
          <Icon type="create-app-from-data" />
          <div className={linkClass}>Generate app from data</div>
        </div>
      </Menu.Item>
      <Menu.Item onClick={openImportAppModal}>
        <div className="flex items-center">
          <Icon type="create-app-from-import" />

          <div className={linkClass}>Import an app</div>
        </div>
      </Menu.Item>
      <Menu.Item onClick={openGlobalWidgetAppModal}>
        <div className="flex items-center">
          <Icon type="create-app-from-template" />
          <div className={`${linkClass} flex items-center`}>
            Create a module
            <span className={`tag ${cx('new-tag')}`}>New</span>
          </div>
        </div>
      </Menu.Item>
      {props.enableNewFolder && props.creatorPrivileged && <Menu.Divider />}
      {props.enableNewFolder && props.creatorPrivileged && (
        <Menu.Item onClick={openNewFolderModal}>
          <div className="flex items-center">
            <Icon type="plus-big" />
            <div className={linkClass}>Create a new folder</div>
          </div>
        </Menu.Item>
      )}
    </Menu>
  )

  if (!props.editorPrivileged && !props.hasFoldersWithWriteAccess) {
    return <></>
  }

  // NB: the string specifies the corner of the button (not a direction of open)
  const placement = props.openRight ? 'bottomLeft' : 'bottomRight'

  return (
    <div className={props.className}>
      <div className="folder-view__create-block">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Dropdown overlay={createMenu} placement={placement}>
            <Button className="folder-view__create-button" type="primary">
              {props.children}
            </Button>
          </Dropdown>
        </div>

        {isBlankAppModalOpen() && (
          <CreateAppModal title="Create a blank app" visible={isBlankAppModalOpen()} closeModal={closeModal} />
        )}
        {isGlobalWidgetModalOpen() && (
          <CreateAppModal
            title="Create a module"
            visible={isGlobalWidgetModalOpen()}
            closeModal={closeModal}
            isGlobalWidget
          />
        )}
        {isImportAppModalOpen() && (
          <ImportAppModal visible={isImportAppModalOpen()} closeModal={closeModal} mode="IMPORT_APP_FROM_FILE" />
        )}
        {isCreateAppFromTemplateModalOpen() && (
          <CreateAppFromTemplateModal visible={isCreateAppFromTemplateModalOpen()} closeModal={closeModal} />
        )}
        {isCreateAppFromDataModalOpen() && (
          <CreateAppFromDataModal visible={isCreateAppFromDataModalOpen()} closeModal={closeModal} />
        )}
        {isNewFolderModalOpen() && <CreateFolderModal visible={isNewFolderModalOpen()} closeModal={closeModal} />}
      </div>
    </div>
  )
}

const mapStateToProps = (state: RetoolState) => {
  return {
    hasFoldersWithWriteAccess: hasFoldersWithWriteAccessSelector(state),
    editorPrivileged: editorPrivilegedSelector(state),
    creatorPrivileged: creatorPrivilegedSelector(state),
  }
}

export default withRouter(connect(mapStateToProps)(CreateApplicationDropdown))

import './Button.scss'

import { Button } from 'antd'
import React from 'react'

import { ButtonProps } from 'antd/lib/button/button'

class RetoolSkinnedButton extends React.PureComponent<ButtonProps, any> {
  render() {
    return (
      <div className="retool-button">
        <Button {...this.props} />
      </div>
    )
  }
}

export default RetoolSkinnedButton

import Immutable from 'immutable'
import { registerPlugin } from 'components/plugins'
import { evaluateInstrument } from 'common/evaluator'
import { SafeAny } from 'common/types'
import { InstrumentationTemplate } from 'common/records'

import { JSON_HEADERS } from 'networking/util'
import { dispatch } from 'store'
import { callApi } from '../../store/callApi'
import { emptyOnTemplateUpdate } from 'components/plugins/widgets/utils'

export enum POST_INSTRUMENTATION_INFO {
  REQUEST = 'REQUEST_SEND_INSTRUMENTATION_INFO',
  SUCCESS = 'RECEIVE_SEND_INSTRUMENTATION_INFO',
  FAILURE = 'SEND_INSTRUMENTATION_INFO_FAILURE',
}

export const InstrumentWidgetTemplate: InstrumentationTemplate = {
  properties: [
    {
      pluginId: '',
      property: '',
    },
  ],
  jsonBody: '{\n\ttime: {{ moment.now() }},\n\tuser_email: {{ current_user.email }},\n}',
  hasConditionsEnabled: false,
  conditionBody: '{{ true }}',
}

const options = {
  docs: {
    link: 'https://docs.retool.com/docs/transformers',
    description: 'Use instruments to track data // TODO:: Update me!',
    properties: {},
  },
  propertyAnnotations: {
    funcBody: {},
    widgetId: {},
    property: {},
  },
}

const wireTemplate = (template: SafeAny) => (options: SafeAny) => {
  return Immutable.Map(template).merge(Immutable.fromJS(options))
}

export async function runInstrument(instrumentPlugin: SafeAny, updatedParams: SafeAny) {
  const id = instrumentPlugin.get('id')
  const instrument = instrumentPlugin.get('template').toJS() as InstrumentationTemplate
  const shouldRun = instrument.properties.some((e) => updatedParams[`${e?.pluginId}.${e?.property}`])
  if (shouldRun) {
    // eslint-disable-next-line no-console
    console.log(`[DBG] instrument evaluating: ${id}`)
    await evalInstrument(instrument, id)
  }
}

export async function evalInstrument(instrumentTemplate: InstrumentationTemplate, id: string) {
  try {
    const result = await evaluateInstrument(instrumentTemplate)
    if (result) {
      dispatch(sendInstrumentation(result, id))
      // eslint-disable-next-line no-console
      console.log(result)
    }
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.error(ex)
  }
}

export function sendInstrumentation(result: unknown, instrumenationName: string) {
  return async (dispatch: SafeAny) => {
    await dispatch(
      callApi({
        endpoint: `/api/organization/instrumentation`,
        method: 'POST',
        body: JSON.stringify({ instrumentation: result, name: instrumenationName }),
        headers: JSON_HEADERS,
        types: [
          POST_INSTRUMENTATION_INFO.REQUEST,
          POST_INSTRUMENTATION_INFO.SUCCESS,
          POST_INSTRUMENTATION_INFO.FAILURE,
        ],
      }),
    )
  }
}

// Finally, wire the widget
registerPlugin(
  'Instrumentation',
  null,
  wireTemplate(InstrumentWidgetTemplate),
  emptyOnTemplateUpdate,
  { basic: [], advanced: [] },
  options,
)

import { connect } from 'react-redux'
import Omnibox from './Omnibox'
import {
  pagesWithPathsSelector,
  resourcesJSSelector,
  foldersSelector,
  playgroundSavedQueriesArraySelector,
} from 'store/selectors'
import { showOmnibox, hideOmnibox } from 'store/omnibox'
import { RetoolState } from 'store'
import { createSelector } from 'reselect'

const omniBoxVisibleSelector = (state: RetoolState) => {
  return state.omnibox.visible
}

const foldersJSSelector = createSelector(foldersSelector, (folders) => {
  return Object.values(folders.toJS())
})

const mapDispatchToProps = {
  showOmnibox,
  hideOmnibox,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    pages: pagesWithPathsSelector(state),
    resources: resourcesJSSelector(state),
    visible: omniBoxVisibleSelector(state),
    folders: foldersJSSelector(state),
    playgroundQueries: playgroundSavedQueriesArraySelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Omnibox)

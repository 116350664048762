import React from 'react'
import { InputField } from '../common'
import { ResourceFormProps } from '../types'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'
import { StripeResourceSettings } from 'common/records'

const StripeForm = (props: ResourceFormProps) => (
  <div className="grid-1c mb12">
    <InputField label="API key" resourceKey="api_key" required {...props} />
    <div className="grid-offset-1 description">
      Enter your secret Stripe API key above. To retrieve or create a key{' '}
      <a href="https://dashboard.stripe.com/account/apikeys" target="_blank" rel="noopener">
        visit the Developers: API keys section
      </a>{' '}
      of your Stripe dashboard.
    </div>
  </div>
)

const defaultOptions: StripeResourceSettings = {
  options: {
    customQueryParameters: [['', '']],
    customHeaders: [['Authorization', 'Bearer API_KEY']],
    authentication: 'apiKey',
    api_key: '',
    spec: `${RETOOL_SPECS_DOMAIN}/stripe/3.0.0/2020-08-27.json`,
  },
}

export default {
  label: 'Stripe',
  form: StripeForm,
  defaults: defaultOptions,
  validator: ({ options }: StripeResourceSettings) => options.api_key,
}

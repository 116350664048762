import { RadioChangeEvent } from 'antd/lib/radio'
import { Radio } from 'components/design-system'
import React from 'react'
import { ResourceFormProps } from '../types'

export type AvailableScope = {
  label: string
  value: string
  description: string
}

function OAuthScopeRadioInput(props: ResourceFormProps & { availableScopes: AvailableScope[] }) {
  const { availableScopes } = props
  return (
    <>
      <Radio.Group
        className="form-google-authorize-radio"
        value={props.resource?.options?.oauth2_scope || availableScopes[0]}
        onChange={(e: RadioChangeEvent) => {
          props.onChangeOption('oauth2_scope')(e.target.value)
        }}
      >
        {availableScopes.map((scope) => {
          return (
            <React.Fragment key={scope.value}>
              <Radio value={scope.value}>{scope.label}</Radio>
              <div className="ml24 fw-400">{scope.description}</div>
            </React.Fragment>
          )
        })}
      </Radio.Group>
    </>
  )
}

export default OAuthScopeRadioInput

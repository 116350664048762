import React from 'react'
import { Map } from 'immutable'
import { copyToClipboard } from 'common/utils'
import { message } from 'antd'
import update from 'immutability-helper'
import { get } from 'lodash'
import { TextInput, Button, Icon, Select } from 'components/design-system'
import { USER_CALLBACK_URL } from '../../oauthForm'
import { RESTBody, RESTQueryType, RESTParameters, RESTHeaders } from './HTTPEditor'
import { SharedStepEditorProps, CustomAuthStep } from '../types'
const REDIRECT_CALLBACK_URL = `${window.location.origin}/oauth/user/redirectCallback`
import './CustomAuth.scss'

export const InputField = (props: any) => (
  <>
    <label className="input-label">{props.label}</label>
    <TextInput
      className="fs-exclude"
      type={props.password ? 'password' : 'text'}
      value={get(props.step, props.resourceKey)}
      placeholder={props.placeholder}
      addonBefore={props.inputAddOnBefore}
      disabled={props.frozen}
      onChange={(e) => {
        props.onChange({
          [props.resourceKey]: e.target.value,
        })
      }}
    />
  </>
)

export const RedirectAuth = (props: any) => {
  return (
    <>
      <div className="lh-16 gray full-grid">
        In redirect based authentication flows, it is important to generate a nonce to defend against CSRF. To support
        this, Retool auto-generates a nonce that you can use with <code>{`{{ csrfNonce }}`}</code> inside your redirect
        url.
      </div>

      <label className="input-label">Callback url</label>
      <div>
        <TextInput className="fs-exclude" value={REDIRECT_CALLBACK_URL} disabled />
        <Button
          type="link"
          onClick={() => {
            copyToClipboard(REDIRECT_CALLBACK_URL)
            message.success('Copied link')
          }}
        >
          Click to copy this URL for your callback url.
        </Button>
      </div>

      <InputField
        label="URL to redirect you to"
        placeholder="https://auth.yourcompany.com/sso?callback=https://retool.yourcompany.com"
        resourceKey="redirect_url"
        {...props}
      />

      <InputField
        label="Parameter in the URL to check the nonce against"
        placeholder="query_nonce"
        resourceKey="csrf_nonce_query_name"
        {...props}
      />
    </>
  )
}

export const GenericOAuth = (props: any) => {
  return (
    <>
      <label className="input-label">OAuth callback URL</label>
      <div>
        <TextInput className="fs-exclude" value={USER_CALLBACK_URL} disabled />
        <Button
          type="link"
          onClick={() => {
            copyToClipboard(USER_CALLBACK_URL)
            message.success('Copied link')
          }}
        >
          Copy this URL to your application.
        </Button>
      </div>

      <InputField
        label="Authorization URL"
        placeholder="https://accounts.google.com/o/oauth2/v2/auth"
        resourceKey="oauth2_auth_url"
        {...props}
      />

      <InputField
        label="Access Token URL"
        placeholder="https://www.googleapis.com/oauth2/v4/token"
        resourceKey="oauth2_access_token_url"
        {...props}
      />

      <InputField label="Client ID" resourceKey="oauth2_client_id" {...props} />

      <InputField label="Client Secret" resourceKey="oauth2_client_secret" password {...props} />

      <InputField label="Scopes (separated by a space)" resourceKey="oauth2_scope" {...props} />

      <InputField label="Audience" resourceKey="oauth2_audience" {...props} />
    </>
  )
}

export const Google = (_props: any) => {
  return (
    <>
      <label className="input-label">OAuth callback URL</label>
      <div>
        <TextInput className="fs-exclude" value={USER_CALLBACK_URL} disabled />
        <Button
          type="link"
          onClick={() => {
            copyToClipboard(USER_CALLBACK_URL)
            message.success('Copied link')
          }}
        >
          Copy this URL to your application.
        </Button>
      </div>
    </>
  )
}

type RestEditorProps = {
  noExport?: boolean
  stepNum?: number
  restApiSenderType: 'server' | 'client'
  customGlobals?: {}
} & SharedStepEditorProps

export const RESTEditor = (props: RestEditorProps) => {
  const editorProps = {
    queryEditorState: Map(props.step),
    queryEditorUpdate: (update: any) => {
      props.onChange(update)
    },
    queryEditorUpdateNoDebounce: (update: any) => {
      props.onChange(update)
    },
    autocompleteHidden: false,
    includeOnlyCustomGlobalsInScope: true,
    customGlobals: props.customGlobals || {},
  }
  return (
    <>
      {props.restApiSenderType === 'client' && (
        <>
          <label className="input-label custom-auth-rest-editor-warning-label">Warning</label>
          <label className="custom-auth-rest-editor-warning-label">
            Only select this option if you need the API request to be made from the client-side. Browser credentials
            will be included, even for cross-origin calls, with this request.
          </label>
        </>
      )}
      <label className="input-label">HTTP query type</label>
      <RESTQueryType {...editorProps} showActionType />

      <RESTParameters {...editorProps} />

      <RESTHeaders {...editorProps} />

      <RESTBody {...editorProps} />
    </>
  )
}

export const DefineVariable = (props: any) => {
  return (
    <>
      <div className=" lh-16 gray full-grid">
        Define a magic variable that should be available in all requests based on some value that was created from a
        previous step. Only usable with the REST API and GraphQL configurations.
      </div>

      <InputField
        label="Variable name"
        placeholder="AUTHORIZATION_TOKEN"
        resourceKey="defined_variable_name"
        {...props}
      />
      <InputField
        label="Value of the variable"
        placeholder="{{ http1.body.auth_token }}"
        resourceKey="defined_variable_value"
        {...props}
      />
    </>
  )
}

type FormModalProps = {
  step: CustomAuthStep
  stepNum: number
  onChange: (value: any) => void
}

export const FormModal = (props: FormModalProps) => {
  const step = props.step
  const addStep = () =>
    props.onChange(
      update(step, {
        form: {
          $push: [{ key: 'email', type: 'email' }],
        },
      }),
    )
  return (
    <>
      <label className="input-label">Input keys</label>
      <div className="keyed-table-editor">
        {step.form.map((row: any, index: any) => {
          const onChange = (changeSet: any) => {
            props.onChange(
              update(step, {
                form: {
                  [index]: {
                    $merge: changeSet,
                  },
                },
              }),
            )
          }
          const deleteStep = () => {
            props.onChange(
              update(step, {
                form: {
                  $splice: [[index, 1]],
                },
              }),
            )
          }
          return (
            <React.Fragment key={index}>
              <div className="key">
                <div className="key-editor">
                  <TextInput
                    value={get(row, 'key')}
                    onChange={(e) => {
                      onChange({
                        ['key']: e.target.value,
                      })
                    }}
                  />
                </div>
              </div>
              <div className="value">
                <div className="value-editor">
                  <Select
                    className="fs-exclude"
                    value={row.type}
                    style={{ margin: 0 }}
                    onChange={(value: any) => onChange({ type: value })}
                  >
                    <Select.Option key="email" value="email">
                      Email
                    </Select.Option>
                    <Select.Option key="text" value="text">
                      Text
                    </Select.Option>
                    <Select.Option key="password" value="password">
                      Password
                    </Select.Option>
                  </Select>
                  <div className="kv-delete">
                    <Button type="link" onClick={deleteStep}>
                      <Icon type="close" />
                    </Button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )
        })}
        <div className="new-row">
          <Button type="link" onClick={addStep}>
            <Icon type="plus" className="mr4" /> Add new
          </Button>
        </div>
      </div>
    </>
  )
}

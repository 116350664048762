export default async function configurePolyfills() {
  // use smooth scroll polyfill
  if (!document.body.scrollTo) {
    // eslint-disable-next-line no-console
    console.log('Loading smoothscroll polyfill...')
    const smoothscroll = await import(/* webpackChunkName: "smoothscroller" */ 'smoothscroll-polyfill')
    smoothscroll.polyfill()
  }
}

export const findUrlSearchParam = (key: string) => {
  const searchStr = window.location.search.substr(1)

  if (!searchStr) return null

  const keyValuePair = searchStr
    .split('&')
    .map((p) => p.split('='))
    .find((p) => p[0] === key)

  return keyValuePair ? keyValuePair[1] : null
}

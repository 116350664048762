import React, { useState, useEffect } from 'react'

import './EditTemplateModalBody.scss'

import { connect } from 'react-redux'
import Button from 'components/design-system/Button'
import { RetoolState } from 'store'
import { Icon, Message } from 'components/design-system'
import { foldersSelector } from 'store/selectors'
import { MarketingTemplateType } from 'store/marketingTemplates.d'
import { resourceIcons } from 'retoolConstants'
import { Folder, ImmutableMapType } from 'common/records'
import NameAndFolderSelector from './NameAndFolderSelector'
import { cloneTemplate } from 'store/user'
import Immutable from 'immutable'
import { navigateToApp } from './CreateAppModal'
import { newAppFolderIdSelector } from 'routes/Home/selectors'

const STARTER_TEMPLATE = 'starterTemplate'

type EditTemplateModalBodyProps = {
  onBack: () => void
  selectedTemplate: MarketingTemplateType
  folders: Immutable.Map<string, ImmutableMapType<Folder>>
  newAppFolderId: number
  cloneTemplate: (
    templateId: string,
    pageName: string,
    folderId: number,
    createType: string,
  ) => Promise<{ demoResourceConnectionRequired?: boolean; newPageName?: string }>
  closeModal: () => void
}

function isValidState(appName: string | undefined) {
  const appNameValid = appName !== undefined
  return appNameValid
}

function EditTemplateModalBody(props: EditTemplateModalBodyProps) {
  const { selectedTemplate } = props
  const [appName, setAppName] = useState<string | undefined>()
  const [parentFolderId, setParentFolderId] = useState<number>(props.newAppFolderId)
  const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false)

  // If we change our current directory, default to that in the folder selector
  useEffect(() => {
    setParentFolderId(props.newAppFolderId)
  }, [props.newAppFolderId])

  useEffect(() => {
    setIsSubmitEnabled(isValidState(appName))
  }, [appName])

  const create = async () => {
    if (!isValidState(appName)) {
      return
    }
    let demoResourceConnectionRequired

    try {
      const { demoResourceConnectionRequired: _demoResourceConnectionRequired } = await props.cloneTemplate(
        props.selectedTemplate.id,
        appName!,
        parentFolderId,
        STARTER_TEMPLATE,
      ) // null check for appName is in validState()
      demoResourceConnectionRequired = _demoResourceConnectionRequired
    } catch (err) {
      return Message.error(err.message)
    }

    const selectedFolder = props.folders.get(parentFolderId.toString())
    navigateToApp({
      folder: selectedFolder,
      appName: appName!,
      queryParam: demoResourceConnectionRequired ? 'demoResourceConnectionRequired=true' : false,
    })

    props.closeModal()
  }

  return (
    <div className="flex fd-col">
      <div className="flex mt24 ml24 mr24 edit-template-modal-body__middle-container">
        <div className="edit-template-modal-body__preview-container">
          <div className="flex fd-col edit-template-modal-body__preview-title-container">
            <div className="flex justify-between edit-template-modal-body__preview-title-and-tags-container">
              <div className="fw-600 dark-gray fs-14 mb4">{props.selectedTemplate.name}</div>
              <div className="flex">
                {selectedTemplate.resources &&
                  selectedTemplate.resources
                    .filter((resource) => resourceIcons[resource])
                    .map((resource) => {
                      return (
                        <div className="flex" key={resource}>
                          <div className="ml4">
                            <img
                              style={{ maxWidth: 16, maxHeight: 16, verticalAlign: 'middle' }}
                              width="16"
                              src={resourceIcons[resource]}
                              className="mr4"
                            />
                          </div>
                          <div className="fs-10 fw-600 mr4 light-gray">{resource.toUpperCase()}</div>
                        </div>
                      )
                    })}
              </div>
            </div>
            <div className="mb12 edit-template-modal-body__preview-title-description-container">
              <div className="gray">{props.selectedTemplate.description}</div>
            </div>
          </div>
          <div className="mb24 bg-faint-gray ba-washed-gray flex edit-template-modal-body__preview-screenshot-container">
            <iframe className="create-app-from-template__preview-iframe" src={selectedTemplate.embeddedAppUrl} />
          </div>
        </div>
        <div className="edit-template-modal-body__name-and-folder-container">
          <NameAndFolderSelector
            appName={appName}
            onAppNameChange={setAppName}
            onCurrentFolderIdChange={setParentFolderId}
          />
        </div>
      </div>
      <div className="flex justify-between pa12 edit-template-modal-body__footer-container">
        <div>
          <Button type="link" onClick={props.onBack}>
            <Icon type="caret-left" />
            Select another template
          </Button>
        </div>
        <div>
          <Button type="primary" disabled={!isSubmitEnabled} onClick={create}>
            Create app
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  cloneTemplate,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    folders: foldersSelector(state),
    newAppFolderId: newAppFolderIdSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTemplateModalBody)

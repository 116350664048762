import { useRef } from 'react'

import useMountEffect from './useMountEffect'
import useUnmountEffect from './useUnmountEffect'

export type Query<Args extends unknown[], Value = void> = (...args: Args) => Promise<Value>
export type Fetch<Args extends unknown[]> = (...args: Args) => void

export type Options<Args extends unknown[] = [], Value = void> = {
  initialArgs?: Args
  fetchOnMount?: boolean
  onFetchStart?: (...args: Args) => void
  onFetchEnd?: () => void
  onSuccess?: (res: Value) => void
  onError?: (error: unknown) => void
}

/**
 * For safely dispatching async requests by avoiding memory leaks from calling setState on unmounted components
 */
const useFetch = <Args extends unknown[] = [], Value = void>(
  query: Query<Args, Value>,
  options: Options<Args, Value> = {},
): Fetch<Args> => {
  const ignore = useRef(false)

  const { onError, onSuccess, onFetchStart, onFetchEnd, fetchOnMount, initialArgs = [] } = options

  const fetch: Fetch<Args> = (...args) => {
    if (!ignore.current) {
      onFetchStart?.(...args)
      query(...args)
        .then((res) => (!ignore.current ? onSuccess?.(res) : null))
        .catch((error: unknown) => (!ignore.current ? onError?.(error) : null))
        .finally(() => (!ignore.current ? onFetchEnd?.() : null))
    }
  }

  useMountEffect(() => {
    if (fetchOnMount) fetch(...(initialArgs as Args))
  })

  useUnmountEffect(() => {
    ignore.current = true
  })

  return fetch
}

export default useFetch

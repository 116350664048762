import _ from 'lodash'

import React, { useState, useEffect, CSSProperties } from 'react'

import { withRouter, browserHistory } from 'react-router'
import { connect } from 'react-redux'

import { UploadFile } from 'antd/lib/upload/interface'

import { unused, ReactHook as Hook } from 'common/types'
import { Page } from 'common/records'
import { fuzzyFindAll, FuzzyFindInput, FuzzyFindResult } from 'components/FuzzyFind'
import { showConfirm } from 'components/standards/Modal'
import { Button, Checkbox, Icon, Modal, TextInput } from 'components/design-system'
import OnboardingSuggestions from './OnboardingSuggestions'

import { hasPresentationModeFeatureSelector } from 'common/paymentPlans'
import ApplicationItem from './ApplicationItem'
import FolderItem from './FolderItem'
import { List, AutoSizer, WindowScroller } from 'react-virtualized'

import {
  uploadPage,
  deletePage,
  renamePage,
  movePage,
  bulkMovePages,
  bulkDeletePages,
  renameFolder,
} from 'store/appModel/pages'
import {
  FolderWithChildren,
  LastPageEdit,
  archiveFolderSelector,
  editorPrivilegedSelector,
  onboardingSuggestionsVisibleSelector,
  pagesEditedBySelector,
  creatorPrivilegedSelector,
  pagesWithPathsSelector,
  isAdminSelector,
  moduleCalloutShownSelector,
  hasFoldersWithWriteAccessSelector,
  protectedAppsEnvVarsSetSelector,
} from 'store/selectors'
import { inviteToOrg, favoritePage, unfavoritePage } from 'store/user'
import { exportSave } from 'store/appModel/template'
import { RetoolState } from 'store'

import RenameModal from '../modals/RenameModal'
import MoveItemsModal from '../modals/MoveItemsModalContainer'
import CreateAppModal from '../modals/CreateAppModal'

import CreateApplicationDropdown from './CreateApplicationDropdown'
import MoveAppsButton from './MoveAppsButton'

import { HomeViewFilter, isTrashFilter, isUserFolderFilter, isSpecialViewFilter } from 'routes/Home/filters'

import { visiblePagesSelector, currentFilterSelector } from 'routes/Home/selectors'

import './ApplicationListing.scss'
import LearnRetool from '../onboarding-helpers/LearnRetool'
import AppDetails from '../AppDetails'
import UserFolderControls from '../controls/UserFolderControls'
import AppProtectionModal from 'components/ProtectedApps/AppProtectionModal/AppProtectionModalContainer'
import TryModules from '../onboarding-helpers/TryModules'
import { pageFavoritesSelector, tutorialCTAShownSelector } from 'store/selectors/userSelectors'
import PageProtectionModalContainer from 'components/ProtectedApps/AppProtectionModal/PageProtectionModalContainer'

type Dimensions = {
  width: number
  height: number
}

type WidthSized = {
  width: number
}

type HeightCalculatorProps = {
  index: number
}

type RowRendererProps = {
  index: number
  style: React.CSSProperties
}

const mapDispatchToProps = {
  uploadPage,
  deletePage,
  renamePage,
  movePage,
  bulkMovePages,
  bulkDeletePages,
  favoritePage,
  unfavoritePage,
  renameFolder,
  exportSave,
  inviteToOrg,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    pagesEditedBy: pagesEditedBySelector(state),
    learnRetoolVisible: tutorialCTAShownSelector(state),
    onboardingVisible: onboardingSuggestionsVisibleSelector(state),
    moduleCalloutVisible: moduleCalloutShownSelector(state),
    allPagesInCurrentFilter: visiblePagesSelector(state),
    allPages: pagesWithPathsSelector(state),
    currentFilter: currentFilterSelector(state),
    hasPresentationModeFeature: hasPresentationModeFeatureSelector(state),
    favoritePageIds: pageFavoritesSelector(state),
    editorPrivileged: editorPrivilegedSelector(state),
    creatorPrivileged: creatorPrivilegedSelector(state),
    hasFolderWithWriteAccess: hasFoldersWithWriteAccessSelector(state),
    isAdmin: isAdminSelector(state),
    archiveFolder: archiveFolderSelector(state),
    protectedAppsEnvVarsSet: protectedAppsEnvVarsSetSelector(state),
  }
}

type PagesEditedBy = { [k: number]: LastPageEdit }

export type Props = {
  scroller: React.RefObject<HTMLDivElement | null>
  learnRetoolVisible: boolean
  onboardingVisible: boolean
  moduleCalloutVisible: boolean
  allPagesInCurrentFilter: Page[]
  allPages: Page[]
  pagesEditedBy: PagesEditedBy
  favoritePageIds: number[]
  favoritePage: (pageId: number) => Promise<any>
  unfavoritePage: (pageId: number) => Promise<any>
  currentFilter: HomeViewFilter
  hasPresentationModeFeature: boolean
  uploadPage: (file: UploadFile, pageName: string, folderId: number) => Promise<any>
  deletePage: (pageId: number) => Promise<any>
  renamePage: (renameId: string, name: string, redirect: boolean) => Promise<void>
  movePage: (pageId: number, folderId: number) => Promise<void>
  renameFolder: (folderId: number, folderName: string) => Promise<any>
  bulkMovePages: (pageIds: number[], folderId: number) => Promise<any>
  bulkDeletePages: (pageIds: number[]) => Promise<any>
  exportSave: (pageName: string, pageUuid: string, downloadJson: boolean, isGlobalWidget: boolean) => Promise<any>
  inviteToOrg: (emails: string[], groupIds: number[]) => Promise<any>
  archiveFolder: FolderWithChildren | null
  editorPrivileged: boolean
  creatorPrivileged: boolean
  hasFolderWithWriteAccess: boolean
  isAdmin: boolean
  protectedAppsEnvVarsSet: boolean
}

type TitleAndSearchProps = Props & {
  queryHook: Hook<string>
  folderToRenameHook: Hook<FolderWithChildren | null>
}

// Exported for testing
export const isFolderEditable = (allPages: Page[], currentFolderId: number, isAdmin: boolean): boolean => {
  const childPages = allPages.filter((p) => p.folderId === currentFolderId)
  const isOwner = isAdmin || childPages.every((p) => p.accessLevel === 'own')

  return isOwner
}

const TitleAndSearch = (props: TitleAndSearchProps) => {
  const { currentFilter, creatorPrivileged, hasFolderWithWriteAccess, queryHook, folderToRenameHook } = props
  const [query, setQuery] = queryHook

  let createApplication = <></>
  if (creatorPrivileged || hasFolderWithWriteAccess) {
    createApplication = (
      <CreateApplicationDropdown enableNewFolder>
        Create new
        <Icon type="caret-down" style={{ marginRight: -8 }} />
      </CreateApplicationDropdown>
    )
  }

  return (
    <div className="justify-between items-center flex mb24 pt20">
      <div className="fs-20 fw-600 truncate pr12 lh-28">
        {isUserFolderFilter(currentFilter) && <Icon type="folder" className="mr8 flex-no-shrink" />}
        {currentFilter.name}
      </div>
      <div className="flex items-center">
        <TextInput
          className="application-listing-search"
          placeholder="Search"
          icon="magnifying-glass"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div className="ml8">{createApplication}</div>
        {isUserFolderFilter(currentFilter) ? (
          <UserFolderControls folder={currentFilter} folderToRenameHook={folderToRenameHook} />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

type SearchToolsProps = Props & {
  visiblePages: Page[]
  selectedPageIdsHook: Hook<number[]>
}

const SearchTools = (props: SearchToolsProps) => {
  const {
    visiblePages,
    allPagesInCurrentFilter,
    selectedPageIdsHook,
    currentFilter,
    bulkDeletePages,
    bulkMovePages,
    archiveFolder,
  } = props
  const [selectedPageIds, setSelectedPageIds] = selectedPageIdsHook
  const deselectPages = () => setSelectedPageIds([])
  const numSelectedPages = selectedPageIds.length

  const numPages = visiblePages.length
  // TODO num folders === 0 empty stuff
  // testing of all this

  let controls = <></>
  if (numSelectedPages > 0) {
    let selectedText = `${numSelectedPages} apps selected`
    if (numSelectedPages === 1) {
      selectedText = `1 app selected`
    }

    let trashButton: React.ReactElement = <></>
    if (isTrashFilter(currentFilter)) {
      const confirmDeletion = async () => {
        let applicationDescriptor = `all ${numSelectedPages} applications`
        if (numSelectedPages === 1) {
          applicationDescriptor = `this application`
        }

        showConfirm({
          title: `Are you sure you want to delete ${applicationDescriptor}?`,
          okText: 'Delete',
          autoFocusButton: 'ok',
          okButtonProps: {
            className: 'btn-danger',
          },
          content: (
            <div>
              This action is <strong>permanent</strong>! It cannot be undone.
            </div>
          ),
          onOk: async (close: () => void) => {
            await bulkDeletePages(selectedPageIds)
            close()
          },
        })
      }

      trashButton = (
        <Button type="danger" onClick={confirmDeletion} className="ml8">
          Delete permanently
        </Button>
      )
    } else {
      trashButton = (
        <Button type="danger" onClick={() => bulkMovePages(selectedPageIds, archiveFolder!.id)} className="ml8">
          Trash
        </Button>
      )
    }

    controls = (
      <div className="flex items-center pv4">
        <div className="gray mr12 mt2" style={{ marginTop: -2 }}>
          {selectedText}
        </div>
        <Button type="link" className="mr12" onClick={deselectPages}>
          Deselect
        </Button>
        <MoveAppsButton selectedPageIds={selectedPageIds} deselectItems={deselectPages} />
        {trashButton}
      </div>
    )
  }

  const toggleHeaderCheckbox = (_: unused) => {
    if (numSelectedPages === 0) {
      setSelectedPageIds(visiblePages.map((p) => p.id))
    } else {
      setSelectedPageIds([])
    }
  }

  const totalPages = allPagesInCurrentFilter.length

  const checked = numSelectedPages > 0
  const indeterminate = checked && numSelectedPages < numPages

  let checkboxMessage: string
  if (checked) {
    checkboxMessage = 'Deselect all'
  } else if (numPages !== totalPages) {
    checkboxMessage = 'Select all matching apps'
  } else {
    checkboxMessage = 'Select all'
  }

  if (numPages === 0) {
    return <div />
  }

  return (
    <div className="justify-between items-center flex mb4 pl4" style={{ height: 40 }}>
      <Checkbox checked={checked} indeterminate={indeterminate} onChange={toggleHeaderCheckbox}>
        <span style={{ marginLeft: 2 }} className="light-gray fw-400 lh-24">
          {checkboxMessage}
        </span>
      </Checkbox>
      {controls}
    </div>
  )
}

const sendInvitesStateless = (
  setInvitationModalContent: (_: React.ReactElement | null) => void,
  inviteToOrg: (emails: string[], groupIds: number[]) => Promise<any>,
) => async (emailsString: string) => {
  const emails = emailsString.split(',').filter((e) => e.trim().length > 0)
  if (emails.length === 0) {
    setInvitationModalContent(
      <div className="validation">
        <p>Please provide a list of comma-separated e-mails.</p>
      </div>,
    )
  } else {
    try {
      const payload = await inviteToOrg(emails, [])
      const invitees = payload.invitees

      // we may have only invited a part of the invitees
      const invitedEmails = invitees.map((i: any) => i.email)
      const failedEmails = emails.filter((email) => !invitedEmails.find((e: any) => e === email))

      let pluralizedInvitatons = `${emails.length} invitations`
      if (emails.length === 1) {
        pluralizedInvitatons = '1 invitation'
      }

      if (failedEmails.length === 0) {
        setInvitationModalContent(
          <div className="good">
            <p>{pluralizedInvitatons} dispatched!</p>
          </div>,
        )
      } else {
        setInvitationModalContent(
          <div className="partial">
            <p>
              {failedEmails.length} of {pluralizedInvitatons} could not be sent
            </p>
            <ul>
              {failedEmails.map((e) => (
                <li key={e}>{e}</li>
              ))}
            </ul>
          </div>,
        )
      }
    } catch (error) {
      let subjectifiedInvitations = `all ${emails.length} invitations`
      if (emails.length === 1) {
        subjectifiedInvitations = 'the invitation'
      }

      setInvitationModalContent(
        <div className="error">
          <p>Error sending {subjectifiedInvitations}.</p>
        </div>,
      )
    }
  }
}

type EmptyListProps = {
  isTrash: boolean
  noFoldersBeforeSearch: boolean
  noPagesBeforeSearch: boolean
  style: CSSProperties
}

const EmptyList = (props: EmptyListProps) => {
  const { isTrash, noFoldersBeforeSearch, noPagesBeforeSearch, style } = props

  let ifEmptyMessage = 'Nothing here.'

  if (noPagesBeforeSearch) {
    if (noFoldersBeforeSearch) {
      ifEmptyMessage = 'Nothing here.'
    } else {
      ifEmptyMessage = 'No matching folders.'
    }
  } else {
    if (noFoldersBeforeSearch) {
      ifEmptyMessage = 'No matching apps.'
    } else {
      ifEmptyMessage = 'No matching apps or folders.'
    }
  }

  if (isTrash && noPagesBeforeSearch) {
    ifEmptyMessage = 'Trash is empty.'
  }

  return (
    <div style={style}>
      <div
        style={{ border: '1.6px dashed var(--faint-gray)' }}
        className="w-100 h-100 fs-13 light-gray br4 flex items-center justify-center"
      >
        {ifEmptyMessage}
      </div>
    </div>
  )
}

type StickySlabConfiguration = {
  height: number
  zIndex: number
  marginTop: number
  paddingLeft?: number
  paddingTop?: number
  stickyOffset?: number
}

type StickySlabProps = StickySlabConfiguration & {
  children: React.ReactChild
}

const StickySlab = (props: StickySlabProps) => {
  const { height, zIndex, marginTop, children } = props

  const paddingLeft = props.paddingLeft || 0
  const paddingTop = props.paddingTop || 0
  const stickyOffset = props.stickyOffset || 0

  const containerStyle: CSSProperties = { position: 'absolute', height: height + paddingTop }

  const spacerStyle: CSSProperties = {
    zIndex,
    top: 0,
    marginTop,
    paddingTop: stickyOffset,
    position: 'sticky',
    pointerEvents: 'none',
  }

  const contentStyle: CSSProperties = {
    pointerEvents: 'all',
    paddingLeft,
    background: 'white',
    paddingTop,
  }

  return (
    <AutoSizer defaultHeight={height}>
      {(sizing: Dimensions) => (
        <div style={{ ...containerStyle, ...sizing }}>
          {/* this is a spacer to make sure a non-clickable gap is forced */}
          <div style={spacerStyle}>
            <div style={contentStyle}>{children}</div>
          </div>
        </div>
      )}
    </AutoSizer>
  )
}

type SizedStickyTitleAndSearchProps = StickySlabConfiguration & TitleAndSearchProps

const SizedStickyTitleAndSearch = (props: SizedStickyTitleAndSearchProps) => {
  return (
    <StickySlab {...props}>
      <TitleAndSearch {...props} />
    </StickySlab>
  )
}

type SizedStickySearchTools = StickySlabConfiguration & SearchToolsProps

const SizedStickySearchTools = (props: SizedStickySearchTools) => {
  return (
    <StickySlab {...props}>
      <SearchTools {...props} />
    </StickySlab>
  )
}

const getSubheaderDimensions = (
  moduleCalloutVisible: boolean,
  learnRetoolVisible: boolean,
): { subheaderHeight: number; subheaderTopMargin: number } => {
  if (moduleCalloutVisible) {
    return {
      subheaderHeight: 257,
      subheaderTopMargin: 24,
    }
  }

  if (learnRetoolVisible) {
    return {
      subheaderHeight: 72,
      subheaderTopMargin: 24,
    }
  }

  return {
    subheaderHeight: 0,
    subheaderTopMargin: 0,
  }
}

const ApplicationListing = (props: Props) => {
  const {
    allPages,
    allPagesInCurrentFilter,
    currentFilter,
    learnRetoolVisible,
    onboardingVisible,
    moduleCalloutVisible,
    pagesEditedBy,
    scroller,
    inviteToOrg,
    renameFolder,
    isAdmin,
  } = props

  const MODAL_CLOSED = null

  const [invitationModalContent, setInvitationModalContent] = useState<React.ReactElement | null>(MODAL_CLOSED)

  const closeInviteModal = () => setInvitationModalContent(MODAL_CLOSED)
  const isInviteModalClosed = invitationModalContent !== MODAL_CLOSED

  const sendInvites = sendInvitesStateless(setInvitationModalContent, inviteToOrg)

  const queryHook: Hook<string> = useState<string>('')
  const [query, setQuery] = queryHook
  useEffect(() => setQuery(''), [setQuery, props.currentFilter])

  const selectedPageIdsHook = useState<number[]>([])
  const [, setSelectedPageIds] = selectedPageIdsHook
  const deselectPages = () => setSelectedPageIds([])

  useEffect(deselectPages, [props.currentFilter])

  const [pageToShowPermissions, setPageToShowPermissions] = useState<Page | null>(null)
  const closePermissionsModal = () => setPageToShowPermissions(null)

  const [pageToBeRenamed, setPageToBeRenamed] = useState<Page | null>(null)
  const closeRenamePageModal = () => setPageToBeRenamed(null)

  const [pageToBeMoved, setPageToBeMoved] = useState<Page | null>(null)
  const closeMovePageModal = () => setPageToBeMoved(null)

  const [pageToBeCloned, setPageToBeCloned] = useState<Page | null>(null)
  const closeClonePageModal = () => setPageToBeCloned(null)

  const [pageToBeProtected, setPageToBeProtected] = useState<Page | null>(null)
  const closeProtectPageModal = () => setPageToBeProtected(null)

  const [pageToBeUnprotected, setPageToBeUnprotected] = useState<Page | null>(null)
  const closeUnprotectPageModal = () => setPageToBeUnprotected(null)

  const folderToRenameHook = useState<FolderWithChildren | null>(null)
  const [folderToRename, setFolderToRename] = folderToRenameHook
  const closeRenameFolderModal = () => setFolderToRename(null)

  const isTrash = isTrashFilter(currentFilter)
  const allFolders = isSpecialViewFilter(currentFilter) ? currentFilter.foldersToDisplay : []
  const folders = allFolders.filter((f) => !f.systemFolder)

  // this is the sticky folder/filter title and search box; `AutoSizer` enables
  // us to resize this based on size of the window
  const onboardingHeight = onboardingVisible ? 284 : 0
  const titleHeight = 60

  const { subheaderHeight, subheaderTopMargin } = getSubheaderDimensions(moduleCalloutVisible, learnRetoolVisible)

  const emptyIndicatorMarginTop = 20
  const emptyIndicatorHeight = 88

  const lastEditors = _.mapValues(pagesEditedBy, (lastEdit) => lastEdit.user)

  const idExtractor = (item: Page | FolderWithChildren) => item.id

  const makeFuzzyFindPagesInput = (page: Page) => {
    const lastEditor = lastEditors[page.id] || ''
    const matchables = {
      name: page.name.toLowerCase(),
      editor: lastEditor.toLowerCase(),
    }
    return [page, matchables] as FuzzyFindInput<Page>
  }
  const fuzzyFindPagesInput =
    query.length === 0 ? allPagesInCurrentFilter.map(makeFuzzyFindPagesInput) : allPages.map(makeFuzzyFindPagesInput)

  const [pageMatches, visiblePages]: FuzzyFindResult<Page> = fuzzyFindAll(
    query.toLowerCase(),
    idExtractor,
    fuzzyFindPagesInput,
  )

  const numPages = visiblePages.length

  const fuzzyFindFoldersInput = folders.map((folder) => {
    const matchables = {
      name: folder.name.toLowerCase(),
    }
    return [folder, matchables] as FuzzyFindInput<FolderWithChildren>
  })

  const [folderMatches, visibleFolders]: FuzzyFindResult<FolderWithChildren> = fuzzyFindAll(
    query.toLowerCase(),
    idExtractor,
    fuzzyFindFoldersInput,
  )

  const numFolders = visibleFolders.length

  const stickySearchToolsTopMargin = onboardingHeight + subheaderHeight + subheaderTopMargin

  const searchToolsHeight = 40
  const searchToolsPadding = 24

  /*
   * NB: mechanically shift the elements down as needed
   * changing the count of items is an effective way to force a reflow
   * changing the heights isn't an effective way to cause a re-render
   *
   * Ordering of Components:
   *  - OnboardingSuggestions (if visible)
   *  - TitleAndSearch
   *  - LearnRetool (if visible)
   *  - SearchTools
   *  - Folder[]
   *  - Application[]
   */

  let onboardingIndex = -1
  let subheaderIndex = -1
  let titleIndex = 0
  let searchToolsIndex = 1

  if (onboardingVisible) {
    onboardingIndex = 0
    titleIndex++
    searchToolsIndex++
  }

  if (learnRetoolVisible || moduleCalloutVisible) {
    subheaderIndex = titleIndex + 1
    searchToolsIndex++
  }

  let emptyIndicatorIndex = -1
  if (numPages === 0 && numFolders === 0) {
    emptyIndicatorIndex = searchToolsIndex
    searchToolsIndex = -1
  }

  const lastExtraItemIndex = Math.max(searchToolsIndex, emptyIndicatorIndex)

  const folderOffset = lastExtraItemIndex + 1
  const pageOffset = folderOffset + numFolders
  const totalItems = pageOffset + numPages

  const heightCalculator = ({ index }: HeightCalculatorProps) => {
    if (index === onboardingIndex) {
      return onboardingHeight
    }
    if (index === titleIndex) {
      return titleHeight
    }
    if (index === subheaderIndex) {
      return subheaderHeight + subheaderTopMargin
    }
    if (index === searchToolsIndex) {
      return searchToolsHeight + searchToolsPadding
    }
    if (index === emptyIndicatorIndex) {
      return emptyIndicatorHeight + emptyIndicatorMarginTop
    }
    return 64
  }

  const rowRenderer = ({ index, style }: RowRendererProps) => {
    if (index < folderOffset) {
      if (index === onboardingIndex) {
        return <OnboardingSuggestions key="onboarding-suggestions" sendInvites={sendInvites} style={style} />
      }
      if (index === subheaderIndex) {
        if (moduleCalloutVisible) {
          return (
            <TryModules
              key="try-modules"
              style={{ ...style, zIndex: 4, height: subheaderHeight, marginTop: subheaderTopMargin }}
            />
          )
        } else {
          return (
            <LearnRetool
              key="learn-retool"
              style={{ ...style, zIndex: 4, height: subheaderHeight, marginTop: subheaderTopMargin }}
            />
          )
        }
      }
      if (index === emptyIndicatorIndex) {
        const height = (style.height || emptyIndicatorHeight) as number
        return (
          <EmptyList
            key="empty-list"
            noFoldersBeforeSearch={allFolders.length === 0}
            noPagesBeforeSearch={allPagesInCurrentFilter.length === 0}
            isTrash={isTrashFilter(props.currentFilter)}
            style={{ ...style, height: height - emptyIndicatorMarginTop, marginTop: emptyIndicatorMarginTop }}
          />
        )
      }
      // NB: this renders empty space for sticky items
      return <div key={`empty-space-${index}`} style={style} />
    } else if (index < pageOffset) {
      const folder = visibleFolders[index - folderOffset]
      const id = folder.id

      const isOwner = isFolderEditable(allPages, id, isAdmin)
      return (
        <div key={`folder-${id}`} style={{ ...style, paddingLeft: 4 }}>
          <FolderItem
            selectedPageIdsHook={selectedPageIdsHook}
            folderToRenameHook={folderToRenameHook}
            matches={folderMatches[id]}
            folder={folder}
            isEditable={isOwner}
          />
        </div>
      )
    } else {
      const record = visiblePages[index - pageOffset]
      const id = record.id
      return (
        <div key={`page-${id}`} style={{ ...style, paddingLeft: 4 }}>
          <ApplicationItem
            isTrash={isTrash}
            selectedPageIdsHook={selectedPageIdsHook}
            setPageToShowPermissions={setPageToShowPermissions}
            setPageToBeRenamed={setPageToBeRenamed}
            setPageToBeMoved={setPageToBeMoved}
            setPageToBeCloned={setPageToBeCloned}
            setPageToBeProtected={setPageToBeProtected}
            setPageToBeUnprotected={setPageToBeUnprotected}
            matches={pageMatches[id]}
            pageEditedBy={pagesEditedBy[id]}
            record={record}
            protectedAppsHasEnvVarsSet={props.protectedAppsEnvVarsSet}
          />
        </div>
      )
    }
  }

  let maybeModal: React.ReactChild = <></>
  if (pageToBeRenamed !== null) {
    maybeModal = (
      <RenameModal
        visible={true}
        renameFunction={(name) => props.renamePage(pageToBeRenamed.uuid, name, false)}
        name="App"
        closeModal={closeRenamePageModal}
      />
    )
  } else if (pageToBeMoved !== null) {
    maybeModal = (
      <MoveItemsModal
        visible={true}
        closeModal={closeMovePageModal}
        selectedPageIds={[pageToBeMoved.id]}
        deselectItems={closeMovePageModal}
      />
    )
  } else if (pageToBeCloned !== null) {
    maybeModal = (
      <CreateAppModal
        title={`Duplicate '${pageToBeCloned.name}'`}
        navigateToAppAfterCreate={false}
        visible={true}
        closeModal={closeClonePageModal}
        cloneFromAppUuid={pageToBeCloned.uuid}
      />
    )
  } else if (pageToBeUnprotected !== null) {
    maybeModal = (
      <AppProtectionModal
        visible={true}
        page={pageToBeUnprotected}
        closeModal={closeUnprotectPageModal}
        widgetType={pageToBeUnprotected.isGlobalWidget ? 'module' : 'application'}
      />
    )
  } else if (pageToBeProtected !== null) {
    maybeModal = (
      <PageProtectionModalContainer
        visible={true}
        page={pageToBeProtected}
        closeModal={closeProtectPageModal}
        pageType={pageToBeProtected.isGlobalWidget ? 'module' : 'application'}
      />
    )
  } else if (pageToShowPermissions !== null) {
    maybeModal = (
      <Modal visible={true} onCancel={closePermissionsModal} footer={null} width={615}>
        <AppDetails page={pageToShowPermissions} />
      </Modal>
    )
  } else if (folderToRename !== null) {
    const followRename = folderToRename === currentFilter
    maybeModal = (
      <RenameModal
        visible={true}
        renameFunction={async (name) => {
          await renameFolder(folderToRename.id, name)
          if (followRename) {
            browserHistory.push(`/folders/${encodeURIComponent(name)}`)
          }
        }}
        name="Folder"
        closeModal={closeRenameFolderModal}
      />
    )
  }

  const inviteModal = (
    <Modal
      className="onboarding-invitation-modal"
      visible={isInviteModalClosed}
      onCancel={closeInviteModal}
      onOk={closeInviteModal}
      footer={null}
      width={500}
    >
      {invitationModalContent}
    </Modal>
  )

  return (
    <>
      <div className="application-listing">
        <SizedStickyTitleAndSearch
          zIndex={5}
          height={titleHeight}
          marginTop={onboardingHeight}
          queryHook={queryHook}
          folderToRenameHook={folderToRenameHook}
          {...props}
        />
        {searchToolsIndex >= 0 ? (
          <SizedStickySearchTools
            zIndex={3}
            paddingLeft={4}
            height={searchToolsHeight}
            paddingTop={searchToolsPadding}
            marginTop={stickySearchToolsTopMargin}
            stickyOffset={titleHeight}
            visiblePages={visiblePages}
            selectedPageIdsHook={selectedPageIdsHook}
            {...props}
          />
        ) : (
          <></>
        )}
        {/* NB: on initialization, the ref is unset, so we need a fallback component to prevent the page from being broken */}
        <WindowScroller scrollElement={(scroller.current || document.querySelector('#root')) as Element}>
          {({ height, isScrolling, onChildScroll, scrollTop, registerChild }) => {
            return (
              <AutoSizer disableHeight defaultHeight={height}>
                {({ width }: WidthSized) => {
                  return (
                    <div ref={registerChild}>
                      <List
                        autoHeight
                        height={height ?? 0}
                        isScrolling={isScrolling}
                        onScroll={onChildScroll}
                        overscanRowCount={2}
                        scrollTop={scrollTop}
                        width={width}
                        rowHeight={heightCalculator}
                        rowCount={totalItems}
                        rowRenderer={rowRenderer}
                      />
                    </div>
                  )
                }}
              </AutoSizer>
            )
          }}
        </WindowScroller>
      </div>
      {inviteModal}
      {maybeModal}
    </>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationListing))

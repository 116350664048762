import { OnboardingStep, OnboardingSubStep } from 'common/records'
import { ResourceType } from 'common/resourceTypes'
import { fromJS } from 'immutable'
import _ from 'lodash'
import {
  ALL_TABLE_COLUMNS,
  BuildingBlock,
  BuildingBlockGeneratorInput,
  BuildingBlockId,
  GOOGLE_SHEET_PLACEHOLDER,
  QUERY_ID_PLACEHOLDER,
  TABLE_ID_PLACEHOLDER,
  TABLE_PLACEHOLDER,
  TABLE_WIDGET_DATA_SOURCE_PLACEHOLDER,
  TARGET_COLUMN,
  TARGET_COLUMN_PLACEHOLDER,
  UNIQUE_COLUMN,
  UNIQUE_COLUMN_PLACEHOLDER,
  UPDATE_RECORD_BUILDING_BLOCK,
  UPDATE_TEXT_INPUT_ID_PLACEHOLDER,
  WidgetBuildingBlock,
} from '../buildingBlocks'
import { allResourceSheets, allResourcesInput, allResourceTables } from 'components/BuildingBlocks/generatorInputs'

const updateTableQueryName = 'updateSelectedRecord'
const updateTextInputId = 'updatedSelectedRowInput'

//Building block assumes a table is made from another building block (this is enforced in the building block grid)
const constructBuildingBlock = () => {
  const widgets = []
  const queries = []

  const textInputWidget: WidgetBuildingBlock = {
    id: `${updateTextInputId}`,
    type: 'TextInputWidget',
    mergeWidget: false,
    parentBuildingBlockId: UPDATE_RECORD_BUILDING_BLOCK,
    options: {
      onSubmit: {
        value: `${UPDATE_RECORD_BUILDING_BLOCK}-${QUERY_ID_PLACEHOLDER}`,
        priority: 0,
      },
      label: {
        value: `Update ${TARGET_COLUMN_PLACEHOLDER} (enter submits)`,
        priority: 0,
      },
      inputType: {
        value: `text`,
        priority: 1,
      },
      initialValue: {
        value: `{{${TABLE_ID_PLACEHOLDER}.selectedRow.data['${TARGET_COLUMN_PLACEHOLDER}']}}`,
        priority: 1,
      },
    },
  }

  widgets.push(textInputWidget)

  const postgresSearchBuildingBlock = generatePostgresBuildingBlock()
  const mysqlBlock = _.cloneDeep(postgresSearchBuildingBlock)
  mysqlBlock.type = 'mysql'
  const googleSheetsBuildingBlock = generateGoogleSheetsBuildingBlock()

  queries.push(postgresSearchBuildingBlock)
  queries.push(googleSheetsBuildingBlock)
  queries.push(mysqlBlock)

  const targetColumnUpdate: BuildingBlockGeneratorInput = {
    id: TARGET_COLUMN,
    options: ALL_TABLE_COLUMNS,
    label: 'Column to update',
  }

  const uniqueColumnUpdate: BuildingBlockGeneratorInput = {
    id: UNIQUE_COLUMN,
    options: ALL_TABLE_COLUMNS,
    label: 'Column with unique identifier',
  }

  const updateExistingRecordsBuildingBlock: BuildingBlock = {
    id: 'updateRecord',
    displayName: 'Update Row',
    description: 'Textbox to update a column in a table',
    widgets,
    queries,
    showInEditor: true,
    showInOnboarding: true,
    generatorInputs: [allResourcesInput, allResourceTables, allResourceSheets, targetColumnUpdate, uniqueColumnUpdate],
  }

  return updateExistingRecordsBuildingBlock
}

const generatePostgresBuildingBlock = () => {
  const postgresSelectTabHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `#query-editor__tab-${updateTableQueryName}`,
    title: `Click on ${updateTableQueryName}`,
    hintText: `Navigate to the right query from the bottom query editor.`,
    nextCondition: (_template, _model, editor) => {
      const allSelectedElements = editor.lastSelectedElements
      return allSelectedElements.has(`query-editor__tab-${updateTableQueryName}`)
      //Query is defined and different from the template's query
    },
  })

  const postgresChangeTableHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `#change-set-row`,
    title: 'Confirm the table and column you want to use',
    hintText: 'We narrow which records to update with filter by and update the columns in the changeset.',
    nextCondition: (_template, _model, editor) => {
      const allSelectedElements = editor.lastSelectedElements
      return allSelectedElements.has(`#change-set-row`)
      //Query is defined and different from the template's query
    },
  })

  const postgresOnboardingSubsteps = [postgresSelectTabHint, postgresChangeTableHint]
  const postgresOnboardingStep = new OnboardingStep({
    title: 'Modify your update query',
    substeps: fromJS(postgresOnboardingSubsteps),
    condition: postgresOnboardingSubsteps[postgresOnboardingSubsteps.length - 1].nextCondition,
  })

  return {
    id: updateTableQueryName,
    type: 'postgresql' as ResourceType,
    parentBuildingBlockId: UPDATE_RECORD_BUILDING_BLOCK as BuildingBlockId,
    options: {
      editorMode: 'gui',
      requireConfirmation: true,
      tableName: TABLE_PLACEHOLDER,
      actionType: 'UPDATE_BY',
      runWhenModelUpdates: false,
      filterBy: JSON.stringify([
        {
          value: `{{${TABLE_ID_PLACEHOLDER}.selectedRow.data['${UNIQUE_COLUMN_PLACEHOLDER}']}}`,
          key: UNIQUE_COLUMN_PLACEHOLDER,
          operation: '=',
        },
      ]),
      changeset: JSON.stringify([
        {
          value: `{{${UPDATE_TEXT_INPUT_ID_PLACEHOLDER}.value}}`,
          key: TARGET_COLUMN_PLACEHOLDER,
        },
      ]),
      triggersOnSuccess: [TABLE_WIDGET_DATA_SOURCE_PLACEHOLDER],
    },
    hintSequence: postgresOnboardingStep,
  }
}

const generateGoogleSheetsBuildingBlock = () => {
  return {
    id: updateTableQueryName,
    type: 'googlesheets' as ResourceType,
    parentBuildingBlockId: UPDATE_RECORD_BUILDING_BLOCK as BuildingBlockId,
    options: {
      actionType: 'update',
      spreadsheetId: `${TABLE_PLACEHOLDER}`,
      runWhenModelUpdates: false,
      requireConfirmation: true,
      sheetName: GOOGLE_SHEET_PLACEHOLDER,
      triggersOnSuccess: [TABLE_WIDGET_DATA_SOURCE_PLACEHOLDER],
      filterBy: JSON.stringify([
        {
          key: UNIQUE_COLUMN_PLACEHOLDER,
          operation: '=',
          value: `{{${TABLE_ID_PLACEHOLDER}.selectedRow.data['${UNIQUE_COLUMN_PLACEHOLDER}']}}`,
        },
      ]),
      sheetUpdate: JSON.stringify({ [TARGET_COLUMN_PLACEHOLDER]: `{{${UPDATE_TEXT_INPUT_ID_PLACEHOLDER}.value}}` }),
    },
  }
}

//TODO: currently only postgres is ready, the rest need to be figured out
/*const mongoDBBuildingBlock = () => {
  const mongoDBBuildingBlockHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${viewTabularDataQueryName}"] .cm-evaluation-wrapper`,
    hintText: 'Confirm the table and column you want to use',
    nextCondition: (template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      const queryState = editor.queryState.get(queryStateKey)?.toJS()
      const queryPlugin = template.plugins.get(viewTabularDataQueryName)?.toJS()
      //Query is defined and different from the template's query
      return (
        queryState?.query != null && queryState.query !== queryPlugin?.template.query && queryState.query.length > 0
      )
    },
  })

  const postgresOnboardingSubsteps = [postgresChangeTableHint]
  const postgresOnboardingStep = new OnboardingStep({
    title: 'Set up your postgres query',
    substeps: fromJS(postgresOnboardingSubsteps),
    condition: postgresOnboardingSubsteps[postgresOnboardingSubsteps.length - 1].nextCondition,
  })

  return {
    id: viewTabularDataQueryName,
    type: 'mongodb' as ResourceType,
    options: {
      query: `{ : { "$regex": {{textinput1.value ? textinput1.value : {$exists: true} }}, "$options": "i" } }`,
    },
    hintSequence: postgresOnboardingStep,
  }
}

const generateRestBuildingBlock = () => {
  const restQueryUrl = 'https://represent.opennorth.ca/boundary-sets'
  const restChangeResourceHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${viewTabularDataQueryName}"] .resourceSelector`,
    hintText: 'Select your api from the resource dropdown ',
    nextCondition: (_template, _model, editor) => {
      //Need to use the editor rather then the template since we want the version before it's saved
      return (
        editor.selectedDatasourceId === viewTabularDataQueryName &&
        editor.selectedResourceName !== 'REST-WithoutResource'
      )
    },
  })

  const restChangeUrlHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${viewTabularDataQueryName}"] .http-query-editor .query-scroller`,
    hintText: 'Change the URL to select from your api',
    nextCondition: (_template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      return editor.queryState.get(queryStateKey)?.get('query') !== restQueryUrl
    },
  })

  const restOnboardingSubsteps = [restChangeResourceHint, restChangeUrlHint]
  const restOnobardingStep = new OnboardingStep({
    title: 'Set up your REST resource',
    substeps: fromJS(restOnboardingSubsteps),
    condition: (template, model, _editor) => {
      const data = model.values.get(`${TABLE_ID}`).get('data')
      return data.length > 0 && data[0].domain !== 'Acton Vale, QC'
    },
  })

  return {
    id: viewTabularDataQueryName,
    type: 'restapi' as ResourceType,
    options: {
      query: 'https://represent.opennorth.ca/boundary-sets',
      type: 'GET',
    },
    hintSequence: restOnobardingStep,
  }
}

const generateGoogleSheetsBuildingBlock = () => {
  const sheetsTransformer = `const results = data
const filterBy = {{textOne.value}}
if (filterBy.length > 0 ) {
  //TODO: change filterKey to the key you want to filter based on
  const filterKey = Object.keys(results[0])[0]
  return results.filter( (result) => result[filterKey].includes(filterBy) )
} else {
  return results
}`

  const sheetsAdjustHint: OnboardingSubStep = new OnboardingSubStep({
    //For some reason this causes the bouncing box to appear at the top rather than right next to it :(
    hintNodeSelector: `[data-query="${viewTabularDataQueryName}"] .input-label[data-label="Spreadsheet"]`,
    hintText: 'Confirm all settings in the top pane',
    nextCondition: (template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      const query = editor.queryState.get(queryStateKey) as SafeAny
      const queryPlugin = template.plugins.get(viewTabularDataQueryName)?.toJS()
      return query != null && queryPlugin != null && query.get('spreadsheetId') !== queryPlugin.template.spreadsheetId
    },
  })

  const sheetsAdjustTransformerHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${viewTabularDataQueryName}"] [data-sectiontitle="Transformer"]`,
    hintText: 'adjust what field you look at from the api and what table column you filter on',
    //TODO: (eventually) test this
    nextCondition: (_template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      const queryEditorState = editor.queryState.get(queryStateKey)
      return (
        queryEditorState != null && queryEditorState.get('transformer').indexOf('Object.keys(results[0])[0]') === -1
      )
    },
  })

  const sheetsOnboardingSubsteps = [sheetsAdjustHint, sheetsAdjustTransformerHint]
  const sheetOnboardingStep = new OnboardingStep({
    title: 'Set up your REST resource',
    substeps: fromJS(sheetsOnboardingSubsteps),
    condition: (template, model, _editor) => {
      const data = model.values.get(`${TABLE_ID}`).get('data')
      return data.length > 0 && data[0].domain !== 'Acton Vale, QC'
    },
  })

  return {
    id: viewTabularDataQueryName,
    type: 'googlesheets' as ResourceType,
    options: {
      actionType: 'read',
      enableTransformer: true,
      transformer: sheetsTransformer,
    },
    hintSequence: sheetOnboardingStep,
  }
}

const generateMysqlBuildingBlock = () => {
  const postgresChangeTableHint: OnboardingSubStep = new OnboardingSubStep({
    hintNodeSelector: `[data-query="${viewTabularDataQueryName}"] .cm-evaluation-wrapper`,
    hintText: 'Confirm the table and column you want to use',
    nextCondition: (template, _model, editor) => {
      const queryStateKey = editor.queryState.keySeq().toArray()[0]
      const queryState = editor.queryState.get(queryStateKey)?.toJS()
      const queryPlugin = template.plugins.get(viewTabularDataQueryName)?.toJS()
      //Query is defined and different from the template's query
      return (
        queryState?.query != null && queryState.query !== queryPlugin?.template.query && queryState.query.length > 0
      )
    },
  })

  const mysqlOnboardingSubsteps = [postgresChangeTableHint]
  const mysqlOnboardingStep = new OnboardingStep({
    title: 'Set up your mysql query',
    substeps: fromJS(mysqlOnboardingSubsteps),
    condition: mysqlOnboardingSubsteps[mysqlOnboardingSubsteps.length - 1].nextCondition,
  })

  return {
    id: viewTabularDataQueryName,
    type: 'mysql' as ResourceType,
    options: {
      query: `select * from ${TABLE_PLACEHOLDER} where ${COLUMN_PLACEHOLDER} like {{"%" +textOne.value+ "%"}} limit 100`,
    },
    hintSequence: mysqlOnboardingStep,
  }
}*/

export default constructBuildingBlock

import { isListViewItemScope } from './isListViewItemScope'

// Merges a normal scope with a set of jsAPI functions that mirrors the shape of the scope
//
// We don't something like `lodash.merge` because it will recursively traverse query data,
// which is not what we want and is bad for performance (Some of customers have MB+ of query data)
export function extendScopeWithJsApi(scope: Record<string, unknown>, jsApi: Record<string, unknown>) {
  const extendedScope: Record<string, unknown> = {
    // First copying the top level apis,
    // ie `utils`, `mock`, `waitForCondition` etc
    ...jsApi,
  }

  Object.keys(scope).forEach((topLevelKey) => {
    const pluginScope = (scope[topLevelKey] || {}) as Record<string, unknown>
    const jsApiScope = jsApi[topLevelKey] as Record<string, unknown> | undefined

    if (typeof jsApiScope === 'object') {
      if (isListViewItemScope(pluginScope)) {
        const scope: Record<string, unknown> = {
          ...pluginScope,
        }

        extendedScope[topLevelKey] = scope

        Object.keys(jsApiScope).forEach((index) => {
          scope[index] = {
            ...(jsApiScope[index] as Object),
            ...((pluginScope[index] as Object) || {}),
          }
        })
      } else {
        extendedScope[topLevelKey] = {
          ...pluginScope,
          ...jsApiScope,
        }
      }
    } else {
      extendedScope[topLevelKey] = scope[topLevelKey]
    }
  })

  return extendedScope
}

import React, { useState } from 'react'
import { Icon } from 'components/design-system'

import './Expandable.scss'

export type ExpandableProps<T> = {
  limit: number
  data: T[]
  children: (_: T) => React.ReactElement
}

export const Expandable = <T extends any>(props: ExpandableProps<T>) => {
  const { limit, data, children: builder } = props
  const [expanded, setExpanded] = useState(false)

  let slice = data
  if (!expanded && data.length > limit) {
    slice = data.slice(0, limit)
  }

  let expando = <></>
  if (expanded) {
    expando = (
      <div className="expandable-shrinker flex items-center light-gray" onClick={(_) => setExpanded(false)}>
        Show {data.length - limit} less <Icon type="caret-up" />
      </div>
    )
  } else if (data.length > limit) {
    expando = (
      <div className="expandable-expander flex items-center light-gray" onClick={(_) => setExpanded(true)}>
        Show {data.length - limit} more <Icon type="caret-down" />
      </div>
    )
  }

  return (
    <>
      {slice.map(builder)}
      {expando}
    </>
  )
}

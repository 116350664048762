import React, { useEffect } from 'react'
import * as _ from 'lodash'
import { Checkbox, Tooltip, Icon } from 'components/design-system'
import {
  InputField,
  EditPrivilege,
  DangerZoneheader,
  sqlUnifiedResourceDefaults,
  QueryEditorModesToggle,
} from './common'
import { ResourceFormProps } from './types'
import { isLaunchedUnifiedSqlResource } from 'components/plugins/datasources/SqlQueryUnified/model'

export const databaseDefaults = {
  host: '',
  databaseName: '',
  databaseUsername: '',
  databasePassword: '',
  options: {
    warehouse: '',
    role: '',
    databaseSchema: '',
  },
}

export const databaseValidator = (resource: any) =>
  resource.displayName &&
  resource.type &&
  resource.host &&
  resource.databaseName &&
  resource.databaseUsername &&
  resource.databasePassword

const SnowflakeForm = (props: ResourceFormProps) => {
  const isUnifiedSql = isLaunchedUnifiedSqlResource(props.resource)

  useEffect(() => {
    // TODO https://linear.app/retool/issue/ENG-531/clean-up-readwrite-feature-flags
    if (props.resource.options.version === undefined && isUnifiedSql) {
      props.updateResourceOptions(sqlUnifiedResourceDefaults)
    }
  }, [])

  return (
    <>
      <hr />
      <h5 className="section-heading light-gray mb12">General</h5>
      <div className="grid-1c mb20">
        <InputField
          label="Account name"
          placeholder="Account Name"
          resourceKey="host"
          topLevel
          required
          inputAriaLabel="Account name"
          {...props}
        />
        <InputField
          label="Database name"
          placeholder="testdb"
          resourceKey="databaseName"
          topLevel
          required
          inputAriaLabel="Database name"
          {...props}
        />
        <InputField
          label="Database schema"
          placeholder="public"
          resourceKey="databaseSchema"
          inputAriaLabel="Database schema"
          {...props}
        />
        <InputField
          label="Database username"
          placeholder="retool"
          resourceKey="databaseUsername"
          required
          topLevel
          inputAriaLabel="Database username"
          {...props}
        />
        <InputField
          label="Database password"
          placeholder="••••••••"
          resourceKey="databasePassword"
          topLevel
          required
          password
          inputAriaLabel="Database password"
          {...props}
        />
        <InputField
          label="Database warehouse"
          placeholder="warehouse"
          resourceKey="warehouse"
          inputAriaLabel="Database warehouse"
          {...props}
        />
        <InputField label="User role" placeholder="PUBLIC" resourceKey="role" inputAriaLabel="User role" {...props} />
      </div>
      <DangerZoneheader />
      {!isUnifiedSql && <EditPrivilege {...props} />}
      {!props.resource.editPrivilege && (
        <div className="grid-1c mb20">
          <Checkbox
            checked={_.get(props.resource, 'options.disableServerSidePrepare')}
            onChange={(checked) => {
              props.onChangeOption('disableServerSidePrepare')(checked.target.checked)
            }}
            className="grid-offset-1"
          >
            Disable converting queries to prepared statements
            <Tooltip title="This allows you to use Javascript to dynamically generate SQL but also turns off SQL injection protection.">
              <Icon type="tooltip" className="washed-gray hover-lightest-gray ml4" />
            </Tooltip>
          </Checkbox>
          {isUnifiedSql && (
            <QueryEditorModesToggle resource={props.resource} updateResourceOptions={props.updateResourceOptions} />
          )}
        </div>
      )}
    </>
  )
}

export default {
  label: 'Snowflake',
  form: SnowflakeForm,
  defaults: {
    ...databaseDefaults,
  },
  validator: databaseValidator,
}

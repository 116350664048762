import { Children, Fragment, ReactElement, ReactNode } from 'react'

/**
 * Removes the wrapping Fragment from a React node, if present, returning the children as an array instead.
 *
 * Useful for components that depend on a flat children structure, like AntD's Menu->MenuItem.
 */
export default function removeFragmentFromNode(node: ReactNode): ReactNode {
  if (node && Children.count(node) === 1) {
    const child = Children.toArray(node)[0] as ReactElement

    if (child?.type === Fragment) {
      return Children.toArray(child.props.children)
    }
  }

  return node
}

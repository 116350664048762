import React from 'react'
import { Form, message } from 'antd'
import queryString from 'query-string'
import { withState } from 'recompose'
import ls from 'local-storage'
import { get } from 'lodash'

import { POST } from 'common/fetch'
import Modal from 'components/design-system/Modal'
import Button from 'components/standards/Button'
import FormButton from 'components/design-system/Button'

import TextInput from 'components/design-system/TextInput'
import { showEmptyModal } from 'components/standards/Modal'
import { dispatch, getState } from 'store'
import { regenerateModelAndDependencyGraph } from 'store/appModel/model'

import { OAuthForm } from './oauthForm'

import { authModalSelector, numCustomAuthResourcesInAppSelector } from 'store/selectors'

import './CustomAuthForm.scss'
import { ClientSideHttpRequestProperties, performClientSideHttpRequest } from 'common/utils'
import { closeAuthModal } from 'routes/Presentation/modules/closeAuthModal'

type CustomAuthFormProps = {
  resourceId?: number
  resourceName?: string
  environment?: string
  disabled?: boolean
  buttonText?: string
  buttonClassName?: string
  size?: 'small' | 'large'
  showDebugInfo?: boolean
  restartAuthOnEachSubmit?: boolean
  skipConsentScreen?: boolean
  onSuccess?: () => void
  onFailure?: () => void
}

const _FormModal = (props: any) => {
  const { formState, setFormState, properties } = props
  return (
    <Modal
      width={540}
      title="Login"
      destroyOnClose
      visible
      onCancel={props.onCancel}
      footer={
        <div className="footer-container">
          <FormButton className="footer-button" key="submit" type="primary" htmlType="submit" form="customAuthForm">
            Login
          </FormButton>
        </div>
      }
    >
      <Form
        id="customAuthForm"
        onSubmit={(ev) => {
          ev.preventDefault()
          props.onConfirm(formState)
        }}
      >
        {properties.map((property: { key: string; type: string }, i: number) => {
          return (
            <div className="mb8" key={property.key}>
              <div className="custom-auth-form-input-title">{property.key}</div>
              <TextInput
                autoFocus={i === 0}
                onChange={(e) => setFormState({ ...formState, [property.key]: e.target.value })}
                value={formState[property.key]}
                type={property.type}
              />
            </div>
          )
        })}
      </Form>
    </Modal>
  )
}
const FormModal = withState('formState', 'setFormState', {})(_FormModal)

class CustomAuthForm extends React.Component<CustomAuthFormProps, any> {
  oauthForm: OAuthForm | null = null

  constructor(props: any) {
    super(props)
    this.state = {
      isFetching: false,
      stepNum: 0,
      debugState: null,
    }
  }

  componentDidMount() {
    const queryParams = queryString.parse(window.location.hash)
    if (queryParams.resourceAuthCSRFState && queryParams.stepNum && typeof queryParams.stepNum === 'string') {
      window.location.hash = ''

      // From a redirect
      const storedResourceAuthCSRFState = ls.get('resourceAuthCSRFState')
      if (queryParams.resourceAuthCSRFState === storedResourceAuthCSRFState) {
        // Check for csrf before resuming auth flow

        this.startAuthentication(parseInt(queryParams.stepNum) + 1)
      }
      ls.set('resourceAuthCSRFState', '')
    } else {
      ls.set('resourceAuthCSRFState', '')
      if (this.props.skipConsentScreen) {
        this.startAuthentication(0)
      }
    }
  }

  startAuthentication = async (stepNum?: number, customScope?: {}) => {
    // eslint-disable-next-line no-console
    console.log('Beginning authorization step')
    stepNum = stepNum != null ? stepNum : this.state.stepNum
    if (stepNum && stepNum !== this.state.stepNum) {
      this.setState({ stepNum })
    }
    this.setState({ isFetching: true })

    const startingAuthFlow = stepNum === 0

    let environment = this.props.environment
    if (environment) {
      if (startingAuthFlow) {
        // If we start the flow, we want to remember the environment (staging vs production)
        // in the case that it's a redirect-based auth flow
        ls.set('_tempAuthEnv', environment)
      } else {
        // If we are restarting the authentication flow at a later step
        // we want to use the environment we used to start the flow with
        environment = ls.get('_tempAuthEnv')
      }
    }

    try {
      const body = await POST(
        '/api/resourceAuth/processStep',
        {},
        {},
        {
          sourceUri: window.location.href.split('#')[0],
          resourceId: this.props.resourceId,
          resourceName: this.props.resourceName,
          environment,
          stepNum,
          customScope,
          showDebugInfo: !!this.props.showDebugInfo,
        },
      )
      this.setState({ debugState: get(body, 'debug.scope') })
      if (body.actionRequired) {
        switch (body.type) {
          case 'redirect': {
            const state = body.state
            ls.set('resourceAuthCSRFState', state)
            window.location = body.redirectUrl
            break
          }
          case 'oauth': {
            // Use the server generated random string for the state var
            const state = body.state
            ls.set('resourceAuthCSRFState', state)
            this.oauthForm?.submit(state)
            break
          }
          case 'client_side_http_request': {
            const { res } = await performClientSideHttpRequest(body.properties as ClientSideHttpRequestProperties)

            this.startAuthentication(body.stepNum + 1, {
              [`http${body.stepNum + 1}`]: { body: res },
            })

            break
          }
          case 'form_modal': {
            // Show a modal given the server's returned properties
            const properties = body.properties
            const modal = showEmptyModal({})
            modal.update({
              content: (
                <FormModal
                  properties={properties}
                  onCancel={() => modal.destroy()}
                  onConfirm={(data: any) => {
                    this.startAuthentication(body.stepNum + 1, {
                      [`form${body.stepNum + 1}`]: data,
                    })
                    modal.destroy()
                  }}
                />
              ),
            })
            break
          }
        }
      } else {
        // re-run all queries since some of them probably failed earlier when we were unauthenticated
        dispatch(regenerateModelAndDependencyGraph())
        this.props.onSuccess?.()
        const numCustomAuthResourcesInApp = numCustomAuthResourcesInAppSelector(getState())
        // only auto-close the auth modal on success if there is just 1 custom auth resource
        if (numCustomAuthResourcesInApp === 1) {
          const modal = authModalSelector(getState())
          dispatch(closeAuthModal(modal))
        }
        message.success(body.message)
      }
    } catch (err) {
      this.setState({ stepNum: 0 })
      message.error(err.message)
      this.props.onFailure?.()
    }
    this.setState({ isFetching: false })
  }

  render() {
    const {
      resourceId,
      resourceName,
      environment,
      disabled,
      buttonText,
      showDebugInfo,
      restartAuthOnEachSubmit,
    } = this.props

    let startStepNum: number | undefined

    if (restartAuthOnEachSubmit) {
      startStepNum = 0
    }
    const buttonClassName = this.props.buttonClassName || 'login-button google-login-button'
    return (
      <div>
        <Button
          disabled={disabled}
          loading={this.state.isFetching}
          className={buttonClassName}
          onClick={() => this.startAuthentication(startStepNum)}
        >
          {buttonText || 'Authorize'}
        </Button>
        <OAuthForm
          style={{ display: 'none' }}
          ref={(form) => {
            this.oauthForm = form
          }}
          resourceId={resourceId}
          resourceName={resourceName}
          environment={environment}
          stepNum={this.state.stepNum}
        />
        {showDebugInfo && this.state.debugState && (
          <div style={{ marginTop: 20 }}>
            <p> All scopes (this only appears for developers)</p>
            <pre>{JSON.stringify(this.state.debugState, null, 2)}</pre>
          </div>
        )}
      </div>
    )
  }
}

export default CustomAuthForm

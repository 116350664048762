// ------------------------------------
// Constants
// ------------------------------------
import { triggerModelUpdate } from 'store/appModel/model'
import queryString from 'query-string'

export const LOCATION_CHANGE = 'LOCATION_CHANGE'
export const LOCATION_LOADING = 'LOCATION_LOADING'
export const LOCATION_LOADED = 'LOCATION_LOADED'

// ------------------------------------
// Actions
// ------------------------------------
export function locationChange(location = '/') {
  return {
    type: LOCATION_CHANGE,
    payload: location,
  }
}

export function locationLoading() {
  return {
    type: LOCATION_LOADING,
    payload: {},
  }
}

export function locationLoaded() {
  return {
    type: LOCATION_LOADED,
    payload: {},
  }
}

// ------------------------------------
// Specialized Action Creator
// ------------------------------------
export const updateLocation = ({ dispatch }: any) => {
  return (nextLocation: any) => {
    const hash = nextLocation.hash
    const parsedHash = queryString.parse(hash)

    setTimeout(() =>
      dispatch(
        triggerModelUpdate([
          { selector: ['urlparams', 'href'], newValue: window.location.href },
          { selector: ['urlparams', 'hash'], newValue: parsedHash },
        ]),
      ),
    )

    return dispatch(locationChange(nextLocation))
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState: any = {
  ...window.location,
  previousPathname: null,
  loaded: true,
}
export default function locationReducer(state = initialState, action: any) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...action.payload,
        loaded: state.loaded,
        previousPathname: state.pathname,
      }
    case LOCATION_LOADING:
      return {
        ...state,
        loaded: false,
      }
    case LOCATION_LOADED:
      return {
        ...state,
        loaded: true,
      }
    default:
      return state
  }
}

import { PluginNamespaceInfo } from 'common/records'
import { SafeAny } from 'common/types'

export type StandardEvaluationType =
  | 'template'
  | 'json-template'
  | 'json-template-error-check'
  | 'error-check'
  | 'function-error-check'
  | 'function'
  | 'function-no-return'
  | Repl

export type EvaluationType = StandardEvaluationType | 'callback' | 'alasql'

export interface EvalOptions {
  disallowJSON: boolean
  errorCheck: boolean
  batch: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  commonScope: any
  htmlEscapeRetoolExpressions: boolean
  namespace?: PluginNamespaceInfo
  dataSourceId?: string
}

export type StandardSandboxRequest = {
  evalType: StandardEvaluationType
  code: string
  scope: { [name: string]: SafeAny }
  requestId: number
  htmlEscapeRetoolExpressions: boolean
  dataSourceId?: string
  experimentalEvaluatorCaching: boolean
  updateSetValueDynamically: boolean
}
export type SandboxScopeResponse = {
  scope: { [name: string]: SafeAny }
  requestId: number
}

export type BatchSandboxRequest = {
  evalType: StandardEvaluationType
  code: string
  scope: { [name: string]: SafeAny }[]
  requestId: number
  batch: boolean
  commonScope: {}
  htmlEscapeRetoolExpressions: boolean
  dataSourceId?: string
  experimentalEvaluatorCaching: boolean
}

export type SandboxRequest =
  | BatchSandboxRequest
  | StandardSandboxRequest
  | AlasqlSandboxRequest
  | CallbackSandboxRequest

export type CallbackSandboxRequest = {
  evalType: 'callback'
  scope: { [name: string]: SafeAny }[]
  callbackId: string
  requestId: number
  data: unknown
  htmlEscapeRetoolExpressions: boolean
  dataSourceId?: string
  experimentalEvaluatorCaching?: boolean
}

export type AlasqlSandboxRequest = {
  evalType: 'alasql'
  requestId: number
  query: string
  parameters: unknown[]
  htmlEscapeRetoolExpressions: boolean
  experimentalEvaluatorCaching?: boolean
}

export type Repl = 'repl' | 'jsquery' | 'test'

export const RETOOL_JS_API_PLACEHOLDER = '__RETOOL_JS_API__'
export const RETOOL_JS_CALLBACK_PLACEHOLDER = '__RETOOL_JS_CALLBACK__'

import _ from 'lodash'
import { createSelector } from 'reselect'

import { RetoolState } from 'store'
import {
  FolderWithChildren,
  allFoldersSelector,
  archiveFolderSelector,
  folderTreeSelector,
  pagesWithPathsSelector,
  pathSelector,
  userSelector,
  protectedAppsEnvVarsSetSelector,
} from 'store/selectors'

import {
  allApplications,
  SpecialViewFilterMap,
  makeFilter,
  recentApplications,
  starredApplications,
  selectPages,
  HomeViewFilter,
  moduleApplications,
  protectedApplications,
  SpecialViewFilterKey,
} from 'routes/Home/filters'
import { folderFavoritesSelector, pageFavoritesSelector, recentVisitsSelector } from 'store/selectors/userSelectors'

export const isFetchingPagesForFirstTimeSelector = (state: RetoolState) =>
  state.pages.get('isFetchingPagesForFirstTime')

export const emptyRootSelector = createSelector(folderTreeSelector, (root) => {
  return _.get(root, 'children', []).length === 0 && _.get(root, 'pages', []).length === 0
})

const trimPath = (path: string, part: string) => {
  if (!path.startsWith(part)) {
    return null
  } else {
    return path.slice(part.length)
  }
}

const addTrailingSlash = (path: string) => {
  if (path.endsWith('/')) {
    return path
  }

  return `${path}/`
}

const viewKeySelector = createSelector(pathSelector, (path: string) => {
  return trimPath(path, '/views/') as SpecialViewFilterKey
})

// NB: this is where we configure how the left-sidebar is configured
export const specialViewFilterMapSelector = createSelector(
  pageFavoritesSelector,
  folderFavoritesSelector,
  recentVisitsSelector,
  archiveFolderSelector,
  folderTreeSelector,
  protectedAppsEnvVarsSetSelector,
  (
    favoritePageIds,
    favoriteFolderIds,
    recentVisits,
    archive,
    rootFolder,
    protectedAppsHasEnvVarsSet,
  ): SpecialViewFilterMap => {
    const recentVisitIds = recentVisits.map((v) => v.pageId)

    let maybeTrash
    if (archive) {
      maybeTrash = makeFilter(archive, { name: 'Trash', key: 'trash' })
    }

    let favoritedFolders: FolderWithChildren[] = []
    if (rootFolder) {
      favoritedFolders = rootFolder.children.filter((folder) => favoriteFolderIds.includes(folder.id))
    }

    return {
      all: allApplications(rootFolder),
      recent: recentApplications(recentVisitIds),
      starred: starredApplications(favoritePageIds, favoritedFolders, archive),
      modules: moduleApplications(archive),
      protected: protectedAppsHasEnvVarsSet ? protectedApplications() : undefined,
      trash: maybeTrash,
    }
  },
)

export const displayableFoldersSelector = createSelector(allFoldersSelector, archiveFolderSelector, (all, archive) =>
  all.filter((f) => f.id !== archive?.id),
)

const specialViewFilterSelector = createSelector(
  viewKeySelector,
  specialViewFilterMapSelector,
  (viewKey: SpecialViewFilterKey, specialFilters: SpecialViewFilterMap) => {
    return specialFilters[viewKey]
  },
)

const folderPathSelector = createSelector(pathSelector, (path) => {
  return trimPath(path, '/folders/')
})

const currentFolderSelector = createSelector(folderTreeSelector, folderPathSelector, (rootFolder, folderPath) => {
  if (!rootFolder) {
    return null
  }
  if (folderPath) {
    // in a folder
    const slashed = addTrailingSlash(folderPath)
    return rootFolder.children.find((f) => f.path === slashed)
  } else {
    return null
  }
})

const idExtractor = (folder: FolderWithChildren | undefined | null) => {
  if (folder) {
    return folder.id
  } else {
    return null
  }
}

export const currentFolderIdSelector = createSelector(currentFolderSelector, idExtractor)
export const rootFolderIdSelector = createSelector(folderTreeSelector, idExtractor)
export const newAppFolderIdSelector = createSelector(currentFolderIdSelector, rootFolderIdSelector, (c, r) => c || r!)

export const currentFilterSelector = createSelector(
  currentFolderSelector,
  specialViewFilterSelector,
  specialViewFilterMapSelector,
  (currentFolder, specialViewFilter, specialViewFilterMap) =>
    (currentFolder || specialViewFilter || specialViewFilterMap.all) as HomeViewFilter,
)

export const visiblePagesSelector = createSelector(
  currentFilterSelector,
  pagesWithPathsSelector,
  (currentFilter, allPages) => {
    return selectPages(currentFilter)(allPages)
  },
)

export const preloadedCSSSelector = createSelector(userSelector, (user) => {
  if (user.get('orgInfo').get('organization').applyPreloadedCSSToHomepage) {
    return user.get('orgInfo').get('organization').preloadedCSS
  }
  return null
})

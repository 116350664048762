import {
  ALL_RESOURCE_SHEETS,
  ALL_RESOURCE_TABLES,
  ALL_RESOURCES,
  BuildingBlockGeneratorInput,
  SELECTED_DATABASE_TABLE,
  SELECTED_GOOGLE_SHEET,
  SELECTED_RESOURCE,
} from 'components/BuildingBlocks/buildingBlocks'

export const allResourcesInput: BuildingBlockGeneratorInput = {
  id: SELECTED_RESOURCE,
  options: ALL_RESOURCES,
  label: 'Resource to query',
}

export const allResourceSheets: BuildingBlockGeneratorInput = {
  id: SELECTED_GOOGLE_SHEET,
  options: ALL_RESOURCE_SHEETS,
  label: 'Sheet to read from',
  conditionalShow: (resourceType) => {
    return resourceType === 'googlesheets'
  },
}

export const allResourceTables: BuildingBlockGeneratorInput = {
  id: SELECTED_DATABASE_TABLE,
  options: ALL_RESOURCE_TABLES,
  label: 'Database table to query',
}

import React from 'react'
import { ResourceFormProps } from '../resources/types'
import APIKeyField from '../resources/components/APIKeyField'
import { ResourceApiKeyAuth } from './generator-types'

/**
 * Resource Form for an API Key authentication scheme (mostly useful for REST resources)
 */
function APIKeyView(props: ResourceFormProps & { auth: ResourceApiKeyAuth }) {
  const { auth, ...restOfProps } = props
  return (
    <div className="grid-1c mb12">
      <APIKeyField
        {...restOfProps}
        fieldName="api_key"
        fieldLabel={auth.options.label}
        fieldDescription={auth.options.description}
        fieldRequired={true}
      />
    </div>
  )
}

export default APIKeyView

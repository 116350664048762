// eslint-disable-next-line @typescript-eslint/no-unused-vars
import cookies from 'js-cookie'

// Disable the no-restricted-imports for the `isomorphic-fetch` package here (but nowhere
// else) to guarantee it is only used behind its facade function(s)
// eslint-disable-next-line no-restricted-imports, @typescript-eslint/no-unused-vars
import fetch from 'isomorphic-fetch'

/* eslint-disable no-restricted-imports */
// Disable the no-restricted-imports for the `redux-api-middleware` package here (but nowhere
// else) as we deprecate it.
// Once we no longer need to import it here we can safely remove the package (and the lint
// rule disallowing its import)
import {
  apiMiddleware as apiMiddleware_DEPRECATE,
  CALL_API as CALL_API_DEPRECATE,
  CallAPIFn as CallAPIFn_DEPRECATE,
  CallAPIAction as CallAPIAction_DEPRECATE,
  CallAPIResult as CallAPIResult_DEPRECATE,
  APIResultT as APIResultT_DEPRECATE,
  CallAPISuccess as CallAPISuccess_DEPRECATE,
  CallAPIParams as CallAPIParams_DEPRECATE,
} from 'redux-api-middleware'
import CALL_API_CONST_DEPRECATE from 'redux-api-middleware/lib/CALL_API'
/* eslint-enable no-restricted-imports */

export {
  apiMiddleware_DEPRECATE,
  CALL_API_DEPRECATE,
  CALL_API_CONST_DEPRECATE,
  CallAPIFn_DEPRECATE,
  CallAPIAction_DEPRECATE,
  CallAPIResult_DEPRECATE,
  APIResultT_DEPRECATE,
  CallAPISuccess_DEPRECATE,
  CallAPIParams_DEPRECATE,
}

import * as espree from 'espree'

/**
 * Return the AST of the provided expression
 * @param expression Any JS Expression
 * @param options ESPree options
 */
export default function parseJSExpression(expression: string, options?: { [s: string]: unknown }) {
  return espree.parse(expression, { ecmaVersion: 11, ...options })
}

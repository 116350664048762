import moment from 'moment'
import cookies from 'js-cookie'
import { clonedTemplateInfoFromUrl, isClonedTemplateASupportedSignupTheme } from 'common/utils'
import { findUrlSearchParam } from 'common/polyfills'
import {
  ONBOARDING_URL_QUERY_LIBRARY,
  ONBOARDING_URL_FIREBASE,
  ONBOARDING_PREFIX_CLONED_TEMPLATE,
} from 'retoolConstants'
import { SafeAny } from 'common/types'

export const setCookies = () => {
  setClonedTemplateIdCookie()
  setOnboardingRedirectCookie()
}

export const setCookie = (key: string, value: string) => {
  let options: SafeAny = {
    expires: moment().add(5, 'm').toDate(),
  }
  if (MAIN_DOMAIN) {
    options = { ...options, domain: `.${MAIN_DOMAIN}` }
  }

  cookies.set(key, value, options)
}

const setClonedTemplateIdCookie = () => {
  // the template clone flow relies on the templateId cookie, which is normally set by
  // the marketing site. but now the marketing site is on retool.com, while the retool app is on
  // tryretool.com. so we manually set the templateId cookie here
  // we can remove this once marketing site and retool app are guaranteed to be on the same domain

  const { clonedTemplateId, demoResourceOptionOverrides } = clonedTemplateInfoFromUrl(window.location.search)
  if (clonedTemplateId) {
    setCookie('clonedTemplateId', clonedTemplateId)
    if (demoResourceOptionOverrides) {
      setCookie('base64TemplateDemoResourceOptionOverrides', demoResourceOptionOverrides)
    }
  }
}

const setOnboardingRedirectCookie = () => {
  const nextUrl = findUrlSearchParam('next')

  if (nextUrl === `/${ONBOARDING_URL_QUERY_LIBRARY}`) {
    setCookie('onboardingUrl', ONBOARDING_URL_QUERY_LIBRARY)
  } else if (nextUrl === `/${ONBOARDING_URL_FIREBASE}`) {
    setCookie('onboardingUrl', ONBOARDING_URL_FIREBASE)
  }

  // set this cookie if there is a clonedTemplateId and it is a supportedSignupTheme
  const { clonedTemplateId } = clonedTemplateInfoFromUrl(window.location.search)

  if (clonedTemplateId && isClonedTemplateASupportedSignupTheme(clonedTemplateId)) {
    setCookie('onboardingUrl', `${ONBOARDING_PREFIX_CLONED_TEMPLATE}/${clonedTemplateId}`)
  }
}

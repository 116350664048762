import React from 'react'
import { Alert, Spin } from 'antd'
import ConfirmResetPasswordForm from './../forms/ConfirmResetPasswordForm'
import AuthRetoolLogo from '../components/AuthRetoolLogo'

interface ConfirmResetPasswordPageProps {
  error: string
  isFetching: boolean
  location: any // <-- improve type after refactoring `location.ts & upgrading react-router`
  restrictedDomain: string
  disableUserPassLogin: boolean
}

const ConfirmResetPasswordPage = (props: ConfirmResetPasswordPageProps) => {
  const { error, isFetching, location, restrictedDomain, disableUserPassLogin } = props

  if (restrictedDomain || disableUserPassLogin) {
    return null
  }

  return (
    <div>
      <div className="body-inner">
        <div className="auth-container">
          <div className="auth-form-container">
            <AuthRetoolLogo className="mb24" />
            <h6 className="mb12">Finish resetting your password</h6>
            <Spin spinning={isFetching}>
              <ConfirmResetPasswordForm location={location} />
              {error && (
                <Alert message={error} type="error" style={{ margin: 'auto', marginBottom: 15, marginTop: 20 }} />
              )}
            </Spin>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmResetPasswordPage

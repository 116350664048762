import { createSelector } from 'reselect'
import keyBy from 'lodash/keyBy'

import { onEmbeddedPage } from 'common/utils'
import { organizationSelector, embeddedSelector, appTemplateSelector } from 'store/selectors'

import { RETOOL_DEFAULT_THEME_ID } from './constants'

export const orgAppThemesSelector = createSelector(organizationSelector, (org) => org.appThemes)

export const appThemesSelector = createSelector(orgAppThemesSelector, embeddedSelector, (orgAppThemes, embedded) => {
  if (embedded && onEmbeddedPage()) {
    return embedded.appThemes
  }

  return orgAppThemes ?? []
})
export const appThemesMapSelector = createSelector(appThemesSelector, (themes) => keyBy(themes, 'id'))
export const defaultAppThemeIdSelector = createSelector(organizationSelector, embeddedSelector, (org, embedded) => {
  if (embedded && onEmbeddedPage()) {
    return embedded.defaultAppThemeId
  }

  return org.defaultAppThemeId
})

export const appThemeIdSelector = createSelector(
  appTemplateSelector,
  defaultAppThemeIdSelector,
  appThemesMapSelector,
  (appTemplate, defaultAppThemeId, appThemesMap) => {
    const { appThemeId } = appTemplate

    // unset, or a deleted theme, default to the organization's default theme
    if (appThemeId == null || (appThemeId > RETOOL_DEFAULT_THEME_ID && !appThemesMap[appThemeId])) {
      return defaultAppThemeId
    }

    return appThemeId
  },
)

export const globalStylesSelector = createSelector(appThemesMapSelector, appThemeIdSelector, (themes, appThemeId) => {
  if (appThemeId != null) {
    return themes[appThemeId]?.theme
  }
})

import React from 'react'
import { Alert } from 'components/design-system'
import './../containers/AuthContainer.scss'

interface ErrorDisplayProps {
  errors: string[]
  passwordsMatch: boolean
  passwordGoodLength?: boolean
}

const ErrorDisplay = (props: ErrorDisplayProps) => {
  const { errors, passwordsMatch, passwordGoodLength } = props
  return (
    <div className="error-container">
      {errors.slice(0, 2).map((k: string, index: number) => (
        <Alert message={k} type="error" key={index} />
      ))}
      {passwordsMatch ? '' : <Alert message="New passwords must match" type="error" />}
      {passwordGoodLength ? '' : <Alert message="Passwords must be at least 8 characters" type="error" />}
    </div>
  )
}

export default ErrorDisplay

import React, { createContext, FC, useContext, useMemo } from 'react'
import { UnknownModel } from 'components/plugins'
import { TypedMap } from 'common/utils/immutable'

type DocsContextValue = { model: UnknownModel }

const DocsContext = createContext<{ model: UnknownModel } | undefined>(undefined)

export const DocsProvider: FC<DocsContextValue> = ({ children, model }) => {
  const value = useMemo(() => ({ model }), [model])
  return <DocsContext.Provider value={value}>{children}</DocsContext.Provider>
}

export const useDocsModel = <T extends {}>(): TypedMap<T> | undefined =>
  useContext(DocsContext)?.model as TypedMap<T> | undefined

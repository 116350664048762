import React from 'react'
import { Checkbox } from 'components/design-system'
import { AWSRegionDropdown, InputField, ARNInputField } from './common'
import { ResourceFormProps } from './types'

type LambdaFormOptions = {
  amazon_aws_region: string
  amazon_access_key_id: string
  amazon_secret_access_key: string
  assumeRole: string
  authWithIAM: boolean
}

type LambdaFormFields = {
  options: LambdaFormOptions
}

const defaultOptions: LambdaFormFields = {
  options: {
    amazon_aws_region: '',
    amazon_access_key_id: '',
    amazon_secret_access_key: '',
    assumeRole: '',
    authWithIAM: false,
  },
}

const LambdaForm = (props: ResourceFormProps) => (
  <div className="grid-1c mb20">
    <div className="grid-offset-1">
      Enter your Lambda details below. You can find instructions on where to find these{' '}
      <a
        href="https://docs.aws.amazon.com/general/latest/gr/aws-sec-cred-types.html#access-keys-and-secret-access-keys"
        target="_blank"
        rel="noopener"
      >
        in the Lambda docs.
      </a>{' '}
      You can find examples of how to connect Lambda to Retool{' '}
      <a href="https://docs.retool.com/docs/lambda-integration" target="_blank" rel="noopener">
        in the Retool docs.
      </a>
    </div>
    <AWSRegionDropdown {...props} />
    {(!MAIN_DOMAIN || __DEV__) && (
      <Checkbox
        checked={props.resource.options.authWithIAM}
        onChange={(checked) => {
          props.onChangeOption('authWithIAM')(checked.target.checked)
        }}
        className="grid-offset-1"
      >
        Connect using underlying AWS IAM Role
      </Checkbox>
    )}
    {!props.resource.options.authWithIAM && (
      <>
        <InputField label="Access Key ID" resourceKey="amazon_access_key_id" required {...props} />
        <InputField label="Secret Access Key ID" resourceKey="amazon_secret_access_key" required {...props} />
      </>
    )}
    <ARNInputField {...props} />
  </div>
)

export default {
  label: 'Lambda',
  form: LambdaForm,
  defaults: {
    options: defaultOptions,
  },
  validator: ({ options }: { options: LambdaFormOptions }) =>
    (options.authWithIAM || (options.amazon_access_key_id && options.amazon_secret_access_key)) &&
    options.amazon_aws_region,
}

import React, { useEffect } from 'react'
import { Input, Select as AntdSelect } from 'antd'
import Dropdown from 'components/standards/Dropdown'
import { Checkbox, Tooltip, Icon, Select } from 'components/design-system'
import {
  DangerZoneheader,
  sqlUnifiedResourceDefaults,
  QueryEditorModesToggle,
  EditPrivilege,
  SERVICE_ACCOUNT_PLACEHOLDER,
} from './common'
import { ResourceFormProps } from '../resources/types'
import { isLaunchedUnifiedSqlResource } from 'components/plugins/datasources/SqlQueryUnified/model'

import './bigquery.scss'

// https://cloud.google.com/bigquery/docs/locations
const BIG_QUERY_LOCATIONS = [
  'us',
  'eu',
  'us-east1',
  'us-east4',
  'us-west1',
  'us-west2',
  'us-west3',
  'us-west4',
  'northamerica-northeast1',
  'southamerica-east1',
  'europe-north1',
  'europe-west1',
  'europe-west2',
  'europe-west3',
  'europe-west4',
  'europe-west6',
  'asia-east1',
  'asia-east2',
  'asia-northeast1',
  'asia-northeast2',
  'asia-northeast3',
  'asia-south1',
  'asia-southeast1',
  'asia-southeast2',
  'australia-southeast1',
]

const BigQueryForm = (props: ResourceFormProps) => {
  const isUnifiedSql = isLaunchedUnifiedSqlResource(props.resource)

  useEffect(() => {
    // TODO https://linear.app/retool/issue/ENG-531/clean-up-readwrite-feature-flags
    if (props.resource.options.version === undefined && isUnifiedSql) {
      props.updateResourceOptions(sqlUnifiedResourceDefaults)
    }
  }, [])

  return (
    <>
      <hr />
      <h5 className="section-heading light-gray mb12">General</h5>
      {!isUnifiedSql && <EditPrivilege {...props} />}
      <div className="grid-1c mb20 form-content-bigquery">
        <label className="input-label">Authentication</label>
        <Dropdown
          defaultValue="serviceAccount"
          value={props.resource.options.authentication}
          onChange={props.onChangeOption('authentication')}
        >
          <AntdSelect.Option value="serviceAccount">Service Account</AntdSelect.Option>
        </Dropdown>
        <label className="input-label">
          The private key associated with a Service Account with BigQuery privileges
        </label>
        <div className="light-gray">
          <Input.TextArea
            value={props.resource.options.serviceAccountKey}
            onChange={props.onChangeOption('serviceAccountKey', { handleEvent: true })}
            autosize={{ minRows: 12, maxRows: 12 }}
            placeholder={SERVICE_ACCOUNT_PLACEHOLDER}
          />
          <p>
            See <a href="https://cloud.google.com/docs/authentication/getting-started"> here </a> for documentation on
            how to obtain this key. Please use the roles and BigQuery Data Viewer and BigQuery User.
          </p>
        </div>
        <label className="input-label" htmlFor="bigquery-region-qualifier">
          Region qualifier
        </label>
        <div className="light-gray">
          <Select
            value={props.resource.options.regionQualifier}
            onChange={(val) => {
              props.onChangeOption('regionQualifier', { handleEvent: false })(val)
            }}
            placeholder="region-us"
            id="bigquery-region-qualifier"
            aria-labelledby="bigquery-region-qualifier-extra-explanation"
          >
            {BIG_QUERY_LOCATIONS.map((regionCode) => (
              <Select.Option key={regionCode} value={`region-${regionCode}`}>
                {regionCode}
              </Select.Option>
            ))}
          </Select>
          <p className="mt4" id="bigquery-region-qualifier-extra-explanation">
            If your dataset is located outside the US, Retool requries your dataset’s location in order to fetch your{' '}
            <a href="https://cloud.google.com/bigquery/docs/information-schema-intro">dataset's metadata</a> to power
            query autocomplete. If you leave this blank, this defaults to <span className="monospace">region-us</span>.
            See <a href="https://cloud.google.com/bigquery/docs/locations">Google’s documentation</a> for more
            information on dataset locations.
          </p>
        </div>
      </div>
      <DangerZoneheader />
      <div className="grid-1c mb12">
        {!props.resource.editPrivilege && (
          <Checkbox
            checked={props.resource.options.disableServerSidePrepare}
            onChange={(checked) => {
              props.onChangeOption('disableServerSidePrepare')(checked.target.checked)
            }}
            className="grid-offset-1"
          >
            Disable converting queries to prepared statements
            <Tooltip title="This allows you to use Javascript to dynamically generate SQL but also turns off SQL injection protection.">
              <Icon type="tooltip" className="washed-gray hover-lightest-gray ml4" />
            </Tooltip>
          </Checkbox>
        )}
        {isUnifiedSql && (
          <QueryEditorModesToggle resource={props.resource} updateResourceOptions={props.updateResourceOptions} />
        )}
      </div>
    </>
  )
}

type BigQueryResource = { options: { serviceAccountKey: string; authentication: 'serviceAccount' } }

export default {
  label: 'Big Query',
  form: BigQueryForm,
  defaults: { options: { authentication: 'serviceAccount' } },
  validator: ({ options }: BigQueryResource) => {
    if (options.authentication === 'serviceAccount') {
      return !!options.serviceAccountKey
    }
    return false
  },
}

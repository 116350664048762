import React, { useState, useEffect } from 'react'
import { Checkbox, Icon, Button, Alert, Tooltip, TextInput } from 'components/design-system'
import * as CustomAuth from './CustomAuth'
import * as url from 'url'
import { connect } from 'react-redux'
import { testCheckQueryAuth, AuthRequiredResourcesType } from 'routes/Resources/modules/resources'
import { PlainResource, PlainResourceOptions } from 'common/records'
import _ from 'lodash'
import { SafeAny } from 'common/types'
import './AuthTriggerInput.scss'
import { CustomAuthStep } from '../types'

const defaultVerifySessionAction = {
  query: '',
  method: 'GET',
  type: 'http_request',
  runWhenModelUpdates: false,
  bodyType: 'raw',
}

type TestCheckQueryAuthType = {
  testCheckQueryAuth: (
    resourceName: string,
    environment: 'staging' | 'production',
  ) => { authRequiredResources: AuthRequiredResourcesType[]; debugLog: { [resourceId: string]: string[] } }
}

enum TestResultState {
  NOT_STARTED,
  IN_PROGRESS,
  SHOW_AUTH_MODAL,
  ALREADY_AUTHENTICATED,
  ATTEMPT_AUTHENTICATION_WITHOUT_PROMPT,
}

const MessageForTestResult: {
  [test in TestResultState]: { title: string; infoAuthUrl: string; infoTimeBasedCheck: string }
} = {
  [TestResultState.NOT_STARTED]: { title: '', infoAuthUrl: '', infoTimeBasedCheck: '' },
  [TestResultState.IN_PROGRESS]: { title: '', infoAuthUrl: '', infoTimeBasedCheck: '' },
  [TestResultState.ALREADY_AUTHENTICATED]: {
    title: 'Auth modal will not appear (you are already authenticated)',
    infoAuthUrl: 'A 2xx response was received from your endpoint which indicates that you are already authenticated',
    infoTimeBasedCheck:
      'This authentication flow was last run within the allowed threshold, which indicates that you are already authenticated',
  },
  [TestResultState.SHOW_AUTH_MODAL]: {
    title: 'Auth modal will appear (you are not authenticated)',
    infoAuthUrl:
      "A non-2xx response was received from your endpoint which indicates that you are not authenticated. When you open an app with this resource, you'll be prompted to authenticate with a modal",
    infoTimeBasedCheck:
      "This authentication flow has not been run within the allowed threshold, which indicates that you are not authenticated. When you open an app with this resource, you'll be prompted to authenticate with a modal",
  },
  [TestResultState.ATTEMPT_AUTHENTICATION_WITHOUT_PROMPT]: {
    title: 'Retool will attempt auth automatically (you are not authenticated)',
    infoAuthUrl:
      'A non-2xx response was received from your endpoint which indicates that you are not authenticated. When you open an app with this resource, Retool will attempt to authenticate you automatically',
    infoTimeBasedCheck:
      'This authentication flow has not been run within the allowed threshold, which indicates that you are not authenticated. When you open an app with this resource, Retool will attempt to authenticate you automatically',
  },
}

type TestAuthTriggerProps = {
  resource: PlainResource
  authTriggerType: 'time_based_expiration' | 'login_test_url' | 'none'
} & TestCheckQueryAuthType

const TestAuthTrigger = (props: TestAuthTriggerProps) => {
  const { resource, authTriggerType } = props
  const [debugLogMessage, setDebugLogMessage] = useState('')

  const handleTestResult = (authRequiredResources: AuthRequiredResourcesType[]) => {
    let newTestResult: TestResultState
    if (authRequiredResources.length === 0) {
      newTestResult = TestResultState.ALREADY_AUTHENTICATED
    } else {
      if (authRequiredResources.length > 1) {
        // eslint-disable-next-line no-console
        console.error(
          'This should not happen - more than one unauthenticated resource returned when checking a single resource',
        )
        return
      }
      const [authRequiredResource] = authRequiredResources
      const attemptLogin = authRequiredResource?.options?.attemptLogin

      newTestResult = attemptLogin
        ? TestResultState.ATTEMPT_AUTHENTICATION_WITHOUT_PROMPT
        : TestResultState.SHOW_AUTH_MODAL
    }
    setTestResult(newTestResult)
  }

  const [testResult, setTestResult] = useState(TestResultState.NOT_STARTED)

  useEffect(() => {
    // Reset the test result whenever the auth trigger type changes
    setTestResult(TestResultState.NOT_STARTED)
  }, [authTriggerType])

  return (
    <>
      <div>
        <Button
          loading={TestResultState.IN_PROGRESS === testResult}
          type="default"
          onClick={async () => {
            setTestResult(TestResultState.IN_PROGRESS)
            const { authRequiredResources, debugLog } = await props.testCheckQueryAuth(
              resource.name,
              resource.environment,
            )
            handleTestResult(authRequiredResources)
            setDebugLogMessage((debugLog[resource.id] || []).join('. '))
          }}
        >
          {`Test ${testResult !== TestResultState.NOT_STARTED ? 'again' : ''} with current credentials`}
        </Button>
      </div>
      {testResult !== TestResultState.NOT_STARTED && testResult !== TestResultState.IN_PROGRESS && (
        <>
          <div />
          <Alert
            closable
            onClose={() => setTestResult(TestResultState.NOT_STARTED)}
            type={testResult === TestResultState.ALREADY_AUTHENTICATED ? 'success' : 'info'}
            message={
              <div className="flex fd-row items-center">
                <div className="fw-600">{MessageForTestResult[testResult].title}</div>
                <Tooltip
                  title={
                    MessageForTestResult[testResult][
                      authTriggerType === 'time_based_expiration' ? 'infoTimeBasedCheck' : 'infoAuthUrl'
                    ]
                  }
                >
                  <Icon type="tooltip" className="ml4" />
                </Tooltip>
              </div>
            }
            description={debugLogMessage}
          />
        </>
      )}
    </>
  )
}

export const transformHeadersForEditor = (headers: [string, string][]): string | undefined => {
  if (headers) {
    const entries = _.values(headers)
    const transformed = entries.map(([key, value]) => {
      return { key, value }
    })
    return JSON.stringify(transformed)
  }
  return undefined
}

type AuthTriggerInputPropsType = {
  resource: PlainResource
  onChangeOption: (key: string, options?: PlainResourceOptions) => (value: SafeAny) => void
  hideToggle?: boolean
  updateResourceOptions: (resource: PlainResourceOptions) => void
  // time_based_expiration will run auth flow once the auth flow has not been run in x seconds
  // (x is provided by customer)
  // login_test_url check auth status by calling an endpoint provided by the customer
  authTriggerType: 'time_based_expiration' | 'login_test_url' | 'none'
} & TestCheckQueryAuthType

const AuthTriggerInput = (props: AuthTriggerInputPropsType) => {
  const { resource, authTriggerType, testCheckQueryAuth } = props

  const joinAndCollapse = (sep: string, l: string, r: string): string => {
    if (l.endsWith(sep)) {
      if (r.startsWith(sep)) {
        return l + r.slice(sep.length)
      }
      return l + r
    } else {
      if (r.startsWith(sep)) {
        return l + r
      }
      return l + sep + r
    }
  }

  const introduceParametersForEditor = (base: string, params: [string, string][]): string => {
    const parsed = url.parse(base)
    let separator = '?'
    if (parsed.search) {
      separator = '&'
    } else {
      // NB: makes += not result in `toString`ing a `null`
      parsed.search = ''
    }
    for (const param of params) {
      parsed.search += `${separator + param[0]}=${param[1]}`
      separator = '&'
    }
    return url.format(parsed)
  }

  let { verify_session_action_enabled, verify_session_action } = props.resource.options
  const { verify_session_url, baseURL } = props.resource.options

  // TODO remove after migration
  if (verify_session_action_enabled === undefined) {
    verify_session_action_enabled = !!verify_session_action || !!verify_session_url
    props.onChangeOption('verify_session_action_enabled')(verify_session_action_enabled)
  }

  // NB: performs migration of `url` to `action` if one is present
  // TODO: remove this once migration is committed
  if (!verify_session_action) {
    if (verify_session_url) {
      const verifySessionAbsoluteBase = joinAndCollapse('/', baseURL as string, verify_session_url)

      const verifySessionAbsoluteWithParams = introduceParametersForEditor(
        verifySessionAbsoluteBase,
        props.resource.options.urlparams as [string, string][],
      )

      verify_session_action = {
        query: verifySessionAbsoluteWithParams,
        method: 'GET',
        type: 'http_request',
        runWhenModelUpdates: false,
        bodyType: 'raw',
        headers: transformHeadersForEditor(props.resource.options.headers as [string, string][]),
        // NB: though `body` can be defined in the resource,
        // because the migration to "configurable API request"
        // is with a `GET`, this can safely be ignored
      }
    } else {
      verify_session_action = defaultVerifySessionAction
    }
  }

  const checkbox = (
    <Checkbox
      checked={verify_session_action_enabled}
      onChange={(checked) => {
        if (checked.target.checked) {
          props.onChangeOption('verify_session_action_enabled')(true)
          if (!verify_session_action) {
            props.onChangeOption('verify_session_action')(defaultVerifySessionAction)
          }
        } else {
          props.onChangeOption('verify_session_action_enabled')(false)
        }
      }}
      className="grid-offset-1"
    >
      Enable an auth verification endpoint
    </Checkbox>
  )
  const isResourceCreated = !!resource.environment

  return (
    <>
      {!props.hideToggle && checkbox}
      <div />
      {authTriggerType !== 'none' && (
        <>
          <a
            className="gray link fs-13 flex items-center"
            href="https://docs.retool.com/docs/custom-api-authentication#7-using-this-resource-in-an-app"
            rel="noopener noreferrer"
            target="_blank"
          >
            Auth trigger docs <Icon type="link" className="blue ml4" />
          </a>

          <div className="custom-auth-step grid-offset-1">
            <div className="custom-auth-step-form grid-1c mt12">
              {authTriggerType === 'login_test_url' && (
                <CustomAuth.RESTEditor
                  restApiSenderType="server"
                  step={verify_session_action as CustomAuthStep}
                  onChange={(edit: { [k: string]: string }) => {
                    const edited = { ...verify_session_action, ...edit }
                    props.updateResourceOptions({
                      verify_session_action: edited,
                    })
                  }}
                />
              )}
              {authTriggerType === 'time_based_expiration' && (
                <>
                  <label className="input-label">
                    <span>
                      <span className="light-red mr4">*</span>
                      Re-run auth only once this many seconds have elapsed
                    </span>
                  </label>
                  <TextInput
                    className="nofs"
                    type="text"
                    placeholder="e.g. 3600 or someCustomAuthDefinedVariable"
                    value={props.resource.options.verify_session_after_custom_expiry_action?.expiryTimeInSeconds || ''}
                    onChange={(event) => {
                      props.updateResourceOptions({
                        verify_session_after_custom_expiry_action: { expiryTimeInSeconds: event.target.value || '' },
                      })
                    }}
                  />
                </>
              )}
              {isResourceCreated && (
                <>
                  <div />
                  <TestAuthTrigger
                    resource={resource}
                    testCheckQueryAuth={testCheckQueryAuth}
                    authTriggerType={authTriggerType}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}

const mapDispatchToProps = {
  testCheckQueryAuth,
}

export default connect(null, mapDispatchToProps)(AuthTriggerInput)

import React, { useState } from 'react'
import { range } from 'lodash'
import classNames from 'classnames'
import cookies from 'js-cookie'

import OnboardingPulse from './OnboardingPulse'
import { Icon, Button } from '../design-system'

import './editorTutorial.scss'

type TutorialStage = 0 | 1 | 2 | 3
type StageInfo = {
  title: string
  description: string
  cssSelector: string
  style: React.CSSProperties
}

const stageInfo: StageInfo[] = [
  {
    title: 'These are components',
    description: 'You can drag and drop them from the right sidebar.',
    cssSelector: '.editor-form-bottom-container',
    style: { top: 425, left: 4 },
  },
  {
    title: 'This is a Table component',
    description: "It lets you display and edit data, and it's backed by the data from a query.",
    cssSelector: '.ReactTable',
    style: { top: 8 },
  },
  {
    title: 'These are queries',
    description: 'They power your app via reads, writes, updates, and anything else you need.',
    cssSelector: '.query-editor__tab-selected',
    style: { top: 8, left: '50%' },
  },
  {
    title: 'Click "play" for presentation mode',
    description: "You're currently in edit mode. Click the play button to see your app as an end user would.",
    cssSelector: '.navbar__run-button',
    style: { top: 25 },
  },
]

const EditorTutorial = () => {
  const [currentStage, setCurrentStage] = useState<TutorialStage>(0)
  const [tutorialComplete, setTutorialComplete] = useState(false)

  const totalStages = stageInfo.length - 1

  const goToPreviousStage = () => {
    setCurrentStage((prev) => Math.max(0, prev - 1) as TutorialStage)
  }

  const goToNextStage = () => {
    setCurrentStage((prev) => {
      if (prev === totalStages) {
        cookies.remove('onboardingUrl')
        setTutorialComplete(true)
        return prev
      }
      return (prev + 1) as TutorialStage
    })
  }

  if (tutorialComplete) {
    return null
  }

  const isFinalStage = currentStage === totalStages
  const info = stageInfo[currentStage]
  const style = info.style

  return (
    <OnboardingPulse
      selector={info.cssSelector}
      style={style}
      popoverContent={
        <div className="editor-tutorial">
          <div className="flex items-center mb12">
            <div className="opacity-80 mr20">
              {currentStage + 1}/{stageInfo.length}
            </div>
            {range(0, stageInfo.length).map((i) => (
              <div
                className={classNames('editor-tutorial__spacer', {
                  'editor-tutorial__spacer-active': i <= currentStage,
                })}
                key={i}
              />
            ))}
          </div>
          <div className="fw-600 fs-14 mb12">{info.title}</div>
          <div className="fs-13 opacity-80 mb20">{info.description}</div>
          <div className="flex justify-between items-center">
            {currentStage !== 0 && (
              <Button type="link" className="white opacity-80 editor-tutorial__prev" onClick={goToPreviousStage}>
                <Icon type="arrow-right" className="rotate-180 mr4" />
                Prev
              </Button>
            )}
            <Button type="primary" className="white" data-testid="tutorial-next" onClick={goToNextStage}>
              {isFinalStage ? 'Got it' : 'Next'}
              {!isFinalStage && <Icon type="arrow-right" className="ml4" />}
            </Button>
          </div>
        </div>
      }
      popoverProps={{
        overlayClassName: 'editor-tutorial__popover',
        placement: isFinalStage ? 'bottomLeft' : 'bottom',
      }}
    />
  )
}

export default EditorTutorial

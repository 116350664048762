import { debounce, get } from 'lodash'
import { LICENSE_TYPE_LOCAL_TRIAL, RETOOL_VERSION, LICENSE_TYPE_TRIAL } from 'retoolConstants'
import { getState, RetoolState } from 'store'
import { configureScope } from '@sentry/browser'
import { isGroupAdminSelector } from 'store/selectors'

type SimpleHash = { [key: string]: unknown }
type AnalyticsEvent = {
  context: SimpleHash
  event: string
  properties: SimpleHash
  sid: string
  timestamp: number
}

// Replace 'localhost:4000' with 'http://p.retool-local.com' to test login flow locally.
// Same domain is required to confirm cookies are passed correctly.
const ANALYTICS_HOST = __DEV__
  ? 'http://localhost:4000'
  : window.MAIN_DOMAIN
  ? `https://p.${window.MAIN_DOMAIN}`
  : 'https://p.tryretool.com'

function getContext(state: RetoolState): {} {
  const org = state.user.getIn(['orgInfo', 'organization'])
  return {
    app: {
      onPremise: !MAIN_DOMAIN,
      version: RETOOL_VERSION,

      // context.app.name is important for our GA analytics
      // see https://segment.com/docs/destinations/google-analytics/#page-screen
      name: 'retoolApp',
    },
    traits: {
      onPremise: !MAIN_DOMAIN,
      planTrial: isTrialPlan(state),
      planExpiryDate: org.trialExpiryDate,
      planName: get(org.plan, ['name'], 'Pro Plan'),
      planStripeId: org.stripePlanId,
      planMinSeats: get(org.plan, ['minSeats'], 0),
      planGrandfathered: get(org.plan, ['grandfathered'], !org.trialExpiryDate),
    },
    screen: {
      width: window.screen.width,
      height: window.screen.height,
      density: window.devicePixelRatio,
    },
    page: {
      ...JSON.parse(JSON.stringify(window.location)),
      referrer: document.referrer,
    },
    groupId: get(org, ['sid']),
  }
}
const analyticsBuffer: AnalyticsEvent[] = []

function flushAnalyticsBuffer(): {} {
  const bufferCopy = analyticsBuffer.slice(0)
  analyticsBuffer.splice(0, analyticsBuffer.length)
  return fetch(`${ANALYTICS_HOST}/v2/p`, {
    method: 'POST',
    body: JSON.stringify(bufferCopy),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
}
const debouncedFlushAnalyticsBuffer = debounce(flushAnalyticsBuffer, 1000)

export async function retoolAnalyticsTrack(
  eventName: string,
  data: SimpleHash = {},
  options?: {
    sid?: string | null
    flush?: boolean
    sendInAirgapped?: boolean
  },
): Promise<void> {
  if (__AIRGAPPED__ && !options?.sendInAirgapped) {
    return
  }

  try {
    const state = getState()
    const org = state.user.getIn(['orgInfo', 'organization'])
    const orgSid = get(org, ['sid'])
    const email = state.user.getIn(['user', 'email'])

    const context = getContext(state)

    const payload: AnalyticsEvent = {
      sid: state.user.getIn(['user', 'sid']) || options?.sid,
      event: eventName,
      properties: {
        ...data,
        orgSid,
        page: {
          pageName: state.pages.get('pageName'),
          pageUuid: state.pages.get('pageUuid'),
          protected: !!state.pages.get('pageProtected'),
          onBranch: !!state.pages.get('pageBranchId'),
        },
        email,
      },
      timestamp: Date.now(),
      context,
    }

    analyticsBuffer.push(payload)
    if (options?.flush) {
      await flushAnalyticsBuffer()
    } else {
      debouncedFlushAnalyticsBuffer()
    }
  } catch (err) {
    // Do nothing.
  }
}

export function retoolAnalyticsIdentify(
  additionalTraits = {},
  options?: { sendInAirgapped?: boolean }, // Options are here to allow bypassing __AIRGAPPED__ in tests
): void {
  if (__AIRGAPPED__ && !options?.sendInAirgapped) {
    return
  }

  try {
    const state = getState()
    const org = state.user.getIn(['orgInfo', 'organization'])
    const orgSid = get(org, ['sid'])
    const orgName = get(org, ['name'])

    const context = getContext(state)
    const email = state.user.getIn(['user', 'email'])
    const firstName = state.user.getIn(['user', 'firstName'])
    const lastName = state.user.getIn(['user', 'lastName'])
    const localTrial = state.user.getIn(['licenseStatus', 'licenseType']) === LICENSE_TYPE_LOCAL_TRIAL
    const traits = {
      email,
      orgSid,
      orgName,
      orgCreatedAt: get(org, ['createdAt']),
      onPremise: !MAIN_DOMAIN,
      createdAt: state.user.getIn(['user', 'createdAt']),
      firstName,
      lastName,
      localTrial,
      enabled: state.user.getIn(['user', 'enabled']),
      userCreatedAt: state.user.getIn(['user', 'createdAt']),
      lastLoggedIn: state.user.getIn(['user', 'lastLoggedIn']),
      profilePhotoUrl: state.user.getIn(['user', 'profilePhotoUrl']),
      groupAdmin: isGroupAdminSelector(state),
      admin: state.user.getIn(['user', 'permissions', 'admin']),
      editor: state.user.getIn(['user', 'permissions', 'editor']),
      planTrial: isTrialPlan(state),
      planExpiryDate: org.trialExpiryDate,
      planName: get(org.plan, ['name'], 'Pro Plan'),
      planStripeId: org.stripePlanId,
      planMinSeats: get(org.plan, ['minSeats'], 0),
      planGrandfathered: get(org.plan, ['grandfathered'], !org.trialExpiryDate),
      ...additionalTraits,
    }

    if ('Intercom' in window) {
      window.Intercom('update', {
        app_id: 'nptsh54m',
        user_id: state.user.getIn(['user', 'sid']),
        name: `${firstName} ${lastName}`,
        email,
        user_hash: state.user.getIn(['user', 'intercomUserIdHash']),
      })
    }

    configureScope((scope) => {
      scope.setUser({ email })
      scope.setTag('orgName', orgName)
    })

    const payload: RequestInit = {
      method: 'POST',
      body: JSON.stringify({
        sid: state.user.getIn(['user', 'sid']),
        traits,
        context,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }
    fetch(`${ANALYTICS_HOST}/v2/i`, payload)
  } catch (err) {
    // Do nothing.
  }
}

export function retoolAnalyticsError(
  type: string | { type: string; subtype: string },
  message: string,
  metadata: {},
): void {
  if (typeof type === 'string') {
    retoolAnalyticsTrack('Error Raised', {
      type,
      message,
      metadata,
    })
  } else {
    retoolAnalyticsTrack('Error Raised', {
      type: type.type,
      subtype: type.subtype,
      message,
      metadata,
    })
  }
}

function isTrialPlan(state: RetoolState) {
  const org = state.user.getIn(['orgInfo', 'organization'])
  if (!MAIN_DOMAIN) {
    // org is on premise
    const licenseType = state.user.getIn(['licenseStatus', 'licenseType'])
    return licenseType === LICENSE_TYPE_LOCAL_TRIAL || licenseType === LICENSE_TYPE_TRIAL
  }
  return !!org.trialExpiryDate
}

import { useCallback, useState } from 'react'

export default function useBooleanState(initialState: boolean) {
  const [value, setValue] = useState(initialState)

  return {
    setFalse: useCallback(() => setValue(false), []),
    setTrue: useCallback(() => setValue(true), []),
    toggle: useCallback(() => setValue((value) => !value), []),
    setValue,
    value,
  }
}

export type BooleanState = ReturnType<typeof useBooleanState>

import React from 'react'
import classNames from 'classnames'
import { Tooltip as AntTooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip/index'
import 'antd/lib/tooltip/style/index'
import './Tooltip.scss'

const Tooltip = (props: TooltipProps) => {
  return <AntTooltip {...props} overlayClassName={classNames('retool-tooltip', props.overlayClassName)} />
}

export default Tooltip

import { PlainRoute, RouteConfig } from 'react-router'
import { RetoolStore } from 'store'
import { pagesLoad } from 'store/appModel/pages'
import { injectReducer } from 'store/reducers'
import { isAdminSelector, permissionsSelector } from 'store/selectors'
import { getInstrumentationIntegrations, getOrganization, getProfile, getResources } from 'store/user'
import { groupNameToSlug, paths } from './components/permissions/constants'
import { groupsSelector } from './components/permissions/selectors'
import SettingsLayout, { availableRoutesSelector, indexRouteSelector, SettingRoute } from './components/SettingsLayout'
import { getPermissions } from './modules/groups'

type RouteConfigs = { [key in SettingRoute]: PlainRoute }

export default function SettingsRoute(store: RetoolStore): RouteConfig {
  const { getState, dispatch } = store

  const routeConfigs: RouteConfigs = {
    Account: {
      getComponent: async (_, cb) => {
        const Profile = await import(/* webpackChunkName: "profile" */ './components/profile/Profile')
        const { getAuditLoginHistory } = await import(/* webpackChunkName: "settings" */ './modules/auditLoginHistory')

        dispatch(getAuditLoginHistory(1))

        document.title = 'Account Settings | Retool'
        return cb(null, Profile.default)
      },
    },
    Users: {
      onEnter: async (_, __, cb) => {
        await dispatch(getPermissions())
        document.title = 'Users Settings | Retool'
        cb?.()
      },
      getComponent: async (_, cb) => {
        const UserList = await import(
          /* webpackChunkName: "userList" */ './components/permissions/Users/UserListContainer'
        )
        return cb(null, UserList.default)
      },
    },
    Permissions: {
      onEnter: async (_, replace, cb) => {
        await dispatch(getPermissions())
        cb?.()
      },
      indexRoute: {
        onEnter: () => {
          document.title = 'Permissions Settings | Retool'
        },
        getComponent: async (_, cb) => {
          const GroupList = await import(
            /* webpackChunkName: "groupList" */ './components/permissions/Groups/GroupListContainer'
          )
          return cb(null, GroupList.default)
        },
      },
      childRoutes: [
        {
          path: paths.detail,
          onEnter: (routerState, replace) => {
            const { groupId, groupSlug } = routerState.params
            const group = groupsSelector(getState())[Number(groupId)]

            if (!group) {
              replace(paths.root)
            }

            if (group && (!groupSlug || groupNameToSlug(group.name) !== groupSlug)) {
              replace(paths.createGroupDetailPath(group))
            }
          },
          getComponent: async (_, cb) => {
            const GroupDetail = await import(
              /* webpackChunkName: "groupDetail" */ './components/permissions/GroupDetail/GroupDetailContainer'
            )
            return cb(null, GroupDetail.default)
          },
        },
      ],
    },
    Billing: {
      onEnter: () => {
        document.title = 'Billing Settings | Retool'
      },
      getComponent: async (_, cb) => {
        const Billing = await import(/* webpackChunkName: "billing" */ './components/billing/Billing')
        return cb(null, Billing.default)
      },
    },
    Themes: {
      onEnter: () => {
        document.title = 'Themes Settings | Retool'
      },
      getComponent: async (_, cb) => {
        const AppThemes = await import(/* webpackChunkName: "appThemes" */ './components/appThemes')
        return cb(null, AppThemes.default)
      },
    },
    Branding: {
      onEnter: () => {
        document.title = 'Branding Settings | Retool'
      },
      getComponent: async (_, cb) => {
        const Branding = await import(/* webpackChunkName: "branding" */ './components/branding/BrandingContainer')
        return cb(null, Branding.default)
      },
    },
    Authentication: {
      onEnter: () => {
        document.title = 'Authentication Settings | Retool'
      },
      getComponent: async (_, cb) => {
        const Authentication = await import(
          /* webpackChunkName: "authentication" */ './components/authentication/Authentication'
        )
        return cb(null, Authentication.default)
      },
    },
    Advanced: {
      onEnter: () => {
        document.title = 'Advanced Settings | Retool'
      },
      getComponent: async (_, cb) => {
        const Advanced = await import(/* webpackChunkName: "advanced" */ './components/code/CodeContainer')
        return cb(null, Advanced.default)
      },
    },
    Beta: {
      onEnter: () => {
        document.title = 'Beta Settings | Retool'
      },
      getComponent: async (_, cb) => {
        const Beta = await import(/* webpackChunkName: "beta" */ './components/beta/BetaContainer')
        return cb(null, Beta.default)
      },
    },
    Debug: {
      onEnter: () => {
        document.title = 'Debug information | Retool'
      },
      getComponent: async (_, cb) => {
        const Debug = await import(/* webpackChunkName: "debug" */ './components/debug/DebugContainer')
        return cb(null, Debug.default)
      },
    },
  }

  return {
    path: 'settings',
    pageName: 'Settings',
    component: SettingsLayout,
    getChildRoutes: async (_, cb) => {
      /*
        note we ultimately call getProfile twice if the user navigates directly to a setting child
        route. once here, and once in `requireLogin`

        if we don't, there's a race condition to define the routes and it's possible for
        users to access pages they should not be able to.
      */
      if (!permissionsSelector(getState())?.size) {
        await dispatch(getProfile())
      }

      const state = getState()
      const availableRoutes = availableRoutesSelector(state)
      cb(
        null,
        availableRoutes.map(({ title, to }) => ({ ...routeConfigs[title], path: to })),
      )
    },
    onEnter: async (_, __, cb) => {
      dispatch(pagesLoad())
      dispatch(getResources())
      const settingsReducer = await import(/* webpackChunkName: "settings" */ './modules/settings')
      const auditLoginHistoryReducer = await import(/* webpackChunkName: "settings" */ './modules/auditLoginHistory')
      if (isAdminSelector(getState())) {
        dispatch(getInstrumentationIntegrations())
        await dispatch(getOrganization())
      }
      injectReducer(store, { key: 'settings', reducer: settingsReducer.default })
      injectReducer(store, { key: 'auditLoginHistory', reducer: auditLoginHistoryReducer.default })
      document.title = 'Settings | Retool'
      cb?.()
    },
    // cannot return a promise here or weird things happen with react router...
    getIndexRoute: (_, cb) => {
      if (!permissionsSelector(getState())?.size) {
        dispatch(getProfile())
          .then(() => {
            const state = getState()
            const indexRoute = indexRouteSelector(state)
            return cb(null, routeConfigs[indexRoute])
          })
          .catch((err) => cb(err, {}))
      } else {
        const state = getState()
        const indexRoute = indexRouteSelector(state)
        return cb(null, routeConfigs[indexRoute])
      }
    },
  }
}

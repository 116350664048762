import { Map, Record } from 'immutable'

export interface QueryRunStats {
  backendTimeMs: number
  dbconnectorTimeMs: number
  estimatedResponseSizeBytes: number
  frontendRunTimeMs: number
  handleResponseTimeMs: number
  postProcessingTimeMs: number
  prepareQueryTimeMs: number
  totalTimeMs: number
  transformerTimeMs: number
}

export interface QueryPerformanceStats {
  lastRun: QueryRunStats
}

const performanceModelDefaults = {
  queryPerformance: Map<string, QueryPerformanceStats>(),
}

export class PerformanceModel extends Record(performanceModelDefaults) {}

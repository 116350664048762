import React, { Component } from 'react'

import { connect } from 'react-redux'

import { Form } from 'antd'
import { Button, TextInput } from 'components/design-system'

import { resetPassword } from '../modules/login'

const FormItem = Form.Item

interface ResetPasswordFormProps {
  resetPassword: (email: string) => any // <-- improve type after refactoring `login.js`
}

interface ResetPasswordFormState {
  email: string
  sendState: SendState
}

type SendState = 'NOT SENT' | 'SENDING' | 'SENT'

class ResetPasswordForm extends Component<ResetPasswordFormProps, ResetPasswordFormState> {
  constructor(props: ResetPasswordFormProps) {
    super(props)
    this.state = {
      email: '',
      sendState: 'NOT SENT',
    }
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value,
      sendState: 'NOT SENT',
    })
  }

  render() {
    const { resetPassword } = this.props
    const { email, sendState } = this.state

    const resetPasswordAndDisableButton = async () => {
      this.setState({ sendState: 'SENDING' })
      await resetPassword(email)
      this.setState({ sendState: 'SENT' })
    }

    let disabled = false
    let buttonLabel = 'Send reset link'
    switch (sendState) {
      case 'SENT':
        disabled = true
        buttonLabel = 'Link sent'
        break
      case 'SENDING':
        disabled = true
        buttonLabel = 'Sending...'
        break
    }

    return (
      <Form className="login-form" layout="vertical">
        <FormItem
          {...{
            labelCol: { span: 24 },
            wrapperCol: { span: 24 },
          }}
        >
          <TextInput placeholder="Email" size="large" onChange={this.handleEmailChange} />
        </FormItem>
        <Button
          type={disabled ? 'ghost' : 'primary'}
          htmlType="submit"
          className="auth-button mb8"
          disabled={disabled}
          onClick={resetPasswordAndDisableButton}
        >
          {buttonLabel}
        </Button>
      </Form>
    )
  }
}

const mapDispatchToProps = {
  resetPassword,
}

export default connect(null, mapDispatchToProps)(ResetPasswordForm)

// must come before any other import with styles
import '../styles/core.scss'

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import { browserHistory, Router } from 'react-router'
import { Provider } from 'react-redux'
import { AsyncComponentProvider, createAsyncContext } from 'react-async-component' // 👈
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ErrorBoundary from 'components/ErrorBoundary'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale-provider/en_US'
import ExpiryWarning from 'components/ExpiryHeaderWarning'
import { RETOOL_VERSION, IS_ON_PREM, RETOOL_ENVIRONMENT } from 'retoolConstants'
import { shortcutManager } from 'shortcutsKeymap'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { ResizeObserverProvider } from 'common/resizeObserver'
import { FocusVisibleManager } from 'use-focus-visible'

import 'components/plugins/widgets/widgetStyles.scss'
import { KeyboardProvider } from 'common/keyboardContext'

class AppContainer extends Component<any> {
  static propTypes = {
    routes: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
  }

  asyncContext: any

  static childContextTypes = {
    shortcuts: PropTypes.object.isRequired,
  }

  constructor(props: any) {
    super(props)
    this.asyncContext = createAsyncContext()
  }

  getChildContext() {
    return { shortcuts: shortcutManager }
  }

  UNSAFE_componentWillMount() {
    window.Intercom('boot', {
      app_id: 'nptsh54m',
      hide_default_launcher: true,
      custom_launcher_selector: '#contact-retool-support',
      vertical_padding: 75,
    })

    window.Intercom('onShow', () => {
      retoolAnalyticsTrack('Open Intercom')
    })

    // don't log for on-prem, airgapped or dev instances
    // add || __DEV__ to test sentry on dev

    //  Since sentry.retool.dev is behind our vpn, browsers won't be able to send events to it.
    // We've set up a proxy on sentry.retool.com that proxies these api events to sentry.retool.dev
    const dsn =
      window.MAIN_DOMAIN && !IS_ON_PREM ? 'https://e74295e6da4545e2ac617e2f6f011626@sentry.retool.com/2' : undefined
    Sentry.init({
      dsn,
      environment: RETOOL_ENVIRONMENT,
      release: RETOOL_VERSION,
      attachStacktrace: true,
    })
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    const { routes, store } = this.props

    return (
      <FocusVisibleManager>
        <DndProvider backend={HTML5Backend}>
          <ConfigProvider locale={enUS}>
            <ErrorBoundary boundaryName="AppContainer">
              <Provider store={store}>
                <AsyncComponentProvider asyncContext={this.asyncContext}>
                  <ResizeObserverProvider>
                    <KeyboardProvider>
                      <ExpiryWarning>
                        <Router history={browserHistory} children={routes} />
                      </ExpiryWarning>
                    </KeyboardProvider>
                  </ResizeObserverProvider>
                </AsyncComponentProvider>
              </Provider>
            </ErrorBoundary>
          </ConfigProvider>
        </DndProvider>
      </FocusVisibleManager>
    )
  }
}

export default AppContainer

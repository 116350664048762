// Default export is the query models
import { registerPlugin, setTemplateCodeCustomAliases, setResourceSpecificTemplateDefaults } from 'components/plugins'
import _ from 'lodash'
import { Selector } from 'common/utils'
import { DEFAULT_TEMPLATE_KEYS } from './modelConstants'

const queries: any = {}

export function getLoadedQueries() {
  return Object.keys(queries)
}

// This is called with every query, i.e. the model.ts for each query
export function registerDatasource(queryName: string, query: any) {
  queries[queryName] = query
  registerPlugin(queryName, null, () => query.template, query.onTemplateUpdate, null!, query.options)

  /**
   * Imported queries have template aliases for some of their fields
   */
  setTemplateCodeCustomAliases(queryName, (template: any, selector: Selector) => {
    if (template.get('isImported')) {
      const queryId = selector[0]
      const fieldName = selector[1]

      // default template fields (e.g. `triggersOnSuccess`) can't reference template aliases
      if (DEFAULT_TEMPLATE_KEYS.has(fieldName)) {
        return {}
      }

      return _.mapValues(
        template.get('importedQueryInputs').toJS(),
        (_val, key) => `${queryId}.importedQueryInputs.${key}`,
      )
    }
    return {}
  })

  if (query.resourceSpecificTemplateDefaults) {
    setResourceSpecificTemplateDefaults(queryName, query.resourceSpecificTemplateDefaults)
  }
}

export default queries

import React, { FC } from 'react'
import Icon from '../Icon'
import _styles from "./index-styles.module.scss";


const styles = _styles

const TierLockTag: FC = ({ children }) => {
  return (
    <div className={styles.main}>
      <Icon type="locked" className={styles.lock} />
      <div className={styles.children}>{children}</div>
    </div>
  )
}

export default TierLockTag

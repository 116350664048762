import React from 'react'
import { InputField } from './common'
import { ResourceFormProps } from './types'

const ElasticSearchForm = (props: ResourceFormProps) => (
  <div className="grid-1c mb20">
    <InputField
      label="Connection String"
      placeholder="http://mynode.example.com:8082/apiKey"
      resourceKey="connectionString"
      {...props}
    />
  </div>
)

export default {
  label: 'Elasticsearch',
  form: ElasticSearchForm,
  defaults: { options: { connectionString: '' } },
  validator: (resource: any) => resource.options.connectionString && resource.displayName,
}

import React from 'react'
import { Input } from 'antd'
import { SERVICE_ACCOUNT_PLACEHOLDER } from './common'
import { ResourceFormProps } from './types'

class DatastoreForm extends React.Component<ResourceFormProps> {
  render() {
    return (
      <div className="grid-1c mb12">
        <label className="input-label">
          The private key associated with a Service Account with Datastore privileges
          <p>
            See{' '}
            <a href="https://firebase.google.com/docs/admin/setup" target="_blank" rel="noopener noreferrer">
              here
            </a>{' '}
            for documentation on how to obtain this key.
          </p>
        </label>
        <Input.TextArea
          value={this.props.resource.options.serviceAccountKey}
          onChange={this.props.onChangeOption('serviceAccountKey', { handleEvent: true })}
          autosize={{ minRows: 12, maxRows: 12 }}
          placeholder={SERVICE_ACCOUNT_PLACEHOLDER}
        />
      </div>
    )
  }
}

export default {
  label: 'Datastore',
  form: DatastoreForm,
  defaults: { options: { authentication: 'serviceAccount' } },
  validator: ({ options }: any) => {
    return !!options.serviceAccountKey
  },
}

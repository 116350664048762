import * as React from 'react'
import { Button as AntdButton, message, Popconfirm } from 'antd'

import { callInternalApi } from 'networking'
import { dispatch } from 'store'
import { handleAuthResponse } from 'store/session'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'

import { LoginResponse } from 'common/records'
import AuthRetoolLogo from 'routes/Login/components/AuthRetoolLogo'
import Button from 'components/standards/Button'

type Props = {
  partialRegistrationType: 'ONE_INVITE' | 'ONE_ORG' | 'MULTIPLE'
  partialRegistrationId: string
  email: string
  domain: string
  joinToken: null | string
}

const header = (emailSent: boolean, partialRegistrationType: string, domain: string) => {
  if (emailSent) {
    if (partialRegistrationType === 'ONE_INVITE' || partialRegistrationType === 'MULTIPLE') {
      return 'Email confirmation sent'
    }
    if (partialRegistrationType === 'ONE_ORG') {
      return `Request sent`
    }
  }
  if (partialRegistrationType === 'ONE_ORG' || partialRegistrationType === 'MULTIPLE') {
    return 'Looks like your team is already on Retool'
  }
  if (partialRegistrationType === 'ONE_INVITE') {
    return `Looks like you are invited to ${domain}`
  }
  return null
}

const body = (partialRegistrationType: string, domain: string, email: string) => {
  if (partialRegistrationType === 'ONE_ORG') {
    return (
      <>
        Looks like a team with the domain <span className="strong">{domain}</span> is already using retool. Request to
        join now. Once approved, you will get an email.
      </>
    )
  }
  if (partialRegistrationType === 'ONE_INVITE') {
    return (
      <>
        You (<span className="strong">{email}</span>) are invited to join {domain} on Retool. We will send you a
        confirmation email.
      </>
    )
  }
  if (partialRegistrationType === 'MULTIPLE') {
    return (
      <>
        Looks like there are multiple teams associated with the domain <span className="strong">{domain}</span>. Verify
        your identity first to view all the organizations.
      </>
    )
  }
  return null
}

const buttonText = (partialRegistrationType: string) => {
  if (partialRegistrationType === 'ONE_ORG') return 'Request to join'
  return 'Send me a confirmation link'
}

const postEmailText = (partialRegistrationType: string, domain: string) => {
  if (partialRegistrationType === 'ONE_ORG') {
    return (
      <>
        We have sent your request to the admins of <span className="strong">{domain}</span>. Once approved, you will get
        an email with an invite link to join.
      </>
    )
  }
  if (partialRegistrationType === 'MULTIPLE') {
    return <>Click on the link in the confirmation email to view all the organizations and select which one to join. </>
  }
  if (partialRegistrationType === 'ONE_INVITE') {
    return (
      <>
        We have sent your an confirmation with a link to join <span className="strong">{domain}</span>.
      </>
    )
  }
  return null
}

const VerifyEmailPage = ({ partialRegistrationType, partialRegistrationId, email, domain, joinToken }: Props) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [emailSent, setEmailSent] = React.useState(false)
  return (
    <div>
      <div className="body-inner">
        <div>
          <div className="auth-container">
            <div className="auth-form-container">
              <AuthRetoolLogo className="mb24" />
              <h6 className="mb12">{header(emailSent, partialRegistrationType, domain)}</h6>
              {emailSent ? (
                <div className="mb12">{postEmailText(partialRegistrationType, domain)}</div>
              ) : (
                <>
                  <div className="mb12">{body(partialRegistrationType, domain, email)}</div>
                  <Button
                    data-testid="cta"
                    type="primary"
                    size="large"
                    block={true}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={() => {
                      setIsSubmitting(true)
                      let url
                      if (joinToken == null) {
                        retoolAnalyticsTrack('Sent Verification Email', {
                          userEmail: email,
                        })
                        url = '/api/sendVerificationEmail'
                      } else {
                        let organizationId
                        // attempt to tease the org ID out of the join token.
                        //
                        // note that this is a minor authenticity violation.  you should NEVER
                        // attempt to read a JWT's data without verifying it first.  however,
                        // because we're in a client-side context (and thus lack the symmetric
                        // key needed to verify the sig) and because we're using the data for a
                        // low-stakes application application (analytics), this is probably ok.
                        try {
                          const tokenAsB64 = joinToken.split('.')[1]
                          organizationId = JSON.parse(atob(tokenAsB64)).organizationId
                        } catch (err) {
                          // do nothing if parsing out org ID fails.
                        }
                        retoolAnalyticsTrack('Request To Join Org', {
                          userEmail: email,
                          organizationId,
                        })
                        url = '/api/requestToJoin'
                      }
                      const body = { partialRegistrationId, joinToken }
                      return callInternalApi({
                        method: 'POST',
                        url,
                        body,
                      })
                        .then((payload: LoginResponse | { error: boolean; message: string }) => {
                          if ('message' in payload) {
                            setEmailSent(true)
                            message.success(payload.message)
                          } else {
                            return dispatch(handleAuthResponse(payload))
                          }
                          return
                        })
                        .catch((err: Error) => {
                          message.error(`An unexpected error occurred: ${err.message}`)
                        })
                        .finally(() => {
                          setIsSubmitting(false)
                        })
                    }}
                  >
                    {buttonText(partialRegistrationType)}
                  </Button>
                  <div className="mt12">
                    No, I want to{' '}
                    <Popconfirm
                      title="Are you sure you want to create a new organization instead of joining teammates at an existing organization?"
                      trigger="click"
                      disabled={isSubmitting}
                      placement="bottom"
                      onConfirm={() => {
                        setIsSubmitting(true)
                        const body = { partialRegistrationId }
                        retoolAnalyticsTrack('Created Org From Partial Registration', {
                          userEmail: email,
                          emailVerified: false,
                        })
                        return (
                          callInternalApi({
                            method: 'POST',
                            url: '/api/createOrgFromPartialRegistration',
                            body,
                          })
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            .then((payload: any) => {
                              return dispatch(handleAuthResponse(payload))
                            })
                            .catch(() => {
                              message.error('An unexpected error occurred while creating a new organization')
                            })
                            .finally(() => {
                              setIsSubmitting(false)
                            })
                        )
                      }}
                    >
                      <AntdButton
                        type="link"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        style={{ padding: 0, fontSize: 'inherit', fontWeight: 'inherit' }}
                      >
                        create a new organization
                      </AntdButton>
                    </Popconfirm>
                    .
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyEmailPage

import React, { useState, useEffect } from 'react'

import { browserHistory } from 'react-router'

import * as Immutable from 'immutable'

import { Message } from 'components/design-system'

import { createPage, clonePage, pagesLoad } from 'store/appModel/pages'
import './CreateAppModal.scss'

import { connect } from 'react-redux'
import NameAndFolderSelector from './NameAndFolderSelector'
import Modal from 'components/design-system/Modal'
import Button from 'components/design-system/Button'
import { foldersSelector } from 'store/selectors'
import { RetoolState } from 'store'
import { ImmutableMapType, Folder } from 'common/records'
import { newAppFolderIdSelector } from 'routes/Home/selectors'
import { Form } from 'antd'
import { openInNewTab } from '../../../../common/utils/openInNewTab'

type CreateAppModalProps = {
  visible: boolean
  folders: Immutable.Map<String, ImmutableMapType<Folder>>
  newAppFolderId: number
  createPage: (pageName: string, folderId: number, isGlobalWidget?: boolean) => Promise<unknown>
  clonePage: (pageUuid: string, pageName: string, folderId: number) => Promise<unknown>
  pagesLoad: () => Promise<void>
  title: string
  navigateToAppAfterCreate: boolean
  openAppInNewTabAfterCreate?: boolean
  cloneFromAppUuid?: string
  closeModal: () => void
  isGlobalWidget?: boolean
}

function isValidState(appName: string | undefined) {
  const appNameValid = appName !== undefined
  return appNameValid
}

type NavigateToAppParams = {
  folder: ImmutableMapType<Folder> | undefined
  appName: string
  inEditView?: boolean
  newTab?: boolean
  queryParam?: boolean | string
}

export function navigateToApp({
  folder,
  appName,
  inEditView = true,
  newTab = false,
  queryParam = false,
}: NavigateToAppParams) {
  if (!folder) {
    return Message.error('Please select a valid folder.')
  }

  const path = inEditView ? 'editor' : 'apps'
  const url =
    folder.get('parentFolderId') === null
      ? `/${path}/${encodeURIComponent(appName.trim())}${queryParam ? `?${queryParam}` : ''}`
      : `/${path}/${encodeURIComponent(folder.get('name'))}/${encodeURIComponent(appName.trim())}${
          queryParam ? `?${queryParam}` : ''
        }`

  if (!newTab) {
    browserHistory.push(url)
  } else {
    openInNewTab(url)
  }
}

function CreateAppModal(props: CreateAppModalProps) {
  const [appName, setAppName] = useState<string | undefined>(undefined)
  const [parentFolderId, setParentFolderId] = useState<number>(props.newAppFolderId)
  const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  // If we change our current directory, default to that in the folder selector
  useEffect(() => {
    setParentFolderId(props.newAppFolderId)
  }, [props.newAppFolderId])

  useEffect(() => {
    setIsSubmitEnabled(isValidState(appName))
  }, [appName])

  const create = async () => {
    if (!isValidState(appName)) {
      return
    }

    setIsSubmitting(true)
    try {
      if (props.cloneFromAppUuid) {
        await props.clonePage(props.cloneFromAppUuid, appName!, parentFolderId)
      } else {
        await props.createPage(appName!, parentFolderId, props.isGlobalWidget) // null check for appName is in validState()
      }
    } catch (err) {
      return Message.error(err.message)
    } finally {
      setIsSubmitting(false)
    }

    const selectedFolder = props.folders.get(parentFolderId.toString())

    props.closeModal()

    if (props.navigateToAppAfterCreate) {
      navigateToApp({ folder: selectedFolder, appName: appName!, newTab: props.openAppInNewTabAfterCreate })
    } else {
      Message.success(`Successfully created ${appName}`)
      props.pagesLoad()
    }
  }

  return (
    <Modal
      className="homepage-modal"
      width={540}
      title={props.title}
      destroyOnClose
      visible={props.visible}
      onCancel={props.closeModal}
      footer={
        <div className="footer-container">
          <Button
            className="footer-button create-blank-app-modal__submit-button"
            key="submit"
            type="primary"
            htmlType="submit"
            form="createAppModalForm"
            onClick={create}
            disabled={!isSubmitEnabled}
            loading={isSubmitting}
          >
            Create {props.isGlobalWidget ? 'module' : 'app'}
          </Button>
        </div>
      }
    >
      <Form id="createAppModalForm">
        <NameAndFolderSelector
          appName={appName}
          onAppNameChange={setAppName}
          onCurrentFolderIdChange={setParentFolderId}
          isGlobalWidget={props.isGlobalWidget}
        />
      </Form>
    </Modal>
  )
}

CreateAppModal.defaultProps = {
  navigateToAppAfterCreate: true,
}

const mapDispatchToProps = {
  createPage,
  clonePage,
  pagesLoad,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    folders: foldersSelector(state),
    newAppFolderId: newAppFolderIdSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAppModal)

import { injectReducer } from 'store/reducers'
import requireLogin from 'routes/routeUtils/requireLogin'

export default function SetupRoute(store: any) {
  return {
    path: 'setup',
    onEnter: requireLogin(store, true),
    indexRoute: {
      getComponent(nextState: any, cb: any) {
        /*  Webpack - use 'require.ensure' to create a split point
        and embed an async module loader (jsonp) when bundling   */
        require.ensure(
          [],
          (require) => {
            /*  Webpack - use require callback to define
          dependencies for bundling   */
            const reducer = require('./modules/setup').default
            const Setup = require('./containers/SetupContainer').default

            /*  Add the reducer to the store on key 'counter'  */
            injectReducer(store, { key: 'setup', reducer })

            /*  Return getComponent   */
            cb(null, Setup)

            /* Webpack named bundle   */
          },
          'settings',
        )
      },
    },
    childRoutes: [
      {
        path: 'querylibrary',
        onEnter: () => {
          document.title = 'Setup Query Library | Retool'
        },
        getComponent: async (nextState: any, cb: any) => {
          // note: importing step two here to save on time
          const [stepOne] = await Promise.all([
            import(/* webpackChunkName: "setupQueryLibraryOne" */ './components/QueryLibrarySetupStepOne'),
            import(/* webpackChunkName: "setupQueryLibraryTwo" */ './components/QueryLibrarySetupStepTwo'),
          ])

          return cb(null, stepOne.default)
        },
      },
      {
        path: 'querylibrary/two',
        onEnter: () => {
          document.title = 'Setup Query Library | Retool'
        },
        getComponent: async (nextState: any, cb: any) => {
          const component = await import('./components/QueryLibrarySetupStepTwo')

          return cb(null, component.default)
        },
      },
      {
        path: 'firebase',
        onEnter: () => {
          document.title = 'Setup Firebase | Retool'
        },
        getComponent: async (nextState: any, cb: any) => {
          const component = await import('./components/FirebaseSetup')

          return cb(null, component.default)
        },
      },
      {
        path: 'onprem',
        onEnter: () => {
          document.title = 'Setup On-Prem | Retool'
        },
        getComponent: async (nextState: any, cb: any) => {
          const component = await import('./components/OnPremSetup')

          return cb(null, component.default)
        },
      },
    ],
  }
}

import React from 'react'
import classNames from 'classnames'

import { copyValueToClipboard } from 'common/copy'
import TextInput from 'components/design-system/TextInput'
import Button from 'components/design-system/Button'

import './CopyableText.scss'

type CopyableTextProps = {
  value: string
  className?: string
  rows?: number
  disabled?: boolean
}

const CopyableText = (props: CopyableTextProps) => {
  return (
    <div className={classNames('retool-copyable-text', props.className)}>
      {props.rows ? (
        <TextInput.TextArea value={props.value} rows={props.rows} disabled={props.disabled} />
      ) : (
        <TextInput type="text" value={props.value} onClick={(ev) => ev.stopPropagation()} disabled={props.disabled} />
      )}
      <Button type="primary" onClick={() => copyValueToClipboard(props.value)}>
        Copy
      </Button>
    </div>
  )
}

export default CopyableText

import React, { Component } from 'react'
import { Button } from 'components/design-system'
import { OAUTH_2_SSO_LOGIN_URL, SAML_LOGIN_URL } from 'retoolConstants'

interface SSOLoginButtonProps {
  // `ssoType` can be tightened to `'saml' | 'okta'`, but not without refactoring some other stuff
  ssoType: string
}
export default class SSOLoginButton extends Component<SSOLoginButtonProps> {
  handleClick = () => {
    const { ssoType } = this.props
    if (ssoType === 'okta') {
      window.location.href = '/oktalogin'
    } else if (ssoType === 'customoauth2') {
      window.location.href = OAUTH_2_SSO_LOGIN_URL
    } else {
      window.location.href = SAML_LOGIN_URL
    }
  }

  render() {
    return (
      <Button className="login-button google-login-button" onClick={this.handleClick} type="default" block>
        Sign in with {this.props.ssoType === 'okta' ? 'Okta' : 'SSO'}
      </Button>
    )
  }
}

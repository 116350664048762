import { connect } from 'react-redux'
import { RetoolState } from 'store'
import {
  profileSelector,
  isOnPremSelector,
  organizationThemeSelector,
  permissionLabelSelector,
  companyNameSelector,
  isAdminSelector,
  editorPrivilegedSelector,
  canAccessQueryLibrarySelector,
  pathSelector,
  onPresentationSelector,
} from 'store/selectors'
import { isTutorialCTAShouldAppearInMenuSelector, userDisplayNameSelector } from 'store/selectors/userSelectors'
import { showOnboarding } from 'store/onboarding'
import { showOmnibox } from 'store/omnibox'
import UserTools from './UserTools'

const mapDispatchToProps = {
  showOmnibox,
  showOnboarding,
}

const mapStateToProps = (state: RetoolState) => ({
  canAccessQueryLibrary: canAccessQueryLibrarySelector(state),
  canAdmin: isAdminSelector(state),
  canEdit: editorPrivilegedSelector(state),
  companyName: companyNameSelector(state),
  displayName: userDisplayNameSelector(state),
  isOnPrem: isOnPremSelector(state),
  isTutorialMenuItemVisible: isTutorialCTAShouldAppearInMenuSelector(state),
  renderAsIfOnPresentation: !!onPresentationSelector(state),
  path: pathSelector(state),
  permissionLabel: permissionLabelSelector(state),
  theme: organizationThemeSelector(state),
  user: profileSelector(state),
})

const UserToolsContainer = connect(mapStateToProps, mapDispatchToProps)(UserTools)

export default UserToolsContainer

import { RouteConfig } from 'react-router'

import { RetoolStore } from 'store'
import { injectReducer } from 'store/reducers'
import { permissionsSelector } from 'store/selectors'

export default function AuditTrailsRoute(store: RetoolStore): RouteConfig {
  const { dispatch, getState } = store
  return {
    path: 'audit',
    childRoutes: [],
    indexRoute: {
      getComponent: async (_, cb) => {
        const Overview = await import(/* webpackChunkName: "audit" */ './containers/OverviewContainer')
        cb(null, Overview.default)
      },
    },
    onEnter: async (_, replace, cb) => {
      const permissions = permissionsSelector(getState())
      if (!permissions.get('admin') && !permissions.get('auditLogAccess')) {
        replace('/')
        return cb?.()
      }

      const auditTrails = await import(/* webpackChunkName: "audit" */ './modules/auditTrails')
      injectReducer(store, { key: 'auditTrails', reducer: auditTrails.default })
      dispatch(auditTrails.getAllAuditTrailEvents())
      document.title = 'Audit Trail | Retool'

      cb?.()
    },
  }
}

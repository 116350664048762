import React, { useState } from 'react'

import { SignupPageProps } from './DefaultSignupPage'
import { signupThemeMap, SignUpTheme } from '../../../retoolConstants'
import { MonochromeRetoolLogoFull } from '../../../common/logo'
import _ from 'lodash'

interface SignupThemePageProps extends SignupPageProps {
  signupTheme: SignUpTheme
}

const SignupThemePage = (props: SignupThemePageProps) => {
  const [emailFormVisible] = useState(true)

  const { emphasizeGoogleLogin, signupFooter, signupForm, signupTheme } = props

  const integrationLogo = require(`../assets/integrations/${signupTheme}-logo.svg`)
  const signupThemeCapitalized = _.capitalize(signupTheme)

  const defaultHeadingText = `Start building on top of your ${signupThemeCapitalized} data`

  const themeDetails = signupThemeMap.get(signupTheme) || {}

  const { subheadingText } = themeDetails

  const defaultSubheadingText = `Retool connects to your ${signupThemeCapitalized} setup in a few clicks and gives you granular access to the API.`

  return (
    <div id="integration-signup-page">
      <div className="integration-app" data-theme={signupTheme}>
        <div className="integration-app-overlay" />
        <div className="integration-app-nav">
          <div className="integration-app-nav-l" />
          <div className="integration-app-nav-m" />
          <div className="integration-app-nav-r" />
        </div>
        <div className="integration-app-canvas">
          <div className="integration-app-grid" />
          <div className="integration-app-queryeditor">
            <div className="integration-app-queryeditor-l" />
            <div className="integration-app-queryeditor-m" />
            <div className="integration-app-queryeditor-r" />
          </div>
        </div>
        <div className="integration-app-inspector" />
      </div>
      <div className="auth-container">
        <div className="main-container">
          <div className="form-container">
            <div style={emphasizeGoogleLogin && !emailFormVisible ? { height: 460, position: 'relative' } : {}}>
              <div className="logo-container show-on-mobile mb12">
                <MonochromeRetoolLogoFull style={{ fill: 'var(--near-black)' }} />
                <div className="plus-sign">+</div>
                <img className="integration-logo" src={integrationLogo} />
              </div>
              <h6 className="mb4">{defaultHeadingText}</h6>
              <div className="subheader">{subheadingText || defaultSubheadingText}</div>
              {signupForm}
              {signupFooter}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignupThemePage

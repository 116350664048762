import { Checkbox } from 'components/design-system'
import React from 'react'
import { ResourceFormProps } from '../types'

export type OAuthShareableOptionInputProps = {
  updateResourceOptions: ResourceFormProps['updateResourceOptions']
  shareUserCredentials: boolean
  label: string
  getCallbackUrl: (isShared: boolean) => string
}

function OAuthShareableOptionInput(props: OAuthShareableOptionInputProps) {
  const { shareUserCredentials, label, updateResourceOptions, getCallbackUrl } = props
  return (
    <>
      <Checkbox
        checked={shareUserCredentials}
        onChange={(checked) => {
          updateResourceOptions({
            oauth2_share_user_credentials: checked.target.checked,
            oauth2_access_token: undefined,
            oauth2_refresh_token: undefined,
            oauth2_callback_url: getCallbackUrl(checked.target.checked),
          })
        }}
        className="grid-offset-1"
      >
        Share {label} credentials between users
      </Checkbox>
    </>
  )
}

export default OAuthShareableOptionInput

import React, { ComponentProps } from 'react'
import classNames from 'classnames'
import { connect, ConnectedProps } from 'react-redux'
import { Link, WithRouterProps } from 'react-router'
import { createSelector } from 'reselect'
import keyBy from 'lodash/keyBy'
import capitalize from 'lodash/capitalize'
import { getExperimentValue } from 'common/utils/experiments'

import {
  isAdminSelector,
  isGroupAdminSelector,
  canViewUserListSelector,
  isOnPremSelector,
  enableCustomPlatformLevelAuthStepsSelector,
} from 'store/selectors'
import { RetoolState } from 'store'
import { hasStylingFeatureSelector } from 'common/paymentPlans'

import GroupsSidebar from './permissions/Groups/GroupSidebarContainer'
import './SettingsLayout.scss'
import { onEnterprisePlanSelector, onPremSelfServiceSelector } from 'store/selectors/billingSelectors'

type Plan = 'Enterprise' | 'Pro'

const PERSONAL_SETTINGS = ['Account'] as const
const ORGANIZATION_SETTINGS = [
  'Users',
  'Permissions',
  'Billing',
  'Branding',
  'Themes',
  'Advanced',
  'Authentication',
  'Beta',
  'Debug',
] as const

type SideBarSection = { title: string; routes: Readonly<SettingRoute[]> }
const SIDE_BAR_SECTIONS: SideBarSection[] = [
  { title: 'Personal', routes: PERSONAL_SETTINGS },
  { title: 'Organization', routes: ORGANIZATION_SETTINGS },
]

type PersonalSettingRoute = typeof PERSONAL_SETTINGS[number]
type OrgSettingRoute = typeof ORGANIZATION_SETTINGS[number]
export type SettingRoute = PersonalSettingRoute | OrgSettingRoute
type SettingChildRoute = 'Group'

type SettingRouteConfig = {
  title: SettingRoute
  to: string
  plan?: Plan
  hiddenFromSidebar?: boolean
}

type RouteMap<T extends SettingRoute = SettingRoute> = { [key in T]: Omit<SettingRouteConfig, 'title'> }
export const SettingsRoutes: RouteMap = {
  Account: {
    to: 'account',
  },
  Permissions: {
    to: 'permissions',
  },
  Users: {
    to: 'users',
  },
  Billing: {
    to: 'billing',
  },
  Branding: {
    to: 'branding',
    plan: 'Enterprise',
  },
  Themes: {
    to: 'themes',
    plan: 'Pro',
  },
  Advanced: {
    to: 'advanced',
  },
  Authentication: {
    to: 'authentication',
    plan: 'Enterprise',
  },
  Beta: {
    to: 'beta',
  },
  Debug: {
    to: 'debug',
    plan: 'Enterprise',
  },
}

export const indexRouteSelector = createSelector(
  isAdminSelector,
  canViewUserListSelector,
  (isAdmin, canViewUserList) => (isAdmin || canViewUserList ? 'Users' : 'Account') as SettingRoute,
)

const settingsLayoutPropsSelector = (_: RetoolState, props?: SettingsLayoutOwnProps) => props
const settingsLayoutRouterPropsSelector = createSelector(settingsLayoutPropsSelector, (props) => ({
  location: props?.location,
  params: props?.params,
}))

type SettingsRouteDetails = {
  activeRoute: SettingRoute
  childRoute: SettingChildRoute | null
}

export const routeDetailsSelector = createSelector(
  settingsLayoutRouterPropsSelector,
  indexRouteSelector,
  (router, indexRoute): SettingsRouteDetails => {
    const { location } = router

    // get the actual route path (relative to settings)
    const parts = location?.pathname.replace('/settings', '').split('/') ?? []

    // the initial item could be the empty string. if the array has length 0 | 1
    // and all items are the empty string we're at the index route.
    // an async indexRoute would not properly redirect without a provided component.
    const routeName = capitalize(parts.shift() || parts.shift() || indexRoute) as SettingRoute
    const childRoute = parts[0] ? (capitalize(parts[0]) as SettingChildRoute) : null

    return { activeRoute: routeName, childRoute }
  },
)

export const availableRoutesSelector = createSelector(
  isAdminSelector,
  isGroupAdminSelector,
  isOnPremSelector,
  canViewUserListSelector,
  enableCustomPlatformLevelAuthStepsSelector,
  onPremSelfServiceSelector,
  (
    userIsAdmin,
    userIsGroupAdmin,
    isOnPrem,
    canViewUserList,
    enableCustomPlatformLevelAuthSteps,
    isSelfServiceOnPrem,
  ) => {
    const debugPageEnabled = getExperimentValue('debugPage')
    // determine which routes should be available to the current user
    const routeVisibility: { [key in SettingRoute]?: boolean } = {
      Users: canViewUserList || userIsAdmin || userIsGroupAdmin,
      Permissions: userIsAdmin || userIsGroupAdmin,
      Billing: userIsAdmin,
      Branding: userIsAdmin,
      Advanced: userIsAdmin,
      Themes: userIsAdmin,
      Beta: userIsAdmin,
      Debug: userIsAdmin && !!debugPageEnabled && isOnPrem,
      Authentication: userIsAdmin && isOnPrem && enableCustomPlatformLevelAuthSteps,
    }

    const settingsRoutes: RouteMap = {
      ...SettingsRoutes,
      Themes: {
        to: 'themes',
        plan: isSelfServiceOnPrem ? 'Enterprise' : 'Pro',
      },
    }

    return Object.entries(settingsRoutes).reduce((acc, [_route, config]) => {
      const route = _route as SettingRoute

      // default to true if no entry is defined
      if (routeVisibility[route] ?? true) {
        return acc.concat({ ...config, title: route })
      }

      return acc
    }, [] as SettingRouteConfig[])
  },
)

type SettingsSidebarProps = {
  hasThemes?: boolean
  isEnterprise?: boolean
  routes?: SettingRouteConfig[]
  activeRoute: string
}

const cn = 'settings-layout'

const SettingsSidebar: React.FC<SettingsSidebarProps> = ({
  hasThemes = false,
  isEnterprise = false,
  activeRoute,
  routes,
}) => {
  const routeMap = keyBy(routes, 'title')

  const makePlanTag = (plan?: Plan, title?: SettingRoute) => {
    // if on enterprise don't render a tag -- everything is available
    if (isEnterprise) return

    // check for individual feature access
    if (title === 'Themes' && hasThemes) return

    return plan && <div className="tag">{plan}</div>
  }

  const makeLink = (route: SettingRoute) => {
    if (routeMap[route]) {
      const { plan, to, hiddenFromSidebar } = routeMap[route]

      if (hiddenFromSidebar) {
        return <></>
      }

      return (
        <Link
          key={route}
          className={classNames(`${cn}__link`, route === activeRoute && `${cn}__link--active`)}
          to={`/settings/${to}`}
        >
          {route} {makePlanTag(plan, route)}
        </Link>
      )
    }
  }

  return (
    <>
      {SIDE_BAR_SECTIONS.map(({ title, routes }) => {
        const links = routes.map(makeLink).filter(Boolean)
        if (links.length)
          return (
            <div key={title} className={`${cn}__section`}>
              <div className={`${cn}__header`}>{title}</div>
              {links}
            </div>
          )
      })}
    </>
  )
}

type CustomSettingSidebarProps = ComponentProps<typeof GroupsSidebar> // | other custom sidebars
const ChildSidebar: { [key in SettingChildRoute]: React.FC<CustomSettingSidebarProps> } = {
  Group: GroupsSidebar,
}

type SettingsLayoutOwnProps = {} & WithRouterProps
type SettingsLayoutProps = SettingsLayoutOwnProps & ConnectedProps<typeof connector>
const SettingsLayout: React.FC<SettingsLayoutProps> = ({
  onEnterprisePlan,
  routeDetails,
  hasStylingFeature,
  params,
  routes,
  children,
}) => {
  const { activeRoute, childRoute } = routeDetails
  const { groupId } = params

  const routesWithCustomHeaders: SettingRoute[] = ['Themes', 'Billing', 'Permissions', 'Users']

  const Sidebar = childRoute && ChildSidebar[childRoute] ? ChildSidebar[childRoute] : SettingsSidebar
  return (
    <div className={cn}>
      <div className={`${cn}__side-bar`}>
        <Sidebar
          activeGroupId={Number(groupId)}
          activeRoute={activeRoute}
          routes={routes}
          isEnterprise={onEnterprisePlan}
          hasThemes={hasStylingFeature}
        />
      </div>
      <div className={`${cn}__content--container`}>
        <div className={`${cn}__content`}>
          {!routesWithCustomHeaders.includes(activeRoute) && <div className="settings-header">{activeRoute}</div>}
          {children}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: RetoolState, props: SettingsLayoutOwnProps) => ({
  routes: availableRoutesSelector(state),
  routeDetails: routeDetailsSelector(state, props),
  hasStylingFeature: hasStylingFeatureSelector(state),
  onEnterprisePlan: onEnterprisePlanSelector(state),
})

const connector = connect(mapStateToProps)
export default connector(SettingsLayout)

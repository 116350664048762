import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import { browserHistory, Link, withRouter } from 'react-router'

import { unused } from 'common/types'

import { Button, Icon } from 'components/design-system'
import { TextArea } from 'components/standards/TextInput'

import { RetoolState } from 'store'
import { NEW_RESOURCE_URL_PREFIX } from 'store/constants'
import { showOnboarding } from 'store/onboarding'
import { dismissOnboardingSuggestions, inviteToOrg } from 'store/user'
import { APPLICATION, INVITE, RESOURCE, Stage, STAGES } from 'store/userConstants'

import { onboardingSuggestionsStagesCompletedSelector, onboardingSuggestionsVisibleSelector } from 'store/selectors'

import './OnboardingSuggestions.scss'

import createResourceGraphic from 'assets/onboarding-images/suggestions-resources.svg'
import createApplicationGraphic from 'assets/onboarding-images/suggestions-applications.svg'

import CreateApplicationDropdown from 'routes/Home/components/sections/CreateApplicationDropdown'
import { firstNameSelector } from 'store/selectors/userSelectors'

type Props = {
  completed: Stage[] | null
  dismissOnboardingSuggestions: () => Promise<void>
  firstName: string | undefined | null
  sendInvites: (emailsString: string) => Promise<void>
  showOnboarding: () => Promise<void>
  style: React.CSSProperties
  visible: boolean
}

type StageSelectorProps = {
  completed: string[]
  nextStage: string | undefined
  setLocalStage: (stage: string | undefined) => void
  stage: string
}

const navigateTo = browserHistory.push

const CREATE_RESOURCE_DESCRIPTION = (
  <>
    Create a resource that connects to your data. You'll use resources to access whatever data you need to build tools –
    databases, APIs, even cloud stores.
  </>
)

const CREATE_APPLICATION_DESCRIPTION = (
  <>
    Build an app with your data. Write queries that connect to components like tables, text inputs and buttons. Add
    logic with <code>{'{{ javascript }}'}</code>
  </>
)

const INVITE_TEAMMATES_DESCRIPTION = (
  <>
    Invite your teammates to Retool.
    <br />
    You can create user groups and manage access controls in <Link to="/settings">Settings</Link>.
  </>
)

const StageSelector = (props: StageSelectorProps) => {
  const { stage, completed, setLocalStage } = props

  type IndicatorProps = {
    children: React.ReactChild
    name: string
  }

  const Indicator = (props: IndicatorProps) => {
    const { name, children } = props
    const isDone = completed.includes(name)
    const isCurrent = name === stage

    return (
      <Button type="link" onClick={(_: unused) => setLocalStage(name)} className="dark-gray">
        <div
          className={`${isCurrent ? ' current' : ''} ${
            isDone ? ' completed' : ''
          } onboarding-stage fw-500 flex items-center`}
        >
          {isDone ? <Icon type="check-circle-dark" /> : <Icon type="check-circle-light" />}
          <div className="label ml8">{children}</div>
        </div>
      </Button>
    )
  }

  return (
    <div className="onboarding-stage-list">
      <Indicator name={RESOURCE}>Create a resource</Indicator>
      <Indicator name={APPLICATION}>Build an app</Indicator>
      <Indicator name={INVITE}>Invite users</Indicator>
    </div>
  )
}

type StageProps = Props & StageSelectorProps

const CreateResource = (_props: StageProps) => {
  return (
    <>
      <div className="flex fd-col flex-grow pa28 pt0">
        <div className="flex-grow gray mb24">{CREATE_RESOURCE_DESCRIPTION}</div>
        <div className="flex items-center">
          <Button type="primary" onClick={(_) => navigateTo(NEW_RESOURCE_URL_PREFIX)}>
            Create a resource&nbsp;
            <Icon type="arrow-right" />
          </Button>
        </div>
      </div>
      <div className="onboarding-image">
        <img src={createResourceGraphic} />
      </div>
    </>
  )
}

const CreateApplication = (props: StageProps) => {
  const tutorialHandler = (_: unused) => {
    props.showOnboarding()
  }

  return (
    <>
      <div className="flex fd-col flex-grow pa28 pr12 pt0">
        <div className="flex-grow gray mb24">{CREATE_APPLICATION_DESCRIPTION}</div>
        <div className="flex items-center " style={{ height: 32 }}>
          <CreateApplicationDropdown openRight>
            Build an app&nbsp;
            <Icon type="arrow-right" />
          </CreateApplicationDropdown>
          <p className="ml8 light-gray">
            or{' '}
            <Button type="link" onClick={tutorialHandler}>
              take the tutorial
            </Button>
            .
          </p>
        </div>
      </div>
      <img className="onboarding-image" src={createApplicationGraphic} />
    </>
  )
}

const InviteUsers = (props: StageProps) => {
  const [emails, setEmails] = useState('')

  return (
    <div className="flex flex-grow email-invite-container pa28 pt0">
      <div style={{ width: 280 }} className="flex fd-col flex-shrink pr12 invite-content">
        <div className="gray">{INVITE_TEAMMATES_DESCRIPTION}</div>
      </div>
      <div className="flex fd-col flex-grow">
        <div className="flex-grow">
          <TextArea
            style={{ minHeight: 71, height: 71 }}
            className="invite-email-input fs-13"
            placeholder="Enter email addresses, separated by comma"
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
          />
        </div>
        <div className="flex items-center mt16">
          <Button type="primary" onClick={(_) => props.sendInvites(emails)}>
            Send invites&nbsp;
            <Icon type="arrow-right" />
          </Button>
        </div>
      </div>
    </div>
  )
}

const OnboardingSuggestions = (props: Props) => {
  const { visible } = props
  const completed = props.completed || []
  const remaining = STAGES.filter((s) => !completed.includes(s))

  const currentStage = remaining[0]
  const nextStage = remaining[1]

  const [stage, setLocalStage] = useState<string | undefined>(currentStage)
  useEffect(() => {
    // NB: skip for apps as `createPage` returns before navigation occurs.
    // this causes a weird "advance stage" before loading the editor.
    if (currentStage !== APPLICATION) {
      setLocalStage(currentStage)
    }
  }, [currentStage, props.completed])

  if (!visible || typeof stage === 'undefined') {
    return <></>
  }

  const { firstName } = props

  const stageProps = { ...props, completed, stage, remaining, nextStage, setLocalStage }
  const dismiss = (_: unused) => {
    setLocalStage(undefined)
    props.dismissOnboardingSuggestions()
  }

  const wrap = (element: React.ReactElement) => (
    <div style={props.style}>
      <div className="fs-20 fw-600" style={{ paddingTop: 24 }}>
        Welcome to Retool{firstName ? `, ${firstName}` : ''}!
      </div>
      <div className="onboarding-stage-container">
        <StageSelector {...stageProps} />
        <div className="flex fd-col flex-grow bg-near-white">
          <div className="flex justify-end">
            <Button type="link" onClick={dismiss} className="pa4 lightest-gray hover-blue">
              <Icon type="close" />
            </Button>
          </div>
          <div className="flex flex-grow">{element}</div>
        </div>
      </div>
      <hr className="onboarding-divider mt12" />
    </div>
  )

  switch (stage) {
    case RESOURCE:
      return wrap(<CreateResource {...stageProps} />)
    case APPLICATION:
      return wrap(<CreateApplication {...stageProps} />)
    case INVITE:
      return wrap(<InviteUsers {...stageProps} />)
    default:
      // NB: if you add a new stage, this may happen
      return <></>
  }
}

const mapDispatchToProps = {
  dismissOnboardingSuggestions,
  inviteToOrg,
  showOnboarding,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    completed: onboardingSuggestionsStagesCompletedSelector(state),
    firstName: firstNameSelector(state),
    visible: onboardingSuggestionsVisibleSelector(state),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingSuggestions))

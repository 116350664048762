/* eslint-disable */
;(function () {
  function removeAllOccurrences(list, id) {
    return list.filter(v => v !== id)
  }
  function removeLastOccurence(list, id) {
    for (let i = list.length - 1; i >= 0; i--) {
      if (list[i] === id) {
        list.splice(i, 1)
        return list
      }
    }
    return list
  }

  var estraverse, recurse, lastFoundIdentifier
  estraverse = require('estraverse')
  recurse = function (ast, declared, undeclared) {
    var ast_fn, ast_fns, declared_copy, i, ids, len
    if (declared == null) {
      declared = new Set()
    }
    if (undeclared == null) {
      undeclared = new Set()
    }
    ids = []
    ast_fns = []
    estraverse.traverse(ast, {
      enter: function (node, parent) {
        var brokenLastId, lastPieceId
        if (lastFoundIdentifier) {
          brokenLastId = lastFoundIdentifier.split('.')
          lastPieceId = brokenLastId[brokenLastId.length - 1]
        }

        if (parent != null) {
          if (parent.type != 'MemberExpression') {
            // member expressions are a[b] or a.b
            lastFoundIdentifier = null
          }

          if (node.type === 'Identifier') {
            if (parent.type === 'VariableDeclarator') {
              declared.add(node.name)
            } else if (!parent.key && (parent.type !== 'MemberExpression' || node.name !== parent.property.name)) {
              ids.push(node.name)
              lastFoundIdentifier = node.name
            } else if (
              (parent.type == 'MemberExpression' && parent.object && parent.object.name == lastPieceId) ||
              (parent.object && parent.object.object && parent.object.property.name === lastPieceId)
            ) {
              ids = removeAllOccurrences(ids, lastFoundIdentifier)
              if (lastFoundIdentifier) {
                lastFoundIdentifier += '.'
                lastFoundIdentifier += node.name
              } else {
                lastFoundIdentifier = node.name
              }
              ids.push(lastFoundIdentifier)
            }
          } else if (
            // if its a function, add it to the list of functions to recurse later and add it to declared
            node.type === 'FunctionDeclaration' ||
            node.type === 'FunctionExpression' ||
            node.type === 'ArrowFunctionExpression'
          ) {
            ast_fns.push(node)
            if (node.id != null) {
              declared.add(node.id.name)
            }
            this.skip()
          }
        }
      },
      leave: function (node) {
        if (node.type == 'CallExpression') {
          // function or method call a()
          // prop.value.funcCall
          var namedArgs = node.arguments
            .map(function (a) {
              return a.type
            })
            .filter(function (t) {
              return t != 'FunctionExpression' && t != 'Literal' && t != 'ArrowFunctionExpression' // e.g. t != () => {} &&  t != 5
            })
          var lastId = ids[ids.length - namedArgs.length - 1]
          if (
            lastId &&
            node.callee.type === 'MemberExpression' && // If this isn't a MemberExpression (i.e. Identifier) then we can't strip as below (as an example try removing this and running with "moment()")
            node.callee.property.name == lastId.split('.')[lastId.split('.').length - 1]
          ) {
            // ensure that we're stripping the right thing
            // e.g. abc.def.join('.').split('.')
            ids = removeLastOccurence(ids, lastId)
            //prop.value
            var strippedId = lastId
              .split('.')
              .slice(0, lastId.split(',').length - 2)
              .join('.')
            ids.push(strippedId)
          }
        }
      },
    })
    ids.forEach(function (id) {
      var firstPiece = id.split('.')[0]
      if (!declared.has(firstPiece)) {
        undeclared.add(id)
      }
    })
    for (i = 0, len = ast_fns.length; i < len; i++) {
      ast_fn = ast_fns[i]
      declared_copy = new Set()
      declared.forEach(function (id) {
        declared_copy.add(id)
      })
      ast_fn.params.forEach(function (param) {
        declared_copy.add(param.name)
      })
      recurse(ast_fn, declared_copy, undeclared)
    }
    return undeclared
  }
  module.exports = recurse
}.call(this))
/* eslint-enable */

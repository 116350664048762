import { convertToRowBased, sanitizeTabularDataForExcel, getDataAsCSV } from 'common/dataProcessing'
import { assertIsString, assertPlainObject, assertPlainObjectOrArray } from 'types/typeguards'
import { PluginMethodConfig } from 'components/plugins'
import { assertObjectOfType } from 'types/isObjectOfType'

const SUPPORTED_FILE_TYPES = ['csv', 'tsv', 'json', 'xlsx']
export type FileType = typeof SUPPORTED_FILE_TYPES[number]

export type ExportDataParams = {
  data: unknown[] | Record<string, unknown> | Record<string, unknown[]>
  fileName?: string
  fileType?: FileType
  options?: {
    sheetName?: string
  }
}

const getBlob = async (
  data: ExportDataParams['data'] = [],
  fileType: ExportDataParams['fileType'] = 'csv',
  options: ExportDataParams['options'] = {},
) => {
  const formattedData = convertToRowBased(data)

  const Blob = (await import('blob')).default

  switch (fileType) {
    case 'csv': {
      return new Blob([getDataAsCSV(formattedData)], { type: 'text/csv;charset=utf-8;' })
    }

    case 'tsv': {
      return new Blob([getDataAsCSV(formattedData, { delimiter: '\t' })], { type: 'text/tsv;charset=utf-8;' })
    }

    case 'json': {
      return new Blob([JSON.stringify(data, null, 2)], { type: 'data:text/json' })
    }

    case 'xlsx': {
      const s2ab = (s: string) => {
        if (typeof ArrayBuffer !== 'undefined') {
          const buf = new ArrayBuffer(s.length)
          const view = new Uint8Array(buf)
          for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
          return buf
        } else {
          const buf = new Array(s.length)
          for (let i = 0; i !== s.length; ++i) buf[i] = s.charCodeAt(i) & 0xff
          return buf
        }
      }

      const { sheetName = 'Sheet1' } = options
      assertIsString(sheetName, '`sheetName` must be a string.')

      const sanitizedData = sanitizeTabularDataForExcel(formattedData)

      const xlsx = await import('xlsx')
      const wb = {
        SheetNames: [sheetName],
        Sheets: { [sheetName]: xlsx.utils.aoa_to_sheet(sanitizedData) },
      }

      const wbout = s2ab(xlsx.write(wb, { bookType: 'xlsx', type: 'binary' }))

      return new Blob([wbout], { type: 'application/octet-stream' })
    }

    default: {
      throw new TypeError(`Invalid filetype: ${fileType}. Supported types are: 'csv', 'tsv', 'xlsx', 'json'`)
    }
  }
}

const exportData: PluginMethodConfig = {
  metadata: {
    label: 'Export data',
    description: 'Download a file with the referenced tabular data.',
    example: "`utils.exportData(table1.data, 'fileName', 'csv')`",
    params: [
      {
        type: 'codeInput',
        name: 'data',
        props: {
          label: 'Data',
          docs:
            'The data to download. Accepts an array of entries or an object mapping column names to arrays of values.',
          placeholder: '{{ table1.data }}',
        },
      },
      {
        type: 'codeInput',
        name: 'fileName',
        props: {
          label: 'File name',
          validator: 'string | void',
          docs: "The downloaded file's name without an extension.",
          defaultValue: 'export',
        },
      },
      {
        type: 'select',
        name: 'fileType',
        props: {
          label: 'File type',
          placeholder: 'Select a file type',
          defaultValue: 'csv',
          options: [
            { value: 'csv', label: 'CSV' },
            { value: 'tsv', label: 'TSV' },
            { value: 'json', label: 'JSON' },
            { value: 'xlsx', label: 'Excel (.xlsx)' },
          ],
        },
      },
      {
        type: 'objectInput',
        name: 'options',
        props: {
          label: 'Options',
          params: [
            {
              type: 'codeInput',
              name: 'sheetName',
              props: { label: 'Sheet name', validator: 'string', placeholder: 'Sheet1' },
              hidden: (model) => model.get('fileType') !== 'xlsx',
            },
          ],
        },
      },
    ],
  },
  method: async (data, fileName = 'export', fileType = 'csv', options = {}) => {
    assertPlainObjectOrArray(data, '`data` must be a plain object or array.')
    assertPlainObject(options, '`options` must be an object.')
    assertIsString(fileName, '`fileName` must be a string.')
    assertObjectOfType<{ fileType: ExportDataParams['fileType'] }>(
      { fileType },
      { fileType: { type: 'literal', values: SUPPORTED_FILE_TYPES } },
      '`fileType` must be one of `csv`, `tsv`, `xlsx`, `json`.',
    )

    const blob = await getBlob(data, fileType as FileType, options)

    const { saveAs } = await import('file-saver')
    return saveAs(blob, `${fileName}.${fileType}`)
  },
}

export default exportData

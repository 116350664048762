import Menu from 'antd/lib/menu'
import { Branch, Page, User } from 'common/records'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { Button, Icon } from 'components/design-system'
import { Dropdown, DropdownProps } from 'components/design-system/Dropdown'
import BranchMenuItem from 'components/ProtectedApps/BranchMenuItem'
import React from 'react'
import { connect, useSelector } from 'react-redux'
import { browserHistory, withRouter } from 'react-router'
import { RetoolState } from 'store'
import {
  branchesSelector,
  isFetchingBranchesSelector,
  profileSelector,
  protectedAppsEnvVarsSetSelector,
} from 'store/selectors'
import classNames from 'classnames'
import useBooleanState from 'common/hooks/useBooleanState'
import CreateBranchModal from 'components/ProtectedApps/CreateBranch/CreateBranchModal'
import _styles from "./EditApplicationDropdown-styles.module.scss";


const navigateTo = (route: string) => browserHistory.push(route)

const styles = _styles

type EditApplicationDropdownProps = {
  branches: Branch[]
  isFetching: boolean
  editLink: string
  page?: Page
  user: User
  buttonStyles?: React.CSSProperties
} & Omit<DropdownProps, 'overlay'>

function renderMenuItemForBranch(editLink: string, branch: Branch) {
  return (
    <BranchMenuItem
      key={`branch-${branch.name}`}
      onClick={() => {
        retoolAnalyticsTrack('Branch Switched', { protectedBranch: false, mode: 'edit' })
        navigateTo(`/tree/${encodeURIComponent(branch.name)}${editLink}`)
      }}
      branchName={branch.name}
    />
  )
}

const EditApplicationDropdown = (props: EditApplicationDropdownProps) => {
  const { page, editLink, branches, user, buttonStyles, ...dropdownProps } = props
  const creatingBranch = useBooleanState(false)
  const protectedAppsEnvVarsSet = useSelector(protectedAppsEnvVarsSetSelector)

  const branchesMenuItems = branches
    .filter((branch) => branch.pageId === page?.id)
    .filter((branch) => branch.ownerId === user.id)
    .slice(0, 10)
    .map((branch) => renderMenuItemForBranch(editLink, branch))

  const showBranches = branchesMenuItems.length > 0
  const menu = (
    <Menu>
      {showBranches && <div className="application-listing__menu-title">Your branches</div>}
      {showBranches && branchesMenuItems}
      {showBranches && <Menu.Divider />}
      <Menu.Item
        key="new-branch"
        className={styles.menuDropdown}
        onClick={creatingBranch.setTrue}
        disabled={!protectedAppsEnvVarsSet}
      >
        <div className={classNames(styles.menuLink, !protectedAppsEnvVarsSet && styles.menuLinkDisabled)}>
          Create new branch
        </div>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      {creatingBranch.value && page && (
        <CreateBranchModal
          closeModal={() => {
            creatingBranch.setFalse()
            dropdownProps.visible = false
          }}
          pageUuid={page.uuid}
          editLink={editLink}
        />
      )}
      <Dropdown
        disabled={creatingBranch.value}
        className="ml8 edit-action edit-action-dropdown"
        overlay={menu}
        trigger={['click']}
        {...dropdownProps}
      >
        <Button type="primary" style={buttonStyles}>
          Edit
          <Icon type="caret-down" style={{ marginRight: -8 }} />
        </Button>
      </Dropdown>
    </>
  )
}

const mapStateToProps = (state: RetoolState) => {
  return {
    branches: branchesSelector(state),
    isFetching: isFetchingBranchesSelector(state),
    user: profileSelector(state),
  }
}

export default withRouter(connect(mapStateToProps)(EditApplicationDropdown))

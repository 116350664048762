import { OnboardingStep, WidgetType } from 'common/records'
import { AdhocResourceType, ResourceType } from 'common/resourceTypes'
import { SafeAny } from 'common/types'

export const BUILDING_BLOCK_COOKIE = 'showBuildingBlocks'
export const DEFAULT_SELECTED_BLOCK_ID: BuildingBlockId = 'tableView'
export const SELECT_FOR_USER_BUILDING_BLOCK_ID: BuildingBlockId = 'tableFilter'
export const BUILDING_BLOCK_ENABLED_RESOURCES: (ResourceType | AdhocResourceType)[] = [
  'postgresql',
  'mysql',
  'googlesheets',
]
export const ONBOARDING_BUILDING_BLOCK_ENABLED_RESOURCES: (ResourceType | AdhocResourceType)[] = [
  'postgresql',
  'mysql',
  'googlesheets',
]
export const BUILDING_BLOCK_MODAL_WIDTH = 280
export const BUILDING_BLOCK_MODAL_HEIGHT_OFFSET = 500
export const BUILDING_BLOCK_MODAL_WIDTH_OFFSET = 20

export type BuildingBlockFormDict = Record<BuildingBlockGeneratorInputId, string>
export type WidgetOptionValue = string | boolean | {}

export type WidgetBuildingBlock = {
  id: string
  type: WidgetType
  options: BuildingBlockWidgetOptions
  //TODO: (Andrew) sign these should be a class instead
  parentBuildingBlockId: BuildingBlockId
  mergeWidget?: boolean
  container?: string
  hintSequence?: OnboardingStep
  hideInPreview?: boolean
}

export type QueryBuildingBlock = {
  id: string
  type: ResourceType
  options: SafeAny
  parentBuildingBlockId: BuildingBlockId
  hintSequence?: OnboardingStep
  hintOnlyBlock?: boolean
  overrideEditorType?: ResourceType
}

export type BuildingBlockWidgetOptionObject = {
  value: WidgetOptionValue
  priority: number
  hideInPreview?: boolean
  overridePreviewValue?: string
}

export type BuildingBlockWidgetOptions = Record<string, BuildingBlockWidgetOptionObject>

export const TABLE_FILTER_BUILDING_BLOCK = 'tableFilter'
export const TABLE_VIEW_BUILDING_BLOCK = 'tableView'
export const UPDATE_RECORD_BUILDING_BLOCK = 'updateRecord'
export const INSERT_NEW_DATA_BUILDING_BLOCK = 'insertNewData'
export const DELETE_RECORD_BUILDING_BLOCK = 'deleteRecord'
export const EXAMINE_DATA_BUILDING_BLOCK = 'examineData'

export type BuildingBlockId =
  | typeof TABLE_FILTER_BUILDING_BLOCK
  | typeof TABLE_VIEW_BUILDING_BLOCK
  | typeof UPDATE_RECORD_BUILDING_BLOCK
  | typeof INSERT_NEW_DATA_BUILDING_BLOCK
  | typeof DELETE_RECORD_BUILDING_BLOCK
  | typeof EXAMINE_DATA_BUILDING_BLOCK

export type BuildingBlock = {
  id: BuildingBlockId
  displayName: string
  description: string
  widgets: WidgetBuildingBlock[]
  queries: QueryBuildingBlock[]
  showInEditor: boolean
  showInOnboarding: boolean
  generatorInputs?: BuildingBlockGeneratorInput[]
}

export type BuildingBlockGeneratorInput = {
  id: BuildingBlockGeneratorInputId
  options: BuildingBlockGeneratorInputOptions
  label: string
  conditionalShow?: (editorForm: ResourceType | AdhocResourceType) => boolean
}

export const BUILDING_BLOCK_WIDGET_TYPE = 'BuildingBlockContainerWidget'

export const TABLE_PLACEHOLDER = 'REPLACE_TABLE'
//TODO: (Andrew) sign that there has to be a better abstraction, if we continue investing in this will need to change
export const FILTER_TEXT_INPUT_ID_PLACEHOLDER = 'FILTER_TEXT_INPUT_ID_PLACEHOLDER'
export const UPDATE_TEXT_INPUT_ID_PLACEHOLDER = 'UPDATE_TEXT_INPUT_ID_PLACEHOLDER'
export const TABLE_ID_PLACEHOLDER = 'TABLE_ID_PLACEHOLDER'
export const TARGET_COLUMN_PLACEHOLDER = 'TARGET_COLUMN_PLACEHOLDER'
export const QUERY_ID_PLACEHOLDER = 'QUERY_ID_PLACEHOLDER'
export const UNIQUE_COLUMN_PLACEHOLDER = 'UNIQUE_COLUMN_PLACEHOLDER'
export const TABLE_WIDGET_DATA_SOURCE_PLACEHOLDER = 'TABLE_WIDGET_DATA_SOURCE_PLACEHOLDER'
export const GOOGLE_SHEET_PLACEHOLDER = 'GOOGLE_SHEET_PLACEHOLDER'

export const BUILDING_BLOCKS_ONBOARDING_ID = 'buildingBlocksOnboarding'

export const buildingBlockRepository: { [s: string]: BuildingBlock } = {}

export const ALL_TABLE_WIDGETS = 'ALL_TABLE_WIDGETS'
export const ALL_RESOURCE_SHEETS = 'ALL_RESOURCE_SHEETS'
export const CREATE_NEW_TABLE = 'Add new table'
export const ALL_RESOURCES = 'ALL_RESOURCES'
export const ALL_RESOURCE_TABLES = 'ALL_RESOURCE_TABLES'
export const ALL_TABLE_COLUMNS = 'ALL_TABLE_COLUMNS'

export const UNIQUE_COLUMN = 'UNIQUE_COLUMN'
export const SELECTED_RESOURCE = 'SELECTED_RESOURCE'
export const SELECTED_GOOGLE_SHEET = 'SELECTED_GOOGLE_SHEET'
export const SELECTED_DATABASE_TABLE = 'SELECTED_DATABASE_TABLE'
export const TARGET_COLUMN = 'TARGET_COLUMN'

export type BuildingBlockGeneratorInputOptions =
  | typeof ALL_TABLE_WIDGETS
  | typeof ALL_RESOURCES
  | typeof ALL_RESOURCE_TABLES
  | typeof ALL_TABLE_COLUMNS
  | typeof ALL_RESOURCE_SHEETS

export type BuildingBlockGeneratorInputId =
  | typeof UNIQUE_COLUMN
  | typeof TARGET_COLUMN
  | typeof SELECTED_RESOURCE
  | typeof SELECTED_DATABASE_TABLE
  | typeof SELECTED_GOOGLE_SHEET

export const ENABLED_BUILDING_BLOCKS: BuildingBlockId[] = [
  TABLE_VIEW_BUILDING_BLOCK,
  TABLE_FILTER_BUILDING_BLOCK,
  UPDATE_RECORD_BUILDING_BLOCK,
  INSERT_NEW_DATA_BUILDING_BLOCK,
  DELETE_RECORD_BUILDING_BLOCK,
  EXAMINE_DATA_BUILDING_BLOCK,
]

export const initializeBuildingBlocks = () => {
  //Only run if the building blocks haven't been initalized yet
  if (Object.keys(buildingBlockRepository).length === 0) {
    const context = require.context('./blockDefinitions', true, /.+(js|ts)$/)

    context.keys().forEach((filename) => {
      const buildingBlock = context(filename).default()
      if (ENABLED_BUILDING_BLOCKS.includes(buildingBlock.id)) {
        buildingBlockRepository[buildingBlock.id] = buildingBlock
      }
    })
  }
}

import { OAUTH_2_SSO_LOGIN_URL, SAML_LOGIN_URL } from 'retoolConstants'
import { GET_AUTH_HEADERS, JSON_HEADERS } from 'networking/util'
import ls from 'local-storage'
import cookies from 'js-cookie'
import { merge } from 'lodash'

type DeauthSessionOptionsType = {
  optionalCustomLogoutRedirect?: string
  triggerOauth2SSOLoginAutomatically?: boolean
  triggerSamlLoginAutomatically?: boolean
}

function deauthSession(options: DeauthSessionOptionsType) {
  const { optionalCustomLogoutRedirect, triggerOauth2SSOLoginAutomatically, triggerSamlLoginAutomatically } = options
  // Clear localstorage on logout
  ls.remove('customAppData')

  // Save the last location so that we can open it back up once they've logged in.
  ls.set('redirectOnLogin', {
    pathname: window.location.pathname,
    search: window.location.search,
    hash: window.location.hash,
    validUntil: Date.now() + 3 * 60 * 1000,
  })
  let urlToRedirectTo
  if (MAIN_DOMAIN) {
    const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https'
    urlToRedirectTo = `${protocol}://login.${MAIN_DOMAIN}/auth/login`
  } else {
    urlToRedirectTo = optionalCustomLogoutRedirect ?? '/auth/login'
  }
  if (triggerOauth2SSOLoginAutomatically) {
    urlToRedirectTo = OAUTH_2_SSO_LOGIN_URL
  } else if (triggerSamlLoginAutomatically) {
    urlToRedirectTo = SAML_LOGIN_URL
  }

  if (window.MAIN_DOMAIN) {
    // User is no longer authenticated, so no point in the landing page trying to link
    // users to theirsubdomain.retool.com (since they'll just get redirected to login)
    cookies.remove('subdomain', { domain: `.${window.MAIN_DOMAIN}` })
  }

  window.location.href = urlToRedirectTo
}

const logout = async (): Promise<void> => {
  let triggerOauth2SSOLoginAutomatically
  let triggerSamlLoginAutomatically
  let optionalCustomLogoutRedirect

  try {
    const response = await (
      await fetch('/api/logout', {
        method: 'POST',
        credentials: INCLUDE_COOKIES_IN_API_CALLS || 'same-origin',
        headers: merge(JSON_HEADERS, GET_AUTH_HEADERS()),
      })
    ).json()

    optionalCustomLogoutRedirect = response?.optionalCustomLogoutRedirect
    // triggerOauth2SSOLoginAutomatically and triggerSamlLoginAutomatically are set only when the call to /api/logout
    // fails. This happens when the user is not logged in and first loads Retool (since Retool attempts to fetch the
    // user's profile and gets a 401). While hacky, this avoids us having to wait to fetch the user's sso type
    // when we are rendering the login page (before potentially making a redirect)
    triggerOauth2SSOLoginAutomatically = response?.triggerOauth2SSOLoginAutomatically
    triggerSamlLoginAutomatically = response?.triggerSamlLoginAutomatically
  } finally {
    deauthSession({
      optionalCustomLogoutRedirect,
      triggerOauth2SSOLoginAutomatically,
      triggerSamlLoginAutomatically,
    })
  }
}

export default logout

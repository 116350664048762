import cookies from 'js-cookie'
import Immutable from 'immutable'
import * as Sentry from '@sentry/browser'
import { browserHistory } from 'react-router'
import { normalize, schema } from 'normalizr'
const { Map } = require('immutable')
import { callApi } from 'store/callApi'
import { processLoginRedirect } from 'store/session'
import _ from 'lodash'
import { byteArrayToImgBase64String, hexColorToRgbColor, logger } from 'common/utils'
import { retoolAnalyticsError, retoolAnalyticsIdentify, retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { Icon, Message } from 'components/design-system'
import { showSuccess } from 'components/standards/Modal'
import {
  RECEIVE_PAGE_CREATE,
  RECEIVE_PAGE_LOAD,
  REQUEST_TEMPLATE_CLONE,
  RECEIVE_TEMPLATE_CLONE,
  FAILURE_TEMPLATE_CLONE,
} from 'store/appModel/pages'
import {
  FAILURE_DELETE_APP_THEME,
  FAILURE_DELETE_INSTRUMEMTATION_INTEGRATION,
  FAILURE_DISABLE_EXPERIMENT,
  FAILURE_ENABLE_EXPERIMENT,
  FAILURE_INVITE_SUGGESTION,
  FAILURE_NEW_AUTH_TOKEN,
  FAILURE_ORGANIZATION_EXPERIMENTS,
  FAILURE_PATCH_APP_THEME,
  FAILURE_PATCH_INSTRUMEMTATION_INTEGRATION,
  FAILURE_GET_INSTRUMEMTATION_INTEGRATIONS,
  RECEIVE_GET_INSTRUMEMTATION_INTEGRATIONS,
  REQUEST_GET_INSTRUMEMTATION_INTEGRATIONS,
  FAILURE_REMOVE_ORGANIZATION_THEME,
  FAILURE_RESET_2FA,
  FAILURE_SET_DEFAULT_APP_THEME,
  FAILURE_UPDATE_INVITE_SUGGESTION,
  FAILURE_UPDATE_ORGANIZATION_THEME,
  FAILURE_VIEW_INVITE_SUGGESTION,
  RECEIVE_CREATE_GROUP,
  RECEIVE_DELETE_APP_THEME,
  RECEIVE_DELETE_INSTRUMEMTATION_INTEGRATION,
  RECEIVE_DISABLE_EXPERIMENT,
  RECEIVE_ENABLE_EXPERIMENT,
  RECEIVE_INVITE_SUGGESTION,
  RECEIVE_NEW_AUTH_TOKEN,
  RECEIVE_ORGANIZATION_EXPERIMENTS,
  RECEIVE_PATCH_APP_THEME,
  RECEIVE_PATCH_INSTRUMEMTATION_INTEGRATION,
  RECEIVE_PUT_WORKSPACE,
  RECEIVE_REMOVE_ORGANIZATION_THEME,
  RECEIVE_RESET_2FA,
  RECEIVE_SET_DEFAULT_APP_THEME,
  RECEIVE_UPDATE_INVITE_SUGGESTION,
  RECEIVE_UPDATE_ORGANIZATION_THEME,
  RECEIVE_VIEW_INVITE_SUGGESTION,
  REQUEST_DELETE_APP_THEME,
  REQUEST_DELETE_INSTRUMEMTATION_INTEGRATION,
  REQUEST_DISABLE_EXPERIMENT,
  REQUEST_ENABLE_EXPERIMENT,
  REQUEST_INVITE_SUGGESTION,
  REQUEST_NEW_AUTH_TOKEN,
  REQUEST_ORGANIZATION_EXPERIMENTS,
  REQUEST_PATCH_APP_THEME,
  REQUEST_PATCH_INSTRUMEMTATION_INTEGRATION,
  REQUEST_REMOVE_ORGANIZATION_THEME,
  REQUEST_RESET_2FA,
  REQUEST_SET_DEFAULT_APP_THEME,
  REQUEST_UPDATE_INVITE_SUGGESTION,
  REQUEST_UPDATE_ORGANIZATION_THEME,
  REQUEST_VIEW_INVITE_SUGGESTION,
  RESOURCES_URL_PREFIX,
  SWITCH_USER_PLAN,
  SET_GROUPS_FOR_ACCOUNT_RECEIVE,
  GET_PERMISSIONS_RECEIVE,
  SET_GROUP_ADMINS_RECEIVE,
} from 'store/constants'
import {
  AppThemeType,
  PatchInstrumentationIntegrationBodyType,
  TestInstrumentationIntegrationResponseType,
  ThemeType,
  ChangePasswordAPIResultType,
  ChangePasswordFailureType,
  ChangePasswordReceiveType,
  ChangePasswordRequestType,
  DeleteResourceAPIResultType,
  DeleteResourceFailureType,
  DeleteResourceReceiveType,
  DeleteResourceRequestType,
  DisableExperimentFailureType,
  DisableExperimentReceiveType,
  DisableExperimentRequestType,
  EnableExperimentFailureType,
  EnableExperimentReceiveType,
  EnableExperimentRequestType,
  Experiment,
  FavoriteFolderFailureType,
  FavoriteFolderReceiveType,
  FavoriteFolderRequestType,
  FavoritePageFailureType,
  FavoritePageReceiveType,
  FavoritePageRequestType,
  GetBranchesFailureType,
  GetBranchesRequestType,
  GetFlowsFailureType,
  GetFlowsReceiveType,
  GetFlowsRequestType,
  GetOrganizationExperimentsFailureType,
  GetOrganizationExperimentsReceiveType,
  GetOrganizationExperimentsRequestType,
  GetOrganizationFailureType,
  GetOrganizationReceiveType,
  GetOrganizationRequestType,
  GetResourcesFailureType,
  GetResourcesReceiveType,
  GetResourcesRequestType,
  OrganizationType,
  PageType,
  PatchOrganizationAPIResultType,
  PatchOrganizationBodyType,
  PatchOrganizationFailureType,
  PatchOrganizationLibrariesBodyType,
  PatchOrganizationLibrariesFailureType,
  PatchOrganizationLibrariesReceiveType,
  PatchOrganizationLibrariesRequestType,
  PatchOrganizationReceiveType,
  PatchOrganizationRequestType,
  RemoveOrganizationThemeFailureType,
  RemoveOrganizationThemeReceiveType,
  RemoveOrganizationThemeRequestType,
  UnfavoriteFolderFailureType,
  UnfavoriteFolderReceiveType,
  UnfavoriteFolderRequestType,
  UnfavoritePageFailureType,
  UnfavoritePageReceiveType,
  UnfavoritePageRequestType,
  UpdateOrganizationThemeFailureType,
  UpdateOrganizationThemeReceiveType,
  UpdateOrganizationThemeRequestType,
  UpdatePhotoUrlFailureType,
  UpdatePhotoUrlReceiveType,
  UpdatePhotoUrlRequestType,
} from 'store/user.d'

import * as ls from 'local-storage'
import {
  Branch,
  Flow,
  GenericErrorResponse,
  InstrumentationIntegrationType,
  ResourceFromServer,
  UserModel,
} from 'common/records'

import { callInternalApi } from 'networking'
import { JSON_HEADERS } from 'networking/util'

// ------------------------------------
// Constants
// ------------------------------------
import {
  BEGIN_2FA_CHALLENGE,
  CLEAR_RESOURCE_ERROR_MESSAGE,
  FAILURE_BRANCH_CREATE,
  FAILURE_BRANCH_DELETE,
  FAILURE_BRANCH_RENAME,
  FAILURE_BRANCHES,
  FAILURE_CHANGE_PASSWORD,
  FAILURE_CONFIRM_2FA,
  FAILURE_CREATE_RESOURCE,
  FAILURE_DELETE_RESOURCE,
  FAILURE_DISMISS_ONBOARDING_SUGGESTIONS,
  FAILURE_DISMISS_SALES_CTA,
  FAILURE_DISMISS_TUTORIAL_CTA,
  FAILURE_FAVORITE_FOLDER,
  FAILURE_FAVORITE_PAGE,
  FAILURE_FLOWS,
  FAILURE_INVITE_EMAIL,
  FAILURE_ORGANIZATION,
  FAILURE_PATCH_ORGANIZATION,
  FAILURE_PATCH_ORGANIZATION_LIBRARIES,
  FAILURE_PLAN_CHANGE,
  FAILURE_RESOURCE,
  FAILURE_RESOURCES,
  FAILURE_SETUP_2FA,
  FAILURE_STRIPE_CUSTOMER_UPDATE,
  FAILURE_UNFAVORITE_FOLDER,
  FAILURE_UNFAVORITE_PAGE,
  FAILURE_UPDATE_PHOTO_URL,
  FAILURE_UPSERT_FLOW,
  FAILURE_USER_PROFILE,
  FAILURE_VERIFY_2FA_CHALLENGE,
  RECEIVE_ADD_USERS_TO_GROUP,
  RECEIVE_BRANCH_CREATE,
  RECEIVE_BRANCH_DELETE,
  RECEIVE_BRANCH_RENAME,
  RECEIVE_BRANCHES,
  RECEIVE_CHANGE_PASSWORD,
  RECEIVE_CONFIRM_2FA,
  RECEIVE_CREATE_RESOURCE,
  RECEIVE_DELETE_RESOURCE,
  RECEIVE_DISMISS_ONBOARDING_SUGGESTIONS,
  RECEIVE_DISMISS_SALES_CTA,
  RECEIVE_DISMISS_TUTORIAL_CTA,
  RECEIVE_FAVORITE_FOLDER,
  RECEIVE_FAVORITE_PAGE,
  RECEIVE_FLOWS,
  RECEIVE_INVITE_EMAIL,
  RECEIVE_ORGANIZATION,
  RECEIVE_PATCH_GROUP,
  RECEIVE_PATCH_ORGANIZATION,
  RECEIVE_PATCH_ORGANIZATION_LIBRARIES,
  RECEIVE_PLAN_CHANGE,
  RECEIVE_REMOVE_USER,
  RECEIVE_RESET_USER_TWO_FACTOR_AUTH,
  RECEIVE_RESOURCE,
  RECEIVE_RESOURCES,
  RECEIVE_REVOKE_INVITE,
  RECEIVE_SET_PAGES_FOR_GROUP,
  RECEIVE_SET_RESOURCES_FOR_GROUP,
  RECEIVE_SET_USER_ENABLED_FLAG,
  RECEIVE_SET_USERS_FOR_GROUP,
  RECEIVE_SETUP_2FA,
  RECEIVE_STRIPE_CUSTOMER_UPDATE,
  RECEIVE_UNFAVORITE_FOLDER,
  RECEIVE_UNFAVORITE_PAGE,
  RECEIVE_UPDATE_PHOTO_URL,
  RECEIVE_UPSERT_FLOW,
  RECEIVE_USER_PROFILE,
  RECEIVE_VERIFY_2FA_CHALLENGE,
  REQUEST_BRANCH_CREATE,
  REQUEST_BRANCH_DELETE,
  REQUEST_BRANCH_RENAME,
  REQUEST_BRANCHES,
  REQUEST_CHANGE_PASSWORD,
  REQUEST_CONFIRM_2FA,
  REQUEST_CREATE_RESOURCE,
  REQUEST_DELETE_RESOURCE,
  REQUEST_DISMISS_ONBOARDING_SUGGESTIONS,
  REQUEST_DISMISS_SALES_CTA,
  REQUEST_DISMISS_TUTORIAL_CTA,
  REQUEST_FAVORITE_FOLDER,
  REQUEST_FAVORITE_PAGE,
  REQUEST_FLOWS,
  REQUEST_INVITE_EMAIL,
  REQUEST_ORGANIZATION,
  REQUEST_PATCH_ORGANIZATION,
  REQUEST_PATCH_ORGANIZATION_LIBRARIES,
  REQUEST_PLAN_CHANGE,
  REQUEST_RESOURCE,
  REQUEST_RESOURCES,
  REQUEST_SETUP_2FA,
  REQUEST_STRIPE_CUSTOMER_UPDATE,
  REQUEST_UNFAVORITE_FOLDER,
  REQUEST_UNFAVORITE_PAGE,
  REQUEST_UPDATE_PHOTO_URL,
  REQUEST_UPSERT_FLOW,
  REQUEST_USER_PROFILE,
  REQUEST_VERIFY_2FA_CHALLENGE,
  UPDATE_GROUP_RECEIVE,
  SET_ACCOUNTS_FOR_GROUP_RECEIVE,
} from './constants'
import { RetoolDispatch, RetoolState, RetoolThunk } from 'store'
import React from 'react'
import { ONBOARDING_URL_RESOURCES } from 'retoolConstants'
import { processCustomPlatformLevelAuthSteps } from './platformLevelAuthSteps'
import { APPLICATION, INVITE, ONBOARDING_SUGGESTIONS_DISMISSED, RESOURCE } from './userConstants'
import { ONBOARDING_START } from 'store/onboarding/constants'
import { recalculateTemplate } from 'store/appModel/model'
import { SafeAny } from 'common/types'
import type {
  UpdateGroupResult,
  SetGroupsForAccountResult,
  SetAccountsForGroupResult,
  GetPermissionsResult,
  SetGroupAdminsResult,
  // eslint-disable-next-line import/no-restricted-paths
} from 'routes/Settings/modules/groups'
import logout from 'networking/logout'

// todo: move this schema info to a separate file (i) so that it's reusable and (ii)
// so that the variable names don't conflict with function params in our action
// creators
const group = new schema.Entity('groups')
const user = new schema.Entity('users')
const userInvite = new schema.Entity('userInvites')
const userInviteSuggestion = new schema.Entity('userInviteSuggestions')
const page = new schema.Entity('pages')
const folder = new schema.Entity('folders')
const resource = new schema.Entity('resources')
const protectedAppEnvVarsSet = new schema.Entity('protectedAppEnvVarsSet')

const userGroup = new schema.Entity('userGroups', { user, group })
const userInviteGroup = new schema.Entity('userInviteGroups', { userInvite, group })
const groupPage = new schema.Entity('groupPages', { group, page })
const groupFolderDefault = new schema.Entity('groupFolderDefaults', { group, folder })
const groupResource = new schema.Entity('groupResources', { group, resource })
const organization = new schema.Entity('organization', {
  users: [user],
  groups: [group],
  pages: [page],
  userInvites: [userInvite],
  userGroups: [userGroup],
  userInviteGroups: [userInviteGroup],
  userInviteSuggestions: [userInviteSuggestion],
  groupPages: [groupPage],
  groupFolderDefaults: [groupFolderDefault],
  groupResources: [groupResource],
  protectedAppsEnvVarsSet: [protectedAppEnvVarsSet],
})

export const UPDATE_USER_EXPERIMENT_VALUES = 'UPDATE_USER_EXPERIMENT_VALUES'

// ------------------------------------
// Actions
// ------------------------------------

export function setup2FA() {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/user/setup2FA',
        method: 'POST',
        headers: JSON_HEADERS,
        types: [REQUEST_SETUP_2FA, RECEIVE_SETUP_2FA, FAILURE_SETUP_2FA],
      }),
    )
    if (result.type === RECEIVE_SETUP_2FA) {
      return result.payload.qrCodeDataUrl
    }
  }
}

export function confirm2FASetup(twoFactorAuthToken: any) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/user/confirm2FASetup',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ twoFactorAuthToken }),
        types: [REQUEST_CONFIRM_2FA, RECEIVE_CONFIRM_2FA, FAILURE_CONFIRM_2FA],
      }),
    )
    if (result.type === RECEIVE_CONFIRM_2FA) {
      Message.success('Successfully set up 2fa')
      dispatch(getProfile())
      return true
    } else {
      Message.error(_.get(result, ['payload', 'response', 'message'], 'Invalid token'))
      return false
    }
  }
}

export function verify2FAChallenge(twoFactorAuthToken: any) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/user/verify2FAChallenge',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ twoFactorAuthToken }),
        types: [REQUEST_VERIFY_2FA_CHALLENGE, RECEIVE_VERIFY_2FA_CHALLENGE, FAILURE_VERIFY_2FA_CHALLENGE],
      }),
    )
    if (result.type === RECEIVE_VERIFY_2FA_CHALLENGE) {
      Message.success('Success!')
      dispatch(getProfile())
    } else {
      Message.error(_.get(result, ['payload', 'response', 'message'], 'Invalid token'))
    }
  }
}

export function twoFactorChallenge() {
  return async (dispatch: any, getState: () => RetoolState) => {
    if (!getState().user.get('twoFactorAuthChallenged')) {
      if (!ls.get('redirectOnLogin')) {
        ls.set('redirectOnLogin', {
          pathname: window.location.pathname,
          search: window.location.search,
          hash: window.location.hash,
          validUntil: Date.now() + 3 * 60 * 1000,
        })
      }
      browserHistory.push('/two-factor-challenge')
      dispatch({
        type: BEGIN_2FA_CHALLENGE,
      })
    }
  }
}

export function reset2FAChallenge() {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/user/reset2FA',
        method: 'POST',
        headers: JSON_HEADERS,
        types: [REQUEST_RESET_2FA, RECEIVE_RESET_2FA, FAILURE_RESET_2FA],
      }),
    )
    if (result.type === RECEIVE_RESET_2FA) {
      Message.success('Successfully reset 2FA')
      dispatch(getProfile())
      return true
    } else {
      Message.error(_.get(result, ['payload', 'response', 'message'], 'Unable to reset 2FA'))
      return false
    }
  }
}

export function getProfile(relaxLoginSubdomainRestrictions: any = false) {
  return async (dispatch: any) => {
    // Check if _ignoreCustomPlatformLevelAuthSteps query param is set before /api/user redirects occur
    const urlParams = new URLSearchParams(window.location.search)
    const ignoreCustomPlatformLevelAuthSteps = urlParams.get('_ignoreCustomPlatformLevelAuthSteps')

    const response = await dispatch(
      callApi({
        endpoint: '/api/user',
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Relax-Login-Subdomain-Restrictions': relaxLoginSubdomainRestrictions,
        },
        types: [REQUEST_USER_PROFILE, RECEIVE_USER_PROFILE, FAILURE_USER_PROFILE],
      }),
    )

    const {
      payload: { user, org, experimentValues },
      type,
    } = response

    if (experimentValues) {
      // log enabled experiments
      Object.keys(experimentValues).forEach((experiment) => {
        Sentry.addBreadcrumb({
          level: Sentry.Severity.Info,
          category: 'experiment',
          message: experiment,
        })
      })
    }

    if (type === RECEIVE_USER_PROFILE) {
      const {
        user: { id, email, firstName, lastName, groups },
      } = response.payload

      const update = {
        id,
        email,
        firstName,
        lastName,
        fullName: `${firstName} ${lastName}`,
        groups: groups.map((g: any) => _.pick(g, ['id', 'name', 'createdAt', 'updatedAt'])),
      }

      setTimeout(() => dispatch(recalculateTemplate('current_user', null, update)))

      const name = user.firstName ? `${user.firstName} ${user.lastName}` : user.email
      if (window.Intercom) {
        window.Intercom('update', { name, email, user_id: user.sid, user_hash: user.intercomUserIdHash })
      } else {
        logger.warn(`window.Intercom did not exist`)
      }
      if (window?.FS?.identify) {
        window.FS.identify(user.email, {
          displayName: name,
          email: user.email,
        })
      } else {
        logger.warn('window.FS.identify did not exist')
      }

      if (!user.email) {
        retoolAnalyticsError('invalidEmailError', 'Current users email value is invalid', {
          location: 'user.ts-getProfile',
          name,
        })
      }

      retoolAnalyticsTrack('Info Logged', {
        type: 'Profile Received',
        user: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        },
        location: 'user.ts',
      })
      retoolAnalyticsIdentify()

      // Now exchange our token for a new one from the server
      await dispatch(refreshAccessToken())

      // Now force 2fa set up if required
      if (org.twoFactorAuthRequired && !user.twoFactorAuthEnabled) {
        if (window.location.pathname.indexOf('/two-factor-challenge-setup') === 0) {
          return
        } else {
          return browserHistory.push('/two-factor-challenge-setup')
        }
      }

      if (org.platformLevelAuthSteps && !ignoreCustomPlatformLevelAuthSteps) {
        await processCustomPlatformLevelAuthSteps()
      }

      // Now redirect the user if needed
      processLoginRedirect()
      return org
    } else if (type === FAILURE_USER_PROFILE) {
      const httpStatus = response.payload.status
      const errorMessage = _.get(response, ['payload', 'response', 'message'])
      retoolAnalyticsError('getProfileError', errorMessage, {
        httpStatus,
        httpStatusText: response.payload.statusText,
      })

      if (httpStatus >= 500) {
        // Internal server error or a timeout. Notify the user to refreshthe page.
        browserHistory.push('/500')
      } else if (httpStatus === 401) {
        // Unauthorized. Logout the user immediately
        logout()
      } else if (httpStatus === 404) {
        // Tried to access endpoint on a login.xyz subdomain. Logout the user immediately
        logout()
      } else if (httpStatus === 400 && errorMessage === '2FA Challenge') {
        dispatch(twoFactorChallenge())
      } else {
        // eslint-disable-next-line no-console
        console.log('Encountered unexpected error: ', response)
      }
    }
  }
}

let currentRefreshAccessTokenIntervalId: number | null = null

export function refreshAccessToken() {
  return async (dispatch: any) => {
    if (currentRefreshAccessTokenIntervalId) {
      clearInterval(currentRefreshAccessTokenIntervalId)
    }

    const callRefreshTokenApi = () => {
      dispatch(
        callApi({
          endpoint: '/api/refreshtoken',
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          types: [REQUEST_NEW_AUTH_TOKEN, RECEIVE_NEW_AUTH_TOKEN, FAILURE_NEW_AUTH_TOKEN],
        }),
      )
    }
    currentRefreshAccessTokenIntervalId = window.setInterval(callRefreshTokenApi, 30 * 60 * 1000)
    callRefreshTokenApi()
  }
}

export const redirectToOnboardingPage = () => {
  cookies.remove('onboardingUrl')
  browserHistory.push('/editor/Onboarding Page')
}

export function editResource(
  resourceName: any,
  environment: any,
  changeset: any,
  triggeredByOAuth = false,
  allowNameOverwriting = false,
) {
  return async (dispatch: any) => {
    const result: { type: typeof RECEIVE_RESOURCE } | (GenericErrorResponse & { type: '' }) = await dispatch(
      callApi({
        endpoint: `/api/resources/names/${encodeURIComponent(resourceName)}`,
        body: JSON.stringify({ environment, changeset, allowNameOverwriting }),
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        types: [REQUEST_RESOURCE, RECEIVE_RESOURCE, FAILURE_RESOURCE],
      }),
    )

    if (result.type === RECEIVE_RESOURCE) {
      Message.success('Edited resource')
      retoolAnalyticsTrack('Resource Edited', {
        id: changeset.id,
        resourceName,
        resourceType: changeset.type,
        environment,
      })

      if (!triggeredByOAuth && cookies.get('onboardingUrl') === ONBOARDING_URL_RESOURCES) {
        dispatch({ type: ONBOARDING_START })
        redirectToOnboardingPage()
        return
      }
    } else {
      retoolAnalyticsError(
        'resourceEditError',
        result.payload.response ? result.payload.response.message : 'unknown error',
        {
          resourceType: changeset.type,
          resourceName,
          httpStatus: result.payload.status,
          httpStatusText: result.payload.statusText,
        },
      )
    }
    return result
  }
}

export function createResource(resource: any, _isOAuth = false, hideSuccessModal = false, triggeredByOAuth = false) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: `/api/resources/`,
        body: JSON.stringify(resource),
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        // failureResoureType = GenericErrorResponse
        types: [REQUEST_CREATE_RESOURCE, RECEIVE_CREATE_RESOURCE, FAILURE_CREATE_RESOURCE],
      }),
    )

    if (result.type === RECEIVE_CREATE_RESOURCE) {
      try {
        retoolAnalyticsTrack(
          'Resource Created',
          {
            id: resource.id,
            resourceName: resource.displayName,
            resourceType: resource.type,
            environment: 'production',
            isResourceOnboarding: !!cookies.get('onboardingUrl'),
          },
          { flush: true },
        )
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(`Unable to send Resource Created analytics event for ${resource.type}`, err)
      }
      if (!hideSuccessModal && triggeredByOAuth && !cookies.get('onboardingUrl')) {
        showSuccess({
          title: <div className="fs-16">Resource created and saved</div>,
          icon: <Icon style={{ marginLeft: '-4px', marginBottom: '5px' }} type="larger-check-circle" />,
          className: 'resource-created-success',
          okText: 'Done',
          content: 'You can now test the OAuth integration by clicking on the Sign In button after closing this modal',
          cancelText: '',
        })
      }
      return result
    } else {
      retoolAnalyticsError('resourceCreateError', result.payload.response.message, {
        resourceType: resource.type,
        resourceName: resource.displayName,
        httpStatus: result.payload.status,
        httpStatusText: result.payload.statusText,
      })
      return result
    }
  }
}

export function clearResourceErrorMessage() {
  return {
    type: CLEAR_RESOURCE_ERROR_MESSAGE,
  }
}

export function upsertFlow(flow: Flow) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: `/api/flows/`,
        body: JSON.stringify({ flow }),
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        types: [REQUEST_UPSERT_FLOW, RECEIVE_UPSERT_FLOW, FAILURE_UPSERT_FLOW],
      }),
    )

    if (result.type === RECEIVE_UPSERT_FLOW) {
      const created = _.get(result, ['payload', 'response', 'message'])
      if (created) {
        const content = (
          <div className="fs-13">
            {'Next, build an app with your new '}
            <b>{flow.name}</b>
            {' flow'}
          </div>
        )
        showSuccess({
          title: <div className="fs-16">Flow created</div>,
          icon: <Icon style={{ marginLeft: '-4px', marginBottom: '5px' }} type="larger-check-circle" />,
          className: 'resource-created-success',
          okText: 'Done',
          content,
          cancelText: '',
        })
      } else {
        Message.success('Flow saved')
      }
    }
    if (result.type === FAILURE_UPSERT_FLOW) {
      const message = _.get(result, ['payload', 'response', 'message']) || 'Failed to create flow'
      Message.error(message)
    }
  }
}

/** TODO: the calls to refresh the organization are not efficient **/
export function inviteToOrg(inviteeEmails: any, defaultGroupIds: any, isResend = false) {
  return async (dispatch: any) => {
    const response = await dispatch(
      callApi({
        endpoint: '/api/organization/admin/bulkInviteUsers',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ inviteeEmails, defaultGroupIds, isResend }),
        types: [
          REQUEST_INVITE_EMAIL,
          {
            type: RECEIVE_INVITE_EMAIL,
            meta: { originalInvitees: inviteeEmails },
          },
          FAILURE_INVITE_EMAIL,
        ],
      }),
    )

    if (response.type === FAILURE_INVITE_EMAIL) {
      const errorRaised = _.get(response, ['payload', 'response', 'message'], 'Unknown error')
      retoolAnalyticsError('inviteUsersError', errorRaised, {
        inviteeEmails,
      })
      throw new Error(errorRaised)
    } else {
      retoolAnalyticsTrack('Users Invited', {
        inviteeEmails,
        numInviteesInvited: inviteeEmails.length,
      })
    }
    return response.payload
  }
}

export function markUpdateAsViewed(suggestionIds: string[]) {
  return async (dispatch: any) => {
    const response = await dispatch(
      callApi({
        endpoint: `/api/organization/userInviteSuggestions/markUpdatesViewed`,
        method: 'PATCH',
        body: JSON.stringify({ suggestionIds }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        types: [REQUEST_VIEW_INVITE_SUGGESTION, RECEIVE_VIEW_INVITE_SUGGESTION, FAILURE_VIEW_INVITE_SUGGESTION],
      }),
    )

    return response.payload
  }
}

export function suggestUsersToInviteToOrg(suggestedEmails: string[]) {
  return async (dispatch: any) => {
    const response = await dispatch(
      callApi({
        endpoint: '/api/organization/bulkSuggestUsers',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ suggestedEmails }),
        types: [
          REQUEST_INVITE_SUGGESTION,
          {
            type: RECEIVE_INVITE_SUGGESTION,
            meta: { originalSuggestions: suggestedEmails },
          },
          FAILURE_INVITE_SUGGESTION,
        ],
      }),
    )

    if (response.type === FAILURE_INVITE_SUGGESTION) {
      const errorRaised = _.get(response, ['payload', 'response', 'message'], 'Unknown error')
      retoolAnalyticsError('suggestUsersError', errorRaised, {
        suggestedEmails,
      })
      throw new Error(errorRaised)
    } else {
      retoolAnalyticsTrack('Users Invited', {
        suggestedEmails,
        numInviteesSuggested: suggestedEmails.length,
      })
    }
    return response.payload
  }
}

export function updateUserSuggestionStatus(
  userInviteSuggestionId: number,
  userInviteSuggestionEmail: string,
  status: 'approve' | 'decline',
  defaultGroupIds: string[],
) {
  return async (dispatch: any) => {
    const response = await dispatch(
      callApi({
        endpoint: `/api/organization/admin/userInviteSuggestions/${userInviteSuggestionId}/${status}`,
        method: 'PATCH',
        headers: JSON_HEADERS,
        body: JSON.stringify(status === 'approve' ? { defaultGroupIds } : {}),
        types: [
          REQUEST_UPDATE_INVITE_SUGGESTION,
          {
            type: RECEIVE_UPDATE_INVITE_SUGGESTION,
            meta: { userInviteSuggestionId, userInviteSuggestionEmail, status },
          },
          FAILURE_UPDATE_INVITE_SUGGESTION,
        ],
      }),
    )

    if (response.type === FAILURE_UPDATE_INVITE_SUGGESTION) {
      const errorRaised = _.get(response, ['payload', 'response', 'message'], 'Unknown error')
      retoolAnalyticsError(`${status}UserSuggestionsError`, errorRaised, {
        userInviteSuggestionId,
      })
      throw new Error(errorRaised)
    } else {
      retoolAnalyticsTrack(`User Suggestion ${status}`, {
        userInviteSuggestionId,
      })
    }
    return response.payload
  }
}

export function dismissOnboardingSuggestions() {
  return async (dispatch: any) => {
    const response = await dispatch(
      callApi({
        endpoint: '/api/onboarding/dismissStages',
        method: 'POST',
        headers: JSON_HEADERS,
        types: [
          REQUEST_DISMISS_ONBOARDING_SUGGESTIONS,
          RECEIVE_DISMISS_ONBOARDING_SUGGESTIONS,
          FAILURE_DISMISS_ONBOARDING_SUGGESTIONS,
        ],
      }),
    )

    if (response.type === RECEIVE_DISMISS_ONBOARDING_SUGGESTIONS) {
      retoolAnalyticsTrack('Dismissed Onboarding Suggestions')
    }

    return response.payload
  }
}

export function dismissSalesCTA() {
  return async (dispatch: any) => {
    const response = await dispatch(
      callApi({
        endpoint: '/api/onboarding/dismissSalesCTA',
        method: 'POST',
        headers: JSON_HEADERS,
        types: [REQUEST_DISMISS_SALES_CTA, RECEIVE_DISMISS_SALES_CTA, FAILURE_DISMISS_SALES_CTA],
      }),
    )

    if (response.type === RECEIVE_DISMISS_SALES_CTA) {
      retoolAnalyticsTrack('Dismissed Sales CTA')
    }

    return response.payload
  }
}

export function dismissTutorialCTA() {
  return async (dispatch: any) => {
    const response = await dispatch(
      callApi({
        endpoint: '/api/onboarding/dismissTutorialCTA',
        method: 'POST',
        headers: JSON_HEADERS,
        types: [REQUEST_DISMISS_TUTORIAL_CTA, RECEIVE_DISMISS_TUTORIAL_CTA, FAILURE_DISMISS_TUTORIAL_CTA],
      }),
    )

    if (response.type === RECEIVE_DISMISS_TUTORIAL_CTA) {
      retoolAnalyticsTrack('Dismissed Tutorial CTA')
    }

    return response.payload
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

function fromJS(obj: any, defaultValue = Immutable.Map()) {
  if (obj == null) {
    return defaultValue
  }
  return Immutable.fromJS(obj)
}

// ------------------------------------
// Synchronous Actions
// ------------------------------------
const getResourcesRequest = (): GetResourcesRequestType => {
  return {
    type: REQUEST_RESOURCES,
  }
}

const getResourcesReceive = (result: ResourceFromServer[]): GetResourcesReceiveType => {
  return {
    type: RECEIVE_RESOURCES,
    payload: result,
  }
}

const getResourcesFailure = (errorMessage: string): GetResourcesFailureType => {
  return {
    type: FAILURE_RESOURCES,
    payload: {
      error: errorMessage,
    },
  }
}

const getFlowsRequest = (): GetFlowsRequestType => {
  return {
    type: REQUEST_FLOWS,
  }
}

const getFlowsReceive = (result: { flows: Flow[] }): GetFlowsReceiveType => {
  return {
    type: RECEIVE_FLOWS,
    payload: result,
  }
}

const getFlowsFailure = (errorMessage: string): GetFlowsFailureType => {
  return {
    type: FAILURE_FLOWS,
    payload: {
      error: errorMessage,
    },
  }
}

const getBranchesRequest = (): GetBranchesRequestType => {
  return {
    type: REQUEST_BRANCHES,
  }
}

// todo type this to GetBranchesReceiveType
const getBranchesReceive = (result: { branches: any[] }) => {
  return {
    type: RECEIVE_BRANCHES,
    payload: result,
  }
}

const getBranchesFailure = (errorMessage: string): GetBranchesFailureType => {
  return {
    type: FAILURE_BRANCHES,
    payload: {
      error: errorMessage,
    },
  }
}

const getOrganizationRequest = (): GetOrganizationRequestType => {
  return {
    type: REQUEST_ORGANIZATION,
  }
}

const getOrganizationReceive = (result: { org: OrganizationType }): GetOrganizationReceiveType => {
  return {
    type: RECEIVE_ORGANIZATION,
    payload: result,
  }
}

const getOrganizationFailure = (errorMessage: string): GetOrganizationFailureType => {
  return {
    type: FAILURE_ORGANIZATION,
    payload: {
      error: errorMessage,
    },
  }
}

const patchOrganizationRequest = (): PatchOrganizationRequestType => {
  return {
    type: REQUEST_PATCH_ORGANIZATION,
  }
}

const getInstrumentationIntegrationRequest = () => ({
  type: REQUEST_GET_INSTRUMEMTATION_INTEGRATIONS,
})

const getInstrumentationIntegrationReceive = (integrations: InstrumentationIntegrationType[]) => ({
  type: RECEIVE_GET_INSTRUMEMTATION_INTEGRATIONS,
  payload: {
    integrations,
  },
})

const getInstrumentationIntegrationFailure = (error: string) => ({
  type: FAILURE_GET_INSTRUMEMTATION_INTEGRATIONS,
  payload: {
    error,
  },
})

const patchInstrumentationIntegrationRequest = () => ({
  type: REQUEST_PATCH_INSTRUMEMTATION_INTEGRATION,
})

const patchInstrumentationIntegrationReceive = (integration: InstrumentationIntegrationType) => ({
  type: RECEIVE_PATCH_INSTRUMEMTATION_INTEGRATION,
  payload: {
    integration,
  },
})

const patchInstrumentationIntegrationFailure = (integration: InstrumentationIntegrationType) => ({
  type: FAILURE_PATCH_INSTRUMEMTATION_INTEGRATION,
  payload: {
    integration,
  },
})

const deleteInstrumentationIntegrationRequest = () => ({
  type: REQUEST_DELETE_INSTRUMEMTATION_INTEGRATION,
})

const deleteInstrumentationIntegrationReceive = (integration: string) => ({
  type: RECEIVE_DELETE_INSTRUMEMTATION_INTEGRATION,
  payload: { integration },
})

const deleteInstrumentationIntegrationFailure = (integration: string) => ({
  type: FAILURE_DELETE_INSTRUMEMTATION_INTEGRATION,
  payload: { integration },
})

const patchAppThemeRequest = () => ({
  type: REQUEST_PATCH_APP_THEME,
})

const patchAppThemeReceive = (appTheme: AppThemeType) => ({
  type: RECEIVE_PATCH_APP_THEME,
  payload: { appTheme },
})

const patchAppThemeFailure = (err: string) => ({
  type: FAILURE_PATCH_APP_THEME,
  payload: { err },
})

const setDefaultAppThemeRequest = () => ({
  type: REQUEST_SET_DEFAULT_APP_THEME,
})

const setDefaultAppThemeReceive = (id: number) => ({
  type: RECEIVE_SET_DEFAULT_APP_THEME,
  payload: {
    id,
  },
})

const setDefaultAppThemeFailure = (err: string) => ({
  type: FAILURE_SET_DEFAULT_APP_THEME,
  payload: { err },
})

const deleteAppThemeRequest = () => ({
  type: REQUEST_DELETE_APP_THEME,
})

const deleteAppThemeReceive = (id: string) => ({
  type: RECEIVE_DELETE_APP_THEME,
  payload: { id },
})

const deleteAppThemeFailure = (id: string) => ({
  type: FAILURE_DELETE_APP_THEME,
  payload: { id },
})

const updateOrganizationThemeRequest = (): UpdateOrganizationThemeRequestType => {
  return {
    type: REQUEST_UPDATE_ORGANIZATION_THEME,
  }
}

const updateOrganizationThemeReceive = (theme: ThemeType): UpdateOrganizationThemeReceiveType => {
  Message.success('Organization theme updated')
  return {
    type: RECEIVE_UPDATE_ORGANIZATION_THEME,
    payload: {
      theme,
    },
  }
}

const updateOrganizationThemeFailure = (errorMessage: string): UpdateOrganizationThemeFailureType => {
  Message.error(errorMessage)
  return {
    type: FAILURE_UPDATE_ORGANIZATION_THEME,
    payload: {
      error: errorMessage,
    },
  }
}

const removeOrganizationThemeRequest = (): RemoveOrganizationThemeRequestType => {
  return {
    type: REQUEST_REMOVE_ORGANIZATION_THEME,
  }
}

const removeOrganizationThemeReceive = (): RemoveOrganizationThemeReceiveType => {
  Message.success('Organization theme removed')
  return {
    type: RECEIVE_REMOVE_ORGANIZATION_THEME,
  }
}

const removeOrganizationThemeFailure = (errorMessage: string): RemoveOrganizationThemeFailureType => {
  Message.error(errorMessage)
  return {
    type: FAILURE_REMOVE_ORGANIZATION_THEME,
    payload: {
      error: errorMessage,
    },
  }
}

const patchOrganizationReceive = (result: PatchOrganizationAPIResultType): PatchOrganizationReceiveType => {
  Message.success(result.message)
  return {
    type: RECEIVE_PATCH_ORGANIZATION,
    payload: result,
  }
}

const patchOrganizationFailure = (errorMessage: string): PatchOrganizationFailureType => {
  Message.error(errorMessage)
  return {
    type: FAILURE_PATCH_ORGANIZATION,
    payload: {
      error: errorMessage,
    },
  }
}

const patchOrganizationLibrariesRequest = (): PatchOrganizationLibrariesRequestType => {
  return {
    type: REQUEST_PATCH_ORGANIZATION_LIBRARIES,
  }
}

const patchOrganizationLibrariesReceive = (
  result: PatchOrganizationAPIResultType,
): PatchOrganizationLibrariesReceiveType => {
  Message.success(result.message)
  return {
    type: RECEIVE_PATCH_ORGANIZATION_LIBRARIES,
    payload: result,
  }
}

const patchOrganizationLibrariesFailure = (errorMessage: string): PatchOrganizationLibrariesFailureType => {
  Message.error(errorMessage)
  return {
    type: FAILURE_PATCH_ORGANIZATION_LIBRARIES,
    payload: {
      error: errorMessage,
    },
  }
}

const deleteResourceRequest = (): DeleteResourceRequestType => {
  return {
    type: REQUEST_DELETE_RESOURCE,
  }
}

const deleteResourceReceive = (result: DeleteResourceAPIResultType): DeleteResourceReceiveType => {
  Message.success('Deleted resource')
  browserHistory.push(RESOURCES_URL_PREFIX)
  return {
    type: RECEIVE_DELETE_RESOURCE,
    payload: result,
  }
}

const deleteResourceFailure = (errorMessage: string): DeleteResourceFailureType => {
  Message.error(errorMessage)
  return {
    type: FAILURE_DELETE_RESOURCE,
    payload: {
      error: errorMessage,
    },
  }
}

const favoriteFolderRequest = (): FavoriteFolderRequestType => {
  return {
    type: REQUEST_FAVORITE_FOLDER,
  }
}

const favoriteFolderReceive = (result: {}, favoritedFolderId: number): FavoriteFolderReceiveType => {
  return {
    type: RECEIVE_FAVORITE_FOLDER,
    payload: {
      result,
      favoritedFolderId,
    },
  }
}

const favoriteFolderFailure = (errorMessage: string): FavoriteFolderFailureType => {
  return {
    type: FAILURE_FAVORITE_FOLDER,
    payload: {
      error: errorMessage,
    },
  }
}

const unfavoriteFolderRequest = (): UnfavoriteFolderRequestType => {
  return {
    type: REQUEST_UNFAVORITE_FOLDER,
  }
}

const unfavoriteFolderReceive = (result: {}, unfavoritedFolderId: number): UnfavoriteFolderReceiveType => {
  return {
    type: RECEIVE_UNFAVORITE_FOLDER,
    payload: {
      result,
      unfavoritedFolderId,
    },
  }
}

const unfavoriteFolderFailure = (errorMessage: string): UnfavoriteFolderFailureType => {
  return {
    type: FAILURE_UNFAVORITE_FOLDER,
    payload: {
      error: errorMessage,
    },
  }
}

const favoritePageRequest = (): FavoritePageRequestType => {
  return {
    type: REQUEST_FAVORITE_PAGE,
  }
}

const favoritePageReceive = (result: {}, favoritedPageId: number): FavoritePageReceiveType => {
  return {
    type: RECEIVE_FAVORITE_PAGE,
    payload: {
      result,
      favoritedPageId,
    },
  }
}

const favoritePageFailure = (errorMessage: string): FavoritePageFailureType => {
  return {
    type: FAILURE_FAVORITE_PAGE,
    payload: {
      error: errorMessage,
    },
  }
}

const unfavoritePageRequest = (): UnfavoritePageRequestType => {
  return {
    type: REQUEST_UNFAVORITE_PAGE,
  }
}

const unfavoritePageReceive = (result: {}, unfavoritedPageId: number): UnfavoritePageReceiveType => {
  return {
    type: RECEIVE_UNFAVORITE_PAGE,
    payload: {
      result,
      unfavoritedPageId,
    },
  }
}

const unfavoritePageFailure = (errorMessage: string): UnfavoritePageFailureType => {
  return {
    type: FAILURE_UNFAVORITE_PAGE,
    payload: {
      error: errorMessage,
    },
  }
}

const updatePhotoUrlRequest = (): UpdatePhotoUrlRequestType => {
  return {
    type: REQUEST_UPDATE_PHOTO_URL,
  }
}

const updatePhotoUrlReceive = (result: PageType): UpdatePhotoUrlReceiveType => {
  return {
    type: RECEIVE_UPDATE_PHOTO_URL,
    payload: result,
  }
}

const updatePhotoUrlFailure = (errorMessage: string): UpdatePhotoUrlFailureType => {
  return {
    type: FAILURE_UPDATE_PHOTO_URL,
    payload: {
      error: errorMessage,
    },
  }
}

const changePasswordRequest = (): ChangePasswordRequestType => {
  return {
    type: REQUEST_CHANGE_PASSWORD,
  }
}

const changePasswordReceive = (result: ChangePasswordAPIResultType): ChangePasswordReceiveType => {
  Message.success(result.message)
  return {
    type: RECEIVE_CHANGE_PASSWORD,
    payload: result,
  }
}

const changePasswordFailure = (errorMessage: string): ChangePasswordFailureType => {
  Message.error(errorMessage)
  return {
    type: FAILURE_CHANGE_PASSWORD,
    payload: {
      error: errorMessage,
    },
  }
}

const getOrganizationExperimentsRequest = (): GetOrganizationExperimentsRequestType => {
  return {
    type: REQUEST_ORGANIZATION_EXPERIMENTS,
  }
}

const getOrganizationExperimentsReceive = (
  result: GetOrganizationExperimentsReceiveType['payload'],
): GetOrganizationExperimentsReceiveType => {
  return {
    type: RECEIVE_ORGANIZATION_EXPERIMENTS,
    payload: result,
  }
}

const getOrganizationExperimentsFailure = (error: string): GetOrganizationExperimentsFailureType => {
  return {
    type: FAILURE_ORGANIZATION_EXPERIMENTS,
    payload: { error },
  }
}

const enableExperimentRequest = (): EnableExperimentRequestType => {
  return {
    type: REQUEST_ENABLE_EXPERIMENT,
  }
}

const enableExperimentReceive = (result: EnableExperimentReceiveType['payload']): EnableExperimentReceiveType => {
  return {
    type: RECEIVE_ENABLE_EXPERIMENT,
    payload: result,
  }
}

const enableExperimentFailure = (error: string): EnableExperimentFailureType => {
  return {
    type: FAILURE_ENABLE_EXPERIMENT,
    payload: { error },
  }
}

const disableExperimentRequest = (): DisableExperimentRequestType => {
  return {
    type: REQUEST_DISABLE_EXPERIMENT,
  }
}

const disableExperimentReceive = (result: DisableExperimentReceiveType['payload']): DisableExperimentReceiveType => {
  return {
    type: RECEIVE_DISABLE_EXPERIMENT,
    payload: result,
  }
}

const disableExperimentFailure = (error: string): DisableExperimentFailureType => {
  return {
    type: FAILURE_DISABLE_EXPERIMENT,
    payload: { error },
  }
}

// ------------------------------------
// Async Actions
// ------------------------------------
export const getBranches = (option: { useCache: boolean } = { useCache: false }): RetoolThunk => async (
  dispatch,
  getState,
) => {
  if (option.useCache && getState().user.branches.branches.size > 0) {
    return
  }

  dispatch(getBranchesRequest())
  try {
    const result = await callInternalApi({
      url: '/api/branches',
      method: 'GET',
    })
    dispatch(getBranchesReceive(result))
  } catch (error) {
    dispatch(getBranchesFailure(error.message))
  }
}

export function createBranch(name: string, pageId: string) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/branches',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ name, pageId }),
        types: [REQUEST_BRANCH_CREATE, RECEIVE_BRANCH_CREATE, FAILURE_BRANCH_CREATE],
      }),
    )
    if (result.type === RECEIVE_BRANCH_CREATE) {
      retoolAnalyticsTrack('Branch Created', {
        pageUuid: pageId,
      })
      Message.success('Successfully created branch.')
    } else {
      Message.error(result.payload.response.message)
    }
    return result
  }
}

export interface RenameBranchResult {
  type: string
}

export function renameBranch(id: string, oldName: string, newName: string) {
  return async (dispatch: any): Promise<RenameBranchResult> => {
    const result = await dispatch(
      callApi({
        endpoint: `/api/branches/${id}/rename`,
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ oldName, newName }),
        types: [REQUEST_BRANCH_RENAME, RECEIVE_BRANCH_RENAME, FAILURE_BRANCH_RENAME],
      }),
    )
    if (result.type === RECEIVE_BRANCH_RENAME) {
      Message.success('Successfully renamed branch.')
      retoolAnalyticsTrack('Branch Renamed', {
        pageUuid: id,
      })
    } else {
      Message.error(result.payload.response.message)
    }
    return result
  }
}

export function deleteBranch(id: string) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: `/api/branches/${id}`,
        method: 'DELETE',
        headers: JSON_HEADERS,
        types: [REQUEST_BRANCH_DELETE, RECEIVE_BRANCH_DELETE, FAILURE_BRANCH_DELETE],
      }),
    )
    if (result.type === RECEIVE_BRANCH_DELETE) {
      Message.success('Successfully deleted branch.')
      retoolAnalyticsTrack('Branch Deleted', {
        pageUuid: id,
      })
    } else {
      Message.error(result.payload.response.message)
    }
    return result
  }
}

export const getFlows = () => async (dispatch: RetoolDispatch) => {
  dispatch(getFlowsRequest())
  try {
    const result = await callInternalApi({
      url: '/api/flows',
      method: 'GET',
    })
    dispatch(getFlowsReceive(result))
  } catch (error) {
    dispatch(getFlowsFailure(error.message))
  }
}

export const getResources = (option: { useCache: boolean } = { useCache: false }): RetoolThunk => async (
  dispatch,
  getState,
) => {
  if (option.useCache && getState().user.resources.resources.size > 0) {
    return
  }

  dispatch(getResourcesRequest())
  try {
    const result = await callInternalApi({
      url: '/api/resources',
      method: 'GET',
    })
    dispatch(getResourcesReceive(result))
  } catch (error) {
    dispatch(getResourcesFailure(error.message))
  }
}

export function cloneTemplate(
  templateId: string,
  newPageName?: string,
  folderId?: number,
  createType = 'templateClone',
  base64TemplateDemoResourceOptionOverrides?: string,
) {
  return async (dispatch: any) => {
    const res = await dispatch(
      callApi({
        endpoint: '/api/pages/cloneTemplate',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ templateId, newPageName, folderId, base64TemplateDemoResourceOptionOverrides }),
        types: [REQUEST_TEMPLATE_CLONE, RECEIVE_TEMPLATE_CLONE, FAILURE_TEMPLATE_CLONE],
      }),
    )
    if (res.type === FAILURE_TEMPLATE_CLONE) {
      throw Error(res.payload.response.message)
    }

    // Fetch resources again to obtain cloned resources
    await dispatch(getResources())

    retoolAnalyticsTrack('App Created', {
      createType,
      pageName: res.payload.newPage.name,
      folderId,
      templateId,
      pageUuid: res.payload.newPage.uuid,
    })
    return {
      newPageName: res.payload.newPage.name,
      demoResourceConnectionRequired: res.payload.demoResourceConnectionRequired,
    }
  }
}

export const getInstrumentationIntegrations = (): RetoolThunk => async (dispatch) => {
  dispatch(getInstrumentationIntegrationRequest())
  try {
    const result = await callInternalApi({
      url: '/api/organization/instrumentation',
      method: 'GET',
    })
    dispatch(getInstrumentationIntegrationReceive(result))
  } catch (error) {
    dispatch(getInstrumentationIntegrationFailure(error.message))
  }
}

export const getOrganization = () => async (dispatch: RetoolDispatch) => {
  dispatch(getOrganizationRequest())
  try {
    const result = await callInternalApi({
      url: '/api/organization/admin',
      method: 'GET',
    })
    dispatch(getOrganizationReceive(result))
  } catch (error) {
    dispatch(getOrganizationFailure(error.message))
  }
}

export const getOrganizationExperiments = () => async (dispatch: RetoolDispatch) => {
  dispatch(getOrganizationExperimentsRequest())
  try {
    const payload = await callInternalApi({
      url: '/api/organization/admin/experiments',
      method: 'GET',
    })
    dispatch(getOrganizationExperimentsReceive(payload))
  } catch (error) {
    dispatch(getOrganizationExperimentsFailure(error.message))
  }
}

export const disableExperiment = (experiment: Experiment) => async (dispatch: RetoolDispatch) => {
  dispatch(disableExperimentRequest())
  try {
    await callInternalApi({
      url: `/api/organization/admin/experiments/${experiment.name}`,
      method: 'DELETE',
    })
    dispatch(disableExperimentReceive({ experiment }))
  } catch (error) {
    Message.error(error.message)
    dispatch(disableExperimentFailure(error.message))
  }
}

export const enableExperiment = (name: string) => async (dispatch: RetoolDispatch) => {
  dispatch(enableExperimentRequest())

  try {
    const payload = await callInternalApi({
      url: `/api/organization/admin/experiments/`,
      method: 'POST',
      body: { name },
    })
    dispatch(enableExperimentReceive(payload))
  } catch (error) {
    Message.error(error.message)
    dispatch(enableExperimentFailure(error.message))
  }
}

export const updateOrganizationTheme = (formDataBody: FormData) => async (dispatch: RetoolDispatch) => {
  dispatch(updateOrganizationThemeRequest())
  try {
    const result = await callInternalApi({
      url: '/api/organization/admin/updateOrganizationTheme',
      method: 'POST',
      formDataBody,
    })
    dispatch(updateOrganizationThemeReceive(result.theme))
  } catch (error) {
    dispatch(updateOrganizationThemeFailure(error.message))
  }
}

export const removeOrganizationTheme = () => async (dispatch: RetoolDispatch) => {
  dispatch(removeOrganizationThemeRequest())
  try {
    await callInternalApi({
      url: '/api/organization/admin/removeOrganizationTheme',
      method: 'POST',
    })
    dispatch(removeOrganizationThemeReceive())
  } catch (error) {
    dispatch(removeOrganizationThemeFailure(error.message))
  }
}

export const patchOrganization = (body: PatchOrganizationBodyType) => async (dispatch: RetoolDispatch) => {
  dispatch(patchOrganizationRequest())
  try {
    const result = await callInternalApi({
      url: '/api/organization/admin/',
      method: 'PATCH',
      body,
    })
    dispatch(patchOrganizationReceive(result))
  } catch (error) {
    dispatch(patchOrganizationFailure(error.message))
  }
}

export const patchOrganizationLibraries = (body: PatchOrganizationLibrariesBodyType) => async (
  dispatch: RetoolDispatch,
) => {
  dispatch(patchOrganizationLibrariesRequest())
  try {
    const result = await callInternalApi({
      url: '/api/organization/admin/libraries',
      method: 'PATCH',
      body,
    })
    dispatch(patchOrganizationLibrariesReceive(result))
  } catch (error) {
    dispatch(patchOrganizationLibrariesFailure(error.message))
  }
}

export const createOrUpdateAppTheme = (body: Partial<AppThemeType>) => async (dispatch: RetoolDispatch) => {
  dispatch(patchAppThemeRequest())
  try {
    const result = await callInternalApi({
      url: '/api/organization/appTheme',
      method: 'PATCH',
      body,
    })
    dispatch(patchAppThemeReceive(result))
    return result
  } catch (error) {
    dispatch(patchAppThemeFailure(error.message))
  }
}

export const setDefaultAppTheme = (id?: number | string | null) => async (dispatch: RetoolDispatch) => {
  dispatch(setDefaultAppThemeRequest())
  try {
    const base = '/api/organization/appTheme/default'
    const result = await callInternalApi({
      url: id ? `${base}/${id}` : base,
      method: 'PATCH',
    })
    dispatch(setDefaultAppThemeReceive(result.id))
  } catch (error) {
    dispatch(setDefaultAppThemeFailure(error.message))
  }
}

export const deleteAppTheme = (id: number) => async (dispatch: RetoolDispatch) => {
  dispatch(deleteAppThemeRequest())
  try {
    const result = await callInternalApi({
      url: `/api/organization/appTheme/${id}`,
      method: 'DELETE',
    })
    dispatch(deleteAppThemeReceive(result.id))
  } catch (error) {
    dispatch(deleteAppThemeFailure(error.message))
  }
}

export const updateInstrumentationIntegration = (body: PatchInstrumentationIntegrationBodyType) => async (
  dispatch: RetoolDispatch,
) => {
  dispatch(patchInstrumentationIntegrationRequest())
  try {
    const result = await callInternalApi({
      url: '/api/organization/admin/instrumentation',
      method: 'PATCH',
      body,
    })
    dispatch(patchInstrumentationIntegrationReceive(result))
  } catch (error) {
    dispatch(patchInstrumentationIntegrationFailure(error.message))
  }
}

export const testInstrumentationIntegration = (
  body: PatchInstrumentationIntegrationBodyType,
) => async (): Promise<TestInstrumentationIntegrationResponseType> => {
  try {
    const result = await callInternalApi({
      url: '/api/organization/instrumentation/test',
      method: 'POST',
      body,
    })

    return result
  } catch (error) {
    return { success: false, error: error.message }
  }
}

export const removeInstrumentationIntegration = (integration: string) => async (dispatch: RetoolDispatch) => {
  dispatch(deleteInstrumentationIntegrationRequest())
  try {
    await callInternalApi({
      url: `/api/organization/admin/instrumentation/${integration}`,
      method: 'DELETE',
    })
    dispatch(deleteInstrumentationIntegrationReceive(integration))
  } catch (error) {
    dispatch(deleteInstrumentationIntegrationFailure(error.message))
  }
}

export const deleteResource = (resourceName: string) => async (dispatch: RetoolDispatch) => {
  dispatch(deleteResourceRequest())
  try {
    const result = await callInternalApi({
      url: `/api/resources/names/${encodeURIComponent(resourceName)}`,
      method: 'DELETE',
    })
    dispatch(deleteResourceReceive(result))
  } catch (error) {
    dispatch(deleteResourceFailure(error.message))
  }
}

export const favoritePage = (pageId: number) => async (dispatch: RetoolDispatch) => {
  dispatch(favoritePageRequest())
  try {
    const result = await callInternalApi({
      url: `/api/pages/${pageId}/favorite`,
      method: 'POST',
    })
    dispatch(favoritePageReceive(result, pageId))
  } catch (error) {
    dispatch(favoritePageFailure(error.message))
  }
}

export const unfavoritePage = (pageId: number) => async (dispatch: RetoolDispatch) => {
  dispatch(unfavoritePageRequest())
  try {
    const result = await callInternalApi({
      url: `/api/pages/${pageId}/favorite`,
      method: 'DELETE',
    })
    dispatch(unfavoritePageReceive(result, pageId))
  } catch (error) {
    dispatch(unfavoritePageFailure(error.message))
  }
}

export const favoriteFolder = (folderId: number) => async (dispatch: RetoolDispatch) => {
  dispatch(favoriteFolderRequest())
  try {
    const result = await callInternalApi({
      url: `/api/folders/favorite/${folderId}`,
      method: 'POST',
    })
    dispatch(favoriteFolderReceive(result, folderId))
  } catch (error) {
    dispatch(favoriteFolderFailure(error.message))
  }
}

export const unfavoriteFolder = (folderId: number) => async (dispatch: RetoolDispatch) => {
  dispatch(unfavoriteFolderRequest())
  try {
    const result = await callInternalApi({
      url: `/api/folders/favorite/${folderId}`,
      method: 'DELETE',
    })
    dispatch(unfavoriteFolderReceive(result, folderId))
  } catch (error) {
    dispatch(unfavoriteFolderFailure(error.message))
  }
}

export const updatePhotoUrl = (pageUuid: string, photoUrl: string) => async (dispatch: RetoolDispatch) => {
  dispatch(updatePhotoUrlRequest())
  try {
    const result = await callInternalApi({
      url: `/api/pages/uuids/${pageUuid}`,
      method: 'PATCH',
      body: {
        photoUrl,
      },
    })
    dispatch(updatePhotoUrlReceive(result))
  } catch (error) {
    dispatch(updatePhotoUrlFailure(error.message))
  }
}

export const changePassword = (oldPassword: string, newPassword: string, confirmNewPassword: string) => async (
  dispatch: RetoolDispatch,
) => {
  dispatch(changePasswordRequest())
  try {
    const result = await callInternalApi({
      url: '/api/user/changePassword',
      method: 'POST',
      body: {
        oldPassword,
        newPassword,
        confirmNewPassword,
      },
    })
    dispatch(changePasswordReceive(result))
  } catch (error) {
    dispatch(changePasswordFailure(error.message))
  }
}

export const switchUserPlan = (planName: string | null, stripePlanId: string | null) => {
  return {
    type: SWITCH_USER_PLAN,
    payload: { planName, stripePlanId },
  }
}

const updateOnboardingStagesToInclude = (name: string, state: UserModel) => {
  const organization = state.getIn(['orgInfo', 'organization']) as OrganizationType
  const oldOnboardingStages = organization.onboardingStagesCompleted
  if (oldOnboardingStages && !oldOnboardingStages.includes(name)) {
    const onboardingStagesCompleted = oldOnboardingStages.concat([name])
    return state.setIn(['orgInfo', 'organization'], { ...organization, onboardingStagesCompleted })
  } else {
    return state
  }
}

function handleSwitchUserPlan(state: UserModel, action: SafeAny) {
  const { planName, stripePlanId } = action.payload
  return state
    .setIn(['orgInfo', 'organization', 'plan'], { name: planName, stripePlanId })
    .setIn(['orgInfo', 'organization', 'planId'], planName)
}

const updateOrgInfoWithComputedTheming = (state: UserModel) => {
  const pathToThemes = ['orgInfo', 'organization', 'theme']
  const organization = state.getIn(['orgInfo', 'organization']) as OrganizationType
  const { theme } = organization

  if (theme && theme.headerBackgroundColor) {
    const rgb = hexColorToRgbColor(theme.headerBackgroundColor)
    if (rgb) {
      // https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
      const brightness = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000)
      const useDarkTheme = brightness > 125

      const darkTheme = 'rgba(38, 38, 38, 0.25)'
      const lightTheme = 'rgba(255, 255, 255, 0.4)'
      const headerNavigatorButtonColor = useDarkTheme ? 'var(--medium-gray)' : 'white'
      const headerIconColor = useDarkTheme ? darkTheme : lightTheme

      state = state.setIn(pathToThemes, {
        ...theme,
        headerNavigatorButtonColor,
        headerIconColor,
      })
    }
  }

  if (theme && theme.logoFile && theme.logoFile.data) {
    state = state
      .setIn(pathToThemes, {
        ...state.getIn(pathToThemes),
        logoFileBase64String: byteArrayToImgBase64String(theme.logoFile.data),
      })
      .deleteIn([...pathToThemes, 'logoFile'])
  }

  return state
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = new UserModel()

const userReducer = (state: UserModel = initialState, action: any): UserModel => {
  switch (action.type) {
    case SWITCH_USER_PLAN: {
      return handleSwitchUserPlan(state, action)
    }
    case REQUEST_BRANCHES: {
      return state.setIn(['branches', 'isFetching'], true)
    }
    case RECEIVE_BRANCHES: {
      if (action?.payload?.branches) {
        return state
          .setIn(['branches', 'branches'], Immutable.fromJS(action.payload.branches))
          .setIn(['branches', 'isFetching'], false)
      } else {
        return state.setIn(['branches', 'isFetching'], false)
      }
    }
    case FAILURE_BRANCHES: {
      return state.setIn(['branches', 'isFetching'], false)
    }

    case REQUEST_BRANCH_CREATE: {
      return state.setIn(['branches', 'isFetching'], true)
    }
    case RECEIVE_BRANCH_CREATE: {
      const newState = state.setIn(['branches', 'isFetching'], false)
      if (action?.payload?.branch) {
        return newState.updateIn(['branches', 'branches'], (branches: Branch[]) =>
          Immutable.fromJS([...branches, action.payload.branch]),
        )
      } else {
        return newState
      }
    }
    case FAILURE_BRANCH_CREATE: {
      return state.setIn(['branches', 'isFetching'], false)
    }

    case REQUEST_BRANCH_DELETE: {
      return state.setIn(['branches', 'isFetching'], true)
    }
    case RECEIVE_BRANCH_DELETE: {
      const newState = state.setIn(['branches', 'isFetching'], false)
      if (action?.payload?.branch) {
        return newState.updateIn(['branches', 'branches'], (branches: any) => {
          return branches.filter((b: any) => b.get('id') !== action.payload.branch.id)
        })
      } else {
        return newState
      }
    }
    case FAILURE_BRANCH_DELETE: {
      return state.setIn(['branches', 'isFetching'], false)
    }

    case REQUEST_BRANCH_RENAME: {
      return state.setIn(['branches', 'isFetching'], true)
    }
    case RECEIVE_BRANCH_RENAME: {
      const newState = state.setIn(['branches', 'isFetching'], false)
      if (action?.payload?.branch) {
        return newState.updateIn(['branches', 'branches'], (branches: any) => {
          return branches.map((b: any) => {
            if (b.get('id') === action.payload.branch.id) {
              return b.set('name', action.payload.branch.name)
            } else {
              return b
            }
          })
        })
      } else {
        return newState
      }
    }
    case FAILURE_BRANCH_RENAME: {
      return state.setIn(['branches', 'isFetching'], false)
    }

    case REQUEST_FLOWS: {
      return state.setIn(['flows', 'isFetching'], true)
    }
    case RECEIVE_FLOWS: {
      if (action?.payload?.flows) {
        return state
          .setIn(['flows', 'flows'], Immutable.fromJS(action.payload.flows))
          .setIn(['flows', 'isFetching'], false)
      } else {
        return state.setIn(['flows', 'isFetching'], false)
      }
    }
    case FAILURE_FLOWS: {
      return state.setIn(['flows', 'isFetching'], false)
    }

    case REQUEST_UPSERT_FLOW: {
      return state.setIn(['flows', 'isFetching'], true).setIn(['flows', 'errorMessage'], '')
    }
    case RECEIVE_UPSERT_FLOW: {
      const newState = state.setIn(['flows', 'isFetching'], false)
      if (_.get(action, ['payload', 'created'])) {
        return newState.updateIn(['flows', 'flows'], (flows: Flow[]) =>
          Immutable.fromJS([...flows, action.payload.flow]),
        )
      }
      const i = state.getIn(['flows', 'flows']).findIndex((r: any) => r.get('id') === action.payload.flow.id)
      return newState.setIn(['flows', 'flows', i], Immutable.fromJS(action.payload.flow))
    }
    case FAILURE_UPSERT_FLOW: {
      return state.setIn(['flows', 'isFetching'], false)
    }

    case REQUEST_RESOURCES: {
      return state.setIn(['resources', 'isFetching'], true)
    }
    case RECEIVE_RESOURCES: {
      if (action?.payload?.resources) {
        return state
          .setIn(['resources', 'resources'], Immutable.fromJS(action.payload.resources))
          .setIn(['resources', 'isFetching'], false)
      } else {
        return state.setIn(['resources', 'isFetching'], false)
      }
    }
    case FAILURE_RESOURCES: {
      return state.setIn(['resources', 'isFetching'], false)
    }

    case REQUEST_RESOURCE: {
      return state.setIn(['resources', 'isFetching'], true).setIn(['resources', 'errorMessage'], '')
    }
    case RECEIVE_RESOURCE: {
      let newState = state.setIn(['resources', 'isFetching'], false)
      if (action.payload) {
        const rindex = state
          .getIn(['resources', 'resources'])
          .findIndex((r: any) => r.get('name') === action.payload.oldResourceName)
        if (rindex > 0) {
          newState = newState.setIn(['resources', 'resources', rindex], Immutable.fromJS(action.payload.newResource))
        }
      }
      return newState
    }
    case FAILURE_RESOURCE: {
      return state
        .setIn(['resources', 'isFetching'], false)
        .setIn(
          ['resources', 'errorMessage'],
          action.payload.response ? action.payload.response.message : 'Failed to save resource',
        )
    }
    case REQUEST_CREATE_RESOURCE: {
      return state.setIn(['resources', 'isFetching'], true).setIn(['resources', 'errorMessage'], '')
    }
    case RECEIVE_CREATE_RESOURCE: {
      let newState = state.setIn(['resources', 'isFetching'], false)
      newState = updateOnboardingStagesToInclude(RESOURCE, newState)
      if (action.payload) {
        newState = newState.updateIn(['resources', 'resources'], (resources: any) => {
          return resources.push(Immutable.fromJS(action.payload))
        })
      }
      return newState
    }
    case RECEIVE_PAGE_CREATE: {
      return updateOnboardingStagesToInclude(APPLICATION, state)
    }
    case FAILURE_CREATE_RESOURCE: {
      return state
        .setIn(['resources', 'isFetching'], false)
        .setIn(
          ['resources', 'errorMessage'],
          action.payload.response ? action.payload.response.message : 'Failed to create resource',
        )
    }
    case CLEAR_RESOURCE_ERROR_MESSAGE: {
      return state.setIn(['resources', 'errorMessage'], '')
    }
    case REQUEST_DELETE_RESOURCE: {
      return state.setIn(['resources', 'isFetching'], true)
    }
    case RECEIVE_DELETE_RESOURCE: {
      const { deletedResourceIds } = action.payload
      let newState = state.setIn(['resources', 'isFetching'], false)
      for (const deletedResourceId of deletedResourceIds) {
        const resources = newState.getIn(['resources', 'resources'])
        const prodResourceIndex = resources.findIndex((r: any) => {
          return r.getIn(['production', 'id']) === deletedResourceId
        })

        if (prodResourceIndex > 0) {
          newState = newState.deleteIn(['resources', 'resources', prodResourceIndex, 'production'])
        }
        const stagingResourceIndex = resources.findIndex((r: any) => {
          return r.getIn(['staging', 'id']) === deletedResourceId
        })

        if (stagingResourceIndex > 0) {
          newState = newState.deleteIn(['resources', 'resources', stagingResourceIndex, 'production'])
        }
      }
      return newState.updateIn(['resources', 'resources'], (resources: any) => {
        return resources.filter((r: any) => r.has('production'))
      })
    }
    case FAILURE_DELETE_RESOURCE: {
      return state.setIn(['resources', 'isFetching'], false)
    }
    case REQUEST_CHANGE_PASSWORD: {
      return state.setIn(['changePassword', 'isFetching'], true)
    }

    case FAILURE_REMOVE_ORGANIZATION_THEME:
    case FAILURE_UPDATE_ORGANIZATION_THEME: {
      return state.setIn(['orgInfo', 'organization', 'themeIsFetching'], false)
    }
    case REQUEST_REMOVE_ORGANIZATION_THEME:
    case REQUEST_UPDATE_ORGANIZATION_THEME: {
      return state.setIn(['orgInfo', 'organization', 'themeIsFetching'], true)
    }
    case RECEIVE_UPDATE_ORGANIZATION_THEME: {
      let newState = state
        .setIn(['orgInfo', 'organization', 'theme'], {
          ...action.payload.theme,
        })
        .setIn(['orgInfo', 'organization', 'themeIsFetching'], false)
      newState = updateOrgInfoWithComputedTheming(newState)
      return newState
    }

    case RECEIVE_REMOVE_ORGANIZATION_THEME: {
      return state
        .deleteIn(['orgInfo', 'organization', 'theme'])
        .setIn(['orgInfo', 'organization', 'themeIsFetching'], false)
    }

    case REQUEST_DISMISS_SALES_CTA: {
      return state.setIn(['user', 'salesCTADismissed'], true)
    }
    case REQUEST_DISMISS_TUTORIAL_CTA: {
      return state.setIn(['user', 'tutorialCTADismissed'], true)
    }
    case REQUEST_DISMISS_ONBOARDING_SUGGESTIONS: {
      return updateOnboardingStagesToInclude(ONBOARDING_SUGGESTIONS_DISMISSED, state)
    }
    // to support feature flags in embedded apps (where we fetch the flags with the page not the user)
    case RECEIVE_PAGE_LOAD: {
      const { experimentValues } = action.payload
      if (experimentValues) {
        return state.set('experimentValues', experimentValues)
      }

      return state
    }
    case RECEIVE_USER_PROFILE: {
      const { result, entities } = normalize(action.payload.org, organization)
      const org = entities.organization[result]
      const { userInvites, users, userInviteSuggestion } = entities
      let newState = state.update('orgInfo', (model: any) => {
        return model
          .set('organization', org)
          .set('users', fromJS(users))
          .set('userInvites', fromJS(userInvites))
          .set('userInviteSuggestions', fromJS(userInviteSuggestion))
          .set('isFetching', false)
      })
      newState = newState
        .set('user', fromJS(action.payload.user))
        .set('licenseStatus', fromJS(action.payload.licenseStatus))
        .set('experimentValues', { ...state.experimentValues, ...action.payload.experimentValues })
      newState = updateOrgInfoWithComputedTheming(newState)
      return newState
    }
    case REQUEST_USER_PROFILE: {
      return state.setIn(['orgInfo', 'isFetching'], true)
    }
    case FAILURE_USER_PROFILE: {
      return state.setIn(['orgInfo', 'isFetching'], false)
    }
    case RECEIVE_CREATE_GROUP: {
      const { group } = action.payload
      return state.setIn(['orgInfo', 'groups', group.id], group)
    }
    case RECEIVE_ORGANIZATION: {
      const { result, entities } = normalize(action.payload.org, organization)
      const org = entities.organization[result]
      const { organizationEmailDomains } = entities
      let orgInfo = state.update('orgInfo', (model: any) => {
        return model
          .set('organization', org)
          .set('emailDomains', fromJS(organizationEmailDomains))
          .set('isFetching', false)
      })
      orgInfo = updateOrgInfoWithComputedTheming(orgInfo)
      return orgInfo
    }
    case REQUEST_ORGANIZATION: {
      return state.setIn(['orgInfo', 'isFetching'], true)
    }
    case FAILURE_ORGANIZATION: {
      return state.setIn(['orgInfo', 'isFetching'], false)
    }
    case RECEIVE_INVITE_EMAIL: {
      let newState = state
      const originalInvitees = action.meta.originalInvitees
      const { invitees, userInviteGroups } = action.payload
      if (invitees.length === originalInvitees.length) {
        newState = updateOnboardingStagesToInclude(INVITE, newState)
      }

      const invitedEmails = new Set(invitees.map((x: any) => x.email))

      newState = newState.updateIn(['orgInfo', 'userInvites'], (userInvites: any) => {
        return userInvites
          .map((v: any) => {
            if (!invitedEmails.has(v.get('email'))) return v
            return v.merge(Map({ isReinvitedDuplicate: true }))
          })
          .merge(fromJS(_.keyBy(invitees, 'id')))
      })
      newState = newState.updateIn(['orgInfo', 'userInviteGroups'], (uigs: any) => {
        return uigs.merge(fromJS(_.keyBy(userInviteGroups, 'id')))
      })
      return newState
    }
    case RECEIVE_VIEW_INVITE_SUGGESTION: {
      let newState = state
      const { updatedUserSuggestions } = action.payload
      newState = newState.updateIn(['orgInfo', 'userInviteSuggestions'], (userInviteSuggestions: any) => {
        return userInviteSuggestions.merge(fromJS(_.keyBy(updatedUserSuggestions, 'id')))
      })
      return newState
    }
    case RECEIVE_INVITE_SUGGESTION: {
      let newState = state
      const { suggestedUsers } = action.payload
      newState = newState.updateIn(['orgInfo', 'userInviteSuggestions'], (userInviteSuggestions: any) => {
        return userInviteSuggestions.merge(fromJS(_.keyBy(suggestedUsers, 'id')))
      })
      return newState
    }
    case RECEIVE_UPDATE_INVITE_SUGGESTION: {
      let newState = state
      const { userInviteSuggestionId, status } = action.meta
      newState = newState.updateIn(['orgInfo', 'userInviteSuggestions'], (userInviteSuggestions: any) => {
        return userInviteSuggestions.filterNot((v: any) => {
          return v.get('id') === userInviteSuggestionId
        })
      })
      if (status === 'approve') {
        const { invitee, userInviteGroups } = action.payload
        if (invitee) {
          newState = newState
            .updateIn(['orgInfo', 'userInvites'], (userInvites: any) => {
              return userInvites.merge(fromJS(_.keyBy([invitee], 'id')))
            })
            .updateIn(['orgInfo', 'userInviteGroups'], (uigs: any) => {
              return uigs.merge(fromJS(_.keyBy(userInviteGroups, 'id')))
            })
        }
      }
      return newState
    }
    case RECEIVE_REVOKE_INVITE: {
      const { destroyedUserInviteId } = action.payload
      return state
        .deleteIn(['orgInfo', 'userInvites', destroyedUserInviteId.toString()])
        .updateIn(['orgInfo', 'userInviteGroups'], (userInviteGroups: any) => {
          return userInviteGroups.filterNot((v: any) => {
            return v.get('userInviteId') === destroyedUserInviteId
          })
        })
    }
    case RECEIVE_ADD_USERS_TO_GROUP: {
      const { createdUserGroups, createdUserInviteGroups } = action.payload
      return state
        .updateIn(['orgInfo', 'userGroups'], (userGroups: any) => {
          return userGroups.merge(fromJS(_.keyBy(createdUserGroups, 'id')))
        })
        .updateIn(['orgInfo', 'userInviteGroups'], (userInviteGroups: any) => {
          return userInviteGroups.merge(fromJS(_.keyBy(createdUserInviteGroups, 'id')))
        })
    }
    case RECEIVE_REMOVE_USER: {
      const { destroyedUserGroupId, destroyedUserInviteGroupId } = action.payload
      if (destroyedUserGroupId) {
        return state.deleteIn(['orgInfo', 'userGroups', destroyedUserGroupId.toString()])
      } else {
        return state.deleteIn(['orgInfo', 'userInviteGroups', destroyedUserInviteGroupId.toString()])
      }
    }
    case RECEIVE_SET_USERS_FOR_GROUP: {
      const { userGroups, userInviteGroups } = action.payload
      const userGroupsById = _.keyBy(userGroups, 'id')
      const userInviteGroupsById = _.keyBy(userInviteGroups, 'id')
      const groupId = parseInt(action.payload.groupId)
      return state
        .updateIn(['orgInfo', 'userGroups'], (userGroups: any) => {
          return userGroups.filter((ug: any) => ug.get('groupId') !== groupId).merge(fromJS(userGroupsById))
        })
        .updateIn(['orgInfo', 'userInviteGroups'], (userInviteGroups: any) => {
          return userInviteGroups.filter((ug: any) => ug.get('groupId') !== groupId).merge(fromJS(userInviteGroupsById))
        })
    }
    case RECEIVE_SET_PAGES_FOR_GROUP: {
      const { groupPages } = action.payload
      const groupId = parseInt(action.payload.groupId)
      const keyedById = _.keyBy(groupPages, 'id')
      return state.updateIn(['orgInfo', 'groupPages'], (groupPages: any) => {
        return groupPages.filter((gp: any) => gp.get('groupId') !== groupId).merge(fromJS(keyedById))
      })
    }
    case RECEIVE_SET_RESOURCES_FOR_GROUP: {
      const { groupResources } = action.payload
      const groupId = parseInt(action.payload.groupId)
      const keyedById = _.keyBy(groupResources, 'id')
      return state.updateIn(['orgInfo', 'groupResources'], (groupResources: any) => {
        return groupResources.filter((gp: any) => gp.get('groupId') !== groupId).merge(fromJS(keyedById))
      })
    }

    case RECEIVE_PUT_WORKSPACE: {
      const { workspace } = action.payload
      let groupsUserBelongsTo = state.getIn(['user', 'groups'])
      const groupsUserBelongsToIndex = groupsUserBelongsTo.findIndex(function (item: any) {
        return item.get('id') === workspace.groupId
      })
      if (groupsUserBelongsToIndex >= 0) {
        groupsUserBelongsTo = groupsUserBelongsTo.update(groupsUserBelongsToIndex, function (item: any) {
          return item.set('workspace', fromJS(workspace))
        })
      }

      return state
        .setIn(['orgInfo', 'groups', String(workspace.groupId), 'workspace'], fromJS(workspace))
        .setIn(['user', 'groups'], groupsUserBelongsTo)
    }

    case RECEIVE_PATCH_GROUP: {
      const { group } = action.payload
      return state.mergeDeepIn(['orgInfo', 'groups', String(group.id)], fromJS(group))
    }
    case RECEIVE_SET_USER_ENABLED_FLAG: {
      const { user } = action.payload
      return state.setIn(['orgInfo', 'users', String(user.id)], fromJS(user))
    }

    case RECEIVE_RESET_USER_TWO_FACTOR_AUTH: {
      const { user } = action.payload
      return state.setIn(['orgInfo', 'users', String(user.id)], fromJS(user))
    }

    case REQUEST_PATCH_ORGANIZATION: {
      return state.setIn(['orgInfo', 'isFetching'], true)
    }
    case RECEIVE_PATCH_ORGANIZATION: {
      return state
        .setIn(['orgInfo', 'isFetching'], false)
        .mergeIn(['orgInfo', 'organization'], action.payload.organization)
    }
    case RECEIVE_ORGANIZATION_EXPERIMENTS: {
      return state.setIn(
        ['orgInfo', 'experiments'],
        _.keyBy(action.payload.experiments, (e) => e.name),
      )
    }
    case RECEIVE_DISABLE_EXPERIMENT: {
      const experiment = action.payload.experiment
      return state.deleteIn(['orgInfo', 'experiments', experiment.name])
    }
    case RECEIVE_ENABLE_EXPERIMENT: {
      const experiment = action.payload.experiment
      return state.setIn(['orgInfo', 'experiments', experiment.name], experiment)
    }
    case UPDATE_USER_EXPERIMENT_VALUES: {
      const experiments = action.payload.experiments
      return state.mergeIn(['experimentValues'], experiments)
    }
    case FAILURE_PATCH_ORGANIZATION: {
      return state.setIn(['orgInfo', 'isFetching'], false)
    }
    case REQUEST_STRIPE_CUSTOMER_UPDATE: {
      return state.set('cardIsUpdating', true)
    }
    case RECEIVE_STRIPE_CUSTOMER_UPDATE:
    case FAILURE_STRIPE_CUSTOMER_UPDATE: {
      return state.set('cardIsUpdating', false)
    }
    case REQUEST_PLAN_CHANGE: {
      return state.set('planIsUpdating', true)
    }
    case RECEIVE_PLAN_CHANGE:
    case FAILURE_PLAN_CHANGE: {
      return state.set('planIsUpdating', false)
    }
    case BEGIN_2FA_CHALLENGE: {
      return state.set('twoFactorAuthChallenged', true)
    }
    case RECEIVE_FAVORITE_PAGE: {
      const newPageFavorites = state.getIn(['user', 'pageFavorites']).push(action.payload.favoritedPageId)
      return state.setIn(['user', 'pageFavorites'], newPageFavorites)
    }
    case RECEIVE_UNFAVORITE_PAGE: {
      const newPageFavorites = state
        .getIn(['user', 'pageFavorites'])
        .filter((pgId: any) => pgId !== action.payload.unfavoritedPageId)
      return state.setIn(['user', 'pageFavorites'], newPageFavorites)
    }
    case RECEIVE_FAVORITE_FOLDER: {
      const newFolderFavorites = state.getIn(['user', 'folderFavorites']).push(action.payload.favoritedFolderId)
      return state.setIn(['user', 'folderFavorites'], newFolderFavorites)
    }
    case RECEIVE_UNFAVORITE_FOLDER: {
      const newFolderFavorites = state
        .getIn(['user', 'folderFavorites'])
        .filter((pgId: any) => pgId !== action.payload.unfavoritedFolderId)
      return state.setIn(['user', 'folderFavorites'], newFolderFavorites)
    }
    case RECEIVE_GET_INSTRUMEMTATION_INTEGRATIONS: {
      const { integrations } = action.payload
      return state.update('orgInfo', (orgInfo) =>
        orgInfo.set('instrumentationIntegrations', Immutable.fromJS(_.keyBy(integrations, 'integration'))),
      )
    }
    case RECEIVE_PATCH_INSTRUMEMTATION_INTEGRATION: {
      const { integration: updatedIntegration } = action.payload
      return state.setIn(['orgInfo', 'instrumentationIntegrations', updatedIntegration.integration], updatedIntegration)
    }
    case RECEIVE_DELETE_INSTRUMEMTATION_INTEGRATION: {
      const { integration } = action.payload
      return state.deleteIn(['orgInfo', 'instrumentationIntegrations', integration])
    }
    case RECEIVE_PATCH_APP_THEME: {
      const { appTheme } = action.payload
      const path = ['orgInfo', 'organization', 'appThemes']
      const appThemes = state.getIn(path)
      return state.setIn(path, _.uniqBy([appTheme, ...appThemes], 'id'))
    }
    case RECEIVE_DELETE_APP_THEME: {
      const { id } = action.payload
      const path = ['orgInfo', 'organization', 'appThemes']
      const appThemes = state.getIn(path)
      return state.setIn(
        path,
        _.filter(appThemes, (o) => o.id !== +id),
      )
    }
    case RECEIVE_SET_DEFAULT_APP_THEME: {
      const { id } = action.payload
      return state.setIn(['orgInfo', 'organization', 'defaultAppThemeId'], +id)
    }
    case GET_PERMISSIONS_RECEIVE: {
      const { entities } = normalize(action.payload, organization)
      const {
        users,
        userInvites,
        userInviteSuggestions,
        groups,
        userGroups,
        userInviteGroups,
        pages,
        groupPages,
        groupFolderDefaults,
        groupResources,
      }: GetPermissionsResult = entities

      return state.update('orgInfo', (orgInfo) =>
        orgInfo
          .set('users', fromJS(users))
          .set('userInvites', fromJS(userInvites))
          .set('userInviteSuggestions', fromJS(userInviteSuggestions))
          .set('groups', fromJS(groups))
          .set('userGroups', fromJS(userGroups))
          .set('userInviteGroups', fromJS(userInviteGroups))
          .set('pages', fromJS(pages))
          .set('groupPages', fromJS(groupPages))
          .set('groupFolderDefaults', fromJS(groupFolderDefaults))
          .set('groupResources', fromJS(groupResources))
          .set('groupMembershipManagementDisabled', action.payload.membershipManagementDisabled),
      )
    }
    case UPDATE_GROUP_RECEIVE: {
      const {
        group,
        groupPages,
        groupFolderDefaults,
        groupResources,
        userGroups,
        userInviteGroups,
        workspace,
      }: UpdateGroupResult = action.payload

      const groupId = group.id.toString()

      return state.update('orgInfo', (orgInfo) =>
        orgInfo
          .setIn(['groups', groupId], fromJS(group))
          .setIn(['groups', groupId, 'workspace'], fromJS(workspace))
          .set(
            'groupPages',
            orgInfo
              .get('groupPages')
              .filter((groupPage) => groupPage.get('groupId') !== group.id)
              .merge(fromJS(_.keyBy(groupPages, 'id'))),
          )
          .set(
            'groupFolderDefaults',
            orgInfo
              .get('groupFolderDefaults')
              .filter((groupFolderDefault) => groupFolderDefault.get('groupId') !== group.id)
              .merge(fromJS(_.keyBy(groupFolderDefaults, 'id'))),
          )
          .set(
            'groupResources',
            orgInfo
              .get('groupResources')
              .filter((groupResource) => groupResource.get('groupId') !== group.id)
              .merge(fromJS(_.keyBy(groupResources, 'id'))),
          )
          .set(
            'userGroups',
            orgInfo
              .get('userGroups')
              .filter((userGroup) => userGroup.get('groupId') !== group.id)
              .merge(fromJS(_.keyBy(userGroups, 'id'))),
          )
          .set(
            'userInviteGroups',
            orgInfo
              .get('userInviteGroups')
              .filter((userInviteGroup) => userInviteGroup.get('groupId') !== group.id)
              .merge(fromJS(_.keyBy(userInviteGroups, 'id'))),
          ),
      )
    }
    case SET_GROUPS_FOR_ACCOUNT_RECEIVE: {
      const result: SetGroupsForAccountResult = action.payload
      if (result.accountType === 'user') {
        return state.update('orgInfo', (orgInfo) =>
          orgInfo.set(
            'userGroups',
            orgInfo
              .get('userGroups')
              .filter((userGroup) => userGroup.get('userId') !== result.accountId)
              .merge(fromJS(_.keyBy(result.userGroups, 'id'))),
          ),
        )
      } else {
        return state.update('orgInfo', (orgInfo) =>
          orgInfo.set(
            'userInviteGroups',
            orgInfo
              .get('userInviteGroups')
              .filter((userInviteGroup) => userInviteGroup.get('userInviteId') !== result.accountId)
              .merge(fromJS(_.keyBy(result.userInviteGroups, 'id'))),
          ),
        )
      }
    }
    case SET_ACCOUNTS_FOR_GROUP_RECEIVE: {
      const result: SetAccountsForGroupResult = action.payload
      return state.update('orgInfo', (orgInfo) =>
        orgInfo
          .set(
            'userGroups',
            orgInfo
              .get('userGroups')
              .filter((userGroup) => userGroup.get('groupId') !== result.groupId)
              .merge(fromJS(_.keyBy(result.userGroups, 'id'))),
          )
          .set(
            'userInviteGroups',
            orgInfo
              .get('userInviteGroups')
              .filter((userInviteGroup) => userInviteGroup.get('groupId') !== result.groupId)
              .merge(fromJS(_.keyBy(result.userInviteGroups, 'id'))),
          ),
      )
    }
    case SET_GROUP_ADMINS_RECEIVE: {
      const result: SetGroupAdminsResult = action.payload
      return state.update('orgInfo', (orgInfo) =>
        orgInfo.set(
          'userGroups',
          orgInfo
            .get('userGroups')
            .filter((userGroup) => userGroup.get('groupId') !== result.groupId)
            .merge(fromJS(_.keyBy(result.userGroups, 'id'))),
        ),
      )
    }
    case REQUEST_PATCH_ORGANIZATION_LIBRARIES:
    case RECEIVE_PATCH_ORGANIZATION_LIBRARIES:
    case FAILURE_PATCH_ORGANIZATION_LIBRARIES:
    case REQUEST_SETUP_2FA:
    case RECEIVE_SETUP_2FA:
    case FAILURE_SETUP_2FA:
    case REQUEST_CONFIRM_2FA:
    case RECEIVE_CONFIRM_2FA:
    case FAILURE_CONFIRM_2FA:
    case REQUEST_VERIFY_2FA_CHALLENGE:
    case RECEIVE_VERIFY_2FA_CHALLENGE:
    case FAILURE_VERIFY_2FA_CHALLENGE:
    case REQUEST_FAVORITE_PAGE:
    case REQUEST_UNFAVORITE_PAGE:
    case FAILURE_FAVORITE_PAGE:
    case FAILURE_UNFAVORITE_PAGE:
    case FAILURE_UPDATE_PHOTO_URL: {
      return state
    }
    default: {
      // assertNever(action, false) // <-- uncomment this once the reducer actions are properly typed
      return state
    }
  }
}

export default userReducer

import { PluginNamespaceInfo, PluginSubtype } from 'common/records'
import { SafeAny } from 'common/types'
import { addNamespace } from 'common/utils/namespaces'
import { isEqual } from 'lodash'
import { SHARED_PLUGINS_BETWEEN_GLOBAL_WIDGETS_AND_PARENT } from './constants'

/**
 * Fetch an appropriate model for a given namespace.
 *
 * If a namespace is not provided (undefined) this will return `currentModel` as a convenience.
 *
 * For a model {textInput1: {...}, global1::table1: {...}} and namespace [global1] the model will filter and denamespace
 * the model and return only things belonging to namespace [global1]: {table1: {...}}.
 *
 * This will allow all evaluation to take place "inside" the namespace. Call this on the model before evaluating templates.
 *
 * TODO: better type for pluginType
 */
export default function getNamespaceAwareModel(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentModel: { [k: string]: any },
  namespace?: PluginNamespaceInfo,
  pluginType?: PluginSubtype,
) {
  if (!namespace) {
    return currentModel
  }

  let namespacePrefix = addNamespace(namespace, '')
  if (pluginType === 'GlobalWidgetQuery' || pluginType === 'GlobalWidgetProp') {
    const parentNamespace = namespace.getParentNamespace()
    if (parentNamespace) {
      namespacePrefix = addNamespace(parentNamespace, '')
    }
  }

  const denamespaced = Object.entries(currentModel)
    .filter(([k, v]) => {
      const filterNamespaceArray = namespace?.getNamespace()
      const currentValueNamespaceArray = v?.namespace?.getNamespace() || []
      return (
        pluginType === 'GlobalWidgetQuery' || // this takes care of global queries
        (v && isEqual(currentValueNamespaceArray.concat([v.childNamespace]), filterNamespaceArray)) || // this is for props - we want to pass the global1 object for evaluation
        SHARED_PLUGINS_BETWEEN_GLOBAL_WIDGETS_AND_PARENT.includes(k) ||
        // special cases for additional scope that is passed in to model
        ['triggeredById', 'i', 'currentRow', 'self'].includes(k) ||
        isEqual(currentValueNamespaceArray, filterNamespaceArray)
      )
    })
    .reduce((acc: SafeAny, [key, v]) => {
      acc[key.replace(namespacePrefix, '')] = v
      return acc
    }, {})
  return denamespaced
}

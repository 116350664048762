import React from 'react'
import { FolderWithChildren, folderTreeSelector, currentAppPathArraySelector } from 'store/selectors'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { Link } from 'react-router'
import { Breadcrumb, Dropdown, Menu, Icon as OldIcon } from 'antd'
import { RetoolState } from 'store'
import { Icon } from 'components/design-system'
import classNames from 'classnames/bind'
import _styles from "./AppMenu-styles.module.scss";

import { SafeAny } from 'common/types'

const styles = _styles

const cx = classNames.bind(styles)

interface AppMenuProps {
  breadcrumbItems: SafeAny
  inRetoolPill?: boolean
  label?: string
}

// recurisively get folders children and pages
function resolveFolder(folder: FolderWithChildren, path: string[]): SafeAny {
  return [
    ...folder.children.map((child: any) => ({
      type: 'folder',
      name: child.name,
      children: resolveFolder(child, [...path, child.name]),
    })),
    ...folder.pages.map((page: any) => ({
      type: 'page',
      name: page.name,
      href: ['/apps', ...path, page.name].join('/'),
      children: [],
    })),
  ]
}

const breadcrumbItemsSelector = createSelector(currentAppPathArraySelector, folderTreeSelector, (path, root) => {
  let curFolder = root
  if (!curFolder) {
    return []
  }
  const items = path.map((page: any, i: any) => {
    if (!curFolder) {
      return undefined
    }
    const child = curFolder.children.find((c: any) => c.name === page)

    const splat = path.slice(0, i + 1).join('/')
    const folderSplat = splat.split('/').slice(0, -1)

    const res = {
      name: page,
      siblings: curFolder ? resolveFolder(curFolder, folderSplat) : [],
    }

    curFolder = child || null

    return res
  })
  return items.filter((v: any) => !!v)
})

const AppMenu = (props: AppMenuProps) => {
  const { breadcrumbItems, inRetoolPill, label } = props

  const trigger = inRetoolPill ? 'hover' : 'click'

  const children = breadcrumbItems.map((page: any, i: any) => (
    <Breadcrumb.Item key={i} separator="">
      <Dropdown
        trigger={[trigger]}
        overlayClassName={cx('browseAppDropdown', { inRetoolPill })}
        align={{ offset: inRetoolPill ? [172, 0] : [0, 0] }}
        placement="bottomLeft"
        overlay={
          page.siblings.length && (
            <Menu>
              {page.siblings.map((sib: any) => {
                if (sib.type === 'folder') {
                  return (
                    <Menu.SubMenu
                      key={`folder-${sib.name}`}
                      popupClassName={cx('browseFolderDropdown')}
                      title={
                        <>
                          <OldIcon type="folder" theme="twoTone" />
                          {sib.name}
                        </>
                      }
                      onTitleClick={(e) => {
                        e.domEvent.stopPropagation()
                      }}
                    >
                      {(sib.children || []).map((child: any) => (
                        <Menu.Item key={`sib-${sib.name}-${child.name}`}>
                          <Link to={child.href}>{child.name}</Link>
                        </Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  )
                }

                return (
                  <Menu.Item key={`sib-${sib.name}`}>
                    <Link to={sib.href}>{sib.name}</Link>
                  </Menu.Item>
                )
              })}
            </Menu>
          )
        }
      >
        <div className={cx('sectionTitle', { inRetoolPill })}>
          <span className={cx('sectionText', { inRetoolPill })}>{inRetoolPill ? label : page.name}</span>
          {inRetoolPill ? <Icon type="caret-right" /> : <Icon type="caret-down" />}
          {i + 1 < props.breadcrumbItems.length && !inRetoolPill && <span>/&nbsp;&nbsp;</span>}
        </div>
      </Dropdown>
    </Breadcrumb.Item>
  ))

  return (
    <div>
      <Breadcrumb>
        <div className={cx('section', { inRetoolPill })}>
          {!inRetoolPill && (
            <Breadcrumb.Item separator="">
              <Icon type="folder" className={cx('folderIcon')} />
            </Breadcrumb.Item>
          )}
          {inRetoolPill ? children[0] : children}
        </div>
      </Breadcrumb>
    </div>
  )
}

const mapStateToProps = (state: RetoolState) => ({
  breadcrumbItems: breadcrumbItemsSelector(state),
})

export default connect(mapStateToProps)(AppMenu)

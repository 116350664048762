import React, { ReactElement } from 'react'
import classNames from 'classnames'

import { browserHistory } from 'react-router'

import { Dropdown as AntDropdown, Menu as AntMenu } from 'antd'
import { DropDownProps as AntDropdownProps } from 'antd/lib/dropdown'

import { MenuItemProps as AntMenuItemProps } from 'antd/lib/menu/MenuItem'

import { ClickParam } from 'antd/lib/menu'

import 'antd/lib/dropdown/style/index'
import './Dropdown.scss'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { SafeAny } from 'common/types'

type AntTrigger = Exclude<AntDropdownProps['trigger'], undefined>

export type DropdownProps = Omit<AntDropdownProps, 'trigger'> & {
  children?: React.ReactNode
  trigger?: AntTrigger | AntTrigger[number]
}

export const Dropdown = ({ trigger, ...props }: DropdownProps): ReactElement => {
  return (
    <AntDropdown
      {...props}
      trigger={trigger && !Array.isArray(trigger) ? [trigger] : trigger}
      className={classNames('retool-dropdown', props.className)}
    >
      {props.children}
    </AntDropdown>
  )
}

export type MenuHeaderProps = {
  className?: string
  children: React.ReactElement
}

export const MenuItem = (props: SafeAny) => (
  <AntMenu.Item
    {...props}
    key={name}
    className={classNames('retool-dropdown-menu-item', props.className)}
    onClick={() => {
      let menuItemName = props.name
      if (!menuItemName && typeof props.children === 'string') {
        menuItemName = props.children
      }
      retoolAnalyticsTrack('App Menu Item Clicked', {
        menuItemName,
      })
      props.onClick()
    }}
  />
)

export const MenuHeader = (props: MenuHeaderProps): ReactElement => {
  const className = classNames('headerized-menu-item menu-header', props.className)
  return (
    <AntMenu.Item className={className} disabled={true}>
      {props.children}
    </AntMenu.Item>
  )
}

export type MenuLinkCommonProps = AntMenuItemProps & {
  className?: string
  label: string
  visible?: boolean
}

export type MenuHrefProps = MenuLinkCommonProps & {
  redirectLink: string
}

export type MenuOnClickProps = MenuLinkCommonProps & {
  onSelected: Function
}

export type MenuRoutedProps = MenuLinkCommonProps & {
  routedLink: string
}

export type MenuLinkProps = MenuRoutedProps | MenuHrefProps | MenuOnClickProps

export type MenuLinkAnyProps = MenuRoutedProps & MenuHrefProps & MenuOnClickProps

export function isRouted(p: MenuLinkProps): p is MenuRoutedProps {
  return Object.prototype.hasOwnProperty.call(p, 'routedLink')
}

export function isHref(p: MenuLinkProps): p is MenuHrefProps {
  return Object.prototype.hasOwnProperty.call(p, 'redirectLink')
}

export const MenuLink = (props: MenuLinkProps): ReactElement => {
  const { label, visible, redirectLink, onSelected, routedLink, ...menuItemProps } = props as MenuLinkAnyProps

  // nilable field - so explicitly checking for false
  if (visible === false) {
    return <></>
  }

  let handler: (event: ClickParam) => void

  if (isRouted(props)) {
    handler = (_: ClickParam) => browserHistory.push(routedLink)
  } else if (isHref(props)) {
    handler = (_: ClickParam) => window.open(redirectLink, '_blank')
  } else {
    handler = (_: ClickParam) => onSelected()
  }

  return (
    <AntMenu.Item
      {...menuItemProps}
      key={label}
      onClick={(param: ClickParam) => {
        handler(param)
        if (props.onClick) {
          props.onClick(param)
        }
      }}
      className={classNames('retool-dropdown-menu-item', props.className)}
    >
      {label}
    </AntMenu.Item>
  )
}

// NB: I couldn't get the following to work for the life of me
// export const Menu = (props: AntMenuProps) => (
//   <AntMenu {...props} className={classNames('retool-dropdown-menu', props.className)} />
// )

export default Dropdown

import { QueryTemplateShape } from 'common/records'

export const QUERIES_WITH_METADATA = ['RESTQuery', 'GraphQLQuery', 'OpenAPIQuery']
export const QUERIES_WITH_ERRORS = ['GraphQLQuery']
export const DEFAULT_ERROR_TRANSFORMER = `// The variable 'data' allows you to reference the request's data in the transformer. \n// example: return data.find(element => element.isError)\nreturn data.error`

export const defaultTemplate: QueryTemplateShape = {
  runWhenPageLoads: false,
  runWhenPageLoadsDelay: '',
  runWhenModelUpdates: false,
  requireConfirmation: false,
  confirmationMessage: undefined,
  queryDisabled: '',
  queryDisabledMessage: '',
  data: null,
  metadata: null,
  rawData: null,
  isFetching: false,
  timestamp: 0,
  triggersOnSuccess: [],
  triggersOnFailure: [],
  privateParams: [],
  watchedParams: [],
  allowedGroups: [],
  queryRefreshTime: '',
  queryThrottleTime: '750',
  queryTimeout: '10000',
  queryTriggerDelay: '0',
  cacheKeyTtl: '',
  enableCaching: false,
  updateSetValueDynamically: false,
  showUpdateSetValueDynamicallyToggle: true,
  showSuccessToaster: true,
  showFailureToaster: true,
  showSuccessConfetti: false,
  successMessage: '',
  notificationDuration: '',
  resourceNameOverride: '',
  transformer: `// type your code here\n// example: return formatDataAsArray(data).filter(row => row.quantity > 20)\nreturn data`,
  enableTransformer: false,
  errorTransformer: DEFAULT_ERROR_TRANSFORMER,
  enableErrorTransformer: false,
  queryFailureConditions: '',
  isImported: false,
  playgroundQueryId: undefined,
  playgroundQueryUuid: '',
  playgroundQuerySaveId: 'latest',
  importedQueryInputs: {},
  importedQueryDefaults: {},
  query: '',
  showLatestVersionUpdatedWarning: false,
}

//Used when all old queries created should have one default but new queries should have a different one
export const defaultOverridesForNewQueries: Partial<QueryTemplateShape> = {
  updateSetValueDynamically: true,
  showUpdateSetValueDynamicallyToggle: false,
}

export const DEFAULT_TEMPLATE_KEYS = new Set(Object.keys(defaultTemplate))

import React from 'react'
import { connect } from 'react-redux'

import { RetoolState } from 'store'

import { showOnboarding } from 'store/onboarding'
import { dismissTutorialCTA } from 'store/user'

import { Button, Icon } from 'components/design-system'
import { tutorialCTAShownSelector } from 'store/selectors/userSelectors'

type Props = {
  style?: React.CSSProperties
  hide: () => Promise<void>
  visible: boolean
  showOnboarding: () => Promise<void>
}

const LearnRetool = (props: Props) => {
  if (!props.visible) {
    return <></>
  }

  return (
    <div className="flex pa8 fs-12 bg-faint-blue br4" style={props.style}>
      <div className="pa12">
        <Icon type="tutorial" className="v-mid" />
      </div>
      <div className="flex-grow pa8">
        <div className="fw-500 dark-gray fs-13">Learn Retool in 5 minutes</div>
        <div className="gray fs-12">
          Walk through the core concepts by building an app with real data.
          <Button type="link" onClick={props.showOnboarding} className="ml4 fw-500">
            Take the tutorial&nbsp;
            <Icon type="arrow-right" style={{ verticalAlign: 'text-top' }} />
          </Button>
        </div>
      </div>
      <div>
        <Button type="link" onClick={props.hide} className="pa4 lightest-gray hover-blue">
          <Icon type="close" />
        </Button>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  hide: dismissTutorialCTA,
  showOnboarding,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    visible: tutorialCTAShownSelector(state),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnRetool)

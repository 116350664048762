import { camelCase } from 'lodash'
import { WidgetOptionsResolver } from 'components/plugins'
import { RetoolState } from 'store'
import { localPluginsSelector } from 'store/selectors'
import { useStore } from 'react-redux'
import { VALID_JS_IDENTIFIER_REGEX } from 'common/regexes'
import useCallbackRef from 'common/hooks/useCallbackRef'

/** ids reserved for retool use */
const RESERVED_IDS = [
  'additionalScope', // queries
  'currentRow', // widgets
  'description', // modules
  'dimensions', // modules
  'event', // future
  'i', // queries, widgets
  'moduleContainer', // modules
  'name', // modules
  'namespace', // modules
  'outputs', // modules
  'pageUuid', // modules
  'props', // modules
  'queries', // modules
  'retool', // future
  'self', // widgets
  'tags', // modules
  'this', // js
  'triggeredById', // queries
  'utils', // api
]

const validatePluginId = (state: RetoolState, id: string, oldId?: string): string | null => {
  // always allow ids that haven't changed
  if (oldId && id === oldId) return null

  if (!/^[A-Z_]/i.test(id)) {
    return `"${id}" must start with a letter or "_"`
  }

  if (!VALID_JS_IDENTIFIER_REGEX.test(id)) {
    return `"${id}" can only contain letters, numbers, "_", or "$"`
  }

  if (state.appModel.values.has(id)) {
    return `"${id}" already exists`
  }

  if (RESERVED_IDS.includes(id)) {
    return `"${id}" is a reserved id`
  }

  return null
}

export const usePluginIdValidator = (): ((id: string, oldId?: string) => string | null) => {
  // We only need access to state when validating, so we use useStore instead
  // of useSelector to avoid triggering a re-render when state changes.
  const store = useStore<RetoolState>()
  return useCallbackRef((id, oldId) => validatePluginId(store.getState(), id, oldId))
}

export const getNewPluginId = (state: RetoolState, defaultId: string): string => {
  const plugins = localPluginsSelector(state)
  const idPrefix = defaultId.replace(/([0-9]+)$/g, '')
  let maxSuffix = 0

  plugins.forEach(({ id }) => {
    const suffix = id === idPrefix ? 1 : Number(id.replace(idPrefix, ''))
    if (isNaN(suffix)) return
    maxSuffix = Math.max(maxSuffix, suffix)
  })

  if (defaultId === idPrefix && maxSuffix === 0) {
    return defaultId
  }

  return `${idPrefix}${maxSuffix + 1}`
}

export const cleanWidgetName = (name: string): string => name.replace(/([0-9]*widget)?[0-9]*$/gi, '')

export function getNewWidgetId(state: RetoolState, widgetType: string, defaultId?: string): string {
  if (!defaultId) {
    const { directory } = WidgetOptionsResolver(widgetType)
    const prefix = directory?.idPrefix ?? cleanWidgetName(widgetType).toLowerCase()
    defaultId = `${prefix}1`
  }

  return getNewPluginId(state, defaultId)
}

export function getNewGlobalWidgetId(state: RetoolState, name: string, defaultId?: string): string {
  if (!defaultId) {
    defaultId = `${camelCase(cleanWidgetName(name)) || 'module'}1`
  }

  return getNewPluginId(state, defaultId)
}

import _ from 'lodash'
import fuse from 'fuse.js'
import { Folder, Page, PlaygroundQuery, ResourceFromServer } from 'common/records'
import { AdhocResourceType, ResourceType } from 'common/resourceTypes'
import { QUERY_LIBRARY_URL_PREFIX, RESOURCES_URL_PREFIX, DATA_SOURCES_URL_PREFIX } from 'store/constants'

export type SearchResult =
  | { type: 'folder' | 'page' | 'staticpage' | 'module'; title: string; path: string; searchTerm: string }
  | {
      type: 'resource' | 'query'
      title: string
      path: string
      resourceType: ResourceType | AdhocResourceType
      searchTerm: string
    }

type StaticPage = { name: string; path: string }
const staticPages: StaticPage[] = [
  {
    name: 'Home',
    path: '/',
  },
  {
    name: 'Apps',
    path: '/',
  },
  {
    name: 'Data Editor',
    path: DATA_SOURCES_URL_PREFIX,
  },
  {
    name: 'Query Library',
    path: QUERY_LIBRARY_URL_PREFIX,
  },
  {
    name: 'Resources',
    path: RESOURCES_URL_PREFIX,
  },
  {
    name: 'Settings',
    path: '/settings',
  },
]

const searchOptions = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    {
      name: 'searchTerm',
      weight: 0.7,
    },
    {
      name: 'path',
      weight: 0.3,
    },
  ],
}

export const createSearchExtension = (
  pages: Page[],
  folders: Folder[],
  resources: ResourceFromServer[],
  playgroundQueries: PlaygroundQuery[],
) => {
  const searchablePages: SearchResult[] = pages.map((result) => {
    return {
      searchTerm: decodeURIComponent(result.path),
      title: decodeURIComponent(result.path),
      path: `/apps/${result.path}`,
      type: result.isGlobalWidget ? 'module' : 'page',
    }
  })
  const searchableResources: SearchResult[] = resources.map((result) => {
    return {
      searchTerm: `${result.displayName} ${result.production.type}`,
      title: result.displayName,
      path: `${RESOURCES_URL_PREFIX}/${encodeURIComponent(result.name)}`,
      type: 'resource',
      resourceType: result.production.type,
    }
  })
  const searchableStaticPages: SearchResult[] = staticPages.map((result) => {
    return {
      searchTerm: result.name,
      title: result.name,
      path: result.path,
      type: 'staticpage',
    }
  })
  const searchableFolders: SearchResult[] = folders.map((result) => {
    return {
      searchTerm: result.name,
      title: result.name,
      path: `/folders/${encodeURIComponent(result.name)}`,
      type: 'folder',
    }
  })
  const resourcesById = new Map(
    resources.map((resource) => [resource.production?.id ?? resource.staging?.id, resource]),
  )
  const searchablePlaygroundQueries: SearchResult[] = playgroundQueries.map(({ name, id, resourceId }) => {
    return {
      searchTerm: name,
      title: name,
      path: `/querylibrary/${encodeURIComponent(id)}`,
      type: 'query',
      resourceType: (resourceId && resourcesById.get(resourceId)?.type) || 'restapi',
    }
  })

  const defaultResults = [
    ...searchableFolders,
    ...searchablePages,
    ...searchableResources,
    ...searchableStaticPages,
    ...searchablePlaygroundQueries,
  ].sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1))
  const index = new fuse(defaultResults, searchOptions)

  return (query: string) => {
    if (query === '') {
      return defaultResults
    }

    const results = index.search<SearchResult>(query)

    return _.flatten(
      _.partition(results, (p) => {
        return p.path.indexOf('/apps/archive/') !== 0 && p.path !== '/folders/archive'
      }),
    )
  }
}

import * as React from 'react'
import Input from './Input'
import Results from './Results'
import search from './search'
import { KEYS, BLUR_DELAY } from './constants'
import { debounce } from './utils'
import { shortcutFormatter } from 'shortcutsKeymap'

import hasModifierKeys from 'common/utils/hasModifierKeys'
import { SearchResult } from '../extensions'
export default class Omnibar extends React.PureComponent<Omnibar.Props<SearchResult>, Omnibar.State<SearchResult>> {
  // TODO - fix generic container
  static defaultProps = {
    extensions: [],
    inputDelay: 100,

    // style props
    resultStyle: {},
    rootStyle: { position: 'relative' },
    autoFocus: true,
  }

  inputNode: Input | null = null

  constructor(props: Omnibar.Props<SearchResult>) {
    super(props)

    this.state = {
      results: search<SearchResult>('', this.props.extensions),
      selectedIndex: 0,
      displayResults: false,
      adjustScroll: false,
    }

    this.query = debounce(this.query, this.props.inputDelay!)
  }

  query = (value: string) => {
    if (this.props.extensions.length > 0) {
      const results = search<SearchResult>(value, this.props.extensions)
      this.setState({
        results: this.props.maxResults! > 0 ? results.slice(0, this.props.maxResults) : results,
        displayResults: results.length > 0,
      })
    }
  }

  reset() {
    this.query('')
  }

  prev = () => {
    this.setState((prevState: Omnibar.State<SearchResult>) => {
      const selectedIndex = prevState.selectedIndex - 1
      if (selectedIndex >= 0) {
        return { ...prevState, selectedIndex, adjustScroll: -1 }
      }
      return prevState as any
    })
  }

  next = () => {
    this.setState((prevState: Omnibar.State<SearchResult>) => {
      const selectedIndex = prevState.selectedIndex + 1
      if (selectedIndex < prevState.results.length) {
        return { ...prevState, selectedIndex, adjustScroll: 1 }
      }
      return prevState as any
    })
  }

  action = (newTab = false) => {
    // uses the hovered index if the user is currently
    // mousing over an item, falls back on the
    // selected index
    const idx = this.state.selectedIndex
    const item = this.state.results[idx]
    const action = this.props.onAction
    action.call(null, item as any, newTab)
  }

  handleChange = (value: string) => {
    if (value) {
      this.query(value)
    } else {
      this.reset()
    }
  }

  handleKeyDown = (evt: any /* Event */) => {
    if (evt.ctrlKey && (evt.keyCode === 80 || evt.keyCode === 75)) {
      // ctrl+p, or ctrl+k
      this.prev()
      evt.preventDefault()
    } else if (evt.ctrlKey && (evt.keyCode === 78 || evt.keyCode === 74)) {
      // ctrl+n, or ctrl+j
      this.next()
      evt.preventDefault()
    } else if (hasModifierKeys(evt, 'command') && evt.keyCode === KEYS.ENTER) {
      // cmd+enter: open in new tab
      this.action(true)
      evt.preventDefault()
    } else {
      switch (evt.keyCode) {
        case KEYS.UP:
          this.prev()
          evt.preventDefault()
          break
        case KEYS.DOWN:
          this.next()
          evt.preventDefault()
          break
        case KEYS.ENTER:
          this.action()
          evt.preventDefault()
          break
      }
    }
  }

  handleBlur = () => {
    setTimeout(() => this.setState({ displayResults: false }), BLUR_DELAY)
  }

  handleFocus = () => {
    this.setState({ displayResults: true })
  }

  handleClickItem = (e: React.MouseEvent) => {
    e.preventDefault()
    this.action(hasModifierKeys(e, 'command'))
  }

  handleMouseMoveItem = (hoveredIndex: number) => {
    this.setState({ selectedIndex: hoveredIndex })
  }

  UNSAFE_componentWillReceiveProps(nextProps: Omnibar.Props<SearchResult>) {
    if (this.props.defaultValue !== nextProps.defaultValue) {
      this.handleChange(nextProps.defaultValue!)
    }
  }

  render() {
    return (
      <div className={this.state.displayResults ? '' : 'empty'} style={this.props.rootStyle}>
        <div style={{ borderTopLeftRadius: 6, borderTopRightRadius: 6, padding: '0 24px', background: 'white' }}>
          {React.createElement(Input, {
            defaultValue: this.props.defaultValue,
            autoFocus: this.props.autoFocus,
            style: this.props.style,
            placeholder: this.props.placeholder,
            onChange: this.handleChange,
            onKeyDown: this.handleKeyDown,
            onBlur: this.handleBlur,
            onFocus: this.handleFocus,
            ref: (n) => {
              this.inputNode = n
            },
          })}
        </div>
        <Results
          selectedIndex={this.state.selectedIndex}
          items={this.state.results}
          adjustScroll={this.state.adjustScroll}
          style={this.props.resultStyle}
          onMouseMoveItem={this.handleMouseMoveItem}
          onClickItem={this.handleClickItem}
          onResetQuery={() => {
            this.inputNode?.handleChange({ target: { value: '' } })
          }}
        />
        <div className="shortcutHint">
          Hint: use <span style={{ margin: '0 4px' }}>{shortcutFormatter('OMNIBOX', 'TOGGLE')}</span> to open the
          omnibox anywhere!
        </div>
      </div>
    )
  }
}

import { connect } from 'react-redux'
import { protectPage, unprotectPage } from 'store/appModel/pages'
import AppProtectionModal from './AppProtectionModal'

const mapDispatchToProps = {
  protectPage,
  unprotectPage,
}

const connector = connect(() => ({}), mapDispatchToProps)

export default connector(AppProtectionModal)

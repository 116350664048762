import uuid from 'uuid'

let group: string | null = null

let nestedCalls = 0

export const startBatchUndoGroup = () => {
  group ??= uuid() // only sets uuid if group is nullish
  nestedCalls++
}

export const stopBatchUndoGroup = () => {
  nestedCalls--
  if (nestedCalls === 0) {
    group = null
  }
}

export const clearBatchUndoGroups = () => {
  group = null
  nestedCalls = 0
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const batchUndoGroup = <A extends any[], R>(fn: (...args: A) => R): ((...args: A) => Promise<R>) => {
  return async (...args) => {
    startBatchUndoGroup()
    const result = await fn(...args)
    stopBatchUndoGroup()
    return result
  }
}

export const batchUndoGroupBy = () => group

export type AppViewMode = 'editor' | 'presentation' | 'embedded' | 'other'

export default function getAppViewModeFromPathname(pathname: string): AppViewMode {
  const firstPathPart = pathname.split('/').find((segment) => !!segment)

  switch (firstPathPart) {
    case 'embedded':
      return 'embedded'
    case 'editor':
      return 'editor'
    case 'apps':
      return 'presentation'
    default:
      return 'other'
  }
}

import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import { Popover as AntPopover } from 'antd'
import { PopoverProps as AntPopoverProps } from 'antd/lib/popover'
import 'antd/lib/popover/style/index'

import Icon from '../Icon'

import './Popover.scss'

export type PopoverProps = AntPopoverProps & {
  children?: React.ReactNode
  childrenClassName?: string
  onVisibleChange?: (value: boolean) => void
}

const clickIsInsideRect = (ev: MouseEvent, rect: DOMRect) => {
  return (
    ev.clientX >= rect.x &&
    ev.clientX <= rect.x + rect.width &&
    ev.clientY >= rect.y &&
    ev.clientY <= rect.y + rect.height
  )
}

class Popover extends React.PureComponent<PopoverProps, { expanded: boolean }> {
  /*
    Note: we need all this business with the refs and manual click handlers to let devs use
    CodeEditors inside popovers. If we didn't use these (and let antd handle it), the popover
    would just close when someone tried to use the CodeEditor inside the popover
   */
  contentRef: HTMLDivElement | null = null
  childrenRef: HTMLDivElement | null = null

  constructor(props: PopoverProps) {
    super(props)

    this.state = {
      expanded: !!props.visible,
    }
  }

  componentDidMount() {
    if (this.props.trigger === 'click') document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    if (this.props.trigger === 'click') document.removeEventListener('mousedown', this.handleClickOutside)
  }

  private handleClickOutside = (ev: MouseEvent): void => {
    const { expanded } = this.state
    const target = ev.target as Element

    if (
      !expanded ||
      !this.contentRef ||
      !this.childrenRef ||
      this.contentRef.contains(target) ||
      this.childrenRef.contains(target) ||
      clickIsInsideRect(ev, this.contentRef.getBoundingClientRect())
    ) {
      return
    }

    this.closePopover()
  }

  private closePopover = () => this.setExpanded(false)

  private openPopover = () => this.setExpanded(true)

  private setExpanded = (expanded: boolean) => {
    if (this.state.expanded === expanded) return
    this.setState({ expanded })
    this.props.onVisibleChange?.(expanded)
  }

  render() {
    const props = this.props
    const { visible } = props

    const visibilityProps =
      this.props.trigger === 'click'
        ? {
            visible: visible ?? this.state.expanded,
          }
        : {}

    return (
      <AntPopover
        {..._.omit(props, ['title'])}
        {...visibilityProps}
        onVisibleChange={(visible) => {
          if (visible) {
            // ant calls this on outside click, but we have more nuanced logic for this,
            // so we only respond to `onVisibleChange(true)`
            this.openPopover()
          }
        }}
        content={
          <div
            className={classNames('retool-popover-content', props.className)}
            ref={(node) => (this.contentRef = node)}
          >
            {props.title && (
              <div className="retool-popover-title">
                <span className="retool-popover-title__title-text truncate">{props.title}</span>
                {props.trigger !== 'hover' && <Icon onClick={this.closePopover} type="close" />}
              </div>
            )}
            {props.content}
          </div>
        }
        overlayClassName={classNames('retool-popover', props.overlayClassName)}
      >
        <div
          className={classNames('retool-popover-children', props.childrenClassName)}
          ref={(node) => (this.childrenRef = node)}
        >
          {props.children}
        </div>
      </AntPopover>
    )
  }
}

export default Popover

import { fromJS } from 'immutable'
import { OrgImageBlobsModel } from 'common/records'
import { SafeAny } from 'common/types'
import { byteArrayToImgBase64String } from 'common/utils'
import { RetoolAction } from 'store'
import { callApi } from './callApi'
import {
  REQUEST_UPLOAD_ORG_IMAGE_BLOB,
  RECEIVE_UPLOAD_ORG_IMAGE_BLOB,
  FAILURE_UPLOAD_ORG_IMAGE_BLOB,
  REQUEST_ORG_IMAGE_BLOB,
  RECEIVE_ORG_IMAGE_BLOB,
  FAILURE_ORG_IMAGE_BLOB,
  RECEIVE_USER_PROFILE,
} from './constants'
import { RcFile } from 'antd/lib/upload'

export function fetchBlobData(id: string) {
  return async (dispatch: SafeAny) => {
    const result = await dispatch(
      callApi({
        endpoint: `/api/organization/blobs/${id}`,
        method: 'GET',
        types: [REQUEST_ORG_IMAGE_BLOB, RECEIVE_ORG_IMAGE_BLOB, FAILURE_ORG_IMAGE_BLOB],
      }),
    )

    if (result.type === RECEIVE_ORG_IMAGE_BLOB) {
      const {
        data: { data },
        type,
      } = result.payload
      return byteArrayToImgBase64String(data, type)
    }
  }
}

export function uploadBlobData(file: RcFile) {
  return async (dispatch: SafeAny) => {
    // const { data, contentType } = file
    const data = new FormData()
    data.append('image', file)

    const result = await dispatch(
      callApi({
        endpoint: '/api/organization/blobs',
        method: 'POST',
        types: [REQUEST_UPLOAD_ORG_IMAGE_BLOB, RECEIVE_UPLOAD_ORG_IMAGE_BLOB, FAILURE_UPLOAD_ORG_IMAGE_BLOB],
        body: data,
      }),
    )

    if (result.type === RECEIVE_UPLOAD_ORG_IMAGE_BLOB) {
      return result.payload.id
    }
  }
}

const initialState = new OrgImageBlobsModel()
export default function orgImageBlobs(state = initialState, action: RetoolAction): OrgImageBlobsModel {
  switch (action.type) {
    case RECEIVE_USER_PROFILE: {
      const { blobs } = action.payload
      return state.setIn(['blobs'], fromJS(blobs))
    }
    case RECEIVE_UPLOAD_ORG_IMAGE_BLOB:
      return state.setIn(['blobs', action.payload.id], action.payload)

    default:
      return state
  }
}

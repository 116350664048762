import React, { useEffect, useState } from 'react'
import { ResourceFormProps } from '../resources/types'
import APIKeyView from './APIKeyView'
import CustomAuthView from './CustomAuthView'
import { isApiKeyAuth, isCustomAuth, isOauth2Auth, ResourceAuth } from './generator-types'
import OAuth2View from './OAuth2View'
import { ServerVariablesKeyValueField } from '../resources/openapi'
import { fetchOpenAPISpec, OpenAPISpec } from 'store/fetchOpenAPISpec'

const ServerVariables = (
  props: ResourceFormProps & { auth: ResourceAuth; integrationLabel: string; hasServerVariables?: boolean },
) => {
  const [spec, setSpec] = useState<OpenAPISpec | undefined>(undefined)
  const specURL = props.resource.options?.spec || ''

  useEffect(() => {
    ;(async () => {
      const { spec: newSpec } = await fetchOpenAPISpec(specURL, props.resource.type)
      if (newSpec != null) {
        setSpec(newSpec)
      }
    })()
  }, [specURL])

  return (
    <div className="grid-1c mb12">
      <ServerVariablesKeyValueField
        spec={spec}
        updateResourceOptions={props.updateResourceOptions}
        resource={props.resource}
      />
    </div>
  )
}

function PreconfiguredResourceFormView(
  props: ResourceFormProps & { auth: ResourceAuth; integrationLabel: string; hasServerVariables?: boolean },
) {
  const { auth, hasServerVariables } = props

  let serverVariables = null
  if (hasServerVariables) {
    serverVariables = <ServerVariables {...props} />
  }

  let authFields = null
  if (isOauth2Auth(auth)) {
    authFields = <OAuth2View {...props} auth={auth} />
  } else if (isApiKeyAuth(auth)) {
    authFields = <APIKeyView {...props} auth={auth} />
  } else if (isCustomAuth(auth)) {
    authFields = <CustomAuthView {...props} auth={auth} />
  }

  return (
    <>
      {serverVariables}
      {authFields}
    </>
  )
}

export default PreconfiguredResourceFormView

import useBooleanState from 'common/hooks/useBooleanState'
import React from 'react'
import { useDispatch } from 'react-redux'

import { createGroup } from 'routes/Settings/modules/groups'
import { RetoolDispatch } from 'store'
import { Group } from '__globalShared__/permissions'

import CreateNewGroupModal from './CreateNewGroupModal'

export type CreateNewGroupModalProps = {
  onRequestClose?: () => void
  onSuccess?: (group: Pick<Group, 'id' | 'name'>) => void
  onFailure?: () => void
}

const CreateNewGroupModalContainer: React.FC<CreateNewGroupModalProps> = ({ onRequestClose, onSuccess, onFailure }) => {
  const isLoading = useBooleanState(false)

  const dispatch = useDispatch<RetoolDispatch>()

  const handleOnCreateGroup = async (name: string) => {
    isLoading.setTrue()
    const group = await dispatch(createGroup(name))
    isLoading.setFalse()
    if (group) {
      onSuccess?.(group)
    } else {
      onFailure?.()
    }
  }

  return (
    <CreateNewGroupModal
      onRequestClose={onRequestClose}
      onCreateGroup={handleOnCreateGroup}
      isLoading={isLoading.value}
    />
  )
}

export default CreateNewGroupModalContainer

import { dispatch, getState, RetoolDispatch, RetoolState } from 'store/index'
import { addNamespace } from 'common/utils/namespaces'
import { APIResolver, PluginMethods } from 'components/plugins'
import { getWidgetRef } from 'components/plugins/widgets/v2/do-not-use/widgetApiRef'
import { getModel } from 'components/plugins/widgets/v2/do-not-use/useModel'
import mapValues from 'lodash/mapValues'
import { getLoadedQueries } from 'components/plugins/datasources'
import { SafeAny, UnknownObject } from 'common/types'
import { Selector, sleep, ss } from 'common/utils'
import { triggerModelUpdate, triggerQuery } from 'store/appModel/model'
import { NodeData, PluginNamespaceInfo, PluginNamespaceInfoImpl } from 'common/records'
import { assertPlainObject } from 'types/typeguards'
import ls from 'local-storage'

export const LOCAL_STORAGE_ID = 'localStorage' as const

export type UnknownPlugin = {
  pluginType: string
  namespace?: PluginNamespaceInfo
  [key: number]: UnknownObject
} & UnknownObject

export async function setModel(id: string, index: number | undefined, delta: UnknownObject, insideOfTest: boolean) {
  const selectors: Selector[] = []
  await dispatch(
    triggerModelUpdate(
      Object.keys(delta).map((key) => {
        const selector: string[] = index != null ? [id, index as SafeAny, key] : [id, key]
        selectors.push(selector)
        return {
          // Selector is string[] but it should be (string | number)[]
          selector,
          newValue: delta[key],
        }
      }),
    ),
  )

  /**
   * This is a hack. This pattern should not be repeated.
   * We want to wait until all transformers have finished until we return which we can't
   * do with an await statement since they're run in a set timeout, so we manually block
   * until we see that there are no more dirty transformers that are dependent on the
   * item that was changed. The correct solution would be ensuring that transformers are
   * synchronously run, but this would involve adding another case to renderTemplateString which
   * we want to avoid. We will refactor renderTemplateString once we can prioritize that,
   * and when we do that we should remove this hack.
   */

  if (insideOfTest) {
    const functions = dispatch(getDependentFunctions(selectors))
    await waitForDependentFunctionsToSettle(functions)
  }
}

const getDependentFunctions = (selectors: Selector[]) => {
  return (dispatch: RetoolDispatch, getState: () => RetoolState) => {
    const currentAppState = getState()
    let dependents: NodeData[] = []
    selectors.forEach((selector) => {
      const dependentSet = currentAppState.appModel.dependencyGraph.getDependantsOf(selector)
      dependents = dependents.concat(dependentSet.toArray())
    })
    const appTemplate = currentAppState.appTemplate.present
    return dependents.filter((selector) => appTemplate.getPlugin(selector.selector[0]).subtype === 'Function')
  }
}

const waitForDependentFunctionsToSettle = async (functions: NodeData[]) => {
  while (
    functions.some((fNode) => {
      //This needs to be a fresh get State to make sure we are referencing the latest state
      return getState().appModel.isDirty(ss(fNode.selector))
    })
  ) {
    await sleep(60)
  }
}

export const addQueryMethod = (id: string, methods: {}, testId?: string, namespace?: PluginNamespaceInfo) => {
  const namespacedId = namespace && namespace instanceof PluginNamespaceInfoImpl ? addNamespace(namespace, id) : id // uhh not sure whats going on here - sometimes its an array
  return {
    ...methods,
    trigger: async (options: unknown = {}) => {
      assertPlainObject(options, '`trigger` options must be an object')
      const { onSuccess, onFailure } = options
      const instance = typeof options.instance === 'number' ? options.instance : undefined

      // eslint-disable-next-line no-console
      console.log('JSCode triggering', id, options)
      return new Promise((resolve, reject) => {
        const wrappedArgs = {
          ...options,
          onSuccess: (data: unknown) => {
            resolve(data)

            if (typeof onSuccess === 'function') onSuccess(data)
          },
          onFailure: (msg: unknown) => {
            reject(msg)
            if (typeof onFailure === 'function') onFailure(msg)
          },
          namespace,
        }

        dispatch(triggerQuery(id, true, instance, wrappedArgs))
      })
    },
    reset: () => {
      const insideOfTest = testId !== undefined
      return setModel(namespacedId, undefined, { data: null, rawData: null, error: null }, insideOfTest)
    },
  }
}

export const getLocalStorageMethods = (insideOfTest: boolean) => {
  return {
    setValue: (key: string, value: unknown) => {
      ls.set('customAppData', {
        ...ls.get('customAppData'),
        [key]: value,
      })
      return setModel(LOCAL_STORAGE_ID, undefined, { values: ls.get('customAppData') }, insideOfTest)
    },
    clear: () => {
      ls.set('customAppData', {})
      return setModel(LOCAL_STORAGE_ID, undefined, { values: ls.get('customAppData') }, insideOfTest)
    },
  } as const
}

export function getAllPluginMethodsExceptEvents(
  state: RetoolState,
  plugin: UnknownPlugin,
  id: string,
  testId?: string,
  index?: number,
) {
  const { namespace, pluginType } = plugin
  const namespacedId = namespace ? addNamespace(namespace, id) : id
  let pluginMethods: PluginMethods = {}
  if (plugin != null) {
    const pluginAPI = APIResolver(pluginType)

    const insideOfTest = testId !== undefined
    const initializedPluginAPI =
      pluginAPI?.({
        ref: getWidgetRef(id, index),
        id: namespacedId,
        getModel: () => getModel(state, namespacedId, index),
        updateModel: (data) => setModel(namespacedId, index, data, insideOfTest),
      }) ?? {}

    pluginMethods = mapValues(initializedPluginAPI, ({ method }) => method)

    if (getLoadedQueries().indexOf(pluginType) !== -1) {
      pluginMethods = addQueryMethod(id, pluginMethods, testId, plugin.namespace) // TODO dmitriy we need to be more consistent about what gets the original ID and what gets the namespaced ID
    }
  }

  return pluginMethods
}

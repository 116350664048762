import { Position2 } from 'common/records'
import { WidgetOptionsResolver } from 'components/plugins'
import { Map } from 'immutable'

export default function getCurrentContainerKey(id: string, widgetType: string, model: Map<string, unknown>): string {
  const { container } = WidgetOptionsResolver(widgetType)

  if (container) {
    const { currentSubcontainer, currentTabNum } = container

    return Position2.getContainerKey({
      container: id,
      subcontainer: currentSubcontainer?.(model),
      tabNum: currentTabNum?.(model),
    })
  }

  return ''
}

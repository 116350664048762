import * as React from 'react'

export default function OrganizationUnverifiedRoute() {
  return (
    <div className="flex-centered">
      <h3> Thank you for your interest in using Retool! </h3>
      <p style={{ margin: 10 }}>
        Unfortunately, we are still in private beta, and we've restricted access to beta members. If you'd like to join
        our beta please email us at <a href="mailto:verifications@retool.com"> david@retool.com </a> and we'll be in
        touch!
      </p>
      <a href="/"> Recheck verification status. </a>
    </div>
  )
}

import React, { ReactNode } from 'react'
import { InputField } from '../common'
import { ResourceFormProps } from '../types'

type TemplatizedEditorForm = ResourceFormProps & {
  fieldName: string
  fieldLabel: string
  fieldDescription?: ReactNode
  fieldRequired?: boolean
}

function APIKeyField(props: TemplatizedEditorForm) {
  return (
    <>
      <InputField
        {...props}
        label={<span>{props.fieldLabel}</span>}
        resourceKey={props.fieldName}
        required={props.fieldRequired}
        inputAriaLabel={props.fieldLabel}
      />
      <div className="grid-offset-1 description">{props.fieldDescription}</div>
    </>
  )
}

export default APIKeyField

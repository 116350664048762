import React, { useState } from 'react'
import { Modal, TextInput } from 'components/design-system'

import './Groups.scss'

export type CreateNewGroupModalProps = {
  isLoading?: boolean
  onRequestClose?: () => void
  onCreateGroup?: (name: string) => void
}

const CreateNewGroupModal: React.FC<CreateNewGroupModalProps> = ({
  isLoading = false,
  onRequestClose,
  onCreateGroup,
}) => {
  const [newGroupName, setNewGroupName] = useState('')

  const handleOnCreateGroup = () => {
    onCreateGroup?.(newGroupName)
  }

  const cn = 'create-new-group-modal'
  return (
    <Modal
      visible
      onCancel={onRequestClose}
      className={cn}
      onOk={handleOnCreateGroup}
      confirmLoading={isLoading}
      okButtonProps={{ disabled: !newGroupName.trim() }}
      okText="Create group"
      title="Create a new group"
    >
      <div className={`${cn}__description`}>Create a group to assign apps, resources, and additional access.</div>
      <TextInput
        value={newGroupName}
        className={`${cn}__input`}
        onChange={({ currentTarget: { value } }) => setNewGroupName(value)}
        placeholder="Group name"
      />
    </Modal>
  )
}

export default CreateNewGroupModal

import { shortcutFormatter, formatShortcutForPlatform, currentPlatform } from 'shortcutsKeymap'

export interface Shortcut {
  name: string
  bindings: string[]
}

export interface ShortcutSection {
  label: string
  shortcuts: Shortcut[]
}

const PLATFORM = currentPlatform()

// NOTE: the + here is pretty gross. But it is made necessary by the
// way we parse shortcuts. It *might* be reasonable to search/replace
// just "mod"
const MOD = formatShortcutForPlatform('mod+', PLATFORM)

// TODO
// - display all keybindings instead of just the first for a given
// action.
// - Stop formatting "click", "drag" in ALL CAPS

export const ALL_SECTIONS: ShortcutSection[] = [
  {
    label: 'Global',
    shortcuts: [
      {
        name: 'Global search',
        bindings: [shortcutFormatter('OMNIBOX', 'TOGGLE')],
      },
    ],
  },
  {
    label: 'Editor',
    shortcuts: [
      {
        name: 'Open Model Browser',
        bindings: [shortcutFormatter('GLOBAL_EDITOR', 'TOGGLE_MODEL_BROWSER')],
      },

      // TODO: Enable this when it is ready
      // {
      //   name: 'Toggle Debugger',
      //   bindings: [shortcutFormatter('GLOBAL_EDITOR', 'TOGGLE_DEBUGGER')],
      // },

      {
        name: 'Save app',
        bindings: [shortcutFormatter('EDITOR_CANVAS', 'SAVE_PAGE')],
      },

      {
        name: 'Toggle Presentation Mode',
        bindings: [shortcutFormatter('GLOBAL_EDITOR', 'TOGGLE_PRESENTATION_MODE')],
      },

      // TODO: Enable this when it is working.
      // {
      //   name: 'Focus Query Search',
      //   bindings: [shortcutFormatter('EDITOR', 'FOCUS_QUERY_SEARCH')],
      // },

      {
        name: 'Undo',
        bindings: [shortcutFormatter('GLOBAL_EDITOR', 'UNDO')],
      },
      {
        name: 'Redo',
        bindings: [shortcutFormatter('GLOBAL_EDITOR', 'REDO')],
      },
      {
        name: 'Show grid',
        bindings: [MOD],
      },
      {
        name: 'Toggle grid',
        bindings: [shortcutFormatter('GLOBAL_EDITOR', 'TOGGLE_GRID')],
      },
    ],
  },
  {
    label: 'Components',
    shortcuts: [
      {
        name: 'Select multiple',
        bindings: [`${MOD}+Click`],
      },
      {
        name: 'Select all',
        bindings: [shortcutFormatter('EDITOR_CANVAS', 'SELECT_ALL')],
      },
      {
        name: 'Select without interacting',
        bindings: [`${MOD}+Click`],
      },
      {
        name: 'Drag without interacting',
        bindings: [`${MOD}+Drag`],
      },

      {
        name: 'Copy',
        bindings: [shortcutFormatter('EDITOR_CANVAS', 'COPY_COMPONENT')],
      },
      {
        name: 'Cut',
        bindings: [shortcutFormatter('EDITOR_CANVAS', 'CUT_COMPONENT')],
      },
      {
        name: 'Paste',
        bindings: [shortcutFormatter('EDITOR_CANVAS', 'PASTE_COMPONENT')],
      },
      // TODO: Duplicate component (currently in beta)
      {
        name: 'Move',
        bindings: [
          shortcutFormatter('EDITOR_CANVAS', 'MOVE_COMPONENT_UP'),
          shortcutFormatter('EDITOR_CANVAS', 'MOVE_COMPONENT_RIGHT'),
          shortcutFormatter('EDITOR_CANVAS', 'MOVE_COMPONENT_DOWN'),
          shortcutFormatter('EDITOR_CANVAS', 'MOVE_COMPONENT_LEFT'),
        ],
      },

      {
        name: 'Resize',
        // Hardcoded for readability.
        bindings: [`${MOD}+↑→↓←`],
      },

      {
        name: 'Delete',
        bindings: [shortcutFormatter('EDITOR_CANVAS', 'DELETE_COMPONENT')],
      },

      {
        name: 'Cancel drag or resize',
        bindings: [formatShortcutForPlatform('esc', PLATFORM)],
      },
      {
        name: 'Deselect',
        bindings: [shortcutFormatter('EDITOR_CANVAS', 'DESELECT_COMPONENT')],
      },
    ],
  },
  {
    label: 'Query editor',
    shortcuts: [
      {
        name: 'Preview Query',
        bindings: [shortcutFormatter('QUERY_EDITOR', 'PREVIEW_QUERY')],
      },
      {
        name: 'Reformat Query',
        bindings: [shortcutFormatter('QUERY_EDITOR', 'REFORMAT_QUERY')],
      },
      {
        name: 'Save Query',
        bindings: [shortcutFormatter('QUERY_EDITOR', 'SAVE_QUERY')],
      },
      {
        name: 'Duplicate Query',
        bindings: [shortcutFormatter('QUERY_EDITOR', 'DUPLICATE_QUERY')],
      },
    ],
  },
  {
    label: 'Code editors',
    shortcuts: [
      {
        name: 'Show code autocomplete',
        bindings: [shortcutFormatter('MISC', 'AUTOCOMPLETE')],
      },
      {
        name: 'Toggle Vim Mode (beta)',
        bindings: [shortcutFormatter('GLOBAL_EDITOR', 'TOGGLE_VIM_MODE')],
      },
    ],
  },
  {
    label: 'Table rows',
    shortcuts: [
      {
        name: 'Next row',
        bindings: [shortcutFormatter('TABLE_COMPONENT', 'SELECT_NEXT_ROW')],
      },
      {
        name: 'Previous row',
        bindings: [shortcutFormatter('TABLE_COMPONENT', 'SELECT_PREV_ROW')],
      },
    ],
  },
]

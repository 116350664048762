import { injectReducer } from 'store/reducers'
import { getFlows, getOrganization, getResources } from 'store/user'
import { pagesLoad } from 'store/appModel/pages'
import { getImportableQueries } from '../Editor/modules/editor'

import { getPermissions } from 'routes/Settings/modules/groups'

export default function FlowsRoute(store: any) {
  return {
    path: 'flows',
    /*  Async getComponent is only invoked when route matches   */
    childRoutes: [
      {
        path: 'new',
        onEnter: async (nextState: any, replace: any, cb: any) => {
          const { resetLoadedFlow } = await import(/* webpackChunkName: "flows" */ './modules/flows')
          await store.dispatch(resetLoadedFlow())
          cb()
        },
        getComponent: async (nextState: any, cb: any) => {
          const FlowEditor = await import(
            /* webpackChunkName: "flows" */
            './containers/FlowEditorContainer'
          )
          cb(null, FlowEditor.default)
        },
        pageName: 'flows',
      },
      {
        path: '(:flowId)',
        onEnter: async (nextState: any, replace: any, cb: any) => {
          const { flowLoad } = await import(/* webpackChunkName: "flows" */ './modules/flows')
          await store.dispatch(flowLoad(nextState.params.flowId))
          cb()
        },
        getComponent: async (nextState: any, cb: any) => {
          const FlowEditor = await import(
            /* webpackChunkName: "flows" */
            './containers/FlowEditorContainer'
          )
          cb(null, FlowEditor.default)
        },
        pageName: 'flows',
      },
    ],
    onEnter: async (nextState: any, replace: any, cb: any) => {
      const reducer = await import(/* webpackChunkName: "flows" */ './modules/flows')
      store.dispatch(getFlows())
      store.dispatch(pagesLoad())
      store.dispatch(getOrganization())
      store.dispatch(getPermissions())
      await store.dispatch(getResources())
      await store.dispatch(getImportableQueries())
      const playgroundReducer = require('store/playground').default
      injectReducer(store, { key: 'flows', reducer: reducer.default })
      injectReducer(store, { key: 'playground', reducer: playgroundReducer })
      cb()
    },
    indexRoute: {
      onEnter: async () => {
        document.title = 'Flows | Retool'
      },
      getComponent: async (nextState: any, cb: any) => {
        const Flows = await import(/* webpackChunkName: "flows" */ './containers/FlowsContainer')
        return cb(null, Flows.default)
      },
    },
  }
}

import React from 'react'
import { HostInputField, InputField } from './common'
import { ResourceFormProps } from '../resources/types'

export const databaseDefaults = {
  host: '',
  port: '443',
  databaseName: '',
  databasePassword: '',
}

const CosmosDBForm = (props: ResourceFormProps) => (
  <>
    <hr />
    <h5 className="section-heading light-gray mb12">General</h5>
    <div className="grid-1c mb20">
      <HostInputField {...props} />

      <InputField label="Port" placeholder={databaseDefaults.port} resourceKey="port" topLevel {...props} />

      <InputField label="Database Id" placeholder="MyDatabase" resourceKey="databaseName" topLevel {...props} />

      <InputField label="Key" placeholder="••••••••" resourceKey="databasePassword" topLevel password {...props} />
    </div>
  </>
)

export default {
  label: 'CosmosDB',
  form: CosmosDBForm,
  defaults: {
    ...databaseDefaults,
  },
}

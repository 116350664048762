import React from 'react'
import classNames from 'classnames'

import Button, { ButtonProps } from '../Button'
import Icon, { IconProps } from '../Icon'
import Tooltip from '../Tooltip'

import './ButtonToggle.scss'

type ButtonToggleProps = ButtonProps & {
  iconType?: IconProps['type']
  tooltip?: string
  children?: React.ReactNode

  active: boolean
  onChange?: (active: boolean) => void
}

export default function ButtonToggle({
  active,
  children,
  className,
  iconType,
  tooltip,
  ...buttonProps
}: ButtonToggleProps) {
  const content = (
    <>
      {iconType && <Icon type={iconType} />}
      {children}
    </>
  )
  return (
    <Button
      {...buttonProps}
      className={classNames(
        'retool-button-toggle',
        className,
        active ? 'retool-button-toggle__active' : 'retool-button-toggle__inactive',
      )}
    >
      {tooltip ? <Tooltip title={tooltip}>{content}</Tooltip> : content}
    </Button>
  )
}

import React from 'react'
import classNames from 'classnames'

import { Button as AntButton } from 'antd'
import { ButtonProps as AntButtonProps } from 'antd/lib/button/button'

import './Button.scss'

type AntButtonType = AntButtonProps['type']

export type ButtonProps = {
  type?: AntButtonType | 'secondary'
  danger?: boolean
} & Omit<AntButtonProps, 'type'>

class Button extends React.PureComponent<ButtonProps> {
  static Group: typeof AntButton.Group

  render() {
    const { danger, type } = this.props
    return (
      <AntButton
        {...this.props}
        type={type as AntButtonType}
        className={classNames(
          'retool-button',
          this.props.className,
          danger && type === 'link' && 'ant-btn-link-danger',
        )}
      />
    )
  }
}

Button.Group = AntButton.Group

export default Button

// NOTE: this file is very similar to widgets/ReorderableList/widget.tsx [1]. We should merge the two files.
// In the meantime, if you make a change to this file, you probably need to make it there too.
// [1] https://github.com/tryretool/retool_development/blob/dev/frontend/src/components/plugins/widgets/ReorderableList/widget.tsx

/* eslint-disable react/no-find-dom-node */
import React from 'react'
import Immutable from 'immutable'
import { findDOMNode } from 'react-dom'
import Icon from '../Icon'
import { ConnectDragSource, ConnectDropTarget, DragSource, DropTarget, DropTargetMonitor } from 'react-dnd'

import './ReorderableList.scss'

const columnTarget = {
  hover(props: ReorderableItemProps, monitor: DropTargetMonitor, component: BaseReorderableItem) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Determine rectangle on screen
    const hoverBoundingRect = (findDOMNode(component) as Element).getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset() || { x: 0, y: 0 }

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }

    // Time to actually perform the action
    props.moveColumn(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  },

  drop(props: ReorderableItemProps, monitor: DropTargetMonitor) {
    // eslint-disable-next-line no-console
    console.log('DROPPING', monitor.getItem())
    props.persistChange(monitor.getItem().originalIndex)
  },
}

const columnSource = {
  beginDrag(props: ReorderableItemProps) {
    return {
      name: props.name,
      originalIndex: props.index,
      index: props.index,
    }
  },
}

type ReorderableItemProps = {
  name: string
  index: number
  moveColumn: (dragIndex: number, hoverIndex: number) => void
  persistChange: (index: number) => void
  connectDropTarget: ConnectDropTarget
  connectDragSource: ConnectDragSource
  isDragging: boolean
}

const makeDropTarget = DropTarget('reorderable', columnTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))

const makeDragSource = DragSource('reorderable', columnSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))

class BaseReorderableItem extends React.PureComponent<ReorderableItemProps> {
  render() {
    const { connectDropTarget, connectDragSource, isDragging, name } = this.props

    const opacity = isDragging ? 0.2 : 1
    return connectDragSource(
      connectDropTarget(
        <div className="reorderable-list__item" style={{ opacity }}>
          <Icon type="drag-handle" className="light-gray" /> {name}
        </div>,
      ),
    )
  }
}

export const ReorderableItem = makeDropTarget(makeDragSource(BaseReorderableItem))

interface ReorderableListProps {
  items: Immutable.List<string>
  persistChange: (index: number, newIndex: number) => void
}
interface ReorderableListState {
  items: Immutable.List<string>
}

class ReorderableList extends React.Component<ReorderableListProps, ReorderableListState> {
  mostRecentHoverIndex = 0

  constructor(props: ReorderableListProps) {
    super(props)
    this.state = { items: this.props.items || Immutable.List() }
  }

  UNSAFE_componentWillReceiveProps(nextProps: ReorderableListProps) {
    this.setState({ items: nextProps.items || Immutable.List() })
  }

  moveColumn(dragIndex: number, hoverIndex: number) {
    const dragged = this.state.items.get(dragIndex)
    const newRows = this.state.items.splice(dragIndex, 1).splice(hoverIndex, 0, dragged!)
    this.setState({ items: newRows })
    this.mostRecentHoverIndex = hoverIndex
  }

  persistChange = (index: number) => {
    this.props.persistChange(index, this.mostRecentHoverIndex)
  }

  render() {
    return (
      <div className="reorderable-list">
        {this.state.items.map((key, i) => {
          return (
            <ReorderableItem
              key={i}
              name={key}
              index={i}
              moveColumn={(dragIndex, hoverIndex) => this.moveColumn(dragIndex, hoverIndex)}
              persistChange={this.persistChange}
            />
          )
        })}
      </div>
    )
  }
}

export default ReorderableList

import { Select, Spin } from 'antd'
import { Alert, Button, Icon, Message as message, TextInput } from 'components/design-system'
import update from 'immutability-helper'
import { values } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { bookACallHref } from 'retoolConstants'
import { getPermissions } from 'routes/Settings/modules/groups'
import { RetoolState } from 'store'
import { onLocalHost, onPremSelfServiceSelector, trialExpiryDateSelector } from 'store/selectors/billingSelectors'
import { userEmailSelector } from 'store/selectors/userSelectors'
import { inviteToOrg } from 'store/user'
import { Group } from '__globalShared__/permissions'
import './UserInviteForm.scss'

type EmailAddressInputProps = {
  key: number
  value: string
  onChange: (emailAddress: string) => void
  onDelete: () => void
}

const EmailAddressInput = (props: EmailAddressInputProps) => {
  return (
    <div className="invite-form__email-address-input">
      <TextInput
        className="mb8"
        type="email"
        name="email" /* this name helps trigger chrome autocomplete */
        value={props.value}
        placeholder="name@example.com"
        onChange={(e) => props.onChange(e.target.value)}
      />
      <div className="invite-form__email-address-input__close light-gray mb12" style={{ marginRight: -6 }}>
        <Icon type="close" onClick={props.onDelete} />
      </div>
    </div>
  )
}

type EmailAddressFormProps = {
  emailAddresses: string[]
  onChange: (i: number, emailAddress: string) => void
  onDelete: (i: number) => void
  addEmailAddress: (e: any) => void
  showBulkImporter: (e: any) => void
}

const EmailAddressForm = (props: EmailAddressFormProps) => {
  const { emailAddresses, onChange, onDelete, addEmailAddress, showBulkImporter } = props
  return (
    <div className="invite-form__sub-container invite-form__email-address-form" style={{ position: 'relative' }}>
      <div className="flex justify-between items-center mb8 mt8">
        <div className="fw-600 dark-gray fs-12">Emails</div>
        <a href="#" onClick={showBulkImporter} className="flex fw-500 items-center" style={{}}>
          Add many at once
        </a>
      </div>
      {emailAddresses.map((emailAddress: string, i: number) => (
        <EmailAddressInput
          key={i}
          value={emailAddress}
          onChange={(v: any) => onChange(i, v)}
          onDelete={() => onDelete(i)}
        />
      ))}
      <div className="invite-form__sub-container__actions flex justify-end fw-500 items-center">
        <a href="#" onClick={addEmailAddress}>
          Add another email
        </a>
      </div>
    </div>
  )
}

type BulkImporterProps = {
  bulkEmailAddressString: string
  updateBulkEmailAddressString: (emailAddressString: string) => void
}

const BulkImporter = (props: BulkImporterProps) => {
  return (
    <div className="bulk-invite-form__sub-container">
      <TextInput.TextArea
        placeholder="Enter multiple comma-separated email addresses."
        value={props.bulkEmailAddressString}
        onChange={(e) => props.updateBulkEmailAddressString(e.target.value)}
      />
    </div>
  )
}

export const orgInfoSelector = (state: RetoolState) => state.user.get('orgInfo')
export const groupsSelector = createSelector(orgInfoSelector, (orgInfo) =>
  values(orgInfo.get('groups') ? orgInfo.get('groups').toJS() : {}),
)

const mapStateToProps = (state: RetoolState) => {
  return {
    email: userEmailSelector(state),
    groups: groupsSelector(state),
    selfServiceOnPrem: onPremSelfServiceSelector(state),
    trialExpired: trialExpiryDateSelector(state),
  }
}

const mapDispatchToProps = {
  inviteToOrg,
  getPermissions,
}

type UserInviteFormOwnProps = {
  onInvite?: () => void
  groups: Array<Group>
  getPermissions: () => Promise<undefined>
  allowConfigureGroups?: boolean
  inviteToOrg: any
}

type UserInviteFormState = {
  loading: boolean
  showBulkImporter: boolean
  emailAddresses: string[]
  bulkEmailAddressString: string
  selectedGroupIds: number[]
  inviteSuccessMessage: string
  inviteWarningMessage: string
  inviteErrorMessage: string
}

export type UserInviteFormProps = UserInviteFormOwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps

export class UserInviteForm extends React.PureComponent<UserInviteFormProps, UserInviteFormState> {
  constructor(props: any) {
    super(props)
    this.state = {
      loading: false,
      showBulkImporter: false,
      emailAddresses: ['', ''],
      selectedGroupIds: [],
      inviteErrorMessage: '',
      inviteSuccessMessage: '',
      bulkEmailAddressString: '',
      inviteWarningMessage: '',
    }
  }

  componentDidMount() {
    if (this.props.groups.length === 0) {
      this.props.getPermissions()
    }
  }

  onChange = (index: any, value: any) => {
    this.setState({
      emailAddresses: update(this.state.emailAddresses, {
        $splice: [[index, 1, value]],
      }),
    })
  }

  onDelete = (index: any) => {
    this.setState({
      emailAddresses: update(this.state.emailAddresses, {
        $splice: [[index, 1]],
      }),
    })
  }

  addEmailAddress = (event: any) => {
    event.preventDefault()
    this.setState({
      emailAddresses: update(this.state.emailAddresses, {
        $push: [''],
      }),
    })
  }

  showBulkImporter = (event: any) => {
    event.preventDefault()
    this.setState({ showBulkImporter: true })
  }

  updateBulkEmailAddressString = (bulkEmailAddressString: any) => {
    this.setState({ bulkEmailAddressString })
  }

  selectGroupIds = (selectedGroupIds: any) => {
    this.setState({ selectedGroupIds })
  }

  sendInvitations = async () => {
    this.setState({ loading: true })

    const emailAddresses = this.state.showBulkImporter
      ? this.state.bulkEmailAddressString.split(',').map((e) => e.trim())
      : this.state.emailAddresses

    const emailsToInvite = emailAddresses.filter((e) => e.trim().length > 0)

    try {
      const payload: any = await this.props.inviteToOrg(emailsToInvite, this.state.selectedGroupIds)

      message.success(payload.message)
      await this.props.getPermissions()
      this.setState({
        loading: false,
        inviteSuccessMessage: payload.message,
        inviteErrorMessage: '',
        inviteWarningMessage: payload.warningMessage,
      })
    } catch (err) {
      this.setState({ loading: false, inviteErrorMessage: err.message })
    }
  }

  hideBulkImporter = () => this.setState({ showBulkImporter: false })

  render() {
    const { selectedGroupIds, showBulkImporter } = this.state

    if (selectedGroupIds.length === 0) {
      const allUsersGroup = this.props.groups.find((g) => g.name === 'All Users')
      if (allUsersGroup) {
        this.setState({ selectedGroupIds: [allUsersGroup.id] })
      }
    }

    if (onLocalHost() && this.props.selfServiceOnPrem) {
      return (
        <div className="flex fd-col">
          <h3 className="fw-600 dark-gray fs-13 lh-32 pa8">Host Retool to invite other users.</h3>
          <div className="pa8">
            You need to deploy Retool in an environment your team can access to invite them. <br /> <br />
            To deploy Retool, check out our{' '}
            <a target="_blank" href="https://docs.retool.com/docs/setup-instructions">
              {' '}
              docs{' '}
            </a>{' '}
            or book a call with one of our engineers.
          </div>
          <div className="pa8 flex">
            <div className="flex-grow" />
            <Button type="primary" href={bookACallHref(this.props.email, 'users_modal')}>
              Book a call with an engineer
            </Button>
          </div>
        </div>
      )
    }

    return (
      <Spin spinning={this.state.loading}>
        <h3 className="fw-600 dark-gray fs-13 lh-32">{showBulkImporter ? 'Bulk invite' : 'Invite'} new members</h3>
        {showBulkImporter && (
          <div className="flex items-center justify-between">
            <div className="fw-600 dark-gray fs-12">Emails</div>
            <Button size="large" onClick={this.hideBulkImporter} type="link">
              Add one at a time
            </Button>
          </div>
        )}

        <div>
          {showBulkImporter ? (
            <BulkImporter
              bulkEmailAddressString={this.state.bulkEmailAddressString}
              updateBulkEmailAddressString={this.updateBulkEmailAddressString}
            />
          ) : (
            <EmailAddressForm
              emailAddresses={this.state.emailAddresses}
              addEmailAddress={this.addEmailAddress}
              showBulkImporter={this.showBulkImporter}
              onChange={this.onChange}
              onDelete={this.onDelete}
            />
          )}
          {this.props.allowConfigureGroups && (
            <GroupForm
              selectedGroupIds={selectedGroupIds}
              groups={this.props.groups}
              onChange={this.selectGroupIds}
              displayInputTitle={true}
            />
          )}
          <div className="invite-form__sub-container__groups-action">
            {this.state.inviteSuccessMessage && (
              <Alert style={{ marginTop: 10 }} message={this.state.inviteSuccessMessage} type="success" />
            )}
            {this.state.inviteWarningMessage && (
              <Alert style={{ marginTop: 10 }} message={this.state.inviteWarningMessage} type="warning" />
            )}
            {this.state.inviteErrorMessage && (
              <Alert style={{ marginTop: 10, marginBottom: 15 }} message={this.state.inviteErrorMessage} type="error" />
            )}
            <div className="invite-form__sub-container__groups-action__buttons mt8">
              {showBulkImporter ? (
                <Button
                  size="large"
                  type="primary"
                  disabled={this.state.bulkEmailAddressString === ''}
                  onClick={this.sendInvitations}
                >
                  Bulk invite users
                </Button>
              ) : (
                <Button
                  size="large"
                  type="primary"
                  disabled={this.state.emailAddresses.join('') === ''}
                  onClick={this.sendInvitations}
                >
                  Invite users
                </Button>
              )}
            </div>
          </div>
        </div>
      </Spin>
    )
  }
}

export type GroupFormProps = {
  displayInputTitle: boolean
  groups: any
  selectedGroupIds: number[]
  onChange: (ids: number[]) => void
}
type GroupFormState = {
  showGroupEditor: boolean
}

export class GroupForm extends React.Component<GroupFormProps, GroupFormState> {
  constructor(props: GroupFormProps) {
    super(props)
    this.state = {
      showGroupEditor: !props.displayInputTitle,
    }
  }

  enableGroupEditor = (ev: any) => {
    ev.preventDefault()
    this.setState({ showGroupEditor: true })
  }

  render() {
    const { selectedGroupIds, groups, displayInputTitle } = this.props

    const inputTitle = (
      <div>
        <p className="fw-600 dark-gray fs-12 mb8">Permission groups</p>
      </div>
    )
    return (
      <div className="invite-form__sub-container" style={{ marginTop: displayInputTitle ? '10px' : '0px' }}>
        <div className="invite-form__sub-container__groups">
          {displayInputTitle && inputTitle}
          <div className="invite-form__groups-dropdown">
            <Select
              className=""
              mode="multiple"
              placeholder="Groups to add invited members to"
              value={selectedGroupIds}
              onChange={(ids: number[]) => this.props.onChange(ids)}
              filterOption={(inputValue: any, option: any) => {
                return option.props.title.indexOf(inputValue) !== -1
              }}
            >
              {groups.map((g: { id: number; name: string }) => {
                return (
                  <Select.Option value={g.id} title={g.name} disabled={g.name === 'All Users'} key={g.id}>
                    {g.name}
                  </Select.Option>
                )
              })}
            </Select>
          </div>
          <div className="light-gray mt4">
            {selectedGroupIds
              ? 'New members will automatically be added to these groups and the '
              : 'New members will automatically be added to the '}
            All Users group.
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInviteForm)

import { SafeAny } from 'common/types'

export default function EmbeddedRoute() {
  return {
    path: 'iframeembed',
    getComponent(nextState: SafeAny, cb: SafeAny) {
      const App = require('./containers/iFrameEmbed').default

      cb(null, App)
    },
  }
}

import Immutable from 'immutable'
import ls from 'local-storage'
import { browserHistory } from 'react-router'
import cookies from 'js-cookie'

import { JSON_HEADERS } from 'networking/util'
import { buildSignupRedirectUri, clonedTemplateIdFromRedirectOnLoginCookie } from 'common/utils'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'

import { RECEIVE_NEW_AUTH_TOKEN, CLONED_TEMPLATE_PREFIX } from 'store/constants'
import { callApi } from './callApi'

// ------------------------------------
// Constants
// ------------------------------------
export const REQUEST_AUTHORIZATION_TOKEN = 'REQUEST_AUTHORIZATION_TOKEN'
export const RECEIVE_AUTHORIZATION_TOKEN = 'RECEIVE_AUTHORIZATION_TOKEN'
export const FAILURE_AUTHORIZATION_TOKEN = 'FAILURE_AUTHORIZATION_TOKEN'

export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN'
export const FAILURE_LOGIN = 'FAILURE_LOGIN'

export const REQUEST_SIGNUP = 'REQUEST_SIGNUP'
export const RECEIVE_SIGNUP = 'RECEIVE_SIGNUP'
export const FAILURE_SIGNUP = 'FAILURE_SIGNUP'

// ------------------------------------
// Actions
// ------------------------------------

export function handleAuthResponse(
  response: {
    redirectUri?: string
    authUrl?: string
    authorizationToken?: string
    partialRegistrationType?: string
    partialRegistrationId?: string
    email?: string
    domain?: string
    joinToken?: string
  },
  signupTheme?: string,
) {
  const {
    authUrl,
    authorizationToken,
    domain,
    email,
    partialRegistrationId,
    partialRegistrationType,
    redirectUri,
    joinToken,
  } = response

  if (redirectUri) {
    // if there is a signupTheme, append the theme name to the redirectUri to display
    // signupTheme specific UI and prevent passing this to the backend
    const modifiedRedirect = buildSignupRedirectUri(
      redirectUri,
      signupTheme,
      partialRegistrationType,
      partialRegistrationId,
      domain,
      email,
      joinToken,
    )
    window.location.href = modifiedRedirect
    // dummy return value to make the return types on both branches match.
    return () => Promise.resolve()
  } else {
    return async (dispatch: any) => {
      const result = await dispatch(
        callApi({
          endpoint: authUrl,
          method: 'POST',
          credentials: 'include',
          headers: JSON_HEADERS,
          body: JSON.stringify({ authorizationToken }),
          types: [REQUEST_LOGIN, RECEIVE_LOGIN, FAILURE_LOGIN],
        }),
      )
      if (result.type === RECEIVE_LOGIN) {
        handleLoginOrSignup(result.payload)
        window.location = result.payload.redirectUri
      }
    }
  }
}

const handleLoginOrSignup = (payload: any) => {
  const user = payload.user
  const displayName = user.firstName ? `${user.firstName} ${user.lastName}` : user.email
  FS.identify(user.email, {
    displayName,
    email: user.email,
  })
  window.Intercom('update', {
    user_sid: user.sid,
    name: displayName,
    email: user.email,
    user_id: user.sid,
    user_hash: user.intercomUserIdHash,
  })

  const anonymousId = cookies.get('segment_anonymous_id') || cookies.get('ajs_anonymous_id')

  const gaCookie = cookies.get('_ga')
  const clientIdPrefix = /GA[1-9].[1-9]./g
  const googleAnalyticsClientId = gaCookie?.replace(clientIdPrefix, '')

  let ROOT_DOMAIN = window.location.host.split('.').slice(-2).join('.').split(':')[0]
  if (ROOT_DOMAIN !== 'localhost') {
    ROOT_DOMAIN = `.${ROOT_DOMAIN}`
  }

  retoolAnalyticsTrack(
    'Logged In',
    {
      firstName: user.firstName,
      lastName: user.lastName,
      segmentAnonymousId: anonymousId,
      googleAnalyticsClientId,
      loginMethod: 'email-password',
    },
    { sid: user.sid },
  )
}

export function processLoginRedirect() {
  let redirectOnLogin = ls.get('redirectOnLogin')

  const isRetoolDomain = window.MAIN_DOMAIN || __DEV__
  if (isRetoolDomain && !redirectOnLogin) {
    // try looking for cookies
    // we use this to implement redirect post-login (cuz the login flow is
    // complicated and this is the easiest thing to do)
    // yes i know it's hacky
    const pathname = cookies.get('redirectOnLogin')
    if (pathname) {
      const url = new URL(pathname, window.location.origin)

      // ughhhhh, we need this state to customize the onboarding flow
      const isTemplateUser = pathname.startsWith(CLONED_TEMPLATE_PREFIX)
      if (isTemplateUser) {
        ls.set('templateUserInfo', JSON.stringify({ appUrl: pathname }))
      }

      redirectOnLogin = {
        validUntil: Date.now() + 1000,
        pathname: url.pathname,
        search: url.search,
        hash: '',
      }
    }
  }

  let clonedTemplateId
  const redirectOnLoginCookie = cookies.get('redirectOnLogin')
  const onboardingUrlCookie = cookies.get('onboardingUrl')

  // We want to remove the redirectOnLogin cookie even if we have the onboarding url so the
  // user doesn't get redirected multiple times
  // Only remove the cookies once we know we're done with the login flow
  // otherwise if the login flow has multiple calls to getProfile we will remove the
  // redirect!
  if (onboardingUrlCookie && redirectOnLogin?.pathname !== 'logout' && window.location.host.indexOf('login') !== 0) {
    ls.remove('redirectOnLogin')
    cookies.remove('redirectOnLogin', { domain: `.${window.MAIN_DOMAIN}` })
  }

  if (redirectOnLoginCookie) {
    clonedTemplateId = clonedTemplateIdFromRedirectOnLoginCookie(redirectOnLoginCookie)
  }

  // OnboardingUrl take precedence over redirectOnLogin unless it is clonedTemplate user flow which will need to redirect to template
  if (cookies.get('onboardingUrl') && !clonedTemplateId) {
    return
  }

  if (redirectOnLogin && redirectOnLogin.pathname !== '/logout' && redirectOnLogin.validUntil > Date.now()) {
    if (window.location.host.indexOf('login') !== 0) {
      // If we do the redirect on login, we want to ensure to delete both the cookie and local storage so we don't redirect multiple times
      ls.remove('redirectOnLogin')
      cookies.remove('redirectOnLogin', { domain: `.${window.MAIN_DOMAIN}` })
      if (window.location.pathname !== redirectOnLogin.pathname) {
        browserHistory.push(redirectOnLogin.pathname + redirectOnLogin.search + redirectOnLogin.hash)
      }
    }
  } else {
    if (window.location.pathname === '/two-factor-challenge') {
      browserHistory.push('/')
    }
  }
}

export function redirectToSubdomain() {
  return async (dispatch: any) => {
    const response = await dispatch(
      callApi({
        endpoint: '/api/obtainAuthorizationToken',
        method: 'POST',
        headers: JSON_HEADERS,
        types: [REQUEST_AUTHORIZATION_TOKEN, RECEIVE_AUTHORIZATION_TOKEN, FAILURE_AUTHORIZATION_TOKEN],
      }),
    )

    if (response.type === RECEIVE_AUTHORIZATION_TOKEN) {
      dispatch(handleAuthResponse(response.payload))
    }
  }
}

export function redirectToOauth() {
  return async (dispatch: any) => {
    const response = await dispatch(
      callApi({
        endpoint: '/api/obtainAuthorizationToken',
        method: 'POST',
        headers: JSON_HEADERS,
        types: [REQUEST_AUTHORIZATION_TOKEN, RECEIVE_AUTHORIZATION_TOKEN, FAILURE_AUTHORIZATION_TOKEN],
      }),
    )

    return response
  }
}

// ------------------------------------
// Specialized Action Creator
// ------------------------------------

const handleReceiveNewAuthToken = (state: any) => {
  return state
}

const ACTION_HANDLERS: any = {
  [REQUEST_LOGIN]: (state: any) => state.set('isFetching', true),
  [RECEIVE_LOGIN]: (state: any) => state.set('isFetching', false),
  [FAILURE_LOGIN]: (state: any) => state.set('isFetching', false),
  [RECEIVE_NEW_AUTH_TOKEN]: handleReceiveNewAuthToken,
  [REQUEST_SIGNUP]: (state: any) => state.set('isFetching', true),
  [RECEIVE_SIGNUP]: (state: any) => state.set('isFetching', false),
  [FAILURE_SIGNUP]: (state: any) => state.set('isFetching', false),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = Immutable.Map({
  user: null,
  hostname: ls.get('hostname') || null,
  isFetching: false,
})

export default function sessionReducer(state = initialState, action: any) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

import * as React from 'react'
import * as _ from 'lodash'

import { Button, Icon, Select } from 'components/design-system'
import CodeEditor from 'components/CodeEditor'
import KeyedTableEditor from 'components/KeyedTableEditor'
import { addSlashToUrl } from 'common/utils'
import { getPopupContainerParent } from 'common/utils/getPopupContainer'

export const RESTQueryType: React.StatelessComponent<any> = (props) => {
  const methodOptions = [
    { value: 'POST', label: 'POST' },
    { value: 'GET', label: 'GET' },
    { value: 'PUT', label: 'PUT' },
    { value: 'PATCH', label: 'PATCH' },
    { value: 'DELETE', label: 'DELETE' },
  ]

  const queryPrefix = props.resource && props.resource.options ? props.resource.options.baseURL : ''
  const formattedQueryPrefix = addSlashToUrl(queryPrefix)
  const id = props.selectedDatasourceId

  return (
    <div className="flex">
      <Select
        getPopupContainer={getPopupContainerParent}
        style={{ paddingRight: 10, width: 120 }}
        showSearch
        onChange={(v: any) => props.queryEditorUpdateNoDebounce({ method: v, runWhenModelUpdates: v === 'GET' })}
        value={props.queryEditorState.get('method')}
      >
        {methodOptions.map((o) => (
          <Select.Option value={o.value} key={o.value}>
            {o.label}
          </Select.Option>
        ))}
      </Select>
      <div className="query-scroller">
        {queryPrefix !== '' && <code className="query-prefix elevation-1">{formattedQueryPrefix}</code>}
        <CodeEditor
          customGlobals={{}}
          onlyShowEvaluationError
          autocompleteHidden={props.autocompleteHidden}
          className="query-suffix"
          id={id}
          value={props.queryEditorState.get('query')}
          oneLine
          onChange={(v) => {
            props.queryEditorUpdateNoDebounce({ query: v })
          }}
          placeholder={queryPrefix !== '' ? 'api/v2/endpoint.json' : 'https://example_site.com/api/v2/endpoint.json'}
        />
      </div>
    </div>
  )
}

function splitByOutsideChar(s: any, delimiter: any) {
  let inside = false
  const splits = []
  let acc = ''
  for (let i = 0; i < s.length; i++) {
    const c = s[i]
    if (c === '{') {
      if (s[i + 1] === '{') {
        acc = acc + c
        i += 1
        inside = true
      }
    }
    if (c === '}') {
      if (s[i + 1] === '}') {
        acc = acc + c
        i += 1
        inside = false
      }
    }
    if (!inside) {
      if (c === delimiter) {
        splits.push(acc)
        acc = ''
      } else {
        acc = acc + c
      }
    } else {
      acc = acc + c
    }
  }
  if (acc.length > 0) {
    splits.push(acc)
  }
  return splits
}

type RESTParametersState = {
  queryArray: { key: string; value: string }[]
}
export class RESTParameters extends React.Component<any, RESTParametersState> {
  urlString = ''
  constructor(props: any) {
    super(props)

    this.state = {
      queryArray: [],
    }
  }

  queryParamsToArray(queryParams: any) {
    return queryParams.concat([{ key: '', value: '' }])
  }

  handleParamUpdate(index: number, key: string, value: string) {
    const props = this.props
    const parsed = this.parseUrl(props.queryEditorState.get('query'))
    const queryArray = this.state.queryArray.slice(0)
    const newQueryArray = queryArray.map((e, i) => {
      return i === index ? { key, value } : e
    })

    if (key === null && value === null) {
      newQueryArray[index] = null!
    }

    if (index === newQueryArray.length) {
      newQueryArray.push({ key, value })
    }

    const nonEmptyQueryArray = _.filter(newQueryArray, (e) => {
      return !!(e.key || e.value)
    })

    this.setState({
      queryArray: nonEmptyQueryArray.concat([{ key: '', value: '' }]),
    })

    const pairs = nonEmptyQueryArray.filter((e) => e.key)

    const queryString = pairs
      .reduce((acc, pair) => {
        return `${acc}&${pair.key}=${pair.value}`
      }, '')
      .slice(1)
    this.urlString = `${parsed.path}?${queryString}`
    props.queryEditorUpdateNoDebounce({ query: this.urlString })
  }

  parseUrl(query: any) {
    try {
      if (!query) {
        return { path: '', query: [] }
      }
      const res = splitByOutsideChar(query, '?')
      const path = res[0]
      const queryString = res.slice(1).join('?')
      if (!queryString) {
        return { path, query: [] }
      }
      return {
        path,
        query: splitByOutsideChar(queryString, '&').reduce((acc, s) => {
          const i = s.indexOf('=')
          const key = s.slice(0, i)
          const value = s.slice(i + 1)
          return acc.concat([{ key, value }])
        }, [] as any[]),
      }
    } catch (err) {
      return { path: '', query: [] }
    }
  }

  componentDidMount() {
    const query = this.props.queryEditorState.get('query') || ''
    this.urlString = query
    const parsed = this.parseUrl(query).query
    this.setState({ queryArray: this.queryParamsToArray(parsed) })
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.selectedDatasourceId !== nextProps.selectedDatasourceId) {
      const query = nextProps.queryEditorState.get('query') || ''
      const parsed = this.parseUrl(query).query
      this.urlString = query
      this.setState({ queryArray: this.queryParamsToArray(parsed) })
    } else if (this.urlString !== nextProps.queryEditorState.get('query')) {
      const query = nextProps.queryEditorState.get('query') || ''
      const parsed = this.parseUrl(query).query
      this.urlString = query
      this.setState({ queryArray: this.queryParamsToArray(parsed) })
    }
  }

  render() {
    const props = this.props
    const queryParamsArray = this.state.queryArray
    const id = props.selectedDatasourceId

    return (
      <>
        <label className="input-label"> URL Parameters </label>
        <div className="keyed-table-editor">
          {queryParamsArray.map((e: any, i) => {
            return (
              <React.Fragment key={i}>
                <div className="key">
                  <div className="key-editor">
                    <CodeEditor
                      customGlobals={props.customGlobals || {}}
                      onlyShowEvaluationError
                      autocompleteHidden={props.autocompleteHidden}
                      includeOnlyCustomGlobalsInScope={props.includeOnlyCustomGlobalsInScope}
                      type="text"
                      placeholder="key"
                      value={e.key}
                      onChange={(value) => this.handleParamUpdate(i, value.trim(), e.value)}
                    />
                  </div>
                </div>
                <div className="value">
                  <div className="value-editor">
                    <CodeEditor
                      customGlobals={props.customGlobals || {}}
                      onlyShowEvaluationError
                      autocompleteHidden={props.autocompleteHidden}
                      includeOnlyCustomGlobalsInScope={props.includeOnlyCustomGlobalsInScope}
                      id={id + e.value}
                      value={e.value || ''}
                      onChange={(v) => this.handleParamUpdate(i, e.key, v)}
                      oneLine
                      placeholder="value"
                    />
                    <div className="kv-delete">
                      <Button type="link" onClick={() => this.handleParamUpdate(i, '', '')}>
                        <Icon type="close" />
                      </Button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </>
    )
  }
}

export const RESTHeaders: React.StatelessComponent<any> = (props) => {
  const id = props.selectedDatasourceId
  return (
    <>
      <label className="input-label"> Headers </label>
      <KeyedTableEditor
        id={id}
        dict={props.queryEditorState.get('headers')}
        dictName="headers"
        autocompleteHidden={props.autocompleteHidden}
        queryEditorUpdateNoDebounce={props.queryEditorUpdateNoDebounce}
        queryEditorUpdate={props.queryEditorUpdate}
        customGlobals={props.customGlobals || {}}
        includeOnlyCustomGlobalsInScope={props.includeOnlyCustomGlobalsInScope}
      />
    </>
  )
}

export const RESTBody: React.StatelessComponent<any> = (props) => {
  const id = props.selectedDatasourceId
  const method = props.queryEditorState.get('method')
  if (method === 'GET') {
    return null
  }
  const bodyType = props.queryEditorState.get('bodyType')
  const bodyOptions = [
    { value: 'json', label: 'JSON' },
    { value: 'raw', label: 'Raw' },
    { value: 'form', label: 'x-www-form-urlencoded' },
    { value: 'form-data', label: 'Form Data' },
    { value: 'binary', label: 'binary' },
  ]

  return (
    <>
      <label className="input-label">Body query type</label>
      <Select
        getPopupContainer={getPopupContainerParent}
        style={{ width: '100%', marginBottom: 10 }}
        value={bodyType}
        onChange={(v: any) => props.queryEditorUpdateNoDebounce({ bodyType: v, body: '' })}
      >
        {bodyOptions.map((o) => (
          <Select.Option value={o.value} key={o.value}>
            {o.label}
          </Select.Option>
        ))}
      </Select>
      <label className="input-label">Body params</label>
      <div>
        {bodyType === 'json' || bodyType === 'form' ? (
          <KeyedTableEditor
            id={id}
            dict={props.queryEditorState.get('body')}
            dictName="body"
            autocompleteHidden={props.autocompleteHidden}
            queryEditorUpdateNoDebounce={props.queryEditorUpdateNoDebounce}
            queryEditorUpdate={props.queryEditorUpdate}
            includeOnlyCustomGlobalsInScope={props.includeOnlyCustomGlobalsInScope}
            customGlobals={props.customGlobals || {}}
          />
        ) : bodyType === 'form-data' ? (
          <KeyedTableEditor
            id={id}
            dict={props.queryEditorState.get('body')}
            dictName="body"
            autocompleteHidden={props.autocompleteHidden}
            operations={[
              { value: 'text', label: 'Text' },
              { value: 'binary', label: 'File' },
            ]}
            queryEditorUpdateNoDebounce={props.queryEditorUpdateNoDebounce}
            queryEditorUpdate={props.queryEditorUpdate}
            includeOnlyCustomGlobalsInScope={props.includeOnlyCustomGlobalsInScope}
            customGlobals={props.customGlobals || {}}
          />
        ) : (
          <CodeEditor
            onlyShowEvaluationError
            autocompleteHidden={props.autocompleteHidden}
            value={props.queryEditorState.get('body')}
            baseMode="text/javascript"
            lineNumbers
            onChange={(v) => props.queryEditorUpdate({ body: v })}
            includeOnlyCustomGlobalsInScope={props.includeOnlyCustomGlobalsInScope}
            customGlobals={props.customGlobals || {}}
          />
        )}
      </div>
    </>
  )
}

import React from 'react'
import { Checkbox, Select } from 'components/design-system'

import { AWSRegions, InputField, ARNInputField } from './common'
import { ResourceFormProps } from './types'

type DynamodbFormOptions = {
  dynamo_aws_region: string
  dynamo_access_key_id: string
  dynamo_secret_access_key: string
  assumeRole: string
  authWithIAM: boolean
}

type DynamodbFormFields = {
  options: DynamodbFormOptions
}

const defaultOptions: DynamodbFormFields = {
  options: {
    dynamo_aws_region: '',
    dynamo_access_key_id: '',
    dynamo_secret_access_key: '',
    assumeRole: '',
    authWithIAM: false,
  },
}

const DynamoDBForm = (props: ResourceFormProps) => (
  <div className="grid-1c mb20">
    <label className="input-label">AWS region</label>
    <Select
      showSearch
      placeholder="us-east-2"
      value={props.resource.options.dynamo_aws_region}
      onChange={props.onChangeOption('dynamo_aws_region')}
    >
      {AWSRegions.map((region) => (
        <Select.Option key={region} value={region}>
          {region}
        </Select.Option>
      ))}
    </Select>
    {(!MAIN_DOMAIN || __DEV__) && (
      <Checkbox
        checked={props.resource.options.authWithIAM}
        onChange={(checked) => {
          props.onChangeOption('authWithIAM')(checked.target.checked)
        }}
        className="grid-offset-1"
      >
        Connect using underlying AWS IAM Role
      </Checkbox>
    )}
    {!props.resource.options.authWithIAM && (
      <>
        <InputField label="AWS Access Key ID" resourceKey="dynamo_access_key_id" required {...props} />
        <InputField label="AWS Secret Key" resourceKey="dynamo_secret_access_key" required {...props} />
      </>
    )}
    <ARNInputField {...props} />
  </div>
)

export default {
  label: 'DynamoDB',
  form: DynamoDBForm,
  defaults: {
    options: defaultOptions,
  },
  validator: ({ options }: { options: DynamodbFormOptions }) =>
    (options.authWithIAM || (options.dynamo_access_key_id && options.dynamo_secret_access_key)) &&
    options.dynamo_aws_region,
}

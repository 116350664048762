import { injectReducer } from 'store/reducers'
import { pagesLoad } from 'store/appModel/pages'
import { RetoolStore } from 'store'
import { RedirectFunction, RouteComponent, RouterState } from 'react-router'
import { SafeAny } from 'common/types'

/**
 * This route used to be used for managing onprem keys. It needs to exist still since
 * some customers could be on the version that links to this page still.
 */
export default function OnpremBillingPortalRoute(store: RetoolStore) {
  return {
    path: 'onprem-billing-portal',
    onEnter: async (
      nextState: RouterState<SafeAny>,
      replace: RedirectFunction,
      cb: (...args: SafeAny[]) => SafeAny,
    ) => {
      window.location.href = '/settings/billing?onPremPortal=true'
      const settingsReducer = await import(/* webpackChunkName: "settings" */ '../Settings/modules/settings')
      store.dispatch(pagesLoad())
      injectReducer(store, { key: 'settings', reducer: settingsReducer.default })
      document.title = 'Billing | Retool'
      cb()
    },
    indexRoute: {
      getComponent: async (_: RouterState<SafeAny>, cb: (err: SafeAny, component: RouteComponent) => SafeAny) => {
        const Billing = await import(/* webpackChunkName: "billing" */ '../Settings/components/billing/Billing')
        return cb(null, Billing.default)
      },
    },
  }
}

import { PluginNamespaceInfo, InstrumentationTemplate } from 'common/records'
import moment from 'moment'
import 'moment-timezone/moment-timezone'
import * as yarpex from 'yarpex'
import * as yarpexBert from 'yarpex-bert'
import numbro from 'numbro'
import * as _ from 'lodash'
import * as uuid from 'uuid'
import pseudoJsonParse from 'common/private-json5'
import * as Papa from 'papaparse'

import { getState } from 'store'
import { appModelJSValuesSelector } from 'store/selectors'
import { safeEval, safeEvalWithCallbacks } from './sandbox'
import { ANY_TEMPLATE_REGEX, JSON_LIKE_REGEX, ARRAY_LIKE_REGEX } from 'common/regexes'

import { shortcutFormatter } from 'shortcutsKeymap'
import { EvalOptions, Repl, StandardEvaluationType } from '../sandbox/types'

import getNamespaceAwareModel from 'store/appModel/getNamespaceAwareModel'
import { ds, logger, ss } from './utils'
import { addNamespace } from './utils/namespaces'
import parseJSExpression from './parseJSExpression'
import undeclaredV2 from 'common/undeclared/undeclaredV2'

export const helperFuncs = {
  moment,
  _,
  yarpex,
  yarpexBert,
  uuid,
  Papa,
  formatDataAsArray: () => {},
  formatDataAsObject: () => {},
  assertCondition: () => {},
  waitForCondition: () => {},
  mock: () => {},
  numbro,
}

const jsBuiltInFunctions = [
  // default globals
  'eval',
  'uneval',
  'isFinite',
  'isNaN',
  'parseFloat',
  'parseInt',
  'encodeURI',
  'encodeURIComponent',
  'decodeURI',
  'decodeURIComponent',
  'JSON',

  'Number',
  'BigInt',
  'Math',
  'Date',

  'Object',
  'Function',
  'Boolean',
  'Symbol',

  'String',
  'RegExp',

  'Map',
  'Set',
  'WeakMap',
  'WeakSet',

  'Promise',

  // WindowOrWorkerGlobalScope
  'atob',
  'btoa',
  'setInterval',
  'setTimeout',
  'clearInterval',
  'clearTimeout',
  'console',
]

export const globalScopeFuncs = new Set(Object.keys(helperFuncs).concat(jsBuiltInFunctions))

export const dummyVariables = {
  i: 0,
  self: 0,
}

export function getScope(state: any, additionalProps = {}) {
  return {
    ...state,
    ...additionalProps,
  }
}

export function getScopeWithHelpers(state: any, additionalProps = {}) {
  return {
    ...helperFuncs,
    ...state,
    ...additionalProps,
  }
}

export async function renderFunction(id: string | null, templateFunction: string, namespace?: PluginNamespaceInfo) {
  const state = getState()
  const model = appModelJSValuesSelector(state)
  const scope: any = {}

  let i = 0
  const renderedFunction = templateFunction
    .replace(ANY_TEMPLATE_REGEX, (v: string) => {
      const varName = `___var${i++}`
      scope[varName] = v
      return varName
    })
    .replace(/^(?!\s*$)/gm, '  ') // indentation

  for (const varName in scope) {
    if (!id) {
      scope[varName] = await evaluateSafe(model, scope[varName])
    } else {
      const { computeTemplateStringDependencies } = await import(
        /* webpackChunkName: "DependencyGraph" */ 'store/appModel/dependencyGraph'
      )

      const rootDependencyIds = computeTemplateStringDependencies(scope[varName], {
        unescapeRetoolExpressions: false,
      }).map((s) => s[0])
      const namespacedRootDependencyIds = namespace
        ? rootDependencyIds.map((p) => addNamespace(namespace, p))
        : rootDependencyIds
      const reducedScope = _.pick(model, namespacedRootDependencyIds)
      scope[varName] = await evaluateSafe(reducedScope, scope[varName], undefined, { namespace })
    }
  }

  return { scope, renderedFunction }
}

export async function evaluateTransformer(
  transformerNodeId: string | null,
  code: string,
  data: {},
  splatData = false,
  namespace?: PluginNamespaceInfo,
) {
  const { scope, renderedFunction } = await renderFunction(transformerNodeId, code, namespace)
  let scopeAndData = { ...scope, data }
  if (splatData) {
    scopeAndData = { ...scope, ...data }
  }

  const options = { ...defaultEvalOptions, namespace, dataSourceId: transformerNodeId ?? undefined }
  const value = await safeEval(renderedFunction, scopeAndData, 'function-no-return', options)
  return { value, renderedFunction }
}

export async function evaluateInstrument(instrument: InstrumentationTemplate, skipPropertyEvaluation = false) {
  const state = appModelJSValuesSelector(getState())
  const condition = await renderFunction(null, instrument.conditionBody)

  const shouldRunFn =
    instrument.conditionBody && instrument.hasConditionsEnabled
      ? condition.scope[condition.renderedFunction?.trim()]
      : true

  const shouldRun = skipPropertyEvaluation || !instrument.hasConditionsEnabled || shouldRunFn
  if (shouldRun) {
    const result = await evaluateSafe(state, instrument.jsonBody)
    if (typeof result === 'string') {
      throw new Error('Invalid instrumentation. Unable to parse JSON')
    }
    return result
  }

  return null
}

export async function evaluateQueryTransformer(
  id: string,
  dataAndMetadata: { data: {}; metadata: unknown },
  template: any,
  namespace?: PluginNamespaceInfo,
) {
  const transformer = template.get('transformer')
  const transformerId = `${id}.transformer`
  const { value } = await evaluateTransformer(transformerId, transformer, dataAndMetadata, true, namespace)
  return value
}

export async function evaluateQueryErrorTransformer(
  id: string,
  data: { data: {}; metadata: unknown; errors?: Array<{ message: string }> },
  template: any,
) {
  const transformer = template.get('errorTransformer')
  const transformerId = `${id}.errorTransformer`
  const { value } = await evaluateTransformer(transformerId, transformer, data, true)
  return value
}

export async function evaluateQueryMockResponseTransformer(
  id: string,
  data: { data: {}; metadata: unknown; errors?: Array<{ message: string }> },
  template: any,
  namespace?: PluginNamespaceInfo,
) {
  const transformer = template.get('mockResponseTransformer')
  const transformerId = `${id}.mockResponseTransformer`
  const { value } = await evaluateTransformer(transformerId, transformer, data, true, namespace)
  return value
}

function preprocessTemplate(template: string) {
  if (typeof template !== 'string') return template
  return template.replace(/{{{/g, '{{ {').replace(/}}}/g, '} }}')
}

export function evaluateWithCallbacks(
  code: string,
  getScope: () => {},
  getJsApi: () => {},
  repl: Repl = 'jsquery',
  dataSourceId?: string,
) {
  return safeEvalWithCallbacks(code, getScope, getJsApi, repl, dataSourceId)
}

class EvaluationError extends Error {
  payload?: any
  constructor(message: string, payload?: any) {
    super(message)
    this.payload = payload
  }
}
async function evaluateHelper(
  rawTemplateString: string,
  scope: Map<string, any> | Map<string, any>[],
  evaluationType: StandardEvaluationType = 'template',
  options: EvalOptions,
) {
  async function evalHelper(rawBody: string, scope: Map<string, any> | Map<string, any>[]) {
    try {
      const body = rawBody.trim()
      if (evaluationType === 'error-check') {
        return await safeEval(body, scope, 'function-error-check', options)
      } else {
        return await safeEval(body, scope, 'function', options)
      }
    } catch (e) {
      const payloaded = evaluationType === 'error-check'
      const message = getErrorMessage(e, payloaded)
      throw new EvaluationError(
        `Error in template '${templateString.slice(0, 200) + (templateString.length > 197 ? '...' : '')}': ${message}`,
        getErrorPayload(e),
      )
    }
  }
  const templateString = preprocessTemplate(rawTemplateString.trim())

  const matchOnce = templateString.match(/{{([\s\S]+?)}}/)
  if (matchOnce && matchOnce[0].length === templateString.length) {
    return await evalHelper(matchOnce[1], scope)
  }

  try {
    if (templateString.indexOf('{{') === -1 && templateString.indexOf('<%') === -1) {
      if (options.batch) {
        scope = scope as Map<string, any>[]
        return scope.map(() => templateString)
      } else {
        return templateString
      }
    }
    return await safeEval(templateString, scope, evaluationType, options)
  } catch (e) {
    const payloaded = evaluationType === 'error-check'
    const message = getErrorMessage(e, payloaded)
    throw new EvaluationError(
      `Error in template '${templateString.slice(0, 200) + (templateString.length > 197 ? '...' : '')}': ${message}`,
      getErrorPayload(e),
    )
  }
}

export const defaultEvalOptions: EvalOptions = {
  disallowJSON: false,
  errorCheck: false,
  batch: false,
  commonScope: {},
  htmlEscapeRetoolExpressions: false,
}

async function _evaluate(state: any, rawTemplateString: any, additionalProps: any, options: EvalOptions) {
  if (typeof rawTemplateString !== 'string') {
    if (options.batch) {
      return state.map(() => rawTemplateString)
    }
    return rawTemplateString
  }

  const trimmed = rawTemplateString.trim()
  let jsonLike = !!trimmed.match(JSON_LIKE_REGEX) || !!trimmed.match(ARRAY_LIKE_REGEX)
  if (!options.disallowJSON) {
    try {
      const parsed = JSON.parse(rawTemplateString)
      jsonLike = true
      let shortcircuited: any = undefined
      // Under the case where `parsed` is a number, but loses precision, return the raw string
      if (typeof parsed === 'number') {
        if (String(parsed) === rawTemplateString.trim()) {
          shortcircuited = parsed
        } else {
          shortcircuited = rawTemplateString
        }
      }
      if (shortcircuited !== undefined) {
        if (options.batch) {
          return state.map(() => shortcircuited)
        } else {
          return shortcircuited
        }
      }
    } catch (e) {}
  }

  const normalScope = getScope(state, additionalProps)
  let scope: any = getNamespaceAwareModel(normalScope, options.namespace)
  if (options.batch) {
    scope = state.map((s: any) => {
      const miniScope = getScope(s, additionalProps)
      return getNamespaceAwareModel(miniScope, options.namespace)
    })
  }

  const evaluationType = jsonLike
    ? options.errorCheck
      ? 'json-template-error-check'
      : 'json-template'
    : options.errorCheck
    ? 'error-check'
    : 'template'

  const res: any = await evaluateHelper(rawTemplateString, scope, evaluationType, options)
  if (!jsonLike) {
    return res
  }
  try {
    if (options.batch) {
      return res.map((r: any) => pseudoJsonParse.parse(r))
    } else {
      return pseudoJsonParse.parse(res)
    }
  } catch (e) {
    return res
  }
}

/*
  WARNING: you should probably use evaluateSafe.
  ----

  This method will raise an error if we can't evaluate any variable in the template
  string, which can cause widgets to entirely fail to load on the canvas
*/
export function evaluate(state: any, rawTemplateString: any, additionalProps = {}, options: Partial<EvalOptions> = {}) {
  const optionsExtendedWithDefaults = { ...defaultEvalOptions, ...options }
  const retoolState = getState()
  if (retoolState.globals.htmlEscapeRetoolExpressions) {
    optionsExtendedWithDefaults.htmlEscapeRetoolExpressions = true
  }

  const datasourceId = retoolState?.editor?.get('selectedDatasourceId')
  if (datasourceId) {
    optionsExtendedWithDefaults.dataSourceId = datasourceId
  }

  return _evaluate(state, rawTemplateString, additionalProps, optionsExtendedWithDefaults)
}

export async function evaluateSafe(
  state: {},
  rawTemplateString: any,
  additionalProps = {},
  options: Partial<EvalOptions> = {},
) {
  try {
    return await evaluate(state, rawTemplateString, additionalProps, options)
  } catch (e) {
    return null
  }
}

export function canUseSimpleEvaluation(templateString: unknown): boolean {
  if (typeof templateString !== 'string') {
    return true
  }
  return templateString.indexOf('{{') === -1 && templateString.indexOf('<%') === -1
}

export function evaluateSimple(templateString: unknown): unknown {
  if (typeof templateString !== 'string') {
    return templateString
  }

  if (!canUseSimpleEvaluation(templateString)) {
    throw new Error('evaluateSimple can not be used with templated strings. Use evaluateSafe for these strings.')
  }

  const trimmed = templateString.trim()

  try {
    const parsedTemplateString = pseudoJsonParse.parse(templateString)

    // Under the case where `parsed` is a number, but loses precision, return the raw string
    if (typeof parsedTemplateString === 'number' && String(parsedTemplateString) !== trimmed) {
      return templateString
    }

    return parsedTemplateString
  } catch (e) {
    return trimmed
  }
}

type BatchJob = {
  states: any[]
  code: string | null | undefined
  commonScope?: any
  namespace?: PluginNamespaceInfo
}
export async function evaluateBatchJob(batchJob: BatchJob) {
  try {
    return await evaluate(
      batchJob.states,
      batchJob.code,
      {},
      {
        batch: true,
        commonScope: batchJob.commonScope,
        namespace: batchJob.namespace,
      },
    )
  } catch (e) {
    return batchJob.states.map(() => null)
  }
}

function getUndeclaredFromTemplate(templateString: string, ignoredGlobals: Set<string> = globalScopeFuncs) {
  try {
    let parsed
    try {
      parsed = parseJSExpression(templateString)
    } catch (err) {
      parsed = parseJSExpression(`(async () => {${templateString}})`)
    }
    const parsedVars = undeclaredV2(parsed)
    const vars: string[] = Array.from(parsedVars)
    const varSelectors = vars.filter((x) => x !== '').map((v) => ds(v))

    const varArr = varSelectors?.filter((v) => {
      return !ignoredGlobals.has(v[0])
    })
    return varArr.map((v) => ss(v))
  } catch (err) {
    logger.warn('error in computeTemplateStringDependencies', templateString, err)
  }
}

type ConvertToParamOptions = Partial<{
  withTypes: boolean
  isNoCurlyBraceTemplate: boolean
  additionalGlobalsToIgnore: string[]
}>
/**
 * Specifically for queries: for creating the userParams we pass up to the backend
 */
export async function convertToParameters(
  templateString: string,
  model: any,
  decodeJSON = false,
  castUndefinedToNull = false,
  { withTypes = false, isNoCurlyBraceTemplate = false, additionalGlobalsToIgnore = [] }: ConvertToParamOptions = {},
) {
  let parameterTemplates: string[] = []
  if (isNoCurlyBraceTemplate) {
    const ignored = new Set(globalScopeFuncs)
    additionalGlobalsToIgnore.forEach((g) => ignored.add(g))
    parameterTemplates = getUndeclaredFromTemplate(templateString, ignored) || []
  } else {
    templateString.replace(ANY_TEMPLATE_REGEX, (v) => {
      if (decodeJSON) {
        const unescaped = JSON.parse(`{ "v": "${v}" }`).v
        parameterTemplates.push(unescaped)
      } else {
        parameterTemplates.push(v)
      }
      return ''
    })
  }

  const parameters: Record<string | number, unknown> = {}

  const evaluations = await Promise.all(
    parameterTemplates.map((param: string) => {
      if (isNoCurlyBraceTemplate) {
        return safeEval(param, model, 'function', defaultEvalOptions)
      } else {
        return evaluateSafe(model, param)
      }
    }),
  )

  for (let i = 0; i < parameterTemplates.length; i++) {
    const evaluated = evaluations[i]

    if (evaluated === undefined) {
      if (castUndefinedToNull) {
        parameters[i] = null
      }
      // do nothing!
    } else {
      parameters[i] = evaluated
      if (withTypes) {
        let type: string = typeof evaluated
        if (evaluated instanceof Date || evaluated instanceof moment) {
          type = 'Date'
        }
        parameters[`${i}Type`] = type
      }
    }
  }
  parameters.length = parameterTemplates.length

  if (isNoCurlyBraceTemplate) {
    const scope = {}
    for (let i = 0; i < parameterTemplates.length; i++) {
      _.set(scope, parameterTemplates[i], parameters[i])
    }
    parameters.sandboxScope = scope
  }
  return parameters
}

// TODO check error messages on non-Chrome browsers
// list of error messages: https://developer.mozilla.org/bm/docs/Web/JavaScript/Reference/Errors
const errorMapping = [
  {
    regex: /(.*?) is not defined/,
    mapper: (varName: any) =>
      `${varName} is not defined - try using the shortcut ${shortcutFormatter(
        'MISC',
        'AUTOCOMPLETE',
      )} to show an autocomplete menu.`,
  },
  {
    regex: /Unexpected token .*/,
    mapper: () => `An unexpected token has been found. Please make sure that your JS syntax is correct.`,
  },
]
function getErrorMessage(error: Error, payloaded = false) {
  let message
  if (payloaded) {
    message = JSON.parse(error.message).message
  } else {
    message = error.message
  }
  for (const errorHandler of errorMapping) {
    const match = message.match(errorHandler.regex)
    if (match) return errorHandler.mapper(match.slice(1))
  }
  return message
}
function getErrorPayload(error: Error) {
  try {
    return JSON.parse(error.message).payload
  } catch (err) {
    if (err instanceof SyntaxError) return
    throw err
  }
}

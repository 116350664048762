import { useEffect, useRef } from 'react'

/*
Dan Abramov's useInterval hook. 
https://overreacted.io/making-setinterval-declarative-with-react-hooks/
*/

const useInterval = <CallbackType extends (...args: unknown[]) => void>(
  callback: CallbackType,
  delay: number | null,
): void => {
  const savedCallback = useRef<CallbackType>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (delay === null) return

    let id: number

    const tick = () => {
      savedCallback.current?.()
      id = window.setTimeout(tick, delay)
    }

    id = window.setTimeout(tick, delay)

    return () => {
      clearTimeout(id)
    }
  }, [delay])
}

export default useInterval

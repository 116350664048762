import React from 'react'
import { browserHistory } from 'react-router'

// This is the simplest way to check if the user is navigating to another Retool app
// To make this even more rebust against future changes, we can pass our app route config
// into this handler and check if the requested path is one of them.
export function shouldClientRoute({ hostname, pathname }: { hostname: string; pathname: string }) {
  if (hostname !== window.location.hostname) {
    return false
  }

  const pathnameSegments = pathname.split('/').filter((x) => x !== '')

  if (pathnameSegments[0] !== 'apps') {
    return false
  }

  return true
}

// Some of our customers are using custom made <a> links inside HTML Text component
// as a "hack" to do client side routing between Retool apps.
//
// This makes it so we don't do a full page reload when navigating via <a> tag on the same domain
//
// For testing purposes, we want to abstract history object because in testing it is better
// to use in memory router
export function makeForceClientSideRoutingOnClickHandler<
  THistory extends {
    push: (location: { pathname: string; search: string; hash?: string }) => void
  }
>(history: THistory) {
  return (event: React.MouseEvent) => {
    if (
      event.defaultPrevented ||
      event.button !== 0 ||
      event.metaKey ||
      event.altKey ||
      event.ctrlKey ||
      event.shiftKey
    ) {
      return
    }

    const anchor = (event.target as HTMLElement).closest('a')

    if (!anchor) return

    const { href, target, hostname, pathname, search, hash } = anchor

    if (href && !target && shouldClientRoute({ hostname, pathname })) {
      history.push({
        pathname,
        search,
        hash,
      })
      event.preventDefault()
    }
  }
}

export default makeForceClientSideRoutingOnClickHandler(browserHistory)

import React from 'react'
import { dispatch } from 'store'
import { redirectToOauth } from 'store/session'
import { Button } from 'components/design-system'

export const SHARED_OAUTH_CALLBACK_URL = MAIN_DOMAIN
  ? `${window.location.protocol}//oauth.${MAIN_DOMAIN}/oauth/oauthcallback`
  : `${window.location.origin}/oauth/oauthcallback`
export const USER_CALLBACK_URL = MAIN_DOMAIN
  ? `${window.location.protocol}//oauth.${MAIN_DOMAIN}/oauth/user/oauthcallback`
  : `${window.location.origin}/oauth/user/oauthcallback`
export const OAUTH_AUTHORIZE_URL = MAIN_DOMAIN
  ? `${window.location.protocol}//oauth.${MAIN_DOMAIN}/oauth/authorize`
  : `${window.location.origin}/oauth/authorize`

type OAuthFormProps = {
  onBeforeSubmit?: (triggeredByOauth: boolean) => any
  resourceId?: number
  resourceName?: string
  environment?: string
  disabled?: boolean
  buttonText?: string | JSX.Element
  buttonStyle?: {}
  className?: string
  buttonClassName?: string
  style?: React.CSSProperties
  stepNum?: number
  shouldAttemptLogin?: boolean
}

export class OAuthForm extends React.Component<OAuthFormProps, {}> {
  form: HTMLFormElement | null = null
  submit = async (state?: string) => {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', 'resourceAuthCSRFState')
    input.setAttribute('value', state!)
    this.form?.appendChild(input)

    const res = await dispatch(redirectToOauth())
    const authInput = document.createElement('input')
    authInput.setAttribute('type', 'hidden')
    authInput.setAttribute('name', 'authorizationToken')
    authInput.setAttribute('value', res.payload.authorizationToken)
    this.form?.appendChild(authInput)
    this.form?.submit()
  }

  render() {
    const props = this.props
    const { resourceId, resourceName, environment, disabled, buttonText, stepNum, style } = props
    let xsrfToken = ''
    try {
      xsrfToken = document.cookie.match('xsrfToken=(.*)')![1].split(';')[0]
    } catch (err) {}
    const buttonClassName = props.buttonClassName
    return (
      <form
        ref={(form) => (this.form = form)}
        action={OAUTH_AUTHORIZE_URL}
        onSubmit={async (e) => {
          e.preventDefault()
          e.stopPropagation()
          this.submitForm()
        }}
        method="POST"
        style={style || {}}
        className={this.props.className}
      >
        <input type="hidden" name="xsrfToken" value={xsrfToken} />
        <input type="hidden" name="resourceId" value={resourceId} />
        <input type="hidden" name="resourceName" value={resourceName} />
        <input type="hidden" name="environment" value={environment} />
        <input type="hidden" name="redirectUri" value={window.location.href.split('#')[0]} />
        <input type="hidden" name="stepNum" value={stepNum} />
        <Button
          type="default"
          htmlType="submit"
          disabled={disabled}
          style={props.buttonStyle}
          className={buttonClassName}
        >
          {buttonText || 'Authorize'}
        </Button>
      </form>
    )
  }

  submitForm = async () => {
    if (this.props.onBeforeSubmit) {
      const res = await this.props.onBeforeSubmit(true)
      if (res.payload.status === 400) {
        // if unable to create the resource, do not proceed
        throw Error('Could not create or edit resource.')
      }
    }
    // Hacky, but the reason why this is here is because
    // the form needs to have the `resourceId` input populated for it to work. The set timeout lets React update the resourceId prop.
    // TODO: refactor it so onBeforeSubmit returns a promise that resolves to the resource which we can use.
    setTimeout(async () => {
      const formTarget = this.form
      const res = await dispatch(redirectToOauth())
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', 'authorizationToken')
      input.setAttribute('value', res.payload.authorizationToken)
      formTarget?.appendChild(input)
      formTarget?.submit()
      formTarget?.removeChild(input)
    }, 50)
  }

  componentDidMount() {
    if (this.props.shouldAttemptLogin) {
      this.submitForm()
    }
  }
}

export const EXPAND_ONBOARDING_CHECKLIST = 'EXPAND_ONBOARDING_CHECKLIST'
export const ONBOARDING_FINISH = 'ONBOARDING_FINISH'

export const ONBOARDING_GO_TO_NEXT_SUB_STEP = 'GO_TO_NEXT_SUB_STEP'
export const ONBOARDING_GO_TO_PREV_SUB_STEP = 'GO_TO_PREV_SUB_STEP'

export const REQUEST_SUBMIT_APP_BEING_BUILT = 'REQUEST_SUBMIT_APP_BEING_BUILT'
export const RECEIVE_SUBMIT_APP_BEING_BUILT = 'RECEIVE_SUBMIT_APP_BEING_BUILT'
export const FAILURE_SUBMIT_APP_BEING_BUILT = 'FAILURE_SUBMIT_APP_BEING_BUILT'

export const ONBOARDING_MODAL_REPOSITION = 'ONBOARDING_MODAL_REPOSITION'
export const ONBOARDING_GO_TO_NEXT_STEP = 'GO_TO_NEXT_STEP'
export const ONBOARDING_ADVANCE_TUTORIAL = 'ONBOARDING_ADVANCE_TUTORIAL'
export const ONBOARDING_REWIND_TUTORIAL = 'ONBOARDING_REWIND_TUTORIAL'
export const ONBOARDING_REVIVE_TUTORAIL = 'ONBOARDING_REVIVE_TUTORIAL'
export const ONBOARDING_GO_TO_END = 'GO_TO_END'
export const ONBOARDING_SKIP_NO_NEXT_STEPS = 'ONBOARDING_SKIP_NO_NEXT_STEPS'
export const ONBOARDING_GO_TO_PREV_STEP = 'ONBOARDING_GO_TO_PREV_STEP'
export const ONBOARDING_START = 'ONBOARDING_START'

export const REQUEST_DISMISS_CHECKLIST = 'REQUEST_DISMISS_CHECKLIST'
export const RECEIVE_DISMISS_CHECKLIST = 'RECEIVE_DISMISS_CHECKLIST'
export const FAILURE_DISMISS_CHECKLIST = 'FAILURE_DISMISS_CHECKLIST'

export const COLLAPSE_ONBOARDING_CHECKLIST = 'COLLAPSE_ONBOARDING_CHECKLIST'
export const OVERRIDE_ONBOARDING_STATE = 'OVERRIDE_ONBOARDING_STATE'

export const FIRST_CREATED_RESOURCE_TYPE_TUTORIAL = 'FIRST_CREATED_RESOURCE_TYPE_TUTORIAL'

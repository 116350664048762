import React, { useState, useCallback } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import TextArea from 'antd/lib/input/TextArea'
import { copyToClipboard, RecursiveObjectType, flattenObj } from 'common/utils'
import { TextInput, Checkbox, Icon, Tooltip, Radio, Button, Select, Message, Alert } from 'components/design-system'
import update from 'immutability-helper'
import './common.scss'
import { OAuthForm, USER_CALLBACK_URL, SHARED_OAUTH_CALLBACK_URL } from '../oauthForm'
import CustomAuthForm from '../CustomAuthForm'
import * as CustomAuth from './components/CustomAuth'
import { isAdminSelector, isOnPremSelector, enableClientSideCustomAuthBrowserCallsSelector } from 'store/selectors'
import { RetoolState, getState } from 'store'
import { SQL_QUERY_VERSION_UNIFIED } from 'common/records'
import { IS_ON_PREM } from 'retoolConstants'
import { POST } from 'common/fetch'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import CodeEditor from 'components/CodeEditor'
import OauthDebugHelper from './components/OauthDebugHelper'
import AuthTriggerInput from './components/AuthTriggerInput'
import classNames from 'classnames'
import { CustomAuthStep, ResourceFormProps, SharedStepEditorProps } from './types'

interface InputFieldProps extends ResourceFormProps {
  label: string | JSX.Element
  resourceKey: string
  placeholder?: string
  topLevel?: boolean
  password?: boolean
  inputAddOnBefore?: string | JSX.Element
  frozen?: boolean
  required?: boolean
  dataTestId?: string
  subtitle?: string
  inputAriaLabel?: string
  textInputClassName?: string
}

interface ARNInputFieldProps extends Omit<InputFieldProps, 'label' | 'resourceKey'> {}

export const InputField: React.FC<InputFieldProps> = (props) => (
  <>
    <label className="input-label">
      <span>
        {props.required && <span className="light-red mr4">*</span>}
        {props.label}
      </span>
    </label>
    <div>
      <TextInput
        className={classNames('fs-exclude', props.textInputClassName)}
        aria-label={props.inputAriaLabel}
        type={props.password ? 'password' : 'text'}
        placeholder={props.placeholder}
        value={(props.topLevel ? props.resource : props.resource.options)[props.resourceKey]}
        onChange={
          props.frozen
            ? undefined
            : (props.topLevel ? props.onChangeTopLevel : props.onChangeOption)(props.resourceKey, {
                handleEvent: true,
              })
        }
        addonBefore={props.inputAddOnBefore}
        disabled={props.frozen}
        data-testid={props.dataTestId}
      />
      {props.subtitle && <div className="mt4 gray">{props.subtitle}</div>}
    </div>
  </>
)

export const ARNInputField = (props: ARNInputFieldProps) => (
  <>
    <InputField label="Role to assume (ARN)" resourceKey="assumeRole" {...props} />
    <div className="grid-offset-1 description">
      Use a{' '}
      <a href="https://docs.aws.amazon.com/STS/latest/APIReference/API_AssumeRole.html" target="_blank">
        different role
      </a>{' '}
      when accessing Lambda.
    </div>
  </>
)

interface HostInputFieldProps extends ResourceFormProps {
  placeholder?: string
  inputAddOnBefore?: string | JSX.Element
  frozen?: boolean
  isOnPrem: boolean
}

// NB: these regexes technically matches incomplete IP's; once we see a known prefix, we know it's private
const localhostRegex = /^\s*localhost|127\.[0-9.]*\s*$/
const privateIPRegex = /^\s*(10\.|192\.168\.|172\.(1[6-9]|2|3[01])\.)[0-9.]*\s*$/

type HostSuggestionProps = {
  host: string
  sshTunnelEnabled: boolean | undefined
  isOnPrem: boolean
}

const HostSuggestion = (props: HostSuggestionProps) => {
  const { host, sshTunnelEnabled, isOnPrem } = props

  const isLocalhost = !sshTunnelEnabled && localhostRegex.test(host)
  const isPrivateIP = !sshTunnelEnabled && privateIPRegex.test(host)

  if ((isPrivateIP || isLocalhost) && !isOnPrem) {
    return (
      <Alert
        type="info"
        style={{ marginTop: 12 }}
        message={
          <>
            <p style={{ marginBottom: 8 }}>
              It looks like you're trying to connect to a database that's local to your machine or part of a private
              network. You're using Retool in the cloud, so this might not work. Check out our
              <a href="https://docs.retool.com/docs/running-retool-locally"> docs</a> for how to run Retool locally, or
              just run this command:
            </p>
            <code className="code-block">
              /bin/bash -c "$(curl -fsSL
              https://raw.githubusercontent.com/tryretool/retool-onpremise/master/local-trial)"
            </code>
          </>
        }
      />
    )
  } else if (isLocalhost && isOnPrem) {
    return (
      <Alert
        type="info"
        style={{ marginTop: 12 }}
        message={
          <>
            <p style={{ marginBottom: 8 }}>
              It looks like you're trying to connect to a database that's local to your machine. Depending on your
              deployment type, you may need to specify a different hostname.
            </p>
            <p>
              In the case of using <code>docker-compose</code>, we advise trying <code>host.docker.internal</code>. If
              that doesn't work, it's possible your deployment is different or <code>localhost</code> refers to a
              different host than the one you're running Retool on.
            </p>
          </>
        }
      />
    )
  } else {
    return <></>
  }
}
const HostInputFieldUnconnected = (props: HostInputFieldProps) => {
  const host = props.resource.host
  const sshTunnelEnabled = props.resource.sshTunnelEnabled
  const { isOnPrem } = props

  return (
    <>
      <label className="input-label">
        <span>
          <span className="light-red mr4">*</span> <span id="host-label">Host</span>
        </span>
      </label>
      <div>
        <TextInput
          aria-labelledby="host-label"
          className="fs-exclude"
          type="text"
          placeholder={props.placeholder}
          value={host}
          onChange={
            props.frozen
              ? undefined
              : props.onChangeTopLevel('host', {
                  handleEvent: true,
                })
          }
          addonBefore={props.inputAddOnBefore}
          disabled={props.frozen}
        />
        <HostSuggestion {...{ host, sshTunnelEnabled, isOnPrem }} />
      </div>
    </>
  )
}

const mapHostInputStateToProps = (state: RetoolState) => {
  return {
    isOnPrem: isOnPremSelector(state),
  }
}

export const HostInputField = connect(mapHostInputStateToProps)(HostInputFieldUnconnected)

export const EditPrivilege = (props: any) => {
  return (
    <div className="grid-1c mb12">
      <label className="input-label required">Type</label>
      <Radio.Group
        value={props.resource.editPrivilege}
        onChange={(selected) => {
          props.onChangeTopLevel('editPrivilege')(selected.target.value)
        }}
      >
        <Radio value={false}>Read connection</Radio>
        <div className="ml24 mb8 fw-400">Run raw SQL statements against your database.</div>
        <Radio value={true}>Write connection</Radio>
        <div className="ml24 fw-400">
          Run INSERT, UPDATE, and DELETE statements against your database using our GUI to prevent accidental
          destructive changes.{' '}
          <a
            target="_blank"
            href="https://docs.retool.com/docs/troubleshooting-integrations#section-reads-vs-writes-in-sql"
            rel="noopener noreferrer"
          >
            Learn more here
          </a>
          .
        </div>
      </Radio.Group>
    </div>
  )
}
export const DangerZoneheader = () => {
  return (
    <>
      <hr />
      <h5 className="section-heading light-gray mb12">Admin only</h5>
      <div className="danger-zone-header mb12 mt4">
        <Alert type="warning" message="Be careful when making changes." />
      </div>
    </>
  )
}
export const QueryEditorModesToggle = (props: { resource: any; updateResourceOptions: (resource: any) => void }) => {
  return (
    <Checkbox
      checked={!_.get(props.resource, 'options.queryEditorModes.allowSqlMode')}
      onChange={(e) => {
        props.updateResourceOptions({
          queryEditorModes: {
            ...props.resource.options.queryEditorModes,
            allowSqlMode: !e.target.checked,
          },
        })
      }}
      className="grid-offset-1"
    >
      Show write GUI mode only
      <Tooltip title="This allows you to enable writing via only the restrictive GUI query editor.">
        <Icon type="tooltip" className="washed-gray hover-lightest-gray ml4" />
      </Tooltip>
    </Checkbox>
  )
}
interface DatabaseFormProps extends ResourceFormProps {
  placeholderHost: string
  placeholderPort: string
  renderPort?: (props: DatabaseFormProps) => React.ReactNode
  placeholderUsername: string
  placeholderConnectionString?: string
  renderConnectionString?: (props: DatabaseFormProps) => React.ReactNode
  hideEditPrivilege?: boolean
  databaseNameOverrideSupported?: boolean
  databaseCredentialsOverrideSupported?: boolean
  acceptSelfSignedCertificatesSupported?: boolean
  customCertificateSupported?: boolean
  mongoDbSelfSignedCertificatesSupported?: boolean
  gcpSelfSignedCertificatesSupported?: boolean
  hideDangerZone?: boolean
  hideSSL?: boolean
  disableDateStrings?: boolean
  isAdmin: boolean
  isUnifiedSqlResource?: boolean
}
type DatabaseFormState = {
  usingConnectionString: boolean
}
class DatabaseForm extends React.Component<DatabaseFormProps, DatabaseFormState> {
  constructor(props: DatabaseFormProps) {
    super(props)
    this.state = { usingConnectionString: !!props.resource.options.connectionString }

    // TODO https://linear.app/retool/issue/ENG-531/clean-up-readwrite-feature-flags
    if (this.props.resource.options.version === undefined && this.props.isUnifiedSqlResource) {
      this.props.updateResourceOptions(sqlUnifiedResourceDefaults)
    }
  }

  useConnectionString = () => {
    this.props.updateResource({
      host: '',
      port: '',
      databaseName: '',
      databaseUsername: '',
      databasePassword: '',
    })
    this.setState({ usingConnectionString: true })
  }

  removeConnectionString = () => {
    this.props.onChangeOption('connectionString')('')
    this.setState({ usingConnectionString: false })
  }

  render() {
    const { renderPort, renderConnectionString } = this.props
    return (
      <div className="resource-form__database-form">
        {!this.props.hideEditPrivilege && !this.props.isUnifiedSqlResource && <EditPrivilege {...this.props} />}
        <hr />
        <div className="flex mb16" style={{ justifyContent: 'space-between' }}>
          <h5 className="section-heading light-gray">General</h5>
          {this.props.placeholderConnectionString && (
            <>
              {this.state.usingConnectionString ? (
                <a className="fs-12 fw-500 blue" href="#" onClick={this.removeConnectionString}>
                  Enter connection details individually
                </a>
              ) : (
                <a className="fs-12 fw-500 blue" href="#" onClick={this.useConnectionString}>
                  Use a database connection string
                </a>
              )}
            </>
          )}
        </div>
        {this.state.usingConnectionString && (
          <div className="grid-1c mb12">
            {renderConnectionString ? (
              renderConnectionString(this.props)
            ) : (
              <InputField
                label="Connection String"
                placeholder={this.props.placeholderConnectionString}
                resourceKey="connectionString"
                required
                {...this.props}
              />
            )}
          </div>
        )}
        {!this.state.usingConnectionString && (
          <>
            <div className="grid-2c mb12">
              <HostInputField {...this.props} />
              {renderPort ? (
                renderPort(this.props)
              ) : (
                <InputField
                  label="Port"
                  placeholder={this.props.placeholderPort}
                  resourceKey="port"
                  topLevel
                  required
                  {...this.props}
                />
              )}
            </div>
            <div className="grid-1c mb12">
              <InputField
                label="Database name"
                placeholder="hn_api_production"
                resourceKey="databaseName"
                topLevel
                {...this.props}
              />
              <InputField
                label="Database username"
                placeholder={this.props.placeholderUsername}
                resourceKey="databaseUsername"
                topLevel
                {...this.props}
              />
              <InputField
                label="Database password"
                placeholder="••••••••"
                resourceKey="databasePassword"
                topLevel
                password
                {...this.props}
              />
            </div>
          </>
        )}

        {!this.state.usingConnectionString && !this.props.hideSSL && (
          <div className="resource-form__toggles-group grid-1c">
            <Checkbox
              checked={this.props.resource.ssl}
              onChange={(checked) => {
                this.props.onChangeTopLevel('ssl')(checked.target.checked)
              }}
              className="grid-offset-1"
            >
              Connect using SSL
            </Checkbox>
          </div>
        )}
        {((!this.state.usingConnectionString && !this.props.hideSSL) || !this.props.hideEditPrivilege) && (
          <div className="resource-form__toggles-group grid-1c">
            {this.props.resource.type === 'oracledb' && (
              <Checkbox
                checked={this.props.resource.options.connectWithSid}
                onChange={(checked) => {
                  this.props.onChangeOption('connectWithSid')(checked.target.checked)
                }}
                className="grid-offset-1"
              >
                Treat Database Name field as SID
                <Tooltip title="Some Oracle databases require you to specify the SID instead of the Service Name.">
                  <Icon type="tooltip" className="washed-gray hover-lightest-gray ml4" />
                </Tooltip>
              </Checkbox>
            )}
          </div>
        )}
        {this.props.resource.ssl &&
          this.props.gcpSelfSignedCertificatesSupported &&
          !this.state.usingConnectionString &&
          !this.props.hideSSL && <GCPSelfSignedCertForm {...this.props} />}
        {this.props.resource.ssl &&
          this.props.mongoDbSelfSignedCertificatesSupported &&
          !this.state.usingConnectionString &&
          !this.props.hideSSL && <MongoDBSelfSignedCertForm {...this.props} />}
        {this.props.customCertificateSupported && this.props.resource.ssl && (
          <div className="resource-form__toggles-group">
            <TextArea
              value={this.props.resource.options.ca_cert}
              onChange={this.props.onChangeOption('ca_cert', { handleEvent: true })}
              autosize={{ minRows: 8 }}
              placeholder={CERTIFICATE_PLACEHOLDER}
            />
          </div>
        )}
        {!this.props.hideDangerZone && (
          <>
            <DangerZoneheader />
            <div className="resource-form__toggles-group grid-1c">
              {this.props.databaseNameOverrideSupported && (
                <>
                  <Checkbox
                    checked={_.get(this.props.resource, 'options.enableDynamicDatabaseNames')}
                    onChange={(checked) => {
                      this.props.onChangeOption('enableDynamicDatabaseNames')(checked.target.checked)
                    }}
                    className="grid-offset-1"
                  >
                    Use dynamic database names
                    <Tooltip title="Enable this to allow the Database Name to be overridden by a dynamically generated value. This allows using Retool with a database that has been sharded into several different databases.">
                      <Icon type="tooltip" className="washed-gray hover-lightest-gray ml4" />
                    </Tooltip>
                  </Checkbox>
                </>
              )}
              {this.props.databaseCredentialsOverrideSupported && (
                <>
                  <Checkbox
                    checked={_.get(this.props.resource, 'options.enableDynamicDatabaseCredentials')}
                    onChange={(checked) => {
                      this.props.onChangeOption('enableDynamicDatabaseCredentials')(checked.target.checked)
                    }}
                    className="grid-offset-1"
                  >
                    Use dynamic database credentials
                    <Tooltip title="Enable this to allow the Database Username and Password to be overridden by a dynamically generated value. This allows using Retool with a database that has been sharded into several different databases.">
                      <Icon type="tooltip" className="washed-gray hover-lightest-gray ml4" />
                    </Tooltip>
                  </Checkbox>
                </>
              )}
              {this.props.acceptSelfSignedCertificatesSupported && this.props.resource.ssl && (
                <>
                  <Checkbox
                    checked={_.get(this.props.resource, 'options.acceptSelfSignedCertificates')}
                    onChange={(checked) => {
                      this.props.onChangeOption('acceptSelfSignedCertificates')(checked.target.checked)
                    }}
                    className="grid-offset-1"
                  >
                    Allow self-signed certificates
                    <Tooltip title="Enable this to allow self-signed certificates to be used to connect to your database. This makes it possible to keep your data end-to-end encrypted when using a cloud-hosted database that self-signs certificates.">
                      <Icon type="tooltip" className="washed-gray hover-lightest-gray ml4" />
                    </Tooltip>
                  </Checkbox>
                </>
              )}
              {!this.props.resource.editPrivilege && (
                <>
                  <Checkbox
                    checked={_.get(this.props.resource, 'options.disableServerSidePrepare')}
                    onChange={(checked) => {
                      this.props.onChangeOption('disableServerSidePrepare')(checked.target.checked)
                    }}
                    className="grid-offset-1"
                  >
                    Disable converting queries to prepared statements
                    <Tooltip title="This allows you to use Javascript to dynamically generate SQL but also turns off SQL injection protection.">
                      <Icon type="tooltip" className="washed-gray hover-lightest-gray ml4" />
                    </Tooltip>
                  </Checkbox>
                </>
              )}
              {this.props.isUnifiedSqlResource && (
                <QueryEditorModesToggle
                  resource={this.props.resource}
                  updateResourceOptions={this.props.updateResourceOptions}
                />
              )}
              {this.props.resource.type === 'mysql' && (
                <>
                  <Checkbox
                    checked={
                      _.get(this.props.resource, 'options.disableDateStrings') === undefined
                        ? true
                        : _.get(this.props.resource, 'options.disableDateStrings')
                    }
                    onChange={(checked) => {
                      this.props.onChangeOption('disableDateStrings')(checked.target.checked)
                    }}
                    className="grid-offset-1"
                  >
                    Convert MySQL date strings to Javascript
                    <Tooltip title="This allows you to turn your MySQL date strings into Javascript Date objects.">
                      <Icon type="tooltip" className="washed-gray hover-lightest-gray ml4" />
                    </Tooltip>
                  </Checkbox>
                </>
              )}
            </div>
          </>
        )}
      </div>
    )
  }
}
const ConnectedDatabaseForm = connect((state: RetoolState) => ({
  isAdmin: isAdminSelector(state),
}))(DatabaseForm)
export { ConnectedDatabaseForm as DatabaseForm }

export const databaseDefaults = {
  host: '',
  port: '',
  databaseName: '',
  databaseUsername: '',
  databasePassword: '',
  ssl: false,
  editPrivilege: false,
  options: {
    connectionString: '',
  },
}

export const databaseValidator = (resource: any) =>
  resource.options.connectionString || (resource.type && resource.host && resource.port)

interface ResourceFormExtensionProps extends ResourceFormProps {
  children: JSX.Element
}

export const SSHTunnelForm = (props: ResourceFormExtensionProps) => (
  <div>
    {props.children}
    <div className="grid-1c mb12 mt4">
      <Checkbox
        checked={props.resource.options.sshTunnelEnabled}
        onChange={(e: CheckboxChangeEvent) => {
          const checked = e.target.checked
          if (!checked) {
            props.updateResourceOptions({
              sshHost: '',
              sshPort: '',
              sshTunnelEnabled: checked,
            })
          } else {
            props.onChangeOption('sshTunnelEnabled')(checked)
          }
        }}
        className="grid-offset-1"
      >
        Enable SSH tunnel
      </Checkbox>
    </div>
    <div>
      {props.resource.options.sshTunnelEnabled && (
        <>
          <div className="grid-2c mb12">
            <InputField label="Bastion host" placeholder="bastion.company.com" resourceKey="sshHost" {...props} />
            <InputField label="Bastion port" placeholder="22" resourceKey="sshPort" {...props} />
          </div>
          <div className="mb12 grid-1c">
            <div />
            <div className="pa8 ph12 bg-near-white mb12 lh-16 br4">
              <div className="dark-gray fw-500 mb4">SSH public key authentication</div>
              <a href="/publicKeys/retool.pub" target="_blank">
                Download Retool's public key here
              </a>{' '}
              and add it to your <code>~/.ssh/authorized_keys</code> You'll need to create a user named{' '}
              <code>retool</code>. Click{' '}
              <a target="_blank" href="https://docs.retool.com/docs/enabling-ssh-tunnels" rel="noopener noreferrer">
                here
              </a>{' '}
              for docs on how to use this feature.
            </div>

            {(__DEV__ || !MAIN_DOMAIN) && (
              <>
                <Checkbox
                  checked={props.resource.options.useCustomAuth || !!props.resource.options.sshPrivateKeyName}
                  onChange={(checked) => {
                    if (checked.target.checked) {
                      props.onChangeOption('useCustomAuth')(true)
                    } else {
                      props.updateResourceOptions({
                        useCustomAuth: false,
                        sshUsername: '',
                        sshPrivateKeyName: '',
                        sshPassword: '',
                      })
                    }
                  }}
                  className="grid-offset-1"
                >
                  Use custom authentication details
                </Checkbox>
                {(props.resource.options.useCustomAuth || props.resource.options.sshPrivateKeyName) && (
                  <>
                    <InputField label="SSH username" placeholder="bastion" resourceKey="sshUsername" {...props} />
                    <InputField
                      label="SSH password"
                      placeholder="••••••••"
                      resourceKey="sshPassword"
                      password
                      {...props}
                    />
                    <InputField
                      label="SSH private key name"
                      placeholder="myprivatekey.pem"
                      resourceKey="sshPrivateKeyName"
                      {...props}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  </div>
)

export const sshTunnelDefaults = {
  options: {
    sshTunnelEnabled: false,
    sshHost: '',
    sshUsername: '',
    sshPassword: '',
    sshPrivateKeyName: '',
  },
}

export const sqlUnifiedResourceDefaults = {
  version: SQL_QUERY_VERSION_UNIFIED,
  queryEditorModes: {
    allowSqlMode: true,
    allowGuiMode: true,
  },
}

const GCPSelfSignedCertForm = (props: ResourceFormProps) => (
  <div className="grid-1c mb12">
    <Checkbox
      checked={props.resource.options.gcpConnectWithSelfSignedCertificate}
      onChange={(checked) => {
        props.updateResourceOptions({
          gcpConnectWithSelfSignedCertificate: checked.target.checked,
          client_key: null,
          client_cert: null,
        })
      }}
      className="grid-offset-1"
    >
      Use a self-signed certificate
      <Tooltip title={"This is relevant if you are using Google Cloud Platform's Cloud SQL.'"}>
        <Icon type="tooltip" className="washed-gray hover-lightest-gray ml4" />
      </Tooltip>
    </Checkbox>
    {props.resource.options.gcpConnectWithSelfSignedCertificate && (
      <>
        <label className="input-label">Client Key</label>
        <TextArea
          value={props.resource.options.client_key}
          onChange={props.onChangeOption('client_key', { handleEvent: true })}
          autosize={{ minRows: 8 }}
          placeholder={CERTIFICATE_PLACEHOLDER_SHORTENED}
        />

        <label className="input-label">Client Cert</label>
        <TextArea
          value={props.resource.options.client_cert}
          onChange={props.onChangeOption('client_cert', { handleEvent: true })}
          autosize={{ minRows: 8 }}
          placeholder={CERTIFICATE_PLACEHOLDER_SHORTENED}
        />
      </>
    )}
  </div>
)

const MongoDBSelfSignedCertForm = (props: ResourceFormProps) => (
  <div className="grid-1c mb12">
    <Checkbox
      checked={props.resource.options.mongoDBConnectWithSelfSignedCertificate}
      onChange={(checked) => {
        props.updateResourceOptions({
          mongoDBConnectWithSelfSignedCertificate: checked.target.checked,
          ca_cert: null,
          client_cert_and_key: null,
        })
      }}
      className="grid-offset-1"
    >
      Use a self-signed certificate
    </Checkbox>

    {props.resource.options.mongoDBConnectWithSelfSignedCertificate && (
      <>
        <label className="input-label">Client Cert and Key</label>
        <TextArea
          value={props.resource.options.client_cert_and_key}
          onChange={props.onChangeOption('client_cert_and_key', { handleEvent: true })}
          autosize={{ minRows: 8 }}
          placeholder={RSA_KEY_PLACEHOLDER_SHORTENED + CERTIFICATE_PLACEHOLDER_SHORTENED}
        />

        <label className="input-label">CA Cert</label>
        <TextArea
          value={props.resource.options.ca_cert}
          onChange={props.onChangeOption('ca_cert', { handleEvent: true })}
          autosize={{ minRows: 8 }}
          placeholder={CERTIFICATE_PLACEHOLDER_SHORTENED}
        />
      </>
    )}
  </div>
)

const Auth0Group = (props: ResourceFormProps) => (
  <>
    <InputField label="Auth0 Domain" placeholder="retool.auth0.com" resourceKey="auth0_domain" {...props} />
    <InputField
      label="Auth0 Client ID"
      placeholder="652tNgs63HR0...................."
      resourceKey="auth0_clientID"
      {...props}
    />
    <InputField
      label="Auth0 Client Secret"
      placeholder="••••••••••••••••"
      resourceKey="auth0_clientSecret"
      password
      {...props}
    />
    <InputField
      label="Auth0 Audience (Optional)"
      placeholder="https://retool.auth0.com/api/v2"
      resourceKey="auth0_custom_audience"
      {...props}
    />
  </>
)

const auth0Defaults = {
  auth0_domain: '',
  auth0_clientID: '',
  auth0_clientSecret: '',
  auth0_custom_audience: '',
}

const BasicAuthGroup = (props: ResourceFormProps) => (
  <>
    <InputField label="Basic auth username" placeholder="username" resourceKey="basic_username" {...props} />
    <InputField label="Basic auth password" placeholder="password" resourceKey="basic_password" password {...props} />
  </>
)

const basicAuthDefaults = {
  basic_username: '',
  basic_password: '',
}

enum OAuth1SignatureMethod {
  HMACSHA1 = 'HMAC-SHA1',
  HMACSHA256 = 'HMAC-SHA256',
}

const oauth1Defaults = {
  oauth1_signature_method: OAuth1SignatureMethod.HMACSHA1,
}

const OAuth1Group = (props: ResourceFormProps) => (
  <>
    <InputField label="Consumer Key" resourceKey="oauth1_consumer_key" {...props} />
    <InputField label="Consumer Secret" resourceKey="oauth1_consumer_secret" password {...props} />
    <InputField label="Realm (Optional)" resourceKey="oauth1_realm_parameter" {...props} />
    <label className="input-label">Signature method</label>
    <Select
      showSearch
      value={props.resource.options.oauth1_signature_method}
      onChange={props.onChangeOption('oauth1_signature_method')}
    >
      {[OAuth1SignatureMethod.HMACSHA1, OAuth1SignatureMethod.HMACSHA256].map((signature) => (
        <Select.Option key={signature} value={signature}>
          {signature}
        </Select.Option>
      ))}
    </Select>
  </>
)

const loginVerificationDefaults = {
  verify_session_url: '',
}

export const CallbackURLField = (props: ResourceFormProps) => (
  <>
    <InputField label="OAuth callback URL" resourceKey="oauth2_callback_url" frozen {...props} />
    <div className="grid-offset-1 description">
      <div />
      <Button
        type="link"
        onClick={() => {
          copyToClipboard(props.resource.options.oauth2_callback_url)
          Message.success('Copied link')
        }}
      >
        Copy this URL to your application
      </Button>
    </div>
  </>
)

const OAuthGroup = (props: ResourceFormProps) => {
  const useClientCredentialsAuth = !props.resource.options.oauth2_callback_url
  const oauthFlowTestable = props.resource.id && !useClientCredentialsAuth
  const { verify_session_action_enabled } = props.resource.options

  return (
    <>
      <label className="input-label">Configuring OAuth 2.0</label>
      <div className="bg-near-white br4 pa8">
        OAuth 2.0 is a complex spec. Retool currently supports the server-side OAuth 2.0 authentication flow as well as
        the Client Credentials flow. In both cases, you must use the <code>OAUTH2_TOKEN</code> placeholder in order to
        inform Retool where to place the OAuth access token in the API request. A common location for this is as a
        header such as <code>Authorization: Bearer OAUTH2_TOKEN</code>
        For more information and examples see our docs{' '}
        <a href="https://docs.retool.com/docs/apis#section-oauth-2-0" target="_blank" rel="noopener noreferrer">
          here
        </a>
        .
      </div>
      <Checkbox
        checked={useClientCredentialsAuth}
        onChange={(checked) => {
          if (checked.target.checked) {
            props.onChangeOption('oauth2_callback_url')('')
          } else {
            props.onChangeOption('oauth2_callback_url')(USER_CALLBACK_URL)
          }
        }}
        className="grid-offset-1"
      >
        Use client credentials auth
      </Checkbox>
      {!useClientCredentialsAuth && <CallbackURLField {...props} />}
      {props.resource.options.oauth2_callback_url && (
        <Checkbox
          checked={props.resource.options.oauth2_share_user_credentials}
          onChange={(checked) => {
            props.updateResourceOptions({
              oauth2_share_user_credentials: checked.target.checked,
              oauth2_access_token: '',
              oauth2_refresh_token: '',
              oauth2_callback_url: checked.target.checked ? SHARED_OAUTH_CALLBACK_URL : USER_CALLBACK_URL,
            })
          }}
          className="grid-offset-1"
        >
          Share OAuth2.0 credentials between users
        </Checkbox>
      )}
      <InputField
        label="Authorization URL"
        placeholder="https://accounts.google.com/o/oauth2/v2/auth"
        resourceKey="oauth2_auth_url"
        {...props}
      />
      <InputField
        label="Access Token URL"
        placeholder="https://www.googleapis.com/oauth2/v4/token"
        resourceKey="oauth2_access_token_url"
        {...props}
      />
      <InputField label="Client ID" resourceKey="oauth2_client_id" {...props} />

      <InputField label="Client Secret" resourceKey="oauth2_client_secret" password {...props} />

      <InputField label="Scopes (separated by a space)" resourceKey="oauth2_scope" {...props} />

      <InputField label="Audience" resourceKey="oauth2_audience" {...props} />

      {!useClientCredentialsAuth && (
        <>
          <InputField label="Access Token" resourceKey="oauth2_access_token" {...props} />

          <InputField label="Refresh Token" resourceKey="oauth2_refresh_token" {...props} />
        </>
      )}
      <label className="input-label">Access token lifespan (optional)</label>
      <TextInput
        className="fs-exclude"
        type="number"
        placeholder="Token Lifespan in seconds"
        value={props.resource.options.oauth2_access_token_lifespan_seconds}
        onChange={(evt) => {
          let value: number | null = parseInt(evt.target.value)
          if (isNaN(value)) value = null
          props.onChangeOption('oauth2_access_token_lifespan_seconds', {})(value)
        }}
      />

      <AuthTriggerInput {...props} authTriggerType={verify_session_action_enabled ? 'login_test_url' : 'none'} />
      {IS_ON_PREM && (
        <Checkbox
          checked={props.resource.options.skipRetoolOAuthConsentScreen}
          onChange={(checked) => {
            props.onChangeOption('skipRetoolOAuthConsentScreen')(checked.target.checked)
          }}
          className="grid-offset-1"
        >
          Skip Retool consent screen & attempt login
        </Checkbox>
      )}

      <div />
      {oauthFlowTestable && (
        <div className="flex items-center">
          <OAuthForm
            className="mr12"
            resourceId={props.resource.id}
            buttonClassName="login-button"
            buttonText="Test OAuth integration with your own account"
          />
          <OauthDebugHelper {...props} />
        </div>
      )}
    </>
  )
}
const oauthDefaults = {
  oauth2_callback_url: USER_CALLBACK_URL,
  oauth2_auth_url: '',
  oauth2_access_token_url: '',
  oauth2_client_id: '',
  oauth2_client_secret: '',
  oauth2_scope: '',
  oauth2_access_token: '',
  oauth2_refresh_token: '',
  ...loginVerificationDefaults,
}

const SessionAuthGroup = (props: ResourceFormProps) => (
  <>
    <InputField label="Cookies to forward" resourceKey="session_cookie_name" {...props} />

    <AuthTriggerInput
      {...props}
      authTriggerType={props.resource.options.verify_session_action_enabled ? 'login_test_url' : 'none'}
    />

    <InputField label="URL to link to for logging in" resourceKey="session_relogin_url" {...props} />
  </>
)

const sessionAuthDefaults = {
  session_cookie_name: '',
  ...loginVerificationDefaults,
}

const APIKeyAuthGroup = (props: ResourceFormProps) => <InputField label="API Key" resourceKey="api_key" {...props} />

const apiKeyAuthDefaults = {
  api_key: '',
}

export const AWSRegionDropdown = (props: ResourceFormProps) => {
  return (
    <>
      <label className="input-label required">AWS region</label>
      <Select
        showSearch
        allowClear
        placeholder="us-east-2"
        value={props.resource.options.amazon_aws_region}
        onChange={props.onChangeOption('amazon_aws_region')}
      >
        {AWSRegions.map((region) => (
          <Select.Option key={region} value={region}>
            {region}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

const AWSv4Group = (props: ResourceFormProps) => (
  <>
    <AWSRegionDropdown {...props} />

    <InputField
      label="AWS Service"
      resourceKey="amazon_service_name"
      textInputClassName="mb4"
      required={true}
      {...props}
    />

    {(!MAIN_DOMAIN || __DEV__) && (
      <Checkbox
        checked={props.resource.options.authWithDefaultCredentialProviderChain}
        onChange={(checked) => {
          props.onChangeOption('authWithDefaultCredentialProviderChain')(checked.target.checked)
        }}
        className="grid-offset-1"
      >
        Connect using
        <a href="https://docs.aws.amazon.com/sdk-for-java/v1/developer-guide/credentials.html" className="ml4">
          {' '}
          default credential provider chain
        </a>
      </Checkbox>
    )}

    {!props.resource.options.authWithDefaultCredentialProviderChain && (
      <>
        <InputField label="AWS Access Key ID" resourceKey="amazon_access_key_id" required={true} {...props} />

        <InputField label="AWS Secret Key" resourceKey="amazon_secret_access_key" required={true} {...props} />
      </>
    )}
  </>
)

export const getScopeForStep = (step: CustomAuthStep, stepNum: number): RecursiveObjectType => {
  switch (step.type) {
    case 'form_modal': {
      const formInputs: RecursiveObjectType = step.form.reduce(
        (acc: any, row: any) => ({ ...acc, [`${row.key}`]: '' }),
        {},
      )
      return {
        [`form${stepNum}`]: formInputs,
      }
    }
    case 'redirect_auth': {
      return { [`redirect${stepNum}`]: { urlparams: '', hashparams: '' } }
    }
    case 'oauth_generic': {
      return { [`oauth${stepNum}`]: { accessToken: '', idToken: '', refreshToken: '' } }
    }
    case 'oauth_google': {
      return { [`google${stepNum}`]: { accessToken: '', idToken: '' } }
    }
    case 'client_side_http_request':
    case 'http_request': {
      return { [`http${stepNum}`]: { body: '', headers: '' } }
    }
    default:
      return {}
  }
}

const customStepDefault: any = {
  oauth_google: {
    oauth2_callback_url: USER_CALLBACK_URL,
  },
  oauth_generic: {
    oauth2_callback_url: USER_CALLBACK_URL,
  },
  form_modal: {
    form: [
      {
        key: 'email',
        type: 'email',
      },
      {
        key: 'password',
        type: 'password',
      },
    ],
  },
}

type StepEditorProps = {
  onChange: (changeset: { [k: string]: string }) => void
  setValue: (value: { type: string }) => void
  options: { value: string; label: string }[]
  stepNum: number
  deleteStep: () => void
  exportedVariables: string[]
  editorScope: {}
} & SharedStepEditorProps

const StepEditor = (props: StepEditorProps) => {
  const { step, exportedVariables, editorScope } = props
  return (
    <div className="custom-auth-step grid-offset-1">
      <div className="custom-auth-step-header flex justify-center">
        <Select
          value={step.type}
          onChange={(value: any) => {
            props.setValue({ type: value, ...customStepDefault[value] })
          }}
        >
          {props.options.map((option: any) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
        <Button type="link" onClick={props.deleteStep} className="custom-auth-step-delete">
          Delete
        </Button>
      </div>
      <div className="custom-auth-step-form grid-1c">
        {(() => {
          switch (step.type) {
            case 'none':
              return null
            case 'form_modal':
              return <CustomAuth.FormModal step={step} stepNum={props.stepNum} onChange={props.onChange} />
            case 'redirect_auth':
              return <CustomAuth.RedirectAuth step={step} stepNum={props.stepNum} onChange={props.onChange} />
            case 'oauth_generic':
              return <CustomAuth.GenericOAuth step={step} stepNum={props.stepNum} onChange={props.onChange} />
            case 'oauth_google':
              return <CustomAuth.Google step={step} stepNum={props.stepNum} onChange={props.onChange} />
            case 'http_request':
              return (
                <CustomAuth.RESTEditor
                  step={step}
                  stepNum={props.stepNum}
                  onChange={props.onChange}
                  restApiSenderType="server"
                  customGlobals={editorScope}
                />
              )
            case 'define_variable':
              return <CustomAuth.DefineVariable step={step} stepNum={props.stepNum} onChange={props.onChange} />
            case 'client_side_http_request':
              return (
                <CustomAuth.RESTEditor
                  step={step}
                  stepNum={props.stepNum}
                  onChange={props.onChange}
                  restApiSenderType="client"
                  customGlobals={editorScope}
                />
              )
          }
        })()}
        {exportedVariables && exportedVariables.length > 0 && (
          <>
            <label className="input-label">Exported variables</label>
            <div className="exported-variables">
              {exportedVariables.map((exportedVariable) => {
                return <pre key={exportedVariable}>{`{{ ${exportedVariable} }}`}</pre>
              })}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const getCurrentUserScope = () => {
  return {
    current_user: {
      email: '',
      firstName: '',
      lastName: '',
      id: '',
    },
  }
}

type RefreshWorkflowProps = {
  refreshAuthSteps: any
  updateResourceOptions: (value: any) => void
}

const RefreshWorkflow = (props: RefreshWorkflowProps) => {
  let editorScope = getCurrentUserScope()
  let previousStepScope = {}
  const { refreshAuthSteps, updateResourceOptions } = props

  return (
    <>
      {refreshAuthSteps.map((step: any, index: any) => {
        const stepNum = index + 1
        const scopeForStep = getScopeForStep(step, stepNum)
        editorScope = { ...editorScope, ...previousStepScope }
        previousStepScope = scopeForStep

        // exportedVariables are of form ['form1.email', 'form1.password']
        const exportedVariables = Object.keys(flattenObj(scopeForStep))

        return (
          <StepEditor
            key={index}
            editorScope={editorScope}
            exportedVariables={exportedVariables}
            step={step}
            options={[
              { value: 'none', label: 'None' },
              { value: 'http_request', label: 'API Request' },
              { value: 'define_variable', label: 'Define a variable' },
            ]}
            stepNum={stepNum}
            setValue={(newStep: any) => {
              updateResourceOptions({
                refreshAuthSteps: update(refreshAuthSteps, { $splice: [[index, 1, newStep]] }),
              })
            }}
            onChange={(changeset: any) => {
              const newStep = {
                ...step,
                ...changeset,
              }
              updateResourceOptions({
                refreshAuthSteps: update(refreshAuthSteps, { $splice: [[index, 1, newStep]] }),
              })
            }}
            deleteStep={() => {
              updateResourceOptions({
                refreshAuthSteps: update(refreshAuthSteps, { $splice: [[index, 1]] }),
              })
            }}
          />
        )
      })}
    </>
  )
}

const TestRefreshAuthWorkflow = (props: any) => {
  const [isFetching, setIsFetching] = useState(false)
  const [debugInfo, setDebugInfo] = useState('')

  const startAuth = useCallback(async () => {
    // eslint-disable-next-line no-console
    console.log('Beginning authorization step')
    setIsFetching(true)
    setDebugInfo('')

    try {
      const body = await POST(
        '/api/resourceAuth/processRefreshAuthStep',
        {},
        {},
        {
          resourceName: props.resourceName,
          environment: props.environment,
          showDebugInfo: true,
        },
      )
      setDebugInfo(body)
      setIsFetching(false)
    } catch (err) {
      setIsFetching(false)
    }
  }, [props.environment, props.resourceName, setDebugInfo, setIsFetching])
  return (
    <div>
      <Button onClick={startAuth} type="default" className="retool-button google-login-button" loading={isFetching}>
        Test refresh auth workflow
      </Button>
      {debugInfo && <pre>{JSON.stringify(debugInfo, null, 2)}</pre>}
    </div>
  )
}

type CustomAuthWorkflowProps = {
  enableClientSideCustomAuthBrowserCalls: boolean
  authSteps: CustomAuthStep[]
  onUpdateAuthSteps: (newAuthSteps: CustomAuthStep[]) => void
  customStepTypesFilter?: string[]
}

export const CustomAuthWorkflow = (props: CustomAuthWorkflowProps) => {
  const { authSteps, enableClientSideCustomAuthBrowserCalls, onUpdateAuthSteps, customStepTypesFilter } = props
  let stepTypes = [
    { value: 'none', label: 'None' },
    { value: 'form_modal', label: 'Form (modal)' },
    { value: 'oauth_generic', label: 'OAuth2 (Generic)' },
    { value: 'oauth_google', label: 'OAuth2 (Google)' },
    { value: 'http_request', label: 'API Request' },
    { value: 'redirect_auth', label: 'Redirect to SSO' },
    { value: 'define_variable', label: 'Define a variable' },
  ]
  if (enableClientSideCustomAuthBrowserCalls) {
    stepTypes = [
      ...stepTypes,
      {
        value: 'client_side_http_request',
        label: 'Browser API request',
      },
    ]
  }

  if (customStepTypesFilter) {
    stepTypes = stepTypes.filter((step) => customStepTypesFilter.includes(step.value) || step.value === 'none')
  }

  let editorScope = getCurrentUserScope()
  let previousStepScope = {}

  return (
    <>
      <div className="grid-offset-1">
        <b> Auth workflow </b>
        {authSteps.length} steps
      </div>
      {authSteps.map((step: any, index: any) => {
        const stepNum = index + 1
        const scopeForStep = getScopeForStep(step, stepNum)
        editorScope = { ...editorScope, ...previousStepScope }
        previousStepScope = scopeForStep

        // exportedVariables are of form ['form1.email', 'form1.password']
        const exportedVariables = Object.keys(flattenObj(scopeForStep))

        return (
          <StepEditor
            key={index}
            options={stepTypes}
            step={step}
            stepNum={stepNum}
            setValue={(newStep: any) => onUpdateAuthSteps(update(authSteps, { $splice: [[index, 1, newStep]] }))}
            onChange={(changeset: any) => {
              const newStep = {
                ...step,
                ...changeset,
              }
              onUpdateAuthSteps(update(authSteps, { $splice: [[index, 1, newStep]] }))
            }}
            deleteStep={() => {
              onUpdateAuthSteps(update(authSteps, { $splice: [[index, 1]] }))
            }}
            editorScope={editorScope}
            exportedVariables={exportedVariables}
          />
        )
      })}
      <div className="grid-offset-1">
        <Button
          type="link"
          className="dib"
          onClick={() => {
            const newStep: CustomAuthStep = { type: 'none' }
            onUpdateAuthSteps(authSteps.concat([newStep]))
          }}
        >
          Add new step to the auth workflow
        </Button>
      </div>
    </>
  )
}

type AuthTriggerType = 'login_test_url' | 'time_based_expiration' | 'none'
const CustomAPIAuth = (props: ResourceFormProps) => {
  const authSteps = props.resource.options.authSteps || []
  const refreshAuthSteps = props.resource.options.refreshAuthSteps || []
  const enableClientSideCustomAuthBrowserCalls = enableClientSideCustomAuthBrowserCallsSelector(getState())

  const getAuthTriggerType = () => {
    if (props.resource.options.verify_session_action_enabled) {
      return 'login_test_url'
    } else if (props.resource.options.verify_session_after_custom_expiry_enabled) {
      return 'time_based_expiration'
    }
    return 'none'
  }
  // Note: authTriggerType should be an enum ideally but this avoids doing a migration (to support backwards compatibility)
  const setAuthTriggerType = (authTriggerType: AuthTriggerType) => {
    props.updateResourceOptions({
      verify_session_action_enabled: authTriggerType === 'login_test_url',
      verify_session_after_custom_expiry_enabled: authTriggerType === 'time_based_expiration',
      ...(authTriggerType !== 'time_based_expiration' && { verify_session_after_custom_expiry_action: null }),
    })
  }

  const authTriggerType = getAuthTriggerType()

  return (
    <>
      <CustomAuthWorkflow
        enableClientSideCustomAuthBrowserCalls={enableClientSideCustomAuthBrowserCalls}
        authSteps={authSteps}
        onUpdateAuthSteps={(newAuthSteps) =>
          props.updateResourceOptions({
            authSteps: newAuthSteps,
          })
        }
      />
      <div className="grid-offset-1">
        {props.resource.id && (
          <div className="grid-offset-1">
            <CustomAuthForm
              resourceName={props.resource.name}
              environment={props.resource.environment}
              buttonText="Test auth workflow"
              showDebugInfo
              restartAuthOnEachSubmit
            />
          </div>
        )}
      </div>
      <div className="grid-offset-1 mt12">
        <b> Refresh auth workflow </b>
        {refreshAuthSteps.length} steps
      </div>
      <RefreshWorkflow refreshAuthSteps={refreshAuthSteps} updateResourceOptions={props.updateResourceOptions} />
      <div className="grid-offset-1">
        <Button
          type="link"
          className="dib"
          onClick={() => {
            props.updateResourceOptions({
              refreshAuthSteps: refreshAuthSteps.concat([{ type: 'none' }]),
            })
          }}
        >
          Add new step to the refresh auth workflow
        </Button>
      </div>
      <div className="grid-offset-1">
        {props.resource.id && (
          <div className="grid-offset-1">
            <TestRefreshAuthWorkflow resourceName={props.resource.name} environment={props.resource.environment} />
          </div>
        )}
      </div>

      <>
        <label className="input-label">Auth trigger</label>
        <Select value={authTriggerType} onChange={(val) => setAuthTriggerType(val as AuthTriggerType)}>
          <Select.Option key="none">None</Select.Option>
          <Select.Option key="login_test_url">Login test URL</Select.Option>
          <Select.Option key="time_based_expiration">Time-based expiration</Select.Option>
        </Select>
      </>

      <AuthTriggerInput {...props} hideToggle authTriggerType={authTriggerType} />

      {authTriggerType !== 'none' && (
        <Checkbox
          checked={props.resource.options.skipConsentScreen}
          onChange={(checked) => {
            props.onChangeOption('skipConsentScreen')(checked.target.checked)
          }}
          className="grid-offset-1"
        >
          Run this custom auth workflow without prompting the user
        </Checkbox>
      )}
    </>
  )
}

export const API_AUTH_METHODS: { [m in APIAuthMethodType | '']: { label: string; group: any; defaults: any } } = {
  auth0: { label: 'Auth0', group: Auth0Group, defaults: auth0Defaults },
  basic: { label: 'Basic Auth', group: BasicAuthGroup, defaults: basicAuthDefaults },
  oauth1: { label: 'OAuth 1.0 (one legged)', group: OAuth1Group, defaults: oauth1Defaults },
  oauth2: { label: 'OAuth 2.0', group: OAuthGroup, defaults: oauthDefaults },
  apiKey: { label: 'API Key Auth', group: APIKeyAuthGroup, defaults: apiKeyAuthDefaults },
  custom: {
    label: 'Custom Auth',
    group: CustomAPIAuth,
    defaults: {
      authSteps: [],
    },
  },
  aws4: {
    label: 'AWS v4',
    group: AWSv4Group,
    defaults: {},
  },
  session: { label: 'Session Based Auth (deprecated)', group: SessionAuthGroup, defaults: sessionAuthDefaults },
  '': {
    label: 'None',
    group: (): any => null,
    defaults: {},
  },
}

type UpdaterProps = {
  resourceKey: string
  resource: any
  updateResourceOptions: (any: any) => void
}

const makeKeyValueAppender = (updateResourceOptions: (any: any) => void, resource: any, resourceKey: string) => () =>
  updateResourceOptions({
    [resourceKey]: [...resource.options[resourceKey], ['', '']],
  })

interface DeleteButtonProps extends UpdaterProps {
  index: number
}
const DeleteButton = (props: DeleteButtonProps) => (
  <Button
    type="link"
    onClick={() => {
      if (props.resource.options[props.resourceKey].length === 1) {
        props.updateResourceOptions({
          [props.resourceKey]: [['', '']],
        })
      } else {
        const updatedOptions = props.resource.options
        updatedOptions[props.resourceKey].splice(props.index, 1)
        props.updateResourceOptions(updatedOptions)
      }
    }}
  >
    <Icon type="close" />
  </Button>
)

interface KeyValueMapProps {
  useCodeEditorForValueInput?: boolean
  valueCustomGlobals?: {}
  keyFieldPlaceholder?: string
  valueFieldPlaceholder?: string
  // If you want a custom value placeholder for each key
  valueFieldPlaceholderForKey?: { [key: string]: string }
  possibleValues?: { [key: string]: any }
}

const KeyValueMap = (props: UpdaterProps & KeyValueMapProps) => {
  let keys = props.resource.options[props.resourceKey]
  if (!_.isArray(keys)) {
    keys = [['', '']]
  }
  const { useCodeEditorForValueInput, valueCustomGlobals } = props

  const updateValue = (newValue: any, index: number) => {
    const updatedOptions = { ...props.resource.options }
    _.set(updatedOptions, `${props.resourceKey}.${index}[1]`, newValue)
    props.updateResourceOptions(updatedOptions)
  }

  return (
    <div className="keyed-table-editor">
      {keys.map((pair: any, index: any) => {
        let valuePlaceholder = props.valueFieldPlaceholder || 'value'
        if (props.valueFieldPlaceholderForKey && props.valueFieldPlaceholderForKey[pair[0]]) {
          valuePlaceholder = props.valueFieldPlaceholderForKey[pair[0]]
        }

        return (
          <React.Fragment key={index}>
            <div className="key">
              <div className="key-editor">
                {props.possibleValues ? (
                  <Select
                    value={props.resource.options[props.resourceKey][index][0]}
                    onChange={(value) => {
                      const updatedOptions = props.resource.options
                      _.set(updatedOptions, `${props.resourceKey}.${index}[0]`, value)
                      props.updateResourceOptions(updatedOptions)
                    }}
                  >
                    {Object.entries(props.possibleValues).map(([key, value]) => (
                      <Select.Option key={key} value={key}>
                        {value}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <TextInput
                    className="fs-exclude"
                    placeholder={props.keyFieldPlaceholder || 'key'}
                    value={pair[0]}
                    onChange={(e) => {
                      const { value } = e.target
                      const updatedOptions = props.resource.options
                      _.set(updatedOptions, `${props.resourceKey}.${index}[0]`, value)
                      props.updateResourceOptions(updatedOptions)
                    }}
                  />
                )}
              </div>
            </div>
            <div className="value">
              <div className="value-editor">
                {useCodeEditorForValueInput ? (
                  <CodeEditor
                    className="fs-exclude"
                    placeholder={valuePlaceholder}
                    value={pair[1]}
                    onChange={(value) => updateValue(value, index)}
                    customGlobals={valueCustomGlobals}
                    includeOnlyCustomGlobalsInScope
                    baseMode="application/json"
                    oneLine
                  />
                ) : (
                  <TextInput
                    className="fs-exclude"
                    placeholder={valuePlaceholder}
                    value={pair[1]}
                    onChange={(e) => {
                      const { value } = e.target
                      updateValue(value, index)
                    }}
                  />
                )}

                <div className="kv-delete">
                  <DeleteButton index={index} {...props} />
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      })}
      <div className="new-row">
        <Button
          type="link"
          onClick={makeKeyValueAppender(props.updateResourceOptions, props.resource, props.resourceKey)}
        >
          <Icon type="plus" className="mr4" /> Add new
        </Button>
      </div>
    </div>
  )
}

interface KeyValueFieldProps extends UpdaterProps {
  label: string | JSX.Element
  subtitle?: string
}

export const KeyValueField = (props: KeyValueFieldProps & KeyValueMapProps) => (
  <>
    <label className="input-label">{props.label}</label>
    <div>
      <KeyValueMap {...props} />
      {props.subtitle && <div className="mt4 gray">{props.subtitle}</div>}
    </div>
  </>
)

export const API_AUTHENTICATION_OPTION_KEY = 'authentication'
export type APIAuthMethodType = 'auth0' | 'basic' | 'oauth1' | 'oauth2' | 'session' | 'apiKey' | 'custom' | 'aws4'
const AUTH_METHOD_NONE = ''

interface APIFormProps extends ResourceFormProps {
  authMethods: APIAuthMethodType[]
}

export const AuthenticationSelection = (props: APIFormProps) => {
  const AuthenticationGroup: any = _.get(API_AUTH_METHODS, `${props.resource.options.authentication || ''}.group`, null)
  return (
    <>
      <label className="input-label">Authentication</label>
      <Select
        value={props.resource.options.authentication || AUTH_METHOD_NONE}
        onChange={props.onChangeOption(API_AUTHENTICATION_OPTION_KEY)}
      >
        <Select.Option key="none" value={AUTH_METHOD_NONE}>
          None
        </Select.Option>
        {props.authMethods.map((method) => (
          <Select.Option key={method} value={method}>
            {API_AUTH_METHODS[method].label}
          </Select.Option>
        ))}
      </Select>
      {AuthenticationGroup && <AuthenticationGroup {...props} />}
    </>
  )
}

export const APIForm = (props: APIFormProps) => {
  const headerCustomGlobals = getCurrentUserScope()
  return (
    <div className="mb20">
      <hr />
      <h5 className="section-heading light-gray mb12">General</h5>
      <div className="grid-1c">
        <InputField
          label="Base URL"
          resourceKey="baseURL"
          subtitle="Use the absolute URL (e.g https://example.com)"
          dataTestId="baseUrlInput"
          {...props}
        />
        <KeyValueField
          label="URL parameters"
          resourceKey="urlparams"
          resource={props.resource}
          updateResourceOptions={props.updateResourceOptions}
        />
        <KeyValueField
          label="Headers"
          resourceKey="headers"
          resource={props.resource}
          updateResourceOptions={props.updateResourceOptions}
          useCodeEditorForValueInput
          valueCustomGlobals={headerCustomGlobals}
        />
        <KeyValueField
          label="Extra body values"
          resourceKey="extraBodyKeyValues"
          resource={props.resource}
          updateResourceOptions={props.updateResourceOptions}
          subtitle="Extra body values are not passed for GET or HEAD requests"
        />
        <label className="input-label">List of cookies to forward</label>
        <div>
          <Select
            mode="tags"
            value={props.resource.options.cookiesToForward || []}
            onChange={props.onChangeOption('cookiesToForward')}
          />
          <div className="mt4 gray">
            You can use the pattern <code> COOKIE_your_cookie_name </code> in the Headers section in order to implement
            the{' '}
            <a
              target="_blank"
              rel="noopener"
              href="https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html#double-submit-cookie"
            >
              double-cookie submit pattern
            </a>
            . Learn more about how to do it{' '}
            <a
              target="_blank"
              rel="nooopener"
              href="https://docs.retool.com/docs/apis#section-double-cookie-submit-pattern"
            >
              here
            </a>
            .
          </div>
        </div>

        <div className="grid-offset-1">
          <Checkbox
            checked={props.resource.options.forwardAllCookies}
            onChange={(checked) => {
              props.onChangeOption('forwardAllCookies')(checked.target.checked)
            }}
            className="grid-offset-1"
          >
            Forward All Cookies
          </Checkbox>
          <div className="ml24">This is useful if you have dynamic cookie names.</div>
        </div>
        <AuthenticationSelection {...props} />
      </div>
    </div>
  )
}

type CommonAPIOptions = {
  baseURL: string
  urlparams: [string, string][]
  headers: [string, string][]
  cookiesToForward: string[]
  forwardAllCookies: boolean
  authentication: string
}

type BasicAPIOptions = {
  basic_username: string
  basic_password: string
}

export type OAuth2APIOptions = {
  oauth2_callback_url: string
  oauth2_auth_url: string
  oauth2_access_token_url: string
  oauth2_client_id: string
  oauth2_client_secret: string
  oauth2_scope: string
  oauth2_access_token: string
  oauth2_refresh_token: string
  verify_session_url: string
}

type SessionAPIOptions = {
  session_cookie_name: string
  verify_session_url: string
}

export type Auth0APIOptions = {
  auth0_domain: string
  auth0_clientID: string
  auth0_clientSecret: string
  auth0_custom_audience: string
}

type OAuth1APIOptions = {
  oauth1_signature_method: OAuth1SignatureMethod
}

type AWSv4Options = {
  authWithDefaultCredentialProviderChain: boolean
}

export type APIOptions = {
  options: CommonAPIOptions &
    BasicAPIOptions &
    OAuth2APIOptions &
    OAuth1APIOptions &
    Auth0APIOptions &
    SessionAPIOptions &
    AWSv4Options
}

export const apiDefaults: APIOptions = {
  options: {
    baseURL: '',
    urlparams: [['', '']],
    headers: [['', '']],
    extraBodyKeyValues: [['', '']],
    cookiesToForward: [],
    forwardAllCookies: false,
    authentication: '',
    ...API_AUTH_METHODS.basic.defaults,
    ...API_AUTH_METHODS.oauth2.defaults,
    ...API_AUTH_METHODS.auth0.defaults,
    ...API_AUTH_METHODS.session.defaults,
    ...API_AUTH_METHODS.oauth1.defaults,
  },
}

export const SERVICE_ACCOUNT_PLACEHOLDER = `{
    "type": "service_account",
    "project_id": "yourProjectId",
    "private_key_id": "yourPrivateKeyId",
    "private_key": "-----BEGIN PRIVATE KEY-----\n11111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111=\n-----END PRIVATE KEY-----\n",
    "client_email": "google-adminsdk-pxixy@somethinggooglerelated.iam.gserviceaccount.com",
    "client_id": "111111111111111111111",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/google-adminsdk-pxixy%40somethinggooglerelated.iam.gserviceaccount.com"
}`

export const CERTIFICATE_PLACEHOLDER = `-----BEGIN CERTIFICATE-----
MIIEMDCCApigAwIBAgIDI2GWMA0GCSqGSIb3DQEBDAUAMDoxODA2BgNVBAMML2Fm
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
-----END CERTIFICATE-----
`

export const CERTIFICATE_PLACEHOLDER_SHORTENED = `-----BEGIN CERTIFICATE-----
MIIEMDCCApigAwIBAgIDI2GWMA0GCSqGSIb3DQEBDAUAMDoxODA2BgNVBAMML2Fm
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
...
-----END CERTIFICATE-----
`

export const RSA_KEY_PLACEHOLDER_SHORTENED = `-----BEGIN RSA PRIVATE KEY-----
MIIEMDCCApigAwIBAgIDI2GWMA0GCSqGSIb3DQEBDAUAMDoxODA2BgNVBAMML2Fm
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
DTE5MDQwODAzNDIyMloXDTI5MDQwNTAzNDIyMlowOjE4MDYGA1UEAwwvYWY1ZjU4
...
-----END RSA PRIVATE KEY-----
`

// from https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/Concepts.RegionsAndAvailabilityZones.html 03/06/2020
export const AWSRegions = [
  'us-east-2',
  'us-east-1',
  'us-west-1',
  'us-west-2',

  'ap-east-1',
  'ap-south-1',
  'ap-northeast-3',
  'ap-northeast-2',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-northeast-1',

  'ca-central-1',

  'cn-north-1',
  'cn-northwest-1',

  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-west-3',
  'eu-north-1',

  'me-south-1',

  'sa-east-1',

  'us-gov-east-1',
  'us-gov-west-1',
]

import {
  AppModelRecord,
  AppModelType,
  AppModelValues,
  AppTemplate,
  IDependencyGraph,
  ExecutionCallStacks,
  PluginTemplate,
} from 'common/records'
import { ds } from 'common/utils'
import Immutable, { OrderedMap } from 'immutable'
import ls from 'local-storage'
import queryString from 'query-string'
import { RetoolState } from 'store'
import { onEditorSelector } from 'store/selectors'
import datadogReporter from '../../DatadogReporter'
import { renderTemplateString } from './renderTemplateString'
import * as Sentry from '@sentry/browser'
import { getViewportData } from './getViewportData'

export async function renderAppTemplate(
  state: AppModelType,
  appTemplate: AppTemplate,
  getCurrentModel: (getState: () => RetoolState) => AppModelType,
  getState: () => RetoolState,
): Promise<{ pageUuid: string; model: AppModelType; currentStackId: number }> {
  datadogReporter.reportEvent({
    type: 'frontend.performance.app_page.fetch_page_data',
    durationMs: performance.now() - datadogReporter.pageLoadedAt,
  })

  const modelInitTimer = datadogReporter.startTimer()

  const origPageUuid = getState().pages.get('pageUuid')
  const isEditor = onEditorSelector(getState())
  const endDepGraphBuildTimer = datadogReporter.startTimer()
  const dependencyGraph = await generateDependencyGraph(state, appTemplate, true, isEditor)
  const extendedDependencyGraph = await cloneDependencyGraphWithPageLoadValueOverrides(dependencyGraph, appTemplate)

  const graphSize = extendedDependencyGraph.getSize()

  endDepGraphBuildTimer.end((duration) => ({
    type: 'frontend.performance.app_page.model_init.dependency_graph_build',
    durationMs: duration,
    data: {
      graphSize,
    },
  }))

  const endTopSortTimer = datadogReporter.startTimer()
  const renderOrder = extendedDependencyGraph.topologicalSort()
  endTopSortTimer.end((duration) => ({
    type: 'frontend.performance.app_page.model_init.topological_sort',
    durationMs: duration,
    data: {
      graphSize,
    },
  }))

  let newModel = new AppModelRecord({
    values: new AppModelValues(
      appTemplate.plugins.map(
        (plugin) => Immutable.OrderedMap({ pluginType: plugin.subtype, namespace: plugin.namespace }), // we want namespace in the model so we can efficiently filter them out without parsing the id
      ),
    ),
    dependencyGraph: extendedDependencyGraph,
    globals: state.globals,
    environment: state.environment,
    executionCallStacks: new ExecutionCallStacks(),
  }) as AppModelType
  const stack = newModel.executionCallStacks.createStack([])

  const templateStringRenderLoopTimer = datadogReporter.startTimer()

  for (const selector of renderOrder) {
    newModel = await renderTemplateString(selector, extendedDependencyGraph.lookupTemplateString(selector), stack.id, {
      model: newModel,
      dependencyGraph,
      namespace: extendedDependencyGraph.lookupNamespace(selector),
      getCurrentModel,
      getState,
    })
  }

  templateStringRenderLoopTimer.end((duration) => ({
    type: 'frontend.performance.app_page.model_init.template_string_render_loop',
    durationMs: duration,
    data: {
      iterations: renderOrder.length,
    },
  }))

  newModel = newModel.setDependencyGraph(dependencyGraph)

  modelInitTimer.end((duration) => ({
    type: 'frontend.performance.app_page.model_init',
    durationMs: duration,
    data: {
      pluginCount: appTemplate.plugins.size,
    },
  }))

  return { model: newModel, currentStackId: stack.id, pageUuid: origPageUuid }
}

const depGraphAsyncImport = async () => await import(/* webpackChunkName: "DependencyGraph" */ './dependencyGraph')

type PageLoadValueOverrides = {
  [pluginId: string]: {
    key: string[]
    value: unknown
  }
}

function pageLoadValueOverridesFromTemplate(appTemplate: AppTemplate) {
  return (
    appTemplate?.pageLoadValueOverrides?.reduce((acc: PageLoadValueOverrides, def) => {
      const [pluginId, ...propertySelector] = def.get('name')?.split('.') ?? []

      if (pluginId && propertySelector.length > 0) {
        acc[pluginId] = {
          key: propertySelector,
          value: def.get('value'),
        }
      }

      return acc
    }, {}) ?? {}
  )
}

export async function cloneDependencyGraphWithPageLoadValueOverrides(
  dependencyGraph: IDependencyGraph,
  appTemplate: AppTemplate,
): Promise<IDependencyGraph> {
  const { DependencyGraph } = await depGraphAsyncImport()

  const extendedDependencyGraph = new DependencyGraph(dependencyGraph)
  appTemplate.plugins.forEach((plugin) => {
    if (plugin.template?.get('runWhenPageLoads')) {
      extendedDependencyGraph.updatePlugin(plugin, Immutable.fromJS({ runWhenModelUpdates: true }))
    }
  })

  const pageLoadValueOverrides = pageLoadValueOverridesFromTemplate(appTemplate)
  Object.entries(pageLoadValueOverrides).forEach(([pluginId, { key, value }]) => {
    const plugin = appTemplate.getPlugin(pluginId)
    if (plugin?.get('template')) {
      extendedDependencyGraph.updatePlugin(plugin, plugin.setIn(['template', ...key], value).template)
    }
  })

  return extendedDependencyGraph
}

export async function generateDependencyGraph(
  state: AppModelType,
  appTemplate: AppTemplate,
  firstPageLoad = false,
  isEditor = false,
  // our jest tests don't work with `await import(...)`, so we add this to let
  // us avoid that codepath. remove this when our test setup supports `await import(...)`
  importDepGraph = depGraphAsyncImport,
): Promise<IDependencyGraph> {
  const { DependencyGraph } = await importDepGraph()
  const dependencyGraph = new DependencyGraph()
  addGlobals(state, dependencyGraph, appTemplate, isEditor)

  const addOverrides = (plugin: PluginTemplate) => {
    if (firstPageLoad) {
      if (plugin.template?.get('runWhenPageLoads')) {
        plugin = plugin.setIn(['template', 'runWhenModelUpdates'], true)
      }
    }

    const pageLoadValueOverrides = appTemplate.pageLoadValueOverrides
    pageLoadValueOverrides.forEach((override) => {
      const selector = override.get('name')?.split('.') ?? []
      if (plugin.id === selector[0] && plugin.get('template')) {
        plugin = plugin.setIn(['template'].concat(selector.slice(1)), override.get('value'))
      }
    })
    return plugin
  }

  // we sort plugins like this because deep children of listviews need to
  // create a dependency to their ancestor listview, and its easiest if the
  // parent hierarchy is built before we hit the children
  const sortedPlugins = sortPluginsByContainerDepth(appTemplate.plugins)
  sortedPlugins.map((plugin) => {
    plugin = addOverrides(plugin)
    dependencyGraph.addPlugin(plugin, plugin.template)
  })
  sortedPlugins.map((plugin) => {
    plugin = addOverrides(plugin)
    dependencyGraph.updatePlugin(plugin, plugin.template)
  })

  const urlFragmentsObject: { [key: string]: unknown } = {}
  appTemplate.urlFragmentDefinitions.map((def) => {
    const name = def.get('name')
    const value = def.get('value')
    if (name) {
      urlFragmentsObject[name] = value
    }
  })
  dependencyGraph.addObject(ds('urlFragments'), Immutable.Map(urlFragmentsObject))
  dependencyGraph.updateObject(ds('urlFragments'), Immutable.Map(urlFragmentsObject))

  dependencyGraph.applyTemplateStringOverrides(appTemplate)

  return dependencyGraph
}

function addGlobals(
  state: AppModelType,
  dependencyGraph: IDependencyGraph,
  appTemplate: AppTemplate,
  isEditor: boolean,
) {
  dependencyGraph.addObject(
    ds('current_user'),
    Immutable.Map({
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      name: '',
      metadata: '',
      groups: Immutable.List([]),
    }),
  )
  dependencyGraph.updateObject(
    ds('current_user'),
    Immutable.Map({
      id: state.globals.get('id'),
      email: state.globals.get('email'),
      firstName: state.globals.get('firstName'),
      lastName: state.globals.get('lastName'),
      fullName: state.globals.get('fullName'),
      groups: state.globals.get('groups'),
      metadata: state.globals.get('metadata'),
    }),
  )

  const parsedSearch = queryString.parse(window.location.search)
  let parsedHash = {}
  if (window.location.hash) {
    parsedHash = queryString.parse(window.location.hash)
  }
  const urlQuery = Immutable.Map({
    href: window.location.href,
    hash: parsedHash,
    ...parsedSearch,
  })
  dependencyGraph.addObject(ds('urlparams'), urlQuery)
  dependencyGraph.updateObject(ds('urlparams'), urlQuery)

  dependencyGraph.addObject(
    ds('localStorage'),
    Immutable.Map({
      values: ls.get('customAppData'),
    }),
  )

  dependencyGraph.updateObject(
    ds('localStorage'),
    Immutable.Map({
      values: ls.get('customAppData'),
    }),
  )

  dependencyGraph.addObject(
    ds('retoolContext'),
    Immutable.Map({
      environment: state.environment,
      runningQueries: [],
      inEditorMode: true,
    }),
  )
  dependencyGraph.updateObject(
    ds('retoolContext'),
    Immutable.Map({
      environment: state.environment,
      runningQueries: [],
      inEditorMode: isEditor,
    }),
  )

  const viewportData = getViewportData()
  dependencyGraph.addObject(ds('viewport'), viewportData)
  dependencyGraph.updateObject(ds('viewport'), viewportData)

  if (appTemplate?.customDocumentTitle && appTemplate?.customDocumentTitleEnabled) {
    const customDocumentTitle = Immutable.Map({ value: appTemplate.customDocumentTitle })
    dependencyGraph.addObject(ds('customDocumentTitle'), customDocumentTitle)
    dependencyGraph.updateObject(ds('customDocumentTitle'), customDocumentTitle)
  }
}

/**
 * sorts plugins such that widget containers are always before their children
 */
function sortPluginsByContainerDepth(plugins: OrderedMap<string, PluginTemplate>) {
  // first pass, build a map of pluginId to its child pluginIds
  const pluginChildren: { [key: string]: string[] | undefined } = {
    '': [],
  }
  plugins.forEach((plugin) => {
    const containerId = plugin.position2 ? plugin.position2.container : ''
    const childrenArr = pluginChildren[containerId]
    if (childrenArr) {
      childrenArr.push(plugin.id)
    } else {
      pluginChildren[containerId] = [plugin.id]
    }
  })

  // second pass, build a map of pluginId to its depth
  const pluginDepths: { [key: string]: number } = {
    '': 0,
  }
  let nextPlugins: [string, number][] = [['', 0]]
  // this is really a while loop, but we use a for-loop for safety
  // against a malformed appTemplate. we expect an iteration for
  // each plugin, + 1 more for "root" (which isn't a plugin)
  for (let i = 0; i < plugins.size + 1; i++) {
    const next = nextPlugins.pop()
    if (!next) break

    const [pluginId, depth] = next
    pluginDepths[pluginId] = depth

    const childrenArr = pluginChildren[pluginId]
    if (childrenArr) {
      nextPlugins = nextPlugins.concat(childrenArr.map((c) => [c, depth + 1]))
    }
  }
  if (nextPlugins.length > 0) Sentry.captureMessage(`Unexpected leftover plugins in sort. is this app malformed?`)

  // now sort based on the depths computed
  return plugins.sort((p1, p2) => pluginDepths[p1.id] - pluginDepths[p2.id])
}

import React from 'react'
import { useResizeObserver } from 'common/resizeObserver'
import { Alert, Button, Icon as NewIcon } from 'components/design-system'
import _collapsibleAlertStyles from "./CollapsibleAlert-collapsibleAlertStyles.module.scss";


const collapsibleAlertStyles = _collapsibleAlertStyles

const CollapsibleAlert: React.FC<{
  title?: string
  message: JSX.Element | string | undefined
  type: React.ComponentProps<typeof Alert>['type']
  banner?: true
}> = ({ title, message, type, banner }) => {
  const [isExpanded, setExpanded] = React.useState(false)

  const ref = React.useRef<HTMLSpanElement>(null)
  const [isClamped, setClamped] = React.useState(false)

  const calculateClamped = React.useCallback(() => {
    if (isExpanded) {
      return
    }
    const el = ref.current
    if (el) {
      setClamped(el.scrollHeight > el.clientHeight)
    }
  }, [isExpanded, ref, setClamped])

  React.useLayoutEffect(() => {
    calculateClamped()
  }, [calculateClamped])

  const handleExpandToggle = React.useCallback(() => {
    setExpanded((isExpanded) => !isExpanded)
  }, [setExpanded])

  React.useLayoutEffect(() => {
    calculateClamped()
  }, [isExpanded, calculateClamped])

  const resizeRef = useResizeObserver(() => {
    calculateClamped()
  })

  let expandButtonContents = null
  if (isExpanded) {
    expandButtonContents = (
      <>
        Hide details
        <NewIcon type="caret-up" className="mr4" />
      </>
    )
  } else if (isClamped) {
    expandButtonContents = (
      <>
        Show details
        <NewIcon type="caret-down" className="mr4" />
      </>
    )
  }
  const expandButton = expandButtonContents && (
    <Button className={collapsibleAlertStyles.expandButton} onClick={handleExpandToggle} type="link">
      {expandButtonContents}
    </Button>
  )

  return (
    <Alert
      message={
        <div ref={resizeRef}>
          <span ref={ref} className={isExpanded ? '' : collapsibleAlertStyles.truncated}>
            {title ? <span className={collapsibleAlertStyles.title}>{title}</span> : null}
            {title && message ? <span> </span> : null}
            <span>{message}</span>
          </span>
          {expandButton}
        </div>
      }
      type={type}
      banner={banner}
    />
  )
}

export default CollapsibleAlert

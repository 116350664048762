import { RETOOL_VERSION } from '../../retoolConstants'
import { getMigrationsBySemver } from '../../components/plugins'
import semverCmp from 'semver-compare'
import { AppTemplate, PluginTemplate, Position2 } from '../../common/records'
import * as Immutable from 'immutable'

const appMigrations = [
  {
    fromVersion: '1.3.13',
    toVersion: '1.4.0',
    up: (template: any) => {
      const CONTAINER_WIDTH = 1260
      const COL_WIDTH = CONTAINER_WIDTH / 12
      const ROW_HEIGHT = 35
      return template.plugins.reduce((newState: any, plugin: any) => {
        const position = plugin.position
        if (!position) {
          return newState
        }
        if (plugin.position2) {
          return newState
        }
        return newState
          .setIn(
            ['plugins', plugin.id, 'position2'],
            new Position2({
              col: Math.round(position.x / COL_WIDTH),
              row: Math.round(position.y / ROW_HEIGHT),
              width: Math.round(position.width / COL_WIDTH),
              height: Math.round(position.height / ROW_HEIGHT),
              container: plugin.container,
            }),
          )
          .deleteIn(['plugins', plugin.id, 'position'])
      }, template)
    },
  },
  {
    fromVersion: '1.25.15',
    toVersion: '1.26.0',
    up: (state: any) => {
      return state.plugins.reduce((newState: any, plugin: any) => {
        if (plugin.type !== 'datasource') {
          return newState
        }
        if (plugin.template.get('triggersOnFailure') !== undefined) {
          return newState
        }

        return newState.setIn(
          ['plugins', plugin.id, 'template', 'triggersOnFailure'],
          newState.getIn(['plugins', plugin.id, 'template', 'triggersOnSuccess']),
        )
      }, state)
    },
  },
  {
    fromVersion: '1.26.4',
    toVersion: '1.26.5',
    up: (state: any) => {
      return state.plugins.reduce((newState: any, plugin: any) => {
        if (plugin.type !== 'datasource') {
          return newState
        }
        if (plugin.template.get('showSuccessToaster') !== undefined) {
          return newState
        }

        return newState.setIn(['plugins', plugin.id, 'template', 'showSuccessToaster'], true)
      }, state)
    },
  },
  {
    fromVersion: '1.26.5',
    toVersion: '1.26.6',
    up: (state: any) => {
      return state.plugins.reduce((newState: any, plugin: any) => {
        if (plugin.type !== 'datasource') {
          return newState
        }
        if (plugin.template.get('privateParams') !== undefined) {
          return newState
        }

        return newState.setIn(['plugins', plugin.id, 'template', 'privateParams'], Immutable.List())
      }, state)
    },
  },
  {
    fromVersion: '2.24.0',
    toVersion: '2.25.0',
    up: function convertSnakeToCamelCase(state: any) {
      return state.plugins.reduce((newState: any, plugin: any) => {
        if (plugin.template.has('raw_values')) {
          newState = newState.updateIn(['plugins', plugin.id, 'template'], (template: any) => {
            // Rename properties from raw_values -> rawValues
            if (template.has('raw_values')) {
              template = template.set('values', template.get('raw_values')).delete('raw_values')
            }
            // Rename properties from display_values -> displayValues
            if (template.has('display_values')) {
              template = template.set('labels', template.get('display_values')).delete('display_values')
            }
            return template
          })
        }
        // Then do a string replace
        const { newValue, newValueExists } = templateMapper(
          newState.getIn(['plugins', plugin.id, 'template']),
          (templateStr: any) => {
            return templateStr.replace(/\.raw_values/g, '.values').replace(/\.display_values/g, '.labels')
          },
        )
        if (newValueExists) {
          return newState.setIn(['plugins', plugin.id, 'template'], newValue)
        } else {
          return newState
        }
      }, state)
    },
  },
  {
    fromVersion: '2.66.5',
    toVersion: '2.66.6',
    up: function noOp(state: any) {
      // This is a bandaid for a larger bug where if there is a backend
      // migration, it will run on every page load until either the page
      // is saved (e.g. someone edits it) or a frontend migration runs.
      // This is because those are the only two cases where the version number
      // on a page save is updated. This no-op migration essentially triggers
      // all loaded pages to hop to version 2.66.6
      return state
    },
  },
  {
    fromVersion: '2.66.11',
    toVersion: '2.66.12',
    up: function noOp(state: any) {
      // This is a bandaid for a larger bug where if there is a backend
      // migration, it will run on every page load until either the page
      // is saved (e.g. someone edits it) or a frontend migration runs.
      // This is because those are the only two cases where the version number
      // on a page save is updated. This no-op migration essentially triggers
      // all loaded pages to hop to version 2.66.12
      return state
    },
  },
  {
    fromVersion: '2.66.38',
    toVersion: '2.66.39',
    up: (appTemplate: AppTemplate) => {
      if (!appTemplate.isGlobalWidget) {
        return appTemplate
      }

      return appTemplate.setIn(['plugins', 'moduleContainer', 'subtype'], 'ModuleContainerWidget')
    },
  },
  {
    fromVersion: '2.66.69',
    toVersion: '2.66.70',
    up: (appTemplate: AppTemplate) => {
      if (!appTemplate.isGlobalWidget) {
        return appTemplate
      }

      return appTemplate.plugins.reduce((newState: AppTemplate, plugin: PluginTemplate) => {
        // we're only modifying global widget props
        if (plugin.type !== 'globalwidgetprop') {
          return newState
        }

        // in the plugins template, we're setting the default value to be the plugins value
        if (!plugin.template.get('defaultValue')) {
          return newState
            .setIn(['plugins', plugin.id, 'template', 'value'], '')
            .setIn(['plugins', plugin.id, 'template', 'defaultValue'], plugin.template.get('value'))
        }

        return newState
      }, appTemplate)
    },
  },
  {
    // force a version bump
    fromVersion: '2.68.4',
    toVersion: '2.68.5',
    up: (state: unknown) => state,
  },
]

export function assembleMigrations(appVersion: any) {
  const migrationsBySemver: any = getMigrationsBySemver()
  appMigrations.forEach((migration) => {
    if (!migrationsBySemver[migration.toVersion]) {
      migrationsBySemver[migration.toVersion] = []
    }
    migrationsBySemver[migration.toVersion].push({
      type: 'global',
      migration,
    })
  })
  const applicableSemvers = Object.keys(migrationsBySemver).filter((semver) => {
    return semverCmp(semver, appVersion) > 0
  })
  // eslint-disable-next-line no-console
  console.log('[DBG] Assembled', applicableSemvers)
  return applicableSemvers.map((toVersion) => {
    return {
      toVersion,
      migrations: migrationsBySemver[toVersion],
    }
  })
}

function runMigration(appTemplate: any, migration: any) {
  switch (migration.type) {
    case 'plugin': {
      return appTemplate.plugins.reduce((newTemplate: any, plugin: any) => {
        if (plugin.subtype !== migration.pluginType) {
          return newTemplate
        }
        return newTemplate.setIn(['plugins', plugin.id], migration.migration.up(plugin))
      }, appTemplate)
    }
    case 'global': {
      return migration.migration.up(appTemplate)
    }
    default: {
      return appTemplate
    }
  }
}

export function migrateAppTemplate(appTemplate: any) {
  let appVersion = appTemplate.version

  // If appVersion not defined, then it is from pre-migration days (version 0.0.0)
  if (!appVersion) {
    appVersion = '0.0.0'
  }
  if (__DEV__ || appVersion !== RETOOL_VERSION) {
    if (__DEV__ && appVersion === RETOOL_VERSION) {
      // eslint-disable-next-line no-console
      console.log('[DBG] App is up to date, but running migrations Safeanyways because in dev mode')
    } else {
      // eslint-disable-next-line no-console
      console.log(`[DBG] App is on version ${appVersion}, migrating to version ${RETOOL_VERSION}`)
    }

    // Run the necessary migrations
    const migrationsToRun = assembleMigrations(appVersion)
    let migrated = false
    let migratedAppTemplate = appTemplate
    migrationsToRun.forEach(({ toVersion, migrations }) => {
      migrated = true
      // eslint-disable-next-line no-console
      console.log(`[Migrating to version ${toVersion}]`)
      migrations.forEach((migration: any) => {
        // eslint-disable-next-line no-console
        console.log('[Running migration]', migration.type, migration.pluginType)
        migratedAppTemplate = runMigration(migratedAppTemplate, migration)
      })
    })
    migratedAppTemplate = migratedAppTemplate.set('version', RETOOL_VERSION)
    // eslint-disable-next-line no-console
    console.log(`[DBG] Finished running migrations. Now on version ${RETOOL_VERSION}.`)
    return [migratedAppTemplate, migrated]
  } else {
    // App is up to date
    // eslint-disable-next-line no-console
    console.log(`[DBG] App is on version ${appVersion}, and is up to date`)
    return [appTemplate, false]
  }
}

function templateMapper(obj: any, mapper: any) {
  let newValue, newValueExists
  if (typeof obj === 'string') {
    const mappedValue = mapper(obj)
    if (obj !== mappedValue) {
      newValue = mappedValue
      newValueExists = true
    }
  } else if (Immutable.isCollection(obj)) {
    newValueExists = false
    newValue = obj.map((subObj) => {
      const res: any = templateMapper(subObj, mapper)
      if (res.newValueExists) {
        newValueExists = true
        return res.newValue
      }
      return subObj
    })
  } else {
    newValue = obj
    newValueExists = false
  }
  return { newValue, newValueExists }
}

import React from 'react'
import _ from 'lodash'
import './ProfilePhoto.scss'
import classNames from 'classnames/bind'
import _styles from "./index-styles.module.scss";


export type ProfilePhotoUser = {
  firstName?: string | null
  lastName?: string | null
  profilePhotoUrl?: string | null
}

export type ProfilePhotoProps = {
  stacked?: boolean
  user: ProfilePhotoUser
  style?: React.CSSProperties
  size?: number
}

const styles = _styles

const cx = classNames.bind(styles)

const ProfilePhoto = (props: ProfilePhotoProps) => {
  const { stacked } = props
  const size = props.size ?? 28
  const style = { ...props.style, '--size': `${size}px` }

  if (props.user.profilePhotoUrl) {
    return <img style={style} className={cx('profilePhoto', { stacked })} src={props.user.profilePhotoUrl} />
  } else if (Object.prototype.hasOwnProperty.call(props.user, 'firstName')) {
    return (
      <div className={cx('profilePhoto', 'profilePhotoPlaceholder', { stacked })} style={style}>
        <div>
          {_.get(props, 'user.firstName[0]', '')}
          {_.get(props, 'user.lastName[0]', '')}
        </div>
      </div>
    )
  } else {
    return <span />
  }
}

export default ProfilePhoto

export type StackedProfilePhotosProps = {
  users: ProfilePhotoUser[]
  maxAvatarsToShow?: number
}

export const StackedProfilePhotos = ({ users, maxAvatarsToShow = 5 }: StackedProfilePhotosProps) => {
  let children: Array<ProfilePhotoUser | number>
  const renderElidedCount = users.length > maxAvatarsToShow
  if (renderElidedCount) {
    children = users.slice(0, maxAvatarsToShow)
    children.push(users.length - maxAvatarsToShow)
  } else {
    children = [...users]
  }

  const size = 28
  // This object has to have a valid CSS prop before `--size` to make TypeScript happy
  const numberUsersStyle = { color: 'inherit', '--size': `${size}px` }

  // here's how this component works:
  // - negative margin allows each list element to overlay its predecessor
  //
  // - to make the left-most list elements appear on top, reverse the order of the
  //   elements and set `flex-direction: row-reverse` so that the last child in the DOM
  //   shows up in the left-most position on top.
  //
  // - skip the negative margin trick for the "first" displayed element (last DOM element)
  //   so the whole list doesn't overflow.
  return (
    <div className="stacked-profile-photos" style={{}}>
      {children.reverse().map((child: ProfilePhotoUser | number, idx: number) => {
        return typeof child === 'number' ? (
          <div
            key="overflow-users-count"
            className={cx('profilePhoto', 'numberUsers', { stacked: true })}
            style={numberUsersStyle}
          >
            <div>{child}+</div>
          </div>
        ) : (
          <ProfilePhoto user={child} key={child.profilePhotoUrl || idx} stacked size={size} />
        )
      })}
    </div>
  )
}

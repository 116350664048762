import './TopNavigation.scss'
import React from 'react'

import { connect } from 'react-redux'
import { Link, withRouter, WithRouterProps } from 'react-router'

import { Icon, Tooltip } from 'components/design-system'
import Navigator from './Navigator'
import { RetoolState } from 'store'
import { showOnboarding } from 'store/onboarding'
import { showOmnibox } from 'store/omnibox'
import {
  canAccessQueryLibrarySelector,
  companyNameSelector,
  editorPrivilegedSelector,
  isAdminSelector,
  isOnPremSelector,
  organizationThemeSelector,
  pathSelector,
  permissionLabelSelector,
  profileSelector,
} from 'store/selectors'
import { MonochromeRetoolLogoFull } from 'common/logo'
import UserToolsContainer from './UserToolsContainer'
import {
  DATA_SOURCES_URL_PREFIX,
  FLOWS_URL_PREFIX,
  QUERY_LIBRARY_URL_PREFIX,
  RESOURCES_URL_PREFIX,
  TREE_URL_PREFIX,
} from 'store/constants'
import { getExperimentValue } from 'common/utils/experiments'
import { isTutorialCTAShouldAppearInMenuSelector, userDisplayNameSelector } from 'store/selectors/userSelectors'
import { NavigationThemeTypes, TopNavigationProps } from './types'

type Props = TopNavigationProps & WithRouterProps

type SearchProps = {
  onClick: () => void
} & NavigationThemeTypes

const Search = (props: SearchProps) => {
  let customStyle
  const { theme, useCustomThemeIfAvailable, ...remainingProps } = props

  if (useCustomThemeIfAvailable && theme && theme.headerIconColor) {
    customStyle = { color: theme.headerIconColor }
  }
  return (
    <Tooltip title="Search anything.">
      <Icon
        type="magnifying-glass"
        className="lighter-gray hover-lightest-gray mr8"
        style={customStyle}
        {...remainingProps}
      />
    </Tooltip>
  )
}

const TopNavigation: React.FC<Props> = (props: Props) => {
  const { canEdit, canAccessQueryLibrary, path, theme } = props

  const urlBeginsWith = (content: string) => path.indexOf(content) === 0

  const active = {
    queryLibrary: urlBeginsWith(QUERY_LIBRARY_URL_PREFIX),
    resources: urlBeginsWith(RESOURCES_URL_PREFIX),
    dataEditor: urlBeginsWith(DATA_SOURCES_URL_PREFIX),
    flowsEditor: urlBeginsWith(FLOWS_URL_PREFIX),
    apps: urlBeginsWith(TREE_URL_PREFIX) || path === '/',
  }

  // We show custom themes for non-editors and when in presentation mode (or simulating presentation mode)
  const useCustomThemeIfAvailable = props.renderAsIfOnPresentation || !canEdit

  // Note: useCustomThemeIfAvailable is passed down as a prop to Navigator etc.
  // because some TopNavigation instances explicitely display custom themes regardless of
  // which page the TopNavigation is on (e.g. the "preview nav bar" in Organization Settings -> Themes)
  const sharedProps = { useCustomThemeIfAvailable, theme }

  return (
    <nav
      className="top-navigation"
      style={{
        background:
          useCustomThemeIfAvailable && theme && theme.headerBackgroundColor
            ? theme.headerBackgroundColor
            : 'var(--white)',
      }}
    >
      <div className="left-bound">
        {useCustomThemeIfAvailable && theme && theme.logoFileBase64String ? (
          <Link to="/" className="customLogo">
            <img src={theme.logoFileBase64String} height="32" width="80" />
          </Link>
        ) : (
          <Link to="/" className="logo">
            <MonochromeRetoolLogoFull
              height={20}
              title="Return to home page"
              desc="Click this Retool logo to return to the home page"
            />
          </Link>
        )}

        {!props.renderAsIfOnPresentation && (
          <div className="main-menu">
            <Navigator
              name="Apps"
              icon="apps"
              active={active.apps}
              hidden={!canEdit}
              navigatesTo="/"
              {...sharedProps}
            />
            <Navigator
              name="Resources"
              icon="resources"
              active={active.resources}
              navigatesTo={RESOURCES_URL_PREFIX}
              hidden={!canEdit}
              {...sharedProps}
            />
            <Navigator
              name="Query Library"
              icon="query-library"
              active={active.queryLibrary}
              navigatesTo={QUERY_LIBRARY_URL_PREFIX}
              hidden={!canAccessQueryLibrary}
              {...sharedProps}
            />
            {getExperimentValue('flows') && (
              <Navigator
                name="Flows"
                icon="flows"
                active={active.flowsEditor}
                navigatesTo={FLOWS_URL_PREFIX}
                hidden={!canEdit}
                inBeta
                {...sharedProps}
              />
            )}
          </div>
        )}
      </div>
      <div className="user-utilities flex items-center">
        <UserToolsContainer />
        <Search onClick={props.showOmnibox} {...sharedProps} />
      </div>
    </nav>
  )
}

const mapDispatchToProps = {
  showOmnibox,
  showOnboarding,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    isTutorialMenuItemVisible: isTutorialCTAShouldAppearInMenuSelector(state),
    permissionLabel: permissionLabelSelector(state),
    displayName: userDisplayNameSelector(state),
    companyName: companyNameSelector(state),
    canAdmin: isAdminSelector(state),
    canEdit: editorPrivilegedSelector(state),
    canAccessQueryLibrary: canAccessQueryLibrarySelector(state),
    path: pathSelector(state),
    user: profileSelector(state),
    theme: organizationThemeSelector(state),
    isOnPrem: isOnPremSelector(state),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNavigation))

import * as Sentry from '@sentry/browser'
import { message } from 'antd'
import { AppTemplate, Folder, ImmutableMapType, NestedGlobalWidgets } from 'common/records'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { initSandbox } from 'common/sandbox'
import { fromJS, List, Map } from 'immutable'
import { get, keyBy } from 'lodash'
import moment from 'moment'
import { callInternalApi } from 'networking'
import { JSON_HEADERS } from 'networking/util'
import { browserHistory } from 'react-router'
import { RetoolDispatch, RetoolState } from 'store'
import { PAGE_USER_HEARTBEATS_EVENT } from 'store/actionTypes'
import { deserializeSave } from 'store/appModel/templateUtils'
import { callApi } from 'store/callApi'
import { RECEIVE_UPDATE_PHOTO_URL } from 'store/constants'
import { RetoolAPIDispatcher } from 'store/storeUtils'
import uuidv4 from 'uuid/v4'
import { RECEIVE_SAVE, TEARDOWN_PAGE } from './actionTypes'
import { createdNamespacedPreloadedJS, formatBackendModulesResponse } from './namespaceHelpers'
import { exportSave, FAILURE_RESTORE, runMigrationsAndStart } from './template'

// ------------------------------------
// Constants
// ------------------------------------
export const REQUEST_PAGES_LOAD = 'REQUEST_PAGES_LOAD'
export const RECEIVE_PAGES_LOAD = 'RECEIVE_PAGES_LOAD'
export const FAILURE_PAGES_LOAD = 'FAILURE_PAGES_LOAD'

export const REQUEST_PAGE_NAMES = 'REQUEST_PAGE_NAMES'
export const RECEIVE_PAGE_NAMES = 'RECEIVE_PAGE_NAMES'
export const FAILURE_PAGE_NAMES = 'FAILURE_PAGE_NAMES'

export const REQUEST_PAGE_LOAD = 'REQUEST_PAGE_LOAD'
export const RECEIVE_PAGE_LOAD = 'RECEIVE_PAGE_LOAD'
export const FAILURE_PAGE_LOAD = 'FAILURE_PAGE_LOAD'

export const REQUEST_PAGE_RENAME = 'REQUEST_PAGE_RENAME'
export const RECEIVE_PAGE_RENAME = 'RECEIVE_PAGE_RENAME'
export const FAILURE_PAGE_RENAME = 'FAILURE_PAGE_RENAME'

export const REQUEST_PAGE_DESCRIPTION_CHANGE = 'REQUEST_PAGE_DESCRIPTION_CHANGE'
export const RECEIVE_PAGE_DESCRIPTION_CHANGE = 'RECEIVE_PAGE_DESCRIPTION_CHANGE'
export const FAILURE_PAGE_DESCRIPTION_CHANGE = 'FAILURE_PAGE_DESCRIPTION_CHANGE'

export const REQUEST_PAGE_PUBLISH = 'REQUEST_PAGE_PUBLISH'
export const RECEIVE_PAGE_PUBLISH = 'RECEIVE_PAGE_PUBLISH'
export const FAILURE_PAGE_PUBLISH = 'FAILURE_PAGE_PUBLISH'

export const REQUEST_PAGE_UNPUBLISH = 'REQUEST_PAGE_UNPUBLISH'
export const RECEIVE_PAGE_UNPUBLISH = 'RECEIVE_PAGE_UNPUBLISH'
export const FAILURE_PAGE_UNPUBLISH = 'FAILURE_PAGE_UNPUBLISH'

export const REQUEST_PAGE_CREATE = 'REQUEST_PAGE_CREATE'
export const RECEIVE_PAGE_CREATE = 'RECEIVE_PAGE_CREATE'
export const FAILURE_PAGE_CREATE = 'FAILURE_PAGE_CREATE'

export const REQUEST_PAGE_CLONE = 'REQUEST_PAGE_CLONE'
export const RECEIVE_PAGE_CLONE = 'RECEIVE_PAGE_CLONE'
export const FAILURE_PAGE_CLONE = 'FAILURE_PAGE_CLONE'

export const REQUEST_TEMPLATE_CLONE = 'REQUEST_TEMPLATE_CLONE'
export const RECEIVE_TEMPLATE_CLONE = 'RECEIVE_TEMPLATE_CLONE'
export const FAILURE_TEMPLATE_CLONE = 'FAILURE_TEMPLATE_CLONE'

export const REQUEST_PAGE_UPLOAD = 'REQUEST_PAGE_UPLOAD'
export const RECEIVE_PAGE_UPLOAD = 'RECEIVE_PAGE_UPLOAD'
export const FAILURE_PAGE_UPLOAD = 'FAILURE_PAGE_UPLOAD'

export const REQUEST_PAGE_PASSWORD_EDIT = 'REQUEST_PAGE_PASSWORD_EDIT'
export const RECEIVE_PAGE_PASSWORD_EDIT = 'RECEIVE_PAGE_PASSWORD_EDIT'
export const FAILURE_PAGE_PASSWORD_EDIT = 'FAILURE_PAGE_PASSWORD_EDIT'

export const REQUEST_SAVES = 'REQUEST_SAVES'
export const RECEIVE_SAVES = 'RECEIVE_SAVES'
export const FAILURE_SAVES = 'FAILURE_SAVES'

export const REQUEST_DELETE_PAGE = 'REQUEST_DELETE_PAGE'
export const RECEIVE_DELETE_PAGE = 'RECEIVE_DELETE_PAGE'
export const FAILURE_DELETE_PAGE = 'FAILURE_DELETE_PAGE'

export const REQUEST_CREATE_FOLDER = 'REQUEST_CREATE_FOLDER'
export const RECEIVE_CREATE_FOLDER = 'RECEIVE_CREATE_FOLDER'
export const FAILURE_CREATE_FOLDER = 'FAILURE_CREATE_FOLDER'

export const REQUEST_DELETE_FOLDER = 'REQUEST_DELETE_FOLDER'
export const RECEIVE_DELETE_FOLDER = 'RECEIVE_DELETE_FOLDER'
export const FAILURE_DELETE_FOLDER = 'FAILURE_DELETE_FOLDER'

export const REQUEST_RENAME_FOLDER = 'REQUEST_RENAME_FOLDER'
export const RECEIVE_RENAME_FOLDER = 'RECEIVE_RENAME_FOLDER'
export const FAILURE_RENAME_FOLDER = 'FAILURE_RENAME_FOLDER'

export const REQUEST_MOVE_PAGE = 'REQUEST_MOVE_PAGE'
export const RECEIVE_MOVE_PAGE = 'RECEIVE_MOVE_PAGE'
export const FAILURE_MOVE_PAGE = 'FAILURE_MOVE_PAGE'

export const REQUEST_BULK_MOVE_PAGES = 'REQUEST_BULK_MOVE_PAGES'
export const RECEIVE_BULK_MOVE_PAGES = 'RECEIVE_BULK_MOVE_PAGES'
export const FAILURE_BULK_MOVE_PAGES = 'FAILURE_BULK_MOVE_PAGES'

export const REQUEST_BULK_DELETE_PAGES = 'REQUEST_BULK_DELETE_PAGES'
export const RECEIVE_BULK_DELETE_PAGES = 'RECEIVE_BULK_DELETE_PAGES'
export const FAILURE_BULK_DELETE_PAGES = 'FAILURE_BULK_DELETE_PAGES'

export const REQUEST_PAGE_PROTECT = 'REQUEST_PAGE_PROTECT'
export const RECEIVE_PAGE_PROTECT = 'RECEIVE_PAGE_PROTECT'
export const FAILURE_PAGE_PROTECT = 'FAILURE_PAGE_PROTECT'

export const REQUEST_PAGE_UNPROTECT = 'REQUEST_PAGE_UNPROTECT'
export const RECEIVE_PAGE_UNPROTECT = 'RECEIVE_PAGE_UNPROTECT'
export const FAILURE_PAGE_UNPROTECT = 'FAILURE_PAGE_UNPROTECT'

export const REQUEST_COMMIT_CREATE = 'REQUEST_COMMIT_CREATE'
export const RECEIVE_COMMIT_CREATE = 'RECEIVE_COMMIT_CREATE'
export const FAILURE_COMMIT_CREATE = 'FAILURE_COMMIT_CREATE'

const SAVE_HEARTBEAT_INTERVAL = 'SAVE_HEARTBEAT_INTERVAL'

export type PageHeartbeatMode = 'editing' | 'viewing'
export const HEARTBEAT_MODE_EDITING = 'editing'
export const HEARTBEAT_MODE_VIEWING = 'viewing'

// ------------------------------------
// Actions
// ------------------------------------

export function pagesLoad(option: { useCache: boolean } = { useCache: false }) {
  return async (dispatch: any) => {
    await dispatch(
      callApi({
        endpoint: '/api/pages',
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        bailout: (reduxState) => {
          return option.useCache && reduxState.pages.get('pages').size > 0
        },
        types: [
          REQUEST_PAGES_LOAD,
          {
            type: RECEIVE_PAGES_LOAD,
            // eslint-disable-next-line no-console
            meta: { onSuccess: () => console.log('[DBG] Successfully loaded pages') },
          },
          FAILURE_PAGES_LOAD,
        ],
      }),
    )
  }
}

export function getPageNames() {
  return (dispatch: any) => {
    dispatch(
      callApi({
        endpoint: '/api/editor/pageNames',
        method: 'GET',
        headers: JSON_HEADERS,
        types: [REQUEST_PAGE_NAMES, RECEIVE_PAGE_NAMES, FAILURE_PAGE_NAMES],
      }),
    )
  }
}

export function publishPage(pageUuid: any) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: `/api/pages/uuids/${pageUuid}/publish`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        types: [REQUEST_PAGE_PUBLISH, RECEIVE_PAGE_PUBLISH, FAILURE_PAGE_PUBLISH],
      }),
    )
    // eslint-disable-next-line no-console
    console.log(result)
    if (result.type === FAILURE_PAGE_PUBLISH) {
      message.error(result.payload.response.message)
    }
  }
}

export function editPagePassword(pageUuid: any, password: any) {
  return (dispatch: any) => {
    dispatch(
      callApi({
        endpoint: `/api/pages/uuids/${pageUuid}/editPassword`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
        types: [REQUEST_PAGE_PASSWORD_EDIT, RECEIVE_PAGE_PASSWORD_EDIT, FAILURE_PAGE_PASSWORD_EDIT],
      }),
    )
  }
}

export function renamePage(pageUuid: string, newPageName: string, redirect: boolean) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: `/api/pages/uuids/${pageUuid}`,
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newPageName, redirect }),
        types: [REQUEST_PAGE_RENAME, RECEIVE_PAGE_RENAME, FAILURE_PAGE_RENAME],
      }),
    )

    if (result.type === FAILURE_PAGE_RENAME) {
      throw Error(result.payload.response.message)
    }

    // eslint-disable-next-line no-console
    console.log(result)
    if (result.type === RECEIVE_PAGE_RENAME && redirect) {
      window.location.replace(encodeURIComponent(result.payload.page.name))
    }
  }
}

type ChangePageDescriptionParams = {
  pageUuid: string
  newPageDescription: string
  redirect?: boolean
}

export function changePageDescription({ pageUuid, newPageDescription, redirect = false }: ChangePageDescriptionParams) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: `/api/pages/uuids/${pageUuid}`,
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newPageDescription, redirect }),
        types: [REQUEST_PAGE_DESCRIPTION_CHANGE, RECEIVE_PAGE_DESCRIPTION_CHANGE, FAILURE_PAGE_DESCRIPTION_CHANGE],
      }),
    )

    if (result.type === FAILURE_PAGE_DESCRIPTION_CHANGE) {
      // If we don't get a response back from the server
      throw Error(result.payload.response?.message || 'Could not change page description')
    }
  }
}

export function unpublishPage(pageUuid: any) {
  return (dispatch: any) => {
    dispatch(
      callApi({
        endpoint: `/api/pages/uuids/${pageUuid}/unpublish`,
        method: 'POST',
        headers: JSON_HEADERS,
        types: [REQUEST_PAGE_UNPUBLISH, RECEIVE_PAGE_UNPUBLISH, FAILURE_PAGE_UNPUBLISH],
      }),
    )
  }
}

export function createPage(pageName: string, folderId?: number, isGlobalWidget?: boolean) {
  return async (dispatch: any) => {
    const res = await dispatch(
      callApi({
        endpoint: '/api/pages/createPage',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ pageName, folderId, isGlobalWidget: !!isGlobalWidget }),
        types: [REQUEST_PAGE_CREATE, RECEIVE_PAGE_CREATE, FAILURE_PAGE_CREATE],
      }),
    )
    if (res.type === FAILURE_PAGE_CREATE) {
      throw Error(res.payload.response.message)
    }

    const eventType = isGlobalWidget ? 'Module Created' : 'App Created'
    retoolAnalyticsTrack(eventType, { createType: 'scratch', pageName, folderId })
  }
}

export function createAppFromOnboarding(encodedOnboardingSourcePage: string | null) {
  return async (dispatch: any) => {
    if (encodedOnboardingSourcePage !== null) {
      browserHistory.push(`/editor/${encodedOnboardingSourcePage}`)
    } else {
      const newPageName = `Untitled - ${uuidv4().replace('-', '').slice(0, 4)}`
      await dispatch(createPage(newPageName))
      browserHistory.push(`/editor/${encodeURIComponent(newPageName)}`)
    }
  }
}

export function clonePage(pageUuid: any, newPageName: any, folderId: any) {
  return async (dispatch: any) => {
    const res = await dispatch(
      callApi({
        endpoint: '/api/pages/clonePage',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ pageUuid, newPageName, folderId }),
        types: [REQUEST_PAGE_CLONE, RECEIVE_PAGE_CLONE, FAILURE_PAGE_CLONE],
      }),
    )
    if (res.type === FAILURE_PAGE_CLONE) {
      throw Error(res.payload.response.message)
    }
    retoolAnalyticsTrack('App Created', { createType: 'clone', pageName: newPageName, folderId })
  }
}

export function createGlobalWidgetFromApp(
  newPageName: string,
  newFolderId: number,
  currentPageName: string,
  currentPageUuid: string,
) {
  return async (dispatch: any) => {
    const save = await dispatch(exportSave(currentPageName, currentPageUuid, false, false))
    const page = save.page.data.appState

    const response = await dispatch(
      callApi({
        endpoint: '/api/pages/createPage',
        body: JSON.stringify({ appState: page, pageName: newPageName, folderId: newFolderId, isGlobalWidget: true }),
        method: 'POST',
        headers: JSON_HEADERS,
        types: [REQUEST_PAGE_UPLOAD, RECEIVE_PAGE_UPLOAD, FAILURE_PAGE_UPLOAD],
      }),
    )
    if (response.type === FAILURE_PAGE_UPLOAD) {
      throw Error(response.payload.response.message)
    }
    retoolAnalyticsTrack('Module Created', {
      createType: 'fromExistingApp',
      pageName: newPageName,
      folderId: newFolderId,
    })
  }
}

export function uploadPage(file: any, newPageName: any, folderId: any) {
  return async (dispatch: any) => {
    if (!file) {
      return
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = async (event: any) => {
        try {
          const result = JSON.parse(event.target.result)
          const appState = result.page.data.appState
          const modules = result.modules || {}
          const uuid = result.uuid
          const isGlobalWidget = deserializeSave(appState).get('isGlobalWidget')
          const response = await dispatch(
            callApi({
              endpoint: '/api/pages/createPage',
              body: JSON.stringify({ appState, uuid, pageName: newPageName, folderId, isGlobalWidget, modules }),
              method: 'POST',
              headers: JSON_HEADERS,
              types: [REQUEST_PAGE_UPLOAD, RECEIVE_PAGE_UPLOAD, FAILURE_PAGE_UPLOAD],
            }),
          )
          if (response.type === FAILURE_PAGE_UPLOAD) {
            throw Error(response.payload.response.message)
          }
          resolve(response)

          const eventType = isGlobalWidget ? 'Module Created' : 'App Created'
          retoolAnalyticsTrack(eventType, { createType: 'upload', pageName: newPageName, folderId })
        } catch (err) {
          reject(err)
        }
      }
      reader.readAsText(file)
    })
  }
}

export function createFolder(parentFolderId: any, folderName: any) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/folders/createFolder',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ parentFolderId, folderName }),
        types: [REQUEST_CREATE_FOLDER, RECEIVE_CREATE_FOLDER, FAILURE_CREATE_FOLDER],
      }),
    )
    if (result.type === RECEIVE_CREATE_FOLDER) {
      message.success('Successfully created folder.')
    } else {
      message.error(result.payload.response.message)
    }
    return result.payload.folder as Folder
  }
}

export function deleteFolder(folderId: number) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/folders/deleteFolder',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ folderId }),
        types: [REQUEST_DELETE_FOLDER, RECEIVE_DELETE_FOLDER, FAILURE_DELETE_FOLDER],
      }),
    )
    if (result.type === RECEIVE_DELETE_FOLDER) {
      message.success('Successfully deleted folder.')
    } else {
      message.error(result.payload.response.message)
    }
    return result
  }
}

export function renameFolder(folderId: number, folderName: string) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/folders/renameFolder',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ folderId, folderName }),
        types: [REQUEST_RENAME_FOLDER, RECEIVE_RENAME_FOLDER, FAILURE_RENAME_FOLDER],
      }),
    )
    if (result.type === RECEIVE_RENAME_FOLDER) {
      message.success('Successfully renamed folder.')
    } else {
      message.error(result.payload.response.message)
    }
    return result
  }
}

export function deletePage(pageId: any) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/folders/deletePage',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ pageId }),
        types: [REQUEST_DELETE_PAGE, RECEIVE_DELETE_PAGE, FAILURE_DELETE_PAGE],
      }),
    )
    if (result.type === RECEIVE_DELETE_PAGE) {
      message.success('Successfully deleted page.')
    } else {
      message.error(result.payload.response.message)
    }
  }
}

export function movePage(pageId: any, folderId: any) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/folders/movePage',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ pageId, folderId }),
        types: [REQUEST_MOVE_PAGE, RECEIVE_MOVE_PAGE, FAILURE_MOVE_PAGE],
      }),
    )
    if (result.type === RECEIVE_MOVE_PAGE) {
      message.success('Successfully moved page.')
    } else {
      message.error(result.payload.response.message)
    }
    return result
  }
}

export function protectPage(pageId: string) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/pages/protectPage',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ pageUuid: pageId }),
        types: [REQUEST_PAGE_PROTECT, RECEIVE_PAGE_PROTECT, FAILURE_PAGE_PROTECT],
      }),
    )
    if (result.type === RECEIVE_PAGE_PROTECT) {
      message.success('Successfully protected page.')
      retoolAnalyticsTrack(result?.isGlobalWidget ? 'Module Protected' : 'App Protected', { pageUuid: pageId })
    } else {
      message.error(result.payload.response.message)
    }
    return result
  }
}

export function unprotectPage(pageId: string) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/pages/unprotectPage',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ pageUuid: pageId }),
        types: [REQUEST_PAGE_UNPROTECT, RECEIVE_PAGE_UNPROTECT, FAILURE_PAGE_UNPROTECT],
      }),
    )
    if (result.type === RECEIVE_PAGE_UNPROTECT) {
      retoolAnalyticsTrack(result?.isGlobalWidget ? 'Module Unprotected' : 'App Unprotected', { pageUuid: pageId })
      message.success('Successfully removed protected status from page.')
    } else {
      message.error(result.payload.response.message)
    }
    return result
  }
}

export function createCommit(subject: string, body: string, pageId: string, branchName: string) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/commits',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ subject, body, pageId, branchName }),
        types: [REQUEST_COMMIT_CREATE, RECEIVE_COMMIT_CREATE, FAILURE_COMMIT_CREATE],
      }),
    )
    if (result.type === RECEIVE_COMMIT_CREATE) {
      message.success('Successfully committed changes.')
    } else {
      message.error(result.payload.response.message)
    }
    return result
  }
}

export function bulkMovePages(pageIds: number[], folderId: number) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/folders/bulkMovePages',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ pageIds, folderId }),
        types: [REQUEST_BULK_MOVE_PAGES, RECEIVE_BULK_MOVE_PAGES, FAILURE_BULK_MOVE_PAGES],
      }),
    )
    if (result.type === RECEIVE_BULK_MOVE_PAGES) {
      if (pageIds.length === 0) {
        // NB: this should _never_ happen, but being defensive
        message.warn(`No apps were moved`)
      } else if (pageIds.length === 1) {
        message.success(`Successfully moved 1 app.`)
      } else {
        message.success(`Successfully moved ${pageIds.length} apps.`)
      }
    } else {
      message.error(result.payload.response.message)
    }
    return result
  }
}

export function bulkDeletePages(pageIds: number[]) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/folders/bulkDeletePages',
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({ pageIds }),
        types: [REQUEST_BULK_DELETE_PAGES, RECEIVE_BULK_DELETE_PAGES, FAILURE_BULK_DELETE_PAGES],
      }),
    )
    if (result.type === RECEIVE_BULK_DELETE_PAGES) {
      if (pageIds.length === 0) {
        // NB: this should _never_ happen, but being defensive
        message.warn(`No apps were deleted`)
      } else if (pageIds.length === 1) {
        message.success(`Successfully deleted 1 app.`)
      } else {
        message.success(`Successfully deleted ${pageIds.length} apps.`)
      }
    } else {
      message.error(result.payload.response.message)
    }
    return result
  }
}

function handlePagesLoad(state: any, action: any) {
  return state
    .set('pages', fromJS(keyBy(action.payload.pages, 'uuid')))
    .set('folders', fromJS(keyBy(action.payload.folders, 'id')))
    .set('isFetchingPagesForFirstTime', false)
}

export function tearDownPage() {
  return { type: TEARDOWN_PAGE }
}

export function sandboxParams(
  deserializedSave: AppTemplate,
  payload: {
    javaScriptLinks?: string[]
    preloadedJavaScript?: string
    environmentVariables?: { [key: string]: string }
  },
  nestedModules: NestedGlobalWidgets,
) {
  const preloadedOrganizationJavaScript = payload.preloadedJavaScript ?? ''
  const preloadedAppJavaScript = deserializedSave.preloadedAppJavaScript ?? ''

  const preloadedOrgJSLinks = payload.javaScriptLinks ?? []
  const preloadedAppJSLinks = deserializedSave.preloadedAppJSLinks ?? []

  const preloadedModulesJavaScript = createdNamespacedPreloadedJS(nestedModules).join('\n\n')
  const preloadedModulesJSLinks = Object.values(nestedModules).flatMap((m) => m.appTemplate.preloadedAppJSLinks)

  const preloadedJavaScript = [
    preloadedOrganizationJavaScript,
    preloadedAppJavaScript,
    preloadedModulesJavaScript,
  ].join('\n\n')
  const javaScriptLinks = preloadedOrgJSLinks.concat(preloadedAppJSLinks).concat(preloadedModulesJSLinks)
  return {
    preloadedJavaScript,
    javaScriptLinks,
    environmentVariables: payload.environmentVariables,
  }
}

export function pageLoad(
  pagePath: string,
  editorMode: boolean,
  historyOffset?: string,
  releaseVersion?: string,
  branchName?: string,
) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: '/api/pages/lookupPage',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pagePath,
          showLatest: editorMode && !historyOffset,
          releaseVersion,
          historyOffset,
          branchName,
          mode: editorMode ? 'edit' : 'view',
        }),
        types: [
          REQUEST_PAGE_LOAD,
          {
            type: RECEIVE_PAGE_LOAD,
            meta: { pageName: pagePath, editorMode },
          },
          {
            type: FAILURE_PAGE_LOAD,
            meta: { pageName: pagePath, editorMode },
          },
        ],
      }),
    )

    if (result.payload.page && result.type === RECEIVE_PAGE_LOAD) {
      const nestedGlobalWidgets = formatBackendModulesResponse(result.payload.modules ?? {})
      const deserializedSave = deserializeSave(result.payload.page.data.appState)
      initSandbox(sandboxParams(deserializedSave, result.payload, nestedGlobalWidgets))
      await dispatch(runMigrationsAndStart(deserializedSave, nestedGlobalWidgets))
      dispatch(startUserHeartbeat())
      return result
    } else {
      if (result.payload.status === 403) {
        // eslint-disable-next-line no-console
        console.log('403 error', result)
        browserHistory.push('/forbidden')
      }
      if (result.payload.status === 404) {
        // eslint-disable-next-line no-console
        console.log('404 error', result)
        browserHistory.push('/pageNotFound')
      }
      await dispatch({
        type: FAILURE_RESTORE,
        payload: result.payload,
      })
    }
  }
}

export enum GET_TAGS {
  REQUEST = 'REQUEST_GET_TAGS',
  SUCCESS = 'RECEIVE_GET_TAGS',
  FAILURE = 'FAILURE_GET_TAGS',
}

export function getTags(): RetoolAPIDispatcher<typeof GET_TAGS> {
  return async (dispatch, getState) => {
    const pageUuid = getState().pages.get('pageUuid')
    if (!pageUuid) {
      // todo(abdul) this happens sometimes, but i can't repro reliably
      Sentry.captureMessage('pageUuid null when fetching tags')
      return
    }

    const res = await dispatch(
      callApi({
        endpoint: `/api/pages/uuids/${pageUuid}/tags`,
        method: 'GET',
        headers: JSON_HEADERS,
        types: [GET_TAGS.REQUEST, GET_TAGS.SUCCESS, GET_TAGS.FAILURE],
      }),
    )
    return res
  }
}

export enum CREATE_TAG {
  REQUEST = 'REQUEST_CREATE_TAG',
  SUCCESS = 'RECEIVE_CREATE_TAG',
  FAILURE = 'FAILURE_CREATE_TAG',
}

export function createTag(tagName: string, tagDescription: string): RetoolAPIDispatcher<typeof CREATE_TAG> {
  return async (dispatch, getState) => {
    const pageUuid = getState().pages.get('pageUuid')
    const res = await dispatch(
      callApi({
        endpoint: `/api/pages/uuids/${pageUuid}/createTag`,
        method: 'POST',
        headers: JSON_HEADERS,
        types: [CREATE_TAG.REQUEST, CREATE_TAG.SUCCESS, CREATE_TAG.FAILURE],
        body: JSON.stringify({ tagName, tagDescription }),
      }),
    )
    if (res.type === CREATE_TAG.FAILURE) {
      message.error(res.payload.response.message)
    } else {
      message.success(res.payload.message)
    }
    return res
  }
}

export enum RELEASE_TAG {
  SUCCESS = 'RECEIVE_RELEASE_TAG',
}

type ApiReleaseTagSuccessType = {
  message: string
  page: {
    id: number
    uuid: string
  }
  tag: {
    id: string
    name: string
  }
}

type ReleaseTagReceiveType = {
  type: typeof RELEASE_TAG.SUCCESS
  payload: ApiReleaseTagSuccessType
}

const releaseTagReceive = (json: ApiReleaseTagSuccessType): ReleaseTagReceiveType => {
  message.success(json.message)
  return {
    type: RELEASE_TAG.SUCCESS,
    payload: json,
  }
}

export const releaseTag = (pageUuid: string, tagId: string) => async (dispatch: RetoolDispatch): Promise<void> => {
  try {
    const result = await callInternalApi({
      url: `/api/pages/uuids/${pageUuid}/releaseTag`,
      method: 'POST',
      body: { tagId },
    })
    const deserializedSave = deserializeSave(result.pageSave.data.appState)
    await dispatch(runMigrationsAndStart(deserializedSave))
    dispatch(releaseTagReceive(result))
  } catch (error) {
    message.error(error.message)
  }
}

export enum RELEASE_LATEST_TAG {
  SUCCESS = 'RECEIVE_RELEASE_LATEST_TAG',
}

type ApiReleaseLatestTagSuccessType = {
  message: string
  page: {
    id: number
    uuid: string
  }
}

type ReleaseLatestTagReceiveType = {
  type: typeof RELEASE_TAG.SUCCESS
  payload: {
    message: string
  }
}

const releaseLatestTagReceive = (
  json: ApiReleaseLatestTagSuccessType,
  customSuccessMessage?: string,
): ReleaseLatestTagReceiveType => {
  message.success(customSuccessMessage || json.message)
  return {
    type: RELEASE_TAG.SUCCESS,
    payload: json,
  }
}

export const releaseLatestTag = (pageUuid: string, customSuccessMessage?: string) => async (
  dispatch: RetoolDispatch,
): Promise<void> => {
  try {
    const result = await callInternalApi({
      url: `/api/pages/uuids/${pageUuid}/releaseLatestTag`,
      method: 'POST',
    })
    const deserializedSave = deserializeSave(result.pageSave.data.appState)
    await dispatch(runMigrationsAndStart(deserializedSave))
    dispatch(releaseLatestTagReceive(result, customSuccessMessage))
  } catch (error) {
    message.error(error.message)
  }
}

function handlePageLoad(state: any, action: any) {
  return state
    .set('pageUuid', action.payload.uuid || action.meta.uuid)
    .set('pageName', action.meta.pageName)
    .set('pageDescription', action.payload.description)
    .set('pageTagName', action.payload.tagName)
    .set('pageReleasedTagName', action.payload.releasedTagName)
    .set('editorMode', action.meta.editorMode)
    .set('embed_uuid', action.payload.embed ? action.payload.embed.uuid : null)
    .set('embedPassword', action.payload.embed ? action.payload.embed.password : null)
    .set('uuidToPaths', action.payload.linkedPagePaths)
    .set('pageBranchName', action.payload.branchName)
    .set('pageBranchId', action.payload.branchId)
    .set('pageCommit', action.payload.commitId)
    .set('pageProtected', action.payload.protected)
}

function handlePagePublish(state: any, action: any) {
  return state.set('embed_uuid', action.payload.embed ? action.payload.embed.uuid : null)
}

function handlePageUnpublish(state: any) {
  return state.set('embed_uuid', null)
}

function handlePagePasswordEdit(state: any, action: any) {
  return state.set('embedPassword', action.payload.embed ? action.payload.embed.password : null)
}

function handleTearDownPage(state: any) {
  return state
    .set('pageName', null)
    .set('pageUuid', null)
    .set('pageTagName', null)
    .set('pageReleasedTagName', null)
    .set('pageCommit', null)
    .set('pageBranchName', null)
    .set('pageBranchId', null)
    .set('pageProtected', false)
    .set('pageTags', List())
}

function handleReceivePageNames(state: any, action: any) {
  const pageNames = action.payload.pageNames
  return state.set('pageNames', List(pageNames)).set('uuidToPaths', keyBy(pageNames, 'uuid'))
}

export function handleSaves(state: any, action: any) {
  const states = action.payload.saves

  return state.set(
    'currentSaves',
    Map({
      saves: states,
      isFetching: false,
    }),
  )
}

function handleReceiveCreateFolder(state: any, action: any) {
  const { folder } = action.payload
  return state.setIn(['folders', folder.id.toString()], fromJS(folder))
}

function handleReceiveMovePage(state: any, action: any) {
  const { page } = action.payload
  return state.setIn(['pages', page.uuid], fromJS(page))
}

function handleReceiveDeletePage(state: any, action: any) {
  const { page } = action.payload
  return state.deleteIn(['pages', page.uuid])
}

function handleReceiveBulkMovePages(state: any, action: any) {
  const { pages } = action.payload
  return pages.reduce((newState: any, page: any) => {
    return newState.setIn(['pages', page.uuid], fromJS(page))
  }, state)
}

function handleReceiveBulkDeletePages(state: any, action: any) {
  const { pages } = action.payload
  return pages.reduce((newState: any, page: any) => {
    return newState.deleteIn(['pages', page.uuid])
  }, state)
}

function handleReceiveDeleteFolder(state: any, action: any) {
  const { deletedFolderId } = action.payload
  return state.deleteIn(['folders', deletedFolderId.toString()])
}

function handleReceiveRenameFolder(state: any, action: any) {
  const { folder } = action.payload
  return state.setIn(['folders', folder.id.toString()], fromJS(folder))
}

function handleReceiveRenamePage(state: any, action: any) {
  const { page } = action.payload
  return state.setIn(['pages', page.uuid, 'name'], page.name)
}

function handleReceivePageDescriptionChange(state: any, action: any) {
  const { page } = action.payload
  return state.set('pageDescription', page.description).setIn(['pages', page.uuid, 'description'], page.description)
}

function handleReceiveProtectPage(state: any, action: any) {
  const { page, branchName } = action.payload
  return state.set('pageBranchName', branchName).setIn(['pages', page.uuid, 'protected'], true)
}

function handleReceiveUnprotectPage(state: any, action: any) {
  const { page, branchName } = action.payload
  return state.set('pageBranchName', branchName).setIn(['pages', page.uuid, 'protected'], false)
}

const PRESENTATION_MODE_HEARTBEAT_INTERVAL_MS = 60 * 1000
const EDITOR_MODE_HEARTBEAT_INTERVAL_MS = 5 * 1000

export function startUserHeartbeat() {
  return async (dispatch: any, getState: () => RetoolState) => {
    const existingIntervalId = getState().pages.get('heartbeatInterval')

    clearInterval(existingIntervalId)

    const editorMode = getState().pages.get('editorMode')

    const heartbeat = () => {
      const pages = getState().pages
      const pageUuid = pages.get('pageUuid')
      const mode = editorMode ? HEARTBEAT_MODE_EDITING : HEARTBEAT_MODE_VIEWING

      // No page uuid means we are not on an app page, so we don't update the heartbeat
      // we don't call clearInterval when we leave the app/editor, unfortunately :'(
      if (!pageUuid) return

      dispatch(
        callApi({
          endpoint: `/api/pages/uuids/${pageUuid}/updateUserHeartbeat`,
          method: 'POST',
          body: JSON.stringify({ mode, updateOnly: !editorMode }),
          headers: JSON_HEADERS,
          types: [
            PAGE_USER_HEARTBEATS_EVENT.REQUEST,
            PAGE_USER_HEARTBEATS_EVENT.SUCCESS,
            PAGE_USER_HEARTBEATS_EVENT.FAILURE,
          ],
        }),
      )
    }
    const newIntervalId = setInterval(
      heartbeat,
      editorMode ? EDITOR_MODE_HEARTBEAT_INTERVAL_MS : PRESENTATION_MODE_HEARTBEAT_INTERVAL_MS,
    )

    heartbeat()

    dispatch({
      type: SAVE_HEARTBEAT_INTERVAL,
      payload: newIntervalId,
    })
  }
}

function usersAreTheSame(prev: Iterable<ConnectedUser>, next: Iterable<ConnectedUser>) {
  const setA = new Set([...(prev ?? [])].map((user) => user.id))
  const setB = new Set([...(next ?? [])].map((user) => user.id))

  if (setA.size !== setB.size) return false
  for (const a of setA) {
    if (!setB.has(a)) return false
  }
  return true
}

type ConnectedUsersState = ImmutableMapType<{
  connectedEditors: Iterable<ConnectedUser>
  connectedViewers: Iterable<ConnectedUser>
}>

function handleReceivePageUserHeartbeats(state: ConnectedUsersState, action: any) {
  if (!action.payload) {
    // we only get back connectedUsers if we're in editor mode
    return state
  }

  const { connectedUsers } = action.payload

  const connectedEditors = connectedUsers.filter((user: ConnectedUser) => user.mode === HEARTBEAT_MODE_EDITING)
  const connectedViewers = connectedUsers.filter((user: ConnectedUser) => user.mode === HEARTBEAT_MODE_VIEWING)

  return state.withMutations((state: ConnectedUsersState) => {
    if (!usersAreTheSame(connectedEditors, state.get('connectedEditors'))) {
      state.set('connectedEditors', connectedEditors)
    }
    if (!usersAreTheSame(connectedViewers, state.get('connectedViewers'))) {
      state.set('connectedViewers', connectedViewers)
    }
  })
}

const handleSaveHeartbeatInterval = (state: any, action: any) => {
  return state.set('heartbeatInterval', action.payload)
}

function handleReceiveSave(state: any) {
  return state.set('pageCommit', null)
}

function handleReceiveCommitCreate(state: any, action: any) {
  return state.set('pageCommit', action.payload.commit.id)
}

const ACTION_HANDLERS: any = {
  [RECEIVE_PAGES_LOAD]: handlePagesLoad,
  [RECEIVE_PAGE_LOAD]: handlePageLoad,
  [FAILURE_PAGE_LOAD]: handlePageLoad,
  [RECEIVE_PAGE_NAMES]: handleReceivePageNames,
  [RECEIVE_PAGE_PUBLISH]: handlePagePublish,
  [RECEIVE_PAGE_UNPUBLISH]: handlePageUnpublish,
  [RECEIVE_PAGE_PASSWORD_EDIT]: handlePagePasswordEdit,
  [RECEIVE_PAGE_RENAME]: handleReceiveRenamePage,
  [RECEIVE_PAGE_DESCRIPTION_CHANGE]: handleReceivePageDescriptionChange,
  [RECEIVE_PAGE_PROTECT]: handleReceiveProtectPage,
  [RECEIVE_PAGE_UNPROTECT]: handleReceiveUnprotectPage,

  [RECEIVE_CREATE_FOLDER]: handleReceiveCreateFolder,

  [RECEIVE_COMMIT_CREATE]: handleReceiveCommitCreate,

  [RECEIVE_MOVE_PAGE]: handleReceiveMovePage,
  [RECEIVE_BULK_MOVE_PAGES]: handleReceiveBulkMovePages,
  [RECEIVE_BULK_DELETE_PAGES]: handleReceiveBulkDeletePages,

  [RECEIVE_DELETE_PAGE]: handleReceiveDeletePage,
  [RECEIVE_DELETE_FOLDER]: handleReceiveDeleteFolder,
  [RECEIVE_RENAME_FOLDER]: handleReceiveRenameFolder,

  [RECEIVE_SAVE]: handleReceiveSave,

  [REQUEST_SAVES]: (state: any) => state.setIn(['saves', 'isFetching'], true),
  [RECEIVE_SAVES]: handleSaves,
  [FAILURE_SAVES]: (state: any) => state.setIn(['saves', 'isFetching'], false),
  [TEARDOWN_PAGE]: handleTearDownPage,
  [GET_TAGS.SUCCESS]: (state: any, action: any) => state.set('pageTags', fromJS(action.payload.tags)),
  [RELEASE_TAG.SUCCESS]: (state: any, action: any) =>
    state.set('pageReleasedTagName', get(action, ['payload', 'tag', 'name'], 'latest')),
  [RELEASE_LATEST_TAG.SUCCESS]: (state: any) => state.set('pageReleasedTagName', 'latest'),
  [RECEIVE_UPDATE_PHOTO_URL]: (state: any, action: any) => {
    return state.mergeIn(['pages', action.payload.page.uuid], {
      photoUrl: action.payload.page.photoUrl,
      updatedAt: action.payload.page.updatedAt,
    })
  },

  [PAGE_USER_HEARTBEATS_EVENT.SUCCESS]: handleReceivePageUserHeartbeats,
  [SAVE_HEARTBEAT_INTERVAL]: handleSaveHeartbeatInterval,
}

// ------------------------------------
// Reducer
// ------------------------------------
//

type UserId = number

export interface ConnectedUser {
  id: UserId
  firstName: string
  lastName: string
  mode: string
  email: string
  updatedAt: moment.Moment
  profilePhotoUrl: string
}

const initialState = Map({
  pages: Map(),
  folders: Map(),
  pageNames: List(),
  pageTagName: null,
  pageBranchName: null,
  pageBranchId: null,
  pageCommit: null,
  pageTags: List(),
  editorMode: false,
  currentPage: '',
  pageUuid: '',
  currentSaves: Map({
    isFetching: false,
    saves: List(),
  }),
  uuidsToPaths: {},
  isFetchingPagesForFirstTime: true,
  nestedModules: {},

  heartbeatInterval: null,
  connectedEditors: [],
  connectedViewers: [],
})

export default function pagesReducer(state = initialState, action: any) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

import { DeepWriteable } from 'common/types'
import { PluginMethodConfig } from 'components/plugins'

const getCurrentPosition: PluginMethodConfig = {
  metadata: {
    label: 'Get current position',
    example: '`utils.getCurrentPosition({ onSuccess: (res) => void })`',
    description:
      'Returns a timestamp and an object containing the current lat/long location of the user. The onSuccess object argument is optional.',
  },
  method: (args = {}) => {
    // for turning 'position' instance into a serializable object
    // to pass in iframe.postMessage()
    const positionToObject = (pos: GeolocationPosition) => ({
      timestamp: pos.timestamp,
      coords: {
        latitude: pos.coords.latitude,
        longitude: pos.coords.longitude,
        speed: pos.coords.speed,
        accuracy: pos.coords.accuracy,
        altitude: pos.coords.altitude,
        altitudeAccuracy: pos.coords.altitudeAccuracy,
        heading: pos.coords.heading,
      },
    })

    return new Promise((resolve, reject) => {
      window.navigator.geolocation.getCurrentPosition((pos) => {
        const res = positionToObject(pos)
        resolve(res)
        ;(args as { onSuccess?: (pos: DeepWriteable<GeolocationPosition>) => void }).onSuccess?.(res)
      }, reject)
    })
  },
}

export default getCurrentPosition

import React from 'react'
import _ from 'lodash'

export type Range = [number, number]
export type MatchRanges = Range[]

const makeRanges = (matches: number[]) => {
  if (matches.length === 0) {
    return []
  }

  const ranges = [[matches[0], matches[0]]]

  for (let i = 1; i < matches.length; i++) {
    if (ranges[ranges.length - 1][1] + 1 === matches[i]) {
      ranges[ranges.length - 1][1]++
    } else {
      ranges.push([matches[i], matches[i]])
    }
  }

  return ranges as MatchRanges
}

/**
 * Searches the text for all of rawQuery to appear in order.
 *
 * e.g. 'a xyz b c' would match a query 'abc' or 'axbc', but not 'abcx'.
 *
 * @param {string} query - a string (case sensitive)
 * @param {string | null} text - text to search (case sensitive)
 *
 * @returns {MatchRanges} the ranges that match, or undefined if there is no match
 */
export const fuzzyFind: FinderType = (query, text) => {
  if (!text) return undefined

  let qi = 0
  const matchingIndices: number[] = []
  for (let i = 0; i < text.length; i++) {
    if (text.charAt(i) === query.charAt(qi)) {
      matchingIndices.push(i)
      qi++
    }
  }

  if (matchingIndices.length !== query.length) {
    return undefined
  }

  return makeRanges(matchingIndices)
}

/**
 * Searches the text for rawQuery.
 *
 * @param {string} query - a string (case sensitive)
 * @param {string | null} text - text to search (case sensitive)
 *
 * @returns {MatchRanges} the range that matches, or undefined if there is no match (an array to be compatible with `fuzzyFind`)
 */
export const fixedStringFind: FinderType = (query, text) => {
  if (!text) return undefined

  const index = text.indexOf(query)

  if (index >= 0) {
    return [[index, index + query.length - 1]]
  } else {
    return undefined
  }
}

export const doesMatch = (matches: MatchRanges | undefined): matches is MatchRanges => {
  return matches !== undefined
}

/**
 * "Magic" scoring - prioritizes matches being closer to the beginning and longer
 *
 * @param {MatchRanges | undefined} matches - the ranges that match, or undefined if there is no match
 *
 * @returns {number} the score for the given match
 */
export const scoreMatch = (matches: MatchRanges | undefined) => {
  if (!doesMatch(matches)) {
    return 0
  }
  const scoreRange = ([a, b]: Range) => {
    const len = b - a + 1
    // matches that are longer rank up
    const boosted = len * len * 10
    // matches earlier in the string rank up
    const proximity = 100.0 / (a + 1)
    return -(proximity + boosted * boosted)
  }
  const rangeScores = matches.map(scoreRange)
  return _.sum(rangeScores)
}

const fuzzyHighlight = (slab: string) => (
  <span key={slab} className="fuzzy-match-region">
    {slab}
  </span>
)

export const annotate = (
  text: string | null | undefined,
  matches: MatchRanges | undefined,
  highlight: (slab: string) => React.ReactElement = fuzzyHighlight,
): React.ReactChild[] => {
  if (!text) {
    return []
  } else if (!matches) {
    return [text]
  } else {
    const annotated = []
    let lastEnd = 0
    for (const i in matches) {
      const [start, end] = matches[i]
      annotated.push(text.slice(lastEnd, start))
      annotated.push(highlight(text.slice(start, end + 1)))
      lastEnd = end + 1
    }
    annotated.push(text.slice(lastEnd, text.length))
    return annotated
  }
}

export type Matchables = { [k: string]: string }

export type FuzzyFindInput<T> = [T, Matchables]

export type Matches = {
  [k: string]: MatchRanges
}

export type MatchesByKey = {
  [k: number]: Matches
}

export type ScoreWeights = {
  [k: string]: number
}

export type FuzzyFindResult<T> = [MatchesByKey, T[]]

export type FinderType = (query: string, text: string | null) => MatchRanges | undefined

/**
 * queries a corpus of objects for a particular search string using the same approach as `fzf`.
 * search is case sensitive; if you'd like it to be case insensitive, lower/upper-case the query
 * and data.
 *
 * in this code, `T` refers to the "data", but you can simply use a number if that suits your needs.
 *
 * @param {string} query - a string (case sensitive)
 * @param {(T) => number} keyExtractor - how you'd like your result keyed
 * @param {FuzzyFindInput<T>[]} data - the search corpus
 * @param {ScoreWeights} scoreWeighting - the weighting of fields (default is 100 unless specified)
 *
 * @returns {FuzzyFindResult<T>} the result of the fuzzy search
 */
export function fuzzyFindAll<T>(
  query: string,
  keyExtractor: (item: T) => number,
  data: FuzzyFindInput<T>[],
  scoreWeighting: ScoreWeights = {},
  fixedStringSearch = false,
): FuzzyFindResult<T> {
  // NB: semantically, the finder will return all results with an empty query.
  // this just saves us CPU/memory
  if (query.length === 0) {
    const items = data.map((tuple) => tuple[0])
    return [{}, items] as FuzzyFindResult<T>
  }

  const matches: MatchesByKey = {}
  const matchingItems: T[] = []

  const finder = fixedStringSearch ? fixedStringFind : fuzzyFind

  for (const i in data) {
    const [item, matchables] = data[i]
    const itemMatches = {} as Matches

    for (const key in matchables) {
      const contentMatches = finder(query, matchables[key])

      if (contentMatches) {
        itemMatches[key] = contentMatches
      }
    }

    if (!_.isEmpty(itemMatches)) {
      matchingItems.push(item)
      matches[keyExtractor(item)] = itemMatches
    }
  }

  const scoreItem = (item: T) => {
    const itemMatches = matches[keyExtractor(item)]
    let score = 0
    for (const key in itemMatches) {
      const weighting = scoreWeighting[key] || 100
      score += weighting * scoreMatch(itemMatches[key])
    }
    return score
  }

  const sortedMatchingItems = _.sortBy(matchingItems, scoreItem)
  return [matches, sortedMatchingItems]
}

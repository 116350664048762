import { RetoolDispatch } from 'store'

import { callInternalApi } from 'networking'

import {
  REQUEST_MARKETING_TEMPLATES_LOAD,
  RECEIVE_MARKETING_TEMPLATES_LOAD,
  FAILURE_MARKETING_TEMPLATES_LOAD,
} from './constants'

import { assertNever } from 'types/typeguards'

import {
  MarketingTemplateType,
  GetMarketingTemplatesRequestType,
  GetMarketingTemplatesReceiveType,
  GetMarketingTemplatesFailureType,
  MarketingTemplatesActionTypes,
  MarketingTemplatesReducerType,
} from './marketingTemplates.d'

// ------------------------------------
// Synchronous Actions
// ------------------------------------
export const getMarketingTemplatesRequest = (): GetMarketingTemplatesRequestType => {
  return {
    type: REQUEST_MARKETING_TEMPLATES_LOAD,
  }
}

export const getMarketingTemplatesReceive = (result: MarketingTemplateType[]): GetMarketingTemplatesReceiveType => {
  return {
    type: RECEIVE_MARKETING_TEMPLATES_LOAD,
    payload: result,
  }
}

export const getMarketingTemplatesFailure = (errorMessage: string): GetMarketingTemplatesFailureType => {
  return {
    type: FAILURE_MARKETING_TEMPLATES_LOAD,
    payload: {
      error: errorMessage,
    },
  }
}

// ------------------------------------
// Async Actions
// ------------------------------------
export const getMarketingTemplates = () => async (dispatch: RetoolDispatch) => {
  dispatch(getMarketingTemplatesRequest())
  try {
    const result = await callInternalApi({
      url: '/api/templates/marketingTemplates',
      method: 'GET',
    })
    dispatch(getMarketingTemplatesReceive(result))
  } catch (error) {
    dispatch(getMarketingTemplatesFailure(error.message))
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  marketingTemplates: [],
}

const marketingTemplatesReducer = (
  state: MarketingTemplatesReducerType = initialState,
  action: MarketingTemplatesActionTypes,
): MarketingTemplatesReducerType => {
  switch (action.type) {
    case REQUEST_MARKETING_TEMPLATES_LOAD:
    case FAILURE_MARKETING_TEMPLATES_LOAD: {
      return state
    }
    case RECEIVE_MARKETING_TEMPLATES_LOAD: {
      return {
        ...state,
        marketingTemplates: action.payload,
      }
    }
    default: {
      assertNever(action, false)
      return state
    }
  }
}

export default marketingTemplatesReducer

import { ResourceType } from 'common/resourceTypes'
import { Message } from 'components/design-system'
import Immutable, { fromJS, Record } from 'immutable'
import { JSON_HEADERS } from 'networking/util'
import { browserHistory } from 'react-router'
import { RESOURCES_URL_PREFIX } from 'store/constants'
import { CHECK_QUERY_AUTH, RetoolAPIDispatcher } from 'store/storeUtils'
import { callApi } from '../../../store/callApi'

// ------------------------------------
// Constants
// ------------------------------------
export const RESET_LOADED_RESOURCE = 'RESET_LOADED_RESOURCE'
export const REQUEST_RESOURCE_LOAD = 'REQUEST_RESOURCE_LOAD'
export const RECEIVE_RESOURCE_LOAD = 'RECEIVE_RESOURCE_LOAD'
export const FAILURE_RESOURCE_LOAD = 'FAILURE_RESOURCE_LOAD'
export const RESET_LOADED_RESOURCE_WITH_TYPE = 'RESET_LOADED_RESOURCE_WITH_TYPE'

export const REQUEST_GET_GRPC_SERVICE_NAMES = 'REQUEST_GET_GRPC_SERVICE_NAMES'
export const RECEIVE_GET_GRPC_SERVICE_NAMES = 'RECEIVE_GET_GRPC_SERVICE_NAMES'
export const FAILURE_GET_GRPC_SERVICE_NAMES = 'FAILURE_GET_GRPC_SERVICE_NAMES'

export const REQUEST_DELETE_SESSION_STATE_BY_KEY = 'REQUEST_DELETE_SESSION_STATE_BY_KEY'
export const RECEIVE_DELETE_SESSION_STATE_BY_KEY = 'RECEIVE_DELETE_SESSION_STATE_BY_KEY'
export const FAILURE_DELETE_SESSION_STATE_BY_KEY = 'FAILURE_DELETE_SESSION_STATE_BY_KEY'

export function resourceLoad(resourceName: any) {
  const endpoint = `/api/resources/names/${encodeURIComponent(resourceName)}`

  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        types: [REQUEST_RESOURCE_LOAD, RECEIVE_RESOURCE_LOAD, FAILURE_RESOURCE_LOAD],
      }),
    )

    if (result.type === 'FAILURE_RESOURCE_LOAD') {
      browserHistory.push(RESOURCES_URL_PREFIX)
    }

    return result
  }
}

export function resetLoadedResource() {
  return { type: RESET_LOADED_RESOURCE }
}

export function resetLoadedResourceWithType(type: any) {
  return { type: RESET_LOADED_RESOURCE_WITH_TYPE, payload: { type } }
}

export function getGrpcServiceNames() {
  return async (dispatch: any) => {
    await dispatch(
      callApi({
        endpoint: `/api/resources/getGRPCServiceNames`,
        method: 'GET',
        headers: JSON_HEADERS,
        types: [REQUEST_GET_GRPC_SERVICE_NAMES, RECEIVE_GET_GRPC_SERVICE_NAMES, FAILURE_GET_GRPC_SERVICE_NAMES],
      }),
    )
  }
}

export function deleteSessionStateByKey(resourceId: string, key: string) {
  return async (dispatch: any) => {
    const result = await dispatch(
      callApi({
        endpoint: `/api/resources/${resourceId}/sessionState`,
        method: 'DELETE',
        body: JSON.stringify({ key }),
        headers: JSON_HEADERS,
        types: [
          REQUEST_DELETE_SESSION_STATE_BY_KEY,
          RECEIVE_DELETE_SESSION_STATE_BY_KEY,
          FAILURE_DELETE_SESSION_STATE_BY_KEY,
        ],
      }),
    )
    if (result.type === RECEIVE_DELETE_SESSION_STATE_BY_KEY) {
      Message.success('Token removed')
    } else {
      Message.error('Could not remove token')
    }
    return result
  }
}

export type AuthRequiredResourcesType = {
  id: number
  name: string
  type: ResourceType
  options: {
    authentication: string
    reloginUrl: string
    attemptLogin: boolean
  }
}

export function testCheckQueryAuth(
  resourceName: string,
  environment: 'production' | 'staging',
): RetoolAPIDispatcher<typeof CHECK_QUERY_AUTH> {
  return async (dispatch) => {
    const result = await dispatch(
      callApi({
        endpoint: `/api/pages/uuids/testing/checkQueryAuth`,
        body: JSON.stringify({ resourceNames: [resourceName], environment, isTesting: true }),
        method: 'POST',
        headers: JSON_HEADERS,
        types: [CHECK_QUERY_AUTH.REQUEST, CHECK_QUERY_AUTH.SUCCESS, CHECK_QUERY_AUTH.FAILURE],
      }),
    )

    const {
      authRequiredResources,
      debugLog,
    }: { authRequiredResources: AuthRequiredResourcesType[]; debugLog: { [resourceId: string]: string[] } } =
      result?.payload || {}

    return { authRequiredResources, debugLog }
  }
}

// ------------------------------------
// Action Handlers
// ------------------------------------

function handleRequestResourceLoad(state: any) {
  return state
}

function handleReceiveResourceLoad(state: any, action: any) {
  return state.set('resourceByEnv', fromJS(action.payload.resourceByEnv))
}

function handleFailureResourceLoad(state: any) {
  return state
}

function handleResetLoadedResource(state: any) {
  return state.set('resourceByEnv', Immutable.Map())
}

function handleResetLoadedResourceWithType(state: any, action: any) {
  return state.set('resourceByEnv', Immutable.Map()).setIn(['resourceByEnv', 'production', 'type'], action.payload.type)
}

function handleRequestGetGrpcServiceNames(state: any) {
  return state.set('isFetchingGrpcServiceNames', true)
}

function handleReceiveGetGrpcServiceNames(state: any, action: any) {
  return state.set('isFetchingGrpcServiceNames', false).set('grpcServiceNames', action.payload)
}

function handleFailureGetGrpcServiceNames(state: any) {
  return state.set('isFetchingGrpcServiceNames', false).set('grpcServiceNames', null)
}

const ACTION_HANDLERS: any = {
  [REQUEST_RESOURCE_LOAD]: handleRequestResourceLoad,
  [RECEIVE_RESOURCE_LOAD]: handleReceiveResourceLoad,
  [FAILURE_RESOURCE_LOAD]: handleFailureResourceLoad,

  [RESET_LOADED_RESOURCE]: handleResetLoadedResource,

  [RESET_LOADED_RESOURCE_WITH_TYPE]: handleResetLoadedResourceWithType,

  [REQUEST_GET_GRPC_SERVICE_NAMES]: handleRequestGetGrpcServiceNames,
  [RECEIVE_GET_GRPC_SERVICE_NAMES]: handleReceiveGetGrpcServiceNames,
  [FAILURE_GET_GRPC_SERVICE_NAMES]: handleFailureGetGrpcServiceNames,
}

// ------------------------------------
// Reducer
// ------------------------------------
const defaultResourcesParams: any = {
  resourceByEnv: Immutable.Map({}),
  isFetchingGrpcServiceNames: false,
  grpcServiceNames: [],
}

class ResourcesModel extends Record(defaultResourcesParams) {}

const initialState = new ResourcesModel()

export default function resourcesReducer(state = initialState, action: any) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}

import React from 'react'

import { InputField } from './common'
import { ResourceFormProps } from './types'

const PrestoForm = (props: ResourceFormProps) => (
  <>
    <hr />
    <h5 className="section-heading light-gray mb12">General</h5>
    <div className="grid-1c mb20">
      <InputField
        label={<span className="resource-form__connection-string-label">Connection String</span>}
        placeholder="jdbc:presto://host:port/catalog/schema?user=test"
        resourceKey="connectionString"
        {...props}
      />
    </div>
  </>
)

export default {
  label: 'Presto',
  form: PrestoForm,
  defaults: {
    options: {
      connectionString: '',
    },
  },
}

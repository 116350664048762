import * as Immutable from 'immutable'
import uuidv4 from 'uuid/v4'

const GITHUB_URL = 'https://github.com'

export type GithubPullRequestOptions = {
  path: string
  pageName: string
  pageBranchName: string
  organizationGitBranch: string
  organizationGitOrg: string
  organizationGitRepo: string
  organizationGitUrl?: string
  changedPlugins?: Immutable.Set<string>
  initialCommit?: boolean
}

export function githubPullRequestUrl(options: GithubPullRequestOptions): string {
  const url = options.organizationGitUrl || GITHUB_URL
  const base = encodeURIComponent(options.organizationGitBranch || 'master')
  const compare = encodeURIComponent(options.pageBranchName)
  const org = encodeURIComponent(options.organizationGitOrg)
  const repo = encodeURIComponent(options.organizationGitRepo)
  const title = encodeURIComponent(
    `${options.initialCommit ? 'Initial commit for' : 'Update'} ${decodeURIComponent(options.pageName)}`,
  )

  let body
  if (options.initialCommit) {
    body = encodeURIComponent(
      `${
        `\n\n### Retool Initial Commit for ${options.pageName}\n` +
        `Preview here: ${window.location.origin}/apps/${options.path}\n`
      }`,
    )
  } else {
    const previewChangedComponents = options.changedPlugins
      ?.toJS()
      .filter((x) => !!x)
      .map((x) => `* Go to change: [${x}](${window.location.origin}/tree/${compare}/editor/${options.path}#goto:${x})`)
    body =
      previewChangedComponents &&
      encodeURIComponent(
        `${
          '\n\n### Retool Changes\n' +
          `Preview all changes here: ${window.location.origin}/tree/${compare}/apps/${options.path}\n`
        }${previewChangedComponents.join('\n')}`,
      )
  }

  return `${url}/${org}/${repo}/compare/${base}...${compare}?quick_pull=1&title=${title}${body && `&body=${body}`}`
}

export const getUserPrefix = (userEmail: string) => {
  return userEmail
    .split('@')[0]
    .toLowerCase()
    .replace(/[_:| ]+/g, '-')
    .replace(/[^a-zA-Z0-9-]+/g, '')
}

export function autogeneratedBranchName(userEmail: string): string {
  return `${getUserPrefix(userEmail)}/patch-${uuidv4().replace('-', '').slice(0, 4)}`
}

export const generateCommitUrl = (
  enterpriseUrl: string,
  repositoryOwner: string,
  repositoryName: string,
  gitSha: string,
): string => {
  const url = enterpriseUrl || GITHUB_URL
  const org = encodeURIComponent(repositoryOwner)
  const repo = encodeURIComponent(repositoryName)
  const sha = encodeURIComponent(gitSha)

  return `${url}/${org}/${repo}/commit/${sha}`
}

interface ValidationResult {
  valid: boolean
  message: string
}

const branchRegex = /^[a-zA-Z0-9/-]+$/

export function isValidBranchName(name: string | undefined): ValidationResult {
  if (!name || name.length === 0) {
    return { valid: false, message: `Branch name is required` }
  }
  if (name.startsWith('/') || name.endsWith('/')) {
    return { valid: false, message: `Branch name cannot start or end with '/'` }
  }
  if (name.startsWith('-')) {
    return { valid: false, message: `Branch name cannot start with '-'` }
  }
  if (name.includes('//')) {
    return {
      valid: false,
      message: `Branch name cannot contain multiple consecutive '/'`,
    }
  }
  if (!branchRegex.test(name)) {
    return {
      valid: false,
      message: `Branch name may only contain letters, numbers, -, and /`,
    }
  }
  return { valid: true, message: '' }
}

import React, { useState } from 'react'

import './CreateAppFromTemplateModal.scss'

import Modal from 'components/design-system/Modal'
import { MarketingTemplateType } from 'store/marketingTemplates.d'
import PickTemplateModalBody from './PickTemplateModalBody'
import EditTemplateModalBody from './EditTemplateModalBody'

type CreateAppFromTemplateModalProps = {
  visible: boolean
  closeModal: () => void
}

const PICK_TAB_KEY = 'pick'
const EDIT_TAB_KEY = 'edit'

function CreateAppFromTemplateModal(props: CreateAppFromTemplateModalProps) {
  const [activeTab, setActiveTab] = useState<string>(PICK_TAB_KEY)
  const [selectedTemplate, setSelectedTemplate] = useState<MarketingTemplateType>()

  const moveToEditTab = () => setActiveTab(EDIT_TAB_KEY)
  const moveToPickTab = () => setActiveTab(PICK_TAB_KEY)

  return (
    <Modal
      className="homepage-modal create-app-from-template-modal"
      title={
        <div className="flex items-center">
          <div>Create an app from a template</div>
        </div>
      }
      destroyOnClose
      visible={props.visible}
      width="80vw"
      style={{ maxWidth: 1100, minWidth: 560 }}
      onCancel={props.closeModal}
      footer={null}
    >
      {activeTab === PICK_TAB_KEY && (
        <PickTemplateModalBody
          selectedTemplate={selectedTemplate}
          onSelectedTemplateChange={setSelectedTemplate}
          onNext={moveToEditTab}
        />
      )}
      {activeTab === EDIT_TAB_KEY && (
        <EditTemplateModalBody
          selectedTemplate={selectedTemplate!}
          onBack={moveToPickTab}
          closeModal={props.closeModal}
        />
      )}
    </Modal>
  )
}

export default CreateAppFromTemplateModal

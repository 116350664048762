import * as React from 'react'

import './AuthLayout.scss'

const AuthLayout = (props: any) => (
  <span>
    <div className="auth-container">
      <div className="auth-form-container">{props.top}</div>
    </div>
    <div className="other-view-div">
      <div>{props.bottom}</div>
    </div>
  </span>
)

export default AuthLayout

import { Button as AntdButton, message, Popconfirm } from 'antd'
import { retoolAnalyticsTrack } from 'common/retoolAnalytics'
import { Button, Icon } from 'components/design-system'
import { StackedProfilePhotos } from 'components/standards/ProfilePhoto'
import { callInternalApi } from 'networking'
import * as React from 'react'
import AuthRetoolLogo from 'routes/Login/components/AuthRetoolLogo'
import { dispatch } from 'store'
import { handleAuthResponse } from 'store/session'
import './JoinOrgPage.scss'

type Props = {}

interface JoinableOrg {
  orgName: string
  memberCount: number
  users: User[]
  organizationId: number
}

type OrgClaimingDomain = JoinableOrg & { joinToken: string; allowAutoJoin: boolean }
type OrgWithInvite = JoinableOrg & { signupToken: string }

type JoinableOrgsPayload = {
  userEmailDomain: string
  userEmail: string
  orgsClaimingDomain: OrgClaimingDomain[]
  invites: OrgWithInvite[]
}

type LoadingError = { statusCode: number; message: string }

const JoinOrgPage = (_props: Props) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [, setLoading] = React.useState<boolean>(false)
  const [loadingError, setLoadingError] = React.useState<LoadingError | null>(null)
  const [serverPayload, setServerPayload] = React.useState<JoinableOrgsPayload | null>(null)
  const [domainRequestedToJoin, setDomainRequestedToJoin] = React.useState<string | null>(null)

  let userEmailDomain: null | string = null,
    orgsClaimingDomain: OrgClaimingDomain[] = [],
    invites: OrgWithInvite[] = [],
    userEmail = ''
  if (serverPayload) {
    ;({ userEmailDomain, orgsClaimingDomain, invites, userEmail } = serverPayload)
  }
  React.useEffect(() => {
    setLoading(true)
    callInternalApi({
      method: 'GET',
      url: '/api/joinableOrgs',
    })
      .then((payload: JoinableOrgsPayload) => {
        setServerPayload(payload)
        return
      })
      .catch((err: LoadingError) => {
        setLoadingError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])
  const orgCount = invites.length + orgsClaimingDomain.length
  return (
    <div className="join-org-page">
      <div className="body-inner">
        <div>
          <div className="auth-container">
            <div className="auth-form-container">
              <AuthRetoolLogo className="mb24" />
              {loadingError != null ? (
                <div>An unexpected error occurred loading the organizations you can join: {loadingError.message}</div>
              ) : domainRequestedToJoin ? (
                <>
                  <h3 data-testid="submitted-header" className="mb10">
                    Request sent 🎊
                  </h3>
                  <div>
                    We have sent your request to the admins of <strong>{domainRequestedToJoin}</strong>. Once approved,
                    you will get an email with an invitation link to join.
                  </div>
                </>
              ) : (
                <>
                  <h3 className="mb10">Select a team to join</h3>
                  <div className="mb12 gray fs-13 join-org-page__instructions">
                    There {orgCount === 1 ? 'is one organization' : `are ${orgCount} organizations`} linked to the email
                    domain <strong>{userEmailDomain}</strong>. Select which one you want to join:
                  </div>
                  <div className="orgs-container">
                    <>
                      {invites.map((org) => (
                        <JoinableOrg
                          key={org.signupToken}
                          orgName={org.orgName}
                          memberCount={org.memberCount}
                          users={org.users}
                          isInvite={true}
                        >
                          <Button
                            data-testid="claim-invite-button"
                            type="primary"
                            disabled={isSubmitting}
                            onClick={() => {
                              setIsSubmitting(true)
                              callInternalApi({
                                method: 'POST',
                                url: '/api/claimInvitationAsPartiallyRegisteredUser',
                                body: {
                                  signupToken: org.signupToken,
                                },
                              })
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                .then((payload: any) => {
                                  retoolAnalyticsTrack('Invitation Claimed', {
                                    organizationId: org.organizationId,
                                    userEmail,
                                  })
                                  return dispatch(handleAuthResponse(payload))
                                })
                                .catch(() => {
                                  message.error('An unexpected error occurred while joining this organization')
                                })
                                .finally(() => {
                                  setIsSubmitting(false)
                                })
                            }}
                          >
                            Join now <Icon className="ml4" type="arrow-right" />
                          </Button>
                        </JoinableOrg>
                      ))}
                    </>
                    <>
                      {orgsClaimingDomain.map((org) => (
                        <JoinableOrg
                          key={org.joinToken}
                          orgName={org.orgName}
                          memberCount={org.memberCount}
                          users={org.users}
                          isInvite={false}
                        >
                          <Button
                            data-testid="request-to-join-button"
                            type={org.allowAutoJoin ? 'primary' : 'default'}
                            disabled={isSubmitting}
                            onClick={() => {
                              setIsSubmitting(true)
                              callInternalApi({
                                method: 'POST',
                                url: '/api/requestToJoin',
                                body: {
                                  joinToken: org.joinToken,
                                },
                              })
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                .then((payload: any) => {
                                  const analyticsEvent = org.allowAutoJoin
                                    ? 'Join Auto Join Org'
                                    : 'Request To Join Org'
                                  retoolAnalyticsTrack(analyticsEvent, {
                                    organizationId: org.organizationId,
                                    userEmail,
                                  })
                                  if (org.allowAutoJoin) {
                                    return dispatch(handleAuthResponse(payload))
                                  }
                                  setDomainRequestedToJoin(org.orgName)
                                  return
                                })
                                .catch(() => {
                                  message.error(
                                    'An unexpected error occurred while requesting to join this organization',
                                  )
                                })
                                .finally(() => {
                                  setIsSubmitting(false)
                                })
                            }}
                          >
                            {org.allowAutoJoin ? 'Join now' : 'Request'} <Icon className="ml4" type="arrow-right" />
                          </Button>
                        </JoinableOrg>
                      ))}
                    </>
                  </div>
                  <div className="mt12 gray fs-13">
                    No, I want to{' '}
                    <Popconfirm
                      title="Are you sure you want to create a new organization instead of joining one of the listed organizations?"
                      trigger="click"
                      placement="bottom"
                      disabled={isSubmitting}
                      onConfirm={() => {
                        setIsSubmitting(true)
                        const partialRegistrationId = undefined
                        return (
                          callInternalApi({
                            method: 'POST',
                            url: '/api/createOrgFromPartialRegistration',
                            body: { partialRegistrationId }, // TODO fix this
                          })
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            .then((payload: any) => {
                              retoolAnalyticsTrack('Create org from partial registration', {
                                userEmail,
                                emailVerified: true,
                              })
                              return dispatch(handleAuthResponse(payload))
                            })
                            .catch(() => {
                              message.error('An unexpected error occurred while creating a new organization')
                            })
                            .finally(() => {
                              setIsSubmitting(false)
                            })
                        )
                      }}
                    >
                      <AntdButton
                        type="link"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        style={{ padding: 0, fontSize: 'inherit', fontWeight: 'inherit' }}
                      >
                        create a new organization
                      </AntdButton>
                    </Popconfirm>
                    .
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

type User = {
  firstName: string | null
  lastName: string | null
  profilePhotoUrl: string | null
}

type JoinableOrgProps = {
  orgName: string
  memberCount: number
  users: User[]
  children: React.ReactNode
  isInvite: boolean
}

const JoinableOrg = ({ isInvite, orgName, memberCount, users, children }: JoinableOrgProps) => (
  <div className="joinable-org-row mb10">
    <div className="joinable-org-row__content">
      <div className="joinable-org-row__left-side">
        <div className="joinable-org-row__basic-info">
          <div className="org-name">{orgName}</div>
          {/* TODO handle "auto add" in the membership count line */}
          <div className="member-invited-wrapper">
            <div className="membership-count">{memberCount === 1 ? '1 member' : `${memberCount} members`}</div>
            {isInvite && (
              <div className="invited-tag" data-testid="invited-tag">
                Invited
              </div>
            )}
          </div>
        </div>
        <div className="joinable-org-row__membership">
          <StackedProfilePhotos maxAvatarsToShow={4} users={users} />
        </div>
      </div>
      <div className="joinable-org-row__right-side">
        <div className="joinable-org-row__cta">{children}</div>
      </div>
    </div>
  </div>
)

export default JoinOrgPage

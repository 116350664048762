import { JiraResourceSettings } from 'common/records'
import { USER_CALLBACK_URL } from 'components/ResourceForm/oauthForm'
import { generateResourceForm } from 'components/ResourceForm/resourceFormGenerator/generator'
import { AuthTemplate } from 'components/ResourceForm/resourceFormGenerator/generator-types'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'
import React from 'react'

const JIRA_AUTH_URL = 'https://auth.atlassian.com/authorize'
const JIRA_TOKEN_URL = 'https://auth.atlassian.com/oauth/token'

const defaultOptions: JiraResourceSettings = {
  options: {
    spec: `${RETOOL_SPECS_DOMAIN}/atlassian/jira/3.0.1/1001.0.0-SNAPSHOT.json`,
    authentication: 'oauth2',
    oauth2_client_id: '',
    oauth2_client_secret: '',
    oauth2_callback_url: USER_CALLBACK_URL,
    oauth2_auth_url: JIRA_AUTH_URL,
    oauth2_access_token_url: JIRA_TOKEN_URL,
    oauth2_share_user_credentials: false,
    oauth2_scope:
      'read:jira-user read:jira-work manage:jira-project manage:jira-configuration write:jira-work manage:jira-data-provider',
    oauth2_audience: 'api.atlassian.com',
  },
}

const auth: AuthTemplate = [
  {
    type: 'oauth2',
    options: {
      retoolHostedApp: {},
      customApp: {
        description: (
          <>
            To connect Retool to Jira create an app in the{' '}
            <a target="_blank" href="https://developer.atlassian.com/console/myapps/" rel="noopener noreferrer">
              Jira Developer Console
            </a>
            .
          </>
        ),
      },
    },
  },
]

export default generateResourceForm(
  'Jira',
  defaultOptions,
  {
    auth,
    isOAuth: (resource) => resource.options?.authentication === 'oauth2',
    hideTestConnection: () => true,
  },
  true,
)

import React from 'react'
import { RETOOL_SPECS_DOMAIN } from 'retoolConstants'
import { generateResourceForm } from 'components/ResourceForm/resourceFormGenerator/generator'
import { AuthTemplate } from 'components/ResourceForm/resourceFormGenerator/generator-types'
import { SHARED_OAUTH_CALLBACK_URL } from 'components/ResourceForm/oauthForm'
import { transformHeadersForEditor } from '../../components/AuthTriggerInput'
import { defaultShowConnectedApp } from 'components/ResourceForm/resourceFormGenerator/OAuth2View'
import { FrontResourceSettings } from 'common/records'

const FRONT_AUTH_URL = 'https://app.frontapp.com/oauth/authorize'
const FRONT_TOKEN_URL = 'https://app.frontapp.com/oauth/token'

const defaultOptions: FrontResourceSettings = {
  options: {
    customQueryParameters: [['', '']],
    customHeaders: [['Authorization', 'Bearer OAUTH2_TOKEN']],
    authentication: 'oauth2',
    oauth2_client_id: '',
    oauth2_client_secret: '',
    oauth2_scope: '',
    oauth2_callback_url: SHARED_OAUTH_CALLBACK_URL,
    oauth2_auth_url: FRONT_AUTH_URL,
    oauth2_access_token_url: FRONT_TOKEN_URL,
    oauth2_share_user_credentials: true,
    spec: `${RETOOL_SPECS_DOMAIN}/front/3.0.0/1.0.0.json`,
    verify_session_action_enabled: true,
    verify_session_action: {
      query: 'https://api2.frontapp.com/events?limit=0',
      method: 'GET',
      type: 'http_request',
      runWhenModelUpdates: false,
      bodyType: 'raw',
      headers: transformHeadersForEditor([['Authorization', 'Bearer OAUTH2_TOKEN']]),
    },
  },
}

const auth: AuthTemplate = [
  {
    type: 'oauth2',
    options: {
      retoolHostedApp: {},
      customApp: {
        description: (
          <>
            To connect Retool to Front create a connected app in your Front account for Retool.{' '}
            <a target="_blank" href="https://dev.frontapp.com/docs/oauth" rel="noopener noreferrer">
              Here is some information on how to create an application in Front
            </a>
          </>
        ),
      },
      isShareableAcrossUsers: true,
    },
  },
]

export default generateResourceForm('Front', defaultOptions, {
  auth,
  hideTestConnection: () => true,
  hideCreateResourceButton: () => defaultShowConnectedApp(),
  isOAuth: () => true,
})

import { ButtonProps as AntButtonProps } from 'antd/lib/button/button'
import { Form } from 'antd'
import { Alert, Message } from 'components/design-system'
import Button from 'components/design-system/Button'
import Modal from 'components/design-system/Modal'
import React, { useState } from 'react'
import './AppProtectionModal.scss'

type WidgetType = 'module' | 'application'

export type AppProtectionModalProps = {
  visible: boolean
  closeModal: () => void
  page: {
    name: string
    protected: boolean
    releasedTagId: string
    uuid: string
  }
  widgetType?: WidgetType
} & {
  protectPage?: (pageUuid: string) => void
  unprotectPage?: (pageUuid: string) => void
}

const AppProtectionModal: React.FC<AppProtectionModalProps> = ({
  visible,
  page,
  protectPage,
  unprotectPage,
  closeModal,
  widgetType = 'application',
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const protect = async () => {
    try {
      setIsSubmitting(true)
      await protectPage?.(page.uuid)
      closeModal()
    } catch (err) {
      return Message.error(err.message)
    } finally {
      setIsSubmitting(false)
    }
  }

  const unprotect = async () => {
    try {
      setIsSubmitting(true)
      await unprotectPage?.(page.uuid)
      closeModal()
    } catch (err) {
      return Message.error(err.message)
    } finally {
      setIsSubmitting(false)
    }
  }

  let modalTitle = `Protect the ${page.name} ${widgetType}`
  let buttonHandler = protect
  let buttonText = `Add protection`
  let buttonType: AntButtonProps['type'] = 'primary'
  let body = (
    <>
      <p>
        Once you protect this {widgetType}, changes are made on branches and must be reviewed and merged via GitHub pull
        requests.
      </p>
      {page.releasedTagId && (
        <div className="mt16">
          <Alert
            message={
              <span>
                The published release for this app will be <b>unset</b>. The most recent change will instead be visible
                to all users.
              </span>
            }
            type="warning"
          />
        </div>
      )}
      <div className="mt16">
        <Alert
          message={
            <span>
              Protected {widgetType}s or folders that contain protected applications/modules <b>cannot</b> be renamed,
              moved, or deleted. You can unprotect this {widgetType} anytime.
            </span>
          }
          type="info"
        />
      </div>
    </>
  )

  if (page.protected) {
    modalTitle = `Unprotect the ${page.name} ${widgetType}`
    buttonHandler = unprotect
    buttonText = 'Remove protection'
    buttonType = 'danger'
    body = (
      <>
        <p>
          Once you unprotect this {widgetType}, new changes can be made directly within Retool, without reviewing and
          merging via GitHub pull requests.
        </p>
        <div className="mt16">
          <Alert
            message={
              <span>
                Unprotected {widgetType}s <b>can</b> be renamed, moved, or deleted, unless they are contained in a
                folder with another protected application or module.
              </span>
            }
            type="warning"
          />
        </div>
      </>
    )
  }

  return (
    <Modal
      destroyOnClose
      className="app-protection-modal"
      title={modalTitle}
      width={520}
      visible={visible}
      onCancel={closeModal}
      footer={null}
    >
      <Form id="appProtectionForm">
        {body}
        <div className="flex mt24 justify-center">
          <Button
            type={buttonType}
            htmlType="submit"
            className="protect-app-modal__submit-button"
            form="appProtectionForm"
            onClick={buttonHandler}
            loading={isSubmitting}
          >
            {buttonText}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default AppProtectionModal

import { fromJS } from 'immutable'
import { RetoolAction, RetoolDispatch } from 'store'
import { GlobalWidgetType, ModulesReducerModel } from 'common/records'
import { FAILURE_MODULES, RECEIVE_MODULES, REQUEST_MODULES, STORE_MODULE_TEMPLATES } from 'store/constants'
import { callInternalApi } from 'networking'

import {
  FetchGlobalWidgetsRequestType,
  FetchGlobalWidgetsReceiveType,
  FetchGlobalWidgetsFailureType,
} from './modules.d'

const fetchModulesRequest = (): FetchGlobalWidgetsRequestType => {
  return {
    type: REQUEST_MODULES,
  }
}

const fetchModulesReceive = (result: { globalWidgets: GlobalWidgetType[] }): FetchGlobalWidgetsReceiveType => {
  return {
    type: RECEIVE_MODULES,
    payload: result,
  }
}

const fetchModulesFailure = (errorMessage: string): FetchGlobalWidgetsFailureType => {
  return {
    type: FAILURE_MODULES,
    payload: {
      error: errorMessage,
    },
  }
}

export const fetchAvailableModules = () => async (dispatch: RetoolDispatch) => {
  dispatch(fetchModulesRequest())
  try {
    const result = await callInternalApi({
      url: '/api/pages/globalWidgets',
      method: 'GET',
    })
    dispatch(fetchModulesReceive(result))
  } catch (error) {
    dispatch(fetchModulesFailure(error.message))
  }
}

type ModulesReducerHandler = (state: ModulesReducerModel, action: RetoolAction) => ModulesReducerModel

const handleRequestModules: ModulesReducerHandler = (state, _action) => {
  return state.setIn(['availableModules', 'isFetching'], true)
}

const handleReceiveModules: ModulesReducerHandler = (state, action) => {
  if (action?.payload?.globalWidgets) {
    return state
      .setIn(['availableModules', 'modules'], fromJS(action.payload.globalWidgets))
      .setIn(['availableModules', 'isFetching'], false)
  } else {
    return state.setIn(['availableModules', 'isFetching'], false)
  }
}

const handleFailureModules: ModulesReducerHandler = (state, _action) => {
  return state.setIn(['availableModules', 'isFetching'], false)
}

const handlStoreModulesTemplates: ModulesReducerHandler = (state, action) => {
  return state.set('templates', {
    ...state.get('templates'),
    ...action.payload.modules,
  })
}

const initialState = new ModulesReducerModel()
export default function modulesReducer(state = initialState, action: RetoolAction): ModulesReducerModel {
  const HANDLERS: { [action: string]: ModulesReducerHandler } = {
    [REQUEST_MODULES]: handleRequestModules,
    [RECEIVE_MODULES]: handleReceiveModules,
    [FAILURE_MODULES]: handleFailureModules,
    [STORE_MODULE_TEMPLATES]: handlStoreModulesTemplates,
  }

  const handler = HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

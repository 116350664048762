import { TypedMap } from 'common/utils/immutable'
import { useDocsModel } from 'documentation/containers/docsContext'
import { useSelector } from 'react-redux'
import { RetoolState } from 'store'
import { appModelValuesSelector } from 'store/selectors'
import { WidgetModel, WidgetTemplateConfig } from './types'

export const getModel = <ModelShape = {}>(
  state: RetoolState,
  id: string,
  instance?: number,
): TypedMap<ModelShape> | undefined => {
  const modelValues = appModelValuesSelector(state)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const model = modelValues.getPlugin(id) as any
  return instance == null ? model : model.get(instance)
}

export default function useModel<C extends WidgetTemplateConfig = {}>(
  id: string,
  instance?: number,
): TypedMap<WidgetModel<C>> {
  const docsModel = useDocsModel<WidgetModel<C>>()
  const model = useSelector((state: RetoolState) => getModel<WidgetModel<C>>(state, id, instance))

  if (docsModel) {
    // use the model from context on the components docs page
    return docsModel
  }

  if (!model) {
    throw new Error(`The model for ${id} was not found.`)
  }

  return model
}

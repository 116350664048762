import { convertToParameters } from 'common/evaluator'
import { isArray } from 'util'

export type SqlQueryTemplate = {
  query: string
  databaseNameOverride: string
  databaseUsernameOverride: string
  databasePasswordOverride: string
  shouldEnableBatchQuerying?: boolean // this is only for mssql
  shouldUseLegacySql?: boolean // this is only for big query
}

const getQueryParams = async (template: SqlQueryTemplate, model: any) => {
  const queryParams = await convertToParameters(template.query || '', model)
  const databaseNameOverrideParams = await convertToParameters(template.databaseNameOverride || '', model)
  const databaseUsernameOverrideParams = await convertToParameters(template.databaseUsernameOverride || '', model)
  const databasePasswordOverrideParams = await convertToParameters(template.databasePasswordOverride || '', model)
  const params = {
    queryParams,
    databaseNameOverrideParams,
    databaseUsernameOverrideParams,
    databasePasswordOverrideParams,
  }

  return params
}

export const getPreviewParams = async (template: SqlQueryTemplate, model: any) => {
  const userParams = await getQueryParams(template, model)

  return { queryTemplate: template, userParams }
}

export const getRunQueryParams = async (template: SqlQueryTemplate, model: any) => {
  const userParams = await getQueryParams(template, model)

  return userParams
}

export const formatResult = (result: any) => {
  if (isArray(result)) {
    const data = result[0]
    const dataArray = result
    return { data, dataArray }
  } else {
    return {
      data: result,
      dataArray: [result],
    }
  }
}

import React from 'react'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { verify2FAChallenge } from 'store/user'
import AuthLayout from 'components/AuthLayout/AuthLayout'
import Setup2FA from 'components/Setup2FA'

class TwoFactorAuthChallenge extends React.Component<any, any> {
  state = { code: '' }
  confirmCode = async () => {
    this.props.verify2FAChallenge(this.state.code.replace(' ', ''))
  }
  render() {
    return (
      <div className="body-inner">
        <AuthLayout top={<Setup2FA afterSuccess={() => browserHistory.push('/')} />} />
      </div>
    )
  }
}

const mapStateToProps = () => ({})
const mapDispatchToProps = {
  verify2FAChallenge,
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuthChallenge)

import React from 'react'

import { InputField } from './common'
import { ResourceFormProps } from './types'

const SlackForm = (props: ResourceFormProps) => (
  <div className="grid-1c mb20">
    <InputField
      label="Webhook URL"
      required
      placeholder="https://hooks.slack.com/services/my-incoming-webhook"
      resourceKey="slack_webhook_url"
      {...props}
    />
    <div className="grid-offset-1">
      <a rel="noopener noreferrer" target="_blank" href="https://my.slack.com/apps/new/A0F7XDUAZ-incoming-webhooks">
        {' '}
        Create a Slack webhook
      </a>{' '}
      and then paste it in the webhook URL above.
    </div>
  </div>
)

export default {
  label: 'Slack',
  form: SlackForm,
  defaults: {
    options: {
      slack_webhook_url: '',
    },
  },
  validator: (resource: any) => resource.options.slack_webhook_url,
}

import { ResourceType } from 'common/resourceTypes'
import React from 'react'
import { IS_ON_PREM } from 'retoolConstants'

const hostnameNotFoundErrMessage = `The hostname you provided doesn't work. Perhaps there's a typo, or it's not a public hostname? (e.g. behind a VPC)`
const sshCredentialsFailedMessage = `Retool reached the SSH host, but couldn't authenticate. Make sure you created a 'retool' user, and added Retool's public key.`
const maybeFirewalledMessage = `Try checking your database firewall configuration and whitelisting Retool's IP Address: 52.175.251.223`
const pgSslOffMessage = `Try connecting with SSL on. If you're currently using a connection string, add '?ssl=true' to the end of the string.`
const getDuplicateLabelMessage = (name: string) =>
  `You already have a resource named "${name}". Change your resource's label to something else.`

type Props = {
  errMsg: string
  resourceName: string
  resourceType: ResourceType
}
export default function getResourceErrorTip({ errMsg, resourceName }: Props): JSX.Element | null {
  errMsg = errMsg || ''
  if (errMsg.includes('getaddrinfo ENOTFOUND')) {
    return <>{hostnameNotFoundErrMessage}</>
  }
  if (errMsg.includes('All configured authentication methods failed')) {
    return <>{sshCredentialsFailedMessage}</>
  }
  if (errMsg.includes('connect EHOSTUNREACH')) {
    return <>{maybeFirewalledMessage}</>
  }
  if (errMsg.includes('connect ECONNREFUSED') && !IS_ON_PREM) {
    return <>{maybeFirewalledMessage}</>
  }
  if (errMsg.startsWith('connect ETIMEDOUT')) {
    return <>{maybeFirewalledMessage}</>
  }
  if (errMsg.startsWith('no pg_hba.conf entry')) {
    return <>{pgSslOffMessage}</>
  }
  if (errMsg.startsWith('Validation error: organizationId')) {
    return <>{getDuplicateLabelMessage(resourceName)}</>
  }
  return null
}

import moment from 'moment'

import React from 'react'

import { browserHistory, Link, withRouter } from 'react-router'
import { connect, useSelector } from 'react-redux'
import classNames from 'classnames'

import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import { annotate, Matches } from 'components/FuzzyFind'
import { showConfirm } from 'components/standards/Modal'
import { Button, Checkbox, Icon } from 'components/design-system'
import { Menu } from 'antd'
import { Dropdown, MenuLink } from 'components/design-system/Dropdown'

import { AccessLevel } from '__globalShared__/permissions'

import { hasPresentationModeFeatureSelector } from 'common/paymentPlans'
import { Page } from 'common/records'
import { deletePage, movePage } from 'store/appModel/pages'
import { archiveFolderSelector, FolderWithChildren, isAdminSelector, LastPageEdit } from 'store/selectors'
import { favoritePage, unfavoritePage } from 'store/user'
import { exportSave } from 'store/appModel/template'
import { RetoolState } from 'store'
import { getLink } from 'common/utils'

import { fuzzyHighlight } from '../utilities/text'

import './ApplicationListing.scss'

import ProtectedIcon from '../icons/ProtectedIcon'
import Favorite from '../controls/Favorite'
import EditApplicationDropdown from './EditApplicationDropdown'
import RunApplicationDropdown from './RunApplicationDropdown'
import useConditionallyShownPopover from 'common/hooks/useConditionallyShownPopover'
import { pageFavoritesSelector } from 'store/selectors/userSelectors'

const navigateTo = (route: string) => () => browserHistory.push(route)

const isWrite = (accessLevel: AccessLevel) => accessLevel === 'own' || accessLevel === 'write'
const isRead = (accessLevel: AccessLevel) => isWrite(accessLevel) || accessLevel === 'read'

const mapDispatchToProps = {
  deletePage,
  movePage,
  favoritePage,
  unfavoritePage,
  exportSave,
}

const mapStateToProps = (state: RetoolState) => {
  return {
    hasPresentationModeFeature: hasPresentationModeFeatureSelector(state),
    favoritePageIds: pageFavoritesSelector(state),
    archiveFolder: archiveFolderSelector(state),
  }
}

export type Props = {
  isTrash: boolean
  pageEditedBy: LastPageEdit
  selectedPageIdsHook: [number[], (ids: number[]) => void]
  setPageToShowPermissions: (page: Page | null) => void
  setPageToBeRenamed: (page: Page | null) => void
  setPageToBeMoved: (page: Page | null) => void
  setPageToBeCloned: (page: Page | null) => void
  setPageToBeProtected: (page: Page | null) => void
  setPageToBeUnprotected: (page: Page | null) => void
  matches: Matches | undefined
  hasPresentationModeFeature: boolean
  movePage: (pageId: number, folderId: number) => Promise<void>
  archiveFolder: FolderWithChildren | null
  deletePage: (pageId: number) => Promise<any>
  exportSave: (pageName: string, pageUuid: string, downloadJson: boolean, isGlobalWidget: boolean) => Promise<any>
  favoritePageIds: number[]
  favoritePage: (pageId: number) => Promise<any>
  unfavoritePage: (pageId: number) => Promise<any>
  record: Page
  protectedAppsHasEnvVarsSet: boolean
}

const ApplicationItem = (props: Props) => {
  const {
    isTrash,
    pageEditedBy,
    selectedPageIdsHook,
    setPageToShowPermissions,
    setPageToBeRenamed,
    setPageToBeMoved,
    setPageToBeCloned,
    setPageToBeProtected,
    setPageToBeUnprotected,
    matches,
    hasPresentationModeFeature,
    movePage,
    archiveFolder,
    deletePage,
    exportSave,
    favoritePageIds,
    favoritePage,
    unfavoritePage,
    record,
    protectedAppsHasEnvVarsSet,
  } = props
  const isModule = record.isGlobalWidget
  const { showPopoverTriggers, parentHandlers, popoverProps } = useConditionallyShownPopover()

  const [selectedPageIds, setSelectedPageIds] = selectedPageIdsHook
  const selectionMode = selectedPageIds.length > 0

  const addSelectedPageId = () => setSelectedPageIds(selectedPageIds.concat([record.id]))
  const removeSelectedPageId = () => setSelectedPageIds(selectedPageIds.filter((id) => id !== record.id))

  const pageSelected = selectedPageIds.includes(record.id)
  const isAdmin = useSelector(isAdminSelector)

  const toggleMembershipInSelectedPageIds = () => {
    if (pageSelected) {
      removeSelectedPageId()
    } else {
      addSelectedPageId()
    }
  }

  const accessLevel = record.accessLevel
  const editAccess = isWrite(accessLevel)
  const endUserAccess = isRead(accessLevel)

  const endUserLink = getLink('read', record.path, hasPresentationModeFeature)
  const editLink = getLink('write', record.path, hasPresentationModeFeature)

  let trash: React.ReactElement = (
    <MenuLink
      className={record.protected ? undefined : 'red'}
      disabled={record.protected}
      onSelected={() => movePage(record.id, archiveFolder!.id)}
      label="Move to trash"
    />
  )
  if (isTrash) {
    const confirmDeletion = async (recordId: number) => {
      showConfirm({
        title: `Are you sure you want to delete this application?`,
        okText: 'Delete',
        autoFocusButton: 'ok',
        okButtonProps: {
          className: 'btn-danger',
        },
        content: (
          <div>
            This action is <strong>permanent</strong>! It cannot be undone.
          </div>
        ),
        onOk: async (close: () => void) => {
          await deletePage(recordId)
          close()
        },
      })
    }

    trash = <MenuLink className="red fw-600" onSelected={() => confirmDeletion(record.id)} label="Delete permanently" />
  }

  let controls = <></>

  if (!selectionMode) {
    let endUser = <></>
    if (endUserAccess) {
      if (record.protected) {
        endUser = <RunApplicationDropdown endUserLink={endUserLink} page={record} {...popoverProps} />
      } else {
        endUser = (
          <Button className="run-action ml8" onClick={navigateTo(endUserLink)}>
            <Icon type="run" />
          </Button>
        )
      }
    }

    let edit = <></>
    if (editAccess) {
      if (record.protected) {
        edit = <EditApplicationDropdown editLink={editLink} page={record} {...popoverProps} />
      } else {
        edit = (
          <Button type="primary" className="edit-action" onClick={navigateTo(editLink)}>
            Edit
          </Button>
        )
      }
    }

    const isAdminOrOwner = isAdmin || accessLevel === 'own'

    const showProtectionMenu = (protectedAppsHasEnvVarsSet || record.protected) && isAdminOrOwner
    const enableProtectionLabel = `Protect ${isModule ? 'module' : 'app'}`

    const menu = (
      <Menu className="ant-dropdown-menu">
        <MenuLink onSelected={() => setPageToShowPermissions(record)} label="View user list" />
        <MenuLink disabled={record.protected} onSelected={() => setPageToBeRenamed(record)} label="Rename" />
        <MenuLink onSelected={() => setPageToBeCloned(record)} label="Duplicate" />
        <MenuLink
          onSelected={() => exportSave(record.name, record.uuid, true, record.isGlobalWidget)}
          label="Export and download"
        />
        <MenuLink disabled={record.protected} onSelected={() => setPageToBeMoved(record)} label="Move to folder" />
        {showProtectionMenu && <Menu.Divider />}
        {showProtectionMenu && !record.protected && (
          <MenuLink onSelected={() => setPageToBeProtected(record)} label={enableProtectionLabel} />
        )}
        {showProtectionMenu && record.protected && (
          <MenuLink onSelected={() => setPageToBeUnprotected(record)} label="Remove protection" />
        )}
        <Menu.Divider />
        {trash}
      </Menu>
    )

    const dropdown = editAccess ? (
      <Dropdown placement="bottomRight" className="other-actions" trigger={['click']} overlay={menu}>
        <Button type="ghost" style={{ padding: '2px 4px' }} className="ml8important" aria-label="More information">
          <Icon type="more-info-h" />
        </Button>
      </Dropdown>
    ) : (
      <></>
    )

    controls = (
      <div className="page-actions">
        {edit}
        {endUser}
        {dropdown}
      </div>
    )
  }

  const updateSelectedPageIds = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      addSelectedPageId()
    } else {
      removeSelectedPageId()
    }
  }

  const checkbox = <Checkbox checked={pageSelected} onChange={updateSelectedPageIds} />

  const nameMatches = matches ? matches.name : undefined
  const name: React.ReactChild[] = annotate(record.name, nameMatches, fuzzyHighlight)

  const isFavorited = favoritePageIds.includes(record.id)

  const favorite = () => favoritePage(record.id)
  const unfavorite = () => unfavoritePage(record.id)

  let linkProps: any = { to: endUserLink }

  if (selectionMode) {
    linkProps = {
      onClick: (e: MouseEvent) => {
        e.preventDefault()
        toggleMembershipInSelectedPageIds()
      },
    }
  }

  const lastEditedTime = moment(pageEditedBy.timestamp).fromNow()
  const lastEditorName = pageEditedBy.user
  const editorMatches = matches ? matches.editor : undefined

  const lastEditedBlurb = (
    <div className="light-gray fw-400 fs-13 flex items-center">
      {record.protected && <ProtectedIcon />}
      {lastEditedTime}
      {lastEditorName ? [' by ', ...annotate(lastEditorName, editorMatches, fuzzyHighlight)] : ''}
    </div>
  )

  return (
    <div
      key={record.id}
      className={classNames(
        'application-listing-item',
        'flex justify-center items-center bb-faint-gray h-100',
        pageSelected && 'selected',
        showPopoverTriggers && 'visible',
      )}
      {...parentHandlers}
    >
      {checkbox}
      <div className="listing-item-inner flex items-center justify-between w-100 h-100">
        <div className="pa12">
          <Icon type={isModule ? 'module' : 'app'} className="v-mid mh8" />
        </div>
        <div className="flex-grow h-100">
          <Link {...linkProps} className="fw-500 dark-gray fs-13 flex fd-col h-100 justify-center">
            <div className="listing-item-title truncate">
              {name}&nbsp;&nbsp;
              <Favorite
                isFavorited={isFavorited}
                disabled={selectionMode}
                favorite={favorite}
                unfavorite={unfavorite}
              />
            </div>
            {lastEditedBlurb}
          </Link>
        </div>
        {controls}
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationItem))

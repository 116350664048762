import addEventHandlerMigrations from './widgets/common/events/addEventHandlerMigrations'

type PluginFamily =
  | 'temporaryState'
  | 'transformers'
  | 'widgets'
  | 'datasources'
  | 'globalWidgetProp'
  | 'globalWidgetOutput'
  | 'instrumentation'
  | 'frame'

const requirePlugins = (
  cb: () => void,
  requireModules: { [pf in PluginFamily]?: boolean } = {
    temporaryState: true,
    transformers: true,
    widgets: true,
    datasources: true,
    globalWidgetProp: true,
    globalWidgetOutput: true,
    instrumentation: true,
    frame: true,
  },
): void => {
  require.ensure(
    [],
    () => {
      // Ensure the widgets are loaded before loading the page.
      // If you add additional one-off require statements here, you may want to add them to
      // initializePlugins (bootstrapEnv.ts) so they are "required" for testing purposes
      if (requireModules.transformers) {
        require('./Function')
      }
      if (requireModules.temporaryState) {
        require('./State')
      }
      if (requireModules.globalWidgetProp) {
        require('./GlobalWidgetProp/GlobalWidgetProp')
      }
      if (requireModules.globalWidgetOutput) {
        require('./GlobalWidgetOutput/GlobalWidgetOutput')
      }
      if (requireModules.datasources) {
        require('./datasources/models')
      }
      if (requireModules.instrumentation) {
        require('./Instrument')
      }
      if (requireModules.frame) {
        require('./Frame')
      }

      if (requireModules.widgets) {
        // TODO(Garrett): Switch everything from index -> widgetPlugin
        const context = require.context('./widgets', true, /(index|widgetPlugin)\.tsx?$/)
        context.keys().forEach(context)

        // adds the event handlers migration to (almost) all widgets
        addEventHandlerMigrations()
      }

      cb()
    },
    'widgets',
  )
}

export default requirePlugins

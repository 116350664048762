import React from 'react'
import ShortcutDetails from './ShortcutDetails'
import { ALL_SECTIONS } from './shortcutSections'
import Modal from 'components/design-system/Modal'

import './ShortcutDetailsModal.scss'

interface ShortcutDetailsPopoverProps {
  onClose: () => void
}

export default function ShortcutDetailsModal({ onClose }: ShortcutDetailsPopoverProps) {
  return (
    <Modal
      title="Keyboard Shortcuts"
      visible={true}
      onCancel={onClose}
      mask={false}
      maskClosable={false}
      footer={null}
      centered={false}
      className="shortcut-details-modal"
      width="300px"
    >
      <ShortcutDetails sections={ALL_SECTIONS} />
    </Modal>
  )
}
